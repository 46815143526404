import { useContext } from "react";
import { WebsiteEditorCard, renderBorderSeparator } from "pages/post-auth/Website/styles";
import { useStyles } from "../../styles";
import { objectToArrayInOrder } from "modules/Utils";
import {
  streamLinkOrientations,
  StyleType,
} from "../../utils";
import { clsx } from 'modules/Utils';
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { Select } from "@mui/material";
import { Body2, Subtitle1 } from "components/shareable/Typography";
import { EditorSectionContainer, WebsiteEditorSection } from "./styles";
import { Button, MenuItem } from "@material-ui/core";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { BoxesWebsiteLayoutIcon, StackedWebsiteLayoutIcon, TraditionalWebsiteLayoutIcon } from "components/svg-icons/website/themes/icons";
import CloudinaryUploadWidget from "components/shareable/CloudinaryUploadWidget";
import { ReactComponent as EditIcon } from "assets/images/edit-purple.svg";
import { WEBSITE_TYPES, WebsiteType } from "types/global";
import SymphonyLogoEditorCard from "../../../SymphonyLogoEditorCard";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { FontSelectorSection } from "./FontSelectorSection";
import TextAlignmentSection from "./TextAlignmentSection";
import { ColorSelectorSection } from "./ColorSelectorSection";
import { ThemeSelectorSection } from "./ThemeSelectorSection";
import { THEME_STYLES, ThemeStyleKey } from "./ThemeSelectorSection/utils";
import Text from "modules/Text";
import { PlanType } from "modules/Const";
import Colors from "modules/Colors";
import SwitchSymphony from "components/shareable/SwitchSymphony";

const { BASIC, ART, CLASSIC, FULL_VIDEO } = THEME_STYLES

interface Props {
  websiteType: WebsiteType
}
export const StyleSection = ({ websiteType }: Props) => {
  const classes = useStyles();
  const { currentBrand, isPaidBrand: isPaidUser } = useContext(CurrentBrandContext);
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const {
    styleType, setStyleType,
    mainColor = '', setMainColor,
    recordSelected,
    secondaryColor = '', setSecondaryColor,
    themeStyle, setThemeStyle,
    fullVideoUrl, setFullVideoUrl,
    fontThemeStyle, setFontThemeStyle,
    customThumbnailUrl, setCustomThumbnailUrl,
    customThumbnailViewStyle, setCustomThumbnailViewStyle,
    removeSymphonyBrand, setRemoveSymphonyBrand,
    addAffiliateLinkToLogo,
    titleAlignment, setTitleAlignment,
    youtubeVideoLink, setYTVideoLink,
    selectedSymphonyLogoStyle, setSelectedSymphonyLogoStyle,
    embedYt, setEmbedYt,
  } = useContext(WebsiteContext)

  const imageUrl = recordSelected?.thumbnail_url || currentBrand?.image || ''

  const LinkOrientationBox = (props: { currentStreamLinkOrientation: StyleType, websiteType: WebsiteType }) => {
    const { currentStreamLinkOrientation, websiteType } = props
    const isStyleType = styleType === currentStreamLinkOrientation.name
    if (websiteType === WEBSITE_TYPES.TOUR && currentStreamLinkOrientation.name === 'traditional') {
      return null
    }

    return (
      <div
        className={clsx(classes.themeBox, isStyleType && classes.themeBoxSelected)}
        onClick={() => setStyleType(currentStreamLinkOrientation.name)}
      >
        {currentStreamLinkOrientation.name === 'boxes' && (<BoxesWebsiteLayoutIcon selected={isStyleType} />)}
        {currentStreamLinkOrientation.name === 'stacked' && (<StackedWebsiteLayoutIcon selected={isStyleType} />)}
        {currentStreamLinkOrientation.name === 'traditional' && (<TraditionalWebsiteLayoutIcon selected={isStyleType} />)}
        {currentStreamLinkOrientation.title}
      </div>
    );
  };

  const UploadArtworkSection = ({ artworkUrl }: { artworkUrl: string }) => {
    const classes = useStyles();
    const buttonClassName = artworkUrl
      ? classes.changeButton
      : classes.uploadButton;

    return (
      <div className="flex">
        <Button className={buttonClassName} id="upload_video">
          {artworkUrl && (
            <video
              className={classes.artworkPreview}
              src={artworkUrl}
            />
          )}
          <Body2 color={Colors.textColorWhite}>{artworkUrl ? 'video.mp4' : 'Upload'}</Body2>
          {artworkUrl && <EditIcon style={{ width: 18, height: 18 }} />}
        </Button>
        <CloudinaryUploadWidget
          uploadButtonId="upload_video"
          resourceType="video"
          maxFiles={1}
          showUploadMoreButton={false}
          clientAllowedFormats={["mp4"]}
          onUploadAdded={(_fileId: string) => { }}
          onUploadSuccess={handleOnUploadVideoSuccess}
          onUploadAbortAll={() => { }}
          onUploadAbort={(_fileId: string) => { }}
          onCancel={(_info: Record<string, unknown>) => { }}
          maxFileSize={50000000}
        />
      </div>
    );
  };
  const UploadThumbnailSection = ({ thumbnailUrl, allowResizing = false }: { thumbnailUrl: string, allowResizing?: boolean }) => {
    const classes = useStyles();
    const buttonClassName = thumbnailUrl
      ? classes.changeButton
      : classes.uploadButton;

    return (
      <div className="flex flex-col">
        <div className="flex">
          <Button className={buttonClassName} id="upload_thumbnail">
            {customThumbnailUrl && (
              <img
                className={classes.artworkPreview}
                src={customThumbnailUrl}
                alt="Custom Thumbnail"
              />
            )}
            <Body2 color={Colors.textColorWhite}>{customThumbnailUrl ? 'thumbnail.jpg' : 'Upload'}</Body2>
            {customThumbnailUrl && <EditIcon style={{ width: 18, height: 18 }} />}
          </Button>
          <CloudinaryUploadWidget
            uploadButtonId="upload_thumbnail"
            resourceType="image"
            maxFiles={1}
            showUploadMoreButton={false}
            clientAllowedFormats={["jpg", "jpeg", "png"]}
            onUploadAdded={(_fileId: string) => { }}
            onUploadSuccess={handleOnUploadThumbnailSuccess}
            onUploadAbortAll={() => { }}
            onUploadAbort={(_fileId: string) => { }}
            onCancel={(_info: Record<string, unknown>) => { }}
          />
        </div>
        {allowResizing && (
          <>
            <Select
              value={customThumbnailViewStyle}
              onChange={(e) => setCustomThumbnailViewStyle(e.target.value as 'cover' | 'contain')}
              className='mt-4 border h-8 w-full'
            >
              <MenuItem value='cover'>Stretch to Fit</MenuItem>
              <MenuItem value='contain'>Show Entire Image</MenuItem>
            </Select>
            <div className='text-xs text-gray-500 mt-2'>
              {customThumbnailViewStyle === 'contain'
                ? '* Your image will be shown with its full height + width ratio.'
                : '* Your image will be stretched to fill the entire image container.'}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleOnUploadVideoSuccess = (file: { info: { secure_url: string } }) => {
    const artwork = file.info.secure_url;
    setFullVideoUrl(artwork)
  };

  const handleOnUploadThumbnailSuccess = (file: { info: { secure_url: string } }) => {
    const thumbnail = file.info.secure_url;
    setCustomThumbnailUrl(thumbnail)
  };

  const handleThemeSelect = (themeName: ThemeStyleKey) => {
    setThemeStyle(themeName);
    if (themeName !== ART && themeName !== CLASSIC) {
      setEmbedYt(false);
    } else {
      setEmbedYt(Boolean(youtubeVideoLink?.length));
    }
  };

  return (
    <div
      className={classes.customizeWebsiteV2Variable}
      style={{ flexDirection: 'column' }}
    >
      <EditorSectionContainer>
        <ThemeSelectorSection
          websiteType={websiteType}
          selectedTheme={themeStyle || THEME_STYLES.ART}
          isPaidUser={isPaidUser}
          recordType={recordSelected?.type}
          onThemeSelect={handleThemeSelect}
        />
        {(websiteType === WEBSITE_TYPES.RECORD_STREAMS || websiteType === WEBSITE_TYPES.TOUR) && (
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>
                {websiteType === WEBSITE_TYPES.TOUR ? 'Dates Style' : 'Choose your link display'}
              </Subtitle1>
              <div className="flex gap-4 overflow-scroll">
                {objectToArrayInOrder(streamLinkOrientations).map((slo, index) => {
                  return <LinkOrientationBox key={`${slo.name}-${index}`} currentStreamLinkOrientation={slo} websiteType={websiteType} />
                }
                )}
              </div>
            </WebsiteEditorSection>
          </>
        )}
        {themeStyle === FULL_VIDEO && (
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>Upload a video</Subtitle1>
              <Body2>
                Select the video you want to display. (50MB max)
              </Body2>
              <UploadArtworkSection artworkUrl={fullVideoUrl || ''} />
            </WebsiteEditorSection>
          </>
        )}
        {websiteType === WEBSITE_TYPES.DATA_COLLECTOR &&
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>Cover</Subtitle1>
              <Body2>
                Select the artwork you want to display along with your data collector. We recommend using at least an image that's 1000x1000.
              </Body2>
              <UploadThumbnailSection thumbnailUrl={customThumbnailUrl || ''} />
            </WebsiteEditorSection>
          </>
        }
        {websiteType === WEBSITE_TYPES.TOUR &&
          <>
            {renderBorderSeparator()}
            <WebsiteEditorSection>
              <Subtitle1>Cover</Subtitle1>
              <Body2>
                Select the artwork you want to display along with your tour. We recommend using at least an image that's 1000x1000.
              </Body2>
              <UploadThumbnailSection thumbnailUrl={customThumbnailUrl || ''}
                allowResizing={themeStyle === BASIC} />
            </WebsiteEditorSection>
          </>
        }
        {renderBorderSeparator()}
        <FontSelectorSection
          isPaidUser={isPaidUser}
          fontThemeStyle={fontThemeStyle}
          onFontStyleChange={(fontStyle) => {
            setFontThemeStyle(fontStyle)
          }}
        />
        {renderBorderSeparator()}
        <TextAlignmentSection
          selectedTitleAlignment={titleAlignment}
          onTitleAlignmentChange={(titleAlignment) => {
            setTitleAlignment(titleAlignment)
          }}
        />
        {renderBorderSeparator()}
        <WebsiteEditorSection>
          <ColorSelectorSection
            sectionTitle="Page Color"
            selectedColor={mainColor}
            onColorChange={setMainColor}
            paletteImageUrl={customThumbnailUrl || imageUrl}
          />
        </WebsiteEditorSection>
        {(styleType === 'boxes' || styleType === 'traditional' || websiteType === WEBSITE_TYPES.DATA_COLLECTOR || websiteType === WEBSITE_TYPES.TOUR) &&
          <>
            {renderBorderSeparator()}
            <ColorSelectorSection
              sectionTitle="Button Color"
              selectedColor={secondaryColor}
              onColorChange={setSecondaryColor}
              paletteImageUrl={customThumbnailUrl || imageUrl}
            />
          </>
        }
      </EditorSectionContainer>
      {(themeStyle === ART || themeStyle === CLASSIC) && (
        <WebsiteEditorCard
          title="YouTube Embed"
          subtitle="Paste the URL of the Youtube video you want to embed in your website here."
        >
          <SwitchSymphony
            checked={embedYt}
            onChange={() => setEmbedYt(!embedYt)}
            className={clsx(embedYt && classes.switchEnabled)}
          />
          {embedYt && (
            <input
              style={Text.input}
              type='text'
              value={youtubeVideoLink}
              placeholder='YouTube Embed URL'
              onChange={(e) => setYTVideoLink(e.target.value)}
            />
          )}
        </WebsiteEditorCard>
      )}
      <SymphonyLogoEditorCard
        selectedSymphonyLogoStyle={selectedSymphonyLogoStyle}
        setSelectedSymphonyLogoStyle={(styleId) => setSelectedSymphonyLogoStyle(styleId)}
        removeSymphonyBrand={removeSymphonyBrand || false}
        onChangeShowSymphonyLogo={(checked) => {
          let hideSymphonyLogo = !checked
          setRemoveSymphonyBrand(hideSymphonyLogo)
        }}
        onUpgradeClick={() => {
          handleOpenUpgradeToProModal({
            upgradeCallToAction: "Customize + get paid from your website with Symphony Lite",
            highlightedPlan: PlanType.LITE,
            source: "Website Editor - Symphony Logo Editor Card"
          })
        }}
        addAffiliateLinkToLogo={addAffiliateLinkToLogo}
      />
      {/* TODO: Add backgroud color editor back in the future */}
      {/* <WebsiteEditorCard title='Desktop Background'>
        <div className='text-xs text-gray-500 mb-2'>
          * Select the background you want your website to have on larger, desktop devices
        </div>
        <div className='mainBgTypeContainer flex flex-col gap-4'>
          <Select
            value={bgType}
            onChange={(e) => setBgType(e.target.value)}
            className='mainBgTypeDropdown border h-8 w-10/12 w-full'
          >
            <MenuItem value='color'>Color</MenuItem>
            <MenuItem value='artwork'>Artwork</MenuItem>
          </Select>
          <div className='py-2 flex items-center w-auto'>
            {bgType === 'color' ? (
              <div className={classes.colorPickerContainer}>
                <input
                  className={classes.colorPickerBox}
                  type='color'
                  id='bg-color'
                  name='bgcolor'
                  value={bgValue?.color}
                  onChange={(e) => setBgValue({
                    color: e.target.value,
                    artwork: bgValue?.artwork || 0,
                  })}
                />
                <div>{bgValue?.color}</div>
              </div>
            ) : (
              <p>Your artwork will be used as the background image.</p>
            )}
          </div>
        </div>
      </WebsiteEditorCard> */}
    </div>
  )
}