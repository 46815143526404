import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import Colors from "modules/Colors";

export const useStyles = makeStyles({
  textfield: {
    border: 'none',
    borderRadius: '8px 12px',
    backgroundColor: Colors.PAPER_BACKGROUND_CONTAINER,
  },
  globeIcon: {
    '& path': {
      stroke: Colors.greyDark,
    },
  },
  dialog: {
    borderRadius: 12,
  },
  adornment: {
    marginRight: 0,
    paddingRight: 0,
    gap: 8,
  },
});

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  width: 100%;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
