import Dialog from "@material-ui/core/Dialog"
import { useContext } from "react"
import {
  ModalConnectButton,
  ModalConnectButtonContainer,
  ModalConnectButtonLabel,
  ModalContainer,
  useStyles,
} from "./style"
import ArtistItem from "../Spotify/Components/ArtistList/ArtistItem"
import { useHistory } from "react-router-dom"
import { CurrentBrand, SpotifyArtist } from "types/global"
import HeaderModal from 'components/connect/connectModals/Spotify/Components/HeaderModal'
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import useScreen from "Hooks/useScreen"

interface Props {
  isOpen: boolean
  artist: SpotifyArtist
  existingBrand: CurrentBrand
  goBack: () => void
  closeModal: () => void
}

const AlreadyExistingArtistModal = ({
  isOpen,
  artist,
  existingBrand,
  goBack,
  closeModal,
}: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const { mobileView } = useScreen();
  const { setCurrentBrand } = useContext(CurrentBrandContext)

  const goToDashboard = (_parameter: unknown) => {
    setCurrentBrand(existingBrand)
    closeModal()
    history.push('/dashboard')
  }

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={mobileView}
    >
      <ModalContainer
        width={mobileView ? "auto" : "678px"}
        margin={mobileView ? "0px" : "auto"}
        height="100%"
        padding={mobileView ? "16px" : "32px"}
        maxHeight="100%"
        gap="32px"
      >
        <HeaderModal
          closeModal={goBack}
          title="You’ve already added this artist."
          subtitle={`Looks like you’ve already added ${artist?.name || ""} to the artists under your profile.`}
        />
        <ArtistItem
          artist={artist}
          selectArtist={goToDashboard}
          buttonLabel="Go to Artist Dashboard"
        />
        <ModalConnectButtonContainer>
          <ModalConnectButton onClick={goBack} color="#ffffff">
            <ModalConnectButtonLabel color="#8800ff">
              Search for another artist
            </ModalConnectButtonLabel>
          </ModalConnectButton>
        </ModalConnectButtonContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default AlreadyExistingArtistModal
