import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { MoreVert } from "@material-ui/icons";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash.svg";
import { Body2 } from 'components/shareable/Typography';
import Colors from "modules/Colors";
import { useStyles } from './styles';
import useModal from 'Hooks/useModal';
import { MemberRole, MemberRoles, TeamMember, TeamPricesData } from 'types/global';
import RemoveMemberModal from '../RemoveMemberModal';

type TeamMemberMenuProps = {
  member: TeamMember;
  myRole?: MemberRole;
  onEditPermissions: () => void;
  teamPrices?: TeamPricesData;
  handleRemoveTeamMember: () => void;
};

export const TeamMemberMenu = ({
  member,
  myRole,
  onEditPermissions,
  teamPrices,
  handleRemoveTeamMember
}: TeamMemberMenuProps) => {
  const classes = useStyles();
  const removeMemberModal = useModal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(prev => prev ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClose = async () => {
    handleClose();
    handleRemoveTeamMember()
    removeMemberModal.closeModal();
  };

  // Don't show menu options if:
  // 1. The member is an owner (can't modify owner permissions)
  // 2. Current user is a regular member (members can't modify other members)
  // 3. Current user is an admin trying to modify another admin (admins can't modify other admins)
  if (member.role === MemberRoles.OWNER || myRole === MemberRoles.MEMBER
    || (myRole === MemberRoles.ADMIN && member.role === MemberRoles.ADMIN)
  ) {
    return null
  }

  return (
    <div onClick={handleClick}>
      <MoreVert />
      <Menu
        id="long-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={onEditPermissions}
          className='flex items-center gap-1'
        >
          <EditIcon />
          <Body2 color={Colors.textColor}>Edit Permissions</Body2>
        </MenuItem>
        <MenuItem
          onClick={() => removeMemberModal.openModal()}
          className='flex items-center gap-1'
        >
          <TrashIcon className={classes.redIcon} />
          <Body2 color={Colors.red}>Remove Member</Body2>
        </MenuItem>
      </Menu>
      <RemoveMemberModal open={removeMemberModal.open} member={member} closeModal={handleModalClose} teamPrices={teamPrices} />
    </div>
  );
};