import { AutocompleteCloseReason } from "@material-ui/lab/useAutocomplete";
import { FbSuggestion } from "types/global";
import uniqBy from "lodash/uniqBy";
import head from "lodash/head";
import { Option as SpotifyArtistsOption } from "pages/post-auth/MarketingPage/MusicStreams/TargetingView/Components/AutoCompleteSpotifyArtists/utils";

export interface DeleteIconProps {
  color?: string;
  onClick: () => void;
}

export const NOT_REMOVABLE = "NOT_REMOVABLE";

export const EMPTY_STRING = "";

export type AutoCompleteReason = AutocompleteCloseReason | "remove-option";

export type DeleteArgs = [FbSuggestion, () => void, string | undefined];

export const getNotRemovableOptions = (genre: string) => {
  const options = ["Your existing fans", "Fans similar to your existing fans"];

  if (genre) options.push(`Fans of ${genre} genre`);

  return options.map((name, index) => ({
    id: `${NOT_REMOVABLE}${index}`,
    name,
    artistId: null,
    audience_size: null,
    country: null,
    country_access: null,
    description: null,
    partner: null,
    path: null,
    source: null,
    topic: null,
    type: null,
  }));
};

export const getOnDeleteChip = (option: FbSuggestion, onDelete: () => void, color?: string) => {
  if (!option.id?.includes(NOT_REMOVABLE)) return onDelete;
  return undefined;
};

export const getDataWhenDeletedArtist = (
  data: FbSuggestion[],
  deletedArtist: SpotifyArtistsOption[]
) => data.filter((o) => o.artistId !== head(deletedArtist)?.id);

export const getDataWhenSuggestedArtist = (
  data: FbSuggestion[],
  suggested: FbSuggestion[]
) => uniqBy([...data, ...suggested], "id");

export const getDataWhenGenreChanged = (
  data: FbSuggestion[],
  notRemovable: FbSuggestion[]
) => [...notRemovable, ...data.filter((o) => !o.id?.includes(NOT_REMOVABLE))];
