import { useContext, useEffect } from "react";
import { track } from "analytics";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import useStyles, {
  LeftSection,
  RightSection,
  DialogContent,
  DialogTitle,
  DialogTitleContainer,
  BenefitsMainContainer,
  BenefitContainer,
  BenefitLabel,
  CloseIconContainer,
  Image,
  BenefitListContainer,
} from "./styles";
import { ReactComponent as CheckGreen } from "assets/images/check-green.svg"
import CloseIcon from "components/shareable/CloseIcon";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import ProfileIcon from "assets/images/profileIcon.svg";
import AvatarUserStatus from "../AvatarUserStatus";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { triggerVWOVirtualPageView } from "modules/Utils";
import PrimaryButton from "../PrimaryButton";
import useScreen from "Hooks/useScreen";
import { SUBSCRIPTION_BENEFITS, PlanType } from "modules/Const";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

interface Props {
  sessionId?: string;
  dialogProps: DialogProps;
  isOrganization?: boolean;
}

const UpgradeToProSucceededModal = ({
  sessionId,
  dialogProps,
  isOrganization
}: Props) => {
  const { mobileView } = useScreen();
  const { currentBrand, isProBrand, isLiteBrand } = useContext(CurrentBrandContext)
  const { organization, isOrganizationReady } = useCurrentTeam()
  const {
    name: brandName = '',
    image: brandImage = '',
    slug: brandSlug = ''
  } = currentBrand || {}

  const { onClose, open } = dialogProps;
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      triggerVWOVirtualPageView('/welcome-to-pro-modal'); // Trigger VWO virtual page view with full URL
    }
  }, [open]);


  const handleCloseDialog = (event: {}) =>
    onClose && onClose(event, "backdropClick");


  useEffect(() => {
    if (sessionId) {
      track("Finalizing Subscription", { name: "UpgradeToProSucceededModal" });
    }
  }, [sessionId]);

  let welcomeText = "Welcome to Symphony Pro!"
  if (isLiteBrand) {
    welcomeText = "Welcome to Symphony Lite!"
  }

  let subscriptionBenefits = SUBSCRIPTION_BENEFITS[isLiteBrand ? PlanType.LITE : PlanType.PRO]

  // dont show modal if user is part of an organization, 
  let showModal = !organization && open
  return (
    <Dialog
      {...dialogProps}
      open={showModal}
      classes={{ root: classes.rootDialog }}
      maxWidth="md"
      PaperProps={{ className: classes.paper }}
    >
      <DialogContent flexDirection={mobileView ? "column" : "row"}>
        <CloseIconContainer onClick={handleCloseDialog}>
          <CloseIcon />
        </CloseIconContainer>
        <LeftSection width={mobileView ? "100%" : "40%"} height={mobileView ? "180px" : "auto"}>
          <Image
            src={require("../../../assets/images/settingsScreen/proSucceeded.png").default}
            width={mobileView ? "120px" : "auto"}
            height={mobileView ? "120px" : "auto"}
          />
        </LeftSection>

        <RightSection width={mobileView ? "100%" : "60%"} padding={mobileView ? "32px" : "40px 32px"}>
          <DialogTitleContainer>
            {isOrganization && (<UpgradeToProLabel
              color="PURPLE"
              label={"Symphony for Teams"}
              maxWidth={"210px"} />)}
            <DialogTitle>
              {welcomeText}
            </DialogTitle>
          </DialogTitleContainer>

          <AvatarUserStatus
            withoutWhiteBorder
            mainContainerProps={{
              backgroundColor: "#FFF2E2",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
            }}
            userNameTextProps={{ color: "black", fontSize: 16, textAlign: "left" }}
            proTextProps={{ color: "#F5BA23", fontSize: 10 }}
            avatarOverride={brandImage ? brandImage : ProfileIcon}
          />
          <BenefitsMainContainer>
            <BenefitLabel fontWeight="bold">You’ve unlocked:</BenefitLabel>
            <BenefitListContainer>
              {subscriptionBenefits.perks.map(({ info, detail }) => (
                <BenefitContainer key={info} >
                  <CheckGreen />
                  <BenefitLabel>{info} {detail && `(${detail})`}</BenefitLabel>
                </BenefitContainer>
              ))}
            </BenefitListContainer>
          </BenefitsMainContainer>
          <PrimaryButton
            width="100%"
            text={"Explore Symphony"}
            onClick={() => handleCloseDialog({})}></PrimaryButton>
        </RightSection>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeToProSucceededModal;
