import CircularLoader from "../CircularLoader"
import { Card } from "../../styles"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { PreConnectionConfigs } from "pages/post-auth/MarketingPage/utils/fbCampaigns"

interface Props {
  configs: PreConnectionConfigs
  handleClickRefreshButton: () => void
}

const AnimatedCircleIcon = () => (
  <div className=" flex-none loading-blk">
    <div className="svg-loader">
      <svg
        className="svg-container"
        height="36"
        width="36"
        viewBox="0 0 100 100"
      >
        <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
        <circle className="loader-svg animate" cx="50" cy="50" r="45"></circle>
      </svg>
    </div>
  </div>
)

const PreConnectionConfigsCard = ({
  configs,
  handleClickRefreshButton,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <div className="flex flex-col w-full">
        {!configs && (
          <CircularLoader message="Making sure your connections are setup..." />
        )}

        {configs && !configs.status && (
          <>
            {!configs?.custom_audience_accepted?.status && (
              <div className="sy-card px-5 lg:px-10">
                <div className="flex">
                  <AnimatedCircleIcon />
                  <div className="px-3 items-center flex">
                    <h5 className="text-dark text-left">
                      Please accept the{" "}
                      {configs?.custom_audience_accepted?.name} on Facebook
                    </h5>
                  </div>
                  <a
                    className="flex items-center pl-3"
                    target="_blank"
                    href={configs?.custom_audience_accepted?.action || ""}
                    rel="noreferrer"
                  >
                    <img
                      alt="right-arrow.svg"
                      src={require("assets/images/right-arrow.svg").default}
                    />
                  </a>
                </div>
              </div>
            )}
            {!configs?.value_based_custom_audience_tos?.status && (
              <div className="sy-card px-5">
                <div className="flex">
                  <AnimatedCircleIcon />
                  <div className="px-3 items-center flex">
                    <h5 className="text-dark text-left">
                      Please accept the{" "}
                      {configs.value_based_custom_audience_tos?.name} on
                      Facebook
                    </h5>
                  </div>
                  <a
                    className="flex items-center pl-3"
                    target="_blank"
                    href={
                      configs?.value_based_custom_audience_tos?.action || ""
                    }
                    rel="noreferrer"
                  >
                    <img
                      alt="right-arrow.svg"
                      src={require("assets/images/right-arrow.svg").default}
                    />
                  </a>
                </div>
              </div>
            )}
            <button
              type="button"
              onClick={handleClickRefreshButton}
              className="w-full text-center p-4 text-primary font-bold border-b border-backgound-100"
            >
              Refresh
            </button>
          </>
        )}
      </div>
    </Card>
  )
}

export default PreConnectionConfigsCard
