import { GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";

const getCurrencyFormat = (value: number, currency: string) => {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(value);
  };

const UserCell = ({ userId, userEmail }: { userId: string, userEmail: string}) => (
    <div>
        <p>{userId}</p>
        <p>{userEmail}</p>
    </div>
);

export const CustomToolBarSnapshot = () => {
    return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarExport/>
        </GridToolbarContainer>
      );
}

export const SnapshotsCols: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
    },
    {
        field: "userEmail",
        headerName: "User",
        renderCell: (params) => {
            return <UserCell userId={params.row.userId} userEmail={params.row.userEmail} />;
        },
        headerAlign: "center",
        flex: 1,
        minWidth: 280,
    },
    {
        field: "brandName",
        headerName: "Brand",
        renderCell: (params) => {
            return <UserCell userId={params.row.brandId} userEmail={params.row.brandName} />;
        },
        headerAlign: "center",
        flex: 1,
        minWidth: 130,
    },
    {
        field: "source",
        headerName: "Source",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 200,
        width: 200,
    },
    {
        field: "campaignType",
        headerName: "Campaign Type / #",
        renderCell: (params) => {
            if(isNaN(Number(params.row.campaignType)))return params.row.campaignType.replaceAll('_', ' ').toUpperCase();
            return params.row.campaignType;
        },
        headerAlign: "center",
        align: "center",
        flex: 1,
        minWidth: 200,
    },
    {
        field: "spend",
        headerName: "Spend",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            const spend = params.row.spend ?? 0;
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p>
                {getCurrencyFormat(spend, params.row.currency.code)}
            </p>
            <p>{params.row.currency.code}</p>
            </div>
        }
    },
    {
        field: "totalClicks",
        headerName: "Total Clicks",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            return params.row.totalClicks ?? 0;
        }
    },
    {
        field: "totalViews",
        headerName: "Total Views",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            return params.row.totalViews ?? 0;
        }
    },
    {
        field: "totalPlaylistFollows",
        headerName: "Total Playlist Follows",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            return params.row.totalPlaylistFollows ?? 0;
        }
    },
    {
        field: "totalStreamingLinkClicks",
        headerName: "Total Streaming Clicks",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            return params.row.totalStreamingLinkClicks ?? 0;
        }
    },
    {
        field: "totalClicksConverted",
        headerName: "Total Clicks Converted",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            return params.row.totalClicksConverted ?? 0;
        }
    },
    {
        field: "costPerView",
        headerName: "Cost Per View",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            const cost = params.row.costPerView ?? 0;
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p>
                {getCurrencyFormat(cost, params.row.currency.code)}
            </p>
            <p>{params.row.currency.code}</p>
            </div>
        }
    },
    {
        field: "costPerClick",
        headerName: "Cost Per Click",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            const cost = params.row.costPerClick ?? 0;
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p>
                {getCurrencyFormat(cost, params.row.currency.code)}
            </p>
            <p>{params.row.currency.code}</p>
            </div>
        }
    },
    {
        field: "costPerStreamingLinkClick",
        headerName: "Cost Per Streaming Click",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            const cost = params.row.costPerStreamingLinkClick ?? 0;
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p>
                {getCurrencyFormat(cost, params.row.currency.code)}
            </p>
            <p>{params.row.currency.code}</p>
            </div>
        }
    },
    {
        field: "costPerConversion",
        headerName: "Cost Per Conversion Click",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            const cost = params.row.costPerConversionClick ?? 0;
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p>
                {getCurrencyFormat(cost, params.row.currency.code)}
            </p>
            <p>{params.row.currency.code}</p>
            </div>
        }
    },
    {
        field: "costPerFollow",
        headerName: "Cost Per Follow",
        align: "center",
        headerAlign: "center",
        flex: 1,
        minWidth: 120,
        width: 120,
        renderCell: (params) => {
            const cost = params.row.costPerFollow ?? 0;
            return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p>
                {getCurrencyFormat(cost, params.row.currency.code)}
            </p>
            <p>{params.row.currency.code}</p>
            </div>
        }
    },
]; 
