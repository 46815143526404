import PresaveMiniature from '../PresaveMiniature';
import { Website } from 'types/global';
import TertiaryButton from 'components/shareable/TertiaryButton';
import { MainContainer, PresavePreviewContainer } from './styles';

interface Props {
  openPresaveModal: () => void;
  presaveImage: string;
  style: Website;
}

const DesignPreviewButton = ({
  openPresaveModal,
  presaveImage,
  style,
}: Props) => {
  return (
    <MainContainer>
      <PresavePreviewContainer>
        <PresaveMiniature image={presaveImage} style={style} />
        <span>Design Preview</span>
      </PresavePreviewContainer>
      <TertiaryButton
        onClick={openPresaveModal}
        text="View"
      />
    </MainContainer>
  );
}

export default DesignPreviewButton;
