import { StyledAnchor, StyledArticle, StyledBannerDetails } from './styles'
import { IssuesCountSummary } from './IssuesCountSummary'
import { Body2, Subtitle2, ButtonText, Subtitle1 } from 'components/shareable/Typography'
import Colors from "modules/Colors"
import Intercom from 'helpers/Intercom'
import { FacebookError } from 'types/global'

export type FacebookAdAccountInfo = {
  name: string,
  id: string,
  business?: { name: string, id: string },
  pixel?: { id: string, name: string }
}

interface FacebookCampaignIssuesBannerProps {
  adAccount: FacebookAdAccountInfo
  conversionsEventName?: string
  facebookErrors: FacebookError[]
}

export function FacebookCampaignIssuesBanner({
  adAccount,
  conversionsEventName,
  facebookErrors,
}: FacebookCampaignIssuesBannerProps) {

  return (
    <StyledBannerDetails open>
      <IssuesCountSummary issuesCount={facebookErrors.length} />
      <div className="flex gap-2 flex-col">
        {facebookErrors.map((error, index) => {
          const errorLink = (error.details as { url: string })?.url
            ? (error.details as { url: string }).url.toString()
            : null
          const { title, description, ctaLink, ctaText, supportMessage, intercomArticleId } = error
          const displayDivider: boolean = index !== facebookErrors.length - 1

          return (
            <StyledArticle key={error.code} displayBottomBorderLine={displayDivider}>
              <div className="flex flex-col gap-1">
                <Subtitle1>{title}</Subtitle1>
                <Body2>
                  {description || ''}
                </Body2>
              </div>
              {ctaLink && (
                <StyledAnchor href={ctaLink || errorLink || ''} target="about:blank" rel="noreferrer">
                  <ButtonText>{ctaText}</ButtonText>
                </StyledAnchor>
              )}
              {intercomArticleId && (
                <ButtonText onClick={() => Intercom.openArticleWithId(intercomArticleId)}>
                  {ctaText}
                </ButtonText>
              )}
              {supportMessage && (
                <Subtitle2 color={Colors.yellow}>{supportMessage}</Subtitle2>
              )}
            </StyledArticle>
          )
        })}
      </div>
    </StyledBannerDetails>
  )
}
