import { useContext, useEffect, useState, CSSProperties } from "react";
import { Container, MainContainer } from "styles/shared";
import {
  OverallChartContainer,
  ChartHeader,
  LeftSideHeader,
  RightSideHeader,
  StatsCardsContainer,
  LowerSection,
  DateButton,
} from "./styles";
import dayjs from "dayjs";
import { Body1, Headline2 } from "components/shareable/Typography";
import { StatCard } from 'components/shareable/StatCard';
import { getWebsiteOverallData } from "services/symphonyApi/brandService";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import LoadingLottie from "components/Loader/LoadingLottie";
import SitesList from './components/SitesList';
import { contrastLinkImgMapping, streamingLinkNamesToDbKeysMapping } from "helpers/StreamingMappings";
import { TopGeographiesBarChart } from './components/CountriesBarChart';
import { SLineChart } from "components/shareable/DATA_PRESENTATION/Charts/SLineChart";
import MenuProOption from "components/shareable/MenuProOption";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockChartImage from "assets/images/unlock-data-chart.svg";
import useScreen from "Hooks/useScreen";
import PopoverCampaignAnnotations from "components/shareable/PopoverCampaignAnnotations";
import Colors from "modules/Colors";

type ChartOption = {
  label: string;
  value: string;
  selected: boolean;
  proOption: boolean;
}

const chartOptionsInit: ChartOption[] = [
  {
    label: "7 days",
    value: "7-days",
    selected: true,
    proOption: false,
  },
  {
    label: "30 days",
    value: "30-days",
    selected: false,
    proOption: true,
  },
  {
    label: "3 months",
    value: "3-months",
    selected: false,
    proOption: true,
  },
  {
    label: "All Time",
    value: "all",
    selected: false,
    proOption: true,
  },
];

type Metrics = {
  topLevelMetrics: {
    pageVisits: number,
    streamingLinkClicks: number,
    ctr: number
  },
  referrers: [],
  overallActivity: [],
  geographicBreakdown: {
    countries: [],
    cities: []
  },
  contentBreakdown: [],
  streamingServicesBreakdown: []
}


export const OverallWebsiteDashboard = () => {
  const { mobileView } = useScreen();
  const { currentBrand, isPaidBrand } = useContext(CurrentBrandContext);

  const [chartOptions, setChartOptions] = useState(chartOptionsInit)
  const [loading, setLoading] = useState(false);
  const [emptyView, setEmptyView] = useState(false);
  const [mainMetrics, setMainMetrics] = useState<Metrics>()

  const [showAnnotations, setShowAnnotations] = useState(false);

  const getMainMetrics = async () => {
    setEmptyView(false)
    setLoading(true);
    if (!currentBrand) return

    const selectedCharOption = chartOptions.find(option => option.selected);
    let startDate = undefined;
    let endDate = undefined;
    switch (selectedCharOption?.value) {
      case '7-days':
        endDate = dayjs().startOf('day').format('YYYY-MM-DD')
        startDate = dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')
        break
      case '30-days':
        endDate = dayjs().startOf('day').format('YYYY-MM-DD')
        startDate = dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
        break
      case '3-months':
        endDate = dayjs().startOf('day').format('YYYY-MM-DD')
        startDate = dayjs().subtract(3, 'months').startOf('day').format('YYYY-MM-DD')
        break
    }

    const mainMetrics = await getWebsiteOverallData(currentBrand.slug!, startDate, endDate);

    if (mainMetrics?.data) {
      setMainMetrics(mainMetrics.data)
    }
    else {
      setEmptyView(true)
    }
    setLoading(false);
  }

  useEffect(() => {
    getMainMetrics();
  }, [chartOptions])

  const getChartButtons = () => {
    return (
      <div className="chartButtonsContainer" style={sty.chartButtonsContainer}>
        {chartOptions.map((option, index) => {
          return (
            <DateButton
              key={`chartOption-${index}`}
              onClick={() => handleChartOptionClick(index)}
              selected={option.selected}
              width="fit-content"
            >
              {option.proOption ?
                <MenuProOption
                  mainContainerClassName="flex items-center gap-2 w-full"
                  labelClassName={!isPaidBrand ? "w-5/12" : ""}
                  label={option.label}
                  selected={option.selected}
                />
                :
                option.label
              }
            </DateButton>
          )
        })}
      </div>
    )
  }

  const handleChartOptionClick = (index: number) => {
    setLoading(true);

    setChartOptions(chartOptions.map((option, i) => {
      return {
        ...option,
        selected: i === index
      }
    }))

    setLoading(false);
    
  }

  const formatDataToSitesList = (data: { count: number, image: string, name: string, slug: string }[]) => {
    const dataFormatted = data.map((content) => ({
      title: content.name,
      image_url: content.image,
      value: content.count,
      subValue: 'Clicks',
    }))
    return dataFormatted.sort((a, b) => b.value! - a.value!)
  }

  const formatDataToStreamingServicesList = (data: { count: number, percent: number, service: string }[]) => {
    const dataFormatted = data.map((content) => {
      const imageIdentifier = (streamingLinkNamesToDbKeysMapping as any)[content.service]
      return {
        title: content.service,
        value: content.count,
        subValue: `${(content.percent * 100).toFixed(1)}%`,
        image_url: contrastLinkImgMapping(null, 'light', imageIdentifier)?.default || '',
      }
    })
    return dataFormatted.sort((a, b) => b.value! - a.value!)
  }

  const formatDataToReferrersList = (data: { count: number, referrer?: string }[]) => {
    const dataFormatted = data.map((content) => ({
      title: content.referrer ? content.referrer : "Direct",
      value: content.count,
    }))

    let userAdClicks = 0;

    for (const data of dataFormatted) {
      if (data.title.includes('fbclid=')) {
        userAdClicks = userAdClicks + data.value;
      }
    }

    if (userAdClicks > 0) {
      dataFormatted.push({
        title: "FB/Instagram Ads",
        value: userAdClicks,
      })
    }

    return dataFormatted.sort((a, b) => b.value! - a.value!)
  }

  const hasAccessToOption = (): boolean => {
    return Boolean(!isPaidBrand && chartOptions.find(option => option.selected)?.proOption)
  }

  const getStartDate = (data: any[]) => {
    let date = data[0]?.date

    if (date) {
      date = date.replace(/\//g, '-')
    }

    if (!date) {
      date = dayjs().format('YYYY-MM-DD')
    }

    return date
  }

  const getEndDate = (data: any[]) => {
    let date = data[data.length - 1]?.date

    if (date) {
      date = date.replace(/\//g, '-')
    }

    if (!date) {
      date = dayjs().format('YYYY-MM-DD')
    }

    return date
  }

  const getDataForLineChart = (data: any[]) => {
    return data.map((item) => ({
      ...item,
      date: item.date.replace(/\//g, '-')
    }))
  }

  if (emptyView) {
    return (
      <MainContainer padding="8px 0px 8px 0px" backgroundColor={Colors.PAPER_CONTAINER} justifyContent="center" borderRadius="8px">
        <Container
          display="flex"
          padding="24px"
          gap="16px"
          width="100%"
          flexDirection="column"
        >
          <Container
            display={mobileView ? "ruby" : "flex"}
            justifyContent="flex-end"
          >
            {getChartButtons()}
          </Container>
          <div style={{ width: '100%' }}>
            <UnlockDataBanner
              title="Unlock deeper data on your fans"
              description="Get more insights with Symphony Lite"
              image={UnlockChartImage}
              hideImage={mobileView}
              source="Website Overall Dashboard - Top Countries & Cities by Visits"
              unlockedWithLite
            />
          </div>
        </Container>
      </MainContainer>
    )
  }

  if (!mainMetrics || loading) {
    return (
      <MainContainer padding="8px 0px 8px 0px" backgroundColor={Colors.PAPER_CONTAINER} justifyContent="center" borderRadius="8px">
        <LoadingLottie height={36} width={36} black />
      </MainContainer>
    )
  }

  const chartDatasets = [
    { key: "pageViews", name: "Page Views", color: "#8884d8", showIfPositive: false },
    { key: "streamingLinkClicks", name: "Streaming Link Clicks", color: "#42c221", showIfPositive: false },
    { key: "signups", name: "Signups", color: "#ff7300", showIfPositive: true },
    { key: "tourLinkClicks", name: "Tour Link Clicks", color: "#ffa500", showIfPositive: true }
  ];

  function renderLocationStats() {
    if (isPaidBrand) {
      return (
        <>
          <OverallChartContainer>
            <LeftSideHeader>
              <Headline2 color={Colors.textColor}>
                Top Countries by Visits
              </Headline2>
              <Body1>The top countries your fans are clicking from.</Body1>
            </LeftSideHeader>
            <TopGeographiesBarChart
              metrics={mainMetrics?.geographicBreakdown || { countries: [], cities: [] }}
              areaType="countries"
            />
          </OverallChartContainer>
          <OverallChartContainer>
            <LeftSideHeader>
              <Headline2 color={Colors.textColor}>
                Top Cities by Visits
              </Headline2>
              <Body1>The top cities your fans are clicking from.</Body1>
            </LeftSideHeader>
            <TopGeographiesBarChart
              metrics={mainMetrics?.geographicBreakdown || { countries: [], cities: [] }}
              areaType="cities"
            />
          </OverallChartContainer>
        </>
      )
    } else {
      return (
        <>
          <OverallChartContainer>
            <LeftSideHeader>
              <Headline2 color={Colors.textColor}>
                Top Countries & Cities by Visits
              </Headline2>
              <Body1>The top countries and cities your fans are visiting from.</Body1>
            </LeftSideHeader>
            <UnlockDataBanner
              title="Unlock deeper data on your fans"
              description="Get more insights with Symphony Lite"
              image={UnlockChartImage}
              hideImage={mobileView}
              source="Website Overall Dashboard - Top Countries & Cities by Visits"
              unlockedWithLite={true}
            />
          </OverallChartContainer>
        </>
      )
    }
  }
  return (
    <>
      <MainContainer className="overallWebsiteDashboardMainContainer" gap="16px" flexDirection="column">
        <OverallChartContainer
          className="overallWebsiteChartContainer"
          borderRadius="0px"
        >
          <ChartHeader flexDirection={mobileView ? 'column' : 'row'}>
            <LeftSideHeader>
              <Headline2 color={Colors.textColor}>
                Overall Activity
              </Headline2>
              <Body1>
                This is the performance across all your website pages.
              </Body1>
            </LeftSideHeader>
            <RightSideHeader
              overflow={mobileView ? 'scroll' : undefined}
              maxWidth={mobileView ? '100%' : undefined}
              display={mobileView ? "ruby" : "flex"}
            >
              {getChartButtons()}
              <PopoverCampaignAnnotations
                className="popoverOverallWebsiteDashboard"
                style={{ marginLeft: 'auto', marginRight: 10 }}
                showAnnotations={showAnnotations}
                setShowAnnotations={setShowAnnotations}
                upgradeSource="Overall Website Dashboard - Annotations"
              />
            </RightSideHeader>
          </ChartHeader>
          <SLineChart
            data={getDataForLineChart(mainMetrics.overallActivity)}
            datasets={chartDatasets}
            xAxisKey="date"
            showAnnotations={showAnnotations}
            startDate={getStartDate(mainMetrics.overallActivity)}
            endDate={getEndDate(mainMetrics.overallActivity)}
          />
        </OverallChartContainer>
      </MainContainer>

      <div
        className="statsCardsContainerWrapper"
        style={sty.statsCardsContainerWrapper}
      >
        <StatsCardsContainer className="statsCardsContainer" flexDirection={mobileView ? 'column' : 'row'}>
          <StatCard
            title="Page Visits"
            statValue={mainMetrics.topLevelMetrics.pageVisits}
          />
          <StatCard
            title="Streaming Link Clicks"
            statValue={mainMetrics.topLevelMetrics.streamingLinkClicks}
          />
          <StatCard
            title="Clickthrough Rate"
            statValue={`${(mainMetrics.topLevelMetrics.ctr * 100).toFixed(1)}%`}
          />
        </StatsCardsContainer>
        <LowerSection className="lowerSection1" flexDirection={mobileView ? 'column' : 'row'}>
          <SitesList
            title="Top Sites"
            items={formatDataToSitesList(mainMetrics.contentBreakdown)}
            numbered
          />
          <SitesList
            title="Top Streaming Services"
            items={formatDataToStreamingServicesList(
              mainMetrics.streamingServicesBreakdown
            )}
            numbered
          />
        </LowerSection>
        <LowerSection className="lowerSection2">
          <SitesList
            title="Top Referrers"
            items={formatDataToReferrersList(mainMetrics.referrers)}
            numbered
          />
        </LowerSection>
        <OverallChartContainer>
          <LeftSideHeader>
            <Headline2 color={Colors.textColor}>
              Top Countries by Visits
            </Headline2>
            <Body1>The top countries your fans are clicking from.</Body1>
          </LeftSideHeader>
          <TopGeographiesBarChart
            metrics={mainMetrics.geographicBreakdown}
            areaType="countries"
          />
        </OverallChartContainer>
        <OverallChartContainer>
          <LeftSideHeader>
            <Headline2 color={Colors.textColor}>
              Top Cities by Visits
            </Headline2>
            <Body1>The top cities your fans are clicking from.</Body1>
          </LeftSideHeader>
          <TopGeographiesBarChart
            metrics={mainMetrics.geographicBreakdown}
            areaType="cities"
          />
        </OverallChartContainer>
      </div>
    </>
  );
}

const sty = {
  chartButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  } as CSSProperties,
  statsCardsContainerWrapper: {
    backgroundColor: Colors.PAPER_BACKGROUND_CONTAINER,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    borderRadius: 8,
  } as CSSProperties
}
