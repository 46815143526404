import Axios from "helpers/Interceptor";

export const getFacebookInformation = async (brandId: number) => {
    try {
        const response = await Axios.get(`/admin/ad-account/${brandId}`);
        return response.data.data.fbInfo;
    } catch (error) {
        return null;
    }
}

export const getCampaingInformation = async (campaignId: number) => {
    try {
        const response = await Axios.get(`/admin/campaign-info/${campaignId}`);
        return response.data.data;
    } catch (error) {
        return null;
    }
}

/**
 * Updates a brand's subscription plan and trial settings via admin API
 * 
 * @param brandSlug - The unique identifier for the brand
 * @param params - Configuration for the subscription update
 * @param params.planType - The plan type to set for the brand
 * @param params.createStripeTrial - Whether to create a Stripe trial subscription
 * @param params.trialDays - Optional number of trial days if creating a trial
 * @returns Promise resolving to the API response data
 */
export const updateBrandSubscription = async (
  brandSlug: string,
  params: {
    planType: string;
    createStripeTrial?: boolean;
    trialDays?: number;
  }
): Promise<any> => {
  const response = await Axios.post(`/admin/brand/${brandSlug}/subscription`, params);
  return response.data;
};
