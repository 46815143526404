const Colors = {
    purple: '#8800FF',
    purpleLight: '#B982FB',
    purpleLighter: '#F5EBFF',
    purpleDark: '#2C2135',

    red: '#FF3D3D',
    redLight: '#FFD6D6',

    green: '#07C806',
    greenLight: '#EBFBEB',

    yellow: '#E4900D',
    yellowLight: '#FBEDD8',

    white: 'white', // for text
    black: 'black', // for cards

    grey: '#B2B2B2', // for disabled states
    greyLight: '#EDECF2', // for dividers
    // greyLighter: '#F5F3F6', // for screen backgrounds
    greyLighter: '#F0EEF1', // for screen backgrounds
    greyDark: '#707070', // for text

    divider: '#EDECF2',
    textColor: 'black',

    DARK_SUCCESS: '#07c80629',
    DARK_WARNING: '#E4900D29',
    GREEN_COLOR: '#00892d',
    GREEN_COLOR_ON_GREEN: '#00892d4f',
    DARK_BLUE_PAPER_CONTAINER: '#1977f314',
    textColorWhite: 'white',
    DARK_DIVIDER_LINE: '#0000001F',
    PAPER_CONTAINER: 'white',
    VIEW_ONLY_BACKGROUND: '#1977f350', // Brighter blue background (increased opacity from 14 to 29)
    PAPER_DARK_CONTAINER: 'black',
    PAPER_LIGHT_CONTAINER: '#FFFFFFCB',
    PAPER_ERROR_CONTAINER: '#FF000028',
    PAPER_WARNING_CONTAINER: '#E4900D28',
    PAPER_BACKGROUND_CONTAINER: '#F5F3F6',
    PAPER_DIVIDER_CONTAINER: '#EDECF2',
    PAPER_DIVIDER_CONTAINER_DARK: '#D9D9D9',
    PAPER_LIGHT_GRAY_CONTAINER: '#E9E9E9',
    PAPER_ACCENT_CONTAINER: '#8800FF14',
    PAPER_ACCENT_CONTAINER_ON_DARK: '#B982FB63',
    PAPER_SUCCESS_CONTAINER: '#EBFBEB',
    VIDEO_BUTTON_CONTAINER: '#00000014',
    LIGHT_VIDEO_HOVER_LAYOUT: '#0000007A',
    DARK_VIDEO_HOVER_LAYOUT: '#000000B8',
    PAPER_DIVIDER_CONTAINER_LIGHT: '#EDECF214',
    GRADIENT: 'linear-gradient(166deg, #80F 0%, #F680C5 100%)',
    BACKGROUND_EMPTY_STATE: '#F0F0EF',
    DARK_GRAY: '#393939',
    BACKGROUND_CHART_BILLING: '#E4900D14',
    GRID_CHART_BILLING: '#7a7a7a80',
    GREEN_COLOR_LIGHT: '#1ED7601F',
    BACKGROUND_TIKTOK_ICON: '#19191929',
    BACKGROUND_YOUTUBE_ICON: '#ff000029',
    GRADIENT_INSTAGRAM: 'radial-gradient(144.41% 122.74% at 1.81% 90.83%, rgba(254, 213, 118, 0.16) 0%, rgba(244, 113, 51, 0.16) 26.34%, rgba(188, 48, 129, 0.16) 60.91%, rgba(76, 99, 210, 0.16) 100%)',
    ORANGE_BACKGROUND: 'rgba(228, 144, 13, 0.16)',

    orangeAnnotation: '#F47133',

    BORDER_COLOR: '#E5E7B',
}

export default Colors

