import { isDev } from 'modules/Const';

const { log, error, debug, info, warn } = console

// Disable logging on production
export const initLogger = () => {
  if (!isDev) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.info = () => {}
    console.warn = () => {}
  }
}

// For really necessary logs, use Logger instead
// But be careful to not expose sensible data
const Logger = {
  log: (message?: any, ...optionalParams: any[]) => log(message, ...optionalParams),
  error: (message?: any, ...optionalParams: any[]) => error(message, ...optionalParams),
  debug: (message?: any, ...optionalParams: any[]) => debug(message, ...optionalParams),
  info: (message?: any, ...optionalParams: any[]) => info(message, ...optionalParams),
  warn: (message?: any, ...optionalParams: any[]) => warn(message, ...optionalParams),
}

export default Logger
