import styled from "styled-components";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    fontFamily: "DIN",
  },
  blackColor: {
    color: "#000000",
  },
  placeholderColor: {
    color: "#707070",
  },
  outlinedTextField: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C8C7CC",
    },
  },
  nativeInput: {
    fontFamily: "DIN",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  goBackParent: {
    position: "absolute",
    cursor: "pointer",
    top: "50%",
    transform:
      "translate3d(0, -50%, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)",
  },
  goBackChild: {
    stroke: "currentColor",
    fontSize: 24,
    color: "white",
    [theme.breakpoints.down(1024)]: {
      color: "rgba(136, 0, 255, 1)",
    },
  },
  rootCheckbox: {
    color: "#07C806",
  },
  checkedCheckbox: {},
  tableContainer: {
    marginTop: theme.spacing(4),
    borderRadius: 6,
    maxHeight: "100%",
  },
  tableHead: {
    color: "#707070",
  },
  emailHead: {
    width: "40%",
  },
  sourceHead: {
    width: "60%",
  },
  avatar: {
    width: 24,
    height: 24,
  },
  withBorderBottom: {
    borderBottom: "1px solid #c8c7cc",
  },
  noBorderBottom: {
    borderBottom: "none",
  },
}));

interface FlexContainerProps {
  alignItems?: string;
  flexDirection?: string;
  margin?: string;
  height?: string;
  padding?: string;
}

interface SectionProps {
  width?: string;
  display?: string;
  flexDirection?: string;
  alignItems?: string;
  padding?: string;
  borderBottom?: string;
  columnGap?: string;
  justifyContent?: string;
}

interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  padding?: string;
  margin?: string;
  color?: string;
  cursor?: string;
  lineHeight?: string;
}

interface LeftColumnProps {
  width?: string;
}

interface RightColumnProps {
  backgroundColor?: string;
  width?: string;
  borderRadius?: string;
}

interface NextButtonContainerProps {
  maxWidth?: string;
  padding?: string;
}

export const HeadContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    padding-top: 16px;
    padding-bottom: 80px;
  }
`;

export const MainNavbar = styled.div`
  position: relative;
  width: 100%;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 0, 255, var(--tw-bg-opacity));
  }
`;

export const ViewTitle = styled.h4`
  text-align: center;
  @media (min-width: 1024px) {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
  }
`;

export const TabsContainer = styled.nav`
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, 1);
`;

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ alignItems }: FlexContainerProps) => alignItems};
  flex-direction: ${({ flexDirection }: FlexContainerProps) => flexDirection || "row"};
  margin: ${({ margin }: FlexContainerProps) => margin};
  height: ${({ height }: FlexContainerProps) => height};
  padding: ${({ padding }: FlexContainerProps) => padding};
`;

export const LeftColumn = styled.div`
  padding-bottom: 224px;
  width: ${({ width }: LeftColumnProps) => width || "50%"};
  border-right: 1px solid rgba(209, 213, 219, 1);
  background-color: "white";
`;

export const RightColumn = styled.div`
  background-color: ${({ backgroundColor }: RightColumnProps) =>
    backgroundColor};
  width: ${({ width }: RightColumnProps) => width || "50%"};
  border-radius: ${({ borderRadius }: RightColumnProps) => borderRadius || "0px"};
`;

export const ButtonContainer = styled.div`
  padding: 24px 24px 110px 24px;
`;

export const FanbaseTableContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;
`;

export const StyledPlaceholder = styled.span`
  font-family: DIN;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 4px;
  text-align: center;
  color: #707070;
`;

export const StyledGrayLabel = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #707070;
`;

export const StyledLabel = styled.span`
  margin: ${({ margin }: StyledLabelProps) => margin};
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  padding: ${({ padding }: StyledLabelProps) => padding};
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
`;

export const FinalizeTabPreview = styled.div`
  padding: 32px 24px;
`;

export const Section = styled.div`
  width: ${({ width }: SectionProps) => width};
  display: ${({ display }: SectionProps) => display || "flex"};
  flex-direction: ${({ flexDirection }: SectionProps) =>
    flexDirection || "row"};
  align-items: ${({ alignItems }: SectionProps) => alignItems || "center"};
  padding: ${({ padding }: SectionProps) => padding || "24px 32px"};
  border-bottom: ${({ borderBottom }: SectionProps) =>
    borderBottom || "1px solid rgba(229, 231, 235, 1)"};
  column-gap: ${({ columnGap }: SectionProps) => columnGap};
  justify-content: ${({ justifyContent }: SectionProps) => justifyContent};
`;

export const UnorderedList = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  padding-left: 16px;
  color: #707070;
  &::before {
    content: "\u2022";
    color: #707070;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const TableImageContainer = styled.div`
  position: relative;
  margin: 24px auto 0px auto;
  width: 80%;
`;

export const NextButtonContainer = styled.div`
  max-width: ${({ maxWidth }: NextButtonContainerProps) => maxWidth || "235px"};
  padding: ${({ padding }: NextButtonContainerProps) =>
    padding || "24px 24px 32px 0px"};
`;

export const Icon = styled.img``;

export const IconContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const MatchLabelContainer = styled.div`
  padding: 14px 16px;
  border-radius: 4px;
  background-color: rgba(136, 0, 255, 0.08);
  min-width: 227px;
`;

export const LoadingContainer = styled.div`
  margin: 92px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

export const TableRowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const TableLabel = styled.p``;

export const CheckboxContainer = styled.div`
  width: 30px;
`;

export const NextButton = withStyles((theme: Theme) => ({
  root: {
    "&:disabled": {
      color: "#ffffff",
      backgroundColor: "#707070",
    },
    width: "100%",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button);

export default useStyles;
