import { SyntheticEvent, useState } from "react";
import Box from "@mui/material/Box";
import { ReactComponent as AddArtistIcon } from "assets/images/add-artist-profile.svg";
import { ExistingProfiles } from "./Components/ExistingProfileArtist";
import NewProfile from "./Components/NewProfileArtist";
import { TabNewArtistPanelProps } from "./interfaces";
import {
  CustomTab,
  CustomTabs,
} from "./styles";
import ModalSymphony from "components/shareable/ModalSymphony";

const AddNewArtistModal = ({
  className,
  open,
  onClose,
  onFinishAddingToOrganization
}: {
  className?: string;
  open: boolean;
  onClose: () => void;
  onFinishAddingToOrganization: () => void;
}) => {
  return (
    <ModalSymphony
      className="addNewArtistModalComponent"
      isOpen={open}
      onClose={onClose}
      title="Add a Profile"
      subtitle="Setup a new profile to add to your Team."
      headerIcon={<AddArtistIcon />}
    >
      <NavTabs
        onFinishAddingToOrganization={onFinishAddingToOrganization}
      />
    </ModalSymphony>
  );
}

const CustomTabPanel = ({
  children,
  value,
  index,
  ...other
}: TabNewArtistPanelProps) => {
  return (
    <div
      className="addNewArtistModalTabPanelComponent"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const NavTabs = ({ onFinishAddingToOrganization }: { onFinishAddingToOrganization: () => void }) => {
  const [countExistingProfiles, setCountExistingProfiles] = useState(0);
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const [value, setValue] = useState(0);

  const handleChangeInputText = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeCount = (count: number) => {
    setCountExistingProfiles(count);
  };

  return (
    <Box className="addNewArtistModalNavTabsComponent" sx={{ width: "100%", paddingTop: "24px" }}>
      <Box sx={{ borderBottom: 1 }}>
        <CustomTabs
          value={value}
          onChange={handleChangeInputText}
          aria-label="basic tabs example"
          textColor="inherit"
        >
          <CustomTab
            label={<span>New Profile</span>}
            {...a11yProps(0)}
          ></CustomTab>
          {/* <CustomTab
            label={
              <CustomBoxTab>
                <span>Existing Profiles</span>
                <Badge
                  className="ml-5 text-lg"
                  badgeContent={countExistingProfiles}
                  color="error"
                />
              </CustomBoxTab>
            }
            {...a11yProps(1)}
          /> */}
        </CustomTabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <NewProfile onFinishAddingToOrganization={onFinishAddingToOrganization} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ExistingProfiles
          handleChangeCount={handleChangeCount}
          onComplete={onFinishAddingToOrganization}
        />
      </CustomTabPanel>
    </Box>
  );
}

export default AddNewArtistModal