import { TitleAlignmentThemeStyleType } from "../TextAlignmentSection/utils";

/**
 * Interface representing a theme style configuration
 * @interface ThemeStyle
 */
export interface ThemeStyle {
    /** Display title of the theme */
    title: string;
    /** Unique identifier/key for the theme */
    name: ThemeStyleKey;
    /** Order in which theme appears in selection */
    order: number;
    /** Whether theme is only available to pro users */
    isPaidOnly: boolean;
    /** Default title alignment configuration for this theme */
    defaultTitleAlignment?: TitleAlignmentThemeStyleType;
}

/**
 * Theme styles available for music-related content
 * @const {Object} musicThemeStyles
 */
export const musicThemeStyles: {
    classic: ThemeStyle,
    art: ThemeStyle,
    full_image: ThemeStyle,
    vinyl?: ThemeStyle,
    full_video: ThemeStyle,
} = {
    classic: {
        title: "Classic",
        name: "classic",
        order: 0,
        isPaidOnly: false,
        defaultTitleAlignment: { title: "Left", name: "left", order: 1 },
    },
    art: {
        title: "Art",
        name: "art",
        order: 1,
        isPaidOnly: false,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_image: {
        title: "Full image",
        name: "full_image",
        order: 2,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    vinyl: {
        title: "Vinyl",
        name: "vinyl",
        order: 3,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_video: {
        title: "Full video",
        name: "full_video",
        order: 4,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
}

/**
 * Constant containing all available theme style keys
 * @const {Object} THEME_STYLES
 */
export const THEME_STYLES = {
    CLASSIC: "classic",
    ART: "art",
    VINYL: "vinyl",
    BASIC: "basic",
    SIMPLE: "simple",
    FULL_IMAGE: "full_image",
    FULL_VIDEO: "full_video",
} as const

/**
 * Type representing valid theme style keys
 * @typedef {string} ThemeStyleKey
 */
export type ThemeStyleKey = typeof THEME_STYLES[keyof typeof THEME_STYLES];

/**
 * Theme styles available for data collector content
 * @const {Object} dataCollectorThemeStyles
 */
export const dataCollectorThemeStyles: {
    basic: ThemeStyle,
    simple: ThemeStyle,
    full_image: ThemeStyle,
    full_video: ThemeStyle,
} = {
    basic: {
        title: "Basic",
        name: "basic",
        order: 1,
        isPaidOnly: false,
        defaultTitleAlignment: { title: "Left", name: "left", order: 1 },
    },
    simple: {
        title: "Simple",
        name: "simple",
        order: 2,
        isPaidOnly: false,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_image: {
        title: "Full image",
        name: "full_image",
        order: 3,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_video: {
        title: "Full video",
        name: "full_video",
        order: 4,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
}

/**
 * Theme styles available for tour content
 * @const {Object} tourThemeStyles
 */
export const tourThemeStyles: {
    basic: ThemeStyle,
    simple: ThemeStyle,
    full_image: ThemeStyle,
    full_video: ThemeStyle,
} = {
    basic: {
        title: "Basic",
        name: "basic",
        order: 1,
        isPaidOnly: false,
        defaultTitleAlignment: { title: "Left", name: "left", order: 1 },
    },
    simple: {
        title: "Simple",
        name: "simple",
        order: 2,
        isPaidOnly: false,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_image: {
        title: "Full image",
        name: "full_image",
        order: 3,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
    full_video: {
        title: "Full video",
        name: "full_video",
        order: 4,
        isPaidOnly: true,
        defaultTitleAlignment: { title: "Center", name: "center", order: 2 },
    },
}

/**
 * Button styles available for tour content
 * @const {Object} tourButtonStyles
 */
export const tourButtonStyles: {
    basic: ThemeStyle,
    simple: ThemeStyle,
    full_image: ThemeStyle,
    full_video: ThemeStyle,
} = {
    basic: {
        title: "Corners",
        name: "basic",
        order: 1,
        isPaidOnly: false,
    },
    simple: {
        title: "Soft corners",
        name: "simple",
        order: 2,
        isPaidOnly: false,
    },
    full_image: {
        title: "Ovals",
        name: "full_image",
        order: 3,
        isPaidOnly: true,
    },
    full_video: {
        title: "Cutted",
        name: "full_video",
        order: 4,
        isPaidOnly: true,
    },
}