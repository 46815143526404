import { useState } from 'react'
import { boostWebsite } from 'services/symphonyApi/websiteService'
import { WEBSITE_TYPES, WebsiteType } from 'types/global'
import { useHistory } from 'react-router-dom'
import { showToast } from "modules/Utils"
import useScreen from 'Hooks/useScreen'
import { ReactComponent as BoostIcon } from "assets/images/boost-white.svg";
import { track } from 'analytics'
import { CAMPAIGN_TYPE_MAPPINGS } from 'modules/Const'
import ButtonSymphony from './ButtonSymphony'

interface BoostButtonProps {
  className?: string,
  brandContent: any,
  websiteType?: WebsiteType,
  collapseOnMobile?: boolean,
  location?: string,
}

const BoostButton = ({
  className = "",
  brandContent,
  collapseOnMobile = false,
  websiteType = WEBSITE_TYPES.RECORD_STREAMS,
  location
}: BoostButtonProps) => {
  const history = useHistory()
  const { mobileView } = useScreen()
  const [loadingBoost, setLoadingBoost] = useState(false)

  const handleBoostWebsite = async () => {
    setLoadingBoost(true)
    try {
      track("Clicked Boost Button", {
        websiteType: websiteType,
        location: location ?? undefined,
      })
      const brandContentSlug = brandContent?.slug?.trim() || ''
      if (!brandContentSlug) throw new Error("Something went wrong while boosting the website")
      const res = await boostWebsite(brandContentSlug)
      const campaignId = res?.data?.id
      const campaignType = res.data?.campaign_metadata.campaign_type;
      if (campaignId && CAMPAIGN_TYPE_MAPPINGS[campaignType]) {
        const link = CAMPAIGN_TYPE_MAPPINGS[campaignType].route;
        history.push(`${link}?draft=${campaignId}`)
      }
    } catch (_) {
      showToast({
        message: "We couldn't boost this website",
        error: true,
        mobile: mobileView,
      })
    } finally {
      setLoadingBoost(false)
    }
  }

  return (
    <ButtonSymphony
      className={`boostButtonComponent ${className}`}
      onClick={handleBoostWebsite}
      isLoading={loadingBoost}
      iconLeft={mobileView && collapseOnMobile ? undefined : <BoostIcon />}
      style={{
        paddingLeft: loadingBoost ? 24 : 14,
        minWidth: 112,
      }}
    >
      Boost
    </ButtonSymphony>
  )
}

export default BoostButton