import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import Axios from "helpers/Interceptor";
import { CampaignDetails } from 'types/global';
import Playbooks, { Playbook, PlaybookType } from './Playbooks';

interface CampaignState {
    budget: string;
    startDate: string | null;
    endDate: string | null;
    playbookType: PlaybookType;
    playbook: Playbook;
    campaign: CampaignDetails | null;
    campaignName: string;
    isLoadingCampaign: boolean;

    setBudget: (budget: string) => void;
    setStartDate: (startDate: string) => void;
    setEndDate: (endDate: string) => void;
    setPlaybookType: (playbookType: PlaybookType) => void;
    setPlaybook: (playbookType: PlaybookType) => void;
    setIsLoadingCampaign: (isLoadingCampaign: boolean) => void;

    setCampaign: (campaign: CampaignDetails) => void;
    setCampaignName: (campaignName: string) => void;
    getCampaign: (campaignId: number) => Promise<CampaignDetails | null>;
    getCampaignDraft: (campaignId: number) => Promise<CampaignDetails | null>;
    createCampaign: (brandId: string) => Promise<CampaignDetails>;
}

export const useCampaign = create(
    immer<CampaignState>((set, get) => ({
        budget: '0',
        startDate: '',
        endDate: '',
        playbookType: PlaybookType.INCREASE_MUSIC_STREAMS,
        playbook: Playbooks[PlaybookType.INCREASE_MUSIC_STREAMS],
        campaign: null,
        campaignName: '',
        isLoadingCampaign: false,

        setBudget: (budget: string) => { set(state => { state.budget = budget }) },
        setStartDate: (startDate: string) => { set(state => { state.startDate = startDate }) },
        setEndDate: (endDate: string) => { set(state => { state.endDate = endDate }) },
        setPlaybookType: (playbookType: PlaybookType) => { set(state => { state.playbookType = playbookType }) },
        setIsLoadingCampaign: (isLoadingCampaign: boolean) => { set(state => { state.isLoadingCampaign = isLoadingCampaign }) },
        setPlaybook: (playbookType: PlaybookType) => { set(state => { state.playbook = Playbooks[playbookType] }) },
        setCampaign: (campaign: CampaignDetails) => { set(state => { state.campaign = campaign }) },
        setCampaignName: (campaignName: string) => { set(state => { state.campaignName = campaignName }) },

        getCampaign: async (campaignId: number) => {
            const currentCampaign = get().campaign;

            if (!campaignId) {
                campaignId = currentCampaign?.id ?? 0;
            }

            if (campaignId === 0) {
                return null;
            }

            try {
                set(state => { state.isLoadingCampaign = true });
                const res = await Axios.get(`/campaign/${campaignId}`);
                const campaignLocal: CampaignDetails = res.data.data;

                set(state => {
                    state.campaign = campaignLocal;
                    state.isLoadingCampaign = false;
                });

                return campaignLocal;
            } catch (error) {
                throw error;
            } finally {
                set(state => { state.isLoadingCampaign = false });
            }
        },

        getCampaignDraft: async (campaignId: number) => {
            const currentCampaign = get().campaign;

            if (!campaignId) {
                campaignId = currentCampaign?.id ?? 0;
            }

            if (campaignId === 0) {
                return null;
            }

            try {
                set(state => { state.isLoadingCampaign = true });
                const res = await Axios.get(`/campaign/${campaignId}/draft`);
                const campaignLocal: CampaignDetails = res.data.data;

                set(state => {
                    state.campaign = campaignLocal;
                    state.isLoadingCampaign = false;
                });

                return campaignLocal;
            } catch (error) {
                throw error;
            } finally {
                set(state => { state.isLoadingCampaign = false });
            }
        },

        createCampaign: async (brandId: string) => {
            const newCampaignData = {
                budget: get().budget,
                startDate: get().startDate,
                endDate: get().endDate,
                playbookType: get().playbookType,
                playbook: get().playbook,
            };

            try {
                set(state => { state.isLoadingCampaign = true });
                const res = await Axios.post(`/campaign/brand/${brandId}/create`, newCampaignData);

                const campaignLocal: CampaignDetails = res.data.data;

                set(state => { state.campaign = campaignLocal });

                return campaignLocal;
            } catch (error) {
                throw error;
            } finally {
                set(state => { state.isLoadingCampaign = false });
            }
        }
    }))
);