import { useMemo, useState } from "react";
import DialogPaper from "components/shareable/DialogPaper";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { OrganizationBrand, ArtistPermissions, MemberRoles, TeamMember } from "types/global";
import Avatar from '@mui/material/Avatar';
import useScreen from "Hooks/useScreen";
import gravatar from "gravatar";
import { Body2, Headline2 } from "components/shareable/Typography";
import { Divider } from "@material-ui/core";
import { ArtistsList } from "../ArtistsList";
import PrimaryButton from "components/shareable/PrimaryButton";
import { updateMemberPermissions } from "../../../../../services/symphonyApi/organizationService"
import { toast } from "react-toastify"
import { TOAST_ERROR_OPTIONS, TOAST_SUCCESS_OPTIONS } from "../../utils"
import { MemberRolesNames } from "../TeamMemberList/utils";
import { Container } from "styles/shared";
import { MemberPermissionsSelect } from "../MemberPermissionsSelect";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import Colors from "modules/Colors";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import ModalSymphony from "components/shareable/ModalSymphony";

interface EditMemberModalProps {
  member: TeamMember;
  currentMember: TeamMember;
  onClose: () => void;
}

export const EditMemberModal = ({ member, currentMember, onClose }: EditMemberModalProps) => {
  const { mobileView } = useScreen();
  const [artists, setArtists] = useState<OrganizationBrand[]>(member.artists)
  const { getTeamMembers } = useCurrentTeam();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [role, setRole] = useState(member.role);


  const disableSaveButton = useMemo(() => Boolean(artists.find(artist => !artist.permissionLevel)), [artists])

  /**
     * Determines if the current member can edit another member's permissions
     * 
     * Edit Rules:
     * 1. Owners (priority 0):
     *    - Can edit all other roles (Admin priority 1, Member priority 2)
     *    - Can edit themselves
     * 
     * 2. Admins (priority 1):
     *    - Can edit Members (priority 2)
     *    - Can edit themselves
     *    - Cannot edit Owners or other Admins
     * 
     * 3. Members (priority 2):
     *    - Cannot edit anyone
     * 
     * @returns {boolean} Whether the current member can edit the target member
     */
  const canEditMember = useMemo(() => {
    const currentMemberPriority = MemberRolesNames[currentMember.role].priority;
    const memberPriority = MemberRolesNames[member.role].priority;

    // Special case: Owners and Admins can edit themselves
    if (member.id === currentMember.id) {
      return currentMember.role === MemberRoles.ADMIN ||
        currentMember.role === MemberRoles.OWNER;
    }

    // For editing other members: can only edit if current member's priority is LOWER (numerically)
    // than the target member's priority (e.g., Owner(0) can edit Admin(1))
    return currentMemberPriority < memberPriority;
  }, [currentMember.role, member.role, member.id, currentMember.id]);

  const getFormattedChanges = () => {
    const artistsToAdd = artists.filter(artist => !member.artists.find(a => a.id === artist.id))
    const artistsToAddFormatted = artistsToAdd.map(artist => ({ brandId: artist.id, permission: artist?.permissionLevel || 'view_only' }))

    const artistsToDelete = member.artists.filter(artist => !artists.find(a => a.id === artist.id))
    const artistsToDeleteFormatted = artistsToDelete.map(artist => artist.id)

    const artistsToEdit = member.artists.filter(artist => artists.find(a => a.id === artist.id && a.permissionLevel !== artist.permissionLevel))
    const artistsToEditFormatted = artistsToEdit.map(artist => ({
      brandId: artist.id,
      permission:
        artist?.permissionLevel === ArtistPermissions.VIEW_ONLY
          ? ArtistPermissions.ADMINISTRATOR
          : ArtistPermissions.VIEW_ONLY
    }))

    const changes = {
      artistsToAdd: artistsToAddFormatted,
      artistsToEdit: artistsToEditFormatted,
      artistsToDelete: artistsToDeleteFormatted,
      roleChanged: role !== member.role ? role : undefined
    }
    return changes;
  }

  const onEditMember = async () => {
    try {
      setIsLoading(true)
      const { artistsToAdd, artistsToEdit, artistsToDelete, roleChanged } = getFormattedChanges()
      await updateMemberPermissions({
        memberId: member.id,
        addedArtists: artistsToAdd,
        editedArtists: artistsToEdit,
        removedArtists: artistsToDelete,
        role: roleChanged
      })
      await getTeamMembers()
      toast.success("Member edited", TOAST_SUCCESS_OPTIONS)
      onClose()
    } catch (error) {
      toast.error("Something went wrong editing the member", TOAST_ERROR_OPTIONS)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalSymphony
      className="editMemberModalTeam"
      isOpen={true}
      onClose={onClose}
      headerIcon={<Avatar src={gravatar.url(member.email, { protocol: 'https', d: 'retro' })} sx={{ width: 32, height: 32 }} />}
      fullScreen={mobileView}
      title={member.name ? `${member.name} ${member.lastName}` : member.email}
      subtitle={(
        <Container display='flex' flexDirection='column' gap='8px'>
          <Body2>Permission Level</Body2>
          <MemberPermissionsSelect
            value={role}
            onChange={setRole}
            disabled={!canEditMember}
          />
        </Container>
      )}
    >
      <ArtistsList
        isCurrentUser={member.isCurrentUser}
        artists={artists}
        onUpdateArtists={setArtists}
        canEdit={canEditMember}
      />
      <ButtonSymphony
        className="buttonSaveChangesEditMemberModal"
        style={{ marginTop: 16 }}
        isLoading={isLoading}
        onClick={canEditMember ? onEditMember : onClose}
        disabled={isLoading || (canEditMember && disableSaveButton)}
        tooltip={canEditMember && disableSaveButton && "Please select all artist's permissions"}
      >
        {canEditMember ? 'Save changes' : 'Close'}
      </ButtonSymphony>
    </ModalSymphony>
  );
}