import { SetStateAction, useRef, Dispatch, useContext } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import CircularStep from "../CircularStep";
import { CurrencyCodesType, PlaylistsRecord as Record } from "types/global";
import { useHistory } from "react-router-dom";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import {
  ButtonCheckoutContainer,
} from "./styles";
import { createPayPalOrder } from "../../api";
import PrimaryButton from "components/shareable/PrimaryButton";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import PayPalButtonGroup from "pages/post-auth/MarketingPage/Components/GoLiveCard/PaypalCheckoutOption";
import { track } from "analytics";
import { redirectToOneOffCampaignCheckout } from "services/symphonyApi/subscriptionService";


type Tier = {
  tier: number;
  cost: number;
};

type PayPalOrder = {
  orderId: string | null;
  campaignId: string | null;
};

interface Props {
  campaignId: string | null;
  pitchNotes: string;
  creatingCampaign?: boolean;
  record: Record | null;
  tier: Tier;
  onCancelPayPalOrder?: () => void;
  onErrorPayPalOrder?: () => void;
  setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
  cancelSaving: () => void;
  currencyCode?: CurrencyCodesType
}

const GoLiveCard = ({
  campaignId,
  record,
  pitchNotes,
  creatingCampaign,
  tier,
  onCancelPayPalOrder,
  onErrorPayPalOrder,
  setCreatingCampaign,
  cancelSaving,
  currencyCode = 'USD'
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const { currentBrand } = useContext(CurrentBrandContext)

  const payPalOrder = useRef<PayPalOrder>({ orderId: null, campaignId: null });

  if (!currentBrand) {
    return null
  }

  const handleClickStripeButton = async () => {
    cancelSaving();
    setCreatingCampaign(true);
    
    // Track analytics event
    track("Completed Campaign Step - Checkout", {
      type: "get_playlisted",
      payment_method: "stripe",
      step: 3,
      name: "confirmation",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: record?.name,
      content_slug: record?.slug,
      content_id: record?.id,
      tier: tier,
      pitchNotes: pitchNotes
    });

    // Prepare checkout data
    const checkoutData = {
      campaign_input: {
        id: campaignId,
        campaign_type: "get_playlisted",
        campaign_version: 'v2',
        pitch_notes: pitchNotes,
        price_data: {
          tier: tier.tier,
          price: tier.cost,
          name: `${tier.tier.toLocaleString("en")} Followers Across Playlists ("${record?.name}")`,
          description: `"${record?.name}" (Get Playlisted)`,
        },
        content: {
          spotifyId: record?.spotify_id,
          name: record?.name,
          id: record?.id,
          slug: record?.slug,
          thumbnail_url: record?.thumbnail_url,
          url: record?.url,
        },
        campaign_platforms: ["playlisting"],
      },
    };

    // Call one-off checkout directly
    const result = await redirectToOneOffCampaignCheckout(currentBrand, checkoutData);
    
    if (result?.error) {
      setCreatingCampaign(false);
      // You might want to handle the error here (e.g., show an error message)
    }
  };

  const onPayPalOrderApprove = async (
    _data: OnApproveData,
    _actions: OnApproveActions
  ) => {
    const { campaignId, orderId } = payPalOrder.current;
    const url = `/marketing/success?campaign_id=${campaignId}&session_id=${orderId}`;

    history.push(url);
  };

  const createOrder = async () => {
    cancelSaving();
    setCreatingCampaign(true);

    const order = await createPayPalOrder({
      campaignId,
      currentBrand,
      record,
      tier,
      pitchNotes
    });
    const { orderId } = order;

    payPalOrder.current = order;
    setCreatingCampaign(false);
    return orderId;
  };

  return (
    <Card>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step="⚡" />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Let’s go live
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
          <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
            Your campaign will be checked for eligibility before going active and may take up to 7 days to go live. If we are not able to pitch your song effectively, you will be refunded the full amount of your payment with an explanation.
          </StyledLabel>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
          <Grid container direction="column">
            <ButtonCheckoutContainer>
              <PrimaryButton
                width="100%"
                onClick={handleClickStripeButton}
                disabled={creatingCampaign}
                loading={creatingCampaign}
                text="Checkout with credit or debit card"
              />

            </ButtonCheckoutContainer>
            <div style={{ marginTop: '12px' }}>
              <PayPalButtonGroup
                createOrder={createOrder}
                onCancel={onCancelPayPalOrder}
                onError={onErrorPayPalOrder}
                onApprove={onPayPalOrderApprove}
                className="w-full"
                allowWithoutPro={true}
                currencyCode={currencyCode}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GoLiveCard;
