import DialogPaper from "components/shareable/DialogPaper";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import { ReactComponent as GlobeIcon } from "assets/images/campaignDetails/globe.svg"
import { AgeRestrictedCountry } from "../../constants";
import Chip from "components/shareable/Chip";
import { ChipCountriesContainer, DetailsContainer, ButtonsContainer } from "./styles";
import { Body2 } from 'components/shareable/Typography';
import Colors from "modules/Colors";
import SecondaryButton from 'components/shareable/SecondaryButton';
import { AgeRange } from 'types/global';

interface Props {
  open: boolean
  countries: AgeRestrictedCountry[]
  currentAgeRange: AgeRange
  closeModal: () => void
  removeCountries: () => void
  updateAgeRange: (ageRange: AgeRange) => void
}

const getSuggestedAgeRange = (countries: AgeRestrictedCountry[], currentAgeRange: AgeRange): AgeRange => {
  let lowestAge = countries[0].age;
  countries.forEach(country => {
    if (country.age > lowestAge) {
      lowestAge = country.age
    }
  })

  if (lowestAge > currentAgeRange.max) {
    currentAgeRange = { min: lowestAge, max: lowestAge + 1 }
  } else {
    currentAgeRange = { min: lowestAge, max: currentAgeRange.max }
  }

  return currentAgeRange;
}

const TargetingWarning = ({
  open,
  closeModal,
  countries,
  currentAgeRange,
  removeCountries,
  updateAgeRange
}: Props) => {
  const ageSuggestions = getSuggestedAgeRange(countries, currentAgeRange);

  const handleOnUpdateAgeRange = () => updateAgeRange(ageSuggestions)

  return (
    <DialogPaper open={open} onClose={closeModal}>
      <>
        <HeaderModal
          HeaderIcon={GlobeIcon}
          closeModal={closeModal}
          title='Update your age targeting'
          subtitle={`Based on Facebook requirements, you’ll need to update your minimum age targeting to ${ageSuggestions.min} to run ads in the following countries:`}
        />
        <ChipCountriesContainer>
          {
            countries.map((country, index) =>
              <Chip key={index}>{country.name}</Chip>
            )
          }
        </ChipCountriesContainer>
        <DetailsContainer>
          <div>
            <Body2>
              Selected age range:&nbsp;
            </Body2>
            <Body2 color={Colors.textColor}>
              {`${currentAgeRange.min} to ${currentAgeRange.max}`}
            </Body2>
          </div>
          <div>
            <Body2>
              New age range if you target these countries:&nbsp;
            </Body2>
            <Body2 color={Colors.textColor}>
              {`${ageSuggestions.min} to ${ageSuggestions.max}`}
            </Body2>
          </div>
        </DetailsContainer>
        <ButtonsContainer>
          <SecondaryButton
            onClick={removeCountries}
            text="Don’t target these countries"
            width="100%"
          />
          <SecondaryButton
            onClick={handleOnUpdateAgeRange}
            text={`Update minimum age to ${ageSuggestions.min}`}
            width="100%"
          />
        </ButtonsContainer>
      </>
    </DialogPaper>
  )
}
export default TargetingWarning