import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { BrandContent, WebsiteType, WEBSITE_TYPES } from "types/global";
import CardTitle from "components/shareable/CardTitle/CardTitle";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import * as Sentry from "@sentry/react";
import { pageView } from "analytics";
import Axios from "helpers/Interceptor";
import { Container, StyledNavBar, TabsPanelsContainer } from "styles/shared";
import { BannerContainer, TabsContainer } from "../../MarketingPage/styles";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import WebsiteListItem from "./WebsiteListItem";
import { formattedWebsiteUrl, getSearchParam, getStatusToShowPaymentFailedBanner } from "modules/Utils";
import CreateNewWebsiteModal, { CreateWebsiteOptions, Steps } from "./CreateNewWebsiteModal";
import { WEBSITE_CREATION_TYPE } from "../types";
import { Body2, Caption, Headline2 } from "components/shareable/Typography";
import { OverallWebsiteDashboard } from "./OverallWebsiteDashboard";
import useScreen from "Hooks/useScreen";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { getRecordDetails } from "services/symphonyApi/brandContentService";
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import DataCollectIcon from 'assets/images/websiteThemes/data-collector-site.svg';
import TourSiteIcon from 'assets/images/websiteThemes/tour.svg';
import DefaultAdditionalActions from "components/shareable/CardTitle/DefaultAdditionalActions";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments";
import Colors from "modules/Colors";
import PageSymphony from "components/shareable/PageSymphony";

enum WebsitePageTabs {
  SITES = 'sites',
  DASHBOARD = 'dashboard',
}

const WebsitesList = () => {
  const websiteTypeParam = getSearchParam({ name: 'websiteType' });

  const [loadingContent, setLoadingContent] = useState<boolean>(false)
  const [brandContents, setBrandContents] = useState<BrandContent[]>([])
  const [step, setStep] = useState<Steps>(Steps.SELECT_OPTION)
  const [initialSiteType, setInitialSiteType] = useState<WebsiteType | null>(null);

  const { currentBrand, isBrandAdmin } = useContext(CurrentBrandContext);
  const { mobileView } = useScreen();

  const tabs = [WebsitePageTabs.SITES, WebsitePageTabs.DASHBOARD];

  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const { remainingFreeTrial, isOrganizationInactive } = useCurrentTeam()


  const [selectedTab, setSelectedTab] = useTabs(tabs, WebsitePageTabs.SITES);

  const [actionedItem, setActionedItem] = useState<BrandContent | undefined>(undefined)

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

  const openCreateSiteModal = (websiteKey?: string) => {
    setInitialSiteType(websiteKey as WebsiteType || null);
    setOpenCreateModal(true);
  }

  const closeCreateSiteModal = () => {
    setOpenCreateModal(false);
    setInitialSiteType(null);
  }

  const fetchBrandContent = async () => {
    try {
      const brandId = currentBrand?.id;
      Sentry.setContext('fetchBrandContent', { brandId, screen: 'Website' });
      setLoadingContent(true);
      const params = {
        brandContentsTypes: ['record_streams', 'data_collector', 'tour'],
        attachCampaigns: true,
      }
      const brandContentResponse = await Axios.get('/website/active', { params });

      if (brandContentResponse.data.data) {
        const releases = brandContentResponse.data.data;
        const [latestRelease] = releases;
        const isSingle = (
          latestRelease?.content_metadata.type === "project" &&
          latestRelease?.content_metadata.tracks.length <= 1 &&
          latestRelease?.content_metadata.spotify_release_type === "single"
        );
        if (isSingle) {
          const [single] = latestRelease.content_metadata.tracks;
          try {
            const singleDetails = await getRecordDetails(single.spotify_id, 'single', brandId);
            if (singleDetails) {
              releases[0].single = singleDetails;
            }
          } catch (error) {
            console.error('Error in fetching brand content single', error);
          }
        }
        setBrandContents(releases);
      } else {
        console.error("Error loading releases");
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error in fetching brand content', error);
    } finally {
      setLoadingContent(false);
    }
  }

  useEffect(() => {
    if (websiteTypeParam) {
      openCreateSiteModal(websiteTypeParam);
    }
  }, []);

  useEffect(() => {
    pageView("Website List")
    fetchBrandContent()
  }, [currentBrand])

  useEffect(() => {
    if (selectedTab === WebsitePageTabs.DASHBOARD) {
      pageView("Website Dashboard")
    }
  }, [selectedTab])

  function renderWebsiteItems() {
    if (brandContents.length === 0 && !isBrandAdmin) {
      return (
        <div className="flex w-full justify-center">
          <Caption color={Colors.greyDark}>No websites created yet.</Caption>
        </div>
      )
    }
    if (brandContents.length === 0) {
      return (
        <>
          <div className="flex flex-col py-4">
            <Headline2 color={Colors.textColor}>Create your first site</Headline2>
            <Body2>Choose a website template based on your goal.</Body2>
          </div>
          <CreateWebsiteOptions step={step} setStep={setStep} />
        </>
      )
    }
    const items = brandContents.map((item: BrandContent, index: number) => {

      const thumbnail = item.content_metadata.thumbnail_url || currentBrand?.image || ''
      const primaryTitle = item.name
      let websiteType: WebsiteType = WEBSITE_TYPES.RECORD_STREAMS
      let additionalLabel = null

      // if this is a presave, but it hasnt been saved yet - mark it as a presave site
      if (item.content_metadata.presave && !(item.content_metadata.presave.saved_at)) {
        websiteType = WEBSITE_TYPES.PRESAVE
      } else if (item.type === WEBSITE_TYPES.DATA_COLLECTOR) {
        websiteType = WEBSITE_TYPES.DATA_COLLECTOR
      } else if (item.type === WEBSITE_TYPES.TOUR) {
        websiteType = WEBSITE_TYPES.TOUR
      } else {
        switch (item.content_metadata.type) {
          case 'single':
          case 'record':
            additionalLabel = 'Single'
            break;
          case 'album':
          case 'project':
            additionalLabel = 'Album/Project'
            break;
          case 'playlist':
            additionalLabel = 'Playlist'
            break;
        }
      }

      let mainStyle = {
        bgType: 'artwork',
        bgColor: Colors.textColor as any,
        primaryColor: Colors.purple as any,
        secondaryColor: Colors.textColor as any,
        buttonConfig: "boxes"
      }

      if (item.primary_customization) {
        mainStyle = {
          ...mainStyle,
          ...item.primary_customization
        }
      } else if (item.content_metadata.website?.style) {
        mainStyle = {
          ...mainStyle,
          ...item.content_metadata.website.style
        }
      } else if (item.content_metadata.presave?.style) {
        mainStyle = {
          ...mainStyle,
          ...item.content_metadata.presave.style
        }
      }

      let creationType: WEBSITE_CREATION_TYPE = WEBSITE_CREATION_TYPE.AUTOMATIC
      if (item.content_metadata.publishReason === 'marketing_campaign') {
        creationType = WEBSITE_CREATION_TYPE.CAMPAIGN
      }
      else if (item.content_metadata.presave) {
        creationType = WEBSITE_CREATION_TYPE.PRESAVE
      }

      const websiteUrl = formattedWebsiteUrl(currentBrand, item)

      let linkToEdit = `/website/${item.type}/${item.slug}`
      if(item.content_metadata.presave && !item.content_metadata.presave.saved_at) {
        const linkedPresaveCampaigns = item.linked_campaigns?.filter(campaign => campaign.campaign_metadata.campaign_type === 'pre_save')
        if(linkedPresaveCampaigns && linkedPresaveCampaigns?.length > 0) {
          linkToEdit = `/marketing/campaigns/${linkedPresaveCampaigns[0].id}`
        }
      }

      return (
        <WebsiteListItem
          key={item.id}
          content={item}
          setActionedItem={setActionedItem}
          titleText={primaryTitle}
          thumbnailSrc={thumbnail}
          subtitle={null}
          websiteUrl={websiteUrl}
          linkToEdit={linkToEdit}
          onClick={() => { }}
          creationType={creationType}
          websiteType={websiteType}
          websiteSubtypeLabel={additionalLabel}
          createdAt={item.createdAt}
          updatedAt={item.updatedAt}
          websiteStyle={{
            bgType: mainStyle?.bgType,
            primaryColor: mainStyle?.primaryColor,
            buttonConfig: mainStyle?.buttonConfig,
            secondaryColor: mainStyle?.secondaryColor,
            bgColor: mainStyle?.bgColor,
          }}
        />
      )
    })

    return (
      <Container>
        {items}
      </Container>)
  }

  const tabsToShow = [
    {
      value: WebsitePageTabs.SITES,
      label: "Websites"
    },
    {
      value: WebsitePageTabs.DASHBOARD,
      label: "Dashboard"
    }
  ]

  if (loadingContent) {
    return (
      <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
        <NewLoader black />
        <p className="mt-1 text-center">Loading Websites...</p>
      </div>
    )
  }

  // NOTE: render()
  return (
    <>
      <PageSymphony
        className="websiteListComponent"
        isContent
      >
        {(showPaymentFailedBanner || remainingFreeTrial > 0 || isOrganizationInactive) &&
          <BannerContainer>
            {showPaymentFailedBanner &&
              <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />
            }
            <OrganizationSubscriptionStatusBanner onlyShowFailedPayments />
          </BannerContainer>
        }

        <CardTitle
          className="cardTitleWebsiteList"
          title="Website"
          useDefaultPadding={false}
          titleTextPadding="16px 24px"
          subtitle="Own your audience and grow with our customizable website builder."
          additionalButtons={
            <>
              {isBrandAdmin &&
                <DefaultAdditionalActions
                  className="defaultAdditionalActionsWebsiteList"
                  additionalItemsText="Popular Websites:"
                  buttonText="Create New Website"
                  onButtonClick={() => openCreateSiteModal()}
                  campaignItems={[
                    {
                      key: "music",
                      title: "Promote Music",
                      icon: MusicSiteIcon,
                      link: "#",
                    },
                    {
                      key: "data_collector",
                      title: "Collect Fan Data",
                      icon: DataCollectIcon,
                      link: "#",
                    },
                    {
                      key: "tour",
                      title: "Promote Events",
                      icon: TourSiteIcon,
                      link: "#",
                    },
                  ]}
                  onCampaignItemClick={(websiteKey) =>
                    openCreateSiteModal(websiteKey)
                  }
                />
              }
            </>
          }
        />
        <TabsContainer
          borderRadius={mobileView ? "0px" : "8px"}
          padding="16px 16px 16px 16px"
        >
          <StyledNavBar>
            <SymphonyTabs
              selectedValue={selectedTab!}
              onChange={(value) => setSelectedTab(value as WebsitePageTabs)}
              data={tabsToShow}
            />
          </StyledNavBar>
          <TabsPanelsContainer>
            <TabPanel hidden={selectedTab !== WebsitePageTabs.SITES}>
              <Container
                backgroundColor="#FFFFFF"
                padding={mobileView ? "0px 0px 16px 0px" : "0px 24px 24px"}
                borderRadius="8px"
              >
                {renderWebsiteItems()}
              </Container>
            </TabPanel>
            <TabPanel hidden={selectedTab !== WebsitePageTabs.DASHBOARD}>
              <Container>
                <OverallWebsiteDashboard />
              </Container>
            </TabPanel>
          </TabsPanelsContainer>
        </TabsContainer>
      </PageSymphony>
      <CreateNewWebsiteModal
        isOpen={openCreateModal}
        onClose={closeCreateSiteModal}
        initialSiteType={initialSiteType}
      />
    </>
  );
};

export default WebsitesList;