import { CSSProperties, Fragment, useEffect } from "react";
import { PlaybookInfo, PlaybookType } from "Hooks/Playbooks";
import { PlaybookStep } from "Hooks/Playbooks";
import { useTabs, TabPanel } from "react-headless-tabs";
import TargetingCampaignView from "./CampaignViews/TargetingCampaignView";
import ContentCampaignView from "./CampaignViews/ContentCampaignView";
import ConnectionsCampaignView from "./CampaignViews/ConnectionsCampaignView";
import BudgetCampaignView from "./CampaignViews/BudgetCampaignView";
import FinalizationCampaignView from "./CampaignViews/FinalizationCampaignView";
import RecordSelectorCampaignView from "./CampaignViews/RecordSelectorCampaignView";
import LinkSelectorCampaignView from "./CampaignViews/LinkSelectorCampaignView";
import LinkEntryCampaignView from "./CampaignViews/LinkEntryCampaignView";
import PlaylistSelectorCampaignView from "./CampaignViews/PlaylistSelectorCampaignView";
import VideoSelectorCampaignView from "./CampaignViews/VideoSelectorCampaignView";
import PresaveSelectorCampaignView from "./CampaignViews/PresaveSelectorCampaignView";
import { useCampaign } from "Hooks/useCampaign";
import Colors from "modules/Colors";
import ButtonSymphony from "./shareable/ButtonSymphony";
import { clsx } from "modules/Utils";

const CAMPAIGN_VIEW_MAX_WIDTH = 900;
const SIDE_CONTENT_WIDTH = 240;

interface CampaignViewProps {
    playbookType: PlaybookType;
}

const CampaignView = ({
    playbookType: playbookTypeProp = PlaybookType.INCREASE_MUSIC_STREAMS,
}: CampaignViewProps) => {
    const { setPlaybookType, playbook, setPlaybook, campaign, getCampaign, budget } = useCampaign();

    const [value, onChange] = useTabs(playbook.steps);

    useEffect(() => {
        setPlaybookType(playbookTypeProp);
        setPlaybook(playbookTypeProp);
        getCampaign(campaign?.id ?? 0);
    }, []);

    const nextStep = () => {
        const nextStep = playbook.steps[playbook.steps.indexOf(value as PlaybookStep) + 1];
        if (nextStep) {
            onChange(nextStep);
        }
    }

    const prevStep = () => {
        const prevStep = playbook.steps[playbook.steps.indexOf(value as PlaybookStep) - 1];
        if (prevStep) {
            onChange(prevStep);
        }
    }

    const renderRightSideContent = () => {
        return (
            <div className="rightSideContent" style={sty.rightSideContent}>
                <div>Right side content</div>
                <div>Budget: {budget}</div>
            </div>
        )
    }

    const renderLeftSideContent = () => {
        return (
            <div className="leftSideContent" style={sty.leftSideContent}>
                <div>Left side content</div>
                <div>Budget: {budget}</div>
            </div>
        )
    }

    const renderPlaybookStep = (step: PlaybookStep) => {
        switch (step) {
            case PlaybookStep.TARGETING:
                return <TargetingCampaignView style={sty.campaignView} />
            case PlaybookStep.CONTENT:
                return <ContentCampaignView style={sty.campaignView} />
            case PlaybookStep.CONNECTIONS:
                return <ConnectionsCampaignView style={sty.campaignView} />
            case PlaybookStep.BUDGET:
                return <BudgetCampaignView style={sty.campaignView} />
            case PlaybookStep.FINALIZATION:
                return <FinalizationCampaignView style={sty.campaignView} />
            case PlaybookStep.RECORD_SELECTOR:
                return <RecordSelectorCampaignView style={sty.campaignView} />
            case PlaybookStep.LINK_SELECTOR:
                return <LinkSelectorCampaignView style={sty.campaignView} />
            case PlaybookStep.LINK_ENTRY:
                return <LinkEntryCampaignView style={sty.campaignView} />
            case PlaybookStep.PLAYLIST_SELECTOR:
                return <PlaylistSelectorCampaignView style={sty.campaignView} />
            case PlaybookStep.VIDEO_SELECTOR:
                return <VideoSelectorCampaignView style={sty.campaignView} />
            case PlaybookStep.PRESAVE_SELECTOR:
                return <PresaveSelectorCampaignView style={sty.campaignView} />
            default:
                return <div>Unknown step</div>
        }
    }

    const getCampaignTabViews = () => {
        return playbook.steps.map((step: PlaybookStep) => {
            return (
                <TabPanel
                    key={`${step}-${value}`}
                    className={clsx(
                        "tabPanelCampaignView",
                        value === step ? "tabPanelActive" : "tabPanelInactive",
                        step
                    )}
                    style={sty.tabPanelCampaignView}
                    hidden={value !== step}
                >
                    {renderPlaybookStep(step)}
                </TabPanel>
            )
        })
    }

    const renderTabNumber = (index: number, step: PlaybookStep) => {
        const isActive = value === step;
        const indexOfStep = playbook.steps.indexOf(step);
        const currentIndex = playbook.steps.indexOf(value as PlaybookStep);
        const number = index + 1;
        const pastCurrentStep = indexOfStep < currentIndex;

        let backgroundColor = Colors.grey;
        if (isActive) {
            backgroundColor = Colors.purple;
        } else if (pastCurrentStep) {
            backgroundColor = Colors.green;
        }

        return (
            <div
                className="campaignNumber"
                style={{
                    ...sty.campaignNumber,
                    backgroundColor
                }}
            >
                {pastCurrentStep ? '✓' : number}
            </div>
        )
    }

    const renderTabTitle = (step: PlaybookStep) => {
        const isActive = value === step;
        return (
            <div
                className="campaignTitle"
                style={{
                    ...sty.campaignTitle,
                    ...(isActive ? { color: Colors.purple } : { color: Colors.greyDark })
                }}
            >
                {PlaybookInfo[step].title}
            </div>
        )
    }

    const renderCampaignTabs = () => {
        return (
            <div className="campaignTabs" style={sty.campaignTabs}>
                <div className="campaignTabsInner" style={sty.campaignTabsInner}>
                    {playbook.steps.map((step: PlaybookStep, index: number) => {
                        const isActive = value === step;

                        return (
                            <Fragment key={`${index}-${step}`}>
                                <div
                                    className={clsx(
                                        "campaignTab",
                                        isActive ? "campaignTabActive" : "campaignTabInactive",
                                        step
                                    )}
                                    style={{
                                        ...sty.campaignTab,
                                        // ...(isActive ? sty.campaignTabActive : sty.campaignTabInactive)
                                    }}
                                    onClick={() => onChange(step)}
                                >
                                    {renderTabNumber(index, step)}
                                    {renderTabTitle(step)}
                                </div>
                                {index < playbook.steps.length - 1 &&
                                    <div className="lineSeparator" style={sty.lineSeparator} />
                                }
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        );
    };

    const renderNavigationButtons = () => {
        const currentStepIndex = playbook.steps.indexOf(value as PlaybookStep);
        const isFirstStep = currentStepIndex === 0;
        const isLastStep = currentStepIndex === playbook.steps.length - 1;

        return (
            <div className="campaignButtonsBottom" style={sty.campaignButtonsBottom}>
                <div className="campaignNavigationButtonsInner" style={sty.campaignNavigationButtonsInner}>
                    <ButtonSymphony onClick={prevStep} variant="outlined">
                        {isFirstStep ? 'Back' : 'Prev'}
                    </ButtonSymphony>
                    <ButtonSymphony onClick={nextStep} color={isLastStep ? 'green' : 'purple'}>
                        {isLastStep ? 'Finish' : 'Next'}
                    </ButtonSymphony>
                </div>
            </div>
        );
    };

    return (
        <div className="campaignViewComponent" style={sty.campaignViewComponent}>
            {/* <div className="campaignStatus">
                {campaign?.status}
            </div>
            <div className="campaignTitle">
                playbook.title: {playbook.title}
            </div> */}
            {renderCampaignTabs()}
            <div className="campaignScrollView" style={sty.campaignScrollView}>
                <div className="campaignContentView" style={sty.campaignContentView}>
                    {renderRightSideContent()}
                    {getCampaignTabViews()}
                    {renderLeftSideContent()}
                </div>
            </div>
            {renderNavigationButtons()}
        </div>
    )
};

const sty: Record<string, CSSProperties> = {
    campaignViewComponent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        backgroundColor: Colors.greyLighter,
        borderRadius: 16,
        overflow: 'hidden',
    },
    campaignTabs: {
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: 'white',
        width: '100%',
        height: 72,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',

    },
    campaignTabsInner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        maxWidth: CAMPAIGN_VIEW_MAX_WIDTH,
    },
    campaignTab: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 12,
        gap: 8,
    },
    campaignTabActive: {
        borderBottom: `2px solid ${Colors.purple}`,
    },
    campaignTabInactive: {
        borderBottom: '2px solid white',
    },
    campaignContentView: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        maxWidth: 1200,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        gap: 16,
        padding: 16,
    },
    campaignScrollView: {
        overflowY: 'scroll',
        height: 'calc(100vh - 144px)',
    },
    tabPanelCampaignView: {
        padding: 16,
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 8,
        height: 1000,
    },
    campaignButtonsBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 72,
        gap: 16,
        backgroundColor: 'white',
        boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
    },
    campaignNavigationButtonsInner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 16,
        maxWidth: CAMPAIGN_VIEW_MAX_WIDTH,
        width: '100%',
    },
    campaignView: {
        minHeight: 200,
    },
    campaignNumber: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        borderRadius: 100,
        backgroundColor: Colors.purple,
        color: 'white',
        fontSize: 12,
        flexShrink: 0,
    },
    lineSeparator: {
        width: '100%',
        height: 1,
        backgroundColor: Colors.grey,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    rightSideContent: {
        position: 'sticky',
        top: 16,
        width: SIDE_CONTENT_WIDTH,
        height: 400,
        backgroundColor: 'white',
        flexShrink: 0,
        borderRadius: 8,
    },
    leftSideContent: {
        position: 'sticky',
        top: 16,
        width: SIDE_CONTENT_WIDTH,
        height: 400,
        backgroundColor: 'white',
        flexShrink: 0,
        borderRadius: 8,
    },
}

export default CampaignView;