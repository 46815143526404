export const performanceTextForLocations = (locations: [string, string], actionString?: string): string => {
    const fakeGPT: string[] = [
        "Top clickers on your ads were .COUNTRY. and .COUNTRY..",
        "Your ads had the most clicks in .COUNTRY. and .COUNTRY..",
        "Most ad clicks came from .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. showed the highest click rates.",
        "The best ad click results were in .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. led the way in ad clicks.",
        "Ad clicks were highest from .COUNTRY. and .COUNTRY..",
        "Most clicks originated from .COUNTRY. and .COUNTRY..",
        "Your ads performed best in .COUNTRY. and .COUNTRY..",
        "Top ad engagement came from .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. had the most ad engagement.",
        "Ads clicked most frequently were in .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. showed strong ad click rates.",
        "The top locations for ad clicks were .COUNTRY. and .COUNTRY..",
        "Ad clicks were most prominent in .COUNTRY. and .COUNTRY..",
        "Most ad clicks were registered in .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. topped the list in ad clicks.",
        "Highest ad engagement was in .COUNTRY. and .COUNTRY..",
        "The strongest ad clicks came from .COUNTRY. and .COUNTRY..",
        "Your ads got maximum clicks in .COUNTRY. and .COUNTRY..",
        "Most effective ad clicks: .COUNTRY. and .COUNTRY..",
        "Top ad click sources: .COUNTRY. and .COUNTRY..",
        "Best-performing locations: .COUNTRY. and .COUNTRY..",
        "Ads clicked most in .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. excelled in ad click performance.",
        "Ad clicks peaked in .COUNTRY. and .COUNTRY..",
        "Your ads were popular in .COUNTRY. and .COUNTRY..",
        "Ad clicks favored .COUNTRY. and .COUNTRY..",
        "The most ad clicks came from .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. had the top ad click counts.",
        "Ad click hotspots: .COUNTRY. and .COUNTRY..",
        "Your ads saw peak engagement in .COUNTRY. and .COUNTRY..",
        "Ads were most successful in .COUNTRY. and .COUNTRY..",
        "Ad clicks were concentrated in .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. were the key ad click sources.",
        "Your ads resonated most in .COUNTRY. and .COUNTRY..",
        "Ad clicks thrived in .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. outperformed in ad click metrics.",
        "The ad click leaders were .COUNTRY. and .COUNTRY..",
        ".COUNTRY. and .COUNTRY. surpassed others in ad clicks.",
        "Ad click champions: .COUNTRY. and .COUNTRY..",
        "Maximum ad engagement was in .COUNTRY. and .COUNTRY..",
        "Top ad click performers: .COUNTRY. and .COUNTRY.."
    ]


    // Get a random index from the array
    const randomIndex = Math.floor(Math.random() * fakeGPT.length);

    // Get a random sentence from the array
    let randomSentence = fakeGPT[randomIndex];

    if (actionString) {
        // replace the action string with the passed action string
        randomSentence = randomSentence.replace('clickers', `${actionString}ers`)
        randomSentence = randomSentence.replace('clicks', `${actionString}s`)
        randomSentence = randomSentence.replace('click', `${actionString}`)

    }

    // Replace both occurrences of .COUNTRY. with the passed locations
    var locations1 = fixCountryNameForUnited(locations[0])

    var locations2 = fixCountryNameForUnited(locations[1])

    const output = randomSentence.replace('.COUNTRY.', locations1).replace('.COUNTRY.', locations2);

    return output;
}




export const performanceTextForDemos = (demographics: {
    age: string;
    gender: string;
}[], actionString?: string): string => {
    // Pseudocode: fakeGPT is the array containing the 50 sentences provided above
    const fakeGPT: string[] = [
        "Top performing demographics: .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        "Ad clicks were highest among .Gender1., Ages .Age1..",
        "Most ad engagement: .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        ".Gender1., Ages .Age1. and .Gender2., Ages .Age2. had the most ad clicks.",
        "Ads resonated with .Gender1., Ages .Age1. and .Age2. the most.",
        "Top age groups: .Age1. for .Gender1. and .Age2. for .Gender2..",
        ".Gender1., Ages .Age1. and .Gender2., Ages .Age2. showed strong ad engagement.",
        "Ad clicks peaked among .Gender1., Ages .Age1. and .Age2. age groups.",
        "Top ad click demographics: .Gender1., Ages .Age1. and .Age2..",
        "Ads were most popular among .Gender1., Ages .Age1..",
        "Your ads performed best with .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        "Most ad clicks were registered by .Gender1., Ages .Age1..",
        "Highest ad engagement: .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        ".Gender1., Ages .Age1. and .Age2. had the highest click rates.",
        "Ad clicks favored .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        "Top ad engagement: .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        ".Gender1., Ages .Age1. and .Gender2., Ages .Age2. showed the best results.",
        "Ads clicked most frequently by .Gender1., Ages .Age1. and .Age2..",
        "Best-performing demographic: .Gender1., Ages .Age1..",
        "Ad clicks thrived among .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        ".Gender1., Ages .Age1. and .Gender2., Ages .Age2. led the way.",
        "Your ads resonated most with .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        "Most effective ad clicks: .Gender1., Ages .Age1. and .Age2..",
        "Ad clicks were most prominent among .Gender1., Ages .Age1. and .Age2..",
        "Top ad click sources: .Gender1., Ages .Age1. and .Gender2., Ages .Age2..",
        "Ads were most successful among .Gender1., Ages .Age1..",
        "Ad clicks were concentrated among .Gender1., Ages .Age1. and .Gender2., Ages .Age2.."
    ]

    // Get a random index from the array
    const randomIndex = Math.floor(Math.random() * fakeGPT.length);

    // Get a random sentence from the array
    let randomSentence = fakeGPT[randomIndex];

    if (actionString) {
        // replace the action string with the passed action string
        randomSentence = randomSentence.replace('clickers', `${actionString}ers`)
        randomSentence = randomSentence.replace('clicks', `${actionString}s`)
        randomSentence = randomSentence.replace('click', `${actionString}`)
    }

    // Replace gender words based on the input
    const genderMapper: Record<string, string> = {
        male: 'Men',
        female: 'Women',
        unknown: 'Unspecified',
    };

    // Helper function to convert the input gender to its mapped value
    const getMappedGender = (gender: string) => {
        return genderMapper[gender.toLowerCase()] || 'unspecified';
    };

    // Replace the delimiters with the provided demographics
    const output = randomSentence
        .replace('.Gender1.', getMappedGender(demographics[0].gender))
        .replace('.Age1.', demographics[0].age)
        .replace('.Gender2.', getMappedGender(demographics[1]?.gender))
        .replace('.Age2.', demographics[1]?.age);

    return output;
}

export const fixCountryNameForUnited = (location: string) => {
    if (location.indexOf('United') > -1) {
        return `the ${location}`
    }

    return location

}