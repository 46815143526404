import { BackgroundBarContainer, Bar } from "./styles";
import Colors from "modules/Colors";

interface Props {
  progress: number;
  maximumProgress: number;
  squared?: boolean;
  fullWidth?: boolean;
  barColor?: string;
  backgroundColor?: string;
}

const ProgressBar = ({
  progress,
  maximumProgress,
  squared,
  fullWidth,
  barColor,
  backgroundColor,
}: Props) => {
  const width = (progress / maximumProgress) * 100;

  return (
    <BackgroundBarContainer
      width={fullWidth ? "100%" : "72px"}
      borderRadius={squared ? 0 : 24}
      background={backgroundColor || Colors.PAPER_DIVIDER_CONTAINER}
    >
      <Bar width={width} background={barColor || Colors.purple} />
    </BackgroundBarContainer>
  );
};

export default ProgressBar;