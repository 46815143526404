import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react"
import Axios from "helpers/Interceptor"
import { convertArrayDraftDataToSendAsObject, STEPS } from "../utils"
import { CurrentBrand, SavingDraftStatus, AdAccountStatusCodes } from "types/global"
import debounce from "lodash/debounce"
import { saveDraft } from "../api"
import { track } from "analytics"
import { CAMPAIGN_TYPES, DEBOUNCE_SAVE_DRAFT_TIME } from "modules/Const"
import { areValidDates } from '../../utils/daysCalculator'
import { UploadedAssetType } from '../../utils/fbCampaigns'
import { AD_ACCOUNT_STATUS } from 'helpers/FB'
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { getFormattedGeographyTargeting } from "../../Components/TargetingView/utils"
import { checkIsValidUrl } from "modules/Utils"
import { CreativeAssetTypes } from "../../constants"
import { ConnectionsContext } from "../../hooks/ConnectionsContext"
import { useLocation } from "react-router-dom"

const { FIRST_TIME_DRAFT_DATA_LOAD, SAVING_NEXT_DRAFT } = SavingDraftStatus
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

type SaveDraftFunction = (
  value: unknown
) => Promise<
  | { data: Record<string, unknown>; error: null }
  | { data: null; error: Record<string, unknown> }
>

export const useDraftDataGetter = (args: {
  setters: Record<string, Dispatch<SetStateAction<unknown> | VoidFunction>>
}) => {
  const location = useLocation();
  const draft = new URLSearchParams(location.search).get("draft")
  const { setters } = args
  const { currentBrand } = useContext(CurrentBrandContext)

  const {
    setFacebookPage: setSelectedFB_page,
    setInstagramPage: setSelectedInstaPage,
    setFacebookAdAccount:setFBAdAccount,
    setFacebookPixel: setFBPixel,
    setFacebookBusinessManager: setFBBusinessManager,
    setLoggedInFbUser,
    setValidateConnections,
    setConfirmConnections,
    setConversionsEnabled,
    setConversionsEventName,
  } = useContext(ConnectionsContext)

  const { conversions_tasks_completed } = currentBrand?.conversionsStatus || {};
  const {
    setCurrentLink,
    setCaption,
    setCTA,
    setRetrievingDraftData,
    setBudgetValue,
    setSelectedTab,
    setCampaignDraftData,
    setTargets,
    setAudiences,
    setCustomAudiences,
    setAdditionalPagesAudiences,
    setSelectedInstagramPost,
    setUpload,
    setEndDate,
    setStartDate,
    saveTargets,
    saveAudiences,
    saveArtists,
    saveDemographics,
    setAddVisualSelected,
    setIsValidURL,
  } = setters

  useEffect(() => {
    const getDraftCampaignData = async (draft?: string | null) => {
      if (draft) {
        try {
          const response = await Axios.get(`campaign/${draft}`)
          const data = response.data.data
          const saved_draft_step = data?.campaign_metadata?.saved_draft_step  || STEPS.LINK
          const add_visual_selected = data?.campaign_metadata?.add_visual_selected;
          const {
            link,
            caption,
            cta,
            targetedCountries,
            interestTargetingAudiences,
            igMedia,
            assets,
            startDate,
            endDate,
            price_data,
            targetingDemographics,
            customAudiences = [],
            additionalPageAudiences = [],
            conversionsEnabled,
            conversionsEventName,
          } = data?.campaign_metadata?.campaign_input || {}
          const { price } = price_data || {}
          const {
            facebook_business_manager,
            facebook_ad_account,
            facebook_pixel,
            facebook_page,
            instagram_page,
            logged_in_fb_user,
          } = data?.campaign_metadata?.campaign_state.brand?.connections || {}

          const adAccountToSet = conversions_tasks_completed ? currentBrand?.connections.facebook_ad_account : facebook_ad_account

          const formattedAudiences =
            interestTargetingAudiences?.map(
              ({ fbId, name }: { fbId: string; name: string }) => ({
                id: fbId,
                name,
                audience_size: null,
                country: null,
                country_access: null,
                description: null,
                partner: null,
                path: null,
                source: null,
                topic: null,
                type: null,
                artistId: null,
              })
            ) || []

          track("Continued Creating Campaign from Draft", {
            type: "link_clicks",
            step: saved_draft_step,
          })

          const {
            validDates,
            formattedEndDate,
            formattedStartDate
          } = areValidDates({ startDate, endDate })
          if (facebook_ad_account && !AD_ACCOUNT_STATUS[facebook_ad_account.account_status as AdAccountStatusCodes]?.valid) {
            setSelectedTab(STEPS.CREATIVE)
          } else if (saved_draft_step === STEPS.FINALIZATION && !validDates) {
            setSelectedTab(STEPS.BUDGET)
          } else {
            setSelectedTab(saved_draft_step)
          }
          setEndDate(formattedEndDate)
          setStartDate(formattedStartDate)
          setCurrentLink(link || "")
          setCaption(caption || "")
          setCTA(cta || "")

          if (facebook_page) setSelectedFB_page(facebook_page)
          if (adAccountToSet) setFBAdAccount(adAccountToSet)
          if (instagram_page) setSelectedInstaPage(instagram_page)
          if (facebook_pixel) setFBPixel(facebook_pixel)
          if (facebook_business_manager) setFBBusinessManager(facebook_business_manager)
          if (logged_in_fb_user) setLoggedInFbUser(logged_in_fb_user)
          setValidateConnections(true)
          setConfirmConnections(true)

          const formattedGeographyTargeting = getFormattedGeographyTargeting(CAMPAIGN_TYPES.link_clicks,targetedCountries)
          setTargets(formattedGeographyTargeting)
          setAudiences(formattedAudiences)
          setCustomAudiences(customAudiences)
          setAdditionalPagesAudiences(additionalPageAudiences)
          setSelectedInstagramPost(igMedia)
          const allAssets = assets?.all  as UploadedAssetType[] || []
          if (add_visual_selected) {
            setAddVisualSelected(add_visual_selected)
          } else {
            setAddVisualSelected(allAssets.length ? UPLOAD_VIDEO : INSTAGRAM_POSTS)
          }
          setUpload(assets?.all || null)
          setBudgetValue(price || 0)
          saveTargets(formattedGeographyTargeting)
          saveAudiences(formattedAudiences)
          saveArtists([])
          saveDemographics(targetingDemographics || {})
          setCampaignDraftData(data || null)
          setRetrievingDraftData(false)
          setIsValidURL(checkIsValidUrl(link || ""))
          setConversionsEnabled(conversionsEnabled)
          setConversionsEventName(conversionsEventName)
        } catch (error) {
          console.log("error retrieving draft campaign data: ", error)
        }
      }
    }

    getDraftCampaignData(draft)
  }, [draft])
}

export const useDraftDataSetter = (args: {
  setters: Record<string, Dispatch<unknown>>
  campaignDraftData?: Record<string, unknown> | null
  requiredDataChanged: boolean
  retrievingDraftData: boolean
  currentLink: string
  dataToSaveDraft: Record<string, unknown>[]
  audiencesData: Record<string, unknown>
  currentBrand: CurrentBrand
  draft: string | null
  savingDraftStatus: number
}) => {
  const {
    setters,
    campaignDraftData,
    requiredDataChanged,
    currentLink,
    retrievingDraftData,
    dataToSaveDraft,
    audiencesData,
    currentBrand,
    draft,
    savingDraftStatus
  } = args
  const {
    setRequiredDataChanged,
    setIsSavingDraft,
    setCampaignDraftData,
    setSavingDraftStatus,
  } = setters

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        async (args: {
          dataToSend: Record<string, unknown>
          campaignDraftData?: Record<string, unknown> | null
          audiencesData: Record<string, unknown>
          currentBrand: CurrentBrand
        }) => {
          if (draft && !campaignDraftData) return setIsSavingDraft(false)

          const save = saveDraft as SaveDraftFunction
          const { data, error } = await save(args)

          if (!error && data) {
            const draftData = data as { campaign_metadata: Record<string, unknown> }
            const step = draftData.campaign_metadata.saved_draft_step

            track("Saved Draft", { type: "link_clicks", step })
            setCampaignDraftData(data)
          }
          setIsSavingDraft(false)
        },
        DEBOUNCE_SAVE_DRAFT_TIME
      ),
    [campaignDraftData]
  )

  useEffect(() => {
    setRequiredDataChanged(true)
  }, dataToSaveDraft)

  useEffect(() => {
    if (requiredDataChanged && currentLink && !retrievingDraftData) {
      setSavingDraftStatus((prev: number) =>
        prev > FIRST_TIME_DRAFT_DATA_LOAD ? SAVING_NEXT_DRAFT : prev + 1
      )
    }
  }, [
    requiredDataChanged,
    currentLink,
    retrievingDraftData,
    ...dataToSaveDraft,
  ])

  useEffect(() => {
    if (
      requiredDataChanged &&
      currentLink &&
      !retrievingDraftData &&
      savingDraftStatus === SAVING_NEXT_DRAFT
    ) {
      const dataToSend = convertArrayDraftDataToSendAsObject(
        dataToSaveDraft as unknown as Record<string, unknown>[]
      )

      setIsSavingDraft(true)
      debouncedChangeHandler({
        audiencesData,
        dataToSend,
        campaignDraftData,
        currentBrand,
      })
      setRequiredDataChanged(false)
    }
  }, [
    requiredDataChanged,
    currentLink,
    retrievingDraftData,
    campaignDraftData,
    ...dataToSaveDraft,
    audiencesData,
    savingDraftStatus
  ])

  const cancelSavingDraft = () => {
    setIsSavingDraft(false)
    debouncedChangeHandler.cancel()
  }

  useEffect(() => {
    return cancelSavingDraft
  }, [debouncedChangeHandler])

  return { cancelSavingDraft }
}
