import { ReactComponent as SpotifyIcon } from 'assets/images/streaming/square-icons/spotify-sqr.svg';
import { ReactComponent as EmailPurpleIcon } from 'assets/images/email_purple.svg';
import { ReactComponent as StarIcon } from 'assets/images/outlinedStar.svg';
import { IconContainer } from '../../Details/styles';
import { SpotifyIconContainer } from './styles';

interface Boost {
  key: string;
  title: string;
  description: string;
  actualDataDescription: string;
  isChecked: boolean;
  isDisabled: boolean;
  icon: React.ReactNode;
  requiresPro?: boolean;
  onToggle: (newValue: boolean) => void;
}

export interface PresaveBoosts {
  auto_presaves: boolean;
  boost_follows: boolean;
  collect_emails: boolean;
}

export interface PresaveTotals {
  auto_presaves: number;
  boost_follows: number;
  collect_emails: number;
}

const getActualDataForeverSavesDescription = (totalForeverSaves: number, isPaidBrand: boolean) => {
  switch (totalForeverSaves) {
    case 0:
      return 'Grow day 1 saves for this release and all future releases with Forever Saves.'
    case 1:
    default:
      if (isPaidBrand) {
        let startingString = `${totalForeverSaves.toLocaleString()} ${totalForeverSaves === 1 ? `fan has` : 'fans have'} subscribed to Forever Saves!`
        let dataString = totalForeverSaves > 0 ? ` You'll get ${totalForeverSaves.toLocaleString()} saves on day 1 for this release and all future releases.` : ''
        return `${startingString} ${dataString}`
      } else {
        return `Unlock ${totalForeverSaves.toLocaleString()} save on day 1 for this release and all future releases.`
      }
  }
}

export const getFormattedBoostsData = (
  boosts: PresaveBoosts,
  totalValues: PresaveTotals,
  onToggleChange: (key: string, newValue: boolean) => void,
  isPaidBrand: boolean,
  handleOpenUpgradeToProModal: (props: {
    source: string
  }) => void,
): Boost[] => {
  return [
    {
      key: 'auto_presaves',
      title: 'Forever Saves',
      description: 'Grow saves with every release by asking fans if they want to automatically save your future releases.',
      actualDataDescription: getActualDataForeverSavesDescription(totalValues.auto_presaves, isPaidBrand),
      icon: <IconContainer><StarIcon height={28} width={28} /></IconContainer>,
      isChecked: Boolean(boosts.auto_presaves && isPaidBrand),
      isDisabled: false,
      requiresPro: true,
      onToggle: () => {
        if (isPaidBrand) {
          onToggleChange('auto_presaves', !boosts.auto_presaves)
        } else {
          handleOpenUpgradeToProModal({
            source: 'Presave Campaign Details - Enable Boosts',
          });
        }
      },
    },
    {
      key: 'boost_follows',
      title: 'Boost Followers',
      description: 'When enabled, fans who pre-save will automatically follow your streaming service profile.',
      actualDataDescription: `You've gained ${totalValues.boost_follows.toLocaleString()} new followers across streaming services using this boost.`,
      icon: <SpotifyIconContainer>
        <SpotifyIcon height={56} width={56} style={{ marginTop: '-4px', marginLeft: '-4px' }} />
      </SpotifyIconContainer>,
      isChecked: Boolean(boosts!.boost_follows),
      isDisabled: false,
      requiresPro: true,
      onToggle: () => {
        if (isPaidBrand) {
          onToggleChange('boost_follows', !boosts.boost_follows)
        } else {
          handleOpenUpgradeToProModal({
            source: 'Presave Campaign Details - Enable Boosts',
          });
        }
      },
    },
    {
      key: 'collect_emails',
      title: 'Collect Fan Data',
      description: 'When enabled, fans who pre-save will be asked to subscribe and consent to sharing their fan data, including name, email, and location.',
      actualDataDescription: `You've collected ${totalValues.collect_emails.toLocaleString()} emails using this boost.`,
      icon: <EmailPurpleIcon height={52} width={52} />,
      isChecked: Boolean(boosts!.collect_emails),
      isDisabled: false,
      onToggle: () => onToggleChange('collect_emails', !boosts.collect_emails),
    },
  ];
};

export const DEFAULT_BOOST_VALUES = {
  auto_presaves: false,
  boost_follows: false,
  collect_emails: false,
};
