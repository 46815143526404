import { createTheme } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import Colors from 'modules/Colors'
export const useStyles = makeStyles(() => ({
  iconSecondaryColor: {
    '& path': {
      stroke: Colors.greyDark,
    },
  },
  iconDividerColor: {
    '& path': {
      stroke: Colors.greyLight,
    },
  },
  iconBackgroundSecondaryColor: {
    '& circle': {
      fill: Colors.greyDark,
    },
  },
}))

export const radioBtnColorTheme = createTheme({
  palette: {
    primary: {
      main: Colors.purple,
    },
  },
})

export const OptionItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid ${Colors.greyLight};
`

interface StyledChipProps {
  isLinkedVariant?: boolean
}

export const StyledChip = styled.div<StyledChipProps>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  background: ${(props) => props.isLinkedVariant ? Colors.DARK_SUCCESS : Colors.divider};
  color: ${Colors.greyDark};
  font-size: 16px;
  gap: 8px;
  width: fit-content;
`

export const ExternalLink = styled.a`
  color: ${Colors.purple};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const AdAccountInvalidCtn = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${Colors.PAPER_ERROR_CONTAINER};
  padding: 8px;
  gap: 10px;
  font-size: 16px;
  color: ${Colors.greyDark};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

interface AdAccountCtnProps {
  isValid: boolean
}

export const AdAccountCtn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  color: ${({ isValid }: AdAccountCtnProps) => isValid && Colors.greyDark};
`
