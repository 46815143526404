/**
 * App Constants
 */
import keyBy from "lodash/keyBy";
import { globalDefaultLocations, touringDefaultLocations } from "pages/post-auth/MarketingPage/MusicStreams/TargetingView/data";
import ads from 'assets/images/recommendations/ads.png'
import website from 'assets/images/recommendations/website.png'
import presave from 'assets/images/recommendations/presave.png'

const linkClicksCampaignImage = require('assets/images/ui/link-clicks-campaign-img.png').default
const boostIgCampaignImage = require('assets/images/ui/ig-boost-campaign-img.png').default

export const TABS = {
  RECORD: "record",
  LINK: "link",
  CREATIVE: "creative",
  TARGETING: "targeting",
  BUDGET: "budget",
  FINALIZATION: "finalization",
};

export const DEFAULT_CURRENCY = "USD";

// ONE OFF CAMPAIGN PRICE
export const ONE_OFF_CAMPAIGN_PRICE = 10;

// PRO PLAN
// Price per additional user per month for Pro plan accounts
export const PRO_PLAN_ADDITIONAL_SEAT_PRICE = 15;

// Base monthly price for Pro plan subscription
export const PRO_MONTHLY_PLAN_PRICE = 20.00;

// Base yearly price for Pro plan subscription
export const PRO_ANNUAL_PLAN_PRICE = 200.00;

// Monthly price when paying annually (10 months for price of 12)
export const PRO_ANNUAL_BY_MONTH_PLAN_PRICE = PRO_ANNUAL_PLAN_PRICE / 12;

// Monthly ad spend limit before 2% automation fee applies
export const ADDITIONAL_SPEND_BUDGET = 1000;

// Maximum number of additional team members that can be added to a Pro plan
export const PRO_PLAN_INCLUDED_SEATS = 2;

// Previous Pro plan monthly price, used for grandfathered accounts
export const OLD_PLAN_PRICE = 15;

// Monthly price for Lite plan subscription
export const LITE_MONTHLY_PLAN_PRICE = 6;

// Yearly price for Lite plan subscription (10 months for price of 12)
export const LITE_ANNUAL_PLAN_PRICE = 60;

// Monthly cost for an annual Lite plan
export const LITE_ANNUAL_BY_MONTH_PLAN_PRICE = LITE_ANNUAL_PLAN_PRICE / 12;

// Fee charged per campaign for Lite plan
export const LITE_PER_CAMPAIGN_FEE = 10;

// TEAM PLAN
// Base monthly price for team plan subscription
export const TEAM_MONTHLY_PLAN_PRICE = 65.00
// Base yearly price for team plan subscription (10 months for price of 12)
export const TEAM_ANNUAL_PLAN_PRICE = 650.00
export const TEAM_ANNUAL_BY_MONTH_PLAN_PRICE = TEAM_ANNUAL_PLAN_PRICE / 12;

// 2% fee charged on ad spend above the monthly limit
export const TEAM_PLAN_MONTHLY_AD_SPEND_OVERAGE_LIMIT_FEE = 0.02
// Monthly ad spend limit before overage fees apply
export const TEAM_PLAN_MONTHLY_AD_SPEND_LIMIT = 5000;
// Price per additional team member seat beyond included seats
export const TEAM_PLAN_ADDITIONAL_SEAT_PRICE = 10.00
// Price per additional Pro account beyond included accounts
export const TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE = 15.00
// Number of team member seats included in base plan
export const TEAM_PLAN_INCLUDED_SEATS = 3;
// Number of Pro accounts included in base plan
export const TEAM_PLAN_INCLUDED_PRO_ACCOUNTS = 3;

/**
 * Enum representing the different subscription plan types available in the application
 * @enum {string}
 */
export enum PlanType {
  PRO = 'pro',
  TEAM = 'team',
  LITE = 'lite',
  FREE = 'free'
}

/**
 * Interface defining subscription plan details
 * @interface Subscription
 */
interface Subscription {
  title: string
  subtitle: string
  priceMonthly: number
  priceAnnual: number
  priceAnnualByMonth: number
  buttonText: string
  buttonFreeTrialText: string
  perks: Perk[]
}


/**
 * Interface defining a subscription plan perk
 * @interface Perk
 */
export interface Perk {
  /** Main perk description */
  info: string;
  /** Additional detail about the perk */
  detail: string | null;
}


/** Configuration object for all subscription plans */
export const SUBSCRIPTION_BENEFITS: { [key in PlanType]: Subscription } = {
  [PlanType.FREE]: {
    title: 'Free',
    subtitle: 'Get started with a link-in-bio and free marketing tools.',
    priceMonthly: 0,
    priceAnnual: 0,
    priceAnnualByMonth: 0,
    buttonText: 'Get Started',
    buttonFreeTrialText: 'Get Started',
    perks: [
      {
        info: 'Create Websites & Pre-saves',
        detail: null,
      },
      {
        info: 'Automated Marketing',
        detail: '$10 / campaign',
      },
      {
        info: 'Fanbase Analytics',
        detail: 'Last 7 days',
      },
    ],
  },
  [PlanType.LITE]: {
    title: 'Lite',
    subtitle: 'Built for creators who want full link-in-bio customization and analytics.',
    priceMonthly: LITE_MONTHLY_PLAN_PRICE,
    priceAnnual: LITE_ANNUAL_PLAN_PRICE,
    priceAnnualByMonth: LITE_ANNUAL_BY_MONTH_PLAN_PRICE,
    buttonText: 'Get Started',
    buttonFreeTrialText: 'Start 7-Day Free Trial',
    perks: [
      {
        info: 'Website Customization',
        detail: 'Unlimited',
      },
      {
        info: 'Forever Saves + Pre-save Boosts',
        detail: null,
      },
      {
        info: 'Website Analytics',
        detail: 'Unlimited',
      },
      {
        info: 'Automated Marketing',
        detail: '$10 / campaign',
      },
      {
        info: 'Fanbase Analytics',
        detail: 'Last 30 days',
      },
    ],
  },
  [PlanType.PRO]: {
    title: 'Pro',
    subtitle: 'For creators who are ready to run advanced marketing campaigns.',
    priceMonthly: PRO_MONTHLY_PLAN_PRICE,
    priceAnnual: PRO_ANNUAL_PLAN_PRICE,
    priceAnnualByMonth: PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
    buttonText: 'Get Started',
    buttonFreeTrialText: 'Start 14-Day Free Trial',
    perks: [
      {
        info: 'Everything in Lite',
        detail: null,
      },
      {
        info: 'Automated Marketing',
        detail: 'Unlimited',
      },
      {
        info: 'Fanbase Analytics',
        detail: 'Lifetime',
      },
      {
        info: 'Full Access to AI Tools',
        detail: null,
      },
      {
        info: 'Downloadable Reports',
        detail: null,
      },
      {
        info: 'Invite your Team',
        detail: "+$15 / user",
      },
    ],
  },
  [PlanType.TEAM]: {
    title: 'Teams',
    subtitle: 'Built for teams to scale their marketing efforts across multiple profiles',
    priceMonthly: TEAM_MONTHLY_PLAN_PRICE,
    priceAnnual: TEAM_ANNUAL_PLAN_PRICE,
    priceAnnualByMonth: TEAM_ANNUAL_BY_MONTH_PLAN_PRICE,
    buttonText: 'Get Started',
    buttonFreeTrialText: 'Start 14-Day Free Trial',
    perks: [
      {
        info: 'Unlock the Teams Dashboard',
        detail: null,
      },
      {
        info: '3 Pro Profiles Included',
        detail: `$${TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE} / month for additional`,
      },
      {
        info: 'Unlock Pro Profiles at a discount',
        detail: '25% off',
      },
      {
        info: '3 Team Members Included',
        detail: `$${TEAM_PLAN_ADDITIONAL_SEAT_PRICE} / month for additional`,
      },
      {
        info: 'Advanced Permissions Management',
        detail: 'Admin & View-Only access',
      },
    ],
  },
}

export const PRO_ADS_COMISSION = 0;

export const FREE_ADS_COMISSION = 0.1;

export const stage: "development" | "production" | "review" = process.env.NODE_ENV as "development" | "production" | "review";

export const isDev = stage !== "production";

export const SYMPHONY_WEBSITE_URL = isDev
  ? "http://localhost:3001"
  : stage === "review"
    ? "https://symphonyos.app"
    : "https://symphony.to";


export const CAMPAIGN_WIZARD_ROUTES = [
  "/marketing/new/music-streams",
  "/marketing/new/grow-playlist-followers",
  "/marketing/new/grow-instagram-engagement",
  "/marketing/new/playlists",
  "/marketing/new/video-views",
  "/marketing/new/tiktok-ads",
  "/marketing/new/presave",
  "/marketing/new/creatives",
  "/marketing/new/link-clicks",
  "/marketing/success",
];

export enum CAMPAIGN_TYPE_ENUM {
  record_streams = 'record_streams',
  grow_playlist_followers = 'grow_playlist_followers',
  get_playlisted = 'get_playlisted',
  increase_video_views = 'increase_video_views',
  pre_save = 'pre_save',
  email_fanbase = 'email_fanbase',
  link_clicks = 'link_clicks',
  creative_generator = 'creative_generator',
  boost_instagram = 'boost_instagram'
}

export const CAMPAIGN_TYPES = {
  record_streams: CAMPAIGN_TYPE_ENUM.record_streams,
  grow_playlist_followers: CAMPAIGN_TYPE_ENUM.grow_playlist_followers,
  get_playlisted: CAMPAIGN_TYPE_ENUM.get_playlisted,
  increase_video_views: CAMPAIGN_TYPE_ENUM.increase_video_views,
  pre_save: CAMPAIGN_TYPE_ENUM.pre_save,
  email_fanbase: CAMPAIGN_TYPE_ENUM.email_fanbase,
  link_clicks: CAMPAIGN_TYPE_ENUM.link_clicks,
  creative_generator: CAMPAIGN_TYPE_ENUM.creative_generator,
  boost_instagram: CAMPAIGN_TYPE_ENUM.boost_instagram
};

export const FB_CAMPAIGN_TYPES = [
  CAMPAIGN_TYPES.record_streams,
  CAMPAIGN_TYPES.link_clicks,
  CAMPAIGN_TYPES.boost_instagram,
  CAMPAIGN_TYPES.grow_playlist_followers
];

export interface CampaignTypeMapping {
  [key: string]: {
      title: string;
      route?: string;
      defaultImage?: string;
      badgeIcons?: string[];
  };
}

export const CAMPAIGN_TYPE_MAPPINGS: CampaignTypeMapping = {
  record_streams: {
    title: "Increase Music Streams",
    route: "/marketing/new/music-streams",
    badgeIcons: ["spotify"],
  },
  grow_playlist_followers: {
    title: "Grow Playlist Followers",
    route: "/marketing/new/grow-playlist-followers",
    badgeIcons: ["spotify"],
  },
  link_clicks: {
    title: "Increase Link Clicks",
    route: "/marketing/new/link-clicks",
    defaultImage: linkClicksCampaignImage,
    badgeIcons: ['link_clicks'],
  },
  get_playlisted: {
    title: "Pitch Playlists",
    route: "/marketing/new/playlists",
    badgeIcons: ["spotify"],
  },
  increase_video_views: {
    title: "Increase Video Views",
    route: "/marketing/new/video-views",
    badgeIcons: ["youtube"],
  },
  boost_instagram: {
    title: "Boost Instagram Engagement",
    route: "/marketing/new/grow-instagram-engagement",
    defaultImage: boostIgCampaignImage,
    badgeIcons: ["instagram"],
  },
  pre_save: {
    title: "Pre-save Campaign",
    route: "/marketing/new/presave",
    badgeIcons: ["spotify", "apple_music", "deezer"],
  },
  music_site: {
    title: "Music Site",
    route: "/marketing/new/music_site",
  },
  data_collector: {
    title: "Data Collector",
    route: "/marketing/new/data_collector",
  },
}

export const GEOGRAPHY_TARGETING_DEFAULTS = [
  {
    id: "touring-markets",
    description:
      "This audience automatically targets people in countries where you're likely to tour, including the US, Canada, and primary markets in Europe.",
    name: "Touring Markets",
    budget: 0.7,
    locations: keyBy(touringDefaultLocations, "value"),
  },
  {
    id: "global-markets",
    description:
      "This audience automatically targets people in countries where it's cheaper to drive a stream, to help build your global fanbase and drive your algorithmic streams.",
    name: "Global Markets",
    budget: 0.3,
    locations: keyBy(globalDefaultLocations, "value"),
  },
];


export const PLATFORMS = {
  spotify: 'spotify',
  apple_music: 'apple_music',
  youtube: 'youtube',
  deezer: 'deezer',
  sound_cloud: 'soundcloud'
}

export const DEBOUNCE_SAVE_DRAFT_TIME = 3000

export const CURRENCY_SYMBOLS = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  // BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  // ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  // XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$'
}

export enum Severity {
  WARNING = 'warning',
  ERROR = 'error',
}

export type SnackBarType = "SUCCESS" | "ERROR" | "INFO";
export type SeverityType = "success" | "info" | "warning" | "error";

export enum CheckoutType {
  UPGRADE_PLAN = 'upgradePlan',
  ONE_OFF_CAMPAIGN = 'oneOffCampaign',
}

export const YELLOW_BACKGROUND_COLOR = "rgba(255, 185, 92, 0.18)";
export const PURPLE_BACKGROUND_COLOR = "rgba(136, 0, 255, 0.24)";
export const YELLOW_LABEL_COLOR = "#D18827";
export const PURPLE_LABEL_COLOR = "#8800ff";

export const YouTubeCampaignTypes = [
  {
    id: "default",
    name: "⚡️ Recommended",
    text: "Run your video ads with Symphony's recommended approach.",
    enabled: false
  },
  {
    id: "views",
    name: "📈 Cheapest Views",
    text: "Run your video ads to drive as many views as possible on your video.",
    enabled: true,
    adFormat: "YouTube Pre-roll Ads (Skippable)"
  },
  {
    id: "engagement",
    name: "🗣 More Engagement",
    text: "Run your video ads focused on driving more engagement to your video, including likes, comments, and subscribers.",
    enabled: true,
    adFormat: "YouTube In-feed Discovery Ads"
  },
]

export const YouTubeCampaignGeographyTypes = [
  {
    id: "english",
    name: "🌎 English-speaking Markets",
    text: "Run your video ads only in countries where English is the primary language.",
    subtitle: "This includes markets like the US, Canada, UK, and Australia. Average cost-per-view is 4 - 6 cents USD."
  },
  {
    id: "global",
    name: "🌐 Global Markets",
    text: "Run your video ads globally, everywhere YouTube is available.",
    subtitle: "This includes markets like India, Mexico, Brazil, etc. Average cost-per-view is 1 cent USD or less."
  },
  {
    id: "custom",
    name: "📍 Custom",
    text: "Run your video ads in specific countries.",
    subtitle: null
  },
]

export const REVIEW = "Review"
export const REVIEW_YOUR_CAMPAIGN = "Review your campaign"
export const NEXT = "Next"
//TODO: Change by the real quotes and images
export const TESTIMONIALS = [
  {
    action: "Grew by",
    quantity: "400%",
    units: "monthly listeners",
    testimony:
      "For any artist serious about growing their fanbase, Symphony is a must. Their ability to automate ad campaigns and extract valuable insight gives users a unique blend of dependability & simplicity.",
    witness: "West",
    occupation: "Rapper",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/artist-west.jpeg",
  },
  {
    action: "Manages",
    quantity: "5 artists",
    units: "using Symphony",
    testimony:
      "Symphony is an incredible resource for today’s music entrepreneur and artist. As a full time artist manager for 4 artists and the CEO of a record label, my goal everyday is to promote my artist’s music and scale their careers as quickly as possible, with long-term career sustainability in mind.",
    witness: "Ashley",
    occupation: "CEO, Breakroom17",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/ceo-ashley-breakroom17.png",
  },
  {
    action: "Grown to",
    quantity: "25k",
    units: "monthly listeners on Spotify",
    testimony:
      "Symphony is a necessity for all artists. Their automated ad campaigns and other marketing tools have allowed me to grow my following and listeners across all platforms!",
    witness: "Christof Hunte",
    occupation: "Pop Artist",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/artist-christof-hunte.png",
  },
]

export enum COMMON_WIZARD_STEPS {
  FINALIZATION = "review",
  BUDGET = "budget",
}

export interface Stat {
  title: string;
  subtitle?: string;
  value: string | number;
  isCurrency?: boolean;
  currency?: string;
  icon?:string;
}

export const NAV_BAR_WIDTH_COLLAPSED = 64;
export const NAV_BAR_WIDTH_EXPANDED = 240;
export const NAV_BAR_HEIGHT_MOBILE = 56;
export const WEBSITE_TOP_BAR_HEIGHT = 64;
export const WEBSITE_BOTTOM_BAR_HEIGHT = 72;

export const HIDDEN_NAVBAR_ROUTES = [
  '/subscribe',
  '/marketing2/new',
  '/marketing/new',
  '/add-artist',
  '/email'
]

export enum RecommendationType {
  RUN_IG_ADS = 'runIgAds',
  BUILD_WEBSITE = 'buildWebsite',
  RUN_YOUTUBE_ADS = 'runYouTubeAds',
  CREATE_PRESAVE = 'createPresave',
}

export const welcomeToSymphonyRecommendations: Record<RecommendationType, {
  title: string,
  description: string,
  buttonText: string,
  image: string,
  imageBackgroundColor: string,
}> = {
  [RecommendationType.RUN_IG_ADS]: {
      title: 'Promote Your Music & Playlists on Instagram',
      description: 'Run AI-powered Instagram ads to reach your ideal fans and grow your streaming audiences.',
      buttonText: 'Run IG Ads >',
      image: ads,
      imageBackgroundColor: '#E3EDFE',
  },
  [RecommendationType.CREATE_PRESAVE]: {
      title: 'Create a Pre-save Campaign',
      description: 'Boost followers, grow streams, and collect fan emails with Symphony\'s pre-save campaigns.',
      buttonText: 'Create a Pre-save >',
      image: presave,
      imageBackgroundColor: '#C3DFD9',
  },
  [RecommendationType.BUILD_WEBSITE]: {
      title: 'Build Your Artist Website',
      description: 'Create a fully customizable home for your music, and collect deep analytics on your fanbase.',
      buttonText: 'Build a Website >',
      image: website,
      imageBackgroundColor: '#FFA477',
  },
  [RecommendationType.RUN_YOUTUBE_ADS]: {
      title: 'Run YouTube Ads and Grow Views',
      description: 'Boost your video views with targeted TruView and Pre-roll ads on YouTube.',
      buttonText: 'Run YouTube Ads >',
      image: ads,
      imageBackgroundColor: '#FAFAFA',
  },
}

export enum MARKETING_GOALS {
  TRACK_DATA = 'track_data',
  COLLECT_DATA = 'collect_data',
  LINK_IN_BIO = 'link_in_bio',
  GROW_STREAMS = 'grow_streams',
  GROW_SOCIALS = 'grow_socials',
  SELL_TICKETS = 'sell_tickets',
  SELL_MERCH = 'sell_merch',
  UNDERSTAND_DATA = 'understand_data',
}

export type MarketingGoalType = {
  type: MARKETING_GOALS,
  title: string
}

/** Enum defining all possible onboarding tab identifiers */
export enum TAB_ID {
  ACCOUNT_TYPE = 'account_type',
  BASIC = 'basic',
  MARKETING_EXPERIENCE = 'marketing-experience',
  TEAMS_TOTAL_CREATORS = 'teams-total-creators',
  MARKETING_BUDGET = 'marketing-budget',
  MARKETING_GOALS = 'marketing-goals',
  WELCOME = 'welcome'
}