import { Body2, Caption } from "components/shareable/Typography";
import { BadgeContainer, EmptyIconCenter, EmptyIconContainer } from "./styles";
import { TEAM_PLAN_INCLUDED_SEATS, TEAM_PLAN_ADDITIONAL_SEAT_PRICE } from "modules/Const"
import Colors from "modules/Colors";
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface AvailableSeatBadgeProps {
  onAddMember: () => void;
  currentSeats: number;
}

export const EmptyIcon = () => {
  return (
    <EmptyIconContainer>
      <EmptyIconCenter />
    </EmptyIconContainer>
  );
};

const AvailableSeatBadge = ({ onAddMember, currentSeats }: AvailableSeatBadgeProps) => {
  return (
    <BadgeContainer>
      <div className="flex gap-4 items-center">
        <EmptyIcon />
        <div className="flex flex-col gap-1">
          <Body2 color={Colors.greyDark}>You have {TEAM_PLAN_INCLUDED_SEATS - currentSeats} user seats available</Body2>
          <Caption color={Colors.greyDark}>{TEAM_PLAN_INCLUDED_SEATS} seats are included with the team plan, +${TEAM_PLAN_ADDITIONAL_SEAT_PRICE} / month for additional</Caption>
        </div>
      </div>
      <ButtonSymphony
        className="buttonAddMemberAvailableSeatBadge"
        onClick={onAddMember}
        variant="text"
      >
        Add member
      </ButtonSymphony>
    </BadgeContainer>
  );
};

export default AvailableSeatBadge;