import ConnectBrandLoader from "./ConnectBrandLoader";

interface LoaderFullScreenProps {
    title?: string
}

const LoaderFullScreen = ({
    title = "Welcome to Symphony"
}: LoaderFullScreenProps) => {
    return (
        <ConnectBrandLoader
            title={title}
        />
    )
};

export default LoaderFullScreen;
