import { Theme, makeStyles } from "@material-ui/core"
import styled from "styled-components"
import { MEDIA_BREAKPOINTS } from "types/global"
import Colors from "modules/Colors"

const useStyles = makeStyles((theme: Theme) => ({
  svgChecked: {
    height: "24px",
    width: "24px",
    "& path": {
      strokeWidth: "1px",
    },
  },
}))

interface StepNumberProps {
  background?: string
}

interface MainContainerProps {
  disabled?: boolean
  padding?: string
}

interface BodyContainerProps {
  paddingLeft?: string
}

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: ${({ background }: StepNumberProps) => background};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const MainContainer = styled.div<MainContainerProps>`
  pointer-events: ${({ disabled }) => (disabled ? "none" : undefined)};
  filter: ${({ disabled }) => (disabled ? "saturate(0)" : undefined)};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }) => padding || '24px 16px'};
  gap: 16px;
  background: ${Colors.PAPER_CONTAINER};
  border-radius: 12px;
  ${MEDIA_BREAKPOINTS.mobileView} {
    border-radius: 0px;
  }
`

export const BodyContainer = styled.div<BodyContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  gap: 16px;
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const LiveIndicator = styled.div`
  display: block;
  width: auto;
  border: 1px solid green;
  border-radius: 99px;
  font-size: 0.5rem;
  padding: 3px 5px;
  box-sizing: border-box;
  transform: translateY(-2px);
  background: #0080001c;
`

export default useStyles
