export type ColorFormat = 'light' | 'dark';

export const lightOrDark = (color: string): ColorFormat => {
    let r: number, g: number, b: number, hsp: number;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        const rgbMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        if (!rgbMatch) {
            throw new Error("Invalid RGB color format");
        }
        r = parseInt(rgbMatch[1]);
        g = parseInt(rgbMatch[2]);
        b = parseInt(rgbMatch[3]);
    } else {
        // If HEX --> Convert it to RGB
        // color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
        // r = color >> 16;
        // g = color >> 8 & 255;
        // b = color & 255;

        // If HEX --> Convert it to RGB
        const hex = color.slice(1);
        const fullHex = hex.length < 5 ? hex.replace(/./g, '$&$&') : hex;
        let newColor = +("0x" + fullHex);

        r = newColor >> 16;
        g = (newColor >> 8) & 255;
        b = newColor & 255;
    }

    // HSP equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    return hsp > 127.5 ? 'light' : 'dark';
}

// scroll the main body to top if needed
export function scrollToTop(): void {
    const doc = document;
    const mainBodyElement = doc.getElementById('main-body');
    if (mainBodyElement) {
        mainBodyElement.scrollTo(0, 0);
    }
}

export const scrollIntoView = (): void => {
    const mainBodyElement = document.getElementById("main-body");
    if (mainBodyElement) {
        mainBodyElement.scrollIntoView();
    }
};
