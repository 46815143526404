import { BackArrowImage, BackButtonContainer, BackToDashboardContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import { Container } from "styles/shared";
import { MARKETING_GOALS, MarketingGoalType } from "modules/Const";
import { DataGoal, MerchGoal, MusicGoal, SocialsGoal, TicketsGoal, UnderstandAnalyticsGoal, VideoGoal } from "./images/goal-icons";
import { useStyles } from "./styles";
import PrimaryButtonContainer from "./PrimaryButtonContainer";
import useScreen from "Hooks/useScreen";
import { useOnboarding } from "Hooks/OnboardingContext";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import Colors from "modules/Colors";

const MarketingGoals = ({
    multiSelect
}: {
    multiSelect?: boolean
}) => {
    const {
        setMarketingGoals,
        marketingGoals,
        currentAccountType: accountType,
        handleGoNext,
        showBackButton,
        handleGoBack
    } = useOnboarding()

    const { mobileView } = useScreen();
    const classes = useStyles();

    const MARKETING_GOALS_DATA: Record<string, MarketingGoalType> = {
        [MARKETING_GOALS.GROW_STREAMS]: {
            type: MARKETING_GOALS.GROW_STREAMS,
            title: accountType === 'creator' ? "Grow My Music" : "Grow Music Streams"
        },
        [MARKETING_GOALS.COLLECT_DATA]: {
            type: MARKETING_GOALS.COLLECT_DATA,
            title: accountType === 'creator' ? "Grow My Email List & Fan Data" : "Grow Email Lists & Fan Data"
        },
        // [MARKETING_GOALS.LINK_IN_BIO]: {
        //     type: MARKETING_GOALS.LINK_IN_BIO,
        //     title: "Grow My Videos"
        // },

        [MARKETING_GOALS.GROW_SOCIALS]: {
            type: MARKETING_GOALS.GROW_SOCIALS,
            title: accountType === 'creator' ? "Grow My Social Following" : "Grow Socials"
        },
        [MARKETING_GOALS.SELL_TICKETS]: {
            type: MARKETING_GOALS.SELL_TICKETS,
            title: "Sell More Tickets"
        },
        [MARKETING_GOALS.SELL_MERCH]: {
            type: MARKETING_GOALS.SELL_MERCH,
            title: "Sell More Merch"
        },
        [MARKETING_GOALS.UNDERSTAND_DATA]: {
            type: MARKETING_GOALS.UNDERSTAND_DATA,
            title: accountType === 'creator' ? "Understand My Analytics" : "Understand Analytics"
        },
    };

    const getImageForGoal = (goal: MarketingGoalType, selected: boolean) => {
        switch (goal.type) {
            case MARKETING_GOALS.TRACK_DATA:
                return <VideoGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.COLLECT_DATA:
                return <DataGoal
                    isSelected={Boolean(selected)} />

            case MARKETING_GOALS.GROW_STREAMS:
                return <MusicGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.GROW_SOCIALS:
                return <SocialsGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.SELL_TICKETS:
                return <TicketsGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.SELL_MERCH:
                return <MerchGoal
                    isSelected={Boolean(selected)} />
            case MARKETING_GOALS.UNDERSTAND_DATA:
                return <UnderstandAnalyticsGoal
                    isSelected={Boolean(selected)} />
            default:
                return <VideoGoal
                    isSelected={Boolean(selected)} />
        }
    }

    const handleChipSelected = (option: MarketingGoalType) => {
        if (!multiSelect) {
            setMarketingGoals([option]);
            return;
        } else {
            const isSelected = marketingGoals?.find(goal => goal.type === option.type);
            if (isSelected) {
                setMarketingGoals(marketingGoals?.filter(goal => goal.type !== option.type) || []);
            } else {
                setMarketingGoals([...(marketingGoals || []), option]);
            }
        }
    }

    console.log("marketingGoals", marketingGoals)


    const goalOptions = Object.keys(MARKETING_GOALS_DATA).map(key => MARKETING_GOALS_DATA[key as MARKETING_GOALS]);
    let disableNextButton = marketingGoals ? marketingGoals?.length === 0 : true
    return (
        <>
            <LeftColumn className="leftColumnMarketingGoals" width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton() && mobileView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton() && !mobileView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>What's your main goal at the moment?</MainTitle>
                    <SubTitle>Symphony can help with all of these, and we'll focus our recommendations on helping you accomplish this goal.</SubTitle>
                </Titles>
                <Container width="100%" display="flex" gap="18px" flexWrap="wrap" paddingBottom={mobileView ? "72px" : undefined}>
                    {goalOptions.map((option, index) => {
                        const selected = marketingGoals?.find(goal => goal.type === option.type);
                        return (
                            <ButtonSymphony
                                key={option.type}
                                variant="outlined"
                                className="marketingGoalChip"
                                iconLeft={getImageForGoal(option, Boolean(selected))}
                                color={selected ? "purple" : "grey"}
                                style={{
                                    backgroundColor: selected ? Colors.purpleLighter : 'transparent',
                                    borderWidth: 2
                                }}
                                onClick={() => handleChipSelected(option)}
                                size={mobileView ? 'medium' : 'large'}
                            >
                                {option.title}
                            </ButtonSymphony>
                        )
                    })}
                </Container>
                <PrimaryButtonContainer>
                    <ButtonSymphony
                        className="continueButtonMarketingGoals"
                        width={mobileView ? "100%" : 300}
                        disabled={disableNextButton}
                        onClick={handleGoNext}
                    >
                        Continue
                    </ButtonSymphony>
                </PrimaryButtonContainer>
            </LeftColumn>
            <RightColumn display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}

export default MarketingGoals