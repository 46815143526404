/* eslint-disable no-useless-escape */
import { ChangeEvent, useContext, useState } from "react";
import { STREAMLINK_REGEX } from "helpers/StreamingMappings";
import {
  Dialog,
  DialogContent,
  DialogSubtitle,
  InputTextContainer,
  InputText,
  InfoLabel,
  ButtonContainer,
  ConnectButton,
  LoadingContainer,
} from "./styles";
import { connectTwitter } from "./api";
import PopupHeader from "components/shareable/Popup/header";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Colors from "modules/Colors";
const USERNAME_REGEXP = /^[ A-Za-z0-9_@]*$/;

interface Props {
  onCloseModal: () => void;
  onSave?: () => void;
  tempUserData?: {
    url: string,
    username: string
  }
}

const TwitterConnect = ({
  onCloseModal,
  onSave,
  tempUserData
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext);

  const [text, setText] = useState<string>(tempUserData ? tempUserData.username : "");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [userData, setUserData] = useState<{
    url: string,
    username: string
  }>(tempUserData ? tempUserData : { url: "", username: "" });
  const [isValidText, setIsValidText] = useState(tempUserData ? true : false);
  const isContinueButtonDisabled = error || !text || !isValidText;

  if (!currentBrand) {
    return null;
  }

  const handleChangeInputText = (e: ChangeEvent<HTMLInputElement>) => {
    let matched = null;
    let username = null;
    const newText = e.target.value;
    const isUsername = USERNAME_REGEXP.test(newText);
    const twitterRegexps = STREAMLINK_REGEX["twitter"];

    if (error) setError(false);

    for (const index in twitterRegexps) {
      const regexp = twitterRegexps[index];

      matched = newText.match(regexp);
      if (matched) break;
    }

    username = matched?.[1] || (isUsername && newText) || "";
    const url = `https://twitter.com/${username}`;
    setUserData({ url, username });
    setIsValidText(!!username.trim());
    setText(newText);
  };

  const handleClickConnectButton = async () => {
    if (error) setError(false);

    setLoading(true);

    const { error: connectError } = await connectTwitter({
      id: currentBrand.id,
      slug: currentBrand.slug,
      userData,
    });

    if (connectError) {
      setError(true);
    } else {
      onSave && onSave();
      onCloseModal();
    }
    setLoading(false);
  };

  return (
    <Dialog>
      <PopupHeader
        text="Connect Twitter"
        onClose={onCloseModal}
      />

      <DialogContent>
        <DialogSubtitle>
          What's {currentBrand.name}'s Twitter username or profile URL?
        </DialogSubtitle>
        <InputTextContainer>
          <InputText
            placeholder="e.g. @jayz"
            type="text"
            autoComplete="off"
            value={text}
            onChange={handleChangeInputText}
          />
        </InputTextContainer>
        {text && !isValidText && (
          <InfoLabel color={!isValidText ? Colors.red : undefined}>
            Please enter a valid Twitter username or profile URL.
          </InfoLabel>
        )}
        {error && (
          <InfoLabel color={Colors.red}>
            An error occurred. Try again later
          </InfoLabel>
        )}
        <ButtonContainer>
          <ConnectButton
            disabled={isContinueButtonDisabled}
            onClick={handleClickConnectButton}
          >
            {loading ?
              <LoadingContainer>
                <LoadingIndicator />
              </LoadingContainer>
              :
              'Connect'
            }
          </ConnectButton>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TwitterConnect;
