import { CSSProperties } from "react"

const CardOption = (props: {
    className?: string,
    image?: string,
    text: string,
    thumbnail?: string,
    subtitle?: string
    onClick?: () => void
}) => {
    const {
        className = '',
        text,
        thumbnail,
        subtitle,
        onClick
    } = props

    return (
        <div
            className={`cardOptionComponent ${className}`}
            style={sty.cardOptionParent}
            onClick={onClick}
        >
            {thumbnail &&
                <img className="cardOptionImage" style={sty.cardOptionImage} src={thumbnail} alt="cardOptionImage" />
            }

            <div className="cardDetails" style={sty.cardDetails}>
                <p className="cardOptionTitle" style={sty.cardOptionTitle}>
                    {text}
                </p>
                {subtitle &&
                    <span className="cardSubtitle" style={sty.cardSubtitle}>
                        {subtitle}
                    </span>
                }
            </div>
        </div>
    )
}

const sty = {
    cardOptionParent: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        gap: 16,
        border: '1px solid #EDECF2',
        borderRadius: 8,
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
        cursor: 'pointer'
    } as CSSProperties,
    cardOptionImage: {
        borderRadius: 16,
        width: 50,
        height: 50
    } as CSSProperties,
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
        gap: 4,
        textOverflow: 'ellipsis',
        width: '100%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto'
    } as CSSProperties,
    cardOptionTitle: {
        fontSize: 20,
        alignItems: 'center',
        color: '#000000'
    } as CSSProperties,
    cardSubtitle: {
        fontSize: 16,
        color: '#707070',
        width: '80%',
        marginRight: 'auto',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto'
    } as CSSProperties
}

export default CardOption