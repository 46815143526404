import { CSSProperties, useContext } from "react";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import AdPreviewBox, { SelectedPost } from "components/shareable/FacebookAdPreview";
import { AdsPreviewContainer, Header, Subtitle, ImageContainer, Performance, PerformanceContainer, PerformanceType, PerformanceValue, GraphContainer, HeaderContainer } from "./styles";
import MultiProgress from "react-multi-progress";
import { ReactComponent as PreviewIcon } from 'assets/images/campaignDetails/instagram-purple.svg'
import millify from "millify";
import startCase from "lodash/startCase"
import { SymphonyTooltip } from "styles/shared";
import VideoAsImageBanner from "components/shareable/FacebookAdPreview/VideoAsImageBanner";
import { InstagramPageType } from "types/global";
import useScreen from "Hooks/useScreen";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import UpgradeToUnlockCTA from "pages/post-auth/Website/components/CustomizeWebsite/components/UpgradeToUnlockCTA";
import { PlanType } from "modules/Const";

interface Props {
  className?: string
  style?: CSSProperties
  callToAction?: string
  post: SelectedPost
  performance: CreativePerformance[]
  postUrl: string
  instagramAccount: InstagramPageType
  isLoading: boolean
  showViewOnInstagramButton?: boolean
  handleOnOpenPreview: () => void
}

export interface CreativePerformance {
  type: string,
  clicks: number,
  percentage: number,
}

export const getViewType = (
  values: CreativePerformance[]
) => {
  const [clicksReels, clicksFeed, clicksStories] = values
  const maxValue = Math.max(clicksReels.clicks, clicksFeed.clicks, clicksStories.clicks)
  if (clicksReels.clicks === maxValue) return clicksReels.type
  if (clicksFeed.clicks === maxValue) return clicksFeed.type
  if (clicksStories.clicks === maxValue) return clicksStories.type
  return ""
}

const AdsPreview = ({
  className = '',
  style = {},
  post,
  performance,
  postUrl,
  instagramAccount,
  isLoading,
  showViewOnInstagramButton,
  callToAction = "Listen now",
  handleOnOpenPreview,
}: Props) => {
  const { mobileView } = useScreen();
  const { isProBrand } = useContext(CurrentBrandContext);

  if (isLoading && mobileView) return null

  if (isLoading && !mobileView) {
    return (
      <AdsPreviewContainer
        width={mobileView ? "100%" : "30%"}
        paddingBottom="16px"
      >
        <NewLoader black />
      </AdsPreviewContainer>
    );
  }

  let adsPreviewComponent = { ...sty.adsPreviewComponent }
  if (mobileView) {
    adsPreviewComponent.paddingBottom = 32
  }

  const getAligmentforPerformance = (position: number) => {
    if (position === 0) return "flex-start"
    if (position === performance.length - 1) return "flex-end"
    return "center"
  }

  const renderPerformance = () => {
    if (!isProBrand) {
      return (
        <div className="px-5 py-1 flex flex-col justify-between">
          <UpgradeToUnlockCTA
            highlightedPlan={PlanType.PRO}
            requiresPaidPlan={true}
            paywallText="Track clicks across stories, reels, and feed."
            buttonText="Upgrade to unlock"
            upgradeSource="IG Campaign Details - Creative Performance"
          />
        </div>
      )
    }

    return (
      <div>
        <PerformanceContainer>
          {performance.map((value, index) =>
            <Performance alignItems={getAligmentforPerformance(index)} key={value.type}>
              <PerformanceValue>{millify(value.clicks)}</PerformanceValue>
              <PerformanceType>{startCase(value.type)}</PerformanceType>
            </Performance>
          )}
        </PerformanceContainer>
        <GraphContainer>
          <MultiProgress
            elements={[
              { value: performance[0]?.percentage || 0, color: '#8800ffa8' },
              { value: performance[1]?.percentage || 0, color: '#8800ff4f' },
              { value: performance[2]?.percentage || 100, color: '#8800ff29' },
            ]}
          />
        </GraphContainer>
      </div>
    )
  }

  return (
    <AdsPreviewContainer
      className={`adsPreviewComponent ${className}`}
      style={{ ...adsPreviewComponent, ...style }}
      width={mobileView ? "100%" : "30%"}
    >
      <HeaderContainer className="adsPreviewHeaderContainer">
        <div className="adsPreviewHeaderContainerDiv">
          <Header>Creative Performance</Header>
          <Subtitle>by clicks</Subtitle>
        </div>
        {showViewOnInstagramButton && (
          <a
            className="viewOnInstagramButton"
            href={postUrl}
            target="_blank"
            onClick={handleOnOpenPreview}
            rel="noreferrer"
          >
            <SymphonyTooltip title="View on Instagram">
              <PreviewIcon />
            </SymphonyTooltip>
          </a>
        )}
      </HeaderContainer>
      {renderPerformance()}
      <ImageContainer
        borderRadius={mobileView ? "0" : "0 0 12px 12px"}
      >
        <AdPreviewBox
          callToAction={callToAction}
          captions={[{ type: 'all', caption: post.caption }]}
          selectedPosts={[post]}
          instagramAccount={instagramAccount}
          shown
          simplePreview={post.type}
          muted={false}
        />
        {!post.media_url && post.thumbnail_url && (
          <VideoAsImageBanner borderRadius="6px" />
        )}
      </ImageContainer>
    </AdsPreviewContainer>
  );
}

const sty = {
  adsPreviewComponent: {
    position: 'sticky',
    top: 16,
  } as CSSProperties
}

export default AdsPreview