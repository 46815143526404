import { useContext, useEffect, useState } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import CircularStep from "../CircularStep";
import { Container } from 'styles/shared';
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { DEFAULT_CURRENCY, PRO_MONTHLY_PLAN_PRICE } from "modules/Const";
import getSymbolFromCurrency from "currency-symbol-map";
import { getConvertedCurrency } from "services/symphonyApi";
import { getNumberWithDecimals } from "helpers/General";
import useScreen from "Hooks/useScreen";

interface Props {
  budget: number;
}

const format = (budget: number, showDigits?: boolean) => {
  const numberOfDigits = showDigits ? 2 : 0

  return budget.toLocaleString("en-US", {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
  })
}

const BudgetCard = ({
  budget
}: Props) => {
  const classes = useStyles();
  const { mobileView } = useScreen()
  const { isProBrand, proFreeTrialRedeemed } = useContext(CurrentBrandContext)

  // TODO: Let's comment this for now in case roll back is needed
  const adsComission = 0
  const proFee = !isProBrand && proFreeTrialRedeemed() ? PRO_MONTHLY_PLAN_PRICE : 0;
  const { currency } = useContext(SpendBudgetContext)

  const [proFeeConverted, setProFeeConverted] = useState<number>(proFee);

  useEffect(() => {
    if (currency !== DEFAULT_CURRENCY && proFee > 0) {
      getConvertedCurrency({ amount: proFee, from: DEFAULT_CURRENCY, to: currency }).then((res) => {
        setProFeeConverted(Number(getNumberWithDecimals(res, 2)));
      });
    }
  }, [currency]);

  return (
    <Card borderRadius={mobileView ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={1} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Budget
            </StyledLabel>
          </Grid>
        </Grid>
        <Container className={clsx(classes.space, classes.border)}
          display="flex" flexDirection="row"
          justifyContent="space-between">
          <Container width="75%" justifyContent="flex-start">
            <StyledLabel fontSize={16} fontWeight={600}>
              Marketing Budget
            </StyledLabel>
            <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
              This amount will be charged and spent on your YouTube Ads campaign.
            </StyledLabel>
          </Container>
          <Container width="25%" display="flex" justifyContent="flex-end">
            <StyledLabel fontSize={16} fontWeight={400}>
              {getSymbolFromCurrency(currency)}
              {format(budget - adsComission * budget, true)}

            </StyledLabel>
          </Container>
        </Container>
      </Grid>
    </Card>
  );
};

export default BudgetCard;
