import { useContext, useState } from 'react'
import HeadCard from "./Components/HeadCard";
import BudgetCard from "./Components/BudgetCard";
import GoLiveCard from './Components/GoLiveCard';
import { PlaylistsRecord } from "types/global";
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

interface FinalizationProps {
    campaignId: string | null
    pitchNotes: string;
    tier: {
        tier: number;
        cost: number;
        discount: number;
        afterDiscountPrice: number;
    },
    record: PlaylistsRecord | null;
    cancelSaving: () => void;
}

const Finalization = ({
    campaignId,
    tier,
    record,
    pitchNotes,
    cancelSaving,
}: FinalizationProps) => {
    const { currentBrand } = useContext(CurrentBrandContext)

    const [creatingCampaign, setCreatingCampaign] = useState<boolean>(false)

    return (
        <>
            <HeadCard
                record={record}
                tier={tier}
            />
            <BudgetCard
                budget={tier.cost}
                currency={currentBrand?.currency?.code}
            />
            <GoLiveCard
                currencyCode={currentBrand?.currency?.code}
                pitchNotes={pitchNotes}
                campaignId={campaignId}
                record={record}
                tier={tier}
                creatingCampaign={creatingCampaign}
                setCreatingCampaign={setCreatingCampaign}
                cancelSaving={cancelSaving}
            />
        </>
    )
}

export default Finalization;