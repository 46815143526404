import { useContext } from 'react';
import {
  ArrowGreenUpContainer,
  CardTitle,
  MainContainer,
  NumberContainer,
  NumberText,
  PercentageText,
  RoseIconContainer,
  StatusIndicatorContainer,
  TotalNumberContainer,
  useStyles,
} from "./styles";
import { ReactComponent as Arrow } from 'assets/images/arrowRight.svg';
import Intercom from "helpers/Intercom";
import { SymphonyTooltip } from 'styles/shared';
import ArticleTitle, { Article } from 'components/shareable/ArticleTitle';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

interface Props {
  image: JSX.Element,
  title: string | JSX.Element,
  percentage?: string,
  total: number,
  mobile?: boolean,
  elementKey?: string,
}

const getFormattedTotal = (total: number) => {
  return total.toLocaleString();
}

const getFormattedPercentage = (percentage: string) => {
  return `${percentage} %`
}

const CardMetrics = ({
  image,
  title,
  total,
  percentage,
  mobile = true,
  elementKey
}: Props) => {
  const classes = useStyles();
  const { isProBrand } = useContext(CurrentBrandContext)

  const openIntercomArticle = () => {
    Intercom.openForeverSavesArticle();
  }

  const getCardTitle = () => {
    const titleElement = <CardTitle>{title}</CardTitle>;
    if (elementKey !== 'forever-presave-fans') return titleElement;
    if (isProBrand) {
      return (
        <ArticleTitle
          children={titleElement}
          article={Article.ForeverSaves}
          toolTipText='Click to learn more about how Forever Saves work.'
        />)
    }

    return (
      <SymphonyTooltip title="Upgrade to Symphony Pro to unlock Forever Saves. Click to learn more.">
        <CardTitle onClick={openIntercomArticle}>{title}</CardTitle>
      </SymphonyTooltip>
    )
  }

  return (
    <MainContainer
      className="cardMetricsComponent"
      flexDirection={mobile ? "column" : "row"}
      alignItems={mobile ? "flex-start" : "center"}
      gap={mobile ? "4px" : "16px"}
    >
      <RoseIconContainer>
        {image}
      </RoseIconContainer>
      {mobile && getCardTitle()}
      <TotalNumberContainer>
        <NumberContainer>
          {!mobile && getCardTitle()}
          <NumberText>{getFormattedTotal(total)}</NumberText>
        </NumberContainer>
        <StatusIndicatorContainer>
          <ArrowGreenUpContainer>
            <Arrow className={classes.svgGreen}/>
          </ArrowGreenUpContainer>
          {percentage && <PercentageText>{getFormattedPercentage(percentage)}</PercentageText>}
        </StatusIndicatorContainer>
      </TotalNumberContainer>
    </MainContainer>
  )
}

export default CardMetrics;
