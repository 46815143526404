import { Fragment, useEffect } from "react";
import TargetRow from "../../../TargetingView/Components/TargetRow";
import { getTooltipLabel } from "../../../TargetingView/GeographicTargeting";
import { GeographicTargetingType } from "../../../TargetingView/reducer";
import useStyles,
{
  Card,
  StyledLabel,
} from "../../styles";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import CircularStep from "../CircularStep";
import CircularLoader from "../CircularLoader";
import { Chip, useMediaQuery, useTheme } from "@material-ui/core";
import { DemographicsTargetingType, FBCustomAudience, FacebookPageResponse, FbSuggestion as TargetingAudience } from "types/global";
import isEqual from "lodash/isEqual";
import {
  FB_CAMPAIGNS_DEFAULT_LOCAES,
  FB_CAMPAIGNS_GENDERS,
  FB_CAMPAIGNS_AUTOMATED_AGES
} from '../../../../constants';
import TargetingDemographics from '../../../../Components/TargetDemographics/TargetingDemographics';
import { LanguageChip } from 'types/global';
import { getFormattedDemographics } from '../../../../utils/fbCampaigns';
import { defaultAudienceCopy, lowBudgetAudienceCopy } from "../../../TargetingView/data";
import { CAMPAIGN_TYPES } from "modules/Const";
import { SongAdsCampaignSubtype } from "../../..";
import useScreen from "Hooks/useScreen";

interface Props {
  loadingStep: number;
  targetAudiences: TargetingAudience[];
  customAudiences: FBCustomAudience[];
  additionalPageAudiences: FacebookPageResponse[];
  geographicTargets: GeographicTargetingType[];
  demographicTargets: DemographicsTargetingType;
  handleImproveAudienceTargeting: () => void;
  handleEditGeographicTargeting: () => void;
  saveDemographics: (demographics: DemographicsTargetingType) => void;
  isBelowRecommendedBudget: boolean;
  subtype: SongAdsCampaignSubtype;
}

const TargetingCard = ({
  loadingStep,
  targetAudiences,
  customAudiences,
  additionalPageAudiences,
geographicTargets,
  demographicTargets,
  handleImproveAudienceTargeting,
  handleEditGeographicTargeting,
  saveDemographics,
  isBelowRecommendedBudget,
  subtype
}: Props) => {
  const { age, gender, languages } = demographicTargets;

  const theme = useTheme();
  const { mobileView: isMobile } = useScreen()
  const isResponsive = useMediaQuery(theme.breakpoints.down(601));
  const classes = useStyles();

  useEffect(() => {
    const demographics = getFormattedDemographics({
      minAge: age?.min || FB_CAMPAIGNS_AUTOMATED_AGES.MIN,
      maxAge: age?.max || FB_CAMPAIGNS_AUTOMATED_AGES.MAX,
      gender: gender || FB_CAMPAIGNS_GENDERS.ALL,
      locales: languages || FB_CAMPAIGNS_DEFAULT_LOCAES
    });
    saveDemographics(demographics);
  }, []);

  const handleOnChangeDemographics = (data: {
    minAge: number,
    maxAge: number,
    gender: string,
    locales: Array<LanguageChip>
  }) => {
    const { minAge, gender, locales, maxAge } = data;
    const demographics = getFormattedDemographics({ minAge, maxAge, gender, locales });
    saveDemographics(demographics);
  }

  const campaignTypeEnum = subtype === "grow_playlist_followers" ? CAMPAIGN_TYPES.grow_playlist_followers : CAMPAIGN_TYPES.record_streams;

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={2} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Targeting
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
          <Grid
            className={clsx(classes.border, classes.withPaddingBottom)}
            container
            direction="column"
          >
            <StyledLabel fontSize={16} fontWeight={600}>
              Audiences
            </StyledLabel>
            <StyledLabel
              marginTop="8px"
              color="#707070"
              fontSize={16}
              fontWeight={400}
            >
              Your ads will target these audiences:
            </StyledLabel>
            {!isEqual(loadingStep, 3) && (
              <CircularLoader message="Symphony is finding the best audiences for you to target..." />
            )}
            {isEqual(loadingStep, 3) && (
              <Fragment>
                <Grid>
                  {targetAudiences.map(({ name }, index) => (
                    <Chip
                      //TODO: Fix problem with classes not applying styles
                      style={{
                        backgroundColor: "rgba(136, 0, 255, 0.08)",
                      }}
                      key={`${name}-${index}`}
                      className={classes.audienceTargetingChips}
                      classes={{
                        label: classes.chipLabelColor,
                        colorPrimary: classes.chipBackgroundColor,
                      }}
                      color="primary"
                      label={name}
                    />
                  ))}
                  {customAudiences && customAudiences.map(({ name }, index) => (
                    <Chip
                      //TODO: Fix problem with classes not applying styles
                      style={{
                        backgroundColor: "rgba(136, 0, 255, 0.08)",
                      }}
                      key={`${name}-${index}`}
                      className={classes.audienceTargetingChips}
                      classes={{
                        label: classes.chipLabelColor,
                        colorPrimary: classes.chipBackgroundColor,
                      }}
                      color="primary"
                      label={name}
                    />
                  ))}
                  {additionalPageAudiences && additionalPageAudiences.map(({ name, instagram_business_account, picture }, index) => (
                    <Chip
                      //TODO: Fix problem with classes not applying styles
                      icon={<img src={picture.data.url} className="w-5 h-5 rounded-full" />}
                      style={{
                        backgroundColor: "rgba(136, 0, 255, 0.08)",
                      }}
                      key={`${name}-${index}`}
                      className={classes.audienceTargetingChips}
                      classes={{
                        label: classes.chipLabelColor,
                        colorPrimary: classes.chipBackgroundColor,
                      }}
                      color="primary"
                      label={`Fans of ${name}'s Facebook Page${instagram_business_account ? ` + Instagram Account` : ''}`}
                    />
                  ))}
                </Grid>
                <StyledLabel
                  textAlign="left"
                  marginTop="24px"
                  color="#8800FF"
                  fontSize={16}
                  fontWeight={500}
                  cursor="pointer"
                  onClick={handleImproveAudienceTargeting}
                >
                  Improve your Audience Targeting
                </StyledLabel>
              </Fragment>
            )}
            <TargetingDemographics
              age={age || { min: FB_CAMPAIGNS_AUTOMATED_AGES.MIN, max: FB_CAMPAIGNS_AUTOMATED_AGES.MAX }}
              gender={gender || FB_CAMPAIGNS_GENDERS.ALL}
              languages={languages || FB_CAMPAIGNS_DEFAULT_LOCAES}
              handleOnChangeDemographics={handleOnChangeDemographics}
            />
          </Grid>
        </Grid>
        {isEqual(loadingStep, 3) && (
          <Grid item className={classes.space}>
            <Grid container alignItems="center" justifyContent="space-between">
              <StyledLabel fontSize={16} fontWeight={600}>
                Geographies
              </StyledLabel>
              <StyledLabel
                marginTop="8px"
                color="#707070"
                fontSize={16}
                fontWeight={400}
              >
                {isBelowRecommendedBudget ? lowBudgetAudienceCopy(campaignTypeEnum) : defaultAudienceCopy(campaignTypeEnum)}
              </StyledLabel>
              <Grid>
                <div className="py-5 md:px-5 lg:px-10 block sy-card border-0">
                  <table className="table-fixed w-full">
                    <thead className="items-start">
                      <tr className="text-left"
                        style={{
                          wordBreak: "break-all"
                        }}>
                        <th className="w-1/4 pl-2 py-2 border">Name</th>
                        <th
                          className={`w-${isResponsive ? "1/2" : "3/8"
                            } pl-2 py-2 border-t border-b`}
                        >
                          Locations
                        </th>
                        <th className="w-1/8 pl-2 py-2 border">% of Budget</th>
                      </tr>
                    </thead>
                    <tbody>
                      {geographicTargets.map(
                        ({ id, name, budget, locations }, index) => (
                          <TargetRow
                            isBelowRecommendedBudget={isBelowRecommendedBudget ? isBelowRecommendedBudget : false}
                            id={index}
                            isResponsive={isResponsive}
                            key={`${name}-${index}`}
                            currentName={name}
                            currentBudget={budget}
                            currentLocations={locations}
                            loading={false}
                            isEditing={false}
                            searchResults={[]}
                            editMode={false}
                            searchText=""
                            tooltipLabel={getTooltipLabel(id)}
                          />
                        )
                      )}
                    </tbody>
                  </table>
                  <StyledLabel
                    textAlign="center"
                    marginTop="24px"
                    color="#8800FF"
                    fontSize={16}
                    fontWeight={500}
                    cursor="pointer"
                    onClick={handleEditGeographicTargeting}
                  >
                    Edit Geographic Targeting
                  </StyledLabel>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default TargetingCard;
