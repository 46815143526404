import ProfileDropdown from "components/ProfileDropdown";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { CSSProperties, useContext, useEffect, useState, Fragment } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { getStatusToShowPaymentFailedBanner } from "modules/Utils";
import { SymphonyTooltip } from "styles/shared";
import { CurrentBrand } from "types/global";
import { NavigationItem } from "./Navbar";
import miniSymphonyLogo from "../../assets/images/logos/sidebar-collapsed.png";
import symphonyLogo from "../../assets/images/symphonyLogo.svg";
import {
    DownArrow,
    FansIcon,
    GiftIcon,
    SettingsIcon,
    SidebarIcon
} from "../svg-icons";
import AdminOptions from "./Components/AdminOptions";
import Indicator from "./Components/indicator";
import NavItem from "./Components/NavItem";
import SubscriptionStatusIndicator from "./Components/SubscriptionStatusIndicator";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import Colors from "modules/Colors";
import { NAV_BAR_WIDTH_COLLAPSED, NAV_BAR_WIDTH_EXPANDED } from "modules/Const";
import { useNavbar } from "Hooks/useNavbar";
import useScreen from "Hooks/useScreen";

interface DesktopNavigationProps {
    navigationItems: NavigationItem[];
    loadedBrands: CurrentBrand[] | null;
}

const DesktopNavigation = ({
    navigationItems,
    loadedBrands,
}: DesktopNavigationProps) => {
    const location = useLocation();
    const history = useHistory();
    const {
        currentBrand,
        isProBrand,
        isLiteBrand,
        proFreeTrialRedeemed
    } = useContext(CurrentBrandContext);
    const { currentUser } = useContext(CurrentUserContext);
    const { isSymphonyAdmin } = useContext(CurrentUserContext)
    const { teamMembers, organization } = useCurrentTeam();
    const { handleOpenModal } = useContext(UpgradeModalContext);
    const { isCollapsed, setIsCollapsed, isHoverSmallSidebar, setIsHoverSmallSidebar } = useNavbar();
    const { cssCalc } = useScreen();

    const myRole = teamMembers.find((member) => member.id === currentUser?.id)?.role;

    // free trial over status
    const freeTrialOver = proFreeTrialRedeemed()

    const [enablePro, setEnablePro] = useState(isProBrand)

    // shows an "add to Pro in your team"- type modal
    const [showUpgradeInTeamModal, setShowUpgradeInTeamModal] = useState(false);
    const initialStatus = localStorage.getItem('sidebar')

    const showEnablePro = isSymphonyAdmin
    const selectedRoute = location.pathname

    const isOrganizationReadyOrWaiting = (
        organization?.status === 'ready' ||
        organization?.status === 'waiting_for_details') &&
        currentUser?.metadata?.organization_subscription?.status !== 'canceled';

    const hasViewOnlyAccess = currentBrand?.permissionLevel === "view_only";

    const isCollapsedInit = initialStatus && initialStatus === 'collapsed' ? true : false

    const isSmallSidebar = isCollapsed && !isHoverSmallSidebar;

    const [tracked, setTracked] = useState(false);
    const [dropdownOpenId, setDropdownOpenId] = useState<string | null>(null)

    useEffect(() => {
        setTracked(true)
    }, [])

    useEffect(() => {
        if (isSymphonyAdmin) {
            setEnablePro(isProBrand)
        }
    }, [currentBrand])

    useEffect(() => {
        // hacky way of changing main content padding
        // when sidebar is collapsed
        // if (tracked) {
        //     track("Toggle Sidebar", {
        //         to: isCollapsed ? 'collapsed' : 'not collapsed'
        //     })
        // }

        // only activate padding-left if the sidebar is shown (lg: // 1024px width)
        // if (isCollapsed) {
        // document!.getElementById('main-content')!.classList.add('lg:pl-14')
        // setIsHoverSmallSidebar(false)
        // if (tracked) setUserProperty("desktop sidebar", "collapsed")
        // localStorage.setItem('sidebar', 'collapsed')
        // } else {
        // document!.getElementById('main-content')!.classList.remove('lg:pl-14')
        // if (tracked) setUserProperty("desktop sidebar", "not collapsed")
        // localStorage.setItem('sidebar', 'open')

        // }
    }, [isCollapsed])

    const handleUpgradeClick = () => {
        handleOpenModal({
            source: "Desktop Navigation - Upgrade to Pro Button"
        });
    }

    const renderActionButtons = () => {
        const renderUpgradePlanText = () => {
            // Return early if view-only access
            if (hasViewOnlyAccess) {
                return "";
            }

            if (isOrganizationReadyOrWaiting) {
                return "Upgrade to Pro"
            }

            if (isProBrand) return ""
            const isUnpaid = getStatusToShowPaymentFailedBanner(currentBrand)

            if (isUnpaid) {
                return "Reactivate your Plan"
            } else if (freeTrialOver) {
                if (isLiteBrand) {
                    return "Upgrade to Pro"
                } else {
                    return "Upgrade Plan"
                }
            }

            return "Start Pro Trial"
        }
        const handleUpgradeClick = () => {
            handleOpenModal({
                source: "Desktop Navigation - Upgrade to Pro Button"
            });
        }

        return (
            <>
                {!isSmallSidebar && hasViewOnlyAccess ? (
                    <SubscriptionStatusIndicator
                        text="View Only Access"
                        showStars={false}
                        backgroundColor={Colors.VIEW_ONLY_BACKGROUND}
                        textColor={Colors.textColorWhite}
                    />
                ) : !isProBrand && (
                    <SubscriptionStatusIndicator
                        text={renderUpgradePlanText()}
                        showStars
                        handleOpenModal={handleUpgradeClick}
                    />
                )}
            </>
        );
    }

    const renderLogoContainer = () => {
        return (
            <div className='logoContainer'>
                {isCollapsed && isSmallSidebar &&
                    <img
                        className="miniSymphonyLogo"
                        style={sty.miniSymphonyLogo}
                        src={miniSymphonyLogo}
                        alt="miniSymphonyLogo"
                    />
                }
                {!isSmallSidebar &&
                    <div className="logoMainContainer" style={sty.logoMainContainer}>
                        <Link
                            className="hidden text-2xl text-white font-bold row items-center justify-center lg:flex lg:flex-col lg:items-start"
                            to="/"
                        >
                            <img
                                className="symphonyLogo"
                                style={sty.symphonyLogo}
                                src={symphonyLogo}
                                alt="symphonyLogo"
                            />
                            <p className="betaText text-xs font-medium lg:mt-1 text-gray-400">
                                BETA{/* v{VERSION}*/}
                            </p>
                        </Link>
                        <SymphonyTooltip
                            title={isCollapsed ? "Always Show" : "Collapse"}
                            placement="right"
                        >
                            <div
                                className="sidebarToggleIconContainer"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <SidebarIcon filledIn={!isCollapsed} />
                            </div>
                        </SymphonyTooltip>
                    </div>
                }
            </div>
        )
    }

    /**
     * Renders the team onboarding button in the navigation based on user role and organization status
         * 
         * Logic flow:
         * 1. If user is an admin:
         *    - Show "Admin: Team Onboarding" button that links to admin onboarding page
         *    - Button text collapses to just "Team" when sidebar is small
         * 
         * 2. If user is not an admin:
         *    - Only show button if user doesn't belong to an organization yet
         *    - Shows "Start a Team" button that opens upgrade modal
         *    - Button text collapses to just "Team" when sidebar is small
         *    - If user already has an organization, returns null (no button shown)
         * 
         * @returns JSX.Element | null - The team onboarding button UI or null if not applicable
         */
    const renderTeamOnboardingButton = () => {
        if (isSymphonyAdmin) {
            return (
                <div
                    className={`adminUserTeamPlanContainer nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full lg:px-5 cursor-pointer`}
                    onClick={() => { history.push('/team/admin/onboarding') }}
                >
                    <div
                        className="fansIconContainer flex justify-center lg:inline-block"
                        style={{ transform: "translateY(-2px)" }}>
                        <FansIcon color="white" />
                    </div>
                    <div className={`teamSidebarContainer smallSidebar ${isSmallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base text-gray-400 font-medium`}>
                        {isSmallSidebar ? `Team` : `Admin: Team Onboarding`}
                    </div>
                </div>
            )
        } else if (!organization) {
            return (
                <div
                    className={`nonAdminTeamPlanContainer nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full lg:px-5 cursor-pointer`}
                    onClick={() => handleOpenModal({
                        source: "Desktop Navigation - Upgrade to Pro Button",
                        tab: "team",
                    })}
                >
                    <div
                        className="flex justify-center lg:inline-block"
                        style={{ transform: "translateY(-2px)" }}
                    >
                        <FansIcon color="white" />
                    </div>
                    <div className={`${isSmallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base text-gray-400 font-medium`}>
                        {isSmallSidebar ? `Team` : `Start a Team`}
                    </div>
                </div>
            )
        }

        return null
    }

    const renderDesktopNavigationInnerAbove = () => {
        return (
            <div className="desktopNavigationInnerAbove">
                {renderLogoContainer()}

                {loadedBrands && loadedBrands.length > 0 &&
                    renderNavigationItems()
                }
            </div>
        )
    }

    const renderDesktopNavigationInnerBelow = () => {
        return (
            <div className="desktopNavigationInnerBelow">
                <div className="actionButtonsContainer" style={sty.actionButtonsContainer}>
                    {renderActionButtons()}
                    {/* // Notice: check if we should show the subscription status when is on active organization */}
                    {!isSmallSidebar && !isOrganizationReadyOrWaiting && (isProBrand && currentBrand?.subscription && !currentBrand?.subscription.details?.trialOver && currentBrand?.subscription.details?.trialDaysLeft) &&
                        <SubscriptionStatusIndicator
                            text={`${currentBrand?.subscription.details?.trialDaysLeft} Day${currentBrand?.subscription.details?.trialDaysLeft === 1 ? '' : 's'} Left on Trial`}
                        />
                    }
                    <div className={`
                        ${isSmallSidebar ? 'lg:bg-opacity-0' : 'lg:bg-opacity-25'}
                        hidden lg:mt-auto lg:block lg:w-10/12 lg:mx-auto lg:bg-gray-600 lg:rounded-2xl`}>
                        <ProfileDropdown
                            smallSidebar={isSmallSidebar}
                            loadedBrands={loadedBrands}
                        />
                    </div>
                </div>

                {renderTeamOnboardingButton()}

                <NavLink
                    className="navLinkReferComponent nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full  lg:px-5 lg:ml-1"
                    activeClassName="text-primary active-stroke"
                    to="/refer"
                    exact
                >
                    <div
                        className="giftIconContainer flex justify-center lg:inline-block"
                        style={{ transform: "translateY(-2px)" }}
                    >
                        <GiftIcon color="white" />
                    </div>
                    <div className={`${isSmallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base ${selectedRoute === '/refer' ? `text-primary lg:text-white` : `text-gray-400`} font-medium`}>
                        {isSmallSidebar ? `Refer` : `Refer & Earn`}
                    </div>
                </NavLink>
                <NavLink
                    className={`nav-link text-black lg:text-white block lg:flex items-center p-0 lg:py-1.5 lg:px-0 w-full  lg:px-5 `}
                    activeClassName="text-primary active-stroke"
                    to="/settings"
                    exact
                >
                    <div
                        className="settingsIconContainer flex justify-center lg:inline-block"
                        style={{ transform: "translateY(-2px)" }}
                    >
                        <SettingsIcon color="white" />
                    </div>
                    <div className={`settingsText ${isSmallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-base ${selectedRoute === '/settings' ? `text-primary lg:text-white` : `text-gray-400`} font-medium`}>
                        Settings
                    </div>
                </NavLink>
                <AdminOptions
                    isCollapsed={isCollapsed}
                />
         
            </div>
        )
    }

    const renderNavigationItems = () => {
        return (
            <ul
                className="mainNavigationContainer"
                style={sty.mainNavigationContainer}
            >
                {navigationItems
                    .filter((navItem: NavigationItem) => navItem.showOnDesktopMainMenu)
                    .map((navItem: NavigationItem) => {
                        if (navItem.needsOrganization && !organization) {
                            return null;
                        }

                        let showDropdown = dropdownOpenId === navItem.name

                        let selected = Boolean((selectedRoute === navItem.href) || (navItem.alternateHrefs && navItem.alternateHrefs.indexOf(selectedRoute) > -1)
                            || (navItem.href && navItem.href !== '/' && selectedRoute.includes(navItem.href)))

                        const innerComponent = (
                            <>
                                <div className="flex justify-center lg:inline-block"
                                    style={{ transform: "translateY(-2px)" }}>
                                    {navItem.iconComponent && <navItem.iconComponent color="white" />}
                                </div>
                                <div className={`navBarIcon ${isSmallSidebar ? 'invisible' : 'visible'} block lg:inline-block px-1 py-1 lg:px-3 text-xs sm:text-lg lg:text-lg ${selected ? `text-white` : `hover:text-gray-300 text-gray-400`} font-medium`}>
                                    {isSmallSidebar ? navItem.smallName ? navItem.smallName : navItem.name : navItem.name}
                                </div>
                            </>
                        )

                        const mainNavItem = (
                            <li
                                key={navItem.name}
                                className="liNavItemContainer"
                                style={sty.liNavItemContainer}
                            >
                                <Indicator selected={selected} />
                                <NavItem
                                    selected={selected}
                                    innerComponent={innerComponent}
                                    link={navItem.href}
                                    onClick={(e) => {
                                        if (navItem.dropdownItems && navItem.dropdownItems.length > 0) {
                                            if (dropdownOpenId === navItem.name) {
                                                setDropdownOpenId(null)
                                            } else {
                                                setDropdownOpenId(navItem.name)
                                            }
                                        } else {
                                            if (e.onClick) {
                                                e.onClick(e)
                                            }
                                            setDropdownOpenId(null)
                                        }
                                    }}
                                />
                                {!isSmallSidebar && navItem.dropdownItems && navItem.dropdownItems.length > 0 &&
                                    <div
                                        className="dropdownArrowContainer"
                                        style={sty.dropdownArrowContainer}
                                        onClick={() => {
                                            // if this is a dropdown, open it
                                            // otherwise handle onclick normally
                                            if (navItem.dropdownItems && navItem.dropdownItems.length > 0) {
                                                if (dropdownOpenId === navItem.name) {
                                                    setDropdownOpenId(null)
                                                } else {
                                                    setDropdownOpenId(navItem.name)
                                                }
                                            }
                                        }}
                                    >
                                        <DownArrow
                                            position={dropdownOpenId === navItem.name ? 'down' : 'up'}
                                            width="26"
                                            height="26"
                                        />
                                    </div>
                                }
                            </li>
                        )

                        let mainNavChildrenItems = null
                        if (!isSmallSidebar && navItem.dropdownItems) {
                            const filteredDropdownItems = navItem.dropdownItems.filter(item => item.showOnDesktopSubMenu);
                            mainNavChildrenItems = (
                                <ul
                                    className="ulNavItemOuterContainer"
                                    style={sty.ulNavItemOuterContainer}
                                >
                                    {filteredDropdownItems.map((dropdownItem: NavigationItem) => {
                                        let dropdownSelected = Boolean((selectedRoute === dropdownItem.href) || (dropdownItem.alternateHrefs && dropdownItem.alternateHrefs.indexOf(selectedRoute) > -1))

                                        return (
                                            <NavItem
                                                key={`${dropdownItem.name}-${navItem.name}`}
                                                className="desktopNavigationNavItem"
                                                selected={dropdownSelected}
                                                innerComponent={dropdownItem.name}
                                                link={dropdownItem.href}
                                                onClick={(e) => {
                                                    if (dropdownItem.onClick) {
                                                        dropdownItem.onClick(e)
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                </ul>
                            )
                        }

                        return (
                            <Fragment key={navItem.name}>
                                {mainNavItem}
                                {mainNavChildrenItems && navItem.dropdownItems &&
                                    <li className={`additionalItemsContainer pl-9 w-full overflow-hidden transition-all duration-200 ease-in-out  ${showDropdown ? `h-28` : `h-0`}`}>
                                        {mainNavChildrenItems}
                                    </li>
                                }
                            </Fragment>
                        )
                    })
                }
            </ul>
        )
    }

    let collapsedSidebarStyles = null;

    if (isCollapsed) {
        collapsedSidebarStyles = {
            position: 'fixed',
            maxWidth: isHoverSmallSidebar ? NAV_BAR_WIDTH_EXPANDED : NAV_BAR_WIDTH_COLLAPSED,
            zIndex: 50,
        } as CSSProperties
    }

    return (
        <div
            className="desktopNavigationComponent"
            style={{
                ...sty.desktopNavigationComponent,
                height: cssCalc(),
                ...collapsedSidebarStyles,
            }}
            onMouseEnter={() => {
                if (isCollapsed) {
                    setIsHoverSmallSidebar(true);
                }
            }}
            onMouseLeave={() => {
                if (isCollapsed) {
                    setIsHoverSmallSidebar(false);
                }
            }}
        >
            {renderDesktopNavigationInnerAbove()}
            {renderDesktopNavigationInnerBelow()}
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    desktopNavigationComponent: {
        position: 'sticky',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: NAV_BAR_WIDTH_EXPANDED,
        backgroundColor: Colors.purpleDark,
        paddingTop: 24,
        paddingBottom: 16,
        flexShrink: 0,
        zIndex: 1,
    },
    actionButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
    },
    symphonyLogo: {
        width: 139,
    },
    logoMainContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingLeft: 20,
        paddingRight: 20,
    },
    mainNavigationContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'around',
        color: 'white',
        marginBottom: 'auto',
    },
    navItemContainer: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ulNavItemOuterContainer: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        justifyContent: 'around',
        flexDirection: 'column',
    },
    miniSymphonyLogo: {
        marginLeft: 12,
        marginBottom: 1,
        height: 40,
        width: 40,
    },
    liNavItemContainer: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
    },
    dropdownArrowContainer: {
        cursor: 'pointer',
        paddingRight: 16,
    },
}

export default DesktopNavigation