import HeaderModal from "../../../HeaderModal"
import {
  CardCampaign,
  CardCampaignDetails,
  CardCampaignDetailsInfo,
  CardCampaignDetailsPlatforms,
  CardCampaignHeader,
  CardCampaignHeaderInfo,
  CardCampaignHeaderInfoArtist,
  CardCampaignHeaderInfoRecord,
  GenreLabel,
  GenreTitleLabel,
  MainContainer,
  NextButton,
  PlatformsContainer,
  RecordImg,
} from "../../style";
import { ReactComponent as DuplicateIcon } from "assets/images/campaignDetails/duplicate.svg";
import { ReactComponent as SpotifyIcon } from "assets/images/campaignDetails/spotifyGrey.svg";
import { ReactComponent as AppleMusicIcon } from "assets/images/campaignDetails/appleMusicGrey.svg";
import { ReactComponent as YoutubeIcon } from "assets/images/campaignDetails/youtubeGrey.svg";
import { ReactComponent as DeezerIcon } from "assets/images/campaignDetails/deezerGrey.svg";
import { ReactComponent as SoundCloudIcon } from "assets/images/campaignDetails/soundCloudGrey.svg";
import ErrorBanner from "../ErrorBanner";
import Lottie from "react-lottie";
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';

interface Props {
  loading: boolean;
  error: boolean;
  recordType: string;
  brandName: string;
  recordName: string;
  thumbnailUrl: string;
  genres: string;
  platforms: string[];
  mobile: boolean,
  handleGoNext: () => void;
  closeModal: () => void;
  handleGoBack: () => void;
}

const platformIcons: Record<string, JSX.Element> = {
  apple_music: <AppleMusicIcon />,
  deezer: <DeezerIcon />,
  soundcloud: <SoundCloudIcon />,
  spotify: <SpotifyIcon />,
  youtube: <YoutubeIcon />,
}

const RecordStreamsReview = ({
  loading,
  error,
  recordType,
  brandName,
  recordName,
  thumbnailUrl,
  genres,
  platforms,
  handleGoNext,
  closeModal,
  handleGoBack,
  mobile,
}: Props) => (
  <MainContainer>
    <HeaderModal
      HeaderIcon={DuplicateIcon}
      title="Duplicate campaign"
      subtitle={`We’re going to create a campaign with this ${recordType}:`}
      closeModal={closeModal}
      handleGoBack={handleGoBack}
    />
    <CardCampaign>
      <CardCampaignHeader>
        <CardCampaignHeaderInfo>
          <CardCampaignHeaderInfoRecord>
            {recordName}
          </CardCampaignHeaderInfoRecord>
          <CardCampaignHeaderInfoArtist>
            {brandName}
          </CardCampaignHeaderInfoArtist>
        </CardCampaignHeaderInfo>
        <RecordImg src={thumbnailUrl} />
      </CardCampaignHeader>
      {/* <CardCampaignDetails flexDirection={mobile ? 'column' : 'row'}>
        <CardCampaignDetailsInfo>
          <GenreTitleLabel>Genre</GenreTitleLabel>
          <GenreLabel> {genres} </GenreLabel>
        </CardCampaignDetailsInfo>
        <CardCampaignDetailsPlatforms>
          <GenreTitleLabel>Found on</GenreTitleLabel>
          <PlatformsContainer>
            {platforms.map((platform: string) => (
              <Fragment key={platform}>{platformIcons[platform]}</Fragment>
            ))}
          </PlatformsContainer>
        </CardCampaignDetailsPlatforms>
      </CardCampaignDetails> */}
    </CardCampaign>
    {!loading && error && (
      <div className="order-3">
        <ErrorBanner errorText="An error occurred when duplicating your campaign... Please try again." />
      </div>
    )}
    <NextButton onClick={!loading ? handleGoNext : undefined}>
      {loading ? (
        <Lottie
          height={32}
          width={32}
          options={{
            rendererSettings: {
              className: "bg-white-500"
            },
            loop: true,
            autoplay: true,
            animationData: SymphonyLoadingLottie,
          }}
        />
      ) : (
        "Next"
      )}
    </NextButton>
  </MainContainer>
)

export default RecordStreamsReview
