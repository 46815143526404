import { ReactNode, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as SearchIcon } from "assets/images/search-icon-2.svg";
import { Caption } from '../Typography';
import LoadingLottie from 'components/Loader/LoadingLottie';
import useScreen from 'Hooks/useScreen'
import Colors from 'modules/Colors';

interface FilteredListProps {
  list: string[];
  children: ReactNode;
  isEmpty?: boolean;
  emptyMessage?: string;
  loading?: boolean;
  filterList: (items: string[]) => void;
}

export const FilteredList = ({
  list,
  children,
  isEmpty,
  emptyMessage,
  loading,
  filterList
}: FilteredListProps) => {
  const classes = useStyles();
  const { mobileView } = useScreen();
  const [filterText, setFilterText] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    if (filterText.length > 1) {
      filterList(list.filter(item => item.toLowerCase().includes(filterText.toLowerCase())));
    } else {
      filterList(list);
    }
  }, [filterText]);

  if (loading) return <div className="py-6"><LoadingLottie black /></div>;

  const showSearchBar = list.length > 3 || filterText.length >= 1;
  return (
    <div className={`flex flex-col gap-4 w-full overflow-y-scroll px-8 pb-6 ${!mobileView ? 'max-h-96' : ''}`}>
      {showSearchBar && ( 
        <TextField
          value={filterText}
          type="search"
          variant="outlined"
          fullWidth
          className={classes.input}
          placeholder="Search..."
          onChange={handleInputChange}
          InputProps={{ startAdornment: (<SearchIcon height={24} width={24} className="mr-4" />) }}
        />
      )}
      {isEmpty && emptyMessage && (
        <div className='flex justify-center items-center'>
          <Caption color={Colors.greyDark}>{emptyMessage || 'No results found'}</Caption>
        </div>
      )}
      {children}
    </div>
  );
};