import { Body2, Caption } from "components/shareable/Typography";
import { ReactComponent as FacebookRoundedIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-icon.svg";
import { ReactComponent as InstagramBwIcon } from "assets/images/instagram-bw.svg";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right-icon.svg";
import { useState } from "react";
import styled from "styled-components";
import { MEDIA_BREAKPOINTS } from "types/global";
import ButtonSymphony from "./shareable/ButtonSymphony";
import Colors from "modules/Colors";

interface Props {
  className?: string;
  fbAccount: string;
  fbImg?: string;
  instagramImg?: string;
  instagramAccount?: string;
  buttonText?: string;
  buttonWidth?: string;
  disabledButton?: boolean;
  onClick: () => void;
}

const AccountContainerIg = ({
  className = '',
  fbAccount,
  fbImg,
  instagramImg,
  instagramAccount,
  buttonText,
  buttonWidth,
  disabledButton,
  onClick,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <MainContainer className={`accountContainerIgComponent ${className}`}>
      <DescriptionContainer>
        <ConnectionDescription>
          {fbImg ? (
            <img className="w-10 h-10 rounded-lg" src={fbImg} alt="Facebook" />
          ) : (
            <ImageContainer>
              <FacebookRoundedIcon />
            </ImageContainer>
          )}
          <div className="flex flex-col">
            <div className="flex gap-1 items-center">
              <FacebookRoundedIcon width={16} height={16} />
              <Caption color={Colors.greyDark}>
                FB Page
              </Caption>
            </div>
            <Body2 color={Colors.textColor}>{fbAccount}</Body2>
          </div>
        </ConnectionDescription>
        <ArrowRight width={24} height={24} />
        <ConnectionDescription>
          {instagramImg ?
            <img className="w-10 h-10 rounded-lg" src={instagramImg} alt="Instagram" />
            :
            <ImageContainer backgroundColor="#E9E9E9">
              <InstagramBwIcon />
            </ImageContainer>
          }
          <div className="flex flex-col min-w-0">
            <div className="flex gap-1 items-center">
              <InstagramIcon width={16} height={16} />
              <Caption color={Colors.greyDark}>
                IG Page
              </Caption>
            </div>
            {instagramAccount &&
              <div className="min-w-0 truncate">
                <Body2 color={Colors.textColor}>
                  {instagramAccount}
                </Body2>
              </div>
            }
          </div>
        </ConnectionDescription>
      </DescriptionContainer>
      {buttonText &&
        <ButtonSymphony
          className="buttonAccountContainer"
          variant="outlined"
          onClick={handleConnect}
          isLoading={loading}
          minWidth={buttonWidth}
          disabled={disabledButton}
        >
          {buttonText}
        </ButtonSymphony>
      }
    </MainContainer>
  );
}

interface ImageContainerProps {
  backgroundColor?: string;
}

const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || "#1977F329"};
  padding: 0.75rem;
  border-radius: 8px;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Colors.divider};
  padding: 12px;
  border-radius: 8px;
  gap: 16px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  ${MEDIA_BREAKPOINTS.mobileView} {
      flex-direction: column;
      align-items: flex-start;
      & svg {
          transform: rotate(90deg);
      };
  }
`;

const ConnectionDescription = styled.div`
  display: flex;
  width: 45%;
  align-items: center;
  gap: 8px;
  ${MEDIA_BREAKPOINTS.mobileView} {
      width: 100%;
  }
`;

export default AccountContainerIg;