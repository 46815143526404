import { Dialog } from '@material-ui/core';
import {
  ButtonContactSupport,
  EmailLabel,
  ModalContainer,
  SendEmailContainer,
  SendEmailLabel,
  useStyles
} from './style';
import { showNewMessageIntercom } from 'components/Navbar/Navbar';
import HeaderModal from '../HeaderModal';
import { ReactComponent as CloseRedIcon } from 'assets/images/error-close-red.svg';
import useScreen from 'Hooks/useScreen';

interface Props {
  open: boolean;
  closeModal: () => void;
  campaignId: number;
  errorTitle?: string | null;
  errorMessage?: string | null;
}

const ErrorUpdatingCampaignModal = ({
  open,
  closeModal,
  campaignId,
  errorTitle = 'Something went wrong...',
  errorMessage = 'There was an error updating your campaign. Please try again or reach out to our team to complete your request.'
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useScreen();

  const handleContactSupport = () => showNewMessageIntercom()

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={mobileView ? "auto" : "678px"}
        padding={mobileView ? "32px 24px" : "32px"}
      >
        <HeaderModal
          HeaderIcon={CloseRedIcon}
          title={errorTitle ? errorTitle : 'Something went wrong...'}
          subtitle={errorMessage ? errorMessage : 'There was an error updating your campaign. Please try again or reach out to our team to complete your request.'}
          closeModal={closeModal}
        />
        <ButtonContactSupport
          onClick={handleContactSupport}
        >
          Contact support
        </ButtonContactSupport>
        <SendEmailContainer>
          <SendEmailLabel>Or send an email to:</SendEmailLabel>
          <EmailLabel href={`mailto:team@symphonyos.co?subject=Update Campaign ${campaignId}`}>team@symphonyos.co</EmailLabel>
        </SendEmailContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default ErrorUpdatingCampaignModal;
