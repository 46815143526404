import { ReactNode } from "react"
import {
  StepButton,
  StepLabel,
  Step,
  Stepper,
  StepIconProps,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import useStyles, { StepButtonsContainer, StyledLabel } from "./styles"
import StepButtons, { StepButtonsProps } from "./StepButtons"
import { clsx } from "modules/Utils"
import { isEqual } from "lodash"

interface CustomStepperProps {
  className?: string
  onStepsErrors?: number[]
  activeStep: number
  steps: {
    description: string
    name: string
    onClickHandler?: (step: string) => () => void
  }[]
  skipped?: number[]
  optionalSteps?: number[]
  children?: (boolean | JSX.Element | undefined)[] | boolean | JSX.Element
  stepButtonsProps?: StepButtonsProps
  showStepButtons?: boolean,
  stepperButtonAmendments?: JSX.Element
}

const getStepWithError = (step: number, onStepsErrors?: number[]) => onStepsErrors?.includes(step)
const getOptionalStep = (step: number, optionalSteps?: number[]) => optionalSteps?.includes(step)
const getSkippedStep = (step: number, skipped?: number[]) => skipped?.includes(step)
const getRootClassName = (args: {
  classes: Record<string, string>
  isActive: boolean
  isCompleted: boolean
}) => {
  const { classes, isActive, isCompleted } = args

  if (isCompleted) return classes.completed
  if (isActive) return classes.active
  return classes.unactive
}

const CustomStepper = ({
  className = '',
  onStepsErrors = [],
  activeStep,
  steps,
  skipped,
  optionalSteps,
  children,
  stepButtonsProps,
  showStepButtons = true,
  stepperButtonAmendments
}: CustomStepperProps) => {
  const theme = useTheme()
  const hideStepIcon = useMediaQuery(theme.breakpoints.down(601))
  const classes = useStyles()

  return (
    <>
      <Grid 
        className={clsx(
          className,
          'customStepperComponent',
          classes.stepperMainContainer
        )}
      >
        <Stepper {...{ activeStep }} nonLinear className={classes.stepper}>
          {steps.map(({ description, name, onClickHandler }, index: number) => {
            const errorStep = getStepWithError(index, onStepsErrors)
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: ReactNode
              error?: boolean
              StepIconProps?: StepIconProps
            } = {}
            const isCompleted = index < activeStep
            const isActive = isEqual(index, activeStep)
            const stepIcon = hideStepIcon ? <Grid /> : index + 1

            labelProps.StepIconProps = {
              icon: stepIcon,
              active: isActive,
              classes: {
                root: getRootClassName({
                  classes,
                  isActive,
                  isCompleted,
                }),
                active: classes.empty,
              },
              completed: isCompleted,
            }

            if (getOptionalStep(index, optionalSteps)) {
              labelProps.optional = (
                <StyledLabel
                  fontSize={16}
                  fontWeight={600}
                  className={clsx(
                    !errorStep && classes.textColorSecondary,
                    errorStep && classes.errorColor
                  )}
                >
                  Optional
                </StyledLabel>
              )
            }

            if (errorStep) {
              labelProps.StepIconProps = {
                icon: stepIcon,
                active: false,
                classes: {
                  root: classes.errorColor,
                },
              }
            }

            if (getSkippedStep(index, skipped)) {
              stepProps.completed = false
            }

            return (
              <Step {...stepProps} key={index}>
                <StepButton
                  className='stepButtonCustomStepper'
                  disableTouchRipple
                  onClick={onClickHandler && onClickHandler(name)}
                >
                  <StepLabel
                    {...labelProps}
                    classes={{
                      root: classes.stepLabelRoot,
                      label: classes.text,
                    }}
                    className={clsx(
                      isActive && !errorStep && classes.textActive,
                      !isActive && !errorStep && classes.textColorPrimary,
                      errorStep && classes.errorColor
                    )}
                  >
                    {description}
                  </StepLabel>
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
      </Grid>
      {children}
      {showStepButtons &&
        <StepButtonsContainer className="stepButtonsContainer">
          {stepperButtonAmendments}
          <StepButtons {...stepButtonsProps} />
        </StepButtonsContainer>
      }
    </>
  )
}

export default CustomStepper
