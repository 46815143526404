import { Body2 } from "components/shareable/Typography";
import Colors from "modules/Colors";
import { ReactComponent as MarketingGray } from "assets/images/marketingIcon-gray.svg";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import { CSSProperties } from "react";
import { styx, clsx } from "modules/Utils";

interface EmptyStateProps {
    className?: string;
    style?: CSSProperties;
    isFiltering?: boolean;
    buttonAction?: () => void;
    primaryText?: string;
    buttonText?: string;
}

const TableEmptyState = ({
    className,
    style,
    isFiltering = false,
    buttonAction,
    primaryText = "No campaigns found.",
    buttonText = "Create a Campaign",
}: EmptyStateProps) => {
    return (
        <div
            className={clsx('tableEmptyStateComponent', className)}
            style={styx(sty.tableEmptyStateComponent, style)}
        >
            <div className="flex flex-col items-center gap-2">
                {isFiltering ?
                    <>
                        <MarketingGray style={{ height: 18, width: 18 }} />
                        <Body2 color={Colors.greyDark}>No campaigns match your filters</Body2>
                        {/* TODO: add a button to clear filters here */}
                    </>
                    :
                    <>
                        <Body2 color={Colors.greyDark}>{primaryText}</Body2>
                        {buttonAction && buttonText &&
                            <ButtonSymphony
                                className="tableEmptyStateButton"
                                onClick={buttonAction}
                            >
                                {buttonText}
                            </ButtonSymphony>
                        }
                    </>
                }
            </div>
        </div>
    );
};

const sty: Record<string, CSSProperties> = {
    tableEmptyStateComponent: {
        background: Colors.greyLight,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        gap: 8,
        borderRadius: 8,
        minHeight: 250,
    }
}

export default TableEmptyState;