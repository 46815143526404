import { useContext } from "react";
import {
  BlurContainer,
  EmailCellContainer,
  EmailContainer,
  NameContainer,
  NewFanContainer,
  UserDataContainer,
  UserInformationContainer,
} from "./styles";
import emptyUser from "assets/images/user.svg";
import { DefaultImage } from '../../styles';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

interface Props {
  email?: string;
  cellphone?: string;
  name?: string;
  lastname?: string;
  imageUrl?: string;
  isNew: boolean;
  newFollower?: boolean;
  newFollowerPlatformName?: string;
  isProUser?: boolean;
}

const ContactInfoCell = ({
  email,
  cellphone,
  name,
  lastname,
  imageUrl,
  isNew,
  newFollower,
  newFollowerPlatformName,
  isProUser: isProUserOverride
}: Props) => {
  const { isProBrand } = useContext(CurrentBrandContext)

  const formattedName = `${name || ""} ${lastname || ""}`.trim() || "-";

  let formattedEmail = "Email not shared";
  if (cellphone) {
    formattedEmail = cellphone;
  } else if (email) {
    formattedEmail = email;
  }

  return (
    <EmailCellContainer>
      <DefaultImage
        src={imageUrl || emptyUser}
        height="24px"
        width="24px"
        borderRadius="50%"
        backgroundColor={imageUrl ? "none" : "#B2B2B224"}
      />
      <UserDataContainer>
        <EmailContainer>{formattedEmail}</EmailContainer>
        <UserInformationContainer>
          {isProBrand || isProUserOverride ?
            <NameContainer>{formattedName}</NameContainer>
            :
            <BlurContainer>xxxxxx xxxxxx xxx</BlurContainer>
          }
          {isNew && <NewFanContainer>NEW FAN</NewFanContainer>}
          {newFollower && newFollowerPlatformName && <NewFanContainer>FOLLOWED YOU ON {newFollowerPlatformName.toUpperCase()}</NewFanContainer>}
        </UserInformationContainer>
      </UserDataContainer>
    </EmailCellContainer>
  );
};

export default ContactInfoCell;
