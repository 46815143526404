import Colors from "modules/Colors";
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';

export const useStyles = makeStyles(() => ({
  videoIconStroke: {
    height: '16px',
    width: '16px',
    '& path': {
      stroke: Colors.textColorWhite,
    },
  },
}));

interface VideoContainerProps {
  thumbnail: string;
  height?: string;
}

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 472px;
  border-radius: 16px;
  padding: 16px;
  background-image: url(${({ thumbnail }: VideoContainerProps) => thumbnail});
  background-size: cover;
  ${MEDIA_BREAKPOINTS.mobileView} {
    height: 216px;
  }
`;

export const VideoCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${Colors.DARK_VIDEO_HOVER_LAYOUT};
  border-radius: inherit;
  left: 0;
  top: 0;
`;

export const HeadlineContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  bottom: 28px;
  max-width: 90%;
`;
