import { ChangeEvent, useState } from 'react';
import { TextField } from '@material-ui/core';
import HeaderModal from '../../../HeaderModal';
import { useStyles } from '../../style';
import ErrorBanner from '../ErrorBanner';
import { checkIsValidUrl } from 'modules/Utils';
import ButtonSymphony from 'components/shareable/ButtonSymphony';

interface Props {
  loading: boolean;
  error: boolean;
  closeModal: () => void;
  handleGoBack: () => void;
  confirmDuplicateLinkClicks: (link: string) => void;
}

const DuplicateLinkClicksConfirmView = ({
  loading,
  error,
  closeModal,
  handleGoBack,
  confirmDuplicateLinkClicks,
}: Props) => {
  const classes = useStyles()
  const [isValidUrl, setIsValidUrl] = useState<boolean>(false)
  const [link, setLink] = useState("")

  const handleChangeLink = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLink(value)
    setIsValidUrl(checkIsValidUrl(value))
  }

  const handleConfirm = () => confirmDuplicateLinkClicks(link)

  return (
    <>
      <HeaderModal
        closeModal={closeModal}
        subtitle="Enter your link."
        body="This can be any type of link - increase traffic to your merch page, drive ticket sales, or grow traffic anywhere else."
        title="Duplicate campaign"
        handleGoBack={handleGoBack}
      />
      <TextField
        placeholder="e.g. https://link.com"
        InputProps={{ classes: { root: classes.textField } }}
        FormHelperTextProps={{
          classes: { root: classes.formHelperText },
        }}
        className={classes.textField}
        variant="outlined"
        fullWidth
        type="url"
        onChange={handleChangeLink}
      />
      {!loading && error && (
        <ErrorBanner errorText="An error occurred when duplicating your campaign... Please try again." />
      )}
      <ButtonSymphony
        className="buttonDuplicateLinkClicksConfirmView"
        disabled={!link || link.trim().length === 0 || !isValidUrl}
        onClick={!loading ? handleConfirm : undefined}
        isLoading={loading}
      >
        Next
      </ButtonSymphony>
    </>
  )
}

export default DuplicateLinkClicksConfirmView;
