import Axios from "helpers/Interceptor"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const getWebsiteData = async (
    brandSlug: string,
    contentSlug?: string,
    startDate?: string | null,
    endDate?: string | null
) => {
    try {
        var url = contentSlug ?
            `/dashboard/brand/${brandSlug}/website/${contentSlug}`
            :
            `/dashboard/brand/${brandSlug}/website/overall`;

        const userTimezone = dayjs.tz.guess()

        const res = await Axios.get(url, {
            params: {
                startDate,
                endDate,
                timezone: userTimezone
            }
        });
        if (res.data?.data) {
            return res.data.data;
        }
    } catch (error) {
        console.log("Error on fetching website data: ", error);
    }
}

export default getWebsiteData;