import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';
import { Card, CardProps, Container, ContainerProps, StyledLabel } from 'styles/shared';
import { CampaignOption } from '../utils';
import SymphonyRadioButton from 'components/SymphonyRadioButton';

interface CampaignSelectionProps {
    options: CampaignOption[];
    handleChange: (id: string) => void;
    selectedValue: CampaignOption;
}

const CampaignSelection = ({ options, handleChange, selectedValue }: CampaignSelectionProps) => {
    return (
        <Container flexDirection={"column"} gap="16px" display='flex'>
            {options.map((option) => (
                <CampaignCard
                    icon={option.icon}
                    id={option.id}
                    key={option.label}
                    label={option.label}
                    description={option.description}
                    isSelected={option.id === selectedValue.id}
                    recommendation={option.recommendation}
                    handleChange={handleChange}
                    availabilityNote={option.availabilityNote}
                    available={option.available}
                />
            ))}
        </Container>
    );
};

interface CampaignCardProps {
    id: string;
    label: string;
    description: string;
    isSelected: boolean;
    handleChange: (id: string) => void;
    recommendation: string;
    icon: string;
    availabilityNote?: string;
    available?: boolean;
}

const CampaignCard = ({
    id,
    label,
    description,
    isSelected,
    handleChange,
    recommendation,
    icon,
    availabilityNote,
    available = true
}: CampaignCardProps) => {
    return (
        <SelectableCard selected={isSelected}>
            <FormControlLabel
                value={label}
                disabled={!available}
                control={<SymphonyRadioButton checked={isSelected} onChange={() => handleChange(id)} />}
                label={
                    <Container display='flex' flexDirection='column' alignItems='center' gap='12px'>
                        <Container gap='8px' display='flex' flexDirection='row' alignItems='center' marginLeft='8px'>
                            <img className="w-10 h-10" src={icon} />
                            <Container display='flex' flexDirection='column' alignItems='flex-start' gap='4px'>
                                <StyledLabel fontWeight={500} fontSize={16}>
                                    {label}
                                </StyledLabel>
                                {availabilityNote && <StyledLabel color="#FFA500" fontSize={14} >
                                    {availabilityNote}
                                </StyledLabel>}
                                <StyledLabel color="#6B7280" fontSize={14}>
                                    {description}
                                </StyledLabel>

                            </Container>

                        </Container>
                    </Container>
                }
            />
        </SelectableCard>
    );
};

const StyledLabelContainer = styled(Container) <ContainerProps>`
  flex-direction: column;
`;

const SelectableCard = styled(Card) <CardProps & { selected: boolean }>`
  background-color: ${({ selected }) => (selected ? '#F5EBFF' : '#ffffff')};
  transition: background-color 0.3s;
  border-radius: 12px;
  border: 1px solid #EDECF2;

  &:hover {
    background-color: #F5EBFF;
  }
`;

export default CampaignSelection;

