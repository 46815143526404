import HeaderModal from "../../../HeaderModal"
import {
  CardInfo,
  CardTitle,
  DuplicateCard,
  DuplicateOptionsContainer,
} from "../../style"
import { ReactComponent as SuperboostIcon } from "assets/images/ui/superboost.svg"

interface Props {
  closeModal: () => void
}

const MainView = ({
  closeModal
}: Props) => {
  return (
    <>
      <HeaderModal
        HeaderIcon={SuperboostIcon}
        title="Superboost Instagram Post"
        subtitle={`What do you want to grow?`}
        closeModal={closeModal}
      />
      <DuplicateOptionsContainer>
        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Instagram Post Engagement</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>

        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Instagram Profile Visits</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>
        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Grow Awareness on a Song / Project</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>
        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Website traffic</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>

      </DuplicateOptionsContainer>
      {/* {!loading && error && (
        <div className="order-2">
          <ErrorBanner errorText="A problem ocurred when duplicating your campaign... Please try again." />
        </div>
      )} */}
    </>
  )
}

export default MainView
