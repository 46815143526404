import { ChangeEvent, useContext } from "react"
import useStyles, {
  BrandIconContainer,
  IconContainer,
  InputAdornmentContainer,
  MobileContainer,
  StyledImage,
} from "./styled"
import { TextField, InputAdornment, Button } from "@material-ui/core"
import { ReactComponent as FacebookIcon } from "assets/images/facebook-rounded-icon.svg"
import { ReactComponent as EditIcon } from "assets/images/edit.svg"
import Lottie from "react-lottie"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import { Body2 } from "components/shareable/Typography"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { Steps } from '../../utils'
import Colors from "modules/Colors"

const DEFAULT_MAX_LENGTH = 524288

const getEndAdornmentButtonLabel = (data: {
  initialValue?: string | null
  value?: string
  connected?: boolean
  error?: boolean
  showButtonLabelAsSaved?: boolean
  stepName?: Steps
}) => {
  const { initialValue, value, connected, error, showButtonLabelAsSaved, stepName } = data

  if (showButtonLabelAsSaved) {
    if (error || initialValue !== value) return "Save"
    return "Saved"
  }

  if (stepName === Steps.FACEBOOK_PIXEL) {
    if (error) return "Use another pixel"
  }

  if (connected) return "Connected"
  if (error) return "Re-connect"
  return "Connect"
}

interface EndAdornmentProps {
  initialValue?: string | null
  disabled?: boolean
  loading?: boolean
  error?: boolean
  connected?: boolean
  value?: string
  showButtonLabelAsSaved?: boolean
  stepName?: Steps
  customButtonLabel?: string
  onClickConnectButton?: () => void
}

const EndAdornment = ({
  initialValue,
  loading,
  disabled,
  error,
  connected,
  value,
  showButtonLabelAsSaved,
  stepName,
  customButtonLabel,
  onClickConnectButton,
}: EndAdornmentProps) => {
  const classes = useStyles({
    error,
    connected,
    value,
    initialValue,
    showButtonLabelAsSaved,
    customButtonLabel,
  })
  const buttonLabel = getEndAdornmentButtonLabel({
    initialValue,
    value,
    connected,
    error,
    showButtonLabelAsSaved,
    stepName,
  })

  if (loading) {
    return (
      <Lottie
        isClickToPauseDisabled
        height={24}
        width={24}
        options={{
          loop: true,
          autoplay: true,
          animationData: SymphonyLoadingLottie,
        }}
      />
    )
  }

  return (
    <Button
      disabled={disabled}
      classes={{ root: classes.button }}
      onClick={onClickConnectButton}
      disableRipple
    >
      {customButtonLabel || buttonLabel}
      {Boolean(buttonLabel === "Connected" || buttonLabel === "Saved") && (
        <EditIcon />
      )}
    </Button>
  )
}

interface Props {
  initialValue?: string | null
  value: string
  error?: boolean
  loading?: boolean
  connected?: boolean
  maxLength?: number
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  showButtonLabelAsSaved?: boolean
  showBrandIcon?: boolean
  stepName?: Steps
  warning?: boolean
  id?: string
  padding?: string
  customButtonLabel?: string
  inputError?: boolean
  customIcon?: JSX.Element
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClickConnectButton?: () => void
}

const StyledTextField = ({
  initialValue,
  value,
  error,
  loading,
  maxLength,
  connected,
  placeholder,
  disabled,
  readOnly,
  showButtonLabelAsSaved,
  showBrandIcon,
  id,
  warning,
  stepName,
  padding,
  customButtonLabel,
  inputError,
  customIcon,
  onChange,
  onClickConnectButton,
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const { image } = currentBrand || {}
  const classes = useStyles({
    id,
    error,
    warning,
    connected,
    value,
    showButtonLabelAsSaved,
    endAdornment: Boolean(onClickConnectButton),
  })

  return (
    <TextField
      {...{ onChange, placeholder, disabled }}
      value={id ? "" : value}
      variant="outlined"
      fullWidth
      classes={{ root: classes.root }}
      inputProps={{ maxLength: maxLength || DEFAULT_MAX_LENGTH }}
      InputProps={{
        readOnly,
        placeholder,
        classes: {
          input: classes.input,
        },
        style: { padding },
        startAdornment: (
          <InputAdornment position="start">
            <InputAdornmentContainer>
              {Boolean(!showBrandIcon || (showBrandIcon && (!image || !connected))) && (
                customIcon || (
                  <IconContainer>
                    <FacebookIcon width={16} height={16} />
                  </IconContainer>
                ))}
              <BrandIconContainer>
                {(showBrandIcon && connected && image) &&
                  <StyledImage alt="brand-img" src={image} />
                }
                {id && (
                  <MobileContainer>
                    <div>
                      <Body2 color={Colors.textColor}>
                        {value}
                      </Body2>
                    </div>
                    <div>
                      <Body2>ID: {id}</Body2>
                    </div>
                  </MobileContainer>
                )}
              </BrandIconContainer>
            </InputAdornmentContainer>
          </InputAdornment>
        ),
        endAdornment: onClickConnectButton ? (
          <InputAdornment
            position="end"
            disablePointerEvents={loading || disabled || inputError}
          >
            <EndAdornment
              {...{
                disabled: inputError,
                initialValue,
                loading,
                error,
                connected,
                value,
                showButtonLabelAsSaved,
                onClickConnectButton,
                stepName,
                customButtonLabel,
              }}
            />
          </InputAdornment>
        ) : null,
      }}
    />
  )
}

export default StyledTextField
