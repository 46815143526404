import styled from "styled-components";


export const VideoHolder = styled.div`
    width: 100%;
    border-radius: 12px;    
    overflow: hidden;
`


export const VideoItemContainer = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin: 8px 0;
    width: 100%;  // Make width 100% to allow flex container to control the width
    // @media (min-width: 1000px) {
    //     width: 250px;
    // }
    // @media (min-width: 768px) {
    //     width: 300px;
    // }

    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: #F3F4F6;
    }
    `;

export const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 12px;  // Optional: for rounded corners
    margin-right: 8px;

    @media (min-width: 768px) {
        height: 150px;
    }
`;

export const VideoInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h2`
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 4px;
`;

export const Description = styled.p`
    font-size: 12px;
    margin: 0;
`;


