import Logger from 'Logger';
import Axios from 'helpers/Interceptor'
import { WebsiteType } from "pages/post-auth/Website/types";
import { RecordSelectorType } from 'types/global';
import * as Sentry from "@sentry/react";

/** Shape of data required to publish brand content */
export type PublishBrandContent = {
  /** Type of website being published */
  type: WebsiteType,
  /** Metadata containing website content and configuration */
  content_metadata: any,
  /** ID of the brand this content belongs to */
  brand_id: number,
  /** URL slug for the published content */
  slug: string,
  /** Optional name for the content */
  name?: string,
  /** Full URL where content will be published */
  url: string,
  /** Optional ID of existing content being updated */
  id?: number
}

/** 
 * Publishes brand content to create/update a website
 * @param brandContent - Content and configuration for the website
 * @returns Response data from the publish API
 */
export const publishBrandContent = async (brandContent: PublishBrandContent) => {
  try {
    const response = await Axios.post('/brand-content/publish/website', {
      brandContent,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Updates external service links for brand content
 * @param brandSlug - Slug identifier for the brand
 * @param recordSlug - Slug identifier for the specific content
 * @param links - Object containing service name and URL to link
 * @returns Response data from the update API
 */
export const updateBrandContentExternalLinks = async (
  brandSlug: string, 
  recordSlug: string, 
  links: { serviceName: string, url: string }
) => {
  try {
    const results = await Axios.post(`/brand-content/${brandSlug}/${recordSlug}/link`, links)

    return results.data
  } catch (error) {
    throw error
  }
}

/**
 * Searches Spotify for music content matching search text
 * @param value - Search text to query Spotify
 * @param limit - Maximum number of results to return (default: 10)
 * @returns Spotify search results if successful
 */
export const searchSpotifyByText = async (value: string, limit: number = 10) => {
  try {
    const url = "spotify/search/music";
    const encodedValue = encodeURIComponent(value)
    const options = { params: { q: encodedValue, limit: limit } };
    const response = await Axios.get(url, options);
    if (response?.data) return response.data
  } catch (e: unknown) {
    console.error("Error on searchSpotifyByText component: ", e);
  }
}

/**
 * Fetches available markets for a piece of content
 * @param contentSlug - Slug identifier for the content
 * @returns Array of available markets or empty array if none found
 */
export const fetchMarkets = async (contentSlug: string) => {
  try {
    const response = await Axios.post(`/brand-content/available-markets/${contentSlug}`);
    if (!response?.data?.data) return [];
    return response.data.data;
  } catch (error) {
    Logger.error(error);
    console.error("Error on fetchMarkets component: ", error);
  }
};

/**
 * Gets detailed record information from Spotify
 * @param spotifyId - Spotify ID of the track/record
 * @param recordType - Type of record being requested
 * @param brandId - Optional ID of brand to associate with record
 * @returns Formatted record details including metadata and URLs
 */
export async function getRecordDetails(spotifyId: string, recordType: RecordSelectorType, brandId?: number) {
  Sentry.setContext('getRecordDetails', { brandId: brandId, spotifyId: 'spotifyId' });

  try {
    const response = await Axios.get(`/brand-content/${brandId}/track/${spotifyId}`, {
      params: {
        type: recordType
      }
    });

    const data = response?.data?.data;

    if (data) {
      const { content_metadata, slug, name, url } = data;
      const spotifyReleaseType = content_metadata.type === "record"
        ? 'Single'
        : (content_metadata.spotify_release_type || content_metadata.type || 'Release');

      return {
        full_info: true,
        ...content_metadata,
        slug,
        name,
        url,
        thumbnail_url: content_metadata.thumbnail_url,
        spotify_release_type: spotifyReleaseType
      };
    }
  } catch (error) {
    Logger.error(error);
    console.error("Error on getRecordDetails component: ", error);
  }
}

/**
 * Retrieves content for a specific brand and content combination
 * @param brandSlug - Slug identifier for the brand
 * @param contentSlug - Slug identifier for the specific content
 * @returns Brand content data if found, null otherwise
 */
export async function getBrandContent(brandSlug: string | undefined, contentSlug: string | undefined) {
    if (!brandSlug || !contentSlug) {
        return null;
    }

    try {
        const getData = await Axios.get(`/brand-content/${brandSlug}/${contentSlug}`)
        if (getData.data?.data) {
            return getData.data.data
        }
    } catch (error) {
        console.log("Error getting Brand content", error)
        return null
    }
}