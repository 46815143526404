import { toast } from "react-toastify";
import SubscriptionStatusIndicator from "./SubscriptionStatusIndicator";
import { ToastOptions } from "react-toastify";
import Axios from "helpers/Interceptor";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import SwitchSymphony from "components/shareable/SwitchSymphony";
import DevSubscriptionSpoofer from "./DevSubscriptionSpoofer";
import ButtonSymphony from "components/shareable/ButtonSymphony";

export const TOAST_OPTIONS: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

interface Props {
    isCollapsed: boolean;
}

let CREATE_STRIPE_TRIAL = true;

const AdminOptions = ({
    isCollapsed
}: Props) => {
    const { isSymphonyAdmin: isAdmin } = useContext(CurrentUserContext)
    const { currentBrand, isPaidBrand } = useContext(CurrentBrandContext)
    const history = useHistory()

    const [loadingPro, setLoadingPro] = useState(false)

    if (!currentBrand || !isAdmin) {
        return null
    }

    const {
        subscription,
        slug: brandSlug
    } = currentBrand

    const turnUserPro = async () => {
        setLoadingPro(true);
        try {
            if (isAdmin) {
                await Axios.get(`/subscription/brand/${brandSlug}/admin/pro`);
                toast.success("User subscription updated.", TOAST_OPTIONS);
            } else {
                throw new Error("User isn't admin");
            }
        } catch (e) {
            toast.error("Error updating user subscription.", TOAST_OPTIONS);
            console.error(e);
        }
        setLoadingPro(false);
    }

    const createSubscriptionClicker = () => {
        const isActiveSubscription = subscription?.status === 'active';
        const isForcedByAdmin = subscription?.details?.forced;
        if (subscription && isActiveSubscription && isForcedByAdmin) {
            return (
                <SubscriptionStatusIndicator
                    text="PRO via ADMIN"
                    style={{ marginBottom: 8 }}
                    handleOpenModal={() => {
                        if (subscription.details?.viewSubscriptionUrl) {
                            window.open(subscription.details?.viewSubscriptionUrl!, "_blank")
                        }
                    }}
                />
            )
        }
    }

    return (
        <>
            {createSubscriptionClicker()}
            {isAdmin &&
                <div className="adminOptionsContainer flex flex-row  items-center mr-auto px-4 w-full justify-start">
                    {!isCollapsed && (isPaidBrand ? (
                        <ButtonSymphony
                            className="detailsAdminOptions"
                            variant="outlined"
                            color="white"
                            width="100%"
                            onClick={() => history.push('/settings?tab=BILLING')}
                            size="small"
                        >
                            💰 Details
                        </ButtonSymphony>
                    ) : (
                        <DevSubscriptionSpoofer createStripeTrial={CREATE_STRIPE_TRIAL} />
                    ))}
                </div>
            }
        </>
    );
}

export default AdminOptions;