import Axios from "helpers/Interceptor";
import Logger from "Logger";
import { CurrentBrand, PaymentMethods, CurrencyCodesType, SpendBudgetData } from "types/global";
import * as Sentry from "@sentry/react";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { PlanType } from "modules/Const";

/**
 * Fetches the brand's subscription usage data including spend limits, billing dates, and user seats
 * 
 * @param params - Object containing brandSlug and currencyCode
 * @returns Promise resolving to SpendBudgetData or null if request fails
 */
export const getBrandCurrentSubscriptionData = async ({
  brandSlug,
  currencyCode
}: {
  brandSlug: string;
  currencyCode: CurrencyCodesType;
}): Promise<SpendBudgetData | null> => {
  try {
    const query = `currency=${currencyCode}`;
    const response = await Axios.get(`/subscription/brand/${brandSlug}/usage/campaigns?${query}`);
    return response.data.data;
  } catch (error) {
    Logger.error(
      "Error when retrieving spend budget data in subscription service: ",
      error
    );
    return null;
  }
};

/**
 * Generates a URL for the subscription cancellation portal
 * 
 * @param brandSlug - The brand's unique identifier
 * @returns Promise resolving to the portal URL
 */
export const getCancelSubscriptionPortalUrl = async (brandSlug: string): Promise<string> => {
  try {
    const { data } = await Axios.post(`/subscription/brand/${brandSlug}/get-cancel-subscription-portal-link`);
    return data.data.url;
  } catch (error) {
    Logger.error("Error getting cancel subscription portal URL: ", error);
    throw error;
  }
};

/**
 * Resumes a cancelled subscription
 * 
 * @param brandSlug - The brand's unique identifier
 * @returns Promise resolving to the API response
 */
export const resumeSubscription = async (brandSlug: string) => {
  try {
    const response = await Axios.get(`/subscription/brand/${brandSlug}/resume-subscription`);
    return response.data;
  } catch (error) {
    Logger.error("Error resuming subscription: ", error);
    throw error;
  }
};

/**
 * Cancels an active subscription
 * 
 * @param brandSlug - The brand's unique identifier
 * @returns Promise resolving to an object containing:
 *   - success {boolean} - Whether the cancellation was successful
 *   - status {string} - The status of the subscription after cancellation
 *   - cancelDate {string} - The date when the subscription will be cancelled, in YYYY-MM-DD format
 */
export const cancelSubscription = async (brandSlug: string): Promise<{
  success: boolean;
  status: string;
  cancelDate: string;
}> => {
  try {
    const response = await Axios.post(`/subscription/brand/${brandSlug}/cancel-subscription`);
    return response.data;
  } catch (error) {
    Logger.error("Error cancelling subscription: ", error);
    throw error;
  }
};

/**
 * Generates a URL for the Stripe Customer Portal where users can manage their subscription settings
 * including payment methods, billing information, and subscription details
 * 
 * @param brandSlug - The brand's unique identifier
 * @returns Promise resolving to the Stripe Customer Portal URL
 * @throws Error if unable to generate portal URL
 */
export const getStripeSubscriptionPortalUrl = async (brandSlug: string): Promise<string> => {
  try {
    const response = await Axios.post(`/subscription/brand/${brandSlug}/settings`);
    return response.data.data.url;
  } catch (error) {
    Logger.error("Error getting subscription settings URL: ", error);
    throw error;
  }
};

/**
 * Redirects user to the upgrade plan checkout page to upgrade their account to Pro status.
 * This endpoint handles both upgrading the user's account to Pro and collecting payment details.
 * 
 * @param currentBrand - The current brand object containing brand details
 * @param data - Checkout data including campaign and billing details
 * @returns Object with error flag if the request fails
 */
export const createCampaignAndUpgradePlanCheckout = async (
  currentBrand: CurrentBrand,
  data: Record<string, unknown>,
) => {
  try {
    const response = await Axios.post(
      `/campaign/brand/${currentBrand.id}/checkout`,
      data
    );

    window.location = response.data.data.url;
  } catch (error) {
    Sentry.captureException(error);
    console.error("error: ", error);
    return { error: true }
  }
};

/**
 * Redirects user to the one-off campaign checkout page.
 * This endpoint is specifically for non-Pro users to create and pay for individual campaigns
 * without requiring a Pro subscription.
 * 
 * @param currentBrand - The current brand object containing brand details
 * @param data - Campaign data including budget, targeting and content details
 * @returns Object with error flag if the request fails
 */
export const redirectToOneOffCampaignCheckout = async (
  currentBrand: CurrentBrand,
  data: Record<string, unknown>,
) => {
  try {
    const response = await Axios.post(
      `/campaign/brand/${currentBrand.id}/checkout/one-off`,
      data
    );

    window.location = response.data.data.url;
  } catch (error) {
    Sentry.captureException(error);
    console.error("error: ", error);
    return { error: true }
  }
};

export interface UpgradePlanAndRedirectToCheckoutParams {
  recurring_interval: BillingCycle,
  payment_method: PaymentMethods.STRIPE,
  checkout_source?: 'onboarding-signup',
  plan_type: PlanType,
  brandSlug: string
}

/**
 * Upgrades a brand's plan and redirects to the Stripe checkout page.
 * This function handles the upgrade process for brand subscriptions, including trial periods.
 * 
 * @param {Object} params - The parameters for upgrading the plan
 * @param {BillingCycle} params.recurring_interval - The billing cycle (monthly/annual) for the subscription
 * @param {'onboarding-signup'} [params.checkout_source] - Source of the checkout, e.g. onboarding flow
 * @param {PlanType} [params.plan_type] - The plan type being upgraded to (Pro, Team etc)
 * @param {string|null} [params.brandSlug] - The brand's unique slug identifier
 * 
 * @returns {Promise<void>} Redirects to Stripe checkout on success, logs error on failure
 */
export const upgradePlanAndRedirectToCheckout = async ({
  recurring_interval,
  checkout_source,
  plan_type,
  brandSlug,
}: UpgradePlanAndRedirectToCheckoutParams) => {
  const data = {
    recurring_interval,
    checkout_source,
    plan_type,
    brandSlug,
  }
  try {
    const res = await Axios.post(`/subscription/brand/${brandSlug}/checkout`, data)
    window.location = res.data.data.url
  } catch (error) {
    Sentry.captureException(error)
    console.error("Brand Subscription checkout failed: ", error)
  }
}

export interface ChangeSubscriptionPlanParams {
  brandSlug: string,
  recurring_interval: BillingCycle,
  checkout_source: string,
  plan_type: PlanType
}

/**
 * Changes a brand's subscription plan.
 * This function handles changing between different subscription plans for a brand.
 * 
 * @param {Object} params - The parameters for changing the subscription plan
 * @param {string} params.brandSlug - The brand's unique slug identifier
 * @param {BillingCycle} params.recurring_interval - The billing cycle (monthly/annual) for the subscription
 * @param {string} params.checkout_source - Source of the checkout
 * @param {PlanType} params.plan_type - The plan type to change to
 * @returns {Promise<{tier: string, success: boolean}>} Returns tier and success status if successful, throws error if not
 * @throws {Error} Throws error if request fails
 */
export const changeSubscriptionPlan = async ({
  brandSlug,
  recurring_interval,
  checkout_source,
  plan_type,
}: ChangeSubscriptionPlanParams): Promise<{ tier: string, success: boolean }> => {
  try {
    const data = {
      recurring_interval,
      checkout_source,
      plan_type,
    }

    const response = await Axios.post(`/subscription/brand/${brandSlug}/change-plan`, data)
    return {
      tier: response.data.data.tier,
      success: true
    }
  } catch (error) {
    Sentry.captureException(error)
    console.error("Failed to change subscription plan: ", error)
    throw new Error("Failed to change subscription plan")
  }
}

/**
 * Redeems a coupon on cancellation for a brand's subscription.
 * This function handles applying a discount coupon when a user attempts to cancel.
 * 
 * @param {string} brandSlug - The brand's unique slug identifier
 * @returns {Promise<void>} Returns void if successful, throws error if not
 * @throws {Error} Throws error if request fails
 */
export const redeemCouponOnCancellation = async (brandSlug: string): Promise<void> => {
  try {
    await Axios.post(`/subscription/brand/${brandSlug}/redeem-coupon-on-cancelation`);
  } catch (error) {
    Sentry.captureException(error)
    console.error("Failed to redeem cancellation coupon: ", error)
    throw new Error("Failed to redeem cancellation coupon")
  }
}
