import { CSSProperties, useState } from 'react'
import { CreatedCampaign, Website } from 'types/global'
import { ReactComponent as LinkedIcon } from 'assets/images/linked.svg'
import LinkButton from './LinkButton'
import DialogPaper from './DialogPaper'
import HeaderModal from 'components/connect/connectModals/Spotify/Components/HeaderModal'
import CampaignCard from 'pages/post-auth/MarketingPage/Components/CampaignCard'
import { daysCalculator } from 'helpers/General'
import PresaveMiniature from 'pages/post-auth/MarketingPage/PreSave/Components/PresaveMiniature'

interface LinkedCampaignModalProps {
  show: boolean,
  websiteName: string,
  onClose: () => void
  linkedCampaigns: Array<CreatedCampaign>
  thumbnailSrc: string
  website: Website
}

const LinkedCampaignModal = ({
  show,
  onClose,
  linkedCampaigns,
  websiteName,
  thumbnailSrc,
  website,
}: LinkedCampaignModalProps) => {
  const subtitle = `These marketing campaigns were submitted for your website: "${websiteName}"`
  return (
    <DialogPaper open={show} onClose={onClose}>
      <>
        <HeaderModal
          HeaderIcon={
            <PresaveMiniature
              image={thumbnailSrc}
              style={website}
            />
          }
          title="Marketing campaigns"
          subtitle={subtitle}
          closeModal={onClose}
        />
        {linkedCampaigns.map((campaign) => (
          <CampaignCard
            campaign={campaign}
            daysCalculator={daysCalculator}
          />
        ))}
      </>
    </DialogPaper>
  )
}

interface LinkedCampaignsBannerProps {
  linkedCampaigns: Array<CreatedCampaign>
  website: Website
  websiteName: string
  thumbnailSrc: string
}

const LinkedCampaignsBanner = ({
  linkedCampaigns,
  thumbnailSrc,
  website,
  websiteName
}: LinkedCampaignsBannerProps) => {
  const message = linkedCampaigns.length + ' linked campaign' + (linkedCampaigns.length > 1 ? 's' : '')
  const [showModal, setShowModal] = useState(false)

  const onClick = () => {
    setShowModal(true)
  }

  const onClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <div
        className="LinkedCampaignsBanner-container-link-button"
        style={sty.container}
        onClick={onClick}
      >
        <LinkedIcon />
        <LinkButton text={message} underlined />
      </div>
      <LinkedCampaignModal
        show={showModal}
        websiteName={websiteName}
        onClose={onClose}
        linkedCampaigns={linkedCampaigns}
        thumbnailSrc={thumbnailSrc}
        website={website}
      />
    </>
  )
}

const sty = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  } as CSSProperties,
}

export default LinkedCampaignsBanner