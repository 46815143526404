import { WebsiteIconProps } from "./types"

const DefaultWebsiteThemeIcon: React.FC<WebsiteIconProps> = ({ width = "28", height = "40", selected }) => {

    const color = !selected ? "#707070" : "#8800FF";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 40" fill="none" style={{ minHeight: height, maxHeight: height, minWidth: width, maxWidth: width }}>
            <path fill={color} d="M6 26a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM4 33a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2ZM15 33a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-2ZM9 23a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Z" />
            <rect width="25.091" height="37.091" x="1.455" y="1.455" stroke={color} strokeWidth="1.091" rx="4.2" />
            <path fill={color} d="M6 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5Z" />
        </svg>
    )
}

export default DefaultWebsiteThemeIcon;