import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { ContainerProps } from "styles/shared";

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    width: 64,
  },
  spacedLeft: { paddingLeft: theme.spacing(2) },
  spacedTop: { marginTop: theme.spacing(2) },
}));

interface CardMetricsContainerProps {
  padding?: string,
}

interface UnlockBannerContainerProps {
  padding?: string,
}

interface TabsContainerProps {
  borderRadius?: string,
  padding?: string,
}

interface DefaultImageProps {
  borderRadius?: string,
  backgroundColor?: string,
  width: string,
  height: string,
}
export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
  marginLeft?: string;
}

export const FanbaseListContainer = styled.div`
  margin-top: 32px;
  padding-bottom: 48px;
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 24px;
`;

export const LoadingIndicatorContainer = styled.div`
  display: flex;
  margin: 32px 24px;
  flex-direction: column;
  align-items: center;
`;

export const EmptyFanbaseContainer = styled.div`
  margin-top: 32px;
  @media (max-width: 641px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const LoadingText = styled.p`
  margin-top: 4px;
  text-align: center;
`;

export const FansButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 4px;
  flex-grow: 1;
`;



export const TabsPanelsContainer = styled.div`
  padding: 0px 0px;
  height: 100%;
`;

export const MusicalNoteIcon = styled.img`
  margin: 10px auto;
`;

export const FansIcon = styled.img`
  margin: 4px auto;
`;

export const RoundedFansIconContainer = styled.div`
  display: inline-block;
  border: 1px solid #07c806;
  border-radius: 50%;
  width: 64px;
  height: 64px;
`;

export const Container = styled.div`
  width: ${({ width }: ContainerProps) => width};
  display: ${({ display }: ContainerProps) => display};
  gap: ${({ gap }: ContainerProps) => gap};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  height: ${({ height }: ContainerProps) => height};
`;

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
`;

export const MessageYourFansButton = withStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button);

export const FansTabButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 4px;
  flex-grow: 1;
`;

export const DownloadCsvButton = styled.div`
  cursor: pointer;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8800FF;
`;

export const TabsContainer = styled.div`
  border-radius: ${({ borderRadius }: TabsContainerProps) => borderRadius || "8px"};
  padding: ${({ padding }: TabsContainerProps) => padding || "16px 24px"};
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #FFFFFF;
`;

export const FansContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  background: #F5F3F6;
  width: 100%;
`;


export const CardMetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: ${({ padding }: CardMetricsContainerProps) => padding || '0'};
`;

export const DefaultImage = styled.img`
  background-color: ${({ backgroundColor }: DefaultImageProps) => backgroundColor || 'none'};
  max-width: ${({ width }: DefaultImageProps) => width};
  max-height: ${({ height }: DefaultImageProps) => height};
  min-width: ${({ width }: DefaultImageProps) => width};
  min-height: ${({ height }: DefaultImageProps) => height};
  border-radius: ${({ borderRadius }: DefaultImageProps) => borderRadius || "0"};
  object-fit: cover;
`;

export const UnlockBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }: UnlockBannerContainerProps) => padding || '0px'};
`;

export const BannerContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`

export default useStyles;
