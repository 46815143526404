import DialogPaper from "components/shareable/DialogPaper";
import { useRef, useState } from "react";
import { CloseButton, ModalFooter, Video, VideoButton, VideoContainer, VideoCover, ProgressContainer, TimeContainer, MobileVideoContainer, useStyles } from "./styles";
import { Body2, Headline1, Subtitle2 } from "components/shareable/Typography";
import SecondaryButton from "components/shareable/SecondaryButton";
import Colors from "modules/Colors";
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as PauseIcon } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import useScreen from "Hooks/useScreen";
import ProgressBar from "components/shareable/ProgressBar";

const formatTime = (time: number) => {
  return time.toLocaleString("en", {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).replace(".", ":")
}

interface Props {
  open: boolean;
  videoUrl: string;
  title: string;
  description?: string;
  titleModalOpened?: string;
  descriptionModalOpened?: string;
  showDescription?: boolean;
  buttonAction?: () => void;
  closeModal: () => void;
}

const VideoModalLandscape = ({
  open,
  videoUrl,
  title,
  description,
  titleModalOpened,
  descriptionModalOpened,
  showDescription = false,
  buttonAction,
  closeModal
}: Props) => {

  const [timeWatched, setTimeWatched] = useState(0);
  const [paused, setPaused] = useState(false);

  const classes = useStyles();
  const { mobileView } = useScreen();

  const video = useRef<HTMLVideoElement>(null);
  const duration = video?.current?.duration ? video.current.duration / 100 : 0

  const handleOnPauseUnpauseVideo = () => {
    if (video?.current) {
      paused ? video.current.play() : video.current.pause()
      setPaused(prev => !prev)
    }
  }

  const onEnded = () => {
    setTimeWatched(0)
    setPaused(true)
  }

  const onTimeUpdate = () => {
    if (!video?.current?.currentTime) {
      setTimeWatched(0)
    } else {
      setTimeWatched(video.current.currentTime / 100)
    }
  }

  const dialogStyleMobile = {
    width: "80%",
    height: "90%",
    gap: "0px",
    padding: "0px",
  }

  const dialogStyleDesktop = {
    width: "80%",
    maxWidth: "900px",
    height: "fit-content",
    maxHeight: "712px",
    gap: "0px",
    padding: "0px",
  }

  const handleClose = () => {
    setPaused(false)
    closeModal()
  }

  if (mobileView) {
    return (
      <DialogPaper
        open={open}
        dialogStyle={dialogStyleMobile}
      >
        <>
          <MobileVideoContainer>
            <CloseButton
              onClick={handleClose}
            >
              <CloseIcon className={classes.svgWhite} />
            </CloseButton>
            <VideoContainer>
              <VideoCover
                background={paused ? Colors.LIGHT_VIDEO_HOVER_LAYOUT : "transparent"}
                onClick={handleOnPauseUnpauseVideo}
              >
                <VideoButton
                  background={paused ? Colors.VIDEO_BUTTON_CONTAINER : "transparent"}
                  color={paused ? Colors.textColorWhite : "transparent"}
                >
                  {paused
                    ? <PlayIcon width="32px" color='inherit' />
                    : <PauseIcon width="32px" color='inherit' />
                  }
                </VideoButton>
                <ProgressContainer>
                  <TimeContainer>
                    <Subtitle2 color={Colors.textColorWhite}>
                      {formatTime(timeWatched)}
                    </Subtitle2>
                    <Subtitle2 color={Colors.textColorWhite}>
                      {formatTime(duration)}
                    </Subtitle2>
                  </TimeContainer>
                  <ProgressBar
                    progress={timeWatched}
                    maximumProgress={duration}
                    backgroundColor={Colors.PAPER_LIGHT_CONTAINER}
                    barColor={Colors.purpleLight}
                    fullWidth
                    squared
                  />
                </ProgressContainer>
              </VideoCover>
              <Video
                ref={video}
                src={videoUrl}
                playsInline
                autoPlay
                {...{
                  onEnded,
                  onTimeUpdate
                }}
              />
            </VideoContainer>
          </MobileVideoContainer>
          <ModalFooter
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Headline1 color={Colors.textColor}>{titleModalOpened || title}</Headline1>
            {descriptionModalOpened && showDescription && (
              <Body2 color={Colors.greyDark}>
                {descriptionModalOpened}
              </Body2>
            )}
            {buttonAction && <SecondaryButton onClick={buttonAction} text="Read step by step instructions" />}
          </ModalFooter>
        </>
      </DialogPaper>
    );
  }

  return (
    <DialogPaper
      open={open}
      dialogStyle={dialogStyleDesktop}
    >
      <>
        <VideoContainer>
          <CloseButton onClick={handleClose}>
            <CloseIcon className={classes.svgWhite} />
          </CloseButton>
          <VideoCover
            background={paused ? Colors.LIGHT_VIDEO_HOVER_LAYOUT : "transparent"}
            onClick={handleOnPauseUnpauseVideo}
          >
            <VideoButton
              background={paused ? Colors.VIDEO_BUTTON_CONTAINER : "transparent"}
              color={paused ? Colors.textColorWhite : "transparent"}
            >
              {paused
                ? <PlayIcon width="32px" color='inherit' />
                : <PauseIcon width="32px" color='inherit' />
              }
            </VideoButton>
            <ProgressContainer>
              <TimeContainer>
                <Subtitle2 color={Colors.textColorWhite}>
                  {formatTime(timeWatched)}
                </Subtitle2>
                <Subtitle2 color={Colors.textColorWhite}>
                  {formatTime(duration)}
                </Subtitle2>
              </TimeContainer>
              <ProgressBar
                progress={timeWatched}
                maximumProgress={duration}
                backgroundColor={Colors.PAPER_LIGHT_CONTAINER}
                barColor={Colors.purpleLight}
                fullWidth
                squared
              />
            </ProgressContainer>
          </VideoCover>
          <Video
            ref={video}
            src={videoUrl}
            playsInline
            autoPlay
            {...{
              onEnded,
              onTimeUpdate
            }}
          />
        </VideoContainer>
        <ModalFooter
          flexDirection="column"
          justifyContent="space-between"
        >
          <div className="flex justify-between items-center">
            <Headline1 color={Colors.textColor}>{titleModalOpened || title}</Headline1>
            {buttonAction && <SecondaryButton onClick={buttonAction} text="Read step by step instructions" />}
          </div>
          {descriptionModalOpened && showDescription && (
              <Body2 color={Colors.greyDark}>
                {descriptionModalOpened}
              </Body2>
          )}
        </ModalFooter>
      </>
    </DialogPaper>
  );
}

export default VideoModalLandscape;