import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

type Props = {
  padding?: string;
} | undefined;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    color: '#ffffff',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'left',
    padding: '12px 16px',
  },
  buttonText: {
    padding: (props: Props) => props?.padding || '6px 12px',
    textAlign: 'left',
    alingItems: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    fontHeight: '19px',
    color: '#000000',
    textTransform: 'none',
  },
  buttonChip: {
    display: 'flex',
    color: '#ffffff',
    width: '100%',
    height: 'fit-content',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '13px',
    cursor: 'pointer',
  },
  textField: {
    borderRadius: '6px',
    '& .MuiOutlinedInput-input': {
      padding: '14px 16px',
    },
  },
}));

interface DropDownBorderProps {
  backgroundColor?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  textAlign?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
  fontFamily?: string;
  fontStyle?: string;
}

interface ListProps {
  gap?: string;
}

interface DropDownListContainerProps {
  maxHeight?: string;
  position?: string;
  top?: string;
}

interface LanguageInputProps {
  width?: string;
}

export const SelectButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  gap: 8px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #8800FF;
  cursor: pointer;
`;

export const DropDownBorder = styled.div`
  border: 1px solid #C8C7CC;
  border-radius: 6px;
  width: 100%;
  height: fit-content;
  background-color: ${({ backgroundColor }: DropDownBorderProps) => backgroundColor};
`;

export const DropDownButton = styled.div`
  padding: 6px 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-transform: none;
`;

export const DropDownIcon = styled.img`
  margin-left: auto;
  width: 22px;
  height: 22px;
`;

export const RowContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  align-items: center;
  gap: 4px;
  overflow-wrap: break-word;
  height: fit-content;
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-family: ${({ fontFamily }: StyledLabelProps) => fontFamily || 'DIN'};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  font-style: ${({ fontStyle }: StyledLabelProps) => fontStyle || 'normal'};
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  text-align: ${({ textAlign }: StyledLabelProps) => textAlign};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
`;

export const DropDownListContainer = styled.div`
  position: ${({ position }: DropDownListContainerProps) => position || "absolute"};
  top: ${({ top }: DropDownListContainerProps) => top};
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  background: #FFFFFF;
  box-shadow: -3px 4px 9px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  max-height: ${({ maxHeight }: DropDownListContainerProps) => maxHeight || "371px"};
  width: 100%;
  z-index: 100;
`;

export const ListRelativeContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const List = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({gap}: ListProps) => gap || "8px" };
  overflow-y: auto;
  z-index: 100;
`;

export const DropDownChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: linear-gradient(0deg, rgba(136, 0, 255, 0.08), rgba(136, 0, 255, 0.08)), #FFFFFF;
  border-radius: 32px;
  cursor: default;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  width: ${({ width }: LanguageInputProps) => width};
`;

export default useStyles;