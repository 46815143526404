import { CSSProperties, useContext, useEffect, useState } from "react";
import DropdownString from "components/shareable/DropDown/DropDownString";
import { Body2 } from "components/shareable/Typography";
import Colors from "modules/Colors";
import { CURRENCY_SYMBOLS } from "modules/Const";
import { saveCurrency } from 'services/symphonyApi';
import { CurrencyCodesType } from "types/global";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { Container } from "styles/shared";

/** Common currencies that should appear at the top of the dropdown */
const COMMON_CURRENCIES = ['USD', 'EUR', 'GBP', 'CAD', 'AUD'];

/**
 * Maps currency codes and symbols into formatted strings, with common currencies appearing first
 * @returns Array of formatted currency strings (e.g., "USD ($)")
 */
const mapCurrencyItems = () => {
  const allCurrencies = Object.keys(CURRENCY_SYMBOLS);
  
  // Separate common and other currencies
  const commonCurrencyItems = COMMON_CURRENCIES
    .filter(code => allCurrencies.includes(code))
    .map(code => `${code} (${CURRENCY_SYMBOLS[code as CurrencyCodesType]})`);
    
  const otherCurrencyItems = allCurrencies
    .filter(code => !COMMON_CURRENCIES.includes(code))
    .map(code => `${code} (${CURRENCY_SYMBOLS[code as CurrencyCodesType]})`);
    
  return [...commonCurrencyItems, ...otherCurrencyItems];
}

interface Props {
  className?: string
  style?: CSSProperties
  initialValue: string
  hideTitle?: boolean
}

const CurrencyDropdown = ({
  className = '',
  style = {},
  initialValue,
  hideTitle = false,
}: Props) => {
  const { reloadBrand } = useContext(CurrentBrandContext)
  const [selectedValue, setSelectedValue] = useState<string>(initialValue)

  const handleOnSelectCurrency = async (value: string) => {
    const [currencyCode] = value.split('(')
    const currencyTypeValue = currencyCode.trim() as CurrencyCodesType
    const response = await saveCurrency({ code: currencyTypeValue, symbol: CURRENCY_SYMBOLS[currencyTypeValue] })
    if (response?.data?.code) setSelectedValue(`${currencyTypeValue} (${CURRENCY_SYMBOLS[currencyTypeValue]})`)
  }

  useEffect(() => {
    if (selectedValue !== initialValue) {
      reloadBrand()
      setSelectedValue(initialValue)
    }
  }, [selectedValue, initialValue])

  return (
    <Container
      className={className}
      padding={style.padding?.toString() || "24px 0px"}
      gap={8}
      display="flex"
      flexDirection={style.flexDirection || "column"}
    >
      {!hideTitle && <Body2 color={Colors.textColor}>Currency</Body2>}
      <DropdownString
        items={mapCurrencyItems()}
        setSelectedValue={handleOnSelectCurrency}
        value={selectedValue}
      />
    </Container>
  )
}

export default CurrencyDropdown