import { useContext, useState } from 'react';
import Axios from 'helpers/Interceptor';
import dayjs from 'dayjs';
import { Redirect } from 'react-router-dom';
import { StyledLabel, Container } from 'styles/shared';
import { TextField } from '@material-ui/core';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { CurrentUserContext } from 'Hooks/CurrentUserContext';

const SubscriptionReport = () => {
    const { isSymphonyAdmin: isAdmin } = useContext(CurrentUserContext)

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<string>(dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

    const fetchCsv = async () => {
        try {
            setLoading(true);
            const uri = '/admin/funnel/get-churn-list';
            const response = await Axios.get(uri, {
                params: {
                    startDate, endDate
                }
            });
            const csvContent = response.data;
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `subscriptions_data_${startDate}_to_${endDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadReport = () => {
        fetchCsv();
    };

    if (!isAdmin) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Container flexDirection="column" alignItems="center" justifyContent="center" padding="20px" gap="20px">
            <Container flexDirection="column" backgroundColor="#fff" padding="20px" borderRadius="8px" display='flex'>
                <StyledLabel fontSize={20} padding="0px auto 20px">Subscriptions Report</StyledLabel>
                <Container flexDirection="row" gap="12px" alignItems="center" display='flex'>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <ButtonSymphony
                        isLoading={loading}
                        onClick={handleDownloadReport}
                    >
                        Download
                    </ButtonSymphony>
                </Container>
            </Container>
        </Container>
    );
};

export default SubscriptionReport;
