import { CircularProgress } from "@material-ui/core";
import useStyles, { MainContainer, TitleLabel, RemainingBudgetLabel, LiveLabel, PausedLabel, LiveLabelContainer, PausedLabelContainer, TitleAndStatusContainer, PerformanceContainer, HeaderContainer, ButtonContainer, ButtonLabel, MobileContainer } from "./styles";
import { useEffect, useState } from "react";
import { ReactComponent as Play } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as Pause } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg"
import { MarketDetail } from "../MarketsDetail";
import { SymphonyTooltip } from "styles/shared";
import Intercom from "helpers/Intercom";
import { formatNumberToCurrency } from "modules/Utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { CurrencyCodesType } from "types/global";
import useScreen from "Hooks/useScreen";
import { DEFAULT_CURRENCY } from "modules/Const";

interface Props {
  market?: MarketDetail
  onPauseMarket: () => void
  showOverall?: boolean
  name?: string
  campaignStatus: string
  marketStatus?: string
  fraction?: number
  currency?: CurrencyCodesType
}

const MarketDetailHeader = ({
  market,
  onPauseMarket,
  showOverall = false,
  name = '',
  campaignStatus,
  marketStatus = '',
  fraction,
  currency,
}: Props) => {
  const { mobileView } = useScreen();
  const classes = useStyles();

  const [isMarketPaused, setIsMarketPaused] = useState<boolean>(marketStatus === 'PAUSED')
  const [showButton, setShowButton] = useState<boolean>(!showOverall && campaignStatus !== 'FINISHED')

  const budgetRemaining = market ? market.totalBudget - market.spend : 0
  const budgetPercentage = market && budgetRemaining !== 0 ? (budgetRemaining) * 100 / market.totalBudget : 0
  const buttonColor = "#8800ff"
  const allocation = fraction ? fraction * 100 : 0
  const currencySymbol = getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)
  useEffect(() => {
    if (market) {
      setIsMarketPaused(market.status === "PAUSED")
      setShowButton(!showOverall && campaignStatus !== 'FINISHED')
    }
  }, [market, campaignStatus])

  if (mobileView) {
    return (
      <MobileContainer>
        <MainContainer
          flexDirection="column"
        >
          <CircularProgress
            value={budgetPercentage}
            variant="determinate"
            className={classes.progress}
            thickness={8}
          />
          <HeaderContainer>
            <PerformanceContainer>
              <RemainingBudgetLabel>
                Budget spent: {currencySymbol}{formatNumberToCurrency(market?.spend)} of {currencySymbol}{formatNumberToCurrency(market?.totalBudget)} ({currencySymbol}{formatNumberToCurrency(budgetRemaining)} left to spend)
              </RemainingBudgetLabel>
              {!showOverall && (
                <RemainingBudgetLabel>
                  Budget allocation: {formatNumberToCurrency(allocation)}% of campaign spend
                </RemainingBudgetLabel>
              )}
              <TitleAndStatusContainer>
                <TitleLabel>
                  {!name ? 'Overall' : name}
                </TitleLabel>
                {
                  campaignStatus === "FINISHED" && (
                    <PausedLabelContainer>
                      <PausedLabel>
                        Campaign finished
                      </PausedLabel>
                    </PausedLabelContainer>
                  )
                }
                {
                  isMarketPaused && showButton && (
                    <PausedLabelContainer>
                      <PausedLabel>
                        Paused
                      </PausedLabel>
                    </PausedLabelContainer>
                  )
                }
                {
                  !isMarketPaused && showButton && (
                    <LiveLabelContainer>
                      <LiveLabel>
                        Live
                      </LiveLabel>
                    </LiveLabelContainer>
                  )
                }
                {
                  showOverall && (
                    <SymphonyTooltip
                      className={classes.overallTooltip}
                      onClick={() => Intercom.openIGPerformanceStatsArticle()}
                      title="Learn more about your performance stats"
                    >
                      <QuestionMarkIcon />
                    </SymphonyTooltip>
                  )
                }
              </TitleAndStatusContainer>
            </PerformanceContainer>
          </HeaderContainer>
        </MainContainer>
        {showButton && (
          <ButtonContainer
            onClick={onPauseMarket}
          >
            {isMarketPaused ? (
              <Play height={32} width={32} color={buttonColor} />
            ) : (
              <Pause height={32} width={32} color={buttonColor} />
            )}
            <ButtonLabel
              color={buttonColor}
            >
              {isMarketPaused ? "Resume market" : "Pause market"}
            </ButtonLabel>
          </ButtonContainer>
        )}
      </MobileContainer>
    );
  }

  return (
    <MainContainer
      flexDirection="row"
      alignItems="center"
    >
      <CircularProgress
        value={budgetPercentage}
        variant="determinate"
        className={classes.progress}
        thickness={8}
      />
      <HeaderContainer>
        <PerformanceContainer>
          <TitleAndStatusContainer>
            <TitleLabel>
              {!name ? 'Overall' : name}
            </TitleLabel>
            {campaignStatus === "FINISHED" &&
              <PausedLabelContainer>
                <PausedLabel>
                  Campaign finished
                </PausedLabel>
              </PausedLabelContainer>
            }
            {isMarketPaused && showButton &&
              <PausedLabelContainer>
                <PausedLabel>
                  Paused
                </PausedLabel>
              </PausedLabelContainer>
            }
            {!isMarketPaused && showButton &&
              <LiveLabelContainer>
                <LiveLabel>
                  Live
                </LiveLabel>
              </LiveLabelContainer>
            }
            {showOverall &&
              <SymphonyTooltip
                className={classes.overallTooltip}
                onClick={() => Intercom.openIGPerformanceStatsArticle()}
                title="Learn more about your performance stats"
              >
                <QuestionMarkIcon />
              </SymphonyTooltip>
            }
          </TitleAndStatusContainer>
          <RemainingBudgetLabel>
            Budget spent:&nbsp;
            {getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{formatNumberToCurrency(market?.spend)} of&nbsp;
            {getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{formatNumberToCurrency(market?.totalBudget)}&nbsp;
            ({getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{formatNumberToCurrency(budgetRemaining)} left to spend)
          </RemainingBudgetLabel>
          {!showOverall && (
            <RemainingBudgetLabel>
              Budget allocation: {formatNumberToCurrency(allocation)}% of campaign spend
            </RemainingBudgetLabel>
          )}
        </PerformanceContainer>
        {showButton && (
          <ButtonContainer
            justifyContent="center"
            onClick={onPauseMarket}
          >
            {isMarketPaused ?
              <Play height={32} width={32} color={buttonColor} />
              :
              <Pause height={32} width={32} color={buttonColor} />
            }
            <ButtonLabel
              color={buttonColor}
            >
              {isMarketPaused ? "Resume market" : "Pause market"}
            </ButtonLabel>
          </ButtonContainer>
        )}
      </HeaderContainer>
    </MainContainer>
  );
}

export default MarketDetailHeader