import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { PlanType } from "modules/Const";

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  textAlign?: string;
  padding?: string;
  width?: string;
  height?: string;
}

interface GradientRoundedContainerProps {
  width?: string;
  height?: string;
  planType?: PlanType;
}

interface AvatarContainerProps {
  top?: string;
}

export const StyledNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, 1);
  flex-grow: 1;
  margin-top: 32px;
`;

export const TabsPanelsContainer = styled.div`
  padding: 0px 0px;
  height: 100%;
`;

export const GradientRoundedContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: ${({ width }: GradientRoundedContainerProps) => width || "50px"};
  height: ${({ height }: GradientRoundedContainerProps) => height || "50px"};
  background: ${({ planType }: GradientRoundedContainerProps) =>
    planType === PlanType.PRO
      ? 'linear-gradient(to top right, #8e33ff 25%, #ff7e33, #ffc300 90%)'
      : 'linear-gradient(to top right, #6366f1 25%, #8b5cf6, #a78bfa 90%)'};
  border-radius: 50%;
`;

export const WhiteRoundedContainer = styled.div`
  position: relative;
  top: 2.5px;
  left: 2.5px;
  width: 37px;
  height: 37px;
  background-color: #ffffff;
  border-radius: 50%;
`;

export const AvatarContainer = styled.div`
  position: relative;
  left: 2.5px;
  top: ${({ top }: AvatarContainerProps) => top || "3px"};
`;

export const Icon = styled.img``;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 32,
    height: 32,
  },
}));

export default useStyles;
