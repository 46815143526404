import { Fragment, useEffect, CSSProperties } from 'react';
import RecordPreview from "components/shareable/RecordPreview";
import { BackgroundTypes, RecordPreviewData, StyleTypes, Website } from "types/global";
import { BlurredPicture, PagePreview, Preview, PreviewContainer, PreviewHeader } from "./styles";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { SymphonyTooltip } from "styles/shared";
import useScreen from "Hooks/useScreen";
import useModal from "Hooks/useModal";

interface Props {
  className?: string;
  style?: Website;
  styleCss?: CSSProperties;
  contentDetails: RecordPreviewData;
  styleType: StyleTypes;
  bgColor?: string;
  isFinalized: boolean;
  canEdit?: boolean;
  openEditModal?: () => void;
}

const PresavePreview: React.FC<Props> = ({
  className = '',
  style,
  styleCss = {},
  contentDetails,
  styleType,
  bgColor,
  isFinalized,
  canEdit = true,
  openEditModal,
}: Props) => {
  const { mobileView } = useScreen()

  const previewModal = useModal()

  useEffect(() => {
    if (!mobileView) {
      previewModal.closeModal();
    }
  }, [mobileView])

  if (mobileView) {
    return (
      <Fragment />
    );
  }

  return (
    <PagePreview
      className={`preSavePreviewComponent ${className}`}
      style={{...style, ...sty.preSavePreviewComponent, ...styleCss}}
      borderRadius="12px"
      width={mobileView ? "100%" : "30%"}
      height="fit-content"
    >
      <PreviewHeader>
        Website Preview
        {canEdit &&
          <SymphonyTooltip title={isFinalized ? 'Edit your website in the “Website” tab' : "Edit your Pre-save"}>
            <EditIcon
              onClick={!isFinalized ? openEditModal : undefined}
              color={isFinalized ? "#b2b2b2" : "#8800FF"}
              cursor={isFinalized ? "not-allowed" : "pointer"}
            />
          </SymphonyTooltip>
        }
      </PreviewHeader>
      <PreviewContainer
        padding="24px"
        height="fit-content"
        alignItems="center"
      >
        <Preview
          background={bgColor}
          width="70%"
          border="4.65949px solid #00000061"
          padding="5% 6% 35% 6%"
          borderRadius="18.73px"
          overflow="hidden"
        >
          {style?.bgType === BackgroundTypes.ARTWORK &&
            <BlurredPicture
              alt="artwork_as_background"
              src={contentDetails.artworkUrl}
              height="100%"
              top="0"
              className="filter"
            />
          }
          <RecordPreview
            backgroundColor={style?.bgColor || ''}
            mainColor={style?.primaryColor || ""}
            secondaryColor={style?.secondaryColor || ""}
            backgroundType={style?.bgType || ""}
            styleType={styleType}
            contentDetails={contentDetails}
            relative
            creation={false}
          />
        </Preview>
      </PreviewContainer>
    </PagePreview>
  );
}

const sty = {
  preSavePreviewComponent: {
    position: 'sticky',
    top: 16,
  } as CSSProperties,
}

export default PresavePreview;