export const streamingLinkNameMapping = {
    "spotify": "Spotify",
    "apple_music": "Apple Music",
    "soundcloud": "Soundcloud",
    "tidal": "Tidal",
    "amazon": "Amazon",
    "google": "Google Play",
    "google_play": "Google Play",
    "deezer": "Deezer",
    "youtube": "YouTube",
    "pandora": "Pandora",
    "amazon_music": "Amazon Music",
    "audiomack": "Audiomack",
    "soundxyz": "Sound.xyz",
    "tiktok": "TikTok",
    "bandcamp": "Bandcamp",
    "itunes": "iTunes",
    "even": "Even",
    "anghami": "Anghami",
    "boomplay": "Boomplay",
    "beatport": "Beatport"
}

export const streamingLinkNamesToDbKeysMapping = {
    "Spotify": "spotify",
    "Apple Music": "apple_music",
    "Soundcloud": "soundcloud",
    "SoundCloud": "soundcloud",
    "Even": "even",
    "Tidal": "tidal",
    "Amazon": "amazon",
    "Google Play": "google",
    "Deezer": "deezer",
    "YouTube": "youtube",
    "Pandora": "pandora",
    "Amazon Music": "amazon_music",
    "Audiomack": "audiomack",
    "Sound.xyz": "soundxyz",
    "TikTok": "tiktok",
    "Bandcamp": "bandcamp",
    "iTunes": "itunes",

    "Anghami": "anghami",
    "Boomplay": "boomplay",
    "Beatport": "beatport"
}


export const streamingLinkDbKeyToCleanKey = {
    "spotify": "spotify",
    "apple_music": "apple",
    "soundcloud": "soundcloud",
    "tidal": "tidal",
    "amazon": "amazon",
    "google": "google-play",
    "google_play": "google-play",
    "deezer": "deezer",
    "youtube": "youtube",
    "pandora": "pandora",
    "amazon_music": "amazonmusic",
    "audiomack": "audiomack",
    "soundxyz": "soundxyz",
    "tiktok": "tiktok",
    "bandcamp": "bandcamp",
    "anghami": "anghami",
    "boomplay": "boomplay",
    "itunes": "itunes",
    "even":"even",

    "beatport": "beatport"
}

export const streamingLinkCleanKeyToDbKey = {
    "spotify": "spotify",
    "apple": "apple_music",
    "soundcloud": "soundcloud",
    "tidal": "tidal",
    "amazon": "amazon",
    "google-play": "google_play",
    "deezer": "deezer",
    "youtube": "youtube",
    "pandora": "pandora",
    "amazonmusic": "amazon_music",
    "audiomack": "audiomack",
    "soundxyz": "soundxyz",

    "tiktok": "tiktok",
    "bandcamp": "bandcamp",
    "anghami": "anghami",
    "boomplay": "boomplay",
    "itunes": "itunes",
    "even":"even",

    "beatport": "beatport"
}

export const streamingLinkPlaceholderUrls = {
    "spotify": "https://open.spotify.com/xyz",
    "apple_music": "https://music.apple.com/xyz",
    "soundcloud": "https://soundcloud.com/xyz",
    "tidal": "https://tidal.com/xyz",
    "amazon": "https://music.amazon.com/xyz",
    "google": "https://play.google.com/xyz",
    "google_play": "https://play.google.com/xyz",
    "deezer": "https://deezer.com/xyz",
    "youtube": "https://youtube.com/xyz",
    "pandora": "https://pandora.com/xyz",
    "amazon_music": "https://music.amazon.com/xyz",
    "audiomack": "https://audiomack.com/xyz",
    "tiktok": "https://www.tiktok.com/@xyz",
    "bandcamp": "https://abc.bandcamp.com/xyz",
    "anghami": "https://play.anghami.com/xyz",
    "boomplay": "https://boomplay.com/xyz",
    "itunes": "https://itunes.apple.com/xyz",
    "soundxyz": "https://www.sound.xyz/xyz",
    "beatport": "https:/www.beatport.com/xyz",
    "even": "https://even.com/xyz"
}

export const filterOutLinks = (linkName) => {

    const removeList = ['spinrilla', 'napster', 'yandex', 'amazon', 'google', 'amazonStore', 'shazam']

    if (removeList.indexOf(linkName) > -1) return false

    return true
}

export const streamingLinkSorter = (aName, bName) => {


    if (aName === "spotify") {
        return -1
    } else if (bName === "spotify") {
        return 1
    }
    if (aName === "apple") {
        return -1
    } else if (bName === "apple") {
        return 1
    }

    if (aName === "applemusic") {
        return -1
    } else if (bName === "applemusic") {
        return 1
    }

    if (aName === "itunes") {
        return -1
    } else if (bName === "itunes") {
        return 1
    }

    if (aName === "apple_music") {
        return -1
    } else if (bName === "apple_music") {
        return 1
    }

    if (aName === "youtube") {
        return -1
    } else if (bName === "youtube") {
        return 1
    }

    if (aName === "soundxyz") {
        return -1
    } else if (bName === "soundxyz") {
        return 1
    }

    if (aName === "tiktok") {
        return -1
    } else if (bName === "tiktok") {
        return 1
    }

    if (aName === "audiomack") {
        return -1
    } else if (bName === "audiomack") {
        return 1
    }

    if (aName === "beatport") {
        return -1
    } else if (bName === "beatport") {
        return 1
    }

    if (aName === "soundcloud") {
        return -1
    } else if (bName === "soundcloud") {
        return 1
    }






    if (aName === "amazon_music") {
        return -1
    } else if (bName === "amazon_music") {
        return 1
    }

    if (aName === "tidal") {
        return -1
    } else if (bName === "tidal") {
        return 1
    }

    if (aName === "deezer") {
        return -1
    } else if (bName === "deezer") {
        return 1
    }

    if (aName === "bandcamp") {
        return -1
    } else if (bName === "bandcamp") {
        return 1
    }


    return 1
}

export const linkImgMapping = (prefix, name) => {
    const mappings = {
        "spotify": require('../assets/images/streaming/microsite/dark/spotify.png'),
        "apple_music": require('../assets/images/streaming/microsite/dark/applemusic.png'),
        "apple": require('../assets/images/streaming/microsite/dark/applemusic.png'),

        "itunes": require('../assets/images/streaming/microsite/dark/itunes.png'),
        "soundcloud": require('../assets/images/streaming/microsite/dark/soundcloud.png'),
        "tidal": require('../assets/images/streaming/microsite/dark/tidal.png'),
        "amazon": require('../assets/images/streaming/microsite/dark/amazonmusic.png'),
        "google_play": require('../assets/images/streaming/microsite/dark/googleplay.png'),
        "googleplay": require('../assets/images/streaming/microsite/dark/googleplay.png'),

        "deezer": require('../assets/images/streaming/microsite/dark/deezer.png'),
        "youtube": require('../assets/images/streaming/microsite/dark/youtube.png'),
        "pandora": require('../assets/images/streaming/microsite/dark/pandora.png'),
        "rhapsody": null,
        "youtubemusic": require('../assets/images/streaming/microsite/dark/youtube.png'),
        "amazon_music": require('../assets/images/streaming/microsite/dark/amazonmusic.png'),
        "audiomack": require('../assets/images/streaming/microsite/dark/audiomack.png'),
        "soundxyz": require('../assets/images/streaming/microsite/dark/soundxyz.png'),

        "tiktok": require('../assets/images/streaming/microsite/dark/tiktok.png'),


        "bandcamp": require('../assets/images/streaming/microsite/dark/bandcamp.png'),

        "anghami": require('../assets/images/streaming/microsite/dark/bandcamp.png'),
        "boomplay": require('../assets/images/streaming/microsite/dark/bandcamp.png'),

        "beatport": require('../assets/images/streaming/microsite/dark/beatport.png'),

        "even": require('../assets/images/streaming/microsite/dark/even.png'),
    }

    return mappings[name]
}

export const contrastLinkImgMapping = (prefix, type, name) => {
    const mappings = {
        "light": {
            "spotify": require('../assets/images/streaming/microsite/light/spotify.png'),
            "apple_music": require('../assets/images/streaming/microsite/light/applemusic.png'),
            "apple": require('../assets/images/streaming/microsite/light/applemusic.png'),

            "itunes": require('../assets/images/streaming/microsite/light/itunes.png'),
            "soundcloud": require('../assets/images/streaming/microsite/light/soundcloud.png'),
            "tidal": require('../assets/images/streaming/microsite/light/tidal.png'),
            "amazon": require('../assets/images/streaming/microsite/light/amazonmusic.png'),
            "google_play": require('../assets/images/streaming/microsite/light/googleplay.png'),
            "googleplay": require('../assets/images/streaming/microsite/light/googleplay.png'),

            "deezer": require('../assets/images/streaming/microsite/light/deezer.png'),
            "youtube": require('../assets/images/streaming/microsite/light/youtube.png'),
            "pandora": require('../assets/images/streaming/microsite/light/pandora.png'),
            "rhapsody": null,
            "youtubemusic": require('../assets/images/streaming/microsite/light/youtube.png'),
            "amazon_music": require('../assets/images/streaming/microsite/light/amazonmusic.png'),
            "audiomack": require('../assets/images/streaming/microsite/light/audiomack.png'),
            "soundxyz": require('../assets/images/streaming/microsite/light/soundxyz.png'),

            "tiktok": require('../assets/images/streaming/microsite/light/tiktok.png'),

            "bandcamp": require('../assets/images/streaming/microsite/light/bandcamp.png'),

            "anghami": require('../assets/images/streaming/microsite/light/anghami.png'),
            "boomplay": require('../assets/images/streaming/microsite/light/boomplay.png'),

            "beatport": require('../assets/images/streaming/microsite/light/beatport.png'),
            "even": require('../assets/images/streaming/microsite/light/even.png'),
        },

        "dark": {
            "spotify": require('../assets/images/streaming/microsite/dark/spotify.png'),
            "apple_music": require('../assets/images/streaming/microsite/dark/applemusic.png'),
            "apple": require('../assets/images/streaming/microsite/dark/applemusic.png'),

            "itunes": require('../assets/images/streaming/microsite/dark/itunes.png'),
            "soundcloud": require('../assets/images/streaming/microsite/dark/soundcloud.png'),
            "tidal": require('../assets/images/streaming/microsite/dark/tidal.png'),
            "amazon": require('../assets/images/streaming/microsite/dark/amazonmusic.png'),
            "google_play": require('../assets/images/streaming/microsite/dark/googleplay.png'),
            "googleplay": require('../assets/images/streaming/microsite/dark/googleplay.png'),
            "even": require('../assets/images/streaming/microsite/dark/even.png'),

            "deezer": require('../assets/images/streaming/microsite/dark/deezer.png'),
            "youtube": require('../assets/images/streaming/microsite/dark/youtube.png'),
            "pandora": require('../assets/images/streaming/microsite/dark/pandora.png'),
            "rhapsody": null,
            "youtubemusic": require('../assets/images/streaming/microsite/dark/youtube.png'),
            "amazon_music": require('../assets/images/streaming/microsite/dark/amazonmusic.png'),
            "audiomack": require('../assets/images/streaming/microsite/dark/audiomack.png'),
            "soundxyz": require('../assets/images/streaming/microsite/light/soundxyz.png'),

            "tiktok": require('../assets/images/streaming/microsite/dark/tiktok.png'),

            "bandcamp": require('../assets/images/streaming/microsite/dark/bandcamp.png'),

            "anghami": require('../assets/images/streaming/microsite/dark/anghami.png'),
            "boomplay": require('../assets/images/streaming/microsite/dark/boomplay.png'),

            "beatport": require('../assets/images/streaming/microsite/dark/beatport.png'),

        }
    }

    return mappings[type][name]
}

export const InstagramBaseUrlRegex = /(?:(?:http|https):\/\/?)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\//

export const STREAMLINK_REGEX = {
    beatport: [
        /https?:\/\/(?:www\.)?beatport\.com\/\w+\/[a-zA-Z0-9\-]+\/\d+/
    ],
    boomplay: [
        /https:\/\/(?:www\.)?boomplay\.com\/*/
    ],

    anghami: [
        /https:\/\/(?:www\.|play\.)?anghami\.com\/*/
    ],

    bandcamp: [
        /(https?:\/\/)?([\d|\w]+)\.bandcamp\.com\/?.*/
    ],

    spotify: [
        /https:\/\/(?:www\.)?open.spotify.com\/*/

    ],

    apple_music: [
        /https:\/\/(?:www\.)?music.apple.com\/*/,
        /https:\/\/(?:www\.)?geo.itunes.apple.com\/*/,
        /https:\/\/(?:www\.)?geo.music.apple.com\/*/

    ],

    google_play: [
        /https:\/\/(?:www\.)?play.google.com\/*/

    ],

    amazon_music: [
        /https:\/\/(?:www\.)?music.amazon\.[a-z]{2,}\/*/,
        /https:\/\/(?:www\.)?amazon\.[a-z]{2,}\/*/,
    ],

    youtube: [
        /https:\/\/(?:www\.)?youtube\.com\/*/,
        /https:\/\/(?:www\.)?youtu\.be\/*/,
        /https:\/\/(?:www\.)?music.youtube.com\/*/
    ],

    tidal: [
        /https:\/\/(?:listen\.)?tidal.com\/*/,
        /https:\/\/(?:www\.)?tidal.com\/*/
    ],

    deezer: [
        /https:\/\/(?:www\.)?deezer.com\/*/
    ],

    soundcloud: [
        /https?:\/\/(?:www\.)?(on\.)?(?:soundcloud\.com|snd\.sc)\/*([a-zA-Z0-9\_\-]+)/
    ],

    pandora: [
        /https?:\/\/(?:www\.)?pandora(.com|.app.link)\//

    ],

    tiktok: [
        /https?:\/\/(?:www\.)tiktok.com\/*/,
        /https:\/\/(?:www\.)?tiktok.com\/@([a-zA-Z0-9._]+)/
    ],

    business_discovery_instagram: [
        /^https?:\/\/(?:www\.)instagram.com\/([a-zA-Z0-9._]+)\/?$/,
    ],

    audiomack: [
        /https:\/\/(?:www\.)?audiomack.com\/*/
    ],
    soundxyz: [
        /https:\/\/(?:www\.)?sound.xyz\/*/
    ],

    shazam: [
        /^https:\/\/(?:www\.)shazam.com\/*/
    ],

    twitter: [
        /^https:\/\/(?:www\.)twitter.com\/*([a-zA-Z0-9\_\-]+)/,
        /^https:\/\/twitter.com\/*([a-zA-Z0-9\_\-]+)/,
    ]
}

export const STREAM_USERNAME_REGEX = {
    tiktok: /^[@.]?(?!.*\.$)[^\W][\w.]{0,50}$/,
}

export const NAME_REG_EXP = '.*[A-Za-z0-9ñ].*[A-Za-z0-9ñ].*$'
