import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import ListComponent from "components/marketing/HelperPopup/list-section";
import BadgeContainer from "../Components/BadgeCampaignTypeContainer/BadgeContainer";
import PLATFORMS_BADGE_ICONS from "../Components/BadgeCampaignTypeContainer/BADGE_ICONS";
import ContactUsHelpPopupSection from "components/marketing/HelperPopup/contact-us";

const PlaylistingHelperPopup = () => {
    return (
        <>
            <div className=" mb-6">
                <p className="text-lg ">The "Get Playlisted" tool is a quick way to pitch highly-targeted, user-generated playlists on Spotify to impact streams + improve your chances of catching the Spotify algorithm. </p>
                <div className="flex mt-3 text-sm">
                    <BadgeContainer text="Pitch Spotify Playlists + Grow Streams" imageUrls={[
                        PLATFORMS_BADGE_ICONS["spotify"]
                    ]} />
                </div>
            </div>

            <ListComponent
                title="When to use it:"
                bulletPoints={[
                    "You want to get your song in front of passive listeners - primarily to drive streams and influence the Spotify algorithm to trigger algorithmic playlists like Discovery Weekly and Release Radar."
                ]}
            />

            <ListComponent
                title="The Symphony Advantage:"
                bulletPoints={[
                    "Symphony's playlist curation team listens to each song submitted through the system to pitch to our extensive Spotify playlist network - based on the genre of your song and the general sound, we'll try to find the most effective playlists that will help grow streams.",
                    "Every month, the Symphony team actively vets playlists by removing the lowest-performing playlists from our network.",
                    "Compared to playlisting on other platforms, Symphony can't guarantee you'll get a specific number of streams from playlists, but we can guarantee your song will be on playlists with real listeners and real engagement."
                ]}
            />

            <ListComponent
                title="When not to use it:"
                bulletPoints={[
                    "If you want to drive real fanbase growth and build a touring fanbase, a more active marketing tool - such as running ads using 'Increase Music Streams' is a better bet.",
                    "User-generated Spotify playlists are great to drive streams and influence the algorithm in the short term, but they won't make as much impact on Spotify followers or real fanbase growth. Most listeners on user-generated Spotify playlists are listening passively - hitting shuffle or listening in the car - vs. listening actively."
                ]}
            />

            <ListComponent
                title="What you need to get started:"
                bulletPoints={[
                    "Your song should be out on Spotify."
                ]}
            />


            <ListComponent
                title="The Dashboard:"
                bulletPoints={[
                    "Track exactly which playlists your song was placed on and their total followers.",
                    "Track growth in your song's Spotify streams."
                ]}
                swiperImages={[
                    require("../../../../assets/images/onboarding/data-examples/playlisting-example.png").default,
                    require("../../../../assets/images/onboarding/data-examples/playlists-example.png").default
                ]}
            />
            <ContactUsHelpPopupSection />
        </>
    )
}

export default PlaylistingHelperPopup