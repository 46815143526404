import { CSSProperties } from "react";
import { DownArrow, UpArrow } from "./icons";

export const GraphTooltip = ({
    title,
    value
}: {
    title: string;
    value: string | JSX.Element
}) => {
    const renderValue = () => {
        if (typeof value === 'string') {
            return (
                <p className="font-semibold text-lg">{value}</p>

            )
        } else {
            return value
        }
    }

    return (
        <div className="bg-white rounded-md px-4 py-2 shadow-md">
            <p className="text-md">{`${title}`}</p>
            {renderValue()}
        </div>
    )
}

/**
 * Component to show the up / down in growth
 * @param props 
 * @returns 
 */
export const GrowthIndicatorMessage = ({
    className,
    style = {},
    percentage,
}: {
    className?: string
    style?: CSSProperties,
    percentage: any
}) => {
    if (!percentage) return null

    const textColor = percentage >= 0 ? "text-green" : "text-gray-500"
    const bgColor = percentage >= 0 ? "#E1F8E1" : "#EBE7EF"

    return (
        <div
            className={`growthIndicatorMessageComponent ${className} ${textColor}`}
            style={{
                backgroundColor: bgColor,
                ...sty.growthIndicatorMessageComponent,
                ...style
            }}
        >
            {percentage >= 0 ? <UpArrow /> : <DownArrow />}
            <p className="growthIndicatorNumber text-lg" style={sty.growthIndicatorNumber}>
                {Math.abs(percentage || 0).toFixed(2)}%
            </p>
        </div>
    )
}

const sty = {
    growthIndicatorMessageComponent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        padding: '4px 8px',
        marginLeft: 12,
        alignSelf: 'center'
    } as CSSProperties,
    growthIndicatorNumber: {
        marginTop: 5,
        fontWeight: 700,
        marginLeft: 4,
    } as CSSProperties
}