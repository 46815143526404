import { Link } from 'react-router-dom';
import ClickableChip from 'pages/post-auth/MarketingPage/Components/CreateCampaignBadge';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import useScreen from 'Hooks/useScreen';
import { CSSProperties } from 'react';
import { clsx, styx } from 'modules/Utils';

interface CampaignItem {
  key: string;
  title: string;
  icon: string;
  link?: string | undefined;
  isWebsite?: boolean;
}

interface CustomCampaignSelectorProps {
  className?: string;
  style?: CSSProperties;
  buttonText?: string;
  onButtonClick?: () => void;
  campaignItems: CampaignItem[];
  onCampaignItemClick: (campaignKey: string) => void;
  additionalItemsText?: string;
  hidePrimaryButton?: boolean;
  stackedView?: boolean;
  isOnlyActionsView?: boolean;
}

const DefaultAdditionalActions = ({
  className = '',
  style = {},
  buttonText,
  onButtonClick,
  campaignItems,
  onCampaignItemClick,
  additionalItemsText,
  hidePrimaryButton = false,
  stackedView = false,
  isOnlyActionsView = false,
}: CustomCampaignSelectorProps) => {
  const { mobileView, mStyle } = useScreen();

  const renderButton = () => {
    if (!hidePrimaryButton && buttonText && onButtonClick) {
      let actionButton = (
        <ButtonSymphony
            className="buttonDefaultAdditionalActions"
            onClick={onButtonClick}
            width={mobileView ? '100%' : 'fit-content'}
          >
            {buttonText}
          </ButtonSymphony>
      )

      if(mobileView) {
        actionButton = (
          <div className="mobileActionButtonContainer" style={{width: '100%', paddingRight: 16}}>
            {actionButton}
          </div>
        )
      }

      return actionButton
    }
  }

  return (
    <div
      className={clsx('defaultAdditionalActionsComponent', className)}
      style={styx(
        mStyle(sty, 'defaultAdditionalActionsComponent'),
        isOnlyActionsView && { paddingBottom: 0, paddingLeft: 0 },
        style,
        stackedView && { marginBottom: 0 }
      )}
    >
      {renderButton()}
      <div
        className="itemsContainer"
        style={{
          ...mStyle(sty, 'itemsContainer'),
          paddingLeft: stackedView ? 16 : 0,
          paddingRight: stackedView ? 16 : 0,
        }}
      >
        {additionalItemsText &&
          <div className="additionalItemsTextWithin" style={{ marginLeft: isOnlyActionsView ? 16 : 0 }}>
            {additionalItemsText}
          </div>
        }
        {campaignItems.map((item) => (
          <div
            key={item.key}
            className="chipContainer"
            style={{
              ...mStyle(sty, 'chipContainer'),
              marginLeft: stackedView ? 0 : 8,
              marginRight: stackedView ? 0 : 8,
            }}
          >
            {item.link ?
              <Link
                className="chipLink"
                to={item.link}
                onClick={() => onCampaignItemClick(item.key)}
              >
                <ClickableChip
                  stacked={stackedView}
                  onClick={() => { }}
                  campaignImage={item.icon}
                  campaignName={item.title}
                />
              </Link>
              :
              <div className="chipContainer" onClick={() => onCampaignItemClick(item.key)}>
                <ClickableChip
                  className="clickableChip"
                  stacked={stackedView}
                  onClick={() => { }}
                  campaignImage={item.icon}
                  campaignName={item.title}
                />
              </div>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

const sty: Record<string, CSSProperties> = {
  defaultAdditionalActionsComponent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
    gap: 16,
    paddingBottom: 16,
  },
  defaultAdditionalActionsComponent_mobile: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    width: 'fit-content',
    padding: '0 16px',
  },
  buttonContainer_mobile: {
    width: '100%',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    width: '100%',
    alignItems: 'center',
    gap: 8,
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    paddingTop: 16,
    paddingBottom: 16,
  },
  itemsContainer_mobile: {
    paddingTop: 0,
  },
}

export default DefaultAdditionalActions;
