import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import { clsx } from "modules/Utils";

interface PlaylistSelectorCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const PlaylistSelectorCampaignView = ({
    className = '',
    style = {}
}: PlaylistSelectorCampaignViewProps) => {
    const { campaign } = useCampaign();

    return (
        <div
            className={clsx("playlistSelectorCampaignViewComponent", className)}
            style={{ ...sty.playlistSelectorCampaignViewComponent, ...style }}
        >
            PlaylistSelectorCampaignView
        </div>
    )
}

const sty = {
    playlistSelectorCampaignViewComponent: {
        width: '100%',
        height: '100%',
    }
}

export default PlaylistSelectorCampaignView;