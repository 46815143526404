import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';
import Colors from "modules/Colors";

export const WebsiteEditorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EditorSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: ${Colors.PAPER_CONTAINER};
  ${MEDIA_BREAKPOINTS.mobileView} {
    border-radius: 0px;
    padding: 16px;
  }
`;
