import GemIconPurple from "assets/images/gem-icon-purple.svg";
import GemIconYellow from "assets/images/gem-icon-yellow.svg";
import { UpgradeLabelContainer, Icon, UpgradeLabel } from "./styles";
import isEqual from "lodash/isEqual";
import ButtonSymphony from "../ButtonSymphony";

enum Colors {
  PURPLE = "PURPLE",
  YELLOW = "YELLOW",
}

const { PURPLE, YELLOW } = Colors;

const getIcon = (color?: string) =>
  isEqual(color, YELLOW)
    ? GemIconYellow
    : isEqual(color, PURPLE)
      ? GemIconPurple
      : undefined;

interface Props {
  color?: string;
  label?: string;
  onClick?: () => void;
  maxWidth?: string;
  minWidth?: string;
  width?: string;
  margin?: string;
  iconBeforeLabel?: React.ReactNode | undefined | false;
  iconAfterLabel?: React.ReactNode | undefined | false;
  customColors?: {
    backgroundColor?: string;
    labelColor?: string;
    borderColor?: string;
  };
}

const UpgradeToProLabel = ({
  color,
  label,
  onClick,
  maxWidth,
  minWidth,
  width,
  iconBeforeLabel = null,
  iconAfterLabel = null,
  customColors,
  margin
}: Props) => {
  const { backgroundColor, labelColor, borderColor } = customColors || {};

  return (
    // <ButtonSymphony
    //   className="upgradeToProLabelContainer"
    //   style={{
    //     maxWidth: maxWidth,
    //     minWidth: minWidth,
    //     width: width,
    //     margin: margin,
    //     backgroundColor: backgroundColor || color,
    //     borderColor: borderColor,
    //     color: labelColor || color,
    //     flexShrink: 1
    //   }}
    //   iconLeft={iconBeforeLabel && iconBeforeLabel}
    //   iconRight={iconAfterLabel && iconAfterLabel}
    // >
    //   {!customColors && (
    //     <Icon alt="icon.svg" src={getIcon(color)} />
    //   )}
    //   {label}
    // </ButtonSymphony>
    <UpgradeLabelContainer
      className="upgradeToProLabelContainer"
      {...{ maxWidth, minWidth }}
      width={width}
      margin={margin}
      backgroundColor={backgroundColor || color}
      borderColor={borderColor}
    >
      {!customColors && (
        <Icon alt="icon.svg" src={getIcon(color)} />
      )}
      {iconBeforeLabel && iconBeforeLabel}
      <UpgradeLabel color={labelColor || color}>{label}</UpgradeLabel>
      {iconAfterLabel && iconAfterLabel}
    </UpgradeLabelContainer>
  );
};

export default UpgradeToProLabel;
