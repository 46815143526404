import { useState, useEffect, useContext } from "react";
import InfoSection from "components/InfoSection";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import { Grid } from "@material-ui/core";
import { FbSuggestion, SpotifyArtistsOption } from "types/global";
import { MarketingDataContext } from "../../Data/Provider";
import { OutlinedButton } from "styles/shared";
import useStyles from "./styles";
import { AudienceTargetingProps, NotFoundSimilarArtistsProps, SimilarArtistLoaderProps, getFbSuggestionsByArtist, getLastSpotifyArtist } from "./utils";
import AutoCompleteSpotifyArtists from "../../MusicStreams/TargetingView/Components/AutoCompleteSpotifyArtists";
import AutoCompleteAudiences from "../../MusicStreams/TargetingView/Components/AutoCompleteAudiences";
import { ConnectionsContext } from "../../hooks/ConnectionsContext";

const SimilarArtistLoader = ({
    artistName,
}: SimilarArtistLoaderProps) => {
    const classes = useStyles();

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <LoadingIndicator color="black" height="14px" />
            </Grid>
            <Grid item>
                <p className="font-normal text-gray-500 lg:text-base">
                    Adding artists similar to{" "}
                    <span className={classes.artistName}>{artistName}</span>
                </p>
            </Grid>
        </Grid>
    );
};

const NotFoundSimilarArtistsLabel = ({
    artistName
}: NotFoundSimilarArtistsProps) => {
    const classes = useStyles();

    return (
        <p className="font-normal text-gray-500 lg:text-base">
            No Facebook interests were found for artists that are similar to{" "}
            <span className={classes.artistName}>{artistName}</span>
        </p>
    );
};

const TITLE = "Search for Similar Artists to Target:";
const DESCRIPTION =
    "Enter artists that are in your genre or make music similar to yours, and we’ll automatically look for them on Facebook.";

const AudienceTargeting = ({
    genre,
    initialTargetingAudiences,
    spotifyId,
    deleteIconColor,
    setAudiences,
    setArtists,
    customAudiences,
    setCustomAudiences,
    openTargetingModal,
    openAdditionalPagesModal,
    additionalPageAudiences,
    setAdditionalPagesAudiences
}: AudienceTargetingProps) => {
    const { artists: contextArtists } = useContext(MarketingDataContext);
    const { loggedInFbUser } = useContext(ConnectionsContext);
    const classes = useStyles();
    const [addingNewTargetingAudiences, setAddingNewTargetingAudiences] =
        useState(false);
    const [spotifyArtists, setSelectedSpotifyArtists] = useState(
        [] as SpotifyArtistsOption[]
    );
    const [
        suggestedTargetingAudienceByArtist,
        setSuggestedTargetingAudienceByArtist,
    ] = useState([] as FbSuggestion[]);
    const [deletedArtist, setDeletedArtist] = useState(
        [] as SpotifyArtistsOption[]
    );
    const [deletedAudience, setDeletedAudience] = useState({
        artistId: null,
        audiences: [],
    } as { artistId: string | null; audiences: FbSuggestion[] });

    const lastContextArtist = getLastSpotifyArtist(contextArtists);
    const lastSpotifyArtist = getLastSpotifyArtist(spotifyArtists);
    const artistName = lastSpotifyArtist.name || lastContextArtist.name || "";
    const showArtistLoader = addingNewTargetingAudiences && artistName;
    const showNotFoundSimilarArtistsLabel =
        !suggestedTargetingAudienceByArtist.length &&
        !showArtistLoader &&
        artistName;

    const handleDeleteAudiences = (
        artistId: string | null,
        selectedAudiences: FbSuggestion[]
    ) => {
        setDeletedAudience({ artistId, audiences: selectedAudiences });
    };

    useEffect(() => {
        if (Boolean(spotifyArtists.length)) {
            getFbSuggestionsByArtist({
                spotifyArtists,
                spotifyId,
                setSuggestedTargetingAudienceByArtist,
                setLoading: setAddingNewTargetingAudiences,
                access_token: loggedInFbUser?.access_token,
            });
        }
    }, [spotifyArtists, spotifyId]);

    return (
        <InfoSection title={TITLE} description={DESCRIPTION}>
            <div>
                <AutoCompleteSpotifyArtists
                    {...{
                        deletedAudience,
                        setDeletedAudience,
                        setSelectedSpotifyArtists,
                        setDeletedArtist,
                        setArtists,
                        deleteIconColor,
                    }}
                />
            </div>
            <div className="mt-7">
                <h5 className="text-dark font-bold text-lg">
                    Your ads will target these audiences:
                </h5>
                {showArtistLoader && <SimilarArtistLoader {...{ artistName }} />}
                {showNotFoundSimilarArtistsLabel && (
                    <NotFoundSimilarArtistsLabel {...{ artistName }} />
                )}
                <div className={classes.container}>
                    <AutoCompleteAudiences
                        {...{
                            genre,
                            deletedArtist,
                            setDeletedArtist,
                            initialTargetingAudiences,
                            suggestedTargetingAudienceByArtist,
                            handleDeleteAudiences,
                            setAudiences,
                            deleteIconColor,
                            customAudiences,
                            setCustomAudiences,
                            additionalPageAudiences,
                            setAdditionalPagesAudiences: setAdditionalPagesAudiences

                        }}
                    />

                </div>
                <div className="mx-auto text-center"><OutlinedButton
                    onClick={openTargetingModal}
                >+ Import your Existing FB Custom Audiences</OutlinedButton></div>

                <div className="mx-auto text-center mt-2"><OutlinedButton
                    onClick={openAdditionalPagesModal}
                >+ Target Additional FB/IG Page Audiences</OutlinedButton></div>

            </div>

        </InfoSection>
    );
};

export default AudienceTargeting;
