import React, { useState, useEffect, useContext } from 'react';
import Axios from 'helpers/Interceptor';
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, TooltipProps } from 'recharts';
import dayjs from 'dayjs';
import { Redirect } from 'react-router-dom';
import LoaderV2 from 'components/Loader/LoaderV2';
import { StyledLabel, Container } from 'styles/shared';
import { MenuItem, Select, TextField, Checkbox, ListItemText } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { CSVLink } from 'react-csv';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { CurrentUserContext } from 'Hooks/CurrentUserContext';

interface CustomTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
    active?: boolean;
    payload?: any[];
    label?: string;
}

interface SignupDataItem {
    period: string;
    total: number;
    source: { [key: string]: number };
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const total = payload.reduce((sum, entry) => sum + entry.value, 0);
        return (
            <div className="p-2 bg-white shadow-lg border border-gray-300 rounded">
                <p className="mb-2 font-bold">{label}</p>
                <p className="mb-2 font-bold">Total: {total.toLocaleString('en')}</p>
                {payload.map((entry, index) => (
                    <p key={`payload-${index}`} className="mb-1 text-gray-700">
                        {entry.name}: {entry.value.toLocaleString('en')}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const SourcesTrends = ({ type }: { type: "logins" | "signups" }) => {
    const { isSymphonyAdmin } = useContext(CurrentUserContext)

    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [signupData, setSignupData] = useState<SignupDataItem[]>([]);
    const [allSignupData, setAllSignupData] = useState<SignupDataItem[]>([]);
    const [source, setSource] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [period, setPeriod] = useState<string>('day');
    const [availableSources, setAvailableSources] = useState<string[]>([]);

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#d45087', '#2f4b7c', '#ff7c43', '#a05195', '#665191'];

    const fetchSignupData = async (showButtonLoader = false) => {
        if (showButtonLoader) {
            setButtonLoading(true);
        }
        try {
            const uri = type === 'logins' ? '/admin/funnel/get-logins' : '/admin/funnel/get-signups';
            const response = await Axios.get(uri, {
                params: {
                    startDate, endDate, period,
                }
            });
            const data: SignupDataItem[] = response.data.data;
            setSignupData(data);
            setAllSignupData(data);
            const uniqueSources: string[] = Array.from(new Set(data.flatMap((item) => Object.keys(item.source))));

            setAvailableSources(uniqueSources);

        } catch (error) {
            console.error(error);
        } finally {
            setButtonLoading(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSignupData();
    }, []);

    const handleFilterSources = () => {
        fetchSignupData(true);
    };

    const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSource(event.target.value as string[]);
    };

    const filterSignupData = () => {
        const mapped = allSignupData.map((item) => {
            const filteredSources = Object.keys(item.source)
                .filter((key) => {
                    if (source && source.length > 0) {
                        return source.includes(key)
                    } else {
                        return true
                    }
                })
                .reduce((obj, key) => {
                    obj[key] = item.source[key];
                    return obj;
                }, {} as { [key: string]: number });
            return {
                ...item,
                source: filteredSources,
            };
        });

        return mapped
    };

    const csvHeaders = [
        { label: "Period", key: "period" },
        { label: `Total ${type === "logins" ? 'Logins' : 'Singups'}`, key: "total" },
        ...availableSources.map((src) => ({ label: src, key: src }))
    ];

    const csvData = signupData.map((item) => {
        const flattenedItem: any = { period: item.period, total: item.total };
        Object.keys(item.source).forEach((key) => {
            flattenedItem[key] = item.source[key];
        });
        return flattenedItem;
    });

    if (!isSymphonyAdmin) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Container flexDirection="column" alignItems="center" justifyContent="center" padding="20px" gap="20px">
            {loading ? (
                <LoaderV2 />
            ) : (
                <>
                    <Container flexDirection="column" backgroundColor="#fff" padding="20px" borderRadius="8px" display='flex'>
                        <StyledLabel fontSize={20} padding="0px auto 20px">{type === "logins" ? "Logins" : "Signups"} Tracker</StyledLabel>
                        <Container flexDirection="row" gap="12px" alignItems="center" display='flex'>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Select
                                value={period}
                                onChange={(e) => setPeriod(e.target.value as string)}
                                IconComponent={ArrowDropDown}
                            >
                                <MenuItem value="day">Day</MenuItem>
                                <MenuItem value="week">Week</MenuItem>
                                <MenuItem value="month">Month</MenuItem>
                            </Select>
                            <Select
                                multiple
                                value={source}
                                onChange={handleSourceChange}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                                IconComponent={ArrowDropDown}
                            >
                                <MenuItem value="" disabled>All Sources</MenuItem>
                                {availableSources.map((src, index) => (
                                    <MenuItem key={index} value={src}>
                                        <Checkbox checked={source.indexOf(src) > -1} />
                                        <ListItemText primary={src} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <ButtonSymphony
                                className="filterSourcesButton"
                                isLoading={buttonLoading}
                                onClick={handleFilterSources}
                            >
                                Filter
                            </ButtonSymphony>
                        </Container>

                        <ResponsiveContainer width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.5}>
                            <AreaChart
                                data={filterSignupData()}
                                margin={{ top: 15, right: 0, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid stroke="#8800FF29" strokeWidth={1.5} strokeDasharray="8 8" />
                                <XAxis dataKey="period" />
                                <YAxis />
                                <Tooltip content={<CustomTooltip />} />
                                {availableSources.map((src, index) => (
                                    <defs key={index}>
                                        <linearGradient id={`colorSignup${index}`} x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={colors[index % colors.length]} stopOpacity={0.8} />
                                            <stop offset="95%" stopColor={colors[index % colors.length]} stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                ))}
                                {availableSources.map((src, index) => {
                                    return <Area
                                        key={index}
                                        type="monotone"
                                        dataKey={`source.${src}`}
                                        stroke={source.includes(src) || source.length === 0 ? colors[index % colors.length] : "#d3d3d3"}
                                        fillOpacity={1}
                                        fill={`url(#colorSignup${index})`}
                                    />
                                })}
                            </AreaChart>
                        </ResponsiveContainer>
                        <Container flexDirection="row" justifyContent="center" marginTop="20px">
                            <CSVLink
                                data={csvData}
                                headers={csvHeaders}
                                filename={`signup_data_${startDate}_to_${endDate}.csv`}
                                style={{ textDecoration: 'none' }}
                            >
                                <ButtonSymphony
                                    className="downloadCSVButton"
                                    variant="outlined"
                                    onClick={() => { }}
                                >
                                    Download CSV
                                </ButtonSymphony>
                            </CSVLink>
                        </Container>
                    </Container>
                </>
            )}
        </Container>
    );
};

export default SourcesTrends;
