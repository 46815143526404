/**
 * Type definition for title alignment theme style options
 * @interface TitleAlignmentThemeStyleType
 * @property {string} title - Display title for the alignment option
 * @property {string} name - Identifier name for the alignment option
 * @property {number} order - Order in which the alignment option should appear
 */
export type TitleAlignmentThemeStyleType = {
    title: string;
    name: string;
    order: number;
};

/**
 * Type representing the possible title alignment values
 * @type {string}
 */
export type TitleAlignment = "left" | "center" | "right";

/**
 * Configuration object containing all available title alignment theme styles
 * @constant
 * @type {Record<string, TitleAlignmentThemeStyleType>}
 * @property {TitleAlignmentThemeStyleType} left - Left alignment option
 * @property {TitleAlignmentThemeStyleType} center - Center alignment option 
 * @property {TitleAlignmentThemeStyleType} right - Right alignment option
 */
export const titleAlignmentThemeStyles: Record<TitleAlignment, TitleAlignmentThemeStyleType> = {
    left: {
        title: "Left",
        name: "left", 
        order: 1,
    },
    center: {
        title: "Center",
        name: "center",
        order: 2,
    },
    right: {
        title: "Right",
        name: "right",
        order: 3,
    },
} as const;