import styled from 'styled-components';
import Colors from "modules/Colors";

interface StyledBtnProps {
  selected?: boolean;
  width?: string;
}

interface ChartHeaderProps {
  flexDirection?: string;
}

interface RightSideHeaderProps {
  overflow?: string;
  maxWidth?: string;
  display?: string;
}

export const OverallChartContainer = styled.div<{ borderRadius?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
  background: #FFFFFF;
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  padding: 16px;
`;

export const ChartHeader = styled.div<ChartHeaderProps>`
  display: flex;
  padding-right: 48px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : "row"};
`;

export const LeftSideHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

export const RightSideHeader = styled.div<RightSideHeaderProps>`
  display: ${({ display }) => display ? display : "flex"};
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  flex-direction: column;
  gap: 4px;
  overflow: ${({ overflow }) => overflow };
  max-width: ${({ maxWidth }) => maxWidth };

`;

export const StatsCardsContainer = styled.div<ChartHeaderProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : "row"};
`;

export const LowerSection = styled.div<ChartHeaderProps>`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  justify-content: space-around;
  flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : "row"};
`;

export const DateButton = styled.button<StyledBtnProps>`
  background: ${({ selected }) => selected ? Colors.purple : "none"};
  color: ${({ selected }) => selected ? Colors.textColorWhite : Colors.purple};
  &:last-of-type {
    margin-right: 0;
  }
  width: ${({ width }) => width ? width : "auto"};
  border-radius: 6px;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  font-family: DIN;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 24px;
  gap: 16px;
`;
