import { useContext } from "react";
import { Avatar } from "@material-ui/core";
import useStyles, {
  GradientRoundedContainer,
  WhiteRoundedContainer,
  AvatarContainer,
  StyledLabelProps,
} from "./styles";
import GemIcon from "../ProCallouts/GemIcon";
import { Container, ContainerProps, StyledLabel } from "styles/shared";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { PlanType } from "modules/Const";

interface CustomAvatarProps {
  avatarOverride?: string | null;
  withoutWhiteBorder?: boolean;
}

const CustomAvatar = ({
  avatarOverride = null,
  withoutWhiteBorder,
}: CustomAvatarProps) => {
  const classes = useStyles();
  const { isPaidBrand, currentBrand } = useContext(CurrentBrandContext)

  if (!currentBrand) {
    return null
  }

  const { image } = currentBrand
  const avatar = avatarOverride || image || ''

  return (
    <>
      {isPaidBrand &&
        <>
          {withoutWhiteBorder && (
            <GradientRoundedContainer width="37px" height="37px" planType={currentBrand.tier}>
              <AvatarContainer top="2.3px">
                <Avatar src={avatar} className={classes.avatar} />
              </AvatarContainer>
            </GradientRoundedContainer>
          )}
          {!withoutWhiteBorder && (
            <GradientRoundedContainer width="42px" height="42px" planType={currentBrand.tier}>
              <WhiteRoundedContainer>
                <AvatarContainer top="3px">
                  <Avatar src={avatar} className={classes.avatar} />
                </AvatarContainer>
              </WhiteRoundedContainer>
            </GradientRoundedContainer>
          )}
        </>
      }
      {!isPaidBrand && <Avatar src={avatar} className={classes.avatar} />}
    </>
  );
};

interface Props {
  withoutWhiteBorder?: boolean;
  mainContainerProps?: ContainerProps;
  proStatusContainerProps?: ContainerProps;
  userNameTextProps?: StyledLabelProps;
  proTextProps?: StyledLabelProps;
  userContainerProps?: ContainerProps;
  avatarContainerProps?: ContainerProps;
  smallSidebar?: boolean;
  avatarOverride?: string;
}

const AvatarUserStatus = ({
  withoutWhiteBorder,
  mainContainerProps,
  proStatusContainerProps,
  userNameTextProps,
  proTextProps,
  avatarContainerProps,
  userContainerProps,
  smallSidebar,
  avatarOverride = ''
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)

  if (!currentBrand) {
    return null
  }

  const { image, name: brandName } = currentBrand
  const avatar = avatarOverride || image || ''
  const renderAccountStatus = () => {
    if (!currentBrand.tier) return null;

    const tier = currentBrand.tier

    let text = ''
    let icon = null
    switch (tier) {
      case PlanType.PRO:
        text = 'PRO ACCOUNT'
        icon = <GemIcon />
        break
      case PlanType.LITE:
        text = 'LITE ACCOUNT'
        icon = <GemIcon />
        break
      default:
        text = ''
    }

    return (
      <Container className="avatarUserStatus" alignItems="center" {...proStatusContainerProps} display="flex">
        {icon}
        <StyledLabel {...proTextProps}>
          {text}
        </StyledLabel>
      </Container>
    );
  };

  return (
    <Container {...mainContainerProps} display="flex" alignItems="center">
      <Container {...avatarContainerProps}>
        <CustomAvatar
          avatarOverride={avatar}
          withoutWhiteBorder={withoutWhiteBorder}
        />
      </Container>
      <Container
        {...userContainerProps}
        display={smallSidebar ? "none" : "flex"}
        flexDirection="column"
        alignItems="flex-start"
      >
        <StyledLabel {...userNameTextProps}>{brandName}</StyledLabel>
        {renderAccountStatus()}
      </Container>
    </Container>
  )
}

export default AvatarUserStatus
