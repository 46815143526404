import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { AssetUploadTypes, UploadedAssetType } from "../utils/fbCampaigns"
import { clsx } from 'modules/Utils'
import useAdPreviewStyles from "components/shareable/FacebookAdPreview/styles"
import { CreativeAssetTypes } from "../constants"

const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface Props {
  selectedInstagramPost?: InstagramPost
  creativeSelectorTab: AssetUploadTypes
  uploadedImages: UploadedAssetType[] | null
  previewedVideo: UploadedAssetType | null
  show?: boolean
  enablePreviewMode: (value: boolean) => void
}

const getPreviewImage = (data: {
  selectedInstagramPost?: InstagramPost
  creativeSelectorTab: AssetUploadTypes
  uploadedImages: UploadedAssetType[] | null
  previewedVideo: UploadedAssetType | null
}) => {
  const {
    creativeSelectorTab,
    selectedInstagramPost,
    previewedVideo,
    uploadedImages,
  } = data

  if (creativeSelectorTab === INSTAGRAM_POSTS) {
    if (selectedInstagramPost) {
      switch (selectedInstagramPost.media_type) {
        case "CAROUSEL_ALBUM":
          return selectedInstagramPost.media_url
        case "VIDEO":
          return selectedInstagramPost.thumbnail_url
        case "IMAGE":
          return selectedInstagramPost.media_url
      }
    }

    return undefined
  }

  if (creativeSelectorTab === UPLOAD_VIDEO) {
    if (uploadedImages && uploadedImages.length > 0) {
      const firstImage = previewedVideo ? previewedVideo : uploadedImages[0]
      return firstImage.thumbnail
    }
    return undefined
  }

  return undefined
}

export function MobileAdPreviewBar({
  selectedInstagramPost,
  creativeSelectorTab,
  uploadedImages,
  previewedVideo,
  show,
  enablePreviewMode,
}: Props) {
  const adPreviewStyles = useAdPreviewStyles()

  if (!show) return null

  const previewImage = getPreviewImage({
    creativeSelectorTab,
    previewedVideo,
    selectedInstagramPost,
    uploadedImages,
  })

  return !previewImage ? null : (
    <button
      onClick={() => enablePreviewMode(true)}
      className={clsx(
        `${!show ? "hidden" : adPreviewStyles.shownPreviewAdButton} ${adPreviewStyles.previewAdButton
        } `,
        "flex items-center justify-between"
      )}
    >
      <div className="w-10 h-10">
        <img
          className="w-full h-full object-contain rounded-sm"
          src={previewImage}
          alt="Ad Preview"
        />
      </div>
      <p className="text-md ml-2">View Ad Preview</p>
    </button>
  )
}
