

import LoadingIndicator from "components/Loader/LoadingIndicator";

interface Props {
  loading: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const PaymentMethodButton = ({
  loading,
  className,
  disabled,
  onClick,
}: Props) => (
  <button {...{ disabled, className, onClick }} type="button">
    {loading ? (
      <LoadingIndicator color="black" height="25px" />
    ) : (
      "Refresh Payment Method"
    )}
  </button>
);

export default PaymentMethodButton;
