import styled from 'styled-components'

export const BannerParent = styled.div`
    border-radius: 12px;
    background:#F5EBFF;
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `;

  export const BannerTitle = styled.p`
    font-size: 1.25rem;
  `

  export const BannerSubtitle = styled.p`
    font-size: 0.9rem;
    color: #707070;
  `

  export const IconComponentParent = styled.div`
    margin-right: 20px;
  
  `