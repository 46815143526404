import { useContext } from 'react';
import {
    BillingChipContainer,
} from '../styles'; // Adjust the import path as necessary
import { BillingCycle } from '../utils';
import { BillingChip } from './BillingChip';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { CurrencyCodesType } from 'types/global';
import useScreen from 'Hooks/useScreen';
import { DEFAULT_CURRENCY, PlanType } from 'modules/Const';

type BillingPlanOptionsProps = {
    className?: string;
    billingCycle: BillingCycle;
    partnerDiscount?: number | undefined;
    setBillingCycle: (billingCycle: BillingCycle) => void;
    specificCurrencyCode?: CurrencyCodesType;
    planType?: PlanType;
};

const BillingPlanOptions = ({
    className,
    billingCycle,
    setBillingCycle,
    partnerDiscount,
    specificCurrencyCode,
    planType = PlanType.PRO
}: BillingPlanOptionsProps) => {
    const { currentBrand } = useContext(CurrentBrandContext)

    const { mobileView } = useScreen();

    const handleChangeBillingCycle = (billingCycle: BillingCycle) => () => {
        // ...your logic to change billing cycle
        setBillingCycle(billingCycle);
    };

    const { ANNUAL: ANNUALLY, MONTHLY } = BillingCycle;

    const currencyCode = specificCurrencyCode ? specificCurrencyCode : (currentBrand?.currency?.code || DEFAULT_CURRENCY);

    return (
        <BillingChipContainer
            className={`billingChipComponent ${className}`}
            isMobileView={mobileView}
            flexDirection="column"
        >
            <BillingChip
                discountPercentage={partnerDiscount}
                isMobileView={mobileView}
                cycle={MONTHLY}
                isActive={billingCycle === MONTHLY}
                onClick={handleChangeBillingCycle(MONTHLY)}
                currency={currencyCode}
                planType={planType}
            />
            <BillingChip
                isMobileView={mobileView}
                cycle={ANNUALLY}
                isActive={billingCycle === ANNUALLY}
                onClick={handleChangeBillingCycle(ANNUALLY)}
                currency={currencyCode}
                planType={planType}
            />
        </BillingChipContainer>
    );
};

export default BillingPlanOptions;
