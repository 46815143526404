import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  svgRotated: {
    transform: 'rotate(-90deg)',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#707070',
  },
  svgEnabledContainer: {
    '& :hover': {
      color: '#8800ff',
    },
  },
  svgDisabledContainer: {
    '& :hover': {
      color: '#b2b2b2',
    },
  },
  svgGray: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#707070',
  },
  svgDisabled: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: '#edecf2',
  },
  datagridRoot: {
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#edecf252',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontFamily: 'DIN',
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: 400,
      color: '#707070',
    },
  },
}));

export const LiveLabel = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #07c806;
`;

export const PausedLabel = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #e4900d;
`;

export const LiveLabelContainer = styled.div`
  background: rgba(7, 200, 6, 0.08);
  border-radius: 4px;
  align-items: center;
  padding: 8px;
  width: 56px;
  height: 31px;
`;

export const PausedLabelContainer = styled.div`
  background: rgba(228, 144, 13, 0.08);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 56px;
  height: 31px;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: fit-content;
`;

export const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledLabel = styled.p`
  height: fit-content;
  font-size: 16px;
  white-space: normal;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const ButtonContainer = styled.button`
  margin: '0px 8px';
  :disabled {
    background-color: transparent;
    border: none;
  }
`;

export default useStyles;
