import React, { useEffect, useContext } from "react";
import {
  DashIcon,
  MktgIcon,
  SettingsIcon,
  SupportIcon,
  WebsiteIcon,
  FansIcon,
  FansIconPath,
  DashboardIconPath,
  MarketingIconPath,
  WebsiteIconPath,
  SettingsIconPath,
  GiftIcon,
  GiftIconPath,
  SupportIconPath,
  LearnIcon,
  LearnIconPath,
  TeamIconPath,
  TeamIcon
} from "../svg-icons"
import Axios from "helpers/Interceptor";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import Intercom from "helpers/Intercom";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import useScreen from "Hooks/useScreen";
import { useNavbar } from "Hooks/useNavbar";

export const showIntercom = () => {
  // @ts-ignore
  Intercom.open();
}

export const showNewMessageIntercom = () => {
  // @ts-ignore
  Intercom.showNewMessage();
}

export interface NavigationItem {
  name: string;
  smallName?: string;
  iconComponent?: React.ComponentType<{ color?: string; height?: string; width?: string }>;
  onClick?: (e: any) => void;
  href?: string;
  alternateHrefs?: string[];
  iconPath?: React.ReactElement,
  svgIconViewbox?: string;
  dropdownItems?: NavigationItem[]
  showOnMobileMainMenu: boolean;
  showOnDesktopMainMenu: boolean;
  showOnMobileSubMenu: boolean;
  showOnDesktopSubMenu: boolean;
  needsOrganization?: boolean;
}

export const getNavLinks = (isProBrand: boolean): NavigationItem[] => [
  {
    name: "Dashboard",
    href: "/",
    alternateHrefs: ["/dashboard"],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <DashIcon {...props} />,
    iconPath: <DashboardIconPath />,
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: true,
    showOnDesktopMainMenu: true,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
  {
    name: "Marketing",
    href: "/marketing",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <MktgIcon {...props} />,
    iconPath: <MarketingIconPath />,
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: true,
    showOnDesktopMainMenu: true,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
  {
    name: "Website",
    href: "/website",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <WebsiteIcon {...props} />,
    iconPath: <WebsiteIconPath />,
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: true,
    showOnDesktopMainMenu: true,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
  {
    name: "Fans",
    href: "/fans",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
    iconPath: <FansIconPath />,
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: true,
    showOnDesktopMainMenu: true,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
  {
    name: "Team",
    href: "/team",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <TeamIcon {...props} />,
    iconPath: <TeamIconPath />,
    svgIconViewbox: "0 0 20 18",
    showOnMobileMainMenu: false,
    showOnDesktopMainMenu: true,
    showOnMobileSubMenu: true,
    showOnDesktopSubMenu: true,
    needsOrganization: true,
  },
  {
    name: "Learn",
    href: "/learn",
    alternateHrefs: [],
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <LearnIcon {...props} />,
    iconPath: <LearnIconPath />,
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: false,
    showOnDesktopMainMenu: true,
    showOnMobileSubMenu: true,
    showOnDesktopSubMenu: true,
    dropdownItems: [
      {
        name: "Videos",
        href: "/learn",
        alternateHrefs: [],
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
        iconPath: <FansIconPath />,
        svgIconViewbox: "0 0 18 18",
        showOnMobileMainMenu: false,
        showOnDesktopMainMenu: false,
        showOnMobileSubMenu: true,
        showOnDesktopSubMenu: true,
      },
      {
        name: "Help Center",
        alternateHrefs: [],
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <FansIcon {...props} />,
        iconPath: <FansIconPath />,
        svgIconViewbox: "0 0 18 18",
        onClick: (e: any) => {
          e.preventDefault()
          Intercom.showHelpCenter()
        },
        showOnMobileMainMenu: false,
        showOnDesktopMainMenu: false,
        showOnMobileSubMenu: true,
        showOnDesktopSubMenu: true,
      },
      {
        name: "Contact Support",
        iconComponent: (props: { color?: string, height?: string, width?: string }) => <SupportIcon {...props} />,
        iconPath: <SupportIconPath />,
        onClick: (e: any) => {
          e.preventDefault()
          Intercom.showMessages()
        },
        svgIconViewbox: "0 0 18 18",
        showOnMobileMainMenu: false,
        showOnDesktopMainMenu: false,
        showOnMobileSubMenu: false,
        showOnDesktopSubMenu: isProBrand,
      },
    ]
  },

  // only shown on mobile - additional options
  {
    name: "Settings",
    href: '/settings',
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <SettingsIcon {...props} />,
    iconPath: <SettingsIconPath />,
    svgIconViewbox: "0 0 22.54 22.59",
    showOnMobileMainMenu: false,
    showOnDesktopMainMenu: false,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
  {
    name: "Refer & Earn",
    smallName: "Refer",
    href: '/refer',
    alternateHrefs: [],
    iconComponent: (props: {}) => <GiftIcon {...props} />,
    iconPath: <GiftIconPath />,
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: false,
    showOnDesktopMainMenu: false,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
  {
    name: "Contact Support",
    iconComponent: (props: { color?: string, height?: string, width?: string }) => <SupportIcon {...props} />,
    iconPath: <SupportIconPath />,
    onClick: (e: any) => {
      e.preventDefault()
      showIntercom()
    },
    svgIconViewbox: "0 0 18 18",
    showOnMobileMainMenu: false,
    showOnDesktopMainMenu: false,
    showOnMobileSubMenu: false,
    showOnDesktopSubMenu: false,
  },
]

const Navbar = () => {
  const { mobileView } = useScreen();
  const { shouldHideNavbar } = useNavbar();
  const { currentBrand, isPaidBrand } = useContext(CurrentBrandContext)
  const { loadedBrands } = useContext(CurrentUserContext);
  const { organization } = useCurrentTeam();

  /** 
   * Determines if there's a valid organization associated with the current context
   * True if either:
   * 1. There's an active organization with a name from the team context
   * 2. The current brand is from admin search and has an associated organization
   */
  const hasOrganization = (organization && organization.name) || (currentBrand?.isFromAdminSearch && currentBrand?.organization);

  // Brand-related states
  /** ID of the currently selected brand */
  const brandId = currentBrand?.id;
  /** Determines if the current user has pro-tier access */

  /**
   * Determines if the navbar should be hidden based on the following rules:
   * 1. Hidden on specific routes (/subscribe, /marketing2/new, /team)
   * 2. Hidden on /add-artist route when on mobile
   * 3. Hidden during campaign creation or website editing
   * 4. Hidden when organization is waiting for details
   * 5. Hidden when there's no current brand selected
   */
  


  // Navbar visibility control
  /** Determines if the navbar should be hidden based on multiple conditions */
  const hideNavbar = shouldHideNavbar(mobileView, organization, currentBrand);
  // Navigation links processing
  /** Get base navigation links based on user's pro status */
  let navLinks = getNavLinks(isPaidBrand);

  useEffect(() => {
    /** Set the brand ID in axios headers, falling back to localStorage if needed */
    const id = brandId || Number(localStorage.getItem("selected-brand"));
    Axios.defaults.headers.common["brand-id"] = id;
  }, [brandId]);

  // useEffect(() => {
  //   /** Adjust main content padding when in special views */
  //   if (isCampaignWizard || isAddArtist || hideNavbar) {
  //     document?.getElementById('main-content')?.classList.remove('lg:pl-14');
  //   }
  // }, [isCampaignWizard, isAddArtist, hideNavbar]);

  /** Remove Fans tab if specified in brand settings */
  if (currentBrand?.hideFansTab) {
    navLinks = navLinks.filter((navItem: NavigationItem) => navItem.name !== "Fans");
  }

  /** Update Team visibility based on organization status */
  if (hasOrganization) {
    navLinks = navLinks.map(item =>
      item.name === "Team"
        ? { ...item, hideOnDesktop: false, hideOnMobile: true }
        : item
    );
  }

  if (hideNavbar) {
    return null;
  }

  if (mobileView) {
    return (
      <MobileNavigation
        navigationItems={navLinks}
      />
    )
  }

  return (
    <DesktopNavigation
      navigationItems={navLinks}
      loadedBrands={loadedBrands}
    />
  )
}

export default Navbar
