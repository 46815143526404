import { makeStyles } from "@material-ui/core"
import Colors from "modules/Colors"

export const useSharedStyles = makeStyles(() => ({
  playIcon: {
    height: "24px",
    width: "24px",
    display: "inline",
    "& path": {
      stroke: Colors.purple,
    },
  },
  expernalLinkIcon: {
    height: "24px",
    width: "24px",
    display: "inline",
  }
}));