import StatsCard from "./card";
import { GridContainer } from "./styles";
import { StatCardDataPoint } from "./utils";

interface StatsCardRowProps {
    stats: StatCardDataPoint[];
    disablePadding?: boolean
}

const StatsCardRow: React.FC<StatsCardRowProps> = ({ stats }) => {
    return (
        <GridContainer>
            {stats.map((stat, index) => (
                <StatsCard
                    key={index}
                    stat={stat}
                    hideCurrencyLabel={stat.hideCurrencyLabel}
                    className={index === 0 ? 'border-t' : ''}
                />
            ))}
        </GridContainer>
    );
};

export default StatsCardRow