import { CSSProperties, useContext, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useScreen from 'Hooks/useScreen'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { showToast } from "modules/Utils"
import WebsiteTypeBadge from 'components/shareable/ContentTypeBadge/website'
import { Caption } from 'components/shareable/Typography'
import { StarsIcon } from 'components/svg-icons'
import PresaveMiniature from 'pages/post-auth/MarketingPage/PreSave/Components/PresaveMiniature'
import { Container, StyledLabel } from 'styles/shared'
import { BrandContent, Website, WEBSITE_TYPES, WebsiteType } from 'types/global'
import Colors from "modules/Colors"
import { WEBSITE_CREATION_TYPE } from '../types'
import { RowContainer } from 'pages/post-auth/MarketingPage/Components/CampaignCard/styles'
import LinkedCampaignsBanner from 'components/shareable/LinkedCampaignsBanner'
import BoostButton from 'components/shareable/BoostButton'
import { ReactComponent as LinkIcon } from 'assets/images/link-gray.svg'
import ButtonSymphony from 'components/shareable/ButtonSymphony'
import { WEBSITE_TABS } from './CustomizeWebsite/utils'

dayjs.extend(relativeTime)

/** Props for the WebsiteListItem component */
interface WebsiteListItemProps {
    /** URL of the thumbnail image */
    thumbnailSrc: string
    /** Content data for the website */
    content: BrandContent
    /** Main title text to display */
    titleText: string
    /** Optional subtitle content */
    subtitle?: ReactNode
    /** Creation timestamp */
    createdAt?: string
    /** Last update timestamp */
    updatedAt?: string
    /** Full URL of the website */
    websiteUrl: string
    /** Website styling configuration */
    websiteStyle?: Website
    /** Type of website (e.g. record streams) */
    websiteType?: WebsiteType

    /** Optional label for website subtype */
    websiteSubtypeLabel?: string | null
    /** How the website was created (automatic, presave, etc) */
    creationType?: WEBSITE_CREATION_TYPE
    /** Click handler */
    onClick: () => void
    /** Optional callback when item is actioned */
    setActionedItem?: undefined | null | ((item: BrandContent) => void)
    /** Primary link to navigate to when clicking */
    linkToEdit: string
}

/** Default styling for websites */
const defaultWebsiteStyle = {
    bgType: 'color',
    primaryColor: 'white',
    buttonConfig: 'stacked',
    secondaryColor: 'white',
    bgColor: 'red',
}

/**
 * Component that displays a website list item with thumbnail, details and actions
 */
const WebsiteListItem = ({
    thumbnailSrc,
    titleText,
    createdAt,
    updatedAt,
    websiteUrl,
    websiteSubtypeLabel,
    linkToEdit,
    creationType,
    websiteType = WEBSITE_TYPES.RECORD_STREAMS,
    websiteStyle = defaultWebsiteStyle,
    content
}: WebsiteListItemProps) => {
    const history = useHistory()
    const { mobileView, largeView } = useScreen()
    const { isBrandAdmin } = useContext(CurrentBrandContext)

    const hasAdminAccess = isBrandAdmin



    /** Handles navigation when edit options are clicked */
    const handleClickActionButton = (websiteTab: WEBSITE_TABS) => {
        history.push(`${linkToEdit}?tab=${websiteTab}`)
    }


    /** Copies website URL to clipboard and shows toast */
    const onClickCopyLink = () => {
        navigator.clipboard.writeText(websiteUrl);
        showToast({ message: "Your website URL was copied to your clipboard.", mobile: mobileView })
    }

    /** Renders the creation type badge and text if applicable */
    const renderCreationType = () => {
        let text = null

        if (!text) {
            return null
        }

        if (websiteType === WEBSITE_TYPES.RECORD_STREAMS) {
            switch (creationType) {
                case WEBSITE_CREATION_TYPE.AUTOMATIC:
                    text = 'This site was created automatically when your release came out.'
                    break
                case WEBSITE_CREATION_TYPE.PRESAVE:
                    text = 'This site was converted to a release page after your pre-save campaign ended.'
                    break
                case WEBSITE_CREATION_TYPE.CAMPAIGN:
                    text = 'This website is linked to an ads campaign you\'re running.'
                    break
                default:
                    break
            }
        }

        return (
            <div className='mb-1 text-sm text-gray-500'>
                <Container display='flex' flexDirection='row' gap="4px">
                    <StarsIcon color={Colors.purple} />
                    <StyledLabel>{text}</StyledLabel>
                </Container>
            </div>
        )
    }

    /** Renders the copyable website URL container */
    const renderLinkContainer = () => {
        return (
            <div
                className="linkContainerV2"
                style={sty.linkContainerV2}
                onClick={onClickCopyLink}
            >
                <Caption color={Colors.greyDark}>{websiteUrl}</Caption>
                <LinkIcon />
            </div>
        )
    }

    /** Renders the main content section with thumbnail and details */
    const linkedContent = (
        <div
            className='linkedContentContainer flex flex-row items-center'
            style={linkToEdit ? { width: '100%' } : !largeView ? { width: '100%' } : { width: '50%' }}
        >
            <div className='mr-4'>
                <PresaveMiniature
                    image={thumbnailSrc}
                    style={websiteStyle}
                />
            </div>
            <div className="flex flex-col justify-center items-start gap-1">
                <div className="mb-1">
                    <h3 className=" titleTextWebsiteListItem font-normal text-lg">{titleText}</h3>
                </div>
                <div className="mb-1">
                    <RowContainer>
                        <WebsiteTypeBadge websiteType={websiteType} additionalLabel={websiteSubtypeLabel} />
                    </RowContainer>
                </div>
                {renderCreationType()}
                {dayjs(createdAt).isSame(dayjs(updatedAt)) ?
                    <Caption color={Colors.greyDark}>Created {dayjs(createdAt).fromNow()}</Caption>
                    :
                    <Caption color={Colors.greyDark}>Last updated {dayjs(updatedAt).fromNow()}</Caption>
                }
                {renderLinkContainer()}
                {Boolean(content?.linked_campaigns?.length) &&
                    <LinkedCampaignsBanner
                        linkedCampaigns={content.linked_campaigns || []}
                        thumbnailSrc={thumbnailSrc}
                        website={websiteStyle}
                        websiteName={titleText}
                    />
                }
            </div>
        </div>
    )

    const renderButtonContainer = () => {
        const buttonSize = mobileView ? 'small' : 'medium'

        return (
            <div
                className="websiteListItemButtonsContainer"
                style={sty.websiteListItemButtonsContainer}
            >
                {hasAdminAccess &&
                    <ButtonSymphony variant='text' onClick={() => handleClickActionButton(WEBSITE_TABS.STYLE)} size={buttonSize}>
                        Edit Site
                    </ButtonSymphony>
                }
                <ButtonSymphony variant='text' onClick={() => handleClickActionButton(WEBSITE_TABS.ANALYTICS)} size={buttonSize}>
                    View Analytics
                </ButtonSymphony>
                <ButtonSymphony variant='text' onClick={onClickCopyLink} size={buttonSize}>
                    Copy Link
                </ButtonSymphony>
                <BoostButton
                    className='boostButtonWebsiteListItem'
                    brandContent={content}
                    websiteType={websiteType}
                    collapseOnMobile
                    location='Website List'
                />
            </div>
        )
    }

    return (
        <div
            className={`websiteListItemComponent flex ${largeView ? 'flex-row' : 'flex-col'} justify-between items-center border-b px-0 py-5 md:px-4 md:py-7`}
        >
            <div className={`w-full flex items-center ${largeView ? 'justify-between' : 'justify-center'} gap-4 ${!largeView ? 'flex-col' : ''}`}>
                <div style={{ textDecoration: 'none', width: !largeView ? '100%' : '50%' }}>
                    {linkedContent}
                </div>
                <Container
                    gap="24px"
                    display='flex'
                    flexDirection={mobileView ? 'column' : 'row'}
                    justifyContent={mobileView ? 'center' : 'flex-end'}
                    alignItems='center'
                    width={!largeView ? '100%' : '50%'}
                >
                    {renderButtonContainer()}
                </Container >
            </div >
        </div >
    )
}

const sty: Record<string, CSSProperties> = {
    websiteListItemButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    linkContainerV2: {
        borderRadius: 4,
        padding: "4px 8px",
        display: "flex",
        flexDirection: "row",
        gap: 4,
        cursor: "pointer",
        backgroundColor: Colors.PAPER_DIVIDER_CONTAINER,
        alignItems: "center",
        width: "fit-content",
    },
}

export default WebsiteListItem