import {
    useEffect,
    useState,
    ChangeEvent,
    useContext,
} from "react"
import {
    ArtistImage as ChannelImage,
    ModalPlatformInputContainer,
    PlatformLogoContainer,
    useStyles,
    SearchListContainer,
} from "../../../pages/post-auth/AddArtist/style"
import { TextField, Grid, InputAdornment } from "@material-ui/core"
import { AudiomackArtistProfile } from "types/global"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import Axios from "helpers/Interceptor"
import isEqual from "lodash/isEqual"
import { track } from "analytics"
import { StyledLabel, TextButton } from "styles/shared"
import { ReactComponent as AudiomackIcon } from "assets/images/platforms/audiomack.svg";
import { clsx } from "modules/Utils"
import useScreen from "Hooks/useScreen"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import Colors from "modules/Colors"
import { pluralize } from "modules/Utils"

interface Props {
    closeModal: () => void;
    selected: string | number | undefined | null;
    open: boolean;
    onSave?: () => void;
}

export const queryAudiomackArtists = async (query: string): Promise<any> => {
    try {
        const results = await Axios.get(`/audiomack/search/artists`, {
            params: {
                q: query
            }
        })

        return results.data
    } catch (error: any) {
        throw error
    }
}

const AudiomackConnectv2 = ({
    closeModal,
    selected,
    open,
    onSave
}: Props) => {
    const classes = useStyles()
    const { mobileView } = useScreen();
    const { currentBrand } = useContext(CurrentBrandContext);

    const [loading, setLoading] = useState<boolean>(false)
    const [textFieldValue, setTextFieldValue] = useState("")

    const [results, setResults] = useState<AudiomackArtistProfile[]>([])

    const [error, setError] = useState(false)
    const showConnectButton = !isEqual(results, [selected])

    const [userSubmittedSearch, setUserSubmittedSearch] = useState(false)

    const getModalContainerHeight = (profiles: number) => {
        if (profiles > 2) {
            return "300px"
        }

        return "100%"
    }

    const containerHeight = mobileView ? '100%' : getModalContainerHeight(results.length)

    const pullResults = async (value: string) => {
        setUserSubmittedSearch(true)

        if (!value.trim().length) {
            setResults([])
            return
        }

        setLoading(true);

        let data = [];
        let error = null;

        try {
            const results = await queryAudiomackArtists(value);
            data = results?.data || [];
        } catch (e) {
            error = e;
        }

        if (error) {
            setError(true);
            setLoading(false);
        } else {
            setError(false);
            setResults(data.map((audiomackArtist: AudiomackArtistProfile) => ({
                ...audiomackArtist,
                id: audiomackArtist.id
            })));
            setLoading(false);
        }
    }

    useEffect(() => {
        setUserSubmittedSearch(false)
        if (results.length && !textFieldValue) {
            setResults([])
        }
    }, [textFieldValue])

    const updateInBrand = (channel: AudiomackArtistProfile) => {
        setLoading(true)
        if (currentBrand) {
            track("Linked Audiomack Profile")
            Axios.put(`/brand/${currentBrand.slug}/connect`, {
                id: currentBrand.id,
                name: currentBrand.slug,
                service: "audiomack",
                value: channel,
            })
                .then((_result) => {
                    onSave && onSave()
                    closeModal()
                    setError(false)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("error creating brand", err)
                    setError(true)
                })
        }
    }

    const handleOnClick = (profile: AudiomackArtistProfile) => () => {
        updateInBrand(profile)
    }

    const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setTextFieldValue(value)
    }

    useEffect(() => {
        setError(false)
        setLoading(false)
    }, [])

    const renderEndAdornment = () => {
        if (loading) {
            // Assuming LottieAnimation is a component or element that renders your Lottie animation
            return <InputAdornment position="end">
                <Lottie
                    height={24}
                    width={24}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: SymphonyLoadingLottie,
                    }}
                />
            </InputAdornment>;
        }

        return (
            <TextButton
                onClick={() => pullResults(textFieldValue)}
            >
                Search
            </TextButton>
        )
    }

    const mainPlaceholder = "Bad Bunny, Jay-Z..."

    const getHelperText = (args: {
        value: string
        error: boolean
        results: AudiomackArtistProfile[]
        loading: boolean
    }) => {
        const { value, error, results, loading } = args

        if (error) return "An error occurred. Try again later."
        if (results.length === 0 && value && !loading && userSubmittedSearch) {
            return `No artists found for "${textFieldValue}". If your profile doesn't pop up, try pasting your URL.`
        }
        return undefined
    }

    const helperText = getHelperText({
        value: textFieldValue,
        error,
        results,
        loading,
    })

    return (
        <div className="audiomackConnectv2Component" style={{ width: '100%' }}>
            <ModalPlatformInputContainer>
                <PlatformLogoContainer
                    width="48px"
                    height="48px"
                >
                    <AudiomackIcon width="48px" height="48px" />
                </PlatformLogoContainer>
                <form style={{
                    width: '100%'
                }}
                    onSubmit={(e) => {
                        e.preventDefault()
                        pullResults(textFieldValue)
                    }}>
                    <TextField
                        InputProps={{
                            classes: {
                                root: classes.inputBorder,
                            },
                            endAdornment: renderEndAdornment(),
                        }}
                        value={textFieldValue}
                        onChange={handleChangeTextField}
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        placeholder={mainPlaceholder}
                        helperText={helperText}
                        error={error}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.formHelperText,
                            },
                        }}
                    />
                </form>
            </ModalPlatformInputContainer>
            {textFieldValue && !loading && (
                <SearchListContainer height={containerHeight}>
                    <Grid container direction="column">
                        {!loading && results.map((profile: AudiomackArtistProfile, index) => {
                            const {
                                id,
                                name,
                                thumbnail_url,
                                followers,
                                url_slug
                            } = profile
                            return (
                                <Grid
                                    item
                                    key={id}
                                    className={clsx(
                                        classes.channelItemRow,
                                        index === 0 && classes.dividerBottom,
                                        index !== 0 && index !== 1 && classes.dividerTop
                                    )}
                                >
                                    <Grid
                                        className="cursor-pointer"
                                        onClick={handleOnClick(profile)}
                                        spacing={showConnectButton && mobileView ? 2 : 3}
                                        container
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                    >
                                        <Grid item className={classes.channelImage}>
                                            <ChannelImage
                                                widthStyle={mobileView ? "48px" : "56px"}
                                                heightStyle={mobileView ? "48px" : "56px"}
                                                alt={`${name}-url-image`}
                                                src={thumbnail_url}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={clsx(showConnectButton && mobileView && "w-24")}
                                            xs={showConnectButton && !mobileView || !showConnectButton}
                                        >
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <StyledLabel fontWeight={500} fontSize={16}>
                                                        {name}
                                                    </StyledLabel>
                                                </Grid>
                                                <Grid item>
                                                    <StyledLabel
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        color={Colors.greyDark}
                                                    >
                                                        {followers.toLocaleString('en')} {pluralize('Follower', followers)}
                                                    </StyledLabel>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {showConnectButton && <div style={{ color: Colors.purple, marginRight: 16 }}>Connect</div>}
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </SearchListContainer>
            )}
        </div>
    )
}

export default AudiomackConnectv2