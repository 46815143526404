import { useEffect, useState } from "react";
import { StyledLabel, Container } from 'styles/shared';
import { MenuItem, Select, TextField, Checkbox, ListItemText, InputLabel, FormControl, FormControlLabel } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import LoaderV2 from "components/Loader/LoaderV2";
import dayjs from "dayjs";
import Axios from "helpers/Interceptor";
import { DataGridPro } from '@mui/x-data-grid-pro';
import useStyles from './styles';
import { CustomToolBarSnapshot, SnapshotsCols } from "./components/SnapshotCols";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import SwitchSymphony from "components/shareable/SwitchSymphony";

interface SourceItemStat {
    columns: ColItem[];
    rows: RowItem[];
    sources: string[];
    campaignTypes: string[];
}

interface ColItem {
    name: string;
    selector: string;
}

interface RowItem {
    id: string;
    path: string[];
    [key: string]: number | string | string[];
}

interface SnapshotsFilters {
    startDate: string;
    endDate: string;
    source: string[];
    campaignType: string[];
    isTotal: boolean;
    minSpend: number | null;
    maxSpend: number | null;
    totalCampaigns: number | null;
}

export const SnapshotsTable = () => {
    const [loading, setLoading] = useState(true);
    const [availableSources, setAvailableSources] = useState<string[]>([]);
    const [availableTypes, setAvailableTypes] = useState<string[]>([]);
    const [rows, setRows] = useState<RowItem[]>([]);
    const [filters, setFilters] = useState<SnapshotsFilters>({
        startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        source: [],
        campaignType: [],
        isTotal: false,
        minSpend: null,
        maxSpend: null,
        totalCampaigns: null,
    });
    const classes = useStyles();

    const fetchData = async () => {
        setLoading(true);
        try {
            const uri = '/admin/snapshot';
            const response = await Axios.get(uri, {
                params: {
                    startDate: filters.startDate, endDate: filters.endDate,
                    sources: filters.source.join(','), campaignType: filters.campaignType.join(','), isTotal: filters.isTotal ? "true" : "false",
                    minSpend: filters.minSpend, maxSpend: filters.maxSpend, totalCampaigns: filters.totalCampaigns
                }
            });
            const data: SourceItemStat = response.data.data;
            setRows(data.rows);
            setAvailableSources(data.sources ?? []);
            setAvailableTypes(data.campaignTypes ?? []);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (rows.length === 0) fetchData();
    }, [])

    const handleFilterSources = () => {
        fetchData();
    };

    const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilters({ ...filters, source: event.target.value as string[] });
    };

    const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilters({ ...filters, campaignType: event.target.value as string[] });
    };

    return (
        <Container flexDirection="column" alignItems="center" justifyContent="center" padding="20px" gap="20px">
            {loading ?
                <LoaderV2 />
                :
                <>
                    <Container flexDirection="column" backgroundColor="#fff" padding="20px" borderRadius="8px" display='flex'>
                        <StyledLabel fontSize={20} padding="0px auto 20px">Table Snapshots</StyledLabel>
                        <Container flexDirection="row" gap="12px" alignItems="center" flexWrap="wrap" display='flex'>
                            <TextField
                                classes={{ root: classes.minWidthTextField }}
                                label="Start Date"
                                type="date"
                                value={filters.startDate}
                                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                classes={{ root: classes.minWidthTextField }}
                                label="End Date"
                                type="date"
                                value={filters.endDate}
                                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                            <FormControl classes={{ root: classes.maxWidthSelect }} fullWidth>
                                <InputLabel id="campaign-type">Sources</InputLabel>
                                <Select
                                    labelId="Sources"
                                    multiple
                                    value={filters.source}
                                    onChange={handleSourceChange}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                    IconComponent={ArrowDropDown}
                                >
                                    <MenuItem value="" disabled>All Sources</MenuItem>
                                    {availableSources.map((src, index) => (
                                        <MenuItem key={index} value={src}>
                                            <Checkbox checked={filters.source.indexOf(src) > -1} />
                                            <ListItemText primary={src} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl classes={{ root: classes.maxWidthSelect }} fullWidth>
                                <InputLabel id="campaign-type">Campaign Type</InputLabel>
                                <Select
                                    label="Campaign Type"
                                    multiple
                                    placeholder="All Campaign Types"
                                    value={filters.campaignType}
                                    onChange={handleTypeChange}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                    IconComponent={ArrowDropDown}
                                >
                                    <MenuItem value="" disabled>All Types</MenuItem>
                                    {availableTypes.map((src, index) => (
                                        <MenuItem key={index} value={src}>
                                            <Checkbox checked={filters.campaignType.indexOf(src) > -1} />
                                            <ListItemText primary={src} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={
                                    <SwitchSymphony
                                        className="snapshotsTableSwitch"
                                        checked={filters.isTotal}
                                        onChange={() => setFilters({ ...filters, isTotal: !filters.isTotal })}
                                    />
                                }
                                label="Total"
                            />
                            <ButtonSymphony
                                className="snapshotsTableFilterButton"
                                isLoading={loading}
                                onClick={handleFilterSources}
                            >
                                Filter
                            </ButtonSymphony>
                        </Container>
                        <Container flexDirection="row" gap="12px" padding='10px' marginTop='20px' alignItems="center" display='flex'>
                            <TextField
                                classes={{ root: classes.minWidthTextField }}
                                label="Min Spend"
                                type="number"
                                value={filters.minSpend}
                                onChange={(e) => setFilters({ ...filters, minSpend: parseInt(e.target.value) })}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                classes={{ root: classes.minWidthTextField }}
                                label="Max Spend"
                                type="number"
                                value={filters.maxSpend}
                                onChange={(e) => setFilters({ ...filters, maxSpend: parseInt(e.target.value) })}
                                InputLabelProps={{ shrink: true }}
                            />
                            {filters.isTotal && <TextField
                                classes={{ root: classes.minWidthTextField }}
                                label="Total Campaigns"
                                type="number"
                                value={filters.totalCampaigns}
                                onChange={(e) => setFilters({ ...filters, totalCampaigns: parseInt(e.target.value) })}
                                InputLabelProps={{ shrink: true }}
                            />}
                        </Container>

                        {rows && <Container flexDirection="row" gap="12px" padding='10px' marginTop='20px' alignItems="center" display='flex'>
                            <DataGridPro
                                rows={rows}
                                getRowId={(row) => filters.isTotal ? row.brandId : row.id}
                                rowCount={rows.length}
                                columns={SnapshotsCols}
                                rowHeight={58}
                                autoHeight
                                pageSizeOptions={[10, 25, 50, 100]}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 10, page: 0 },
                                    },
                                    columns: {
                                        columnVisibilityModel: {
                                            userId: false,
                                            brandId: false,
                                        }
                                    }
                                }}
                                autoPageSize
                                slots={{
                                    toolbar: CustomToolBarSnapshot,
                                }}
                                classes={{ root: classes.datagridRoot }}
                            />
                        </Container>}
                    </Container>
                </>
            }
        </Container>
    );
}