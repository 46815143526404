import UpIndicatorIcon from "assets/images/up-green-arrow.svg";
import DownIndicatorIcon from "assets/images/down-gray-arrow.svg";
import InstagramIcon from "assets/images/insta.svg";

export interface Props {
  alt: string;
  icon: string;
  mainLabel?: string;
  secondaryLabel?: string | undefined;
  percent?: number;
  value?: number;
  todayValue?: number;
  showGrowthCalculation: boolean;
  unit?: string;
  roundPercent?: boolean;
  show?: boolean;

}

interface PercentIndicatorIconProps {
  value: number;
  className?: string;
}

interface InstagramConnectorIndicatorProps {
  children: JSX.Element;
  handleOpenModal: () => void;
}


enum MathSymbols {
  PLUS = "+",
  MINUS = "-",
}

const getMathSymbol = (value: number) =>
  value > 0 ? MathSymbols.PLUS : value < 0 ? MathSymbols.MINUS : "";

export const getSecondaryLabel = (value: number, unit: string) => {
  const mathSymbol = getMathSymbol(value);
  if (value >= 0) {
    return `${mathSymbol}${Math.abs(value).toLocaleString()} new ${unit}`;

  } else {
    return `${mathSymbol}${Math.abs(value).toLocaleString()} ${unit}`;

  }
};

export const getPercentLabelColor = (value: number) =>
  value > 0.5 ? "green" : "gray";

const InstagramConnector = ({ children, handleOpenModal }: InstagramConnectorIndicatorProps) => {
  return (
    <>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-2 row-start-auto">
          <img
            className="w-10 h-10"
            alt="instagram_icon.svg"
            src={InstagramIcon}
          />
        </div>
        <div className="col-span-7 row-start-auto">
          <h5 className="text-dark">
            Connect <b>Instagram</b> to automatically track your follower growth
          </h5>
        </div>
        <div className="col-span-2 row-start-auto">
          <div
            className={"text-green cursor-pointer"}
            onClick={handleOpenModal}
          >
            Connect
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

export const PercentIndicatorIcon = ({ value, className }: PercentIndicatorIconProps) => {
  const data = {
    src: value > 0 ? UpIndicatorIcon : DownIndicatorIcon,
    alt: value > 0 ? "up-green-arrow-icon.svg" : "down-gray-icon.svg",
  };
  const { src, alt } = data;

  return (
    <img {...{ src, alt, className }} />
  );
};

interface DefaultIndicatorProps {
  icon: string;
  alt: string;
  mainLabel: string;
  value?: number;
  percent?: number;
  showPercentIndicatorIcon: boolean;
  percentLabelColor: string;
  secondaryLabel?: string;
  roundPercent?: boolean;
}

export const Indicator = ({
  icon,
  alt,
  mainLabel,
  showPercentIndicatorIcon,
  secondaryLabel,
  value,
  percent,
  percentLabelColor,
  roundPercent,
}: DefaultIndicatorProps) => {

  let showPercent = typeof percent !== "undefined" && percent !== null && !isNaN(percent);
  let showValue = typeof value !== "undefined" && value !== null && !isNaN(value);

  return (
    <div className="grid grid-cols-12 items-center">
      <div className="col-span-2">
        <img {...{ alt }} className="w-10 h-10" src={icon} />
      </div>
      <div className="pl-2 pr-5 col-span-10">

        {mainLabel && (
          <div><p className="flex text-dark font-semibold items-center">
            <span>{mainLabel}</span>
            {showPercent && showValue && (<span className="flex items-center gap-x-0.5">
              {showPercentIndicatorIcon && (
                <span className="pl-1 w-4 h-2">
                  <PercentIndicatorIcon value={value || 0} />
                </span>
              )}
              <span
                className={`text-${percentLabelColor}-500 ${!showPercentIndicatorIcon && "pl-1"
                  }`}
              >
                {roundPercent ? Math.round(percent || 0) : percent}%
              </span>
            </span>)}
          </p>
          </div>
        )}

        {secondaryLabel && (
          <div className="text-gray-500 font-medium">
            <p>{secondaryLabel}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const BrandConnectorIndicator = ({
  icon,
  alt,
  mainLabel,
  secondaryLabel = "",
  percent,
  value,
  unit,
  roundPercent,
  showGrowthCalculation
}: Props) => {
  let percentLabelColor = "";
  let secondary = secondaryLabel ? secondaryLabel : "";
  let showPercentIndicatorIcon = false;

  if (typeof value !== "undefined" && value !== null && unit) {
    percentLabelColor = getPercentLabelColor(value);
    secondary = showGrowthCalculation ? getSecondaryLabel(value, unit) : 'This value will go up or down over time.';
    showPercentIndicatorIcon = percent !== 0;
  }

  return (
    <Indicator
      {...{
        icon,
        alt,
        mainLabel: mainLabel || "",
        showPercentIndicatorIcon,
        secondaryLabel: secondary,
        value,
        percent,
        percentLabelColor,
        roundPercent,
      }}
    />
  );
};
export default BrandConnectorIndicator;
