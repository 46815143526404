import { ReactComponent as TadaIcon } from "assets/images/tada.svg"
import LinkContainer from "components/shareable/LinkContainer";
import MainConfetti from "components/shareable/Confetti";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import Colors from "modules/Colors";
import ModalSymphony from "components/shareable/ModalSymphony";
import { CSSProperties } from "react";

interface Props {
  isOpen: boolean
  websiteUrl: string
  onClose: () => void
}

const PublishedWebsiteModal = ({
  isOpen,
  websiteUrl,
  onClose,
}: Props) => {
  const handleGoToDashboard = () => {
    onClose()
  }

  return (
    <ModalSymphony
      isOpen={isOpen}
      onClose={onClose}
      title="Your website has been published!"
      subtitle="You can come back and view stats in realtime from the Dashboard, and edit styling at any time. Here’s your website link, share it with the world:"
      headerIcon={<TadaIcon />}
    >
      <div className="publishedWebsiteModalContent" style={sty.publishedWebsiteModalContent}>
        <LinkContainer
          url={websiteUrl}
          showShareButton
          showEditButton={false}
          width="100%"
          padding="8px 16px"
          buttonTextColor={Colors.purpleLight}
          backgroundColor={Colors.DARK_DIVIDER_LINE}
          textColor={Colors.textColor}
        />
        <ButtonSymphony
          className="publishedWebsiteModalExploreDashboardButton"
          onClick={handleGoToDashboard}
        >
          Explore the Dashboard
        </ButtonSymphony>
      </div>
      <MainConfetti
        numberOfPieces={600}
      />
    </ModalSymphony>
  );
}

const sty: Record<string, CSSProperties> = {
  publishedWebsiteModalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
}

export default PublishedWebsiteModal