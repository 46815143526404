import React from 'react';
import { StatCardDataPoint } from './utils';
import { Card, CardContainer, CardTitle, Icon, IconContainer, ValueText } from './styles';
import getSymbolFromCurrency from "currency-symbol-map";
import useScreen from 'Hooks/useScreen';

interface StatsCardProps {
    stat: StatCardDataPoint;
    className?: string; // Optional prop for additional styling
    hideCurrencyLabel?: boolean
}

const getFormattedMoney = (amount?: number, currency?: string, hideCurrencyLabel?: boolean): string => {
    const currencySymbol = getSymbolFromCurrency(currency?.toUpperCase() || 'USD');
    return `${currencySymbol}${amount?.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}${hideCurrencyLabel ? '' : ` ${currency?.toUpperCase() || 'USD'}`}`;
};

const StatsCard: React.FC<StatsCardProps> = ({ stat, className, hideCurrencyLabel }) => {
    const { title, value, isCurrency, currency, icon } = stat;
    const formattedDescription = isCurrency && typeof value === 'number'
        ? getFormattedMoney(value, currency, hideCurrencyLabel)
        : (typeof value === 'number' ? value : parseInt(value) || 0).toLocaleString();

    const { mobileView } = useScreen();

    return (
        <CardContainer
            width={mobileView ? "calc(50% - 8px)" : "calc(25% - 8px)"}
        >
            <Card
                flexDirection={mobileView ? "row" : "column"}
                alignItems={mobileView ? "center" : "flex-start"}
            >
                {icon && (<IconContainer>
                    <Icon
                        src={icon}
                    />
                </IconContainer>)}
                <div>
                    <CardTitle>
                        {title}{isCurrency && ` (${currency})`}
                    </CardTitle>
                    <ValueText>

                        {formattedDescription}
                        {/* {getFormattedMoney(value)} */}

                    </ValueText>
                </div>
            </Card>
        </CardContainer>
    );
};

export default StatsCard;