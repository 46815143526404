import { TextButton } from "styles/shared";
import { Body2, ButtonText } from "components/shareable/Typography";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg";
import styled from "styled-components";
interface Props {
  footerText?: string;
  footerButton?: string;
  footerButtonOnClick?: () => void;
}

const FooterModal = ({
  footerText,
  footerButton,
  footerButtonOnClick,
}: Props) => {
  return (
    <FooterContainer>
      {footerText && <Body2>{footerText}</Body2>}
      {footerButton && footerButtonOnClick && (
        <div className="mx-w-5">
          <TextButton>
            <div
              className="flex items-center"
              onClick={footerButtonOnClick}
            >
              <ButtonText>{footerButton}</ButtonText>
              <ExternalLinkIcon />
            </div>
          </TextButton>
        </div>
      )}
    </FooterContainer>
  );
};

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
`;

export default FooterModal;