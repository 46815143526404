// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import ListComponent from "components/marketing/HelperPopup/list-section";
import BadgeContainer from "../Components/BadgeCampaignTypeContainer/BadgeContainer";
import PLATFORMS_BADGE_ICONS from "../Components/BadgeCampaignTypeContainer/BADGE_ICONS";
import ContactUsHelpPopupSection from "components/marketing/HelperPopup/contact-us";
import { SongAdsCampaignSubtype } from ".";


export default function SongAdsHelperPopup(props: {
    subtype: SongAdsCampaignSubtype
}) {
    const {
        subtype
    } = props

    if (subtype === "grow_playlist_followers") {
        return (
            <>
                <div className=" mb-6">
                    <p className="text-lg ">The "Grow Playlist Followers" playbook is ideal for growing your playlist through highly-targeted Instagram Ads. </p>
                    <div className="flex mt-3 text-sm gap-3">
                        <BadgeContainer
                            imageUrls={[
                                PLATFORMS_BADGE_ICONS["instagram"]
                            ]}
                            text="Runs Instagram Ads" />
                        <BadgeContainer
                            imageUrls={[
                                PLATFORMS_BADGE_ICONS["spotify"]
                            ]}
                            text="Grow Playlist Followers & Engagement" />
                    </div>
                </div>
                <ListComponent
                    title="When to use it:"
                    bulletPoints={[
                        "You want to grow your playlist engagement, saves, and followers by finding new, highly-targeted fans that may stream and follow.",
                        "You want to grow your followers on Instagram or increase your reach on Instagram."
                    ]}
                />
                <ListComponent
                    title="The Symphony Advantage:"
                    bulletPoints={[
                        "Symphony automatically targets the best audiences for your ads to drive new followers. Our AI automatically creates audiences using data from your current fanbase, so ads are targeted to new fans that already have an affinity towards your music.",
                        "Our AI looks at your Spotify data to optimize ads better - we're not trying to just drive clicks, Symphony aims to optimize to drive more followers.",
                        "Compared to boosting your post on Instagram or setting up ads yourself, Symphony's Instagram ads tend to have a 2x cheaper cost-per-click."
                    ]}
                />
                <ListComponent
                    title="What you need to get started:"
                    bulletPoints={[
                        "Your playlist should be public on Spotify.",
                        "You should have access to your Facebook + Instagram pages.",
                        "You should have creative ad assets ready, ideally a story-sized video for Instagram Story + Instagram Reels ads and a square or 4x5 video for Instagram Feed ads."
                    ]}
                />
                <ListComponent
                    title="The Dashboard:"
                    bulletPoints={[
                        "Track total growth in Spotify followers and Instagram followers.",
                        "Track ads data, including cost-per-click, total amount spent, comments, and shares on your ad.",
                        "Track countries & demographics that are clicking on your ad the most."
                    ]}
                    swiperImages={[
                        require("../../../../assets/images/onboarding/data-examples/spotify-streams-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/growth-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/spotify-followers-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/demographics-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/countries-example.png").default
                    ]}
                />
                <ContactUsHelpPopupSection />
            </>
        )
    } else {
        return (
            <>
                <div className=" mb-6">
                    <p className="text-lg ">The "Increase Music Streams" tool is the perfect way to promote your music through highly-targeted Instagram Ads. </p>
                    <div className="flex mt-3 text-sm gap-3">
                        <BadgeContainer
                            imageUrls={[
                                PLATFORMS_BADGE_ICONS["instagram"]
                            ]}
                            text="Runs Instagram Ads" />
                        <BadgeContainer
                            imageUrls={[
                                PLATFORMS_BADGE_ICONS["spotify"]
                            ]}
                            text="Grow Streams & Followers" />
                    </div>
                </div>
                <ListComponent
                    title="When to use it:"
                    bulletPoints={[
                        "You want to grow your streaming fanbase by finding new, highly-targeted fans that may stream and follow.",
                        "You want to grow your followers on Instagram or increase your reach on Instagram."
                    ]}
                />
                <ListComponent
                    title="The Symphony Advantage:"
                    bulletPoints={[
                        "Symphony automatically targets the best audiences for your ads to drive new streams. Our AI automatically creates audiences using data from your current fanbase, so ads are targeted to new fans that already have an affinity towards your music.",
                        "Our AI looks at your Spotify data to optimize ads better - we're not trying to just drive clicks, Symphony aims to optimize to drive more streams.",
                        "Compared to boosting your post on Instagram or setting up ads yourself, Symphony's Instagram ads tend to have a 2x cheaper cost-per-click."
                    ]}
                />
                <ListComponent
                    title="What you need to get started:"
                    bulletPoints={[
                        "Your song should be out on Spotify.",
                        "You should have access to your Facebook + Instagram pages.",
                        "You should have creative ad assets ready, ideally a story-sized video for Instagram Story + Instagram Reels ads and a square or 4x5 video for Instagram Feed ads."
                    ]}
                />
                <ListComponent
                    title="The Dashboard:"
                    bulletPoints={[
                        "Track total growth in Spotify followers, streams, Instagram followers, and Shazams.",
                        "Track ads data, including cost-per-click, total amount spent, comments, and shares on your ad.",
                        "Track countries & demographics that are clicking on your ad the most."
                    ]}
                    swiperImages={[
                        require("../../../../assets/images/onboarding/data-examples/spotify-streams-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/growth-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/spotify-followers-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/shazams-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/demographics-example.png").default,
                        require("../../../../assets/images/onboarding/data-examples/countries-example.png").default
                    ]}
                />
                <ContactUsHelpPopupSection />
            </>
        )
    }
}