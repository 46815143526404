import { NEXT, REVIEW, REVIEW_YOUR_CAMPAIGN } from "modules/Const"
import COUNTRY_CODES from "helpers/Countries"

export enum STEPS {
  RECORD = "record",
  TARGETING = "targeting",
  BUDGET = "budget",
  FINALIZATION = "finalization",
}

const { BUDGET } = STEPS

export const getNextButtonLabel = (
  isNextButtonMobile: boolean,
  selectedTab?: STEPS | null
) => {
  if (selectedTab === BUDGET && isNextButtonMobile) return REVIEW
  if (selectedTab === BUDGET && !isNextButtonMobile) return REVIEW_YOUR_CAMPAIGN
  return NEXT
}

export const getNextButtonWidth = (args: {
  selectedTab?: STEPS | null
  isNextButtonResponsive: boolean
  isNextButtonMobile: boolean
}) => {
  const { selectedTab, isNextButtonMobile, isNextButtonResponsive } = args

  if (selectedTab === BUDGET && isNextButtonMobile) return undefined
  if (selectedTab === BUDGET && !isNextButtonMobile && isNextButtonResponsive) return "220px"
  if (selectedTab === BUDGET) return "300px"
  return undefined
}

export const formatCountries = (
  geographyType: string,
  selectedCountries: { label: string; value: string }[]
) => {
  if (geographyType === "custom" && selectedCountries) {
    return selectedCountries.map(({ label }) => label);
  }

  return null;
};

export const getSelectedCountries = (countries: string[]) => {
  if (countries.length) {
    let saved: { code: string; name: string; population: number | null }[] = []

    for (const countryName of countries) {
      const found = COUNTRY_CODES.find(({ name }) => name === countryName)
      if (found) saved = [...saved, found]
    }

    return saved.map(({ name, code }) => ({
      value: code,
      label: name,
    }))
  }

  return []
}

export const convertArrayDraftDataToSendAsObject = (data: Record<string, unknown>[]) => {
  const [
    recordSelected,
    budgetValue,
    startDate,
    endDate,
    selectedAudiences,
    campaignType,
    geographyType,
    selectedTab,
    selectedCountries
  ] = data

  return {
    recordSelected,
    budgetValue,
    startDate,
    endDate,
    selectedAudiences,
    campaignType,
    geographyType,
    selectedTab,
    selectedCountries
  }
}

export interface TargetingBlock {

  channelId: string;
  channelTitle: string;
  id: string;
  thumbnails: {
    default: {
      url: string;
    }
    high?: {
      url: string;
    }
    medium?: {
      url: string;
    }
  }

}

export interface PlacementsBlock {

  channelId?: string;
  id?: string;
  thumbnails?: string;
  data: {
    avg_cpv: number;
    sum_cost: number;
    sum_views: number;
  }
  description: string;
  placement_type: string;
  title: string;
  channelTitle: string;
  youtubeUrl: string;

}

export const videoViewRejectionIdToText = (cancelledReason: any) => {
  let additionalContext: string | null = null;

  if (!cancelledReason) return

  switch (cancelledReason) {

    case 'bad video':
      additionalContext = 'Your campaign was cancelled because the targeting was too narrow. Please try again with a broader audience.';
      break;
    case 'name of video / punctuation (resubmittable)':
      additionalContext = "Your campaign wasn't able to run because of punctuation in the video title. Please remove any punctuation (!,.?|%) in your video title and resubmit your campaign.";
      break;
    case 'explicit content':
      additionalContext = "Your campaign wasn't able to run because YouTube marked your video as 'explicit content'. Please try submitting another video.";
      break;
    case 'super limited':
      additionalContext = 'Your campaign was cancelled because the targeting was too narrow. Please try again with a broader audience.';
      break;
    case 'Video Missing':
      additionalContext = 'Your campaign was uploaded without a video link that works, and or has been taken down on the account. Please reupload the video and refrain from taking it down while the ad is running.';
      break;
    case 'Age Restricted Video':
      additionalContext = 'Your campaign was uploaded with a video that is age-restricted. Please make your video available to all ages and try submitting again..';
      break;
    case 'Improper Content':
      additionalContext = 'Your campaign was cancelled because improper content was shown in it. Please understand that YouTube has content guidelines out of our control. Please try submitting a different video.';
      break;
    case 'Trademark Violation':
      additionalContext = "Your campaign was cancelled because there was a trademark found in the text of the title or the video itself. To resubmit, please take out any trademark wording from the title or video.";
      break;
    case 'Shocking Content':
      additionalContext = "Your campaign was canceled due to YouTube's Shocking Content policy. This could mean that your video contained violent or obscene language, graphic images, and or dangerous situations."
      break
    case 'Automated Content Policy Decision':
      additionalContext = "Your campaign was canceled due to YouTube's automated content policy decision. This could be due to assets that do not meet YouTube ads quality requirements, or that the video has adult content that contains nudity or sexual topics, including other various reasons."
      break
    default:
      additionalContext = null;
  }

  return additionalContext;
};
