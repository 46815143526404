import { useContext, useState, CSSProperties, useEffect } from "react";
import { useHistory } from "react-router";
import { StyledLabel } from "styles/shared";
import { CurrentBrand } from "types/global";
import GemIcon from "./shareable/ProCallouts/GemIcon";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";
import Colors from "modules/Colors";
import Sty from "modules/Sty";
import ButtonSymphony from "./shareable/ButtonSymphony";
import ProfileIcon from "assets/images/profileIcon.svg";
import { styx, clsx } from "modules/Utils";
import CustomAvatar from "./ProfileDropdown/Components/CustomAvatar";

interface MobileHeaderProps {
    className?: string;
    loadedBrands: CurrentBrand[],
}
const MobileHeader = ({
    className = '',
    loadedBrands
}: MobileHeaderProps) => {
    const { mobileView } = useScreen();
    const { isProBrand, currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const renderLoadedBrands = () => {
        return (
            loadedBrands.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((b: any, idx: number) => {
                return (
                    <div
                        key={`${b.name}-${idx}`}
                        className="mobileHeaderMenuItem"
                        style={sty.mobileHeaderMenuItem}
                        onClick={() => {
                            setCurrentBrand(b)
                            setIsOpen(false)
                        }}
                    >
                        {/* <img
                            className="artistImgMobileHeader"
                            src={b.image ? b.image : ProfileIcon}
                            style={{ width: 40, height: 40, borderRadius: 8 }}
                            alt={b.name}
                        /> */}
                        <CustomAvatar
                            avatar={b.image ? b.image : ProfileIcon}
                            isDiv
                        />
                        <div className="brandNameMobileHeader">
                            {b.name}
                        </div>
                        <div
                            className="brandTierMobileHeader"
                            style={{
                                color: b.tier === 'pro' ? '#F5BA23' : b.tier === 'lite' ? '#8800FF' : '#808080',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                                marginLeft: 'auto',
                                fontSize: 14,
                            }}
                        >
                            {b.tier === 'pro' && <GemIcon />}
                            {b.tier === 'pro' ? 'PRO' : b.tier === 'lite' ? 'LITE' : ''}
                        </div>
                    </div>
                )
            })
        )
    }

    const renderAddNewArtist = () => {
        return (
            <div
                key="add artist"
                className="mobileHeaderMenuItemBottom"
                style={styx(sty.mobileHeaderMenuItem, { height: 57 })}
                onClick={() => history.push('/add-artist')}
            >
                <ButtonSymphony
                    variant="text"
                >
                    + Add New Artist
                </ButtonSymphony>
            </div>
        )
    }

    if (!mobileView) {
        return null;
    }

    return (
        <div
            className={clsx('mobileHeaderComponent', className)}
            style={sty.mobileHeaderComponent}
        >
            <div
                className="mobileHeaderDropdown"
                style={sty.mobileHeaderDropdown}
                onClick={() => setIsOpen(!isOpen)}
            >
                <img
                    src={currentBrand?.image ? currentBrand.image : ProfileIcon}
                    style={sty.artistImg}
                    alt="Artist"
                />
                <div className="brandNameProContainer">
                    <div className="brandName" style={{ color: 'white' }}>
                        {currentBrand ? currentBrand.name : ''}
                    </div>

                    {isProBrand &&
                        <div
                            className="proAccountContainer"
                            style={sty.proContainer}
                        >
                            <GemIcon />
                            <StyledLabel {...{ color: "#F5BA23", fontSize: 10 }}>PRO ACCOUNT</StyledLabel>
                        </div>
                    }
                </div>
                <img
                    style={sty.rightArrow}
                    src={require("../assets/images/right-arrow-right.svg").default}
                    alt="Right Arrow"
                />
            </div>
            {/* UPGRADE TO PRO TEST: TODO finalize */}
            {/* <SubscriptionStatusIndicator
        text="Upgrade to Pro"
        showStars
        handleOpenModal={() => handleOpenModal({
          source: "Desktop Navigation - Upgrade to Pro Button"
        })}
      /> */}

            {isOpen &&
                <div
                    className="mobileHeaderMenuOverlay"
                    style={sty.mobileHeaderMenuOverlay}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                    }}
                >
                    <div
                        className="mobileHeaderMenu"
                        style={sty.mobileHeaderMenu}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {renderAddNewArtist()}
                        {loadedBrands && renderLoadedBrands()}
                    </div>
                </div>
            }
        </div>
    );
};

const sty: Record<string, CSSProperties> = {
    mobileHeaderComponent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 88,
        backgroundColor: Colors.purpleDark,
        flexShrink: 0,
        paddingLeft: 16,
        paddingRight: 16,
        position: 'relative',
    },
    mobileHeaderDropdown: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: 8,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        padding: 8,
        gap: 8
    },
    artistImg: {
        width: 40,
        height: 40,
        borderRadius: 8,
    },
    rightArrow: {
        marginLeft: 'auto',
        marginRight: 8
    },
    proContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 4
    },
    mobileHeaderMenuOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        zIndex: 1000,
        paddingTop: 88
    },
    mobileHeaderMenu: {
        position: 'absolute',
        top: 88,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        width: '100%',
        zIndex: 1000,
        maxHeight: 400,
        overflowY: 'auto',
        boxShadow: '0 8px 12px -2px rgba(0, 0, 0, 0.2), 0 4px 8px -2px rgba(0, 0, 0, 0.2)'
    },
    mobileHeaderMenuItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottom: Sty.border,
        cursor: 'pointer'
    }
}

export default MobileHeader;
