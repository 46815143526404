import {
    SetStateAction,
    useState,
    Dispatch,
    useContext,
} from "react";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import CircularStep from "../CircularStep";
import { OrganizationData } from "types/global";
import FB from "helpers/FB";
import { SNACKBAR_WRONG_TARGETING_MESSAGE, TargetedCountries, showFixTargetingSnackbar, showToast } from "modules/Utils";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { useStyles, Card } from "./styles";
import { track } from "analytics";
import { StyledLabel } from "styles/shared";
import SubmitCampaignOrUpgrade from "./SubmitCampaignOrUpgrade";
import { CampaignInput } from "./types";
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { ConnectionsContext } from "../../hooks/ConnectionsContext";
import { CheckoutType, PlanType } from "modules/Const";
import useScreen from "Hooks/useScreen";
import { redirectToOneOffCampaignCheckout, createCampaignAndUpgradePlanCheckout, changeSubscriptionPlan } from "services/symphonyApi/subscriptionService";
import * as Sentry from "@sentry/react";

interface Props {
    budget: number;
    primaryOrganization?: OrganizationData;
    creatingCampaign?: boolean;
    campaignInput: CampaignInput;
    allowCampaignCreation: Dispatch<SetStateAction<boolean>>;
    onCancelPayPalOrder?: () => void;
    onErrorPayPalOrder?: () => void;
    setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
    cancelSaving: () => void;
    validateCountriesToFix: () => boolean;
    submissionName?: string;
    submissionDescription?: string;
    checkout_source: string;
    usesSymphonyAccounts?: boolean;
}

const FacebookCampaignGoLiveCard = ({
    budget,
    primaryOrganization,
    creatingCampaign,
    campaignInput,
    allowCampaignCreation,
    setCreatingCampaign,
    cancelSaving,
    validateCountriesToFix,
    submissionName,
    submissionDescription,
    checkout_source,
    usesSymphonyAccounts = false
}: Props) => {
    const { mobileView } = useScreen()
    const {
        currentBrand,
        isProBrand,
        isLiteBrand,
        reloadBrand,
        proFreeTrialRedeemed
    } = useContext(CurrentBrandContext);
    const { loggedInFbUser } = useContext(ConnectionsContext);
    const { currency } = useContext(SpendBudgetContext)

    const [selectedBillingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.MONTHLY) // [BillingCycle.MONTHLY, BillingCycle.ANNUALLY

    // to show Currency for Pro campaigns

    const classes = useStyles();
    const [error, setError] = useState(false);

    const { targetedCountries } = campaignInput
    const targeted = targetedCountries as unknown as TargetedCountries[]

    const modifyCheckoutData = (checkoutType: CheckoutType, data: any) => {
        console.log('modifyCheckoutData', data)
        switch (checkoutType) {
            case CheckoutType.ONE_OFF_CAMPAIGN:
                const { access_token, campaign_input, checkout_source, uses_symphony_accounts } = data;
                let modifiedData = {
                    access_token,
                    campaign_input,
                    checkout_source,
                    uses_symphony_accounts
                };
                return modifiedData;
            case CheckoutType.UPGRADE_PLAN:
            default:
                return data;
        }
    }

    const handleClickCampaignWithStripe = async (checkoutType: CheckoutType) => {
        if (!validateCountriesToFix()) {
            return
        }

        const showSnackbar = showFixTargetingSnackbar(targeted)

        if (showSnackbar) {
            showToast({
                message: SNACKBAR_WRONG_TARGETING_MESSAGE,
                error: true,
                mobile: mobileView,
            })
            return
        }

        cancelSaving();
        if (error) setError(false);

        const localStorageItem = localStorage.getItem("facebook_page") || "{}";
        const facebookPage = JSON.parse(localStorageItem);
        let accessToken: string | undefined = undefined
        if (!usesSymphonyAccounts) {
            accessToken = loggedInFbUser?.access_token
            if (!accessToken) {
                const authResp = FB().getAuthResponse();
                accessToken = authResp.accessToken
            }
        }

        const data = {
            page_access_token: facebookPage?.access_token,
            access_token: accessToken,
            campaign_input: {
                price_data: {
                    price: budget,
                    name: submissionName,
                    description: submissionDescription,
                },
                payment_method: "stripe",
                ...campaignInput,
            },
            uses_symphony_accounts: usesSymphonyAccounts || false,
            checkout_source: checkout_source,
            recurring_interval: selectedBillingCycle
        };

        console.log('data', data)

        const finalDataForApi = modifyCheckoutData(checkoutType, data)

        setCreatingCampaign(true);

        let errorObject: any;

        switch (checkoutType) {
            case CheckoutType.ONE_OFF_CAMPAIGN:

                track("Completed Campaign Step - Checkout", {
                    type: campaignInput?.campaign_type,
                    step: 4,
                    name: "confirmation",
                    brand_id: currentBrand?.id,
                    brand_name: currentBrand?.name,
                    brand_slug: currentBrand?.slug,
                    payment_method: "stripe",
                    conversionsEnabled: Boolean(campaignInput?.conversionsEnabled),
                    conversionsSetup: Boolean(currentBrand?.conversionsStatus?.conversions_tasks_completed),
                });
                errorObject = await redirectToOneOffCampaignCheckout(currentBrand!, finalDataForApi);
                break;
            case CheckoutType.UPGRADE_PLAN:
                // - if the user is free, they can call both and will have to go to the checkout page
                // - if the user is lite, their plan should automatically be upgraded and they should
                // be redirected to submitting campaign page 
                if (isLiteBrand) {
                    // first call the upgrade plan api
                    // then submit the campaign the traditional way 
                    try {
                        // First upgrade the plan from Lite to Pro
                        await changeSubscriptionPlan({
                            brandSlug: currentBrand!.slug,
                            recurring_interval: selectedBillingCycle,
                            checkout_source: checkout_source,
                            plan_type: PlanType.PRO
                        });

                        // Reload brand to get updated subscription status
                        await reloadBrand();

                        // Submit campaign through normal flow
                        handleClickSubmitButton();

                    } catch (error) {
                        Sentry.captureException(error);
                        console.error("Error upgrading plan:", error);
                        showToast({
                            message: 'Failed to upgrade plan. Please contact support.',
                            error: true,
                            mobile: mobileView,
                        });
                        setError(true);
                    }
                } else {
                    track("Completed Campaign Step - Checkout", {
                        type: campaignInput?.campaign_type,
                        step: 4,
                        name: "confirmation",
                        brand_id: currentBrand?.id,
                        brand_name: currentBrand?.name,
                        brand_slug: currentBrand?.slug,
                        payment_method: "stripe",
                        conversionsEnabled: Boolean(campaignInput?.conversionsEnabled),
                        conversionsSetup: Boolean(currentBrand?.conversionsStatus?.conversions_tasks_completed),
                    });

                    errorObject = await createCampaignAndUpgradePlanCheckout(currentBrand!, finalDataForApi);
                }
                break;
        }


        if (errorObject) {
            showToast({
                message: 'Something went wrong. Please contact support.',
                error: true,
                mobile: mobileView,
            });
            setError(true);
        }
    };

    const handleClickSubmitButton = () => {
        if (!validateCountriesToFix()) {
            return
        }

        const showSnackbar = showFixTargetingSnackbar(targeted)

        if (showSnackbar) {
            showToast({
                message: SNACKBAR_WRONG_TARGETING_MESSAGE,
                error: true,
                mobile: mobileView,
            })
            return
        }

        cancelSaving();
        allowCampaignCreation(true);
    };

    const allowsDirectPayment: boolean = Boolean(usesSymphonyAccounts);

    return (
        <Card className="goLiveCardComponent" borderRadius={mobileView ? undefined : "12px"}>
            <Grid container direction="column">
                <Grid item className={clsx(classes.space, classes.border)}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <CircularStep step="⚡" />
                        </Grid>
                        <StyledLabel fontSize={18} fontWeight={600}>
                            Let’s go live
                        </StyledLabel>
                    </Grid>
                </Grid>
                {allowsDirectPayment ?
                    <SubmitCampaignOrUpgrade
                        allowsDirectPayment={true}
                        currencyCode={currency}
                        selectedBillingCycle={selectedBillingCycle}
                        setBillingCycle={setBillingCycle}
                        error={error}
                        submissionButtonIsLoading={creatingCampaign ?? false}
                        handleClickCampaignWithStripe={handleClickCampaignWithStripe}
                    />
                :
                    <SubmitCampaignOrUpgrade
                        allowsDirectPayment={false}
                        currencyCode={currency}
                        selectedBillingCycle={selectedBillingCycle}
                        setBillingCycle={setBillingCycle}
                        error={error}
                        submissionButtonIsLoading={creatingCampaign ?? false}
                        handleClickCampaignWithStripe={handleClickCampaignWithStripe}
                        handleClickSubmitButton={handleClickSubmitButton}
                    />
                }
            </Grid>
        </Card>
    );
};

export default FacebookCampaignGoLiveCard;
