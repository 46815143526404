import { CSSProperties, ReactNode, useEffect } from 'react';
import useScreen from 'Hooks/useScreen';
import { ReactComponent as CloseIcon } from 'assets/images/close-black.svg';
import Colors from "modules/Colors";
import { clsx } from "modules/Utils";

interface ModalSymphonyProps {
    className?: string;
    style?: CSSProperties;
    children?: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    headerIcon?: ReactNode | string | SVGElement | null | undefined;
    fullScreen?: boolean;
    maxWidth?: number;
    titleStyle?: CSSProperties;
    subtitleStyle?: CSSProperties;
    size?: 'small' | 'medium' | 'large'; // 360 | 678 | 1055
    isIsolateModalContent?: boolean;
}

const ModalSymphony = ({
    className = '',
    style = {},
    children = null,
    isOpen = false,
    onClose = () => { },
    title = '',
    subtitle = '',
    headerIcon = null,
    fullScreen = false,
    maxWidth,
    titleStyle = {},
    subtitleStyle = {},
    size = 'medium',
    isIsolateModalContent = false,
}: ModalSymphonyProps) => {
    const { mStyle, cssCalc } = useScreen();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const renderCloseIcon = () => {
        return (
            <div
                className="closeIconContainer"
                style={mStyle(sty, 'closeIconContainer')}
                onClick={onClose}
            >
                <CloseIcon style={mStyle(sty, 'closeIconSvg')} />
            </div>
        )
    }

    const renderHeaderIcon = () => {
        if (!headerIcon) {
            return null;
        }

        if (headerIcon instanceof SVGElement) {
            return (
                <div
                    className="modalSymphonyHeaderIcon"
                    style={sty.modalSymphonyHeaderIcon}
                    dangerouslySetInnerHTML={{ __html: headerIcon.outerHTML }}
                />
            )
        }

        if (typeof headerIcon === 'string') {
            return (
                <img
                    className="modalSymphonyHeaderIcon"
                    style={sty.modalSymphonyHeaderIcon}
                    src={headerIcon} alt="modal header"
                />
            )
        }

        return (
            <div
                className="modalSymphonyHeaderIcon"
                style={sty.modalSymphonyHeaderIcon}
            >
                {headerIcon}
            </div>
        )
    }

    const renderTitles = () => {
        return (
            <>
                {title &&
                    <div
                        className="modalSymphonyTitle" style={{
                            ...sty.modalSymphonyTitle,
                            ...sty[`modalSymphonyTitle_${size}`],
                            ...titleStyle,
                        }}>
                        {title}
                    </div>
                }
                {subtitle &&
                    <div
                        className="modalSymphonySubtitle" style={{
                            ...sty.modalSymphonySubtitle,
                            ...sty[`modalSymphonySubtitle_${size}`],
                            ...(isIsolateModalContent ? { ...subtitleStyle, ...{ marginBottom: 0 } } : subtitleStyle),
                        }}>
                        {subtitle}
                    </div>
                }
            </>
        )
    }

    const renderModalHeader = () => {
        const headerContent = (
            <>
                {renderCloseIcon()}
                {renderHeaderIcon()}
                {renderTitles()}
            </>
        )

        if (isIsolateModalContent) {
            return (
                <div className="titleContainer" style={sty.titleContainer}>
                    {headerContent}
                </div>
            )
        }

        return headerContent;
    }

    const renderModalChildren = () => {
        if (isIsolateModalContent) {
            return (
                <div className="modalSymphonyChildren" style={style}>
                    {children}
                </div>
            )
        }

        return children;
    }

    if (!isOpen) {
        return null;
    }

    // NOTE: render()
    return (
        <div
            className={clsx('modalSymphonyComponent', className)}
            style={{
                ...mStyle(sty, 'modalSymphonyComponent'),
                ...{ height: cssCalc() },
                ...fullScreen ? sty.fullScreenComponentStyle : {},
                ...sty.fadeAnimation,
            }}
        >
            <div
                className="modalSymphonyContent"
                style={{
                    ...mStyle(sty, 'modalSymphonyContent'),
                    ...(maxWidth ? { maxWidth } : sty[size]),
                    ...(fullScreen ? sty.fullScreenContentStyle : {}),
                    ...(isIsolateModalContent ? sty.isolateModalContent : style),
                    ...sty.contentAnimation,
                }}
            >
                {renderModalHeader()}
                {renderModalChildren()}
            </div>
        </div>
    );
};

const sty: Record<string, CSSProperties> = {
    modalSymphonyComponent: {
        position: "fixed",
        display: "flex",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        padding: "32px 10px 32px 10px",
        zIndex: 999,
        width: '100%',
        overscrollBehavior: "none",
        left: 0,
        top: 0,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
    },
    modalSymphonyComponent_mobile: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
    },
    modalSymphonyContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        border: "none",
        width: "100%",
        borderRadius: 18,
        margin: "auto",
        backgroundColor: "white",
        outline: "none",
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        overflow: 'hidden',
    },
    modalSymphonyContent_mobile: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        height: 'auto',
        minHeight: 'auto',
    },
    modalSymphonyHeaderIcon: {
        alignSelf: "flex-start",
        width: 40,
        height: 40,
        marginBottom: 8,
    },
    modalSymphonyTitle: {
        alignSelf: "flex-start",
        fontSize: 24,
        fontWeight: 600,
        marginBottom: 8,
    },
    modalSymphonySubtitle: {
        alignSelf: "flex-start",
        fontSize: 20,
        color: Colors.greyDark,
        fontWeight: 400,
        marginBottom: 32,
        width: '100%',
    },
    modalSymphonyTitle_small: {
        fontSize: 20,
    },
    modalSymphonySubtitle_small: {
        fontSize: 16,
    },
    small: {
        maxWidth: 360,
        paddingLeft: 24,
        paddingRight: 24,
    },
    medium: {
        maxWidth: 678,
    },
    large: {
        maxWidth: 1055,
    },

    closeIconContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: 9999,
        cursor: "pointer",
        alignSelf: "flex-end",
        flexShrink: 0,
        position: "unset",
        marginTop: -4,
        marginRight: -12,
    },
    closeIconContainer_mobile: {
        marginTop: 0,
        marginRight: 0,
        width: 32,
        height: 32,
    },
    closeIconSvg: {
        width: 24,
        height: 24,
    },
    closeIconSvg_mobile: {
        width: 19,
        height: 19,
    },
    fullScreenComponentStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: 'white',
    },
    fullScreenContentStyle: {
        width: '100%',
        maxWidth: '100%',
        borderRadius: 0,
        height: 'auto',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        width: '100%',
    },
    titleContainer_mobile: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
    },
    isolateModalContent: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    // NOTE: you can find this in App.css; search: modalFadeIn
    fadeAnimation: {
        animation: 'modalFadeIn 200ms ease-out forwards',
    },
    contentAnimation: {
        animation: 'modalContentFadeIn 200ms ease-out forwards',
    },
}

export default ModalSymphony;
