import {
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
  useContext,
  FC,
} from "react"
import {
  StyledLabel,
  ArtistImage as ChannelImage,
  CloseButtonContainer,
  CloseIconContainer,
  ModalContainer,
  ModalMainTitleLabel,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  useStyles,
  StyledButton,
  SearchListContainer,
  CreateNewArtistcontainer,
  CreateNewArtistImage,
  CreateNewBrandLabel,
  ArtistNameBoldLabel,
} from "../styles"
import CloseIcon from "components/shareable/CloseIcon"
import { TextField, Dialog, Grid, InputAdornment, useTheme, useMediaQuery, Chip, Button, Checkbox } from "@material-ui/core"
import debounce from "lodash/debounce"
import { CurrentBrand } from "types/global"
import { clsx } from "modules/Utils"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import { getHelperText, getModalContainerHeight } from "./utils"
import Axios from "helpers/Interceptor"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import dayjs from "dayjs"
import CreateNewBrand from 'assets/images/CreateNewBrand.png';
import { useHistory } from "react-router-dom"
import { Container, TextButton } from "styles/shared"
import { CurrentUserContext } from "Hooks/CurrentUserContext"

interface BrandItemProps {
  /** The brand data to display */
  brand: CurrentBrand;
  /** Index of the item in the list */
  index: number;
  /** Function to handle when brand is clicked */
  onBrandClick: (brand: CurrentBrand) => void;
  /** Whether to show the connect button */
  showConnectButton: boolean;
  /** Whether the view is mobile */
  isMobile: boolean;
  /** Whether to use responsive view */
  setResponsiveView: boolean;
  /** CSS classes for styling */
  classes: {
    channelItemRow: string;
    dividerBottom: string;
    dividerTop: string;
    channelImage: string;
    chip: string;
    connectButtonContainer: string;
  };
}
interface Props {
  open: boolean
  closeModal: () => void
  mode?: 'default' | 'select';
  onImportSelected?: (selectedBrands: CurrentBrand[]) => void;

}

const SearchSelector = ({
  mode = 'default',
  onImportSelected,
  open,
  closeModal,
}: Props) => {

  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(421))
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = useState<string>("")
  const [results, setResults] = useState<any[]>([])
  const [error, setError] = useState(false)

  const [selectedBrands, setSelectedBrands] = useState<CurrentBrand[]>([]);

  // Used for SELECT MODE ==============================================

  const [selectAll, setSelectAll] = useState(false);

  // Handle individual brand selection
  const handleBrandSelection = (brand: CurrentBrand) => {
    if (mode === 'select') {
      const isSelected = selectedBrands.some(b => b.id === brand.id);
      if (isSelected) {
        setSelectedBrands(prev => prev.filter(b => b.id !== brand.id));
      } else {
        setSelectedBrands(prev => [...prev, brand]);
      }
    } else {
      handleOnClick(brand);
    }
  };

  // Handle select all
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedBrands([]);
    } else {
      setSelectedBrands(results);
    }
    setSelectAll(!selectAll);
  };

  // Handle import selected
  const handleImport = () => {
    if (onImportSelected) {
      onImportSelected(selectedBrands);
      closeModal();
    }
  };

  const renderModalFooter = () => {
    if (mode === 'select' && selectedBrands.length > 0) {
      return (
        <Container
          className="modalFooterTeamPlan"
          display="flex"
          justifyContent="flex-end"
          padding="16px"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleImport}
          >
            Import {selectedBrands.length} Selected
          </Button>
        </Container>
      );
    }
    return null;
  };


  // Used for DEFAULT MODE ==============================================
  // with spoofing ability
  const { setSpoofedUserId } = useContext(CurrentUserContext);
  const { setCurrentBrand } = useContext(CurrentBrandContext);
  // const showConnectButton = !isEqual(results, [selectedYoutubeChannel])
  const showConnectButton = false

  const containerHeight = getModalContainerHeight(results.length)


  const SEARCH_OPTIONS = [
    {
      value: 'brand',
      label: "Artist Name",

      placeholder: 'e.g. Bad Bunny, Madonna...'
    },
    {
      value: 'campaignId',
      label: "Campaign ID",
      placeholder: 'e.g. 1242'
    },

    {
      value: 'email',
      label: "User Email",
      placeholder: 'e.g. megh@integral.studio'
    },
    {
      value: 'stripe_id',
      label: "Stripe ID",
      placeholder: 'e.g. Customer ID (cus_123) or Subscription ID (sub_123)'
    },


  ]

  const [searchOption, setSearchOption] = useState(SEARCH_OPTIONS[0])

  const helperText = getHelperText({
    value: textFieldValue,
    error,
    results,
    loading,
    searchString: textFieldValue
  })

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string, searchType: string) => {
        setLoading(true);
        try {
          const response = await Axios.get(`/brand/search?searchTerm=${value}&type=${searchType}`);
          const { data } = response;
          if (data.isError) {
            setError(true);
          } else {
            setResults(data.data.map((brand: CurrentBrand) => ({
              ...brand,
              id: brand.id
            })));
          }
        } catch (e) {
          setError(true);
        }
        setLoading(false);
      }, 800),
    []
  )



  const handleOnClick = (brand: CurrentBrand) => {
    closeModal()
    setCurrentBrand(brand)
    // If we have an ownerId, spoof that user
    if (brand.organization?.ownerId) {
      setSpoofedUserId(brand.organization.ownerId);
    } else {
      // Clear any existing spoofing if there's no owner
      setSpoofedUserId(null);
    }
  }

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.length && open) {
      setLoading(true)
      debouncedChangeHandler(value, searchOption.value)
    }

    setTextFieldValue(value)
  }

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])


  useEffect(() => {
    if (!textFieldValue) {
      setResults([])
      setError(false)
      setLoading(false)
    }
  }, [textFieldValue])

  useEffect(() => {
    setTextFieldValue("")
  }, [searchOption])


  /**
   * Component that renders a single brand item in the search results
   */
  const BrandItem: FC<BrandItemProps> = ({
    brand,
    index,
    onBrandClick,
    showConnectButton,
    isMobile,
    setResponsiveView,
    classes,
  }) => {
    const {
      name: title,
      image: thumbnailUrl,
      tier,
      slug,
      createdAt,
      users,
      organization = null
    } = brand;

    // Determine if the brand is selected
    const isSelected = selectedBrands.some(b => b.id === brand.id);

    const renderTier = () => {
      switch (tier) {
        case 'pro':
          return <StyledLabel marginLeft="auto" color="#E08507" fontSize={12} fontWeight={400}>PRO ACCOUNT</StyledLabel>;
        case 'lite':
          return <StyledLabel marginLeft="auto" color="#8800FF" fontSize={12} fontWeight={400}>LITE ACCOUNT</StyledLabel>;
        default:
          return <StyledLabel fontSize={12} fontWeight={400} color="#707070">Free Account</StyledLabel>;
      }
    }

    return (
      <Grid
        item
        key={brand.id}
        className={clsx(
          classes.channelItemRow,
          index === 0 && classes.dividerBottom,
          index !== 0 && index !== 1 && classes.dividerTop
        )}
      >
        <Grid
          className="cursor-pointer"
          onClick={() => {
            if (mode === 'select' && !organization?.name) {
              return
            } else {
              onBrandClick(brand)
            }
          }}
          spacing={showConnectButton && isMobile ? 2 : 3}
          container
          justifyContent="space-evenly"
          alignItems="center"
        >
          {/* Render checkbox if in 'select' mode */}
          {mode === 'select' && !organization?.name && (
            <Grid item>
              <Checkbox
                checked={isSelected}
                onChange={() => handleBrandSelection(brand)}
              />
            </Grid>
          )}
          <Grid item className={classes.channelImage}>
            <ChannelImage
              widthStyle={setResponsiveView ? "48px" : "56px"}
              heightStyle={setResponsiveView ? "48px" : "56px"}
              alt={`${title}-url-image`}
              src={thumbnailUrl || ''}
            />
          </Grid>
          <Grid
            item
            className={clsx(showConnectButton && isMobile && "w-24")}
            xs={(showConnectButton && !isMobile) || !showConnectButton}
          >
            <Grid container direction="column">
              {/* Brand Title and Slug */}
              <Grid item>
                <StyledLabel fontWeight={500} fontSize={16}>
                  {title}
                </StyledLabel>
                <StyledLabel
                  fontSize={16}
                  fontWeight={400}
                  color="#707070"
                > ({slug})</StyledLabel>
              </Grid>

              {/* Account Type and Creation Date */}
              <Grid item>
                {renderTier()}
                <StyledLabel
                  fontSize={10}
                  fontWeight={400}
                  color="#707070"
                >
                  {"   "}/{"   "}
                </StyledLabel>
                <StyledLabel
                  fontSize={12}
                  fontWeight={400}
                  color="#707070"
                >
                  Created on {dayjs(createdAt).format('MMMM DD, YYYY')}
                </StyledLabel>
              </Grid>

              {/* Users List */}
              <Grid item>
                <StyledLabel
                  margin="12px 0px 0px 0px"
                  fontSize={12}
                  fontWeight={400}
                  color="#707070"
                >
                  Users:
                </StyledLabel>
                {users?.map(({ id, email }) => (
                  <Chip
                    key={`user-${id}`}
                    className={classes.chip}
                    label={email}
                  />
                ))}
              </Grid>

              {/* Organization Name */}
              {organization?.name && (
                <Grid item>
                  <StyledLabel
                    margin="12px 0px 0px 0px"
                    fontSize={12}
                    fontWeight={400}
                    color="#707070"
                  >
                    Team Name:
                  </StyledLabel>
                  <Chip
                    className={classes.chip}
                    label={organization.name}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Connect Button */}
          {showConnectButton && (
            <Grid item className={classes.connectButtonContainer}>
              <Grid container direction="row-reverse">
                <StyledButton>Connect</StyledButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer
        className="searchSelectorModal"
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="32px"
      >
        <ModalTitlesContainer>
          <CloseButtonContainer>
            <CloseIconContainer onClick={closeModal}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseButtonContainer>
          <ModalTitlesLabelsContainer>
            <ModalMainTitleLabel>Admin Artist Selector</ModalMainTitleLabel>
            <ModalSubtitleLabel>
              Search for any artist across Symphony.
            </ModalSubtitleLabel>
          </ModalTitlesLabelsContainer>
        </ModalTitlesContainer>
        <Container display="flex" width="100%" alignItems="center" gap="8px" margin="0px">
          <StyledLabel fontWeight={500} fontSize={16}>
            Search by:
          </StyledLabel>
          <Container display="flex" gap="10px">
            {SEARCH_OPTIONS.map((option, index) => {
              const selected = searchOption.value === option.value;
              return (
                <Chip
                  key={`search-option-${option.value}-${index}`}
                  {...(selected ?
                    { color: 'primary' }
                    :
                    {})
                  }
                  label={option.label} onClick={(e) => setSearchOption(option)} />
              )
            })}


          </Container>

        </Container>
        <Container
          className="modalSearchSelector"
          display="flex"
          flexDirection="column"
          gap="8px"
          width="100%"
          alignItems="flex-start"
        >

          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: loading && (
                <InputAdornment position="end">
                  <Lottie
                    height={24}
                    width={24}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder={searchOption.placeholder}
            helperText={helperText}
            error={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />

          {mode === 'select' && (
            <Container display="flex" flexDirection="column" gap="8px">
              <TextButton
                disabled={loading || results.length === 0}
                onClick={() => {
                  handleSelectAll()
                }}
              >
                Select All
              </TextButton>
            </Container>
          )}
        </Container>
        {textFieldValue && !loading &&
          <SearchListContainer height={containerHeight}>
            <Grid container direction="column">
              {results.length > 0 && textFieldValue !== "" && !loading ?
                results.map((brand, index) => {
                  return (
                    <BrandItem
                      brand={brand}
                      index={index}
                      onBrandClick={handleBrandSelection}
                      showConnectButton={showConnectButton}
                      isMobile={isMobile}
                      setResponsiveView={setResponsiveView}
                      classes={classes}
                    />
                  )
                })
                :
                <Container display="flex" gap="12px"
                  flexDirection="column" width="100%">
                  <StyledLabel
                    fontSize={16}
                    fontWeight={400}
                    color="#707070"
                  >
                    No results found
                  </StyledLabel>
                  <CreateNewArtistcontainer
                    onClick={() => {
                      history.push('/add-artist');
                    }}
                  >
                    <CreateNewArtistImage src={CreateNewBrand} />
                    {searchOption.value === 'brand' ?
                      <CreateNewBrandLabel>
                        Create new artist:&nbsp;
                        <ArtistNameBoldLabel>{textFieldValue}</ArtistNameBoldLabel>
                      </CreateNewBrandLabel>
                      :
                      <CreateNewBrandLabel>
                        Create new artist
                      </CreateNewBrandLabel>
                    }
                  </CreateNewArtistcontainer>
                </Container>
              }
            </Grid>
          </SearchListContainer>
        }
        {mode === 'select' && renderModalFooter()}
      </ModalContainer>
    </Dialog>
  )
}

export default SearchSelector