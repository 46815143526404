import { useState, CSSProperties, useContext } from 'react'
import { OutlinedButton } from 'styles/shared'
import SubscriptionDetailsTile from './SubscriptionDetailsTile'
import useScreen from 'Hooks/useScreen'
import automatedMarketingTools from 'assets/images/automated-marketing-tools.png'
import automatedMarketingToolsMobile from 'assets/images/automated-marketing-tools-mobile.png'
import { BillingCycle } from './shareable/UpgradeToProModal/utils'
import { PlanType, DEFAULT_CURRENCY } from 'modules/Const'
import CurrencyDropdown from './shareable/CurrencyDropdown'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { getBrandPartnerDetails } from 'helpers/partners'
import Colors from 'modules/Colors'
/**
 * Props interface for the SubscriptionDetails component
 * @interface SubscriptionDetailsProps
 */
type SubscriptionDetailsProps = {
    /** Optional CSS class name */
    className?: string;
    /** Optional inline styles */
    style?: CSSProperties;
    /** Optional callback for skipping subscription */
    handleSkipSubscribe?: (() => void) | undefined;
    /** Optional custom call to action text for upgrade */
    upgradeCallToAction?: string;
    /** Optional plan to highlight */
    highlightedPlan?: PlanType;
    /** Optional plan to show in the list */
    onlyShowPlans?: PlanType[];
    /** Source of the checkout */
    checkout_source?: string;
    /** Callback function to be called when the plan changes successfully */
    onPlanChangedSuccess?: (tier: PlanType) => void;
    /** Whether this is a downgrade flow */
    isDowngrade?: boolean;
}

/**
 * Component that displays subscription plan details and options
 * @component
 * @param {SubscriptionDetailsProps} props - Component props
 * @returns {JSX.Element} Rendered component
 */
const SubscriptionDetails = ({
    className = '',
    style = {},
    handleSkipSubscribe,
    upgradeCallToAction,
    highlightedPlan,
    onlyShowPlans = [],
    checkout_source = '',
    onPlanChangedSuccess,
    isDowngrade = false,
}: SubscriptionDetailsProps) => {
    const { mobileView, mStyle } = useScreen()
    const { currentBrand } = useContext(CurrentBrandContext)

    /** State for tracking selected billing cycle */
    const [billingCycle, setBillingCycle] = useState(BillingCycle.MONTHLY)



    /**
     * Handles toggling between billing cycles
     * @param {BillingCycle} billingCycleLocal - The billing cycle to switch to
     */
    const handleBillingCycleToggle = (billingCycleLocal: BillingCycle) => {
        setBillingCycle(billingCycleLocal)
    }

    /**
     * Renders the title section of the subscription details
     * @returns {JSX.Element} Title section with optional custom call to action
     */
    const renderTitle = () => {
        if (isDowngrade) {
            return (
                <div className="subscriptionDetailsTitleContainer">
                    <div
                        className="subscriptionDetailsTitle"
                        style={mStyle(sty, 'subscriptionDetailsTitle')}
                    >
                        Update your subscription with ease.
                    </div>
                </div>
            )
        }
        if (upgradeCallToAction) {
            return (
                <div className="subscriptionDetailsTitleContainer">
                    <div
                        className="subscriptionDetailsTitle"
                        style={mStyle(sty, 'subscriptionDetailsTitle')}
                    >
                        {upgradeCallToAction}
                    </div>
                </div>
            )
        }

        return (
            <div className="subscriptionDetailsTitleContainer">
                <div
                    className="subscriptionDetailsTitle"
                    style={mStyle(sty, 'subscriptionDetailsTitle')}
                >
                    Unlock the full power of Symphony's
                </div>
                <div>
                    <img
                        className="subscriptionDetailsTitleImage"
                        style={{
                            width: '100%',
                            maxWidth: mobileView ? 260 : 520,
                        }}
                        src={mobileView ? automatedMarketingToolsMobile : automatedMarketingTools}
                        alt="Automated Marketing Tools"
                    />
                </div>
            </div>
        )
    }

    /**
     * Renders the billing cycle toggle buttons
     * @returns {JSX.Element} Billing cycle toggle UI
     */
    const renderBillingCycleToggle = () => {
        return (
            <div
                className="subscriptionDetailsMonthlyToggleContainer"
                style={sty.subscriptionDetailsMonthlyToggleContainer}
            >
                <div
                    className="subscriptionDetailsMonthlyToggle"
                    style={{
                        ...sty.subscriptionDetailsMonthlyToggle,
                        ...(billingCycle === BillingCycle.MONTHLY ? sty.isSelected : {}),
                        ...{ borderRight: `1px solid ${Colors.DARK_DIVIDER_LINE}` },
                        ...(mobileView ? { padding: 8, fontSize: 14 } : {}),
                    }}
                    onClick={() => handleBillingCycleToggle(BillingCycle.MONTHLY)}
                >
                    {mobileView ? 'Monthly' : 'Billed monthly'}
                </div>
                <div
                    className="subscriptionDetailsMonthlyToggle"
                    style={{
                        ...sty.subscriptionDetailsMonthlyToggle,
                        ...(billingCycle === BillingCycle.ANNUAL ? sty.isSelected : {}),
                        ...(mobileView ? { padding: 8, fontSize: 14 } : {}),
                    }}
                    onClick={() => handleBillingCycleToggle(BillingCycle.ANNUAL)}
                >
                    {mobileView ? 'Annually (25% off)' : 'Billed annually (25% off)'}
                </div>
            </div>
        )
    }

    /**
     * Renders the currency dropdown selector
     * @returns {JSX.Element} Currency dropdown component
     */
    const renderCurrencyDropdown = () => {
        return (
            <CurrencyDropdown
                className="subscriptionDetailsCurrencyDropdown"
                style={{ padding: 0 }}
                initialValue={currentBrand?.currency?.code || DEFAULT_CURRENCY}
                hideTitle
            />
        )
    }

    /**
     * Renders the subscription plan tiles
     * @returns {JSX.Element} Container with subscription plan options
     */
    const renderSubscriptionTiles = () => {
        /** Determines which subscription plans to display based on onlyShowPlans prop */
        const plansToShow = onlyShowPlans.length > 0 ? onlyShowPlans : [PlanType.LITE, PlanType.PRO, PlanType.TEAM]

        /** Single plan layout styles */
        const singlePlanStyles = plansToShow.length === 1 ? {
            maxWidth: '100%',
            margin: '0 auto'
        } : {}

        return (
            <div
                className="subscriptionDetailsSubscriptionTilesContainer"
                style={{
                    ...sty.subscriptionDetailsSubscriptionTilesContainer,
                    /** Adjust layout for mobile view */
                    ...(mobileView ? { flexDirection: 'column', alignItems: 'center' } : {}),
                }}
            >
                {plansToShow.map((plan) => {
                    /** Configure special display properties for PRO plan */
                    let isMostPopular = false
                    let fromPartner = null
                    if (plan === PlanType.PRO) {
                        /** PRO plan is marked as most popular and may have partner details */
                        isMostPopular = true
                        fromPartner = getBrandPartnerDetails(currentBrand)
                    }
                    return (
                        <SubscriptionDetailsTile
                            key={plan}
                            isDowngrade={isDowngrade}
                            onPlanChangedSuccess={onPlanChangedSuccess}
                            checkout_source={checkout_source}
                            isHighlighted={highlightedPlan === plan}
                            subscriptionType={plan}
                            billingCycle={billingCycle}
                            isMostPopular={isMostPopular}
                            fromPartner={fromPartner}
                            style={singlePlanStyles}
                        />
                    )
                })}
            </div>
        )
    }

    return (
        <div
            className={`subscriptionDetailsComponent ${className}`}
            style={{
                ...mStyle(sty, 'subscriptionDetailsComponent'),
                ...style,
            }}
        >
            {renderTitle()}
            {!isDowngrade && (
                <div className="billingCycleAndCurrencyContainer" style={sty.billingCycleAndCurrencyContainer}>
                    {renderBillingCycleToggle()}
                    {renderCurrencyDropdown()}
                </div>
            )}
            {renderSubscriptionTiles()}

            {handleSkipSubscribe && (
                <div style={{ maxWidth: 412, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <OutlinedButton
                        className="w-full"
                        style={{
                            backgroundColor: 'transparent',
                        }}
                        onClick={handleSkipSubscribe}
                    >
                        Skip for Now
                    </OutlinedButton>
                </div>
            )}
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    subscriptionDetailsComponent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 932,
    },
    subscriptionDetailsComponent_mobile: {
    },
    subscriptionDetailsTitle: {
        fontSize: 40,
        fontWeight: 500,
    },
    subscriptionDetailsTitle_mobile: {
        fontWeight: 500,
        fontSize: 24,
    },
    subscriptionDetailsMonthlyToggleContainer: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        border: `1px solid ${Colors.DARK_DIVIDER_LINE}`,
        borderRadius: 100,
        width: 'fit-content',
        overflow: 'hidden',
        alignSelf: 'flex-start',
    },
    billingCycleAndCurrencyContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    subscriptionDetailsSubscriptionTilesContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 16
    },
    subscriptionDetailsMonthlyToggle: {
        padding: '12px 16px',
    },
    isSelected: {
        color: Colors.purple,
        backgroundColor: Colors.purpleLighter,
    },
}

export default SubscriptionDetails
