import { useContext, useEffect, useState } from "react";
import millify from "millify";
import { checkForFBPermissions, loginWithFB, queryFB } from "helpers/FB";
import Axios from "helpers/Interceptor";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import { track } from "analytics";
import { clsx } from "modules/Utils";
import useStyles from "./styles";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import { ConnectedInstagramAccountType, CreativeTabConnectTypes, InstagramBusinessAccountType } from "types/global";
import { FacebookPageType, InstagramPageType } from "types/global";
import Intercom from "helpers/Intercom";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

export interface ConnectorInstagramPagesType {
    connected_instagram_account: ConnectedInstagramAccountType | null,
    instagram_business_account: InstagramBusinessAccountType | null,
    instagram_account?: {
        follow_count: number;
        followed_by_count: number;
        id: string;
        profile_pic: string;
        username: string;
    } | null,
    facebookPage?: {
        name: string,
        id: string,
        category: string,
        access_token: string,
        followers_count: number,
        picture: string,
        is_published: boolean
    }
}


enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Instagram Pages because we don't have permission. Click the button below to authorize access to your pages."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Instagram pages associated with your Facebook page. To run ads using your Instagram, please link your Instagram to your Facebook page by following the instructions at the link."
    }
}

interface InstagramConnectProps {
    selectedFBPage?: FacebookPageType;
    closeModal: () => void;
    showSave?: any;
    localSave?: any;
    updateFBPages: () => void;
    selectedInstaPage: any;
    withBorderRadius?: boolean;
    selectInstagramPage: (page: any) => void;
    setInstagramPagesAvailable?: (status: boolean) => void;

}

export default function InstagramConnect(props: InstagramConnectProps) {

    const {
        selectedFBPage,
        updateFBPages,
        selectedInstaPage,
        selectInstagramPage,
        closeModal,
        showSave,
        localSave,
        withBorderRadius,
        setInstagramPagesAvailable
    } = props

    const { saveData } = useContext(ConnectModalsContext)
    const { currentBrand: artist, reloadBrand } = useContext(CurrentBrandContext)

    const classes = useStyles();

    // status for Instagram Account
    const [instagramPage, setInstagramPage] = useState<ConnectorInstagramPagesType | null>(selectedInstaPage ? selectedInstaPage : null);
    const [checkedIg, setCheckedIg] = useState<boolean>(false)
    const [loadingPages, setLoadingPages] = useState<boolean>(false)

    // loading indicator for connect
    const [loading, setLoading] = useState<any>(null)

    // error status
    const [error, setError] = useState<any>(null);

    // permissions error
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES | null>(null)

    const isDisabledConnectButton = error || !instagramPage


    useEffect(() => {

        if (setInstagramPagesAvailable) {
            if (instagramPage && instagramPage.connected_instagram_account) {
                setInstagramPagesAvailable(true)
            } else {
                setInstagramPagesAvailable(false)
            }
        }
    }, [instagramPage])
    /**
     * If the user has already selected a Facebook Page, then
     * show that page's linked Instagram pages.
     * 
     * Alternatively, if the user has not selected a Facebook Page,
     * then show a list of their Instagram Pages and select the defaulted Facebook
     */

    async function getIgPage() {
        setLoadingPages(true)
        try {

            let results
            try {
                // first try getting the instagram_business_account
                //  - if there's no instagram_business_account, then there's also no connected_instagram_account ~ instead get the instagram accounts, use the page access token to pull them
                results = await queryFB(`/${selectedFBPage?.id}`, {
                    params: {
                        fields: 'name,category,id,access_token,followers_count,picture{url},is_published,instagram_business_account{username,id,followers_count,profile_picture_url},connected_instagram_account{username,id,followers_count,profile_picture_url}',
                        access_token: selectedFBPage?.access_token
                    }
                })
                const {
                    connected_instagram_account,
                    instagram_business_account,
                    name,
                    id,
                    category,
                    access_token,
                    is_published,
                    followers_count,
                    picture
                } = results

                let mainResult: ConnectorInstagramPagesType = {
                    instagram_business_account,
                    connected_instagram_account,
                    facebookPage: {
                        name,
                        id,
                        category,
                        access_token,
                        is_published,
                        followers_count,
                        picture: picture && picture.data && picture.data.url ? picture.data.url : null
                    }
                }

                if (!mainResult) {
                    throw new Error("No Mapped IG Pages found")
                }
                setInstagramPage(mainResult);
            } catch (e) {
                try {
                    // as a fallback, try getting the instagram accounts
                    let facebookPageDetails = await queryFB(`/${selectedFBPage?.id}`, {
                        params: {
                            fields: 'name,category,id,access_token,followers_count,picture{url},is_published'
                        }
                    })
                    let igAccount = await queryFB(`/${selectedFBPage!.id}/instagram_accounts`, {
                        params: {
                            fields: 'id,follow_count,followed_by_count,username,profile_pic,is_private,is_published',
                            access_token: facebookPageDetails?.access_token
                        }
                    })
                    if (igAccount && igAccount.data && igAccount.data.length > 0) {
                        igAccount = igAccount.data[0]

                        let mainResult: ConnectorInstagramPagesType = {
                            instagram_business_account: null,
                            connected_instagram_account: null,
                            instagram_account: igAccount,
                            facebookPage: {
                                name: facebookPageDetails.name,
                                id: facebookPageDetails.id,
                                category: facebookPageDetails.category,
                                access_token: facebookPageDetails.access_token,
                                is_published: facebookPageDetails.is_published,
                                followers_count: facebookPageDetails.followers_count,
                                picture: facebookPageDetails.picture && facebookPageDetails.picture.data && facebookPageDetails.picture.data.url ? facebookPageDetails.picture.data.url : null
                            }
                        }

                        if (!mainResult) {
                            throw new Error("No Mapped IG Pages found")
                        }
                        setInstagramPage(mainResult);

                    } else {
                        throw new Error("No Instagram Accounts found")
                    }
                } catch (e) {
                    throw e
                }
            }

            setLoadingPages(false)
            setCheckedIg(true)

        } catch (e) {
            console.log('error pulling IG page', e)
            track('Error pulling Instagram - getIgPage in Instagram Connector (Ads Setup)', {
                error: e
            })

            setError(true)
            setCheckedIg(true)
            setInstagramPage(null)
        }


    }

    useEffect(() => {
        setError(null)
        getIgPage()
    }, []);

    async function checkPermissions() {
        const status: any = await checkForFBPermissions([
            'instagram_basic',
            'instagram_manage_insights'
        ])
        if (!status || status.length > 0) {
            // if invalid, show dialog to re-request permissions

            setPermissionsError(ERROR_STATES_STATUSES.PERMISSIONS_ERROR)
        } else {
            // there are no pages
            setPermissionsError(ERROR_STATES_STATUSES.NO_PAGES_ERROR)
        }

        setLoadingPages(false)

    }

    async function reRequestPermissions() {
        const p = await loginWithFB(("rerequest" as any))
        if (p) {
            setError(null)

            setPermissionsError(ERROR_STATES_STATUSES.NONE)
            setCheckedIg(false)
            getIgPage()
        }
    }

    useEffect(() => {
        // if we checked the ad accounts, and they're empty - there 2 things that may
        // have happened here:
        /**
         * 1. the artist declined "ads_management" permission, which we'll need alongside
         * "pages_read_engagement" to get access
         * 2. the artist has no pages, and we need to tell them to setup a page
         */

        if (checkedIg && !instagramPage || instagramPage && !instagramPage.instagram_account && !instagramPage.connected_instagram_account) {
            checkPermissions()
        }
    }, [instagramPage, checkedIg])


    async function updateInBrand() {

        setLoading(true)

        const {
            facebookPage,
            connected_instagram_account,
            instagram_business_account
        } = instagramPage!

        const connectIGPromise = new Promise(async (resolve, reject) => {
            try {

                // to connect IG, we need to get the `instagram_accounts` field from the FB Page

                // In case of error just get the instagram_accounts with the page access token to safely continue
                let igAccount = await queryFB(`/${selectedFBPage!.id}/instagram_accounts`, {
                    params: {
                        fields: 'id,follow_count,followed_by_count,username,profile_pic,is_private,is_published',
                        access_token: facebookPage?.access_token,
                        forceToken: facebookPage?.access_token,
                    }
                })

                if (igAccount && igAccount.data && igAccount.data.length > 0) {
                    igAccount = igAccount.data[0]

                } else {
                    throw new Error("No Instagram Accounts found")
                }

                const p = await Axios.put(`/brand/${artist?.slug}/connect`, {
                    id: artist?.id,
                    name: artist?.name,
                    service: 'instagram_page',
                    value: {
                        businessAccount: instagram_business_account,
                        instagramAccount: igAccount,
                        connected_instagram_account: connected_instagram_account
                    }
                })

                // this is used in local state for the campaign creation workflow
                let returnedItem: InstagramPageType = {
                    id: selectedFBPage!.id,
                    name: selectedFBPage!.name,
                    connected_instagram_account: connected_instagram_account,
                    businessAccount: instagram_business_account,
                    instagramAccount: igAccount
                }
                selectInstagramPage(returnedItem)

                resolve(1)
            } catch (e) {
                console.error("Error connecting Instagram", e)
                track('Error connecting Instagram - updateInBrand in Instagram Connector', {
                    error: e
                })
                reject("Error connecting Instagram")
            }
        })

        await connectIGPromise
        setLoading(false)

        closeModal()
    }

    const handleConnect = async () => {
        if (!localSave) {
            await updateInBrand()
            await reloadBrand()
        } else {
            closeModal()
        }
    }

    useEffect(() => {
        saveData({
            loading,
            connectModalType: CreativeTabConnectTypes.INSTAGRAM_CONNECT,
            isDisabledConnectButton,
            handleConnect
        })
    }, [isDisabledConnectButton, instagramPage, loading])

    function handleNonError() {

        if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR
            || permissionsError === ERROR_STATES_STATUSES.NONE) {
            return (
                <><p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        onClick={() => {
                            Intercom.openFacebookInstagramConnectorArticle()
                        }} className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Learn how to link your Instagram &amp; Facebook Page &gt;
                    </a>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {

            return (
                <><p className="my-4">{ERROR_STATES.PERMISSIONS_ERROR.message}</p>
                    <button type="button"
                        onClick={() => reRequestPermissions()}
                        className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Add Instagram Permissions &gt;
                    </button>
                </>
            )
        }

    }

    function postLoadingComponent() {


        // this requires a FB page to be linked with IG to show any sort of connect info
        if (instagramPage && (instagramPage.connected_instagram_account)) {
            const {
                connected_instagram_account,
                instagram_business_account,
                instagram_account,
                facebookPage
            } = instagramPage!

            let mainAccount = connected_instagram_account ? connected_instagram_account : instagram_account
            const followersCount = connected_instagram_account ? connected_instagram_account!.followers_count :
                (instagram_account?.followed_by_count ? instagram_account?.followed_by_count : null)
            const profilePic = connected_instagram_account ? connected_instagram_account!.profile_picture_url : instagram_account?.profile_pic
            let checked
            let username = connected_instagram_account ? connected_instagram_account.username : instagram_account?.username
            if (connected_instagram_account) {
                checked = instagramPage && instagramPage.connected_instagram_account ? instagramPage?.connected_instagram_account!.id === connected_instagram_account!.id : false
            } else if (instagram_account) {
                checked = instagramPage && instagramPage.instagram_account ? instagramPage?.instagram_account!.id === instagram_account!.id : false
            }

            return (

                <li key={mainAccount!.id}
                    className={'px-5 lg:px-10 sy-card flex-row flex-wrap'}
                    onClick={() => setInstagramPage(instagramPage)}>
                    <div className="flex items-center">
                        <input id={'insta_' + mainAccount!.id}
                            type="radio"
                            className="mr-2"
                            name={'conect-instagram-pages'}
                            value={mainAccount!.id}
                            checked={checked}
                            onClick={() => setInstagramPage(instagramPage)}
                        />

                        <div className="flex items-center ">
                            <div className="artist-img flex-shrink-0"
                                style={(!profilePic)
                                    ? { background: '#07c806', borderRadius: '50%' } : {}}>
                                <img
                                    src={(profilePic) || require("../../assets/images/profileIcon.svg").default}
                                    alt="Instagram Page"
                                />

                            </div>
                            <div className="px-3 ">
                                <p>{username}</p>
                                <div className="flex flex-row items-center">

                                    <div className="social-text text-sm">
                                        {followersCount && <p>
                                            {millify(followersCount!) + ` Followers`}
                                        </p>}
                                        {!instagram_business_account && (
                                            <div className="text-left mt-1">
                                                <p className={'text-red-500 '}>Your Instagram is not a Business Account.</p>

                                                <p>
                                                    We recommend switching your Instagram into a business account to run Instagram ads and do more effective marketing.
                                                </p>
                                                <a className="text-green-500 underline" onClick={() => {
                                                    Intercom.openInstagramBusinessAccountArticle()
                                                }}>
                                                    Click this link to learn how &gt;
                                                </a>


                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  */}
                    </div>

                </li >
            )
        } else {
            return handleNonError()
        }
    }

    return (
        <div className={clsx("bg-white w-full h-full mx-auto", withBorderRadius && classes.withBorderRadius)}>
            <div
                className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
            >
                <p className="font-semibold text-gray-800">Connect Instagram</p>
                <a onClick={() => closeModal()}>
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </a>
            </div>
            <div className="middle-wrapper px-4 lg:px-10 ">
                <h4 className="mt-5 pb-5 text-2x font-semibold border-b border-gray-200">
                    Selected Facebook Page:
                </h4>
                <div className="cursor-pointer sy-card">
                    <div className="flex items-center">
                        <div className="artist-img" style={(!instagramPage?.facebookPage?.picture) ? { background: '#07c806', borderRadius: '50%' } : {}}>
                            <img
                                src={(instagramPage?.facebookPage?.picture) || require("../../assets/images/profileIcon.svg").default}
                                alt="Facebook Page"
                            />
                        </div>
                        <div className="px-3">
                            <h5 className="text-dark">{selectedFBPage?.name}</h5>
                        </div>
                    </div>
                    <div className="icon-block">
                        <a onClick={updateFBPages} className="text-primary" >Edit</a>
                    </div>
                </div>

                <div className="mt-5 pb-5 border-b border-gray-200">
                    <h4 className=" text-2x font-semibold  ">
                        Select {artist?.name}'s Instagram Page:
                    </h4>
                    <p>Your ads will run from your Instagram Account and your follower analytics will be tracked in the Dashboard.</p>
                </div>
                <ul className="add-new">
                    {loadingPages ? (<li className="cursor-pointer sy-card flex items-center justify-center">
                        <LoadingIndicator color="black" height="30px" />
                    </li>) : null}
                    {checkedIg && !loadingPages && postLoadingComponent()}
                </ul>
            </div>
        </div>
    )
}
