import { NewLoader } from "components/Loader/ConnectBrandLoader"
import BannerSection from "components/shareable/BannerSection"
import PostItem from "components/shareable/InstagramPosts/PostItem"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { useContext, useEffect, useState } from "react"
import { FacebookPageType } from "types/global"
import { loadInstagramPosts } from "../../utils/fbCampaigns"
import ConnectInstagramSection from "../ConnectInstagramSection"
import SuperboostModal from "../SuperboostModal"
import { PostParent, Posts } from "./styles"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

const SuperboostSection = () => {
    const { currentBrand } = useContext(CurrentBrandContext)

    const [loading, setLoading] = useState<boolean>(true)

    // holds the IG posts that can be superboosted
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([])

    // selects whether to show superboost modal
    const [superboostedPost, setSuperboostedPost] = useState<InstagramPost | null>(null)
    const [showModal, setShowModal] = useState<boolean>(false)

    // when a superboosted post is selected, show the modal
    useEffect(() => {
        if (superboostedPost) {
            setShowModal(true)
        }
    }, [superboostedPost])

    // when the modal is closed, reset the superboosted post
    useEffect(() => {
        if (!showModal) {
            setSuperboostedPost(null)
        }
    }, [showModal])

    const selectedFacebookPage: FacebookPageType = currentBrand!.connections!.facebook_page!

    const selectedInstaPage = currentBrand &&
        currentBrand.connections &&
        currentBrand.connections.facebook_page &&
        !currentBrand.connections.reauthenticateFbUser &&
        currentBrand.connections.instagram_page

    const loadIGPosts = async () => {
        setLoading(true)

        if (selectedInstaPage) {
            const instagramPosts = await loadInstagramPosts({ instagramPage: selectedInstaPage })
            setInstagramPosts(instagramPosts)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadIGPosts()
    }, [])

    if (loading) {
        return (
            <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
                <NewLoader black />
                <p className="mt-1 text-center">Loading...</p>
            </div>
        )
    }

    if (!(selectedInstaPage && selectedFacebookPage)) {
        return (
            <div>
                <BannerSection
                    title="Superboost your Instagram Posts"
                    description={`Superboosting a post uses Symphony's audience targeting AI to automatically give you 3x the results compared to "boosting" a post on Instagram would.`}
                    iconComponent={(
                        <div className="rounded-full bg-red-500 w-16 h-16"></div>
                    )}
                />

                <ConnectInstagramSection />
            </div>
        )
    } else {
        return (
            <>
                <div>
                    <BannerSection
                        title="Superboost your Instagram Posts"
                        description={`Superboosting a post uses Symphony's audience targeting AI to automatically give you 3x the results compared to "boosting" a post on Instagram would.`}
                        iconComponent={(
                            <div className="rounded-full bg-red-500 w-16 h-16"></div>
                        )}
                    />
                    {!(instagramPosts.length > 0 && selectedInstaPage) &&
                        <ConnectInstagramSection />
                    }

                    <Posts>
                        {instagramPosts.map((post: InstagramPost) => {
                            return <PostParent>
                                <PostItem
                                    post={post}
                                    onClick={(e: InstagramPost) => setSuperboostedPost(e)}
                                />
                            </PostParent>
                        })}
                    </Posts>
                </div>
                <SuperboostModal
                    open={showModal}
                    closeModal={() => setShowModal(false)}
                />
            </>
        )
    }

}

export default SuperboostSection