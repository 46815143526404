import { IconButton, SvgIcon } from "@material-ui/core";

interface CloseButtonProps {
    onClick: () => void;
}

export default function CloseButton(props: CloseButtonProps) {

    const {
        onClick
    } = props
    
    return (
        <IconButton
            onClick={onClick}
            aria-label="close modal" style={{
                "padding": "2px"
            }}>
            <SvgIcon viewBox="0 0 40 40" fontSize="large">
                <rect width="40" height="40" rx="20" fill="#8800FF" fillOpacity="0.04" />
                <path d="M26 14L14 26" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 14L26 26" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </SvgIcon>
        </IconButton>
    )
}