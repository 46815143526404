import { Theme, makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import Colors from "modules/Colors"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: Colors.purple,
    "&$checked": {
      color: `${Colors.purple} !important`,
    },
  },
  checked: {},
}))

export const StyledListItem = styled.li`
  width: 100%;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid ${Colors.divider};
`

export const StyledUnorderedList = styled.ul`
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ChipContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`

export const NotAvailableChip = styled.div`
  width: 117px;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 22px;
  background-color: ${Colors.divider};
  padding: 8px 12px;
`

export const CreateNewPixelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

export const PlusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.green};
  border: 1px solid ${Colors.green};
  width: 32px;
  height: 32px;
  font-size: 30px;
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

export const InnerContainer = styled.div`
  padding: 0px 12px;
`

export const StyledLabel = styled.label`
  color: ${Colors.green};
`

export default useStyles
