import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import millify from "millify";
import { CurrencyCodesType } from "types/global";
import { DEFAULT_CURRENCY } from "modules/Const";
import { getConvertedCurrency } from "services/symphonyApi";
import { getNumberWithDecimals } from "helpers/General";

export const totalLengthCalculator = (args: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { startDate, endDate } = args;

  if (!(startDate && endDate)) return "...";

  const difference = dayjs(endDate).diff(dayjs(startDate), "day");

  if (difference > 1) {
    return `${difference} days`;
  } else {
    return `${difference} day`;
  }
};

export const dailyBudgetCalculator = (args: {
  startDate: Date | null;
  endDate: Date | null;
  budgetValue: number;
}) => {
  const { startDate, endDate, budgetValue } = args;

  if (!(startDate && endDate && budgetValue && budgetValue > 0)) return "...";

  var Difference_In_Time = endDate.getTime() - startDate.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const estDaily = budgetValue / Difference_In_Days;

  return estDaily.toFixed(0);
};

const getConvertedCost = async (defaultValue: number, currency: CurrencyCodesType) => {
  if (currency !== DEFAULT_CURRENCY) {
    const convertedValue = await getConvertedCurrency({ amount: defaultValue, from: DEFAULT_CURRENCY, to: currency })
    return getNumberWithDecimals(convertedValue, 2);
  }
  return getNumberWithDecimals(defaultValue, 2);
}

export const estimatedCostPerView = async (targeting: string, geographyType: string, currency?: CurrencyCodesType) => {
  if (!targeting || !geographyType) return "...";
  currency = currency ?? DEFAULT_CURRENCY;

  if (targeting === 'engagement') {
    if (geographyType === 'english') {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.04, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.10, currency)}`;
    } else if (geographyType === 'global') {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.02, currency)}`;
    } else {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.03, currency)}`
    }
  } else if (targeting === 'views') {
    if (geographyType === 'english') {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.03, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.05, currency)}`;
    } else if (geographyType === 'global') {
      return `less than ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)}`;
    } else {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.02, currency)}`
    }
  } else { // targeting === default
    if (geographyType === 'english') {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.02, currency)}`;
    } else if (geographyType === 'global') {
      return `less than ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)}`;
    } else {
      return `${getSymbolFromCurrency(currency)}${await getConvertedCost(0.01, currency)} - ${getSymbolFromCurrency(currency)}${await getConvertedCost(0.02, currency)}`
    }
  }
}


export const estimatedStreamsCalculator = async (
  budgetValue: number,
  targeting: string,
  geographyType: string,
  currency?: CurrencyCodesType
) => {
  if (budgetValue === 0) return "...";

  currency = currency ?? DEFAULT_CURRENCY;

  let lowestCostPerStream = await getConvertedCost(0.02, currency)
  let highestCostPerStream = await getConvertedCost(0.1, currency)

  if (targeting === 'engagement') {
    if (geographyType === 'english') {
      lowestCostPerStream = await getConvertedCost(0.04, currency)
      highestCostPerStream = await getConvertedCost(0.1, currency)
    } else if (geographyType === 'global') {
      lowestCostPerStream = await getConvertedCost(0.01, currency)
      highestCostPerStream = await getConvertedCost(0.02, currency)
    } else {
      lowestCostPerStream = await getConvertedCost(0.01, currency)
      highestCostPerStream = await getConvertedCost(0.03, currency)
    }
  } else if (targeting === 'views') {
    if (geographyType === 'english') {
      lowestCostPerStream = await getConvertedCost(0.03, currency)
      highestCostPerStream = await getConvertedCost(0.05, currency)
    } else if (geographyType === 'global') {
      lowestCostPerStream = await getConvertedCost(0.0025, currency)
      highestCostPerStream = await getConvertedCost(0.005, currency)
    } else {
      lowestCostPerStream = await getConvertedCost(0.01, currency)
      highestCostPerStream = await getConvertedCost(0.02, currency)
    }
  } else { // targeting === default
    if (geographyType === 'english') {
      lowestCostPerStream = await getConvertedCost(0.01, currency)
      highestCostPerStream = await getConvertedCost(0.02, currency)
    } else if (geographyType === 'global') {
      lowestCostPerStream = await getConvertedCost(0.0025, currency)
      highestCostPerStream = await getConvertedCost(0.005, currency)
    } else {
      lowestCostPerStream = await getConvertedCost(0.01, currency)
      highestCostPerStream = await getConvertedCost(0.02, currency)
    }
  }


  var lowValue = Math.round(budgetValue / Number(highestCostPerStream));
  var highValue = Math.round(budgetValue / Number(lowestCostPerStream));

  return `${millify(lowValue)} - ${millify(highValue)}`;
};
