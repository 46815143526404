/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  ChangeEvent,
  MouseEventHandler,
  MouseEvent,
  KeyboardEvent,
  useContext,
  useCallback
} from "react";
import {
  TextField,
  Chip,
  MenuItem,
  Grid,
  Checkbox,
  SvgIcon,
  InputAdornment,
  IconButton,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import useStyles from "./styles";
import {
  DataFBQueryAdInterest,
  FbSuggestion,
  AutoCompleteCloseReasons as CloseReasons,
  AutoCompleteChangeReasons as ChangeReasons,
  FBCustomAudience,
  FacebookPageResponse,
} from "types/global";
import { Option as SpotifyArtistsOption } from "pages/post-auth/MarketingPage/MusicStreams/TargetingView/Components/AutoCompleteSpotifyArtists/utils";
import debounce from "lodash/debounce";
import { queryFB } from "helpers/FB";
import {
  NOT_REMOVABLE,
  AutoCompleteReason,
  DeleteIconProps,
  getOnDeleteChip,
  DeleteArgs,
  EMPTY_STRING,
  getNotRemovableOptions,
  getDataWhenDeletedArtist,
  getDataWhenGenreChanged,
  getDataWhenSuggestedArtist,
} from "./utils";
import useAutocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@material-ui/lab/useAutocomplete";
import uniqBy from "lodash/uniqBy";
import millify from "millify";
import { MarketingDataContext } from "pages/post-auth/MarketingPage/Data/Provider";
import AudienceTargetingStatusIndicator from "components/shareable/AudienceTargetingStatusIndicator";
import { StyledLabel } from "styles/shared";
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext";

interface CloseIconProps {
  color?: string;
}

const CloseIcon = ({ color }: CloseIconProps) => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 2.25L2.25 6.75"
      stroke={color || "white"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 2.25L6.75 6.75"
      stroke={color || "white"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const getDeleteChipIcon = (option: FbSuggestion, onDelete: () => void, color?: string) => {
  if (!option.id?.includes(NOT_REMOVABLE))
    return <DeleteIcon {...{ color }} onClick={onDelete} />;
  return undefined;
};

const DeleteIcon = ({
  color,
  onClick,
}: DeleteIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon {...{ onClick }} viewBox="1 0 7 8" className={classes.closeIcon}>
      <CloseIcon {...{ color }} />
    </SvgIcon>
  );
};

const CustomCloseIcon = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
    focusable="false"
    viewBox="0 0 26 26"
    aria-hidden="true"
  >
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  </svg>
);

interface AutoCompleteAudiencesProps {
  genre: string;
  deletedArtist: SpotifyArtistsOption[];
  initialTargetingAudiences: FbSuggestion[];
  suggestedTargetingAudienceByArtist: FbSuggestion[];
  deleteIconColor?: string;
  handleDeleteAudiences?: (
    artistId: string | null,
    selected: FbSuggestion[]
  ) => void;
  setDeletedArtist: Dispatch<SetStateAction<SpotifyArtistsOption[]>>;
  setAudiences: Dispatch<SetStateAction<FbSuggestion[]>>;
  customAudiences?: FBCustomAudience[];
  setCustomAudiences?: (customAudiences: FBCustomAudience[]) => void;

  additionalPageAudiences?: FacebookPageResponse[];
  setAdditionalPagesAudiences?: (audiences: FacebookPageResponse[]) => void;
}

const AutoCompleteAudiences = ({
  genre,
  deletedArtist,
  initialTargetingAudiences,
  suggestedTargetingAudienceByArtist,
  deleteIconColor,
  handleDeleteAudiences,
  setDeletedArtist,
  setAudiences,
  customAudiences,
  setCustomAudiences,
  setAdditionalPagesAudiences,
  additionalPageAudiences
}: AutoCompleteAudiencesProps) => {
  const { audiences: contextAudiences } = useContext(MarketingDataContext)
  const { loggedInFbUser } = useContext(ConnectionsContext)
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([] as FbSuggestion[]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([] as FbSuggestion[]);
  const [loadedInitialData, setLoadedInitialData] = useState(false);

  const handleOpenList = () => {
    if (inputValue.trim() !== EMPTY_STRING) setOpen(true);
  };

  const handleCloseList = (
    _event: ChangeEvent<{}>,
    reason: AutoCompleteReason
  ) => {
    if (
      reason !== CloseReasons.selectOption &&
      reason !== CloseReasons.removeOption
    ) {
      setOpen(false);
      setInputValue(EMPTY_STRING);
    }
  };

  const handleClearInput = () => {
    setInputValue("");
  };

  const debouncedAPICall = debounce(
    async (
      _event: ChangeEvent<{}>,
      value: string,
      selectedOptions: FbSuggestion[]
    ) => {
      const isValid = value !== EMPTY_STRING && value !== null

      if (isValid) {
        try {
          setLoading(true)

          const results = await queryFB(`/search`, {
            params: {
              type: "adinterest",
              q: value,
              limit: 10,
              access_token: loggedInFbUser?.access_token,
            },
          })
          const data: DataFBQueryAdInterest = results?.data
          const optionNames = data.filter((o) => o.name).map((o) => o.name)

          const adInterests = await queryFB(`/search`, {
            params: {
              type: "adinterestvalid",
              interest_list: optionNames,
              access_token: loggedInFbUser?.access_token,
            },
          })
          const adInterestsData: DataFBQueryAdInterest = adInterests?.data
          const validAdInterestsData = adInterestsData?.filter((o) => o.valid)

          const newOptions = validAdInterestsData.map((o) => ({
            id: o.id,
            name: o.name,
            path: o.path,
            topic: o.topic,
            description: o.description,
            artistId: null,
            audience_size:
              o.audience_size || o.audience_size_lower_bound || null,
            country: null,
            country_access: null,
            partner: null,
            source: null,
            type: null,
          }))

          setOptions([...newOptions, ...selectedOptions])
          setLoading(false)
          setOpen(true)
        } catch (error: unknown) {
          setOptions([])
          setLoading(false)
          setOpen(false)
          console.error("Error on AutoCompleteAudiences component: ", error)
        }
      }
    },
    1500
  )

  const handleChangeTextField = useCallback(
    (
      _event: ChangeEvent<{}>,
      value: string,
      selectedOptions: FbSuggestion[]
    ) => {
      debouncedAPICall(_event, value, selectedOptions)
    },
    []
  )

  const handleAutoCompleteOnChange = (
    _event: ChangeEvent<{}>,
    selected: FbSuggestion[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<FbSuggestion>
  ) => {
    const artistId = details?.option.artistId || null;
    if (handleDeleteAudiences && reason === ChangeReasons.removeOption) {
      handleDeleteAudiences(artistId, selected);
    }
    setSelectedOptions(selected);
    setAudiences(selected);
  };

  const handleAutoCompleteInputChange = (
    _event: ChangeEvent<{}>,
    value: string
  ) => {
    setInputValue(value);
    handleChangeTextField(_event, value, selectedOptions);
  };

  const handleOnFocus = () => {
    if (inputValue) setOpen(true);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Backspace" || event.key === "Enter") {
      event.stopPropagation();
    }
  };

  const {
    groupedOptions,
    value,
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    setAnchorEl,
  } = useAutocomplete({
    id: "autocomplete-audiences-hook",
    multiple: true,
    options,
    value: selectedOptions,
    inputValue,
    open,
    getOptionSelected: (option, value) => option.id === value.id,
    onOpen: handleOpenList,
    onClose: handleCloseList,
    getOptionLabel: (option) => option.name,
    onInputChange: handleAutoCompleteInputChange,
    onChange: handleAutoCompleteOnChange,
  });

  useEffect(() => {
    if (contextAudiences) {
      setSelectedOptions(contextAudiences);
      setAudiences(contextAudiences);
      setOptions(contextAudiences);
      setLoadedInitialData(true);
    }
  }, [contextAudiences]);

  useEffect(() => {
    if (initialTargetingAudiences && !contextAudiences.length) {
      const notRemovable = getNotRemovableOptions(genre);
      const filtered = selectedOptions.filter(
        (o) => !o.id?.includes(NOT_REMOVABLE)
      );
      const options = [
        ...notRemovable,
        ...initialTargetingAudiences,
        ...filtered,
      ];

      setSelectedOptions(options);
      setAudiences(options);
      setOptions(options)
      setLoadedInitialData(true);
    }
  }, [initialTargetingAudiences]);

  useEffect(() => {
    if (genre) {
      const notRemovable = getNotRemovableOptions(genre);
      setSelectedOptions((prev) => getDataWhenGenreChanged(prev, notRemovable));
      setAudiences((prev) => getDataWhenGenreChanged(prev, notRemovable));
      setOptions((prev) => getDataWhenGenreChanged(prev, notRemovable));
    }
  }, [genre]);

  useEffect(() => {
    if (suggestedTargetingAudienceByArtist && loadedInitialData) {
      setSelectedOptions((prev) => getDataWhenSuggestedArtist(prev, suggestedTargetingAudienceByArtist));
      setAudiences((prev) => getDataWhenSuggestedArtist(prev, suggestedTargetingAudienceByArtist));
      setOptions((prev) => getDataWhenSuggestedArtist(prev, suggestedTargetingAudienceByArtist));
    }
  }, [suggestedTargetingAudienceByArtist]);

  useEffect(() => {
    if (Boolean(deletedArtist.length) && deletedArtist[0].id) {
      setSelectedOptions((prev) => getDataWhenDeletedArtist(prev, deletedArtist));
      setAudiences((prev) => getDataWhenDeletedArtist(prev, deletedArtist));
      setDeletedArtist([]);
    }
  }, [deletedArtist]);

  return (
    <>
      <AudienceTargetingStatusIndicator
        selectedAudiences={selectedOptions.filter(
          (o) => !o.id?.includes(NOT_REMOVABLE)
        )}
      />
      <div className={classes.principalContainer}>
        <div {...getRootProps()}>
          <Grid container direction="column" className="w-full">
            <Grid item className={classes.chipContainer} ref={setAnchorEl}>
              {value.map((option: FbSuggestion, index: number) => {
                const props = getTagProps({ index });
                const { onDelete } = props as Record<string, unknown>;
                const args: DeleteArgs = [option, onDelete as () => void, deleteIconColor];
                const deleteIcon = getDeleteChipIcon(...args);
                const onDeleteChip = getOnDeleteChip(...args);

                return (
                  <Chip
                    {...props}
                    onDelete={onDeleteChip}
                    className="h-7 mx-0.5 mt-2"
                    deleteIcon={deleteIcon}
                    classes={{
                      label: classes.chipLabelColor,
                      colorPrimary: classes.chipBackgroundColor,
                    }}
                    color="primary"
                    label={option.name}
                  />
                );
              })}
            </Grid>
            {customAudiences && customAudiences.length > 0 && (
              <>
                <div className="px-4">
                  <StyledLabel
                    fontSize={16}
                    fontWeight={400}
                    color="#707070"
                    className="mt-4">Selected Custom Audiences</StyledLabel>
                </div>
                <Divider className="mb-4" />
                <Grid item className={classes.chipContainer} ref={setAnchorEl}>

                  {customAudiences && customAudiences.length > 0 && customAudiences.map((customAudience) => {
                    return (
                      <Chip
                        className="h-7 mx-0.5 mt-2"
                        classes={{
                          label: classes.chipLabelColor,
                          colorPrimary: classes.chipBackgroundColor,
                        }}
                        onDelete={() => {
                          if (!setCustomAudiences) return
                          const updated = customAudiences.filter((o: any) => {
                            return o.id !== customAudience.id
                          })

                          setCustomAudiences(updated)
                        }}
                        deleteIcon={<DeleteIcon color="#80f" onClick={() => {
                        }} />}
                        color="primary"
                        label={customAudience.name}
                      />
                    )
                  })}
                </Grid>
              </>
            )}
            {additionalPageAudiences && additionalPageAudiences.length > 0 &&
              <>
                <div className="px-4">
                  <StyledLabel
                    fontSize={16}
                    fontWeight={400}
                    color="#707070"
                    className="mt-4">Selected Additional FB/IG Page Audiences</StyledLabel>
                </div>
                <Divider className="mb-4" />
                <Grid item className={classes.chipContainer} ref={setAnchorEl}>

                  {additionalPageAudiences && additionalPageAudiences.length > 0 && additionalPageAudiences.map((customAudience) => {
                    return (
                      <Chip
                        icon={<img src={customAudience.picture.data.url} className="w-5 h-5 rounded-full" alt="page-icon" />}
                        className="h-7 mx-0.5 mt-2"
                        classes={{
                          label: classes.chipLabelColor,
                          colorPrimary: classes.chipBackgroundColor,
                        }}
                        onDelete={() => {
                          if (!setAdditionalPagesAudiences) return
                          const updated = additionalPageAudiences.filter((o: any) => {
                            return o.id !== customAudience.id
                          })

                          setAdditionalPagesAudiences(updated)
                        }}
                        deleteIcon={<DeleteIcon color="#80f" onClick={() => {
                        }} />}
                        color="primary"
                        label={`Fans of ${customAudience.name}'s Facebook Page${customAudience.instagram_business_account ? ` + Instagram Account` : ''}`}
                      />
                    )
                  })}
                </Grid>
              </>
            }
            <Grid item className="w-full">
              <Grid container>
                <Grid item xs={inputValue ? 11 : 12}>
                  <TextField
                    {...getInputProps()}
                    onFocus={handleOnFocus}
                    onKeyDown={handleOnKeyDown}
                    value={inputValue}
                    className={classes.inputTextField}
                    InputProps={{
                      classes: {
                        input: classes.inputTextAlign,
                      },
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <b>Search for Additional Interests:</b>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="e.g. Jay-Z"
                  />
                </Grid>
                {inputValue && (
                  <Grid
                    item
                    xs={1}
                    className={classes.customCloseIconButtonContainer}
                  >
                    {loading ? (
                      <div className="flex justify-center mt-2">
                        <CircularProgress
                          disableShrink
                          color="inherit"
                          size={16}
                        />
                      </div>
                    ) : (
                      <IconButton
                        className={classes.customCloseIconButton}
                        onClick={handleClearInput}
                      >
                        <SvgIcon viewBox="-1 -1 24 24">
                          <CustomCloseIcon />
                        </SvgIcon>
                      </IconButton>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {open && Boolean(options.length) &&
          <div className={classes.listbox} {...getListboxProps()}>
            {uniqBy(groupedOptions, "id").map((option, index) => {
              const optionProps = getOptionProps({ option, index }) as Record<
                string,
                unknown
              >;
              const { onClick } = optionProps;
              const handleOnClickOption = onClick as (
                event: MouseEvent<HTMLLIElement, MouseEvent>
              ) => void;
              const disabled = option.id?.includes(NOT_REMOVABLE);
              const [foundChecked] = selectedOptions.filter(
                (item) => item.id === option.id
              );
              const checked = Boolean(foundChecked);
              const handleClick = (
                event: MouseEvent<HTMLLIElement, MouseEvent>
              ) => {
                if (!disabled) handleOnClickOption(event);
              };

              return (
                <MenuItem
                  {...optionProps}
                  divider
                  onClick={
                    handleClick as unknown as MouseEventHandler<HTMLLIElement>
                  }
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <span>{option.name}</span>
                        </Grid>
                        <Grid item>
                          <span style={{ fontSize: 12 }}>
                            Total audience size:{" "}
                            {millify(option.audience_size || 0)}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        {...{ checked, disabled }}
                        color="default"
                        classes={{
                          root: classes.rootCheckbox,
                          checked: classes.checkedCheckbox,
                        }}
                      />
                    </Grid>
                  </Grid>
                </MenuItem>
              );
            })}
          </div>
        }
      </div>
    </>
  );
};

export default AutoCompleteAudiences;
