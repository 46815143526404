import LinkButton from 'components/shareable/LinkButton';
import { Body2, Caption } from 'components/shareable/Typography';
import { ConnectionIcon, useStyles } from './styles';
import { ReactComponent as CheckIcon } from 'assets/images/check-black.svg';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import Colors from 'modules/Colors';

interface StepDetailProps {
  isCompleted: boolean;
  stepName: string;
  stepIcon?: JSX.Element;
  showStepName?: boolean;
  iconContainerColor?: string;
  thumbnail?: string;
  title: string;
  descriptions?: string[];
  canEdit?: boolean;
  tooltip?: string;
  disabledButton?: boolean;
  errorText?: string;
  onButtonClick: () => void;
}

const StepDetail = ({
  isCompleted,
  stepName,
  stepIcon,
  showStepName = true,
  iconContainerColor,
  thumbnail,
  title,
  descriptions,
  canEdit = true,
  tooltip,
  disabledButton,
  errorText,
  onButtonClick,
}: StepDetailProps) => {
  const classes = useStyles();

  if (isCompleted) {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.descriptionContainer}>
          <div>
            {thumbnail ?
              <img src={thumbnail} alt={title} className={classes.thumbnail} />
              :
              stepIcon &&
              <ConnectionIcon backgroundColor={iconContainerColor}>
                {stepIcon}
              </ConnectionIcon>
            }
          </div>
          <div className='flex flex-col'>
            {showStepName &&
              <div className='flex gap-1 items-center'>
                {stepIcon && <div className={classes.smallIcon}>{stepIcon}</div>}
                <Caption color={Colors.greyDark}>
                  {stepName}
                </Caption>
              </div>
            }
            <div className='flex flex-col gap-1'>
              <Body2>{title}</Body2>
              {descriptions?.map((description, index) => (
                <Caption key={index} color={Colors.greyDark}>
                  {description}
                </Caption>
              ))}
              {errorText &&
                <Caption color={Colors.yellow}>
                  {errorText}
                </Caption>
              }
            </div>
          </div>
        </div>
        <div className='flex gap-4 items-center'>
          <LinkButton
            text='Edit'
            onClick={onButtonClick}
            disabled={!canEdit}
            tooltip={tooltip}
          />
          <div className={classes.greenContainer}>
            <Body2 color={Colors.green}>Connected</Body2>
            <CheckIcon height={24} width={24} className={classes.greenIcon} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.descriptionContainer}>
        {stepIcon && <ConnectionIcon grayedOut>{stepIcon}</ConnectionIcon>}
        <Body2 color={Colors.textColor}>{title}</Body2>

      </div>
      <ButtonSymphony
        className="buttonConnectStepDetail"
        variant="outlined"
        onClick={onButtonClick}
        minWidth={109}
        disabled={disabledButton}
      >
        Connect
      </ButtonSymphony>
    </div>
  );
};

export default StepDetail;