import { CampaignFilterType } from "./Components/CampaignTypesFilter/types";
import { AssetUploadTypes } from "./utils/fbCampaigns";

export const MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS = 10;

export const PAYMENT_METHODS: Record<number, string> = {
  0: "UNSET",
  1: "CREDIT_CARD",
  2: "FACEBOOK_WALLET",
  3: "FACEBOOK_PAID_CREDIT",
  4: "FACEBOOK_EXTENDED_CREDIT",
  5: "ORDER",
  6: "INVOICE",
  7: "FACEBOOK_TOKEN",
  8: "EXTERNAL_FUNDING",
  9: "FEE",
  10: "FX",
  11: "DISCOUNT",
  12: "PAYPAL_TOKEN",
  13: "PAYPAL_BILLING_AGREEMENT",
  14: "FS_NULL",
  15: "EXTERNAL_DEPOSIT",
  16: "TAX",
  17: "DIRECT_DEBIT",
  18: "DUMMY",
  19: "ALTPAY",
  20: "STORED_BALANCE",
};

export const PAYPAL_BILLING_AGREEMENT = 13;

export const CREDIT_CARD = 1;

export const FB_CAMPAIGNS_DEFAULT_AGES = {
  MIN: 24,
  MAX: 35,
}

export const FB_CAMPAIGNS_AGES = {
  MIN: 18,
  MAX: 65,
}

export const FB_CAMPAIGNS_AUTOMATED_AGES = {
  MIN: 18,
  MAX: 35,
}

export const FB_CAMPAIGNS_DEFAULT_LOCAES = [
  { key: 6, name: 'English (US)', selected: true },
  { key: 24, name: 'English (UK)', selected: true }
]

export const FB_CAMPAIGNS_GENDERS = {
  ALL: 'All',
  MALE: 'Male',
  FEMALE: 'Female'
}

export const FB_COMMON_LANGUAGES = [
  { key: 1001, name: "English (All)", selected: false },
  { key: 24, name: "English (UK)", selected: false },
  { key: 6, name: "English (US)", selected: false },
  { key: 1002, name: "Spanish (All)", selected: false },
]

export type AgeRestrictedCountry = {
  country_code: string,
  name: string,
  age: number
}

export const AGE_RESTRICTED_COUNTRIES: AgeRestrictedCountry[] = [
  { country_code: 'ID', name: 'Indonesia', age: 21 },
  { country_code: 'TH', name: 'Thailand', age: 20 },
]

export const MINIMUM_MEDIA_WIDTH = 500;

export const CUSTOM_AUDIENCES_TOS_URL = 'https://business.facebook.com/customaudiences/app/tos'
export const VALUE_BASED_CUSTOM_AUDIENCES_TOS_URL = 'https://business.facebook.com/customaudiences/value_based/tos';
export const USER_TOS_ACCEPTED_URL = 'https://business.facebook.com/ads/manage/customaudiences/tos';

export const CreativeAssetTypes: Record<string, AssetUploadTypes> = {
  UPLOAD_VIDEO: 'upload_video',
  INSTAGRAM_POSTS: 'instagram_posts',
}

export const DEFAULT_CAMPAIGN_PARAMS: CampaignFilterType = {
  campaignTypes: [],
  campaignStatuses: [],
  dateModifier: "all_time",
  startDate: "2024-05-19",
  endDate: "2024-05-26",
  filterDays: "30",
  budgetModifier: "no_budget_filter",
  budgetAmount1: "",
  budgetAmount2: "",
  limit: 15,
  offset: 0,
  campaignName: ""
};