import { Card, CardContainer, CardTitle, Icon, IconContainer, ValueText, useStyles } from './styles';
import { ReactComponent as Click } from 'assets/images/click.svg';
import { Caption1 } from 'components/shareable/Typography';
import useScreen from 'Hooks/useScreen';
import { Stat } from 'modules/Const';

interface StatsCardProps {
    stat: Stat;
    className?: string; // Optional prop for additional styling
}

const getFormattedMoney = (amount?: number, currency?: string): string =>
    `$${amount?.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })} ${currency?.toUpperCase() || 'USD'}`;

const StatsCard = ({ stat, className }: StatsCardProps) => {
    const { title, value, isCurrency, currency, icon, subtitle } = stat;
    const formattedDescription = isCurrency && typeof value === 'number'
        ? getFormattedMoney(value, currency)
        : value.toLocaleString();

    const classes = useStyles();
    const { mobileView } = useScreen();

    return (
        <CardContainer
            className={className}
            width={mobileView ? "calc(50% - 8px)" : "calc(25% - 8px)"}
        >
            <Card
                flexDirection={mobileView ? "row" : "column"}
                alignItems={mobileView ? "center" : "flex-start"}
            >
                <IconContainer>
                    {icon ? (<Icon src={icon} />) : <Click className={classes.icon} />}
                </IconContainer>
                <div>
                    <Caption1>
                        {title}
                    </Caption1>
                    {subtitle && <CardTitle>{subtitle}</CardTitle>}
                    <ValueText>

                        {formattedDescription}
                        {/* {getFormattedMoney(value)} */}

                    </ValueText>
                </div>
            </Card>
        </CardContainer>
    );
};

export default StatsCard;