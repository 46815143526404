import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useState } from "react";
import { DisplayedBrand } from "./OrganizationProfiles";
import DeleteBrandConfirmationModal from "./DeleteBrandConfirmationModal";

const BrandsActionMenu = ({
    row,
    canDelete,
    onDashboard,
    onUpgrade,
    onDowngrade,
    openDeleteModal,
    isAdminOrOwner
}: {
    row: DisplayedBrand;
    canDelete: boolean;
    onDashboard: (id: number) => void;
    onUpgrade: (slug: string) => void;
    onDowngrade: (slug: string) => void;
    openDeleteModal: (brand: DisplayedBrand) => void;
    isAdminOrOwner: boolean;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    onDashboard(row.id);
                    handleClose();
                }}>
                    Go to Dashboard
                </MenuItem>
                {row.isPro ? (
                    <MenuItem onClick={() => {
                        handleClose();
                        onDowngrade(row.slug);
                    }}>
                        Downgrade to Free
                    </MenuItem>
                ) : (
                    <MenuItem onClick={() => {
                        handleClose();
                        onUpgrade(row.slug);
                    }}>
                        Upgrade to Pro
                    </MenuItem>
                )}
                {isAdminOrOwner && canDelete && (
                    <MenuItem onClick={() => {
                        openDeleteModal(row);
                        handleClose();
                    }}>
                        Remove from Team
                    </MenuItem>
                )}
            </Menu>
           
        </div>
    );
};

export default BrandsActionMenu;