import { CSSProperties, useContext, useState } from "react";
import { Grid, Step, StepLabel, StepContent, Stepper } from "@material-ui/core";
import ConnectionsStepsDescription from "components/connect/ConnectionsStepsDescription";
import CampaignCreationFacebookConnect from "components/connect/Facebook";
import { FacebookAdAccountConnectModal } from "components/connect/FacebookAdAccountConnectModal";
import { InstagramModal } from "components/connect/InstagramModal";
import StepVideoContainer from "components/shareable/StepVideoContainer";
import { Subtitle1, Body2 } from "components/shareable/Typography";
import { CONVERSIONS_VIDEOS, VideoData } from "types/global";
import { EventSelector } from "../LinkClicks/EventSelector";
import ConversionsCallToAction from "./ConversionsCallToAction";
import ConversionsIndicator from "./ConversionsIndicator";
import StepsSocialMedia from "./StepsSocialMedia/StepsSocialMedia";
import useScreen from "Hooks/useScreen";
import { ConnectionsContext } from "../hooks/ConnectionsContext";
import useModal from "Hooks/useModal";
import useBanner from "Hooks/useBanner";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import Intercom from "helpers/Intercom";
import { useDetectAdBlock } from "adblock-detect-react";
import { TextButton } from "styles/shared";
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrowRight.svg';
import { makeStyles } from '@material-ui/core/styles';
import { MEDIA_BREAKPOINTS } from 'types/global';
import Colors from "modules/Colors";
import { CustomStepIcon } from "../LinkClicks/styles";
import AccountContainer from "components/AccountContainer";
import symphonySLogo from 'assets/images/symphony-s-logo.png';
import orangeIgIcon from 'assets/images/orange-ig-icon.png';
import ButtonSymphony from "components/shareable/ButtonSymphony";
import fbSmallIcon from 'assets/images/fb-small-icon.png';
import igSmallIcon from 'assets/images/ig-small-icon.png';
import SymphonyTabs from "components/shareable/SymphonyTabs";

const { stepVideos } = CONVERSIONS_VIDEOS

export const useStyles = makeStyles(() => ({
  stepper: {
    padding: '0 !important',
    '& .MuiStepContent-root': {
      marginTop: '0px !important',
      marginLeft: '7px !important',
      borderLeft: `1px solid ${Colors.divider} !important`,
    },
    '& .MuiStepConnector-root': {
      marginLeft: '7px !important',
      '& span': {
        borderLeft: `1px solid ${Colors.divider} !important`,
      },
    },
    '& .MuiStepLabel-iconContainer > div': {
      height: '16px !important',
      width: '16px !important',
      backgroundColor: Colors.divider,
    },
    '& .MuiStepConnector-vertical': {
      padding: '0 !important',
    },
  },
  sectionsContainer: {
    display: 'flex',
    gap: 24,
    justifyContent: 'center',
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
  mainSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    maxWidth: 672,
    gap: 16,
    [MEDIA_BREAKPOINTS.mobileView]: {
      width: '100%',
    },
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    borderRadius: 12,
    padding: 24,
    backgroundColor: Colors.PAPER_CONTAINER,
  },
  videoContainer: {
    height: '100%',
    width: '20%',
    maxWidth: 250,
    borderRadius: 12,
    [MEDIA_BREAKPOINTS.mobileView]: {
      maxWidth: '100%',
      width: '100%',
      padding: '0px 16px',
    },
  },
  conversionsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    borderRadius: 8,
    padding: 24,
    backgroundColor: Colors.PAPER_CONTAINER,
    [MEDIA_BREAKPOINTS.mobileView]: {
      borderRadius: 0,
    },
  },
  accordion: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  errorContainer: {
    display: 'flex',
    gap: 12,
    marginTop: 4,
    borderRadius: 8,
    padding: '16px 24px',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.PAPER_ERROR_CONTAINER,
  },
  tabs: {
    marginBottom: 24,
    borderBottom: `1px solid ${Colors.divider}`,
    '& .MuiTab-root': {
      textTransform: 'none',
      minWidth: 'auto',
      padding: '12px 24px',
      color: Colors.greyDark,
      '&.Mui-selected': {
        color: Colors.textColor,
      },
    },
  },
}));

export const AVAILABLE_TAB_OPTIONS = [
  {
    value: 'custom',
    label: "Best Setup"
  },
  {
    value: 'express',
    label: "Express Setup"
  },
]

interface ConnectionsSetupProps {
  showConversions?: boolean;
  showConversionsEventSelector?: boolean;
  selectedCampaignType?: string;
  // 'custom' allows users to connect their own pages
  // 'express' allows user to use Symphony's pages
  allowExpressSetup?: boolean;
  expressSetupOrCustomPages?: 'custom' | 'express';
  setExpressSetupOrCustomPages?: (value: 'custom' | 'express') => void;
}

const ConnectionsSetup = ({
  showConversions = false,
  showConversionsEventSelector = false,
  selectedCampaignType,
  allowExpressSetup = false,
  expressSetupOrCustomPages = 'custom',
  setExpressSetupOrCustomPages,
}: ConnectionsSetupProps) => {
  const { mobileView } = useScreen();
  const watchVideoModal = useModal()
  const classes = useStyles()
  const { currentBrand } = useContext(CurrentBrandContext)
  const adBlockDetected = useDetectAdBlock();
  const { conversionsStatus } = currentBrand || {}
  const { conversions_tasks: conversionsTasks, isError: showConversionsError, conversions_tasks_completed: isConversionsSetUp } = conversionsStatus || {};
  const conversionsCallToAction = useBanner({ initiallyVisible: !isConversionsSetUp });

  const {
    connectionsType: setupType,
    facebookAdAccount,
    conversionsEnabled,
    facebookPixel,
    conversionsEventOptions: conversionsEvents,
    conversionsEventName: selectedConversionsEvent,
    setConversionsEnabled,
    setConversionsEventName: selectConversionsEvent,
    eventsLoading,
  } = useContext(ConnectionsContext);

  const [showConnectModal, setConnectModal] = useState('');
  const [currentVideoGetStarted, setCurrentVideoGetStarted] = useState<VideoData>(stepVideos.GETTING_STARTED)

  const toggleConversionsIndicator = () => setConversionsEnabled(prev => !prev)

  const renderExpressSetupInfo = () => {
    return (
      <div className="expressSetupInfoContainer" style={sty.expressSetupInfoContainer}>
        <div style={{ color: Colors.greyDark }}>
          Your ad will use these pages:
        </div>
        <div className="expressSetupMessageContainer" style={sty.expressSetupMessageContainer}>
          <img style={{ width: 18, marginRight: -4 }} src={fbSmallIcon} alt="fbSmallIcon" />
          <img style={{ width: 18 }} src={igSmallIcon} alt="igSmallIcon" />
          <div style={{ color: Colors.greyDark }}>
            Express Setup Enabled
          </div>
          <ButtonSymphony
            className="expressSetupEditConnectionsButton"
            onClick={() => { }}
            variant="text"
            style={{ marginLeft: 'auto', marginRight: 8 }}
          >
            Edit connections
          </ButtonSymphony>
        </div>
      </div>
    )
  }

  const renderTitleAndDescription = () => {
    return (
      <div className="titleAndDescriptionConnectionsSetup flex flex-col pb-8 ">
        <Subtitle1>Connect your Advertising Accounts</Subtitle1>
        <Body2 className='body2ConnectionsSetup'>
          Symphony automatically runs ads on your behalf using your Facebook and Instagram accounts. Connect your accounts below to get started. You'll only have to do this once.
        </Body2>
        {mobileView &&
          <ButtonSymphony
            className="connectionsSetupLearnMoreButton"
            variant="text"
            style={{ marginTop: 16 }}
            onClick={() => { watchVideoModal.openModal() }}
          >
            Learn more <ArrowRightIcon />
          </ButtonSymphony>
        }
        {adBlockDetected &&
          <div className={`adBlockDetectedContainer ${classes.errorContainer}`}>
            <div className="errorContent" style={sty.errorContent}>
              <Subtitle1>
                Please disable your ad blocker to proceed.
              </Subtitle1>
              <Body2 color={Colors.textColor}>
                Symphony may not work correctly if you have an ad blocker enabled because we rely on advertising platforms like Facebook to automate marketing. Please disable your ad blocker to ensure your experience works properly.
              </Body2>
            </div>
          </div>
        }
      </div>
    )
  }

  /**
   * Renders the Facebook connection tabs UI component that allows users to choose between
   * Express Setup and Custom Setup options for connecting their Facebook advertising accounts.
   * 
   * @returns JSX.Element | null - Returns the tabs UI if express setup is allowed and required props exist,
   * otherwise returns null
   */
  const renderSetupTypeTabs = () => {

    return (
      <div className="facebookTabsContainer" style={sty.facebookTabsContainer}>
        {/* Tab header containing Express/Custom setup options */}
        {allowExpressSetup && setExpressSetupOrCustomPages && (
          <div className="facebookTabsHeader" style={sty.facebookTabsHeader}>
            <SymphonyTabs
              selectedValue={expressSetupOrCustomPages}
              onChange={(newValue: string) => {
                setExpressSetupOrCustomPages(newValue as 'custom' | 'express')
              }}
              data={AVAILABLE_TAB_OPTIONS}
            />
          </div>
        )
        }

        {/* Tab content area that renders either custom or express setup UI */}
        <div className="facebookTabsContent">
          {/* Render appropriate setup component based on selected tab */}
          {expressSetupOrCustomPages === 'custom' && renderBestSetup()}
          {expressSetupOrCustomPages === 'express' && renderExpressSetup()}
        </div>
      </div>
    );
  };

  const renderBestSetup = () => {
    return (
      <StepsSocialMedia
        selectedCampaignType={selectedCampaignType}
        handleShowFacebookModal={() => setConnectModal('facebook-instagram')}
        handleShowInstagramModal={() => setConnectModal('facebook-instagram')}
        handleShowAdAccountModal={() => setConnectModal('facebook-ad-account')}
      />
    )
  }

  const renderExpressSetup = () => {
    if (allowExpressSetup && (expressSetupOrCustomPages && setExpressSetupOrCustomPages)) {
      return (
        <div className="expressSetupContainer">


          <Stepper orientation="vertical" className={classes.stepper}>
            <Step active={true}>
              <StepLabel StepIconComponent={() => <CustomStepIcon />}>
                <Subtitle1>Express Setup</Subtitle1>
              </StepLabel>
              <StepContent>
                <div className="flex flex-col gap-2">
                  <Body2>
                    With Express Setup, your ads will run directly through Symphony’s Facebook Ad Account + associated FB + IG Page.
                  </Body2>
                  <TextButton
                    style={{ marginBottom: 16 }}
                    onClick={() => { }}
                  >
                    Learn more
                  </TextButton>
                  <AccountContainer
                    className="accountContainerExpressSetup"
                    title="Symphony Ad Account"
                    iconImg={symphonySLogo}
                    connectedChip
                    connectedText="Connected"
                  />
                  <div className="expressInfoContainer" style={sty.expressInfoContainer}>
                    <img style={{ width: 24 }} src={orangeIgIcon} alt="Express Setup" />
                    <div>
                      With Express Setup, your ads will run through Symphony's FB + IG Pages. You'll get great results, but won't grow in IG followers.
                    </div>
                  </div>
                  <AccountContainer
                    className="accountContainerExpressSetup"
                    title="Want to run ads with your own pages?"
                    subtitle="Login with Facebook to directly run ads with your own ad account, FB Page, and IG."
                    iconImg={null}
                    buttonText="Use my own Pages →"
                    onClick={() => setExpressSetupOrCustomPages('custom')}
                  />
                </div>
              </StepContent>
            </Step>
          </Stepper>
        </div>
      )
    }
  }

  const renderConversionsSetupUI = () => {
    // Early return if conversions should not be shown
    if (!showConversions) {
      return null;
    }

    // Determine if we should show success state for express setup
    const isExpressSetupSuccess = allowExpressSetup && expressSetupOrCustomPages === 'express';

    // Determine conversions enabled state
    const isConversionsEnabled = isExpressSetupSuccess ? true : conversionsEnabled;

    // Get appropriate description text
    const conversionsDescription = selectedConversionsEvent
      ? `Your ads will be geared to drive more "${selectedConversionsEvent}" events on your website.`
      : "Your ads will be geared towards driving conversions on your selected goal.";

    // Determine if we should show success state for express setup
    // - isExpressSetupSuccess: true if express setup is successful
    // - isConversionsSetUp: true if conversions are set up
    // - showConversionsError: true if there is an error with conversions
    const showConversionsEnabledUI = Boolean(isExpressSetupSuccess || isConversionsSetUp || showConversionsError)

    return (
      <div className={`conversionsSectionContainer ${classes.conversionsSection}`}>
        {showConversionsEnabledUI ? (
          <div>
            <ConversionsIndicator
              padding="16px 0px"
              showAsRecommended={false}
              description="Optimize our AI-targeting towards driving specific goals on your website."
              conversionsEnabledDescription={conversionsDescription}
              isConversionsEnabled={isConversionsEnabled}
              toggleConversionsIndicator={toggleConversionsIndicator}
              pixelId={facebookPixel?.id}
              hideConnectedPixelDetails={isExpressSetupSuccess}
              adAccountId={facebookAdAccount?.id}
              hasError={showConversionsError}
              intercomButtonText="Learn more"
              onIntercomClick={() => Intercom.OpenGrowConversionsArticle()}
            />

            {showConversionsEventSelector && (
              <EventSelector
                conversionsEvents={conversionsEvents}
                selectConversionsEvent={selectConversionsEvent}
                selectedConversionsEvent={selectedConversionsEvent}
                eventsLoading={eventsLoading}
              />
            )}
          </div>
        ) : (
          <ConversionsCallToAction
            conversionsTasks={conversionsTasks}
            videoUrl={CONVERSIONS_VIDEOS.onboardingVideo.videoUrl}
            hide={conversionsCallToAction.hide}
            enableCloseButton={false}
            showAsRecommended={false}
            padding="16px 0px"
          />
        )}
      </div>
    );
  }

  return (
    <div className={`connectionsSetupComponent ${classes.sectionsContainer}`}>
      {mobileView ?
        <Grid style={sty.hidden} className='connectionsSetupGrid'>
          <StepVideoContainer
            {...currentVideoGetStarted}
            openModal={watchVideoModal.open}
            onCloseModal={watchVideoModal.closeModal}
            onOpenModal={watchVideoModal.openModal}
            showDescription
            buttonPadding="12px 16px"
          />
        </Grid>
        :
        <ConnectionsStepsDescription showConversionsStep={showConversions} />
      }

      <div className={`mainSectionContainer ${classes.mainSectionContainer}`}>
        {/* {renderExpressSetupInfo()} */}
        <div className={`stepsContainer ${classes.stepsContainer}`}>
          {renderTitleAndDescription()}
          {renderSetupTypeTabs()}
          <FacebookAdAccountConnectModal
            open={showConnectModal === 'facebook-ad-account'}
            onClose={() => setConnectModal('')}
            setupType={setupType}
          />
          <CampaignCreationFacebookConnect
            open={showConnectModal === 'facebook-instagram' || showConnectModal === 'facebook-pixel'}
            withBorderRadius
            closeModal={() => setConnectModal('')}
          />
          <InstagramModal
            open={showConnectModal === 'instagram'}
            withBorderRadius
            closeModal={() => setConnectModal('')}
          />
        </div>
        {renderConversionsSetupUI()}
      </div>
      {!mobileView &&
        <Grid className={classes.videoContainer}>
          <StepVideoContainer
            {...currentVideoGetStarted}
            openModal={watchVideoModal.open}
            onCloseModal={watchVideoModal.closeModal}
            onOpenModal={watchVideoModal.openModal}
            showDescription
            buttonPadding="12px 16px"
          />
        </Grid>
      }
    </div>
  );
};

const sty: Record<string, CSSProperties> = {
  facebookTabsContainer: {
  },
  facebookTabsHeader: {
    marginBottom: 16,
    borderBottom: `1px solid ${Colors.divider}`,
  },
  hidden: {
    display: 'none'
  },
  errorContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  expressInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    marginBottom: 0,
    alignItems: 'center',
    borderRadius: 8,
    padding: '12px 16px',
    backgroundColor: Colors.ORANGE_BACKGROUND,
    color: Colors.greyDark,
  },
  expressSetupInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 24,
    borderRadius: 12,
    backgroundColor: 'white',
  },
  expressSetupMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: "#F5F3F6",
    padding: 8,
    borderRadius: 8,
    gap: 8,
    alignItems: 'center',
  }
}

export default ConnectionsSetup;