import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
} from "react"
import { ConversionsTask } from "types/global"
import ConversionsConfigStep from "../../../ConversionsConfigStep"
import StyledTextField from "../../Components/StyledTextField"
import { commonCardsProps, Steps } from "../../utils"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg"
import useStyles, {
  FlexContainer,
  InstructionsCard,
  LinkContainer,
  NumberedItem,
} from "./styles"
import { Body2, Subtitle2 } from "components/shareable/Typography"
import ErrorBanner from "../../Components/ErrorBanner"
import LinkButton from "components/shareable/LinkButton"
import useScreen from "Hooks/useScreen"
import { resetAccessToken } from "../../api"
import { Data } from "../../Hooks/useCommonStates"
import { useSharedStyles } from "../styles"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import Colors from "modules/Colors"

const primaryTextColor = Colors.textColor

const instructionsLabels: {
  label: ReactElement
  buttonLabel?: string
}[] = [
    {
      label: <Body2 color={primaryTextColor}>Click here to open your&nbsp;</Body2>,
      buttonLabel: "Facebook Pixel Settings",
    },
    {
      label: (
        <Body2 color={primaryTextColor}>
          Scroll down on the page that's opened up, until you see a section titled{' '}
          <Subtitle2>"Conversions API"</Subtitle2>
        </Body2>
      ),
    },
    {
      label: (
        <Body2 color={primaryTextColor}>
          Under that section, you will see blue text that says{" "}
          <Subtitle2>"Generate access token"</Subtitle2>. Click that and you'll
          see your token appear below it.
        </Body2>
      ),
    },
    {
      label: (
        <Body2 color={primaryTextColor}>
          Click on the token to copy it, come back here, and paste it into the input below. Hit "Save", and you're done!
        </Body2>
      ),
    },
  ]

interface Props {
  facebookApiToken: Data
  facebookPixel: Data
  selectedFBPixelId?: string
  conversionsEnabled: boolean
  onChangeAPIAccessToken?: (e: ChangeEvent<HTMLInputElement>) => void
  onClickConnectButton: () => void
  onClickWatchVideoButton: () => void
  onHover: () => void
  conversionsError: boolean
}

const StepFacebookApiToken = ({
  facebookApiToken,
  facebookPixel,
  selectedFBPixelId,
  conversionsEnabled,
  onChangeAPIAccessToken,
  onClickConnectButton,
  onClickWatchVideoButton,
  onHover,
  conversionsError,
}: Props) => {
  const { mobileView } = useScreen()
  const { errorDescription, errorTitle, buttonText } =
    commonCardsProps[Steps.FACEBOOK_API_TOKEN]
  const classes = useStyles()
  const sharedClasses = useSharedStyles()

  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
  const { conversionsStatus, connections } = currentBrand || {}
  const {
    facebook_pixel_conversions_api_access_token: brandAccessToken,
    facebook_pixel: brandFBPixel, facebook_ad_account: fbAdAccount
  } = connections || {}
  const { conversions_tasks: conversionTasks } = conversionsStatus || {}
  const isDisabled =
    !conversionsError && (
      !conversionTasks?.includes(ConversionsTask.FB_PIXEL_CONNECTED) ||
      !conversionTasks?.includes(ConversionsTask.AD_ACCOUNT_CONNECTED) ||
      !fbAdAccount ||
      !brandFBPixel ||
      facebookPixel.error ||
      conversionsEnabled
    )

  const handleClickGoToFBManagerPage = () => {
    window.open(
      `https://business.facebook.com/events_manager2/list/dataset/${selectedFBPixelId}/settings`,
      "_blank"
    )
  }

  useEffect(() => {
    if (facebookPixel.error) {
      resetAccessToken({
        currentBrand,
        reload: reloadBrand,
      })
    }
  }, [facebookPixel.error, reloadBrand, currentBrand])

  return (
    <ConversionsConfigStep
      {...commonCardsProps[Steps.FACEBOOK_API_TOKEN]}
      disabled={isDisabled}
      hideErrorBanner
      isSuccessful={!facebookApiToken.error}
      isCompleted={Boolean(brandAccessToken)}
      onHover={onHover}
    >
      <Fragment>
        <LinkButton
          leftIcon={
            <PlayIcon className={sharedClasses.playIcon} display="inline" />
          }
          text="Watch a walkthrough"
          onClick={onClickWatchVideoButton}
          textAlign="left"
        />
        {!Boolean(brandAccessToken) && (
          <InstructionsCard>
            {instructionsLabels.map(({ label, buttonLabel }, index) => {
              return (
                <FlexContainer key={`step-${index}`}>
                  <NumberedItem>
                    <Subtitle2 color={Colors.purple}>
                      {index + 1}
                    </Subtitle2>
                  </NumberedItem>
                  <LinkContainer>
                    {label}
                    {buttonLabel && (
                      <LinkButton
                        text={buttonLabel}
                        onClick={handleClickGoToFBManagerPage}
                        rightIcon={<ExternalLinkIcon className={classes.externalLinkIcon} />}
                        textAlign={mobileView ? "left" : "center"}
                      />
                    )}
                  </LinkContainer>
                </FlexContainer>
              )
            })}
          </InstructionsCard>
        )}
        <StyledTextField
          initialValue={brandAccessToken}
          showButtonLabelAsSaved
          error={facebookApiToken.error}
          connected={Boolean(brandAccessToken)}
          disabled={isDisabled}
          loading={facebookApiToken.loading}
          placeholder="Paste your token here..."
          value={facebookApiToken.stepValue || ""}
          onChange={onChangeAPIAccessToken}
        />
        {facebookApiToken.error && (
          <ErrorBanner {...{ errorTitle, errorDescription, buttonText }} />
        )}
        <ButtonSymphony
          className="saveFacebookApiTokenButton"
          onClick={onClickConnectButton}
          isLoading={facebookApiToken.loading}
          disabled={isDisabled}
        >
          Save
        </ButtonSymphony>
      </Fragment>
    </ConversionsConfigStep>
  )
}

export default StepFacebookApiToken
