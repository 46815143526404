import React, { useContext, useEffect, useState, CSSProperties, ChangeEvent } from "react";
import DaysSelector from "components/shareable/DaysSelector";
import MenuProOption from "components/shareable/MenuProOption";
import { WebsiteEditorCard } from "../styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { DATE_OPTIONS } from "pages/post-auth/DashboardModule/utils";
import StatsCardRow from "components/shareable/DATA_PRESENTATION/StatCards/row";
import { hasAnnotations } from "modules/Utils";
import SAreaChart, { AreaChartDataSet } from "components/shareable/DATA_PRESENTATION/Charts/SAreaChart";
import SBarChart from "components/shareable/DATA_PRESENTATION/Charts/SBarChart";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie";
import { Body2, Caption, Subtitle2 } from "components/shareable/Typography";
import { WebsiteContext } from "./CustomizeWebsite/hooks/WebsiteContext";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { StreamingLinkTypeOptions, getIconBackgroundStyle, socialMediaIconsInit } from "./CustomizeWebsite/utils";
import { useStyles } from "./CustomizeWebsite/styles";
import { renderIcon } from "./CustomizeWebsite/components/SocialMediaIconsInputs";
import { millify } from "millify";
import { TopGeographiesBarChart } from "./OverallWebsiteDashboard/components/CountriesBarChart";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import { useTabs } from "react-headless-tabs";
import Axios from "helpers/Interceptor";
import { BrandContent, Fan, TourDate, WEBSITE_TYPES, WebsiteType } from "types/global";
import Avatar from "@material-ui/core/Avatar";
import { AvatarColor } from "pages/post-auth/Fans/Components/FanbaseTable/styles";
import { Container, TextButton } from "styles/shared";
import { track } from "analytics";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { GeographicBreakdown, WebsiteDataType } from "pages/post-auth/Website/types";
import { Chip, Popover } from "@material-ui/core";
import CampaignFilterListItemPopover from "pages/post-auth/MarketingPage/Components/CampaignTypesFilter/popovers/list";
import DatePicker from "react-datepicker";
import "styles/react-datepicker.css";
import LoaderV2 from 'components/Loader/LoaderV2';
import useScreen from "Hooks/useScreen";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { CustomDateSelector } from "pages/post-auth/DashboardModule/components/LargeGraphPopup";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import * as Sentry from '@sentry/browser';
import BoostButton from 'components/shareable/BoostButton';
import ButtonSymphony from "components/shareable/ButtonSymphony";
import { PlanType, Stat } from "modules/Const";
import LegendCampaignAnnotations from "components/shareable/LegendCampaignAnnotations";
import PopoverCampaignAnnotations from "components/shareable/PopoverCampaignAnnotations";
import Colors from "modules/Colors";

dayjs.extend(utc);
dayjs.extend(timezone);

const { RECORD_STREAMS, DATA_COLLECTOR, TOUR, PRESAVE } = WEBSITE_TYPES

interface FieldResponse {
    id: string;
    label: string;
    value?: string;
    country?: string;
}

interface UserResponse {
    id: number,
    FanId: number,
    BrandContentId: number,
    fan_response_metadata: {
        fields: FieldResponse[],
        additionalFields?: {
            tourDate?: TourDate
        }
    },
    fan: Fan,
    createdAt: string
}

const formatDecimalAsPercentage = (decimal: number): string => {
    const percentage = decimal * 100;
    return `${percentage.toFixed(0)}%`;
}

const getDateOptions = ({
    isPaidBrand = false, // NOT from context
    showProIcon,
    currentTimeRange,
    allowedDateRanges,
    loadingMetrics
}: {
    isPaidBrand: boolean,
    showProIcon: boolean,
    currentTimeRange: string,
    allowedDateRanges?: DATE_OPTIONS[],
    loadingMetrics: boolean
}) => {
    const createOptionComponent = (label: string, value: string) => (
        <div className="flex items-center justify-between w-full">
            <p>{label}</p>
        </div>
    );

    const createProOptionComponent = (label: string, value: string) => (
        <div className="flex items-center justify-between w-full">
            <MenuProOption
                loading={loadingMetrics && currentTimeRange === value}
                mainContainerClassName="flex items-center gap-4 w-full"
                labelClassName={!isPaidBrand ? "w-5/12" : ""}
                label={label}
            />
        </div>
    );

    const allOptions = [
        { id: "7-days-option", value: "last-7-days", label: "Last 7 Days" },
        { id: "30-days-option", value: "last-30-days", label: "Last 30 Days" },
        { id: "3-months-option", value: "last-3-months", label: "Last 3 Months" },
        { id: "all-time-option", value: "all-time", label: "All Time" },
        { id: "custom-option", value: "custom", label: "Custom" },
    ].map(option => ({
        ...option,
        component: showProIcon && option.value !== "last-7-days"
            ? createProOptionComponent(option.label, option.value)
            : createOptionComponent(option.label, option.value)
    }));

    if (allowedDateRanges && allowedDateRanges.length > 0) {
        return allOptions.filter(option => allowedDateRanges.includes(option.value as DATE_OPTIONS));
    }

    return allOptions;
}

interface WebsiteDashboardProps {
    className?: string;
    style?: CSSProperties;
    id: number;
    slug: string;
    websiteType?: WebsiteType
    handleRefreshData: (date: DATE_OPTIONS, customStartDate?: string, customEndDate?: string) => void
    noWebsiteSpecificContent?: boolean
    metricsOverride?: WebsiteDataType
    title?: string
    subtitle?: string
    noTitles?: boolean
    defaultDateRange?: DATE_OPTIONS
    startDateMin?: Date
    endDateMax?: Date
    allowedDateRanges?: DATE_OPTIONS[]
    fetchOnMount?: boolean
    showProIcon?: boolean
    brandContent?: BrandContent
}

// NOTE: component
const WebsiteDashboardV2 = ({
    className = '',
    style,
    id,
    slug,
    websiteType,
    handleRefreshData,
    noWebsiteSpecificContent,
    metricsOverride,
    title: titleProp = 'High-Level Stats',
    subtitle: subtitleProp = 'See how your website is performing.',
    noTitles,
    defaultDateRange,
    startDateMin,
    endDateMax,
    allowedDateRanges,
    fetchOnMount = true,
    showProIcon = true,
    brandContent,
}: WebsiteDashboardProps) => {
    const classes = useStyles();
    const { largeView: isDesktop } = useScreen();

    const { currentBrand, isPaidBrand, isLiteBrand, isFreeBrand } = useContext(CurrentBrandContext)

    const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

    const { metrics: websiteContextMetrics } = useContext(WebsiteContext)
    const metrics = metricsOverride || websiteContextMetrics
    const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false)
    const [loadingResponses, setLoadingResponses] = useState<boolean>(false)

    const [showAnnotations, setShowAnnotations] = useState<boolean>(false)

    // Date Range Selector
    const [openDaysSelector, setOpenDaysSelector] = useState(false)
    const [currentTimeRange, setTimeRange] = useState<DATE_OPTIONS>(defaultDateRange ? defaultDateRange : isPaidBrand ? "last-30-days" : 'last-7-days')
    const [dataCollected, setDataCollected] = useState<UserResponse[]>()
    const [allDataCollected, setAllDataCollected] = useState<UserResponse[]>()
    const [downloadingCSV, setDownloadingCSV] = useState<boolean>(false)
    const [customStartDate, setCustomStartDate] = useState<Date | null>(() => {
        switch (currentTimeRange) {
            case 'last-7-days':
                return dayjs().subtract(7, 'days').toDate()
            case 'last-30-days':
                return dayjs().subtract(30, 'days').toDate()
            case 'last-3-months':
                return dayjs().subtract(3, 'months').toDate()
            case 'all-time':
                return startDateMin || null
            default:
                return null
        }
    })

    const [customEndDate, setCustomEndDate] = useState<Date | null>(endDateMax || dayjs().toDate())

    const handleOpenDaysSelector = () => setOpenDaysSelector(true);
    const handleCloseDaysSelector = () => setOpenDaysSelector(false);
    const getHighLevelStatsTabs = () => {
        switch (websiteType) {
            case DATA_COLLECTOR:
                return [
                    { label: 'Signups', value: 'collected_data' },
                    { label: 'Demographics', value: 'demographics', isProOnly: false },
                ];
            case RECORD_STREAMS:
                return [
                    { label: 'Actions', value: 'analytics' },
                    { label: 'Demographics', value: 'demographics', isProOnly: false },
                ];
            case TOUR:
                return [
                    { label: 'Actions', value: 'analytics' },
                    { label: 'Collected Data', value: 'collected_data' },
                    { label: 'Demographics', value: 'demographics', isProOnly: false },
                ];
            default:
                return [];
        }
    };

    const highLevelStatsTabs = getHighLevelStatsTabs();
    const highLevelStatsTabsContent = highLevelStatsTabs.map((item) => item.value);
    const [selectedTab, setSelectedTab] = useTabs(highLevelStatsTabsContent, highLevelStatsTabs[0]?.value || '');

    const [filterDateCity, setFilterDateCity] = useState<string>('');
    const [openFilterIndex, setOpenFilterIndex] = useState<number | null>(null);

    useEffect(() => {
        if (websiteType === DATA_COLLECTOR || websiteType === TOUR) {
            initDataCollected()
        }
    }, [])

    useEffect(() => {
        if (currentTimeRange === 'custom' && customStartDate && customEndDate) {
            refreshData();
        }
    }, [customStartDate, customEndDate]);

    useEffect(() => {
        if (fetchOnMount && !metrics) {
            refreshData();
        }
    }, []);

    const downloadCollectedDataCSV = async (brandId: string, contentId: number) => {
        try {
            Sentry.setTag('flow', 'getPixels');
            Sentry.setContext('input', { brandId, contentId });
            const response = await Axios.post(`/website/${contentId}/csv/collected-data`);
            return response.data.data;
        } catch (error) {
            Sentry.captureException(error);
            return null;
        }
    };

    const initDataCollected = async () => {
        setLoadingResponses(true)
        const responses = await getDataCollected();
        setDataCollected(responses)
        setAllDataCollected(responses)
        setLoadingResponses(false)
    }

    const getDataCollected = async () => {
        try {
            const response = await Axios.get(`/website/${slug}/data`)
            const data = await response.data.data
            return data
        } catch (error) {
            console.error(error)
            return undefined
        }
    }

    const handleDownloadResponses = async () => {
        if (!isPaidBrand) {
            track("Tried Downloading Data Collected CSV")
            handleOpenUpgradeToProModal({
                source: "Website Editor - Data Collector - Download Data Collected CSV",
                upgradeCallToAction: "Upgrade to Symphony Lite to download data as a CSV.",
                highlightedPlan: PlanType.LITE,
            });
            return;
        }

        setDownloadingCSV(true)
        const csvUrl = await downloadCollectedDataCSV(currentBrand?.slug!, id)
        if (csvUrl) window.open(csvUrl, '_blank')
        setDownloadingCSV(false)
        track("Download Data Collected CSV")
    }

    const handleChangeDaysSelector = async (
        event: ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const value = event.target.value as (DATE_OPTIONS);
        const freeValidValues = value === "last-7-days";
        const liteValidValues = value === "last-7-days" || value === "last-30-days";

        let showUpgradeToProModal = false;
        let highlightedPlan = PlanType.LITE;

        if (isLiteBrand && !liteValidValues) { // NOTE: if lite tier
            showUpgradeToProModal = true;
            highlightedPlan = PlanType.PRO;
        } else if (isFreeBrand && !freeValidValues) { // NOTE: if free tier
            showUpgradeToProModal = true;
            highlightedPlan = PlanType.LITE;
        }

        if (showUpgradeToProModal) {
            handleOpenUpgradeToProModal({
                source: "Website Editor - Dashboard Date Picker",
                upgradeCallToAction: `Upgrade to Symphony ${highlightedPlan} to unlock unlimited analytics!`,
                highlightedPlan,
            });
            return;
        } else {
            setTimeRange(value);
            if (value !== 'custom') {
                let startDate: Date | null = null;
                let endDate: Date | null = null;
                switch (value) {
                    case 'last-30-days':
                        endDate = new Date();
                        startDate = dayjs().subtract(30, 'days').startOf('day').toDate();
                        break;
                    case 'last-7-days':
                        endDate = new Date();
                        startDate = dayjs().subtract(7, 'days').startOf('day').toDate();
                        break;
                    case 'last-3-months':
                        endDate = new Date();
                        startDate = dayjs().subtract(3, 'months').startOf('day').toDate();
                        break;
                    case 'all-time':
                        startDate = startDateMin || null;
                        endDate = endDateMax || dayjs().toDate();
                        break;
                    default:
                        break;
                }
                setCustomStartDate(startDate);
                setCustomEndDate(endDate);
                refreshData(value, startDate, endDate);
            }
        }
    };

    const refreshData = async (timeRange = currentTimeRange, start = customStartDate, end = customEndDate) => {
        setLoadingMetrics(true);
        await handleRefreshData(
            timeRange,
            start ? dayjs(start).format('YYYY-MM-DD') : undefined,
            end ? dayjs(end).format('YYYY-MM-DD') : undefined
        );
        setLoadingMetrics(false);
    };

    const handleCustomDateChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setCustomStartDate(start);
        setCustomEndDate(end);
    };

    const renderCustomDatePicker = () => {
        if (currentTimeRange !== 'custom') return null;
        return (
            <div className="customDatePickerInWebsite flex items-center gap-4 w-full">
                <div className="flex items-center gap-2">
                    <p className="mr-1">From:</p>
                    <DatePicker
                        className="websiteCustomDatePickerFrom"
                        disabled={!isPaidBrand}
                        minDate={startDateMin}
                        maxDate={endDateMax}
                        filterDate={(date: Date) => {
                            return dayjs(date) < dayjs(customEndDate).subtract(1, 'day');
                        }}
                        selected={customStartDate}
                        onChange={(date) => {
                            handleCustomDateChange([date, customEndDate]);
                        }}
                        customInput={<CustomDateSelector />}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <p className="mr-1">To:</p>
                    <DatePicker
                        className="websiteCustomDatePickerTo"
                        disabled={!isPaidBrand}
                        minDate={dayjs(customStartDate).add(1, 'day').toDate()}
                        maxDate={endDateMax || dayjs().toDate()}
                        selected={customEndDate}
                        onChange={(date) => {
                            handleCustomDateChange([customStartDate, date]);
                        }}
                        customInput={<CustomDateSelector />}
                    />
                </div>
            </div>
        );
    };

    const handleSetFilterDateCity = (dateCityCombo: string) => {
        setFilterDateCity(dateCityCombo);
    };

    const applyFilters = () => {
        if (filterDateCity) {
            const [date, cityOrVenue] = filterDateCity.split(' - ');
            const filteredData = allDataCollected?.filter(d =>
                d.fan_response_metadata.additionalFields?.tourDate?.date === date &&
                (d.fan_response_metadata.additionalFields?.tourDate?.city === cityOrVenue ||
                    d.fan_response_metadata.additionalFields?.tourDate?.venue === cityOrVenue)
            );
            setDataCollected(filteredData);
        }
        setOpenFilterIndex(null);
    };

    const removeFilter = () => {
        setFilterDateCity('');
        setDataCollected(allDataCollected);
        setOpenFilterIndex(null);
    };

    const formatDateForDisplay = (date: string, cityOrVenue: string) => {
        const [month, day] = date.split('-');
        return `${month}/${day} - ${cityOrVenue}`;
    };

    const isPhoneNumberField = (field: FieldResponse) => {
        return field.id === 'phone_number' && typeof field.value === 'object'
    }

    const renderTourDataFilters = () => {
        if (websiteType !== TOUR || selectedTab !== 'collected_data' || !dataCollected?.length) return null;

        const filters = [
            { name: 'event', title: 'Event' }
        ];

        const getFilterResultString = (filter: { name: string; title: string }) => {
            if (filter.name === 'event') {
                if (filterDateCity) {
                    const [date, cityOrVenue] = filterDateCity.split(' - ');
                    return formatDateForDisplay(date, cityOrVenue);
                }
                return 'Event';
            }
            return filter.title;
        };

        const renderFilterContent = (filter: { name: string; title: string }) => {
            if (filter.name === 'event') {
                const eventOptions = Array.from(new Set(dataCollected?.map(d => {
                    const date = d.fan_response_metadata.additionalFields?.tourDate?.date;
                    const city = d.fan_response_metadata.additionalFields?.tourDate?.city;
                    const venue = d.fan_response_metadata.additionalFields?.tourDate?.venue;
                    return `${date} - ${city || venue}`;
                }).filter(Boolean) || []));

                return (
                    <CampaignFilterListItemPopover
                        title="Filter by Event"
                        listItems={eventOptions.map(option => {
                            const [date, cityOrVenue] = option.split(' - ');
                            const displayOption = formatDateForDisplay(date, cityOrVenue);
                            return {
                                key: option,
                                onClick: () => handleSetFilterDateCity(option),
                                checked: filterDateCity === option,
                                label: displayOption
                            };
                        })}
                    />
                );
            }
            return null;
        };

        const renderApplyButton = (loading?: boolean) => {
            return (
                <>
                    <ButtonSymphony
                        className="websiteDashboardV2ApplyFiltersButton"
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                        onClick={applyFilters}
                        disabled={loading}
                        isLoading={loading}
                    >
                        Apply
                    </ButtonSymphony>
                    <TextButton onClick={removeFilter}>
                        Clear
                    </TextButton>
                </>
            );
        }

        return (
            <Container
                display="flex"
                flexDirection={isDesktop ? "row" : "column"}
                justifyContent="space-between"
                alignItems={isDesktop ? "center" : "flex-start"}
                gap="16px"
                margin="0 0 16px 0"
            >
                <Container display="flex" flexDirection="row" gap="8px" alignItems="center">
                    <div>Filter by:</div>
                    <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                        {filters.map((filter, index) => (
                            <React.Fragment key={filter.name}>
                                <Chip
                                    id={`filter-${filter.name}`}
                                    style={{
                                        backgroundColor: filterDateCity ? "rgba(136, 0, 255, 0.08)" : "#EBEBEB",
                                        color: filterDateCity ? "#8800FF" : "#777",
                                    }}
                                    label={getFilterResultString(filter)}
                                    onClick={() => setOpenFilterIndex(openFilterIndex === index ? null : index)}
                                />
                                <Popover
                                    id={`filter-popover-${filter.name}`}
                                    open={openFilterIndex === index}
                                    anchorEl={document.getElementById(`filter-${filter.name}`)}
                                    onClose={() => setOpenFilterIndex(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Container display="flex" gap="8px" padding="16px" flexDirection="column">
                                        {renderFilterContent(filter)}
                                        {renderApplyButton()}
                                    </Container>
                                </Popover>
                            </React.Fragment>
                        ))}
                    </div>
                </Container>
                {Boolean(dataCollected?.length) && renderDownloadCsvButton()}
            </Container>
        );
    };
    // Renders collected data for Data Collector and Tour websites
    const renderDataCollected = () => {
        // Show loading state
        if (loadingResponses || !dataCollected || !metrics) {
            return (
                <div className='p-4 w-full flex gap-2 items-center justify-center bg-white rounded-xl'>
                    <Lottie
                        height={24}
                        width={24}
                        style={{ margin: 0 }}
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: SymphonyLoadingLottie,
                        }}
                    />
                </div>
            )
        }

        // Show message if no data is found
        const isTour = websiteType === TOUR;

        if (!dataCollected.length) {
            return (
                <div className="w-full flex justify-center py-2">
                    <Body2>You haven't collected any fan data yet! Share your website with your fans to start building your email list.</Body2>
                </div>
            )
        }

        let filteredData = dataCollected;

        if (isTour && filterDateCity) {
            const [date, cityOrVenue] = filterDateCity.split(' - ');
            filteredData = filteredData.filter(d =>
                d.fan_response_metadata.additionalFields?.tourDate?.date === date &&
                (d.fan_response_metadata.additionalFields?.tourDate?.city === cityOrVenue ||
                    d.fan_response_metadata.additionalFields?.tourDate?.venue === cityOrVenue)
            );
        }

        const renderTourTable = () => (
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Subtitle2 style={{ textWrap: 'nowrap' }}>Contact Info</Subtitle2></TableCell>
                            <TableCell><Subtitle2 style={{ textWrap: 'nowrap' }}>Name</Subtitle2></TableCell>
                            <TableCell><Subtitle2 style={{ textWrap: 'nowrap' }}>Pre-sale Signup</Subtitle2></TableCell>
                            <TableCell><Subtitle2 style={{ textWrap: 'nowrap' }}>Submission Time</Subtitle2></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((response) => {
                            const emailField = response.fan_response_metadata.fields.find(field => field.label === 'email_number');
                            return (
                                <TableRow key={response.id}>
                                    <TableCell>
                                        <div className="flex items-center">
                                            {response.fan.profile_url ? (
                                                <Avatar className={classes.avatar} src={response.fan.profile_url} />
                                            ) : (
                                                <AvatarColor backgroundColor={''} />
                                            )}
                                            <Body2 style={{ textWrap: 'nowrap', marginLeft: '8px' }}>{emailField?.value || response.fan.email}</Body2>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Body2 style={{ textWrap: 'nowrap' }}>
                                            {response.fan_response_metadata.fields.find(field => field.label === 'name')?.value || '-'}
                                        </Body2>
                                    </TableCell>
                                    <TableCell>
                                        <Body2 style={{ textWrap: 'nowrap' }}>
                                            {renderTourDateInfo(response.fan_response_metadata.additionalFields?.tourDate || null)}
                                        </Body2>
                                    </TableCell>
                                    <TableCell>
                                        <Body2 style={{ textWrap: 'nowrap' }}>
                                            {formatSubmissionTime(response.createdAt)}
                                        </Body2>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );

        const renderDataCollectorTable = () => (
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Subtitle2 style={{ textWrap: 'nowrap' }}>Contact Info</Subtitle2></TableCell>
                            {filteredData[0].fan_response_metadata.fields.map((field) => (
                                <TableCell key={field.id}>
                                    <Subtitle2 style={{ textWrap: 'nowrap' }}>{field.label}</Subtitle2>
                                </TableCell>
                            ))}
                            <TableCell><Subtitle2 style={{ textWrap: 'nowrap' }}>Submission Time</Subtitle2></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((response) => {
                            const contactInfo = response.fan_response_metadata.fields.find(field =>
                                field.id === 'email' || field.id === 'email_number' || field.id === 'number'
                            )?.value || response.fan.email || response.fan.cellphone || '-';
                            const phoneNumberInfo = response.fan_response_metadata.fields.find(field => isPhoneNumberField(field)) as any;
                            return (
                                <TableRow key={response.id}>
                                    <TableCell>
                                        <div className="flex items-center">
                                            {response.fan.profile_url ? (
                                                <Avatar className={classes.avatar} src={response.fan.profile_url} />
                                            ) : (
                                                <AvatarColor backgroundColor={''} />
                                            )}
                                            <Body2 style={{ textWrap: 'nowrap', marginLeft: '8px' }}>{contactInfo}</Body2>
                                        </div>
                                    </TableCell>
                                    {response.fan_response_metadata.fields.map((field) => (
                                        <TableCell key={field.id}>
                                            <Body2 style={{ textWrap: 'nowrap' }}>{(isPhoneNumberField(field) ? phoneNumberInfo.value.number : (field.value || '-'))}</Body2>
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        <Body2 style={{ textWrap: 'nowrap' }}>
                                            {formatSubmissionTime(response.createdAt)}
                                        </Body2>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );

        return isTour ? renderTourTable() : renderDataCollectorTable();
    }

    // Helper function to render tour date information
    const renderTourDateInfo = (tourDate: { city?: string; venue?: string; displayName?: string; date: string } | null): string => {
        if (!tourDate) return '-';
        const location = tourDate.city || tourDate.venue || tourDate.displayName || '-';
        const date = dayjs(tourDate.date).format('M/DD');
        return `${location} (${date})`;
    }

    // Helper function to format submission time
    const formatSubmissionTime = (createdAt: string | null): string => {
        return createdAt ? dayjs(createdAt).tz(dayjs.tz.guess()).format('MMM DD, YYYY h:mm A') : '-';
    }

    // Renders high level stats around a website. Supports tour and music streaming sites.
    const renderWebsiteActions = (): JSX.Element | null => {
        if (!metrics) return null;

        if (websiteType === TOUR) {
            const { tourLinkClicksBreakdown, presaleActionBreakdowns } = metrics;

            return (
                <>
                    {renderBarChart(
                        "Most Clicked Dates",
                        "These are the most clicked event dates.",
                        tourLinkClicksBreakdown || [],
                        "No clicks yet! Share your website and we'll track what people are clicking on."
                    )}
                    {renderBarChart(
                        "Pre-sale Actions Taken",
                        "These are the total successful pre-sale actions taken on your site.",
                        presaleActionBreakdowns || [],
                        "No pre-sale actions yet! Enable a pre-save on your site to collect emails + build fan data."
                    )}
                </>
            );
        } else if (websiteType === RECORD_STREAMS) {
            const { streamingServicesBreakdown, socialMediaBreakdown } = metrics;

            return (
                <>
                    {renderIconBarChart(
                        "Streaming Services",
                        "These are the most clicked streaming services on your site.",
                        streamingServicesBreakdown || [],
                        StreamingLinkTypeOptions,
                        "No streaming service clicks yet! Share your website and we'll track what people are clicking on."
                    )}
                    {renderIconBarChart(
                        "Social Media Actions",
                        "These are the most clicked social media links on your site.",
                        socialMediaBreakdown || [],
                        socialMediaIconsInit,
                        "No social media clicks yet! Share your website and we'll track what people are interacting with."
                    )}
                </>
            );
        }

        return null;
    }

    const renderBarChart = (title: string, subtitle: string, data: any[], emptyMessage: string): JSX.Element => {
        if (!data?.length) {
            return (
                <WebsiteEditorCard
                    className="websiteBarChart1"
                    title={title}
                    subtitle={subtitle}
                >
                    <div className="w-full flex justify-center py-2">
                        <Body2>{emptyMessage}</Body2>
                    </div>
                </WebsiteEditorCard>
            );
        }

        const sortedData = data
            .sort((a, b) => b.count - a.count)
            .map((item) => ({
                ...item,
                uniqueId: `${item.date}-${item.venue || item.city || 'Unknown'}-${item.count}`
            }));

        const getTickContent = (payload: { value: string }): JSX.Element => {
            const uniqueId = payload.value;
            const itemData = sortedData.find((item) => item.uniqueId === uniqueId);
            if (!itemData) return <></>;

            return (
                <div key={itemData.uniqueId} className='getTickContentContainer flex justify-between items-center gap-2 w-full' style={{ marginTop: 7 }}>
                    <div className='flex flex-col' style={{ width: '70%', overflow: 'hidden' }}>
                        <Body2
                            color={Colors.textColor}
                            style={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {`${itemData.city || 'Unknown City'} - ${dayjs(itemData.date).format('MMM DD')}`}
                        </Body2>
                        <Caption
                            color={Colors.greyDark}
                            style={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {itemData.venue || 'Venue TBA'}
                        </Caption>
                    </div>
                    <Body2
                        color={Colors.textColor}
                        style={{ width: '30%', textAlign: 'right' }}
                    >
                        {millify(itemData.count)}
                    </Body2>
                </div>
            );
        };

        return (
            <WebsiteEditorCard
                className="websiteBarChart2"
                title={title}
                subtitle={subtitle}
            >
                <SBarChart
                    direction="horizontal"
                    yAxisKey="uniqueId"
                    xAxisKey="count"
                    tickWidth={250}
                    tickHeight={50}
                    barHeight={24}
                    barWidth={150}
                    tick={getTickContent}
                    data={sortedData}
                />
            </WebsiteEditorCard>
        );
    }

    const renderIconBarChart = (title: string, subtitle: string, data: any[], iconOptions: any, emptyMessage: string): JSX.Element => {
        if (!data?.length) {
            return (
                <WebsiteEditorCard
                    className="websiteIconBarChart1"
                    title={title}
                    subtitle={subtitle}
                >
                    <div className="w-full flex justify-center py-2">
                        <Body2>{emptyMessage}</Body2>
                    </div>
                </WebsiteEditorCard>
            );
        }

        const sortedData = data
            .sort((a, b) => b.count - a.count)
            .map((item) => ({
                ...item,
                uniqueId: `${item.service}-${Date.now()}-${Math.random()}`
            }));

        const getTickContent = (payload: { value: string }): JSX.Element => {
            const uniqueId = payload.value;
            const itemData = sortedData.find((item) => item.uniqueId === uniqueId);
            if (!itemData) return <></>;

            const serviceObject = iconOptions[itemData.service] || iconOptions.find((item: any) => item.title.includes(itemData.service));

            return (
                <div key={itemData.uniqueId} className='getTickContentContainer flex justify-between items-center gap-2 w-full' style={{ marginTop: 7 }}>
                    <div className='flex items-center gap-2' style={{ width: '70%', overflow: 'hidden' }}>
                        <div className={serviceObject ? classes.socialIconContainer : classes.socialIconEmptyContainer} style={getIconBackgroundStyle(serviceObject)}>
                            {renderIcon(true, serviceObject)}
                        </div>
                        <Body2
                            color={Colors.textColor}
                            style={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {serviceObject?.title || itemData.service}
                        </Body2>
                    </div>
                    <Body2
                        color={Colors.textColor}
                        style={{ width: '30%', textAlign: 'right' }}
                    >
                        {millify(itemData.count)}
                    </Body2>
                </div>
            );
        };

        return (
            <WebsiteEditorCard
                className="websiteIconBarChart2"
                title={title}
                subtitle={subtitle}
            >
                <SBarChart
                    direction="horizontal"
                    yAxisKey="uniqueId"
                    xAxisKey="count"
                    tickWidth={250}
                    tickHeight={50}
                    barHeight={24}
                    barWidth={150}
                    tick={getTickContent}
                    data={sortedData}
                />
            </WebsiteEditorCard>
        );
    }

    if (loadingMetrics && !metrics) {
        return (
            <div className='loadingMetricsContainer p-4 w-full flex gap-2 items-center justify-center'>
                <div>
                    <LoaderV2 />
                </div>
                <Body2>Loading data...</Body2>
            </div>
        )
    }

    const renderDownloadCsvButton = () => (
        <div className="downloadCsvButtonContainer">
            <TextButton
                onClick={handleDownloadResponses}
                disabled={downloadingCSV}
            >
                <div className="flex items-center justify-center flex-row">
                    {!isPaidBrand &&
                        <div className="mr-1.5">
                            <UpgradeToProChip
                                className="websiteDashboardV2DownloadCsvProChip"
                                type="gem"
                                color="purple"
                                height={18}
                                width={18}
                            />
                        </div>
                    }
                    <p style={{ fontWeight: 400 }}>
                        {downloadingCSV ? 'Downloading...' : 'Download CSV'}
                    </p>
                </div>
            </TextButton>
        </div>
    );

    const renderWebsiteSpecificContent = () => {
        // for Data Collector, we should still show signups
        const fullyBlockedTabsForFreeUsers = ['analytics', 'demographics']

        if (selectedTab && isFreeBrand && fullyBlockedTabsForFreeUsers.includes(selectedTab)) {
            return (
                <UnlockDataBanner
                    unlockedWithLite
                    source="Website Editor - Website Analytics"
                    title="Unlock unlimited analytics with Symphony Lite"
                    description="Get full website customization, extended analytics, and detailed demographic breakdowns."
                />
            )
        }

        switch (websiteType) {
            case RECORD_STREAMS:
                return (
                    <>
                        {selectedTab === 'analytics' && renderWebsiteActions()}
                        {selectedTab === 'demographics' && renderDemographics()}
                    </>
                );
            case DATA_COLLECTOR:
                return (
                    <>
                        {selectedTab === 'collected_data' &&
                            <WebsiteEditorCard
                                className="dataCollectorCollectDataCard"
                                title="Collected Data"
                                subtitle="View and download the data collected from your website"
                            >
                                {Boolean(dataCollected?.length) && renderDownloadCsvButton()}
                                {renderDataCollected()}
                            </WebsiteEditorCard>
                        }
                        {selectedTab === 'demographics' && renderDemographics()}
                    </>
                );
            case TOUR:
                return (
                    <>
                        {selectedTab === 'analytics' && renderWebsiteActions()}
                        {selectedTab === 'demographics' && renderDemographics()}
                        {selectedTab === 'collected_data' &&
                            <WebsiteEditorCard
                                className="tourCollectDataCard"
                                title="Collected Data"
                                subtitle="View and download the data collected from your pre-sales."
                            >
                                {renderTourDataFilters()}
                                {renderDataCollected()}
                            </WebsiteEditorCard>
                        }
                    </>
                );
            default:
                return null;
        }
    };

    const renderDemographicsChart = (title: string, subtitle: string, data: GeographicBreakdown, areaType: 'countries' | 'cities') => {
        const locations = data[areaType];
        if (!locations || locations.length === 0) {
            return (
                <WebsiteEditorCard
                    className="websiteDemographicsChartCard1"
                    title={title}
                    subtitle={subtitle}
                >
                    <div className="w-full flex justify-center py-2">
                        <Body2>No demographic data available yet.</Body2>
                    </div>
                </WebsiteEditorCard>
            );
        }

        const filteredLocations = areaType === 'countries'
            ? locations.filter(location => !location.city)
            : locations.filter(location => location.city);

        if (filteredLocations.length === 0) {
            return (
                <WebsiteEditorCard
                    className="websiteDemographicsChartCard2"
                    title={title}
                    subtitle={subtitle}
                >
                    <div className="w-full flex justify-center py-2">
                        <Body2>No {areaType} data available.</Body2>
                    </div>
                </WebsiteEditorCard>
            );
        }

        return (
            <WebsiteEditorCard
                className="websiteDemographicsChartCard3"
                title={title}
                subtitle={subtitle}
            >
                <TopGeographiesBarChart metrics={{ [areaType]: filteredLocations }} areaType={areaType} />
            </WebsiteEditorCard>
        );
    };
    const renderDemographics = () => {
        if (!metrics?.geographicBreakdown) return null;

        return (
            <>
                {renderDemographicsChart(
                    "Top Countries by Page Views",
                    "These are the countries with the most page views on your site.",
                    metrics.geographicBreakdown,
                    'countries'
                )}
                <hr className="my-4 border-t border-gray-200" />
                {renderDemographicsChart(
                    "Top Cities by Page Views",
                    "These are the cities with the most page views on your site.",
                    metrics.geographicBreakdown,
                    'cities'
                )}
            </>
        );
    };

    const renderOverviewCard = () => {
        if (!metrics) {
            return null
        }

        const { pageVisits, streamingLinkClicks, ctr, signups, tourLinkClicks, newPresaves, newForeverSaves } = metrics.topLevelMetrics || {};

        const getStatsForWebsiteType = (): Stat[] => {
            const baseStats: Stat[] = [
                {
                    title: "Page Visits",
                    value: pageVisits || 0,
                },
            ];

            switch (websiteType) {
                case RECORD_STREAMS:
                    return [
                        ...baseStats,
                        {
                            title: "Clicks",
                            value: streamingLinkClicks || 0,
                        },
                        {
                            title: "Clickthrough Rate",
                            value: formatDecimalAsPercentage(ctr || 0),
                        },
                    ];
                case DATA_COLLECTOR:
                    return [
                        ...baseStats,
                        {
                            title: "Total Signups",
                            value: signups || 0,
                        },
                        {
                            title: "Submission Rate",
                            value: formatDecimalAsPercentage(ctr || 0),
                        },
                    ];
                case TOUR:
                    const tourStats: Stat[] = [
                        ...baseStats,
                        {
                            title: "Event Link Clicks",
                            value: tourLinkClicks || 0,
                        },
                        {
                            title: "Clickthrough Rate",
                            value: formatDecimalAsPercentage(ctr || 0),
                        },
                    ];
                    if (typeof signups !== 'undefined' && signups !== null) {
                        tourStats.push({
                            title: "Emails Collected",
                            value: signups,
                        });
                    }
                    return tourStats;
                case PRESAVE:
                    const uniquePageVisitors = pageVisits || 0;
                    const totalNewPresaves = (newPresaves || 0) + (newForeverSaves || 0);
                    const timeRangeText = {
                        'last-7-days': 'Last 7 Days',
                        'last-30-days': 'Last 30 Days',
                        'last-3-months': 'Last 3 Months',
                        'all-time': 'All Time',
                        'custom': 'Custom Range'
                    }[currentTimeRange] || currentTimeRange;

                    return [
                        {
                            title: "Unique Page Visitors",
                            value: uniquePageVisitors,
                            subtitle: timeRangeText,
                        },
                        {
                            title: "Total New Pre-Saves",
                            value: totalNewPresaves || 0,
                            subtitle: timeRangeText,
                        },
                        {
                            title: "New Forever Save Subscribers",
                            value: newForeverSaves || 0,
                            subtitle: timeRangeText,
                        },

                        // {
                        //     title: "Total Pre-Saves on this Release",
                        //     value: totalPreSavesOnRelease,
                        //     subtitle: 'All Time',
                        // },
                        // {
                        //     title: "Total Forever Saves on All Releases",
                        //     value: totalForeverSavesAllReleases,
                        //     subtitle: 'All Time',
                        // },
                        // {
                        //     title: "Visits to Pre-Saves",
                        //     value: formatDecimalAsPercentage(preSaveActionRate),
                        //     subtitle: 'Conversion Rate'
                        // },
                        // {
                        //     title: "Visits to Pre-Saves",
                        //     value: formatDecimalAsPercentage(preSaveActionRate),
                        //     subtitle: 'Conversion Rate'
                        // },
                    ];
                default:
                    return baseStats;
            }
        };

        const getDataSetsForLineChart = (): AreaChartDataSet[] => {
            switch (websiteType) {
                case PRESAVE:
                    return [
                        { key: "pageViews", title: "Unique Visitors", color: "#00A3FF" },
                        // { key: "New Pre-sa", title: "Total Pre-saves (This Release)", color: "#8800FF", summationSection: true, hideFromGraph: true },
                        { key: "newPresaves", title: "Fans who just Pre-saved", color: "#4CAF50" },
                        { key: "newForeverSaves", title: "Fans who Pre-saved & Forever Saved", color: "#FFC107" },
                        { key: "totalPresaves", title: "Total Pre-saves (This Release)", color: "#8800FF", summationSection: true, hideFromGraph: true },
                        { key: "totalForeverSaves", title: "Total Forever Saves (All Releases)", color: "#FF7700", summationSection: true, hideFromGraph: true },
                    ];
                case TOUR:
                    return [
                        { key: "pageViews", color: "#8800FF" },
                        { key: "tourLinkClicks", color: "#00A3FF" }
                    ];
                case DATA_COLLECTOR:
                    return [
                        { key: "pageViews", color: "#8800FF" },
                        { key: "signups", color: "#00A3FF" }
                    ];
                default:
                    return [
                        { key: "pageViews", color: "#8800FF" },
                        { key: "streamingLinkClicks", color: "#00A3FF" }
                    ];
            }
        }

        const getDataForLineChart = () => {
            if (!metrics?.dailyStats && !metrics?.overallActivity) return [];

            switch (websiteType) {
                case PRESAVE:
                    return metrics.dailyStats?.map((item: { date: string; newPresaves: number; newForeverSaves: number; totalForeverSaves: number; totalPresaves: number; pageVisits: number }) => ({
                        date: item.date,
                        pageViews: item.pageVisits,
                        totalPresaves: item.totalPresaves,
                        totalForeverSaves: item.totalForeverSaves,
                        newPresaves: item.newPresaves,
                        newForeverSaves: item.newForeverSaves
                    })) || [];
                default:
                    return (
                        metrics.overallActivity?.map((item: any) => {
                            const result: { [key: string]: string | number } = {
                                date: item.date,
                                pageViews: item.pageViews
                            };
                            if (websiteType === TOUR) {
                                result.tourLinkClicks = item.tourLinkClicks || 0;
                            } else if (websiteType === DATA_COLLECTOR) {
                                result.signups = item.signups || 0;
                            } else {
                                result.streamingLinkClicks = item.streamingLinkClicks || 0;
                            }
                            return result;
                        }) || []
                    )
            }
        }

        return (
            <WebsiteEditorCard
                className="websiteDashboardV2Card"
                title={noTitles ? '' : titleProp}
                subtitle={noTitles ? '' : subtitleProp}
                style={{ padding: style?.padding || 24 }}
                rightContent={<BoostButton brandContent={brandContent} websiteType={websiteType} className='WebsiteDashboardV2Button'
                    location={"Website Analytics"} />}
            >
                <div className="websiteAnalyticsOverviewCardInner" style={sty.websiteAnalyticsOverviewCardInner}>
                    <PopoverCampaignAnnotations
                        className="selectWebsiteDashboardAnnotations"
                        showAnnotations={showAnnotations}
                        setShowAnnotations={setShowAnnotations}
                        upgradeSource="Website Dashboard - Annotations"
                    />
                    <DaysSelector
                        className="websiteDashboardV2DaysSelector"
                        options={getDateOptions({ isPaidBrand, showProIcon, currentTimeRange, allowedDateRanges, loadingMetrics })}
                        selectProps={{
                            open: openDaysSelector,
                            className: "h-11",
                            variant: "outlined",
                            value: currentTimeRange,
                            IconComponent: () => (
                                <div
                                    className="cursor-pointer mr-2 lg:w-1/2"
                                    style={{ width: "fit-content" }}
                                    onClick={handleOpenDaysSelector}
                                >
                                    <KeyboardArrowDown viewBox="0 -2 26 26" />
                                </div>
                            ),
                            onOpen: handleOpenDaysSelector,
                            onClose: handleCloseDaysSelector,
                            onChange: handleChangeDaysSelector,
                        }}
                    />
                </div>
                {renderCustomDatePicker()}
                <StatsCardRow stats={getStatsForWebsiteType()} />
                {hasAnnotations(showAnnotations) && <LegendCampaignAnnotations />}
                <SAreaChart
                    className="websiteAnalyticsAreaChart"
                    xAxisKey="date"
                    dataSets={getDataSetsForLineChart()}
                    data={getDataForLineChart()}
                    showLegend
                    startDate={customStartDate ? dayjs(customStartDate).format('YYYY-MM-DD') : undefined}
                    endDate={customEndDate ? dayjs(customEndDate).format('YYYY-MM-DD') : undefined}
                    showAnnotations={showAnnotations}
                />
                <hr className="my-4 border-t border-gray-200" style={{ marginTop: 32 }} />
                <Container borderRadius="12px" backgroundColor={Colors.PAPER_CONTAINER} display="flex" alignItems="center" justifyContent="space-between">
                    <SymphonyTabs
                        className='symphonyTabsWebsiteDashboardV2'
                        data={[
                            ...highLevelStatsTabs
                        ]}
                        selectedValue={selectedTab!}
                        onChange={setSelectedTab}
                        gap={16}
                        tabContainerStyle={{ paddingRight: 0, paddingLeft: 0 }}
                        tabContentStyle={{ paddingRight: 0, paddingLeft: 0 }}
                    />
                </Container>
                {noWebsiteSpecificContent ? null : renderWebsiteSpecificContent()}
            </WebsiteEditorCard>
        );
    }

    return (
        <div className={`websiteDashboardv2Component ${className} w-full flex flex-col gap-4`}>
            {renderOverviewCard()}
        </div>
    )
}

const sty = {
    websiteAnalyticsOverviewCardInner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        marginTop: 8
    } as CSSProperties
}

export default WebsiteDashboardV2
