import { useContext, useEffect, useState } from 'react';
import { CardContainer, CarouselContainer, GettingStartedHeader, GettingStartedTitle, ProgressBarText } from "./styles";
import ProgressBar from '../shareable/ProgressBar/index';
import TaskCard from "../TaskCard/TaskCard";
import CompleteFanbaseIcon from "assets/images/gettingStarted/completeYourFanbase.png"
import CustomizeWebsiteIcon from "assets/images/gettingStarted/customizeYourWebsite.png"
import InviteYourTeamIcon from "assets/images/gettingStarted/inviteYourTeam.png"
import StartCampaignIcon from "assets/images/gettingStarted/startCampaign.png"
// import GrowYoutubeIcon from "assets/images/gettingStarted/growYoutubeEngagements.png"
import GrowYoutubeIcon from "assets/images/campaigns/youtube.png"

import InstagramEngagement from "assets/images/gettingStarted/InstagramEngagement.svg"
import { CurrentBrand, SYMPHONY_TASKS } from "types/global";
import { GettingStartedContext } from '../../Hooks/GettingStartedContext/index';
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import './swiper.css';
import { useHistory } from "react-router-dom";
import { track } from "analytics";

interface GettingStartedItem {
  icon?: string;
  title: string;
  description: string;
  action: () => void;
  doneKey: SYMPHONY_TASKS;
  key: SYMPHONY_TASKS;
}

export const GettingStartedNames = {
    [SYMPHONY_TASKS.FANBASE_COMPLETED]: "Complete your fanbase",
    [SYMPHONY_TASKS.CAMPAIGN_STARTED]: "Build a Touring Fanbase",
    [SYMPHONY_TASKS.TEAM_INVITED]: "Invite your team",
    [SYMPHONY_TASKS.WEBSITE_CUSTOMIZED]: "Customize your website",
    [SYMPHONY_TASKS.GROW_YOUTUBE_ENGAGEMENT]: "Grow your Youtube engagements",
    [SYMPHONY_TASKS.INSTAGRAM_ENGAGEMENT]: "Superboost Instagram Engagement",
};

const getGettingStartedActions = (redirect: (url: string, taskName: string) => void, spotifyConnected: boolean):  GettingStartedItem[] => {
  if(!spotifyConnected){
    return [
      {
        icon: CompleteFanbaseIcon,
        title: "Complete your fanbase.",
        description: "Link all of your platforms to track your fanbase and build marketing audiences more effectively.",
        action: () => alert("Add your first song"),
        doneKey: SYMPHONY_TASKS.FANBASE_COMPLETED,
        key: SYMPHONY_TASKS.FANBASE_COMPLETED,
      },
      {
        icon: InviteYourTeamIcon,
        title: "Invite your team",
        description: "Add your manager, marketer, and anyone else to access your Symphony profile.",
        action: () => {
          redirect("/settings?tab=USERS", "Invite your team");
        },
        doneKey: SYMPHONY_TASKS.TEAM_INVITED,
        key: SYMPHONY_TASKS.TEAM_INVITED,
      },
      {
        icon: InstagramEngagement,
        title: "Superboost Instagram Engagement",
        description: "Superboost your Instagram reach with our automated IG Ads.",
        action: () => {
          redirect("/marketing/new/grow-instagram-engagement", "Superboost Instagram Engagement");
        },
        doneKey: SYMPHONY_TASKS.INSTAGRAM_ENGAGEMENT,
        key: SYMPHONY_TASKS.INSTAGRAM_ENGAGEMENT,
      },
      {
        icon: GrowYoutubeIcon,
        title: "Grow YouTube Engagement",
        description: "Grow your YouTube views with Symphony’s automated YouTube Ads campaigns.",
        action: () => {
          redirect("/marketing/new/video-views", "Grow YouTube Engagement");
        },
        doneKey: SYMPHONY_TASKS.CAMPAIGN_STARTED,
        key: SYMPHONY_TASKS.GROW_YOUTUBE_ENGAGEMENT,
      },
    ]
  }

  return [
    {
      icon: CompleteFanbaseIcon,
      title: "Complete your fanbase.",
      description: "Link all of your platforms to track your fanbase and build marketing audiences more effectively.",
      action: () => alert("Add your first song"),
      doneKey: SYMPHONY_TASKS.FANBASE_COMPLETED,
      key: SYMPHONY_TASKS.FANBASE_COMPLETED,
    },
    {
      icon: StartCampaignIcon,
      title: "Build a Touring Fanbase",
      description: "Run highly-targeted Instagram Ads to grow your Spotify engagement using Symphony’s AI targeted ads.",
      action: () => {
        redirect("/marketing/new/instagram-ads", "Build a Touring Fanbase");
      },
      doneKey: SYMPHONY_TASKS.CAMPAIGN_STARTED,
      key: SYMPHONY_TASKS.CAMPAIGN_STARTED,
    },
    {
      icon: CustomizeWebsiteIcon,
      title: "Customize your website.",
      description: "Give your fans one place to find all your music with your fully customizable, Symphony-powered website.",
      action: () => {
        redirect("/website", "Customize your website");
      },
      doneKey: SYMPHONY_TASKS.WEBSITE_CUSTOMIZED,
      key: SYMPHONY_TASKS.WEBSITE_CUSTOMIZED,
    },
    {
      icon: GrowYoutubeIcon,
      title: "Grow YouTube Engagement",
      description: "Grow your YouTube views with Symphony’s automated YouTube Ads campaigns.",
      action: () => {
        redirect("/marketing/new/video-views", "Grow YouTube Engagement");
      },
      doneKey: SYMPHONY_TASKS.CAMPAIGN_STARTED,
      key: SYMPHONY_TASKS.GROW_YOUTUBE_ENGAGEMENT,
    },
    {
      icon: InviteYourTeamIcon,
      title: "Invite your team",
      description: "Add your manager, marketer, and anyone else to access your Symphony profile.",
      action: () => {
        redirect("/settings?tab=USERS", "Invite your team");
      },
      doneKey: SYMPHONY_TASKS.TEAM_INVITED,
      key: SYMPHONY_TASKS.TEAM_INVITED,
    },
  ];
}

interface Props {
  platformConnectorOpener: () => void;
  currentBrand?: CurrentBrand;
}
export default function GettingStarted({ platformConnectorOpener, currentBrand }: Props) {
  const {
    finishedTasks: completedGettingStartedTasks,
  } = useContext(GettingStartedContext);

  const [totalTasks, setTotalTasks] = useState<number>(0);
  const [doneTasks, setDoneTasks] = useState<GettingStartedItem[]>([]);
  const [undoneTasks, setUndoneTasks] = useState<GettingStartedItem[]>([]);

  const { isMobile, innerWidth } = useScreenQueryDimensions();

  const history = useHistory();
  const spotifyConnected = Boolean(currentBrand?.connections?.spotify_artist_page);

  const redirect = (url: string, taskName: string) => {
    track(`Start "${taskName}" Onboarding Step`, {
      // TODO: Remove this and use totalTaks - doneTasks when the youtube task has been isolated
      remainingSteps:  Object.keys(SYMPHONY_TASKS).length - completedGettingStartedTasks.length - 1,
    })
    history.push(url);
  }

  const platformConnectorOpenerHandler = () => {
    track(`Start "Complete your fanbase" Onboarding Step`, {
      // TODO: Remove this and use totalTaks - doneTasks when the youtube task has been isolated
      remainingSteps:  Object.keys(SYMPHONY_TASKS).length - completedGettingStartedTasks.length - 1,
    })
    platformConnectorOpener();
  }

  useEffect(() => {
    const tasks = getGettingStartedActions(redirect, spotifyConnected);
    setTotalTasks(tasks.length);
    const doneTasks: GettingStartedItem[] = [];
    const undoneTasks: GettingStartedItem[] = [];
    tasks.forEach((task) => {
      if (completedGettingStartedTasks.includes(task.doneKey)) {
        doneTasks.push(task);
      } else {
        undoneTasks.push(task);
      }
    })
    setDoneTasks(doneTasks);
    setUndoneTasks(undoneTasks);
  }, [completedGettingStartedTasks, spotifyConnected, currentBrand]);

  if (innerWidth <= 1200) {
    return (
      <CardContainer gap={24} paddingY={window.innerWidth >= 768 ? 48 : 36} >
        <GettingStartedHeader isMobile>
          <ProgressBar
            maximumProgress={totalTasks}
            progress={doneTasks.length}
          />
          <ProgressBarText>
            <span>{doneTasks.length} of {totalTasks}</span> tasks completed
          </ProgressBarText>
        </GettingStartedHeader>

        <CarouselContainer>
        <GettingStartedTitle isMobile>
          <span>
            Let’s get your account set up for growth 💪
          </span>
          <Swiper
              slidesPerView={ isMobile ? innerWidth < 420 ? 1.6 : 2.1 : 2.8 }
              spaceBetween={16}
              navigation={true}
              modules={[Navigation]}
              style={{ padding: "0px 8px", width: "100%" }}
            >
              {
                undoneTasks.map((item, index) => {
                  return (
                  <SwiperSlide key={`swiper-slide-${item.key}`}>
                      <TaskCard
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                        action={ item.key === SYMPHONY_TASKS.FANBASE_COMPLETED ? platformConnectorOpener: item.action}
                      />
                    </SwiperSlide>
                  )
                })
              }
              {
                doneTasks.map((item, index) => {
                  return (
                  <SwiperSlide key={`swiper-slide-${item.key}`}>
                      <TaskCard
                        key={item.key}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                        action={ item.key === SYMPHONY_TASKS.FANBASE_COMPLETED ? platformConnectorOpenerHandler: item.action}
                        crossedOut
                      />
                    </SwiperSlide>
                  )
              })}
            </Swiper>
        </GettingStartedTitle>

        </CarouselContainer>
      </CardContainer>
    )
  }
  return (
    <CardContainer 
      paddingX={16}
      paddingY={32}
      border="1px #EDECF2"
      borderRadius={16}
      justifyContent='start'
      className="lg:h-screen lg:bg-white sticky top-0 overflow-y-auto"
      >
      <GettingStartedHeader>
        <ProgressBar
          maximumProgress={totalTasks}
          progress={doneTasks.length}
        />
        <ProgressBarText>
          <span>{doneTasks.length} of {totalTasks}</span> tasks completed
        </ProgressBarText>
      </GettingStartedHeader>
      <GettingStartedTitle>
        Let’s get your account set up for growth 💪
      </GettingStartedTitle>
      {/* Not crossed out cards */}
      {
        undoneTasks.map((item, index) => {
          return (
              <TaskCard
                key={item.key}
                icon={item.icon}
                title={item.title}
                description={item.description}
                action={ item.key === SYMPHONY_TASKS.FANBASE_COMPLETED ? platformConnectorOpener: item.action}
              />
          )
        })
      }
      {/* crossed out cards */}
      {
        doneTasks.map((item, index) => {
          return (
              <TaskCard
                key={item.key}
                icon={item.icon}
                title={item.title}
                description={item.description}
                action={ item.key === SYMPHONY_TASKS.FANBASE_COMPLETED ? platformConnectorOpener: item.action}
                crossedOut
              />
          )
          }
        )
      }
    </CardContainer>
  )
}