import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import { clsx } from "modules/Utils";

interface RecordSelectorCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const RecordSelectorCampaignView = ({
    className = '',
    style = {}
}: RecordSelectorCampaignViewProps) => {
    const { campaign, campaignName, setCampaignName } = useCampaign();

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCampaignName(e.target.value);
    };

    return (
        <div
            className={clsx("recordSelectorCampaignViewComponent", className)}
            style={{ ...sty.recordSelectorCampaignViewComponent, ...style }}
        >
            <input
                type="text"
                value={campaignName}
                onChange={handleNameChange}
                style={sty.nameInput}
                placeholder="Campaign Name"
            />
        </div>
    )
}

const sty = {
    recordSelectorCampaignViewComponent: {
        width: '100%',
        height: '100%',
    },
    nameInput: {
        padding: '8px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        width: '100%',
        maxWidth: '300px',
    }
}

export default RecordSelectorCampaignView;