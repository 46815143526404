import DatePicker from "react-datepicker";
import "styles/react-datepicker.css";
import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import { Card, StyledLabel } from "../../styles";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  startDate?: Date | null;
  endDate?: Date | null;
  currentEndSchedule: string;
  budgetValue: number;
  handleSelectStartDate: (date: Date | null) => void;
  handleSelectEndDate: (date: Date | null) => void;
}

const isValidStartDate = (dates: { startDate?: Date | null, endDate?: Date | null }): boolean => {
  const { startDate, endDate } = dates;
  if (!startDate) return false;
  const today = dayjs();
  return (
    (today.isSame(startDate, 'day') || today.isBefore(startDate, 'day')) &&
    (!endDate || dayjs(startDate).isBefore(endDate, 'day'))
  );
}

const isValidEndDate = (dates: { startDate?: Date | null, endDate?: Date | null }): boolean => {
  const { startDate, endDate } = dates;
  if (!endDate || !startDate) return false;
  const today = dayjs();
  return today.isBefore(endDate, 'day') && dayjs(startDate).isBefore(endDate, 'day');
}

const DATE_FORMAT = "MMMM dd, yyyy";

const DatesCard = ({
  startDate,
  endDate,
  currentEndSchedule,
  budgetValue,
  handleSelectStartDate,
  handleSelectEndDate,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <StyledLabel fontWeight={600} fontSize={18} paddingBottom="16px">
          Date
        </StyledLabel>
        <Grid
          className={classes.startDate}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Start Date
          </StyledLabel>
          <Grid>
            <DatePicker
              minDate={new Date()}
              filterDate={(date: Date) => {
                if (!startDate || !endDate) return true;
                return dayjs(date) < dayjs(endDate);
              }}
              className={isValidStartDate({ startDate, endDate }) ? classes.validDate : classes.invalidDate}
              id="budget_start_date"
              selected={startDate}
              onChange={handleSelectStartDate}
              dateFormat={DATE_FORMAT}
              placeholderText="Set starting date"
              wrapperClassName={classes.wrapperStyles}
              autoComplete='off'
            />
          </Grid>
        </Grid>
        <>
          {currentEndSchedule === "scheduled" && (
            <Grid
              className={classes.endDate}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <StyledLabel fontWeight={600} fontSize={16}>
                End Date
              </StyledLabel>
              <Grid item>
                <Grid container alignItems="center">
                  <DatePicker
                    filterDate={(date: Date) => {
                      if (!startDate) return false;

                      var maxEndDate = null;
                      if (budgetValue <= 100) {
                        maxEndDate = dayjs(startDate).add(10, "day");
                        return (
                          dayjs(date) <= maxEndDate &&
                          dayjs(date) > dayjs(startDate)
                        );
                      }
                      return dayjs(date) > dayjs(startDate);
                    }}
                    minDate={dayjs().add(1, "day").toDate()}
                    className={isValidEndDate({ startDate, endDate }) ? classes.validDate : classes.invalidDate}
                    id="budget_end_date"
                    selected={endDate}
                    onChange={handleSelectEndDate}
                    dateFormat={DATE_FORMAT}
                    placeholderText="Set end date"
                    wrapperClassName={classes.wrapperStyles}
                    autoComplete='off'
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </Card>
  );
};

export default DatesCard;
