import { CSSProperties } from 'react';
import { Severity } from 'modules/Const';
import { clsx } from "modules/Utils";

interface SmallCardProps {
  className?: string;
  style?: CSSProperties;
  text: string;
  severity: Severity;
}

const SmallCard = ({
  className = '',
  style = {},
  text,
  severity
}: SmallCardProps) => {
  return (
    <div
      className={clsx('smallCardComponent', className)}
      style={{
        ...sty.smallCardComponent,
        ...style,
        background: severity === Severity.WARNING 
          ? 'linear-gradient(0deg, rgba(228, 144, 13, 0.08), rgba(228, 144, 13, 0.08)), #FFFFFF'
          : 'linear-gradient(0deg, rgba(255, 0, 0, 0.16), rgba(255, 0, 0, 0.16)), #FFFFFF'
      }}
    >
      <div className="smallCardTextContainer" style={sty.smallCardTextContainer}>
        {text}
      </div>
    </div>
  );
};

const sty: Record<string, CSSProperties> = {
  smallCardComponent: {
    display: 'flex',
    padding: '16px 24px',
    borderRadius: 8,
  },
  smallCardTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    width: '100%',
  },
};

export default SmallCard;