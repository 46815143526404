import styled from "styled-components";

interface ElementsContainerProps {
  alignItems?: string;
}

export const ListContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #FFFFFF;
  border-radius: 8px;
  width: -webkit-fill-available;
`;

export const ElementsContainer = styled.div<ElementsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  align-self: stretch;
`;

export const ListElement = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;
`;

export const ListElementTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const ListElementValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
`;

export const ImageContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;