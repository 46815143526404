import { ReactElement } from "react"
import LoadingLottie from "components/Loader/LoadingLottie"
import { SymphonyTooltip } from "styles/shared"
import styled from "styled-components"
import Colors from "modules/Colors"
import { ButtonText } from "./Typography"

interface Props {
  id?: string
  text: string
  loadingText?: string
  disabled?: boolean
  loading?: boolean
  width?: string
  height?: string
  variant?: "dark" | "light" | "error" | "outlined"
  iconLeft?: ReactElement
  iconRight?: ReactElement
  disabledColor?: string
  tooltip?: string
  padding?: string
  onClick: () => void
  className?: string
}

const PrimaryButton = ({
  id,
  disabled = false,
  loading = false,
  loadingText = "",
  text,
  width = "fit-content",
  height = "fit-content",
  variant,
  iconLeft,
  iconRight,
  tooltip = '',
  disabledColor,
  padding,
  onClick,
  className,
}: Props) => {
  const buttonTextColor = variant === 'outlined' ? Colors.purple : Colors.textColorWhite

  return (
    <SymphonyTooltip title={tooltip}>
      <ButtonContainer
        disabled={disabled}
        onClick={onClick}
        width={width}
        height={height}
        variant={variant}
        disabledColor={disabledColor}
        padding={padding}
        className={className}
        id={id}
      >
        {loading ?
          <>
            <LoadingLottie black={variant === 'outlined' ? true : false} height={24} width={24} />
            {loadingText.length > 0 && (
              <ButtonText color={buttonTextColor}>
                {loadingText}
              </ButtonText>
            )}
          </>
          :
          <>
            {iconLeft}
            <ButtonText color={buttonTextColor}>{text}</ButtonText>
            {iconRight}
          </>
        }
      </ButtonContainer>
    </SymphonyTooltip>
  )
}

interface ButtonContainerProps {
  width: string
  height: string
  variant?: "dark" | "light" | "error" | "outlined"
  disabledColor?: string
  padding?: string
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding || '12px 24px'};
  border-radius: 48px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  white-space: nowrap;
  background-color: ${({ variant }) => {
    if (variant === "error") return Colors.red

    if (variant === 'outlined') return 'none'

    return variant === "dark"
      ? Colors.purpleDark
      : Colors.purple
  }};
  border: ${({ variant }) => {
    if (variant === 'outlined') return `1px solid ${Colors.purple}`

    return 'none'
  }};
  :disabled {
    background: ${({ disabledColor }) => disabledColor || Colors.grey};
    cursor: not-allowed;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`

export default PrimaryButton
