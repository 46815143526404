import { useState, ReactNode, CSSProperties } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import useStyles, { StyledLabel, TitleContainer } from "./styles"
import GoBackIcon from "@material-ui/icons/ChevronLeft"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CloseIcon from "./Components/CloseIcon"
import SupportIcon from "./Components/SupportIcon"
import SaveDraftsChip from "./Components/SaveDraftsChip"
import SavesYourProgressInfoModal from "../SavesYourProgressInfoModal"
import { clsx } from "modules/Utils"
import useScreen from "Hooks/useScreen"
import Colors from "modules/Colors"
import { WEBSITE_TOP_BAR_HEIGHT } from "modules/Const"

type GlobalWindow = Window & {
  Intercom: (type: string) => void
}

interface Props {
  className?: string;
  style?: CSSProperties;
  title?: string
  showGoBackIcon?: boolean
  showCloseIcon?: boolean
  disableCloseIcon?: boolean
  showSaveDraftChip?: boolean
  savingChanges?: boolean
  hideGetSupportLabel?: boolean
  renderRightSideContent?: ReactNode
  renderDownSideContent?: ReactNode
  handleGoBack?: () => void
  handleClose?: () => void
}

const TopBar = ({
  className = '',
  style = {},
  title,
  showGoBackIcon,
  showCloseIcon,
  disableCloseIcon,
  showSaveDraftChip,
  savingChanges,
  hideGetSupportLabel = false,
  renderRightSideContent,
  renderDownSideContent,
  handleGoBack,
  handleClose,
}: Props) => {
  const theme = useTheme()
  const isXsView = useMediaQuery(theme.breakpoints.down(421))
  const { mobileView } = useScreen()

  const classes = useStyles()
  const flexGrowTitleContainer = (showSaveDraftChip && mobileView) || !showSaveDraftChip ? 1 : undefined
  const [openModal, setOpenModal] = useState(false)

  const showGetSupportLabel = !hideGetSupportLabel && !mobileView

  const handleOpenModal = (open: boolean) => () => setOpenModal(open)

  const handleClickSupportButton = () => {
    const globalWindow = window as unknown as GlobalWindow
    if (globalWindow.Intercom) globalWindow.Intercom("show")
  }

  const renderGoBackIcon = () => {
    return (
      <IconButton disableTouchRipple edge="start" onClick={handleGoBack}>
        <GoBackIcon className={classes.goBackIcon} />
      </IconButton>
    )
  }

  const renderCloseIcon = () => {
    return (
      <IconButton
        disabled={disableCloseIcon}
        disableTouchRipple
        edge="start"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    )
  }

  const renderTitle = () => {
    return (
      <TitleContainer flexGrow={flexGrowTitleContainer}>
        <StyledLabel
          width="fit-content"
          textOverflow="ellipsis"
          maxHeight="48px"
          overflow="hidden"
          whiteSpace="nowrap"
          color="#ffffff"
          fontSize={16}
          fontWeight={400}
        >
          {title}
        </StyledLabel>
        {!isXsView && mobileView && showSaveDraftChip &&
          renderSaveDraftChip()
        }
      </TitleContainer>
    )
  }

  const renderSaveDraftChip = () => {
    return (
      <SaveDraftsChip
        savingChanges={savingChanges}
        onClick={handleOpenModal(true)}
      />
    )
  }

  const renderGetSupportLabel = () => {
    return (
      <IconButton
        disableTouchRipple
        color="inherit"
        onClick={handleClickSupportButton}
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <SupportIcon />
          </Grid>
          {showGetSupportLabel && (
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={500} color="#B982FB">
                Get help
              </StyledLabel>
            </Grid>)}
        </Grid>
      </IconButton>
    )
  }

  return (
    <>
      <div
        className={clsx('topBarComponent', className)}
        style={{ ...sty.topBarComponent, ...style }}
      >
        {showGoBackIcon && renderGoBackIcon()}
        {showCloseIcon && renderCloseIcon()}
        {title && renderTitle()}
        {!isXsView && !mobileView && showSaveDraftChip && renderSaveDraftChip()}
        {!hideGetSupportLabel && renderGetSupportLabel()}
        {renderRightSideContent}
        {renderDownSideContent &&
          <Toolbar>
            {renderDownSideContent}
          </Toolbar>
        }
      </div>
      <SavesYourProgressInfoModal
        open={openModal}
        handleClose={handleOpenModal(false)}
        onClickAction={handleOpenModal(false)}
      />
    </>
  )
}

const sty: Record<string, CSSProperties> = {
  topBarComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: Colors.purpleDark,
    height: WEBSITE_TOP_BAR_HEIGHT,
    width: "100%",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 900,
  },
}

export default TopBar
