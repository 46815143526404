import styled from "styled-components"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Radio } from "@material-ui/core"
import Colors from "modules/Colors"

interface useStylesProps {
  isMobileView?: boolean
}

const useStyles = makeStyles((_theme: Theme) => ({
  closeIcon: {
    "& path": {
      stroke: Colors.PAPER_CONTAINER,
    },
  },
  paper: { borderRadius: 12 },
  billingChip: {
    backgroundColor: Colors.PAPER_CONTAINER,
    borderRadius: 8,
    border: "1px solid var(--divider-line, #EDECF2)",
    width: ({ isMobileView }: useStylesProps) => ("100%"),
    display: "flex",
    "& .MuiChip-label": {
      width: "100%",
      paddingLeft: '2px'
    },
    "&:hover": {
      backgroundColor: Colors.PAPER_ACCENT_CONTAINER,
    },
    height: 48,
  },
  billingChipActive: {
    width: ({ isMobileView }: useStylesProps) => ("100%"),
    backgroundColor: Colors.PAPER_ACCENT_CONTAINER,
    color: Colors.purple,
  },
  billingRadio: {
    color: Colors.purple,
  },
  icon: {
    width: "100%",
    "& path": {
      stroke: Colors.purple,
    },
  },
  iconFacebook: {
    marginTop: 4,
    width: "100%",
    "& path": {
      fill: Colors.purple,
    },
  }
}))

export const BillingRadio = styled(Radio)`
  color: ${Colors.purple};
  &$checked {
    color: ${Colors.purple};
  }
`

export const AnnuallyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`

export default useStyles
