import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CreateOrderActions, OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import PaypalButtonNonPro from "pages/post-auth/MarketingPage/Components/PaypalButtonNonPro";
import { useContext } from "react";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import PaypalButtonNonUSD from "../PaypalButtonNonUSD";
import { CurrencyCodesType } from "types/global";
import { clsx } from 'modules/Utils';

const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID as string;

interface PayPalButtonGroupProps {
    /**
     * Callback function to create a PayPal order
     */
    createOrder?: (data: Record<string, unknown>, actions: CreateOrderActions) => Promise<string>;
    /**
     * Callback function when PayPal order is cancelled
     */
    onCancel?: () => void;
    /**
     * Callback function when PayPal order encounters an error
     */
    onError?: () => void;
    /**
     * Callback function when PayPal order is approved
     */
    onApprove?: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
    /**
     * Additional class names to apply to the container
     */
    className?: string;
    /**
     * The currency code of the brand
     */
    currencyCode?: CurrencyCodesType;
    /**
     * The style of the PayPal buttons
     */
    style?: React.CSSProperties;

    /**
     * Whether to allow the checkout without being a Pro brand
     */
    allowWithoutPro?: boolean;
}

/**
 * A component that renders PayPal and Venmo payment buttons
 */
const PayPalButtonGroup = ({    createOrder,
    onCancel,
    onError,
    onApprove,
    className,
    currencyCode,
    style,
    allowWithoutPro = false
}: PayPalButtonGroupProps) => {

    const { isProBrand } = useContext(CurrentBrandContext);

    /**
     * Renders PayPal payment options for Pro users or if allowWithoutPro is true
     * Shows PayPal and Venmo buttons for USD currency, or a non-USD PayPal button for other currencies
     * @returns JSX.Element PayPal payment buttons or non-USD PayPal button
     */
    function renderProView() {
        if (currencyCode === 'USD') {
            return (
                <PayPalScriptProvider options={{ "client-id": payPalClientId }}>
                    <PayPalButtons
                        className="w-full relative z-0"
                        style={{ label: "pay", shape: "pill" }}
                        fundingSource="paypal"
                        createOrder={createOrder}
                        onCancel={onCancel}
                        onError={onError}
                        onApprove={onApprove}
                    />
                    <PayPalButtons
                        className="w-full relative z-0"
                        style={{ label: "pay", shape: "pill" }}
                        fundingSource="venmo"
                        createOrder={createOrder}
                        onCancel={onCancel}
                        onError={onError}
                        onApprove={onApprove}
                    />
                </PayPalScriptProvider>
            )
        } else {
            return (
                <PaypalButtonNonUSD width='100%' height='48px' />
            )
        }
    }

    /**
     * Renders PayPal payment options for non-Pro users
     * Shows a simplified PayPal button with limited functionality
     * @returns JSX.Element Non-pro PayPal button
     */
    function renderNonProView() {
        return (
            <PaypalButtonNonPro width='100%' height='48px' />
        )
    }

    return (
        <div className={clsx(className, "payPalButtonContainer")} style={style}>
            {isProBrand || allowWithoutPro ? (
                renderProView()
            ) : (
                renderNonProView()
            )}
        </div>
    );
};

export default PayPalButtonGroup;