import { deepCopy, objectToArray } from "modules/Utils";
import { useStyles } from "../../styles";
import { WebsiteLinks, WebsiteLink, getIconBackgroundStyle, defaultIconProps, convertWebsiteLinkArrayToObject } from "../../utils";
import FacebookIcon from 'components/svg-icons/social/facebook';
import InstagramIcon from 'components/svg-icons/social/instagram';
import TikTokIcon from 'components/svg-icons/social/tiktok';
import TwitterIcon from 'components/svg-icons/social/twitter';
import { YouTubeIcon } from 'components/svg-icons/social/youtube';
import { SpotifyIcon } from 'components/svg-icons/social/spotify';
import { AppleMusicIcon } from 'components/svg-icons/social/applemusic';
import { DeezerIcon } from 'components/svg-icons/social/deezer';
import { AmazonMusicIcon } from 'components/svg-icons/social/amazonmusic';
import { TidalIcon } from 'components/svg-icons/social/tidal';
import { ShazamIcon } from 'components/svg-icons/social/shazam';
import { YandexIcon } from 'components/svg-icons/social/yandex';
import { AudiomackIcon } from 'components/svg-icons/social/audiomack';
import { SoundcloudIcon } from 'components/svg-icons/social/soundcloud';
import { StreamingLinkIcon } from 'components/svg-icons/social/streaminglink';
import { Anghami } from "components/svg-icons/social/anghami"
import { Boomplay } from "components/svg-icons/social/boomplay"
import { clsx } from 'modules/Utils';
import { DraggableList } from "components/shareable/DraggableList";
import { useContext } from "react";
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { Bandcamp } from "components/svg-icons/social/bandcamp";
import SwitchSymphony from "components/shareable/SwitchSymphony";

interface Props {
    socialMediaIcons?: WebsiteLinks;
    handleSocialMediaUrlChange: (url: string, sm: WebsiteLink) => void;
    handleSocialMediaEnabledSwitch: (enabled: boolean, sm: WebsiteLink) => void;
}

export const renderIcon = (defaultIcon: boolean, sm?: WebsiteLink) => {
    if (!sm || (!sm.enabled && !defaultIcon)) {
        return null;
    }

    switch (sm.key) {
        case 'facebook':
            return <FacebookIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'instagram':
            return <InstagramIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'tiktok':
            return <TikTokIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'twitter':
            return <TwitterIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'youtube':
            return <YouTubeIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'spotify':
            return <SpotifyIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'apple_music':
            return <AppleMusicIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'amazon_music':
            return <AmazonMusicIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'deezer':
            return <DeezerIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'tidal':
            return <TidalIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'yandex':
            return <YandexIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'shazam':
            return <ShazamIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'audiomack':
            return <AudiomackIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'soundcloud':
            return <SoundcloudIcon {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'anghami':
            return <Anghami {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'boomplay':
            return <Boomplay {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'bandcamp':
            return <Bandcamp {...(defaultIcon ? defaultIconProps : sm)} />;
        case 'website':
            return <StreamingLinkIcon
                {...(defaultIcon ? defaultIconProps : sm)}
                width={'18'}
                height={'18'} />;

        default:
            return <StreamingLinkIcon {...(defaultIcon ? defaultIconProps : sm)} />;
    }
};

export const SocialMediaIconsInputs = ({
    handleSocialMediaUrlChange,
    handleSocialMediaEnabledSwitch,
}: Props) => {
    const classes = useStyles();
    const { socialMediaIcons = {}, setSocialMediaIcons } = useContext(WebsiteContext);

    const socialMediaIconsArray = objectToArray(socialMediaIcons);

    const handleOnDragEnd: OnDragEndResponder = (destination) => {
        if (!destination) return;

        const sourceIndex = destination.source.index;
        const destinationIndex = destination.destination?.index || 0;

        const list = deepCopy(socialMediaIconsArray);
        const temp = socialMediaIconsArray[sourceIndex];
        list[sourceIndex] = list[destinationIndex];
        list[destinationIndex] = temp;

        setSocialMediaIcons(convertWebsiteLinkArrayToObject(list));
    };

    const renderSocialMediaContent = (item: WebsiteLink, index?: number) => {
        return (
            <div
                key={`socialMediaIcon-${index}`}
                className={clsx(classes.socialMediaInputContainer, 'socialMediaInputContainer')}
            >
                <DragIcon />
                <div className={classes.iconTitleInputContainer}>
                    <div className={classes.titleTrashContainer}>
                        <div className={classes.iconTitleContainer}>
                            <div className={classes.socialIconContainer} style={getIconBackgroundStyle(item)}>
                                {renderIcon(true, item)}
                            </div>
                            <div className='inputTitle' style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>
                                {item.title}
                            </div>
                        </div>
                        <SwitchSymphony
                            checked={item.enabled}
                            onChange={() =>
                                handleSocialMediaEnabledSwitch(!item.enabled, item)
                            }
                            className={clsx(item.enabled && classes.switchEnabled)}
                        />
                    </div>
                    <input
                        className={classes.socialMediaInput}
                        type='text'
                        value={item.url}
                        placeholder={item.placeholder}
                        onChange={(e) =>
                            handleSocialMediaUrlChange(e.target.value, item)
                        }
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.socialMediaIconsContainer}>
            <DraggableList
                list={socialMediaIconsArray}
                updateList={(newList: WebsiteLinks) => setSocialMediaIcons(newList)}
                renderItem={renderSocialMediaContent}
                onDragEnd={handleOnDragEnd}
                keyOfItem="key"
            />
        </div>
    );
};