import styled from "styled-components";
import {
  YELLOW_BACKGROUND_COLOR,
  YELLOW_LABEL_COLOR,
  PURPLE_BACKGROUND_COLOR,
  PURPLE_LABEL_COLOR,
} from "modules/Const";
import isEqual from "lodash/isEqual";

enum Colors {
  PURPLE = "PURPLE",
  YELLOW = "YELLOW",
}

const { PURPLE, YELLOW } = Colors;

interface StyledLabelProps {
  marginTop?: string;
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  lineHeight?: string;
}

interface IconProps {
  widthStyle?: string;
  heightStyle?: string;
  borderRadius?: string;
}

interface UpgradeLabelContainerProps {
  maxWidth?: string;
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  margin?: string;
}

interface UpgradeLabelProps {
  color?: string;
}

export const UpgradeLabelContainer = styled.div`
  max-width: ${({ maxWidth }: UpgradeLabelContainerProps) =>
    maxWidth || "240px"};
  width: ${({ width }: UpgradeLabelContainerProps) =>
    width || "inherit"};
  gap: 12px;
  display: flex;
  margin: ${({ margin }: UpgradeLabelContainerProps) =>
  margin || "inherit"};
  justify-content: space-between;
  align-items: center;
  background-color: ${({ backgroundColor }: UpgradeLabelContainerProps) =>
    isEqual(backgroundColor, PURPLE)
      ? PURPLE_BACKGROUND_COLOR
      : isEqual(backgroundColor, YELLOW)
        ? YELLOW_BACKGROUND_COLOR
        : backgroundColor};
  opacity: 88%;
  padding: 8px 16px;
  border-radius: 44px;
  border: ${({ borderColor }: UpgradeLabelContainerProps) => borderColor && `1px solid ${borderColor}`};
`;

// TODO CONNER: 88% opacity?

export const UpgradeLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  color: ${({ color }: UpgradeLabelProps) =>
    isEqual(color, YELLOW)
      ? YELLOW_LABEL_COLOR
      : isEqual(color, PURPLE)
        ? PURPLE_LABEL_COLOR
        : color};
`;

export const Icon = styled.img`
  width: ${({ widthStyle }: IconProps) => widthStyle};
  height: ${({ heightStyle }: IconProps) => heightStyle};
  border-radius: ${({ borderRadius }: IconProps) => borderRadius};
`;

export const StyledLabel = styled.p`
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize};
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight};
  color: ${({ color }: StyledLabelProps) => color};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
`;
