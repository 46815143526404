import { useState, useEffect, CSSProperties } from "react";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";
import { ReactComponent as UserIcon } from 'assets/images/user.svg';
import { CurrentBrand } from "types/global"; // Adjust import path as needed
import InputWithDebounce from "../InputWithDebounce";
import BrandsListItemModal from "./BrandListItemModal";
import { PlanType } from "modules/Const";
import ModalSymphony from "../ModalSymphony";
import { clsx } from "modules/Utils";
import Sty from "modules/Sty";

interface Props {
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    brands: CurrentBrand[];
    onBrandSelect: (brandId: string) => void;
    titleText?: string;
    subtitleText?: string;
}

interface DisplayedBrand {
    name: string;
    image: string;
    slug: string;
    isPro: boolean;
    isLoading: boolean;
    id: string;
}

const BrandSelectionModal = ({
    className = '',
    isOpen,
    onClose,
    brands = [],
    onBrandSelect = () => { },
    titleText = "Select a Profile",
    subtitleText = "Choose the profile you want to create a campaign for."
}: Props) => {
    const [displayedBrands, setDisplayedBrands] = useState<DisplayedBrand[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        const filteredBrands = formatBrands().filter((brand) =>
            brand.name.toLowerCase().includes(value.toLowerCase())
        );
        setDisplayedBrands(filteredBrands);
    };

    const handleChangeText = (value: string) => {
        if (!value) {
            setDisplayedBrands([]);
        } else {
            setSearchTerm(value);
        }
    };

    const formatBrands = (): DisplayedBrand[] => {
        return brands.map((brand) => ({
            id: String(brand.id),
            name: brand.name!,
            image: brand.image!,
            slug: brand.slug!,
            isPro: brand.tier === PlanType.PRO,
            isLoading: false,
        }));
    };

    const renderBrandsList = () => {
        if (!displayedBrands.length) {
            return (
                <div className="noBrandsFoundContainer flex flex-col justify-center items-center h-64 bg-gray-200">
                    <UserIcon className="w-8 h-8 text-gray-500" />
                    <p className="text-gray-500">No results found, try another search.</p>
                </div>
            );
        }

        return (
            <div className="brandsListContainer" style={sty.brandsListContainer}>
                {displayedBrands.map((brand, idx) => (
                    <BrandsListItemModal
                        className="selectBrandModalListItem"
                        key={idx}
                        isPro={brand.isPro}
                        slug={brand.slug}
                        name={brand.name}
                        image={brand.image}
                        onClickListItem={() => onBrandSelect(brand.id)}
                    />
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (brands?.length) {
            setDisplayedBrands(formatBrands());
        }
    }, [brands]);

    return (
        <ModalSymphony
            className="brandSelectionModalContainer"
            style={sty.brandSelectionModalContainer}
            isOpen={isOpen}
            onClose={onClose}
            title={titleText}
            subtitle={subtitleText}
            subtitleStyle={{ marginBottom: 16 }}
            size="small"
        >
            <div
                className={clsx('brandSelectionModalContentContainer', className)}
                style={sty.brandSelectionModalContentContainer}
            >
                <InputWithDebounce
                    onChange={handleChangeText}
                    debounceHandler={handleSearchChange}
                    value={searchTerm}
                    placeholder="Search..."
                    loading={false}
                    debounceTime={800}
                    leftIcon={<SearchIcon />}
                />
                {renderBrandsList()}
            </div>
        </ModalSymphony>
    );
};

const sty: Record<string, CSSProperties> = {
    brandSelectionModalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    brandSelectionModalContentContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        width: "100%",
        maxWidth: 400,
    },
    brandsListContainer: {
        marginTop: 8,
        borderRadius: 8,
        border: Sty.border,
        boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.1)",
        maxHeight: 320,
        overflow: "scroll",
    },
}

export default BrandSelectionModal;