import { FacebookBusinessManager } from 'types/global'
import { StyledAccountsUl } from '../../../../ConversionsSteps/FacebookAdAccount/SelectAdAccountModal/AccountsList/styles'
import { BusinessAccountOption } from '../../../../ConversionsSteps/FacebookAdAccount/SelectAdAccountModal/AccountsList/BusinessAccountOption/BusinessAccountOption'

interface BusinessesListProps {
  businessAccounts: FacebookBusinessManager[]
  businessManager: FacebookBusinessManager
  setBusinessManager: (selectedBusinessManager: FacebookBusinessManager) => void
}

export const BusinessesList = ({
  businessAccounts,
  businessManager,
  setBusinessManager,
}: BusinessesListProps) => {
  return (
    <StyledAccountsUl>
      {businessAccounts &&
        businessAccounts.map((account) => (
          <BusinessAccountOption
            key={account.id}
            account={account}
            selectedAccount={businessManager}
            setBusinessManager={setBusinessManager}
          />
        ))}
    </StyledAccountsUl>
  )
}
