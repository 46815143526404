import useStyles from "./styles"

interface Props {
    totalBudget: number
}

export const BudgetNotFulfilled = ({ totalBudget }: Props) => {
    const classes = useStyles()

    const hasExceeded = totalBudget > 1
    const totalBudgetPercent = Math.round(totalBudget * 100)

    const title = hasExceeded ? `Your budget percents add up to ${totalBudgetPercent}%` : "Your budget percents don't add up to 100%"
    const subtitle = `
        Please edit your % of budget allocations to add up to 100%. You'll need to
        ${hasExceeded ? `remove ${totalBudgetPercent - 100}% from the` : `add ${100 - totalBudgetPercent}% to your`}
        overall budget to save your locations.
    `

    return (
        <div>
            <div className={classes.mainContainer}>
                <div className={classes.textContainer}>
                    <p className={classes.headerLabel}>{title}</p>
                    <p className={classes.content}>{subtitle}</p>
                </div>
            </div>
        </div>
    )
}

export default BudgetNotFulfilled