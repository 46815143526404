import { CardContainer, GridContainer } from "../styles";
import { Container, StyledLabel } from "styles/shared";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import millify from "millify";
import { GraphTooltip } from "pages/post-auth/DashboardModule/components/Graphs";
import useScreen from "Hooks/useScreen";

// Define the interface for the props
interface BigGraphProps {
    latestValue: number;
    title: string;
    data: {
        date: string;
        value: number;
    }[];
}

export default function BigGraph(props: BigGraphProps) {
    const { mobileView } = useScreen();

    return (
        <GridContainer>
            <CardContainer
                width={mobileView ? "calc(50% - 8px)" : "100%"}
            >
                <Container>
                    <StyledLabel>
                        {props.title}
                    </StyledLabel>
                    <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
                        <AreaChart
                            width={500}
                            height={300}
                            data={props.data}
                            margin={{
                                top: 15,
                                right: 0,
                                // left: window.innerWidth < 576 ? 2 : 20,
                                left: 0,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid stroke="#8800FF29"
                                strokeWidth={1.5}
                                strokeDasharray="8 8" />
                            <XAxis dy={12}
                                axisLine={false}
                                dataKey="date"
                                tickLine={false}
                                minTickGap={window.innerWidth < 768 ? 20 : 30}
                                // interval={interval}
                                tickFormatter={(value: any, index: number) => {
                                    return dayjs(value).format("MM/DD")
                                }} />
                            <YAxis
                                // hide={window.innerWidth < 768}
                                mirror={window.innerWidth < 768}
                                tick={(e) => {
                                    const { payload: { value } } = e;
                                    const color = value === "yourdata" ? "red" : "#666";
                                    e["fill"] = color;
                                    if (window.innerWidth >= 768) {
                                        return (
                                            <Text
                                                {...e}
                                                style={{
                                                    transform: "translateX(-4px)",
                                                    zIndex: 99
                                                }}>
                                                {millify(value, {
                                                    precision: 2
                                                })}
                                            </Text>
                                        )
                                    } else {

                                        return (<>
                                            <rect></rect>
                                            <defs>
                                                <filter id="rounded-corners-overall" x="-5%" width="110%" y="0%" height="100%">
                                                    <feFlood floodColor="#fff" />
                                                    {/* <feGaussianBlur stdDeviation="2" /> */}
                                                    <feComponentTransfer>
                                                        <feFuncA type="table" tableValues="1 1 1 1" />
                                                    </feComponentTransfer>

                                                    <feComponentTransfer>
                                                        <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                                                    </feComponentTransfer>
                                                    <feComposite operator="over" in="SourceGraphic" />
                                                </filter>
                                            </defs>
                                            <Text
                                                filter="url(#rounded-corners-overall)"
                                                {...e}
                                                style={{
                                                    transform: "translateX(-4px)",
                                                    zIndex: 99
                                                }}>
                                                {millify(value, {
                                                    precision: 2
                                                })}
                                            </Text>
                                        </>
                                        )
                                    }
                                }}
                                tickCount={window.innerWidth < 768 ? 3 : 4}
                                axisLine={false}
                                minTickGap={window.innerWidth < 768 ? 30 : 20}
                                tickLine={false}
                                allowDecimals={false}
                                domain={['auto', 'auto']}
                            />
                            <Tooltip
                                cursor={{ stroke: '#8800FF', strokeWidth: 2 }}
                                content={({ active, payload, label }) => {

                                    if (active && payload && payload.length) {
                                        return (
                                            <GraphTooltip
                                                title={dayjs(label).format('dd MM/DD')}
                                                value={payload[0].value!.toLocaleString('en')}
                                            />
                                        );
                                    }

                                    return null;
                                }}

                            />
                            <defs>
                                <linearGradient id="splitColor"
                                    x1="0" y1="0" x2="0" y2="1"
                                >
                                    <stop stopColor="#8800FF" stopOpacity={0.4} />
                                    <stop offset="1" stopColor="#8800FF" stopOpacity={0.05} />
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="value"
                                strokeWidth={2}
                                stroke="#8800FF" fill="url(#splitColor)" activeDot={{ r: 8 }} />
                        </AreaChart>
                    </ResponsiveContainer>
                </Container>

            </CardContainer>
        </GridContainer>
    )
}