import styled from "styled-components"
import Colors from "modules/Colors"

export const WarningContainer = styled.div`
  padding: 16px;
  background-color: ${Colors.PAPER_ACCENT_CONTAINER};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

export const ActionsContainer = styled.div`
  align-self: flex-end;
  gap: 16px;
  display: flex;
`
