export function getCampaignTitleByCampaignType(campaignType: 'views' | 'engagement' | 'default') {
  switch(campaignType) {
    case "views":
      return "Cheapest Views"
    case "engagement":
      return "More Engagement"
    default:
      return "Recommended"
  }
}

export function getGeographiesTitleByGeographyType(geographyType: 'global' | 'english' | 'custom') {
  switch(geographyType) {
    case "global":
      return "Global Markets"
    case "english":
      return "English-speaking Markets"
    default:
      return "Custom"
  }
}

export const minimumDailyBudgetCalculator = (targetingType:string) => {
  if(targetingType === "engagement") {
    return 20 
  } else {
    return 10
  }
}
