import { useContext, useEffect, useState, ChangeEvent } from "react";
import { Body2, ButtonText, Caption } from "components/shareable/Typography";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { renderBorderSeparator, WebsiteEditorCard } from "pages/post-auth/Website/styles"
import { useStyles } from "../../../styles";
import { TextField } from "@material-ui/core";
import Text from 'modules/Text';
import { WebsiteContext } from "../../../hooks/WebsiteContext";
import { deepCopy, isValidUrl } from "modules/Utils";
import SecondaryButton from "components/shareable/SecondaryButton";
import { clsx } from "clsx";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import {
    websiteSubSectionContainer,
    tourDateListContainer,
    tourDateRow,
    tourDateDetail,
    tourDateDay,
    tourDateMonth,
    tourDateAddUploadContainer,
} from "../../../utils";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import { sortBy } from 'lodash';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import UploadInput from "pages/post-auth/Fans/Components/UploadEmailsByCsv/Components/UploadInput";
import head from "lodash/head";
import Axios from 'helpers/Interceptor';
import SymphonyTabs from "components/shareable/SymphonyTabs";
import { useTabs } from "react-headless-tabs";
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import BandsInTownConnect from "components/connect/BandsInTown";
import { Container, TextButton } from "styles/shared";
import DropdownArrow from "components/shareable/DropdownArrow";
import BandsInTownLogo from "assets/images/platforms/bandsintown.svg";
import { motion } from "framer-motion";
import LoaderV2 from 'components/Loader/LoaderV2';
import useScreen from "Hooks/useScreen";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import Colors from "modules/Colors";
import SwitchSymphony from "components/shareable/SwitchSymphony";
import ModalSymphony from "components/shareable/ModalSymphony";

export const TourSetupSection = () => {
    const classes = useStyles();
    const { mobileView } = useScreen();
    const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
    const {
        titleText, setTitleText,
        subtitle, setSubtitle,
        showSecondaryLine, setShowSecondaryLine,
        tourDates, setTourDates,
        hidePastTourDates, setHidePastTourDates,
    } = useContext(WebsiteContext)

    const [localBandsintownUrl, setLocalBandsintownUrl] = useState<{ name: string } | null>(null);

    const userHasBandsintownConnected = localBandsintownUrl !== null;

    const [cityText, setCityText] = useState<string>('')
    const [countryText, setCountryText] = useState<string>('')
    const [currentDate, setCurrentDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
    const [isEditTourDate, setIsEditTourDate] = useState<boolean>(false)
    const [isPreSaleDatePickerOpen, setIsPreSaleDatePickerOpen] = useState<boolean>(false);
    const [isPreSalePeriod, setIsPreSalePeriod] = useState<boolean>(false)
    const [isShowSoldOut, setIsShowSoldOut] = useState<boolean>(false)
    const [isTourDateModalOpen, setIsTourDateModalOpen] = useState<boolean>(false)
    const [preSaleDate, setPreSaleDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
    const [regionText, setRegionText] = useState<string>('')
    const [ticketUrlText, setTicketUrlText] = useState<string>('')
    const [buttonLinkText, setButtonLinkText] = useState<string>('Buy')
    const [tourIndex, setTourIndex] = useState<number>(-1)
    const [venueText, setVenueText] = useState<string>('')

    const [selectedCsv, setSelectedCsv] = useState<File | undefined>(undefined);
    const [uploadingCsv, setUploadingCsv] = useState(false);
    const [errorCsv, setErrorCsv] = useState<string>('');

    const [preSaleButtonText, setPreSaleButtonText] = useState<string>('');
    const [preSaleAction, setPreSaleAction] = useState<'collect' | 'link'>('collect');
    const [preSaleCustomLink, setPreSaleLinkText] = useState<string>('');

    const [googleSearchResults, setGoogleSearchResults] = useState<any[]>([]);
    const [selectedGoogleItem, setSelectedGoogleItem] = useState<any>(null);

    const [selectedTab, setSelectedTab] = useTabs(['event_details', 'date_customization']);

    const [bandsintownSearchText, setBandsintownSearchText] = useState<string>('');

    const purpleRadioStyle = {
        accentColor: '#8800FF', // This sets the color of the radio button when checked
    };

    const [venueInputValue, setVenueInputValue] = useState('');
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [selectedVenue, setSelectedVenue] = useState<{ name: string } | null>(null);

    const [ticketUrlError, setTicketUrlError] = useState<string>('');

    const [isBandsintownImportSectionOpen, setIsBandsintownImportSectionOpen] = useState<boolean>(false);
    const [bandsintownLoading, setBandsintownLoading] = useState<boolean>(false);
    const [isImportEventDropdown, setIsImportEventDropdown] = useState<boolean>(false);

    useEffect(() => {
        if (currentBrand?.connections?.bandsintown) {
            setLocalBandsintownUrl({ name: currentBrand.connections.bandsintown.name });
        }
    }, [currentBrand]);

    // New function to handle venue selection
    const handleVenueSelection = (newValue: any) => {
        if (newValue) {
            setVenueText(newValue.name || '');
            setVenueInputValue(newValue.name || '');
            setCityText(newValue.city || '');
            setRegionText(newValue.state || '');
            setCountryText(newValue.country || '');
            setSelectedGoogleItem(newValue);
            setSelectedVenue({ name: newValue.name || '' });
        } else {
            setVenueText('');
            setVenueInputValue('');
            setSelectedGoogleItem(null);
            setSelectedVenue(null);
        }
    };

    // Updated handleVenueInputChange function
    const handleVenueInputChange = async (query: string) => {
        setVenueInputValue(query);
        setVenueText(query);

        if (query.length >= 2) {
            const results = await searchItems(query);
            if (results.length > 0) {
                setGoogleSearchResults([...results]);
                setShowAutocomplete(true);
            } else {
                setGoogleSearchResults([]);
                setShowAutocomplete(false);
            }
        } else {
            setGoogleSearchResults([]);
            setShowAutocomplete(false);
        }
    };

    useEffect(() => {
        if (isTourDateModalOpen) return;

        setCityText('');
        setCountryText('');
        setCurrentDate(dayjs().format("YYYY-MM-DD"));
        setIsPreSalePeriod(false);
        setIsShowSoldOut(false);
        setPreSaleDate(dayjs().format("YYYY-MM-DD"));
        setRegionText('');
        setTicketUrlText('');
        setButtonLinkText('Buy');
        setVenueText('');
        setVenueInputValue('');
        setPreSaleButtonText('');
        setPreSaleAction('collect');
        setPreSaleLinkText('');
        setSelectedGoogleItem(null);
        setGoogleSearchResults([]);
    }, [isTourDateModalOpen]);

    const handleEditTourDate = (index: number) => {
        setIsEditTourDate(true);
        setIsTourDateModalOpen(true);
        setTourIndex(index);

        const tourDate = tourDates[index];
        setCityText(tourDate.city);
        setCountryText(tourDate.country);
        setCurrentDate(tourDate.date);
        setIsPreSalePeriod(tourDate.isPreSalePeriod);
        setIsShowSoldOut(tourDate.isSoldOut);
        setPreSaleDate(tourDate.preSaleDate);
        setRegionText(tourDate.region);
        setTicketUrlText(tourDate.ticketUrl);
        setButtonLinkText(tourDate.buttonLinkText);
        setVenueText(tourDate.venue);
        setVenueInputValue(tourDate.venue);
        setSelectedVenue({ name: tourDate.venue });
        setPreSaleButtonText(tourDate.preSaleButtonText);
        setPreSaleAction(tourDate.preSaleAction);

        setPreSaleLinkText(tourDate.preSaleCustomLink);

        // Reset the selected Google item and search results
        setSelectedGoogleItem(null);
        setGoogleSearchResults([]);
    };

    const searchItems = async (query: string) => {
        if (query.length < 2) return [];

        try {
            const response = await Axios.get(`/brand/${currentBrand?.slug}/google/autocomplete?q=${query}`);
            return response.data.results;
        } catch (error) {
            console.error('Search error:', error);
            return [];
        }
    };

    const importBandsInTownData = async () => {
        if (userHasBandsintownConnected) {
            setBandsintownLoading(true)
            try {
                const artistName = localBandsintownUrl?.name;
                const response = await Axios.get(`/website/bandsintown/tour-dates?artist=${artistName}`);
                const bandsintownTourData = response.data.data.bandsintownTourData;
                transformTourDatesFromBandsInTown(bandsintownTourData)
            } catch (error) {
                console.error('Error importing BandsInTown data:', error);
            } finally {
                setBandsintownLoading(false)
            }
        } else {
            setIsBandsintownImportSectionOpen(true)
        }
    }

    const handleDeleteTourDate = (index: number) => {
        const newTourDates = deepCopy(tourDates)
        newTourDates.splice(index, 1)
        setTourDates(newTourDates)
    }

    const createNewTourDate = () => {
        const tourDate = {
            city: cityText,
            country: countryText,
            date: currentDate,
            isPreSalePeriod: isPreSalePeriod,
            isSoldOut: isShowSoldOut,
            preSaleDate: preSaleDate,
            region: regionText,
            ticketUrl: ticketUrlText,
            buttonLinkText,
            venue: venueText,
            preSaleButtonText: preSaleButtonText,
            preSaleAction,
            preSaleCustomLink: preSaleCustomLink,
            source: 'manual',
        }

        return tourDate;
    }

    const handleOpenTourDateModal = ({ isEdit }: { isEdit: boolean }) => {
        setIsTourDateModalOpen(true);
        setIsEditTourDate(isEdit);
    }

    const sortTourDates = (tourDatesLocal: any[]) => {
        const sortedTourDates = sortBy(tourDatesLocal, (tourDate) => new Date(tourDate.date));
        return sortedTourDates;
    }

    const handleAddEditTourDate = (tourIndex: number) => {
        const isValid = () => {
            if (!currentDate || (!venueText && !cityText)) {
                return false;
            }
            if (!ticketUrlText) {
                if (isPreSalePeriod) {
                    if (preSaleAction === 'collect' && !preSaleDate) {
                        return false;
                    }
                    if (preSaleAction === 'link' && !preSaleCustomLink) {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            if (ticketUrlError) {
                return false;
            }
            return true;
        };

        if (!isValid()) {
            return;
        }

        const tourDate = createNewTourDate()

        if (isEditTourDate) {
            let newTourDates = deepCopy(tourDates)
            newTourDates[tourIndex] = tourDate
            const sortedTourDates = sortTourDates(newTourDates)
            setTourDates(sortedTourDates)
        } else {
            ignoreDuplicateTourDateDates([tourDate])
        }

        setIsTourDateModalOpen(false)
    }

    const transformTourDatesFromCsv = (tourDatesFromCsv: any[]) => {
        const newTourDates = tourDatesFromCsv.map((tourDate) => {
            return {
                city: tourDate.city || '',
                country: tourDate.country || '',
                date: tourDate.date || null,
                displayName: '',
                isPreSalePeriod: false,
                isSoldOut: tourDate.soldout || false,
                preSaleDate: null,
                region: tourDate.region || '',
                ticketUrl: tourDate.ticketUrl || '',
                venue: tourDate.venue || '',
                preSaleButtonText: '',
                preSaleAction: 'collect',
                preSaleCustomLink: '',
                source: 'csv',
            }
        })

        ignoreDuplicateTourDateDates(newTourDates)
    }

    const ignoreDuplicateTourDateDates = (newTourDates: any[]) => {
        const filteredNewTourDates = newTourDates.filter((newTourDate) => {
            return !tourDates.some((existingTourDate) =>
                existingTourDate.date === newTourDate.date && existingTourDate.venue === newTourDate.venue
            );
        });
        const sortedTourDates = sortTourDates([...tourDates, ...filteredNewTourDates])
        setTourDates(sortedTourDates);
    }

    const handleUploadCsv = async (e: ChangeEvent<HTMLInputElement> | File) => {
        try {
            let csv: File | undefined;
            if (e instanceof File) {
                csv = e;
            } else {
                const files = e.target.files;
                csv = head(files);
            }

            if (csv) {
                // Check file size (5MB = 5 * 1024 * 1024 bytes)
                if (csv.size > 5 * 1024 * 1024) {
                    setErrorCsv('File size limit is 5MB');
                    return;
                }

                const formData = new FormData();
                setErrorCsv('');
                setUploadingCsv(true);
                formData.append("csv", csv);

                const response = await Axios.post(`/website/csv/tour-dates`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const data = response.data.data;
                const tourDatesFromCsv = data.tour_dates.dateArray;
                const error = response.data.error;

                if (error) {
                    setErrorCsv('There was an error uploading your file');
                    return; // Early return to avoid nested conditions
                }

                if (tourDatesFromCsv.length === 0) {
                    setErrorCsv('We weren\'t able to find any events or tour dates found in the uploaded CSV.');
                } else {
                    setSelectedCsv(csv);
                    transformTourDatesFromCsv(tourDatesFromCsv);
                }
            }
        } catch (error) {
            setErrorCsv('An error occurred while uploading the file');
        } finally {
            setUploadingCsv(false);
            setSelectedCsv(undefined);
        }
    }

    const handleDeleteCsv = () => {
    }

    const setIsShowSoldOutInRow = (isSoldOut: boolean, index: number) => {
        const newTourDates = deepCopy(tourDates)
        newTourDates[index].isSoldOut = isSoldOut
        setTourDates(newTourDates)
    }

    const transformTourDatesFromBandsInTown = async (bandsintownTourData: any) => {
        const transformedDates = bandsintownTourData.map((tourDate: any) => ({
            city: tourDate.venue.city || '',
            country: tourDate.venue.country || '',
            date: tourDate.datetime || null,
            displayName: '',
            isPreSalePeriod: false,
            isSoldOut: tourDate.sold_out || false,
            preSaleDate: null,
            region: tourDate.venue.region || '',
            ticketUrl: tourDate.offers[0]?.url || '', // Assuming the first offer is the relevant one
            venue: tourDate.venue.name || '',
            preSaleButtonText: '',
            preSaleAction: 'collect',
            preSaleCustomLink: '',
            source: 'bandsintown',
        }));

        ignoreDuplicateTourDateDates(transformedDates)
    }

    const renderAddTourDateButton = () => {
        let addDateButtonStyle: CSSProperties = {
            marginRight: tourDates.length ? 0 : 'auto',
            marginLeft: 'auto',
        }

        if (mobileView) {
            addDateButtonStyle = {
                marginLeft: 0,
                marginTop: 10,
                marginBottom: 10,
            }
        }

        return (
            <ButtonSymphony
                className="addDateButtonTour"
                onClick={() => handleOpenTourDateModal({ isEdit: false })}
                style={addDateButtonStyle}
                variant="outlined"
            >
                Add a date
            </ButtonSymphony>
        )
    }

    const renderUploadCsvButton = () => {
        return (
            <UploadInput
                className="uploadCsvButtonTour"
                style={{ marginTop: 0, border: 'none', padding: 0 }}
                file={selectedCsv}
                maxWidth={'380px'}
                loading={uploadingCsv}
                onUpload={handleUploadCsv}
                onDelete={handleDeleteCsv}
                noBorder
                isDragDrop
            />
        )
    }

    const renderBandsintownImportButton = (connected: boolean) => {
        return (
            <div>
                <SecondaryButton
                    className="buttonBandsintownImport"
                    onClick={importBandsInTownData}
                    content={
                        <>
                            {bandsintownLoading && <LoaderV2 style={{ marginRight: 4 }} />}
                            <ButtonText>
                                {connected ? 'Refresh from ' : 'Connect '}
                            </ButtonText>
                            <img src={BandsInTownLogo} alt="BandsInTown Logo" />
                        </>
                    }
                />
                {localBandsintownUrl && localBandsintownUrl.name && (
                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#666', textAlign: 'center' }}>
                        Connected: {localBandsintownUrl.name}
                    </div>
                )}
            </div>
        )
    }

    const renderTourDateRow = ({
        tourDate,
        index,
    }: {
        tourDate: any;
        index: number;
    }) => {
        const date = tourDate.date;
        const day = dayjs(date).format('DD')
        const month = dayjs(date).format('MMM')
        const isPastDate = dayjs(date).isBefore(dayjs().subtract(1, 'day'), 'day');

        const truncateVenue = (venue: string) => {
            if (mobileView && venue.length > 16) {
                return venue.slice(0, 16) + '...';
            }
            return venue;
        };

        const renderPreSaleInfo = () => {
            if (tourDate.isPreSalePeriod && !tourDate.isSoldOut) {
                const preSaleEmoji = tourDate.preSaleAction === 'collect' ? '📧' : '🔗';
                const preSaleAction = tourDate.preSaleAction === 'collect'
                    ? 'Collecting Emails'
                    : 'Custom Link';
                const preSaleText = `${preSaleEmoji} Pre-Sale: ${preSaleAction}`;

                if (tourDate.preSaleDate) {
                    if (dayjs(tourDate.preSaleDate).isAfter(dayjs())) {
                        return `${preSaleText} until ${dayjs(tourDate.preSaleDate).format('MM/DD')}`;
                    } else if (!tourDate.ticketUrl) {
                        return `${preSaleText} - Missing ticket link`;
                    }
                }

                return preSaleText;
            }
            return null;
        };

        const renderTicketInfo = () => {
            if (tourDate.isPreSalePeriod) {
                return renderPreSaleInfo();
            }

            if (tourDate.isSoldOut) {
                return "Tickets marked sold out";
            }

            if (tourDate.ticketUrl && !isPastDate) {
                return "Tickets on sale";
            }

            if (isPastDate) {
                return null
            }

            return "No ticket link entered.";
        };

        return (
            <motion.div
                className={`tourDateRow-${index}`}
                style={{ ...tourDateRow, justifyContent: 'space-between' }}
                initial={{ backgroundColor: 'red' }}
                animate={{ backgroundColor: 'white' }}
                transition={{ duration: 5000 }}
            >
                <div className="tourDateDetail" style={tourDateDetail}>
                    <div style={tourDateMonth}>
                        {month}
                    </div>
                    <div className="previewTourDateDay" style={tourDateDay}>
                        {day}
                    </div>
                </div>

                <div style={{ flex: 1, minWidth: 0, marginRight: 16 }}>
                    {isPastDate && (
                        <div style={{
                            fontSize: '12px',
                            color: Colors.yellow,
                            marginBottom: '2px'
                        }}>
                            (Past Date)
                        </div>
                    )}
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {truncateVenue(tourDate.venue)}
                    </div>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: Colors.greyDark }}>
                        {[tourDate.city, tourDate.region].filter(Boolean).join(', ')}
                    </div>
                    {(renderPreSaleInfo() || renderTicketInfo()) && (
                        <div style={{
                            fontSize: '12px',
                            color: Colors.greyDark,
                            marginTop: '2px'
                        }}>
                            {renderPreSaleInfo() || renderTicketInfo()}
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {!mobileView && (
                        <div className={classes.secondaryTextSection} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <SwitchSymphony
                                checked={tourDate.isSoldOut}
                                onChange={() => setIsShowSoldOutInRow(!tourDate.isSoldOut, index)}
                            />
                            <div className='addSecondaryText' style={Text.secondary}>
                                Sold Out
                            </div>
                        </div>
                    )}
                    <EditIcon
                        className="editTourDate"
                        cursor='pointer'
                        onClick={() => handleEditTourDate(index)}
                        color={Colors.greyDark}
                        style={{ flexShrink: 0 }}
                    />
                    <TrashIcon
                        className="trashTourDate"
                        cursor='pointer'
                        onClick={() => handleDeleteTourDate(index)}
                        color={Colors.greyDark}
                        style={{ flexShrink: 0 }}
                    />
                </div>
            </motion.div>
        );
    }

    const renderTourDateList = () => {
        return (
            <div className="tourDateListContainer" style={tourDateListContainer}>
                {tourDates.map((tourDate, index) => (
                    renderTourDateRow({ tourDate, index })
                ))}
            </div>
        );
    }

    const TourReactDatePicker = () => {
        return (
            <ReactDatePicker
                filterDate={(date: Date) => {
                    return dayjs(date).isAfter(dayjs(), 'day') || dayjs(date).isSame(dayjs(), 'day');
                }}
                selected={currentDate ? dayjs(currentDate).toDate() : null}
                onChange={(date) => {
                    if (date) {
                        const newDate = dayjs(date).format("YYYY-MM-DD");
                        setCurrentDate(newDate);
                    }
                }}
                customInput={<input style={Text.input} type='text' placeholder='Event Date' />}
                onCalendarOpen={() => setIsDatePickerOpen(true)}
                onCalendarClose={() => setIsDatePickerOpen(false)}
            />
        )
    }

    const PreSaleDatePicker = () => {
        return (
            <ReactDatePicker
                filterDate={(date: Date) => {
                    return dayjs(date).isAfter(dayjs(), 'day') || dayjs(date).isSame(dayjs(), 'day');
                }}
                selected={preSaleDate ? dayjs(preSaleDate).toDate() : null}
                onChange={(date) => {
                    if (date) {
                        const newDate = dayjs(date).format("YYYY-MM-DD");
                        setPreSaleDate(newDate);
                    }
                }}
                customInput={
                    <input
                        style={Text.input}
                        type='text'
                        placeholder='Select a date'
                        value={preSaleDate ? dayjs(preSaleDate).format("MM/DD/YYYY") : ''}
                    />
                }
                onCalendarOpen={() => setIsPreSaleDatePickerOpen(true)}
                onCalendarClose={() => setIsPreSaleDatePickerOpen(false)}
            />
        )
    }
    const renderSaveUpdateButton = () => {
        const isDisabled = () => {
            if (!currentDate || (!venueText && !cityText)) {
                return true;
            }
            if (!ticketUrlText) {
                if (isPreSalePeriod) {
                    if (preSaleAction === 'collect' && !preSaleDate) {
                        return true;
                    }
                    if (preSaleAction === 'link' && !preSaleCustomLink) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
            if (ticketUrlError) {
                return true;
            }
            return false;
        };

        const getMissingFieldsMessage = () => {
            const missingFields = [];
            if (!currentDate) missingFields.push('Date');
            if (!venueText && !cityText) missingFields.push('Venue or City');
            if (!ticketUrlText && !isPreSalePeriod) missingFields.push('Ticket Link');
            if (isPreSalePeriod) {
                if (preSaleAction === 'collect' && !preSaleDate) missingFields.push('Pre-sale Date');
                if (preSaleAction === 'link' && !preSaleCustomLink) missingFields.push('Pre-sale Link');
            }

            if (missingFields.length === 0) return '';
            return `Please fill in the following required fields: ${missingFields.join(', ')}`;
        };

        return (
            <div
                style={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    paddingTop: 16,
                    paddingBottom: 16,
                    backgroundColor: Colors.PAPER_CONTAINER,
                    borderTop: `1px solid ${Colors.divider}`,
                }}
            >
                {isDisabled() && (
                    <Container padding="8px 16px" display="flex" flexDirection="column" alignItems="center" >
                        <Body2 color={Colors.yellow} style={{ textAlign: 'center', marginBottom: '4px' }}>
                            {getMissingFieldsMessage()}
                        </Body2>
                        {!ticketUrlText && !isPreSalePeriod && (
                            <Body2 color={Colors.yellow} style={{ textAlign: 'center' }}>
                                You can either add a ticket link or set up a pre-sale.
                            </Body2>
                        )}
                    </Container>
                )}
                <ButtonSymphony
                    className='addTourDateButton'
                    onClick={() => handleAddEditTourDate(tourIndex)}
                    width='100%'
                    disabled={isDisabled()}
                >
                    {isEditTourDate ? 'Update' : 'Save'}
                </ButtonSymphony>
            </div>
        )
    }

    const renderEventDetailsTab = () => {
        const validateTicketUrl = (url: string) => {
            if (url && !isValidUrl(url)) {
                setTicketUrlError('Please enter a valid URL (e.g., https://example.com)');
            } else {
                setTicketUrlError('');
            }
        };

        return (
            <Container display="flex" flexDirection="column" gap="24px">
                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={Colors.textColor}>
                        Event Date
                    </Body2>
                    <Container marginTop="8px">
                        <TourReactDatePicker />
                    </Container>
                </Container>

                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={Colors.textColor}>
                        Ticket Link
                    </Body2>
                    <Container marginTop="8px">
                        <input
                            style={{
                                ...Text.input,
                                borderColor: ticketUrlError ? Colors.red : undefined,
                            }}
                            placeholder='https://ticketmaster.com/xyz'
                            value={ticketUrlText}
                            onChange={(e) => {
                                setTicketUrlText(e.target.value);
                                validateTicketUrl(e.target.value);
                            }}
                            onBlur={() => validateTicketUrl(ticketUrlText)}
                        />
                        {ticketUrlError && (
                            <Caption color={Colors.red} style={{ marginTop: '4px' }}>
                                {ticketUrlError}
                            </Caption>
                        )}
                    </Container>
                </Container>

                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={Colors.textColor}>
                        Action Button Text
                    </Body2>
                    <Container marginTop="8px">
                        <input
                            style={Text.input}
                            placeholder='Buy Now'
                            value={buttonLinkText}
                            onChange={(e) => setButtonLinkText(e.target.value)}
                            maxLength={30}
                        />
                    </Container>
                </Container>

                <Container display="flex" flexDirection="column" gap="4px">
                    <Body2 color={Colors.textColor}>
                        Location
                    </Body2>
                    <Caption color={Colors.greyDark}>
                        Enter any or all of the location details around your event - we'll automatically format it. A venue or city is required.
                    </Caption>
                    <Container display="flex" flexDirection="column" gap="8px" marginTop="8px">
                        <Autocomplete
                            options={googleSearchResults}
                            getOptionLabel={(option: any) => option.name || ''}
                            renderOption={(props, option: any) => {
                                const { name, city, state, country } = props;
                                let locationString = '';
                                if (city) locationString += city;
                                if (state) locationString += (locationString ? ', ' : '') + state;
                                if (country) locationString += (locationString ? ', ' : '') + country;

                                return (
                                    <li {...props}>
                                        <div style={{ fontFamily: 'DIN' }}>
                                            {name && <div>{name}</div>}
                                            {locationString && (
                                                <div style={{ fontSize: '0.8em', color: 'gray' }}>
                                                    {locationString}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            }}
                            onChange={(event, newValue: any) => handleVenueSelection(newValue)}
                            inputValue={venueInputValue}
                            onInputChange={(event, newInputValue) => handleVenueInputChange(newInputValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Venue Name'
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            ...Text.input,
                                            padding: 0,
                                            fontFamily: 'DIN',
                                        },
                                        disableUnderline: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            ...Text.input,
                                            fontFamily: 'DIN',
                                        },
                                    }}
                                />
                            )}
                            freeSolo
                            value={selectedVenue}
                            open={showAutocomplete}
                            onClose={() => setShowAutocomplete(false)}
                            filterOptions={(x) => x}
                        />
                        <input
                            style={Text.input}
                            placeholder='City'
                            value={cityText}
                            onChange={(e) => setCityText(e.target.value)}
                        />
                        <input
                            style={Text.input}
                            placeholder='State / Region'
                            value={regionText}
                            onChange={(e) => setRegionText(e.target.value)}
                        />
                        <input
                            style={Text.input}
                            placeholder='Country'
                            value={countryText}
                            onChange={(e) => setCountryText(e.target.value)}
                        />
                    </Container>
                </Container>

                <Container display="flex" alignItems="center" gap="8px">
                    <SwitchSymphony
                        checked={isShowSoldOut}
                        onChange={() => setIsShowSoldOut(!isShowSoldOut)}
                    />
                    <div className='addSecondaryText' style={Text.secondary}>
                        Mark as Sold Out
                    </div>
                </Container>

                {renderSaveUpdateButton()}
            </Container>
        )
    }

    const renderDateCustomizationTab = () => {
        return (
            <Container display="flex" flexDirection="column" gap="24px">
                <Container display="flex" alignItems="center" gap="8px">
                    <SwitchSymphony
                        checked={isPreSalePeriod}
                        onChange={() => setIsPreSalePeriod(!isPreSalePeriod)}
                    />
                    <Body2 color={Colors.textColor}>
                        Add Pre-sale Period
                    </Body2>
                </Container>

                {isPreSalePeriod && (
                    <>
                        <Container display="flex" flexDirection="column" gap="4px">
                            <Body2 color={Colors.textColor}>
                                Pre-sale Actions
                            </Body2>
                            <Container display="flex" flexDirection="column" gap="8px" marginTop="8px">
                                <div className={classes.radioButtonContainer}>
                                    <input
                                        type="radio"
                                        id="collect"
                                        name="preSaleAction"
                                        value="collect"
                                        checked={preSaleAction === 'collect'}
                                        onChange={() => setPreSaleAction('collect')}
                                        style={purpleRadioStyle}
                                    />
                                    <label htmlFor="collect" style={{ color: Colors.greyDark }}>Collect emails & numbers</label>
                                </div>
                                <div className={classes.radioButtonContainer}>
                                    <input
                                        type="radio"
                                        id="link"
                                        name="preSaleAction"
                                        value="link"
                                        checked={preSaleAction === 'link'}
                                        onChange={() => setPreSaleAction('link')}
                                        style={purpleRadioStyle}
                                    />
                                    <label htmlFor="link" style={{ color: Colors.greyDark }}>Open a custom link</label>
                                </div>
                            </Container>
                        </Container>

                        {preSaleAction === 'link' && (
                            <Container display="flex" flexDirection="column" gap="4px">
                                <Body2 color={Colors.textColor}>
                                    Custom Link
                                </Body2>
                                <Caption color={Colors.greyDark}>
                                    When a fan clicks on your pre-sale button, they'll be sent to this link
                                </Caption>
                                <Container marginTop="8px">
                                    <input
                                        style={Text.input}
                                        placeholder='Enter custom link'
                                        value={preSaleCustomLink}
                                        onChange={(e) => setPreSaleLinkText(e.target.value)}
                                    />
                                </Container>
                            </Container>
                        )}

                        <Container display="flex" flexDirection="column" gap="4px">
                            <Body2 color={Colors.textColor}>
                                Pre-sale End Date
                            </Body2>
                            <Caption color={Colors.greyDark}>
                                Once your pre-sale date passes, your ticket link will be shown to fans. If your ticket link is empty, we'll continue showing pre-sale until you enter it in.
                            </Caption>
                            <Container marginTop="8px">
                                <PreSaleDatePicker />
                            </Container>
                        </Container>

                        <Container display="flex" flexDirection="column" gap="4px">
                            <Body2 color={Colors.textColor}>
                                Pre-sale Button Text
                            </Body2>
                            <Caption color={Colors.greyDark}>
                                Customize pre-sale button text (max 10 characters)
                            </Caption>
                            <Container marginTop="8px">
                                <input
                                    style={Text.input}
                                    placeholder='Signup'
                                    value={preSaleButtonText}
                                    onChange={(e) => setPreSaleButtonText(e.target.value.slice(0, 10))}
                                    maxLength={10}
                                />
                            </Container>
                        </Container>
                    </>
                )}

                {renderSaveUpdateButton()}
            </Container>
        )
    }

    const renderTourDateModal = () => {
        return (
            <ModalSymphony
                isOpen={isTourDateModalOpen}
                onClose={() => setIsTourDateModalOpen(false)}
                title="Add a tour date"
                subtitle="Add details for an event to display on your website. This can be a one-off event or a tour stop."
                fullScreen={mobileView}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', position: 'relative' }}>
                    <Container borderRadius="12px" backgroundColor={Colors.PAPER_CONTAINER} display="flex" alignItems="center" justifyContent="space-between">
                        <SymphonyTabs
                            className='symphonyTabsTourSetupSection'
                            data={[
                                { label: 'Event Details', value: 'event_details' },
                                { label: 'Date Customization', value: 'date_customization' },
                            ]}
                            selectedValue={selectedTab!}
                            onChange={(newValue: string) => setSelectedTab(newValue as 'event_details' | 'date_customization')}
                            gap={16}
                            tabContainerStyle={{ paddingRight: 0, paddingLeft: 0 }}
                            tabContentStyle={{ paddingRight: 0, paddingLeft: 0 }}
                        />
                    </Container>
                    {selectedTab === 'event_details' &&
                        renderEventDetailsTab()
                    }
                    {selectedTab === 'date_customization' &&
                        renderDateCustomizationTab()
                    }
                </div>
            </ModalSymphony>
        );
    }

    const renderBandsintownImportModal = () => {
        return (
            <BandsInTownConnect
                isOpen={isBandsintownImportSectionOpen}
                closeModal={() => setIsBandsintownImportSectionOpen(false)}
                selected={currentBrand}
                artist={currentBrand}
                localSave={(data: any) => {
                    setLocalBandsintownUrl(data)
                    reloadBrand()
                }}
                getDataCallback={transformTourDatesFromBandsInTown}
            />
        )
    }

    const renderImportEventsDropdown = () => {
        return (
            <>
                <div
                    className="importEventsDropdownContainer"
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <div style={{ color: Colors.greyDark }}>
                        Events
                    </div>
                    <TextButton
                        onClick={() => setIsImportEventDropdown(!isImportEventDropdown)}
                    >
                        <div
                            className="importEventsDropdownButton"
                            style={{ transform: `translateY(1px)` }}
                        >
                            Import Events
                        </div>
                        <div className="ml-1">
                            <DropdownArrow
                                color="#8800FF"
                                width={16}
                                height={16}
                                direction={isImportEventDropdown ? "up" : "down"}
                            />
                        </div>
                    </TextButton>
                </div>
                {renderBorderSeparator()}

                {isImportEventDropdown && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: isImportEventDropdown ? 1 : 0, height: isImportEventDropdown ? 'auto' : 0 }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="importEventsDropdownContent" style={{ marginBottom: 24 }}>
                            <div
                                style={{ ...websiteSubSectionContainer, flexDirection: 'column' }}
                            >
                                <div style={{ display: 'flex', flexDirection: mobileView ? 'column' : 'row', gap: '16px' }}>
                                    {/* {renderBandsintownImportButton(userHasBandsintownConnected)} */}
                                    {renderUploadCsvButton()}
                                </div>
                                {renderTourDateUploadErrorMessage()}
                            </div>
                        </div>
                    </motion.div>
                )}
            </>
        )
    }

    const renderTourDateUploadErrorMessage = () => {
        if (!errorCsv) return null;
        return (
            <div className="tourDateUploadErrorMessage" style={{ marginTop: 10 }}>
                <div style={{ color: 'red' }}>{errorCsv}</div>
            </div>
        )
    }

    // NOTE: render function
    return (
        <div className={`tourSetupSectionComponent ${classes.customizeWebsiteV2Variable}`}>
            <div className='w-full flex flex-col gap-4'>
                <WebsiteEditorCard title='Title' className="tourTitle">
                    <input
                        style={Text.input}
                        type='text'
                        value={titleText}
                        placeholder='Title'
                        onChange={(e) => setTitleText(e.target.value)}
                    />
                    <div className={classes.secondaryTextSection}>
                        <SwitchSymphony
                            checked={showSecondaryLine}
                            onChange={() => setShowSecondaryLine(!showSecondaryLine)}
                            label="Add secondary line"
                        />
                    </div>
                    {showSecondaryLine && (
                        <input
                            style={Text.input}
                            type='text'
                            value={subtitle}
                            placeholder='Secondary line'
                            onChange={(e) => setSubtitle(e.target.value)}
                        />
                    )}
                </WebsiteEditorCard>
                <WebsiteEditorCard title='Setup your Events' className='setupYourTourTitle'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row' }}>
                        <Body2
                            color={Colors.textColor}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            Add one or more event or tour dates to showcase
                        </Body2>

                        {tourDates.length !== 0 && renderAddTourDateButton()}
                    </div>

                    {tourDates.length !== 0 && renderImportEventsDropdown()}

                    {!tourDates.length &&
                        <div className='tourDateContainer' style={websiteSubSectionContainer}>
                            <Body2 color={Colors.greyDark} style={{ textAlign: 'center' }}>
                                You don't have any events added yet.<br />Get started with your first event.
                            </Body2>
                            <div style={{ ...tourDateAddUploadContainer, flexDirection: mobileView ? 'column' : 'row' }}>
                                {renderAddTourDateButton()}
                                {renderUploadCsvButton()}
                            </div>

                            {/* <div>
                                {renderBandsintownImportButton(userHasBandsintownConnected)}
                            </div> */}

                            {errorCsv && renderTourDateUploadErrorMessage()}
                        </div>
                    }

                    {renderTourDateList()}
                </WebsiteEditorCard>
                <WebsiteEditorCard title='Additional Options' className="additionalOptionsTour">
                    <div className={classes.secondaryTextSection}>
                        <SwitchSymphony
                            checked={hidePastTourDates}
                            onChange={() => setHidePastTourDates(!hidePastTourDates)}
                            className={clsx(hidePastTourDates && classes.switchEnabled)}
                            label="Hide past dates from your tour page"
                        />
                    </div>
                </WebsiteEditorCard>
            </div>

            {renderTourDateModal()}
            {renderBandsintownImportModal()}
        </div>
    )
}