import { SpotifyArtist } from "types/global";
import { Fragment } from "react";
import {
  ArtistList as StyledArtistList,
  ArtistListLine
} from "./styles";
import ArtistItem from "./ArtistItem/index";
import Grid from "@material-ui/core/Grid";
import NotFoundArtistLabel from "pages/post-auth/AddArtist/Components/NotFoundArtistLabel";

interface Props {
  loading?: boolean;
  artists: SpotifyArtist[];
  selectedArtist: SpotifyArtist | null;
  isMobile?: boolean;
  selectArtist: (artist: SpotifyArtist) => void;
}

const ArtistList = ({
  loading,
  artists,
  selectedArtist,
  isMobile,
  selectArtist,
}: Props) => {

  const renderedList: React.ReactElement[] = []

  artists.map((artist, index) => {
    renderedList.push(
      <Fragment key={artist.id}>
        <ArtistItem
          disableSelect={loading}
          loading={loading}
          isSelected={
            selectedArtist ? selectedArtist?.id === artist.id : loading
          }
          artist={artist}
          selectArtist={selectArtist}
          buttonLabel="Connect"
        />
        <ArtistListLine />
      </Fragment>
    )
  })


  return (
    <StyledArtistList gap={isMobile ? "4px" : "8px"}>
      {renderedList}
    </StyledArtistList>
  )
}

export default ArtistList
