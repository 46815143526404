import { useEffect } from "react";
import { Subtitle2 } from "components/shareable/Typography";
import { SHARED_WEBSITE_STYLES, WebsiteEditorCard } from "pages/post-auth/Website/styles"
import { useStyles } from "../../styles";
import { TextField } from "@material-ui/core";
import Text from 'modules/Text';
import { useContext, useState } from "react";
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { deepCopy } from "modules/Utils";
import SecondaryButton from "components/shareable/SecondaryButton";
import LinkButton from "components/shareable/LinkButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FieldsList } from '../FieldsList';
import { clsx } from "clsx";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { PlanType } from "modules/Const";
import SwitchSymphony from "components/shareable/SwitchSymphony";

const initFieldItem = {
  id: "custom",
  name: "",
  required: false,
  priority: 0,
  reserved: false,
}

const fieldItems: Record<string, { key: string, title: string, label: string, isProOnly: boolean }> = {
  email_number: {
    key: "email_number",
    title: "Email - Number",
    label: "What's your email/number?",
    isProOnly: false,
  },
  name: {
    key: "name",
    title: "Name",
    label: "What's your name?",
    isProOnly: false,
  },
  age: {
    key: "age",
    title: "Age",
    label: "What's your age?",
    isProOnly: true,
  },
  email: {
    key: "email",
    title: "Email",
    label: "What's your email?",
    isProOnly: true,
  },
  phone_number: {
    key: "phone_number",
    title: "Phone Number",
    label: "What's your number?",
    isProOnly: true,
  },
  custom: {
    key: "custom",
    title: "Custom",
    label: '',
    isProOnly: true,
  }
}

const upgradeToProChip = () => <UpgradeToProChip
  type="gem"
  color="purple"
  height={20}
  width={20}
/>

export const DataCollectorSetupSection = () => {
  const classes = useStyles();
  const { isProBrand } = useContext(CurrentBrandContext)
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const sharedClasses = SHARED_WEBSITE_STYLES();

  const {
    titleText, setTitleText,
    subtitle, setSubtitle,
    showSecondaryLine, setShowSecondaryLine,
    fields = [], setFields,
    submitButtonText, setSubmitButtonText,
  } = useContext(WebsiteContext)

  const [addNewField, setAddNewField] = useState<boolean>(false)
  const [newFieldText, setNewFieldText] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const titleMessages = [
    "Join my exclusive fan club!",
    "Sign up for behind-the-scenes content",
    "Get early access to new releases",
    "Be part of our music community",
    "Subscribe for exclusive updates",
    "Join the inner circle",
    "Unlock VIP fan experiences",
    "Stay connected with the latest news",
    "Become an insider - sign up now",
    "Get on the list for special perks"
  ];

  useEffect(() => {
    if (!titleText) {
      const randomIndex = Math.floor(Math.random() * titleMessages.length);
      setTitleText(titleMessages[randomIndex]);
    }
  }, []);

  const toggleIsRequiredField = (index: number, isRequired: boolean) => {
    if (!fields?.length) return
    const newFields = deepCopy(fields)
    newFields[index].required = isRequired
    setFields(newFields)
  }


  const saveNewField = (id: string, label: string) => {
    if (fields?.length) {
      const newFields = deepCopy(fields)
      newFields.push({
        ...initFieldItem,
        id,
        label,
      })
      setFields(newFields)
    }
    else {
      const newFields = [
        {
          ...initFieldItem,
          id,
          label,
        }
      ]
      setFields(newFields)
    }
    setAddNewField(false)
    setNewFieldText('')
  }

  const deleteField = (index: number) => {
    if (!fields?.length) return
    const newFields = deepCopy(fields)
    newFields.splice(index, 1)
    setFields(newFields)
  }

  const addNewFieldItem = (type: string) => {
    if (type === 'custom') setAddNewField(true)
    else saveNewField(type, fieldItems[type].label)
    setAnchorEl(null)
  }

  const handleAddNewFieldItem = (field: string) => {
    if (!isProBrand && fieldItems[field].isProOnly) {
      handleOpenUpgradeToProModal({
        upgradeCallToAction: "Unlock unlimited customizations with Symphony Lite!",
        highlightedPlan: PlanType.LITE,
        source: "Website Editor - Data Collector Add Custom Field"
      })
    } else addNewFieldItem(field)
  }

  const renderFieldItemList = () => {
    return Object.keys(fieldItems).map((key) => {
      const disabled = Boolean(fields.find(field => field.id === fieldItems[key].key) && fieldItems[key].key !== 'custom')
      return (
        <MenuItem
          key={key}
          onClick={() => handleAddNewFieldItem(key)}
          disabled={disabled}
        >
          {fieldItems[key].title}
          {fieldItems[key].isProOnly && upgradeToProChip()}
        </MenuItem>
      )
    })
  }

  // NOTE: render function
  return (
    <div className={`dataCollectorSetupSectionComponent ${classes.customizeWebsiteV2Variable}`}>
      <div className='w-full flex flex-col gap-4'>
        <WebsiteEditorCard title='Title'>
          <input
            style={Text.input}
            type='text'
            value={titleText}
            placeholder='Title'
            onChange={(e) => setTitleText(e.target.value)}
          />
          <div className={classes.secondaryTextSection}>
            <SwitchSymphony
              checked={showSecondaryLine}
              onChange={() => setShowSecondaryLine(!showSecondaryLine)}
              className={clsx(showSecondaryLine && classes.switchEnabled)}
              label="Add secondary line"
            />
          </div>
          {showSecondaryLine && (
            <input
              style={Text.input}
              type='text'
              value={subtitle}
              placeholder='Secondary line'
              onChange={(e) => setSubtitle(e.target.value)}
            />
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard title='Form Elements'>
          <FieldsList
            deleteField={deleteField}
            toggleIsRequiredField={toggleIsRequiredField}
          />
          {!addNewField ? (
            <div className='flex justify-center'>
              <LinkButton
                text='+ Add New Field'
                onClick={(e) => {
                  setAnchorEl(e.currentTarget)
                }}
              />
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={classes.fieldsMenu}
              >
                {renderFieldItemList()}
              </Menu>
            </div>
          ) : (
            <div className='flex flex-col gap-4'>
              <div
                className={sharedClasses.innerPanelSection}
                style={{ display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 0 }}
              >
                <Subtitle2>Add a Custom Field</Subtitle2>
                <Subtitle2>What info do you want to collect?</Subtitle2>
                <TextField
                  variant='outlined'
                  inputProps={{ className: classes.socialMediaInput }}
                  value={newFieldText}
                  placeholder={'i.e. What\'s your favorite song?'}
                  onChange={(e) => setNewFieldText(e.target.value)}
                />
              </div>
              <div className='flex gap-2'>
                <SecondaryButton
                  className='buttonCancelNewField'
                  text='Cancel'
                  onClick={() => setAddNewField(false)}
                />
                <SecondaryButton
                  className='buttonSaveNewField'
                  text='Save'
                  onClick={() => saveNewField('custom', newFieldText)}
                  disabled={!newFieldText.length}
                />
              </div>
            </div>
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard title='Submit Button Text'>
          <TextField
            variant='outlined'
            inputProps={{ className: clsx(classes.socialMediaInput, classes.submitButtonInput) }}
            value={submitButtonText}
            placeholder='Subscribe'
            onChange={(e) => setSubmitButtonText(e.target.value)}
          />
        </WebsiteEditorCard>
      </div>
    </div>
  )
}