import { useState, useEffect, useContext, SetStateAction, Dispatch } from 'react'
import { GEOGRAPHY_TARGETING_DEFAULTS, TABS } from "modules/Const";
import { MarketingDataContext } from "../../Data/Provider";
import { AgeRange, FBCustomAudience, FacebookPageResponse, FbSuggestion  } from 'types/global'
import { getNotRemovableOptions } from '../TargetingView/Components/AutoCompleteAudiences/utils';
import uniqBy from 'lodash/uniqBy'
import { confirmConnectionSettings } from "./api";
import DetailsCard from "./Components/PreviewAdsCard";
import DurationCard from "./Components/DurationCard";
import BudgetCard from "../../Components/BudgetCardSummary";
import TargetingCard from "./Components/TargetingCard";
import PreConnectionConfigsCard from './Components/PreConnectionConfigsCard';
import { fixGeographicTargets } from "modules/Utils";
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
    PreConnectionConfigs,
    getFormattedDemographics,
    validateTargetingCountriesAge,
    removeCountries as removeCountriesUtils,
    distributeBudget
} from '../../utils/fbCampaigns';
import useModal from 'Hooks/useModal';
import TargetingWarning from '../../Components/TargetingWarningModal';
import { AgeRestrictedCountry } from '../../constants'
import { getTargetingViewGenre } from '../../Components/TargetingView/utils';
import FacebookCampaignGoLiveCard from '../../Components/GoLiveCard';
import { CampaignInput } from '../../Components/GoLiveCard/types';
import { ConnectionsContext } from '../../hooks/ConnectionsContext';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

const NOT_REMOVABLE = "NOT_REMOVABLE";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FinalizationProps {
    goal: string;
    assets: object;
    captions: object;
    budget: number;
    startDate: any;
    endDate: any;
    record: any;
    cta: Record<string, unknown>;
    caption: string;
    selectedLink: string;
    campaignName: string;
    countryTargetingBreakdown: any;
    setCountryTargetingBreakdown: (breakdown: any) => void;
    interestTargetingAudiences: any;
    setInterestTargetingAudiences: (audiences: any) => void;
    setSelectedTab: (tab: string) => void;
    setShowTargetingView: Dispatch<SetStateAction<boolean>>;
    fbSuggestions: FbSuggestion[];
    igMedia: Record<string, unknown>;
    draft: string | null;
    cancelSaving: () => void;
    handleSaveTargetingData: () => void;
    customAudiences: FBCustomAudience[];
    additionalPageAudiences: FacebookPageResponse[];
}

const Finalization = ({
    isBelowRecommendedBudget,
    budget,
    startDate,
    endDate,
    setSelectedTab,
    allowCampaignCreation,
    setShowTargetingView,
    fbSuggestions,
    selectedLink,
    goal,
    cta,
    caption,
    campaignName,
    assets,
    igMedia,
    draft,
    addVisualSelected,
    cancelSaving,
    handleSaveTargetingData,
    customAudiences,
    additionalPageAudiences,
    primaryOrganization
}: any) => {
    const theme = useTheme()
    const { currentBrand, isProBrand } = useContext(CurrentBrandContext);
    const {
        facebookAdAccount: fbAdAccount,
        facebookPage: fbPage,
        instagramPage: igPage,
        facebookPixel: fbPixel,
        conversionsEnabled,
        conversionsEventName: conversionsEvent,
    } = useContext(ConnectionsContext)

    const isMobile = useMediaQuery(theme.breakpoints.down(769))
    const {
        targets: targetingViewGeographicTargets,
        audiences: targetingViewAudiences,
        demographics: targetingDemographics,
        saveDemographics,
    } = useContext(MarketingDataContext)

    const targetingWarningModal = useModal()

    const [loadingStep, setLoadingStep] = useState(3)
    const [preconnectionConfigurations, setPreconnectConfigurations] = useState<PreConnectionConfigs | null>(null)
    const [creatingCampaign, setCreatingCampaign] = useState(false)
    const [countriesToFix, setCountriesToFix] = useState<AgeRestrictedCountry[]>([])

    const genres = currentBrand?.connections?.spotify_artist_page?.genres;
    const genre = getTargetingViewGenre(genres);
    const notRemovableOptions = getNotRemovableOptions(genre);
    const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id");
    const targetAudiences = !!targetingViewAudiences.length ? targetingViewAudiences : initialTargetingAudiences;
    const geographicTargets = !!targetingViewGeographicTargets.length ? targetingViewGeographicTargets : GEOGRAPHY_TARGETING_DEFAULTS;
    const fixedGeographicTargets = !!targetingViewGeographicTargets.length ? fixGeographicTargets(targetingViewGeographicTargets) : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS);
    const interestTargetingAudiences = targetAudiences
        .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
        .map(({ id, name }) => ({
            fbId: id,
            name,
        }));

    const campaignInput: CampaignInput = {
        campaign_type: "increase_link_clicks",
        id: draft,
        record: null,
        captions: null,
        assets,
        goal,
        link: selectedLink,
        caption,
        cta,
        campaignName,
        fbAdAccount,
        fbPixel,
        fbPage,
        igPage,
        igMedia,
        budget,
        startDate,
        endDate,
        interestTargetingAudiences,
        targetedCountries: fixedGeographicTargets,
        targetingDemographics,
        addVisualSelected,
        conversionsEnabled,
        conversionsEventName: conversionsEvent,
        brand: currentBrand,
    };

    const handleClickPreviewAds = () => setSelectedTab(TABS.CREATIVE);
    const handleImproveAudienceTargeting = () => setShowTargetingView(true);
    const handleEditGeographicTargeting = () => setShowTargetingView(true);
    const handleOnErrorPayPalOrder = () => setCreatingCampaign(false)
    const handleClickRefreshButton = () => confirmConnectionSettings({
        fbAdAccount, setPreconnectConfigurations
    })
    const validateCountriesToFix = () => {
        if (countriesToFix.length) {
            targetingWarningModal.openModal()
            return false
        }
        return true
    }

    const updateAgeRange = (ageRange: AgeRange) => {
        const { min: minAge, max: maxAge } = ageRange
        const { gender, languages: locales } = targetingDemographics
        const demographics = getFormattedDemographics({ minAge, maxAge, gender, locales })
        saveDemographics(demographics)
        targetingWarningModal.closeModal()
        setCountriesToFix([])
    }

    const removeCountries = () => {
        const newGeographicTargets = removeCountriesUtils(geographicTargets, countriesToFix)
        distributeBudget(newGeographicTargets)

        handleSaveTargetingData()
        setCountriesToFix([])
        targetingWarningModal.closeModal()
    }

    useEffect(() => {
        const countries = validateTargetingCountriesAge(geographicTargets)
        const hasInvalidAge = countries.find(country => targetingDemographics?.age?.min < country.age)
        if (hasInvalidAge) {
            setCountriesToFix(countries)
            targetingWarningModal.openModal()
        }
    }, [geographicTargets, targetingDemographics.age])

    return (
        <div style={{ backgroundColor: "#f5f3f6", minHeight: "300px" }}>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <DetailsCard
                    {...{ conversionsEnabled, conversionsEvent }}
                    link={selectedLink}
                    handleClick={handleClickPreviewAds}
                />
            </Grid>
            {!preconnectionConfigurations || preconnectionConfigurations && !preconnectionConfigurations.status && (
                <Grid className={isMobile ? "mb-0" : "mb-4"}>
                    <PreConnectionConfigsCard
                        {...{
                            handleClickRefreshButton
                        }}
                        configs={preconnectionConfigurations}
                    />
                </Grid>
            )}
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <DurationCard {...{ startDate, endDate }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <TargetingCard
                    {...{
                        isBelowRecommendedBudget,
                        loadingStep,
                        targetAudiences,
                        customAudiences,
                        geographicTargets,
                        demographicTargets: targetingDemographics,
                        saveDemographics,
                        handleImproveAudienceTargeting,
                        handleEditGeographicTargeting,
                        openTargetingWarningModal: targetingWarningModal.openModal,
                        additionalPageAudiences
                    }}
                />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <BudgetCard
                    budget={budget}
                    adAccountName={fbAdAccount ? fbAdAccount.name : ""}
                />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <FacebookCampaignGoLiveCard
                    budget={budget}
                    creatingCampaign={creatingCampaign}
                    campaignInput={campaignInput}
                    allowCampaignCreation={allowCampaignCreation}
                    setCreatingCampaign={setCreatingCampaign}
                    cancelSaving={cancelSaving}
                    validateCountriesToFix={validateCountriesToFix}
                    submissionName={`$${budget.toLocaleString("en")} FB/IG Campaign for "${currentBrand?.name}"`}
                    submissionDescription={`"${currentBrand?.name}" (Increase Link Clicks)`}
                    checkout_source="Increase Link Clicks - Campaign Creation"
                    primaryOrganization={primaryOrganization}
                />
            </Grid>
            {Boolean(countriesToFix.length) && <TargetingWarning
                open={targetingWarningModal.open}
                closeModal={targetingWarningModal.closeModal}
                countries={countriesToFix}
                currentAgeRange={{
                    min: targetingDemographics?.age?.min || 0,
                    max: targetingDemographics?.age?.max || 0
                }}
                removeCountries={removeCountries}
                updateAgeRange={updateAgeRange}
            />}
        </div>
    )
}

export default Finalization;
