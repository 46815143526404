/* eslint-disable react-hooks/exhaustive-deps */
import SuccessInfoLabel from "components/shareable/SuccessInfoLabel";
import {
  StyledLabel,
  OpenRateIndicatorsContainer,
  InfoLabelContainer,
  Icon,
  RoundContainer,
  IconContainer,
  LinearProgressContainer,
} from "../../styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "components/shareable/CustomLinearProgress";
import { Section } from "../../../MessageYourFans/styles";
import FireIcon from "assets/images/fire.svg";
import useStyles from "../../styles";

const RoundedFlameIcon = (
  <RoundContainer width={32} height={32}>
    <IconContainer>
      <Icon alt="fire.svg" src={FireIcon} />
    </IconContainer>
  </RoundContainer>
);

interface Props {
  openAverage: number;
  openingRate: number;
  openingRatesRelation: number;
  indicators: {
    id: string;
    label: string;
    value: number;
    icon: string;
  }[];
}

const IndicatorsSection = ({
  openAverage,
  openingRate,
  openingRatesRelation,
  indicators,
}: Props) => {
  const classes = useStyles();

  return (
    <Section display="flex" flexDirection="column" alignItems="flex-start">
      <OpenRateIndicatorsContainer>
        <Grid container direction="row" alignItems="center">
          <Grid className={classes.highWidth} item xs>
            <Grid container spacing={1}>
              <Grid className={classes.highWidth} item xs>
                <StyledLabel
                  fontSize={54}
                  fontWeight={600}
                  lineHeight="initial"
                >
                  {openingRate}
                </StyledLabel>
              </Grid>
              <Grid className={classes.midWidth} item xs>
                <StyledLabel paddingTop={15} fontSize={20} fontWeight={600}>
                  %
                </StyledLabel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <LinearProgressContainer>
              <LinearProgress
                progress={openingRate}
                indicatorValue={openAverage}
                maxValue={openingRatesRelation}
              />
            </LinearProgressContainer>
          </Grid>
        </Grid>
        <StyledLabel paddingLeft={9} color="#707070">
          Open rate
        </StyledLabel>
      </OpenRateIndicatorsContainer>
      {openingRate >= openAverage && (
        <InfoLabelContainer>
          <SuccessInfoLabel
            labelStyles={{
              paddingLeft: 8,
            }}
            label={`Your open rate is on fire! The average for this kind of campaign is around ${openAverage}%`}
            icon={RoundedFlameIcon}
          />
        </InfoLabelContainer>
      )}
      <Grid className={classes.topSpaced} container alignItems="center">
        {indicators.map(({ id, label, value, icon }) => (
          <Grid key={id} item xs>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <Icon src={icon} />
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <StyledLabel>{label}</StyledLabel>
                  </Grid>
                  <Grid item xs>
                    <StyledLabel fontSize={32} fontWeight={500}>
                      {value}
                    </StyledLabel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default IndicatorsSection;
