import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import { clsx } from "modules/Utils";

interface LinkSelectorCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const LinkSelectorCampaignView = ({
    className = '',
    style = {}
}: LinkSelectorCampaignViewProps) => {
    const { campaign } = useCampaign();

    return (
        <div
            className={clsx("linkSelectorCampaignViewComponent", className)}
            style={{ ...sty.linkSelectorCampaignViewComponent, ...style }}
        >
            LinkSelectorCampaignView
        </div>
    )
}

const sty = {
    linkSelectorCampaignViewComponent: {
        width: '100%',
        height: '100%',
    }
}

export default LinkSelectorCampaignView;