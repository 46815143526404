import Axios from "helpers/Interceptor";
import * as Sentry from "@sentry/react";
import { getFanbaseParams } from './constants';

export const getEmailCampaigns = async (brandId: number | undefined) => {
  try {
    if (!brandId) {
      throw new Error("Brand ID is required");
    }

    const response = await Axios.get(`/brand/${brandId}/email-campaigns`);
    const data = response?.data?.data;

    return { data, error: !data };
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
    return { data: [], error: true };
  }
};


//TODO: Add platform and location find criteria to this function
export const getFanbase = async (
  brandId: number,
  params?: getFanbaseParams,
) => {
  try {
    const response = await Axios.get(`brand/${brandId}/fanbase`, {
      params, timeout: 120000
    });

    const data = response?.data?.data;
    return { data, error: !data };
  } catch (error) {
    Sentry.captureException(error);
    console.log("error: ", error);
    return { data: [], error: true };
  }
};

export const sendEmailTemplateData = async (
  brandId: number,
  dataToSend: {
    asap: boolean;
    content: {
      html_content: string;
      subject_line: string;
    };
    release_date?: string;
    email_fanbase: {
      targets: string[];
      sentTo: number;
    };
  }
) => {
  try {
    const url = `campaign/brand/${brandId}/email`;
    const response = await Axios.post(url, dataToSend);
    const data = response?.data?.data;

    return { data, error: !data };
  } catch (error) {
    Sentry.captureException(error);
    console.log("error: ", error);
    return { data: {}, error: true };
  }
};

export const getCampaignDetailsData = async (campaignId: number) => {
  try {
    const url = `campaign/${campaignId}/email`;
    const response = await Axios.get(url);
    const data = response?.data?.data;

    return { data, error: !data };
  } catch (error) {
    Sentry.captureException(error);
    return { data: {}, error: true };
  }
};

export const getCsvHeaders = async (brandId: number) => {
  try {
    const response = await Axios.get(`/brand/${brandId}/csv-headers`);
    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const uploadCsv = async (brandId: number, formData: FormData) => {
  try {
    const response = await Axios.post(`/brand/${brandId}/csv`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const getFansPreview = async (
  brandId: number,
  params: {
    page?: number;
    mappedHeaders?: {
      Names?: string;
      Email?: string;
      Source?: string;
      Tag?: string;
      City?: string;
      DateCollected?: string;
    };
    first?: number;
    source: string;
    email_list?: string;
  }
) => {
  try {
    const response = await Axios.get(`/brand/${brandId}/fans-preview`, {
      params,
    });
    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const importFans = async (
  brandId: number,
  dataToSend: {
    email_list?: string;
    mappedHeaders?: {
      Names?: string;
      Email?: string;
      Source?: string;
      Tag?: string;
      City?: string;
      DateCollected?: string;
    };
    source: string;
  }
) => {
  try {
    const response = await Axios.post(`/brand/${brandId}/csv-fans`, dataToSend);
    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const getImportingNewFansStatus = async (brandId: number) => {
  try {
    const response = await Axios.get(`brand/${brandId}/csv/importing-fans`);
    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const getFansMetrics = async (brandId: number) => {
  try {
    const response = await Axios.get(`brand/${brandId}/metrics/fans`);
    const data = response.data.data;
    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const getDownloadSignedUrl = async (brandId: number) => {
  try {
    const response = await Axios.get(`brand/${brandId}/csv/fans`);
    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
}

/**
 * Forces forever saves for a given content and brand
 * @param {string} contentSlug - The slug identifier for the content
 * @param {string} brandSlug - The slug identifier for the brand
 * @returns {Promise<{data: any, error: any}>} Object containing response data or error
 */

export const forceForeverSaves = async (contentSlug: string, brandSlug: string) => {
  try {
    const response = await Axios.post(`/fan-save/force/forever-fan-saves`, { contentId: contentSlug, brandSlug });

    const data = response.data.data;
    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
}