/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { StyledLabel } from "../../styles"
import { OrganizationData, SYMPHONY_TASKS } from "types/global"
import { GettingStartedContext } from "Hooks/GettingStartedContext/index"
import { Headline3 } from "components/shareable/Typography"
import useStyles from "./styles"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { DataGrid } from "@mui/x-data-grid"
import { Row, getDataGridColumns } from "./utils"
import useModal from "Hooks/useModal"
import DeleteUserModal from "../DeleteUserModal"
import AddUserModal from "../AddUserModal"
import UpgradeToProChip from "components/shareable/UpgradeToProChip"
import { useHistory } from "react-router-dom"
import { PageTabs } from "../../../OrganizationPage/utils"
import { getBrandUsers, PulledUsers } from "services/symphonyApi/brandService"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { Container } from "styles/shared"
import { PlanType } from "modules/Const"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import Colors from "modules/Colors"

interface Props {
  brandBelongsToOrganization?: boolean;
  organization?: OrganizationData;
}

const UsersTab = ({
  brandBelongsToOrganization,
}: Props) => {
  const classes = useStyles()
  const { isProBrand, currentBrand } = useContext(CurrentBrandContext)
  const {
    id: brandId
  } = currentBrand || {}

  const { handleOpenModal: openUpgradeToProModal } = useContext(UpgradeModalContext)

  const [pulledUsers, setPulledUsers] = useState<PulledUsers>({ availableSeats: 0, users: [] })
  const addUserModal = useModal()
  const deleteUserModal = useModal()
  const [potentiallyDeletedUser, setPotentiallyDeletingUser] =
    useState<Row | null>(null)
  const history = useHistory();

  const { finishedTasks } = useContext(GettingStartedContext)

  const dataGridColumns = getDataGridColumns({
    onRemove: (row: Row | null) => {
      if (brandBelongsToOrganization) {
        history.push(`/team?tab=${PageTabs.TEAM}`)
      } else {
        setPotentiallyDeletingUser(row as Row | null)
        deleteUserModal.openModal()
      }
    },
  })

  const markStepAsCompleted = () => {
    if (!finishedTasks.includes(SYMPHONY_TASKS.TEAM_INVITED)) {
      // finishTask(SYMPHONY_TASKS.TEAM_INVITED)
    }
  }

  const handleCloseAddModal = () => {
    addUserModal.closeModal()
  }

  const handleCloseDeleteModal = () => {
    setPotentiallyDeletingUser(null)
    getBrandUsers({
      brandId,
      onSaveUsers: setPulledUsers,
    })
    deleteUserModal.closeModal()
  }

  const handleClickAddNewMemberButton = () => {
    if (brandBelongsToOrganization) {
      history.push(`/team?tab=${PageTabs.TEAM}`)
    } else {
      if (isProBrand) {
        addUserModal.openModal()
      } else {
        openUpgradeToProModal({
          source: "Settings - Add New User",
          highlightedPlan: PlanType.PRO,
          upgradeCallToAction: "Invite your team with Symphony Pro"
        })
      }
    }
  }

  useEffect(() => {
    getBrandUsers({
      brandId,
      onSaveUsers: setPulledUsers,
    })
    markStepAsCompleted()
  }, [])

  const hasReachedLimit = pulledUsers.users.length >= pulledUsers.availableSeats
  const willNotTouchLimit = pulledUsers.users.length - 1 >= pulledUsers.availableSeats

  return (
    <Container>
      <StyledLabel>
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="0px 0px 24px 0px"
          borderBottom="1px solid #E5E7EB"
        >
          <Headline3 color={Colors.textColor}>
            Profile Members
          </Headline3>
          <ButtonSymphony
            className="settingsPageInviteMemberButton"
            onClick={handleClickAddNewMemberButton}
            variant="text"
            iconLeft={<UpgradeToProChip type="gem" color="purple" height={18} width={18} />}
          >
            Invite a Member
          </ButtonSymphony>
        </Container>
      </StyledLabel>
      {pulledUsers.users.length > 0 && (
        <Container margin="0px" minHeight="100px">
          <DataGrid
            autoHeight
            headerHeight={0}
            hideFooter
            classes={{ root: classes.dataGridRoot }}
            columns={dataGridColumns}
            //TODO: Data will change from the same endpoint
            rows={pulledUsers.users.map(({
              email,
              id,
              isOwner,
              removable
            }) => {
              return {
                id,
                email,
                name: "-",
                isOwner,
                removable
              }
            }
            )}
          />
        </Container>
      )}
      <DeleteUserModal
        {...{ brandId }}
        open={deleteUserModal.open}
        member={potentiallyDeletedUser}
        willNotTouchLimit={willNotTouchLimit}
        onSaveUsers={setPulledUsers}
        onSetDeletedUser={setPotentiallyDeletingUser}
        onClose={handleCloseDeleteModal}
      />
      <AddUserModal
        {...{ brandId }}
        users={pulledUsers.users}
        hasReachedLimit={hasReachedLimit}
        open={addUserModal.open}
        onClose={handleCloseAddModal}
        onSaveUsers={setPulledUsers}
      />
    </Container>
  )
}

export default UsersTab
