import { StyledLabel, Card, Container } from 'styles/shared';
import { Box, Typography } from '@material-ui/core';
import { Body2 } from 'components/shareable/Typography';
import Colors from "modules/Colors";

interface MarketingCardProps {
    topImageTextCombos?: { imageSrc: string; text: string }[];
    whenToUse: string[];
    setupNeeded: string[];
    conversionType: string;
    averageConversionCost: number;
}

const MarketingCard = ({ 
    conversionType, 
    averageConversionCost, 
    topImageTextCombos, 
    whenToUse, 
    setupNeeded 
}: MarketingCardProps) => {
    return (
        <Container display="flex" flexDirection="column" gap="24px" padding='16px'>
            {topImageTextCombos && topImageTextCombos.length > 0 && (
                <Card>
                    {topImageTextCombos.map((combo, index) => (
                        <Box key={index} display="flex" alignItems="center" marginBottom="16px">
                            <img src={combo.imageSrc} alt="icon" style={{ marginRight: '16px' }} />
                            <Typography variant="body1">{combo.text}</Typography>
                        </Box>
                    ))}
                </Card>
            )}

            <Container gap="8px" display='flex' flexDirection='column'>
                <StyledLabel fontWeight={500} fontSize={18} color="#000" marginTop="24px">
                    When to use this:
                </StyledLabel>
                <ul className='list-disc space-y-2 pl-4'>
                    {whenToUse.map((item, index) => (
                        <li key={index}><Body2 color={Colors.textColor}>{item}</Body2></li>
                    ))}
                </ul>
            </Container>
            <Container gap="8px" display='flex' flexDirection='column'>

                <StyledLabel fontWeight={500} fontSize={18} color="#000" marginTop="24px">
                    What you’ll need to get setup:
                </StyledLabel>
                <ul className="list-disc space-y-2 pl-4">
                    {setupNeeded.map((item, index) => (
                        <li key={index}><Body2 color={Colors.textColor}>{item}</Body2></li>
                    ))}
                </ul>
            </Container>
            <Container gap="8px" display='flex' flexDirection='column'>
                <StyledLabel fontWeight={500} fontSize={18} color="#000" marginTop="24px">
                    Average cost-per-{conversionType}: {averageConversionCost.toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'USD'
                    })}
                </StyledLabel>

            </Container>
        </Container>
    );
};

export default MarketingCard;
