import InfoSection from 'components/InfoSection'
import LoadingIndicator from "components/Loader/LoadingIndicator"
import { useState } from 'react'
import { useEffect } from 'react'
import RowItem from '../../../../components/shareable/RowItem'
import Select from 'react-select'
import { getCountriesList } from 'helpers/Countries'
import { YouTubeCampaignTypes, YouTubeCampaignGeographyTypes } from 'modules/Const'
import { queryYouTubeApi } from 'helpers/YouTube'
import AudienceTargetingStatusIndicator from 'components/shareable/AudienceTargetingStatusIndicator'
import useScreen from 'Hooks/useScreen'

const VideoTargetingTab = ({
    selectedAudiences,
    setAudiences,
    nextTab,

    geographyType,
    selectedCountries,
    setSelectedCountries,
    setGeographyType,

    targetingType,
    setTargetingType,
    showNextButton = true
}: any) => {
    const { mobileView } = useScreen();

    const [searchValue, setSearchValue] = useState<string>('')
    const [searchResults, setSearchResults] = useState<any>([])
    const [loadingSearch, setLoadingSearch] = useState(false)

    useEffect(() => {
        // query FB foor audiences
        if (!searchValue || searchValue.length === 0) {
            setSearchResults([])
        }
    }, [searchValue])

    const executeSearch = async () => {
        if (searchValue.length) {
            setLoadingSearch(true)

            const results = await queryYouTubeApi({
                endpoint: '/search',
                params: {
                    type: 'channel',
                    q: searchValue,
                    part: 'id,snippet',
                    maxResults: 10,
                }
            })
            if (results?.data?.items?.length) {
                setSearchResults(results.data.items.map((o: any) => {
                    return {
                        ...o.snippet,
                        id: o.id.channelId
                    }
                }))
            }
            setLoadingSearch(false)

        }

    }



    return (
        <>
            <div className=''>
                <InfoSection
                    className="sy-card px-5 w-full lg:px-10 border-b-0"
                    title="Campaign Type"
                    description="Optimize your campaign for the cheapest video views or to drive subscribers."
                />

                <div className=" w-11/12 m-auto border rounded-md overflow-hidden">
                    <div className='w-full flex flex-col'>
                        {YouTubeCampaignTypes
                            .filter((o: {
                                enabled: boolean
                            }) => {
                                return o.enabled
                            }).map((o: {
                                id: string,
                                name: string,
                                text: string,
                                adFormat?: string
                            }) => {
                                const {
                                    id, name, text, adFormat
                                } = o

                                const selected = targetingType === id

                                return (
                                    <div
                                        key={id}
                                        onChange={() => {
                                            setTargetingType(id)
                                        }}
                                        onClick={(e: any) => {
                                            setTargetingType(id)
                                        }}
                                        className={`flex-shrink-0 cursor-pointer flex flex-row w-full ${selected ? 'bg-green-50' : ''} border-b py-6 px-5 lg:px-10`}>
                                        <input className="flex-shrink-0" type="radio" id={id} name={id} value={id}
                                            checked={targetingType === id}
                                            onChange={(e: any) => {
                                                setTargetingType(e.target.value)
                                            }} />
                                        <label></label>
                                        <div className='ml-4'>
                                            <p className='font-semibold'>{name}</p>
                                            <p>{text}</p>
                                            {adFormat && (<p className='text-sm text-gray-600'>Runs using {adFormat}</p>)}

                                        </div>
                                        {/* <label for="huey">Huey</label> */}
                                    </div>
                                )
                            })}


                    </div>

                </div>
            </div>

            <div className='my-6 border-t'>
                <InfoSection
                    className="sy-card px-5 w-full lg:px-10 border-b-0"
                    title="Locations"
                    description="Symphony automatically targets your marketing in geographies we think will help you build your fanbase most effectively."
                />
                <div className=" w-11/12 m-auto border rounded-md overflow-hidden">
                    <div className='w-full flex flex-col'>
                        {YouTubeCampaignGeographyTypes.map((o: {
                            id: string,
                            name: string,
                            text: string,
                            subtitle: string | null,
                        }) => {
                            const {
                                id, name, text, subtitle
                            } = o

                            const selected = geographyType === id

                            let recommended = false
                            if (targetingType === 'views') {
                                if (id === 'global') {
                                    recommended = false
                                }
                            } else if (targetingType === 'engagement') {
                                if (id === 'english') {
                                    recommended = true
                                }
                            }

                            const RECOMMENDED_VIEW = (
                                <div
                                    className={
                                        "mt-2 text-xs inline-block bg-gray-400 py-1 px-2 rounded-sm border-box bg-indigo-500 text-white"
                                    }
                                >
                                    RECOMMENDED
                                </div>
                            )
                            return (
                                <div
                                    key={id}
                                    onChange={() => {
                                        setGeographyType(id)
                                    }}
                                    onClick={(e: any) => {
                                        setGeographyType(id)

                                    }}
                                    className={`flex-shrink-0 cursor-pointer flex flex-row w-full ${selected ? 'bg-green-50' : ''} border-b py-6 px-5 lg:px-10`}>
                                    <input className="flex-shrink-0" type="radio" id={id} name={id} value={id}
                                        checked={selected}
                                        onChange={(e: any) => {
                                            setGeographyType(e.target.value)
                                        }} />
                                    <label></label>
                                    <div className='ml-4'>
                                        <p className='font-semibold'>{name}</p>
                                        <p>{text}</p>
                                        {subtitle ? <p className='text-sm text-gray-600'>{subtitle}</p> : null}
                                        {recommended ? RECOMMENDED_VIEW : null}
                                    </div>
                                    {/* <label for="huey">Huey</label> */}
                                </div>
                            )
                        })}
                    </div>
                </div>

                {geographyType === 'custom' &&
                    <div
                        className="mt-4 px-5 w-full lg:px-10 "
                    >
                        <p>Select the countries you want to target.</p>
                        <Select
                            className='m-auto mt-2'
                            placeholder="Add countries..."
                            isMulti
                            value={selectedCountries}
                            onChange={(e: any) => {
                                setSelectedCountries(e)
                            }}
                            options={getCountriesList()}
                        />
                    </div>
                }
            </div>
            <div className='border-t'>
                <InfoSection title="Targeting" description="Target your video campaign by selecting relevant audiences - your ads will run on these channels." />
                <div className="px-5 lg:px-10 mt-2.5">
                    <AudienceTargetingStatusIndicator
                        tooMuchThreshold={0}
                        tooLowThreshold={10}
                        bestPracticeText="We recommend adding at least 10 channels of medium + big creators in your genre."
                        selectedAudiences={selectedAudiences}
                    />
                </div>
                {selectedAudiences && selectedAudiences.length > 0 &&
                    <div className="sy-card px-5 lg:px-10 py-1">
                        <div className='p-2 w-full rounded-md'>
                            <ul className="flex flex-wrap">
                                {selectedAudiences.map((o: any) => {
                                    return (
                                        <li key={o.id} className="text-sm lg:text-md 
            m-1 lg:m-2 rounded-full py-2 px-3 bg-primary text-white flex items-center">
                                            <p>{o.channelTitle ? o.channelTitle : null}</p>
                                            <p
                                                className="cursor-pointer ml-3 text-xs uppercase"
                                                onClick={() => {
                                                    const updated = selectedAudiences.filter((looking: any) => {
                                                        return looking.id !== o.id
                                                    })
                                                    setAudiences(updated)
                                                }}
                                            >
                                                Remove
                                            </p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                }
                <div className="sy-card ">
                    <div className="w-full">
                        <form className="w-full" onSubmit={(e) => {
                            e.preventDefault()
                            executeSearch()
                        }}>
                            <div className="flex flex-row items-center ">
                                <img className="pl-5 lg:pl-10 w-auto" src={require('../../../../assets/images/search-icon.svg').default} alt="search" />
                                <input
                                    type="text"
                                    placeholder="Search for YouTube channels to target"
                                    value={searchValue}
                                    onChange={(e: any) => setSearchValue(e.target.value)}
                                    className="pl-4 pr-6 outline-none border-gray-200 w-full"
                                />
                                <button type="submit" className="pr-5 lg:pr-10 text-primary">Search</button>
                            </div>
                        </form>

                    </div>
                </div>

                {searchValue && searchValue.length > 0 &&
                    <ul className="add-new border-b max-h-72 overflow-auto" onBlur={() => setSearchValue('')}>
                        {loadingSearch ? (
                            <div className="flex-col flex items-center m-8 ">
                                <LoadingIndicator height="80px" color="black" />
                                <p className="mt-1">Searching...</p>
                            </div>
                        ) : searchResults
                            .map((item: any) => {
                                const primaryThumbnail = item.thumbnails.default ? item.thumbnails.default.url :
                                    item.thumbnails.high ? item.thumbnails.high.url : null

                                const selected = selectedAudiences.findIndex((p: any) => {
                                    return p.id === item.id
                                }) > -1
                                return (
                                    <RowItem
                                        multiSelect
                                        selected={selected}
                                        key={item.id}
                                        imageComponent={(
                                            <div className="artist-img flex-shrink-0">
                                                <img className="flex-shrink-0" src={primaryThumbnail} alt="thumbnail" />
                                            </div>
                                        )}
                                        onClick={() => {
                                            if (!selected) {
                                                const updatedSelected = [
                                                    ...selectedAudiences
                                                ]
                                                updatedSelected.push(item)
                                                setAudiences(updatedSelected)
                                            } else {
                                                const updated = selectedAudiences.filter((o: any) => {
                                                    return o.id !== item.id
                                                })

                                                setAudiences(updated)
                                            }
                                        }}
                                        // onClick={() => selectYoutubeChannel(item)}
                                        image={primaryThumbnail}
                                        name={item.title}
                                        secondRowContent={(
                                            <div className="flex flex-row items-center">
                                                <div className="social-text">
                                                    <p className="text-xs">
                                                        {item?.description.slice(0, 140)}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    />
                                );
                            })}
                    </ul>
                }
            </div>
            {showNextButton && !mobileView &&
                <div className="text-center px-4 lg:px-10 py-4">
                    <button onClick={() => nextTab()} className="btn-primary w-full br-20px mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3">Next</button>
                </div>
            }
        </>
    )
}

export default VideoTargetingTab