import { ReactComponent as FacebookRoundedIcon } from 'assets/images/facebook-rounded-icon.svg'
import { Body2 } from 'components/shareable/Typography'
import { NoAccountsDiv, useStyles } from './styles'
import SecondaryButton from 'components/shareable/SecondaryButton'

interface ErrorStateProps {
  description: string
  callToActionText: string
  callToAction: () => void
}

export const ErrorState = ({
  description,
  callToAction,
  callToActionText,
}: ErrorStateProps) => {
  const classes = useStyles()

  return (
    <NoAccountsDiv>
      <FacebookRoundedIcon
        width={56}
        height={56}
        className={classes.fbIconSecondaryColor}
      />
      <p>
        <Body2>{description}</Body2>
      </p>
      <SecondaryButton text={callToActionText} onClick={callToAction} />
    </NoAccountsDiv>
  )
}
