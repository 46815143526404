
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import dayjs from "dayjs";
import CircularStep from "../CircularStep";
import { Checkbox, FormControlLabel, useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  confirmed: boolean;
  setConfirmation: (val: boolean) => void;
  setRefundConfirmation: (val: boolean) => void;
  refundFeeConfirmed: boolean;

}

const formatDate = (date: Date | null) => dayjs(date).format("MM/DD/YY");

const ConfirmationStepCard = ({
  confirmed,
  refundFeeConfirmed,
  setConfirmation,
  setRefundConfirmation
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769));
  const classes = useStyles();

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={3} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Confirmation
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
          <Grid
            className={clsx(classes.withPaddingBottom)}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmed}
                  onChange={(ev: any) => {
                    setConfirmation(ev.target.checked)
                  }}
                  className="mr-8"
                  color="primary"
                />
              }
              label="Please confirm that your campaign settings above look correct. Once you submit your campaign, you understand that you will not be able to pause, edit, or cancel your campaign."
            />


          </Grid>
          <Grid
            className={clsx(classes.withPaddingBottom)}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={refundFeeConfirmed}
                  onChange={(ev: any) => {
                    setRefundConfirmation(ev.target.checked)
                  }}
                  className="mr-8"
                  color="primary"
                />
              }
              label="If your video is denied due to explicit content, we reserve the right to charge a 5% processing fee from your budget. This is used to cover costs of refunds."
            />

          </Grid>
        </Grid>

      </Grid>
    </Card>
  );
};

export default ConfirmationStepCard;
