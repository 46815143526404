import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { track } from 'analytics';
import { useDetectAdBlock } from 'adblock-detect-react';
import { cancelSubscription, redeemCouponOnCancellation } from 'services/symphonyApi/subscriptionService';
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from 'pages/post-auth/MarketingPage/Components/Modals/utils';
import { CurrentBrandContext } from './CurrentBrandContext';
import CouponModal from 'pages/post-auth/SettingsPage/Components/CouponModal';
import useBaremetricsCancellationWidget from 'components/baremetrics/CancellationWidget';
import { UpgradeModalContext } from './UpgradeModalContext';
/**
 * Interface defining the shape of the cancellation context
 * @interface CancellationContextType
 */
interface CancellationContextType {
    /** Main function to handle subscription cancellation flow */
    handleCancellationFlow: () => Promise<void>;
    /** ID for the Baremetrics cancel button, if applicable */
    cancellationButtonId: string | undefined;
    /** Handles coupon redemption process */
    handleCouponRedemption: () => Promise<void>;
    /** Handles cancel button click in the coupon modal */
    handleCouponModalCancel: () => Promise<void>;
    /** Indicates whether coupon redemption is in progress */
    isCouponProcessing: boolean;
    /** Indicates whether the cancellation flow is in progress */
    isCancelling: boolean;
}

/** Context for managing subscription cancellation state and logic */
const CancellationContext = createContext<CancellationContextType | undefined>(undefined);

/**
 * Provider component that manages subscription cancellation flows.
 * Handles both Stripe portal and Baremetrics cancellation paths, including
 * coupon offers and adblock detection logic.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components
 */
export function CancellationProvider({ children }: { children: React.ReactNode }) {
    const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
    const { handleSuccessfulCancellation, handleCloseModal } = useContext(UpgradeModalContext)
    const [isProcessing, setIsProcessing] = useState(false);
    const [showCouponModal, setShowCouponModal] = useState(false);
    const adBlockDetected = useDetectAdBlock();
    const [isCouponProcessing, setIsCouponProcessing] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    
    /** Event ID for Baremetrics widget communication */
    const BAREMETRICS_CANCELLATION_EVENT_ID = 'symphony:subscription-cancelled';
    
    /** Flag to prevent duplicate cancellation processing */
    const [hasProcessedCancellation, setHasProcessedCancellation] = useState(false);

    // Initialize Baremetrics widget with current customer ID
    useBaremetricsCancellationWidget(
        currentBrand?.subscription?.cid,
        BAREMETRICS_CANCELLATION_EVENT_ID
    );

    /**
     * Event handler for Baremetrics cancellation events.
     * Ensures cancellation is only processed once and has required data.
     */
    const handleBaremetricsEvent = useCallback((event: Event) => {
        if (!currentBrand?.slug || !currentBrand?.subscription || hasProcessedCancellation) {
            return;
        }

        setHasProcessedCancellation(true);
        handleCancelInStripe(
            currentBrand.slug,
            currentBrand.subscription
        );
    }, [currentBrand?.slug, hasProcessedCancellation]);

    /** Reset cancellation processed flag when brand changes */
    useEffect(() => {
        setHasProcessedCancellation(false);
    }, [currentBrand?.slug]);

    /** Setup/cleanup event listener for Baremetrics cancellation events */
    useEffect(() => {
        window.addEventListener(BAREMETRICS_CANCELLATION_EVENT_ID, handleBaremetricsEvent);
        return () => {
            window.removeEventListener(BAREMETRICS_CANCELLATION_EVENT_ID, handleBaremetricsEvent);
        };
    }, [handleBaremetricsEvent]);

    /**
     * Determines if a user is eligible to receive a cancellation coupon offer.
     * User must have an active subscription, not have redeemed a cancellation coupon before,
     * and not be on a Pro trial period.
     * 
     * @returns {boolean} True if user can receive coupon offer
     */
    function userEligibleForCancellationCoupon() {
        if (!currentBrand?.subscription) return false;

        const couponAlreadyRedeemed = currentBrand.subscription.coupon_on_cancelation_redeemed;
        const onProTrial = (
            currentBrand.subscription.status === "trialing")

        return !couponAlreadyRedeemed && !onProTrial;
    }

    /**
     * Determines the appropriate cancellation button ID based on user eligibility.
     * Returns undefined for users eligible for coupon (modal handles trigger),
     * otherwise returns the Baremetrics trigger ID.
     */
    const cancellationButtonId = useMemo(() => {
        if (!currentBrand?.subscription) return undefined;

        const isEligibleForCoupon = userEligibleForCancellationCoupon();
        return isEligibleForCoupon ? undefined : 'barecancel-trigger';
    }, [currentBrand?.subscription]);

    /**
     * Handles subscription cancellation through Stripe.
     * Tracks the event, updates brand data, and handles success/error states.
     * 
     * @param {string} brandSlug - Identifier for the brand
     * @param {any} subscriptionDetails - Current subscription information
     * @throws {Error} If cancellation request fails
     */
    const handleCancelInStripe = useCallback(async (
        brandSlug: string,
        subscriptionDetails?: any,
    ) => {
        if (isCancelling) return;

        try {
            setIsCancelling(true);
            await cancelSubscription(brandSlug);

            track("User Manually Downgraded Subscription", {
                brandSlug,
                ...(subscriptionDetails || {}),
            });

            await reloadBrand();
            handleSuccessfulCancellation();

        } catch (error) {
            Sentry.captureException(error);
            console.error("Subscription cancellation error: ", error);
            toast.error("Failed to cancel subscription. Please try again.", SHARED_TOAST_OPTIONS_ERROR);
            throw error;
        } finally {
            setIsCancelling(false);
        }
    }, [isCancelling, currentBrand]);

    /**
     * Main handler for subscription cancellation flow.
     * Determines appropriate cancellation path:
     * 1. Shows coupon modal for eligible users
     * 2. Direct cancellation for users with adblock
     * 3. Baremetrics flow for standard cases
     */
    const handleCancellationFlow = useCallback(async () => {
        console.log("handleCancellationFlow")
        if (isProcessing || !currentBrand?.slug || !currentBrand?.subscription) {
            return;
        }

        try {
            setIsCancelling(true);

            const couponAlreadyRedeemed = currentBrand.subscription.coupon_on_cancelation_redeemed;
            const onProTrial = (
                currentBrand.subscription.status === "trialing" ||
                (currentBrand.subscription.is_trial && !currentBrand.subscription.details?.trialOver)
            );
           
            if (!couponAlreadyRedeemed && !onProTrial) {
                setShowCouponModal(true);
                return;
            }

            if (adBlockDetected || !cancellationButtonId) {
                await handleCancelInStripe(currentBrand.slug, currentBrand.subscription);
                return;
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Cancellation flow error:', error);
            toast.error("Something went wrong with the cancellation process. Please try again.", SHARED_TOAST_OPTIONS_ERROR);
            throw error;
        } finally {
            setIsCancelling(false);
        }
    }, [adBlockDetected, isProcessing, currentBrand]);

    /**
     * Handles the coupon redemption process.
     * Applies discount and updates subscription state on success.
     */
    const handleCouponRedeemCoupon = useCallback(async () => {
        if (isCouponProcessing || !currentBrand?.slug || isCancelling) {
            return;
        }

        try {
            setIsCouponProcessing(true);
            await redeemCouponOnCancellation(currentBrand.slug);

            track("User Manually Redeemed Coupon on Cancelation", {
                brandSlug: currentBrand.slug,
                ...(currentBrand.subscription || {}),
            });

            await reloadBrand();
            handleCloseModal();
            setShowCouponModal(false);
            toast.success("Discount successfully applied.", SHARED_TOAST_OPTIONS);
        } catch (error) {
            Sentry.captureException(error);
            console.error("Coupon redemption error: ", error);
            toast.error("Something went wrong. Please try again or contact support.", SHARED_TOAST_OPTIONS_ERROR);
        } finally {
            setIsCouponProcessing(false);
        }
    }, [isCouponProcessing, currentBrand, isCancelling]);

    /**
     * Handles the cancel button click in the coupon modal.
     * Either redirects to Stripe portal (adblock) or triggers Baremetrics flow.
     */
    const handleCouponModalProceedWithCancellation = useCallback(async () => {
        if (isCouponProcessing || isCancelling) return;

        try {
            if (adBlockDetected && currentBrand?.slug) {
                await handleCancelInStripe(currentBrand.slug, currentBrand.subscription);
                setShowCouponModal(false);
                return;
            }

            setShowCouponModal(false);
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error handling coupon modal cancellation:', error);
            toast.error("Something went wrong. Please try again.", SHARED_TOAST_OPTIONS_ERROR);
        }
    }, [adBlockDetected, isCouponProcessing, currentBrand, isCancelling]);

    /** Context value containing all necessary handlers and state */
    const value = {
        handleCancellationFlow,
        cancellationButtonId,
        handleCouponRedemption: handleCouponRedeemCoupon,
        handleCouponModalCancel: handleCouponModalProceedWithCancellation,
        isCouponProcessing,
        isCancelling
    };

    return (
        <CancellationContext.Provider value={value}>
            {children}
            <CouponModal
                open={showCouponModal}
                onClose={() => setShowCouponModal(false)}
            />
        </CancellationContext.Provider>
    );
}

/**
 * Hook for using the cancellation context
 */
export function useCancellation() {
    const context = useContext(CancellationContext);
    if (context === undefined) {
        throw new Error('useCancellation must be used within a CancellationProvider');
    }
    return context;
}