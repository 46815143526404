import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import { clsx } from "modules/Utils";
interface ConnectionsCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const ConnectionsCampaignView = ({
    className = '',
    style = {}
}: ConnectionsCampaignViewProps) => {
    const { campaign } = useCampaign();

    return (
        <div
            className={clsx("connectionsCampaignViewComponent", className)}
            style={{ ...sty.connectionsCampaignViewComponent, ...style }}
        >
            ConnectionsCampaignView
        </div>
    )
}

const sty = {
    connectionsCampaignViewComponent: {
        width: '100%',
        height: '100%',
    }
}

export default ConnectionsCampaignView;