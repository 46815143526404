import { CSSProperties } from 'react';
import { Button, makeStyles, Theme, Tooltip, withStyles, MenuItem, useTheme } from '@material-ui/core';
import styled from 'styled-components'
import Select from '@mui/material/Select';
import Colors from 'modules/Colors';

export interface StyledLabelProps {
    fontSize?: number;
    fontWeight?: number;
    lineHeight?: string;
    color?: string;
    cursor?: string;
    margin?: string;
    padding?: string;
    paddingLeft?: string;
    marginTop?: string;
    paddingBottom?: string;
    alignText?: string;
    width?: string;
    marginRight?: string;
    borderBottomWidth?: string;
}

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  align-text: ${({ alignText }: StyledLabelProps) => alignText};
  margin: ${({ margin }: StyledLabelProps) => margin || "0px"};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
  border-bottom-width: ${({ borderBottomWidth }: StyledLabelProps) => borderBottomWidth};
`;

export interface CardProps {
    borderRadius?: string
    showBorder?: boolean
}

export const Card = styled.div`
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-color: rgba(229, 231, 235, 1);
    width: 100%;
    background-color: #ffffff;
    border-radius: ${({ borderRadius, showBorder }: CardProps) => borderRadius || (showBorder ? '12px' : '0px')};
    @media (min-width: 1024px) {
        padding-left: 40px;
        padding-right: 40px;
    };
    @media (max-width: 768px) {
        border-radius: 0px;
    };
`;

export interface ContainerProps {
    responsiveFlexDirection?: string;
    flexDirection?: string;
    gap?: string | number;
    display?: string;
    padding?: string;
    margin?: string;
    backgroundColor?: string;
    borderBottom?: string;
    border?: string;
    alignItems?: string;
    width?: string;
    height?: string;
    marginLeft?: string;
    borderRadius?: string;
    justifyContent?: string;
    marginTop?: string;
    cursor?: string;
    paddingTop?: string;
    flexWrap?: string;
    flex?: number;
    overflow?: string;
    textOverflow?: string;
    textWrap?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
    maxWidth?: string;
    minHeight?: string;
    maxHeight?: string;
    transform?: string;
    lineHeight?: string;
    flexFlow?: string;
    overflowWrap?: string;
}

export const Container = styled.div<ContainerProps>`
  gap: ${({ gap }) => typeof gap === 'number' ? `${gap}px` : gap || "0px"};
  cursor: ${({ cursor }) => cursor || "default"};
  flex-direction: ${({ flexDirection }) => flexDirection};
  display: ${({ display }) => display};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  margin-left: ${({ marginLeft }) => marginLeft};
  border-radius: ${({ borderRadius }) => borderRadius};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: ${({ marginTop }) => marginTop};
  border: ${({ border }) => border || "none"};
  border-bottom: ${({ borderBottom }) => borderBottom};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex: ${({ flex }) => flex};
  overflow: ${({ overflow }) => overflow};
  text-overflow: ${({ textOverflow }) => textOverflow};
  text-wrap: ${({ textWrap }) => textWrap};
  border-bottom: ${({ borderBottom }) => borderBottom};
  transform: ${({ transform }) => transform};
  line-height: ${({ lineHeight }) => lineHeight};
  flex-flow: ${({ flexFlow }) => flexFlow};
  overflow-wrap: ${({ overflowWrap }) => overflowWrap};
  @media (max-width: 1280px) {
    flex-direction: ${({ responsiveFlexDirection }) => responsiveFlexDirection};
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
`;

interface MainContainerProps {
    padding?: string,
    flexDirection?: string,
    backgroundColor?: string,
    gap?: string,
    justifyContent?: string,
    borderRadius?: string,
    maxWidth?: string,
}

export const MainContainer = styled.div<MainContainerProps>`
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || "row"};
    padding: ${({ padding }) => padding || '0px'};
    gap: ${({ gap }) => gap || '24px'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    border-radius: ${({ borderRadius }) => borderRadius || '0px'};
`;

const OutlineButtonBase = withStyles((theme: Theme) => ({
    root: {
        border: "2px solid #8800FF",
        width: 'auto',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        textTransform: "none",
        borderRadius: 48,
        backgroundColor: "#ffffff",
        color: "#8800FF",
        height: 40,
        shadowBox: "none",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#8800FF",
        },
        '&.cancel-type': {
            borderColor: "red",
            color: "red",

            "&:hover": {
                borderColor: "red",
                color: "red",
            },
        }
    },
    text: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },


}))(Button);

export const OutlinedButton = ({
    className,
    style = {},
    children,
    onClick,
    disabled,
    padding,
    id,
    variant,
}: {
    className?: string,
    style?: CSSProperties,
    children: any,
    onClick?: (event: any) => void,
    padding?: string,
    disabled?: boolean
    id?: string;
    variant?: "default" | "cancel";
}) => {
    const theme = useTheme()
    // Create a style object that includes dynamic padding
    const dynamicStyles = {
        root: {
            padding: padding || `${theme.spacing(3)} ${theme.spacing(3)}`, // Fallback to 'the default' if padding is not provided
        },
    };

    return (
        <OutlineButtonBase
            className={`outlinedButtonComponent ${className} ${variant === "cancel" ? "cancel-type" : ""}`}
            style={{ ...dynamicStyles.root, ...style }}
            disableTouchRipple
            variant="outlined"
            onClick={onClick}
            id={id}
            disabled={Boolean(disabled)}
        >
            {children}
        </OutlineButtonBase>
    )
}

export const TextButtonBase = withStyles((theme: Theme) => ({
    root: {
        // border: "2px solid #8800FF",
        width: 'auto',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        textTransform: "none",
        // borderRadius: 48,
        // backgroundColor: "#ffffff",
        color: "#8800FF",
        height: 40,
        shadowBox: "none",
        "&:hover": {
            // backgroundColor: "#ffffff",
            // color: "#8800FF",
        },
        "&:disabled": {
            backgroundColor: "transparent",
        },
    },
    text: {
        transform: 'translateY(2px)',
        fontWeight: 500,
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5),
    },
}))(Button);

export const TextButton = ({
    className = '',
    style,
    children,
    onClick,
    disabled,
    color
}: {
    className?: string,
    style?: CSSProperties,
    children: any,
    onClick?: (event: any) => void,
    disabled?: boolean,
    color?: string,
}) => {
    return (
        <TextButtonBase
            className={`textButtonComponent ${className}`}
            style={{ color: color || Colors.purple, ...style }}
            onClick={onClick}
            disableTouchRipple
            variant="text"
            disabled={disabled}
        >
            {children}
        </TextButtonBase>
    )
}

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const SymphonyTooltip = ({
    className = '',
    ...props
}: any) => {
    const classes = useStylesBootstrap();

    return (
        <Tooltip
            arrow
            classes={classes}
            {...props}
            className={`symphonyTooltipComponent ${className}`}
        />
    )
}

export const TabsPanelsContainer = styled.div`
  padding: 0px 0px;
  height: 100%;
`

interface StyledNavBarProps {
    justifyContent?: string;
}

export const StyledNavBar = styled.nav<StyledNavBarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ justifyContent }: StyledNavBarProps) => justifyContent || "inherit"};
  border-bottom: 1px solid rgba(209, 213, 219, 1);
  background-color: ${Colors.PAPER_CONTAINER};
  padding: 8px 16px 0px 0px;
  @media (max-width: 430px) {
    gap: 8px;
    align-items: center;
    padding-top: 0px;
  }
`;

export const ChipFilterPopOver = styled.span`
  background: rgba(136, 0, 255, 0.08);
  color: #8800FF;
  padding: 4px 8px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: -6px;
  font-family: "DIN";
`

export const TitlePopOver = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
`

export const SelectPopOver = styled(Select)`
    font-family: "DIN";
`

export const MenuItemPopOver = styled(MenuItem)`
    font-family: "DIN";
`