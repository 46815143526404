import { CSSProperties, ReactNode } from "react";
import useScreen from "Hooks/useScreen";
import Colors from "modules/Colors";

const PrimaryButtonContainer = ({children, fullWidth}: {children: ReactNode, fullWidth?: boolean}) => {
    const { mobileView } = useScreen();

    let style = mobileView ? sty : fullWidth ? {width: '100%'} : {};
    return (
        <div style={style}>
            {children}
        </div>
    );
}

const sty = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 16,
    boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
} as CSSProperties

export default PrimaryButtonContainer;