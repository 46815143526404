import { useContext, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import { OutlinedButton, StyledLabel, TextButton, Container } from "styles/shared";
import { MemberRoles, OrganizationBillingData, OrganizationData } from "types/global";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useHistory } from "react-router-dom";
import PrimaryButton from "components/shareable/PrimaryButton";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { getOrganizationBillingData } from "services/symphonyApi/organizationService";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import ButtonSymphony from "components/shareable/ButtonSymphony";

// Extend the Window interface to include the barecancel property
declare global {
    interface Window {
        barecancel?: {
            created: boolean;
            params: {
                access_token_id: string;
                customer_oid: string;
                callback_send: (data: any) => void;
                callback_error: (error: any) => void;
            };
        };
    }
}

interface OrganizationProPlanViewProps {
    organization: OrganizationData;
}

const OrganizationProPlanView = ({
    organization
}: OrganizationProPlanViewProps) => {
    const theme = useTheme();
    const history = useHistory()
    const { handleOpenModal } = useContext(UpgradeModalContext);
    const { isProBrand } = useContext(CurrentBrandContext);
    const { isSymphonyAdmin: isAdmin, spoofedUserId } = useContext(CurrentUserContext);

    const setMdWidth = useMediaQuery(theme.breakpoints.down(645));
    const setLgWidth = useMediaQuery(theme.breakpoints.between(645, 920));
    const setUpperPartAsColumn = useMediaQuery(theme.breakpoints.down(524));

    const [currentOrganizationBillingData, setCurrentOrganizationBillingData] = useState<OrganizationBillingData | null>(null);

    useEffect(() => {
        const fetchOrganizationBillingData = async () => {
            try {
                const billingData = await getOrganizationBillingData({ spoofedUserId: spoofedUserId || undefined });
                if (billingData) {
                    setCurrentOrganizationBillingData(billingData);
                };
            } catch (error) {
                Sentry.captureException(error);
                console.error("fetchOrganizationBillingData: ", error);
            }
        };
        fetchOrganizationBillingData();
    }, []);

    const redirectToStripeSubscription = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewSubscriptionUrl) {
            window.open(currentOrganizationBillingData.viewSubscriptionUrl, "_blank");
        }
    }
    const redirectToStripeCustomer = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewCustomerUrl) {
            window.open(currentOrganizationBillingData.viewCustomerUrl, "_blank");
        }
    }

    const openTeamPlanBillingSettings = async () => {
        history.push(`/organization?tab=BILLING`);
    }

    function dataRow(array: {
        title: string;
        text: string
        editable?: boolean;
        onEdit?: () => void
    }[]) {
        return (
            <Container
                gap={setUpperPartAsColumn ? '8px' : '32px'}
                display="flex"
                flexDirection={setUpperPartAsColumn ? "column" : "row"}
                padding="24px 0px"
                borderBottom="1px solid #E5E7EB"
                alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
            >
                {array.map((item, index) => (
                    <Container
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width={setLgWidth ? "32%" : setMdWidth ? "100%" : "20%"}
                    >
                        <StyledLabel fontSize={16} fontWeight={400}>
                            {item.title}
                        </StyledLabel>
                        <Container display="flex" flexDirection="row" gap="12px">
                            <StyledLabel fontSize={24} fontWeight={500}>
                                {item.text}
                            </StyledLabel>
                            {item.editable && isAdmin &&
                                <Container
                                    display="flex"
                                    gap="4px"
                                    alignItems="center">
                                    <TextButton onClick={item.onEdit}>Edit Spend</TextButton>
                                </Container>
                            }
                        </Container>
                    </Container>
                ))
                }
            </Container>
        )
    }

    const basePlanRowData = []
    basePlanRowData.push({
        title: "Plan",
        text: !isProBrand ? 'Free Profile on Team Plan' : `Pro via Team Plan`
    })

    // handle edit
    return (
        <Container>
            {isAdmin &&
                <Container
                    gap={setUpperPartAsColumn ? '8px' : '32px'}
                    display="flex"
                    flexDirection="row"
                    padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
                >
                    <Container flexDirection="row">
                        <ButtonSymphony
                            className="settingsPageAdminViewStripeSubscriptionButton"
                            onClick={redirectToStripeSubscription}
                            variant="outlined"
                        >
                            Admin: View Stripe Subscription
                        </ButtonSymphony>
                    </Container>
                    <Container flexDirection="row">
                        <ButtonSymphony
                            className="settingsPageAdminViewStripeCustomerButton"
                            onClick={redirectToStripeCustomer}
                            variant="outlined"
                        >
                            Admin: View Stripe Customer
                        </ButtonSymphony>
                    </Container>
                </Container>
            }

            {dataRow(basePlanRowData)}
            {!isProBrand &&
                <Container
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                    padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    gap="8px"
                >
                    <PrimaryButton
                        onClick={() => {
                            handleOpenModal({
                                source: "Settings - Billing Tab (Team Plan subscriber)"
                            })
                        }}
                        text={"Upgrade to Pro"} />
                </Container>
            }
            {isProBrand && organization.role !== MemberRoles.MEMBER &&
                <Container
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width="100%"
                    padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    gap="8px"
                >
                    <StyledLabel fontSize={16} fontWeight={400}>
                        You can view and update your Team Plan in the Team Plan Billing Settings:
                    </StyledLabel>
                    <Container flexDirection={"row"}>
                        <OutlinedButton onClick={openTeamPlanBillingSettings}>View Team Plan Billing Settings</OutlinedButton>
                    </Container>
                </Container>
            }
        </Container>
    );
};

export default OrganizationProPlanView;
