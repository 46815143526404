/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  useContext,
  useRef,
} from "react"
import { pageView } from "analytics"
import {
  Card,
} from "./styles"
import OrganizationNeedsSetupState from "./SetupStatus/OrganizationNeedsSetupState"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { isOrganizationAdministrator } from "modules/Utils"
import { useHistory } from "react-router-dom";
import { useCurrentTeam } from "Hooks/CurrentTeamContext"
import { renderAdminTeamLoadingView } from "./AdminOnboarding"
import { OrganizationReadyView } from "./OrganizationReadyView"
import PageSymphony from "components/shareable/PageSymphony"
import DebugBox from "components/shareable/DebugBox"

enum OrganizationSetupStatus {
  NOT_STARTED = "NOT_STARTED",
  PAID_BUT_NEEDS_SETUP = "PAID_BUT_NEEDS_SETUP",
  SETUP = "SETUP"
}

// loadedBrands represents ALL of the brands a user has access to
// teamArtists represents brands that are on the team
const OrganizationPage = () => {
  const history = useHistory()

  const {
    reloadUser,
    isSymphonyAdmin,
    loadedBrands,
    getBrands: reloadBrands
  } = useContext(CurrentUserContext);
  const { reloadBrand } = useContext(CurrentBrandContext);

  const {
    organization: currentOrganization,
    // for admins looking at the organizations of other users
    loadingOrganizationFromAdminView,
    isLoadingTeamArtists
  } = useCurrentTeam()

  // uses the user.metadata.organization_subscription to determine the status of the organization
  // and display the relevant page:
  //  - not started (payment not set up)
  //  - paid but needs setup (user paid but needs to select artists)
  // - setup (user has selected artists)
  const determineOrganizationStatus = () => {
    const organizationSetupStatus = currentOrganization?.status

    if (!currentOrganization) {
      return OrganizationSetupStatus.NOT_STARTED
    } else {
      switch (organizationSetupStatus) {
        case "ready":
          return OrganizationSetupStatus.SETUP
        case "waiting_for_details":
          return OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP
        default:
          return OrganizationSetupStatus.NOT_STARTED
      }
    }
  }

  const currentOrganizationStatus = determineOrganizationStatus()
  const [organizationSetupStatus, setOrganizationSetupStatus] = useState<OrganizationSetupStatus>(currentOrganizationStatus || OrganizationSetupStatus.NOT_STARTED)

  // the user should never see a UI if they're not in the SETUP or STARTED state
  if (organizationSetupStatus === OrganizationSetupStatus.NOT_STARTED) {
    history.push("/")
  }

  // only show confetti if a user is on the screen, and just finished pro setup. 
  // dont show it any other times
  const [showConfetti, setShowConfetti] = useState(false)
  const [openCompletedModal, setOpenCompletedModal] = useState(false)
  const prevOrganizationSetupStatus = useRef<OrganizationSetupStatus | null>(null);

  useEffect(() => {
    pageView("Organization");
    if (currentOrganization) {
      if (!isOrganizationAdministrator(currentOrganization)) history.push("/dashboard");
    }
  }, []);

  // update the organization setup status when the organization status changes
  useEffect(() => {
    if (currentOrganization?.status) {
      let currentStatus = determineOrganizationStatus()
      console.log("currentStatus: ", currentStatus)
      setOrganizationSetupStatus(currentStatus)
    }
  }, [currentOrganization?.status])

  useEffect(() => {
    if (showConfetti) {
      setOpenCompletedModal(true)
    }
  }, [showConfetti])

  useEffect(() => {
    // Capture the status change specifically from PAID_BUT_NEEDS_SETUP to SETUP
    if (prevOrganizationSetupStatus.current === OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP && organizationSetupStatus === OrganizationSetupStatus.SETUP) {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }
    // Update the ref to the current status for the next render
    prevOrganizationSetupStatus.current = organizationSetupStatus;
  }, [organizationSetupStatus]);

  /**
   * Refreshes all data related to the organization status
   * This includes reloading the current brand, user data, and all brands in the organization
   * Used after making changes that affect organization setup status
   */
  const refreshStatus = async () => {
    await reloadBrand()
    await reloadUser()
    await reloadBrands()
  }

  // Renders the UI based on organization setup status
  const renderPrimaryView = () => {
    if (isSymphonyAdmin && (loadingOrganizationFromAdminView || isLoadingTeamArtists)) {
      return renderAdminTeamLoadingView()
    }

    switch (organizationSetupStatus) {
      case OrganizationSetupStatus.PAID_BUT_NEEDS_SETUP:
        return (
          <Card className="organizationNeedsSetupCardComponent" padding="40px 24px" borderRadius="8px" marginBottom="16px"
            width="750px">
            <OrganizationNeedsSetupState // NOTE: NOT ADMIN
              existingBrands={loadedBrands}
              organization={currentOrganization!}
              onFinished={() => refreshStatus()} />
          </Card>
        )
      case OrganizationSetupStatus.SETUP:
        return <OrganizationReadyView
          refreshStatus={refreshStatus}
        />
    }
  }

  return (
    <PageSymphony
      className="organizationPageComponent"
      isContent
    >
      <DebugBox
        data={currentOrganization}
        filePath="OrganizationPage.tsx"
        varName="currentOrganization"
      />
      {renderPrimaryView()}
    </PageSymphony>
  )
}

export default OrganizationPage
