import {
  BackgroundBar,
  ProgressBar,
  MainContainer,
  Indicator,
  IndicatorLabel,
} from "./styles";
import Grid from "@material-ui/core/Grid";

interface Props {
  progress?: number;
  indicatorValue?: number;
  maxValue?: number;
}

const toFix = (max: number, value?: number) =>
  Number((((value || 0) * 100) / max).toFixed(2));

const getShiftedValues = (args: {
  progress?: number;
  indicatorValue?: number;
  maxValue?: number;
}) => {
  const { progress, indicatorValue, maxValue } = args;

  if (maxValue) {
    return {
      shiftedProgress: toFix(maxValue, progress),
      shiftedIndicatorValue: toFix(maxValue, indicatorValue),
    };
  }

  return {
    shiftedProgress: progress || 0,
    shiftedIndicatorValue: indicatorValue || 0,
  };
};

//TODO: Add average indicator
const CustomLinearProgress = ({
  progress,
  indicatorValue,
  maxValue,
}: Props) => {
  const { shiftedProgress, shiftedIndicatorValue } = getShiftedValues({
    progress,
    indicatorValue,
    maxValue,
  });

  return (
    <MainContainer>
      <BackgroundBar>
        {!!shiftedIndicatorValue && (
          <Grid>
            <Indicator value={shiftedIndicatorValue}>
              <Grid container direction="row-reverse">
                <Grid item>
                  <IndicatorLabel>{indicatorValue}% average</IndicatorLabel>
                </Grid>
              </Grid>
            </Indicator>
          </Grid>
        )}
        <ProgressBar progress={shiftedProgress} />
      </BackgroundBar>
    </MainContainer>
  );
};

export default CustomLinearProgress;
