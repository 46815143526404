import { useState } from "react";
import { Container, StyledLabel } from "styles/shared";
import IGConnector from "./IGConnector";
import { Image } from "./styles";
import useScreen from "Hooks/useScreen";

export default function ConnectInstagramSection() {
    const { mobileView } = useScreen();

    const [showConnectorModal, setShowConnectorModal] = useState<boolean>(false)
    const handleOnOpenConnection = () => {
        setShowConnectorModal(true)
    }

    const handleCloseConnection = () => {
        setShowConnectorModal(false)
    }

    return (
        <>
            <Container
                display="flex"
                flexDirection="row"
                alignItems={mobileView ? "flex-start" : "center"}
                justifyContent="space-between"
                padding="24px"
                borderRadius="8px"
                width="100%"
                gap="16px"
                backgroundColor="none"
                border="1px solid #D9D9D9"
                marginTop="16px"
            >
                <Image
                    width={40}
                    height={40}
                    src={
                        require("assets/images/spotify.svg").default
                    }
                    padding="8px"
                    backgroundColor="#191919"
                    borderRadius="8px"
                />

                <Container
                    display="flex"
                    flexDirection={mobileView ? "column" : "row"}
                    alignItems={mobileView ? "flex-start" : "center"}
                    gap="16px"
                    width="100%"
                >
                    <Container
                        width="100%"
                        display="flex"
                        height="40px"
                        alignItems="center"
                    >
                        <StyledLabel
                            fontSize={16}
                            fontWeight={400}
                            lineHeight="19px"
                        >
                            Connect your <StyledLabel fontWeight={700} fontSize={16}>Facebook Page + Instagram Business Account</StyledLabel> to superboost posts.
                        </StyledLabel>
                    </Container>

                    <Container
                        padding="12px 24px"
                        width="fit-content"
                        backgroundColor="#8800FF"
                        borderRadius="48px"
                        cursor='pointer'
                        onClick={handleOnOpenConnection}
                    >
                        <StyledLabel
                            fontSize={16}
                            fontWeight={400}
                            lineHeight="20px"
                            color="#FFFFFF"
                        >
                            Connect
                        </StyledLabel>
                    </Container>
                </Container>
            </Container>
            <IGConnector
                open={showConnectorModal}
                closeModal={handleCloseConnection}
            />
        </>
    )
}