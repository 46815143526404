import DialogPaper from "components/shareable/DialogPaper";
import { useRef, useState } from "react";
import { ChipContainer, CloseButton, ModalBody, ModalContainer, ModalHeader, Video, VideoContainer } from "./styles";
import Colors from "modules/Colors";
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import PrimaryButton from "components/shareable/PrimaryButton";
import { Body2, Caption, Headline1 } from "components/shareable/Typography";
import useScreen from "Hooks/useScreen";
import YouTubePlayer from 'react-player/youtube'

interface Props {
  open: boolean;
  chipIcon?: JSX.Element;
  closeModal: () => void;
  title: string;
  subtitle: string;
  chipText?: string;
  onClickButton?: () => void;
  buttonText?: string;
  videoUrl: string;
}

const VideoModalStacked = ({
  open,
  chipIcon,
  closeModal,
  title,
  subtitle,
  chipText,
  onClickButton,
  buttonText,
  videoUrl,
}: Props) => {
  const { mobileView } = useScreen();
  const [paused, setPaused] = useState(false);
  const video = useRef<any>(null);

  const dialogStyleMobile = {
    maxWidth: "500px",
    width: "95%",
    height: "90%",
    maxHeight: "649px",
    gap: "0px",
    padding: "0px",
  }

  const dialogStyleDesktop = {
    maxWidth: "800px",
    width: "95%",
    height: "auto",
    maxHeight: "80vh",
    gap: "0px",
    padding: "0px",
  }

  const getVideo = () => {
    if (videoUrl.includes('youtube')
      || videoUrl.includes('youtu.be')) {
      return <YouTubePlayer
        url={videoUrl}
        playing={!paused}
        loop
        playsInline
        ref={video}
        autoPlay
        width="100%"
        height="100%"
        controls
      />
    } else {
      return (
        <Video
          ref={video}
          src={videoUrl}
          loop
          playsInline
          autoPlay
        />
      )
    }
  }
  return (
    <DialogPaper
      margin="0"
      open={open}
      dialogStyle={mobileView ? dialogStyleMobile : dialogStyleDesktop}
    >
      <ModalContainer flexDirection="column">
        <VideoContainer
          width="100%"
          height={mobileView ? "35vh" : "45vh"}
        >
          {getVideo()}
        </VideoContainer>
        <CloseButton
          onClick={closeModal}
          background={Colors.PAPER_DIVIDER_CONTAINER}
        >
          <CloseIcon width={24} height={24} />
        </CloseButton>
        <ModalBody
          padding={mobileView ? "24px 24px" : "32px 32px"}
          width="100%"
          gap={"24px"}
        >
          <ModalHeader>
            {chipText &&
              <ChipContainer>
                {chipIcon ? chipIcon : null}
                <Caption>{chipText}</Caption>
              </ChipContainer>
            }
            <Headline1 color={Colors.textColor}>
              {title}
            </Headline1>
            <Body2 color={Colors.greyDark}>
              {subtitle}
            </Body2>
          </ModalHeader>
          {buttonText && onClickButton &&
            <PrimaryButton
              width="100%"
              text={buttonText}
              onClick={onClickButton}
            />
          }
        </ModalBody>
      </ModalContainer>
    </DialogPaper>
  );
}

export default VideoModalStacked;