import StarsIcon from "assets/images/stars-icon.svg";
import GemIcon from "./ProCallouts/GemIcon";
import { SymphonyTooltip } from "styles/shared";
import styled from "styled-components";

interface Props {
  className?: string;
  mainContainerProps?: MainContainerProps;
  width?: number;
  height?: number;
  type?: 'gem' | 'label';
  color?: 'gold' | 'purple' | 'white'
  withMargin?: boolean;
}

const UpgradeToProChip = ({
  className = '',
  mainContainerProps,
  width,
  height,
  type = 'gem',
  color = 'gold',
  withMargin = true
}: Props) => {
  let mainItem = null;

  switch (type) {
    case 'label':
      mainItem = (
        <MainContainer {...mainContainerProps} gap={4} alignItems="center" width="fit-content">
          <img src={StarsIcon} alt="stars-icon.svg" />
          <span
            className="upgradeToProChipLabel"
            style={sty.upgradeToProChipLabel}
          >
            PRO
          </span>
        </MainContainer>
      );
      break;
    default:
      mainItem = (
        <GemIcon width={width} height={height} color={color} />
      );
  }

  return (
    <SymphonyTooltip
      className={`upgradeToProChipComponent ${className} ${withMargin && 'ml-2'} w-6 h-6`}
      title={"This is a Pro-exclusive feature."}
      arrow
    >
      {mainItem}
    </SymphonyTooltip>
  );
};

export interface MainContainerProps {
  gap?: number;
  alignItems?: string;
  flexShrink?: string;
  flexDirection?: string;
  margin?: string;
  height?: string;
  maxHeight?: string;
  width?: string;
  padding?: string;
  borderRadius?: string;
  minWidth?: string;
}

export const MainContainer = styled.div`
  padding: ${({ padding }: MainContainerProps) => padding || "4px"};
  max-height: ${({ maxHeight }: MainContainerProps) => maxHeight || "28px"};
  min-width: ${({ minWidth }: MainContainerProps) => minWidth};
  flex-shrink: ${({ flexShrink }: MainContainerProps) => flexShrink || "inherit"};
  border-radius: ${({ borderRadius }: MainContainerProps) => borderRadius || "4px"};
  gap: ${({ gap }: MainContainerProps) => gap}px;
  background-color: #f5ba23;
  display: flex;
  align-items: ${({ alignItems }: MainContainerProps) => alignItems || "flex-start"};
  flex-direction: ${({ flexDirection }: MainContainerProps) => flexDirection || "row"};
  margin: ${({ margin }: MainContainerProps) => margin};
  height: ${({ height }: MainContainerProps) => height};
  width: ${({ width }: MainContainerProps) => width ? width : '100%'};
`;

const sty = {
  upgradeToProChipLabel: {
    fontSize: 14,
    fontWeight: 480,
    color: 'white',
    opacity: 80,
    alignContent: 'baseline',
    alignSelf: 'baseline'
  }
}

export default UpgradeToProChip;
