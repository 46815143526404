import { useState, createContext, ReactNode, useContext } from "react";
import { ArtistPermissions, CurrentBrand } from "types/global";
import Axios from "helpers/Interceptor";
import { PlanType } from "modules/Const";
import { CurrentUserContext } from "./CurrentUserContext";

export const checkIsProTier = (currentBrand?: CurrentBrand | null) => {
    return currentBrand?.tier === PlanType.PRO
}

export const checkIsLiteTier = (currentBrand?: CurrentBrand | null) => {
    return currentBrand?.tier === PlanType.LITE
}

export const checkIsPaidTier = (currentBrand?: CurrentBrand | null) => {
    return currentBrand?.tier === PlanType.PRO || currentBrand?.tier === PlanType.LITE
}

export const checkIsFreeTier = (currentBrand?: CurrentBrand | null) => {
    return currentBrand?.tier === PlanType.FREE
}

export const checkTier = (currentBrand?: CurrentBrand | null): PlanType => {
    if (!currentBrand) return PlanType.FREE

    switch (currentBrand.tier) {
        case PlanType.PRO:
            return PlanType.PRO
        case PlanType.LITE:
            return PlanType.LITE
        case PlanType.TEAM:
            return PlanType.TEAM
        case PlanType.FREE:
        default:
            return PlanType.FREE
    }
}

export const checkIfOldPlan = (currentBrand?: CurrentBrand | null) => {
    if (currentBrand) {
        if (currentBrand?.subscription) {
            return Boolean(currentBrand?.subscription.onOldPlan)
        }
    }

    return false
}

// NOTE: currently not used anywhere
export const checkIfIsForcedProTier = (currentBrand?: CurrentBrand | null) => {
    return currentBrand?.tier === PlanType.PRO && currentBrand?.subscription?.type === 'forced'
}

export const isBrandAdmin = (currentBrand: CurrentBrand | undefined) => {
    return currentBrand?.permissionLevel === ArtistPermissions.ADMINISTRATOR
}

interface Context {
    currentBrand: CurrentBrand | undefined;
    setCurrentBrand: (brand: CurrentBrand) => CurrentBrand | undefined;
    reloadBrand: () => Promise<CurrentBrand> | undefined;
    loadingBrand: boolean;
    isPaidBrand: boolean;
    isProBrand: boolean;
    isLiteBrand: boolean;
    isFreeBrand: boolean;
    isBrandAdmin: boolean;
    proFreeTrialRedeemed: () => boolean;
    liteFreeTrialRedeemed: () => boolean;
}

export const CurrentBrandContext = createContext<Context>({
    currentBrand: undefined,
    setCurrentBrand: () => undefined,
    reloadBrand: () => undefined,
    loadingBrand: false,
    isPaidBrand: false,
    proFreeTrialRedeemed: () => false,
    isProBrand: false,
    isLiteBrand: false,
    isFreeBrand: false,
    isBrandAdmin: false,
    liteFreeTrialRedeemed: () => false,
});

type Props = {
    children: ReactNode[] | ReactNode;
};
/**
 * Provider component that manages the current brand state and provides methods to update it
 * @param {Props} props - Component props
 * @param {ReactNode[] | ReactNode} props.children - Child components that will have access to the brand context
 * @returns {JSX.Element} Provider component wrapping children with brand context
 */
const CurrentBrandProvider = ({
    children,
}: Props): JSX.Element => {
    const { currentUser } = useContext(CurrentUserContext)
    // State to store the currently selected brand
    const [storedBrand, setBrand] = useState<CurrentBrand | undefined>(undefined);
    // Loading state for async operations
    const [loading, setLoading] = useState<boolean>(false);


    /**
     * Reloads the current brand data from the API
     * @returns {Promise<CurrentBrand>} Promise resolving to the reloaded brand data
     * @throws {Error} If the API request fails
     */
    const reloadBrand = async (): Promise<CurrentBrand> => {
        setLoading(true);
        var getBrand = await Axios.get(`/brand/${storedBrand?.id}`);
        const reloadedBrand: CurrentBrand = getBrand.data.data
        setBrand(reloadedBrand);
        setLoading(false);
        return reloadedBrand
    }

    /**
     * Updates the current brand and persists the selection to localStorage
     * @param {CurrentBrand} brand - The brand to set as current
     * @returns {CurrentBrand | undefined} The updated brand or undefined
     */
    const setCurrentBrand = (brand: CurrentBrand): CurrentBrand | undefined => {
        // Prevent unnecessary updates if the brand ID hasn't changed
        if (brand?.id === storedBrand?.id) {
            return storedBrand;
        }

        // Persist selected brand ID to localStorage if available
        if (brand?.id) {
            localStorage.setItem('selected-brand', brand.id.toString());
        }

        setBrand(brand);
        return brand;
    }

    /**
     * Checks if the user has redeemed their pro free trial
     * @returns {boolean} True if user has already redeemed trial or trial is over, false if trial is still available
     */
    const proFreeTrialRedeemed = (): boolean => {
        return Boolean(currentUser?.metadata?.free_trial_redeemed)
    }

    /**
     * Checks if the user has redeemed their lite free trial
     * @returns {boolean} True if user has already redeemed trial or trial is over, false if trial is still available
     */
    const liteFreeTrialRedeemed = (): boolean => {
        return Boolean(currentUser?.metadata?.lite_free_trial_redeemed) || proFreeTrialRedeemed()
    }

    /**
     * Context value containing current brand state and methods
     */
    const context = {
        currentBrand: storedBrand,
        setCurrentBrand,
        reloadBrand,
        loadingBrand: loading,
        isPaidBrand: checkIsPaidTier(storedBrand),
        isProBrand: checkIsProTier(storedBrand),
        isLiteBrand: checkIsLiteTier(storedBrand),
        isFreeBrand: checkIsFreeTier(storedBrand),
        isBrandAdmin: isBrandAdmin(storedBrand),
        proFreeTrialRedeemed: proFreeTrialRedeemed,
        liteFreeTrialRedeemed: liteFreeTrialRedeemed,
    };

    return (
        <CurrentBrandContext.Provider value={context}>
            {children}
        </CurrentBrandContext.Provider>
    );
};

export default CurrentBrandProvider;
