import { CSSProperties } from "react";
import { clsx, styx } from "modules/Utils";
import useScreen from "Hooks/useScreen";

interface ContentSymphonyProps {
    className?: string;
    style?: CSSProperties;
    children: React.ReactNode;
}

const ContentSymphony = ({ className, style, children }: ContentSymphonyProps) => {
    const { mobileView, mStyle } = useScreen();

    return (
        <div
            className={clsx('contentSymphonyComponent', className)}
            style={styx(
                mStyle(sty, 'contentSymphonyComponent'),
                style
            )}
        >
            {children}
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    contentSymphonyComponent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
        maxWidth: 1200,
    },
    contentSymphonyComponent_mobile: {
    }
};

export default ContentSymphony;