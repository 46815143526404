import { Radio, ThemeProvider } from '@mui/material'
import { Headline3 } from 'components/shareable/Typography'
import { FacebookBusinessManager } from 'types/global'
import Colors from "modules/Colors"
import { radioBtnColorTheme } from '../AdAccountOption/styles'
import { ReactComponent as FacebookRoundedIcon } from 'assets/images/facebook-rounded-icon.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link.svg'
import {
  BusinessDetailsDiv,
  StyledArtistImg,
  StyledArtistImgDiv,
  StyledBusinessLi,
  StyledLinkButtonDiv,
  useStyles,
} from './styles'
import LinkButton from 'components/shareable/LinkButton'
import { AccountDisabledBanner } from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/Components/AccountDisabledBanner/AccountDisabledBanner'
import { DisabledBannerDiv } from '../styles'

interface BusinessAccountOptionProps {
  account: FacebookBusinessManager
  selectedAccount: FacebookBusinessManager
  setBusinessManager: (account: FacebookBusinessManager) => void
}

export const BusinessAccountOption = ({
  account,
  selectedAccount,
  setBusinessManager,
}: BusinessAccountOptionProps) => {
  const classes = useStyles()
  const { id, profile_picture_uri, name } = account
  const isAdmin: boolean = account.permitted_roles && account.permitted_roles.indexOf('ADMIN') > -1
  const businessUrl = `https://business.facebook.com/home/accounts?business_id=${id}`
  const isChecked: boolean = isAdmin && selectedAccount?.id === id

  return (
    <StyledBusinessLi onChange={() => setBusinessManager(account)}>
      <div className="flex w-full justify-between">
        <BusinessDetailsDiv>
          <ThemeProvider theme={radioBtnColorTheme}>
            <Radio
              id={`facebook_${id}`}
              name="connect-facebook-pages"
              value={selectedAccount}
              checked={isChecked}
              disabled={!isAdmin}
            />
          </ThemeProvider>
          <StyledArtistImgDiv>
            <StyledArtistImg src={profile_picture_uri || 'assets/images/profileIcon.svg'} alt="profile" />
          </StyledArtistImgDiv>
          <label htmlFor={`facebook_${id}`}>
            <Headline3 color={isAdmin ? Colors.textColor : Colors.grey}>
              {name}
            </Headline3>
          </label>
        </BusinessDetailsDiv>
        <StyledLinkButtonDiv>
          <LinkButton
            text="View on Facebook"
            leftIcon={<FacebookRoundedIcon className={classes.linkButtonIcon} />}
            rightIcon={<ExternalLinkIcon className={classes.linkButtonIcon} />}
            onClick={() => {
              window.open(businessUrl, '_blank', 'noopener noreferrer')
            }}
          />
        </StyledLinkButtonDiv>
      </div>
      {!isAdmin &&
        <DisabledBannerDiv>
          <AccountDisabledBanner
            bannerMessage="To create an ad account you must be an admin on this business."
            reviewAccountLink={businessUrl}
            linkText="Learn more"
          />
        </DisabledBannerDiv>
      }
    </StyledBusinessLi>
  )
}
