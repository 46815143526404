import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import Lottie from "react-lottie";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";

interface Props {
  onlyShowFailedPayments?: boolean
}

export const OrganizationSubscriptionStatusBanner = ({
  onlyShowFailedPayments = false
}: Props) => {
  const {
    remainingFreeTrial,
    redirectToSubscriptionStatus,
    isLoadingSubscriptionView,
    isPaymentFailed,
    organization
  } = useCurrentTeam();

  if(!organization) return null

  return (
    <>
      {remainingFreeTrial > 0 && !onlyShowFailedPayments &&
        <Alert severity="warning" icon={<></>}>
          <AlertTitle className="mt-4">
            <Typography variant="body1" fontWeight="600" style={{fontFamily: "DIN, sans-serif"}}>
              Your team trial ends in {remainingFreeTrial} days
            </Typography>
          </AlertTitle>
          <Typography variant="subtitle2" style={{fontFamily: "DIN, sans-serif"}}>
            Once your trial period is finished, your card will be charged for a
            Team Plan.
          </Typography>
        </Alert>
      }

      {isPaymentFailed &&
        <Alert
          severity="error"
          icon={<></>}
          className="mt-4"
          sx={{
            "& .MuiAlert-icon": { display: "none" },
            "& .MuiAlert-message": { width: "100%" },
          }}
        >
          <div className="w-full flex justify-between items-center">
            <div className="w-3/4">
              <AlertTitle>
                <Typography variant="body1" fontWeight="bold">
                  Your payment failed
                </Typography>
              </AlertTitle>
              <Typography variant="subtitle2">
                Please update your payment method to continue using Symphony for
                Teams
              </Typography>
            </div>
            <div className="w-1/4 flex justify-end">
              <Button
                disabled={isLoadingSubscriptionView}
                onClick={redirectToSubscriptionStatus}
                sx={{ textTransform: "capitalize" }}
                variant="text"
                color="error"
              >
                {!isLoadingSubscriptionView && "Manage subscription"}
                {isLoadingSubscriptionView && (
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                    height={24}
                    width={24}
                  ></Lottie>
                )}
              </Button>
            </div>
          </div>
        </Alert>
      }
    </>
  );
};
