import { CSSProperties, useContext } from "react";
import UpgradeToProLabel from "./UpgradeToProLabel";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { PlanType } from "modules/Const";
import ButtonSymphony from "./ButtonSymphony";

const StarsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M11.3895 3.45894C11.5074 3.0834 12.0389 3.0834 12.1568 3.45894L13.1498 6.62056C13.1892 6.74604 13.2875 6.84432 13.413 6.88373L16.5746 7.87668C16.9501 7.99463 16.9501 8.52608 16.5746 8.64403L13.413 9.63698C13.2875 9.67639 13.1892 9.77467 13.1498 9.90015L12.1568 13.0618C12.0389 13.4373 11.5074 13.4373 11.3895 13.0618L10.3966 9.90015C10.3571 9.77467 10.2589 9.67639 10.1334 9.63698L6.97175 8.64403C6.59622 8.52608 6.59622 7.99463 6.97175 7.87668L10.1334 6.88373C10.2589 6.84432 10.3571 6.74604 10.3966 6.62056L11.3895 3.45894Z"
        fill="#ffffff"
      />
      <path
        d="M6.10218 10.6961C6.20595 10.2938 6.77722 10.2938 6.88099 10.6961L7.3116 12.3654C7.34816 12.5071 7.45883 12.6178 7.60055 12.6544L9.26988 13.085C9.67215 13.1887 9.67215 13.76 9.26989 13.8638L7.60055 14.2944C7.45883 14.3309 7.34816 14.4416 7.3116 14.5833L6.88099 16.2527C6.77722 16.6549 6.20595 16.6549 6.10218 16.2527L5.67157 14.5833C5.63501 14.4416 5.52434 14.3309 5.38262 14.2944L3.71329 13.8638C3.31102 13.76 3.31102 13.1887 3.71328 13.085L5.38262 12.6544C5.52434 12.6178 5.63501 12.5071 5.67157 12.3654L6.10218 10.6961Z"
        fill="#ffffff"
      />
    </g>
  </svg>
);

interface Props {
  className?: string;
  title?: string;
  image?: string;
  hideImage?: boolean;
  description?: string;
  secondDescription?: string;
  imageMaxWidth?: string;
  source?: string;
  unlockedWithLite?: boolean;
}

const UnlockDataBanner = ({
  className = '',
  title,
  description,
  secondDescription,
  image,
  hideImage,
  imageMaxWidth,
  source,
  unlockedWithLite,
}: Props) => {
  const { handleOpenModal } = useContext(UpgradeModalContext);

  return (
    <div
      className={`unlockDataBannerComponent ${className}`}
      style={{
        justifyContent: "center",
        borderRadius: 12,
        display: "flex",
        padding: 20,
        gap: 40,
        alignItems: "center",
        backgroundColor: "rgba(136, 0, 255, 0.08)",
      }}
    >
      {image && !hideImage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "25%",
            maxWidth: imageMaxWidth,
            padding: "0px 0px 0px 32px",
          }}
        >
          <img
            className="unlockDataBannerImage"
            style={{
              ...sty.imageUnlockDataBanner,
              maxWidth: imageMaxWidth,
              maxHeight: imageMaxWidth,
            }}
            alt="unlock-data-chart.svg"
            src={image}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
        }}
      >
        <UpgradeToProLabel
          color="YELLOW"
          label={unlockedWithLite ? 'Symphony Lite' : 'Symphony Pro'}
          width="fit-content"
        />
        {title && (
          <span
            className="unlockDataBannerTitle"
            style={{ ...sty.labelUnlock, fontSize: 20, fontWeight: 500, marginTop: 12 }}
          >
            {title}
          </span>
        )}
        {description && (
          <span
            className="unlockDataBannerDescription"
            style={{ ...sty.labelUnlock, color: "#707070", marginTop: "2px" }}
          >
            {description}
          </span>
        )}
        {secondDescription && (
          <span
            className="unlockDataBannerDescription"
            style={{ ...sty.labelUnlock, color: "#707070" }}
          >
            {secondDescription}
          </span>
        )}
        <ButtonSymphony
          className="unlockDataBannerButton"
          style={{ marginTop: 12 }}
          onClick={() => handleOpenModal({
            source: source ? source : "Unlock Data Banner in Campaign Details",
            highlightedPlan: unlockedWithLite ? PlanType.LITE : PlanType.PRO,
          })}
          iconLeft={<StarsIcon />}
          iconRight={
            <div style={{ transform: "scaleX(-1)" }}>
              <StarsIcon />
            </div>
          }
        >
          {unlockedWithLite ? 'Upgrade to Lite' : 'Upgrade to Pro'}
        </ButtonSymphony>
      </div>
    </div>
  );
};

const sty = {
  imageUnlockDataBanner: {
    width: "80%",
    height: "80%",
  } as CSSProperties,
  labelUnlock: {
    fontSize: 16,
    fontWeight: 400,
    color: "black",
  } as CSSProperties,
}

const UpgradeToProButton = withStyles((theme: Theme) => ({
  root: {
    minWidth: 216,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "white",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "white",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button);


export default UnlockDataBanner;
