import { makeStyles, Theme } from '@material-ui/core';
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) => ({
    rootDialog: {
        "& .MuiDialog-paper": {
            maxWidth: "678px",
            borderRadius: "8px",
            [theme.breakpoints.down(701)]: {
                margin: 0,
            },
            [theme.breakpoints.up(701)]: {
                margin: "auto",
            },
        },
    },
    inputBorder: {
        borderRadius: 6,
        width: '100%',
        height: '100%',
    },

    tallInput: {
        height: '100px',  // or any height you want
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ADB7BC',
            },
            '&:hover fieldset': {
                borderColor: '#ADB7BC',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#8800FF',
            },
        },
        "& input": {
            fontFamily: "DIN",
            padding: "16px",
        },
        "& input::placeholder": {
            fontFamily: "DIN",
        },
    },
    formHelperText: {
        "&.MuiFormHelperText-root": {
            fontSize: 16
        }
    },
}));

interface ModalContainerProps {
    width?: string
    padding?: string
    margin?: string
    className?: string
};

interface ButtonsContainerProps {
    flexDirection?: string,
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 678px;
  width: ${({ width }: ModalContainerProps) => width || 'auto'};
  margin: ${({ margin }: ModalContainerProps) => margin || 'auto'};
  padding: ${({ padding }: ModalContainerProps) => padding || '32px'};
  gap: 32px;
`;


export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ButtonsContainerProps) => flexDirection || 'row'};
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

// Modal Card Popup Styles
export const CardDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    text-overflow: ellipsis;
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`

export const CardOptionTitle = styled.p`
 font-size: 20px;
 line-height: 32px;
 align-items: center;
 color: #000000;
`;

export const CardOptionImage = styled.img`
    border-radius: 16px;
    width: 50px;
    height: 50px;
`

export const CardSubtitle = styled.span`
  font-size: 16px;
  line-height: 23px;
  color: #707070;
  width: 80%;
  margin-right: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;


export const CardOptionParent = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: row;
align-items: center;
padding: 16px;
gap: 16px;
border: 1px solid #EDECF2;
border-radius: 8px;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
cursor: pointer;
`;