import { RecommendationType, welcomeToSymphonyRecommendations } from "modules/Const";
import { motion } from 'framer-motion';
import ButtonSymphony from "./ButtonSymphony";
import { CSSProperties } from "react";
import Colors from "modules/Colors";
import useScreen from "Hooks/useScreen";
import { track } from "analytics";
import { trackNewCampaign } from "pages/post-auth/MarketingPage/Components/CampaignsSection/analytics";
import { useHistory } from "react-router-dom";
import { WEBSITE_TYPES } from "types/global";

const RECOMMENDATIONS: Array<RecommendationType> = [
    RecommendationType.RUN_IG_ADS,
    RecommendationType.BUILD_WEBSITE,
    RecommendationType.CREATE_PRESAVE,
    RecommendationType.RUN_YOUTUBE_ADS,
]

interface RecommendationsProps {
    isRecommdationVertical?: boolean;
    isBanner?: boolean;
}

/** Component that renders a list of animated recommendation cards */
const Recommendations = ({
    isRecommdationVertical = false,
    isBanner = false,
}: RecommendationsProps) => {
    const { mobileView, smallView, mStyle } = useScreen()
    const history = useHistory()

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: (i: number) => ({
            opacity: 1,
            transition: {
                delay: i * 0.3,
            },
        }),
    };

    const getRecommendationClickHandler = (recommendationKey: RecommendationType) => {
        switch (recommendationKey) {
            case RecommendationType.RUN_IG_ADS:
                return () => {
                    track('NEW ONBOARDING: WELCOME PAGE ACTION', {
                        action: 'Run IG Ads',
                    })
                    trackNewCampaign({
                        type: 'increase_record_streams',
                        from: 'welcome-to-symphony',
                        zeroState: true,
                        aiQuestion: null,
                        key: 'music-streams'
                    })
                    history.push('/marketing/new/music-streams')
                }
            case RecommendationType.BUILD_WEBSITE:
                return () => {
                    track('NEW ONBOARDING: WELCOME PAGE ACTION', {
                        action: 'Build a Website'
                    })
                    track('Start "New Website" flow', { type: 'music' })
                    history.push(`/website?websiteType=${WEBSITE_TYPES.RECORD_STREAMS}`)
                }
            case RecommendationType.RUN_YOUTUBE_ADS:
                return () => {
                    track('NEW ONBOARDING: WELCOME PAGE ACTION', {
                        action: 'Run YouTube Ads'
                    })
                    trackNewCampaign({
                        type: 'increase_video_views',
                        from: 'welcome-to-symphony',
                        zeroState: true,
                        aiQuestion: null,
                        key: 'video-views'
                    })
                    history.push('/marketing/new/video-views')
                }
            case RecommendationType.CREATE_PRESAVE:
                return () => {
                    track('NEW ONBOARDING: WELCOME PAGE ACTION', {
                        action: 'Create a Pre-save'
                    })
                    trackNewCampaign({
                        type: 'presave',
                        from: 'welcome-to-symphony',
                        zeroState: true,
                        aiQuestion: null,
                        key: 'presave'
                    })
                    history.push('/marketing/new/presave')
                }
            default:
                return () => { }
        }
    }

    const recs = RECOMMENDATIONS.map((recommendationKey, index) => {
        const recommendation = welcomeToSymphonyRecommendations[recommendationKey];
        const clickHandler = getRecommendationClickHandler(recommendationKey);

        const isVerticalLayout = smallView || isRecommdationVertical;

        const {
            title,
            description,
            buttonText,
            image,
            imageBackgroundColor,
        } = recommendation;

        return (
            <motion.div
                key={recommendationKey}
                className="recommendationContainer"
                style={{
                    ...sty.recommendationContainer,
                    ...(isVerticalLayout && {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }),
                    ...(isBanner && {
                        width: 300,
                        padding: 8,
                        borderRadius: 16,
                    }),
                }}
                custom={index}
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <div
                    className="recommendationImageContainer"
                    style={{
                        ...sty.recommendationImageContainer,
                        backgroundColor: imageBackgroundColor,
                        ...(isVerticalLayout && {
                            width: '100%',
                            height: 120,
                        }),
                        ...(isBanner && {
                            width: '100%',
                            height: 80,
                            borderRadius: 10,
                        }),
                    }}
                >
                    <img
                        className="recommendationImage"
                        style={sty.recommendationImage}
                        src={image}
                    />
                </div>
                <div className="recommendationInfo" style={sty.recommendationInfo}>
                    <div
                        className="recommendationTitle"
                        style={{
                            ...sty.recommendationTitle,
                        }}
                    >
                        {title}
                    </div>
                    <div
                        className="recommendationDescription"
                        style={{
                            ...sty.recommendationDescription,
                            ...(isBanner && {
                                marginTop: 0,
                                fontSize: 14,
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }),
                        }}
                    >
                        {description}
                    </div>
                    <ButtonSymphony
                        className="recommendationButton"
                        style={{
                            ...(isVerticalLayout && {
                                width: '100%',
                                marginTop: 12,
                            }),
                            ...(isBanner && {
                                marginTop: 'auto',
                            }),
                        }}
                        onClick={clickHandler}
                        size={(isRecommdationVertical || isBanner) ? 'small' : 'medium'}
                    >
                        {buttonText}
                    </ButtonSymphony>
                </div>
            </motion.div>
        );
    });

    const recommendationsComponent = (
        <div
            className="recommendationsComponent"
            style={{
                ...mStyle(sty, 'recommendationsComponent'),
                ...(isBanner && sty.recommendationsBanner)
            }}
        >
            {recs}
        </div>
    )

    if (isBanner) {
        return (
            <div style={{ position: 'relative' }}>
                <div style={{ marginLeft: 8, marginBottom: 4 }}>
                    Recommendations:
                </div>
                {recommendationsComponent}
                <div className="bannerWhiteFade" style={sty.bannerWhiteFade} />
            </div>
        );
    }

    return recommendationsComponent;
};

const sty: Record<string, CSSProperties> = {
    recommendationsComponent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 16,
    },
    recommendationsBanner: {
        flexDirection: 'row',
        gap: 8,
        width: '100%',
        overflowX: 'scroll',
        backgroundColor: Colors.greyLighter,
        paddingRight: 8,
        position: 'relative',
    },
    recommendationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
        width: '100%',
        border: `1px solid ${Colors.divider}`,
        padding: 16,
        borderRadius: 8,
        flexShrink: 0,
        backgroundColor: 'white',
    },
    recommendationButton: {
        backgroundColor: Colors.purple,
        color: 'white',
        borderRadius: 100,
        cursor: 'pointer',
        width: 'fit-content',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
        paddingBottom: 8,
        marginTop: 'auto',
    },
    recommendationInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 8,
        height: '100%',
    },
    recommendationImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 170,
        height: 140,
        borderRadius: 12,
        flexShrink: 0,
    },
    recommendationImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        flexShrink: 0,
    },
    recommendationTitle: {
        fontWeight: 600,
    },
    recommendationDescription: {
        color: Colors.greyDark,
        lineHeight: '1.4',
    },
    bannerWhiteFade: {
        position: 'absolute',
        top: 28,
        right: 0,
        width: 100,
        height: 248,
        zIndex: 1,
        background: `linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)`,
    },
}

export default Recommendations;