import { useContext, useEffect, useState } from 'react';
import { track } from 'analytics';
import { BodyContainer, CardContainer, ImageContainer, Text } from './styles';
import fbTokenExpired from "assets/images/fb-token-expired.png"
import SecondaryButton from 'components/shareable/SecondaryButton';
import FB, { loginWithFB } from 'helpers/FB';
import Axios from 'helpers/Interceptor';
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import { SymphonyTooltip } from 'styles/shared';
import Intercom from 'helpers/Intercom';
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

interface Props {
  onSuccess?: () => void,
  campaignId: string | number,
  campaignType: string | number,
}

const MAINTENANCE_MODE = false

/** Configuration for different modes */
const CONTENT_CONFIG = {
  maintenance: {
    title: "Facebook's Ads servers are having issues",
    description: "Sorry for the inconvenience, but Facebook's Ads servers are having issues pulling data at the moment. The Symphony team is working with Facebook to resolve, but while this is being solved you can view campaign stats at the link below:",
    buttonText: "View on Facebook",
    footer: "We should have this resolved shortly. Your data is safe and secure and you'll see your dashboard soon."
  },
  normal: {
    title: "Your Facebook session has expired",
    description: "Please login to your Facebook account to reconnect Symphony to your Facebook. (You'll only have do this once)",
    buttonText: "Login to Facebook"
  }
} as const;

const FbTokenExpired = ({
  onSuccess,
  campaignId,
  campaignType,
}: Props) => {
  const { mobileView } = useScreen();
  const { currentBrand } = useContext(CurrentBrandContext)

  const [loading, setLoading] = useState<boolean>(false)

  const updateInBrand = async () => {
    if (!currentBrand) {
      return
    }

    try {
      let logged_in_fb_user = null;
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        logged_in_fb_user = authResp.accessToken
      } else {
        await loginWithFB()
        const authResp = FB().getAuthResponse()
        if (authResp && authResp.accessToken) {
          logged_in_fb_user = authResp.accessToken
        }
      }
      if (logged_in_fb_user) {
        await Axios.put(`/brand/${currentBrand.slug}/connect`, {
          id: currentBrand.id,
          name: currentBrand.name,
          service: 'logged_in_fb_user',
          value: {
            logged_in_fb_user,
          }
        })
        onSuccess?.()
        track('User reconnected FB', {
          brand_id: currentBrand.id,
          brand_name: currentBrand.name,
          campaign_id: campaignId,
          campaign_type: campaignType,
        })
      }
    } catch (error) {
      track('Error reconnecting Facebook', {
        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        campaign_id: campaignId,
        campaign_type: campaignType,
        error,
      })
    }
  }

  const onClickLogin = async () => {
    setLoading(true);
    await updateInBrand();
    setLoading(false);
  }

  const [getCampaignUrl, setGetCampaignUrl] = useState(false)
  const [gettingCampaignUrl, setGettingCampaignUrl] = useState(true)
  const [campaignUrl, setCampaignUrl] = useState('');


  const getCampaignInfo = async () => {
    setGettingCampaignUrl(true)
    const campaignData = await Axios.get(`/campaign/${campaignId}`)
    if (campaignData.data && campaignData.data.data) {
      const { data } = campaignData.data
      const { campaign_metadata } = data
      const {
        platform_details
      } = campaign_metadata
      const campaignIds = platform_details.campaigns
      let adAccount = platform_details.ad_account
      adAccount = adAccount.replace('act_', '')

      const ids = campaignIds.join(',');

      const url = `https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${adAccount}&nav_entry_point=am_local_scope_selector&date=2023-02-24_2023-05-03,maximum&selected_campaign_ids=${ids}`;

      // set the state
      setCampaignUrl(url);

    } else {
      // error state
    }
    setGettingCampaignUrl(false)
  }

  useEffect(() => {
    if (MAINTENANCE_MODE) {
      setGetCampaignUrl(true)
    }
    getCampaignInfo()
  }, [])

  useEffect(() => {
    if (getCampaignUrl) {
      getCampaignInfo()
    }
  }, [getCampaignUrl])

  const openIntercom = () => Intercom.openFacebookSessionArticle();

  /** Renders the button based on the current mode */
  const renderButton = () => {
    if (MAINTENANCE_MODE) {
      return (
        <SecondaryButton
          disabled={loading}
          loading={loading}
          onClick={() => campaignUrl && window.open(campaignUrl, '_blank')}
          text={!(!campaignUrl || gettingCampaignUrl) ? "View on Facebook" : ''}
          content={!campaignUrl || gettingCampaignUrl ? <div><NewLoader black={true} /></div> : ''}
        />
      );
    }

    return (
      <ButtonSymphony
        className="buttonFBTokenExpired"
        onClick={onClickLogin}
        disabled={loading}
        isLoading={loading}
        variant="outlined"
      >
        Login to Facebook
      </ButtonSymphony>
    );
  };

  /** Common layout component for both modes */
  const renderContent = () => {
    const content = MAINTENANCE_MODE ? CONTENT_CONFIG.maintenance : CONTENT_CONFIG.normal;

    return (
      <CardContainer paddingY={mobileView ? 32 : 120}>
        <ImageContainer width={mobileView ? "56%" : "40%"}>
          <img src={fbTokenExpired} alt="facebook-token-expired" />
        </ImageContainer>
        <BodyContainer>
          <Text fontSize={20} color="#000000" textAlign="center">
            {content.title}
            <button onClick={openIntercom} className="cursor">
              <SymphonyTooltip
                className="ml-2 w-6 h-6"
                title="Click here to learn more."
                arrow
              >
                <QuestionMarkIcon height={24} width={24} />
              </SymphonyTooltip>
            </button>
          </Text>
          <Text fontSize={18} color="#707070" textAlign="center">
            {content.description}
          </Text>
          {renderButton()}
          {MAINTENANCE_MODE && (
            <Text fontSize={18} color="#707070" textAlign="center">
              {CONTENT_CONFIG.maintenance.footer}
            </Text>
          )}
        </BodyContainer>
      </CardContainer>
    );
  };

  return renderContent();
}

export default FbTokenExpired;