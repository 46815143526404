import { Dialog } from '@material-ui/core';
import { ButtonContainer, ModalContainer, useStyles } from './styles';
import SecondaryButton from 'components/shareable/SecondaryButton';
import { ReactComponent as WarningIcon } from "assets/images/campaignDetails/warning.svg";
import useScreen from 'Hooks/useScreen';
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal';
import { track } from 'analytics';

interface Props {
  open: boolean;
  closeModal: () => void;
  onDiscardChanges: () => void;
  trackObject: Record<string, any>;
}

const ExitConfirmationModal = ({
  open,
  closeModal,
  onDiscardChanges,
  trackObject = {},
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useScreen()

  const handleOnClose = () => {
    track("Cancelled Edit Design", {
      ...trackObject,
      opened: 'Edit Design Modal'
    })
    closeModal()
  }

  return (
    <Dialog
      open={open}
      classes={{ root: classes.rootDialog }}
    >
      <ModalContainer width='100%'>
        <HeaderModal
          title="Are you sure you want to exit?"
          HeaderIcon={WarningIcon}
          closeModal={handleOnClose}
          subtitle="Your pre-save updates won’t be saved if you exit."
        />
        <ButtonContainer flexDirection={mobileView ? "column" : "row"} gap={mobileView ? "8px" : "16px"}>
          <SecondaryButton
            onClick={onDiscardChanges}
            text="Yes, discard my changes"
            width="100%"
            error
          />
          <SecondaryButton
            onClick={closeModal}
            text="No, don’t discard my changes"
            width='100%'
          />
        </ButtonContainer>
      </ModalContainer>
    </Dialog>
  );
};

export default ExitConfirmationModal;