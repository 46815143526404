import { useContext, useEffect, useState, useRef } from 'react';
import { CampaignDetails, CampaignMetadata, EmailGrowth, SpotifyGrowth, StyleTypes, WEBSITE_TYPES } from 'types/global';
import { get } from 'lodash';
import moment from 'moment';
import SpotifyServiceIcon from 'assets/images/streaming/svg-logos/spotify-service.svg';
import AppleServiceIcon from 'assets/images/streaming/svg-logos/apple-service.svg';
import DeezerServiceIcon from 'assets/images/streaming/svg-logos/deezer-service.svg';
import AudiomackServiceIcon from 'assets/images/streaming/svg-logos/audiomack-service.svg';
import SoundcloudServiceIcon from 'assets/images/streaming/svg-logos/soundcloud-service.svg';
import useCountryList from 'react-select-country-list';
import Demographics, {
  PresaveStreamingServiceDemographicsItem,
} from 'pages/post-auth/MarketingPage/PreSave/Components/Demographics';
import Audience from 'pages/post-auth/MarketingPage/PreSave/Components/Audience';
import Geographies from 'pages/post-auth/MarketingPage/PreSave/Components/Geographies/index';
import {
  getDemographics,
  getSpotifyGrowth,
  getEmailsGrowth,
  getGeographies,
  getAudience,
  getPresaveDailyStats,
} from 'pages/post-auth/MarketingCampaignDetails/utils';
import { track } from 'analytics';
import { CampaignStatus } from 'types/global';
import momentTz from 'moment-timezone';
import UnlockDataBanner from 'components/shareable/UnlockDataBanner';
import UnlockPresavesByDayImage from 'assets/images/unlock-presaves-by-day.svg';
import dayjs from 'dayjs';
import { getUrlParam, isPossiblyInProgress, pluralize } from "modules/Utils";
import { CampaignDetailsContainer } from '../../MusicStreams/styles';
import EditReleaseDateModal from '../../Components/Modals/EditReleaseDateModal';
import ErrorUpdatingCampaignModal from '../../Components/ErrorUpdatingCampaignModal';
import PresaveOverviewCampaignCard from '../../Components/OverviewCampaignCard/presave';
import GeneralContactSupportModal from '../../Components/GeneralContactSupportModal';
import SymphonyTabs, { TabContent } from 'components/shareable/SymphonyTabs';
import DetailCard from './DetailCard';
import {
  DetailContainer,
  GridContainer,
  TimeRemaimingContainer,
  OptionIcon,
  OptionContainer,
  WhiteContainer,
  useStyles,
} from './styles';
import PresaveSetup from '../Components/Setup';
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import { ReactComponent as PreSaves } from 'assets/images/PreSaves.svg';
import { ReactComponent as Mail } from 'assets/images/mail.svg';
import { ReactComponent as Star } from 'assets/images/outlinedStar.svg';
import { TabsContainer } from '../../styles';
import { PageTabs, TABS } from './constants';
import EditPlatformsModal from '../Components/EditPlatformsModal';
import EditBoostsModal from '../Components/EditBoostsModal';
import EditUPCISRCModal from '../Components/EditUPCISRCModal';
import EditPresaveModal from '../Components/EditPresaveModal';
import { PresaveTotals } from '../Components/EditBoostsModal/utils';
import useModal from 'Hooks/useModal';
import PresavePreview from '../Components/PresavePreview';
import useScreen from 'Hooks/useScreen';
import { Body2, Body3, Caption, Headline1 } from 'components/shareable/Typography';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import PresaveLink from '../Components/PresaveLink';
import PreviewModal from '../Components/PresavePreview/PreviewModal';
import MusicStreamsIcon from 'assets/images/campaigns/musicstreams.png';
import PlaylistingIcon from 'assets/images/campaigns/playlisting.png';
import { useHistory } from 'react-router-dom';
import WarningNoReleaseUpcCard from '../Components/WarningNoReleaseUpc/Card';
import { extractSlugsFromFrontfacingLink } from '../../utils/links';
import { EditWebsiteSlugModal } from '../../Components/Modals/EditWebsiteSlugModal';
import { Container } from 'styles/shared';
import ClickableChip from '../../Components/CreateCampaignBadge';
import { getFormattedPlatform } from '../utils';
import { siteIconsData } from 'pages/post-auth/DashboardModule/Dashboard';
import AudiomackConnectv2 from 'components/connect/Audiomack';
import SoundcloudConnectv2 from 'components/connect/Soundcloud';
import DeezerConnectv2 from 'components/connect/Deezer';
import { toast } from 'react-toastify';
import { SHARED_TOAST_OPTIONS } from '../../Components/Modals/utils';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import PrimaryButton from 'components/shareable/PrimaryButton';
import TertiaryButton from 'components/shareable/TertiaryButton';
import AllCountriesModal from '../Components/AllCountriesModal';
import { ManuallyReleaseModal } from '../Components/ManuallyReleaseModal';
import { forceRelease } from 'services/symphonyApi/campaignService';
import { fetchMarkets } from 'services/symphonyApi/brandContentService';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import MaestroBanner from '../../MaestroBanner';
import { DATE_OPTIONS } from 'pages/post-auth/DashboardModule/utils';
import BlurGraph from 'assets/images/ui/blur-graph.png';
import { sty } from '../../styles';
import UpgradeToUnlockCTA from 'pages/post-auth/Website/components/CustomizeWebsite/components/UpgradeToUnlockCTA';
import { PlanType } from 'modules/Const';
import Colors from 'modules/Colors';
import FacebookCampaignSimpleBanner from '../../Components/FacebookCampaignIssuesBanner/FacebookCampaignSimpleBanner';
import WebsiteDashboardV2 from 'pages/post-auth/Website/components/WebsiteDashboardv2';
import { WebsiteDataType } from 'pages/post-auth/Website/types';

let relativeTime = require('dayjs/plugin/relativeTime');
let customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
declare module 'dayjs' {
  interface Dayjs {
    toNow(): any;
  }
}

interface Props {
  campaignDetails: CampaignDetails;
  updateCampaignMetadata?: (data: CampaignMetadata) => void;
  handleGoBack: () => void;
  refreshCampaignDetails: () => void;
}

export enum Modals {
  EDIT_END_DATE = 'EDIT_END_DATE',
  EDIT_PLATFORMS = 'EDIT_PLATFORMS',
  EDIT_UPC_ISRC = 'EDIT_UPC_ISRC',
  EDIT_URL = 'EDIT_URL',
  EDIT_BOOSTS = 'EDIT_BOOSTS',
  EDIT_STYLE = 'EDIT_STYLE',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  ERROR_UPDATING_CAMPAIGN = 'ERROR_UPDATING_CAMPAIGN',
  VIEW_ALL_COUNTRIES = 'VIEW_ALL_COUNTRIES',
  FIND_RELEASE = 'FIND_RELEASE',
}

const { FINISHED, IN_PROGRESS, NOT_FOUND } = CampaignStatus;

const labels = {
  ACTIVE: {
    label: 'live',
    color: '#10B981',
    text: 'LIVE',
  },
  FINISHED: {
    label: 'completed',
    color: Colors.purple,
    text: 'COMPLETED',
  },
  WITH_ISSUES: {
    label: 'with_issues',
    color: Colors.yellow,
    text: 'Needs UPC/ISRC',
  },
  NOT_FOUND: {
    label: 'not_found',
    color: Colors.yellow,
    text: 'NOT FOUND',
  },
  IN_PROGRESS: {
    label: 'in_progress',
    color: Colors.yellow,
    text: 'IN PROGRESS',
  },
};

const icons: Record<string, any> = {
  spotify: SpotifyServiceIcon,
  apple_music: AppleServiceIcon,
  deezer: DeezerServiceIcon,
  audiomack: AudiomackServiceIcon,
  soundcloud: SoundcloudServiceIcon,
};

const geographiesTypes = {
  city: 'city',
  country: 'country',
};

export const formatDate = (date: string, timezone: string) => {
  const abbrev = momentTz.tz(timezone).zoneAbbr();
  if (timezone) return momentTz(date).tz(timezone).format(`dddd MMM D, YYYY ha [${abbrev}]`);
  return moment(date).format(`dddd MMM D, YYYY ha [${abbrev}]`);
};

const PreSaveDetails = ({
  campaignDetails,
  handleGoBack,
  updateCampaignMetadata,
  refreshCampaignDetails
}: Props) => {
  const classes = useStyles();
  const { currentBrand, isPaidBrand, isBrandAdmin } = useContext(CurrentBrandContext);
  const { mobileView } = useScreen();

  const AUDIENCE_LIMIT = 25;



  const [selectedGeographiesTab, setSelectedGeographiesTab] = useState('country');
  const [selectedAudienceTab, setSelectedAudienceTab] = useState<'current' | 'previous'>('current')
  const [audienceOffset, setAudienceOffset] = useState<number>(0);
  const [audienceOrderBy, setAudienceOrderBy] = useState<string>('createdAt');
  const [audienceOrderDirection, setAudienceOrderDirection] = useState<string>('desc');

  const [preSaveMetrics, setPreSaveMetrics] = useState<WebsiteDataType | null>(null)

  const {
    campaign_metadata: { content, link, presave, startDate, endDate },
    emailsData,
    presavesData,
    foreverSavesData,
    newFollowersData,
  } = campaignDetails || {};

  const startDateMin = new Date(campaignDetails.createdAt)
  const endDateMax = endDate ? dayjs(endDate).toDate() : dayjs().toDate()

  let status = campaignDetails?.status || '';
  const timezone = presave?.options?.timezone || '';
  const { thumbnail_url, name, slug } = content;
  const { platforms, boosts, release_url_upc_isrc, created_with_upc, local_release } = presave || {};
  let campaignStatus: any = get(labels, status, {});

  const linkSlugs = extractSlugsFromFrontfacingLink(link, currentBrand?.slug!);

  const [demographics, setDemographics] = useState<PresaveStreamingServiceDemographicsItem[]>([]);
  const [demographicsLoading, setDemographicsLoading] = useState<boolean>(true);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [audience, setAudience] = useState<any[]>([]);
  const [audienceCount, setAudienceCount] = useState<number>(0);
  const [countryGeographies, setCountryGeographies] = useState([]);
  const [selectedView, setSelectedView] = useState<PageTabs>(PageTabs.PERFORMANCE);
  const [cityGeographies, setCityGeographics] = useState([]);
  const [dailyStats, setDailyStats] = useState([]);
  const [spotifyGrowth, setSpotifyGrowth] = useState<SpotifyGrowth | null>(null);
  const [emailsGrowth, setEmailsGrowth] = useState<EmailGrowth | null>(null);
  const [markets, setMarkets] = useState([]);
  const isAfterFansRelease = moment(startDate).isAfter('2023-03-14T20:30:34Z');

  // this is used in the WITH_ISSUEs state - a user has the option to refresh releases
  const [refreshingReleases, setRefreshingReleases] = useState<boolean>(false);
  const [errorRefreshingReleases, setErrorRefreshingReleases] = useState<boolean>(false);
  // edit options - fpr date
  const [releaseDate, setReleaseDate] = useState<{
    date: string;
    timezone: string;
  }>({
    date: endDate,
    timezone: timezone,
  });

  // sets locally when a user updates the url successfully, so we dont need to update ALL of the values
  const [newLocalSlug, setNewLocalSlug] = useState<string>(linkSlugs ? linkSlugs.urlSlug : '');
  const [localUrl, setLocalUrl] = useState(linkSlugs ? linkSlugs.brandSlug + '-' + linkSlugs.urlSlug : '');

  const countryList = useCountryList();
  const daysLeft = moment(releaseDate.date).diff(moment(), 'days');
  const statusChecks = {
    isFinalized: status === FINISHED,
    isInProgress:
      status === IN_PROGRESS ||
      isPossiblyInProgress(
        status,
        local_release,
        release_url_upc_isrc,
        releaseDate.date
      ),
    isNotFound: status === NOT_FOUND,
  };
  const { isFinalized, isInProgress, isNotFound } = statusChecks;
  const hasEndDatePassed = moment(releaseDate.date).isBefore();
  const createdWithUPC = created_with_upc ?? true;

  const campaignId = campaignDetails.id;
  const restrictGraphEndDate = isFinalized || hasEndDatePassed;
  const defaultDateRange = restrictGraphEndDate ? 'all-time' : 'last-30-days';

  const trackObject = {
    campaign_id: campaignId,
    campaign_type: 'pre_save',
    brand_id: currentBrand?.id,
    brand_slug: currentBrand?.slug,
    days_left: daysLeft,
    created_with_upc: createdWithUPC,
  };

  const editEndDateModal = useModal({
    nameTrack: 'Open Release Date',
    metadata: {
      ...trackObject,
      opened: 'Edit Release Date Modal',
    },
  });
  const editPlatformsModal = useModal({
    nameTrack: 'Open Edit Platforms',
    metadata: {
      ...trackObject,
      opened: 'Edit Platforms Modal',
    },
  });
  const editStylesModal = useModal({
    nameTrack: 'Open Edit Design',
    metadata: {
      ...trackObject,
      opened: 'Edit Design Modal',
    },
  });
  const editUPCISRCModal = useModal({
    nameTrack: 'Open Edit UPC/ISRC',
    metadata: {
      ...trackObject,
      created_with_upc: createdWithUPC,
      opened: 'Edit UPC/ISRC Modal',
    },
  });
  const editBoostsModal = useModal({
    nameTrack: 'Open Edit Boosts',
    metadata: {
      ...trackObject,
      opened: 'Edit boosts Modal',
    },
  });
  const editUrlModal = useModal({
    nameTrack: 'Open Edit URL',
    metadata: {
      ...trackObject,
      opened: 'Edit URL Modal',
    },
  });
  const viewAllCountriesModal = useModal({
    nameTrack: 'View All Countries',
    metadata: {
      ...trackObject,
      opened: 'View All Countries Modal',
    },
  });
  const showContactSupportModal = useModal();
  const errorUpdatingCampaignModal = useModal();
  const previewModal = useModal();
  const manuallyFindReleaseModal = useModal();

  const handleOpenModals = (modal: Modals, source?: string) => {
    switch (modal) {
      case Modals.EDIT_END_DATE:
        editEndDateModal.openModal(source);
        break;
      case Modals.EDIT_PLATFORMS:
        editPlatformsModal.openModal(source);
        break;
      case Modals.EDIT_STYLE:
        editStylesModal.openModal(source);
        break;
      case Modals.EDIT_UPC_ISRC:
        editUPCISRCModal.openModal(source);
        break;
      case Modals.EDIT_BOOSTS:
        editBoostsModal.openModal(source);
        break;
      case Modals.CONTACT_SUPPORT:
        showContactSupportModal.openModal(source);
        break;
      case Modals.EDIT_URL:
        editUrlModal.openModal(source);
        break;
      case Modals.ERROR_UPDATING_CAMPAIGN:
        errorUpdatingCampaignModal.openModal(source);
        break;
      case Modals.VIEW_ALL_COUNTRIES:
        viewAllCountriesModal.openModal(source);
        break;
      case Modals.FIND_RELEASE:
        manuallyFindReleaseModal.openModal(source);
        break;
      default:
        break;
    }
  };

  const handleChangeReleaseDate = (releaseDate: string, timeZone: string) => {
    setReleaseDate({
      date: releaseDate,
      timezone: timeZone,
    });
  };

  useEffect(() => {
    const tabParam = getUrlParam('tab')?.toString().toLowerCase();
    
    if (tabParam === 'style') {
      handleOpenModals(Modals.EDIT_STYLE, 'url_param');
    }
  }, []);

  
  useEffect(() => {
    track('Campaign Details Viewed', {
      type: 'pre_save',
      brand_id: currentBrand?.id,
      brand_name: currentBrand?.name,
      brand_slug: currentBrand?.slug,
      content_name: content.name,
      ...(campaignDetails.campaign_metadata ? campaignDetails.campaign_metadata : {}),
    });
    setLoading(false);
  }, [campaignDetails]);

  useEffect(() => {
    if (startDate && endDate) {
      (async () => {
        try {
          const dailyGrowth = await getPresaveDailyStats(currentBrand?.slug || '', slug);
          setDailyStats(dailyGrowth);

          if (isPaidBrand) {
            const spotifyGrowth = await getSpotifyGrowth(startDate, endDate, currentBrand?.id!);
            setSpotifyGrowth(spotifyGrowth);
            const emailsGrowth = await getEmailsGrowth(startDate, endDate, currentBrand?.slug || '', slug);
            setEmailsGrowth(emailsGrowth);
          }

          const demographics: any[] = await getDemographics(currentBrand?.slug || '', slug);
          setDemographics(
            demographics.map((demographic) => {
              const { name, platform, tier, count, percentage } = demographic;
              return {
                name,
                platform,
                tier,
                count,
                percentage,
                icon: icons[platform as string],
              };
            })
          );
          setDemographicsLoading(false)
          if (isPaidBrand) {
            const geographies = await getGeographies(currentBrand?.slug || '', slug, geographiesTypes.country);
            setCountryGeographies(geographies);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [startDate, endDate, currentBrand?.id, currentBrand?.slug, slug]);

  useEffect(() => {
    if (startDate && endDate && selectedView === PageTabs.AUDIENCE) {
      (async () => {
        try {
          const { audience, count } = await getAudience(currentBrand?.slug || '', slug, selectedAudienceTab, AUDIENCE_LIMIT, audienceOffset, audienceOrderBy, audienceOrderDirection);
          setAudience(
            audience.map((audience: any[]) => ({
              ...audience,
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            }))
          );
          setAudienceCount(count);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [currentBrand?.name, endDate, selectedView, slug, startDate, selectedAudienceTab, audienceOffset, audienceOrderBy, audienceOrderDirection]);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      !cityGeographies.length &&
      selectedGeographiesTab === geographiesTypes.city &&
      isPaidBrand
    ) {
      (async () => {
        try {
          const geographies = await getGeographies(currentBrand?.slug || '', slug, geographiesTypes.city);
          setCityGeographics(geographies);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [currentBrand?.name, cityGeographies.length, endDate, selectedGeographiesTab, slug, startDate]);

  useEffect(() => {
    if (local_release) initMarkets();
  }, [local_release]);

  const initMarkets = async () => {
    try {
      const markets = await fetchMarkets(slug);
      setMarkets(markets);
    } catch (error) {
      console.log(error);
    }
  };

  const onGeographiesClickTab = (evt: any) => setSelectedGeographiesTab(evt.target.id);

  const totalValues: PresaveTotals = {
    auto_presaves: campaignDetails?.presavesData?.total || 0,
    boost_follows: campaignDetails?.newFollowersData?.total || 0,
    collect_emails: campaignDetails?.emailsData?.total || 0,
  };

  const getTotalTimeRemaining = () => {
    let date = moment(releaseDate.date);
    if (releaseDate.timezone) date = momentTz(releaseDate.date).tz(timezone);
    const timeRemaining = date.fromNow(true).split(' ');
    if (timeRemaining[0] === 'a' || timeRemaining[0] === 'an') timeRemaining[0] = '1';
    return { amount: timeRemaining[0], unit: timeRemaining[1] };
  };

  const handleChangeCampaignMetadata = (campaign: CampaignDetails) => {
    updateCampaignMetadata?.(campaign.campaign_metadata);
  };

  const initialFetchRef = useRef(false);

  useEffect(() => {
    if (!preSaveMetrics && !initialFetchRef.current) {
      initialFetchRef.current = true;
      handleRefreshPreSaveData(restrictGraphEndDate ? 'all-time' : 'last-30-days');
    }
  }, [preSaveMetrics]);


  const handleRefreshPreSaveData = async (currentTimeRange: DATE_OPTIONS, customStartDate?: string, customEndDate?: string) => {
    try {
      let startDate = null
      let endDate = null
      switch (currentTimeRange) {
        case 'last-30-days':
          endDate = dayjs().format('YYYY-MM-DD')
          startDate = dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD')
          break;
        case 'last-7-days':
          endDate = dayjs().format('YYYY-MM-DD')
          startDate = dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')
          break;
        case 'last-3-months':
          endDate = dayjs().format('YYYY-MM-DD')
          startDate = dayjs().subtract(3, 'months').startOf('day').format('YYYY-MM-DD')
          break;
        case 'all-time':
          // Assuming you want to fetch all data from the beginning
          startDate = startDateMin ? dayjs(startDateMin).format('YYYY-MM-DD') : null
          endDate = endDateMax ? dayjs(endDateMax).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
          break;
        case 'custom':
          if (customStartDate && customEndDate) {
            startDate = dayjs(customStartDate).format('YYYY-MM-DD')
            endDate = dayjs(customEndDate).format('YYYY-MM-DD')
          } else {
            startDate = startDateMin ? dayjs(startDateMin).format('YYYY-MM-DD') : null
            endDate = endDateMax ? dayjs(endDateMax).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
          }
          break;
        default:
          break;
      }

      const websiteData = await getPresaveDailyStats(currentBrand?.slug || '', slug, startDate, endDate)
      setPreSaveMetrics(websiteData)
    } catch (e) {
      console.error(e)
    }
  }

  const previewBgColor = presave?.style?.bgType === 'color' ? presave?.style?.bgColor : '';

  const contentDetails = {
    artworkUrl: content.thumbnail_url,
    name: content.name,
    options:
      presave?.platforms.map((platform: string) => {
        return { name: platform, show: true };
      }) || [],
    subtitle: presave?.subtitle || '',
  };

  const history = useHistory();

  const createMusicStreamsCampaign = () => {
    history.push(`/marketing/new/music-streams`);
  };

  const createPlaylistingCampaign = () => {
    history.push(`/marketing/new/playlists`);
  };

  // If a user has enabled auto-follow, this will be used to denote
  // that the platform is properly connect to allow for autofollows.
  const [showConnectBox, setShowConnectBox] = useState<string | null>(null);
  const [autofollowConnectedPlatforms, setAutofollowConnectPlatforms] = useState<{
    spotify: boolean;
    deezer: boolean;
    audiomack: boolean;
    soundcloud: boolean;
    [key: string]: boolean; // Add index signature
  }>({
    spotify: true,
    deezer: currentBrand?.connections?.deezer ? true : false,
    audiomack: currentBrand && currentBrand.connections.audiomack ? true : false,
    soundcloud: currentBrand?.connections?.soundcloud_artist_page?.id ? true : false,
  });

  const showConnectModalforStreamingService = (platformId: string) => {
    setShowConnectBox(platformId);
  }

  // this will update the draggable options, and set the connected value
  // to true for the platform that was just connected.
  const enableAutosaveOnPlatform = (platformId: string) => {
    setAutofollowConnectPlatforms({
      ...autofollowConnectedPlatforms,
      [platformId]: true,
    });

    // show a toast
    toast.success(`Enabled Boost Followers on ${getFormattedPlatform(platformId)}!`, SHARED_TOAST_OPTIONS);
  }

  const renderUpgradeToLiteCTA = (text: string) => {
    if (!isPaidBrand) {
      return <UpgradeToUnlockCTA
        highlightedPlan={PlanType.LITE}
        requiresPaidPlan={true} buttonText={text} />
    }
    return (undefined);
  }

  const showLinkStreamingServicesButtons = () => {
    if (!isPaidBrand) {
      return renderUpgradeToLiteCTA('Upgrade to unlock Auto-Followers')
    }
    const mappedChips = Object.keys(autofollowConnectedPlatforms)
      .filter((platformId: string) => !autofollowConnectedPlatforms[platformId])
      .map((platformId: string) => {
        const enabled = autofollowConnectedPlatforms[platformId];

        const platformImage = siteIconsData[platformId];
        return (
          <ClickableChip
            campaignName={`Link ${getFormattedPlatform(platformId)} to enable auto-followers`}
            campaignImage={platformImage}
            onClick={() => showConnectModalforStreamingService(platformId)}
          />
        );
      });
    return (
      <Container
        display="flex"
        flexDirection="row"
        gap="10px"
        alignItems="flex-start"
        marginTop="12px"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {mappedChips}
      </Container>
    );
  }

  const refreshReleasesOnError = async () => {
    setRefreshingReleases(true);
    setErrorRefreshingReleases(false);
    try {
      const data = await forceRelease(content.slug);
      if (data) {
        await refreshCampaignDetails();
        toast.success(`Your pre-save campaign was successfully updated!`, SHARED_TOAST_OPTIONS);
      }
    } catch (e) {
      toast.error(`There was an error updating your pre-save campaign`, SHARED_TOAST_OPTIONS);
      setErrorRefreshingReleases(true);
    } finally {
      setRefreshingReleases(false);
    }
  }
  const renderIssueResolutionNotes = () => {
    // if the status is with issues, but the release date is past and the user has entered UPC/ISRC,
    // give them the option to manually swap over
    if (status === 'WITH_ISSUES') {
      if (releaseDate.date && moment().isAfter(moment(releaseDate.date).add(10, 'minutes')) && release_url_upc_isrc) {
        return (
          <FacebookCampaignSimpleBanner
            title="It looks like we weren't able to find your release. This may be due to an incorrect UPC / ISRC or incorrect release date."
            description="You can solve this easily by refreshing releases below."
            ctaComponent={
              <div className="flex gap-4 justify-center flex-col">
                <PrimaryButton
                  disabled={refreshingReleases}
                  loading={refreshingReleases}
                  text="Pull New Releases"
                  onClick={() => refreshReleasesOnError()}
                />
                {refreshingReleases && <p>Finding your release...</p>}
                {errorRefreshingReleases && (
                  <p>
                    There was an error finding your release... Please contact support and we can help resolve this
                    issue!
                  </p>
                )}
              </div>
            }
          />
        );
      }
    }
    return null;
  }

  const getCountryName = (code: string) => {
    const country = countryList.data.find((c) => c.value === code);
    return country ? country.label : code;
  };

  const releaseTime = moment(releaseDate.date);
  const currentTime = moment();
  const timeDiff = currentTime.diff(releaseTime, 'hours');
  const marketsToShow = markets?.length ? markets.slice(0, markets.length < 2 ? 1 : 2) : [];
  const notReleasedOnAllMarkets =
    status === FINISHED && local_release && timeDiff <= 24 && markets.length > 0 && markets.length < 200;

  if (notReleasedOnAllMarkets) campaignStatus = get(labels, 'ACTIVE', {});
  const inProgress = isPossiblyInProgress(status, local_release, release_url_upc_isrc, releaseDate.date);
  if (inProgress) campaignStatus = get(labels, 'IN_PROGRESS', {});

  const renderMaestroBanner = () => {
    return null;

    if (!isPaidBrand) {
      return null;
    }

    return (
      <MaestroBanner
        className="presaveDetailsMaestroBanner"
        campaignDetails={campaignDetails}
      />
    )
  }

  const renderPresaveLinkContainer = () => {
    return (
      <>
        {!isFinalized && !Boolean(release_url_upc_isrc) && (
          <WarningNoReleaseUpcCard
            handleClickAdd={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
            releaseDate={releaseDate.date}
            songName={name}
            key={`warning-card-${mobileView ? '' : 'no-mobile-'}${name}`}
          />
        )}
        <PresaveLink
          brandSlug={linkSlugs.brandSlug}
          contentSlug={newLocalSlug}
          brandName={currentBrand?.name!}
          style={presave?.style!}
          songName={content.name}
          status={status}
          presaveImage={contentDetails.artworkUrl}
          openPresaveModal={previewModal.openModal}
          closePresaveModal={previewModal.closeModal}
          brandId={currentBrand?.id!}
          campaignId={campaignDetails?.id!}
          daysLeft={daysLeft}
          canEdit={isBrandAdmin}
          onOpenEditLinkModal={mobileView ? undefined : () => handleOpenModals(Modals.EDIT_URL)}
        />
      </>
    );
  };

  const renderPresaveLinkPreview = () => {
    return (
      <PresavePreview
        className="preSavePreviewFromPresave"
        style={presave?.style!}
        contentDetails={contentDetails}
        styleType={presave?.style?.buttonConfig as StyleTypes}
        bgColor={previewBgColor}
        openEditModal={editStylesModal.openModal}
        isFinalized={isFinalized}
        canEdit={isBrandAdmin}
      />
    )
  }
  const renderPreSavesByDayGraph = () => {
    if (!isPaidBrand) {
      return null;
    }


    let allowedDateRanges: DATE_OPTIONS[] = []
    if (restrictGraphEndDate) {
      allowedDateRanges = ['all-time', 'custom']
    } else {
      allowedDateRanges = ['last-30-days', 'last-7-days', 'last-3-months', 'all-time', 'custom']
    }

    return (
      <WebsiteDashboardV2
        noTitles
        showProIcon={false}
        fetchOnMount={false}
        allowedDateRanges={allowedDateRanges}
        startDateMin={startDateMin}
        endDateMax={endDateMax}
        id={campaignDetails?.id!}
        style={{ padding: 0 } as React.CSSProperties}
        websiteType={WEBSITE_TYPES.PRESAVE}
        slug={slug}
        defaultDateRange={defaultDateRange}
        handleRefreshData={handleRefreshPreSaveData}
        noWebsiteSpecificContent
        metricsOverride={preSaveMetrics || undefined}
      />
    )
  }

  // NOTE: render()
  return (
    <CampaignDetailsContainer
      className="preSaveDetailsComponent"
      backgroundColor={Colors.greyLighter}
      maxWidth={mobileView ? "100%" : "85%"}
      gap="16px"
    >
      <PresaveOverviewCampaignCard
        name={name}
        thumbnail={thumbnail_url}
        campaignStatusLabel={campaignStatus.text}
        campaignStatus={campaignStatus.label}
        goBack={handleGoBack}
        handleEditPresaveDesign={() => handleOpenModals(Modals.EDIT_STYLE, 'dropdown')}
        handleEditBoost={() => handleOpenModals(Modals.EDIT_BOOSTS, 'dropdown')}
        handleEditPlatforms={() => handleOpenModals(Modals.EDIT_PLATFORMS, 'dropdown')}
        handleEditReleaseDate={() => handleOpenModals(Modals.EDIT_END_DATE, 'dropdown')}
        handleEditUpcIsrc={() => handleOpenModals(Modals.EDIT_UPC_ISRC, 'dropdown')}
        handleEditUrl={() => handleOpenModals(Modals.EDIT_URL, 'dropdown')}
        isFinalized={isFinalized}
      />
      {renderIssueResolutionNotes()}

      <div
        className="maestroBannerRow"
        style={sty.maestroBannerRow}
      >
        <div
          className="leftBodyContainer"
          style={sty.leftBodyContainer}
        >
          {renderMaestroBanner()}

          <DetailContainer flexDirection="column">
            {mobileView && renderPresaveLinkContainer()}
            <DetailContainer flexDirection={mobileView ? 'column' : 'row'}>
              <DetailContainer flexDirection="column" width="100%">
                {!mobileView && renderPresaveLinkContainer()}
                <TabsContainer padding={mobileView ? '16px 0px' : '16px 24px'}>
                  <nav className="border-b">
                    <SymphonyTabs
                      data={TABS}
                      onChange={(newValue) => setSelectedView(newValue as PageTabs)}
                      selectedValue={selectedView}
                      tabContentStyle={{ paddingRight: 0, paddingLeft: 0 }}
                    />
                  </nav>
                  {isLoading ? (
                    <div className="flex-col flex items-center ">
                      <NewLoader black />
                      <p className="mt-1 text-center">Loading Dashboard...</p>
                    </div>
                  ) : (
                    <>
                      <TabContent hidden={selectedView !== PageTabs.PERFORMANCE}>
                        <>
                          {notReleasedOnAllMarkets && (
                            <div className="flex flex-col px-6 pt-4 gap-4">
                              <TimeRemaimingContainer>
                                <div className="flex flex-col gap-1 justify-center w-full">
                                  <div>
                                    <Body2 color={Colors.textColor}>
                                      Your song/album is currently releasing across geographies.
                                    </Body2>
                                    {Boolean(marketsToShow.length) && (
                                      <Body2 color={Colors.textColor}>
                                        {' '}
                                        It’s already been released in{' '}
                                        {marketsToShow.map((market, index) => (
                                          <Body2 color={Colors.textColor}>
                                            {index > 0 && ', '}
                                            {getCountryName(market)}
                                          </Body2>
                                        ))}
                                        {markets.length > 2 && (
                                          <Body2 color={Colors.textColor}>
                                            {' '}
                                            and {markets.length - 2} more countries
                                          </Body2>
                                        )}
                                      </Body2>
                                    )}
                                  </div>
                                  <div className="flex gap-1 items-center">
                                    <Body2>Release date:</Body2>
                                    <CalendarIcon color={Colors.purple} width={14} height={14} />
                                    <Caption className={classes.oneLineText}>
                                      {momentTz(releaseDate.date).tz(releaseDate.timezone).format(`MMMM DD, YYYY h A z`)}
                                    </Caption>
                                  </div>
                                  <TertiaryButton
                                    text="View all countries"
                                    onClick={() => handleOpenModals(Modals.VIEW_ALL_COUNTRIES)}
                                  ></TertiaryButton>
                                </div>
                              </TimeRemaimingContainer>
                            </div>
                          )}
                          {isInProgress && (
                            <div className="flex flex-col px-6 pt-4 gap-4">
                              <TimeRemaimingContainer>
                                <div className="flex flex-col gap-1 justify-center w-full">
                                  <Body2 color={Colors.textColor}>
                                    We're in the process of finding your release. This may take a few minutes as find your
                                    release across different platforms.
                                  </Body2>
                                  <Body2>Please ensure your UPC/ISRC and release date look correct below:</Body2>
                                  <WhiteContainer>
                                    <Body3 color={Colors.textColor}>Release Date:</Body3>
                                    <div className="flex gap-4 justify-between items-center w-full">
                                      <Body2 color={Colors.textColor} className={classes.oneLineText}>
                                        {formatDate(releaseDate.date, releaseDate.timezone)}
                                      </Body2>
                                      <TertiaryButton
                                        onClick={() => handleOpenModals(Modals.EDIT_END_DATE)}
                                        text="Edit"
                                        height="15px"
                                      />
                                    </div>
                                  </WhiteContainer>
                                  <WhiteContainer>
                                    <Body3 color={Colors.textColor}>UPC/ISRC:</Body3>
                                    <div className="flex gap-4 justify-between items-center w-full">
                                      <Body2 color={Colors.textColor} className={classes.oneLineText}>
                                        {release_url_upc_isrc || 'Unset'}
                                      </Body2>
                                      <TertiaryButton
                                        onClick={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
                                        text="Edit"
                                        height="15px"
                                      />
                                    </div>
                                  </WhiteContainer>
                                  <Body2>
                                    If all of the above looks good, you can manually search for your release to convert it
                                    automatically:
                                  </Body2>
                                  <PrimaryButton
                                    text="Find your release manually"
                                    onClick={() => handleOpenModals(Modals.FIND_RELEASE)}
                                  />
                                </div>
                              </TimeRemaimingContainer>
                            </div>
                          )}
                          {isNotFound && (
                            <div className="flex flex-col px-6 pt-4 gap-4">
                              <TimeRemaimingContainer isNotFound>
                                <div className="flex flex-col gap-2 justify-center flex-wrap overflow-auto w-full">
                                  <Body2 color={Colors.textColor}>
                                    We’re having issues finding your release!
                                  </Body2>
                                  <Body2>Please ensure your UPC/ISRC and release date look correct below:</Body2>
                                  <WhiteContainer>
                                    <Body3 color={Colors.textColor}>Release Date:</Body3>
                                    <div className="flex gap-4 justify-between items-center w-full">
                                      <Body2 color={Colors.textColor} className={classes.oneLineText}>
                                        {formatDate(releaseDate.date, releaseDate.timezone)}
                                      </Body2>
                                      <TertiaryButton
                                        onClick={() => handleOpenModals(Modals.EDIT_END_DATE)}
                                        text="Edit"
                                        height="15px"
                                      />
                                    </div>
                                  </WhiteContainer>
                                  <WhiteContainer>
                                    <Body3 color={Colors.textColor}>UPC/ISRC:</Body3>
                                    <div className="flex gap-4 justify-between items-center w-full">
                                      <Body2 color={Colors.textColor} className={classes.oneLineText}>
                                        {release_url_upc_isrc}
                                      </Body2>
                                      <TertiaryButton
                                        onClick={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
                                        text="Edit"
                                        height="15px"
                                      />
                                    </div>
                                  </WhiteContainer>
                                  <Body2>
                                    If all of the above looks good, you can manually search for your release to convert it
                                    automatically:
                                  </Body2>
                                  <PrimaryButton
                                    text="Find your release manually"
                                    onClick={() => handleOpenModals(Modals.FIND_RELEASE)}
                                  />
                                </div>
                              </TimeRemaimingContainer>
                            </div>
                          )}
                          <div className="flex flex-col px-6 pt-4 gap-4">
                            {!notReleasedOnAllMarkets &&
                              (!isFinalized ? (
                                <TimeRemaimingContainer>
                                  <Headline1>{hasEndDatePassed ? 0 : getTotalTimeRemaining().amount}</Headline1>
                                  <div className="flex flex-col gap-1 justify-center">
                                    <Body2>{getTotalTimeRemaining().unit} left until release</Body2>
                                    <div className="flex gap-1 items-center">
                                      <CalendarIcon color="#8800FF" width={14} height={14} />
                                      <Caption>
                                        {momentTz(releaseDate.date).tz(releaseDate.timezone).format(`MMMM DD, YYYY h A z`)}
                                      </Caption>
                                    </div>
                                  </div>
                                </TimeRemaimingContainer>
                              ) : (
                                <TimeRemaimingContainer>
                                  <div className="flex flex-col gap-4 justify-center">
                                    <Body2 color={Colors.textColor}>Your song has been released!</Body2>
                                    <Body2>Promote your song with these campaigns:</Body2>
                                    <div className="flex gap-2">
                                      <OptionContainer onClick={createMusicStreamsCampaign}>
                                        <OptionIcon src={MusicStreamsIcon} />
                                        <Caption color={Colors.purple}>Increase music streams</Caption>
                                      </OptionContainer>
                                      <OptionContainer onClick={createPlaylistingCampaign}>
                                        <OptionIcon src={PlaylistingIcon} />
                                        <Caption color={Colors.purple}>Get playlisted</Caption>
                                      </OptionContainer>
                                    </div>
                                  </div>
                                </TimeRemaimingContainer>
                              ))}
                            <Body2>
                              {status === FINISHED
                                ? `Your presave was automatically converted into a landing page with all of your streaming links.
                                                    You can see stats for your landing page on the "Website" dashboard.`
                                : 'Once your music releases, your presave page will automatically convert into a landing page with all streaming links pulled in.'}
                            </Body2>
                          </div>
                          <div className="px-6 py-6 border-b">
                            <GridContainer>
                              <DetailCard
                                svgIcon={<PreSaves />}
                                title="Total Pre-Saves"
                                value={presavesData?.new!}
                                firstStatTitle="Fans who pre-saved from your website"
                                secondStatTitle="Fans who Forever Saved your past releases"
                                secondStatValue={Math.trunc(presavesData?.total! - presavesData?.new!).toLocaleString()}
                                thirdStatTitle="Total Pre-saves on this release"
                                thirdStatValue={presavesData?.total!}
                                useThreeColumnLayout
                              />
                              <DetailCard
                                svgIcon={<Star />}
                                title="Forever Saves Subscribers"
                                firstStatTitle="Fans who subscribed to Forever Saves from this release"
                                value={foreverSavesData?.new!}
                                secondStatTitle="Fans who subscribed from past releases"
                                secondStatValue={Math.trunc(foreverSavesData?.total! - foreverSavesData?.new!).toLocaleString()}
                                thirdStatTitle="Total Forever Save Subscribers"
                                thirdStatValue={foreverSavesData?.total!}
                                subtitle={
                                  isPaidBrand
                                    ? `This release and all your future releases will start with ${foreverSavesData?.total} saves from day 1.`
                                    : `Upgrade to Lite to unlock ${foreverSavesData?.total === 1
                                      ? `${foreverSavesData?.total} Forever Save.`
                                      : `${foreverSavesData?.total} Forever Saves.`
                                    }`
                                }
                                paidExclusive
                                useThreeColumnLayout
                                additionalContent={renderUpgradeToLiteCTA('Upgrade to Lite to unlock Forever Saves')}
                              />
                              {isAfterFansRelease ?
                                <DetailCard
                                  svgIcon={<Mail stroke="#8800FF" />}
                                  title="Collected Emails"
                                  firstStatTitle="Emails collected in this release"
                                  value={emailsData?.new!}
                                  secondStatTitle="Emails collected from past releases"
                                  secondStatValue={Math.trunc(emailsData?.total! - emailsData?.new!).toLocaleString()}
                                  thirdStatTitle="Total emails"
                                  thirdStatValue={emailsData?.total!}
                                  subtitle={`You've collecting ${emailsData?.new} emails with this release, bringing your total list to ${emailsData?.total} emails.`}
                                  useThreeColumnLayout
                                />
                                :
                                <DetailCard
                                  svgIcon={<Mail stroke="#8800FF" />}
                                  title="Emails"
                                  value={emailsData?.total!}
                                  firstStatTitle="Total"
                                  isMoney={false}
                                  arrow
                                />
                              }
                              <DetailCard
                                svgIcon={<Star />}
                                title="New Streaming Service Followers"
                                firstStatTitle="From this release"
                                value={newFollowersData?.new!}
                                secondStatTitle="Across all Releases"
                                secondStatValue={newFollowersData?.total!}
                                isMoney={false}
                                paidExclusive
                                subtitle={
                                  newFollowersData?.new ?
                                    `${newFollowersData?.new} ${pluralize('fan', newFollowersData?.new)} followed you on their streaming platform when they pre-saved this release.`
                                    :
                                    'When fans pre-save, they\'ll automatically follow you on their preferred streaming platform.'
                                }
                                additionalContent={showLinkStreamingServicesButtons()}
                              />
                            </GridContainer>
                          </div>
                          <div className="px-6 py-6 border-b">
                            <Demographics contentName={name} shown loading={demographicsLoading} demographicBreakdown={demographics} />
                          </div>
                          <div className="px-6 pt-6">
                            <p className="text-lg font-semibold flex items-center gap-1.5">
                              Pre-saves & Visits by Day <UpgradeToProChip type="gem" color="purple" height={18} width={18} />
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              This graph shows how many visitors and pre-saves came in every day.
                            </p>
                            <div className="mt-4">
                              {!isPaidBrand &&
                                <div className="py-5 pt-0">
                                  <UnlockDataBanner
                                    source="Presave Campaign Details - Pre-Saves by Day"
                                    title="Pre-saves & Visits by Day"
                                    description="Go Pro to see how many pre-saves are coming per day."
                                    image={UnlockPresavesByDayImage}
                                    hideImage={mobileView}
                                    unlockedWithLite={true}
                                  />
                                  <img src={BlurGraph} draggable={false} alt="blur-graph" className="w-full object-cover" />
                                </div>
                              }
                              {renderPreSavesByDayGraph()}
                            </div>
                          </div>
                        </>
                      </TabContent>
                      <TabContent hidden={selectedView !== PageTabs.AUDIENCE}>
                        <div className="pt-5">
                          <Audience
                            shown
                            audienceBreakdown={audience}
                            audienceTotal={audienceCount}
                            orderBy={audienceOrderBy}
                            setOrderBy={setAudienceOrderBy}
                            setOrderDirection={setAudienceOrderDirection}
                            limit={AUDIENCE_LIMIT}
                            offset={audienceOffset}
                            setOffset={setAudienceOffset}
                            showBlurredDataRow={!isPaidBrand}
                            selectedAudienceTab={selectedAudienceTab}
                            setSelectedAudienceTab={setSelectedAudienceTab}
                          />
                        </div>
                      </TabContent>
                      <TabContent hidden={selectedView !== PageTabs.GEOGRAPHIES}>
                        <div className="pt-5">
                          <Geographies
                            cityGeographies={cityGeographies}
                            countryGeographies={countryGeographies}
                            hideBannerImage={mobileView}
                            onGeographiesClickTab={onGeographiesClickTab}
                            selectedGeographiesTab={selectedGeographiesTab}
                          />
                        </div>
                      </TabContent>
                      <TabContent hidden={selectedView !== PageTabs.SETUP}>
                        <PresaveSetup
                          releaseDate={releaseDate.date}
                          timezone={timezone}
                          boosts={boosts}
                          platforms={platforms}
                          urlUpcIsrc={release_url_upc_isrc || ''}
                          handleOpenModals={handleOpenModals}
                          isFinalized={isFinalized}
                        />
                      </TabContent>
                    </>
                  )}
                </TabsContainer>
              </DetailContainer>

            </DetailContainer>
          </DetailContainer>
        </div>
        {renderPresaveLinkPreview()}

        <EditPresaveModal
          {...editStylesModal}
          onSave={handleChangeCampaignMetadata}
          campaign={campaignDetails}
          trackObject={trackObject}
        />
        <EditPlatformsModal
          {...editPlatformsModal}
          onSave={handleChangeCampaignMetadata}
          campaign={campaignDetails}
          trackObject={trackObject}
        />
        <EditReleaseDateModal
          {...editEndDateModal}
          campaignId={campaignId}
          releaseDate={releaseDate.date}
          isLocalRelease={Boolean(local_release)}
          timezone={timezone}
          hideReleaseDate={Boolean(campaignDetails.campaign_metadata.presave?.hideReleaseDate)}
          reload={handleChangeReleaseDate}
          onErrorHandler={() => handleOpenModals(Modals.ERROR_UPDATING_CAMPAIGN)}
          trackObject={trackObject}
          status={status}
        />
        <GeneralContactSupportModal
          {...showContactSupportModal}
          title="Edit your Pre-save"
          message="We'll be rolling out the ability to edit pre-saves soon! In the interim, reach out to our team and we'll get things updated ASAP:"
          campaignId={campaignId}
        />
        <ErrorUpdatingCampaignModal {...errorUpdatingCampaignModal} campaignId={campaignId} />
        <EditUPCISRCModal
          {...editUPCISRCModal}
          onSave={handleChangeCampaignMetadata}
          campaignId={campaignId}
          value={release_url_upc_isrc}
          trackObject={trackObject}
          status={status}
        />
        <AllCountriesModal {...viewAllCountriesModal} markets={markets} />
        <EditBoostsModal
          {...editBoostsModal}
          campaignId={campaignId}
          onSave={handleChangeCampaignMetadata}
          boosts={boosts}
          totalValues={totalValues}
          trackObject={trackObject}
        />

        <EditWebsiteSlugModal
          isOpen={editUrlModal.open}
          onClose={editUrlModal.closeModal}
          brandId={currentBrand?.id!}
          updatingContentSlug={newLocalSlug}
          campaignId={campaignId}
          brandSlug={currentBrand?.slug!}
          onSave={(newSlug: string) => {
            setNewLocalSlug(newSlug);
          }}
          url={localUrl}
          onSaveUrl={(newUrl: string) => {
            setLocalUrl(newUrl);
          }}
        />
        <PreviewModal
          open={previewModal.open}
          closePreview={previewModal.closeModal}
          contentDetails={contentDetails}
          style={presave?.style!}
          styleType={presave?.style?.buttonConfig as StyleTypes}
          openEditModal={editStylesModal.openModal}
          bgColor={previewBgColor}
          showEditButton
          isFinalized={isFinalized}
        />
        <ManuallyReleaseModal
          open={manuallyFindReleaseModal.open}
          onClose={manuallyFindReleaseModal.closeModal}
          contentSlug={content.slug}
          refreshCampaignDetails={refreshCampaignDetails}
        />
        {showConnectBox === 'audiomack' && (
          <AudiomackConnectv2
            closeModal={() => setShowConnectBox(null)}
            open
            selected={currentBrand && currentBrand.connections.audiomack ? currentBrand.connections.audiomack.url : null}
            onSave={() => enableAutosaveOnPlatform('audiomack')}
          />
        )}
        {showConnectBox === 'soundcloud' && (
          <SoundcloudConnectv2
            closeModal={() => setShowConnectBox(null)}
            open
            selected={currentBrand?.connections?.soundcloud_artist_page?.id}
            onSave={() => enableAutosaveOnPlatform('soundcloud')}
          />
        )}
        {showConnectBox === 'deezer' && (
          <DeezerConnectv2
            closeModal={() => setShowConnectBox(null)}
            open
            selected={currentBrand?.connections?.deezer?.id ? currentBrand?.connections?.deezer?.id : null}
            onSave={() => enableAutosaveOnPlatform('deezer')}
          />
        )}
      </div>
    </CampaignDetailsContainer>
  );
};

export default PreSaveDetails;