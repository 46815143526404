import { useState, useEffect } from 'react';
import { Dialog, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import HeaderModal from '../../HeaderModal';
import Axios from 'helpers/Interceptor';
import { ButtonsContainer, InputContainer, ModalContainer, useStyles } from 'components/shareable/Modal/styles';
import { StyledLabel } from 'styles/shared';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { clsx } from 'modules/Utils';

interface Props {
    className?: string;
    open: boolean;
    currentSpendLimit: number;
    defaultSpendLimit: number;
    closeModal: () => void;
    updateSpendLimit: (newLimit: number) => void;
    brandSlug: string;
}

const EditSpendLimitModal = ({
    className = '',
    open,
    currentSpendLimit,
    defaultSpendLimit,
    closeModal,
    updateSpendLimit,
    brandSlug
}: Props) => {
    const [spendLimit, setSpendLimit] = useState<number>(currentSpendLimit);
    const [loading, setLoading] = useState<boolean>(false);
    const [spendLimitError, setSpendLimitError] = useState<boolean>(false);

    const classes = useStyles();
    const { mobileView } = useScreen();

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setSpendLimit(currentSpendLimit);
            }, 500);
        }
    }, [open]);

    const handleSpendLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newLimit = Number(event.target.value);
        if (newLimit > currentSpendLimit) {
            setSpendLimitError(true);
        } else {
            setSpendLimitError(false);
        }
        setSpendLimit(newLimit);
    };

    const resetToDefaultLimit = () => {
        setSpendLimit(defaultSpendLimit);
    };

    const handleConfirmEditSpendLimit = async () => {
        setLoading(true);
        try {
            await Axios.post(
                `/subscription/brand/${brandSlug}/usage/campaigns`, {
                newBudget: spendLimit
            }
            )
            updateSpendLimit(spendLimit);
            toast.success('Spend limit updated!', { /* your toast options */ });
        } catch (e) {
            // Handle error
        } finally {
            closeModal();
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} classes={{ root: classes.rootDialog }}>
            <ModalContainer width={mobileView ? "auto" : "678px"} padding={mobileView ? "32px 24px" : "32px"}>
                <HeaderModal
                    closeModal={closeModal}
                    title="Admin: Edit User's Monthly Spend Limit"
                    subtitle="You can edit the user's monthly spend limit to any amount lower than what it's at."
                />
                <InputContainer className={clsx("adminEditSpendLimitModalInputContainer", className)}>
                    <TextField
                        InputProps={{ classes: { root: classes.inputBorder } }}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        type="number"
                        defaultValue={''}
                        value={spendLimit}
                        onChange={handleSpendLimitChange}
                    />
                    {spendLimitError &&
                        <StyledLabel
                            color="red"
                            fontSize={16}
                        >
                            Cannot exceed current spend limit of ${currentSpendLimit}
                        </StyledLabel>
                    }

                    <button onClick={resetToDefaultLimit}>Reset to Default Limit</button>

                </InputContainer>
                <ButtonsContainer flexDirection={mobileView ? "column-reverse" : "row"}>
                    <ButtonSymphony
                        className="buttonEditSpendLimitModal1"
                        onClick={closeModal}
                        variant="outlined"
                        disabled={loading}
                        width={mobileView ? "100%" : "fit-content"}
                    >
                        Cancel
                    </ButtonSymphony>
                    <ButtonSymphony
                        className="buttonEditSpendLimitModal2"
                        onClick={handleConfirmEditSpendLimit}
                        disabled={loading || spendLimitError}
                        width={mobileView ? "100%" : "50%"}
                        isLoading={loading}
                        loadingText="Updating..."
                    >
                        Update Spend Limit
                    </ButtonSymphony>
                </ButtonsContainer>
            </ModalContainer>
        </Dialog>
    );
};

export default EditSpendLimitModal;