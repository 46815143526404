import { PlaybookType } from "Hooks/Playbooks";
import CampaignView from "./CampaignView";
import { CSSProperties } from "react";

const MarketingFlow2 = () => {
    return (
        <div className="marketingFlow2Component" style={sty.container}>
            <CampaignView
                playbookType={PlaybookType.INCREASE_MUSIC_STREAMS}
            />
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    container: {
        display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        height: '100vh',
        width: '100%',
    }
}

export default MarketingFlow2;
