import SourcesTrends from 'components/Admin/SourcesGraph';
import { SourcesTable } from 'components/Admin/SourcesTable';
import { SnapshotsTable } from 'components/Admin/SnapshotsTable';
import SubscriptionReport from 'components/Admin/SubscriptionExport';
import { Redirect } from 'react-router-dom';
import { useContext } from 'react';
import { CurrentUserContext } from 'Hooks/CurrentUserContext';

const SourcesPage = () => {

    const { isSymphonyAdmin: userIsAdmin } = useContext(CurrentUserContext)
    
    if (!userIsAdmin) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <div>
            <SnapshotsTable />
            <SourcesTable />
            <SubscriptionReport />
            <SourcesTrends type='signups' />
            <SourcesTrends type='logins' />
        </div>
    );
};

export default SourcesPage;
