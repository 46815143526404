import LoadingIndicator from "components/Loader/LoadingIndicator";
import { orderBy } from 'lodash'
import { upperFirst } from 'lodash'
import { useState } from "react";
import OrderByIcon from "assets/images/order.svg"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LocationHiddenImg from "assets/images/fanbaseTable/location.svg";
import NameHiddenImg from "assets/images/fanbaseTable/name.svg";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import { getFormattedPlatform } from "../../utils";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";

interface AudienceStatsProps {
    shown: boolean;
    audienceBreakdown: any
    audienceTotal: number
    limit: number
    offset: number
    orderBy: string
    setOrderBy: (orderBy: string) => void
    setOrderDirection: React.Dispatch<React.SetStateAction<string>>
    setOffset: (offset: number) => void
    showBlurredDataRow?: boolean
    selectedAudienceTab: string
    setSelectedAudienceTab: (tab: 'current' | 'previous') => void
}

const isFromUS = (country?: string | null) => country === 'US'

export default function Audience(props: AudienceStatsProps) {
    const theme = useTheme()
    const hideBannerImage = useMediaQuery(theme.breakpoints.down(701));
    const showName = useMediaQuery(theme.breakpoints.up(530));
    const {
        shown,
        audienceBreakdown,
        audienceTotal,
        limit,
        offset,
        orderBy,
        setOrderBy,
        setOrderDirection,
        setOffset,
        showBlurredDataRow,
        selectedAudienceTab,
        setSelectedAudienceTab
    } = props

    const onPressOrderBy = (order: string) => {
        if (orderBy === order) {
            setOrderDirection((prev) => prev === 'asc' ? 'desc' : 'asc')
        } else {
            setOrderDirection('asc')
            setOrderBy(order)
        }
    }

    const onAudienceTabChange = (tab: 'current' | 'previous') => {
        setOffset(0)
        setOrderBy('createdAt')
        setOrderDirection('desc')
        setSelectedAudienceTab(tab)
    }

    return (
        <div className={`px-6 lg:px-10 ${shown ? 'visible' : 'hidden'} select-none`}>
            {!(audienceBreakdown) ?
                (<div className="mx-auto my-6 flex-col flex items-center">
                    <LoadingIndicator height="100px" color="black" />
                    <p className="mt-1 text-center">Loading...</p>
                </div>) : (
                    <>
                        <div className="">
                            <p className="text-lg font-semibold">Audience Breakdown {!showBlurredDataRow && `(${audienceTotal} total)`}</p>
                            <p className="text-md text-gray-500">View the emails you collected from fans who subscribed directly from this release vs emails collected in prior releases.</p>
                        </div>
                        {
                            showBlurredDataRow && audienceBreakdown && audienceBreakdown.length > 0 && <div className="my-2">
                                <UnlockDataBanner
                                    source="Presave Campaign Details - Audience"
                                    title="Learn exactly who your fans are"
                                    description="Go Pro to see your fans name + locations, down to the city"
                                    image={UnlockDataImage}
                                    hideImage={hideBannerImage}
                                />
                            </div>
                        }
                        <div className="py-5 pt-3">
                            <div id="children-tabs" className="radio-toolbar">
                                <input
                                    type="radio"
                                    id="current"
                                    name="dataview-type-2"
                                    value="current"
                                    onChange={() => onAudienceTabChange('current')}
                                    checked={selectedAudienceTab === 'current'}
                                />
                                <label
                                    style={{
                                        borderTopLeftRadius: 10,
                                        borderBottomLeftRadius: 10,
                                    }}
                                    className="w-full text-center border border-primary"
                                    htmlFor="current"
                                >
                                    New Emails from this Release
                                </label>
                                <input
                                    type="radio"
                                    id="previous"
                                    name="dataview-type-3"
                                    value="previous"
                                    onChange={() => onAudienceTabChange('previous')}
                                    checked={selectedAudienceTab === 'previous'}
                                />
                                <label
                                    style={{
                                        borderTopRightRadius: 10,
                                        borderBottomRightRadius: 10,
                                    }}
                                    className="w-full text-center border border-primary"
                                    htmlFor="previous"
                                >
                                    Emails from Past Releases
                                </label>
                            </div>
                        </div>
                        <div className="grid grid-flow-row gap-1 grid-cols-6 sy-card text-sm">
                            <div id="email" className="col-span-3 flex flex-row cursor-pointer" onClick={() => onPressOrderBy('email')}>
                                <div className="ml-8">Email</div>
                                <img className="pl-1" alt="order_by" src={OrderByIcon} />
                            </div>
                            {showName && (
                                <div id="name" className="col-span-1 flex flex-row items-center cursor-pointer" onClick={() => onPressOrderBy('name')}>
                                    <div className="text-sm whitespace-nowrap flex gap-1 items-center justify-center">
                                        {<UpgradeToProChip className="nameProChip" type="gem" color="purple" height={16} width={16} />} Name
                                    </div>
                                    <img className="pl-1" alt="order_by" src={OrderByIcon} />
                                </div>
                            )}
                            <div id="state" className="col-span-1 flex flex-row items-center cursor-pointer" onClick={() => onPressOrderBy('location')}>
                                <div className="text-sm whitespace-nowrap flex gap-1 items-center justify-center"> {<UpgradeToProChip type="gem" color="purple" height={16} width={16} />} Location</div>
                                <img className="pl-1" alt="order_by" src={OrderByIcon} />
                            </div>
                            <div id="platform" className="col-span-1 flex flex-row items-center cursor-pointer pl-8" onClick={() => onPressOrderBy('platform')}>
                                <div className="text-sm text-right">Platform</div>
                                <img className="pl-1" alt="order_by" src={OrderByIcon} />
                            </div>
                        </div>
                        <div className="max-h-80 overflow-auto">
                            {audienceBreakdown && audienceBreakdown.length > 0 ?
                                audienceBreakdown
                                    .map((item: {
                                        email: string,
                                        name: string,
                                        profile_url: string
                                        lastname?: string | null,
                                        city?: string | null,
                                        state?: string | null,
                                        country?: string | null,
                                        platform: string,
                                        tier?: string | null,
                                        color?: string,
                                    }, index: number) => {
                                        let fullName = item.name;
                                        if (item.lastname) fullName += ` ${item.lastname.at(0)}.`

                                        let location = `${item.city}, ${item.state}`;
                                        if (isFromUS(item.country)) location += ` (${item.country})`

                                        const tier = item.tier && <strong>({upperFirst(item.tier)})</strong>
                                        return (
                                            <div key={index} className="grid grid-flow-row gap-1 grid-cols-6 sy-card text-sm">
                                                <div className="col-span-3 flex flex-row break-all">
                                                    {!!item.profile_url
                                                        ? <img className="w-6 h-6 rounded-full col-span-1" alt="avatar" src={item.profile_url} />
                                                        : <div className="w-6 h-6 rounded-full col-span-1" style={{ backgroundColor: item.color, border: 'border: 1px solid #000000' }} />}
                                                    <div className="pl-2">{item.email}</div>
                                                </div>
                                                {
                                                    showBlurredDataRow && <>
                                                        {showName && <img alt="fullName.svg" src={NameHiddenImg} />}
                                                        <img alt="location.svg" src={LocationHiddenImg} />
                                                    </>
                                                }
                                                {
                                                    !showBlurredDataRow && <>
                                                        {showName && <div className="col-span-1 break-words">{fullName}</div>}
                                                        <div className="col-span-1">{location}</div>
                                                    </>
                                                }
                                                <div className="col-span-1 pl-8">{getFormattedPlatform(item.platform)} {tier}</div>
                                            </div>
                                        );
                                    }) : <p className="py-3 text-center">Once fans start pre-saving, you'll see them appear here.</p>
                            }
                            {audienceBreakdown && audienceBreakdown.length > 0 && (
                                <div className="flex justify-end items-center p-2 gap-4">
                                    <div>{offset + 1}-{offset + limit >= audienceTotal ? audienceTotal : offset + limit} of {audienceTotal}</div>
                                    <div className="flex gap-2">
                                        <button className="text-primary p-1 rounded-lg" onClick={() => setOffset(offset-limit)} disabled={offset === 0}>Prev</button>
                                        <button className="text-primary p-1 rounded-lg" onClick={() => setOffset(offset+limit)} disabled={offset + limit >= audienceTotal}>Next</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )
            }
        </div >

    )
}