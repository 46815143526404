import { Chip, makeStyles } from "@material-ui/core";
import { clsx } from 'modules/Utils';

const useStyles = makeStyles({
    chip: {
        backgroundColor: "#F5EBFF",
        color: "#8800FF",
        display: 'flex',
        width: 'auto',
        padding: '10px',
        "&:hover": {
            backgroundColor: "#C7B3FF"
        },
        "& .MuiChip-label": {
            whiteSpace: 'nowrap',
            paddingLeft: '12px',
            paddingRight: '12px',
            textOverflow: 'unset',
            overflow: 'visible'
        }
    },
    stackedChip: {
        padding: '5px 10px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '120px',
        height: 'auto',
        flexDirection: 'column',
        textAlign: 'center',
        marginLeft: '0px',
        marginRight: '0px',

        "& .MuiChip-label": {
            whiteSpace: 'wrap',
            paddingLeft: '0px',
            paddingRight: '0px',
        }
    },
});

type ClickableChipProps = {
    className?: string;
    campaignName: string,
    campaignImage?: string | null;
    onClick?: () => void;
    stacked?: boolean;
}

const ClickableChip = ({
    className = '',
    campaignName,
    campaignImage,
    onClick,
    stacked = false,
}: ClickableChipProps) => {
    const classes = useStyles();

    return (
        <Chip
            className={clsx(`createCampaignBadgeComponent ${className}`, classes.chip, stacked && classes.stackedChip)}
            onClick={onClick}
            icon={campaignImage ?
                <img
                    className={clsx("w-6 h-6 flex-shrink-0", stacked ? "ml-0" : "ml-4")}
                    src={campaignImage}
                    alt="campaign image"
                />
                :
                undefined
            }
            label={campaignName}
            clickable
            color="primary"
        />
    )
}

export default ClickableChip;