import {
  useStyles,
  RowFlexContainer,
  ConversionsDetailsContainer,
  ColumnFlexContainer,
  ConversionsHeaderContainer,
  ConversionsIconContainer,
  ConversionsContainer,
  ErrorBanner,
  ConversionsDataContainer,
} from "./styles";
import { ReactComponent as ConversionsIcon } from "assets/images/campaignDetails/Boost.svg";
import { ReactComponent as StarsIcon } from "assets/images/stars-icon.svg"
import { Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import { useTheme } from "styled-components";
import { ReactComponent as WarningIcon } from "assets/images/campaignDetails/warning.svg"
import SecondaryButton from "components/shareable/SecondaryButton";
import { useHistory } from "react-router-dom";
import useScreen from "Hooks/useScreen";
import { Body } from "../HeaderModal/styles";
import LinkButton from "components/shareable/LinkButton";
import Colors from "modules/Colors";
import SwitchSymphony from "components/shareable/SwitchSymphony";

/**
 * Props interface for the ConversionsIndicator component
 * @interface Props
 */
interface Props {
  /** Flag indicating if Facebook Conversions are currently enabled */
  isConversionsEnabled?: boolean;

  /** Description text to display when conversions are enabled */
  conversionsEnabledDescription?: string;

  /** Facebook Pixel ID associated with the account */
  pixelId?: string;

  /** Flag indicating if there's an error in the conversions setup */
  hasError?: boolean;

  /** Title text for the conversions card */
  title?: string;

  /** Description text when conversions are disabled */
  description?: string;

  /** Facebook Ad Account ID associated with the account */
  adAccountId?: string;

  /** Custom padding value for the container */
  padding?: string;

  /** Flag to enable responsive layout adjustments for small viewports */
  responsiveOnSmallView?: boolean;

  /** Additional description text to display at the bottom of the card */
  bottomDescription?: string;

  /** Flag to show the component with a "Recommended" badge */
  showAsRecommended?: boolean;

  /** Flag to control the visibility of the conversion toggle switch */
  showSwitchButton?: boolean;

  /** Custom text for the Intercom help button */
  intercomButtonText?: string;

  /** Callback function to handle toggling conversions on/off */
  toggleConversionsIndicator?: () => void;

  /** Callback function to handle Intercom help button clicks */
  onIntercomClick?: () => void;

  /** Flag to hide the connected pixel details */
  hideConnectedPixelDetails?: boolean;
}

const ConversionsIndicator = ({
  isConversionsEnabled = false,
  hideConnectedPixelDetails = false,
  conversionsEnabledDescription = '',
  pixelId,
  hasError,
  title,
  description,
  adAccountId,
  padding,
  responsiveOnSmallView,
  bottomDescription,
  showSwitchButton = true,
  showAsRecommended = true,
  intercomButtonText,
  toggleConversionsIndicator = () => { },
  onIntercomClick,
}: Props) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { mobileView } = useScreen()

  const redirect = (url: string, taskName: string) => {
    history.push(url, { taskName });
  }

  const handleOnClickFixSetupError = () => {
    redirect("/settings?tab=ADVANCED_SETTINGS", "Setup Conversions API");
  };

  return (
    <ConversionsContainer
      padding={padding}
      borderRadius={responsiveOnSmallView && mobileView ? "0px" : "12px"}
    >
      <ConversionsIconContainer>
        <ConversionsIcon />
      </ConversionsIconContainer>
      <ColumnFlexContainer gap='8px'>
        <ConversionsHeaderContainer>
          <ColumnFlexContainer>
            {showAsRecommended && !isConversionsEnabled && (
              <RowFlexContainer>
                <StarsIcon className={classes.svgPrimary} />
                <Caption>Recommended</Caption>
              </RowFlexContainer>
            )}
            <Subtitle1>{title || (isConversionsEnabled ? "Facebook Conversions Enabled" : "Enable Facebook Conversions")}</Subtitle1>
            {isConversionsEnabled ? (<Body2>{conversionsEnabledDescription}</Body2>) :
              (<Body2>{description || "Achieve 4-6x better results in your campaigns"}</Body2>)}
          </ColumnFlexContainer>
          {hasError ? (
            <RowFlexContainer gap='8px'>
              <ErrorBanner>
                <Body2 color={Colors.textColor}>Issues detected</Body2>
                <WarningIcon className={classes.svgError} width={24} height={24} />
              </ErrorBanner>
              <SecondaryButton
                className='buttonConversionsIndicator'
                text="Fix"
                onClick={handleOnClickFixSetupError}
                height="48px"
                error
              />
            </RowFlexContainer>
          ) : (!hideConnectedPixelDetails && showSwitchButton) && (
            <SwitchSymphony
              checked={isConversionsEnabled}
              onChange={toggleConversionsIndicator}
              disabled={!pixelId || !adAccountId}
            />
          )}
        </ConversionsHeaderContainer>
        {!hideConnectedPixelDetails && (
          <ConversionsDetailsContainer>
            <ConversionsDataContainer
              flexDirection={responsiveOnSmallView ? "column" : "row"}
            >
              {pixelId ? (
                <RowFlexContainer>
                  <Caption color={Colors.greyDark}>
                    Facebook Pixel:
                  </Caption>
                  <Caption color={Colors.textColor}>
                    {pixelId}
                  </Caption>
                </RowFlexContainer>
              ) : (
                <Caption color={Colors.greyDark}>
                  Your Facebook Pixel is not connected
                </Caption>
              )}
              {pixelId && adAccountId && (
                <RowFlexContainer>
                  <Caption color={Colors.greyDark}>
                    Facebook Ad Account:
                  </Caption>
                  <Caption color={Colors.textColor}>
                    {adAccountId}
                  </Caption>
                </RowFlexContainer>
              )}
            </ConversionsDataContainer>
          </ConversionsDetailsContainer>
        )}
        {(bottomDescription || onIntercomClick) && (
          <Body>
            {bottomDescription && <Body2 color={Colors.greyDark}>{bottomDescription}</Body2>}
            {onIntercomClick && (
              <LinkButton
                showInline
                text={intercomButtonText || "Learn how"}
                onClick={onIntercomClick}
              />
            )}
          </Body>
        )}
      </ColumnFlexContainer>
    </ConversionsContainer>
  );
};

export default ConversionsIndicator;