import { Dialog } from '@mui/material'
import { ModalContainer, useStyles } from './style'
import useScreen from 'Hooks/useScreen'

interface Props {
  children: JSX.Element
  open: boolean
  dialogStyle?: React.CSSProperties
  containerStyle?: React.CSSProperties
  margin?: string
  fullScreen?: boolean
  onClose?: () => void
}

const DialogPaper = ({
  children,
  open,
  dialogStyle,
  containerStyle,
  margin,
  fullScreen = false,
  onClose,
}: Props) => {
  const { mobileView } = useScreen();
  const classes = useStyles({ dialogStyle })

  return (
    <Dialog
      open={open}
      className={`${classes.rootDialog} ${dialogStyle}`}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth={fullScreen}
    >
      <ModalContainer
        padding={
          dialogStyle?.padding
            ? dialogStyle.padding
            : mobileView
            ? '32px 24px'
            : '32px'
        }
        maxWidth={dialogStyle?.width ? '100%' : '678px'}
        gap={(dialogStyle?.gap as string) || '32px'}
        height={dialogStyle?.height ? '100%' : 'auto'}
        style={containerStyle}
        margin={margin ? margin : "auto"}
      >
        {children}
      </ModalContainer>
    </Dialog>
  )
}

export default DialogPaper
