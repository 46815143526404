import { InfoBanner } from "./styles"
import useStyles, { Card, StyledLabel, Icon } from "../../styles"
import Grid from "@material-ui/core/Grid"
import { SongAdsRecord as Record } from "types/global"
import { clsx } from 'modules/Utils'
import FacebookIcon from "assets/images/facebook-rounded-icon.svg"
import InstagramIcon from "assets/images/instagram-bordered-icon.svg"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { Subtitle2, Body2 } from "components/shareable/Typography"
import Colors from "modules/Colors"
import { Container } from "styles/shared"
import { SongAdsCampaignSubtype } from "../../.."

interface Props {
  record: Record
  conversionsEnabled?: boolean
  handleClick: () => void
  subtype: SongAdsCampaignSubtype
}

interface CampaignTypeBannerProps {
  hideIcons?: boolean
  subtype: SongAdsCampaignSubtype
}

const CampaignTypeBanner = ({
  hideIcons,
  subtype
}: CampaignTypeBannerProps) => {

  let label = "Increase Music Streams via IG/FB"
  if (subtype === "grow_playlist_followers") {
    label = "Grow Playlist Followers via IG/FB"
  }

  console.log("subtype", subtype)
  return (
    <InfoBanner minWidth={hideIcons ? "180px" : "244px"}>
      {!hideIcons && (
        <Grid container>
          <Icon
            width={20}
            height={20}
            alt="facebook-icon.svg"
            src={InstagramIcon}
          />
          <Icon
            position="relative"
            right="4px"
            width={20}
            height={20}
            alt="facebook-icon.svg"
            src={FacebookIcon}
          />
        </Grid>
      )}
      <Grid>
        <StyledLabel fontSize={12} fontWeight={400} width="180px">
          {label}
        </StyledLabel>
      </Grid>
    </InfoBanner>
  )
}

const PreviewAdsCard = ({
  record,
  conversionsEnabled,
  handleClick,
  subtype
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const hideIcons = useMediaQuery(theme.breakpoints.down(388))
  const isResponsive = useMediaQuery(theme.breakpoints.down(621))
  const { thumbnail_url, name: recordName, mainTrack } = record

  console.log("record", record)

  let showSubtitle = record.type === "playlist" && mainTrack
  let subtitleComponent = null
  if (showSubtitle && mainTrack) {
    subtitleComponent = (
      <StyledLabel
        fontSize={16}
        fontWeight={400}
        color="#707070"
      >
        Autoplaying "{mainTrack.name}"
      </StyledLabel>
    )
  }
  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container direction="column">
            <StyledLabel fontSize={18} fontWeight={600}>
              Review Campaign Details
            </StyledLabel>
            <StyledLabel
              fontSize={16}
              fontWeight={400}
              color="#707070"
              marginTop="8px"
            >
              Review your details to make sure everything looks good before we
              submit your campaign to Facebook.
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.space, classes.border)}
          container
          alignItems="center"
          justifyContent="space-between"
        >

          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Icon
                  width={80}
                  alt="brand-icon.svg"
                  borderRadius="6px"
                  src={thumbnail_url}
                />
              </Grid>
              <Grid item>
                <Container
                  width="150px"
                  margin="0 0 8px 0px"
                  display="flex" flexDirection="column" justifyContent="center">
                  <CampaignTypeBanner {...{
                    hideIcons,
                    subtype
                  }} />
                </Container>
                <Grid container direction="column" justifyContent="center">
                  <StyledLabel
                    marginTop={isResponsive ? "8px" : undefined}
                    fontWeight={500}
                    fontSize={18}
                  >
                    "{recordName}"
                  </StyledLabel>
                  {subtitleComponent}
                  <StyledLabel
                    marginTop="8px"
                    onClick={handleClick}
                    color="#8800FF"
                    cursor="pointer"
                    fontWeight={500}
                    fontSize={16}
                  >
                    Preview Ads
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {conversionsEnabled && (
          <Grid
            className={clsx(classes.space, classes.border)}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Subtitle2>
                Symphony Conversions
              </Subtitle2>
            </Grid>
            <Grid item>
              <Body2 color={Colors.textColor}>
                Enabled
              </Body2>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

export default PreviewAdsCard
