import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import LoaderV2 from "components/Loader/LoaderV2";

interface OptionProps {
  label?: string;
  mainContainerClassName?: string;
  labelClassName?: string;
  selected?: boolean;
  loading?: boolean;
}

const MenuProOption = ({
  label,
  labelClassName,
  mainContainerClassName,
  selected,
  loading
}: OptionProps) => (
  <div className={mainContainerClassName}>
    <p className={labelClassName}>{label}&nbsp;</p>
    <div className="ml-auto flex items-center gap-2">
      {loading && <LoaderV2 />}
      <UpgradeToProChip
        className="menuProOptionProChip"
        type="gem"
        color={selected ? "white" : "purple"}
      />
    </div>
  </div>
);

export default MenuProOption;
