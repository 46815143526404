import { useEffect } from "react"
import { Dialog } from "@material-ui/core"
import { ModalContainer, useStyles } from "./style"
import MainView from "./Components/MainView"
import useScreen from "Hooks/useScreen"
import { DuplicateSteps } from "./utils"

const {
  REVIEW,
  SELECT_DUPLICATE_OPTION,
  CHOOSE_ANOTHER_PROJECT,
  CHOOSE_ANOTHER_LINK,
} = DuplicateSteps

interface Props {
  open: boolean
  closeModal: () => void
}

const SuperboostModal = ({
  open,
  closeModal,
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useScreen();

  useEffect(() => {
    if (open) {
      //   setRecordSelected(null)
      //   setStep(SELECT_DUPLICATE_OPTION)
      //   setLoadingRecordDetails(false)
      //   setErrorRecordDetails(false)
      //   setErrorDuplicateCampaign(false)
      //   setLoadingDuplicateCampaign(false)
    }
  }, [open])

  // TODO: use ModalSymphony
  return (
    <Dialog open={open} classes={{ root: classes.rootDialog }}>
      <ModalContainer
        width={mobileView ? "auto" : "678px"}
        padding={mobileView ? "32px 24px" : "32px"}
      >
        <MainView closeModal={closeModal} />
      </ModalContainer>
    </Dialog>
  )
}

export default SuperboostModal
