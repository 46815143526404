import { useContext, useEffect, useState } from "react";
import "styles/rc-slider.css";
import GoalSelector from "./GoalSelector";
import MusicStreams from "./MusicStreams";
import GetPlaylisted from "./Playlisting";
import VideoViews from "./VideoAds";
import PreSave from "./PreSave";
import { pageView } from "analytics";
import MarketingDataProvider from "./Data/Provider";
import CreativeGenerator from "./CreativeGenerator";
import PreSaveProvider from "./Data/PreSaveProvider";
import NavbarProvider from "./Data/NavbarProvider";
import LinkClicks from "./LinkClicks";
import GrowInstagramEngagement from "./GrowInstagram";
import ConnectModalsProvider from "Hooks/ConnectModalsProvider"
import TiktokAds from "./TikTokAds";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { CampaignFilterType } from "./Components/CampaignTypesFilter/types";
import { CurrentBrand } from "types/global";
import { DEFAULT_CAMPAIGN_PARAMS } from "./constants";
import { getCampaignsForBrand } from "services/symphonyApi/brandService";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

const MarketingPage = ({
  reloadBrands
}: {
  reloadBrands: any;
}) => {
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }

  const [selectedGoal, setGoal] = useState<any>(null);
  // Active Campaigns
  const [currentCampaigns, setCurrentCampaigns] = useState<any>(null);

  // loadingCampaigns is a global loading state that will load the entire page
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);

  // locallyLoading is a local loading state that will load the current component
  const [locallyLoading, setLocallyLoading] = useState<boolean>(false);

  // specifically for load more button UIs
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  // pagination for campaigns
  const [hasMoreCampaigns, setHasMoreCampaigns] = useState<boolean>(true);

  const [campaignParams, setCampaignParams] = useState<CampaignFilterType>(DEFAULT_CAMPAIGN_PARAMS);
  const [firstRenderCampaigns, setFirstRenderCampaigns] = useState<number>(0);

  const getCampaigns = async (filterObject: CampaignFilterType = DEFAULT_CAMPAIGN_PARAMS, localLoading?: boolean) => {
    if (localLoading) {
      setLocallyLoading(true);
    }

    const campaigns = await getCampaignsForBrand(filterObject, currentBrand.id);
    setCurrentCampaigns(campaigns || []);
    setHasMoreCampaigns(campaigns?.length === filterObject.limit);

    setLoadingCampaigns(false);
    setLocallyLoading(false);
  }

  const refreshCampaigns = async (params = DEFAULT_CAMPAIGN_PARAMS) => {
    setLoadingCampaigns(true);
    clearState();

    const paramsWithOffset = { ...params, offset: 0 };
    const campaigns = await getCampaignsForBrand(paramsWithOffset, currentBrand.id);
    setCurrentCampaigns(campaigns);
    setFirstRenderCampaigns(campaigns?.length || 0)
    setHasMoreCampaigns(campaigns?.length === params.limit);
    setLoadingCampaigns(false);
  }

  const clearState = () => {
    setHasMoreCampaigns(true);
    setGoal(null);
  }

  const loadMoreCampaigns = async () => {
    if (!hasMoreCampaigns) return;

    const newParams = {
      ...campaignParams,
      offset: campaignParams.offset + campaignParams.limit
    };

    setLoadingMore(true);

    const newCampaigns = await getCampaignsForBrand(newParams, currentBrand.id);
    setCurrentCampaigns((currentCampaigns: any) => [...currentCampaigns, ...newCampaigns]);
    setCampaignParams(newParams);
    setHasMoreCampaigns(newCampaigns.length === campaignParams.limit);
    setLoadingMore(false);
  };

  const showGoalBasedFlow = () => {
    switch (selectedGoal) {
      case "increase_record_streams":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <MusicStreams
                subtype="increase_record_streams"
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "grow_playlist_followers":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <MusicStreams
                subtype="grow_playlist_followers"
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "increase_link_clicks":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <LinkClicks
                setGoal={setGoal}
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "grow_instagram_engagement":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <GrowInstagramEngagement
                setGoal={setGoal}
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "get_playlisted":
        return (
          <NavbarProvider>
            <GetPlaylisted />
          </NavbarProvider>
        );
      case "increase_video_views":
        return (
          <VideoViews />
        );
      case "tiktok_ads":
        return (
          <TiktokAds />
        );
      case "creative_generator":
        return (
          <CreativeGenerator />
        );
      case "pre_save":
        return (
          <PreSaveProvider>
            <PreSave
              setGoal={setGoal}
            />
          </PreSaveProvider>
        );
      default:
        return <h1>Goal not supported...</h1>;
    }
  }

  useEffect(() => {
    pageView("Marketing");
  }, []);

  useEffect(() => {
    refreshCampaigns(DEFAULT_CAMPAIGN_PARAMS);
  }, [currentBrand]);

  if (loadingCampaigns) {
    return (
      <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
        <NewLoader black />
        <p className="mt-1 text-center">Loading Campaigns...</p>
      </div>
    )
  }

  if (selectedGoal) {
    showGoalBasedFlow()
  }

  if (!selectedGoal) {
    return (
      <GoalSelector
        returnedAllCampaigns={!hasMoreCampaigns}
        loadMoreCampaigns={loadMoreCampaigns}
        loadingMore={loadingMore}
        currentCampaigns={currentCampaigns}
        setGoal={setGoal}
        getCampaigns={getCampaigns}
        setCampaignParams={setCampaignParams}
        loadingLocally={locallyLoading}
        quantityCampaigns={firstRenderCampaigns}
      />
    );
  }

  return showGoalBasedFlow();
}

export default MarketingPage;
