import Lottie from 'react-lottie';
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';
import SymphonyLoadingLottieGray from 'assets/images/lotties/SymphonyLoading_Gray.json';
import SymphonyLoadingLottieBlack from 'assets/images/lotties/SymphonyLoading.json';
import { CSSProperties } from 'react';


interface LoadingLottieProps {
  black?: boolean,
  disabled?: boolean,
  width?: number,
  height?: number,
  style?: CSSProperties,
}

const LoadingLottie = ({
  black = false,
  disabled = false,
  width = 44,
  height = 44,
  style = {},
}: LoadingLottieProps) => {

  return (
    <Lottie
      width={width || 44}
      height={height || 44}
      style={style}
      options={{
        rendererSettings: {
          className: "bg-white-500"
        },
        loop: true,
        autoplay: true,
        animationData: disabled ? SymphonyLoadingLottieGray : black ? SymphonyLoadingLottieBlack : SymphonyLoadingLottie,
      }}
    />
  )
}

export default LoadingLottie