import { useState } from "react";
import { Slider } from "@material-ui/core";
import { BackArrowImage, BackButtonContainer, BackToDashboardContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import { Container } from "styles/shared";
import { Headline1 } from "components/shareable/Typography";
import PrimaryButton from "components/shareable/PrimaryButton";
import { useStyles } from "./styles";
import PrimaryButtonContainer from "./PrimaryButtonContainer";
import useScreen from "Hooks/useScreen";
import { useOnboarding } from "Hooks/OnboardingContext";

const TeamsTotalCreators = () => {
    const classes = useStyles();
    const { mobileView } = useScreen();

    const {
        setTeamsTotalCreators,
        teamsTotalCreators,
        handleGoNext,
        showBackButton,
        handleGoBack
    } = useOnboarding()

    const [userChangedValue, setUserChangedValue] = useState<boolean>(false);

    const marks: {
        value: number;
        label: string;
    }[] = [
            {
                value: 0,
                label: '1-2',
            },
            {
                value: 1,
                label: '3-5',
            },
            {
                value: 2,
                label: '6-10',
            },
            {
                value: 3,
                label: '10+',
            },
        ];


    const selectedItem = marks.find(item => item.value === teamsTotalCreators?.value)
    return (
        <>
            <LeftColumn className="leftColumnTeamsTotalCreators" width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton() && mobileView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton() && !mobileView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>How many brands or creators do you usually work with?</MainTitle>
                    <SubTitle>This will help our team onboard you in the best way.</SubTitle>
                </Titles>
                <Container width={mobileView ? "85%" : "100%"}
                    margin={mobileView ? 'auto' : 'inherit'}
                    display={mobileView ? 'flex' : 'block'}
                    flexDirection={mobileView ? 'column' : 'unset'}
                    justifyContent={mobileView ? 'center' : 'unset'}
                    alignItems={mobileView ? 'center' : 'unset'}
                >
                    <Headline1>{!userChangedValue ? 'Slide to select.' : (selectedItem ? selectedItem.label : '')}</Headline1>

                    <Slider
                        onChange={(event: any, value: any) => {
                            setUserChangedValue(true);
                            setTeamsTotalCreators({
                                value: value as number,
                                label: marks.find(item => item.value === value)?.label || ''
                            })
                        }}
                        className={classes.ageSlider}
                        defaultValue={0}
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="off"
                        marks={marks}
                        step={1}
                        min={0}
                        max={3}
                        value={teamsTotalCreators?.value || 0}
                    />

                </Container>

                <PrimaryButtonContainer>
                    <PrimaryButton
                        className="continueButtonTeamsTotalCreators"
                        text="Continue"
                        width={mobileView ? "100%" : "300px"}
                        onClick={() => {
                            handleGoNext()
                        }} />
                </PrimaryButtonContainer>

            </LeftColumn>
            <RightColumn display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}

export default TeamsTotalCreators;