import {
  useEffect,
  useState,
  ChangeEvent,
  SetStateAction,
  Dispatch,
  useContext
} from "react";
import moment from "moment";
import { loadPaymentMethod, getBudgetData } from "./utils";
import BudgetCard from "../../Components/BudgetCard";
import WarningsCard from "../../Components/BudgetWarningsCard";
import DatesCard from "./Components/DatesCard";
import PaymentMethodCard from "./Components/PaymentMethodCard";
import RunningForCard from "./Components/RunningForCard";
import { CurrencyCodesType, SongAdsRecord } from "types/global";
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from "@material-ui/core";
import { STEPS } from "../utils";
import dayjs from 'dayjs';
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { ConnectionsContext } from "../../hooks/ConnectionsContext";

interface Props {
  budgetValue: number;
  startDate: Date | null;
  endDate: Date | null;
  record: SongAdsRecord;
  showReviewButton?: boolean;
  selectedTab?: STEPS | null;
  setBudgetValue: (value: SetStateAction<number>) => void;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  finishFlow: () => void;
  saveWhenNextButtonIsDisabled?: (isDisabled: boolean) => void;
  updatedCurrency: (currency: CurrencyCodesType) => void;
  expressSetupOrCustomPages: 'custom' | 'express';
}

/**
 * Determines if the next button should be disabled based on form validation rules
 * @param {Object} params Validation parameters
 * @param {number} params.budgetValue - The campaign budget amount
 * @param {number} params.spendCap - The maximum spend cap (if applicable)
 * @param {Date | null} params.startDate - Campaign start date
 * @param {Date | null} params.endDate - Campaign end date
 * @param {any} params.paymentMethodSelected - Selected payment method
 * @param {number} params.minimumCurrencyBudgetPerDay - Minimum daily budget requirement
 * @param {boolean} params.budgetLowError - Flag indicating if budget is too low
 * @param {boolean} params.loadingPayment - Flag indicating if payment is loading
 * @param {'express' | 'custom'} params.setupType - Setup flow type
 * @returns {boolean} True if the next button should be disabled
 */
const isNextButtonDisabled = ({
  budgetValue,
  spendCap,
  startDate,
  endDate,
  paymentMethodSelected,
  minimumCurrencyBudgetPerDay,
  budgetLowError,
  loadingPayment,
  setupType
}: {
  budgetValue: number;
  spendCap: number;
  startDate: Date | null;
  endDate: Date | null;
  paymentMethodSelected: any;
  minimumCurrencyBudgetPerDay: number;
  budgetLowError: boolean;
  loadingPayment: boolean;
  setupType: 'express' | 'custom';
}): boolean => {
  // Check if budget is valid and within spend cap
  const isBudgetValid =
    typeof budgetValue !== "undefined" &&
    budgetValue !== null &&
    budgetValue > 0 &&
    (spendCap > 0 ? budgetValue * 100 <= spendCap : true);

  // Validate dates
  const areDatesValid =
    startDate &&
    endDate &&
    dayjs(endDate).isAfter(startDate, 'day') &&
    (dayjs(startDate).isSame(dayjs(), 'day') || dayjs(startDate).isAfter(dayjs(), 'day'));

  // Check if minimum daily budget requirement is met
  const isMinimumBudgetMet = minimumCurrencyBudgetPerDay > 0;

  // Payment method validation only required for custom setup
  const isPaymentMethodValid = setupType === 'express' || paymentMethodSelected;
  console.log('isPaymentMethodValid', isPaymentMethodValid)
  // Combine all validation rules
  console.log('isBudgetValid:', isBudgetValid);
  console.log('areDatesValid:', areDatesValid);
  console.log('isMinimumBudgetMet:', isMinimumBudgetMet);
  console.log('isPaymentMethodValid:', isPaymentMethodValid);

  const isFormValid =
    isBudgetValid &&
    areDatesValid &&
    isMinimumBudgetMet &&
    isPaymentMethodValid;

  console.log('isFormValid:', isFormValid);
  console.log('budgetLowError:', budgetLowError);
  console.log('loadingPayment:', loadingPayment);
  console.log('returning:', !isFormValid || budgetLowError || loadingPayment);

  return !isFormValid || budgetLowError || loadingPayment;
};

const BudgetTab = ({
  budgetValue,
  startDate,
  endDate,
  showReviewButton,
  selectedTab,
  setBudgetValue,
  setStartDate,
  setEndDate,
  finishFlow,
  saveWhenNextButtonIsDisabled,
  updatedCurrency,
  expressSetupOrCustomPages,
}: Props) => {
  const theme = useTheme();
  const { facebookAdAccount, loggedInFbUser } = useContext(ConnectionsContext);
  const isMobile = useMediaQuery(theme.breakpoints.down(769));
  const [currentEndSchedule] = useState("scheduled");
  const [paymentMethodSelected, setPaymentMethod] = useState<any>(null);
  const [spendCap, setSpendCap] = useState<number>(0);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [budgetLowError, setBudgetLowError] = useState(false);
  const [minimumSuggestedBudget, setMinimumSuggestedBudget] = useState(0);
  const [suggestedEndDate, setSuggestedEndDate] = useState(
    moment().format("MM/DD/YYYY")
  );

  // when the currency changes, we should make a call to check if it's below the minimum budget
  // and update the "isBelowRecommendedBudget" accordingly
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");

  useEffect(() => {
    // recalculate the minimum budget per day when the currency changes
    if (updatedCurrency) {
      updatedCurrency(currency)
    }
  }, [currency])


  const [minimumCurrencyBudgetPerDay, setMinimumCurrencyBudgetPerDay] =
    useState(0);

  const {
    onChangeBudget: onChangeSpendBudget,
    onChangeCurrency: onChangeSpendCurrency,
  } = useContext(SpendBudgetContext)

  const showWarningsCard =
    budgetLowError ||
    !!(budgetValue && spendCap && budgetValue * 100 > spendCap);

  const isDisabledNextButton = isNextButtonDisabled({
    budgetValue,
    spendCap,
    startDate,
    endDate,
    paymentMethodSelected,
    minimumCurrencyBudgetPerDay,
    budgetLowError,
    loadingPayment: expressSetupOrCustomPages === 'custom' ? loadingPayment : false,
    setupType: expressSetupOrCustomPages
  });

  const loadPaymentMethodArgs = {
    fbAdAccountId: facebookAdAccount?.id,
    setLoadingPayment,
    setPaymentMethod,
    setSpendCap,
    setCurrency,
    onChangeSpendCurrency,
    setMinimumCurrencyBudgetPerDay,
    accessToken: loggedInFbUser?.access_token,
  };

  const handleChangeBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (Number(value) >= 0) {
      const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData(
        {
          startDate,
          endDate,
          budgetValue: value,
          minimumCurrencyBudgetPerDay,
        }
      );

      onChangeSpendBudget(value)
      setBudgetValue(value);
      setBudgetLowError(isLow);
      setMinimumSuggestedBudget(minimumSuggestedBudget);
      setSuggestedEndDate(suggestedEndDate);
    }
  };

  const handleSelectStartDate = (date: Date | null) => {
    const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
      startDate: date,
      endDate,
      budgetValue,
      minimumCurrencyBudgetPerDay,
    });

    setStartDate(date);
    setBudgetLowError(isLow);
    setMinimumSuggestedBudget(minimumSuggestedBudget);
    setSuggestedEndDate(suggestedEndDate);
  };

  const handleSelectEndDate = (date: Date | null) => {
    const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
      startDate,
      endDate: date,
      budgetValue,
      minimumCurrencyBudgetPerDay,
    });

    setEndDate(date);
    setBudgetLowError(isLow);
    setMinimumSuggestedBudget(minimumSuggestedBudget);
    setSuggestedEndDate(suggestedEndDate);
  };

  const handleRefreshPaymentMethod = () => {
    if (facebookAdAccount) loadPaymentMethod(loadPaymentMethodArgs);
  };

  useEffect(() => {
    if (expressSetupOrCustomPages === 'custom') {
      if (!paymentMethodSelected && selectedTab === STEPS.BUDGET && facebookAdAccount) {
        loadPaymentMethod(loadPaymentMethodArgs)
      };
    } else {
      setLoadingPayment(false)
      setMinimumCurrencyBudgetPerDay(10)
    }
  }, [selectedTab, paymentMethodSelected]);

  useEffect(() => {
    if (saveWhenNextButtonIsDisabled) {
      console.log('isDisabledNextButton', isDisabledNextButton)
      saveWhenNextButtonIsDisabled(isDisabledNextButton)
    }
  }, [isDisabledNextButton])

  useEffect(() => {
    if (minimumCurrencyBudgetPerDay > 0) {
      const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
        startDate,
        endDate,
        budgetValue,
        minimumCurrencyBudgetPerDay,
      });
      setBudgetLowError(isLow);
      setMinimumSuggestedBudget(minimumSuggestedBudget);
      setSuggestedEndDate(suggestedEndDate);
    }
  }, [minimumCurrencyBudgetPerDay])

  return (
    <div style={{ backgroundColor: "#f5f3f6", minHeight: "300px" }}>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <BudgetCard
          budgetValue={budgetValue}
          currencyCode={currency}
          handleChangeInputText={handleChangeBudget}
        />
      </Grid>
      {showWarningsCard && (
        <Grid className={isMobile ? "mb-0" : "mb-4"}>
          <WarningsCard
            {...{
              fbAdAccountId: facebookAdAccount?.id,
              budgetLowError,
              currency,
              minimumCurrencyBudgetPerDay,
              minimumSuggestedBudget,
              suggestedEndDate,
              budgetValue,
              spendCap,
              loadingPayment,
              handleRefreshPaymentMethod,
            }}
          />
        </Grid>
      )}
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <DatesCard
          {...{
            startDate,
            endDate,
            currentEndSchedule,
            budgetValue,
            handleSelectStartDate,
            handleSelectEndDate,
          }}
        />
      </Grid>
      {expressSetupOrCustomPages === 'custom' && (
        <Grid className={isMobile ? "mb-0" : "mb-4"}>
          <PaymentMethodCard
            {...{
              fbAdAccountId: facebookAdAccount?.id,
              fbAdAccountName: facebookAdAccount?.name,
              paymentMethodSelected,
              loadingPayment,
              handleRefreshPaymentMethod,
            }}
          />
        </Grid>
      )}
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <RunningForCard
          {...{
            budgetValue,
            startDate,
            endDate,
            finishFlow,
            showReviewButton,
            currencyCode: currency,
          }}
          disableMobileButton={isDisabledNextButton}
        />
      </Grid>
    </div>
  )
}

export default BudgetTab