import { useContext, useEffect } from 'react'
import { FacebookAdAccountAccess } from 'services/facebookApi'
import { FacebookBusinessManager } from 'types/global'
import { BusinessAccountOption } from './BusinessAccountOption/BusinessAccountOption'
import { AdAccountOption } from './AdAccountOption/AdAccountOption'
import { StyledAccountsUl } from './styles'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { CreateAccountOption } from '../../../../Components/CreateAccountOption/CreateAccountOption'

interface AccountsListProps {
  adAccounts: FacebookAdAccountAccess[]
  businessAccounts?: FacebookBusinessManager[]
  businessManager: FacebookBusinessManager
  selectedAdAccount: FacebookAdAccountAccess
  isCreatingAdAccount: boolean
  setSelectedAdAccount: (account: FacebookAdAccountAccess) => void
  setBusinessManager: (account: FacebookBusinessManager) => void
  onClickCreateAdAccount: () => void
}

const AccountsList = ({
  adAccounts,
  businessAccounts,
  selectedAdAccount,
  businessManager,
  isCreatingAdAccount,
  setSelectedAdAccount,
  setBusinessManager,
  onClickCreateAdAccount,
}: AccountsListProps) => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const { facebook_ad_account } = currentBrand?.connections || {}

  const showCreateAdAccountOption = !adAccounts?.some((account) => account.account_status !== 1)

  const selectInitialAdAccount = () => {
    if (selectedAdAccount) return
    if (facebook_ad_account) {
      const currentAdAccount = adAccounts.find(
        (account) => account.id === facebook_ad_account.id
      )
      if (currentAdAccount) setSelectedAdAccount(currentAdAccount)
    }
  }

  useEffect(() => {
    selectInitialAdAccount()
  }, [adAccounts])

  return (
    <StyledAccountsUl>
      {adAccounts && !isCreatingAdAccount && (
        <>
          {adAccounts.map((account) => (
            <AdAccountOption
              key={account.id}
              account={account}
              businessManager={businessManager}
              selectedAdAccount={selectedAdAccount}
              setSelectedAdAccount={setSelectedAdAccount}
            />
          ))}
          {showCreateAdAccountOption &&
            <CreateAccountOption
              accountType="Ad Account"
              description="You'll need to make sure your Facebook Business Manager is linked to Symphony."
              onClick={onClickCreateAdAccount}
            />
          }
        </>
      )}
      {businessAccounts &&
        isCreatingAdAccount &&
        businessAccounts.map((account) => (
          <BusinessAccountOption
            key={account.id}
            account={account}
            selectedAccount={businessManager}
            setBusinessManager={setBusinessManager}
          />
        ))}
    </StyledAccountsUl>
  )
}

export default AccountsList