import { useContext, useEffect, useState } from "react"
import Back from "components/shareable/back"
import MainContentContainer from "components/shareable/MainContentContainer"
import { scrollToTop, scrollIntoView } from "helpers/StyleUtils";
import { useHistory } from "react-router-dom";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Axios from "helpers/Interceptor";
import humanizeDuration from "humanize-duration";
import { toast, ToastOptions } from "react-toastify";
import { CreativeCampaign, CurrentBrand, ColorPalette, Template, JobDetails } from "types/global";
import { track } from "analytics";
import { isMobile } from "react-device-detect";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

const TOAST_OPTIONS: ToastOptions = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
}

interface CreativeGeneratorStatusPageProps {
    currentBrand: CurrentBrand
}

// creative_generator goal 
export default function CreativeGeneratorStatusPage() {
    const { currentBrand } = useContext(CurrentBrandContext)
    const history = useHistory();

    // tab mvg
    const mainTitle = "Music Video Generator"

    // Active Campaigns
    const [currentCampaigns, setCurrentCampaigns] = useState<CreativeCampaign[]>([]);
    const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);

    async function refreshCampaigns() {
        setLoadingCampaigns(true);

        // get campaigns
        async function getCampaigns() {
            try {
                const puller = await Axios.get(`/campaign/brand/${currentBrand?.id}/creative-jobs`);
                if (puller && puller.data.data) {
                    const filteredCampaigns = puller.data.data.filter((campaign: CreativeCampaign) => {
                        const { status } = campaign;
                        return status !== 'DRAFT';
                    });
                    setCurrentCampaigns(filteredCampaigns as CreativeCampaign[]);
                }
            } catch (e) {
                console.error("Error pulling brand campaigns", e);
            }
            setLoadingCampaigns(false);
        }

        getCampaigns();
    }

    useEffect(() => {
        scrollToTop()
        scrollIntoView()
        refreshCampaigns();
    }, [])

    const handleGoBack = () => {
        history.push("/marketing");
    };

    return (<MainContentContainer>
        <div className="md:pt-4 md:pb-20 card-body ">
            <div className="card-content bg-white shadow-none lg:shadow-lg">
                <div className="top-wrapper">
                    <div className="card-head relative">
                        <Back
                            onClick={handleGoBack}
                            parentClassName="absolute top-1/2 transform-gpu -translate-y-1/2"
                            color="white"
                            childClassName="stroke-current text-primary lg:text-white" />


                        <h4 className="text-center text-dark lg:text-white text-2xl al">
                            {mainTitle}
                        </h4>
                    </div>

                    <div className="relative h-100 lg:h-auto lg:pt-0" style={{ marginBottom: '56px' }}>
                        <div className="sy-card px-5 lg:px-10">
                            <div className="">

                                <h5 className="text-dark font-bold text-lg">Your Videos</h5>
                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                    Symphony's AI-powered video generator will create videos automatically based on your creative. You can see the status of the videos here. If you have questions or feedback on the videos you've generated, reach out to us <a href="mailto:team@symphonyos.co"
                                        className="text-underline text-gray-500" style={{ textDecorationLine: "underline" }}>team@symphonyos.co</a>.
                                </p>
                            </div>
                        </div>
                        {loadingCampaigns || !currentCampaigns.length ? (
                            <div className="p-6 flex-col flex items-center mt-4">
                                <LoadingIndicator height="100px" color="black" />
                                <p className="mt-1 text-center">Loading...</p>
                            </div>
                        ) : (
                            <div className="md:p-6 pt-4 px-2 md:px-10 flex flex-row flex-wrap justify-center">
                                {
                                    currentCampaigns.map((campaign: CreativeCampaign, idx: number) => {
                                        const {
                                            content,
                                            creativeData,
                                            jobDetails,
                                            status,
                                            id
                                        } = campaign

                                        return <ContentGeneratorJobBox
                                            key={id + idx}
                                            campaignId={id}
                                            thumbnail_url={content.thumbnail_url}
                                            name={content.name}
                                            jobStatus={status}
                                            jobDetails={jobDetails}
                                            template={creativeData.template}
                                            colorPalette={creativeData.colorPalette} />
                                    })
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </MainContentContainer>

    )
}

function ContentGeneratorJobBoxDownloadBtn(props: {
    type: string,
    campaignId: number,
    trackDownload: () => void,
}) {

    const [loadingUrl, setLoadingUrl] = useState(false)
    const [presignedUrl, setPresignedUrl] = useState<string | null | undefined>(null)

    const {
        type,
        campaignId,
        trackDownload,
    } = props

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async function fullDownload() {
        trackDownload()

        const showToast = () => {
            toast.success(`Check your browser's "Downloads" folder to save your videos onto your phone.`, TOAST_OPTIONS)
        }

        if (presignedUrl) {
            window.location.assign(presignedUrl)
            if (isMobile) setTimeout(showToast, 3000)
        } else {
            setLoadingUrl(true)

            try {
                const getDownloadUrl = await Axios.get(`/contentgenerator/${campaignId}/download/${type}`)

                if (getDownloadUrl.data && getDownloadUrl.data.data) {
                    const {
                        downloadUrl
                    } = getDownloadUrl.data.data
                    setPresignedUrl(downloadUrl)

                    window.location.assign(downloadUrl)
                    if (isMobile) setTimeout(showToast, 3000)
                }
            } finally {
                setLoadingUrl(false)
            }
        }
    }

    return loadingUrl ? 
        <button disabled={true} className="flex items-center h-12 flex-shrink-1 justify-center w-full border-t py-2 font-semibold text-primary">
            <LoadingIndicator color="black" height="20px"></LoadingIndicator>
        </button>
        :
        <button
            onClick={fullDownload}
            className="flex items-center h-12 flex-shrink-1 flex justify-center w-full border-t py-2 font-semibold text-primary">
            Download {capitalizeFirstLetter(type)}
        </button>
}

interface ContentGeneratorJobBoxProps {
    thumbnail_url: string,
    name: string,
    jobStatus: string,
    jobDetails: JobDetails,
    template: Template,
    colorPalette: ColorPalette,
    campaignId: number
}

function ContentGeneratorJobBox(props: ContentGeneratorJobBoxProps) {

    const {
        thumbnail_url,
        name,
        jobStatus,
        jobDetails,
        template,
        colorPalette,
        campaignId
    } = props

    const trackDownload = () => {
        track("Downloaded Music Video Generator Asset", {
            thumbnail_url,
            name,
            jobStatus,
            jobDetails,
            template,
            colorPalette,
            campaignId
        })
    }

    function showAdsStatusIndicator(status: string, jobDetails: {
        links: any[],
    }) {
        switch (status) {
            case "IN_REVIEW":
                return (
                    <div className="rounded-sm bg-yellow-500 text-white py-1 text-xs px-3 text-center">
                        GENERATING...
                    </div>
                );

            case "FINISHED":
                switch (jobDetails.links.length) {
                    case 0:
                        return (
                            <div className="rounded-sm bg-red-500 text-white py-1 text-xs px-3 text-center">
                                ISSUES
                            </div>
                        );
                    case 1:
                        return (
                            <div className="flex flex-col">
                                <div className="pb-0.5">
                                    <div className="rounded-sm bg-green-500 text-white py-1 text-xs px-3 text-center">
                                        COMPLETED
                                    </div>
                                </div>
                                <div className="pt-0.5">
                                    <div className="rounded-sm bg-red-500 text-white py-1 text-xs px-3 text-center">
                                        ISSUES
                                    </div>
                                </div>
                            </div>
                        );
                    default:
                        return (
                            <div className="rounded-sm bg-green-500 text-white py-1 text-xs px-3 text-center">
                                COMPLETED
                            </div>
                        );
                }
            case "ERROR":
                return (
                    <div className="rounded-sm bg-red-500 text-white py-1 text-xs px-3 text-center">
                        ISSUES
                    </div>
                );
        }
    }


    function showActionButton(status: string, jobDetails: JobDetails) {
        switch (status) {
            case "IN_REVIEW":
                const estimatedTimeToComplete = (jobDetails.estimatedTimeToComplete || 0) * 1000;
                return (
                    <button disabled={true} className="flex items-center h-12 flex-shrink-1 justify-center w-full border-t py-2 font-semibold text-primary">
                        <LoadingIndicator color="black" height="20px"></LoadingIndicator>
                        {`${humanizeDuration(estimatedTimeToComplete, { units: ["m"], round: true })} left`}
                    </button>
                );

            case "FINISHED": {
                switch (jobDetails.links.length) {
                    case 0:
                        return (
                            <button disabled={true} className="h-12 flex-shrink-1 flex items-center  justify-center w-full border-t py-2 font-semibold text-primary">
                                <LoadingIndicator color="black" height="20px"></LoadingIndicator>
                            </button>
                        );
                    case 1:
                        return (
                            <div className="flex">
                                {jobDetails.links.map((link: {
                                    type: string,
                                }) => {
                                    return (
                                        <ContentGeneratorJobBoxDownloadBtn
                                            type={link.type}
                                            campaignId={campaignId}
                                            trackDownload={trackDownload}
                                        />
                                    )
                                })}
                                <button disabled={true} className="h-12 flex-shrink-1 flex items-center  justify-center w-full border-t py-2 font-semibold text-primary">
                                    <LoadingIndicator color="black" height="20px"></LoadingIndicator>
                                </button>
                            </div>
                        );
                    default:
                        return (
                            <div className="flex">
                                {jobDetails.links.map((link: {
                                    type: string,
                                }) => {
                                    return (
                                        <ContentGeneratorJobBoxDownloadBtn
                                            campaignId={campaignId}
                                            type={link.type}
                                            trackDownload={trackDownload}
                                        />
                                    )
                                })}
                            </div>
                        );
                }
            }
            case "ERROR":
                return (
                    <button disabled={true} className="h-12 flex-shrink-1 flex items-center  justify-center w-full border-t py-2 font-semibold text-primary">
                        <LoadingIndicator color="black" height="20px"></LoadingIndicator>
                    </button>
                );
        }
    }

    return (
        <div className="border rounded-md w-full md:w-5/12 mx-4 mb-8">
            <div className="p-4">
                <div className="flex items-center justify-between h-12">
                    <div className="flex items-center">
                        <div className="artist-img flex-shrink-0">
                            <img alt="thumbnail.png" src={thumbnail_url} />
                        </div>
                        <div className="px-3">
                            <h5 className="text-dark font-bold text-lg">{name}</h5>
                        </div>
                    </div>
                    {showAdsStatusIndicator(jobStatus, jobDetails)}
                </div>
                <div className="pt-2">
                    <p>Feed + Story Videos Generated</p>
                    <p>{template.name} Theme</p>
                    <div className='mt-1 rounded-md w-full flex-row flex '>
                        {Object.entries(colorPalette).map(([, color]: string[], idx: number) => {
                            return (
                                <div
                                    key={color.replace('#', '') + idx}
                                    className='rounded-sm w-4 h-4 mr-2'
                                    style={{
                                        backgroundColor: color,
                                    }}
                                ></div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {showActionButton(jobStatus, jobDetails)}
        </div>
    )

}