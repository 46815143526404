import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import CloseButton from "components/shareable/CloseButton";
import millify from "millify";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";
import { OutlinedButton } from "styles/shared";
import { GraphTooltip, GrowthIndicatorMessage } from "./Graphs";
import { CSVLink } from "react-csv";
import html2canvas from 'html2canvas';
import dayjs from "dayjs";
import { DATE_OPTIONS, getDatesByRange, getFanbaseData } from "../utils";
import DatePicker from "react-datepicker";
import "styles/react-datepicker.css";
import { CurrentBrand } from "types/global";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { DateSelectorButton } from "../styles";
import { track } from "analytics";
import LoaderV2 from 'components/Loader/LoaderV2';
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { PlanType } from "modules/Const";
import SymphonyDefaultAnnotationsModal from "components/shareable/SymphonyDefaultAnnotationsModal";
import LegendCampaignAnnotations from "components/shareable/LegendCampaignAnnotations";
import PopoverCampaignAnnotations from "components/shareable/PopoverCampaignAnnotations";
import SymphonyDefaultAnnotations from "components/shareable/SymphonyDefaultAnnotations";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { hasAnnotations } from "modules/Utils";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import useScreen from "Hooks/useScreen";

interface LargeGraphPopupProps {
    className?: string;
    onClose: () => void;
    restrictDateRange?: DATE_OPTIONS[] | null
    disableReport?: boolean
    platformName: string | null;
    platformId: string | null;
    statLabel: string | null;
    imageAsset: string | null;
    chartData: {
        date: string;
        value: number;
    }[];
    primaryValue: number | null;
    statIdentifier: string | null;
    additionalContent?: JSX.Element | null | undefined
}

export const CustomDateSelector = forwardRef(({
    userChangedDate,
    color,
    value,
    onClick
}: any, ref: any) => {
    const backgroundColorStyle = color ? { backgroundColor: color } : {};
    return (
        <button
            className={`${userChangedDate ? 'border-primary text-primary' : ''} border rounded p-1 mr-2 w-28`}
            onClick={onClick}
            ref={ref}
            style={backgroundColorStyle}
        >
            {value}
        </button>
    )
});

const LargeGraphPopup = ({
    className = '',
    additionalContent,
    onClose,
    primaryValue,
    platformId,
    platformName,
    statLabel,
    statIdentifier,
    imageAsset,
    chartData,
    disableReport,
    restrictDateRange
}: LargeGraphPopupProps) => {
    const { mobileView } = useScreen();
    const { currentBrand, isProBrand } = useContext(CurrentBrandContext)

    const [showGridModal, setShowGridModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedIgPost, setSelectedIgPost] = useState(null);
    const [selectedSpotifyRelease, setSelectedSpotifyRelease] = useState(null);
    const [showAnnotations, setShowAnnotations] = useState(false);
    const [itemsArray, setItemsArray] = useState<any[]>([]);

    const hasRequiredData = platformName && imageAsset && statLabel && chartData && primaryValue;

    const [isLoadingNewDateRange, setIsLoadingNewDateRange] = useState<boolean>(false)
    let items: {
        key: DATE_OPTIONS;
        label: string;
        requiresPro: boolean;
    }[] = [
            {
                key: 'last-7-days',
                label: "Last 7 Days",
                requiresPro: false
            },
            {
                key: 'last-30-days',
                label: "Last 30 Days",
                requiresPro: true
            },
            {
                key: 'last-3-months',
                label: "Last 3 Months",
                requiresPro: true
            },
        ]

    if (restrictDateRange) {
        items = items.filter((o) => {
            if (restrictDateRange) {
                return restrictDateRange.includes(o.key)
            } else {
                return true
            }
        })
    }

    // pro status check for:
    // 1. date range
    // 2. downloads
    const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

    // holds the data displayed by the card
    const [selectedChartData, setChartData] = useState<{
        date: string,
        value: number
    }[]>(chartData)

    // sets the selected date range
    const [userManuallyChangedDate, setUserChangedDate] = useState<boolean>(false)
    const [selectedRange, setSelectedRange] = useState<DATE_OPTIONS | null>('last-7-days')
    const [selectedDate, setSelectedDate] = useState<{
        startDate: string;
        endDate: string;
    }>({
        startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD")
    })

    const initSelectedItem = () => {
        setSelectedCampaign(null)
        setSelectedIgPost(null)
        setSelectedSpotifyRelease(null)
    }

    const updateDateRange = (range: DATE_OPTIONS) => {
        setSelectedRange(range)
        var {
            startDate,
            endDate
        } = getDatesByRange(range)

        setSelectedDate({
            startDate: startDate,
            endDate: endDate
        })

        setShowGridModal(false)
        initSelectedItem()
    }

    const renderDateSelectorButtons = () => {
        return items.map((o: {
            key: DATE_OPTIONS;
            label: string;
            requiresPro: boolean;
        }) => {
            const isLocked = o.requiresPro && !isProBrand
            return (
                <DateSelectorButton
                    selected={selectedRange === o.key}
                    onClick={() => {
                        if (isLocked) {
                            onClose()
                            handleOpenUpgradeToProModal({
                                highlightedPlan: PlanType.PRO,
                                upgradeCallToAction: "Upgrade to Pro to unlock unlimited Dashboard data.",
                                source: "Dashboard - Large Graph Popup - Date Selector"
                            })
                        } else {
                            updateDateRange(o.key)
                        }
                    }}
                >
                    {isLocked &&
                        <div className="mr-1">
                            <UpgradeToProChip className="largeGraphPopupDateSelectorProChip" type="gem" color="purple" height={18} width={18} />
                        </div>
                    }
                    {o.label}
                </DateSelectorButton>
            )
        })
    }

    const renderCustomDatesPicker = () => {
        return (
            <>
                <p className="mr-1">From:</p>
                <DatePicker
                    disabled={!isProBrand}
                    filterDate={(date: Date) => {
                        return dayjs(date) < dayjs(selectedDate.endDate).subtract(1, 'day');
                    }}
                    selected={dayjs(selectedDate.startDate).toDate()}
                    onChange={(date) => {
                        setUserChangedDate(true)

                        setSelectedDate({
                            startDate: dayjs(date).format("YYYY-MM-DD"),
                            endDate: selectedDate.endDate
                        })
                    }}
                    customInput={<CustomDateSelector />}
                />

                <p className="mr-1">To:</p>
                <DatePicker
                    disabled={!isProBrand}
                    minDate={dayjs(selectedDate.startDate).add(1, 'day').toDate()}
                    maxDate={dayjs().toDate()}
                    selected={dayjs(selectedDate.endDate).toDate()}
                    onChange={(date) => {
                        setUserChangedDate(true)
                        setSelectedDate({
                            endDate: dayjs(date).format("YYYY-MM-DD"),
                            startDate: selectedDate.startDate
                        })
                    }}
                    customInput={<CustomDateSelector />}
                />
            </>
        )
    }

    useEffect(() => {
        if (selectedRange) {
            setUserChangedDate(false)
        }
    }, [selectedRange])

    useEffect(() => {
        if (userManuallyChangedDate) {
            setSelectedRange(null)
        }
    }, [userManuallyChangedDate])

    const updateData = async () => {
        if (hasRequiredData) {
            setIsLoadingNewDateRange(true)
            var p = await getFanbaseData({
                brandId: currentBrand!.id,
                statIdentifier: statIdentifier!,
                platformId: platformId!,
                startDate: selectedDate.startDate,
                endDate: selectedDate.endDate
            })
            if (p.data &&
                p.data.data &&
                p.data.data.chartData) {

                const mapped = p.data.data.chartData
                    .filter((o: {
                        datetime: string;
                    }) => {
                        return dayjs(o.datetime).isBefore(dayjs(selectedDate.endDate))
                    })
                    .map((o: {
                        datetime: string;
                        fanbase_count: string;
                    }) => {
                        return {
                            date: o.datetime,
                            value: parseInt(o.fanbase_count)
                        }
                    })
                setChartData(mapped)
                setIsLoadingNewDateRange(false)
            }
        } else {
            setIsLoadingNewDateRange(false)
            return null
        }
    }

    useEffect(() => {
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformName, selectedDate])

    // to handle report button
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    /**
     * Handles the click event for the download report button
     * @param event - The mouse event from clicking the button
     */
    const handleClickDownloadReport = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // to handle downloading image
    const printRef = useRef(null);

    const handleDownloadImage = async () => {
        const element: any = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = `${platformName} ${statLabel}.jpg`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };

    // to handle downloading CSV
    const CSV_HEADERS = [
        { label: "Date", key: "date" },
        { label: statLabel ? statLabel : 'value', key: "value" },
    ];

    const reportButton = () => {
        return (
            <>
                <ButtonSymphony
                    className="downloadReportGraphButton"
                    style={{ flexShrink: 0, marginLeft: 10 }}
                    onClick={handleClickDownloadReport}
                    variant="outlined"
                    size="small"
                    iconLeft={
                        <UpgradeToProChip
                            className="largeGraphPopupDownloadReportProChip"
                            type="gem"
                            color="purple"
                            height={18}
                            width={18}
                        />
                    }
                >
                    Download Report
                </ButtonSymphony>
                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={(e: any) => {
                            if (!isProBrand) {
                                e.preventDefault()
                                handleOpenUpgradeToProModal({
                                    highlightedPlan: PlanType.PRO,
                                    upgradeCallToAction: "Upgrade to Pro to unlock unlimited Dashboard data.",
                                    source: "Dashboard - Large Graph Popup - Download Report"
                                })
                                track('Free User - tried to Download CSV', {
                                    brandId: currentBrand!.id,
                                    platformId: platformId,
                                    statIdentifier: statIdentifier,
                                    startDate: selectedDate.startDate,
                                    endDate: selectedDate.endDate,
                                    range: selectedRange
                                })
                            } else {
                                track('Download CSV', {
                                    brandId: currentBrand!.id,
                                    platformId: platformId,
                                    statIdentifier: statIdentifier,
                                    startDate: selectedDate.startDate,
                                    endDate: selectedDate.endDate,
                                    range: selectedRange
                                })
                            }

                            handleClose()
                        }}
                    >
                        <CSVLink
                            className="flex items-center"
                            style={{ color: 'inherit' }}
                            filename={`${platformName} ${statLabel}.csv`}
                            headers={CSV_HEADERS}
                            data={selectedChartData}
                        >
                            Download CSV
                        </CSVLink>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            if (!isProBrand) {
                                handleOpenUpgradeToProModal({
                                    highlightedPlan: PlanType.PRO,
                                    upgradeCallToAction: "Upgrade to Pro to unlock unlimited Dashboard data.",
                                    source: "Dashboard - Large Graph Popup - Download Report"
                                })
                                track('Free User - tried to Download PNG', {
                                    brandId: currentBrand!.id,
                                    platformId: platformId,
                                    statIdentifier: statIdentifier,
                                    startDate: selectedDate.startDate,
                                    endDate: selectedDate.endDate,
                                    range: selectedRange
                                })
                            } else {
                                track('Download PNG', {
                                    brandId: currentBrand!.id,
                                    platformId: platformId,
                                    statIdentifier: statIdentifier,
                                    startDate: selectedDate.startDate,
                                    endDate: selectedDate.endDate,
                                    range: selectedRange
                                })
                                handleDownloadImage()
                            }
                            handleClose()

                        }}
                    >
                        Download Image
                    </MenuItem>
                </Menu>
            </>
        )
    }

    const renderLargeGraphPopupDateSelector = () => {
        if (mobileView) {
            return null;
        }

        return (
            <div className="largeGraphPopupDateSelectorContainer mt-6 mb-2 flex flex-col md:flex-row justify-between items-center">
                <div className="flex items-center justify-center">
                    {renderDateSelectorButtons()}
                </div>
                <div className="flex items-center justify-center">
                    {renderCustomDatesPicker()}
                </div>
            </div>
        )
    }

    const renderLargeGraphPopupDateSelectorSmall = () => {
        if (mobileView) {
            return (
                <div className=" flex flex-col md:flex-row justify-between items-center">
                    <div className="flex items-center justify-center">
                        {renderDateSelectorButtons()}
                    </div>
                    <div className="mt-2 flex items-center justify-center">
                        {renderCustomDatesPicker()}
                    </div>
                </div>
            )
        }

        return null;
    }

    let percentageChange = 0
    if (selectedChartData && selectedChartData.length > 0) {
        if (selectedChartData[0].value === 0) {
            percentageChange = 100 * 1
        } else {
            percentageChange = 100 * ((selectedChartData[selectedChartData.length - 1].value - selectedChartData[0].value) / selectedChartData[0].value)
        }
    }

    return (
        <div className={`largeGraphPopupComponent ${className}`} style={sty.largeGraphPopupComponent}>
            {hasRequiredData &&
                <div>
                    <div className="flex w-full justify-between items-center">
                        <div className="largeGraphPopupImageContainer" style={sty.largeGraphPopupImageContainer}>
                            <img
                                className="flex-shrink-0"
                                width={50}
                                src={imageAsset}
                                alt="Image"
                            />
                            <div className="ml-2 text-left self-center">
                                <div className="flex align-center flex-row">
                                    <p className="text-3xl md:text-4xl font-semibold">{millify(primaryValue)}</p>
                                    {isLoadingNewDateRange &&
                                        <div className="ml-1 flex items-center justify-center">
                                            <LoaderV2 height={18} width={18} />
                                        </div>
                                    }
                                </div>
                                <p className="text-md text-gray-600">
                                    {platformName} {statLabel}
                                </p>
                            </div>
                        </div>
                        <GrowthIndicatorMessage
                            className="largeGraphPopupGrowthIndicatorMessage"
                            style={sty.largeGraphPopupGrowthIndicatorMessage}
                            percentage={percentageChange}
                        />
                        {window.innerWidth >= 768 &&
                            <PopoverCampaignAnnotations
                                className="largeGraphPopupPopoverCampaignAnnotations"
                                style={{ marginLeft: 10 }}
                                showAnnotations={showAnnotations}
                                setShowAnnotations={setShowAnnotations}
                                upgradeSource="Dashboard - Large Graph Popup Desktop - Annotations"
                            />
                        }
                        {!disableReport && window.innerWidth >= 768 && reportButton()}
                    </div>

                    {renderLargeGraphPopupDateSelector()}
                    {hasAnnotations(showAnnotations) && <LegendCampaignAnnotations />}

                    <div className="fullAreaChartContainer mt-4 w-full" ref={printRef}>
                        <SymphonyDefaultAnnotationsModal
                            itemsArray={itemsArray}
                            showGridModal={showGridModal}
                            setShowGridModal={setShowGridModal}
                            selectedCampaign={selectedCampaign}
                            selectedIgPost={selectedIgPost}
                            selectedSpotifyRelease={selectedSpotifyRelease}
                            setSelectedCampaign={setSelectedCampaign}
                            setSelectedIgPost={setSelectedIgPost}
                            setSelectedSpotifyRelease={setSelectedSpotifyRelease}
                        />
                        <ResponsiveContainer width="100%" height={window.screen.width > 500 ? 300 : window.innerHeight * 0.3} >
                            <AreaChart
                                data={selectedChartData}
                                margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
                            >
                                <CartesianGrid
                                    stroke="#8800FF29"
                                    strokeWidth={1.5}
                                    strokeDasharray="8 8"
                                />
                                <XAxis
                                    dy={12}
                                    axisLine={false}
                                    dataKey="date"
                                    tickLine={false}
                                    minTickGap={30}
                                    tickFormatter={(value: any, index: number) => {
                                        return dayjs(value, 'YYYY-MM-DD').format("MM/DD")
                                    }}
                                />
                                <YAxis
                                    mirror={window.innerWidth < 768}
                                    tick={(e) => {
                                        const { payload: { value } } = e;
                                        const color = value === "yourdata" ? "red" : "#666";
                                        e["fill"] = color;
                                        if (window.innerWidth >= 768) {
                                            return (
                                                <Text
                                                    {...e}
                                                    style={{ zIndex: 99 }}
                                                >
                                                    {millify(value, {
                                                        precision: 2
                                                    })}
                                                </Text>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    <rect></rect>
                                                    <defs>
                                                        <filter id="rounded-corners" x="-5%" width="110%" y="0%" height="100%">
                                                            <feFlood floodColor="#fff" />
                                                            {/* <feGaussianBlur stdDeviation="2" /> */}
                                                            <feComponentTransfer>
                                                                <feFuncA type="table" tableValues="1 1 1 1" />
                                                            </feComponentTransfer>

                                                            <feComponentTransfer>
                                                                <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                                                            </feComponentTransfer>
                                                            <feComposite operator="over" in="SourceGraphic" />
                                                        </filter>
                                                    </defs>
                                                    <Text
                                                        style={sty.numberMilliText}
                                                        filter="url(#rounded-corners)"
                                                        {...e}
                                                    >
                                                        {millify(value, {
                                                            precision: 2
                                                        })}
                                                    </Text>
                                                </>
                                            )
                                        }
                                    }}
                                    tickCount={window.innerWidth < 768 ? 3 : 4}
                                    axisLine={false}
                                    minTickGap={window.innerWidth < 768 ? 30 : 20}
                                    tickLine={false}
                                    allowDecimals={false}
                                    domain={['auto', 'auto']}
                                />
                                <Tooltip
                                    cursor={{ stroke: '#42c221', strokeWidth: 2 }}
                                    content={({ active, payload, label }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <GraphTooltip
                                                    title={dayjs(label).format('dd MM/DD')}
                                                    value={payload[0].value!.toLocaleString('en')}
                                                />
                                            );
                                        }
                                        return null;
                                    }}
                                />

                                <defs>
                                    <linearGradient
                                        id="small-graph"
                                        x1="0" y1="0" x2="0" y2="1"
                                    >
                                        <stop stopColor="#42c221" stopOpacity={0.4} />
                                        <stop offset="1" stopColor="#42c221" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <Area
                                    type="monotone"
                                    fill="url(#small-graph)"
                                    strokeWidth={2}
                                    dataKey="value" stroke="#42c221" fillOpacity="0.8" activeDot={{ r: 8 }}
                                />

                                {SymphonyDefaultAnnotations({
                                    currentBrand: currentBrand!,
                                    startDate: selectedDate.startDate,
                                    endDate: selectedDate.endDate,
                                    showAnnotations,
                                    setShowGridModal,
                                    setSelectedCampaign,
                                    setSelectedIgPost,
                                    setSelectedSpotifyRelease,
                                    setItemsArray
                                })}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    {window.innerWidth < 768 &&
                        <PopoverCampaignAnnotations
                            className="largeGraphPopupPopoverCampaignAnnotations"
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            showAnnotations={showAnnotations}
                            setShowAnnotations={setShowAnnotations}
                            upgradeSource="Dashboard - Large Graph Popup Mobile - Annotations"
                        />
                    }
                    {renderLargeGraphPopupDateSelectorSmall()}
                </div>
            }

            {additionalContent}
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    largeGraphPopupComponent: {
        width: '100%'
    },
    numberMilliText: {
        transform: "translateX(-4px)",
        zIndex: 99
    },
    downloadReportText: {
        fontWeight: 600,
        whiteSpace: 'nowrap',
        marginLeft: 5
    },
    largeGraphPopupImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
    },
    largeGraphPopupGrowthIndicatorMessage: {
        marginLeft: 10
    }
}

export default LargeGraphPopup;