import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { Body2, Subtitle1 } from 'components/shareable/Typography';
import useScreen from 'Hooks/useScreen';
import styled from 'styled-components';
import { Container, TextButton } from 'styles/shared';

// Define the types for the component's props
type CreatorButtonProps = {
    imageSrc: string; // The source URL for the image
    title: string; // The title text
    description: string; // The description text
    buttonText: string; // The text for the button
    onClick: () => void; // The onClick handler function
};

// Styled-components for the various elements
type Props = {
    mobileView: boolean;
}

const ButtonContainer = styled(Container) <Props>`
  display: flex;
  flex-direction:${({ mobileView }) => mobileView ? 'row' : 'column'};
  align-items: center;
  background: white;
  padding: ${({ mobileView }) => mobileView ? '14px' : '20px'};
  border-radius: 10px;
  transition: all 0.2s;
  border 1px solid #EDECF2;
  box-shadow: none;
  overflow:visible;
  max-width: ${({ mobileView }) => mobileView ? 'unset' : '300px'};
  gap: ${({ mobileView }) => mobileView ? '16px' : '8px'};
  cursor: pointer;
  &:hover {
    background: #f6ebff;
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
  }
`;

const Image = styled.img<Props>`
  height: ${({ mobileView }) => mobileView ? 'auto' : '80px'}; // Adjust the size as needed
  width: ${({ mobileView }) => mobileView ? '60px' : 'auto'};;
  margin-bottom: ${({ mobileView }) => mobileView ? 'unset' : '20px'};
`;

// The component definition using styled-components
const OptionCard = ({
    imageSrc,
    title,
    description,
    buttonText,
    onClick,
}: CreatorButtonProps) => {
    const { mobileView } = useScreen();

    return (
        <ButtonContainer onClick={onClick}
            mobileView={mobileView}>
            <Image mobileView={mobileView} src={imageSrc} alt={title} />
            <Container
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
                cursor="pointer"
            >
                <Subtitle1>{title}</Subtitle1>
                <Body2>{description}</Body2>
                <ButtonSymphony
                    style={{
                        marginTop: '12px',
                        marginRight: 'auto',
                    }}
                    onClick={onClick}>{buttonText}</ButtonSymphony>
            </Container>

        </ButtonContainer>
    );
};

export default OptionCard;
