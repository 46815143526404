import { Theme, makeStyles } from "@material-ui/core/styles"
import Colors from "modules/Colors"
import Sty from "modules/Sty"
import styled from "styled-components"
import { THEME_BREAKPOINTS } from "types/global"

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(THEME_BREAKPOINTS.MEDIUM)]: {
      width: "80%",
    },
    [theme.breakpoints.down(THEME_BREAKPOINTS.MOBILE)]: {
      width: "100%",
      height: 64,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  stepperMainContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 20,
    width: "100%",
    borderBottom: Sty.border,
  },
  textActive: { color: Colors.purple },
  textColorPrimary: {
    color: theme.palette.text.primary,
  },
  textColorSecondary: {
    color: theme.palette.text.secondary,
  },
  errorBackgroundColor: {
    backgroundColor: theme.palette.error.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  active: {
    "&.MuiStepIcon-root.MuiStepIcon-active": { color: Colors.purple },
  },
  completed: {
    "&.MuiStepIcon-root.MuiStepIcon-completed": { color: Colors.green },
  },
  unactive: { color: Colors.greyLight },
  horizontalStepper: {
    border: Sty.border,
  },
  text: {
    height: 22,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "DIN",
  },
  stepLabelRoot: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      height: 22,
      fontSize: 16,
      fontWeight: 600,
      color: Colors.purple,
    },
  },
  empty: {},
}))

type ButtonsContainerProps = {
  marginTop?: number
  marginBottom?: number
}

type CircleProps = {
  backgroundColor: string
}

type ActiveStepLabelProps = { color: string }

export interface StyledLabelProps {
  fontSize?: number
  fontWeight?: number
  lineHeight?: string
  color?: string
  cursor?: string
  margin?: string
  padding?: string
  paddingLeft?: string
  marginLeft?: string
}

interface FlexContainerProps {
  width?: string
  alignItems?: string
  flexDirection?: string
  margin?: string
  backgroundColor?: string
  flexGrow?: string
  justifyContent?: string
  paddingTop?: string
  paddingBottom?: string
  paddingRight?: string
  paddingLeft?: string
  borderTop?: string
}

interface ContainedButtonProps {
  width?: string
}

interface OutlinedButtonProps {
  width?: string
}

interface DummyDivProps {
  width?: string
}

export const FlexContainer = styled.div`
  width: ${({ width }: FlexContainerProps) => width};
  display: flex;
  align-items: ${({ alignItems }: FlexContainerProps) => alignItems};
  flex-direction: ${({ flexDirection }: FlexContainerProps) =>
    flexDirection || "row"};
  margin: ${({ margin }: FlexContainerProps) => margin};
  background-color: ${({ backgroundColor }: FlexContainerProps) =>
    backgroundColor};
  flex-grow: ${({ flexGrow }: FlexContainerProps) => flexGrow};
  justify-content: ${({ justifyContent }: FlexContainerProps) =>
    justifyContent};
  padding-top: ${({ paddingTop }: FlexContainerProps) => paddingTop};
  padding-bottom: ${({ paddingBottom }: FlexContainerProps) => paddingBottom};
  padding-right: ${({ paddingRight }: FlexContainerProps) => paddingRight};
  padding-left: ${({ paddingLeft }: FlexContainerProps) => paddingLeft};
  border-top: ${({ borderTop }: FlexContainerProps) => borderTop};
`

export const StepperContainer = styled.div`
  margin-top: 40px;
  max-width: 1080px;
  padding-right: 40px;
`

export const StepButtonsContainer = styled.div`
  width: 100%;
  background-color: white;
  margin-top: ${({ marginTop }: ButtonsContainerProps) =>
    !!marginTop ? marginTop : 0}px;
  margin-bottom: ${({ marginBottom }: ButtonsContainerProps) =>
    !!marginBottom ? marginBottom : 0}px;
  position: fixed;
  bottom: 0px;
  z-index: 200;
  box-shadow: 5px 5px 8px 2px rgba(0, 0, 0, 0.8);
`

export const CircularContainer = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ backgroundColor }: CircleProps) => backgroundColor};
`

export const ActiveStepLabel = styled.div`
  position: relative;
  color: ${({ color }: ActiveStepLabelProps) => color};
  left: 8.5px;
  top: 1.8px;
`

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
`

export const ContainedButton = styled.button<ContainedButtonProps>`
  width: ${({ width }) => width || "116px"};
  background-color: rgba(136, 0, 255, 1);
  color: white;
  font-size: 16px;
  border-radius: 48px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: DIN;
  text-transform: none;
  height: 40px;
  font-weight: 600;
  :disabled {
    cursor: not-allowed;
  }
`

export const OutlinedButton = styled.button`
  width: ${({ width }: OutlinedButtonProps) => width || "116px"};
  border: 1px solid rgba(136, 0, 255, 1);
  background-color: white;
  color: rgba(136, 0, 255, 1);
  font-size: 16px;
  border-radius: 48px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: DIN;
  text-transform: none;
  height: 40px;
  font-weight: 600;
`

export const DummyDiv = styled.div`
  width: ${({ width }: DummyDivProps) => width || "116px"};
`

export default useStyles
