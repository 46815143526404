import { Grid, Menu, MenuItem } from "@material-ui/core";
import { Fragment, useState } from "react";
import { IconContainer, useStyles } from "./styles";
import { SymphonyTooltip } from "styles/shared";
import GemIcon from "../ProCallouts/GemIcon";

export interface MenuElement {
  icon?: JSX.Element,
  text: string
  show: boolean
  onClick: () => void
  disabled?: boolean
  tooltip?: string
  isProOnly?: boolean
}

export const ShowTooltip = ({
  children,
  tooltip
}: {
  children: JSX.Element,
  tooltip?: string
}) => {
  return (
    tooltip?.length ? (
      <SymphonyTooltip title={tooltip}>
        {children}
      </SymphonyTooltip>
    ) : (
      <>
        {children}
      </>
    )
  )
}

interface Props {
  children?: (JSX.Element | undefined)[] | JSX.Element
  menuItems?: MenuElement[]
}

const CustomMenu = ({ children, menuItems = [] }: Props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChildClick = (childClick: () => void) => {
    childClick();
    setAnchorEl(null);
  }

  return (
    <div className="customMenuComponent">
      <div aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {children}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={3}
        classes={{
          paper: classes.rootMenu,
        }}
      >
        {menuItems.map((menuItem, index) => {
          if (!menuItem.show) {
            return null
          }

          return (
            <MenuItem
              key={index}
              className="menuItemContainer"
              onClick={menuItem.disabled ? undefined : () => handleChildClick(menuItem.onClick)}
              style={menuItem.disabled ? { cursor: menuItem.disabled ? "not-allowed" : "pointer", color: "#b2b2b2" } : {}}
            >
              <ShowTooltip tooltip={menuItem.tooltip}>
                <div className="flex items-center">
                  <IconContainer>{menuItem.icon}</IconContainer>
                  {menuItem.text}
                  {menuItem.isProOnly &&
                    <GemIcon
                      style={{ marginLeft: 4 }}
                      width={16}
                      height={16}
                      color="purple"
                    />
                  }
                </div>
              </ShowTooltip>
            </MenuItem>
          )
        })
        }
      </Menu>
    </div>
  );
}

export default CustomMenu;

