import { InfoBanner } from "./styles"
import useStyles, { Card, StyledLabel, Icon } from "../../styles"
import Grid from "@material-ui/core/Grid"
import { VideoAdsRecord } from "types/global"
import YoutubeIcon from "assets/images/streaming/icons/youtube-logo.svg"
import { YouTubeCampaignGeographyTypes, YouTubeCampaignTypes } from "modules/Const"
import { clsx } from 'modules/Utils'
import { useMediaQuery, useTheme } from "@material-ui/core"

interface Props {
  record: VideoAdsRecord | null
  geographyType: string
  targetingType: string
}

interface CampaignTypeBannerProps {
  hideIcons?: boolean
  marginLeft?: string
}

const getVideoTargetingType = (targetingType?: string) => {
  const found = YouTubeCampaignTypes.find(({ id }) => id === targetingType)
  return found ? found.name : ""
}

const getVideoGeographyType = (geographyType?: string) => {
  const found = YouTubeCampaignGeographyTypes.find(({ id }) => id === geographyType)
  return found ? found.name : ""
}

const CampaignTypeBanner = ({
  hideIcons,
  marginLeft,
}: CampaignTypeBannerProps) => (
  <InfoBanner
    {...{ marginLeft }}
    width={hideIcons ? "220px" : undefined}
    minWidth={hideIcons ? "140px" : "244px"}
  >
    {!hideIcons && (
      <Grid>
        <Icon width={20} height={20} alt="spotify-icon.svg" src={YoutubeIcon} />
      </Grid>
    )}
    <Grid>
      <StyledLabel
        textAlign={hideIcons ? "center" : "right"}
        fontSize={12}
        fontWeight={400}
        width="210px"
      >
        Increase Video Views via YouTube Ads
      </StyledLabel>
    </Grid>
  </InfoBanner>
)

const HeadCard = ({
  record,
  geographyType,
  targetingType,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const hideIcons = useMediaQuery(theme.breakpoints.down(395))
  const isResponsive = useMediaQuery(theme.breakpoints.down(621))
  const { primaryThumbnail, title: recordTitle } = record || {}
  const foundVideoCampaignType = YouTubeCampaignTypes.find(
    (o) => o.id === targetingType
  )
  const { id: videoCampaignId } = foundVideoCampaignType || {}
  const videoGeographyType = getVideoGeographyType(geographyType)
  const videoTargetingType = getVideoTargetingType(targetingType)

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container direction="column">
            <StyledLabel fontSize={18} fontWeight={600}>
              Review Campaign Details
            </StyledLabel>
            <StyledLabel
              fontSize={16}
              fontWeight={400}
              color="#707070"
              marginTop="8px"
            >
              Review your details to make sure everything looks good before we
              submit your campaign to YouTube.
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.headCardReviewVideoSpace, classes.border)}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid className={classes.thumbnailContainer} item>
                <Icon
                  width={80}
                  alt="brand-icon.svg"
                  borderRadius="6px"
                  src={primaryThumbnail}
                />
              </Grid>
              <Grid item xs>
                <Grid container direction="column">
                  {isResponsive && (
                    <Grid>
                      <CampaignTypeBanner {...{ hideIcons }} />
                    </Grid>
                  )}
                  <StyledLabel
                    width="100%"
                    marginTop={isResponsive ? "8px" : undefined}
                    fontWeight={500}
                    fontSize={18}
                  >
                    {recordTitle}
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isResponsive && (
            <Grid item xs>
              <CampaignTypeBanner {...{ hideIcons }} marginLeft="auto" />
            </Grid>
          )}
        </Grid>
        {videoCampaignId !== "default" && (
          <Grid
            className={clsx(classes.space, classes.border)}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={600}>
                Campaign Targeting
              </StyledLabel>
            </Grid>
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={400}>
                {videoTargetingType}
              </StyledLabel>
            </Grid>
          </Grid>
        )}
        <Grid
          className={classes.space}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={600}>
              Locations
            </StyledLabel>
          </Grid>
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={400}>
              {videoGeographyType}
            </StyledLabel>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HeadCard
