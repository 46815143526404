import { CSSProperties } from 'react';

interface OverviewSongImageProps {
    className?: string;
    style?: CSSProperties;
    src: string;
    size?: number | null | undefined;
    isBorder?: boolean | null | undefined;
    alt?: string;
}

export const OverviewSongImage = ({
    className,
    style = {},
    src,
    size,
    isBorder,
    alt = '',
}: OverviewSongImageProps) => {
    let overviewSongImageComponentStyle = {
        ...sty.overviewSongImageComponent,
        width: size || 72,
        height: size || 72,
        border: isBorder ? '1px solid #E5E5E5' : 'none'
    };

    if (window.matchMedia('(max-width: 430px)').matches) {
        overviewSongImageComponentStyle.width = size || 40;
        overviewSongImageComponentStyle.height = size || 40;
    }

    return (
        <img
            className={`overviewSongImageComponent ${className}`}
            style={{ ...overviewSongImageComponentStyle, ...style }}
            src={src}
            alt={alt}
        />
    );
};

const sty = {
    overviewSongImageComponent: {
        borderRadius: 8,
        objectFit: 'cover',
    } as CSSProperties
}

export default OverviewSongImage;
