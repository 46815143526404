/* eslint-disable react-hooks/exhaustive-deps */
import {
    useEffect,
    useState,
    useContext,
} from "react"
import { MarketingCampaignType } from "types/global"
import { Tab, Tabs } from "@material-ui/core"
import {
    StyledNavBar,
    TabsPanelsContainer,
    Card,
    StyledLabel,
    BannerContainer,
} from "./styles"
import { TabPanel } from "react-headless-tabs"
import isEqual from "lodash/isEqual"
import startCase from "lodash/startCase"
import lowerCase from "lodash/lowerCase"
import Colors from "modules/Colors"
import { PageTabs } from "./utils"
import { useLocation } from "react-router-dom"
import OrganizationProfiles from "./ReadyState/OrganizationProfiles"
import OrganizationBilling from "./BillingTab"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import TeamTab from "./Components/TeamTab"
import { useHistory } from "react-router-dom";
import { OrganizationMarketingTab } from "./Components/OrganizationMarketingTab"
import CreateCampaignModal from "components/shareable/CreateCampaignModal"
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"
import QuickActionPanel, { POPULAR_CAMPAIGNS, QuickActionItem } from "components/shareable/QuickActions"
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import OrganizationWebsitesTab from "./Components/OrganizationMarketingTab/websites"
import OrganizationFansTab from "./Components/OrganizationMarketingTab/fans"
import { Body2 } from "components/shareable/Typography"
import Intercom from "helpers/Intercom"
import ButtonSymphony from "components/shareable/ButtonSymphony"

const { BRANDS, MARKETING_CAMPAIGNS, BILLING, TEAM, WEBSITES, FANS } = PageTabs

const tabs = [
    BRANDS, TEAM, MARKETING_CAMPAIGNS, WEBSITES, FANS, BILLING,
].map((tab) => ({
    value: tab,
    name: startCase(lowerCase(tab)),
}))

export const OrganizationReadyView = (props: { refreshStatus: () => void }) => {
    const {
        loadedBrands,
        getBrands: reloadBrands
    } = useContext(CurrentUserContext);

    const {
        remainingFreeTrial,
        isOrganizationInactive,
        isPaymentFailed,
        teamArtists,
        organization: currentOrganization,
        teamMembers,
    } = useCurrentTeam()


    const history = useHistory()
    const location = useLocation()

    const { refreshStatus } = props
    const [campaignModalOpen, setCampaignModalOpen] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const tabFromQuery = queryParams.get("tab") as PageTabs;

    useEffect(() => {
        if (tabFromQuery && Object.values(PageTabs).includes(tabFromQuery)) {
            setMaterialTabValue(tabFromQuery);
        }
    }, [tabFromQuery]);

    const [materialTabValue, setMaterialTabValue] = useState<PageTabs>(
        PageTabs.BRANDS
    )

    const proArtists = teamArtists.filter(brand => brand.isPro);
    let showBanner = isPaymentFailed || remainingFreeTrial > 0 || isOrganizationInactive


    const defaultQuickActionItems: QuickActionItem[] = [
        {
            key: "music",
            title: "Build a Music Site",
            icon: MusicSiteIcon,
            link: undefined,
            isWebsite: true
        },
        ...POPULAR_CAMPAIGNS.filter((campaignType: MarketingCampaignType) => !campaignType.underMaintenance).map((campaignType: MarketingCampaignType) => ({
            key: campaignType.link,
            title: campaignType.title,
            icon: campaignType.icon,
            link: undefined,
            isWebsite: false
        }))
    ];

    const handleQuickActionClick = (itemKey: string) => {
        const item = defaultQuickActionItems.find(i => i.key === itemKey);
        if (item?.isWebsite) {
            // Handle website creation
            return
        } else {
            // Navigate to campaign creation
            history.push(`/marketing/new/${itemKey}`);
        }
    };

    return (
        <>
            {showBanner &&
                <BannerContainer>
                    <OrganizationSubscriptionStatusBanner />
                </BannerContainer>
            }
            <div style={{
                ...sty.quickActionPanelContainer
            }}>
                <QuickActionPanel
                    askMaestroSource="Marketing - Team Plan"
                    campaignCreationSource="team-plan-quick-actions"
                    campaignItems={defaultQuickActionItems}
                    allowBrandSelector
                    loadedBrands={loadedBrands}
                    handleQuickActionClick={handleQuickActionClick}
                />
            </div>

            <Card
                className="organizationHeaderCardComponent"
                marginTop="0px"
                marginBottom="0px"
                padding="40px 24px"
                borderRadius="8px"
                width="100%"
            >
                <StyledLabel className="organizationHeaderTitle" fontSize={28}>
                    {currentOrganization?.name}
                </StyledLabel>
                <div className="organizationHeaderStats flex flex-row items-center mt-5">
                    <Body2 className="totalProfilesLabel mr-2">
                        Total Profiles
                    </Body2>
                    <p className="totalArtistsCount mr-1">
                        {teamArtists?.length}
                    </p>
                    <Body2 className="activeProAccounts ml-5 mr-2">
                        Active Pro Accounts
                    </Body2>
                    <p className="proArtistsCount">
                        {proArtists.length}
                    </p>
                    <ButtonSymphony
                        className="buttonContactSupportOrganizationReadyView"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => Intercom.showNewMessage()}
                        variant="text"
                        size="small"
                    >
                        Contact Support
                    </ButtonSymphony>

                </div>

                <StyledNavBar className="organizationNavHeaderTabs">
                    <Tabs
                        style={{ flex: '1' }}
                        scrollButtons="auto"
                        variant="scrollable"
                        value={materialTabValue}
                        onChange={(_event, newValue) => setMaterialTabValue(newValue)}
                        aria-label="Settings Options Selector"
                        TabIndicatorProps={{
                            style: { backgroundColor: Colors.purple },
                        }}
                    >
                        {tabs.map(({ name, value }) => (
                            <Tab
                                key={value}
                                disableRipple
                                value={value}
                                label={
                                    <span className="capitalize font-medium text-base px-4 py-2">
                                        {name}
                                    </span>
                                }
                            />
                        ))}
                    </Tabs>
                </StyledNavBar>
                <TabsPanelsContainer className="organizationTabsPanelsContainer">
                    <TabPanel hidden={!isEqual(materialTabValue, BRANDS)}>
                        <OrganizationProfiles
                            reloadOrganization={() => refreshStatus()}
                            organization={currentOrganization!}
                            existingBrands={loadedBrands}
                            reloadBrands={reloadBrands}
                        />
                        {/* {showConfetti &&
                            <MainConfetti
                                numberOfPieces={600}
                            />
                        }
                        <DialogPaper open={openCompletedModal}>
                            <ModalContainer>
                                <HeaderModal
                                    closeModal={() => setOpenCompletedModal(false)}
                                    title="Welcome to Symphony for Teams!"
                                    subtitle="We've got a lot of exciting new features on the way for the Team plan. If you have any ideas or feedback, reach out to us - we'd love to hear from you."
                                    HeaderIcon={<TadaIcon />}
                                />
                                <PrimaryButton
                                    className="letsGoPrimaryButton"
                                    onClick={() => {
                                        setOpenCompletedModal(false)
                                    }}
                                    text="Let's Go"
                                />
                            </ModalContainer>
                        </DialogPaper> */}
                    </TabPanel>
                    <TabPanel hidden={!isEqual(materialTabValue, BILLING)}>
                        <OrganizationBilling
                            teamMembers={teamMembers}
                            teamArtists={teamArtists}
                            organizationBrands={loadedBrands}
                            organization={currentOrganization!}
                        />
                    </TabPanel>
                    <TabPanel hidden={!isEqual(materialTabValue, TEAM)}>
                        <TeamTab />
                    </TabPanel>
                    <TabPanel hidden={!isEqual(materialTabValue, MARKETING_CAMPAIGNS)}>
                        <OrganizationMarketingTab organizationBrands={loadedBrands} />
                    </TabPanel>
                    <TabPanel hidden={!isEqual(materialTabValue, WEBSITES)}>
                        <OrganizationWebsitesTab organizationBrands={loadedBrands} />
                    </TabPanel>
                    <TabPanel hidden={!isEqual(materialTabValue, FANS)}>
                        <OrganizationFansTab />
                    </TabPanel>
                    <CreateCampaignModal
                        isOpen={campaignModalOpen}
                        onClose={() => setCampaignModalOpen(false)}
                        existingBrands={loadedBrands}
                    />
                </TabsPanelsContainer>
            </Card>
        </>
    )
}

const sty = {
    quickActionPanelContainer: {
        maxWidth: "1200px",
        margin: "0 auto"
    }
}