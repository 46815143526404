import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import { ActionsContainer } from "./styles"
import { useCancellation } from "Hooks/useCancellation"
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface Props {
  open: boolean;
  onClose: () => void;
}

/**
 * Modal component that displays coupon offer before subscription cancellation
 */
const CouponModal = ({ open, onClose }: Props) => {
  const {
    handleCouponRedemption,
    handleCouponModalCancel,
    isCouponProcessing,
    isCancelling
  } = useCancellation();

  const handleClose = () => !isCouponProcessing && onClose();

  return (
    <DialogPaper {...{ open }} onClose={handleClose}>
      <>
        <HeaderModal
          title="Before you cancel, maybe we can help"
          subtitle="Get 50% off your subscription for the next 2 months. We'd hate to lose you 😢"
          closeModal={handleClose}
        />
        <ActionsContainer>
          {!isCouponProcessing &&
            <div id="barecancel-trigger">
              <ButtonSymphony
                className="cancelSubscriptionButton"
                onClick={handleCouponModalCancel}
                variant="outlined"
                isLoading={isCancelling}
                disabled={isCouponProcessing || isCancelling}
              >
                Cancel Subscription
              </ButtonSymphony>
            </div>
          }
          <ButtonSymphony
            className="redeemCouponButton"
            isLoading={isCouponProcessing}
            width={240}
            disabled={isCouponProcessing || isCancelling}
            onClick={handleCouponRedemption}
          >
            Redeem 50% off for 2 months
          </ButtonSymphony>
        </ActionsContainer>
      </>
    </DialogPaper>
  )
}

export default CouponModal
