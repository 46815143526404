import { Theme, makeStyles } from '@material-ui/core';
import Colors from "modules/Colors";

export const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    height: '32px',
    width: '32px',
    '& path': {
      stroke: Colors.purple,
    },
  },
  input: {
    '& .MuiInputBase-root': {
      height: '45px',
    },
    '& input': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '& input::placeholder': {
      color: `${Colors.greyDark} !important`,
      opacity: '1 !important',
    },
    '& fieldset': {
      borderColor: `${Colors.PAPER_DIVIDER_CONTAINER} !important`,
    },
		'& .Mui-focused > fieldset': {
			borderColor: `${Colors.purple} !important`,
    },
    '& .Mui-error > fieldset': {
			borderColor: `${Colors.red} !important`,
    },
  },
  select: {
    height: '45px',
    '&.MuiSelect-select': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '&.Mui-focused > .MuiSelect-select:focus': {
      backgroundColor: `${Colors.PAPER_CONTAINER} !important`,
    },
    '& fieldset': {
			borderColor: `${Colors.PAPER_DIVIDER_CONTAINER} !important`,
    },
    '&.Mui-focused > fieldset': {
			borderColor: `${Colors.purple} !important`,
    },
  },
}));
