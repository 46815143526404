import { forwardRef, CSSProperties } from "react";
import styled from "styled-components";

export const Icon = styled.img`
  margin-left: auto;
  width: 12px;
`;

interface GemIconProps {
    className?: string
    style?: CSSProperties
    width?: number;
    height?: number;
    color?: 'gold' | 'purple' | 'white'
}

const GemIcon = forwardRef<SVGSVGElement, GemIconProps>(({
    className = '',
    style = {},
    width = 14,
    height = 14,
    color = 'gold',
}, ref) => {
    let strokeColor = '#F5BA23CC'
    switch (color) {
        case 'gold':
            strokeColor = '#F5BA23CC'
            break;
        case 'purple':
            strokeColor = '#9822ff'
            break;
        case 'white':
            strokeColor = 'white'
            break;
    }

    return (
        <svg style={style} ref={ref} viewBox="0 0 25 24" width={width} height={height} fill="none">
            <path stroke={strokeColor} strokeLinecap="round" strokeWidth="2" d="m3.956 11.507 8.172 9.08a.5.5 0 0 0 .744 0l8.172-9.08a1 1 0 0 0 .151-1.117l-2.419-4.837A1 1 0 0 0 17.882 5H7.118a1 1 0 0 0-.894.553l-2.42 4.837a1 1 0 0 0 .152 1.117Z" clipRule="evenodd" />
            <path stroke={strokeColor} strokeLinecap="round" strokeWidth="2" d="m9 8.5-.862 1.724a.5.5 0 0 0 .047.523L9.5 12.5" />
        </svg>
    );
});

export default GemIcon;