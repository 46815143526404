import { useContext } from 'react';
import { StyledInfoSection, StyledInfoSectionInfoContainer, StyledInfoSectionMainLabel, StyledInfoSectionSubTitleText, StyledInfoSectionAskToggle, StyledInfoSectionMainText, StyledInfoSectionTooltipContainer } from './styles';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import { Switch } from '@material-ui/core';
import useStyles from "pages/post-auth/MarketingPage/PreSave/styles";
import { SymphonyTooltip } from 'styles/shared';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import useScreen from 'Hooks/useScreen';
import SwitchSymphony from 'components/shareable/SwitchSymphony';

interface InfoSectionProps {
    mainText: string;
    descriptionText?: string;
    requiresPro?: boolean;
    requiresPaid?: boolean;
    boostEnabled: boolean;
    showBorderTop?: boolean;
    showBorderBottom?: boolean;
    tooltipText?: string;
    tooltipIcon?: string;
    onChange: (ev: any) => void;
    tooltipOnClick?: () => void;
}

const BoostOption = ({
    mainText,
    descriptionText,
    requiresPro = false,
    requiresPaid = false,
    boostEnabled,
    tooltipText,
    tooltipIcon,
    showBorderBottom,
    showBorderTop,
    onChange,
    tooltipOnClick,
}: InfoSectionProps) => {
    const classes = useStyles();
    const { isPaidBrand } = useContext(CurrentBrandContext);
    const { mobileView } = useScreen();

    return (
        <StyledInfoSection
            borderBottom={showBorderBottom ? "1px solid #EDECF2" : "none"}
            borderTop={showBorderTop ? "1px solid #EDECF2" : "none"}
            paddingTop={mobileView ? '0.7rem' : '1rem'}
            paddingBottom={mobileView ? '0.7rem' : '1rem'}
            paddingLeft={mobileView ? '1.25rem' : '0.5rem'}
            paddingRight={mobileView ? '1.25rem' : '0.5rem'}
        >
            <StyledInfoSectionInfoContainer>
                <StyledInfoSectionMainLabel>
                    <StyledInfoSectionTooltipContainer>
                        <StyledInfoSectionMainText>{mainText}</StyledInfoSectionMainText>
                        {tooltipText && tooltipIcon && tooltipOnClick &&
                            <button className="ml-1.5 w-6 h-6"
                                onClick={tooltipOnClick}>
                                <SymphonyTooltip className="block w-6 h-6 cursor-pointer" title={
                                    tooltipText
                                } arrow>
                                    <img
                                        className="w-6 h-6"
                                        alt="question-mark-icon.svg"
                                        src={tooltipIcon}
                                    />
                                </SymphonyTooltip>
                            </button>
                        }
                        {requiresPro &&
                            <div className="ml-1">
                                <UpgradeToProChip
                                    className="boostOptionProChip"
                                    type="gem"
                                    color="purple"
                                    height={20}
                                    width={20}
                                />
                            </div>
                        }
                    </StyledInfoSectionTooltipContainer>
                    <StyledInfoSectionSubTitleText>
                        {descriptionText}
                    </StyledInfoSectionSubTitleText>
                </StyledInfoSectionMainLabel>
                <StyledInfoSectionAskToggle>
                    <SwitchSymphony
                        checked={(requiresPro || requiresPaid) ? ((isPaidBrand) ? boostEnabled : false) : boostEnabled}
                        onChange={onChange}
                    />
                </StyledInfoSectionAskToggle>
            </StyledInfoSectionInfoContainer>
        </StyledInfoSection>
    );
};

export default BoostOption;
