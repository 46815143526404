import { CardContainer, IconContainer, ValueText, CardTitle, Card, Icon } from "./styles";
import millify from "millify";
import { CurrencyCodesType } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "modules/Const";
import useScreen from "Hooks/useScreen";

interface Props {
  icon: string
  title: string
  value: number
  isMoney: boolean
  currency?: CurrencyCodesType
}

const getFormattedMoney = (amount?: number, currency?: string) =>
  `${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${amount?.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ${currency?.toUpperCase() || "USD"}`

const MarketDetailCard = ({
  icon,
  title,
  value,
  isMoney,
  currency,
}: Props) => {
  const { mobileView } = useScreen();

  return (
    <CardContainer
      width={mobileView ? "calc(50% - 8px)" : "calc(25% - 8px)"}
    >
      <Card
        flexDirection={mobileView ? "row" : "column"}
        alignItems={mobileView ? "center" : "flex-start"}
      >
        <IconContainer>
          <Icon
            src={icon}
          />
        </IconContainer>
        <div>
          <CardTitle>
            {title}
          </CardTitle>
          <ValueText>
            {isMoney ?
              getFormattedMoney(value, currency)
              :
              millify(value)
            }
          </ValueText>
        </div>
      </Card>
    </CardContainer>
  );
}

export default MarketDetailCard