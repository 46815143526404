/* eslint-disable react-hooks/exhaustive-deps */
import {
  useContext,
  useState,
  CSSProperties,
} from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import isEqual from "lodash/isEqual";
import { Grid } from "@material-ui/core";
import UploadFileTab from "./Components/UploadFileTab";
import MatchFileTab from "./Components/MatchFileTab";
import FinalizeTab from "./Components/FinalizeTab";
import { UploadEmailsByCsvContext } from "../../Data/UploadEmailsByCsvProvider";
import CustomStepper from "components/shareable/CustomStepper/CustomStepper";
import TopBar from "components/shareable/TopBar/TopBar";
import { importFans } from "../../api";
import { NextButton } from "./styles";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import { ReactComponent as DesktopIcon } from "assets/images/computer.svg";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useHistory } from "react-router-dom";
import useScreen from "Hooks/useScreen";

export enum STEPS {
  UPLOAD_FILE = "upload_file",
  MATCH_FILE = "match_file",
  FINALIZE = "finalize",
}

const { UPLOAD_FILE, MATCH_FILE, FINALIZE } = STEPS;

const steps = [
  { description: "Upload file", name: UPLOAD_FILE },
  { description: "Match file", name: MATCH_FILE },
  { description: "Finalize", name: FINALIZE },
];

const tabs = steps.map(({ name }) => name);

const UploadEmailsByCsv = () => {
  const { currentBrand } = useContext(CurrentBrandContext);
  const { mobileView } = useScreen();
  const { match, mappedColumns } = useContext(UploadEmailsByCsvContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isConsentConfirmed, setIsConsentConfirmed] = useState(false);
  const { source } = match;

  const [selectedStep, setSelectedStep] = useTabs(tabs, UPLOAD_FILE as STEPS);

  const handleClickBackButton = () => {
    if (isEqual(selectedStep, FINALIZE)) {
      setSelectedStep(MATCH_FILE);
    } else if (isEqual(selectedStep, MATCH_FILE)) {
      setSelectedStep(UPLOAD_FILE);
    } else {
      history.push('/fans');
    }
  };

  const handleClickNextButton = async () => {
    if (isEqual(selectedStep, UPLOAD_FILE)) {
      setSelectedStep(MATCH_FILE);
    } else if (isEqual(selectedStep, MATCH_FILE)) {
      setSelectedStep(FINALIZE);
    } else {
      if (!currentBrand) {
        return;
      }
      try {
        const { error } = await importFans(currentBrand.id, {
          mappedHeaders: mappedColumns,
          source,
        });
        if (error) {
          setShowError(true);
        } else {
          history.push({
            pathname: '/fans',
            state: { importingFans: true }
          });
        }
      } catch (err) {
        setShowError(true);
        console.error('Error importing fans:', err);
      } finally {
        setLoading(false);
      }
    }
  };

  if (showError) {
    return (
      <div style={sty.errorContainer}>
        <div style={sty.errorTitle}>
          There was an error importing your fans.
        </div>
        <div style={sty.errorMessage}>
          An error occurred. Try again later.
        </div>
        <div style={sty.errorButton}>
          <NextButton onClick={() => history.push("/fans")}>Try Again</NextButton>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="uploadEmailsByCsvLoadingContainer" style={sty.loadingContainer}>
        <LoadingIndicator height="80px" color="black" />
      </div>
    );
  }

  return (
    currentBrand ?
      <Grid className="uploadEmailsByCsvComponent">
        <TopBar title="Import a new fanbase" showCloseIcon={true} handleClose={() => history.push('/fans')} />
        {mobileView ? (
          <div style={sty.mobileView.container}>
            <DesktopIcon style={sty.mobileView.icon} />
            <h2 style={sty.mobileView.title}>
              Switch to Desktop for Fanbase Import
            </h2>
            <p style={sty.mobileView.description}>
              Importing your fanbase requires advanced tools that are available on larger screens. Please use a desktop to complete this process.
            </p>
          </div>
        ) : (
          <>
            <CustomStepper
              className="uploadEmailsByCsvCustomStepper"
              activeStep={steps.map(({ name }) => name).indexOf(selectedStep as STEPS)}
              steps={steps}
              stepButtonsProps={{
                className: "uploadEmailsByCsvStepButtons",
                handleClickBackButton,
                handleClickNextButton,
                nextButtonLabel: isEqual(selectedStep, FINALIZE) ? `Import Fans` : 'Next',
                nextButtonWidth: isEqual(selectedStep, FINALIZE) ? 'fit-content' : undefined,
                disableNextButton: !isConsentConfirmed
              }}
            />
            <div style={sty.mainContent} className="uploadEmailsByCsvMainContentContainer">
              <Grid>
                <TabPanel hidden={selectedStep !== UPLOAD_FILE}>
                  <UploadFileTab
                    currentBrand={currentBrand}
                    setSelectedStep={(step) => setSelectedStep(step as STEPS)}
                    isConsentConfirmed={isConsentConfirmed}
                    setIsConsentConfirmed={setIsConsentConfirmed}
                  />
                </TabPanel>
                <TabPanel hidden={selectedStep !== MATCH_FILE}>
                  <MatchFileTab
                    currentBrand={currentBrand}
                    selectedStep={selectedStep as STEPS}
                    setSelectedStep={(step) => setSelectedStep(step as STEPS)}
                    setLoading={setLoading}
                  />
                </TabPanel>
                <TabPanel hidden={selectedStep !== FINALIZE}>
                  <FinalizeTab
                    currentBrand={currentBrand}
                    selectedStep={selectedStep as STEPS}
                    setSelectedStep={(step) => setSelectedStep(step as STEPS)}
                    setLoading={setLoading}
                    showError={showError}
                    setShowError={setShowError}
                  />
                </TabPanel>
              </Grid>
            </div>
          </>
        )}
      </Grid>
      : null
  );
};

const sty = {
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px auto'
  },
  errorTitle: {
    fontSize: '32px',
    fontWeight: 500
  },
  errorMessage: {
    color: '#707070',
    padding: '32px 0px 0px 0px'
  },
  errorButton: {
    maxWidth: '116px',
    padding: '32px 0px 0px 0px'
  },
  loadingContainer: {
    margin: '92px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center'
  },
  mobileView: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#fff'
    },
    icon: {
      padding: '12px',
      width: '64px',
      height: '64px',
      backgroundColor: '#EDECF2',
      filter: 'grayscale(100%) brightness(1.2) contrast(0.8)',
      borderRadius: '100px',
      marginBottom: '16px',
      marginTop: '16px'
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      margin: '0 0 10px'
    },
    description: {
      fontSize: '14px',
      color: '#6b6b6b',
      margin: 0
    }
  },
  mainContent: {
    margin: '0 auto',
    maxWidth: '88rem',
    height: '100%',
    width: '100%',
    backgroundColor: '#f5f3f6'
  }
} satisfies {
  // this was the only thing that worked that didn't require writing "CSSProperties" in a bunch of places
  [key: string]: CSSProperties | { [key: string]: CSSProperties }
};

export default UploadEmailsByCsv;
