import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) => ({
  audienceTargetingChips: {
    height: 28,
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(1),
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  chipLabelColor: {
    color: "#8800FF",
  },
  chipBackgroundColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  space: {
    padding: "16px 24px",
    [theme.breakpoints.up(1023)]: {
      padding: "24px 40px",
    },
  },
  border: {
    borderBottomWidth: 1,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  noPaddingX: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(1023)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  withPaddingBottom: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(1023)]: {
      paddingBottom: theme.spacing(3),
    },
  },
  geographicTargetingChips: {
    display: "flex",
    flexDirection: "row",
    color: "#8800FF",
    borderRadius: 32,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    gap: 2,
  },
  brandIconWidth: {
    width: 100
  },
  item: {
    paddingRight: theme.spacing(3.5),
    paddingLeft: theme.spacing(3.5)
  }
}));

interface IconProps {
  position?: string;
  right?: string;
  borderRadius?: string;
}

interface StyledSpanProps {
  color?: string;
  textDecorationLine?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  textAlign?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
  overflowWrap?: string;
}

interface CardProps {
  borderRadius?: string
}

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  background-color: #ffffff;
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 0px;
  };
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  text-align: ${({ textAlign }: StyledLabelProps) => textAlign};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
  overflow-wrap: ${({ overflowWrap }: StyledLabelProps) => overflowWrap};
`;

export const Icon = styled.img`
  border-radius: ${({ borderRadius }: IconProps) => borderRadius};
  position: ${({ position }: IconProps) => position};
  right: ${({ right }: IconProps) => right};
`;

export const StyledSpan = styled.span`
  color: ${({ color }: StyledSpanProps) => color};
  text-decoration-line: ${({ textDecorationLine }: StyledSpanProps) =>
    textDecorationLine};
`;

export default useStyles;
