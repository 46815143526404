import { MouseEventHandler, ReactElement, ReactNode } from "react"
import { ButtonContainer, ButtonTextContainer, useStyles } from "./styles"
import { ShowTooltip } from "../CustomMenu"
import { ButtonText } from "../Typography"
import LoadingLottie from "components/Loader/LoadingLottie"
import Colors from "modules/Colors"

interface Props {
  text: string
  disabled?: boolean
  width?: string
  height?: string
  tooltip?: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  textAlign?: string
  verticalAlign?: string
  fontSize?: string
  showInline?: boolean
  loading?: boolean
  weight?: string | number
  underlined?: boolean
  onClick?: MouseEventHandler<HTMLSpanElement>
}

const LinkButton = ({
  disabled,
  text,
  width = "fit-content",
  height = "fit-content",
  tooltip,
  rightIcon,
  leftIcon,
  textAlign = "center",
  verticalAlign = "center",
  fontSize,
  loading = false,
  showInline = false,
  weight,
  underlined = false,
  onClick,
}: Props) => {
  const classes = useStyles()
  const color = disabled ? Colors.grey : Colors.purple

  return (
    <ButtonContainer
      className={`linkButtonComponent ${disabled ? classes.disabled : ""}`}
      onClick={!disabled ? onClick : undefined}
      width={width}
      height={height}
      textAlign={textAlign}
    >
      <ShowTooltip tooltip={tooltip}>
        <ButtonTextContainer
          display={showInline ? "inline" : "flex"}
          alignItems={verticalAlign}
          fontSize={fontSize}
          cursor={disabled ? "not-allowed" : "pointer"}
        >
          {loading ?
            <LoadingLottie height={16} width={16} black />
            :
            <>
              {leftIcon}
              <ShowUnderlined underlined={underlined}>
                <ButtonText color={color} weight={weight}>{text}</ButtonText>
              </ShowUnderlined>
              {rightIcon}
            </>
          }
        </ButtonTextContainer>
      </ShowTooltip>
    </ButtonContainer>
  )
}

export const ShowUnderlined = (props: { underlined: Boolean, children: ReactNode }) => {
  const { underlined, children } = props
  if (underlined) {
    return (
      <u style={{ textDecorationColor: Colors.purple }}>
        {children}
      </u>
    )
  } else {
    return <>{children}</>
  }
}

export default LinkButton
