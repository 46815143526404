import { useMediaQuery, useTheme } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import LoaderV2 from 'components/Loader/LoaderV2';
import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { Container, OutlinedButton, StyledLabel } from "styles/shared";
import { CurrentBrand, OrganizationBillingData, OrganizationBrand, OrganizationData, TeamMember } from "types/global";
import { goToUrl } from "modules/Utils";
import { getNumberWithDecimals } from "helpers/General";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { getConvertedCurrency } from "services/symphonyApi";
import { Body2 } from "components/shareable/Typography";
import { getOrganizationBillingData, getSubscriptionStatusUrl } from "services/symphonyApi/organizationService";
import { toast } from "react-toastify";
import { SHARED_TOAST_OPTIONS } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { DEFAULT_CURRENCY } from "modules/Const";
import TeamTabSection from "../TeamTabSection";
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface Props {
    organization: OrganizationData;
    organizationBrands: CurrentBrand[];
    teamMembers: TeamMember[];
    teamArtists: OrganizationBrand[];
}

const OrganizationBilling = ({
    organization,
    organizationBrands,
    teamMembers,
    teamArtists
}: Props) => {
    const theme = useTheme();
    const { spoofedUserId, isSymphonyAdmin: isAdmin } = useContext(CurrentUserContext)

    const setMdWidth = useMediaQuery(theme.breakpoints.down(645));
    const setLgWidth = useMediaQuery(theme.breakpoints.between(645, 920));
    const setUpperPartAsColumn = useMediaQuery(theme.breakpoints.down(524));

    // loading status
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentOrganizationBillingData, setCurrentOrganizationBillingData] = useState<OrganizationBillingData | null>(null);
    const proArtists = organizationBrands.filter(brand => brand.tier === 'pro')
    const { currentBrand } = useContext(CurrentBrandContext)

    const [baseSubscriptionRecurringInterval, setBaseSubscriptionRecurringInterval] = useState<string | null>(null)

    const baseSubscriptionFee = currentOrganizationBillingData?.baseSubscriptionPrice

    // ad spend limits
    const [adSpendLimit, setAdSpendLimit] = useState(currentOrganizationBillingData?.usage.adSpend.limit || 0)
    const [adSpendFee, setAdSpendFee] = useState(currentOrganizationBillingData?.usage.adSpend.additionalCost || 0)
    const [adSpendUsage, setAdSpendUsage] = useState(currentOrganizationBillingData?.usage.adSpend.usage || 0)

    // pro accounts usage
    const [proAccountsUsage, setProAccountsUsage] = useState(currentOrganizationBillingData?.usage.proAccounts.usage || 0)
    const [proAccountsLimit, setProAccountsLimit] = useState(currentOrganizationBillingData?.usage.proAccounts.limit || 0)
    const [proAccountsFee, setProAccountsFee] = useState(currentOrganizationBillingData?.usage.proAccounts.additionalCost || 0)
    // seats used
    const [seatsUsed, setSeatsUsed] = useState(currentOrganizationBillingData?.usage.seats.usage || 0)
    const [seatsLimit, setSeatsLimit] = useState(currentOrganizationBillingData?.usage.seats.limit || 0)
    const [seatsFee, setSeatsFee] = useState(currentOrganizationBillingData?.usage.seats.additionalCost || 0)
    //TODO: get currency code from brand team plan configuration and set it instead once it is available
    const currencyCode = DEFAULT_CURRENCY
    const currencySymbol = getSymbolFromCurrency(currencyCode)

    useEffect(() => {
        // update billing data when 
        const fetchOrganizationBillingData = async () => {
            try {
                setIsLoading(true);
                const billingData = await getOrganizationBillingData({ spoofedUserId: spoofedUserId || undefined });
                if (billingData) {
                    setCurrentOrganizationBillingData(billingData);
                } else {
                    toast.error("There was an error fetching your billing data. Please try again.", SHARED_TOAST_OPTIONS)
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                Sentry.captureException(error);
                console.error("fetchOrganizationBillingData: ", error);
            }
        };
        fetchOrganizationBillingData();
    }, [currentBrand, teamMembers, teamArtists]);

    useEffect(() => {
        if (currentOrganizationBillingData) {
            // Helper function to convert and format currency
            const convertAndSetCurrency = async (amount: number, setter: (value: number) => void) => {
                if (currencyCode !== DEFAULT_CURRENCY) {
                    const converted = await getConvertedCurrency({
                        from: DEFAULT_CURRENCY,
                        to: currencyCode,
                        amount
                    });
                    setter(Number(getNumberWithDecimals(converted, 2)));
                } else {
                    setter(Number(getNumberWithDecimals(amount, 2)));
                }
            };

            // set base subscription recurring interval
            setBaseSubscriptionRecurringInterval(currentOrganizationBillingData.recurringInterval)
            // Set usage metrics
            setAdSpendUsage(currentOrganizationBillingData.usage.adSpend.usage);
            setProAccountsUsage(currentOrganizationBillingData.usage.proAccounts.usage);
            setSeatsUsed(currentOrganizationBillingData.usage.seats.usage);

            // Set limits
            setProAccountsLimit(currentOrganizationBillingData.usage.proAccounts.limit);
            setSeatsLimit(currentOrganizationBillingData.usage.seats.limit);

            // Convert and set all currency-based values
            convertAndSetCurrency(currentOrganizationBillingData.usage.adSpend.limit, setAdSpendLimit);
            convertAndSetCurrency(currentOrganizationBillingData.usage.adSpend.additionalCost, setAdSpendFee);
            convertAndSetCurrency(currentOrganizationBillingData.usage.proAccounts.additionalCost, setProAccountsFee);
            convertAndSetCurrency(currentOrganizationBillingData.usage.seats.additionalCost, setSeatsFee);
        }
    }, [currentOrganizationBillingData, currencyCode, proArtists.length]);

    // helper functions
    function dataRow(array: {
        title: string;
        text: string;
        label?: string;
        onEdit?: () => void
    }[]) {
        return (
            <Container
                display="flex"
                flexDirection={setUpperPartAsColumn ? "column" : "row"}
                padding="24px 0px"
                borderBottom="1px solid #E5E7EB"
                alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
            >
                {array.map((item, index) => (
                    <Container
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width={setLgWidth ? "32%" : setMdWidth ? "100%" : "25%"}
                    >
                        <StyledLabel fontSize={16} fontWeight={400}>
                            {item.title}
                        </StyledLabel>
                        <Container display="flex" flexDirection="row" gap={"2px"} alignItems="end">
                            <StyledLabel fontSize={24} fontWeight={500}>
                                {item.text}
                            </StyledLabel>
                            {item?.label && (
                                <Body2 className="capitalize mb-2 ml-1">{item.label}</Body2>
                            )}
                        </Container>
                    </Container>
                ))}
            </Container>
        )
    }

    // Determine the billing status title and date based on subscription state
    let billingStatusTitle: string;
    let billingStatusDate: string;

    // Handle trial period
    if (currentOrganizationBillingData?.status === 'trialing') {
        billingStatusTitle = "Trial Ends";
        billingStatusDate = dayjs(currentOrganizationBillingData.trialEndDate).format("MMM DD, YYYY");
    }
    // Handle cancelled subscriptions
    else if (currentOrganizationBillingData?.scheduledCancellationDate) {
        billingStatusTitle = "Plan Cancels";
        billingStatusDate = dayjs(currentOrganizationBillingData.scheduledCancellationDate).format("MMM DD, YYYY");
    }
    // Handle active subscriptions
    else {
        billingStatusTitle = baseSubscriptionRecurringInterval === 'annual'
            ? "Next Annual Payment"
            : "Next Monthly Payment";
        billingStatusDate = currentOrganizationBillingData?.nextBillingDate
            ? dayjs(currentOrganizationBillingData.nextBillingDate).format("MMM DD, YYYY")
            : 'N/A';
    }

    // High level subscription details
    const basePlanRowData = [
        {
            title: "Plan",
            text: `Team Plan`,
            label: `[${baseSubscriptionRecurringInterval === 'annual' ? "Annual" : "Monthly"}]`,
        },
        {
            title: billingStatusTitle,
            text: billingStatusDate
        },
        {
            title: "Base Subscription",
            text: `${currencySymbol}${baseSubscriptionFee} ${currencyCode}`,
            label: `/${baseSubscriptionRecurringInterval}`,
        }
    ];

    // Pro accounts usage and limits
    const proAccountsRowData = [
        {
            title: "Pro Accounts Used",
            text: `${proAccountsUsage} / ${proAccountsLimit}`
        },
        {
            title: "Additional Pro Accounts",
            text: `${Math.max(0, proAccountsUsage - proAccountsLimit)}`
        },
        {
            title: "Additional Pro Account Fees",
            text: `${currencySymbol}${proAccountsFee}`,
            label: `/ Month`
        }
    ];

    // User seats usage and limits
    const userSeatsRowData = [
        {
            title: "Seats Used",
            text: `${seatsUsed} / ${seatsLimit}`
        },
        {
            title: "Additional Seats",
            text: seatsUsed > seatsLimit ? `${seatsUsed - seatsLimit}` : '0'
        },
        {
            title: "Additional Seat Fees",
            text: `${currencySymbol}${seatsFee}`,
            label: `/ Month`
        }
    ];

    // Ad spend usage and limits
    const spendRowData = [
        {
            title: "Monthly Ad Spend Limit",
            text: adSpendLimit ? `${currencySymbol}${adSpendLimit}` : 'N/A'
        },
        {
            title: "Current Month Spend",
            text: `${currencySymbol}${adSpendUsage}`
        },
        {
            title: "Additional Spend Fees",
            text: `${currencySymbol}${adSpendFee}`,
            label: " this month"
        }
    ];


    let totalCost = proAccountsFee + seatsFee + adSpendFee;

    // if the user is on a monthly plan, add the base subscription fee to the total cost
    if (baseSubscriptionRecurringInterval === 'monthly') {
        totalCost += currentOrganizationBillingData?.baseSubscriptionPrice || 0
    }

    // Ad spend usage and limits
    const totalCostRow = [
        {
            title: "Total Monthly Cost",
            text: `${currencySymbol}${totalCost}`
        }
    ];
    const redirectToSubscriptionStatus = async () => {
        try {
            setIsLoading(true);
            const url = await getSubscriptionStatusUrl();
            goToUrl(url, setIsLoading);
        } catch (error) {
            setIsLoading(false);
            Sentry.captureException(error);
            console.error("redirectToSubscriptionStatus: ", error);
        }
    };

    const redirectToStripeSubscription = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewSubscriptionUrl) {
            window.open(currentOrganizationBillingData.viewSubscriptionUrl, "_blank");
        }
    }
    const redirectToStripeCustomer = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewCustomerUrl) {
            window.open(currentOrganizationBillingData.viewCustomerUrl, "_blank");
        }
    }

    let loadingComponent = () => {
        return <Container
            margin="24px 0px"
            display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="8px">
            <LoaderV2 />
            <Body2>Loading Billing Details....</Body2>
        </Container>
    }

    let loadedData = (
        <>
            {isAdmin && (
                <Container
                    gap={setUpperPartAsColumn ? "8px" : "32px"}
                    display="flex"
                    flexDirection="row"
                    padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
                >
                    <Container flexDirection="row">
                        <ButtonSymphony
                          className="organizationPageAdminViewStripeSubscriptionButton"
                          onClick={redirectToStripeSubscription}
                          variant="outlined"
                        >
                          Admin: View Stripe Subscription
                        </ButtonSymphony>
                    </Container>
                    <Container flexDirection="row">
                        <ButtonSymphony
                          className="organizationPageAdminViewStripeCustomerButton"
                          onClick={redirectToStripeCustomer}
                          variant="outlined"
                        >
                          Admin: View Stripe Customer
                        </ButtonSymphony>
                    </Container>
                </Container>
            )}

            {dataRow(basePlanRowData)}
            {dataRow(proAccountsRowData)}
            {dataRow(userSeatsRowData)}
            {dataRow(spendRowData)}
            {dataRow(totalCostRow)}
        </>
    )
    return (
        <TeamTabSection
            title="Billing"
            buttonText="View Billing Settings"
            onButtonClick={redirectToSubscriptionStatus}
            showDividers
            buttonLoading={isLoading}
        >
            <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
                <Container display="flex" flexDirection="column"
                    alignItems="flex-start" gap="8px">
                    <StyledLabel fontSize={18}>{organization.name}</StyledLabel>
                    <StyledLabel fontSize={12} color="#E08507">TEAM ACCOUNT</StyledLabel>
                </Container>
            </Container>
            <Container>
                {isLoading ? loadingComponent() : loadedData}
            </Container>
            {/* <ChartBilling
                currentOrganizationBillingData={currentOrganizationBillingData}
                monthlySpent={monthlyAdSpendLimit}
            /> */}
        </TeamTabSection>
    );
};

export default OrganizationBilling