import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';
import Colors from "modules/Colors";

export const useStyles = makeStyles((theme: Theme) => ({
  svgPrimary: {
    '& path': {
      stroke: Colors.purple,
      fill: Colors.purple,
    }
  },
  svgError: {
    '& path': {
      stroke: Colors.red,
    }
  },
  svgInline: {
    display: "inline",
  }
}));

interface ColumnFlexContainerProps {
  gap?: string;
}

interface RowFlexContainerProps {
  gap?: string;
}

interface ConversionsContainerProps {
  padding?: string;
  borderRadius?: string;
}

interface ConversionsDataContainerProps {
  flexDirection?: string;
}

export const ConversionsContainer = styled.div<ConversionsContainerProps>`
  display: flex;
  padding: ${({ padding }) => padding || '16px 40px'};
  gap: 8px;
  background: ${Colors.PAPER_CONTAINER};
  border-radius: ${({ borderRadius }) => borderRadius};
  ${MEDIA_BREAKPOINTS.mobileView} {
    border-radius: 0px;
  }
`;

export const ConversionsIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: ${Colors.PAPER_ACCENT_CONTAINER};
  border-radius: 8px;
  height: fit-content;
`;

export const ConversionsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ColumnFlexContainer = styled.div<ColumnFlexContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '4px'};
  width: 100%;
`;

export const RowFlexContainer = styled.div<RowFlexContainerProps>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap || '4px'};
`;

export const ConversionsDataContainer = styled.div<ConversionsDataContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: 8px;
  ${MEDIA_BREAKPOINTS.mobileView} {
    flex-direction: column;
  }
  ${MEDIA_BREAKPOINTS.largeView} {
    flex-direction: row;
  }
`;

export const ConversionsDetailsContainer = styled.div`
  display: flex;
  padding: 8px;
  gap: 24px;
  background: ${Colors.divider};
  border-radius: 8px;
`;

export const ErrorBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  padding: 12px 24px;
  background: ${Colors.PAPER_ERROR_CONTAINER};
  max-height: 48px;
  max-width: 194px;
  text-wrap: nowrap;
  gap: 10px;
`;