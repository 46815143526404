import { TextField } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import { Theme, makeStyles } from "@material-ui/core/styles";
import { Headline3 } from "components/shareable/Typography";
import Colors from "modules/Colors";
import { TextButton } from "styles/shared";
import ButtonSymphony from "components/shareable/ButtonSymphony";

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        '& .MuiInputBase-root': {
            height: '45px',
            width: '182px',
        },
        '& input': {
            fontFamily: 'DIN',
            fontSize: '16px',
        },
        '& input::placeholder': {
            color: `${Colors.greyDark} !important`,
            opacity: '1 !important',
        },
        '& fieldset': {
            borderColor: `${Colors.PAPER_DIVIDER_CONTAINER} !important`,
        },
        '& .Mui-focused > fieldset': {
            borderColor: `${Colors.purple} !important`,
        },
        '& .Mui-error > fieldset': {
            borderColor: `${Colors.red} !important`,
        },
    }
}));

interface PageHeaderWithSearchProps {
    title: string;
    onSearch?: (value: string) => void;
    buttonText?: string;
    onButtonClick?: () => void;
    searchValue?: string;
    loading?: boolean;
    secondaryButtonText?: string;
    onSecondaryButtonClick?: () => void;
}

const PageHeaderWithSearch = ({
    title,
    onSearch,
    buttonText,
    onButtonClick,
    searchValue = "",
    loading = false,
    secondaryButtonText,
    onSecondaryButtonClick
}: PageHeaderWithSearchProps) => {
    const classes = useStyles();

    return (
        <div className='pageHeaderWithSearchComponent flex gap-2 items-center justify-between'>
            <Headline3 color={Colors.textColor}>{title}</Headline3>
            <div className='flex gap-4 items-center'>
                {onSearch && (<TextField
                    value={searchValue}
                    type="search"
                    variant="outlined"
                    className={classes.input}
                    placeholder="Search"
                    onChange={(event) => onSearch(event.target.value)}
                    InputProps={{ startAdornment: (<SearchIcon height={24} width={24} className="mr-3" />) }}
                />)}
                {buttonText && onButtonClick && (
                    <ButtonSymphony
                        className="buttonPageHeaderWithSearch"
                        isLoading={loading}
                        disabled={loading}
                        onClick={onButtonClick}
                    >
                        {buttonText}
                    </ButtonSymphony>
                )}
                {secondaryButtonText && onSecondaryButtonClick && (
                    <TextButton onClick={onSecondaryButtonClick}>{secondaryButtonText}</TextButton>
                )}
            </div>
        </div>
    );
}

export default PageHeaderWithSearch