/* eslint-disable jsx-a11y/alt-text */
/**
 * TargetRow Component
 */

import _ from "lodash"
import { ChangeEvent, MouseEventHandler, useState, Fragment, useEffect } from "react"
import { FBLocation, GeographicTargetingType, Location } from "../reducer"
import Autocomplete from './Autocomplete'
import { Tooltip } from "@material-ui/core";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import TrashIcon from "assets/images/trash.svg";

interface DeleteIconProps {
    color?: string;
}

const DeleteIcon = ({ color }: DeleteIconProps) => (
    <svg
        className="mt-0.5"
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.75 2.25L2.25 6.75"
            stroke={color || "white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.25 2.25L6.75 6.75"
            stroke={color || "white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

type ButtonProps = {
    label: string
    color: string
    textAlign?: string
    onClick?: MouseEventHandler<HTMLDivElement>
}

type NameCellPropsDesktopView = {
    name: string;
    tooltipLabel?: string;
}

type NameCellPropsResponsiveView = {
    name: string;
    tooltipLabel?: string;
    showTrashIcon?: boolean;
    isResponsive?: boolean;
    handleOnClickRemove: () => void
    showTooltip: boolean;
}

type Props = {
    isBelowRecommendedBudget: boolean;
    id: number
    currentName: string
    searchText: string
    currentBudget: number
    isEditing: boolean
    loading: boolean
    searchResults: FBLocation[]
    onClickEdit?: (row: number) => void
    onClickRemove?: (row: number) => void
    currentLocations: { [key: string]: Location }
    onChangeSearchText?: (text: string) => void
    onClickSave?: (row: number, target: GeographicTargetingType) => void
    editMode: boolean
    tooltipLabel?: string
    showTrashIcon?: boolean
    isResponsive?: boolean;
    deleteIconColor?: string;
}

export const LabelButton = ({
    label,
    onClick,
    color,
    textAlign,
}: ButtonProps) => (
    <div
        {...{ onClick }}
        className={`transition ease-in-out delay-150 text-xs font-bold cursor-pointer ${textAlign}`}
        style={{ color }}
    >
        {label}
    </div>
);

const NameCellDesktopView = ({ name, tooltipLabel }: NameCellPropsDesktopView) => (
    <Fragment>
        <div className="text-left">
            {name}
        </div>
        {tooltipLabel && (
            <div className="cursor-pointer">
                <Tooltip className="w-6 h-6" title={tooltipLabel} arrow>
                    <img
                        className="w-6 h-6"
                        alt="question-mark-icon.svg"
                        src={QuestionMarkIcon}
                    />
                </Tooltip>
            </div>
        )}
    </Fragment>
)

const NameCellResponsiveView = ({ name, showTooltip, tooltipLabel, showTrashIcon, isResponsive, handleOnClickRemove }: NameCellPropsResponsiveView) => (
    <Fragment>
        {showTooltip && tooltipLabel && (
            <div className="cursor-pointer mt-2">
                <Tooltip className="w-6 h-6" title={tooltipLabel} arrow>
                    <img
                        className="w-6 h-6"
                        alt="question-mark-icon.svg"
                        src={QuestionMarkIcon}
                    />
                </Tooltip>
            </div>
        )}
        <div className="text-left break-all">
            {name}
        </div>
        {showTrashIcon && isResponsive && <img alt="trash-icon.svg" src={TrashIcon} onClick={handleOnClickRemove} />}
    </Fragment>
)

const TargetRow = ({
    id,
    currentName = '',
    currentBudget = 0,
    currentLocations,
    isEditing,
    onClickEdit = () => void 0,
    onClickSave = () => void 0,
    onClickRemove = () => void 0,
    onChangeSearchText = () => void 0,
    searchResults,
    loading,
    editMode = true,
    searchText,
    tooltipLabel,
    showTrashIcon,
    isResponsive,
    deleteIconColor,
    isBelowRecommendedBudget
}: Props) => {
    // edit data
    const [name, setName] = useState(currentName)
    const [budget, setBudget] = useState(currentBudget * 100)
    const [locations, setLocations] = useState<{ [key: string]: Location }>(currentLocations)
    // Variables
    const currentLocationsArray = Object.values(currentLocations)
    const locationsArray = Object.values(locations)
    const locationsToShow = locationsArray.slice(0, 5)
    const moreLocationsCount = locationsArray.length > 5 ? locationsArray.length - 5 : 0
    const tagNodes = isEditing ? locationsArray : locationsToShow
    const percentage = `${Math.round(currentBudget * 100)}%`

    // Handlers
    const handleOnClickEdit = () => onClickEdit(id)
    const handleOnClickCancel = () => {
        if (currentLocationsArray.length) {
            setName(currentName)
            setBudget(currentBudget * 100)
            setLocations(currentLocations)
            onClickEdit(-1)
        } else {
            onClickEdit(-1)
            onClickRemove(id)
        }
    }
    const handleOnClickRemove = () => onClickRemove(id)
    const handleOnChangeName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)
    const handleOnClickSave = () => {
        const values = Object.values(locations)
        if (values.length) onClickSave(id, { name, budget: budget / 100, locations })
    }
    const handleOnChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => onChangeSearchText(event.target.value)

    const handleOnRemoveLocation = (index: number) => () => {
        if (isEditing) {
            const updatedLocations = Object.assign({}, locations)
            const toRemove = locationsArray[index]
            _.unset(updatedLocations, toRemove.value)
            setLocations(updatedLocations)
        }
    }

    const handleOnAddLocation = (selectedLocation: FBLocation) => {
        const { key, name, type, region_id, country_code } = selectedLocation
        const updatedLocations = Object.assign({}, locations)
        const location = _.get(locations, key)
        if (!!location) {
            _.unset(updatedLocations, key)
            setLocations(updatedLocations)
        } else {
            _.set(updatedLocations, key, { value: key, label: name, type, region_id, country_code })
            setLocations(updatedLocations)
        }
    }

    const handleOnChangeBudget = ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (target.value.match(/^\d/)) {
            const value = parseInt(target.value)
            if (value < 0 || value > 100) return
            setBudget(value)
        }
    }

    // Nodes
    const tags = <div className="flex flex-wrap gap-1 px-2">
        {tagNodes.map(({ label }, index) => (
            <div
                key={index}
                onClick={handleOnRemoveLocation(index)}
                className="flex flex-row w-fit px-1.5 py-0.5 gap-0.5 items-center"
                style={{ borderRadius: 32, color: "#8800FF", backgroundColor: "rgba(136, 0, 255, 0.08)", cursor: isEditing ? "pointer" : "default" }}
            >
                {label}
                {isEditing && <DeleteIcon color={deleteIconColor} />}
            </div>
        ))}
        {!!moreLocationsCount && !isEditing && <div className="rounded-sm px-1.5 py-0.5" style={{ fontSize: 10 }} >{`+ ${moreLocationsCount} more`}</div>}
    </div>

    useEffect(() => {
        setBudget(currentBudget * 100)
    }, [currentBudget])


    function renderActionButtonsOnRow() {
     
        if (!!editMode) {

            if (isBelowRecommendedBudget) {
                return (
                    <td className="border border-backgound-100 px-2 py-4">
                        <div className={`px-4 w-full flex flex-row justify-${"center"}`}>
                            <LabelButton label="EDIT" color="#8800FF" onClick={handleOnClickEdit} />
                        </div>
                    </td>
                )
            } else {
                return (
                    <td className="border border-backgound-100 px-2 py-4">
                        <div className={`px-0.5 w-full flex flex-row justify-${isResponsive ? "center" : "between"}`}>
                            <LabelButton label="EDIT" color="#8800FF" onClick={handleOnClickEdit} />
                            {!isResponsive &&
                                <LabelButton label="REMOVE" color="#CD0000" onClick={handleOnClickRemove} />}
                        </div>
                    </td>
                )
            }
        }
        return null
    }


    function renderBudgetPercentSelector() {
        if (isBelowRecommendedBudget) return null
        return (
            <div className="border-b border-backgound-100 pb-3 mb-3 w-full flex flex-row justify-between">
                <div className="font-bold">% of Budget</div>
                <div>
                    <input className="text-right" type="number" value={Math.round(budget)} onChange={handleOnChangeBudget} />
                    <span>%</span>
                </div>
            </div>
        )
    }

    if (isEditing) {
        return (
            <>
                <tr className="collapsable border-2 border-black">
                    <td className={`${!isResponsive ? "border border-backgound-100" : ""} px-2 py-4 w-full`} colSpan={3}>
                        {`Editing: ${currentName}`}
                    </td>
                    {!isResponsive && <td className="border border-backgound-100 px-2 py-4 w-full" colSpan={1}>
                        <div className="px-0.5 w-full flex flex-row justify-between">
                            <LabelButton label="SAVE" color="#8800FF" onClick={handleOnClickSave} />
                            <LabelButton label="CANCEL" color="#CD0000" onClick={handleOnClickCancel} />
                        </div>
                    </td>}
                </tr>
                {isResponsive && <tr className="collapsable border-2 border-black">
                    <td colSpan={4}>
                        <div className="px-4 w-full flex flex-row justify-between">
                            <div className="w-1/2 border-r-2 border-black py-4">
                                <LabelButton label="SAVE" color="#8800FF" onClick={handleOnClickSave} textAlign="text-center" />
                            </div>
                            <div className="w-1/2 py-4">
                                <LabelButton label="CANCEL" color="#CD0000" onClick={handleOnClickCancel} textAlign="text-center" />
                            </div>
                        </div>
                    </td>
                </tr>}
                <tr className="border-2 border-black">
                    <td className="border border-backgound-100 px-2 py-4 w-full px-4" colSpan={4}>
                        <div className="border-b border-backgound-100 pb-3 mb-3 w-full flex flex-row justify-between">
                            <div className="font-bold">Name</div>
                            <input className="text-right" value={name} onChange={handleOnChangeName} />
                        </div>
                        {renderBudgetPercentSelector()}
                        <div className="font-bold mb-2">Targeted Locations</div>
                        <div className="rounded border-2 border-rose-500 pt-2">
                            {tags}
                            <Autocomplete
                                locations={searchResults}
                                {...{ loading, searchText }}
                                selectedLocations={locations}
                                onChange={handleOnChangeSearchText}
                                onSelectLocation={handleOnAddLocation}
                            />
                        </div>
                    </td>
                </tr>
            </>
        )
    }


    return (<tr className="select-none">
        <td className="text-sm border border-backgound-100 px-2 py-4 font-bold">
            <div
                className={`flex flex-${isResponsive ? "col" : "row"} gap-1 items-${isResponsive ? "start" : "center"
                    }`}
            >
                {!isResponsive && <NameCellDesktopView {...{
                    name,
                    tooltipLabel
                }} />}
                {isResponsive && (
                    <NameCellResponsiveView
                        {...{
                            showTooltip: false,
                            name,
                            tooltipLabel,
                            showTrashIcon: showTrashIcon && !isBelowRecommendedBudget,
                            isResponsive,
                            handleOnClickRemove
                        }}
                    />
                )}
            </div>
        </td>
        <td className=" border border-backgound-100 px-2 py-4">
            {tags}
        </td>
        <td className="text-sm text-center border border-backgound-100 px-2 py-4">{percentage}</td>
        {renderActionButtonsOnRow()}
    </tr>
    )
}

export default TargetRow
