import { useContext, useEffect, useState } from "react";
import { Body2 } from "components/shareable/Typography";
import { toast } from "react-toastify"
import { SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils"
import { OrganizationViewDefaultGrid } from "../../Components/OrganizationViewDefaultGrid";
import { Fan } from "types/global";
import dayjs from "dayjs";
import { getFormattedSource } from "pages/post-auth/Fans/Components/CustomTable/utils";
import { GridColDef } from "@mui/x-data-grid";
import TooltipText from "pages/post-auth/Fans/Components/CustomTable/TooltipText";
import ContactInfoCell from "pages/post-auth/Fans/Components/CustomTable/EmailCell";
import CustomTooltip from "components/shareable/Tooltip";
import PlatformCell from "pages/post-auth/Fans/Components/CustomTable/PlatformCell";
import { track } from "analytics";
import TeamTabSection from "../../TeamTabSection";
import TableEmptyState from "./TableEmptyState";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { getOrganizationFans } from "services/symphonyApi/organizationService";

interface OrganizationFan extends Fan {
  brandName: string;
  brandImage: string;
  spotify_user_id?: string;
  apple_music_user_id?: string;
  deezer_user_id?: string;
  soundcloud_user_id?: string;
  audiomack_user_id?: string;
}

const OrganizationFansTab = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fans, setFans] = useState<OrganizationFan[]>([]);
  const [totalFans, setTotalFans] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const { spoofedUserId } = useContext(CurrentUserContext);
  const [sortModel, setSortModel] = useState<{ field: string; sort: 'asc' | 'desc' }>({
    field: 'added',
    sort: 'desc'
  });
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const PAGE_SIZE = 25;

  const fetchFans = async (pageNumber: number, sortField?: string, sortDir?: 'ASC' | 'DESC') => {
    setIsLoading(true);
    try {
      const response = await getOrganizationFans(
        pageNumber * PAGE_SIZE,  // offset
        PAGE_SIZE,              // limit
        (sortField || 'added'), // orderBy
        sortDir || 'DESC',      // direction
        undefined,              // targeting (optional)
        spoofedUserId ? { spoofedUserId } : undefined // spoof config
      );

      if (response?.data) {
        // Transform the fans data to match our OrganizationFan interface
        const transformedFans: OrganizationFan[] = response.data.fans.map((fan: any) => ({
          ...fan,
          brandName: fan.brandName || '',
          brandImage: fan.brandImage || '',
          id: fan.id,
          email: fan.email || '',
          name: fan.name || '',
          lastname: fan.lastname || '',
          city: fan.city || '',
          country: fan.country || '',
          origin: fan.origin || '',
          createdAt: fan.createdAt || '',
          presaveFutureReleases: fan.presaveFutureReleases || false,
          platform: determinePlatform(fan),
          cellphone: fan.cellphone,
          profile_url: fan.profile_url,
          isNew: fan.isNew,
          newFollower: fan.newFollower,
          presavedSongs: fan.presavedSongs || []
        }));

        setFans(transformedFans);
        setTotalFans(response.data.count);
      }
    } catch (error) {
      toast.error("Error fetching fans", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to determine primary platform
  const determinePlatform = (fan: OrganizationFan): string => {
    if (fan.spotify_user_id) return 'spotify';
    if (fan.apple_music_user_id) return 'apple';
    if (fan.deezer_user_id) return 'deezer';
    if (fan.soundcloud_user_id) return 'soundcloud';
    if (fan.audiomack_user_id) return 'audiomack';
    return '';
  };

  useEffect(() => {
    fetchFans(page, sortModel.field, sortModel.sort.toUpperCase() as 'ASC' | 'DESC');
  }, [page, sortModel]);

  const handleSortModelChange = (newModel: any) => {
    if (newModel.length > 0) {
      setSortModel({
        field: newModel[0].field,
        sort: newModel[0].sort
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "brand",
      headerName: "Brand",
      flex: 2,
      renderCell: (params) => (
        <div className="flex items-center gap-3">
          <img
            src={params.row.brandImage}
            alt={params.row.brandName}
            className="w-10 h-10 rounded-full object-cover"
          />
          <Body2>{params.row.brandName}</Body2>
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Fan",
      flex: 2,
      renderCell: (params) => (
        <ContactInfoCell
          cellphone={params.row.cellphone}
          email={params.row.email}
          name={params.row.name}
          lastname={params.row.lastname}
          imageUrl={params.row.profile_url}
          isNew={params.row.isNew}
          newFollower={params.row.newFollower}
          newFollowerPlatformName={params.row.platform}
        />
      ),
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      renderCell: (params) => {
        const formattedSource = getFormattedSource(
          params.row.origin,
          params.row.presaveFutureReleases
        );
        return (
          <CustomTooltip
            title={<TooltipText
              songsList={params.row.presavedSongs}
              formattedSource={formattedSource.text}
            />}
          >
            <span>{formattedSource.element || formattedSource.text}</span>
          </CustomTooltip>
        );
      },
    },
    {
      field: "added",
      headerName: "Added",
      flex: 1,
      renderCell: (params) => (
        <span>{dayjs(params.row.createdAt).format("MMM DD, YYYY")}</span>
      ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 1,
      renderCell: (params) => (
        <PlatformCell platformKey={params.row.platform} />
      ),
    },
  ];

  const handleDownloadCsv = async () => {
    track("Download Organization Fans CSV");
    setIsDownloading(true);

    try {
      // const downloadUrl = await getDownloadSignedUrl(undefined); // Add a parameter to the API call to indicate organization context
      // if (!downloadUrl.error) {
      //   window.location.assign(downloadUrl.data);
      // } else {
      //   Intercom.showNewMessage("I'd like to download my Organization Fans CSV. Can you help me with that?")
      // }
    } catch (error) {
      console.error('Error downloading CSV:', error);
      // Intercom.showNewMessage("I'd like to download my Organization Fans CSV. Can you help me with that?")
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <TeamTabSection
      title="Fans"
      // buttonText="Download CSV"
      // onButtonClick={handleDownloadCsv}
      showDividers
      buttonLoading={isDownloading}
    >
      <div className="w-full">
        <OrganizationViewDefaultGrid
          className="organizationFansGrid"
          rows={fans}
          columns={columns}
          hideFooter={false}
          disableColumnMenu
          disableColumnSelector
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          itemsPerPage={PAGE_SIZE}
          rowCount={totalFans}
          isLoadingData={isLoading}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          itemName="Fans"
          emptyStateComponent={
            <TableEmptyState
              className="organizationFansTableEmptyState"
              style={{ marginTop: 10 }}
              primaryText="All of the emails and phone numbers collected across your profiles will appear here for easy access."
            />
          }
        />
      </div>
    </TeamTabSection>
  );
};

export default OrganizationFansTab;