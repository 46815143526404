import React, {
    createContext,
    useContext,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useTabs } from "react-headless-tabs";
import { CurrentBrand, InstagramProfileForOnboarding, SpotifyArtistSearchResponse, TikTokProfileForOnboarding } from "types/global";
import { MarketingSelectorOption } from "../pages/post-auth/AddArtist/Components/NewOnboarding/MarketingSelectorVertical";
import { setAnalyticsState, setUserPropertiesOnce, track } from "analytics";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { CreateBrandPayload, createNewBrand } from "services/symphonyApi/brandService";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import * as Sentry from "@sentry/react";
import { MarketingGoalType } from "modules/Const";

export enum OnboardingAccountTypes {
    CREATOR = 'creator',
    TEAM = 'team',
}

// This is the shape of the value we will provide via context:
type OnboardingContextProps = {
    // step-based logic
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
    loadingContinueButton: boolean;
    setLoadingContinueButton: (loading: boolean) => void;
    showGatheringFanbaseLoader: boolean;
    setShowGatheringFanbaseLoader: (loading: boolean) => void;
    handleGoNext: (accountType?: OnboardingAccountTypes) => Promise<void>;
    handleGoBack: () => void;

    // connections
    selectedSpotifyArtist: SpotifyArtistSearchResponse | null;
    setSelectedSpotifyArtist: (artist: SpotifyArtistSearchResponse | null) => void;
    selectedTikTokProfile: TikTokProfileForOnboarding | null;
    setSelectedTikTokProfile: (profile: TikTokProfileForOnboarding | null) => void;
    selectedInstagramProfile: InstagramProfileForOnboarding | null;
    setSelectedInstagramProfile: (profile: InstagramProfileForOnboarding | null) => void;
    signupWithoutProfileName: string | null;
    setSignupWithoutProfileName: (name: string | null) => void;
    // your states, e.g.
    isFirstTimeOnboarding: boolean;

    // example “answers” states
    currentAccountType: OnboardingAccountTypes | null;
    setCurrentAccountType: React.Dispatch<React.SetStateAction<OnboardingAccountTypes | null>>;
    marketingExperience: MarketingSelectorOption | null;
    setMarketingExperience: React.Dispatch<React.SetStateAction<MarketingSelectorOption | null>>;
    teamsTotalCreators: { value: number; label: string } | null;
    setTeamsTotalCreators: React.Dispatch<React.SetStateAction<{ value: number; label: string } | null>>;
    marketingBudget: { value: number; label: string } | null;
    setMarketingBudget: React.Dispatch<React.SetStateAction<{ value: number; label: string } | null>>;
    marketingGoals: MarketingGoalType[] | null;
    setMarketingGoals: React.Dispatch<React.SetStateAction<MarketingGoalType[] | null>>; goToDashboard: () => void;
    showBackButton: () => boolean;
    showBackButtonText: () => string;

    trackOnboardingEvent: (eventName: string, eventData?: Record<string, any>) => void;
};



export const ONBOARDING_VERSION = 'welcome-to-symphony-jan-15-add-paywall-to-signup'
// createContext with a default value (often empty or partial):
const OnboardingContext = createContext<OnboardingContextProps>(
    {} as OnboardingContextProps
);

export const OnboardingProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {

    const { currentUser, setCurrentUser, loadedBrands, getBrands } = useContext(CurrentUserContext)
    const { setCurrentBrand } = useContext(CurrentBrandContext)
    /**
     * FIRST_TIME_ONBOARDING is true if the user has not completed the onboarding flow or shared onboarding answers yet
     */
    let SHOW_ONBOARDING_QUESTIONS = !Boolean(currentUser?.metadata?.onboarding_answers?.account_type)

    // If you are using the same “tabs” logic:
    const firstTimeTabs = [
        "account_type",
        "teams-total-creators",
        "basic",
        "marketing-goals",
        "marketing-experience",
        "marketing-budget",
        "welcome",
    ];
    const returningTabs = ["basic", "welcome", "profile"];

    const tabs = SHOW_ONBOARDING_QUESTIONS ? firstTimeTabs : returningTabs;
    const defaultTab = tabs[0];
    const [selectedTab, setSelectedTab] = useTabs(tabs, defaultTab);

    const history = useHistory();

    // Onboarding states:
    const [currentAccountType, setCurrentAccountType] = useState<OnboardingAccountTypes | null>(null);
    const [marketingExperience, setMarketingExperience] = useState<MarketingSelectorOption | null>(null)
    const [teamsTotalCreators, setTeamsTotalCreators] = useState<{
        value: number;
        label: string;
    } | null>(null)
    const [marketingBudget, setMarketingBudget] = useState<{
        value: number;
        label: string;
    } | null>(null)

    const [marketingGoals, setMarketingGoals] = useState<MarketingGoalType[] | null>(null);

    // Possibly the user has an “artist” as well:
    const [selectedSpotifyArtist, setSelectedSpotifyArtist] = useState<SpotifyArtistSearchResponse | null>(null);
    const [selectedTikTokProfile, setSelectedTikTokProfile] = useState<TikTokProfileForOnboarding | null>(null);
    const [selectedInstagramProfile, setSelectedInstagramProfile] = useState<InstagramProfileForOnboarding | null>(null);
    const [signupWithoutProfileName, setSignupWithoutProfileName] = useState<string | null>(null);
    const [loadingContinueButton, setLoadingContinueButton] = useState(false);
    const [showGatheringFanbaseLoader, setShowGatheringFanbaseLoader] = useState(false);


    /**
     * Resets all profile selection states to their initial null values
     * Used when switching between different onboarding flows or when needing to clear selections
     */
    const resetProfileSelectionState = () => {
        setSelectedSpotifyArtist(null);
        setSelectedTikTokProfile(null);
        setSelectedInstagramProfile(null);
        setSignupWithoutProfileName(null);
    };
    const showBackButton = () => {
        switch (selectedTab) {
            case 'account_type': {
                if (loadedBrands.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
            case 'teams-total-creators': {
                return true;
            }
            case 'basic': {
                if (loadedBrands.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
            case 'marketing-goals': return true;
            case 'marketing-experience': return true;
            case 'marketing-budget': return true;
            case 'welcome': return true;
            default: return false;
        }
    }

    const showBackButtonText = () => {
        switch (selectedTab) {
            case 'account_type':
                if (loadedBrands.length > 0) {
                    return 'Back to dashboard';
                } else {
                    return ''
                }
            default:
                return '';
        }
    }

    // Implementation of your “handleGoNext()” logic
    const userOriginalOnboardingAnswers = currentUser?.metadata?.onboarding_answers
    const onboardingAnswers: Record<string, any> = {
        ...userOriginalOnboardingAnswers,
        account_type: currentAccountType ?? undefined,
        marketing_experience: marketingExperience?.title ?? undefined,
        marketing_budget: marketingBudget?.value ?? undefined,
        marketing_budget_identifier: marketingBudget?.label ?? undefined,
        marketing_goals: marketingGoals && marketingGoals?.length > 0 ? marketingGoals.map((goal) => goal.type) : null,
        teams_total_creators: teamsTotalCreators?.value || undefined,
        teams_total_creators_identifier: teamsTotalCreators?.label || undefined,
    }

    const trackStepCompleted = (stepIndex: number, stepName: string) => {
        trackOnboardingEvent(`NEW ONBOARDING: STEP ${stepIndex} - COMPLETED`, {
            step: stepName,
            accountType: currentAccountType,
        })
    }

    const trackOnboardingEvent = (eventName: string, eventData?: Record<string, any>) => {
        track(eventName, {
            ...(eventData || {}),
            version: ONBOARDING_VERSION
        })
    }
    const handleGoNext = async (accountType?: OnboardingAccountTypes) => {

        if (SHOW_ONBOARDING_QUESTIONS) {
            switch (selectedTab) {
                case 'account_type': {

                    trackStepCompleted(1, "creator or team")
                    setSelectedTab("basic");
                    break
                }
                case 'basic': {
                    trackStepCompleted(2, "select a profile")
                    setSelectedTab("marketing-goals"); break;
                }

                case 'marketing-goals': {
                    trackStepCompleted(3, "marketing goals")
                    setSelectedTab("marketing-experience");
                    break;
                }

                case 'marketing-experience': {
                    trackStepCompleted(4, "marketing experience")
                    setSelectedTab("marketing-budget");
                    break;
                }

                case 'marketing-budget': {
                    trackStepCompleted(5, "marketing budget")
                    await createBrand()
                    trackOnboardingEvent("NEW ONBOARDING: Flow Finished")
                    setSelectedTab("welcome");
                    break;
                }
            }
        } else {
            switch (selectedTab) {
                case 'basic': {
                    await createBrand()
                    setSelectedTab("welcome");
                    break;
                }
            }
        }
    }

    const goToDashboard = () => {
        trackOnboardingEvent("Finalized Artist Creation Flow - Going to Dashboard", {
            newOnboarding: SHOW_ONBOARDING_QUESTIONS,
            ...onboardingAnswers,
        })
        history.push('dashboard');
    }

    const handleGoBack = () => {
        if (SHOW_ONBOARDING_QUESTIONS) {
            switch (selectedTab) {
                case 'account_type': goToDashboard(); break;
                case 'teams-total-creators': setSelectedTab('account_type'); break;
                case 'basic': {
                    switch (currentAccountType) {
                        case OnboardingAccountTypes.TEAM: setSelectedTab('teams-total-creators'); break;
                        default: setSelectedTab('account_type'); break;
                    }
                    break;
                }
                case 'marketing-goals': setSelectedTab('basic'); break;
                case 'marketing-experience': setSelectedTab('marketing-goals'); break;
                case 'marketing-budget': setSelectedTab('marketing-experience'); break;
                case 'profile': setSelectedTab("marketing-budget"); break;
                case 'welcome':
                    setSelectedTab("marketing-budget");
                    break;
            }
        } else {
            switch (selectedTab) {
                case 'basic': goToDashboard(); break;
                case 'profile': setSelectedTab("welcome"); break;
                case 'welcome':
                    setSelectedTab("basic");
                    // clearPlatformsLocalStorage({ except: ["none"] })
                    break;
            }
        }
    }


    /**
     * Handles all analytics tracking after brand creation
     */
    const _handleCreatedBrandAnalytics = async ({
        brandId,
        name,
        slug,
        payload,
        onboardingAnswers
    }: {
        brandId: number;
        name: string;
        slug: string;
        payload: CreateBrandPayload;
        onboardingAnswers: Record<string, any>;
    }): Promise<void> => {
        const additionalContextProps = {
            ...onboardingAnswers,
            rewardful_affiliate_link: currentUser?.metadata?.rewardful_affiliate_link
        };

        setAnalyticsState({
            id: brandId,
            name,
            slug,
            additionalProps: additionalContextProps
        });

        // Track onboarding properties
        Object.entries(onboardingAnswers).forEach(([key, value]) => {
            setUserPropertiesOnce({
                [`onboarding_${key}`]: value
            });
        });

        // Track connected platforms

        const trackingData = {
            newOnboarding: SHOW_ONBOARDING_QUESTIONS,
            ...onboardingAnswers
        };

        // Track various completion events
        trackOnboardingEvent("NEW ONBOARDING: CREATED BRAND", {
            brandId,
            accountType: currentAccountType
        })

        // Old Analytics
        trackOnboardingEvent("Created Artist", trackingData);
        trackOnboardingEvent("Finished Artist Creation", trackingData);
        trackOnboardingEvent("CompleteRegistration", trackingData);
    };


    const _updateBrandAndUserState = (brand: CurrentBrand) => {
        setCurrentBrand(brand);


        if (currentUser) {
            const updatedMetadata = {
                ...currentUser.metadata,
                onboarding_answers: onboardingAnswers,
                affiliate_referrer: currentUser?.metadata?.affiliate_referrer || ''
            };
            setCurrentUser({
                ...currentUser,
                metadata: updatedMetadata,
                hasBrands: true
            });
        }
    }


    // Implementation of your “createBrand()”
    const createBrand = async () => {
        // 1. move user to "creating brand" state
        // 2. build the payload to create the brand
        // 3. post to create brand service
        // 4. set the analytics state locally 
        // 5. once brand is made, get the returned value and set it as current brand
        // 6. set user to welcome page
        try {
            trackOnboardingEvent("NEW ONBOARDING: CREATING BRAND", {
                ...onboardingAnswers,
            })
            setShowGatheringFanbaseLoader(true)
            // Build the brand creation payload

            let brandName = selectedSpotifyArtist?.name || selectedTikTokProfile?.username || selectedInstagramProfile?.username || signupWithoutProfileName || null
            let customization: CreateBrandPayload['customization'] = {
                onboarding_answers: onboardingAnswers
            }


            if (selectedSpotifyArtist) {
                customization.spotify_artist = selectedSpotifyArtist;
            }

            if (selectedTikTokProfile) {
                customization.tiktok = selectedTikTokProfile;
            }

            if (selectedInstagramProfile) {
                customization.instagram_profile_for_onboarding = selectedInstagramProfile;
            }

            const payload: CreateBrandPayload = {
                name: brandName,
                customization: customization
            };

            // Create the brand
            const createdBrand = await createNewBrand(payload);
            if (!createdBrand) {
                throw new Error("Failed to create brand");
            }

            const { id, name, slug } = createdBrand;

            // Handle analytics
            _handleCreatedBrandAnalytics({
                brandId: id,
                name,
                slug,
                payload,
                onboardingAnswers
            });

            // Update brand and user state
            await getBrands();
            _updateBrandAndUserState(createdBrand);
        } catch (e) {
            Sentry.captureException(e);
            console.log("error", e)
        } finally {
            resetProfileSelectionState()
            setShowGatheringFanbaseLoader(false);
        }
    };

    // Finally, we pass all these states and functions as the context value
    const value: OnboardingContextProps = {
        selectedTab: selectedTab || '',
        setSelectedTab,
        handleGoNext,
        handleGoBack,
        isFirstTimeOnboarding: SHOW_ONBOARDING_QUESTIONS,
        selectedInstagramProfile,
        setSelectedInstagramProfile,
        currentAccountType,
        setCurrentAccountType,
        marketingBudget,
        setMarketingBudget,
        marketingExperience,
        setMarketingExperience,
        teamsTotalCreators,
        setTeamsTotalCreators,
        marketingGoals,
        setMarketingGoals,
        goToDashboard,
        showBackButton,
        showBackButtonText,
        loadingContinueButton,
        setLoadingContinueButton,
        showGatheringFanbaseLoader,
        setShowGatheringFanbaseLoader,
        signupWithoutProfileName,
        setSignupWithoutProfileName,

        selectedSpotifyArtist,
        setSelectedSpotifyArtist,
        selectedTikTokProfile,
        setSelectedTikTokProfile,

        trackOnboardingEvent
    };

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
};

// A helper hook for easy usage
export const useOnboarding = () => {
    return useContext(OnboardingContext);
};
