import Colors from 'modules/Colors';
import styled from 'styled-components';

interface Props {
  color?: string
  cursor?: string
  weight?: string | number;
  textAlign?: string;
  lineHeight?: string;
}

export const Body1 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 18px;
  font-weight: ${({ weight }: Props) => weight || '400'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.greyDark};
  text-align: ${({ textAlign }: Props) => textAlign || 'inherit'};
`;

export const Body2 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 16px;
  font-weight: ${({ weight }: Props) => weight || '400'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.greyDark};
`;

export const Body3 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.greyDark};
`;

export const Headline1 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 32px;
  font-weight: ${({ weight }: Props) => weight || '500'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.purple};

  @media (max-width: 700px) {
    font-size: 24px;
  }
`;

export const Headline1Accent = styled(Headline1)`
  background: ${Colors.GRADIENT};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
`

export const Headline2 = styled.h2<Props>`
  font-family: 'DIN';
  font-size: 24px;
  font-weight: ${({ weight }: Props) => weight || '500'};
  font-style: normal;
  color: ${({ color }) => color || Colors.purple};
`;

export const Headline3 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 20px;
  font-weight: ${({ weight }: Props) => weight || '400'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.purple};
`;

export const Caption = styled.span<Props>`
  font-family: 'DIN';
  font-size: 12px;
  font-weight: ${({ weight }: Props) => weight || '400'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.purple};
`;

export const Caption1 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 14px;
  font-weight: ${({ weight }: Props) => weight || '400'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.greyDark};
`;

export const Subtitle1 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 18px;
  font-weight: ${({ weight }: Props) => weight || '500'};
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.textColor};
`;

export const Subtitle2 = styled.span<Props>`
  font-family: 'DIN';
  font-size: 16px;
  font-weight: ${({ weight }: Props) => weight || '500'};
  line-height: normal;
  font-style: normal;
  color: ${({ color }: Props) => color || Colors.textColor};
  line-height: ${({ lineHeight }: Props) => lineHeight || 1.8};

`;

export const ButtonText = styled.span<Props>`
  font-family: 'DIN';
  font-size: 16px;
  font-weight: ${({ weight }: Props) => weight || '500'};
  font-style: normal;
  color: ${({ color }) => color || Colors.purple};
  transform: translateY(2px);
  cursor: ${({ cursor }: Props) => cursor ? 'pointer' : undefined};
`;
