import { Dialog } from "@material-ui/core"
import { ModalContainer } from "pages/post-auth/AddArtist/style"
import { useState } from "react"
import { LocationDetail } from "../MarketsDetail"
import useStyles, { BodyContainer, ButtonContainer, ButtonLabel } from "./styles"
import { ReactComponent as Pause } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as Play } from "assets/images/campaignDetails/play.svg"
import LoadingLottie from 'components/Loader/LoadingLottie'
import HeaderModal from '../HeaderModal'
import { track } from "analytics"

interface Props {
  location: LocationDetail
  open: boolean
  trackObject?: Record<string, any>
  closeModal: () => void
  onPauseLocation: (location: LocationDetail) => Promise<void>
}

const LocationConfirmationModal = ({
  location,
  open,
  trackObject,
  closeModal,
  onPauseLocation,
}: Props) => {
  const classes = useStyles()
  const isLive = location.status === "live"

  const [loading, setLoading] = useState<boolean>(false)

  const handleOnClickButton = async () => {
    setLoading(true)
    await onPauseLocation(location)
    track('Saved Edit Locations', {
      ...trackObject,
      opened: 'Edit Locations Modal',
    })
    setLoading(false)
    closeModal()
  }

  return (
    <Dialog
      open={open}
      className={classes.dialog}
    >
      <ModalContainer className={classes.modalContainer}>
        {
          isLive ? (
            <HeaderModal
              closeModal={closeModal}
              title={`Pause location`}
              subtitle={`Are you sure you want to pause your ads in ${location.name}?`}
              HeaderIcon={Pause}
            />
          ) : (
            <HeaderModal
              closeModal={closeModal}
              title={`Resume location`}
              subtitle={`Are you sure you want to resume your ads in ${location.name}?`}
              HeaderIcon={Play}
            />
          )
        }
        <BodyContainer>
          <ButtonContainer
            onClick={handleOnClickButton}
          >
            {loading ? (
              <LoadingLottie width={20} height={20} />
            ) : (
              <ButtonLabel>Yes, {isLive ? "pause" : "resume"} this location</ButtonLabel>
            )}
          </ButtonContainer>
        </BodyContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default LocationConfirmationModal