import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    mainContainer: {
        borderRadius: theme.spacing(1.5),
        backgroundColor: "#FDF6EC",
        marginBottom: 16,
    },
    textContainer: {
        padding: theme.spacing(2),
    },
    headerLabel: {
        color: "#754600",
    },
    content:{
        color: "black",
    },
    linkLabel: {
        fontWeight: 500,
        marginRight: theme.spacing(8),
        marginTop: theme.spacing(2),
        color: "#8800FF"
    },
}));

export default useStyles