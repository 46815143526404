import { ReactElement, ReactNode } from "react"
import { ButtonContainer } from "./styles"
import LoadingLottie from "components/Loader/LoadingLottie"
import { ButtonText } from "../Typography"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import Colors from "modules/Colors"

const getColor = (data: {
  disabled?: boolean
  error?: boolean
  customColor?: string
}) => {
  const { customColor, error, disabled } = data

  if (customColor) return customColor
  if (disabled) return Colors.grey
  if (error) return Colors.red
  return Colors.purple
}

interface Props {
  className?: string,
  text?: string
  content?: ReactNode
  loadingText?: string
  disabled?: boolean
  loading?: boolean
  error?: boolean
  width?: string
  height?: string
  gap?: string
  leftComponent?: ReactElement | null
  rightComponent?: ReactElement | null
  customColor?: string
  minWidth?: string
  padding?: string | undefined;
  style?: CSSProperties,
  onClick?: () => void
}

const SecondaryButton = ({
  className = '',
  disabled,
  loading,
  loadingText = "",
  error,
  padding,
  text,
  content,
  width = "fit-content",
  height = "fit-content",
  customColor,
  gap,
  leftComponent,
  rightComponent,
  minWidth,
  style = {},
  onClick,
}: Props) => {
  const color = getColor({
    customColor,
    error,
    disabled,
  })

  return (
    <ButtonContainer
      className={`secondaryButtonComponent ${className}`}
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
      gap={gap}
      minWidth={minWidth}
      color={color}
      padding={padding}
      style={style}
    >
      {loading ? (
        <>
          <LoadingLottie height={18} width={18} disabled={loading || disabled} black />
          <ButtonText color={color}>{loadingText}</ButtonText>
        </>
      ) : text ? (
        <>
          {leftComponent && leftComponent}
          <ButtonText color={color}>{text}</ButtonText>
          {rightComponent && rightComponent}
        </>
      ) : (
        content
      )}
    </ButtonContainer>
  )
}

export default SecondaryButton
