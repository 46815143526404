import momentTz from "moment-timezone"
import moment from "moment"
import { getFormattedPlatform } from "../../utils";
import TertiaryButton from 'components/shareable/TertiaryButton';
import { Modals } from '../../Details/PreSaveDetails';
import { Chip, ChipContainer, PropertyDataLabel, PropertyLabelTitle, RowContainer, AddUrlContainer } from './styles';
import ArticleTitle, { Article } from 'components/shareable/ArticleTitle';
import useScreen from 'Hooks/useScreen';
import { Body2 } from 'components/shareable/Typography';
import LinkButton from 'components/shareable/LinkButton';
import Intercom from 'helpers/Intercom';
import { Container } from 'styles/shared';

const BOOST_NAME_MAPPING: Record<string, string> = {
  auto_presaves: "Forever Saves",
  collect_emails: "Collect Fan Data",
  boost_follows: "Boost Followers",
};

export const formatDate = (date: string, timezone: string) => {
    const abbrev = momentTz.tz(timezone).zoneAbbr()
    if (timezone) return momentTz(date).tz(timezone).format(`dddd MMM D, YYYY h:mma [${abbrev}]`)
    return moment(date).format(`dddd MMM D, YYYY ha [${abbrev}]`)
}

const FormatUpcIsrc = (props: { upcIsrc: string, mobileView: boolean }) => {
    const { upcIsrc, mobileView } = props
    if (upcIsrc) {
        return <PropertyDataLabel textAlign={mobileView ? "left" : "right"}>{upcIsrc}</PropertyDataLabel>
    }
    return <AddUrlContainer textAlign={mobileView ? "left" : "right"} alignItems={mobileView ? "flex-start" : "flex-end"}>
        <Body2>Add your release’s UPC or ISRC before release.</Body2>
        <LinkButton
            text='Need help finding this info?'
            onClick={() => Intercom.openFindPresaveUPCArticle()}
        />
    </AddUrlContainer>
}

const FormatRowContainer = (props: { title: JSX.Element, Value: JSX.Element, Button: JSX.Element, mobile: boolean }) => {
    const { title, Value, Button, mobile } = props
    return (
        <RowContainer
            flexDirection={mobile ? 'column' : 'row'}
            justifyContent={mobile ? 'flex-start' : 'space-between'}
            padding={mobile ? '16px' : '20px'}
            gap={mobile ? '8px' : '16px'}
        >
            <Container
                flexDirection='row'
                alignItems='center'
                width={mobile ? '100%' : 'fit-content'}
                gap='8px'
                justifyContent={mobile ? 'space-between' : 'flex-start'}
            >
                {title}
                {mobile && Button}
            </Container>
            <Container
                flexDirection='row'
                alignItems='center'
                justifyContent='flex-start'
                width={mobile ? '100%' : 'fit-content'}
                gap='8px'
            >
                {Value}
                {!mobile && Button}
            </Container>
        </RowContainer>
    )
}

interface SetupProps {
    releaseDate: string,
    timezone: string,
    boosts: {
        auto_presaves: boolean;
        boost_follows: boolean;
        collect_emails: boolean;
    } | undefined,
    platforms: string[] | undefined,
    urlUpcIsrc: string,
    isFinalized: boolean,
    handleOpenModals: (modal: Modals, source?: string) => void
}

export default function PresaveSetup(props: SetupProps) {
    const {
        releaseDate,
        timezone,
        boosts,
        platforms,
        urlUpcIsrc,
        handleOpenModals,
        isFinalized,
    } = props
    const formattedDate = formatDate(releaseDate, timezone);
    const { mobileView } = useScreen();
    const tooltipText = isFinalized ? "You can't edit once your campaign is finished." : ""

    return (
        <div>
            <FormatRowContainer
                title={<PropertyLabelTitle>Release Date</PropertyLabelTitle>}
                Value={
                    <PropertyDataLabel textAlign={mobileView ? "left" : "right"}>
                        {formattedDate}
                    </PropertyDataLabel>}
                Button={
                    <TertiaryButton
                        onClick={() => handleOpenModals(Modals.EDIT_END_DATE, "setup")}
                        text='Edit'
                        disabled={isFinalized}
                        tooltip={tooltipText}
                    />
                }
                mobile={mobileView}
            />
            <FormatRowContainer
                title={<PropertyLabelTitle>UPC / ISRC</PropertyLabelTitle>}
                Value={<FormatUpcIsrc upcIsrc={urlUpcIsrc} mobileView={mobileView} />}
                Button={
                    <TertiaryButton
                        onClick={() => handleOpenModals(Modals.EDIT_UPC_ISRC, "setup")}
                        text='Edit'
                        disabled={isFinalized}
                        tooltip={tooltipText}
                    />
                }
                mobile={mobileView}
            />
            <FormatRowContainer
                title={<ArticleTitle
                    children={<PropertyLabelTitle>Boosts</PropertyLabelTitle>}
                    article={Article.ForeverSaves}
                    toolTipText='Click to learn more about how Forever Saves work.'
                />}
                Value={
                    <ChipContainer justifyContent={mobileView ? 'flex-start' : 'flex-end'}>
                        {boosts && Object.keys(boosts)
                            .filter((key) => {
                                const boostActive = (boosts as any)[key]
                                return boostActive ? true : false
                            })
                            .map((key) => {
                                const boostName = key

                                return (
                                    <Chip>
                                        {BOOST_NAME_MAPPING[boostName]}
                                    </Chip>
                                )
                            })
                        }
                    </ChipContainer>
                }
                Button={
                    <TertiaryButton
                        onClick={() => handleOpenModals(Modals.EDIT_BOOSTS, "setup")}
                        text='Edit'
                        disabled={isFinalized}
                        tooltip={tooltipText}
                    />
                }
                mobile={mobileView}
            />
            <FormatRowContainer
                title={<PropertyLabelTitle>Platforms</PropertyLabelTitle>}
                Value={
                    <ChipContainer justifyContent={mobileView ? 'flex-start' : 'flex-end'}>
                        {platforms?.map((platform) => {
                            return (
                                <Chip>
                                    {getFormattedPlatform(platform)}
                                </Chip>
                            )
                        })}
                    </ChipContainer>
                }
                Button={
                    <TertiaryButton
                        onClick={() => handleOpenModals(Modals.EDIT_PLATFORMS, "setup")}
                        text='Edit'
                        disabled={isFinalized}
                        tooltip={tooltipText}
                    />
                }
                mobile={mobileView}
            />
        </div>
    )
}