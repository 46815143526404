import dayjs from "dayjs";

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeFirstLetterOfEveryWord = (text: string) => {
    const words = text.split(" ");
    for (let i = 0;i < words.length;i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    return words.join(" ");
}

const roundDays = (days: number) => {
    const floatingRemainder = days % 1;

    if (floatingRemainder > 0.2) {
        return Math.ceil(days);
    } else {
        return Math.floor(days);
    }
}

export const daysCalculator = (startDate: string, endDate: string) => {
    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);

    // To calculate the time difference of two dates
    const Difference_In_Days = date2.diff(date1, "day", true);
    return roundDays(Difference_In_Days);

    // const floatingRemainder = Difference_In_Days % 1;
    // if (floatingRemainder > 0.2) {
    //     return Math.ceil(Difference_In_Days);
    // } else {
    //     return Math.floor(Difference_In_Days);
    // }
}

export const daysLeftCalculator = (startDate: string, endDate: string) => {
    const daysLeft = daysCalculator(startDate, endDate);

    return roundDays(daysLeft);

    // const floatingRemainder = daysLeft % 1;
    // if (floatingRemainder > 0.2) {
    //     return Math.ceil(daysLeft);
    // } else {
    //     return Math.floor(daysLeft);
    // }
}

export const checkImageUrlValidity = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (error) => reject({ error, url, img });
        img.src = url;
    });
}

export const getNumberWithDecimals = (number: number | string, decimals: number): string => {
    if (typeof number === "string") number = Number(number);
    return (Math.round(number * 100) / 100).toFixed(decimals)
}