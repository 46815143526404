import {
  ArtistFollowers,
  ArtistFollowersContainer,
  ArtistFollowersPlatform,
  ArtistImage,
  ArtistInfo,
  ArtistItem as StyledArtistItem,
  ArtistName,
  ArtistNameLabel,
  ConnectArtistLabel,
} from "../../../style"
import { ReactComponent as SpotifyFollowersIcon } from "assets/images/streaming/icons/spotify-i.svg"
import millify from "millify"
import { SpotifyArtistSearchResponse } from "types/global"
import Lottie from 'react-lottie'
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading.json'
import Grid from "@material-ui/core/Grid"
import NotFoundArtistImage from "assets/images/not-found-user.png"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import { clsx } from 'modules/Utils'

/**
 * Gets the image URL for an artist
 * @param artist - The artist object to get the image URL from
 * @returns The image URL if found, undefined otherwise
 */
const getImageUrl = (artist?: SpotifyArtistSearchResponse | null) => {
  if (!artist) return undefined
  return artist.thumbnail_url || undefined
}

/**
 * Gets the formatted follower count for an artist
 * @param artist - The artist object to get follower count from
 * @returns Formatted follower count string
 */
const getFollowers = (artist?: SpotifyArtistSearchResponse | null) => {
  if (!artist || !artist.followers) return 0
  return millify(artist.followers)
}

/**
 * Props interface for ArtistItem component
 */
interface Props {
  className?: string
  /** Whether the item is in a loading state */
  loading?: boolean
  /** Whether this artist is currently selected */
  isSelected?: boolean
  /** The artist data to display */
  artist: SpotifyArtistSearchResponse
  /** Label text for the connect button */
  buttonLabel: string
  /** Callback function when artist is selected */
  selectArtist: (artist: SpotifyArtistSearchResponse) => void
}

/**
 * ArtistItem component displays an individual artist with their image, name, 
 * follower count and connect button
 * @param props - Component props
 * @returns ArtistItem component
 */
const ArtistItem = ({
  className,
  loading,
  isSelected,
  artist,
  buttonLabel,
  selectArtist,
}: Props) => {
  const imageUrl = getImageUrl(artist)
  const followers = getFollowers(artist)

  /**
   * Handles click event on the artist item
   */
  const handleClickItem = () => {
    if (!loading) {
      selectArtist(artist)
    }
  }

  return (
    <StyledArtistItem
      className={clsx('artistItemComponent', className)}
      onClick={handleClickItem}
    >
      <ArtistInfo>
        <ArtistImage src={imageUrl || NotFoundArtistImage} />
        <ArtistName>
          <ArtistNameLabel>{artist?.name}</ArtistNameLabel>
          <ArtistFollowersContainer>
            <ArtistFollowersPlatform>
              <SpotifyFollowersIcon width={16} height={16} />
            </ArtistFollowersPlatform>
            <ArtistFollowers>
              {followers}{" "}
              followers
            </ArtistFollowers>
          </ArtistFollowersContainer>
        </ArtistName>
      </ArtistInfo>
      {!loading &&
        <ButtonSymphony className="artistItemButton" variant="text">
          {buttonLabel}
        </ButtonSymphony>
      }
      {(loading && isSelected) &&
        <Grid className="w-6">
          <Lottie
            height={24}
            width={24}
            options={{
              loop: true,
              autoplay: true,
              animationData: SymphonyLoadingLottie,
            }}
          />
        </Grid>
      }
    </StyledArtistItem>
  )
}

export default ArtistItem
