import { useEffect, useState } from 'react';
import { ButtonsContainer, useStyles } from 'components/shareable/Modal/styles';
import { getFacebookInformation } from 'services/symphonyApi/adminService';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import ModalSymphony from 'components/shareable/ModalSymphony';

interface Props {
    open: boolean;
    closeModal: () => void;
    brandId: number | null | undefined;
}

interface FacebookInfo {
    id: string;
    name: string;
    currency: string;
    account_status: string;
    amount_spent: string;
    businessId: string;
    businessName: string;
    accessToken: string;
}

export const ShowFacebookInfo = ({
    open,
    closeModal,
    brandId
}: Props) => {
    const { mobileView } = useScreen();
    const [facebookInfo, setFacebookInfo] = useState<FacebookInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const classes = useStyles();

    const getFacebookInfo = async () => {
        setLoading(true);
        try {
            const queryFbInfo = await getFacebookInformation(brandId || 0);
            setFacebookInfo({
                id: queryFbInfo.fbAdAccount.id,
                name: queryFbInfo.fbAdAccount.name,
                currency: queryFbInfo.fbAdAccount.currency,
                account_status: queryFbInfo.fbAdAccount.account_status,
                amount_spent: queryFbInfo.fbAdAccount.amount_spent,
                businessId: queryFbInfo.fbAdAccount.business?.id || 'No Business Found',
                businessName: queryFbInfo.fbAdAccount.business?.name || 'No Business Found',
                accessToken: queryFbInfo.loggedInFbUser.access_token
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            getFacebookInfo();
        }
    }, [open]);

    return (
        <ModalSymphony
            isOpen={open}
            onClose={closeModal}
            title="Admin: Show Facebook Information"
            subtitle="Show general data to troubleshoot Facebook issues."
            fullScreen={mobileView}
        >
            {facebookInfo &&
                <div>
                    <p>ID: {facebookInfo.id}</p>
                    <p>Name: {facebookInfo.name}</p>
                    <p>Currency: {facebookInfo.currency}</p>
                    <p>Account Status: {facebookInfo.account_status}</p>
                    <p>Amount Spent: {facebookInfo.amount_spent}</p>
                    <p>Business ID: {facebookInfo.businessId}</p>
                    <p>Business Name: {facebookInfo.businessName}</p>
                    <p style={{ wordWrap: 'break-word' }}>Access Token: {facebookInfo.accessToken}</p>
                </div>
            }
            <ButtonsContainer flexDirection={mobileView ? "column-reverse" : "row"}>
                <ButtonSymphony
                    className="buttonAdminShowFacebookInfo"
                    onClick={closeModal}
                    disabled={loading}
                    width={mobileView ? "100%" : "fit-content"}
                    variant="outlined"
                >
                    Cancel
                </ButtonSymphony>
            </ButtonsContainer>
        </ModalSymphony>
    );
};