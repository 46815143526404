import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import HeaderModal from '../../HeaderModal';
import { ButtonsContainer, ModalContainer, useStyles } from '../../../../../../components/shareable/Modal/styles';
import { getCampaingInformation } from 'services/symphonyApi/adminService';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';

interface Props {
    open: boolean;
    closeModal: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    campaignId: number | string | undefined;
}

interface CampaignInfo {
    trackingError?: string;
    errors?: { code: string, name: string }[];
    campaignIds?: string[];
    adAccount?: string;
    accessToken?: string;
    fbPage: { id: string, name: string };
}

export const ShowCampaignInfo = ({
    open,
    closeModal,
    campaignId
}: Props) => {
    const [campaignInfo, setCampaignInfo] = useState<CampaignInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const classes = useStyles();

    const { mobileView } = useScreen();

    const getCampaignInfo = async () => {
        setLoading(true);
        try {
            const { campaignInfo: { campaign_metadata } } = await getCampaingInformation(campaignId as number);
            const { trackingError, errors, platform_details, campaign_state } = campaign_metadata;
            setCampaignInfo({
                trackingError: trackingError?.message,
                errors,
                campaignIds: platform_details?.campaigns,
                adAccount: platform_details?.ad_account,
                accessToken: platform_details?.access_token,
                fbPage: { id: campaign_state?.accounts?.fbPage.id, name: campaign_state?.accounts?.fbPage.name }
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            getCampaignInfo();
        }
    }, [open]);

    return (
        <Dialog open={open} classes={{ root: classes.rootDialog }}>
            <ModalContainer width={mobileView ? "auto" : "678px"} padding={mobileView ? "32px 24px" : "32px"}>
                <HeaderModal
                    closeModal={closeModal}
                    title="Admin: Show Campaign Info"
                    subtitle="Show general data to troubleshoot campaign issues."
                />
                {campaignInfo && (
                    <div>
                        <p>Tracking Error: {campaignInfo.trackingError ?? 'No tracking error information available'}</p>
                        <p>Errors: {campaignInfo.errors?.map(error => error.code).join(', ') || 'No errors reported'}</p>
                        <p>Campaign IDs: {campaignInfo.campaignIds?.join(", ") || "There are no campaign ids"}</p>
                        <p>Ad Account: {campaignInfo.adAccount ?? 'No ad account information available'}</p>
                        <p>Facebook Page Name: {campaignInfo.fbPage.name}</p>
                        <p>Facebook Page ID: {campaignInfo.fbPage.id}</p>
                        <p style={{ wordWrap: 'break-word' }}>Access Token: {campaignInfo.accessToken ?? 'No access token available'}</p>
                    </div>
                )}
                <ButtonsContainer flexDirection={mobileView ? "column-reverse" : "row"}>
                    <ButtonSymphony
                        className="buttonAdminShowCampaignInfo"
                        onClick={closeModal}
                        variant="outlined"
                        disabled={loading}
                        width={mobileView ? "100%" : "fit-content"}
                    >
                        Cancel
                    </ButtonSymphony>
                </ButtonsContainer>
            </ModalContainer>
        </Dialog>
    );
};
