import { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Dialog } from '@mui/material';
import {
  AdditionalUserInfoCard,
  AutoPresavedContainer,
  CloseButtonContainer,
  EmptyPresaves,
  FanInformationContainer,
  HeaderContainer,
  ModalContainer,
  PresavesContainer,
  StyledLabel,
  TabsContainer,
  UserInformationContainer,
  useStyles,
} from "./styles";
import { ReactComponent as CloseIcon } from 'assets/images/close-black.svg';
import { Fan } from 'types/global';
import { getFormattedLocation } from '../CustomTable/utils';
import { getPresavedByFan } from './api';
import PresaveCard from './PresaveCard';
import PlatformCell from '../CustomTable/PlatformCell';
import isEqual from "lodash/isEqual";
import { TabSelector } from 'helpers/TabSelector';
import { TabPanel } from 'react-headless-tabs';
import { DefaultImage } from '../../styles';
import { getFormattedName, getFormattedDate, getFormattedSource } from './utils';
import emptyUser from "assets/images/user.svg";
import LoadingLottie from 'components/Loader/LoadingLottie';
import { StyledNavBar, TextButton } from 'styles/shared';
import useScreen from 'Hooks/useScreen';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

export type Presave = {
  contentName: string,
  artistName: string
  imageUrl: string,
  type: string,
}

interface Props {
  open: boolean,
  fan: Fan,
  closeModal: () => void,
}

enum Tabs {
  PRESAVES = "Pre-saved Releases",
}

const { PRESAVES } = Tabs;

const GRAY_COLOR = "#707070";

const FansDetailsModal = ({
  fan,
  open,
  closeModal,
}: Props) => {
  const classes = useStyles();
  const { mobileView } = useScreen();
  const { isProBrand } = useContext(CurrentBrandContext);

  const [presaves, setPresaves] = useState<Presave[]>([]);
  const [loadingPresaves, setLoadingPresaves] = useState<boolean>(false)
  const [loadingForeverPresaves, setLoadingForeverPresaves] = useState<boolean>(false)
  const [autoPresaves, setAutoPresaves] = useState<boolean>(fan.presaveFutureReleases)
  const [selectedTab, setSelectedTab] = useState<Tabs>(PRESAVES);

  const getPresaves = async (fanId: number) => {
    setLoadingPresaves(true)
    const { data, error } = await getPresavedByFan(fanId)
    if (error) {
      setPresaves([])
    } else {
      setPresaves(data as Presave[])
    }
    setLoadingPresaves(false)
  }

  const handleSelectPresavesTab = () => setSelectedTab(PRESAVES)

  useEffect(() => {
    if (fan.id) {
      setAutoPresaves(fan.presaveFutureReleases)
    }
  }, [fan])

  useEffect(() => {
    setLoadingPresaves(true)
    if (fan.id && isEqual(selectedTab, PRESAVES)) {
      setPresaves([])
      getPresaves(fan.id)
    }
  }, [fan.id, selectedTab])

  const fromPresave = fan.origin === "presave";

  const renderForeverSavesStatus = () => {
    let element = null;

    if (loadingForeverPresaves) {
      element = <LoadingLottie
        black
        width={58}
        height={38}
      />
    } else {

      let fanEnabledForeverSaves = autoPresaves
      let titleMessage = fanEnabledForeverSaves ? "Subscribed to Forever Saves" : ""
      let descriptionMsg = fanEnabledForeverSaves ? "This fan is subscribed to saving all of your future releases. You can set their subscription status at the link below - if they're unsubscribed, they'll be able to re-subscribe from any future presave campaign." : "This fan hasn't consented or subscribed to Forever Saves. They'll be able to re-subscribe from any future presave campaign."
      let fanAction = fanEnabledForeverSaves && fan.updateSubscriptionLink ? <TextButton onClick={() => {
        window.open(fan.updateSubscriptionLink, "_blank")
      }}>Edit Subscription Status</TextButton> : null
      element = (<>
 
        <AdditionalUserInfoCard>
          <StyledLabel fontSize="16px">{titleMessage}</StyledLabel>
          <StyledLabel color={GRAY_COLOR} fontSize="12px">
            {descriptionMsg}
          </StyledLabel>

          {fanAction}

        </AdditionalUserInfoCard>
      </>)
    }

    return (
      <AutoPresavedContainer>
        {element}
      </AutoPresavedContainer>
    )
  }

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        scrollPaper: classes.scrollPaper,
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={mobileView ? "auto" : "auto"}
        margin={mobileView ? "0px" : "auto"}
        padding={mobileView ? "16px" : "32px"}
        height="fit-content"
        gap="24px"
      >
        <HeaderContainer>
          <CloseButtonContainer onClick={closeModal}>
            <CloseIcon />
          </CloseButtonContainer>
          <FanInformationContainer>
            <DefaultImage
              borderRadius="50%"
              height="56px"
              width="56px"
              src={fan.profile_url || emptyUser}
              alt="user avatar"
              backgroundColor={fan.profile_url ? "none" : GRAY_COLOR}
            />
            <UserInformationContainer>
              {fromPresave && <PlatformCell
                justifyContent="flex-start"
                // flexDirection="row-reverse"
                platformKey={fan.platform}
              />}
              <StyledLabel fontSize="32px" lineHeight="40px" blur={!isProBrand}>
                {getFormattedName({
                  name: fan.name,
                  lastname: fan.lastname,
                  isProBrand: isProBrand
                })}
              </StyledLabel>
              <StyledLabel fontSize="18px" color={GRAY_COLOR}>
                {fan.email || fan.cellphone || "Contact info not shared"}
              </StyledLabel>
            </UserInformationContainer>
          </FanInformationContainer>
        </HeaderContainer>
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Joined
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {getFormattedDate(fan.createdAt)}
          </StyledLabel>
        </AdditionalUserInfoCard>
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Contact Info - Email
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {fan.email || "Email not collected"}
          </StyledLabel>
        </AdditionalUserInfoCard>
        {fan.cellphone && (<AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Contact Info - Number
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {fan.cellphone}
          </StyledLabel>
        </AdditionalUserInfoCard>)}
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Location
          </StyledLabel>
          <StyledLabel fontSize="16px" blur={!isProBrand}>
            {getFormattedLocation({ city: fan.city, country: fan.country, isProBrand })}
          </StyledLabel>
        </AdditionalUserInfoCard>
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Source
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {getFormattedSource(fan.origin, presaves[0]?.contentName || "")}
          </StyledLabel>
          {fromPresave && renderForeverSavesStatus()}
        </AdditionalUserInfoCard>
        {fromPresave && (<TabsContainer>
          <StyledNavBar>
            <TabSelector
              isActive={isEqual(selectedTab, PRESAVES)}
              onClick={handleSelectPresavesTab}
            >
              {PRESAVES}
            </TabSelector>
          </StyledNavBar>
          <TabPanel hidden={!isEqual(selectedTab, PRESAVES)}>
            {presaves.length ? (
              <PresavesContainer>
                {presaves.map((presave: Presave) => (
                  <PresaveCard
                    artistName={presave.artistName}
                    imageUrl={presave.imageUrl}
                    presaveName={presave.contentName}
                    presaveType={presave.type}
                  />
                ))}
              </PresavesContainer>
            ) : loadingPresaves ? (
              <Grid className="w-full flex justify-center">
                <LoadingLottie
                  black
                  width={24}
                  height={24}
                />
              </Grid>
            ) : (
              <EmptyPresaves> This fan hasn’t pre-saved any releases yet.</EmptyPresaves>
            )}
          </TabPanel>
        </TabsContainer>)}
      </ModalContainer>
    </Dialog>
  );
}

export default FansDetailsModal;
