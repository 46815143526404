import { makeStyles } from "@material-ui/core";
import { Theme } from "@mui/material";

export const localStyles = makeStyles((theme: Theme) => ({
    inputAdornment: {
        marginRight: '2px',
        paddingRight: '0px', // or any value that you think looks good
    },
    mobileTextField: {
        '& .MuiInputBase-root': {
            height: '100%',
            padding: '10px inherit',
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
            gap: 0,
            lineHeight: 1,
            alignItems: 'flex-start',
            '& .MuiInputAdornment-root': {
                height: '100px'
            },
            '& .MuiInputBase-input': {
                padding: '0',
                height: '40px'
            }
        }
    },
    textField: {
        '& input': {
            padding: '16px 16px 16px 0px',
        },
    },
}));
