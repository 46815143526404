import { useContext } from "react"
import { InfoBanner } from "./styles"
import useStyles, { Card, StyledLabel, Icon } from "../../styles"
import Grid from "@material-ui/core/Grid"
import { clsx } from 'modules/Utils'
import FacebookIcon from "assets/images/facebook-rounded-icon.svg"
import InstagramIcon from "assets/images/instagram-bordered-icon.svg"
import { useTheme, useMediaQuery } from "@material-ui/core"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

interface Props {
  link: string
  conversionsEnabled?: boolean
  conversionsEvent?: string
  handleClick: () => void
}

interface CampaignTypeBannerProps {
  hideIcons?: boolean
  marginLeft?: string
}

const CampaignTypeBanner = ({
  hideIcons,
  marginLeft,
}: CampaignTypeBannerProps) => (
  <InfoBanner {...{ marginLeft }} width={hideIcons ? "175px" : "244px"}>
    {!hideIcons && (
      <Grid container>
        <Icon
          width={20}
          height={20}
          alt="facebook-icon.svg"
          src={InstagramIcon}
        />
        <Icon
          position="relative"
          right="4px"
          width={20}
          height={20}
          alt="facebook-icon.svg"
          src={FacebookIcon}
        />
      </Grid>
    )}
    <Grid>
      <StyledLabel fontSize={12} fontWeight={400} width="180px">
        Increase Link Clicks via IG/FB
      </StyledLabel>
    </Grid>
  </InfoBanner>
)

const HeadCard = ({
  link,
  conversionsEnabled,
  conversionsEvent,
  handleClick,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { currentBrand: brand } = useContext(CurrentBrandContext)
  const hideIcons = useMediaQuery(theme.breakpoints.down(388))
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const { image } = brand || {}

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container direction="column">
            <StyledLabel fontSize={18} fontWeight={600}>
              Review Campaign Details
            </StyledLabel>
            <StyledLabel
              fontSize={16}
              fontWeight={400}
              color="#707070"
              marginTop="8px"
            >
              Review your details to make sure everything looks good before we
              submit your campaign to Facebook.
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.space, classes.border)}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs>
            <Grid container spacing={2} alignItems="center">
              <Grid className={classes.brandIconWidth} item >
                <Icon
                  width={80}
                  alt="brand-icon.svg"
                  borderRadius="6px"
                  src={image || ""}
                />
              </Grid>
              <Grid item xs>
                <Grid container direction="column" justifyContent="center">
                  <CampaignTypeBanner {...{ hideIcons }} />
                  <StyledLabel
                    marginTop="8px"
                    onClick={handleClick}
                    color="#8800FF"
                    cursor="pointer"
                    fontWeight={500}
                    fontSize={16}
                  >
                    Preview Ads
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={600}>
                Link
              </StyledLabel>
            </Grid>
            <Grid item>
              <StyledLabel
                overflowWrap="anywhere"
                fontSize={16}
                fontWeight={400}
              >
                {link}
              </StyledLabel>
            </Grid>
          </Grid>
        </Grid>
        {conversionsEnabled && (
          <>
            <Grid item className={clsx(classes.space, classes.border)}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <StyledLabel fontSize={16} fontWeight={600}>
                    Symphony Conversions
                  </StyledLabel>
                </Grid>
                <Grid item>
                  <StyledLabel
                    overflowWrap="anywhere"
                    fontSize={16}
                    fontWeight={400}
                  >
                    Enabled
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={clsx(classes.space, classes.border)}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <StyledLabel fontSize={16} fontWeight={600}>
                    Conversions Event Name
                  </StyledLabel>
                </Grid>
                <Grid item>
                  <StyledLabel
                    overflowWrap="anywhere"
                    fontSize={16}
                    fontWeight={400}
                  >
                    {conversionsEvent}
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}

export default HeadCard
