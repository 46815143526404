import { Fragment, useContext } from "react";
import { GradientRoundedContainer, AvatarContainer } from "../../styles";
import Avatar from "@material-ui/core/Avatar";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

interface Props {
  avatar?: string;
  isDiv?: boolean;
}

const CustomAvatar = ({
  avatar,
  isDiv
}: Props) => {
  const { isProBrand } = useContext(CurrentBrandContext)

  const avatarComponent = (
    <Fragment>
      {isProBrand &&
        <GradientRoundedContainer width="45px" height="45px">
          <AvatarContainer top="2.3px">
            <Avatar src={avatar} />
          </AvatarContainer>
        </GradientRoundedContainer>
      }
      {!isProBrand && <Avatar src={avatar} />}
    </Fragment>
  );

  if (isDiv) {
    return <div>{avatarComponent}</div>
  }

  return avatarComponent;
};

export default CustomAvatar;
