import DialogPaper from "components/shareable/DialogPaper";
import { useRef, useState } from "react";
import { ChipContainer, CloseButton, MobileBanner, ModalBody, ModalContainer, ModalHeader, Video, VideoButton, VideoContainer, VideoCover, useStyles } from "../../../../components/shareable/Modal/with-video/styles";
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as PauseIcon } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import { Body2, Caption, Headline1, Headline2 } from "components/shareable/Typography";
import ConversionsChart from "assets/images/conversions-chart.png";
import { ReactComponent as FacebookIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as DesktopIcon } from "assets/images/computer.svg";
import useScreen from "Hooks/useScreen";
import Colors from "modules/Colors";
import { clsx } from 'modules/Utils';
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface Props {
  className?: string;
  open: boolean;
  closeModal: () => void;
  title: string;
  subtitle: string;
  onClickButton: () => void;
  buttonText: string;
  videoUrl: string;
}

const VideoModalPortrait = ({
  className,
  open,
  closeModal,
  title,
  subtitle,
  onClickButton,
  buttonText,
  videoUrl,
}: Props) => {

  const classes = useStyles();
  const { mobileView } = useScreen();
  const [paused, setPaused] = useState(false);
  const video = useRef<HTMLVideoElement>(null);

  const handleOnPauseUnpauseVideo = () => {
    if (video?.current) {
      paused ? video.current.play() : video.current.pause()
      setPaused(prev => !prev)
    }
  }

  const dialogStyleMobile = {
    maxWidth: "500px",
    width: "95%",
    height: "90%",
    maxHeight: "649px",
    gap: "0px",
    padding: "0px",
  }

  const dialogStyleDesktop = {
    maxWidth: "894px",
    width: "95%",
    height: "90%",
    maxHeight: "615px",
    gap: "0px",
    padding: "0px",
  }

  if (mobileView) {
    return (
      <DialogPaper
        open={open}
        dialogStyle={dialogStyleMobile}
      >
        <ModalContainer
          className={clsx('videoModalPortraitComponent', className)}
          flexDirection="column"
        >
          <VideoContainer height="100%">
            <CloseButton
              onClick={closeModal}
            >
              <CloseIcon className={classes.svgWhite} />
            </CloseButton>
            <VideoCover
              background={paused ? Colors.LIGHT_VIDEO_HOVER_LAYOUT : "transparent"}
              onClick={handleOnPauseUnpauseVideo}
            >
              <VideoButton
                height="50px"
                width="50px"
                background={paused ? Colors.VIDEO_BUTTON_CONTAINER : "transparent"}
                color={paused ? Colors.textColorWhite : "transparent"}
              >
                {paused ?
                  <PlayIcon height="24px" color='inherit' /> :
                  <PauseIcon height="24px" color='inherit' />
                }
              </VideoButton>
            </VideoCover>
            <ModalHeader>
              <ChipContainer>
                <FacebookIcon className={classes.svgAccent} />
                <Caption>Powered by Facebook Conversions API</Caption>
              </ChipContainer>
              <Headline2 color={Colors.textColorWhite}>
                {title}
              </Headline2>
              <Body2 color={Colors.textColorWhite}>
                {subtitle}
              </Body2>
            </ModalHeader>
            <Video
              ref={video}
              src={videoUrl}
              loop
              playsInline
              autoPlay
            />
          </VideoContainer>
          <ModalBody padding="16px" gap="16px">
            <MobileBanner>
              <DesktopIcon width="24px" height="24px" />
              <Body2 color={Colors.textColor}>
                Go to your laptop or Desktop browser to set up Conversion API
              </Body2>
            </MobileBanner>
            <img src={ConversionsChart} alt="conversions-chart" />
          </ModalBody>
        </ModalContainer>
      </DialogPaper>
    )
  }

  return (
    <DialogPaper
      open={open}
      dialogStyle={dialogStyleDesktop}
    >
      <ModalContainer
        className="videoModalPortraitContainer"
      >
        <VideoContainer width="48%">
          <VideoCover
            background={paused ? Colors.LIGHT_VIDEO_HOVER_LAYOUT : "transparent"}
            onClick={handleOnPauseUnpauseVideo}
          >
            <VideoButton
              className="videoModalPortraitVideoButton"
              width="80px"
              height="80px"
              background={paused ? Colors.VIDEO_BUTTON_CONTAINER : "transparent"}
              color={paused ? Colors.textColorWhite : "transparent"}
            >
              {paused ?
                <PlayIcon width="32px" color='inherit' /> :
                <PauseIcon width="32px" color='inherit' />
              }
            </VideoButton>
          </VideoCover>
          <Video
            ref={video}
            src={videoUrl}
            loop
            playsInline
            autoPlay
          />
        </VideoContainer>
        <ModalBody
          className="videoModalPortraitBody"
          padding="56px 32px 32px 32px"
          width="52%"
          gap="24px"
        >
          <CloseButton
            className="videoModalPortraitCloseButton"
            onClick={closeModal}
            background={Colors.PAPER_DIVIDER_CONTAINER}
          >
            <CloseIcon width={24} height={24} />
          </CloseButton>
          <ModalHeader>
            <ChipContainer>
              <FacebookIcon className={classes.svgAccent} />
              <Caption>Powered by Facebook Conversions API</Caption>
            </ChipContainer>
            <Headline1 color={Colors.textColor}>
              {title}
            </Headline1>
            <Body2 color={Colors.greyDark}>
              {subtitle}
            </Body2>
          </ModalHeader>
          <img src={ConversionsChart} alt="conversions-chart" />
          <ButtonSymphony
            className="videoModalPortraitButton"
            onClick={onClickButton}
            width="100%"
            style={{
              flexShrink: 0,
            }}
          >
            {buttonText}
          </ButtonSymphony>
        </ModalBody>
      </ModalContainer>
    </DialogPaper>
  );
}

export default VideoModalPortrait;