import { ChangeEvent } from "react";
import useStyles from "./styles";
import { Slider } from "@material-ui/core";

interface RangeSliderProps {
  maxLimit: number;
  minLimit: number;
  maxValue: number;
  minValue: number;
  setMaxValue: (value: number) => void;
  setMinValue: (value: number) => void;
  disabled?: boolean;
}

const RangeSlider = ({
  maxValue,
  minValue,
  setMaxValue,
  setMinValue,
  maxLimit,
  minLimit,
  disabled = false
}: RangeSliderProps) => {

  const classes = useStyles();
  const handleOnChangeSlider = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (newValue instanceof Array) {
      setMinValue(newValue[0]);
      setMaxValue(newValue[1]);
    }
  };

  return (
    <Slider
      className={classes.ageSlider}
      value={[minValue, maxValue]}
      classes={disabled ? { thumb: classes.disabledThumb, track: classes.disabledRail }: {}}
      onChange={handleOnChangeSlider}
      valueLabelDisplay='auto'
      aria-labelledby='range-slider'
      min={minLimit}
      max={maxLimit}
      disabled={disabled}
    />
  )
};

export default RangeSlider;