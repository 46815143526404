import { NavLink } from "react-router-dom"

type NavItemProps = {
    className?: string,
    link?: string,
    onClick?: (e: any) => void,
    selected?: boolean
    innerComponent: any
}

const NavItem = ({
    className = '',
    link,
    onClick = () => {},
    selected,
    innerComponent
}: NavItemProps) => {
    const defaultStyles = `nav-link cursor-pointer text-black  block lg:flex items-center p-0 lg:py-1.5
    lg:px-0 w-full lg:px-5 transition-all ${selected ? 'active-stroke text-white ' : 'hover:text-gray-300 text-gray-400'}`

    if (link) {
        return (
            <NavLink
                className={`navLinkComponent ${defaultStyles} ${className}-NavLink`}
                activeClassName="text-white active-stroke"
                to={link}
                onClick={(e: any) => onClick(e)}
                exact
            >
                {innerComponent}
            </NavLink>
        )
    } else {
        return (
            <div
                className={`${defaultStyles} ${className}-NavItem`}
                onClick={(e: any) => onClick(e)}
            >
                {innerComponent}
            </div>
        )
    }
}

export default NavItem