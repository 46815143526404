import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Colors from "modules/Colors";
import { clsx } from "modules/Utils";

const SLIDER_OFFSET = 42;

const sliderStyles = {
    /** Track styles for the slider */
    track: {
        backgroundColor: Colors.purpleLight,
        height: 24,
        borderRadius: 100,
    },
    /** Rail styles (the background track) */
    rail: {
        backgroundColor: '#e1d9eb',
        height: 24,
        borderRadius: 100,
    },
    /** Handle styles for the slider */
    handle: {
        borderColor: Colors.purple,
        backgroundColor: Colors.purple,
        opacity: 1,
        width: 34,
        height: 34,
        marginTop: -6,
        boxShadow: 'none'
    },
    /** Styles for the mark text below the slider */
    marks: {
        fontSize: 12,
        marginTop: 8,
        color: Colors.grey,
    },
    /** Styles for the mark dots on the slider */
    dot: {
        borderColor: Colors.purple,
        backgroundColor: Colors.purple,
        width: 8,
        height: 8,
        bottom: -12,
    }
};


interface BudgetCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const BudgetCampaignView = ({
    className = '',
    style = {}
}: BudgetCampaignViewProps) => {
    const { campaign, budget, setBudget, campaignName } = useCampaign();

    const renderMarks = (labelValue: string, description: string, placement: 'first' | 'middle' | 'last' = 'middle') => {
        const markStyle = { marginTop: 30 };
    
        let markPlacement: CSSProperties = { alignItems: 'center' }
        if(placement === 'first') {
            markPlacement = { alignItems: 'flex-start', marginLeft: 80 }
        } else if(placement === 'last') {
            markPlacement = { alignItems: 'flex-end', marginRight: 80 }
        }

        return {
            style: markStyle, label: (
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: 120, ...markPlacement }}>
                    <div>
                        {labelValue}
                    </div>
                    <div>
                        {description}
                    </div>
                </div>
            )
        };
    }

    /** Maps actual budget values to equidistant positions on slider */
    const valueMap = {
        100: 100,   // First point
        500: 733,   // Second point (1/3 of the way)
        1000: 1367, // Third point (2/3 of the way)
        2000: 2000  // Last point
    };

    /** Converts display value back to actual value */
    const reverseValueMap: Record<number, number> = Object.entries(valueMap).reduce((acc, [actual, display]) => ({
        ...acc,
        [display]: parseInt(actual)
    }), {});

    const BUDGET_MARKS = {
        [valueMap[100]]: renderMarks('$100', '1,500 to 3,000 fans', 'first'),
        [valueMap[500]]: renderMarks('$500', '3,000 to 7,000 fans'),
        [valueMap[1000]]: renderMarks('$1,000', '7,000 to 20,000 fans'),
        [valueMap[2000]]: renderMarks('$2,000', '35,000 to 50,000 fans', 'last')
    };

    return (
        <div
            className={clsx("budgetCampaignViewComponent", className)}
            style={{ ...sty.budgetCampaignViewComponent, ...style }}
        >
            <div style={{ display: 'flex' }}>
                <div>
                    Campaign Name:
                </div>
                <div>
                    {campaignName}
                </div>
            </div>
            <div
                className="budgetInputContainer"
                style={sty.budgetInputContainer}
            >
                <div>
                    Budget
                </div>
                <input
                    type="number"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    style={sty.budgetInput}
                />
            </div>
            <div className="sliderContainer" style={sty.sliderContainer}>
                <Slider
                    min={100 - SLIDER_OFFSET}
                    max={2000 + SLIDER_OFFSET}
                    marks={BUDGET_MARKS as any}
                    value={budget ? valueMap[parseInt(budget) as keyof typeof valueMap] || parseInt(budget) : 0}
                    onChange={(value) => {
                        const actualValue = reverseValueMap[value as keyof typeof reverseValueMap] || value;
                        setBudget(actualValue.toString());
                    }}
                    trackStyle={sliderStyles.track}
                    railStyle={sliderStyles.rail}
                    handleStyle={sliderStyles.handle}
                    dotStyle={sliderStyles.dot}
                    style={{ marginTop: -16 }}
                />
            </div>
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    budgetCampaignViewComponent: {
        width: '100%',
        height: '100%',
    },
    budgetInputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    budgetInput: {
        padding: 8,
        border: `1px solid #ddd`,
        borderRadius: 8,
    },
    sliderContainer: {
        width: '100%',
        marginTop: 16,
        backgroundColor: Colors.purpleLighter,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 14,
        paddingRight: 14,
        borderRadius: 100,
    }
}

export default BudgetCampaignView;