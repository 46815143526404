import { useContext, useEffect, useState } from "react";
import { MainContainer } from "styles/shared";
import OrganizationNeedsSetupState from "../SetupStatus/OrganizationNeedsSetupState";
import { CurrentBrand } from "types/global";
import { Card } from "../styles";
import { useHistory } from "react-router-dom";
import { TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from "modules/Const";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import LoaderV2 from 'components/Loader/LoaderV2';
import { Headline1 } from "components/shareable/Typography";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { CurrentUserContext } from "Hooks/CurrentUserContext";

/**
 * Renders a loading view when an admin is viewing team details
 * Shows a loading spinner and message while team artists and organization data is being fetched
 * Used specifically in admin contexts when viewing/managing other organizations
 * 
 * @returns {JSX.Element} Card component with loading spinner and message
 */
export const renderAdminTeamLoadingView = () => {
    return (
        <Card className="organizationNeedsSetupCardComponent" padding="40px 24px" borderRadius="8px" marginBottom="16px"
            width="750px">
            <Headline1>Loading Team Details for Admin View...</Headline1>
            <LoaderV2 />
        </Card>
    )
}

const AdminOnboarding = () => {
    const history = useHistory()
    const { isSymphonyAdmin: isAdmin } = useContext(CurrentUserContext);
    const { currentBrand } = useContext(CurrentBrandContext)
    const { brandIsInOrganization, loadingOrganizationFromAdminView, isLoadingTeamArtists } = useCurrentTeam()

    let initiallyShownBrands: CurrentBrand[] = [];
    if (currentBrand?.slug && brandIsInOrganization(currentBrand.slug)) {
        initiallyShownBrands = [];
    } else if (currentBrand) {
        initiallyShownBrands = [currentBrand];
    }

    const [defaultBrands, setDefaultBrands] = useState<CurrentBrand[]>(initiallyShownBrands);

    useEffect(() => {
        if (!isAdmin) {
            history.push('/')
        }
    }, [isAdmin])

    const renderPrimaryView = () => {
        return (
            <Card
                className="organizationNeedsSetupCardComponent"
                padding="40px 24px"
                borderRadius="8px"
                marginBottom="16px"
                width="750px"
            >
                <OrganizationNeedsSetupState
                    existingBrands={defaultBrands}
                    title="Admin: Onboard a Team"
                    description={`Create a team on behalf of an organization or profile.\nYou can manage unlimited profiles through your Team, and you have ${TEAM_PLAN_INCLUDED_PRO_ACCOUNTS} Pro accounts included by default.`}
                    organization={null}
                    onFinished={() => { }}
                    isAdminSetup
                />
            </Card>
        )
    }

    return (
        <MainContainer className="organizationPageComponent">
            {loadingOrganizationFromAdminView || isLoadingTeamArtists ? renderAdminTeamLoadingView() : renderPrimaryView()}
        </MainContainer>
    )
}

export default AdminOnboarding