/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
  useContext,
} from "react";
import {
  TextField,
  Chip,
  Checkbox,
  Grid,
  SvgIcon,
  InputAdornment,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/lab/Autocomplete";
import useStyles from "./styles";
import {
  IObjectKeys,
  AutoCompleteCloseReasons as CloseReasons,
  AutoCompleteChangeReasons as ChangeReasons,
} from "types/global";
import SpotifyIcon from "assets/images/streaming/icons/spotify-i.svg";
import debounce from "lodash/debounce";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import millify from "millify";
import {
  AutoCompleteReason,
  AutoCompleteSpotifyArtistsProps,
  DeleteIconProps,
  EMPTY_STRING,
  getNoOptionsText,
  getSpotifyArtists,
  getTextFieldPlaceholder,
  Option,
} from "./utils";
import { MarketingDataContext } from "pages/post-auth/MarketingPage/Data/Provider";

interface CloseIconProps {
  color?: string;
}

const CloseIcon = ({ color }: CloseIconProps) => (
  <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
  <path
      d="M6.75 2.25L2.25 6.75"
      stroke={color || "white"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
  />
  <path
      d="M2.25 2.25L6.75 6.75"
      stroke={color || "white"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
  />
  </svg>
);

const DeleteIcon = ({
  color,
  onClick,
}: DeleteIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon {...{ onClick }} viewBox="1 0 7 8" className={classes.closeIcon}>
      <CloseIcon {...{ color }} />
    </SvgIcon>
  );
};

const AutoCompleteSpotifyArtists = ({
  deletedAudience,
  deleteIconColor,
  setDeletedAudience,
  setSelectedSpotifyArtists,
  setDeletedArtist,
  setArtists,
}: AutoCompleteSpotifyArtistsProps) => {
  const { artists: contextArtists } = useContext(MarketingDataContext);
  const classes = useStyles();
  const [options, setOptions] = useState([] as Option[]);
  const [selectedOptions, setSelectedOptions] = useState([] as Option[]);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(EMPTY_STRING);
  const [loading, setLoading] = useState(false);
  const textFieldPlaceholder = getTextFieldPlaceholder(selectedOptions);
  const noOptionsText = getNoOptionsText(inputValue);

  const handleChangeAutoComplete = (
    _event: ChangeEvent<{}>,
    selected: Option[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option>
  ) => {
    const isDeletedOption =
      reason === ChangeReasons.removeOption && details?.option;
    const isNotRemovedOption = reason !== ChangeReasons.removeOption;

    if (isDeletedOption) setDeletedArtist([details.option]);
    if (setSelectedSpotifyArtists && isNotRemovedOption) {
      setSelectedSpotifyArtists(selected);
    }
    setSelectedOptions(selected);
    setArtists(selected);
  };

  const handleChangeTextField = debounce(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const isValid = value !== EMPTY_STRING && value !== null;
      const args = { value, selectedOptions, setOpen, setOptions, setLoading };

      setInputValue(value);

      if (value.trim() === EMPTY_STRING) {
        setOptions([]);
        setOpen(false);
      }
      if (isValid) await getSpotifyArtists(args);
    },
    1500
  );

  const handleOpenList = () => {
    if (inputValue.trim() !== EMPTY_STRING) setOpen(true);
  };

  const handleCloseList = (
    _event: ChangeEvent<{}>,
    reason: AutoCompleteReason
  ) => {
    if (reason !== CloseReasons.removeOption) {
      setOpen(false);
      setInputValue(EMPTY_STRING);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Backspace" || event.key === "Enter") {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  useEffect(() => {
    if (contextArtists.length) setSelectedOptions(contextArtists);
  }, [contextArtists]);

  useEffect(() => {
    if (deletedAudience && deletedAudience.artistId) {
      const { artistId, audiences } = deletedAudience;
      const [found] = audiences?.filter(
        (audience) => audience.artistId === artistId
      );
      const options = selectedOptions.filter((o) => o.id !== artistId);

      if (!found) {
        setSelectedOptions(options);
        setArtists(options);
      }
      setDeletedAudience({ artistId: null, audiences: [] });
    }
  }, [deletedAudience]);

  return (
    <Autocomplete
      {...{ open, options, loading, noOptionsText }}
      classes={{ option: "border-b" }}
      value={selectedOptions}
      multiple
      className="mt-2 p-0"
      getOptionSelected={(option, value) => option.id === value.id}
      id="asynchronous-autocomplete-spotify-artists"
      onOpen={handleOpenList}
      onClose={handleCloseList}
      forcePopupIcon={false}
      clearOnBlur={false}
      onChange={handleChangeAutoComplete}
      getOptionLabel={(option: Option) => option.name}
      loadingText={
        <Grid container justifyContent="center">
          <Grid item>
            <LoadingIndicator color="black" height="24" />
          </Grid>
        </Grid>
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const props = getTagProps({ index }) as IObjectKeys;
          const { onDelete } = props;

          return (
            <Chip
              {...props}
              avatar={
                <Avatar
                  alt={option.name}
                  src={option.thumbnail_url || undefined}
                />
              }
              className="h-7 mx-0.5"
              deleteIcon={<DeleteIcon color={deleteIconColor} onClick={onDelete as () => void} />}
              classes={{
                label: classes.chipLabelColor,
                colorPrimary: classes.chipBackgroundColor,
              }}
              color="primary"
              label={option.name}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          classes={{ root: classes.root }}
          onChange={handleChangeTextField}
          onKeyDown={handleOnKeyDown}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            ) : undefined,
          }}
          variant="outlined"
          placeholder={textFieldPlaceholder}
        />
      )}
      renderOption={(option) => {
        const [found] = selectedOptions.filter(
          (selected) => selected.id === option.id
        );
        const checked = Boolean(found);

        return (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    variant="square"
                    alt={option.name}
                    src={option.thumbnail_url || undefined}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>{option.name}</Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <img
                            className="w-3 h-3"
                            alt="spotify-icon"
                            src={SpotifyIcon}
                          />
                        </Grid>
                        <Grid item>
                          <span className="text-gray-400">
                            {millify(option.followers || 0, {
                              lowercase: true,
                            })}{" "}
                            Followers
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Checkbox
                {...{ checked }}
                color="default"
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checkedCheckbox,
                }}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AutoCompleteSpotifyArtists;
