import { CSSProperties, useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MainContentContainer from 'components/shareable/MainContentContainer';
import { pageView } from 'analytics';
import { GettingStartedContext } from 'Hooks/GettingStartedContext';
import { formattedWebsiteUrl } from "modules/Utils";
import TopBar from 'components/shareable/TopBar/TopBar';
import WebsiteEditorV2 from './components/CustomizeWebsite/CustomizeWebsiteV2';
import { WebsiteType, WEBSITE_TYPES } from './types';
import LinkContainer from 'components/shareable/LinkContainer';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import useScreen from 'Hooks/useScreen';
import { EditWebsiteSlugModal } from '../MarketingPage/Components/Modals/EditWebsiteSlugModal';
import { searchSpotifyReleases } from '../MarketingPage/Components/SuperboostModal/Components/shared-music-selector-utils';
import PublishSiteModal from './components/PublishSiteModal';
import { WebsiteProvider } from './components/CustomizeWebsite/hooks/WebsiteContext';
import RenderRightSideTopBarContent from './components/RightSideTopBarContent';
import WebsitePreviewV2 from './components/WebsitePreview/WebsitePreviewV2';
import PublishedWebsiteModal from './components/CustomizeWebsite/components/PublishedWebsiteModal';
import { extractSlugsFromFrontfacingLink } from '../MarketingPage/utils/links';
import Colors from 'modules/Colors';
import LoaderFullScreen from 'components/Loader/LoaderFullScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { getBrandContent } from 'services/symphonyApi/brandContentService';

interface WebsiteParams {
  websiteType: WebsiteType;
  websiteSlug?: string;
}

const { DATA_COLLECTOR, TOUR } = WEBSITE_TYPES;

const Website = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<WebsiteParams>();
  const { mobileView, cssCalc } = useScreen();
  const { websiteType, websiteSlug } = params;
  const { finishedTasks } = useContext(GettingStartedContext);
  const { currentBrand, isBrandAdmin } = useContext(CurrentBrandContext);

  const url = new URLSearchParams(location.search).get('url');

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [loadingContent, setLoadingContent] = useState<any>([]);
  const [brandContent, setBrandContent] = useState<any>(null);
  const [openEditUrlModal, setOpenEditUrlModal] = useState<boolean>(false);
  const [openPublishModal, setOpenPublishModal] = useState<boolean>(false);
  const [openPublishedModal, setOpenPublishedModal] = useState<boolean>(false);
  const [websiteUrl, setWebsiteUrl] = useState<string>(formattedWebsiteUrl(currentBrand, brandContent));
  const linkSlugs = extractSlugsFromFrontfacingLink(websiteUrl, currentBrand?.slug!);
  const [localUrl, setLocalUrl] = useState(linkSlugs ? `${linkSlugs.brandSlug}-${linkSlugs.urlSlug}` : '');

  const { published } = brandContent?.content_metadata || {};

  useEffect(() => {
    if (linkSlugs) setLocalUrl(`${linkSlugs.brandSlug}-${linkSlugs.urlSlug}`);
  }, [linkSlugs]);

  /** Tracks if we're currently processing a manual URL edit */
  const isEditingUrl = useRef(false);

  const getWebsiteEditorData = useCallback(async (newWebsiteSlug?: string) => {
    if (newWebsiteSlug) {
      setLoadingContent(true);
      const brandContent = await getBrandContent(currentBrand?.slug!, newWebsiteSlug);
      if (brandContent) {
        setBrandContent(brandContent);
      }
      setLoadingContent(false);
    } else if (url) {
      setLoadingContent(true);
      const response = await searchSpotifyReleases(url);
      if (response) {
        setBrandContent(response.data);
      }
      setLoadingContent(false);
    } else if (currentBrand && websiteSlug) {
      setLoadingContent(true);
      const brandContent = await getBrandContent(currentBrand.slug!, websiteSlug);
      if (brandContent) {
        setBrandContent(brandContent);
      } else if (websiteType === DATA_COLLECTOR) {
        const DefaultDataCollectorContent = {
          name: 'New data collector site',
          type: DATA_COLLECTOR,
          content_metadata: {
            primary_artist: currentBrand.name,
            tracks: [], // TODO: Remove once we verify it's not needed
          },
        };
        setBrandContent(DefaultDataCollectorContent);
      } else if (websiteType === TOUR) {
        const DefaultTourContent = {
          name: 'New Events & Tours site',
          type: TOUR,
          content_metadata: {
            primary_artist: currentBrand.name,
          },
        };
        setBrandContent(DefaultTourContent);
      }
      setLoadingContent(false);
    }
  }, [currentBrand, url, websiteSlug, websiteType]);

  // This is called when the page first loads

  useEffect(() => {
    pageView('Website');

    // New funciton - uses the url param to pull in the specific content data
    // to laod the editor
    getWebsiteEditorData();
  }, [finishedTasks, getWebsiteEditorData]);

  useEffect(() => {
    if (currentBrand && brandContent && !isEditingUrl.current) {
      setWebsiteUrl(formattedWebsiteUrl(currentBrand, brandContent));
    }
    isEditingUrl.current = false;
  }, [brandContent, currentBrand]);

  const isProject = brandContent?.content_metadata?.tracks?.length > 0;
  const artistName = brandContent?.content_metadata?.primary_artist || currentBrand?.name;

  const onEditUrl = (newBrandContentSlug: string) => {
    isEditingUrl.current = true;
    const baseBrandUrlIndex = websiteUrl.lastIndexOf('/');
    const newWebsiteUrl = websiteUrl.slice(0, baseBrandUrlIndex + 1) + newBrandContentSlug;
    setWebsiteUrl(newWebsiteUrl);
    getWebsiteEditorData(newBrandContentSlug);
  };

  const renderCustomizeLoader = () => {
    return (
      <LoaderFullScreen title="Loading Website..." />
    );
  };

  const renderUrlComponent = () => {
    if (!websiteUrl) return;
    return (
      <LinkContainer
        url={websiteUrl}
        showShareButton
        showEditButton={isBrandAdmin}
        width={mobileView ? '100%' : '70%'}
        padding="8px 16px"
        margin={mobileView ? '8px 0px 16px 0' : '0'}
        buttonTextColor={Colors.purpleLight}
        backgroundColor={Colors.PAPER_DIVIDER_CONTAINER_LIGHT}
        textColor={Colors.grey}
        onOpenEditLinkModal={() => setOpenEditUrlModal(true)}
      />
    );
  };

  let topBarTitle = '';
  if (loadingContent || !brandContent) {
    topBarTitle = 'Loading website details...';
  } else if (websiteType === DATA_COLLECTOR) {
    topBarTitle = `${artistName} - ${brandContent.name}`;
  } else if (websiteType === TOUR) {
    topBarTitle = `${artistName} - ${brandContent.name}`;
  } else {
    topBarTitle = `${artistName} - ${brandContent.name} - ${isProject ? 'Album' : 'Single'}`;
  }

  const renderMobileContent = () => {
    return (
      <>
        <WebsitePreviewV2
          className='websitePreviewV2Mobile'
          websiteType={websiteType}
        />
        <div
          className="websiteEditerDrawer"
          style={{
            ...sty.websiteEditerDrawer,
            height: cssCalc(0),
            transform: openDrawer ? 'translateY(0)' : 'translateY(100%)'
          }}
        >
          <WebsiteEditorV2
            className='websiteEditorV2Mobile'
            style={{
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0
            }}
            brandContent={brandContent}
            websiteType={websiteType}
          />
        </div>
        <div
          className='websitePreviewV2FooterMobile2'
          style={sty.websitePreviewV2FooterMobile2}
        >
          <ButtonSymphony
            className='customizeWebsiteButtonMobile'
            width={124}
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            {openDrawer ? 'Preview' : 'Customize'}
          </ButtonSymphony>
          <RenderRightSideTopBarContent
            isButtonOnly
            buttonWidth={124}
            brandContent={brandContent}
            published={published}
            mobileView={mobileView}
            handleOpenPublishModal={() => setOpenPublishModal(true)}
            renderUrlComponent={renderUrlComponent}
          />
        </div>
      </>
    )
  }

  const renderDesktopContent = () => {
    return (
      <MainContentContainer
        className="websiteMainContent"
        style={sty.websiteMainContent}
      >
        <WebsiteEditorV2 brandContent={brandContent} websiteType={websiteType} />
      </MainContentContainer>
    )
  }

  if (loadingContent) {
    return renderCustomizeLoader()
  }

  return (
    <WebsiteProvider brandContent={brandContent} websiteType={websiteType}>
      <div
        className="websiteComponent"
        style={sty.websiteComponent}
      >
        <TopBar
          renderRightSideContent={!mobileView ?
            <RenderRightSideTopBarContent
              brandContent={brandContent}
              published={published}
              mobileView={mobileView}
              handleOpenPublishModal={() => setOpenPublishModal(true)}
              renderUrlComponent={renderUrlComponent}
            />
            :
            undefined
          }
          renderDownSideContent={published && mobileView && renderUrlComponent()}
          showCloseIcon
          hideGetSupportLabel
          handleClose={() => history.goBack()}
          title={topBarTitle}
        />
        {mobileView ?
          renderMobileContent()
          :
          renderDesktopContent()
        }
        <EditWebsiteSlugModal
          isOpen={openEditUrlModal}
          onClose={() => setOpenEditUrlModal(false)}
          brandSlug={currentBrand?.slug!}
          brandId={currentBrand?.id!}
          updatingContentSlug={linkSlugs?.urlSlug || brandContent.slug || websiteSlug!}
          onSave={(newSlug: string) => onEditUrl(newSlug)}
          url={localUrl}
          onSaveUrl={(newSlug: string) => {
            setLocalUrl(newSlug);
          }}
        />
        <PublishSiteModal
          isOpen={openPublishModal}
          onClose={() => setOpenPublishModal(false)}
          brandContent={brandContent}
          originalSlug={brandContent?.slug || websiteSlug!}
          onPublished={() => setOpenPublishedModal(true)}
          refreshSiteData={getWebsiteEditorData}
        />
        <PublishedWebsiteModal
          isOpen={openPublishedModal}
          websiteUrl={websiteUrl}
          onClose={() => setOpenPublishedModal(false)}
        />
      </div>
    </WebsiteProvider>
  );
};

const sty: Record<string, CSSProperties> = {
  websiteComponent: {
    // minHeight: '100vh',
  },
  websiteMainContent: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  websiteEditerDrawer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.greyLighter,
    transition: 'transform 0.3s ease-in-out',
    zIndex: 920,
    overflowY: 'scroll',
  },
  websitePreviewV2FooterMobile2: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    justifyContent: 'space-around',
    padding: 16,
    borderRadius: '8px 8px 0px 0px',
    zIndex: 950,
    backgroundColor: Colors.purpleDark,
    gap: 8,
  },
}

export default Website;