import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CurrencyText,
} from './style';
import { Dialog, TextField } from '@material-ui/core';
import { toast, ToastOptions } from 'react-toastify';
import LowDailySpendContainer from '../../LowDailySpendContainer';
import debounce from 'lodash/debounce';
import { WarningCodesEditBudget } from '../../LowDailySpendContainer/constants';
import moment from 'moment';
import Axios from 'helpers/Interceptor';
import some from "lodash/some";
import FB from 'helpers/FB';
import * as Sentry from "@sentry/react";
import { track } from 'analytics';
import { SHARED_TOAST_OPTIONS } from '../utils';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CurrencyCodesType } from 'types/global';
import { getConvertedCurrency } from 'services/symphonyApi';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import UnlockDataBanner from 'components/shareable/UnlockDataBanner';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import { DEFAULT_CURRENCY } from 'modules/Const';
import { ButtonsContainer, InputContainer, ModalContainer, useStyles } from '../../../../../../components/shareable/Modal/styles';
import HeaderModal from '../../HeaderModal';
import { ReactComponent as MoneyIcon } from 'assets/images/money-purple.svg';

interface Props {
  open: boolean;
  campaignId: number;
  budget: number;
  totalSpent: number;
  minimalAmountPerDay: number;
  startDate: string;
  endDate: string;
  currency?: CurrencyCodesType;
  toastOptions?: ToastOptions;
  trackObject?: Record<string, any>;
  onErrorHandler?: () => void;
  closeModal: () => void;
  reload: (budget: number) => void;
}

const FACEBOOK_MINIMAL_PERCENTAGE = 1.10;

const EditBudgetModal = ({
  open,
  campaignId,
  toastOptions,
  budget,
  totalSpent,
  minimalAmountPerDay,
  startDate,
  endDate,
  currency = DEFAULT_CURRENCY,
  trackObject,
  onErrorHandler,
  closeModal,
  reload,
}: Props) => {
  const { isProBrand } = useContext(CurrentBrandContext);

  const [loading, setLoading] = useState<boolean>(false)
  const [isValidBudget, setIsValidBudget] = useState<boolean>(false)
  const [amountBudget, setAmountBudget] = useState<number>(budget)
  const [warningError, setWarningError] = useState<WarningCodesEditBudget | null>(null)
  const [newDailySpend, setNewDailySpend] = useState<number | null>(null)
  const [convertedMinimalAmountPerDay, setConvertedMinimalAmountPerDay] = useState<number>(minimalAmountPerDay)
  const inputRef = useRef<HTMLInputElement>()
  const classes = useStyles()
  const { mobileView } = useScreen();
  const campaignDays = Math.abs(moment(endDate).diff(startDate, 'days'))
  const facebookRuleMinimal = Math.ceil(totalSpent * FACEBOOK_MINIMAL_PERCENTAGE)
  const totalMinimalAmountPerDay = convertedMinimalAmountPerDay * campaignDays
  const suggestedBudget = Math.max(facebookRuleMinimal, totalMinimalAmountPerDay)

  useEffect(() => {
    if (currency !== DEFAULT_CURRENCY) {
      setLoading(true)
      convertMinimalAmountPerDay()
      setLoading(false)
    }
  }, [minimalAmountPerDay])

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((newBudget: number) => {
        if (newBudget === budget) {
          setIsValidBudget(false);
          setWarningError(null)
          return;
        }
        const costPerDay = newBudget / campaignDays;
        if (newBudget < facebookRuleMinimal) {
          setIsValidBudget(false)
          setWarningError(WarningCodesEditBudget.FACEBOOK_MINIMAL)
          return;
        }
        if (costPerDay < convertedMinimalAmountPerDay) {
          setIsValidBudget(false);
          setWarningError(WarningCodesEditBudget.DAILY_MINIMAL)
          return;
        }
        setIsValidBudget(true)
        setNewDailySpend(costPerDay)
        if (newBudget < budget) {
          setWarningError(WarningCodesEditBudget.DECREASE)
        } else {
          setWarningError(WarningCodesEditBudget.INCREASE)
        }
      }, 300),
    []
  );

  const handleConfirmEditBudget = async () => {
    setLoading(true)
    const options = toastOptions || SHARED_TOAST_OPTIONS
    const marginToast = mobileView ? "24px 16px auto 16px" : "40px 22px auto"

    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) { }

    try {
      const response = await Axios.post(`campaign/${campaignId}/budget`, {
        budget: amountBudget, access_token,
      });
      if (some([!response, !response.data, !response.data.data])) {
        throw new Error("Error editing budget on campaign");
      }
      reload(amountBudget)
      toast.success('Budget updated!', {
        ...options,
        style: {
          ...options.style,
          margin: marginToast
        }
      })

      track('Saved Edit Budget', {
        ...trackObject,
        opened: 'Edit Budget Modal',
      })
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setExtra("campaignId", campaignId)
        scope.setExtra("budget", amountBudget)
        scope.setExtra("currency", currency)
        Sentry.captureException(e)
      });
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const setAmountInput = (amount: number) => {
    setAmountBudget(amount)
    debouncedChangeHandler(amount)
    inputRef.current?.focus()
  }

  const handleChangeBudget = () => {
    setIsValidBudget(false)
    setWarningError(null)
    if (inputRef.current) {
      const amount = Number(inputRef.current.value) || 0;
      setAmountBudget(amount)
      debouncedChangeHandler(amount)
      inputRef.current.value = amount.toString();
    }
  }

  const convertMinimalAmountPerDay = async () => {
    const convertedValue = await getConvertedCurrency({ amount: minimalAmountPerDay, from: DEFAULT_CURRENCY, to: currency });
    setConvertedMinimalAmountPerDay(convertedValue)
  }

  const renderEditBudgetModalBody = () => {
    if (!isProBrand) {
      return (
        <UnlockDataBanner
          className="unlockDataBannerEditBudget"
          title="Unlock more budget options"
          description="Upgrade to Pro to unlock more budget options and features."
        />
      )
    }

    return (
      <>
        <InputContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: <CurrencyText>{currency}</CurrencyText>,
              startAdornment: <CurrencyText>{getSymbolFromCurrency(currency)}</CurrencyText>
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText
              },
            }}
            className={classes.textField}
            variant="outlined"
            fullWidth
            type="number"
            value={amountBudget}
            defaultValue={budget}
            onChange={handleChangeBudget}
            inputRef={inputRef}
          />
        </InputContainer>
        {warningError && (
          <LowDailySpendContainer
            clickUpdate={setAmountInput}
            currentBudget={budget}
            selectedBudget={amountBudget}
            suggestedBudget={suggestedBudget || 0}
            dailySpend={newDailySpend || 0}
            currency={currency}
            errorCode={warningError}
            totalSpent={totalSpent}
          />
        )}
        <ButtonsContainer
          flexDirection={mobileView ? "column-reverse" : "row"}
        >
          <ButtonSymphony
            className="buttonEditBudgetModal1"
            onClick={closeModal}
            disabled={loading}
            width={mobileView ? "100%" : "fit-content"}
            variant="outlined"
          >
            Cancel
          </ButtonSymphony>
          <ButtonSymphony
            className="buttonEditBudgetModal2"
            onClick={handleConfirmEditBudget}
            disabled={loading || !isValidBudget}
            width={mobileView ? "100%" : "50%"}
            isLoading={loading}
            loadingText="Updating campaign..."
          >
            Update campaign
          </ButtonSymphony>
        </ButtonsContainer>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={mobileView ? "auto" : "678px"}
        padding={mobileView ? "32px 24px" : "32px"}
      >
        <HeaderModal
          HeaderIcon={MoneyIcon}
          closeModal={closeModal}
          title='Edit budget'
          subtitle='After editing your campaign, it may go into review again while your changes are reflected.'
        />
        {renderEditBudgetModalBody()}
      </ModalContainer>
    </Dialog>
  )
}

export default EditBudgetModal;
