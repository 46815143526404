import { ChangeEvent, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles, { SpendLimitContainer, Card } from "./styles";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import SpendLimitAlertCard from "components/SpendLimitAlertCard";
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { Body2 } from "components/shareable/Typography";
import Lottie from "react-lottie";
import LoadingLottie from 'assets/images/lotties/SymphonyLoading.json';
import getSymbolFromCurrency from "currency-symbol-map";
import { CURRENCY_CODE_DATA } from "helpers/currencies";
import { Container, StyledLabel, SymphonyTooltip } from "styles/shared";
import Intercom from "helpers/Intercom";
import LinkButton from "components/shareable/LinkButton";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";
import Colors from "modules/Colors";

type BudgetCardProps = {
  usesFacebookAds?: true;
  budgetValue: number;
  handleChangeInputText?: (e: ChangeEvent<HTMLInputElement>) => void;
  currencyCode?: string;
  minimumDailyBudget?: never;
  // no minDailyBudget needed
} | {
  usesFacebookAds?: false;
  budgetValue: number;
  handleChangeInputText?: (e: ChangeEvent<HTMLInputElement>) => void;
  currencyCode?: string;
  minimumDailyBudget: number; // required
};

const ERROR_COLOR = "rgba(239, 68, 68, 1)";

const BudgetCard = ({
  budgetValue,
  handleChangeInputText,
  currencyCode,
  usesFacebookAds = true,
  minimumDailyBudget
}: BudgetCardProps) => {
  const { mobileView } = useScreen();

  const { isProBrand } = useContext(CurrentBrandContext)
  const { data: spendBudgetData, loading: loadingSpendBudget } = useContext(SpendBudgetContext)
  const spendStatus = spendBudgetData?.status
  const spent = spendBudgetData?.currentAttemptedSpend || 0
  const isBudgetLimit = Boolean(spendStatus) && spendStatus !== 'healthy' && budgetValue > 0
  const classes = useStyles({ error: false });

  const selectedCurrencyMessage = () => {
    if (currencyCode && currencyCode !== "USD") {
      const currencyDetails = CURRENCY_CODE_DATA[currencyCode]
      if (currencyDetails) {
        let message = `Your ${usesFacebookAds ? "ad account's" : 'default'} currency is set to ${currencyDetails.name} (${currencyDetails.code}).`
        return (
          <Container display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
            <StyledLabel fontSize={14} fontWeight={400}>
              {message}
            </StyledLabel>
            <button onClick={() => Intercom.openLearnHowEditCurrency()}>
              <SymphonyTooltip
                className="w-6 h-6"
                title="Click here to learn more."
                arrow
              >
                <LinkButton
                  fontSize="12px"
                  text="Learn more"></LinkButton>
              </SymphonyTooltip>
            </button>
          </Container>
        )
      }
    }
    return null
  }

  return (
    <Card borderRadius={mobileView ? undefined : "12px"}>
      <Grid container direction="column">
        <StyledLabel fontSize={18} fontWeight={600}>
          Budget
        </StyledLabel>
        <StyledLabel color={Colors.greyDark} fontSize={16} marginTop="12px">
          Enter the total budget and dates you want to run your campaign.
        </StyledLabel>
        <Grid>
          <Grid
            className={clsx(
              classes.overall, classes.noBottomBorder, classes.paddingBottomThemeSpacing,
              // TODO: Let's comment this for now in case roll back is needed
              // isProBrand && classes.noBottomBorder,
              // isProBrand && classes.paddingBottomThemeSpacing
            )}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Container display="flex" flexDirection="column" style={{
              maxWidth: '50%'
            }}>
              <StyledLabel fontSize={18} fontWeight={600}>
                Overall Budget
              </StyledLabel>
              {selectedCurrencyMessage()}
            </Container>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {loadingSpendBudget && budgetValue > 0 && (
                    <Lottie
                      height={24}
                      width={24}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: LoadingLottie,
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    error={isBudgetLimit}
                    className={classes.textField}
                    variant="outlined"
                    value={budgetValue}
                    onChange={handleChangeInputText}
                    inputProps={{ className: classes.inputProps }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Body2
                            color={
                              Colors.textColor
                            }
                          >
                            {currencyCode ? getSymbolFromCurrency(currencyCode) : ''}
                          </Body2>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {currencyCode && minimumDailyBudget && budgetValue < minimumDailyBudget &&
            <StyledLabel color={ERROR_COLOR}>
              Please enter a minimum budget of {getSymbolFromCurrency(currencyCode)}{minimumDailyBudget}.
            </StyledLabel>
          }
          {/* only show the budget counter if a user is on Pro */}
          {isProBrand && spendBudgetData && budgetValue > 0 && spent > 0 &&
            <SpendLimitContainer>
              <SpendLimitAlertCard {...spendBudgetData} currencyCode={currencyCode} />
            </SpendLimitContainer>
          }
        </Grid>
      </Grid>
    </Card>
  );
};

export default BudgetCard;
