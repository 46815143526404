import {
  ModalConnectButtonContainer,
} from "../../style"
import ArtistItem from "../ArtistList/ArtistItem"
import { useHistory } from "react-router-dom"
import { SpotifyArtistSearchResponse } from "types/global"
import useScreen from "Hooks/useScreen"
import ModalSymphony from "components/shareable/ModalSymphony"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import { useEffect } from "react"
import { clsx } from 'modules/Utils'

/**
 * Props interface for the AlreadyExistingArtist component
 * @interface Props
 * @property {boolean} open - Controls visibility of the modal
 * @property {SpotifyArtistSearchResponse} artist - Artist data to display
 * @property {() => void} closeModal - Function to close the modal
 */
interface Props {
  className?: string
  isOpen: boolean
  artist: SpotifyArtistSearchResponse
  closeModal: () => void
}

/**
 * Modal component shown when attempting to add an artist that already exists
 * @param {Props} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const AlreadyExistingArtist = ({
  className,
  isOpen,
  artist,
  closeModal,
}: Props) => {
  const history = useHistory()
  const { mobileView } = useScreen();

  useEffect(() => {
    console.log("isOpen >>>>", isOpen)
  }, [])

  /**
   * Navigates user to the dashboard
   * @param {unknown} _parameter - Unused parameter
   */
  const goToDashboard = (_parameter: unknown) => {
    history.push('/dashboard')
  }

  console.log("artist >>>>", artist)
  console.log("isOpen >>>>", isOpen)

  return (
    <ModalSymphony
      className={clsx("artistAlreadyExistsModal", className)}
      isOpen={isOpen}
      onClose={closeModal}
      fullScreen={mobileView}
      title="You've already added this artist."
      subtitle={`Looks like you've already added ${artist?.name || ""} to the artists under your profile.`}
    >
      <ArtistItem
        artist={artist}
        selectArtist={goToDashboard}
        buttonLabel="Go to Dashboard"
      />
      <ModalConnectButtonContainer>
        <ButtonSymphony
          className="artistAlreadyExistsModalButton"
          onClick={closeModal}
          variant="outlined"
        >
          Search for another artist
        </ButtonSymphony>
        {/* <ModalConnectButton onClick={closeModal} color={"#ffffff"}>
          <ModalConnectButtonLabel color={"#8800ff"}>
            Search for another artist
          </ModalConnectButtonLabel>
        </ModalConnectButton> */}
      </ModalConnectButtonContainer>
    </ModalSymphony>
  )
}

export default AlreadyExistingArtist
