import { CSSProperties, useContext } from "react";
import { Tab, Tabs } from "@mui/material";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import GemIcon from "../ProCallouts/GemIcon";
import { makeStyles, Theme } from "@material-ui/core";

interface SymphonyTabsProps {
    className?: string;
    style?: CSSProperties;
    data: {
        value: string,
        label: string | JSX.Element
        isProOnly?: boolean
    }[],
    selectedValue: string | boolean,
    ariaLabel?: string
    gap?: number,
    tabContainerStyle?: CSSProperties,
    tabContentStyle?: CSSProperties,
    hideTabs?: number[],
    onProTabClick?: () => void,
    onChange: (newValue: string) => void,
}

export const SymphonyTabs = ({
    className = '',
    style = {},
    selectedValue = false,
    data,
    gap,
    ariaLabel,
    tabContainerStyle,
    tabContentStyle,
    hideTabs,
    onProTabClick,
    onChange,
}: SymphonyTabsProps) => {
    const { isProBrand } = useContext(CurrentBrandContext)
    const classes = useStyles({ gap })

    const handleProTab = (newValue: string) => {
        const isProTab = data.find((tab) => tab.value === newValue)?.isProOnly
        if (isProTab && !isProBrand && onProTabClick) {
            onProTabClick()
        }
    }

    return (
        <Tabs
            className={`symphonyTabsComponent ${className} ${classes.tabs}`}
            style={style}
            scrollButtons="auto"
            variant="scrollable"
            value={selectedValue}
            onChange={(e, value) => onChange(value)}
            aria-label={ariaLabel}
            allowScrollButtonsMobile
            TabIndicatorProps={{
                style: {
                    backgroundColor: "#8800FF",
                }
            }}
        >
            {data.map((item, index) => {
                if (hideTabs && hideTabs.find((tab) => tab === index)) {
                    return null
                }

                return (
                    <Tab
                        key={index}
                        className={`symphonyTabsTab ${item.value}TabValue`}
                        style={tabContainerStyle}
                        disableRipple
                        value={item.value}
                        onClick={() => handleProTab(item.value)}
                        label={
                            <div
                                className='symphonyTabsContent flex items-center gap-1 capitalize font-medium text-base px-4 py-2'
                                style={tabContentStyle}
                            >
                                {item.isProOnly && <GemIcon color='purple' />}
                                <p className="tabItemLabel" style={{ lineHeight: 'normal' }}>
                                    {item.label}
                                </p>
                            </div>
                        }
                    />
                )
            })}
        </Tabs>
    )
}

type Props = {
    gap?: number;
  } | undefined;
  
  const useStyles = makeStyles((theme: Theme) => ({
    tabs: {
      '& .MuiButtonBase-root': {
        width: 'fit-content',
      },
      '& .Mui-selected': {
        color: 'initial !important',
      },
      '& .MuiTabs-flexContainer': {
        gap: (props: Props) => props?.gap || '0px',
      },
    },
  }));

type TabContentProps = {
    hidden: boolean,
    children: JSX.Element;
}

export const TabContent = ({
    hidden,
    children
}: TabContentProps) => {
    return hidden ? null : children
}

export default SymphonyTabs