import { ButtonsContainer, LocationsContainer, StyledButton, WarningContainer, WarningHeader } from "./styles";
import { ReactComponent as ArrowRight } from "assets/images/arrow-right-icon.svg"
import { LocationDetail } from "../MarketsDetail";
import { FBLocation } from "../../MusicStreams/TargetingView/reducer";

interface Props {
  newLocation: FBLocation
  replacedLocations: LocationDetail[] | FBLocation[]
  onCancel: () => void
  onReplace: (location: FBLocation) => void
}

const getFormattedName = (location: FBLocation | LocationDetail, withType?: boolean) => {
  const [locationName] = location.name.split(',');
  if (!withType) return locationName;
  return `${locationName} (${location.type})`;
}

const LocationsWarning = ({
  newLocation,
  replacedLocations,
  onCancel,
  onReplace
}: Props) => {
  const handleOnReplace = () => {
    onReplace(newLocation)
  }

  const toReplace = replacedLocations.map((location) => getFormattedName(location)).join(", ")
  const toReplaceWithType = replacedLocations.map((location) => getFormattedName(location, true)).join(", ")

  return (
    <WarningContainer>
      <WarningHeader>
        You already have {toReplaceWithType} in your targeting. If you add {getFormattedName(newLocation, true)}, we’ll replace {toReplace} and target more specifically in {getFormattedName(newLocation)}.
      </WarningHeader>
      <LocationsContainer>
        {toReplace}
        <ArrowRight />
        {getFormattedName(newLocation)}
      </LocationsContainer>
      <ButtonsContainer>
        <StyledButton
          onClick={onCancel}
        >
          Cancel
        </StyledButton>
        <StyledButton
          onClick={handleOnReplace}
        >
          Replace it
        </StyledButton>
      </ButtonsContainer>
    </WarningContainer>
  );
}

export default LocationsWarning