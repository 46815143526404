import { useState, useEffect, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import millify from 'millify';
import moment from 'moment';
import momentTz from 'moment-timezone';
import CampaignCardContent from './CampaignCardContent';
import { FacebookError, PlaylistDatav2, PreSave, PreSaveCampaignDetails } from 'types/global';
import isEqual from "lodash/isEqual";
import { getCampaignTitleByCampaignType, getGeographiesTitleByGeographyType } from '../../VideoAds/constants';
import dayjs from 'dayjs';
import { isUsingConversionsApi } from '../../utils/fbCampaigns';
import { getFormatDateCampaigns } from '../../utils/daysCalculator';
import * as Sentry from '@sentry/browser';
import { DEFAULT_CURRENCY } from 'modules/Const';
import { CampaignOption } from '../../GrowInstagram/utils';
import { checkImageUrlValidity } from 'helpers/General';
import { isPossiblyInProgress } from 'modules/Utils'

const linkClicksCampaignImage = require('assets/images/ui/link-clicks-campaign-img.png').default
const boostIgCampaignImage = require('assets/images/ui/ig-boost-campaign-img.png').default
const boostVisitsCampaignImage = require('assets/images/ui/ig-boost-profile-visits-img.png').default

interface DaysCalculator {
    (startDate: string, endDate: string): number;
}

interface CampaignCardProps {
    campaign: any;
    daysCalculator: DaysCalculator;
}

const CampaignCard = ({
    campaign,
    daysCalculator,
}: CampaignCardProps) => {
    const history = useHistory();

    const {
        campaign_metadata,
        id,
        createdAt
    } = campaign;

    const {
        content = {},
        campaign_type,
        budget,
        startDate,
        endDate,
        logistics,
        price_data,
        friendlyName,
        currency,
        campaign_state,
        creative
    } = campaign_metadata;

    const {
        name: contentName,
        thumbnail_url: contentThumbnail,
        campaign_name: campaignName,
        type: contentType
    } = content;

    const currencyCode = currency?.code || currency || DEFAULT_CURRENCY

    const [pageContent, setPageContent] = useState<ReactNode | null>(null);

    const handleClick = () => {
        history.push(`/marketing/campaigns/${id}`);
    };

    const isCampaignPaused = () => {
        const campaignMarkets = campaign.campaign_metadata.platform_details?.campaignsMapping
        if (!campaignMarkets) {
            return
        }

        const isCampaignPaused = campaignMarkets.every((market: any) => market.fbCampaign.fields.status === 'PAUSED')

        return isCampaignPaused;
    }

    const renderContent = async () => {
        let campaignTitle: string = ''
        let campaignNeedsActions: boolean = false
        let cleanedBudget: number = 0
        try {
            const facebookErrorCodes = campaign?.campaign_metadata?.errors?.map((error: FacebookError) => error.code)
            campaignNeedsActions = facebookErrorCodes?.length && (campaign.status === 'TO_RETRY' || campaign.status === 'ACTIVE')
        } catch (error) {
            Sentry.captureException(error);
        }

        switch (campaign_type) {
            case 'record_streams':
            case 'grow_playlist_followers':
            case 'link_clicks':
            case 'boost_instagram':
            case 'increase_sales':
                cleanedBudget = typeof budget === 'string' ? parseFloat(budget) : budget

                const budgetText = `${cleanedBudget.toLocaleString('en-US', { style: 'currency', currency: currencyCode })}`
                const startDateText = dayjs(startDate).format('MMM D, YYYY')
                const today = new Date().toLocaleDateString()
                const endDateText = dayjs(endDate).format('MMM D, YYYY')
                const daysLeft = daysCalculator(today, endDate)
                const totalDays = daysCalculator(startDate, endDate)
                let subtitleText = `Budget: ${budgetText}`
                const hasConversionsApi = isUsingConversionsApi(campaign_metadata)
                let mainCampaignStatus = campaign.status

                if (isCampaignPaused()) {
                    mainCampaignStatus = 'PAUSED'
                }

                if (daysLeft > 0) {
                    subtitleText += ` | Running from ${startDateText} to ${endDateText} | Days Left: ${daysLeft.toFixed(0)}`
                } else {
                    subtitleText += ` | Ran from ${startDateText} to ${endDateText} (${totalDays} Day${totalDays === 1 ? '' : 's'})`
                }
                let campaignImg = ''
                if (campaign_type === 'record_streams' || campaign_type === 'grow_playlist_followers') {
                    campaignTitle = contentName
                    if (contentType === 'playlist') {
                        campaignTitle += ' (Playlist)'
                    }
                    campaignImg = contentThumbnail
                } else if (campaign_type === 'link_clicks') {
                    campaignTitle = campaignName
                    campaignImg = linkClicksCampaignImage
                } else if (campaign_type === 'increase_sales') {
                    campaignTitle = campaignName
                    campaignImg = linkClicksCampaignImage
                } else if (campaign_type === 'boost_instagram') {
                    campaignTitle = campaignName
                    // for boost IG, we have to first check campaign subtype
                    // and then update the icon based on the subtype
                    campaignImg = boostIgCampaignImage

                    let campaignSubtype: CampaignOption | undefined = undefined;
                    if (campaign_type === 'boost_instagram') {
                        campaignSubtype = campaign_state?.campaign?.targeting?.campaignType || undefined
                    }

                    if (campaignSubtype) {
                        switch (campaignSubtype?.id) {
                            case 'boost_follows':
                                campaignImg = creative?.owner?.profile_pic || creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || boostVisitsCampaignImage
                                // Fetch image metadata and fix if image is broken
                                try {
                                    if (campaignImg) await checkImageUrlValidity(campaignImg);
                                    // if this doesn't throw, the image is valid and doesn't need a fallback.
                                } catch (error) {
                                    // if this throws, the image is invalid and needs a fallback.
                                    campaignImg = boostVisitsCampaignImage
                                }
                                break;
                            case 'boost_views':
                            default:
                                campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || creative?.owner?.profile_pic || boostIgCampaignImage
                                // Fetch image metadata and fix if image is broken
                                try {
                                    if (campaignImg) await checkImageUrlValidity(campaignImg);
                                    // if this doesn't throw, the image is valid and doesn't need a fallback.
                                } catch (error) {
                                    // if this throws, the image is invalid and needs a fallback.
                                    campaignImg = boostIgCampaignImage
                                }
                                break;
                        }
                    } else {
                        // Default image if no campaign subtype
                        campaignImg = boostIgCampaignImage
                        // Fetch image metadata and fix if image is broken
                        try {
                            if (campaignImg) await checkImageUrlValidity(campaignImg);
                            // if this doesn't throw, the image is valid and doesn't need a fallback.
                        } catch (error) {
                            // if this throws, the image is invalid and needs a fallback.
                            campaignImg = boostIgCampaignImage
                        }
                    }
                }

                if (friendlyName) {
                    campaignTitle = friendlyName
                }

                let createdAtOption = createdAt
                // if the start date for some reason is before created at date from DB,
                // set the createdAt text to the start date
                if (startDate < createdAt) {
                    createdAtOption = startDate
                }

                // to retry campaigns should be markd as IN REVIEW
                if (campaign.status === 'TO_RETRY') {
                    mainCampaignStatus = 'in_review'
                }

                if (campaign.status === 'ANTICIPATED_PAUSE') {
                    mainCampaignStatus = 'paused'
                }

                return (
                    <CampaignCardContent
                        onClick={handleClick}
                        thumbnailSrc={campaignImg}
                        titleText={campaignTitle}
                        campaignType={campaign_type}
                        campaignStatus={mainCampaignStatus}
                        createdAt={dayjs(createdAtOption).format('MMM D, YYYY')}
                        campaignNeedsActions={campaignNeedsActions}
                        subtitle={subtitleText}
                        isUsingConversionsApi={hasConversionsApi}
                        campaignId={id}
                    />
                );
            case 'get_playlisted':
                const { tier: playlistingTier } = price_data
                let getPlaylistedSubtitle: string = `Goal: ${millify(playlistingTier || 0)} Followers`
                const { playlists: playlistsList } = campaign_metadata

                if (playlistsList && playlistsList.length > 0) {
                    const pitchedPlaylists = playlistsList?.filter((playlist: PlaylistDatav2) => (playlist.playlistStatus && playlist.playlistStatus === 'Pitched'))
                    const acceptedPlaylists = playlistsList?.filter((playlist: PlaylistDatav2) => (playlist.playlistStatus && playlist.playlistStatus === 'Accepted'))

                    if (acceptedPlaylists.length > 0) {
                        getPlaylistedSubtitle += ` | Added to ${acceptedPlaylists.length} Playlists`
                    }

                    if (pitchedPlaylists.length > 0) {
                        getPlaylistedSubtitle += ` | Pitching to: ${pitchedPlaylists.length} Playlists`
                    }
                }

                let campaignStatus = campaign.status

                // if the campaign is marked as finished, but is less than 35 days old, mark it as "ACTIVE"
                if (campaignStatus === 'FINISHED' && daysCalculator(new Date().toDateString(), createdAt) < 35) {
                    campaignStatus = 'ACTIVE'
                }

                // if the campaign is marked as active, but was before march 15, 2023,
                // mark it as finished
                if (campaignStatus === 'ACTIVE' && (createdAt < '2023-03-15')) {
                    campaignStatus = 'FINISHED'
                }
                return (
                    <CampaignCardContent
                        onClick={handleClick}
                        thumbnailSrc={contentThumbnail}
                        titleText={contentName}
                        campaignType={campaign_type}
                        campaignStatus={campaignStatus}
                        createdAt={dayjs(createdAt).format('MMM D, YYYY')}
                        campaignNeedsActions={campaignNeedsActions}
                        subtitle={getPlaylistedSubtitle}
                    />
                );
            case 'increase_video_views':
                const {
                    budget: campaignBudget
                } = logistics

                const {
                    targetingType,
                    geographies
                } = campaign_metadata

                cleanedBudget = typeof campaignBudget === 'string' ? parseFloat(campaignBudget) : campaignBudget

                let subtitle = `Budget: ${cleanedBudget.toLocaleString('en-US', { style: 'currency', currency: currencyCode })}`

                subtitle += ` | Goal: ${getCampaignTitleByCampaignType(targetingType)}`

                if (geographies && geographies.type) {
                    subtitle += ` | Targeting: ${getGeographiesTitleByGeographyType(geographies.type)}`
                }

                let mainStatus = campaign.status
                if (!mainStatus) {
                    mainStatus = 'ACTIVE'
                }

                campaignTitle = contentName
                if (friendlyName) {
                    campaignTitle = friendlyName
                }

                return (
                    <CampaignCardContent
                        onClick={handleClick}
                        thumbnailSrc={contentThumbnail}
                        titleText={campaignTitle}
                        campaignType={campaign_type}
                        campaignStatus={mainStatus}
                        createdAt={dayjs(createdAt).format('MMM D, YYYY')}
                        campaignNeedsActions={campaignNeedsActions}
                        subtitle={subtitle}
                    />
                );
            case 'pre_save':
                const {
                    campaign_metadata: preSaveMetaData,
                    presavesData,
                    emailsData,
                    status
                } = (campaign as PreSaveCampaignDetails);
                const { endDate: preSaveEndDate, presave } = preSaveMetaData;
                const { boosts, local_release, release_url_upc_isrc } = presave as PreSave;
                const { collect_emails } = boosts;
                const calculatedDays = Number(
                    daysCalculator(`${new Date()}`, preSaveEndDate).toFixed(0)
                );
                const timezone = preSaveMetaData.presave.options?.timezone || ""
                const daysUntilRelease = calculatedDays >= 0 ? calculatedDays : 0;
                const daysRelease = calculatedDays >= 0 ? calculatedDays : 0;
                const notCompletelyFinished = isEqual(status, "FINISHED") && local_release && moment().diff(preSaveEndDate, 'hours') <= 24;
                const isStatusActive = (isEqual(status, "ACTIVE") && !isPossiblyInProgress(status, local_release, release_url_upc_isrc, preSaveEndDate)) || notCompletelyFinished;
                const isWithIssues = isEqual(status, "WITH_ISSUES");
                const isStatusFinished = isEqual(status, "FINISHED");
                const isNotFound = isEqual(status, "NOT_FOUND");
                const isInProgress = isEqual(status, "IN_PROGRESS") || isPossiblyInProgress(status, local_release, release_url_upc_isrc, preSaveEndDate);
                const isAfterFansRelease = moment(preSaveMetaData.startDate).isAfter('2023-03-14T20:30:34Z');
                const hasBeenReleased = moment().isSameOrAfter(preSaveEndDate);

                const getTotalTimeRemaining = () => {
                    let date = moment(preSaveEndDate);
                    if (timezone) {
                        date = momentTz(preSaveEndDate).tz(timezone);
                    }

                    return date.fromNow();
                }

                const formatted = getFormatDateCampaigns(preSaveEndDate, timezone);
                let currentStatus = 'IN_REVIEW';
                if (isStatusActive) {
                    currentStatus = 'ACTIVE'
                } else if (isStatusFinished) {
                    currentStatus = 'FINISHED'
                } else if (isNotFound) {
                    currentStatus = 'NOT_FOUND'
                } else if (isInProgress) {
                    currentStatus = 'IN_PROGRESS'
                }

                return (
                    <CampaignCardContent
                        onClick={handleClick}
                        thumbnailSrc={contentThumbnail}
                        titleText={contentName}
                        campaignType={campaign_type}
                        campaignStatus={currentStatus}
                        campaignLabel={isWithIssues ? 'NEEDS UPC/ISRC' : currentStatus}
                        campaignNeedsActions={campaignNeedsActions}
                        subtitle={
                            <>
                                <p>
                                    {presavesData ?
                                        presavesData.total === 1 ? `${presavesData.total} Pre-Save ` : `${presavesData.total} Pre-Saves `
                                        :
                                        "0 Pre-Saves "
                                    }
                                    {collect_emails && isAfterFansRelease ?
                                        <span>
                                            &amp; {emailsData ?
                                                emailsData.new === 1 ? `${emailsData.new} New Email` : `${emailsData.new} New Emails`
                                                :
                                                "0 New Emails"
                                            }
                                        </span>
                                        :
                                        <span></span>
                                    }

                                    &nbsp;|&nbsp;
                                    {daysUntilRelease > 0 &&
                                        <span>
                                            {daysUntilRelease} Day{daysUntilRelease > 1 ? 's' : ''} until
                                            Release ({formatted})
                                        </span>
                                    }

                                    {!hasBeenReleased && daysUntilRelease <= 0 &&
                                        <span>
                                            Your song releases {getTotalTimeRemaining()}
                                        </span>
                                    }
                                    {hasBeenReleased &&
                                        <span>
                                            Song released on {formatted}
                                        </span>
                                    }
                                </p>
                            </>
                        }
                    />
                );
            default:
                return null;
        };
    }

    useEffect(() => {
        renderContent().then(setPageContent);
    }, [campaign]);

    return (
        <div className={`campaignCardComponent cursor-pointer flex flex-row justify-between items-center border-b px-0 py-5 md:px-4 md:py-7`}>
            {pageContent}
        </div>
    )
}

export default CampaignCard;
