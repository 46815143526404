import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import Colors from "modules/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  svgPrimary: {
    '& path': {
      stroke: Colors.purple,
      fill: Colors.purple,
    },
  },
  svgGray: {
    cursor: 'pointer',
    '& path': {
      stroke: Colors.greyDark,
    },
  },
  svgWhite: {
    height: '40%',
    position: 'absolute',
    cursor: 'pointer',
    '& path': {
      stroke: Colors.PAPER_CONTAINER,
    },
  },
  svgGrayAbsolute: {
    cursor: 'pointer',
    position: 'absolute',
    right: '8px',
    top: '6px',
    '& path': {
      stroke: Colors.greyDark,
    },
  },
}));

interface ColumnFlexContainerProps {
  gap?: string;
}

interface RowFlexContainerProps {
  gap?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
}

interface VideoContainerProps {
  height?: string;
}

interface RelativeContainerProps {
  cursor?: string;
  background?: string;
  padding?: string;
}

interface AbsoluteContainerProps {
  background?: string;
  borderRadius?: string;
}

interface MainContainerProps {
  flexDirection?: string;
  position?: string;
  borderRadius?: string;
  padding?: string;
}

interface VideoBannerProps {
  bottom?: string;
  left?: string;
  right?: string;
  fontSize?: string;
  padding?: string;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || '16px 24px'};
  gap: 16px;
  background: ${Colors.PAPER_CONTAINER};
  flex-direction: ${({ flexDirection }) => flexDirection};
  position: ${({ position }) => position};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const ConversionsVideo = styled.video`
  height: 100%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
`;

export const VideoContainer = styled.div<VideoContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  min-width: ${({ height }) => height};
  max-width: ${({ height }) => height};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

export const RelativeContainer = styled.div<RelativeContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
`;

export const VideoBanner = styled.div<VideoBannerProps>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  border-radius: 2.825px;
  background: ${Colors.yellow};
  transform: rotate(-7.454deg);
  padding: ${({ padding }) => padding};
  gap: 2.825px;
  font-size: ${({ fontSize }) => fontSize};
  line-height: 11.87px;
  color: ${Colors.divider};
`;

export const ConversionsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ColumnFlexContainer = styled.div<ColumnFlexContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
  width: 100%;
`;

export const RowFlexContainer = styled.div<RowFlexContainerProps>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap || '4px'};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
`;

export const AbsoluteContainer = styled.div<AbsoluteContainerProps>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background};
`;

export default useStyles;
