/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import InfoSection from "components/InfoSection";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import GeographicTargeting from "../../MusicStreams/TargetingView/GeographicTargeting";
import { FbSuggestion } from "types/global";
import { Option as SpotifyArtistsOption } from "./Components/AutoCompleteSpotifyArtists/utils";
import { TargetingExistingCustomAudiences } from "../../Components/TargetDemographics/TargetingExistingCustomAudiences";
import { TargetingViewProps } from "../../Components/TargetingView/utils";
import TargetingAdditionalPageAudiences from "../../Components/TargetDemographics/TargetingAdditionalPageAudiences";
import AudienceTargeting from "../../Components/TargetingView/AudienceTargeting";
import { CAMPAIGN_TYPES } from "modules/Const";

const TITLE = "Audience Targeting";
const DESCRIPTION =
  "Refine the audiences you want to target with your marketing.";

const TargetingView = ({
  fbSuggestions,
  isBelowRecommendedBudget,
  loading,
  customAudiences,
  setCustomAudiences,
  genre,
  showTrashIcon,
  isResponsive,
  deleteIconColor,
  showSaveButton,
  handleIsEditing,
  handleTargets,
  handleAudiences,
  handleArtists,
  handleTotalBudget,
  setShowTargetingView,
  setAdditionalPagesAudiences,
  additionalPageAudiences,
}: TargetingViewProps) => {
  const [audiences, setAudiences] = useState([] as FbSuggestion[]);
  const [artists, setArtists] = useState<SpotifyArtistsOption[]>([]);

  const [openTargetingModal, setOpenTargetingModal] = useState(false);
  const [openAdditionalPagesModal, setOpenAdditionaPagesModal] = useState(false);

  const content = loading ? (
    <LoadingIndicator>
      <p className="mt-1 text-center px-1">
        Symphony is generating audiences that are most optimal for increasing
        link clicks.
      </p>
    </LoadingIndicator>
  ) : (
    <>
      <AudienceTargeting
        {...{
          setAudiences,
          setArtists,
          genre,
          deleteIconColor,
          setOpenTargetingModal
        }}
        openTargetingModal={() => setOpenTargetingModal(true)}
        openAdditionalPagesModal={() => setOpenAdditionaPagesModal(true)}

        customAudiences={customAudiences}
        setCustomAudiences={setCustomAudiences}

        additionalPageAudiences={additionalPageAudiences}
        setAdditionalPagesAudiences={setAdditionalPagesAudiences}

        initialTargetingAudiences={fbSuggestions}
      />

      <GeographicTargeting
        {...{
          campaignType: CAMPAIGN_TYPES.boost_instagram,
          isBelowRecommendedBudget,
          audiences,
          artists,
          setShowTargetingView,
          showTrashIcon,
          isResponsive,
          deleteIconColor,
          handleTargets,
          handleAudiences,
          handleArtists,
          handleTotalBudget,
          handleIsEditing,
          showSaveButton
        }}
      />

      {openTargetingModal &&
        <TargetingExistingCustomAudiences
          openTargetingModal={openTargetingModal}
          customAudiences={customAudiences}
          setCustomAudiences={setCustomAudiences}
          setOpenTargetingModal={setOpenTargetingModal}
        />
      }

      {openAdditionalPagesModal &&
        <TargetingAdditionalPageAudiences
          openTargetingModal={openAdditionalPagesModal}
          customAudiences={additionalPageAudiences}
          setCustomAudiences={setAdditionalPagesAudiences}
          setOpenTargetingModal={setOpenAdditionaPagesModal}
        />
      }
    </>
  );

  return (
    <div className="rounded-xl bg-white">
      <InfoSection title={TITLE} description={DESCRIPTION} />
      {content}
    </div>
  );
};

export default TargetingView;
