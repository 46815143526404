import BadgeCampaignTypeContainer from 'pages/post-auth/MarketingPage/Components/BadgeCampaignTypeContainer/BadgeCampaignTypeContainer';
import CampaignStatusBadge from "../CampaignStatusBadge";

interface Props {
  campaignName: string;
  date: string;
  campaignType: string;
}

const Title = ({ text }: { text: string }) => (
  <h3 className="font-normal text-lg">{text}</h3>
);

// Subtitle component
interface SubtitleProps {
  children: React.ReactNode;
}
const Subtitle = ({ children }: SubtitleProps) => (
  <div className="text-sm text-gray-500">{children}</div>
);

const CampaignDescription = ({
  campaignName,
  date,
  campaignType,
}: Props) => {
  return (
    <div 
      className='campaignDescriptionComponent' 
      style={sty.campaignDescriptionComponent}
    >
      <CampaignBadges campaignType={campaignType} campaignStatus="draft" />
      <CampaignTitleAndDate campaignName={campaignName} date={date} />
    </div>
  );
};

export default CampaignDescription;

export const CampaignBadges = ({
  campaignType, 
  campaignStatus='draft'
}: {
  campaignType: string, 
  campaignStatus: string
}) => {
  return (
    <div className='campaignBadgesComponent' style={sty.campaignBadgesComponent}>
      <BadgeCampaignTypeContainer
        campaignType={campaignType}
      />
      <div className="marketingPageCampaignStatusBadge ml-2">
        <CampaignStatusBadge
          campaignStatus={campaignStatus}
          campaignStatusLabel="DRAFT"
        />
      </div>
    </div>
  )
}

export const CampaignTitleAndDate = ({
  campaignName, 
  date
}: {
  campaignName: string, 
  date: string
}) => {
  return (
    <div className='campaignTitleAndDateComponent'>
      <Title text={campaignName} />
      <Subtitle>Last update: {date}</Subtitle>
    </div>
  )
}

const sty: Record<string, React.CSSProperties> = {
  campaignBadgesComponent: {
    marginBottom: '0.5rem',
    flexGrow: 0,
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left'
  },
  campaignDescriptionComponent: {
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
} 