import { useContext, useEffect, useState } from 'react';
import { useStyles as useWebsiteStyles } from '../../styles';
import ConversionsConfigStep from 'pages/post-auth/SettingsPage/Components/ConversionsConfigStep';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import StyledTextField from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/Components/StyledTextField';
import { Steps } from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/utils';
import { isValidTikTokPixel } from 'components/connect/TikTokPixel';
import { isValidGA4Id } from 'components/connect/GoogleAnalytics';
import ConversionsIndicator from 'pages/post-auth/MarketingPage/Components/ConversionsIndicator';
import { useStyles } from './styles';
import { ReactComponent as TiktokIcon } from 'assets/images/tiktok-black.svg';
import { ReactComponent as GoogleIcon } from 'assets/images/google.svg';
import ConnectFBPixelModal from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/Components/ConnectFBPixelModal';
import useCommonStates from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/Hooks/useCommonStates';
import { updateInBrand } from 'services/symphonyApi/brandService';
import { getLinkedPixels } from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/api';
import { PixelInstructionsSteps } from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/ConversionsSteps/FacebookPixel';
import { useHistory } from 'react-router-dom';
import { Body2, Subtitle1 } from 'components/shareable/Typography';
import { showToast } from "modules/Utils"
import Intercom from "helpers/Intercom";
import UpgradeToUnlockCTA from '../UpgradeToUnlockCTA';
import { PlanType } from 'modules/Const';
import Colors from 'modules/Colors';

const PixelSection = () => {
    const history = useHistory();
    const { currentBrand, isProBrand, reloadBrand } = useContext(CurrentBrandContext);
    const { facebook_pixel, facebook_ad_account, logged_in_fb_user } = currentBrand?.connections || {};
    const { id: fbPixelId, name: fbPixelValue } = facebook_pixel || {};

    const [openPixelModal, setOpenPixelModal] = useState<boolean>(false);

    const { facebookPixel } = useCommonStates(Steps.FACEBOOK_PIXEL)

    const handleClickGoToPixelSettingsPage = () => {
        window.open(
            `https://business.facebook.com/settings/events-dataset-news/${facebook_pixel?.id}?business_id=${facebook_ad_account?.business?.id}`,
            "_blank"
        )
    }

    const validatePixel = async () => {
        if (facebook_ad_account) {
            facebookPixel.setLoading(true)
            const linkedPixels = await getLinkedPixels({
                fbAdAccount: facebook_ad_account,
                onError: facebookPixel.setError,
                onLoading: facebookPixel.setLoading,
                accessToken: logged_in_fb_user?.access_token,
            })

            const isPixelLinked = linkedPixels.find(
                (pixelLinked: { id: string }) => pixelLinked?.id === facebook_pixel?.id
            )

            facebookPixel.setError(!Boolean(isPixelLinked))
            facebookPixel.setLoading(false)
        }
    }

    const goToAdvancedSettings = () => {
        history.push('/settings?tab=ADVANCED_SETTINGS');
    }

    useEffect(() => {
        if (facebook_pixel) {
            validatePixel()
        }
    }, [facebook_pixel])

    if (facebook_pixel && !facebookPixel.error) {
        return (
            <ConversionsIndicator
                title='Facebook Pixel + Conversions API'
                description='Your Facebook Pixel is setup with Conversions.'
                bottomDescription='Your Pixel was successfully set, if you want to modify it you should go to advanced settings.'
                intercomButtonText='Advanced Settings page'
                onIntercomClick={goToAdvancedSettings}
                pixelId={fbPixelId}
                showSwitchButton={false}
                padding='16px 24px'
                showAsRecommended
                isConversionsEnabled
            />
        )
    }

    return (
        <>
            <ConversionsConfigStep
                className='pixelSectionComponent'
                title='Facebook Pixel'
                live={Boolean(facebook_pixel)}
                description='Select the Facebook Pixel you want to use to run ads. Make sure this pixel is on the website you want to run ads on.'
                buttonText='Verify'
                isCompleted={false}
                isSuccessful={false}
                intercomButtonText={null}
                padding='24px'
                showIntercomButton
                showErrorView={facebookPixel.error}
                onClickErrorButton={validatePixel}
                onClickIntercomButton={Intercom.openWhereIsMyFacebookPixelArticle}
            >
                <>
                    <StyledTextField
                        readOnly
                        disabled={!isProBrand}
                        id={fbPixelId}
                        warning={facebookPixel.error}
                        loading={false}
                        connected={false}
                        value={fbPixelValue || "Facebook Pixel"}
                        onClickConnectButton={!isProBrand ? undefined : () => setOpenPixelModal(true)}
                        stepName={Steps.FACEBOOK_PIXEL}
                        padding='0px 16px'
                    />
                    {facebookPixel.error &&
                        <PixelInstructionsSteps
                            handleClickGoToPixelSettingsPage={handleClickGoToPixelSettingsPage}
                        />
                    }
                </>
            </ConversionsConfigStep>
            <ConnectFBPixelModal
                open={openPixelModal}
                facebookPixel={facebookPixel}
                onSuccessful={() => showToast({ error: false, message: 'Facebook Pixel connected successfully' })}
                onError={(error: boolean) => {
                    facebookPixel.setError(error)
                    showToast({ error: true, message: 'Failed to connect your Facebook Pixel' })
                }}
                onClose={(reload?: boolean) => {
                    if (reload) reloadBrand()
                    setOpenPixelModal(false)
                }}
            />
        </>
    );
}

const TikTokSection = () => {
    const classes = useStyles();
    const { currentBrand, isProBrand, reloadBrand } = useContext(CurrentBrandContext);
    const { tiktok_pixel } = currentBrand?.connections || {};

    const [tiktokValue, setTiktokValue] = useState<string>(tiktok_pixel || '');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const onConnect = async () => {
        setLoading(true);
        const response = await updateInBrand(String(currentBrand?.id), String(currentBrand?.slug), tiktokValue, 'tiktok_pixel')
        if (response) {
            await reloadBrand();
            setError(false);
            showToast({ error: false, message: 'TikTok Pixel connected successfully' })
        } else {
            showToast({ error: true, message: 'Failed to connect your TikTok Pixel' })
            setError(true);
        }
        setLoading(false);
    }

    return (
        <ConversionsConfigStep
            className='tikTokSectionComponent'
            title='TikTok Pixel'
            live={Boolean(tiktok_pixel)}
            description='Add a TikTok Pixel you want to use with Symphony.'
            buttonText='Connect'
            isCompleted={false}
            isSuccessful={!error}
            intercomButtonText={null}
            padding='24px'
            showIntercomButton
            onClickIntercomButton={Intercom.openWhereIsMyTiktokPixelArticle}
        >
            <>
                <StyledTextField
                    disabled={!isProBrand}
                    inputError={Boolean(!tiktokValue?.length || !isValidTikTokPixel(tiktokValue))}
                    connected={Boolean(tiktok_pixel)}
                    loading={loading}
                    error={error}
                    value={tiktokValue}
                    placeholder='ABC1234567890000000'
                    onChange={(e) => setTiktokValue(e.target.value)}
                    onClickConnectButton={onConnect}
                    stepName={Steps.FACEBOOK_PIXEL}
                    customButtonLabel={Boolean(tiktok_pixel) ? 'Edit' : 'Add'}
                    padding='0px 16px'
                    customIcon={
                        <div className={classes.iconContainer}>
                            <TiktokIcon width={16} height={16} />
                        </div>
                    }
                />
            </>
        </ConversionsConfigStep>
    );
}

const GoogleAnalyticsSection = () => {
    const classes = useStyles();
    const { currentBrand, isProBrand, reloadBrand } = useContext(CurrentBrandContext);
    const { google_analytics } = currentBrand?.connections || {};

    const [googleAnalitycsValue, setGoogleAnalitycsValue] = useState<string>(google_analytics || '');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const onConnect = async () => {
        setLoading(true);
        const response = await updateInBrand(String(currentBrand?.id), String(currentBrand?.slug), googleAnalitycsValue, 'google_analytics')
        if (response) {
            await reloadBrand();
            showToast({ error: false, message: 'Google Analytics Token connected successfully' })
            setError(false);
        } else {
            showToast({ error: true, message: 'Failed to connect your Google Analytics Token' })
            setError(true);
        }
        setLoading(false);
    }

    return (
        <ConversionsConfigStep
            title='Google Analytics Token'
            live={Boolean(google_analytics)}
            description='Add a Google Analytics Token you want to use with Symphony.'
            buttonText='Connect'
            isCompleted={false}
            isSuccessful={!error}
            intercomButtonText={null}
            padding='24px'
            showIntercomButton
            onClickIntercomButton={Intercom.openWhereIsMyGoogleAnalyticsArticle}
        >
            <StyledTextField
                disabled={!isProBrand}
                inputError={Boolean(!googleAnalitycsValue?.length || !isValidGA4Id(googleAnalitycsValue))}
                connected={Boolean(google_analytics)}
                loading={loading}
                error={error}
                value={googleAnalitycsValue}
                placeholder='G-XXXXXXXXXX'
                onChange={(e) => setGoogleAnalitycsValue(e.target.value)}
                onClickConnectButton={onConnect}
                stepName={Steps.FACEBOOK_PIXEL}
                customButtonLabel={Boolean(google_analytics) ? 'Edit' : 'Add'}
                padding='0px 16px'
                customIcon={
                    <div className={classes.iconContainer}>
                        <GoogleIcon width={16} height={16} />
                    </div>
                }
            />
        </ConversionsConfigStep>
    );
}

export const ConnectSection = () => {
    const websiteClasses = useWebsiteStyles();
    const classes = useStyles();

    const { isProBrand } = useContext(CurrentBrandContext);

    function showConnectionDisclaimer() {
        if (isProBrand) {
            return (
                <div className={`connectSectionBanner ${classes.banner}`}>
                    <Subtitle1>Heads up: your connections are added to all of your websites.</Subtitle1>
                    <Body2 color={Colors.textColor}>
                        Updating them here will add them to every published site.
                    </Body2>
                </div>
            )
        } else {
            return (
                <div className={`connectSectionBanner ${classes.banner}`}>
                    <Subtitle1>Integrate your pixels with Symphony Pro.</Subtitle1>
                    <Body2 color={Colors.textColor}>
                        Full access to website analytics, unlimited themes, and more.
                    </Body2>

                    <UpgradeToUnlockCTA
                        highlightedPlan={PlanType.PRO}
                        requiresPaidPlan={true}
                        upgradeSource="Website Editor - Connect Section"
                        buttonText="Upgrade to Pro"
                        upgradePopupCallToAction="Track your website + unlock advanced analytics with Symphony Pro"
                    />
                </div>
            )
        }
    }
    return (
        <div className={`connectSectionComponent ${websiteClasses.customizeWebsiteV2Variable}`}>
            {showConnectionDisclaimer()}
            <TikTokSection />
            <GoogleAnalyticsSection />
            <PixelSection />
        </div>
    );
};
