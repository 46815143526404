import styled from 'styled-components';
import Colors from "modules/Colors"

interface CardProps {
  flexDirection?: string;
  alignItems?: string;
}

interface CardContainerProps {
  width: string;
}

export const Card = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: CardProps) => flexDirection};
  gap: 8px;
  align-items: ${({ alignItems }: CardProps) => alignItems};
`;

export const CardContainer = styled.div`
  display: inline-block;
  flex-grow: 1;
  width: ${({ width }: CardContainerProps) => width};
  padding: 16px 12px 8px;
  border: 1px solid #e5f1eb;
  border-radius: 8px;
  flex-direction: column;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 16px;
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background-color: #8800ff14;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  align-content: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
`;

export const CardTitle = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
  width: auto;
  text-overflow: ellipsis;
`;

export const ValueText = styled.p`
  font-family: DIN;
  font-size: 20px;
  line-height: 32px;
`;

interface SectionTitleContainerProps {
  flexDirection?: string;
  alignItems?: string;
  marginTop?: string;
}

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
`;
interface SectionContainerProps {
  justifyContent?: string;
}

export const SectionContainer = styled.div<SectionContainerProps>`
  display: flex;
  padding: 16px 0;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #E5F1EB;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
`;

export const SectionTitleContainer = styled.div<SectionTitleContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  margin-top: ${({ marginTop }) => marginTop};
  justify-content: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const ConversionsEventContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const ConversionsEventBox = styled.div`
  display: flex;
  padding: 8px;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 8px;
  background: ${Colors.PAPER_BACKGROUND_CONTAINER};
`;

export const StatDetailCard = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #E5F1EB;
  background: ${Colors.PAPER_CONTAINER};
`;

export const LeftStatDetailsContainer = styled.div`
  display: flex;
  width: 380px;
  align-items: center;
  gap: 16px;
`;

export const StatIconBox = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  border-radius: 8px;
  background: ${Colors.PAPER_ACCENT_CONTAINER};
`;

export const StatDetailTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

export const RightStatDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  flex: 1 0 0;
`;

export const StatDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;