import {
  useEffect,
  useState,
  ChangeEvent,
  useContext,
} from "react"
import {
  ArtistImage as ChannelImage,
  ModalPlatformInputContainer,
  PlatformLogoContainer,
  useStyles,
  SearchListContainer,
} from "../../../pages/post-auth/AddArtist/style"
import { TextField, Grid, InputAdornment } from "@material-ui/core"
import { SoundcloudArtistProfile } from "types/global"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import Axios from "helpers/Interceptor"
import isEqual from "lodash/isEqual"
import { track } from "analytics"
import { StyledLabel, TextButton } from "styles/shared"
import { ReactComponent as SoundcloudIcon } from "assets/images/platforms/soundcloud.svg";
import { clsx } from "modules/Utils"
import { StyledButton } from "pages/post-auth/MarketingPage/Components/AddLocationModal/styles"
import * as Sentry from "@sentry/react"
import useScreen from "Hooks/useScreen"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

interface Props {
  closeModal: () => void;
  selected: string | number | undefined | null;
  open: boolean;
  onSave?: () => void;
}

export async function querySoundcloudArtists(query: string) {
  try {
    Sentry.setContext("Soundcloud Search", { query })
    const results = await Axios.get(`/soundcloud/search/artists`, {
      params: {
        q: query
      }
    })


    return results.data
  } catch (error: any) {
    Sentry.captureException(error)
    throw error
  }
}


const SoundcloudConnectv2 = ({
  closeModal,
  selected,
  open,
  onSave = async () => { }
}: Props) => {
  const { mobileView, smallView } = useScreen();
  const classes = useStyles()
  const { currentBrand } = useContext(CurrentBrandContext);
  const [loading, setLoading] = useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = useState("")

  const [results, setResults] = useState<SoundcloudArtistProfile[]>([])

  const [error, setError] = useState(false)
  const showConnectButton = !isEqual(results, [selected])


  // used to determine if the user submitted a query already
  const [userSubmittedSearch, setUserSubmittedSearch] = useState(false)

  const getModalContainerHeight = (profiles: number) => {
    if (profiles > 2) return "300px"
    return "100%"
  }

  const containerHeight = smallView ? '100%' : getModalContainerHeight(results.length)

  const pullResults = async (value: string) => {
    setUserSubmittedSearch(true);
    setLoading(true)

    if (!value.trim().length) {
      setResults([]);
      return;
    }

    let data = [];
    let error = null;

    // Extract the artist username from the Soundcloud URL
    const soundcloudUsername = value.match(/soundcloud\.com\/([^/]+)/)?.[1];

    try {
      const results = await querySoundcloudArtists(soundcloudUsername || value);
      data = results?.data || [];
    } catch (e) {
      error = e;
    }


    if (error) {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      setResults(data.map((audiomackArtist: SoundcloudArtistProfile) => ({
        ...audiomackArtist,
        id: audiomackArtist.id
      })));
      setLoading(false);

    }
    // setUserSubmittedSearch(false)

  }

  useEffect(() => {
    if (results.length && !textFieldValue) {
      setResults([])
    }
  }, [textFieldValue])

  const updateInBrand = async (channel: SoundcloudArtistProfile) => {
    setLoading(true)
    if (!currentBrand) return

    try {
      Sentry.setContext('Soundcloud Connect', { currentBrand, channel })
      track('Linked Soundcloud Profile')

      await Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: 'soundcloud',
        value: channel,
      })

      await onSave()
      closeModal()
      setError(false)
      setLoading(false)

    } catch (err) {
      Sentry.captureException(err)
      console.log('error creating brand', err)
      setError(true)
      setLoading(false)
    }
  }

  const handleOnClick = (profile: SoundcloudArtistProfile) => () => {
    updateInBrand(profile)
  }

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setTextFieldValue(value)
  }

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])

  const renderEndAdornment = () => {
    if (loading) {
      // Assuming LottieAnimation is a component or element that renders your Lottie animation
      return <InputAdornment position="end">
        <Lottie
          height={24}
          width={24}
          options={{
            loop: true,
            autoplay: true,
            animationData: SymphonyLoadingLottie,
          }}
        />
      </InputAdornment>;
    }

    return <TextButton onClick={() => pullResults(textFieldValue)}>
      Search
    </TextButton>;
  }

  let mainSubtitle = `Search or enter a URL to ${`${currentBrand?.name}'s` || "your primary"} SoundCloud profile.`
  let mainPlaceholder = "Search or paste a SoundCloud URL"

  const getHelperText = (args: {
    value: string
    error: boolean
    results: SoundcloudArtistProfile[]
    loading: boolean
  }) => {
    const { value, error, results, loading } = args

    if (error) return "It looks like we're having an issue finding your profile. Please try again later."
    if (!results.length && value && !loading && userSubmittedSearch) {
      return `No artists found for "${textFieldValue}".`
    }
    return undefined
  }

  const helperText = getHelperText({
    value: textFieldValue,
    error,
    results,
    loading,
  })

  return (
    <div className="soundcloudConnectv2Component" style={{ width: '100%' }}>
      <ModalPlatformInputContainer>
        <PlatformLogoContainer
          width="48px"
          height="48px"
        >
          <SoundcloudIcon width="48px" height="48px" />
        </PlatformLogoContainer>
        <form style={{
          width: '100%'
        }}
          onSubmit={(e) => {
            e.preventDefault()
            pullResults(textFieldValue)
          }}>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: renderEndAdornment(),
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder={mainPlaceholder}
            helperText={helperText}
            error={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />
        </form>
      </ModalPlatformInputContainer>

      {/* NOTE: this banner show when soundcloud is not available */}
      {/* {IS_NOT_AVAILABLE &&
          <WarningContainer>
            <WarningLabel>
                SoundCloud is currently having issues with their connection.
                We're working with them to resolve this, and it should be good to go soon. 
            </WarningLabel>
          </WarningContainer>
        } */}

      {userSubmittedSearch && textFieldValue && !loading &&
        <SearchListContainer height={containerHeight}>
          <Grid container direction="column">
            {!loading && results.map((profile: SoundcloudArtistProfile, index) => {
              const {
                id,
                name,
                thumbnail_url,
                followers,
                url_slug
              } = profile
              return (
                <Grid
                  item
                  key={id}
                  className={clsx(
                    classes.channelItemRow,
                    index === 0 && classes.dividerBottom,
                    index !== 0 && index !== 1 && classes.dividerTop
                  )}
                >
                  <Grid
                    className="cursor-pointer"
                    onClick={handleOnClick(profile)}
                    spacing={showConnectButton && smallView ? 2 : 3}
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item className={classes.channelImage}>
                      <ChannelImage
                        widthStyle={mobileView ? "48px" : "56px"}
                        heightStyle={mobileView ? "48px" : "56px"}
                        alt={`${name}-url-image`}
                        src={thumbnail_url}
                      />
                    </Grid>
                    <Grid
                      item
                      className={clsx(showConnectButton && smallView && "w-24")}
                      xs={showConnectButton && !smallView || !showConnectButton}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <StyledLabel fontWeight={500} fontSize={16}>
                            {name}
                          </StyledLabel>
                        </Grid>
                        <Grid item>
                          <StyledLabel
                            fontSize={12}
                            fontWeight={400}
                            color="#707070"
                          >
                            {followers.toLocaleString('en')} Follower{followers === 1 ? '' : 's'}
                          </StyledLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    {showConnectButton && (
                      <Grid item className={classes.connectButtonContainer}>
                        <Grid container direction="row-reverse">
                          <StyledButton>Connect</StyledButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </SearchListContainer>
      }
    </div>
  )
}

export default SoundcloudConnectv2
