/* eslint-disable no-throw-literal */
import { Dispatch, SetStateAction } from "react"
import { queryFB } from 'helpers/FB';
import { AdAccountPageType } from "types/global";
import { getTosStatus } from "../../utils";
import { PreConnectionConfigs } from "../../utils/fbCampaigns";

export const confirmConnectionSettings = async (args: {
  fbAdAccount?: AdAccountPageType;
  setPreconnectConfigurations: Dispatch<
    SetStateAction<PreConnectionConfigs | null>
  >;
}) => {
  const { fbAdAccount, setPreconnectConfigurations } = args;

  if (!fbAdAccount || !fbAdAccount.id) return null;

  const results = await queryFB(`/${fbAdAccount.id}`, {
    params: {
      fields:
        'tos_accepted,offsite_pixels_tos_accepted,user_tos_accepted,business',
    },
  });

  const toDo = getTosStatus(fbAdAccount, results);
  setPreconnectConfigurations(toDo);
};