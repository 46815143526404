import Badge from "components/shareable/Badge";
import Colors from "modules/Colors";
import { ReactComponent as ConversionsIcon } from "assets/images/campaignDetails/Boost.svg";
import { Caption } from "components/shareable/Typography";
import { RowContainer } from "./CampaignCard/styles";


export default function SymphonyConversionsBadge(props: {
    text?: string
}) {

    const {
        text = "Using Symphony Conversions"
    } = props
    return (<Badge backgroundColor={Colors.PAPER_ACCENT_CONTAINER}>
        <RowContainer>
            <ConversionsIcon height={18} width={18} />
            <Caption color={Colors.textColor}>{text}</Caption>
        </RowContainer>
    </Badge>
    )
}