import { CSSProperties, useContext, useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import UpgradeToProChip from "./UpgradeToProChip";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";

interface PopoverCampaignAnnotationsProps {
    className?: string;
    style?: CSSProperties;
    title?: string;
    showAnnotations: any;
    setShowAnnotations: (show: any) => void;
    upgradeSource: string;
}

const showAnnotationsInit = {
    campaigns: false,
    instagram: false,
    spotify: false
}

let launchAnnotationsFeature = false


const PopoverCampaignAnnotations = ({
    className = '',
    style = {},
    title = 'Views',
    showAnnotations = showAnnotationsInit,
    setShowAnnotations,
    upgradeSource
}: PopoverCampaignAnnotationsProps) => {

    const { isProBrand } = useContext(CurrentBrandContext);
    const { handleOpenModal } = useContext(UpgradeModalContext);

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setShowAnnotations(showAnnotationsInit)
    }, [isProBrand])


    if (!launchAnnotationsFeature) {
        return null
    }


    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheckboxChange = (key: keyof typeof showAnnotationsInit) => {
        const showAnnotationsLocal = {
            ...showAnnotations,
            [key]: !showAnnotations[key]
        }

        setShowAnnotations(showAnnotationsLocal);
    };

    const renderPopoverContents = () => {
        return (
            <div className="formControlContainer" style={sty.formControlContainer}>
                {isProBrand ?
                    <UpgradeToProChip
                        className="PopoverCampaignAnnotationsProChip"
                        type="label"
                        withMargin={false}
                        mainContainerProps={{
                            width: 'auto',
                            flexShrink: '0'
                        }}
                    />
                    :
                    <div className="upgradeToProTextContainer" style={{ ...sty.upgradeToProTextContainer, borderBottom: '1px solid #eee', paddingBottom: 8, marginBottom: 8 }}>
                        <div className="upgradeToProText" style={sty.upgradeToProText}>Upgrade to Pro to see annotations</div>
                        <button
                            className="bg-purple-600 text-white px-4 py-1 rounded-md text-sm hover:bg-purple-700"
                            onClick={() => handleOpenModal({ source: upgradeSource })}
                        >
                            Upgrade to Pro
                        </button>
                    </div>
                }
                <div className="checkboxAnnotationsContainer" style={{ ...sty.checkboxAnnotationsContainer, ...(!isProBrand ? sty.overlayDisable : {}) }}>
                    <div className="formControlContainerItem" style={sty.formControlContainerItem}>
                        <Checkbox
                            checked={showAnnotations.campaigns}
                            onChange={() => handleCheckboxChange('campaigns')}
                            color="default"
                            sx={{ padding: 0 }}
                        />
                        <div>Campaigns</div>
                    </div>
                    <div className="formControlContainerItem" style={sty.formControlContainerItem}>
                        <Checkbox
                            checked={showAnnotations.instagram}
                            onChange={() => handleCheckboxChange('instagram')}
                            color="default"
                            sx={{ padding: 0 }}
                        />
                        <div>Instagram Posts</div>
                    </div>
                    <div className="formControlContainerItem" style={sty.formControlContainerItem}>
                        <Checkbox
                            checked={showAnnotations.spotify}
                            onChange={() => handleCheckboxChange('spotify')}
                            color="default"
                            sx={{ padding: 0 }}
                        />
                        <div>Spotify Releases</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={`popoverCampaignAnnotationsComponent ${className}`}
            style={{ ...sty.popoverCampaignAnnotationsComponent, ...style }}
        >
            <div
                className="optionsContainerOuter"
                style={sty.optionsContainerOuter}
                onClick={handleClick}
            >
                <div style={{ marginRight: 8 }}>
                    {title}
                </div>
                <KeyboardArrowDownOutlined />
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                {renderPopoverContents()}
            </Popover>
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    popoverCampaignAnnotationsComponent: {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content'
    },
    optionsContainerOuter: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '9px 12px',
        borderRadius: 4
    },
    formControlContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        borderRadius: 4,
        gap: 8
    },
    formControlContainerItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    checkboxAnnotationsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    overlayDisable: {
        pointerEvents: 'none',
        opacity: 0.25,
        width: '100%',
        backgroundColor: 'white'
    },
    upgradeToProText: {
        fontSize: 14,
        fontWeight: 400,
    },
    upgradeToProTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    }
}

export default PopoverCampaignAnnotations
