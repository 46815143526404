import { clsx } from 'modules/Utils';
import { BackArrowImage, BackButtonContainer, BackToDashboardContainer, BackButtonText } from "../style";
import GrayArrow from "assets/images/arrow-right-icon.svg";

interface BackButtonProps {
    className?: string
    /** Handler for back button click */
    onBack: () => void;
    /** Whether to show the "Back to dashboard" text */
    text?: string;
}

/**
 * Reusable back button component for onboarding flows
 */
const BackButton = ({ className, onBack, text }: BackButtonProps) => {
    return (
        <BackToDashboardContainer className={clsx('backButtonComponentBackToDashboard', className)}>
            <BackButtonContainer onClick={onBack}>
                <BackArrowImage src={GrayArrow} />
            </BackButtonContainer>
            {text && <BackButtonText className="backToDashboardText">{text}</BackButtonText>}
        </BackToDashboardContainer>
    );
};

export default BackButton; 