import React from 'react';
import { IconHolder, SocialIconProps } from './styles';
import { defaultMaxListeners } from 'events';

const FacebookIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    let fbBlue = iconColor ? "" : "#1977F3";
    iconColor = iconColor ?? "#1977F3";
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_24420_14149)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M22.1507 35.8589C21.4473 35.9534 20.7293 36.0022 20 36.0022C19.8872 36.0022 19.7747 36.001 19.6625 35.9987C19.7747 36.001 19.8873 36.0021 20.0001 36.0021C20.7295 36.0021 21.4473 35.9525 22.1507 35.8589ZM22.4999 35.808V24.6282H26.2282L26.937 20.0021H22.4999V17.0006C22.4999 15.7361 23.1187 14.5008 25.1077 14.5008H27.126V10.5632C27.126 10.5632 25.2945 10.2505 23.5439 10.2505C19.8899 10.2505 17.5003 12.4645 17.5003 16.4763V20.0021H13.4368V24.6282H17.5003V35.8081C9.8511 34.608 4 27.9884 4 20.0022C4 11.1656 11.1634 4.0022 20 4.0022C28.8366 4.0022 36 11.1656 36 20.0022C36 27.9883 30.149 34.6079 22.4999 35.808Z" fill={iconColor} />
                </g>
                <defs>
                    <clipPath id="clip0_24420_14149">
                        <rect width="32" height="32" fill="white" transform="translate(4 4)" />
                    </clipPath>
                </defs>
            </svg>

          

        </IconHolder>
    );
};

export default FacebookIcon;