import { makeStyles } from '@material-ui/core';
import Colors from "modules/Colors";

export const useStyles = makeStyles(() => ({
  warningBanner: {
    backgroundColor: Colors.PAPER_ERROR_CONTAINER,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    alignItems: 'left',
    gap: '8px',
    borderRadius: '8px',
  },
}));
