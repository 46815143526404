import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";


interface MainContainerProps {
  padding: string,
  flexDirection?: string,
}

interface OverviewActionProps { 
  color?: string,
  cursor?: string,
}

export const useStyles = makeStyles(() => ({
  actionIcon: {
    width: '24px',
    height: '24px',
  },
  badgeIcon: {
    width: '18px',
    height: '18px',
  },
  marginBadge: {
    marginLeft: '-14px',
  },
  absolute: {
    position: 'relative',
  },
  svgGray: {
    '& path': {
      stroke: '#b2b2b2',
    }
  },
  menuItemSvgGray: {
    '& path': {
      stroke: '#585858',
    }
  },
}));

export const OverviewCampaignCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  background: #FFFFFF;
  border-radius: 12px;
`;

export const OverviewCampaignCardContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 24px;
  gap: 16px;
  background: #FFFFFF;
`;

export const BackArrowImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const OverviewTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  flex-grow: 1;
  @media (max-width: 430px) {
    gap: 16px;
  }
`;

export const OverviewSongImage = styled.img<{ size?: string | null; borderRadius?: string | null; isBorder?: boolean | null }>`
  width: ${props => props.size || '72px'};
  height: ${props => props.size || '72px'};
  border-radius: ${props => props.borderRadius || '16px'};
  object-fit: cover;
  border: ${props => props.isBorder ? '1px solid #E5E5E5' : 'none'};
  @media (max-width: 430px) {
    width: ${props => props.size || '40px'};
    height: ${props => props.size || '40px'};
    border-radius: ${props => props.borderRadius || '8px'};
  }
`;

export const DefaultOverviewSongImage = styled.div`
  border-radius: 16px;
  img {
    height: 72px;
    height: 72px;
  }

  @media (max-width: 430px) {
    img {
      width: 40px;
      height: 40px;
    }
    border-radius: 8px;
  }
`;

export const OverviewInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex-grow: 1;
`;

export const OverviewText = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #707070;
`;

export const SongName = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #000000;
  align-self: stretch;
  flex-grow: 0;
`;

export const OverviewBadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: flex-start;
  gap: 4px;
`;

export const OverviewActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

interface BadgeTextProps {
  color?: string;
}
export const BadgeText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${({ color }: BadgeTextProps) => color || "#000000"};
`;

export const OverviewAction = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${({ color }: OverviewActionProps) => color || "#8800FF"};
  cursor: ${({ cursor }: OverviewActionProps) => cursor || "pointer"};
`;

export const BackArrowActionsContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: MainContainerProps) => flexDirection || "row"};
  padding: ${({ padding }: MainContainerProps) => padding};
  background: #F5F3F6;
  gap: 24px;
`;