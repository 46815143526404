import { IconContainer, MainContainer, TextContainer, useStyles } from './styles';
import SecondaryButton from 'components/shareable/SecondaryButton';
import { ReactComponent as WarningIcon } from 'assets/images/campaignDetails/warning.svg';
import Colors from "modules/Colors";
import { Body1 } from 'components/shareable/Typography';
import useScreen from 'Hooks/useScreen';
import { daysUntilRelease, daysUntilReleaseText } from './utils';
import { Severity } from 'modules/Const';
import ButtonSymphony from 'components/shareable/ButtonSymphony';

interface Props {
  songName: string;
  releaseDate: string | Date;
  handleClickAdd: () => void;
}

const WarningNoReleaseUpcCard = ({
  songName,
  releaseDate,
  handleClickAdd,
}: Props) => {
  const classes = useStyles();
  const { mobileView } = useScreen();

  const daysLeft = daysUntilRelease(releaseDate);
  const daysText = daysUntilReleaseText(releaseDate)
  const severity = daysLeft < 0 ? Severity.ERROR : Severity.WARNING;
  const text = severity === Severity.ERROR ?
    'Your Pre-Save campaign is ready to be launched. Please add the release’s UPC / ISRC to have your fans save your release.' :
    `Don't forget to add the UPC / ISRC to your pre-save campaign for "${songName}", releasing ${daysText}.`;

  return (
    <MainContainer
      severity={severity}
      borderRadius={mobileView ? '0px' : '8px'}
      flexDirection={mobileView ? 'column' : 'row'}
      alignItems={mobileView ? 'flex-end' : 'center'}
      gap={mobileView ? '12px' : '24px'}
    >
      <TextContainer
        gap={mobileView ? '16px' : '8px'}
      >
        <IconContainer>
          <WarningIcon
            width="24px"
            height="24px"
            className={severity === Severity.ERROR ? classes.svgError : classes.svgWarning}
          />
        </IconContainer>
        <Body1 color={Colors.textColor}>{text}</Body1>
      </TextContainer>
      <ButtonSymphony
        className="cardButtonUPC"
        onClick={handleClickAdd}
        variant="outlined"
      >
        Add UPC or ISRC
      </ButtonSymphony>
    </MainContainer>
  );
}

export default WarningNoReleaseUpcCard;