import { clsx } from 'modules/Utils';
import { NoArtistLable, NoArtistItem, NoArtistLine, CreateNewArtistImage, CreateNewBrandLabel, ArtistNameBoldLabel, CreateNewArtistcontainer, StyledLabel } from "../../style";
import CreateNewBrand from 'assets/images/CreateNewBrand.png';

type GlobalWindow = Window & {
  Intercom: (type: string, article?: number) => void;
};

interface NotFoundArtistLabelProps {
  className?: string
  onClickCreateNewProfile: () => void;
  searchStr: string;
}

const NotFoundArtistLabel = ({
  className,
  onClickCreateNewProfile,
  searchStr
}: NotFoundArtistLabelProps) => {
  const handleClickLearnHow = () => {
    (window as unknown as GlobalWindow).Intercom("showArticle", 5678366);
  };

  return (
    <div className={clsx("notFoundArtistLabelComponent", className)}>
      <p>
        <StyledLabel fontSize={16}>
          Can't find your profile? Try pasting in your Spotify profile URL.
        </StyledLabel>
      </p>
      <p className="text-center">
        <StyledLabel
          className="cursor-pointer"
          fontSize={16}
          fontWeight={500}
          onClick={handleClickLearnHow}
          color="#8800ff"
        >
          Learn how
        </StyledLabel>
      </p>
      <NoArtistLine />
      <NoArtistItem>
        <NoArtistLable>
          Or create a new profile{" "}
          <ArtistNameBoldLabel>"{searchStr}"</ArtistNameBoldLabel>
        </NoArtistLable>
      </NoArtistItem>
      <NoArtistLine />
      <CreateNewArtistcontainer
        onClick={onClickCreateNewProfile}
      >
        <CreateNewArtistImage src={CreateNewBrand} />
        <CreateNewBrandLabel>
          Create a new profile:&nbsp;
          <ArtistNameBoldLabel>{searchStr}</ArtistNameBoldLabel>
        </CreateNewBrandLabel>
      </CreateNewArtistcontainer>
    </div>
  );
};

export default NotFoundArtistLabel;
