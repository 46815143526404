import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      borderRadius: "8px",
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
      },
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ADB7BC',
      },
      '&:hover fieldset': {
        borderColor: '#ADB7BC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8800FF',
      },
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
  formHelperText: {
    "&.MuiFormHelperText-root": {
      fontSize: 16
    }
  },
}));

interface ModalContainerProps {
  width?: string
  padding?: string
}

interface OutlinedButtonProps {
  width?: string
}
interface CardCampaignDetailsProps {
  flexDirection?: string,
}
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 16px;
`;
export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
  marginLeft?: string;
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 678px;
  width: ${({ width }: ModalContainerProps) => width || 'auto'};
  margin: auto;
  padding: ${({ padding }: ModalContainerProps) => padding || '32px'};
  gap: 32px;
`;

export const DuplicateOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const DuplicateCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border: 1px solid #EDECF2;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const CardImage = styled.img`
  border-radius: 16px;
  width: 50px;
  height: 50px;
`;

export const CardTitle = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  align-items: center;
  color: #000000;
`;

export const CardSubTitle = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const CardCampaign = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px 16px 16px;
  gap: 32px;
  background: #FFFFFF;
  border: 1px solid #EDECF2;
  border-radius: 16px;
  z-index: 1;
  overflow-wrap: break-word;
  width: 100%;
`;

export const CardCampaignHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
  gap: 24px;
`;

export const CardCampaignHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const CardCampaignHeaderInfoArtist = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const CardCampaignHeaderInfoRecord = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
`;

export const RecordImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 16px;
`;

export const CardCampaignDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction:  ${({ flexDirection }: CardCampaignDetailsProps) => flexDirection || 'row'};;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  overflow-wrap: break-word;
`;

export const CardCampaignDetailsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  overflow-wrap: break-word;
`;

export const CardCampaignDetailsPlatforms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  overflow-wrap: break-word;
`;

export const GenreTitleLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`;

export const GenreLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

export const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  opacity: 0.64;
`;

export const NextButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  background: #8800FF;
  border-radius: 48px;
  width: 100%;
  color: #FFFFFF;
  cursor: pointer;
`;

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
`;

export const OutlinedButton = styled.button`
  width: ${({ width }: OutlinedButtonProps) => width || "120px"};
  border: 1px solid rgba(136, 0, 255, 1);
  background-color: "rgba(255, 0, 0, 0.16)";
  color: rgba(136, 0, 255, 1);
  font-size: 16px;
  border-radius: 48px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: DIN;
  text-transform: none;
  height: 40px;
  font-weight: 600;
`

export const ErrorBannerMainContainer = styled.div`
  border-radius: 8px;
  padding: 18px 16px;
  display: flex;
  alignItems: center;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.16);
  gap: 24px;
  justify-content: space-between;
`