import {
  FlexContainer,
  DummyDiv,
} from "./styles"
import useScreen from "Hooks/useScreen"
import ButtonSymphony from "../ButtonSymphony"

const BUTTON_MIN_WIDTH = 100
const BACK = "Back"
const NEXT = "Next"

export interface StepButtonsProps {
  className?: string
  width?: string
  loadingNextButton?: boolean
  backButtonWidth?: string
  nextButtonWidth?: string
  showBackButton?: boolean
  showNextButton?: boolean
  disableBackButton?: boolean
  disableNextButton?: boolean
  nextButtonLabel?: string
  backButtonLabel?: string
  handleClickNextButton?: () => void
  handleClickBackButton?: () => void
}

const StepButtons = ({
  className = '',
  width,
  loadingNextButton,
  backButtonWidth,
  nextButtonWidth,
  showBackButton = true,
  showNextButton = true,
  disableBackButton,
  disableNextButton,
  nextButtonLabel,
  backButtonLabel,
  handleClickNextButton,
  handleClickBackButton,
}: StepButtonsProps) => {
  const { mobileView } = useScreen();

  return (
    <FlexContainer
      className={`stepButtonsComponent ${className}`}
      width={width || "100%"}
      paddingBottom={mobileView ? "8px" : "16px"}
      paddingTop={mobileView ? "8px" : "16px"}
      alignItems="center"
      paddingRight={mobileView ? "16px" : undefined}
      paddingLeft={mobileView ? "16px" : undefined}
      justifyContent={mobileView ? "space-between" : "space-evenly"}
      borderTop="1px solid #C8C7CC"
    >
      {showBackButton ?
        <ButtonSymphony
          className="stepButtonBack"
          variant="outlined"
          onClick={handleClickBackButton}
          width={backButtonWidth}
          disabled={disableBackButton}
          style={{ minWidth: BUTTON_MIN_WIDTH }}
        >
          {backButtonLabel || BACK}
        </ButtonSymphony>
        :
        <DummyDiv width={backButtonWidth} />
      }
      {showNextButton ?
        <ButtonSymphony
          className="stepButtonNext"
          onClick={handleClickNextButton}
          style={{ minWidth: BUTTON_MIN_WIDTH }}
          width={nextButtonWidth}
          disabled={disableNextButton || loadingNextButton}
          isLoading={loadingNextButton}
        >
          {nextButtonLabel || NEXT}
        </ButtonSymphony>
        :
        <DummyDiv width={nextButtonWidth} />
      }
    </FlexContainer>
  )
}

export default StepButtons
