import {
  BackArrowActionsContainerMobile,
  OverviewAction,
  OverviewActionsContainer,
  OverviewBadgesContainer,
  OverviewCampaignCardContainer,
  OverviewCampaignCardContainerMobile,
  OverviewInfoContainer,
  OverviewSongImage,
  OverviewText,
  OverviewTitleContainer,
  SongName,
  useStyles,
} from "./styles";
import DefaultImg from "assets/images/music-key-green-bg.svg";
import { ReactComponent as ContextualMenuIcon } from "assets/images/contextualMenu.svg";
import CustomMenu, { MenuElement, ShowTooltip } from "components/shareable/CustomMenu/index";
import { ReactComponent as BackArrowIcon } from "assets/images/BackArrow.svg";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as BoostIcon } from "assets/images/campaignDetails/Boost.svg"
import { ReactComponent as CalendarIcon } from "assets/images/calendar-email-campaign.svg";
import { ReactComponent as LinkIcon } from "assets/images/campaignDetails/link.svg";
import { ReactComponent as BulletListIcon } from "assets/images/campaignDetails/bullet-list.svg";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import BadgeCampaignTypeContainer from "../BadgeCampaignTypeContainer/BadgeCampaignTypeContainer";
import CampaignStatusBadge from "../CampaignStatusBadge";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useContext } from "react";

interface Props {
  goBack: () => void;

  name: string;
  thumbnail?: string;
  campaignStatus?: string;
  campaignStatusLabel?: string;
  isLoading?: boolean;
  handleEditPresaveDesign: () => void;
  handleEditReleaseDate: () => void;
  handleEditUpcIsrc: () => void;
  handleEditBoost: () => void;
  handleEditPlatforms: () => void;

  handleEditUrl: () => void;

  isFinalized?: boolean;
}

const PresaveOverviewCampaignCard = ({
  isLoading,
  goBack,
  name,
  thumbnail,
  campaignStatus = "pending",
  campaignStatusLabel,
  handleEditPresaveDesign,
  handleEditBoost,
  handleEditPlatforms,
  handleEditReleaseDate,
  handleEditUpcIsrc,
  handleEditUrl,
  isFinalized,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isBrandAdmin } = useContext(CurrentBrandContext);
  const isMdWidthScreen = useMediaQuery(theme.breakpoints.down(1024));

  const campaignName = name;

  const active = campaignStatus !== "finished";
  const tooltipText = isFinalized ? "You can't edit once your campaign is finished." : ""

  const menuItems: MenuElement[] = [
    {
      icon: <EditIcon className={classes.svgGray} />,
      text: "Edit Presave Design",
      show: active,
      onClick: () => handleEditPresaveDesign(),
      disabled: isFinalized,
      tooltip: tooltipText,
    },
    {
      icon: <CalendarIcon className={classes.svgGray} />,
      text: "Edit Release Date",
      show: active,
      onClick: () => handleEditReleaseDate(),
      disabled: isFinalized,
      tooltip: tooltipText,
    },
    {
      icon: <LinkIcon className={classes.svgGray} />,
      text: "Edit UPC/ISRC",
      show: active,
      onClick: () => handleEditUpcIsrc(),
      disabled: isFinalized,
      tooltip: tooltipText,
    },
    {
      icon: <BoostIcon className={classes.svgGray} />,
      text: "Edit Boosts",
      show: active,
      onClick: () => handleEditBoost(),
      disabled: isFinalized,
      tooltip: tooltipText,
    },
    {
      icon: <BulletListIcon className={classes.svgGray} />,
      text: "Edit Platforms",
      show: active,
      onClick: () => handleEditPlatforms(),
      disabled: isFinalized,
      tooltip: tooltipText,
    },
    {
      icon: <LinkIcon className={classes.svgGray} />,
      text: "Edit URL",
      show: active,
      onClick: () => handleEditUrl(),
      disabled: isFinalized,
      tooltip: "",
    }
  ];

  const campaignImage = () => {
    return (
      <OverviewSongImage
        src={thumbnail || DefaultImg}
      />
    )
  };

  const getMenuItemsByStatus = (status: string) => {
    return menuItems;
  };

  if (isMdWidthScreen) {
    return (
      <OverviewCampaignCardContainerMobile>
        <BackArrowActionsContainerMobile>
          <BackArrowIcon onClick={goBack} />
          <OverviewActionsContainer>
            {!isLoading && isBrandAdmin && (
              <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
                <OverviewAction
                  color={isFinalized ? "#b2b2b2" : "#8800FF"}
                >
                  <ShowTooltip tooltip={tooltipText}>
                    <Grid container>
                      Edit
                      <ContextualMenuIcon
                        className={`${classes.actionIcon} ${isFinalized && classes.svgGray}`}
                      />
                    </Grid>
                  </ShowTooltip>
                </OverviewAction>
              </CustomMenu>
            )}
          </OverviewActionsContainer>
        </BackArrowActionsContainerMobile>
        <OverviewTitleContainer>
          {campaignImage()}
          <OverviewInfoContainer>
            <OverviewText>Overview</OverviewText>
            <SongName>{campaignName}</SongName>
            <OverviewBadgesContainer>
              <BadgeCampaignTypeContainer campaignType="pre_save" />
              {!isLoading && (
                <CampaignStatusBadge
                  campaignStatus={campaignStatus}
                  campaignStatusLabel={campaignStatusLabel}
                />
              )}
            </OverviewBadgesContainer>
          </OverviewInfoContainer>
        </OverviewTitleContainer>
      </OverviewCampaignCardContainerMobile>
    );
  }

  return (
    <OverviewCampaignCardContainer>
      <BackArrowIcon onClick={goBack} />
      <OverviewTitleContainer>
        {campaignImage()}
        <OverviewInfoContainer>
          <OverviewText>Overview</OverviewText>
          <SongName>{campaignName}</SongName>
          <OverviewBadgesContainer>
            <BadgeCampaignTypeContainer campaignType="pre_save" />
            {!isLoading && (
              <CampaignStatusBadge
                campaignStatus={campaignStatus}
                campaignStatusLabel={campaignStatusLabel}
              />
            )}
          </OverviewBadgesContainer>
        </OverviewInfoContainer>
      </OverviewTitleContainer>
      {!isLoading && isBrandAdmin && (
        <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
          <OverviewAction
            color={isFinalized ? "#b2b2b2" : "#8800FF"}
          >
            <ShowTooltip tooltip={tooltipText}>
              <Grid container>
                Edit
                <ContextualMenuIcon
                  className={`${classes.actionIcon} ${isFinalized && classes.svgGray}`}
                />
              </Grid>
            </ShowTooltip>
          </OverviewAction>
        </CustomMenu>
      )}
    </OverviewCampaignCardContainer>
  );
};

export default PresaveOverviewCampaignCard;
