import { CSSProperties } from "react"

const Indicator = ({
    selected
}: {
    selected?: boolean | undefined
}) => {
    let indicatorComponentStyles = {...sty.indicatorComponent};

    if(selected) {
        indicatorComponentStyles.opacity = 1
    } else {
        indicatorComponentStyles.opacity = 0
    }

    return (
        <div
            className="indicatorComponent"
            style={indicatorComponentStyles}
        />
    )
}

const sty: Record<string, CSSProperties> = {
    indicatorComponent: {
        position: 'absolute',
        backgroundColor: '#8800FF',
        left: 0,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        width: 6,
        height: 32,
        transition: 'opacity 0.3s ease-in-out',
    }
}

export default Indicator