import BadgeContainer from './BadgeContainer';
import PLATFORMS_BADGE_ICONS from './BADGE_ICONS';
import { CAMPAIGN_TYPE_MAPPINGS } from 'modules/Const';

interface Props {
  className?: string
  campaignType: string
  platforms?: string[]
}

const BadgeCampaignTypeContainer = ({
  className,
  campaignType,
  platforms,
}: Props) => {
  const mappingData = CAMPAIGN_TYPE_MAPPINGS[campaignType]
  if(!campaignType || !mappingData) {
    return null 
  }
  const text = mappingData.title as string;

  const badgeIcons = mappingData.badgeIcons as string[];
  const showIcons = platforms || badgeIcons;
  const imageUrls = showIcons.map((platform) => PLATFORMS_BADGE_ICONS[platform]);

  return <BadgeContainer className={className} text={text} imageUrls={imageUrls} />

}

export default BadgeCampaignTypeContainer;
