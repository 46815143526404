import { Theme, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import { MEDIA_BREAKPOINTS } from 'types/global'
import Colors from "modules/Colors"

interface VideoCoverProps {
  background: string
}

interface VideoButtonProps {
  background?: string
}

interface ModalFooterProps {
  flexDirection: string
  alignItems?: string
  justifyContent?: string
}

export const useStyles = makeStyles((theme: Theme) => ({
  svgWhite: {
    height: '24px',
    width: '24px',
    '& path': {
      stroke: Colors.PAPER_CONTAINER,
    },
  },
  svgSecondary: {
    height: '24px',
    width: '24px',
    '& path': {
      stroke: Colors.PAPER_DARK_CONTAINER,
    },
  },
}))

export const VideoContainer = styled.div`
  width: 100%;
  height: 80%;
  max-height: 420px;
  min-height: 420px;
  position: relative;
  display: flex;
  justify-content: center;
  background: ${Colors.PAPER_DARK_CONTAINER};
  ${MEDIA_BREAKPOINTS.mobileView} {
    height: fit-content;
  }
`;

export const Video = styled.video`
  width: auto;
  height: 100%;
  object-fit: cover;
  max-height: 420px;
`;

export const VideoCover = styled.div<VideoCoverProps>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  :hover {
    background: ${Colors.LIGHT_VIDEO_HOVER_LAYOUT};
  }
  :hover > div {
    background: ${Colors.VIDEO_BUTTON_CONTAINER};
    color: ${Colors.PAPER_CONTAINER};
  }
`;

export const VideoButton = styled.div<VideoButtonProps>`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  cursor: pointer;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${Colors.VIDEO_BUTTON_CONTAINER};
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  margin: 8px;
  cursor: pointer;
  ${MEDIA_BREAKPOINTS.mobileView} {
    background: none;
  }
`;

export const ModalFooter = styled.div<ModalFooterProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: 32px;
  gap: 16px;
`;

export const ProgressContainer = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: none;
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`

export const MobileVideoContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.PAPER_DARK_CONTAINER};
`;