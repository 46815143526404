import { PurpleContainer, PlatformsIconsContainer, CampaignTypeText, IconContainer } from './styles';

interface BadgeContainerProps {
    className?: string;
    text: string;
    imageUrls?: string[];
}

const BadgeContainer = ({
    className,
    text,
    imageUrls
}: BadgeContainerProps) => {
    return (
        <PurpleContainer className={className}>
            {imageUrls &&
                <PlatformsIconsContainer>
                    {imageUrls.map((url, index) => (
                        <IconContainer key={`${url}-${index}`}>
                            <img src={url} alt={`badge-image-${index}`} />
                        </IconContainer>
                    ))}
                </PlatformsIconsContainer>
            }
            <CampaignTypeText>
                {text}
            </CampaignTypeText>
        </PurpleContainer>
    );
};

export default BadgeContainer;
