import { useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { ImpactStatsOption } from '../CampaignDetails/ImpactStats/utils';
import { ConnectorIndicatorTypes, FollowersChartData, Platforms, StreamsChartData } from 'types/global';
import ShazamIcon from "assets/images/shazam.svg";
import InstagramIcon from "assets/images/insta.svg";
import SpotifyIcon from "assets/images/streaming/square-icons/spotify-sqr.svg";
import Axios from 'helpers/Interceptor';

export interface PlatformData {
    percentageChange: number;
    netChange: number;
    currentValue: number;
    dailyStats: FollowersChartData[] | StreamsChartData[];
}
export interface AttributionData {
    description: ImpactStatsOption;
    data: PlatformData;
}

interface UseImpactDataProps {
    mainImpactStats: ImpactStatsOption[];
    brandId: number;
    brandSlug: string;
    contentSlug?: string;
    campaignId?: string | number;
    startDate?: string;
    endDate?: string;
    subtract?: boolean;
}

const useAttributionData = ({
    mainImpactStats,
    brandId,
    brandSlug,
    contentSlug,
    campaignId,
    startDate,
    endDate,
    subtract,
}: UseImpactDataProps) => {
    // State to store the attribution data
    const [attributionData, setAttributionData] = useState<AttributionData[]>([]);
    // State to track loading status
    const [loadingAttributionData, setLoadingAttributionData] = useState(false);

    const fetchData = useCallback(async () => {
        // Prevent multiple simultaneous fetches
        if (loadingAttributionData) return;
        setLoadingAttributionData(true);

        // Format dates for API requests
        const formattedStartDate = subtract
            ? dayjs(startDate).subtract(7, 'days').format('YYYY-MM-DD')
            : dayjs(startDate).format('YYYY-MM-DD');
        // Array to store fetched data
        const newImpactStatsData: AttributionData[] = [];

        // Iterate through each stat type
        for (const stat of mainImpactStats) {

            // Adjust end date for Instagram profile reach and profile visits, as the  
            // end date is exclusive for that connector type
            const formattedEndDate =
                stat.connectorType === ConnectorIndicatorTypes.INSTAGRAM_PROFILE_REACH ||
                    stat.connectorType === ConnectorIndicatorTypes.INSTAGRAM_PROFILE_VISITS
                    ? dayjs(endDate).add(2, 'days').format('YYYY-MM-DD')
                    : dayjs(endDate).add(1, 'days').format('YYYY-MM-DD');

            let path: string | undefined;

            // Determine API path based on connector type
            switch (stat.connectorType) {
                case ConnectorIndicatorTypes.SPOTIFY_FOLLOWERS:
                    path = `/dashboard/brand/${brandId}/fanbase/spotify/followers?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.SPOTIFY_STREAMS:
                    path = `/dashboard/brand/${brandSlug}/content/${contentSlug}/spotify/streams?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.INSTAGRAM_FOLLOWERS:
                    path = `/dashboard/brand/${brandId}/fanbase/instagram/followers?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.SHAZAMS:
                    path = `/dashboard/brand/${brandSlug}/content/${contentSlug}/shazam/shazams?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.SPOTIFY_PLAYLIST_FOLLOWERS:
                    path = `/dashboard/brand/${brandSlug}/content/${contentSlug}/spotify/followers?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.INSTAGRAM_POST_REACH:
                    path = `/dashboard/brand/${brandSlug}/campaign/${campaignId}/content/reach?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS:
                    path = `/dashboard/brand/${brandSlug}/campaign/${campaignId}/content/total_interactions?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.INSTAGRAM_PROFILE_REACH:
                    path = `/dashboard/brand/${brandId}/fanbase/instagram/reach?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                case ConnectorIndicatorTypes.INSTAGRAM_PROFILE_VISITS:
                    path = `/dashboard/brand/${brandId}/fanbase/instagram/profile_views?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
                    break;
                default:
                    // No path for unknown connector types
                    break;
            }

            if (path) {
                try {
                    // Fetch data from API
                    const response = await Axios.get(path);
                    const platformData = response?.data;

                    // Initialize data object
                    let data = {
                        dailyStats: [],
                        percentageChange: 0,
                        netChange: 0,
                        currentValue: 0,
                    };

                    if (stat.dataType === Platforms.streams) {
                        data.dailyStats = platformData?.data?.data || [];
                        data.percentageChange = parseFloat(platformData?.data?.viewsGrowth?.percentage || 0);
                        data.netChange = parseInt(platformData?.data?.viewsGrowth?.difference || 0);
                        data.currentValue = parseInt(platformData?.data?.viewsGrowth?.currentGrowthValue || 0);
                    } else if (stat.dataType === Platforms.followers) {
                        data.dailyStats = platformData?.data?.chartData || [];
                        data.percentageChange = parseInt(platformData?.data?.growthData?.percentage || 0);
                        data.netChange = parseInt(platformData?.data?.growthData?.difference || 0);
                        data.currentValue = parseInt(platformData?.data?.growthData?.currentGrowthValue || 0);
                    }

                    // Add fetched data to array
                    newImpactStatsData.push({
                        description: stat,
                        data: data,
                    });
                } catch (error) {
                    console.error(`Error fetching impact data for ${stat.connectorType}:`, error);
                    // Add empty data for failed fetch
                    newImpactStatsData.push({
                        description: stat,
                        data: {
                            dailyStats: [],
                            percentageChange: 0,
                            netChange: 0,
                            currentValue: 0,
                        },
                    });
                }
            }
        }

        // Update state with fetched data
        setAttributionData(newImpactStatsData);
        // Set loading to false
        setLoadingAttributionData(false);
    }, [
        mainImpactStats,
        brandId,
        brandSlug,
        contentSlug,
        campaignId,
        startDate,
        endDate,
        subtract,
    ]);

    // Return state and fetch function
    return { attributionData, loadingAttributionData, fetchData };
};


// ===================================================================
// IMPACT STATS
// these are the platforms we can pull data from
//  - Partial makes it so not all keys are required from ConnectorIndicatorTypes (skipping NONE)
export const IMPACT_STATS_OPTIONS: Partial<Record<ConnectorIndicatorTypes, ImpactStatsOption>> = {

    [ConnectorIndicatorTypes.INSTAGRAM_FOLLOWERS]: {
        alt: "instagram_icon.svg",
        icon: InstagramIcon,
        dataType: Platforms.followers,
        mainLabel: "Instagram Followers",
        unit: "followers",
        platformName: "Instagram",
        connectorType: ConnectorIndicatorTypes.INSTAGRAM_FOLLOWERS,
        roundPercent: true,
        show: true,
        connect: true,
    },

    [ConnectorIndicatorTypes.INSTAGRAM_POST_REACH]: {
        alt: "instagram_icon.svg",
        icon: InstagramIcon,
        dataType: Platforms.followers,
        mainLabel: "Instagram Post Reach",
        unit: "reach",
        platformName: "Instagram",
        connectorType: ConnectorIndicatorTypes.INSTAGRAM_POST_REACH,
        roundPercent: true,
        show: true,
        connect: true,
        hideCostLabel: true,
        showAverage: true,
    },
    [ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS]: {
        alt: "instagram_icon.svg",
        icon: InstagramIcon,
        dataType: Platforms.followers,
        mainLabel: "Instagram Post Total Interactions",
        unit: "total interactions",
        platformName: "Instagram",
        connectorType: ConnectorIndicatorTypes.INSTAGRAM_POST_TOTAL_INTERACTIONS,
        roundPercent: true,
        show: true,
        connect: true,
        hideCostLabel: true,
    },

    [ConnectorIndicatorTypes.INSTAGRAM_PROFILE_REACH]: {
        alt: "instagram_icon.svg",
        icon: InstagramIcon,
        dataType: Platforms.followers,
        mainLabel: "Instagram Accounts Reached",
        unit: "reach",
        platformName: "Instagram",
        connectorType: ConnectorIndicatorTypes.INSTAGRAM_PROFILE_REACH,
        roundPercent: true,
        show: true,
        connect: true,
        hideCostLabel: true,
        showAverage: true,

    },

    [ConnectorIndicatorTypes.INSTAGRAM_PROFILE_VISITS]: {
        alt: "instagram_icon.svg",
        icon: InstagramIcon,
        dataType: Platforms.followers,
        mainLabel: "Instagram Profile Visits",
        unit: "profile visits",
        platformName: "Instagram",
        connectorType: ConnectorIndicatorTypes.INSTAGRAM_PROFILE_VISITS,
        roundPercent: true,
        show: true,
        connect: true,
        hideCostLabel: true,
        showAverage: true,

    },
    [ConnectorIndicatorTypes.SPOTIFY_FOLLOWERS]: {
        alt: "spotify_icon.svg",
        icon: SpotifyIcon,
        dataType: Platforms.followers,
        mainLabel: "Spotify Followers",
        unit: "followers",
        platformName: "Spotify",
        connectorType: ConnectorIndicatorTypes.SPOTIFY_FOLLOWERS,
        roundPercent: true,
        show: true,
    },
    [ConnectorIndicatorTypes.SPOTIFY_PLAYLIST_FOLLOWERS]: {
        alt: "spotify_icon.svg",
        icon: SpotifyIcon,
        dataType: Platforms.streams,
        mainLabel: "Spotify Playlist Saves",
        platformName: "Spotify",
        unit: "saves",
        connectorType: ConnectorIndicatorTypes.SPOTIFY_PLAYLIST_FOLLOWERS,
        roundPercent: true,
        show: true,
    },
    [ConnectorIndicatorTypes.SPOTIFY_STREAMS]: {
        alt: "spotify_icon.svg",
        icon: SpotifyIcon,
        dataType: Platforms.streams,
        mainLabel: "Spotify Streams",
        unit: "total streams",
        platformName: "Spotify",
        connectorType: ConnectorIndicatorTypes.SPOTIFY_STREAMS,
        roundPercent: true,
        show: (value: number | boolean) => {
            if (typeof value === 'number') return value > 0;
            return false;
        }
    },

    [ConnectorIndicatorTypes.SHAZAMS]: {
        alt: "shazam_icon.svg",
        icon: ShazamIcon,
        mainLabel: "Shazams",
        dataType: Platforms.streams,
        unit: "Shazams",
        connectorType: ConnectorIndicatorTypes.SHAZAMS,
        platformName: "Shazam",
        roundPercent: true,
        show: (showShazams: number | boolean) => Boolean(showShazams),
        connect: true,
    }
};

export default useAttributionData;
