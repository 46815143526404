import { useContext } from "react";
import { FANBASE_TASKS } from "types/global";
import { StyledLabel } from "./styles";
import PresaveCreatedIcon from "assets/images/fanbaseTasks/PresaveCreatedIcon.svg"
import { FanbaseContext } from "Hooks/FanbaseContext";
import { useHistory } from "react-router-dom";
import { track } from "analytics";
import TaskCard from "components/TaskCard/TaskCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import useScreen from "Hooks/useScreen";
import { Container } from "styles/shared";

interface FanbaseTaskItem {
  key: FANBASE_TASKS;
  icon?: string;
  title: string;
  description: string;
  action: () => void;
  doneKey: FANBASE_TASKS;
}

export const FanbaseTasksNames = {
  [FANBASE_TASKS.PRESAVE_CREATED]: "Create a Pre-save"
}

const getFanbaseActions = (redirect: (url: string, taskName: string) => void): FanbaseTaskItem[] => {
  return [
    {
      icon: PresaveCreatedIcon,
      title: "Create a Pre-save",
      description: "Grow your day 1 streams and email list by collecting pre-saves for your release.",
      action: () => {
        redirect("/marketing/new/presave", "Create a Pre-save");
      },
      doneKey: FANBASE_TASKS.PRESAVE_CREATED,
      key: FANBASE_TASKS.PRESAVE_CREATED,
    }
  ]
}

const FanbaseTasks = () => {
  const history = useHistory();
  const { finishedTasks: completedFanbaseTasks } = useContext(FanbaseContext);
  const { mobileView, largeView } = useScreen();

  const redirect = (url: string, taskName: string) => {
    track(`Start "${taskName}" Fanbase Step`, {
      remainingSteps: Object.keys(FANBASE_TASKS).length - completedFanbaseTasks.length - 1,
    })
    history.push(url);
  }

  const FanbaseActions = getFanbaseActions(redirect);

  const getTaskIsFinishedCallback = (item: FanbaseTaskItem) => {
    return Boolean(completedFanbaseTasks.find((task) => task === item.doneKey))
  }

  if (largeView) {
    return (
      <Container
        className="fanbaseTasksContainer"
        padding="24px"
        backgroundColor="white"
        borderRadius={mobileView ? "0px" : "16px"}
        flexDirection="column"
        display="flex"
        height="fit-content"
      >
        <StyledLabel
          fontSize={20}
          padding="8px 8px"
        >
          Boost your growth by collecting more pre-saves from fans.
        </StyledLabel>
        <Swiper
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
          style={{ padding: "8px 8px", maxWidth: "350px" }}
        >
          {FanbaseActions.filter((item) => !getTaskIsFinishedCallback(item)).map((item) => {
            return (
              <SwiperSlide key={`swiper-slide-${item.key}`}>
                <TaskCard
                  key={item.key}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  action={item.action}
                  crossedOut={false}
                  headerAlign="center"
                />
              </SwiperSlide>
            )
          })}
          {FanbaseActions.filter((item) => getTaskIsFinishedCallback(item)).map((item) => {
            return (
              <SwiperSlide key={`swiper-slide-${item.key}`}>
                <TaskCard
                  key={item.key}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  action={item.action}
                  crossedOut
                  headerAlign="center"
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    );
  }

  return (
    <Container
      className="fanbaseTasksContainer"
      backgroundColor="#F5F3F6"
      flexDirection="column"
      display="flex"
      height="100%"
      width="40%"
    >
      <Container
        padding="24px"
        backgroundColor="white"
        borderRadius="16px"
        flexDirection="column"
        display="flex"
        height="100%"
      >
        <StyledLabel
          fontSize={20}
          padding="8px 0px"
        >
          Boost your growth by collecting more pre-saves from fans.
        </StyledLabel>
        {FanbaseActions.filter((item) => !getTaskIsFinishedCallback(item)).map((item) => {
          return (
            <TaskCard
              key={item.key}
              icon={item.icon}
              title={item.title}
              description={item.description}
              action={item.action}
              crossedOut={false}
            />
          )
        })}
        {FanbaseActions.filter((item) => getTaskIsFinishedCallback(item)).map((item) => {
          return (
            <TaskCard
              key={item.key}
              icon={item.icon}
              title={item.title}
              description={item.description}
              action={item.action}
              crossedOut
            />
          )
        })}
      </Container>
    </Container>
  );
}

export default FanbaseTasks;