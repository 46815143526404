import { useContext, useEffect, useState } from "react";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Axios from "../../helpers/Interceptor";
import { useAuth0 } from "@auth0/auth0-react";
import gravatar from "gravatar";
import { checkIsValidUsername } from "pages/post-auth/AddArtist/Components/ConnectPlatformModal/TikTok/utils";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { clsx } from "modules/Utils";
import { TikTokArtist } from "types/global";
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface TikTokConnectProps {
  className?: string;
  /** Function to close the modal */
  closeModal: () => void;
  /** Selected TikTok artist data */
  selected: TikTokArtist | null;
  /** Optional function to set TikTok artist data */
  set?: (artist: TikTokArtist) => void;
  /** Whether to save data locally */
  localSave: boolean;
  /** Optional callback after saving */
  onSave?: () => void;
}

/**
 * Component for connecting TikTok accounts
 * Allows users to input and validate TikTok usernames
 */
const TikTokConnect = ({
  className = '',
  closeModal,
  selected,
  set,
  localSave,
  onSave
}: TikTokConnectProps) => {
  const { user } = useAuth0();
  const { currentBrand } = useContext(CurrentBrandContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  // State for handling the TikTok username input and formatted data
  const [valueString, setValueString] = useState<string>(selected?.username || '');
  const [formattedValue, setFormattedValue] = useState<TikTokArtist | null>(selected);

  // Format TikTok username and update formattedValue when input changes
  useEffect(() => {
    const foundTiktokName = valueString;

    // Remove @ prefix and convert to lowercase
    let nameWithoutAt = foundTiktokName.toLowerCase();
    if (nameWithoutAt.charAt(0) === '@') {
      nameWithoutAt = nameWithoutAt.substring(1);
    }

    if (foundTiktokName) {
      setFormattedValue({
        url: `https://tiktok.com/@${nameWithoutAt}`,
        username: nameWithoutAt
      });
    }
  }, [valueString]);

  if (!currentBrand) {
    return null;
  }

  /**
   * Updates TikTok connection in brand data
   * Adds Gravatar picture and makes API call to update brand
   */
  const updateInBrand = async (): Promise<void> => {
    const cloudinaryUrl = gravatar.url(user?.email as string, { protocol: 'https', d: 'retro' });
    const updatedValue = { ...formattedValue, picture: cloudinaryUrl };

    // setFormattedValue(updatedValue);

    try {
      await Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: 'tiktok',
        value: updatedValue
      });

      //   if (set) set(updatedValue);
      onSave?.();
      closeModal();
    } catch (err) {
      console.error('Error updating brand:', err);
    }
  };

  /**
   * Validates TikTok username
   * @param str - Username to validate
   * @returns boolean indicating if username is valid
   */
  const isValid = (str: string): boolean => {
    return Boolean(formattedValue && checkIsValidUsername(str));
  };

  // TODO: render()
  return (
    <div className={clsx('tikTokConnectComponent', className)}>
      <h4 className="mt-5 pb-5 text-2x font-semibold border-b border-gray-200">
        What is {currentBrand.name}'s TikTok username?
      </h4>
      <div className="input-form flex flex-row align-middle relative">
        <input
          className="border-b p-6 outline-none text-left border-gray-200 w-full"
          placeholder="e.g. xyz"
          value={valueString}
          onChange={(e) => setValueString(e.target.value)}
        />
      </div>
      <p className={`w-12/12 mt-5 text-2x text-center ${!isValid(valueString) ? 'text-red-500' : ''}`}>
        {valueString ? isValid(valueString) ? " " : "Please enter a valid TikTok username." : " "}
      </p>

      <ButtonSymphony
        className="updateBrandTikTokConnectButton"
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        disabled={error || !valueString || !isValid(valueString)}
        isLoading={loading}
        loadingText="Connecting..."
        onClick={() => {
          if (!localSave) {
            updateInBrand();
            setLoading(true);
          } else {
            if (set && formattedValue) {
              set(formattedValue);
            }
            closeModal();
          }
        }}
        size="large"
      >
        Connect
      </ButtonSymphony>
    </div>
  );
};

export default TikTokConnect;
