import { useEffect, useState } from "react"
import { Chip } from "@material-ui/core"
import { Body2, Subtitle2, Caption } from "components/shareable/Typography"
import { clsx } from 'modules/Utils'
import useStyles, { AnnuallyContainer, BillingRadio } from "./styles"
import { PlanType, DEFAULT_CURRENCY } from "modules/Const"
import { BillingCycle } from "../../utils"
import { CurrencyCodesType } from "types/global"
import { getConvertedCurrency } from "services/symphonyApi"
import getSymbolFromCurrency from "currency-symbol-map"
import { Container } from "styles/shared"
import { getMonthlyPrice, getYearlyPrice } from "modules/Utils"
import Colors from "modules/Colors"

interface BillingChipProps {
  className?: string;
  /** Whether the chip is currently selected */
  isActive: boolean;
  /** Click handler for the chip */
  onClick: () => void;
  /** Billing cycle (monthly/annually) */
  cycle?: BillingCycle;
  /** Optional override for mobile view styling */
  isMobileView?: boolean;
  /** Optional discount percentage (1 = no discount) */
  discountPercentage?: number;
  /** Currency code for display */
  currency: CurrencyCodesType;
  /** Override the default label text */
  label?: string;
  /** Override the default price calculation */
  overridePrice?: number;
  /** Hide the per month suffix */
  hidePerMonth?: boolean;

  planType?: PlanType;
}

/**
 * BillingChip component that displays pricing options for both Pro and Team plans
 * @component
 */
export const BillingChip = ({
  className = '',
  isActive,
  onClick,
  cycle,
  isMobileView,
  discountPercentage = 1,
  currency,
  label,
  overridePrice,
  hidePerMonth = false,
  planType = PlanType.PRO
}: BillingChipProps) => {
  const classes = useStyles({ isMobileView });
  const [annualPrice, setAnnualPrice] = useState(getYearlyPrice(planType));
  const [monthlyPrice, setMonthlyPrice] = useState(getMonthlyPrice(planType));

  const color = isActive ?
    Colors.purple
    :
    Colors.textColor;

  // Handle currency conversion for default pro plan prices
  // TODO CONNER & MEGH: make sure Lite works with international currencies
  useEffect(() => {
    if (!overridePrice && currency !== DEFAULT_CURRENCY) {
      getConvertedCurrency({
        from: DEFAULT_CURRENCY,
        to: currency,
        amount: cycle === BillingCycle.ANNUAL ? getYearlyPrice(planType) : getMonthlyPrice(planType)
      }).then((res) => {
        cycle === BillingCycle.ANNUAL ? setAnnualPrice(res) : setMonthlyPrice(res);
      });
    }
  }, [currency, cycle, overridePrice]);

  useEffect(() => {
    setAnnualPrice(getYearlyPrice(planType))
    setMonthlyPrice(getMonthlyPrice(planType))
  }, [planType])

  /**
   * Calculate the display price based on whether we're using an override price
   * or the default pro plan pricing
   */
  const getDisplayPrice = () => {
    if (overridePrice !== undefined) {
      const price = Number(overridePrice);
      return price % 1 === 0 ? price.toString() : price.toFixed(2);
    }

    const basePrice = cycle === BillingCycle.ANNUAL ? annualPrice : monthlyPrice;
    const finalPrice = basePrice * discountPercentage;
    return finalPrice % 1 === 0 ? finalPrice.toString() : finalPrice.toFixed(2);
  };

  /**
   * Get the comparison price for discount display
   */
  const getComparisonPrice = () => {
    if (overridePrice || discountPercentage === 1) return null;

    const basePrice = cycle === BillingCycle.ANNUAL ? annualPrice : monthlyPrice;
    return basePrice % 1 === 0 ? basePrice.toString() : basePrice.toFixed(2);
  };

  /**
   * Get the display label based on props or defaults
   */
  const getLabel = () => {
    if (label) return label;
    return cycle === BillingCycle.ANNUAL
      ? "Yearly (15% Discount)"
      : "Monthly";
  };

  const comparisonPrice = getComparisonPrice();
  const displayPrice = getDisplayPrice();
  const currencySymbol = getSymbolFromCurrency(currency);

  return (
    <Chip
      className={`billingChipComponent ${className} ${clsx(classes.billingChip, isActive && classes.billingChipActive)}`}
      icon={<BillingRadio style={{ color }} checked={isActive} />}
      onClick={onClick}
      label={
        <AnnuallyContainer>
          <Body2 color={color}>{getLabel()}</Body2>
          <Container display="flex" flexDirection="row" gap="4px">
            <Subtitle2 color={color}>
              {`${currencySymbol}${displayPrice}`}
              {!hidePerMonth && <Caption color={Colors.greyDark}> /mo</Caption>}
            </Subtitle2>
            {comparisonPrice && (
              <Subtitle2
                color={color}
                style={{
                  textDecoration: 'line-through',
                  color: '#989898',
                  fontSize: '15px'
                }}
              >
                {`${currencySymbol}${comparisonPrice}`}
              </Subtitle2>
            )}
          </Container>
        </AnnuallyContainer>
      }
    />
  );
};
