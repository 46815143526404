import { WebsiteEditorSection } from "../styles";
import { objectToArrayInOrder } from "modules/Utils";
import { Subtitle1 } from 'components/shareable/Typography';
import { useStyles } from '../../../styles';
import { clsx } from 'modules/Utils';
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { FontThemeStyle, fontThemeStyles, FontThemeStyleType } from "./utils";
import UpgradeToUnlockCTA from "../../UpgradeToUnlockCTA";
import { PlanType } from "modules/Const";

/**
 * Props for the FontIcon component
 * @interface FontIconProps
 * @property {string} font - The font family name to display
 */
interface FontIconProps {
    font: string;
}

/**
 * Renders a preview of what text looks like in the specified font
 * @param {FontIconProps} props - Component props
 * @returns {JSX.Element} Font preview component
 */
const FontIcon = ({ font }: FontIconProps): JSX.Element => {
    const classes = useStyles();
    return (
        <div className={classes.fontThemeStyleIcon} style={{ fontFamily: `'${font}'` }}>
            Aa
        </div>
    );
};

/**
 * Props for the FontBox component
 * @interface FontBoxProps
 * @property {FontThemeStyle} currentFontStyle - The font style configuration object
 * @property {number} index - The index position of this font in the list
 * @property {FontThemeStyleType} selectedFontStyle - The currently selected font style
 * @property {(fontStyle: FontThemeStyleType) => void} onFontStyleChange - Callback function when font style changes
 */
interface FontBoxProps {
    currentFontStyle: FontThemeStyle;
    index: number;
    selectedFontStyle: FontThemeStyleType;
    onFontStyleChange: (fontStyle: FontThemeStyleType) => void;
}

/**
 * Renders a selectable box containing a font preview and selection indicator
 * @param {FontBoxProps} props - Component props
 * @returns {JSX.Element} Font selection box component
 */
const FontBox = ({
    currentFontStyle,
    index,
    selectedFontStyle,
    onFontStyleChange
}: FontBoxProps): JSX.Element => {
    const classes = useStyles();
    const maxFonts = Object.keys(fontThemeStyles).length;
    const fontPosition = `${index + 1}/${maxFonts}`;

    const isSelected = selectedFontStyle === currentFontStyle.name;
    return (
        <div
            className={clsx(classes.themeBox, isSelected && classes.themeBoxSelected)}
            onClick={() => {
                onFontStyleChange(currentFontStyle.name)
            }}
        >
            <FontIcon font={currentFontStyle.title} />
            <span>{fontPosition}</span>
            {currentFontStyle.isPaidOnly &&
                <UpgradeToProChip
                    type="gem"
                    color="purple"
                />
            }
        </div>
    );
};

/**
 * Props for the FontSelectorSection component
 * @interface FontSectionProps
 * @property {boolean} isPaidUser - Whether the current user has pro access
 * @property {FontThemeStyleType} [fontThemeStyle] - The currently selected font theme style
 * @property {(fontStyle: FontThemeStyleType) => void} onFontStyleChange - Callback function when font style changes
 */
interface FontSectionProps {
    isPaidUser: boolean;
    fontThemeStyle?: FontThemeStyleType;
    onFontStyleChange: (fontStyle: FontThemeStyleType) => void;

}

/**
 * Main font selection section component that allows users to choose website fonts
 * Displays a grid of available fonts with pro-only indicators and selection state
 * Shows warning message if non-pro user selects a pro-only font
 * 
 * @param {FontSectionProps} props - Component props
 * @returns {JSX.Element} Font selector section component
 */
export const FontSelectorSection = ({
    isPaidUser,
    fontThemeStyle,
    onFontStyleChange
}: FontSectionProps): JSX.Element => {
    const classes = useStyles();

    const currentFontStyle = fontThemeStyle ? fontThemeStyles[fontThemeStyle] : fontThemeStyles.font1;
    return (
        <WebsiteEditorSection>
            <Subtitle1>Primary Font</Subtitle1>
            <div className={classes.renderSelectorBoxRow}>
                {objectToArrayInOrder(fontThemeStyles).map((fs, fsi) => (
                    <FontBox
                        key={fs.name}
                        currentFontStyle={fs}
                        index={fsi}
                        selectedFontStyle={fontThemeStyle || 'font1'}
                        onFontStyleChange={onFontStyleChange}
                    />
                ))}
            </div>
            <UpgradeToUnlockCTA
                requiresPaidPlan={Boolean(!isPaidUser && currentFontStyle.isPaidOnly)}
                upgradeSource="Website Editor - Font Selector"
                highlightedPlan={PlanType.LITE}
                buttonText="Upgrade to unlock Custom Fonts"
                upgradePopupCallToAction="Unlock unlimited Website Customization with Symphony Lite"
                paywallText="You've selected a Lite-only font. You'll need to upgrade to Lite before publishing your site"
            />
        </WebsiteEditorSection>
    );
};
