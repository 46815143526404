import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles(() => ({
  crossedOut: {
    textDecoration: "line-through",
  },
  hidden: {
    display: "hidden",
  },
  icon: {
    width: '40px',
    height: '40px',
  },
  markIcon: {
    width: '24px',
    height: '24px',
  },
  borded: {
    border: '1px solid #EDECF2',
    borderRadius: '6px'
  }
}));

interface TaskTitleProps {
  textAlign?: string,
}

interface TaskHeaderMobileProps {
  flexDirection?: string,
}

interface CardContainerProps {
  showBoxShadow?: boolean,
}

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 12px;
  gap: 12px;
  background: #FFFFFF;
  border-bottom: 1px solid #EDECF2;
  box-shadow:  ${({ showBoxShadow }: CardContainerProps) => showBoxShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};
  border-radius:  ${({ showBoxShadow }: CardContainerProps) => showBoxShadow ? '6px' : '0px'};
  
`;

export const CardContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 21px;
  max-height: 100%;
  height: 196px;
  background: #FFFFFF;
  border: 1px solid #EDECF2;
  border-radius: 6px;
  @media(max-width: 768px) {
    padding: 12px 8px;
  }
  @media(max-width: 424px) {
    height: 215px;
  }
  @media(min-width: 336px) and (max-width: 419px) {
    height: 200px;
  }
  @media(max-width: 314px) {
    height: 230px;
  }
`;

export const TaskTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  padding: 0px;
  height: 100%;
`;

export const TaskHeaderMobile = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: TaskHeaderMobileProps) => flexDirection};
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const TaskIcon = styled.div`
  background: rgba(136, 0, 255, 0.08);
  border-radius: 8.00039px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`
export const TaskIconImage = styled.img`
  width: 32px;
  height: 32px;
`

export const TaskTitle = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: ${({ textAlign }: TaskTitleProps) => textAlign};
`
export const TaskDescription = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
`;

export const CompletedMarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 32px;
`
export const CompletedMark = styled.div`
  background: #07C806;
  border-radius: 29px;
  width: 24px;
  height: 24px;
`