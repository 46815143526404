import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PlaylistingCampaignDetails from "pages/post-auth/MarketingPage/Playlisting/CampaignDetails";
import AdCampaignDetails from "pages/post-auth/MarketingPage/MusicStreams/CampaignDetails";
import VideoCampaignDetails from "pages/post-auth/MarketingPage/VideoAds/CampaignDetails";
import LinkClicksCampaignDetails from "pages/post-auth/MarketingPage/LinkClicks/CampaignDetails";
import MainContentContainer from "components/shareable/MainContentContainer";
import { getCampaignDetails } from "./utils";
import { CampaignDetails, CampaignMetadata } from "types/global";
import PreSaveDetails from "../MarketingPage/PreSave/Details/PreSaveDetails";
import isEqual from "lodash/isEqual";
import BoostInstagramCampaignDetails from "../MarketingPage/GrowInstagram/CampaignDetails";
import { clsx } from 'clsx';
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { CAMPAIGN_TYPE_ENUM, FB_CAMPAIGN_TYPES } from "modules/Const";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import Colors from "modules/Colors";

interface DetailsProps {
  campaignDetails: CampaignDetails;
  updateCampaignMetadata?: (data: CampaignMetadata) => void;
  handleGoBack: () => void;
  refreshCampaignDetails: () => void;
}

const Details = ({
  campaignDetails,
  updateCampaignMetadata,
  handleGoBack,
  refreshCampaignDetails
}: DetailsProps) => {
  const campaignType = campaignDetails?.campaign_metadata?.campaign_type;
  const mainProps = {
    campaignDetails,
    handleGoBack,
  };

  switch (campaignType) {
    case "get_playlisted":
      return <PlaylistingCampaignDetails {...mainProps} />
    case "record_streams":
    case "grow_playlist_followers":
      return <AdCampaignDetails {...mainProps} />
    case "increase_video_views":
      return <VideoCampaignDetails {...mainProps} />
    case "pre_save":
      return <PreSaveDetails
        {...mainProps}
        updateCampaignMetadata={updateCampaignMetadata}
        refreshCampaignDetails={refreshCampaignDetails}
      />
    case "link_clicks":
      return <LinkClicksCampaignDetails {...mainProps} />
    case "increase_sales":
      return <LinkClicksCampaignDetails {...mainProps} />
    case "boost_instagram":
      return <BoostInstagramCampaignDetails {...mainProps} />
    default:
      return null
  }
};

const isNewCampaignStyle = (campaignDetails: CampaignDetails) => {
  const campaignType = campaignDetails?.campaign_metadata?.campaign_type;
  if ([
    'record_streams',
    'grow_playlist_followers',
    'link_clicks',
    'boost_instagram',
    'get_playlisted',
    'increase_video_views',
    'increase_sales'
  ].indexOf(campaignType) > -1) {
    return true;
  } else if (campaignDetails?.campaign_metadata?.presave) {
    return true
  }
  return false;
}

interface OverviewCampaignProps {
  campaignDetails: CampaignDetails;
  handleGoBack: () => void;
  title: string;
}

const InnerOverviewCampaignDetails = ({
  campaignDetails,
  handleGoBack,
  title
}: OverviewCampaignProps) => {
  const campaignType = campaignDetails?.campaign_metadata?.campaign_type;
  switch (campaignType) {
    default:
      return null
  }
}

type LocationState = {
  from: 'TEAM' | undefined | null;
};

const MAIN_TITLE = "Campaign Details";
const LOADING_LABEL = "Loading Campaign Data...";

const checkAllConditions = (condition: boolean) => condition;

const getScreenTitle = (data: CampaignMetadata) => {
  const type = data.campaign_type;

  if (isEqual(type, "pre_save")) {
    return `"${data.content.name}" Pre-save Campaign`;
  }

  return MAIN_TITLE;
};

const MarketingCampaignDetails = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const params = useParams<{ id?: string }>();
  const { currentBrand } = useContext(CurrentBrandContext)
  const { id: campaignId } = params;

  const [campaignDetails, setCampaignDetails] = useState({} as CampaignDetails);
  const [loading, setLoading] = useState(true);
  const [isFbCampaign, setIsFbCampaign] = useState(false);
  const [isPreSave, setIsPreSave] = useState(false);

  const campaignDetailsBrandId = campaignDetails?.brand_id;
  const currentBrandAccessToken = currentBrand?.connections?.logged_in_fb_user?.access_token;

  const campaignMetadata = campaignDetails?.campaign_metadata || {};
  const title = getScreenTitle(campaignMetadata);

  // this just refreshes campaign details
  const refreshCampaignDetails = useCallback(async () => {
    const data = await getCampaignDetails(campaignId as string, currentBrandAccessToken);
    if (data) setCampaignDetails(data)
  }, [campaignId, currentBrandAccessToken]);

  const initCampaignDetails = useCallback(async () => {
    setLoading(true)
    await refreshCampaignDetails()
    setLoading(false)
  }, [refreshCampaignDetails]);

  const handleGoBack = () => {
    if (location.state?.from === 'TEAM') {
      history.push("/organization?tab=MARKETING_CAMPAIGNS");
    } else {
      history.push("/marketing");
    }
  };

  useEffect(() => {
    if (campaignId) {
      initCampaignDetails()
    }
  }, [campaignId, initCampaignDetails]);

  useEffect(() => {
    if (campaignDetails.campaign_metadata) {
      const fbCampaign = FB_CAMPAIGN_TYPES.includes((campaignDetails.campaign_metadata.campaign_type as CAMPAIGN_TYPE_ENUM));
      const isPresave = campaignDetails.campaign_metadata.campaign_type === 'pre_save';
      setIsPreSave(isPresave)
      setIsFbCampaign(fbCampaign)
    }
  }, [campaignDetails])

  useEffect(() => {
    if (!currentBrand) {
      return;
    }

    const pushConditions = [
      Number.isFinite(campaignDetailsBrandId),
      Number.isFinite(currentBrand.id),
      campaignDetailsBrandId !== currentBrand.id,
    ];
    const pushToMarketingScreen = pushConditions.every(checkAllConditions);
    const stopLoading = campaignDetailsBrandId && currentBrand.id;

    if (pushToMarketingScreen) handleGoBack();
    if (stopLoading) setLoading(false);
  }, [currentBrand, campaignDetailsBrandId, currentBrand?.id, history]);

  const updateCampaignMetadata = (data: CampaignMetadata) => {
    setCampaignDetails({
      ...campaignDetails,
      campaign_metadata: {
        ...campaignDetails.campaign_metadata,
        ...data,
      },
    });
  };

  return (
    <MainContentContainer backgroundColor={Colors.PAPER_BACKGROUND_CONTAINER}>
      <div
        className={isFbCampaign || isPreSave ? "md:pt-4 pb-20 m-0" : "md:pt-4 pb-20 card-body max-w-6xl"}
        style={{ backgroundColor: Colors.PAPER_BACKGROUND_CONTAINER }}
      >
        <div className={clsx(!isNewCampaignStyle(campaignDetails) && "card-content bg-white shadow-none lg:shadow-lg pb-6")}>
          <div className={`top-wrapper ${(isFbCampaign || isPreSave) && 'flex justify-center'}`}>
            <InnerOverviewCampaignDetails
              campaignDetails={campaignDetails}
              handleGoBack={handleGoBack}
              title={title}
            />
            {loading ?
              <div className="mx-auto my-6 pt-8 flex-col flex items-center">
                <NewLoader black />
                <p className="mt-1 text-center">{LOADING_LABEL}</p>
              </div>
              :
              <Details
                refreshCampaignDetails={refreshCampaignDetails}
                campaignDetails={campaignDetails}
                updateCampaignMetadata={updateCampaignMetadata}
                handleGoBack={handleGoBack}
              />
            }
          </div>
        </div>
      </div>
    </MainContentContainer>
  );
};

export default MarketingCampaignDetails;
