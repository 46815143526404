import { useContext, useState } from "react";
import {
  StyledLabel,
  ListItem,
  UnorderedList,
  Icon,
  ArrowImageContainer,
} from "../../styles";
import UpArrowImage from "assets/images/settingsScreen/upArrow.svg";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { StarsIcon } from "components/svg-icons";
import { goToUrl } from "modules/Utils";
import * as Sentry from "@sentry/react";
import { Container } from "styles/shared";
import { getStripeSubscriptionPortalUrl } from "services/symphonyApi/subscriptionService";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import ButtonSymphony from "components/shareable/ButtonSymphony";

const FreePlanView = ({
  headline = "Unlock all the power of Symphony's automated marketing tools",
  listItems = [
    'Unlimited data access',
    'Unlimited marketing campaigns',
    'Learn who your fans are, down to their name + city'
  ],
}) => {
  const { currentBrand } = useContext(CurrentBrandContext);
  const { handleOpenModal } = useContext(UpgradeModalContext);

  const { slug: brandSlug, subscription } = currentBrand || {}

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirectToSubscriptionStatus = async () => {
    try {
      setIsLoading(true);
      const url = await getStripeSubscriptionPortalUrl(brandSlug || '');
      goToUrl(url, setIsLoading);
    } catch (error) {
      setIsLoading(false);
      Sentry.captureException(error);
      console.error("redirectToSubscriptionStatus: ", error);
    }
  };

  return (
    <Container>
      {subscription?.allowCustomerPortal &&
        <Container margin="18px 0px 0px 0px" flexDirection="row">
          <ButtonSymphony
            onClick={redirectToSubscriptionStatus} 
            disabled={isLoading} 
            isLoading={isLoading} 
            variant="outlined"
          >
            View Billing Details
          </ButtonSymphony>
        </Container>
      }

      <Container
        display="flex"
        flexDirection="column"
        padding="24px 0px"
        borderBottom="1px solid #E5E7EB"
      >
        <StyledLabel fontSize={16}>
          Plan
        </StyledLabel>
        <StyledLabel marginTop="4px" fontSize={24}>
          Free
        </StyledLabel>
      </Container>
      <Container minHeight="414px" padding="24px 0px 0px 0px" display="flex">
        {window.innerWidth >= 768 ?
          <ArrowImageContainer width="30%">
            <Icon
              margin="auto"
              width={173}
              height={373}
              alt="upArrow.jpg"
              src={UpArrowImage}
            />
          </ArrowImageContainer>
          :
          null
        }
        <Container width={window.innerWidth >= 768 ? "70%" : "100%"} flexDirection="column" padding="32px">
          <UpgradeToProLabel
            color="YELLOW"
            label="Symphony Pro"
            maxWidth="160px"
          />
          <StyledLabel fontSize={32} fontWeight={500} marginTop="16px">
            {headline}
          </StyledLabel>
          <Container margin="24px 0px 0px 0px">
            <UnorderedList>
              {listItems.map((o: string) => {
                return (
                  <ListItem>{o}</ListItem>
                )
              })}
            </UnorderedList>
          </Container>
          <Container margin="24px 0px 0px 0px">
            <ButtonSymphony
              className="upgradeToProButtonFreePlanView"
              onClick={() => {
                handleOpenModal({
                  source: "Settings - Billing Tab"
                })
              }}
              iconLeft={<StarsIcon />}
              iconRight={
                <Container transform="scaleX(-1)">
                  <StarsIcon />
                </Container>
              }
            >
              Upgrade to Pro
            </ButtonSymphony>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default FreePlanView;
