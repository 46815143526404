import { STREAMLINK_REGEX, STREAM_USERNAME_REGEX } from "helpers/StreamingMappings"

const AT = "@"

export const getTikTokUsernameFromUrl = (value: string) => {
  const isUrl = value.includes("http") || value.includes("https")
  let withoutAt = value 

  if (isUrl) {
    const userNameFromUrl = value.substring(value.lastIndexOf("/") + 1)
    withoutAt = userNameFromUrl
    
    if (withoutAt.charAt(0) === AT) {
      withoutAt = withoutAt.substring(1)
    }

    return { url: value, username: userNameFromUrl }
  } else {
    if (withoutAt.charAt(0) === AT) {
      withoutAt = withoutAt.substring(1)
    }

    return { url: `https://tiktok.com/@${withoutAt}`, username: withoutAt }
  }
}

export const checkIsValid = (str: string, formattedValue: { url: string; username: string} | null) => {
  let isValidUrl = false
  const isUrl = str.includes("http") || str.includes("https")
  const urlTikTokRegexps = STREAMLINK_REGEX["tiktok"]

  if (isUrl) {
    for (const regexp of urlTikTokRegexps) {
      isValidUrl = regexp.test(str.toString())
    }

    return isValidUrl
  }

  const isValidUsername = checkIsValidUsername(str)
  return (
    formattedValue && isValidUsername
  )
}

export const checkIsValidUsername = (str: string) => {
  return STREAM_USERNAME_REGEX.tiktok.test(str.toString())
}
