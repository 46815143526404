import { Dispatch, SetStateAction, useContext, useState } from "react";
import useStyles, { StyledLabel, ButtonContainer } from "./styles";
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as PauseIcon } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as Arrow } from "assets/images/campaignDetails/chevron.svg"
import { LocationDetail } from "../MarketsDetail";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { Container } from "styles/shared";

interface Props {
  params: GridRenderCellParams
  showOverall: boolean
  campaignStatus: string
  openConfirmationModal: () => void
  selectLocation: Dispatch<SetStateAction<LocationDetail>>
}

const LocationCell = ({
  params,
  openConfirmationModal,
  selectLocation,
  showOverall,
  campaignStatus,
}: Props) => {
  const location = params.row as LocationDetail
  const classes = useStyles()
  const { isBrandAdmin } = useContext(CurrentBrandContext)
  const [droppedDown, setDroppedDown] = useState<boolean>(false)

  const apiRef = useGridApiContext();

  const handleDropDown = () => {
    if (params.rowNode.type !== 'group') {
      return
    }
    apiRef.current.setRowChildrenExpansion(params.id, !params.rowNode.childrenExpanded)
    apiRef.current.setCellFocus(params.id, params.field)
    setDroppedDown(!droppedDown)
  };

  const getTooltipText = () => {
    if (location.canPause)
      return isLocationPaused ? "Resume this location" : "Pause this location"

    return isLocationPaused ? "Can't resume this location" : "Can't pause this location"
  }

  const handleOnPauseLocation = () => {
    if (location.canPause) {
      selectLocation(location)
      openConfirmationModal()
    }
  }

  const isLocationPaused = location.status === "paused"

  const disabled = !location.canPause || campaignStatus === 'PAUSED'
  const showButton = !showOverall && campaignStatus !== 'FINISHED' && isBrandAdmin
  const tooltipText = getTooltipText()

  return (
    <Container
      gap="8px"
      display="flex"
      marginLeft={`${params.rowNode.depth * 24}px`}
    >
      <Container
        gap="8px"
        display="flex"
        width="24px"
        onClick={handleDropDown}
      >
        {params.rowNode.type === "group" && (
          <Arrow
            className={droppedDown ? classes.svgGray : classes.svgRotated}
          />
        )}
      </Container>
      <Container gap="8px" display="flex">
        <ButtonContainer
          title={tooltipText}
          className={disabled ? classes.svgDisabledContainer : classes.svgEnabledContainer}
          onClick={handleOnPauseLocation}
          disabled={disabled}
        >
          {showButton && isLocationPaused &&
            <PlayIcon
              className={!disabled ? classes.svgGray : classes.svgDisabled}
            />
          }
          {showButton && !isLocationPaused &&
            <PauseIcon
              className={!disabled ? classes.svgGray : classes.svgDisabled}
            />
          }
        </ButtonContainer>
        <StyledLabel>
          {location.name ? location.name : params.value}
        </StyledLabel>
      </Container>
    </Container>
  )
}

export default LocationCell 