import { useEffect, useState, CSSProperties, Fragment, useContext } from 'react';
import LoaderV2 from 'components/Loader/LoaderV2';
import MaestroBackground from 'assets/images/maestro/maestro-background.png';
import MaestroAiTag from 'assets/images/maestro/maestro-ai-tag.svg';
import MaestroLogo from 'assets/images/maestro/maestro-logo.svg';
import MaestroStarScore from 'assets/images/maestro/star-score-filled.svg';
import MaestroStarScoreOutline from 'assets/images/maestro/star-score-outline.svg';
import Colors from "modules/Colors";
import Axios from 'helpers/Interceptor';
import { Container, TextButton } from 'styles/shared';
import { motion } from "framer-motion";
import ArcLoadingBar from 'components/shareable/ArcLoadingBar';
import { CampaignDetails } from 'types/global';
import { Body2, Subtitle1 } from 'components/shareable/Typography';
import MaestroBadge from 'components/shareable/MaestroBadge';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import useScreen from 'Hooks/useScreen';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

dayjs.extend(advancedFormat);

// Each recommendation is an object like this:
type Action = {
    title: string;
    action: 'pause_campaign' | 'increase_budget' | 'edit_end_date' | 'create_campaign' | 'download_report';
    value?: string; // e.g., 'record_streams' or '50' (for $50)
};

const MAESTRO_STATUS = {
    LOADING: 'loading',
    PENDING: 'pending',
    READY: 'ready',
    TRAINING: 'training',
} as const;

type MaestroStatusType = typeof MAESTRO_STATUS[keyof typeof MAESTRO_STATUS];

type Recommendation = {
    title: string;
    subtitle: string;
    score: number | null;
    starScore: number | null;
    status: MaestroStatusType;  // Updated to use the enum type
    insights: {
        text: string;
        replacements?: {
            color: 'green' | 'red';
            text: string;
        }[];
    }[];
    mainMessage: {
        text: string;
        replacements?: {
            color: 'green' | 'red';
            text: string;
        }[];
    }[];
    tipsToImprove?: string[] | null;
    trainingMessage?: string | null;
    trainingStatusPercentage?: number | null;
};

interface MaestroBannerProps {
    className?: string;
    style?: CSSProperties;
    campaignDetails: CampaignDetails;
}

const MaestroBanner = ({
    className = '',
    style = {},
    campaignDetails,
}: MaestroBannerProps) => {
    const { mobileView, mStyle } = useScreen();
    const { isProBrand } = useContext(CurrentBrandContext)

    let generationTime = campaignDetails?.campaign_metadata?.maestro_recommendations?.generationTime || '';

    // Check if the user has access to Maestro
    const [maestroStatus, setMaestroStatus] = useState<MaestroStatusType>(MAESTRO_STATUS.LOADING); // LOADING, TRAINING, READY
    const [maestroData, setMaestroData] = useState<Recommendation | null>(null);
    const [isMaestro, setIsMaestro] = useState<boolean>(true); // show / hide Maestro insights
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // countdown value shown in UI
    const [countdown, setCountdown] = useState<string>();

    useEffect(() => {
        fetchMaestroData()
    }, [])

    /**
     * Effect hook to manage countdown timer for next Maestro report
     * 
     * This effect:
     * 1. Creates a timer that updates every second
     * 2. Calculates time remaining until next report by:
     *    - Getting current time and next report time
     *    - Converting difference to hours, minutes, seconds
     * 3. Updates countdown state with formatted time string
     * 4. Cleans up timer on unmount or when countdown reaches zero
     * 
     * @dependency maestroData - Reruns when maestroData changes to reset timer
     */
    useEffect(() => {
        let timer: NodeJS.Timeout;

        /**
         * Updates the countdown display with time remaining until next report
         * Formats time as "XXh YYm ZZs" or clears if time expired
         */
        const updateCountdown = () => {
            const now = new Date();
            const nextReport = new Date(generationTime ?? '');
            // Add 24 hours to generation time
            const reportExpiry = new Date(nextReport.getTime() + (24 * 60 * 60 * 1000));
            const difference = reportExpiry.getTime() - now.getTime();

            if (difference > 0) {
                // Convert milliseconds to hours/minutes/seconds
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setCountdown(`${hours}h ${minutes}m ${seconds}s`);
            } else {
                // Clear countdown when time expires
                setCountdown('');
                clearInterval(timer);
            }
        };

        if (maestroData && generationTime) {
            updateCountdown(); // Initial update
            timer = setInterval(updateCountdown, 1000);
        }

        // Cleanup timer on unmount
        return () => clearInterval(timer);
    }, [maestroData]);

    const fetchMaestroData = async () => {
        try {
            setIsError(false);
            const res = await Axios.get(`campaign/${campaignDetails.id}/maestro/recommendations`);
            const data = res.data.data;
            setMaestroStatus(data.status);
            setMaestroData(data);
        } catch (error: any) {
            console.error('Error fetching Maestro data: >>>>', error);
            setErrorMessage(error.message);
            setIsError(true);
        } finally {
            // Any cleanup or final operations can go here
            // For example, you might want to set a loading state to false
        }
    }

    const getColorStyle = (color: string) => {
        switch (color) {
            case 'green':
                return Colors.green
            case 'red':
                return Colors.red
        }
    }

    const textReplacer = (text: string | undefined, replacements: { characterIndex?: number, color: string, text: string }[]) => {
        if (!text) {
            return null;
        }

        let parts = [];
        let lastIndex = 0;

        replacements.forEach(replacement => {
            const {
                characterIndex,
                color,
                text: replacementText
            } = replacement;

            const colorStyle = getColorStyle(color);

            let index = characterIndex !== undefined
                ? characterIndex
                : text.indexOf(replacementText, lastIndex);

            if (index === -1) {
                return; // Skip this replacement if the text is not found
            }

            if (index > lastIndex) {
                parts.push(text.slice(lastIndex, index));
            }
            parts.push(
                <span
                    key={index}
                    className="replacementText"
                    style={{ color: colorStyle, fontWeight: 500 }}
                >
                    {replacementText}
                </span>
            );
            lastIndex = index + replacementText.length;
        });

        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        return (
            <>
                {parts.map((part, index) =>
                    typeof part === 'string'
                        ? part.split('\n').map((line, lineIndex) =>
                            <Fragment key={`line-${index}-${lineIndex}`}>
                                {line}
                                {lineIndex < part.split('\n').length - 1 && <br />}
                            </Fragment>
                        )
                        : part
                )}
            </>
        );
    }

    const getGradeFromScore = (score: number) => {
        if (score === null || score === undefined) {
            return null;
        }

        if (score >= 97) {
            return 'A+';
        } else if (score >= 93) {
            return 'A';
        } else if (score >= 90) {
            return 'A-';
        } else if (score >= 87) {
            return 'B+';
        } else if (score >= 83) {
            return 'B';
        } else if (score >= 80) {
            return 'B-';
        } else if (score >= 77) {
            return 'C+';
        } else if (score >= 73) {
            return 'C';
        } else if (score >= 70) {
            return 'C-';
        } else if (score >= 67) {
            return 'D+';
        } else if (score >= 63) {
            return 'D';
        } else if (score >= 60) {
            return 'D-';
        } else {
            return 'F';
        }
    }

    const renderMaestroBadgeLine = () => {
        return (
            <>
                <Container
                    className="maestroBannerBadgeLine"
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <MaestroBadge className="maestroBadge" subtitle="Report" />
                </Container>
                {countdown &&
                    <div
                        className="countdownContainer"
                        style={{
                            fontWeight: 400,
                            fontSize: 12,
                            color: Colors.greyDark,
                            marginTop: -8,
                        }}
                    >
                        {dayjs(generationTime).add(24, 'hours').diff(dayjs(), 'hours') < 1 ?
                            `Next report in ${countdown}`
                            :
                            `Next report on ${dayjs(generationTime).add(24, 'hours').format('MMM Do, h:mm A')}`
                        }
                    </div>
                }
            </>
        )
    }

    const renderStatusMessage = () => {
        const {
            trainingMessage = null,
            trainingStatusPercentage = null,
            subtitle = null
        } = maestroData ?? {};

        if (isError) {
            return (
                <div className="errorContainer" style={sty.errorContainer}>
                    {renderMaestroBadgeLine()}

                    <div style={{ color: Colors.red }}>
                        {errorMessage}
                    </div>
                    {/* <SecondaryButton onClick={() => fetchMaestroData()}>Try again</SecondaryButton> */}
                </div>
            )
        }

        switch (maestroStatus) {
            case MAESTRO_STATUS.LOADING:
                return (
                    <div className="loadingContainer">
                        {renderMaestroBadgeLine()}
                        <div>Analyzing your campaign...</div>
                    </div>
                )
            case MAESTRO_STATUS.TRAINING:
                return (
                    <div className="trainingContainer">
                        {renderMaestroBadgeLine()}
                        <div className="subtitle">
                            {subtitle}
                        </div>
                        <div className="loadingBarContainer" style={sty.loadingBarContainer}>
                            <div className="loadingBar" style={sty.loadingBar}>
                                <motion.div
                                    initial={{ width: '0%' }}
                                    animate={{ width: `${trainingStatusPercentage}%` }}
                                    transition={{ duration: 1, ease: "easeOut" }}
                                    className="loadingBarProgress"
                                    style={{
                                        height: '100%',
                                        backgroundColor: 'rgba(136, 0, 255, 0.40)',
                                        borderRadius: 12,
                                    }}
                                />
                            </div>
                            <div>
                                {trainingStatusPercentage}% trained
                            </div>
                        </div>
                        <div style={{ color: Colors.greyDark }}>
                            {trainingMessage}
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    const renderMaestroStars = () => {
        const {
            starScore = null
        } = maestroData ?? {};

        if (starScore === null) {
            return null;
        }

        return (
            <div className="maestroStarsContainer" style={sty.maestroStarsContainer}>
                {renderMaestroStarScore(starScore >= 1)}
                {renderMaestroStarScore(starScore >= 2)}
                {renderMaestroStarScore(starScore >= 3)}
            </div>
        )
    }

    const renderMaestroStarScore = (isFilled = false) => {
        return (
            <img src={isFilled ? MaestroStarScore : MaestroStarScoreOutline} alt="Maestro Star Score" style={sty.maestroStarScore} />
        )
    }

    const getGradeSubtitleFromStarScore = () => {
        const {
            starScore = null
        } = maestroData ?? {};

        if (starScore === null) {
            return null;
        }

        switch (starScore) {
            case 1:
                return 'Could be improved';
            case 2:
                return 'Good Campaign';
            case 3:
                return 'Above Average Campaign';
            default:
                return 'Not Rated';
        }
    }

    const renderMaestroScoreAsGrade = () => {
        const {
            score = null
        } = maestroData ?? {};

        if (score === null) {
            return null;
        }

        return (
            <div className="gradeContainer" style={sty.gradeContainer}>
                {/* <div className="grade" style={sty.grade}>
                        {getGradeFromScore(score)}
                    </div>
                    <div className="gradeSubtitle" style={sty.gradeSubtitle}>
                        Campaign Score
                    </div>
                    <ArcLoadingBar percentage={score} /> */}
                {renderMaestroStars()}
                <div className="gradeSubtitle" style={{ marginTop: 8, ...sty.gradeSubtitle }}>
                    {getGradeSubtitleFromStarScore()}
                </div>
            </div>
        )
    }

    const renderSideImage = () => {
        let maestroStatusBody = null;

        if (isError) {
            maestroStatusBody = (
                <div
                    className="errorSymbol"
                    style={{
                        display: 'flex',
                        gap: 16,
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: Colors.red,
                        fontSize: 24,
                        fontWeight: 500
                    }}
                >
                    ×
                </div>
            )
        } else {
            switch (maestroStatus) {
                case MAESTRO_STATUS.LOADING:
                    maestroStatusBody = <LoaderV2 />
                    break
                case MAESTRO_STATUS.TRAINING:
                    maestroStatusBody = <img className="maestroLogo" style={sty.maestroLogo} src={MaestroLogo} alt="Maestro Logo" />
                    break
                default:
                    return null
            }
        }

        return (
            <div
                className="maestroSideContainer"
                style={sty.maestroSideContainer}
            >
                {maestroStatusBody}
            </div>
        )
    }

    const renderMaestroInsights = () => {
        const {
            insights = null
        } = maestroData ?? {};

        if (insights === null) {
            return null;
        }

        return (
            <div className="maestroInsights" style={sty.maestroGenericContentText}>
                <div style={{ fontWeight: 500 }}>Insights</div>
                <div className="insightsRow" style={sty.insightsRow}>
                    {insights.map((insight, index) => (
                        <div key={index} className="maestroInsight" style={sty.maestroInsight}>
                            <div className="maestroInsightHeader" style={sty.maestroInsightHeader}>
                                <img src={MaestroLogo} alt="Maestro Logo" style={sty.maestroLogoInsight} />
                                <div>Insight</div>
                            </div>
                            <div>{textReplacer(insight.text, insight.replacements ?? [])}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    // const renderMaestroRecommendedActions = () => {
    //     const {
    //         post_campaign_recommendations = null
    //     } = maestroData ?? {};

    //     if (post_campaign_recommendations === null) {
    //         return null;
    //     }

    //     return (
    //         <div className="maestroRecommendedActionsContainer" style={sty.maestroRecommendedActionsContainer}>
    //             <div className="sectionHeaderTitle" style={sty.sectionHeaderTitle}>Recommended Actions</div>
    //             <div className="maestroRecommendedActionsRow" style={sty.maestroRecommendedActionsRow}>
    //                 {post_campaign_recommendations.map((action, index) => (
    //                     <SecondaryButton
    //                         key={index}
    //                         text={action.title}
    //                     />
    //                 ))}
    //             </div>
    //         </div>
    //     );
    // }

    const renderViewButton = () => {
        if (maestroStatus !== MAESTRO_STATUS.READY) {
            return null;
        }

        return (
            <TextButton onClick={() => setIsMaestro(!isMaestro)}>
                {isMaestro ? 'View Less' : 'View your full report'}
            </TextButton>
        )
    }

    const renderMainMessage = () => {
        const {
            mainMessage = null,
        } = maestroData ?? {};

        if (mainMessage === null) {
            return null;
        }

        return (
            <div className="maestroPerformanceSummary" style={sty.maestroGenericContentText}>
                <div className="maestroPerformanceSummaryTitle" style={{ fontWeight: 500 }}>
                    Performance Summary
                </div>
                {mainMessage?.map((message, index) => (
                    <div key={index} className="maestroPerformanceSummaryMessage">
                        {textReplacer(message?.text, message?.replacements ?? [])}
                    </div>
                ))}
            </div>
        )
    }

    const renderTipsToImprove = () => {
        const {
            tipsToImprove = null,
        } = maestroData ?? {};

        if (tipsToImprove === null) {
            return null;
        }

        return (
            <div className="maestroTipsToImprove" style={sty.maestroGenericContentText}>
                <div style={{ fontWeight: 500 }}>Tips to Improve your Score</div>
                <ol
                    style={{
                        listStyle: 'decimal',
                        paddingLeft: '20px',
                    }}
                >
                    {tipsToImprove?.map((message, index) => (
                        <li key={index}>{textReplacer(message, [])}</li>
                    ))}
                </ol>
            </div>
        )
    }

    const renderMaestroMainContent = () => {
        if (maestroStatus !== MAESTRO_STATUS.READY) {
            return null;
        }

        return (
            <div className="maestroMainContentReady" style={sty.maestroMainContentReady}>
                <div
                    className="maestroTitleContainer"
                    style={mStyle(sty, 'maestroTitleContainer')}
                >
                    {renderMaestroScoreAsGrade()}
                    <div className="maestroTagTitleContainer" style={sty.maestroTagTitleContainer}>
                        {renderMaestroBadgeLine()}
                        <Container className="maestroTitleInnerContainer" style={sty.maestroTitleInnerContainer}>
                            <Subtitle1 className="maestroMainContentTitle">
                                {maestroData?.title}
                            </Subtitle1>
                            <Body2>{maestroData?.subtitle}</Body2>
                        </Container>
                        <Container
                            className="maestroTitleInnerContainer"
                            display={'flex'} flexDirection={'row'} gap={'16px'}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                        >
                            <Container
                                className="maestroTitleInnerContainer"
                                display={'flex'} flexDirection={'row'} gap={'4px'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                borderRadius={'8px'}
                                padding={'4px 8px'}
                                border={'1px solid rgba(136,0,255,0.14)'}
                            >
                                <Body2
                                    className="maestroTitleInnerContainer"
                                    color={Colors.textColor}
                                    style={{ fontWeight: 600 }}
                                >
                                    3
                                </Body2>
                                <Body2>Insights</Body2>
                            </Container>
                            {renderViewButton()}
                        </Container>
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: isMaestro ? 1 : 0, height: isMaestro ? 'auto' : 0 }}
                            transition={{ duration: 0.4 }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 24,
                            }}
                        >
                            {renderMainMessage()}
                            {renderMaestroInsights()}
                            {renderTipsToImprove()}

                            {/* {renderDontWorryMessage()} */}
                            {/* {renderWhyDidIGetThisScore()} */}
                            {/* {renderProsAndCons()} */}
                            {/* {renderMaestroRecommendedActions()} */}
                        </motion.div>
                    </div>
                </div>

                {/* {renderViewButton()} */}
            </div>
        )
    }

    return (
        <div
            className={`maestroBannerComponent ${className}`}
            style={{
                ...sty.maestroBannerComponent,
                alignItems: maestroStatus === MAESTRO_STATUS.READY ? 'flex-start' : 'center',
                ...style
            }}
        >
            {!isProBrand ?
                <UpgradeToProChip
                    className="PopoverCampaignAnnotationsProChip"
                    type="label"
                    withMargin={false}
                    mainContainerProps={{
                        width: 'auto',
                        flexShrink: '0'
                    }}
                />
                :
                <>
                    {(maestroStatus === MAESTRO_STATUS.LOADING || maestroStatus === MAESTRO_STATUS.TRAINING || isError) &&
                        <div
                            className="maestroLoadingTrainingContainer"
                            style={sty.maestroLoadingTrainingContainer}
                        >
                            {renderSideImage()}
                            {renderStatusMessage()}
                        </div>
                    }
                    {!isError && renderMaestroMainContent()}
                </>
            }
        </div>
    );
};

const sty: Record<string, CSSProperties> = {
    maestroBannerComponent: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        alignItems: 'center',
        padding: 16,
        borderRadius: 8,
        backgroundImage: `url(${MaestroBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center -75px',
        minWidth: 0,
        alignSelf: 'flex-start',
        width: '100%',
    },
    maestroAiTag: {
        width: 98,
    },
    maestroBody: {
        display: 'flex',
        minWidth: 0,
    },
    maestroSideContainer: {
        padding: 16,
        maxWidth: 134,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 12,
        width: '100%',
        overflow: 'hidden',
        flexShrink: 0,
    },
    maestroLogo: {
        width: 104,
    },
    maestroMainContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        minWidth: 0,
    },
    loadingBar: {
        width: '100%',
        height: 16,
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderRadius: 100,
        maxWidth: 140,
        flexShrink: 0,
    },
    loadingBarContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    gradeContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        overflow: 'hidden',
        maxWidth: 134,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 12,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 8,
        paddingRight: 8,
    },
    grade: {
        fontSize: 40,
        fontWeight: 600,
        marginBottom: -12,
    },
    gradeSubtitle: {
        fontSize: 12,
        color: Colors.greyDark,
    },
    maestroChart: {
        width: '100%',
        marginTop: 16,
        paddingLeft: 4,
        paddingRight: 4,
    },
    maestroMainContentTitle: {
    },
    maestroMainContentReady: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        minWidth: 0,
    },
    maestroTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 16,
        overflow: 'hidden',
    },
    maestroTitleContainer_mobile: {
        flexDirection: 'column',
    },

    sectionHeaderTitle: {
        fontSize: 16,
        fontWeight: 500,
        marginTop: 16,
    },

    maestroInsightsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    insightsRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        overflowX: 'scroll',
        scrollbarWidth: 'none',  // For Firefox
        msOverflowStyle: 'none',  // For Internet Explorer and Edge
        '&::-webkit-scrollbar': {
            display: 'none',  // For Chrome, Safari, and Opera
        },
    } as CSSProperties,
    maestroInsight: {
        padding: 12,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 12,
        maxWidth: 240,
        width: '100%',
        flexShrink: 0,
    },
    maestroLogoInsight: {
        width: 22,
    },
    maestroInsightHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        color: Colors.greyDark,
        marginLeft: -4,
        fontSize: 12,
    },

    maestroRecommendedActionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },

    maestroRecommendedAction: {
        fontSize: 12,
    },
    maestroRecommendedActionsRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        overflowX: 'scroll',
        scrollbarWidth: 'none',  // For Firefox
        msOverflowStyle: 'none',  // For Internet Explorer and Edge
        '&::-webkit-scrollbar': {
            display: 'none',  // For Chrome, Safari, and Opera
        },
    } as CSSProperties,

    donutStyle: {
        width: '134px',
        height: '67px',
        background: 'conic-gradient(#cddc39 0deg, #ffeb3b 100deg, #ff9800 180deg)',
        borderRadius: '134px 134px 0 0',
        position: 'relative',
        overflow: 'hidden',
    },
    innerCircleStyle: {
        position: 'absolute',
        width: '90px',
        height: '90px',
        backgroundColor: 'white',
        borderRadius: '50%',
        top: '22px',
        left: '22px',
    },
    maestroTagTitleContainer: {
        display: 'flex',
        gap: 16,
        flexDirection: 'column'
    },
    prosAndConsContainer: {
        marginLeft: 24,
    },
    maestroGenericContentText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    dontWorryMessage: {
        color: Colors.purple,
        fontStyle: 'italic',
        fontSize: 14,
        maxWidth: 320,
    },
    errorContainer: {
        width: '100%',
    },
    maestroLoadingTrainingContainer: {
        display: 'flex',
        gap: 16,
        alignItems: 'center',
        width: '100%',
    },
    maestroTitleInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    maestroStarsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },
    maestroStarScore: {
        width: 32,
    },
}

export default MaestroBanner;
