import { CurrentBrand, SongAdsRecord } from "types/global"
import Axios from "helpers/Interceptor"
import { getAdsComission } from "modules/Utils"
import { checkIsProTier } from "Hooks/CurrentBrandContext"
import { SongAdsCampaignSubtype } from "."
import { CAMPAIGN_TYPES } from "modules/Const"

export const saveDraft = async (args: {
  dataToSend: Record<string, unknown>
  campaignDraftData?: Record<string, unknown> | null
  audiencesData: Record<string, unknown>
  currentBrand: CurrentBrand;
  subtype: SongAdsCampaignSubtype;
}) => {
  const {
    dataToSend,
    campaignDraftData,
    audiencesData,
    currentBrand,
    subtype
  } = args
  const campaignDraftId = campaignDraftData ? campaignDraftData.id : null
  const campaignMetadata = campaignDraftData?.campaign_metadata as Record<
    string,
    unknown
  >
  const brand =
    (campaignMetadata as { campaign_input: { brand: CurrentBrand } })
      ?.campaign_input.brand || currentBrand
  const brandId = brand.id
  const campaignInput =
    (campaignMetadata?.campaign_input as Record<string, unknown>) || {}
  const { geographicTargets, interestTargetingAudiences } = audiencesData
  const {
    selectedFB_page,
    selectedInstaPage,
    selectedFBAdAccount,
    selectedInstagramPost,
    addVisualSelected,
    captions,
    budgetValue,
    startDate,
    endDate,
    selectedTab,
    uploadedImages,
    spotifyAssets,
    appleAssets,
    youtubeAssets,
    demographics,
    conversionsEnabled,
    customAudiences = [],
    additionalPageAudiences = []
  } = dataToSend
  const recordSelected = dataToSend.recordSelected as SongAdsRecord
  const isProBrand = checkIsProTier(currentBrand)
  const adsComission = getAdsComission(isProBrand)
  const marketingBudget = Number(((budgetValue as number) - (budgetValue as number) * adsComission).toFixed(2))
  const automationFee = Number(
    ((budgetValue as number) * adsComission).toFixed(2)
  )

  const campaignTypeValue = subtype === "grow_playlist_followers" ? CAMPAIGN_TYPES.grow_playlist_followers : CAMPAIGN_TYPES.record_streams

  try {
    const response = await Axios.put(`campaign/brand/${brandId}/save-draft`, {
      id: campaignDraftId,
      brand_id: brandId,
      campaign_metadata: {
        ...campaignMetadata,
        access_token: currentBrand?.connections?.facebook_page?.access_token || null,
        campaign_type: campaignTypeValue,
        status_notes: null,
        budget: marketingBudget,
        automationFee,
        type: null,
        creative: selectedInstagramPost,
        startDate,
        endDate,
        content: {
          url: recordSelected.url,
          name: recordSelected.name,
          slug: recordSelected.slug,
          type: recordSelected.type,
          thumbnail_url: recordSelected.thumbnail_url,
        },
        saved_draft_step: selectedTab,
        add_visual_selected: addVisualSelected,
        campaign_input: {
          ...campaignInput,
          fbAdAccount: selectedFBAdAccount,
          price_data: {
            name: `${budgetValue} FB/IG Ads Campaign for "${recordSelected.name}"`,
            price: budgetValue,
            description: `"${recordSelected.name}" (Increase Music Streams)`,
          },
          campaignName: null,
          igMedia: selectedInstagramPost || null,
          fbPixel: null,
          fbPage: selectedFB_page || null,
          igPage: selectedInstaPage,
          cta: null,
          link: null,
          caption: null,
          goal: subtype,
          interestTargetingAudiences,
          targetedCountries: geographicTargets,
          targetingDemographics: demographics,
          record: recordSelected,
          brand: {
            ...currentBrand,
            connections: {
              ...currentBrand.connections,
              facebook_ad_account: selectedFBAdAccount,
              facebook_page: selectedFB_page,
              instagram_page: selectedInstaPage,
            },
          },
          captions,
          budget: marketingBudget,
          startDate,
          endDate,
          customAudiences,
          additionalPageAudiences,
          assets: {
            all: uploadedImages,
            spotify: spotifyAssets,
            apple: appleAssets,
            youtube: youtubeAssets,
          },
          conversionsEnabled,
        },
        campaign_state: {
          brand: currentBrand,
          record: recordSelected,
          accounts: {
            fbPage: selectedFB_page || null,
            igPage: selectedInstaPage,
            fbPixel: null,
            fbAdAccount: selectedFBAdAccount,
          },
          campaign: {
            details: {
              budget: marketingBudget,
              endDate,
              startDate,
            },
            creative: {
              cta: null,
              assets: {
                all: uploadedImages,
                spotify: spotifyAssets,
                apple: appleAssets,
                youtube: youtubeAssets,
              },
              igMedia: selectedInstagramPost || null,
              captions,
              mainCaption: null,
            },
            targeting: {
              targetedCountries: geographicTargets,
              interestTargetingAudiences,
              customAudiences,
              additionalPageAudiences
            },
          },
        },
        campaign_platforms: ["fb"],
      },
    })
    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error when saving draft: ", error)
    return { data: null, error }
  }
}
