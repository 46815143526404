import { EnterArtistContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import { Container } from "styles/shared";
import OptionCard from "./OptionCard";
import CreatorAccountTypeIcon from "./images/creator-account-type.png"
import TeamAccountTypeIcon from "./images/team-account-type.png"
import { Headline3 } from "components/shareable/Typography";
import { OnboardingAccountTypes, useOnboarding } from "Hooks/OnboardingContext";
import useScreen from "Hooks/useScreen";
import BackButton from "../BackButton";
import Colors from "modules/Colors";

const AccountType = () => {
    let titleText = 'Get Started'
    let descriptionText = `Setup your account and grow your fanbase with Symphony.`
    const { mobileView } = useScreen();
    const {
        setCurrentAccountType,
        handleGoBack,
        handleGoNext,
        showBackButton,
        showBackButtonText
    } = useOnboarding()

    const options = [
        {
            image: CreatorAccountTypeIcon,
            title: "I'm a creator myself",
            description: "Perfect for individual creators, artists, influencers, and individuals who want to make a personal account.",
            buttonText: "Get Started",
            onClick: () => {
                setCurrentAccountType(OnboardingAccountTypes.CREATOR)
                handleGoNext()
            }
        },
        {
            image: TeamAccountTypeIcon,
            title: "I work with one or more creators",
            description: "Perfect for agencies, labels, managers, and organizations that work with multiple artists and creators.",
            buttonText: "Get Started",
            onClick: () => {
                setCurrentAccountType(OnboardingAccountTypes.TEAM)
                handleGoNext()
            }
        }
    ]

    return (
        <>
            <LeftColumn className="leftColumnAccountType" width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton() && mobileView &&
                    <BackButton
                        onBack={handleGoBack}
                        text={showBackButtonText()}
                    />
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton() && !mobileView &&
                        <BackButton
                            onBack={handleGoBack}
                            text={showBackButtonText()}
                        />
                    }
                    <MainTitle>{titleText}</MainTitle>
                    <SubTitle>{descriptionText}</SubTitle>
                </Titles>
                <EnterArtistContainer style={{ marginTop: -24 }}>
                    <Headline3 color={Colors.textColor}>
                        What kind of account describes you best?
                    </Headline3>
                    <Container display="flex" flexDirection={mobileView ? "column" : "row"} gap="24px"
                        style={{
                            overflow: 'visible'
                        }}>
                        {options.map((option, index) => {
                            return (
                                <OptionCard
                                    key={index}
                                    imageSrc={option.image}
                                    title={option.title}
                                    description={option.description}
                                    buttonText={option.buttonText}
                                    onClick={option.onClick}
                                />
                            )
                        })}
                    </Container>
                </EnterArtistContainer>
            </LeftColumn>
            <RightColumn display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}

export default AccountType