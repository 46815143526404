import { ReactNode } from 'react';
import Colors from 'modules/Colors';
import { clsx } from "modules/Utils";

interface BannerProps {
    className?: string;
    variant: 'warning' | 'error' | 'accent';
    classes?: string;
    children: ReactNode;
};

export const Banner = ({
  variant,
  children,
  classes = '',
  className
}: BannerProps) => {
    let backgroundColor;

    switch (variant) {
        case 'warning':
            backgroundColor = Colors.PAPER_WARNING_CONTAINER;
            break;
        case 'error':
            backgroundColor = Colors.PAPER_ERROR_CONTAINER;
            break;
        case 'accent':
            backgroundColor = Colors.PAPER_ACCENT_CONTAINER;
            break;
    }

    return (
        <div 
            className={clsx('bannerComponent', className, 'w-full rounded-lg', classes)}
            style={{ backgroundColor }}
        >
            {children}
        </div>
    );
};
