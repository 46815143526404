import { clsx } from "modules/Utils";
import { CSSProperties, ReactNode } from "react";

interface MainContentContainerProps {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  flex?: boolean;
  backgroundColor?: string;
}

const MainContentContainer = ({
  className = '',
  style = {},
  children,
  flex,
  backgroundColor,
}: MainContentContainerProps) => {

  const classNames = clsx('mainContentContainerComponent', className);
  return (
    <div
      style={{ ...style, backgroundColor }}
      className={classNames}
      // className={className ?
      //   classNames
      //   :
      //   `${classNames} h-full w-full 
      //   lg:w-12/12 xl:w-12/12 mx-auto lg:pl-16 lg:pr-8 lg:box-border
      //   relative ${flex ? "flex justify-center align-center" : ""}`
      // }
    >
      {children}
    </div>
  );
}

const sty: Record<string, CSSProperties> = {}

export default MainContentContainer;