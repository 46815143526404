import {
  useState,
  Fragment,
  useEffect,
  useContext,
  FunctionComponent,
} from "react";
import { CurrentBrand, Fan } from "types/global";
import Fanbase from "./Components/Fanbase";
import { TabSelector } from "helpers/TabSelector";
import { TabPanel } from "react-headless-tabs";
import isEqual from "lodash/isEqual";
import {
  TabsPanelsContainer,
  DownloadCsvButton,
  FansTabButtonContainer,
  TabsContainer,
  FansContainer,
  DefaultImage,
  UnlockBannerContainer,
  BannerContainer,
} from "./styles";
import FanbaseTasks from "./Components/FanbaseTasks";
import { FanbaseContext } from "Hooks/FanbaseContext";
import { CardMetricsContainer } from "./styles";
import { getFansMetrics, getDownloadSignedUrl } from './api';
import { FansMetrics } from './constants';
import CardMetrics from './Components/CardMetrics';
import emptyUserPink from "assets/images/empty-user-pink.png";
import YellowStar from "assets/images/yellow-star.png";
import Grid from '@material-ui/core/Grid';
import FansDetailsModal from './Components/FansDetailsModal';
import { getStatusToShowPaymentFailedBanner } from "modules/Utils";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import LoadingLottie from 'components/Loader/LoadingLottie';
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import { pageView, track } from "analytics";
import CardTitle from "components/shareable/CardTitle/CardTitle";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import { MainContainer, StyledNavBar } from "styles/shared";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import Intercom from "helpers/Intercom";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import useScreen from "Hooks/useScreen";
import { NAV_BAR_HEIGHT_MOBILE } from "modules/Const";
import PageSymphony from "components/shareable/PageSymphony";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { AdminContainerForceForeverSaves } from './Components/AdminContainerForceForeverSaves';
import { isSymphonyAdmin } from "helpers/admin";

enum Tabs {
  FANBASE = "Fanbase",
  CAMPAIGNS = "Campaigns",
}

const { FANBASE } = Tabs;

/** Props for the Fans component */
interface Props {
  loadedBrands?: CurrentBrand[];
  reloadBrands?: () => void;
}

const Fans: FunctionComponent<Props> = () => {
  const { completed, fetchFanbaseTasks } = useContext(FanbaseContext);
  const { currentBrand, isProBrand, isBrandAdmin } = useContext(CurrentBrandContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { mobileView, largeView } = useScreen();
  const [selectedTab, setSelectedTab] = useState(FANBASE);
  const [hasFans, setHasFans] = useState<boolean>(false);
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [fansMetrics, setFansMetrics] = useState<FansMetrics>({
    fanbaseGrowth: "0.0",
    foreverPresaveFans: 0,
    foreverPresaveFansGrowth: "0.0",
    totalFanbase: 0,
  });
  const [showFanModal, setShowFanModal] = useState<boolean>(false);
  const [selectedFan, seSelectedFan] = useState<Fan | null>(null)
  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const {
    remainingFreeTrial,
    isOrganizationInactive
  } = useCurrentTeam()

  const isAdminUser = currentUser ? isSymphonyAdmin(currentUser) : false;

  useEffect(() => {
    fetchFanbaseTasks();
    pageView("Fans");
  }, []);

  const handleOpenFanModal = (fan: Fan) => {
    track("Viewed Fan Details")
    seSelectedFan(fan)
    setShowFanModal(true)
  }

  const handleCloseFanModal = () => setShowFanModal(false)

  const handleSelectFanbaseTab = () => {
    setSelectedTab(FANBASE);
  };

  const getFanbaseMetrics = async (brandId: number) => {
    setLoadingMetrics(true)
    setHasFans(false)
    const { data, error } = await getFansMetrics(brandId);
    if (error) {
      setLoadingMetrics(false);
      setHasFans(false);
      return;
    }
    const formattedFansMetrics = data as FansMetrics;
    setFansMetrics(formattedFansMetrics);
    setHasFans(formattedFansMetrics.totalFanbase > 0)
    setLoadingMetrics(false);
  }

  const handleDownloadCsv = async () => {
    if (!isProBrand) {
      track("Tried Downloading Fans CSV")
      handleOpenUpgradeToProModal({
        source: "Fans Tab - CSV Download"
      });
      return;
    } else {
      track("Download Fans CSV")
    }

    setIsDownloading(true);

    // then download the csv
    const downloadUrl = await getDownloadSignedUrl(currentBrand?.id!);
    if (!downloadUrl.error) {
      window.location.assign(downloadUrl.data);
    } else {
      Intercom.showNewMessage("I'd like to download my Fans CSV. Can you help me with that?")
    }
    setIsDownloading(false);
  }

  useEffect(() => {
    getFanbaseMetrics(currentBrand?.id!)
  }, [currentBrand?.id])

  if (loadingMetrics) return (
    <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
      <NewLoader black />
      <p className="mt-1 text-center">Loading Fans...</p>
    </div>
  )

  return (
    <PageSymphony
      className="fansComponent"
      isContent
    >
      {(showPaymentFailedBanner || remainingFreeTrial > 0 || isOrganizationInactive) && (
        <BannerContainer>
          {showPaymentFailedBanner && <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />}
          <OrganizationSubscriptionStatusBanner onlyShowFailedPayments={true} />
        </BannerContainer>
      )}
      <CardTitle
        title="Fans"
        subtitle="Your fans, all in one place."
        additionalContent={
          <DefaultLearningIndicator
            type={LearningIndicatorType.FANS}
            text="Learn how to build your Fans list"
          />}

        additionalButtons={(
          <>
            {false && <button >Message your fans</button>}
          </>
        )}
      />
      {isAdminUser && <AdminContainerForceForeverSaves />}
      <CardMetricsContainer
        padding={mobileView ? "0 8px 0" : "0px"}
      >
        <CardMetrics
          image={<DefaultImage src={emptyUserPink} width='18px' height='24px' />}
          percentage={fansMetrics.fanbaseGrowth}
          title='Fans'
          total={fansMetrics.totalFanbase}
          mobile={mobileView}
          key='total-fans-metrics'
        />
        <CardMetrics
          image={<DefaultImage src={YellowStar} width='24px' height='24px' />}
          percentage={fansMetrics.foreverPresaveFansGrowth}
          title={(
            <div className="flex flex-row gap-1">
              Forever Saves <UpgradeToProChip className="cardMetricsForeverPresaveFansProChip" type="gem" color="purple" height={18} width={18} />
            </div>

          )}
          total={fansMetrics.foreverPresaveFans}
          mobile={mobileView}
          key='forever-presave-fans'
          elementKey='forever-presave-fans'
        />
      </CardMetricsContainer>
      {!completed && mobileView && isBrandAdmin && <FanbaseTasks />}
      {!isProBrand && hasFans && (
        <UnlockBannerContainer padding={mobileView ? "0 8px 0" : "0px"}>
          <UnlockDataBanner
            source="Fans Tab - Unlock Data"
            title="Learn exactly who your fans are"
            description="Go Pro to see your fans name + locations, down to the city"
            image={UnlockDataImage}
            hideImage={mobileView}
            imageMaxWidth='230px'
          />
        </UnlockBannerContainer>
      )}
      <TabsContainer
        borderRadius={mobileView ? "0px" : "8px"}
        padding={mobileView ? "16px 8px" : "16px 24px"}
      >
        <StyledNavBar>
          <Fragment>
            <TabSelector
              isActive={isEqual(selectedTab, FANBASE)}
              onClick={handleSelectFanbaseTab}
            >
              {FANBASE}
            </TabSelector>
          </Fragment>
          <FansTabButtonContainer>
            {isDownloading
              ? <Grid className="w-6">
                <LoadingLottie
                  black
                  height={24}
                  width={24}
                />
              </Grid>
              : hasFans && isBrandAdmin && <DownloadCsvButton onClick={handleDownloadCsv}>  {/*TODO: change onclick to the download functionality handleDownloadCsv*/}
                <div className="flex items-center justify-center flex-row">
                  <div className="mr-1.5">
                    <UpgradeToProChip
                      className="downloadCsvProChip"
                      type="gem"
                      color="purple"
                      height={18}
                      width={18}
                    />
                  </div>
                  <p style={{
                    fontWeight: 400
                  }}>
                    Download CSV
                  </p>
                </div>
              </DownloadCsvButton>
            }
          </FansTabButtonContainer>
        </StyledNavBar>
        <TabsPanelsContainer>
          <TabPanel hidden={!isEqual(selectedTab, FANBASE)}>
            <Fanbase
              loading={loadingMetrics}
              hasFans={hasFans}
              openFanDetailModal={handleOpenFanModal}
            />
          </TabPanel>
        </TabsPanelsContainer>
      </TabsContainer>
      {!completed && !mobileView && isBrandAdmin && <FanbaseTasks />}
      {selectedFan &&
        <FansDetailsModal
          closeModal={handleCloseFanModal}
          open={showFanModal}
          fan={selectedFan}
        />
      }
    </PageSymphony>
  );
};

export default Fans;
