import { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { CAMPAIGN_CARDS, availableGoals } from "../../utils/marketing";
import { useHistory } from "react-router-dom";
import MaestroCampaignRecommender from "./MaestroCampaignRecommender";
import useStyles from "./styles";
import CreateCampaignCard from "./CreateCampaignCard";
import { trackNewCampaign } from "../CampaignsSection/analytics";
import HorizontalModalWithVideo from "components/shareable/Modal/with-video";
import ModalSymphony from "components/shareable/ModalSymphony";
import useScreen from "Hooks/useScreen";

interface CreateNewCampaignModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateNewCampaignModal = ({
    isOpen,
    onClose
}: CreateNewCampaignModalProps) => {
    const { mobileView } = useScreen();
    const { currentBrand } = useContext(CurrentBrandContext);
    const classes = useStyles();

    const [selectedModalHelper, setSelectedModalHelper] = useState<string | null>(null);
    const [showModalHelper, setShowModalHelper] = useState<boolean>(false);

    const history = useHistory()
    // when selectedModalHelper is set to null, 
    // hide it after 
    useEffect(() => {
        if (selectedModalHelper) {
            setShowModalHelper(true)
        }
    }, [selectedModalHelper])

    const closeModalHelper = () => {
        setShowModalHelper(false)
        setTimeout(() => {
            setSelectedModalHelper(null)
        }, 300)
    }

    return (
        <>
            {!showModalHelper &&
                <ModalSymphony
                    className="createNewSiteModalComponent"
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Create a Campaign"
                    subtitle="Run automated marketing campaigns to grow your streams, views, and followers."
                    isIsolateModalContent
                    maxWidth={1024}
                    fullScreen={mobileView}
                >
                    <MaestroCampaignRecommender
                        askMaestroSource="Marketing"
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingBottom: 16,
                            maxWidth: 780,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    />
                    <div className={classes.playbooksContainer}>
                        <div className={`${classes.playbooksContent} px-6 pb-4`}>
                            <div className="mb-4 w-full flex items-center flex-row items-center justify-center">
                                <p className="mx-3 text-gray-500">Or Choose a Playbook:</p>
                                <div className="h-0.5 flex-1 w-auto bg-gray-300"></div>
                            </div>
                            <div className="flex mt-4" >
                                <div className={classes.campaignContainer}>
                                    <div className={classes.rightSection}>
                                        <div id="all">
                                            <Grid container spacing={2}>
                                                {CAMPAIGN_CARDS.sort((campaignA: any, campaignB: any) => {
                                                    if (campaignA.underMaintenance && !campaignB.underMaintenance) {
                                                        return 1; // campaignA goes after campaignB
                                                    }
                                                    if (!campaignA.underMaintenance && campaignB.underMaintenance) {
                                                        return -1; // campaignA goes before campaignB
                                                    }
                                                    return 0; // no change in order
                                                })
                                                    .map((campaign, index) => {
                                                        if (campaign.underMaintenance) {
                                                            return <Grid item key={index} xs={12} sm={6} md={4}>
                                                                <CreateCampaignCard
                                                                    requiresPro={campaign.requiresPro}
                                                                    backgroundColor={campaign.backgroundColor}
                                                                    title={campaign.title}
                                                                    description={campaign.description}
                                                                    imageUrl={campaign.imageUrl}
                                                                    statusMessage={campaign.underMaintenance ? campaign.statusMessage : null}

                                                                    status={campaign.underMaintenance ? "maintenance" : null}
                                                                />
                                                            </Grid>
                                                        } else {
                                                            return <Grid item key={index} xs={12} sm={6} md={4}>

                                                                <CreateCampaignCard
                                                                    requiresPro={campaign.requiresPro}

                                                                    link={`/marketing/new/${campaign.link}`}
                                                                    onLinkClick={() => {
                                                                        trackNewCampaign({
                                                                            type: campaign.title,
                                                                            from: 'list',
                                                                            zeroState: false,
                                                                            aiQuestion: null,
                                                                            currentBrand: currentBrand,
                                                                            key: campaign.id
                                                                        })
                                                                    }}
                                                                    backgroundColor={campaign.backgroundColor}
                                                                    onHelpClick={!campaign.helperContent ? undefined : () => setSelectedModalHelper(campaign.id)}

                                                                    title={campaign.title}
                                                                    description={campaign.description}
                                                                    imageUrl={campaign.imageUrl}
                                                                    status={campaign.underMaintenance ? "maintenance" : null}
                                                                    statusMessage={campaign.underMaintenance ? campaign.statusMessage : null}
                                                                    isConversionsCampaign={campaign.isConversionsCampaign}
                                                                />
                                                            </Grid>
                                                        }
                                                    })}
                                            </Grid>
                                        </div>
                                        {/* Add similar sections for other campaign categories */}
                                        <div id="growYourReleases">
                                            {/* ... */}
                                        </div>
                                        <div id="growYourFanbase">
                                            {/* ... */}
                                        </div>
                                        <div id="growTraffic">
                                            {/* ... */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalSymphony>
            }
            <HorizontalModalWithVideo
                icon={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].icon : ""}
                open={showModalHelper}
                closeModal={closeModalHelper}
                title={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].title : ""}
                innerContent={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].helperContent : null}
                onClickButton={() => {
                    if (selectedModalHelper) {
                        trackNewCampaign({
                            type: availableGoals[selectedModalHelper].title,
                            from: 'helper-popup',
                            zeroState: true,
                            aiQuestion: null,
                            currentBrand: currentBrand,
                            key: selectedModalHelper,

                        })

                        history.push(`/marketing/new/${availableGoals[selectedModalHelper].link}`)
                    }
                }}
                buttonText={"Create Campaign"}
                videoUrl={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].walkthroughVideoUrl : ""}
            />
        </>
    );
}

export default CreateNewCampaignModal