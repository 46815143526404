import { ChangeEvent, useState } from "react"
import useScreen from "Hooks/useScreen"
import ModalSymphony from "components/shareable/ModalSymphony"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import TextInputSymphony from "components/shareable/TextInputSymphony"
import { clsx } from 'modules/Utils'

/**
 * Props for the SignupWithoutConnections component
 * @interface Props
 */
interface Props {
  className?: string
  /** Whether the modal is open */
  open: boolean
  /** Callback to close the modal */
  closeModal: () => void
  /** Callback to handle profile name submission */
  onSubmit: (profileName: string) => void
}

/**
 * Maximum allowed length for profile name
 */
const MAX_PROFILE_NAME_LENGTH = 30;

/**
 * Modal component for signing up without platform connections
 */
const SignupWithoutConnections = ({
  className,
  open,
  closeModal,
  onSubmit
}: Props) => {
  const { mobileView } = useScreen();
  const [profileName, setProfileName] = useState<string>("")

  /**
   * Handles changes to the profile name input field
   * @param e - Change event from input
   */
  const handleChangeProfileName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_PROFILE_NAME_LENGTH) {
      setProfileName(value);
    }
  };

  /**
   * Handles clicking the submit button
   */
  const handleSubmit = () => {
    onSubmit(profileName);
    closeModal();
  }

  return (
    <ModalSymphony
      className={clsx("signupWithoutConnectionsModalComponent", className)}
      isOpen={open}
      onClose={closeModal}
      title="Enter your Profile Name"
      subtitle="Symphony is better with your social connections, but you can continue without them."
      fullScreen={mobileView}
      subtitleStyle={{ marginBottom: 24 }}
    >
      <TextInputSymphony
        fullWidth
        value={profileName}
        onChange={handleChangeProfileName}
        placeholder="Enter profile name"
        maxLength={MAX_PROFILE_NAME_LENGTH}
        helperText={profileName.length >= MAX_PROFILE_NAME_LENGTH ? `Your name must be ${MAX_PROFILE_NAME_LENGTH} characters max.` : undefined}
      />

      <ButtonSymphony
        width="100%"
        style={{ marginTop: 16 }}
        disabled={!profileName.trim()}
        onClick={handleSubmit}
      >
        Continue
      </ButtonSymphony>
    </ModalSymphony>
  )
}

export default SignupWithoutConnections
