import { Chip, useTheme } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import {
  AddLabel,
  ButtonContainer,
  GridContainer,
  LoadingContainer,
  PauseNotification,
  TableHeaderContainer,
  TableTitleLabel,
} from "./styles";
import useStyles from "./styles";
import MarketDetailCard from "../MarketDetailCard";
import MarketDetailHeader from "../MarketDetailHeader";
import { addLocationsToMarket, getLocationDetails, getMarketDetail, pauseLocation, pauseMarket } from "./api";
import LocationsTable from "../LocationsTable";
import MarketsConfirmationModal from "../MarketConfirmationModal";
import { ReactComponent as AddIcon } from "assets/images/campaignDetails/add.svg"
import AddLocationModal from "../AddLocationModal";
import { FBLocation } from "../../MusicStreams/TargetingView/reducer";
import LocationConfirmationModal from "../LocationConfirmationModal";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "./utils";
import { CampaignMapping, CurrencyCodesType } from "types/global";
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import { track } from "analytics";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";
import { Container } from "styles/shared";

export interface CardFormat {
  icon: string
  title: string
  value: number
  isMoney: boolean
}

export interface LocationDetail {
  id: number | string,
  name: string,
  path: string[],
  stats: {
    clicks: number,
    reach: number,
    impressions: number,
    cpm: number,
    spend: number,
    costPerClick: number,
    costPerConversion: number,
    conversions: number,
  };
  status: string,
  canPause: boolean,
  type: string,
  market?: string,
}

export interface MarketDetail {
  name: string,
  totalBudget: number,
  budgetRemaining: number,
  spend: number,
  status: string,
  performance: {
    costPerConversion: number;
    reach: number,
    clicks: number,
    views: number,
    impressions: number,
    comments: number,
    shares: number,
    costPerClick: number,
    cpm: number,
    conversions?: number
  }
}

interface Props {
  campaignId: number
  markets?: CampaignMapping[]
  campaignStatus: string
  access_token: string
  trackObject?: Record<string, any>
  currency?: CurrencyCodesType
  hasConversions: boolean
  getCardDetails: (market: MarketDetail, hasConversions: boolean) => CardFormat[]
  updateCampaignData: () => void
}

const MarketsDetail = ({
  campaignId,
  markets,
  campaignStatus,
  access_token,
  trackObject,
  currency,
  hasConversions,
  getCardDetails,
  updateCampaignData,
}: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { mobileView } = useScreen();
  const { isBrandAdmin, isProBrand } = useContext(CurrentBrandContext)
  
  const [selectedMarket, selectMarket] = useState<CampaignMapping>();
  const [performance, setPerformance] = useState<MarketDetail>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLocationsData, setLoadingLocationsData] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<LocationDetail>({} as LocationDetail);
  const [locations, setLocations] = useState<LocationDetail[]>([]);
  const [openMarketsModal, setOpenMarketsModal] = useState<boolean>(false);
  const [openLocationsModal, setOpenLocationsModal] = useState<boolean>(false);
  const [cardDetails, setCardDetails] = useState<CardFormat[]>([]);
  const [openAddLocation, setOpenAddLocation] = useState<boolean>(false);
  const [showOverall, setShowOverall] = useState<boolean>(true);

  const isCampaignFinished = campaignStatus === 'FINISHED'
  const isButtonDisabled = campaignStatus === 'PAUSED'
  const buttonColor = isButtonDisabled ? "#edecf2" : "#8800ff"

  const pauseSelectedMarket = async () => {
    const updatedMarket = await pauseMarket({
      market: selectedMarket,
      campaignId,
      access_token
    })
    if (updatedMarket) {
      await updateCampaignData()
      selectMarket(updatedMarket)
      const action = updatedMarket.fbCampaign.fields.status === "ACTIVE" ? "resumed" : "paused"
      toast.success(`Locations ${action} successfully`, TOAST_OPTIONS)
    } else {
      const action = selectedMarket?.fbCampaign.fields.status === "ACTIVE" ? "resume" : "pause"
      toast.error(`Sorry, we couldn't ${action} the selected location`, TOAST_OPTIONS)
    }
    setOpenMarketsModal(false)
  }

  const pauseSelectedLocation = async () => {
    const response = await pauseLocation({
      location: selectedLocation,
      marketId: selectedMarket?.fbCampaign.id,
      campaignId,
      access_token
    })
    if (response) {
      setLoading(true)
      getLocationsData()
      setLoading(false)
      const action = selectedLocation.status !== "live" ? "resumed" : "paused"
      toast.success(`Location ${action} successfully`, TOAST_OPTIONS)
    } else {
      const action = selectedLocation.status !== "live" ? "resume" : "pause"
      toast.error(`Sorry, we couldn't ${action} the selected location`, TOAST_OPTIONS)
    }
  }

  const getPerformanceData = async () => {
    const performance = await getMarketDetail({
      market: selectedMarket,
      campaignId,
      access_token
    })
    if (performance) {
      setPerformance(performance)
      const cards = getCardDetails(performance, hasConversions)
      setCardDetails(cards)
    }
  }

  const getLocationsData = async () => {
    setLoadingLocationsData(true)
    const locations = await getLocationDetails({
      marketId: selectedMarket?.fbCampaign.id,
      campaignId,
      access_token
    })
    if (locations) setLocations(locations)
    setLoadingLocationsData(false)
  }

  const addLocations = async (locations: FBLocation[]) => {
    const response = await addLocationsToMarket({
      market: selectedMarket,
      campaignId,
      locations,
      access_token
    })
    if (response) {
      setLoading(true)
      getLocationsData()
      setLoading(false)
      toast.success(`Locations added successfully`, TOAST_OPTIONS)
    }
    else {
      toast.error(`Sorry, we couldn't add the locations to the market`, TOAST_OPTIONS)
    }
  }

  const handleOnSelectMarket = (market: CampaignMapping) => {
    selectMarket(market)
    setShowOverall(false)
  }

  const showOverallData = () => {
    setShowOverall(true)
    selectMarket(undefined)
  }

  const setInitialState = async () => {
    setLoading(true)
    await getPerformanceData()
    await getLocationsData()
    setLoading(false)
  }

  const updateStatus = async () => {
    setLoading(true)
    await getPerformanceData()
    setLoading(false)
  }

  const handleOnPauseLocation = () => {
    setOpenLocationsModal(true)
    track('Open Edit Locations', {
      ...trackObject,
      opened: 'Edit Locations Modal',
    })
  }
  const handleOnPauseMarket = () => setOpenMarketsModal(true)
  const handleOnCloseMarketModal = () => setOpenMarketsModal(false)
  const handleOnCloseLocationModal = () => setOpenLocationsModal(false)
  const handleOnAddLocationModal = () => setOpenAddLocation(true)
  const handleOnCloseAddLocationModal = () => setOpenAddLocation(false)

  useEffect(() => {
    if (isProBrand)
      setInitialState()
  }, [selectedMarket])

  useEffect(() => {
    updateStatus()
  }, [campaignStatus])

  if (!isProBrand) {
    return (
      <Container padding="20px 40px">
        <UnlockDataBanner
          source="IG Campaign Details - Markets"
          title="View and edit your targeting in realtime"
          description="Go Pro to add, pause, and edit your targeted locations in real-time."
          image={UnlockDataImage}
          hideImage={mobileView}
        />
      </Container>
    );
  }

  return (
    <Container flexDirection="column" padding="16px" gap="16px">
      {loading && !markets ? (
        <LoadingContainer>
          <NewLoader black />
          <span>Loading Markets...</span>
        </LoadingContainer>
      ) : (
        <>
          {markets && (
            <GridContainer>
              <Chip
                label="Overall"
                className={showOverall ? classes.selected : ""}
                onClick={showOverallData}
                disabled={loading}
              />
              {markets.map((market) => (
                <Chip
                  key={market.id}
                  label={market.name}
                  className={
                    selectedMarket?.name === market.name ? classes.selected : ""
                  }
                  onClick={() => handleOnSelectMarket(market)}
                  disabled={loading}
                />
              ))}
            </GridContainer>
          )}
          {loading || !markets ? (
            <LoadingContainer>
              <NewLoader black />
              <span>Loading Markets...</span>
            </LoadingContainer>
          ) : (
            <>
              <MarketDetailHeader
                market={performance}
                onPauseMarket={handleOnPauseMarket}
                showOverall={showOverall}
                name={selectedMarket?.name}
                campaignStatus={campaignStatus}
                marketStatus={selectedMarket?.fbCampaign.fields.status}
                fraction={selectedMarket?.fraction}
                currency={currency}
              />
              <GridContainer>
                {cardDetails.map((card) => (
                  <MarketDetailCard
                    key={card.title}
                    icon={card.icon}
                    title={card.title}
                    value={card.value}
                    isMoney={card.isMoney}
                    currency={currency}
                  />
                ))}
              </GridContainer>
              <TableHeaderContainer>
                <TableTitleLabel>
                  Locations in this market
                  {showOverall && (
                    <PauseNotification>
                      {isCampaignFinished || isButtonDisabled ?
                        "While your campaign is running, you'll be able to edit your location targeting."
                        :
                        "Edit your location targeting by selecting individual markets above"
                      }
                    </PauseNotification>
                  )}
                </TableTitleLabel>
                {!showOverall && !isCampaignFinished && isBrandAdmin && (
                  <ButtonContainer
                    onClick={handleOnAddLocationModal}
                    disabled={isButtonDisabled}
                  >
                    <AddIcon color={buttonColor} />
                    <AddLabel color={buttonColor}>Add location</AddLabel>
                  </ButtonContainer>
                )}
              </TableHeaderContainer>
              <LocationsTable
                locations={locations}
                openConfirmationModal={handleOnPauseLocation}
                selectLocation={setSelectedLocation}
                showOverall={showOverall}
                loading={loadingLocationsData}
                campaignStatus={campaignStatus}
                currency={currency}
                hasConversions={hasConversions}
              />
              <MarketsConfirmationModal
                market={selectedMarket}
                open={openMarketsModal}
                closeModal={handleOnCloseMarketModal}
                onPauseMarket={pauseSelectedMarket}
                locations={locations}
              />
              <LocationConfirmationModal
                open={openLocationsModal}
                location={selectedLocation}
                onPauseLocation={pauseSelectedLocation}
                closeModal={handleOnCloseLocationModal}
                trackObject={trackObject}
              />
              <AddLocationModal
                open={openAddLocation}
                closeModal={handleOnCloseAddLocationModal}
                onAddLocations={addLocations}
                market={selectedMarket}
                campaignId={campaignId}
                locations={locations}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default MarketsDetail