import { useContext, useState } from "react"
import { saveBrandWebsite, saveWebsiteChanges } from "services/symphonyApi/websiteService"
import { TopBarContentContainer } from "../../styles"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { WebsiteContext } from "../CustomizeWebsite/hooks/WebsiteContext"
import { formatDataToPublish } from "../PublishSiteModal/utils"
import { WebsiteLink } from "../CustomizeWebsite/utils"
import { showToast } from "modules/Utils"
import { WEBSITE_TYPES } from "../../types"
import { fontThemeStyles } from "../CustomizeWebsite/components/StyleSection/FontSelectorSection/utils"
import { dataCollectorThemeStyles, musicThemeStyles } from "../CustomizeWebsite/components/StyleSection/ThemeSelectorSection/utils"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { PlanType } from "modules/Const"
import ButtonSymphony from "components/shareable/ButtonSymphony"
/**
 * Interface defining props for the RightSideTopBarContent component
 * @interface Props
 */
interface Props {
  /** Content data for the brand website */
  brandContent: any;
  /** Whether the website is currently published */
  published: boolean;
  /** Whether component is being rendered in mobile view */
  mobileView: boolean;
  /** Handler for opening the publish modal */
  handleOpenPublishModal: () => void;
  /** Component for rendering the website URL */
  renderUrlComponent: () => JSX.Element | undefined;
  /** Type of website being edited */
  websiteType?: string;
  /** Whether the component is only a button */
  isButtonOnly?: boolean;
  /** Width of the button */
  buttonWidth?: number | string;
}

/**
 * Component that renders the top bar content for the website editor
 * Contains publish/save functionality and URL display
 */
const RenderRightSideTopBarContent = ({
  brandContent,
  published,
  mobileView,
  handleOpenPublishModal,
  renderUrlComponent,
  isButtonOnly = false,
  buttonWidth = 'auto',
}: Props) => {
  // Get brand context values
  const { currentBrand, isPaidBrand, isBrandAdmin } = useContext(CurrentBrandContext)
  const { handleOpenModal } = useContext(UpgradeModalContext)

  // Get all website customization values from context
  const {
    bgType,
    bgValue,
    customLinkOptions,
    customLinksAdded,
    embedYt,
    enableSocialIcons,
    fields,
    fontThemeStyle,
    isNewLink,
    isProject,
    mainColor,
    socialMediaIcons,
    recordSelected,
    removeSymphonyBrand,
    selectedSymphonyLogoStyle,
    secondaryColor,
    selectedPreviewTrack,
    showRecordSelector,
    submitButtonText,
    showSecondaryLine,
    streamingLinks,
    styleType,
    subtitle,
    themeStyle,
    fullVideoUrl,
    titleText,
    youtubeID,
    youtubeVideoLink,
    customThumbnailUrl,
    customThumbnailViewStyle,
    addAffiliateLinkToLogo,
    tourDates,
    hidePastTourDates,
    titleAlignment,
  } = useContext(WebsiteContext);

  // Loading state for save/publish actions
  const [loading, setLoading] = useState(false)

  // Early return if required data is missing
  if (!currentBrand || !brandContent) return <></>

  // Get combined theme styles and create type
  const themeStylesOptions = { ...musicThemeStyles, ...dataCollectorThemeStyles }
  type themeStyleType = keyof typeof themeStylesOptions

  /**
   * Checks if user has any paid-only customizations active
   * @param currentTheme - Current theme style key
   * @param currentFont - Current font style key
   * @returns boolean indicating if any paid features are in use
   */
  const hasPaidCustomizations = (currentTheme: themeStyleType, currentFont: string): boolean => {
    const themeStylesOptions = { ...musicThemeStyles, ...dataCollectorThemeStyles };

    // Check if current theme is paid-only
    const isUsingPaidTheme = themeStylesOptions[currentTheme]?.isPaidOnly;

    // Check if current font is paid-only
    const isUsingPaidFont = fontThemeStyles[currentFont as keyof typeof fontThemeStyles]?.isPaidOnly;

    return isUsingPaidTheme || isUsingPaidFont;
  };

  const hasPaidFeatures = hasPaidCustomizations(themeStyle as themeStyleType, fontThemeStyle || '');

  /**
   * Checks if user has paid features before allowing publish/save
   */
  const handleClickPublish = () => {
    // Show upgrade modal if using paid features without pro account
    if (hasPaidFeatures && !isPaidBrand) {
      handleOpenModal({
        upgradeCallToAction: "It looks like you're using a Lite-only customization. Upgrade to Symphony Lite to unlock unlimited Website Customization!",
        highlightedPlan: PlanType.LITE,
        source: "Website Editor - Publish Button"
      });
      return;
    }

    // Handle save or publish based on current state
    if (published) {
      handleSaveChanges();
    } else {
      handleOpenPublishModal();
    }
  }

  /**
   * Handles saving changes to an already published website
   * Updates both website content and brand settings
   */
  const handleSaveChanges = async () => {
    try {
      // Prepare website content data
      const contextData = {
        bgType,
        bgValue,
        customLinkOptions,
        customLinksAdded,
        embedYt,
        enableSocialIcons,
        fields,
        fontThemeStyle,
        isNewLink,
        isProject,
        mainColor,
        socialMediaIcons,
        recordSelected,
        removeSymphonyBrand,
        selectedSymphonyLogoStyle,
        secondaryColor,
        selectedPreviewTrack,
        showRecordSelector,
        submitButtonText,
        showSecondaryLine,
        streamingLinks,
        styleType,
        subtitle,
        themeStyle,
        fullVideoUrl,
        titleText,
        youtubeID,
        youtubeVideoLink,
        previewUrl: customThumbnailUrl || currentBrand?.image,
        tourDates,
        hidePastTourDates,
        customThumbnailViewStyle: customThumbnailViewStyle || 'cover',
        titleAlignment,
      }

      const dataFormatted = formatDataToPublish(contextData, brandContent)

      // Prepare social icons data
      let socialIcons = currentBrand?.website?.socialIcons || []
      if (enableSocialIcons && socialMediaIcons) {
        socialIcons = Object.entries(socialMediaIcons).map(([key, value]: [string, WebsiteLink]) => value as WebsiteLink)
      }

      // Prepare brand website settings
      const brandDataToUpdate = {
        buttonConfig: styleType,
        enableSocialIcons,
        removeSymphonyBrand,
        selectedSymphonyLogoStyle,
        socialIcons,
        addAffiliateLinkToLogo
      }

      // Save all changes
      setLoading(true)
      await saveWebsiteChanges(currentBrand?.slug || '', dataFormatted)
      await saveBrandWebsite(currentBrand?.slug || '', brandDataToUpdate)
      setLoading(false)

      showToast({
        message: "Successfully saved changes to your website!",
        error: false,
        mobile: mobileView,
      })
    } catch (e) {
      showToast({
        message: "We couldn't save the changes to your website!",
        error: true,
        mobile: mobileView,
      })
      console.error("Error saving site's changes", e)
    }
  }

  // Calculate button disabled state
  let disableByFields = false
  if (brandContent.type === WEBSITE_TYPES.DATA_COLLECTOR) {
    disableByFields = !fields?.length
  }
  const disableByStreamingLinks = streamingLinks && Object.keys(streamingLinks).length === 0 && !youtubeID && !embedYt && !customLinksAdded.length;

  // Disable if its a music site and doesnt have streaming links
  // or if its a data collector site and doesnt have fields
  const isDisabled = disableByStreamingLinks || disableByFields

  const renderPublishButton = () => {
    return (
      <ButtonSymphony
        className="saveChangesWebsiteButton"
        isLoading={loading}
        disabled={isDisabled}
        onClick={handleClickPublish}
        width={buttonWidth}
      >
        {published ? "Save changes" : "Publish"}
      </ButtonSymphony>
    )
  }

  if (isButtonOnly) {
    return renderPublishButton()
  }

  return (
    <TopBarContentContainer
      className="rightSideTopBarContentComponent"
      published={published}
    >
      {published && !mobileView && renderUrlComponent()}
      {isBrandAdmin && renderPublishButton()}
    </TopBarContentContainer>
  )
}

export default RenderRightSideTopBarContent