import { useEffect, useState, useContext, CSSProperties } from "react";
import { useTabs } from "react-headless-tabs";
import { useLocation } from "react-router-dom";
import Axios from "helpers/Interceptor";
import MobileHeader from "components/MobileHeader";
import OverallDashboard from "./OverallDashboard";
import MainContentContainer from "components/shareable/MainContentContainer";
import mixpanel from "mixpanel-browser";
import { pageView, setUserProperty, track } from "analytics";
import { getStatusToShowPaymentFailedBanner } from "modules/Utils";
import PlatformsConnector from "./PlatformsConnector";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { GettingStartedNames } from 'components/dashboard/GettingStarted';
import { GettingStartedContext } from 'Hooks/GettingStartedContext/index';
import { Connections, CurrentBrand, SpotifyArtist, SYMPHONY_TASKS, UserData } from "types/global";
import { BannerContainer } from "./styles";
import { DATE_OPTIONS, getDatesByRange, getFanbaseData, getSmallGraphEmptyData } from "./utils";
import dayjs from "dayjs";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import AlreadyExistingArtist from "components/connect/connectModals/AlreadyExistingArtist";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import UpgradeToProSucceededModal from "components/shareable/UpgradeToProSucceededModal";
import { isYouTubeLogin } from "helpers/admin";
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments";
import QuickActions from "components/shareable/QuickActions";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import useScreen from "Hooks/useScreen";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import Colors from "modules/Colors";
import chevronRightGray from 'assets/images/chevronRight-gray.svg'
import ButtonSymphony from "components/shareable/ButtonSymphony";
import CreateNewWebsiteModal from "../Website/components/CreateNewWebsiteModal";
import CreateNewCampaignModal from "../MarketingPage/Components/CreateNewCampaignModal/CreateNewCampaignModal";
import { NAV_BAR_HEIGHT_MOBILE } from "modules/Const";
import Recommendations from "components/shareable/Recommendations";
import WelcomeToSymphony from "components/WelcomeToSymphony";
import ModalSymphony from "components/shareable/ModalSymphony";
import { getCookie, setCookie } from "modules/Utils";
import PageSymphony from "components/shareable/PageSymphony";
import ContentSymphony from "components/shareable/ContentSymphony";
import { ONBOARDING_VERSION } from "Hooks/OnboardingContext";

enum DashboardSideContent {
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  SOUNDCLOUD = 'soundcloud',
  DEEZER = 'deezer',
}

interface DashboardSideContentItem {
  title: string;
  description: string;
  image: string;
}

const dashboardSideContent: { [key in DashboardSideContent]: DashboardSideContentItem } = {
  [DashboardSideContent.INSTAGRAM]: {
    title: 'Instagram Post Breaks Like Record',
    description: 'Your latest Instagram post has reached a new milestone with record-breaking engagement. Check out the analytics to see what resonated with your audience.',
    image: 'https://picsum.photos/200/300',
  },
  [DashboardSideContent.YOUTUBE]: {
    title: 'Promote the Light with your new AI generated Website',
    description: 'Launch your new AI-powered website to showcase your music and connect with fans. Customize your site with just a few clicks.',
    image: 'https://picsum.photos/200/300',
  },
  [DashboardSideContent.FACEBOOK]: {
    title: 'Los Angeles seems to be growing more than usual',
    description: 'Your fanbase in Los Angeles has grown significantly this month. Consider planning special content or events to engage with this growing audience.',
    image: 'https://picsum.photos/200/300',
  },
  [DashboardSideContent.TIKTOK]: {
    title: 'TikTok Content',
    description: 'Explore trending sounds and hashtags to boost your TikTok presence. Create engaging short-form videos to reach new audiences.',
    image: 'https://picsum.photos/200/300',
  },
  [DashboardSideContent.SOUNDCLOUD]: {
    title: 'SoundCloud Content',
    description: 'Share your latest tracks and connect with the SoundCloud community. Monitor your track performance and engage with listeners.',
    image: 'https://picsum.photos/200/300',
  },
  [DashboardSideContent.DEEZER]: {
    title: 'Deezer Content',
    description: 'Optimize your Deezer profile and playlists to increase streams. Track your performance metrics and grow your listener base.',
    image: 'https://picsum.photos/200/300',
  }
}

const dashboardSideContentArray = [
  DashboardSideContent.INSTAGRAM,
  DashboardSideContent.YOUTUBE,
  DashboardSideContent.FACEBOOK,
]

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export interface FanbaseMetricsType {
  totalFanbase: number;
  foreverPresaveFans: number;
  fanbaseGrowth: string;
  foreverPresaveFansGrowth: string;
  dailyMetrics: {
    [key: string]: {
      totalFans: number;
      foreverSaves: number;
    }
  };
}

// TODO: ask megh for icons - music-player by default
export const siteIconsData: { [key: string]: any; }
  = {
  deezer: require("../../../assets/images/platforms/deezer.svg").default,
  spotify: require("../../../assets/images/platforms/spotify.svg").default,
  tidal: require("../../../assets/images/platforms/tidal.svg").default,
  youtube: require("../../../assets/images/platforms/youtube.svg").default,
  apple_music: require("../../../assets/images/music-player.svg").default,
  amazonStore: require("../../../assets/images/music-player.svg").default,
  amazon_music: require("../../../assets/images/music-player.svg").default,
  instagram: require("../../../assets/images/platforms/instagram.svg").default,
  tiktok: require("../../../assets/images/platforms/tiktok.svg").default,
  facebook: require("../../../assets/images/platforms/facebook.svg").default,
  soundcloud: require("../../../assets/images/platforms/soundcloud.svg").default,
  twitter: require("../../../assets/images/twitter.svg").default,
  audiomack: require("../../../assets/images/platforms/audiomack.svg").default,
  fans: require("../../../assets/images/platforms/fans.svg").default,
  'forever-saves': require("../../../assets/images/platforms/forever-saves.svg").default,
}

const Dashboard = () => {
  const { currentUser, loadedBrands, loadingBrands } = useContext(CurrentUserContext)
  const { currentBrand, reloadBrand, isBrandAdmin, isProBrand } = useContext(CurrentBrandContext);
  const query = useQuery();

  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const [sessionId, setSessionId] = useState(String())
  const [showUpgradeToProSucceededModal, setShowUpgradeToProSucceededModal] = useState(false)

  // TODO: Set this to true when we have recommendations sidebar ready to go
  const [showRecommendationsSidebar, setShowRecommendationsSidebar] = useState(false)
  const [showCreateNewWebsiteModal, setShowCreateNewWebsiteModal] = useState(false)
  const [showCreateNewCampaignModal, setShowCreateNewCampaignModal] = useState(false)

  // show text the YT not included in the graph(this is to adhere with YT terms of service)
  const isYTLoginAdmin = isYouTubeLogin(currentUser || {} as UserData)

  const [isLoadingFanbase, setLoadingFanbase] = useState(true);

  const [isLoadingNewDate, setLoadingNewDate] = useState(false);

  // edit platforms modal
  const [platformsModalOpen, setPlatformsModalOpen] = useState<boolean>(false);
  const [openAlreadyExistModal, setOpenAlreadyExistModal] = useState<boolean>(false)
  const [existingBrand, setExistingBrand] = useState<CurrentBrand>({} as CurrentBrand);
  const [artist, setArtist] = useState<SpotifyArtist>({} as SpotifyArtist);

  // to handle date selection
  const [selectedRange, setSelectedRange] = useState<DATE_OPTIONS | 'custom' | null>('last-7-days')
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD")
  })

  const { remainingFreeTrial, isOrganizationInactive } = useCurrentTeam()

  const checkSubscriptionStatus = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const subscription = urlParams.get("subscription")
    const brand = urlParams.get("brand")
    const session_id = urlParams.get("session_id")

    if (
      session_id &&
      subscription === "succeeded" &&
      brand === currentBrand?.slug
    ) {
      setSessionId(session_id)
      setShowUpgradeToProSucceededModal(true)
    } else {
      setSessionId(String())
      setShowUpgradeToProSucceededModal(false)
    }
  }

  const closeUpgradeToProSucceededModal = () => {
    setSessionId(String())
    setShowUpgradeToProSucceededModal(false)
  }

  useEffect(() => {
    setShowUpgradeToProSucceededModal(Boolean(sessionId))
  }, [sessionId])

  const updateDateRange = (range: DATE_OPTIONS | 'custom') => {
    setSelectedRange(range)
    if (range !== 'custom') {
      const {
        startDate,
        endDate
      } = getDatesByRange(range)

      setSelectedDate({
        startDate,
        endDate
      })
    }
  }

  const tabs = ["overall", "content"];
  let defaultTab = "overall";
  if (query.get("tab")) {
    const t = query.get("tab");
    if (t && tabs.includes(t)) {
      defaultTab = t;
    }
  }

  const [selectedTab, setSelectedTab] = useTabs(tabs, defaultTab);

  const [overallStats, setOverallStats] = useState<any>({});

  const NEEDS_CONNECTIONS: Record<string, (currentBrand: CurrentBrand) => boolean> = {
    'spotify': (currentBrand: CurrentBrand) => !(currentBrand.connections.spotify_artist_page),
    'youtube': (currentBrand: CurrentBrand) => !(currentBrand.connections.youtube_channel),
    'instagram': (currentBrand: CurrentBrand) => !(currentBrand.connections.business_discovery_instagram),
    'tiktok': (currentBrand: CurrentBrand) => !(currentBrand.connections.tiktok && currentBrand.connections.tiktok.url && currentBrand.connections.tiktok.username),
    'soundcloud': (currentBrand: CurrentBrand) => !(currentBrand.connections.soundcloud_artist_page),
    'audiomack': (currentBrand: CurrentBrand) => !(currentBrand.connections.audiomack),
    // 'twitter': (currentBrand: CurrentBrand) => !(currentBrand.connections.twitter && currentBrand.connections.twitter.url),
    'facebook': (currentBrand: CurrentBrand) => !(currentBrand.connections.facebook_page && currentBrand.connections.facebook_page.id),
    'deezer': (currentBrand: CurrentBrand) => !(currentBrand.connections.deezer && currentBrand.connections.deezer.id),
  }

  const [smallGraphData, setSmallGraphData] = useState<any[]>([
    {
      name: "Spotify Monthly Listeners",
      platformId: 'spotify',
      platformName: "Spotify",
      statIdentifier: 'monthly_listeners',
      statLabel: "Monthly Listeners",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['spotify'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['spotify'](currentBrand),
      type: "platforms"
    },
    {
      name: "Spotify Followers",
      platformId: 'spotify',
      platformName: "Spotify",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['spotify'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['spotify'](currentBrand),
      type: "platforms"
    },
    {
      name: "YouTube Subscribers",
      platformId: 'youtube',
      platformName: "YouTube",
      statIdentifier: 'subscribers',
      statLabel: "Subscribers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['youtube'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['youtube'](currentBrand),
      hideDataFromOverall: isYTLoginAdmin,
      type: "platforms"
    },
    {
      name: "Instagram Followers",
      platformId: 'instagram',
      platformName: "Instagram",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['instagram'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['instagram'](currentBrand),
      type: "platforms"
    },
    {
      name: "TikTok Followers",
      platformId: 'tiktok',
      platformName: "TikTok",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['tiktok'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['tiktok'](currentBrand),
      type: "platforms"
    },
    // {
    //   name: "Twitter Followers",
    //   platformId: 'twitter',
    //   platformName: "Twitter",
    //   statIdentifier: 'followers',
    //   statLabel: "Followers",
    //   chartData: {},
    //   secondaryData: {},
    //   imageAsset: siteIconsData['twitter'],
    //   needsConnection: currentBrand && NEEDS_CONNECTIONS['twitter'](currentBrand),
    //   type: "platforms",
    //   decommissionMessage: "Unfortunately due to changes at X / Twitter beyond our control, it's no longer possible for us to pull followers. We're hoping to bring this feature back soon!"
    // },
    {
      name: "Facebook Page Followers",
      platformId: 'facebook',
      platformName: "Facebook Page",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['facebook'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['facebook'](currentBrand),
      type: "platforms"
    },
    {
      name: "SoundCloud Followers",
      platformId: 'soundcloud',
      platformName: "SoundCloud",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['soundcloud'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['soundcloud'](currentBrand),
      type: "platforms"
    },
    {
      name: "Audiomack Followers",
      platformId: 'audiomack',
      platformName: "Audiomack",
      statIdentifier: 'followers',
      statLabel: "Followers",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['audiomack'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['audiomack'](currentBrand),
      type: "platforms"
    },
    {
      name: "Deezer Fans",
      platformId: 'deezer',
      platformName: "Deezer",
      statIdentifier: 'followers',
      statLabel: "Fans",
      chartData: {},
      secondaryData: {},
      imageAsset: siteIconsData['deezer'],
      needsConnection: currentBrand && NEEDS_CONNECTIONS['deezer'](currentBrand),
      type: "platforms"
    },

    // {
    //   name: "Instagram",
    //   platformId: 'instagram',
    //   platformName: "Instagram",
    //   statIdentifier: 'followers',
    //   statLabel: "Followers",
    //   chartData: {},
    //   chartOptions: JSON.parse(JSON.stringify(defaultChartOptions)),
    //   secondaryData: {},
    //   imageAsset: siteIconsData['instagram'],
    //   needsConnection: currentBrand && currentBrand.connections.instagram_page ? false : true
    // },
  ])

  const {
    finishedTasks,
    completed,
  } = useContext(GettingStartedContext);
  const { mobileView, smallView, mStyle } = useScreen();

  // const brandHasData = async () => {
  //   if (currentBrand && currentBrand.dashboard_initialized) {
  //     getContent()
  //   } else {
  //     const brandId = localStorage.getItem('selected-brand');

  //     // create a timeout to wait for current 
  //     setLoadingFanbase(true)

  //     const brandPromise = await new Promise(async (resolve: any, reject: any) => {
  //       // every 3 seconds check if its done
  //       const refreshIntervalId = setInterval(async () => {
  //         const brandResponse = await Axios.get(`/brand/${brandId}`);
  //         const brandData = brandResponse.data.data

  //         if (brandData.dashboard_initialized) {
  //           clearInterval(refreshIntervalId);
  //           resolve(brandData)
  //         }
  //       }, 7000);
  //     })
  //     setCurrentBrand(brandPromise)
  //   }
  // }

  const [fanbaseMetrics, setFanbaseMetrics] = useState<FanbaseMetricsType>({
    totalFanbase: 0,
    foreverPresaveFans: 0,
    fanbaseGrowth: "0",
    foreverPresaveFansGrowth: "0",
    dailyMetrics: {}
  });

  const getFanEngagementStats = async () => {
    try {
      const { data } = await Axios.get(`/brand/${currentBrand!.id}/metrics/fans?startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`);
      setFanbaseMetrics(data.data);
    } catch (err) {
      console.error('Error fetching fan engagement stats:', err);
    }
  };

  /** Fetches all dashboard content data and updates state */
  const getContent = async () => {
    try {
      // Set loading state at start of content fetch
      setLoadingFanbase(true)
      setLoadingNewDate(true)

      // Run both fetches in parallel
      const [fanEngagement, fanbaseResults] = await Promise.all([
        getFanEngagementStats(),
        getOverallFanbaseGrowth()
      ])

      // Process results and update state
      plotChartData(fanbaseResults)
      return true // Return success

    } catch (error) {
      console.error('Error fetching dashboard content:', error)
      return false // Return failure
    } finally {
      // Always ensure loading states are cleared
      setLoadingFanbase(false)
      setLoadingNewDate(false)
    }
  }

  /** Fetches overall fanbase growth data */
  const getOverallFanbaseGrowth = async () => {
    const { startDate, endDate } = selectedDate

    const promises = smallGraphData.map((d: any) => {
      const { platformId, statIdentifier } = d

      if (!currentBrand) {
        console.error('currentBrand is undefined')
        return Promise.resolve(null)
      }

      // Get connection key for platform
      const connectionKey = getPlatformConnectionKey(platformId)
      const isConnected = Boolean(currentBrand.connections[connectionKey])

      return isConnected
        ? getFanbaseData({
          brandId: currentBrand.id,
          platformId,
          statIdentifier,
          startDate,
          endDate
        })
        : Promise.resolve(getSmallGraphEmptyData(platformId, statIdentifier))
    })

    return Promise.all(promises)
  }

  /** Maps platform IDs to their connection keys */
  const getPlatformConnectionKey = (platformId: string): keyof Connections => {
    const platformMap: Record<string, keyof Connections> = {
      youtube: "youtube_channel",
      instagram: "business_discovery_instagram",
      spotify: "spotify_artist_page",
      facebook: "facebook_page",
      soundcloud: "soundcloud_artist_page"
    }

    return platformMap[platformId] || platformId as keyof Connections
  }

  useEffect(() => {
    pageView("Dashboard")
    checkSubscriptionStatus()
    refreshConnections()
  }, [])
  useEffect(() => {
    let retryCount = 0;
    let retryInterval: NodeJS.Timeout;

    const attemptGetContent = async () => {

      const success = await getContent();

      if (success) {
        clearInterval(retryInterval);
      } else if (retryCount >= 2) { // Allow for 3 total attempts (initial + 2 retries)
        clearInterval(retryInterval);
      }
      retryCount++;
    };

    if (currentBrand) {
      // Initial attempt
      attemptGetContent();

      // Setup retry interval if needed
      retryInterval = setInterval(attemptGetContent, 3000);
    }

    return () => {
      if (retryInterval) {
        clearInterval(retryInterval);
      }
    };
  }, [currentBrand]);

  useEffect(() => {
    if (currentBrand?.dashboard_initialized) {
      getContent()

      track("Updated Time Range", {
        name: "Dashboard",
        to: selectedRange,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        brand_id: currentBrand.id,
        brand_slug: currentBrand.slug,
        brand_name: currentBrand.name
      })
    }
  }, [selectedDate])

  // only fire the brand content api, if we are the content tab
  useEffect(() => {
    const fetchBrandContent = async () => {
      const brandId = localStorage.getItem('selected-brand');
      try {
        await Axios.get(`/brand-content/${brandId}/analytics`, { timeout: 60 * 1000 });
      } catch (error) {
        console.error('Error in fetching brand content', error);
      }
    }
    if (selectedTab === 'content') {
      fetchBrandContent()
    }
  }, [selectedTab])

  useEffect(() => {
    if (!isLoadingFanbase && !finishedTasks.includes(SYMPHONY_TASKS.FANBASE_COMPLETED)) {
      const completedFanbase = smallGraphData.every((chartItem: any) => !chartItem.needsConnection);
      if (completedFanbase) {
        setUserProperty(`Completed "${GettingStartedNames[SYMPHONY_TASKS.FANBASE_COMPLETED]}" Onboarding Step`, true)
        track(`Completed "${GettingStartedNames[SYMPHONY_TASKS.FANBASE_COMPLETED]}" Onboarding Step`, {
          remainingSteps: Object.keys(SYMPHONY_TASKS).length - finishedTasks.length - 1,
        });
        mixpanel.people.increment({
          "completed_onboarding_steps": 1,
        });
      }
    }
  }, [smallGraphData, isLoadingFanbase])

  const plotChartData = (dataArray: any) => {
    const currentPlatformData = JSON.parse(JSON.stringify(smallGraphData))

    const overallDataCalculator: any = {}

    dataArray.map((p: any) => {
      const {
        platform,
        stat,
      } = p.data.data

      // returns the data for the chart 
      const dataForChart: any = plotChart(p.data.data)

      const foundItem = currentPlatformData.find((d: any) => {
        return platform === d.platformId && stat === d.statIdentifier
      })

      foundItem.chartData.data = dataForChart.chartData
      foundItem.secondaryData = dataForChart.growthData

      // now we've got the items populated for each graph 

      let minValue = 0
      dataForChart.chartData.map((dateValue: any) => {
        minValue = minValue === 0 ? dateValue.value : Math.min(dateValue.value, minValue)
        if (!overallDataCalculator[dateValue.date]) {
          overallDataCalculator[dateValue.date] = {
            datetime: dateValue.date,
            values: []
          }
        }

        overallDataCalculator[dateValue.date].values.push(parseInt(dateValue.value))

        return null;
      })

      return null
    })

    // overalldatacalculator is an object with:
    /**
     * {
     *  datetime: 2021-08-09T00:00:00.000Z
     *  values: []
     * }
     * 
     * Just calculate the average for now
     */

    const dateValues = Object.keys(overallDataCalculator)
    const weeklyOverallData = dateValues.map((d: any) => {
      const { values, datetime } = overallDataCalculator[d]
      const filteredValues = values.filter((v: any) => v !== 0)
      const total = filteredValues.reduce((acc: any, c: any) => acc + c, 0);
      // const avg = total / filteredValues.length;

      return {
        date: datetime,
        value: total
      }
    })

    const overallChart = {
      chartData: weeklyOverallData,
      percentage: 0
    }

    // in the case where NO DATA is available for any platform
    //  (for example, where an artist doesnt have any past data in chartmetrics and we're starting fresh),
    // show an empty chart
    if (overallChart.chartData.length > 0) {
      overallChart.percentage = ((weeklyOverallData[weeklyOverallData.length - 1].value - weeklyOverallData[0].value) / weeklyOverallData[0].value) * 100
    }

    setSmallGraphData(currentPlatformData)
    setOverallStats(overallChart)
    setLoadingFanbase(false)
    setLoadingNewDate(false)
  }

  const plotChart = (chartData: any) => {
    let totalDays = 7

    if (selectedRange === 'last-30-days') {
      totalDays = 30
    } else if (selectedRange === 'last-3-months') {
      totalDays = 90
    } else if (selectedRange === 'custom') {
      totalDays = dayjs(selectedDate.endDate).diff(dayjs(selectedDate.startDate), 'day')
    }

    const lastWeekStartDate = selectedDate.startDate

    /**
     * an item in the api's data reponse array looks like
     * {
     *    datetime: 2021-08-09T00:00:00.000Z
     *    fanbase_count: 123
     * }
     **/

    /**
     * get 7 days ago date
     *  - get each day for last 7 days
     */
    let localChartData: any = []

    let lastVal = 0

    // if no data was returned at all, it means we're still gathering data
    //  - return a fully empty chart breakdown

    if (chartData.chartData.length === 0) {
      return {
        chartData: localChartData,
        growthData: {
          currentGrowthValue: 0,
          percentage: 0
        }
      }
    }

    for (let i = 0; i < totalDays; i++) {
      // get each day of data based on avail date
      const date = dayjs(lastWeekStartDate).add(i, 'days').startOf('day');
      let dateData = chartData.chartData.find((d: any) => {
        return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
      })

      // if the dateData for the given day is found, then use that as the
      // lastVal AND set it for the day
      let dateDetails = {
        date: date.format("YYYY-MM-DD"),
        value: 0
      }

      if (dateData) {
        let fanCount = parseInt(dateData.fanbase_count)

        if (fanCount > 0) {
          dateDetails = {
            date: date.format("YYYY-MM-DD"),
            value: fanCount
          }
        } else {
          // otherwise, 
          //  - if this is the first value, get the next value
          //  - if this is the last value, get the value before it
          //  - if this is a middle value, ttake the averages of before and after
          if (i === 0) {
            const nextDate = date.clone().add(1, 'days').startOf('day');
            let nextDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
            })

            if (nextDateData) {
              // if there is a found value, then use that value 
              let nextDayCount = parseInt(nextDateData.fanbase_count)

              if (nextDayCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: nextDayCount
                }
              }
            }
          } else if (i === 6) {
            //  - if this is the last value, get the value before it
            const prevDate = date.clone().subtract(1, 'days').startOf('day');
            let prevDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
            })

            if (prevDateData) {
              // if there is a found value, then use that value 
              let prevDayCount = parseInt(prevDateData.fanbase_count)

              if (prevDayCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: prevDayCount
                }
              }
            }
          } else {
            //  - if this is a middle value, take the averages of before and after
            const nextDate = date.clone().add(1, 'days').startOf('day');
            let nextDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
            })

            const prevDate = date.clone().subtract(1, 'days').startOf('day');
            let prevDateData = chartData.chartData.find((d: any) => {
              return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
            })

            if (prevDateData && nextDateData) {
              let prevDayCount = parseInt(prevDateData.fanbase_count)
              let nextDayCount = parseInt(nextDateData.fanbase_count)

              if (nextDayCount > 0 && prevDateData > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: (nextDayCount + prevDayCount) / 2
                }
              }
            } else if (prevDateData) {
              let prevDayCount = parseInt(prevDateData.fanbase_count)
              if (prevDayCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: prevDayCount
                }
              }
            } else if (nextDateData) {
              let nextDayCount = parseInt(nextDateData.fanbase_count)
              if (nextDayCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: nextDayCount
                }
              }
            } else {
              // no data found for previous date OR next date
              // -- loop thru the dates until a value is found 
              let dateAddition = 2
              let dataFound: any = null
              while (!dataFound) {
                if (dateAddition === 6) {
                  break
                }

                let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');
                let attemptedDateData = chartData.chartData.find((d: any) => {
                  return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(attemptedDate).format('YYYY-MM-DD')
                })

                if (attemptedDateData) {
                  dataFound = attemptedDateData
                }

                dateAddition++
              }

              if (dataFound) {

                const foundDataCount = parseInt(dataFound.fanbase_count)
                if (foundDataCount > 0) {
                  dateDetails = {
                    date: date.format("YYYY-MM-DD"),
                    value: foundDataCount
                  }
                }
              }
            }
          }
        }
      } else {
        if (i === 0) {
          const nextDate = date.clone().add(1, 'days').startOf('day');
          let nextDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
          })

          if (nextDateData) {
            // if there is a found value, then use that value 
            let nextDayCount = parseInt(nextDateData.fanbase_count)
            if (nextDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: nextDayCount
              }
            }
          } else {
            // loop thru the dates until a value is found 
            let dateAddition = 2
            let dataFound: any = null
            while (!dataFound) {
              if (dateAddition === 6) break

              let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');

              let attemptedDateData = chartData.chartData.find((d: any) => {
                return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(attemptedDate).format('YYYY-MM-DD')
              })

              if (attemptedDateData) {
                dataFound = attemptedDateData
              }

              dateAddition++
            }

            if (dataFound) {
              const foundDataCount = parseInt(dataFound.fanbase_count)
              if (foundDataCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: foundDataCount
                }
              }
            }
          }
        } else if (i === 6) {
          //  - if this is the last value, get the value before it
          const prevDate = date.clone().subtract(1, 'days').startOf('day');
          let prevDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
          })

          if (prevDateData) {
            // if there is a found value, then use that value 
            let prevDayCount = parseInt(prevDateData.fanbase_count)

            if (prevDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: prevDayCount
              }
            }
          }
        } else {
          //  - if this is a middle value, take the averages of before and after
          const nextDate = date.clone().add(1, 'days').startOf('day');
          let nextDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')
          })

          const prevDate = date.clone().subtract(1, 'days').startOf('day');
          let prevDateData = chartData.chartData.find((d: any) => {
            return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(prevDate).format('YYYY-MM-DD')
          })

          if (prevDateData && nextDateData) {
            let prevDayCount = parseInt(prevDateData.fanbase_count)
            let nextDayCount = parseInt(nextDateData.fanbase_count)

            if (nextDayCount > 0 && prevDateData > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: (nextDayCount + prevDayCount) / 2
              }
            }
          } else if (prevDateData) {
            let prevDayCount = parseInt(prevDateData.fanbase_count)
            if (prevDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: prevDayCount
              }
            }
          } else if (nextDateData) {
            let nextDayCount = parseInt(nextDateData.fanbase_count)
            if (nextDayCount > 0) {
              dateDetails = {
                date: date.format("YYYY-MM-DD"),
                value: nextDayCount
              }
            }
          } else {
            // no data found for previous date OR next date
            // -- loop thru the dates until a value is found 
            let dateAddition = 2
            let dataFound: any = null
            while (!dataFound) {
              if (dateAddition === 6) {
                break
              }

              let attemptedDate = date.clone().add(dateAddition, 'days').startOf('day');
              let attemptedDateData = chartData.chartData.find((d: any) => {
                return dayjs(d.datetime).format('YYYY-MM-DD') === dayjs(attemptedDate).format('YYYY-MM-DD')
              })

              if (attemptedDateData) {
                dataFound = attemptedDateData
              }

              dateAddition++
            }

            if (dataFound) {

              const foundDataCount = parseInt(dataFound.fanbase_count)
              if (foundDataCount > 0) {
                dateDetails = {
                  date: date.format("YYYY-MM-DD"),
                  value: foundDataCount
                }
              }
            }
          }
        }
      }

      localChartData.push(dateDetails)
    }

    // in the case where the first few days have 0 data, and the next few days dont,
    // update all of the first few days to the firstvalue found
    let firstFoundValue = 0
    localChartData.map((o: {
      date: string,
      value: number
    }, idx: number) => {
      const {
        value
      } = o

      if (value > 0) {
        firstFoundValue = value
      }

      return null
    })

    // now go thru the numbers and set each one to the fixed one
    localChartData = localChartData.map((o: {
      date: string,
      value: number
    }, idx: number) => {
      const {
        value,
        date
      } = o

      if (value === 0) {
        return {
          date: date,
          value: firstFoundValue
        }
      } else {
        return o
      }
    })

    return {
      chartData: localChartData,
      growthData: {
        currentGrowthValue: chartData.growthData.currentGrowthValue,
        percentage: ((localChartData[localChartData.length - 1].value - localChartData[0].value) / localChartData[0].value) * 100
      }
    }
  }

  const refreshDashboardData = async () => {
    setLoadingFanbase(true)

    // call refresh function 
    try {
      const url = `/dashboard/brand/${currentBrand!.id}/fanbase-refresh`
      await Axios.get(url)

      // then on success, re-call the individual fanbase data
      await getContent()
    } catch (e) {
      console.error("Error calling refreshDashboardData", e)
    }
  }

  const refreshConnections = () => {
    const currentPlatformsData = smallGraphData.map(platformData => {
      const { platformId = 'spotify' } = platformData;
      platformData.needsConnection = currentBrand && NEEDS_CONNECTIONS[platformId](currentBrand);
      return platformData;
    })
    setSmallGraphData(currentPlatformsData)
  }

  const updateBrandConnections = async () => {
    try {
      await reloadBrand()
      refreshConnections()
    } catch (e) {
      console.error("Error calling updateBrandConnections", e)
    }
  }

  const handleShowAlreadyExistsModal = (artist: SpotifyArtist, existingBrand: CurrentBrand) => {
    setOpenAlreadyExistModal(true)
    setArtist(artist)
    setExistingBrand(existingBrand)
    setPlatformsModalOpen(false)
  }
  const handleCloseAlreadyExistsModal = () => {
    setPlatformsModalOpen(false)
    setArtist({} as SpotifyArtist)
    setExistingBrand({} as CurrentBrand)
    setOpenAlreadyExistModal(false)
  }
  const handleGoBackAlreadyExistsModal = () => {
    setPlatformsModalOpen(true)
    setArtist({} as SpotifyArtist)
    setExistingBrand({} as CurrentBrand)
    setOpenAlreadyExistModal(false)
  }

  const handleItemClick = (itemType: DashboardSideContent) => {
    switch (itemType) {
      case DashboardSideContent.INSTAGRAM:
        // TODO: Handle Instagram click
        break;
      case DashboardSideContent.YOUTUBE:
        // TODO: Handle YouTube click
        break;
      case DashboardSideContent.FACEBOOK:
        // TODO: Handle Facebook click
        break;
      case DashboardSideContent.TIKTOK:
        // TODO: Handle TikTok click
        break;
      case DashboardSideContent.SOUNDCLOUD:
        // TODO: Handle SoundCloud click
        break;
      case DashboardSideContent.DEEZER:
        // TODO: Handle Deezer click
        break;
      default:
        break;
    }
  };

  const renderOverallDashboard = () => {
    return (
      <div
        className="overallDashboardContainer"
        style={mStyle(sty, 'overallDashboardContainer')}
      >
        <OverallDashboard
          isLoadingNewDate={isLoadingNewDate}
          isYTLoginAdmin={isYTLoginAdmin}
          fanbaseMetrics={fanbaseMetrics}
          timeRange={selectedRange}
          setTimeRange={updateDateRange}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          refreshDashboardData={refreshDashboardData}
          isLoading={isLoadingFanbase}
          setLoading={setLoadingFanbase}
          chartData={smallGraphData}
          overallData={overallStats}
          setPlatformsModalOpen={(value: any) => {
            setPlatformsModalOpen(value)
          }}
          updateBrandConnections={updateBrandConnections}
        />
      </div>
    )
  }

  const renderSideContentInner = () => {
    return (
      null
    )
    return (
      <div className="dashboardSideItemsContainer" style={mStyle(sty, 'dashboardSideItemsContainer')}>
        {dashboardSideContentArray.map((itemString, index) => {
          const item = dashboardSideContent[itemString]
          return (
            <div
              key={index}
              className="dashboardSideItem"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                paddingBottom: 16,
                borderBottom: index === dashboardSideContentArray.length - 1 ? 'none' : `1px solid ${Colors.divider}`,
              }}
            >
              <img
                className="dashboardSideItemImage"
                style={sty.dashboardSideItemImage}
                src={item.image}
                alt={item.title}
              />
              <div
                className="dashboardSideItemTitle"
                style={{ fontSize: 18 }}
              >
                {item.title}
              </div>
              <div
                className="dashboardSideItemDescriptionContainer"
                style={sty.dashboardSideItemDescriptionContainer}
                onClick={() => handleItemClick(itemString)}
              >
                <div
                  className="dashboardSideItemDescription"
                  style={sty.dashboardSideItemDescription}
                >
                  {item.description}
                </div>
                <img className="dashboardSideItemChevron" src={chevronRightGray} alt="chevron right" />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderUpdateNotifications = () => {
    return (
      <div className="updateNotificationsContainer" style={mStyle(sty, 'updateNotificationsContainer')}>
        <div style={{ marginRight: 'auto', marginBottom: 8 }}>Recommendations:</div>
        <Recommendations
          isRecommdationVertical
          isBanner={mobileView}
        />
        {/* <div className="updateNotificationsCount" style={sty.updateNotificationsCount}>
          {dashboardSideContentArray.length}
        </div> */}
      </div>
    )
  }

  const renderDashboardSideContent = () => {
    return (
      <div className="dashboardSideContentContainer" style={mStyle(sty, 'dashboardSideContentContainer')}>
        <div className="sideDashboardButtonsContainer" style={mStyle(sty, 'sideDashboardButtonsContainer')}>
          <ButtonSymphony
            className="newWebsiteSideDashboardButton"
            onClick={() => setShowCreateNewWebsiteModal(true)}
            variant="outlined"
            width="100%"
            size="small"
          >
            + New Website
          </ButtonSymphony>
          <ButtonSymphony
            className="newCampaignSideDashboardButton"
            onClick={() => setShowCreateNewCampaignModal(true)}
            width="100%"
            size="small"
          >
            + New Campaign
          </ButtonSymphony>
        </div>

        {renderUpdateNotifications()}
        {renderSideContentInner()}
      </div>
    )
  }

  /** Controls visibility of the welcome modal */
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  /** Check if user has seen welcome modal before */
  useEffect(() => {
    if (currentBrand?.createdAt && new Date(currentBrand.createdAt) > new Date('2025-01-15T22:30:00.000Z')) {
      const hasSeenWelcome = getCookie('hasSeenWelcomeModal');
      if (!hasSeenWelcome) {
        track("NEW ONBOARDING: Welcome to Symphony Modal", {
          version: ONBOARDING_VERSION
        })
        setShowWelcomeModal(true);
      }
    }
  }, []);

  /** Handle closing the welcome modal */
  const handleCloseWelcomeModal = () => {
    setCookie('hasSeenWelcomeModal')
    setShowWelcomeModal(false)
  };

  const getDashboardLeftContainerWidth = () => {
    if (!showRecommendationsSidebar || smallView || mobileView) {
      return '100%'
    }

    return 'calc(100% - 356px)'
  }

  return (
    <>
      <MobileHeader
        className="dashboardComponentMobileHeader"
        loadedBrands={loadedBrands}
      />
      {isLoadingFanbase ?
        <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
          <NewLoader black />
          <p className="mt-1 text-center">Loading Dashboard...</p>
        </div>
        :
        <PageSymphony
          className="dashboardComponent"
          isProMobileNavCta
        >
          <ContentSymphony>
            {/* Only render BannerContainer if at least one banner needs to be shown */}
            {(showPaymentFailedBanner && currentBrand?.subscription?.cid) &&
              <BannerContainer>
                {showPaymentFailedBanner && currentBrand?.subscription?.cid &&
                  <PaymentFailedBanner customerId={currentBrand.subscription.cid} />
                }
              </BannerContainer>
            }

            <div className="dashboardInnerContainer" style={mStyle(sty, 'dashboardInnerContainer')}>
              <div
                className="dashboardInnerContainerLeft"
                style={{
                  ...mStyle(sty, 'dashboardInnerContainerLeft'),
                  width: getDashboardLeftContainerWidth(),
                }}
              >
                {isBrandAdmin &&
                  <QuickActions
                    showMaestro={mobileView}
                    askMaestroSource="Dashboard"
                    campaignCreationSource="maestro-dashboard"
                  />
                }
                {(mobileView || smallView) && showRecommendationsSidebar &&
                  <Recommendations isBanner />
                }
                {renderOverallDashboard()}
              </div>
              {!mobileView && !smallView && showRecommendationsSidebar &&
                renderDashboardSideContent()
              }
            </div>
          </ContentSymphony>
        </PageSymphony>
      }

      <PlatformsConnector
        open={platformsModalOpen}
        closeModal={() => setPlatformsModalOpen(false)}
        updateBrandConnections={updateBrandConnections}
        onArtistAlreadyExist={handleShowAlreadyExistsModal}
      />
      {
        Boolean(artist) &&
        <AlreadyExistingArtist
          isOpen={openAlreadyExistModal}
          goBack={handleGoBackAlreadyExistsModal}
          closeModal={handleCloseAlreadyExistsModal}
          artist={artist}
          existingBrand={existingBrand}
        />
      }

      {/* RENDER: MODALS */}
      {showCreateNewWebsiteModal &&
        <CreateNewWebsiteModal
          isOpen={showCreateNewWebsiteModal}
          onClose={() => setShowCreateNewWebsiteModal(false)}
        />
      }
      {showCreateNewCampaignModal &&
        <CreateNewCampaignModal
          isOpen={showCreateNewCampaignModal}
          onClose={() => setShowCreateNewCampaignModal(false)}
        />
      }
      {currentBrand &&
        <UpgradeToProSucceededModal
          dialogProps={{
            open: showUpgradeToProSucceededModal,
            onClose: closeUpgradeToProSucceededModal,
          }}
          sessionId={sessionId}
        />
      }
      <ModalSymphony
        className="welcomeToSymphonyModal"
        isOpen={showWelcomeModal}
        onClose={handleCloseWelcomeModal}
        maxWidth={750}
      >
        <WelcomeToSymphony skipAction={handleCloseWelcomeModal} />
      </ModalSymphony>
    </>
  );
};

const sty: Record<string, CSSProperties> = {
  dashboardComponent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    marginBottom: 0,
  },
  dashboardComponent_mobile: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  overallDashboardContainer: {
    backgroundColor: 'white',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
    width: '100%',
    maxWidth: 1200,
    borderRadius: 8,
  },
  overallDashboardContainer_mobile: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 0,
  },
  dashboardInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    maxWidth: 1200,
    width: '100%',
  },
  dashboardInnerContainer_mobile: {
    flexDirection: 'column',
  },
  dashboardInnerContainerLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  dashboardSideContentContainer: {
    position: 'sticky',
    top: 24,
    backgroundColor: 'white',
    padding: 16,
    width: 340,
    borderRadius: 8,
    flexShrink: 0,
    alignSelf: 'flex-start',
  },
  dashboardSideContentContainer_mobile: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 32,
  },
  dashboardSideContentContainer_medium: {
    top: 16,
  },
  sideDashboardButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    marginBottom: 16,
  },
  dashboardSideItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  dashboardSideItemImage: {
    width: '100%',
    height: 120,
    borderRadius: 12,
  },
  dashboardSideItemDescription: {
    color: Colors.greyDark,
    display: '-webkit-box',
    cursor: 'pointer',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.2em',
    maxHeight: '2.4em',
  },
  dashboardSideItemDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
  updateNotificationsCount: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 9,
    paddingRight: 9,
    backgroundColor: Colors.purple,
    color: 'white',
    borderRadius: 8,
    width: 'fit-content',
  },
  updateNotificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}

export default Dashboard;