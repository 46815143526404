import { useState } from 'react';
import { Dialog } from '@material-ui/core';
import {
  ModalContainer,
  useStyles
} from './style';
import { ReactComponent as PauseIcon } from 'assets/images/pause-purple.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play-purple.svg';
import { toast, ToastOptions } from 'react-toastify';
import HeaderModal from '../HeaderModal';
import Axios from "helpers/Interceptor";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading_White.json"
import Lottie from "react-lottie"
import FB from 'helpers/FB';
import * as Sentry from "@sentry/react";
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import Colors from 'modules/Colors';

interface Props {
  open: boolean;
  pause: boolean;
  campaignId: number;
  onErrorHandler?: () => void;
  closeModal: () => void;
  toastOptions?: ToastOptions;
  reload: () => void;
  facebookErrorCode?: string[];
}

const TOAST_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  closeButton: true,
  style: {
    background: "#07C806",
    color: "#FFFFFF",
    margin: "16px 16px auto 16px",
    fontSize: "16px",
    borderRadius: "8px",
  },
}

const UpdatingCampaignLabel = ({ pause }: { pause: boolean }) => (
  // <Grid container alignItems="center" alignContent="center">
  //   <Grid item xs={2}>
  //     <Lottie
  //       height={16}
  //       width={16}
  //       options={{
  //         loop: true,
  //         autoplay: true,
  //         animationData: SymphonyLoadingLottie,
  //       }}
  //     />
  //   </Grid>
  //   <Grid item xs>
  //     <p>{ pause ? "Resuming" : "Pausing" } this campaign...</p>
  //   </Grid>
  // </Grid>
  <div className='flex items-center justify-center'>
    <div>
      <Lottie
        height={16}
        width={16}
        options={{
          loop: true,
          autoplay: true,
          animationData: SymphonyLoadingLottie,
        }}
      />
    </div>
    <p className='ml-2'>{pause ? "Pausing" : "Resuming"} campaign...</p>

  </div>
)

const PauseCampaignModal = ({
  open,
  pause,
  closeModal,
  campaignId,
  toastOptions,
  reload,
  onErrorHandler,
  facebookErrorCode,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const { mobileView } = useScreen();
  const labelPaused = pause ? "paused" : "resumed";

  const errorCodesThatImpedeExecution = [
    "FB_BILLING_HOLD_ERROR",
    "FB_PENDING_BALANCE",
    "FB_NO_PERMISSIONS_OVER_AD_ACCOUNT",
    "FB_NO_PERMISSIONS_OVER_PIXEL",
    "FB_IG_PAGE_RESTRICTED",
    "FB_CAMPAIGN_ADS_DENIED",
    "FB_AD_ACCOUNT_UNSETTLED",
  ];

  const handlePause = async () => {
    setLoading(true)
    const options = toastOptions || TOAST_OPTIONS
    const marginToast = mobileView ? "24px 16px auto 16px" : "41px 22px auto"

    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) { }

    try {
      const response = await Axios.put(`/campaign/${campaignId}/pause`, {
        pause, access_token
      }, { timeout: 30000 });
      if (!response.data?.isError) {
        reload();
      }

      toast.success(`Campaign ${labelPaused}`, {
        ...options,
        style: {
          ...options.style,
          margin: marginToast,
        }
      })
    } catch (e) {
      Sentry.captureException(`Error pausing/resuming campaign ${campaignId}: ${e}`)
      onErrorHandler?.()
      const isErrorPause = facebookErrorCode?.some(code => errorCodesThatImpedeExecution.includes(code));
      if (isErrorPause) {
        const errorMessage = "Your campaign isn't currently running due to an ad account issue. Please resolve the issue in Meta first before updating your campaign."
        toast.error(errorMessage, {
          ...options,
          style: {
            ...options.style,
            margin: marginToast,
            background: Colors.red,
          }
        })
      }
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={mobileView ? "auto" : "678px"}
        padding={mobileView ? "32px 24px" : "32px"}
      >
        <HeaderModal
          HeaderIcon={pause ? PauseIcon : PlayIcon}
          title={pause ? "Pause this campaign" : "Resume this campaign"}
          subtitle={
            pause
              ? "You can run your campaign again by unpausing it before the end date."
              : "Your campaign will be resumed and continue spending until the end date."
          }
          closeModal={closeModal}
        />
        <ButtonSymphony
          className="buttonPauseCampaignModal"
          disabled={loading}
          onClick={handlePause}
        >
          {pause ?
            (loading ? <UpdatingCampaignLabel pause={pause} /> : "Yes, pause this campaign")
            :
            (loading ? <UpdatingCampaignLabel pause={pause} /> : "Yes, resume this campaign")
          }
        </ButtonSymphony>
      </ModalContainer>
    </Dialog>
  )
}

export default PauseCampaignModal;
