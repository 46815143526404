import { Subtitle1 } from "components/shareable/Typography";
import ImagePalette from "pages/post-auth/Website/ImagePalette";
import { useStyles } from "../../../styles";

/**
 * Interface defining the required props for the ColorSelectorSection component
 * @interface ColorSelectorSectionProps
 */
interface ColorSelectorSectionProps {
  /** The title to display above the color selector */
  sectionTitle: string;
  /** The currently selected color value */
  selectedColor: string;
  /** Callback function to handle color changes */
  onColorChange: (color: string) => void;
  /** URL of the image to use for the color palette */
  paletteImageUrl: string;
  /** Optional className to apply to the container */
  className?: string;
}

/**
 * A reusable component for selecting colors with both a color picker and an image-based palette
 * @component
 * @param {ColorSelectorSectionProps} props - The component props
 * @returns {JSX.Element} The rendered ColorSelectorSection component
 */
export const ColorSelectorSection = ({
  sectionTitle,
  selectedColor,
  onColorChange,
  paletteImageUrl,
  className,
}: ColorSelectorSectionProps): JSX.Element => {
  const classes = useStyles();

  /**
   * Handles changes from the hex input field
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event
   */
  const handleHexInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onColorChange('#' + e.target.value);
  };

  /**
   * Handles changes from the color picker input
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event
   */
  const handleColorPickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onColorChange(e.target.value);
  };

  return (
    <div className={className}>
      <Subtitle1>{sectionTitle}</Subtitle1>
      <div
        className='palettePickerContainer'
        style={{ display: 'flex', alignItems: 'center', overflow: 'scroll', gap: 8 }}
      >
        <div className={classes.colorPickerContainer}>
          <input
            className={classes.colorPickerBox}
            type='color'
            id='bg-color'
            name='bgcolor'
            value={selectedColor}
            onChange={handleColorPickerChange}
          />
          <div>#</div>
          <input
            value={selectedColor.replace('#', '')}
            className={classes.hexColorInput}
            onChange={handleHexInputChange}
            maxLength={6}
          />
        </div>
        <ImagePalette
          imageUrl={paletteImageUrl}
          selectedColor={selectedColor}
          selectColor={onColorChange}
          showBlackAndWhite
        />
      </div>
    </div>
  );
};