import { Grid } from "@material-ui/core";
import { Headline1, Subtitle2 } from "components/shareable/Typography";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import BillingPlanOptions from "components/shareable/UpgradeToProModal/Components/BillingPlanOptions";
import { BillingCycle, format, labels, teamLabels } from "components/shareable/UpgradeToProModal/utils";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Container, StyledLabel, SymphonyTooltip } from "styles/shared";
import { useStyles } from "./styles";
import PrimaryButton from "components/shareable/PrimaryButton";
import { getBrandPartnerDetails } from "helpers/partners";
import { CurrencyCodesType } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";
import { OnApproveActions, OnApproveData, CreateOrderActions } from "@paypal/paypal-js";
import {
    CheckoutType,
    PRO_MONTHLY_PLAN_PRICE,
    TEAM_MONTHLY_PLAN_PRICE,
    TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE,
    PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
    TEAM_ANNUAL_BY_MONTH_PLAN_PRICE,
    ONE_OFF_CAMPAIGN_PRICE,
    DEFAULT_CURRENCY
} from "modules/Const";
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import Intercom from "helpers/Intercom";
import { getConvertedCurrency } from "services/symphonyApi";
import { getNumberWithDecimals } from "helpers/General";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { clsx } from 'modules/Utils';
import SymphonyTabs from "components/shareable/SymphonyTabs";
import PayPalButtonGroup from "./PaypalCheckoutOption";
import Colors from "modules/Colors";

type CurrencyPrices = {
    annualConverted: number,
    monthlyConverted: number,
    oneOffConverted: number
}

/**
 * Props for the SubmitCampaignOrUpgrade component. This type is a discriminated union
 * based on the `allowsDirectPayment` flag which determines available payment options.
 */
type SubmitCampaignOrUpgradeProps = {
    /** Indicates if the submission button is in a loading state */
    submissionButtonIsLoading: boolean;
    /** Indicates if there's an error state that should disable submission */
    error: boolean;
    /** Handler for Stripe-based campaign payments */
    handleClickCampaignWithStripe: (checkoutType: CheckoutType) => void;
    /** Handler for direct campaign submission (used when payment is not required) */
    handleClickSubmitButton: () => void;
    /** Currently selected billing cycle (monthly/annual) */
    selectedBillingCycle: BillingCycle;
    /** State setter for billing cycle selection */
    setBillingCycle: Dispatch<SetStateAction<BillingCycle>>;
    /** Currency code for pricing display and transactions */
    currencyCode: CurrencyCodesType;
    /** Optional disclaimer text shown below the campaign submission UI */
    campaignDisclaimer?: string;
    /** Flag indicating if direct payment is allowed - false variant */
    allowsDirectPayment: false;
    /** PayPal order creation handler - not available when direct payment is false */
    createPaypalOrder?: never;
    /** PayPal order cancellation handler - not available when direct payment is false */
    onCancelPayPalOrder?: never;
    /** PayPal order error handler - not available when direct payment is false */
    onErrorPayPalOrder?: never;
    /** PayPal order approval handler - not available when direct payment is false */
    onPayPalOrderApprove?: never;
} | {
    /** Indicates if the submission button is in a loading state */
    submissionButtonIsLoading: boolean;
    /** Indicates if there's an error state that should disable submission */
    error: boolean;
    /** Currency code for pricing display and transactions */
    currencyCode: CurrencyCodesType;
    /** Handler for Stripe-based campaign payments */
    handleClickCampaignWithStripe: (checkoutType: CheckoutType) => void;
    /** Direct submission handler - not available when direct payment is true */
    handleClickSubmitButton?: never;
    /** Currently selected billing cycle (monthly/annual) */
    selectedBillingCycle: BillingCycle;
    /** State setter for billing cycle selection */
    setBillingCycle: Dispatch<SetStateAction<BillingCycle>>;
    /** Optional disclaimer text shown below the campaign submission UI */
    campaignDisclaimer?: string;
    /** Flag indicating if direct payment is allowed - true variant */
    allowsDirectPayment: true;
    /** Handler to create a PayPal order - required when direct payment is true */
    createPaypalOrder?: (data: Record<string, unknown>, actions: CreateOrderActions) => Promise<string>;
    /** Handler for PayPal order cancellation - optional when direct payment is true */
    onCancelPayPalOrder?: () => void;
    /** Handler for PayPal order errors - optional when direct payment is true */
    onErrorPayPalOrder?: () => void;
    /** Handler for PayPal order approval - optional when direct payment is true */
    onPayPalOrderApprove?: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
};

const SubmitCampaignOrUpgrade = ({
    submissionButtonIsLoading,
    error,
    handleClickCampaignWithStripe,
    handleClickSubmitButton,
    selectedBillingCycle,
    setBillingCycle,
    currencyCode,
    campaignDisclaimer = "Once you submit your campaign, it will be sent to Facebook for review, and begin running automatically once approved.",
    createPaypalOrder,
    onCancelPayPalOrder,
    onErrorPayPalOrder,
    onPayPalOrderApprove,
    allowsDirectPayment
}: SubmitCampaignOrUpgradeProps) => {
    const classes = useStyles()
    const {
        organization: primaryOrganization,
        brandIsInOrganization,
        hasProAccountOverage
    } = useCurrentTeam()
    const { currentBrand, isProBrand, proFreeTrialRedeemed } = useContext(CurrentBrandContext);
    
    const freeTrialOver = !proFreeTrialRedeemed()
    // get the currency codes
    const currencySymbol = getSymbolFromCurrency(currencyCode)


    const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
        annualConverted: PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
        monthlyConverted: PRO_MONTHLY_PLAN_PRICE,
        oneOffConverted: ONE_OFF_CAMPAIGN_PRICE
    })
    const [monthlyAdditionalProPlanPrice, setMonthlyAdditionalProPlanPrice] = useState<number>(TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE)

    // for non-Pro users, we show the option to run a one-off campaign or upgrade to Pro
    const [selectedTab, setSelectedTab] = useState<CheckoutType>(CheckoutType.ONE_OFF_CAMPAIGN)
    // UI shown when a user can successfully submit campaign
    const renderSubmissionUI = () => {
        if (allowsDirectPayment) {
            return (
                <>
                    <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
                        {campaignDisclaimer}
                    </StyledLabel>

                    <Grid item className={clsx(classes.noPaddingTop)}>
                        <Container display="flex" flexDirection="column" gap="12px" marginTop="16px">
                            <PrimaryButton
                                width="100%"
                                onClick={() => handleClickCampaignWithStripe(CheckoutType.ONE_OFF_CAMPAIGN)}
                                disabled={submissionButtonIsLoading}
                                loading={submissionButtonIsLoading}
                                text={"Checkout with Card"} />
                            <PayPalButtonGroup
                                createOrder={createPaypalOrder}
                                onCancel={onCancelPayPalOrder}
                                onError={onErrorPayPalOrder}
                                onApprove={onPayPalOrderApprove}
                                className="w-full"
                                currencyCode={currencyCode}
                                style={{ marginTop: 8 }}
                            />
                        </Container>
                    </Grid >
                </>
            )
        }

        return (
            <Grid item className={classes.noPaddingTop}>
                <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
                    {campaignDisclaimer}
                </StyledLabel>
                <Grid container direction="column">
                    <div style={{
                        marginTop: '24px'
                    }}>
                        <PrimaryButton onClick={handleClickSubmitButton}
                            width="100%"
                            text="Submit Campaign"
                            loading={submissionButtonIsLoading}
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }

    // this is used ONLY for international currencies - specifically when a user selects an ad account with an international currency, and
    // we need to convert the "Upgrade to Pro" price to the international currency
    const getPricesValues = async () => {
        let baseMonthly;
        let baseAnnual;
        let oneOffCampaign;

        if (primaryOrganization && primaryOrganization.status === 'ready') {
            baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_MONTHLY_PLAN_PRICE })
            baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_ANNUAL_BY_MONTH_PLAN_PRICE })
        } else {
            baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: PRO_MONTHLY_PLAN_PRICE })
            baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: PRO_ANNUAL_BY_MONTH_PLAN_PRICE })
        }

        oneOffCampaign = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: ONE_OFF_CAMPAIGN_PRICE })

        setCurrencyPrices({
            annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
            monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2)),
            oneOffConverted: Number(getNumberWithDecimals(oneOffCampaign, 2))
        })
    }

    useEffect(() => {
        if (currencyCode !== DEFAULT_CURRENCY) {
            if (primaryOrganization && primaryOrganization.status === 'ready') {
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE })
                    .then((res) => setMonthlyAdditionalProPlanPrice(Number(getNumberWithDecimals(res, 2))))
            }
            getPricesValues()
        } else {
            // TODO: remove this else statement when we fix the way we switch from brand to brand, right now it keeps some values unchanged
            if (currencyPrices.monthlyConverted !== PRO_MONTHLY_PLAN_PRICE) {
                setCurrencyPrices({
                    annualConverted: PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
                    monthlyConverted: PRO_MONTHLY_PLAN_PRICE,
                    oneOffConverted: ONE_OFF_CAMPAIGN_PRICE
                })
            }
            if (primaryOrganization && primaryOrganization.status === 'ready') {
                setCurrencyPrices({
                    annualConverted: TEAM_ANNUAL_BY_MONTH_PLAN_PRICE,
                    monthlyConverted: TEAM_MONTHLY_PLAN_PRICE,
                    oneOffConverted: ONE_OFF_CAMPAIGN_PRICE
                })
            }
        }
    }, [currencyCode])

    const renderOrganizationUpgradeToProText = () => {
        // if a user is on a ready organization, there is no free trial opportunity + no partner-driven discount
        // - they either have a UI that lets them know the plan s included,
        // - or a UI to let them know they're purchasing an additional seat
        const isOverage = primaryOrganization?.status === 'ready' && hasProAccountOverage

        // based on selected billing cycle, get the price
        const { billedTrialText } = teamLabels[selectedBillingCycle ?? 'monthly']

        // the price, formatted properly for the international currency
        const formattedExtraSpend = format(monthlyAdditionalProPlanPrice)
        const formattedCurrency = currencySymbol
        // the time period (monthly or annual)
        const formattedTimePeriod = billedTrialText

        // to open Upgrade to Pro details article
        const openIntercom = () => Intercom.openSymphonyProArticle();

        let subtitleText;
        subtitleText = isOverage ? `Once you submit your campaign, your profile will be upgraded to Pro as part of your Team Plan: ${formattedCurrency}${formattedExtraSpend} / ${formattedTimePeriod}.` : 'Once you submit your campaign, your profile will be upgraded to Pro as part of your Team Plan.'

        return (
            <>
                <Container className={classes.noPaddingBottom}
                    display="flex" flexDirection="column"
                    gap="8px">
                    <Container display="flex" flexDirection="row" gap="8px" >
                        <UpgradeToProLabel
                            maxWidth="210px"
                            color="PURPLE"
                            label="Symphony for Teams"

                        />
                        <button onClick={openIntercom}>
                            <SymphonyTooltip
                                className="w-6 h-6"
                                title="Click here to learn more."
                                arrow
                            >
                                <QuestionMarkIcon height={24} width={24} />
                            </SymphonyTooltip>
                        </button>
                    </Container>
                    {subtitleText && (<Subtitle2 color={Colors.textColor}>
                        {subtitleText}
                    </Subtitle2>)}
                    <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
                        {campaignDisclaimer}
                    </StyledLabel>
                </Container>

                {allowsDirectPayment ? (
                    <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
                        <Container display="flex" flexDirection="column" gap="12px" marginTop="16px">
                            <div>
                                <PrimaryButton
                                    width="100%"
                                    onClick={() => handleClickCampaignWithStripe(CheckoutType.UPGRADE_PLAN)}
                                    disabled={submissionButtonIsLoading}
                                    loading={submissionButtonIsLoading}
                                    text={"Checkout & Submit Campaign"}
                                />
                            </div>
                            <div className="payPalButtonContainer2" style={{ marginTop: 8 }}>
                                <PayPalButtonGroup
                                    createOrder={createPaypalOrder}
                                    onCancel={onCancelPayPalOrder}
                                    onError={onErrorPayPalOrder}
                                    onApprove={onPayPalOrderApprove}
                                    className="w-full"
                                    currencyCode={currencyCode}
                                />
                            </div>
                        </Container>
                    </Grid>
                ) : (
                    <Grid item className={classes.noPaddingTop}>
                        <Grid container direction="column">
                            <div style={{
                                marginTop: '24px'
                            }}>
                                <PrimaryButton
                                    width="100%"
                                    text={"Submit Campaign"}
                                    onClick={handleClickSubmitButton}
                                    loading={submissionButtonIsLoading}
                                    disabled={error ? !error : submissionButtonIsLoading}
                                />
                            </div>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    // UI shown when a user will need to upgrade to Pro
    const renderNonProCheckoutSection = () => {
        // used to determine how to calculate price
        const fromPartner = getBrandPartnerDetails(currentBrand)

        // based on selected billing cycle, get the price
        const { id: priceId, billedTrialText } = labels[selectedBillingCycle ?? 'monthly']

        // either discountAmount, or 1x (no discount)
        const partnerDiscount: number = fromPartner && fromPartner.discountAmount ? fromPartner.discountAmount : 1

        // the price, formatted properly for the international currency
        const formattedAmount = format(priceId === "monthly" ? currencyPrices.monthlyConverted * partnerDiscount : currencyPrices.annualConverted * 12)
        const formattedCurrency = currencySymbol
        // the time period (monthly or annual)
        const formattedTimePeriod = billedTrialText

        // to open Upgrade to Pro details article
        const openIntercom = () => Intercom.openSymphonyProArticle();

        let headlineText;
        if (selectedTab === CheckoutType.ONE_OFF_CAMPAIGN) {
            headlineText = `Run a one-off campaign for ${formattedCurrency}${currencyPrices.oneOffConverted}`
        } else {
            if (freeTrialOver) {
                headlineText = 'Upgrade to Pro to run AI-powered marketing campaigns.'
            } else {
                headlineText = 'Start your 14-day free trial with Symphony Pro to unlock unlimited campaigns.'
            }
        }

        let subtitleText;

        let showPartnerText;
        if (selectedTab === CheckoutType.ONE_OFF_CAMPAIGN) {
            subtitleText = 'You can run a campaign without subscribing. To unlock advanced ad analytics, we recommend upgrading to Pro.'
        } else {
            if (fromPartner) {
                switch (fromPartner.partnerId) {
                    case 'UM':
                        showPartnerText = `UnitedMasters SELECT artists `
                        break
                    case 'Audiomack':
                        showPartnerText = `Audiomack artists `
                        break
                    case 'CDBaby':
                        showPartnerText = `CDBaby artists `
                        break
                    case 'Daily Playlists':
                        showPartnerText = `Daily Playlists members `
                        break
                    case 'Downtown':
                        showPartnerText = `Downtown Music artists `
                        break
                }
                const partnerDiscountPercentage = 100 - (partnerDiscount! * 100)
                subtitleText = freeTrialOver ? `${showPartnerText} get a ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months!` : `${showPartnerText} get a 14-day free trial + ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months!`
            } else {
                subtitleText = freeTrialOver ? `Unlock the full power of Symphony for ${formattedCurrency}${formattedAmount} / ${formattedTimePeriod}.` : 'Start your 14-day free trial and unlock the full power of Symphony.'
            }
        }

        return (
            <>
                <Container className={classes.noPaddingBottom}
                    display="flex" flexDirection="column"
                    gap="8px">
                    {selectedTab === CheckoutType.UPGRADE_PLAN && (
                        <Container display="flex" flexDirection="row" gap="8px" >
                            <UpgradeToProLabel
                                maxWidth="160px"
                                color="PURPLE"
                                label="Symphony Pro"
                            />
                            <button onClick={openIntercom}>
                                <SymphonyTooltip
                                    className="w-6 h-6"
                                    title="Click here to learn more."
                                    arrow
                                >
                                    <QuestionMarkIcon height={24} width={24} />
                                </SymphonyTooltip>
                            </button>
                        </Container>
                    )}
                    <Headline1 color={Colors.textColor}>
                        {headlineText}
                    </Headline1>
                    {subtitleText && (<Subtitle2 color={Colors.textColor}>
                        {subtitleText}
                    </Subtitle2>)}
                </Container>

                <Grid item className={classes.noPaddingTop}>
                    <Grid container direction="column">
                        <div style={{
                            marginTop: '24px',
                            gap: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}>

                            {selectedTab === CheckoutType.UPGRADE_PLAN && (
                                <>
                                    <BillingPlanOptions
                                        className="billingPlanOptionsSubmitCampaignOrUpgrade"
                                        billingCycle={selectedBillingCycle}
                                        setBillingCycle={setBillingCycle}
                                        partnerDiscount={partnerDiscount}
                                        specificCurrencyCode={currencyCode}
                                    />

                                    <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
                                        {campaignDisclaimer} {!freeTrialOver && `Free trial is for 14 days, then ${formattedCurrency}${formattedAmount}${currencyCode !== 'USD' ? ` ${currencyCode}` : ``} / ${formattedTimePeriod} unless you cancel.  You'll get an email reminder 7 days before your trial ends.`}
                                    </StyledLabel>
                                    <PrimaryButton
                                        width="100%"
                                        text={!freeTrialOver ? "Start Free Trial & Submit Campaign" : "Upgrade & Submit Campaign"}
                                        onClick={() => handleClickCampaignWithStripe(CheckoutType.UPGRADE_PLAN)}
                                        loading={submissionButtonIsLoading}
                                        disabled={error ? !error : submissionButtonIsLoading}
                                    />
                                </>
                            )}
                            {selectedTab === CheckoutType.ONE_OFF_CAMPAIGN && (
                                <>
                                    <PrimaryButton
                                        width="100%"
                                        text="Checkout with Card"
                                        loading={submissionButtonIsLoading}
                                        disabled={error ? !error : submissionButtonIsLoading}
                                        onClick={() => handleClickCampaignWithStripe(CheckoutType.ONE_OFF_CAMPAIGN)}
                                    />
                                    <PrimaryButton
                                        variant="outlined"
                                        width="100%"
                                        text="Unlock Unlimited Campaigns"
                                        disabled={error ? !error : submissionButtonIsLoading}
                                        onClick={() => setSelectedTab(CheckoutType.UPGRADE_PLAN)}
                                    />
                                </>
                            )}
                        </div>
                        {allowsDirectPayment && <div >
                            <PayPalButtonGroup
                                createOrder={createPaypalOrder}
                                onCancel={onCancelPayPalOrder}
                                onError={onErrorPayPalOrder}
                                onApprove={onPayPalOrderApprove}
                                className="w-full"
                                currencyCode={currencyCode}
                            />
                        </div>}
                    </Grid>
                </Grid>
            </>
        )

    }

    /**
     * Renders the view for Pro users, showing submission UI
     */
    function renderProView() {
        return (
            <Container className={clsx(`submitCampaignOrUpgradeComponent`, classes.space)}>
                {renderSubmissionUI()}
            </Container>
        );
    }

    /**
     * Renders the view for non-Pro users, showing upgrade options
     */
    function renderNonProView() {

        // Check if brand belongs to an organization and if organization is ready to use
        const showOrganizationUI = brandIsInOrganization(currentBrand?.slug!) &&
            primaryOrganization &&
            primaryOrganization.status === 'ready';

        if (showOrganizationUI) {
            return (
                <Container className={clsx(`submitCampaignOrUpgradeComponent`, classes.space)}>
                    {renderOrganizationUpgradeToProText()}
                </Container>
            );
        }

        const upgradeToProTabText = !freeTrialOver ? 'Start Free Trial' : 'Unlock Unlimited Campaigns';
        return (
            <>
                <div className="border-b">
                    <SymphonyTabs
                        data={[
                            {
                                value: CheckoutType.ONE_OFF_CAMPAIGN,
                                label: 'Pay for One-Off Campaign'
                            },
                            {
                                value: CheckoutType.UPGRADE_PLAN,
                                label: upgradeToProTabText
                            }
                        ]}
                        selectedValue={selectedTab}
                        onChange={(newValue) => setSelectedTab(newValue as CheckoutType)}
                    />
                </div>
                <Container className={clsx(`submitCampaignOrUpgradeComponent`, classes.space)}>
                    {renderNonProCheckoutSection()}
                </Container>
            </>
        );
    }

    return isProBrand ? renderProView() : renderNonProView();
}

export default SubmitCampaignOrUpgrade