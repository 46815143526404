import { makeStyles } from '@material-ui/core/styles';
import Colors from 'modules/Colors';

export const useStyles = makeStyles({
  mainContainer: {
    height: 'fit-content',
    width: '20%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '16px',
    borderRadius: '8px',
    backgroundColor: Colors.white,
  },
  stepper: {
    padding: '0 !important',
    '& .MuiStepContent-root': {
      marginTop: '0px !important',
      marginLeft: '7px !important',
      borderLeft: `1px solid ${Colors.divider} !important`,
    },
    '& .MuiStepConnector-root': {
      marginLeft: '7px !important',
      '& span': {
        borderLeft: `1px solid ${Colors.divider} !important`,
      },
    },
    '& .MuiStepLabel-iconContainer > div': {
      height: '16px !important',
      width: '16px !important',
      backgroundColor: Colors.divider,
    },
    '& .MuiStep-completed': {
      '& .MuiStepLabel-iconContainer > div': {
        backgroundColor: Colors.PAPER_ACCENT_CONTAINER,
      },
    },
    '& .MuiStepConnector-vertical': {
      padding: '0 !important',
    },
  },
  circle: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: Colors.PAPER_ACCENT_CONTAINER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkMark: {
    '& path': {
      stroke: Colors.purple,
    },
  },
});
