import SBarChart from "components/shareable/DATA_PRESENTATION/Charts/SBarChart";
import { Body1, Caption } from "components/shareable/Typography";
import { renderIcon } from "pages/post-auth/Website/components/CustomizeWebsite/components/SocialMediaIconsInputs";
import { StreamingLinkTypeOptions, getIconBackgroundStyle } from "pages/post-auth/Website/components/CustomizeWebsite/utils";
import { useStyles } from "pages/post-auth/Website/components/CustomizeWebsite/styles";
import { StreamingPlatformsData } from "types/global";
import { Container } from "styles/shared";
import GemIcon from "components/shareable/ProCallouts/GemIcon";
import Colors from "modules/Colors";

interface Props {
  data: StreamingPlatformsData | null;
  isUsingConversionsApi?: boolean;
}

export const StreamingLinksBreakdown = ({
  data,
  isUsingConversionsApi
}: Props) => {
  const classes = useStyles()

  let captionText = `These are the number of streaming service clicks directly tracked from Symphony ads. These stats may be higher than shown above, as they update more often.`

  if (!isUsingConversionsApi) {
    captionText = `These are the number of streaming service clicks directly tracked from your ads.`
  }

  let mappedChartData: { uniqueId: string, serviceName: string, result: number }[] = []

  if (data && data.length) {
    mappedChartData = data.sort((a, b) => b.result - a.result).map((item, index) => ({
      ...item,
      uniqueId: `${item.serviceName}-${index}`
    }))
  }

  return (
    <Container display='flex' flexDirection="column" paddingTop="16px" gap="8px">
      <Container display="flex" flexDirection="column" gap='8px'>
        <Container display='flex' gap='8px' alignItems="center" borderBottom="1px solid #E5F1EB" paddingBottom="16px">
          <p className="text-lg font-semibold">Streaming Clicks by Platform</p>
          <GemIcon color="purple" />
        </Container>
        <Caption color={Colors.greyDark}>{captionText}</Caption>
      </Container>
      {!data?.length ?
        <Container display="flex" justifyContent="center" padding="16px">
          <Caption color={Colors.greyDark}>No data found</Caption>
        </Container>
        :
        <SBarChart
          data={mappedChartData || []}
          xAxisKey="result"
          yAxisKey="serviceName"
          direction="horizontal"
          tickWidth={190}
          tickHeight={46}
          barHeight={24}
          barWidth={150}
          tick={(value) => {
            const item = mappedChartData?.find((item) => item.uniqueId === value.value) || { serviceName: '', result: 0 }
            const { serviceName, result: clicks } = item
            let serviceObject = StreamingLinkTypeOptions.find((option) => option.title.toLowerCase().includes(serviceName.toLowerCase()))
            // for the Symphony Website - we dont want to show the option in the StreamingLinkTypeOptions.
            // instead, show it as a separate option in the chart.
            if (serviceName === 'Symphony Website') {
              serviceObject = {
                "enabled": true,
                "rounded": true,
                "backgroundColor": "",
                "height": "25",
                "width": "25",
                "url": "",
                "key": "website",
                "title": "Symphony Website",
                "placeholder": ""
              }
            }
            const iconStyle = getIconBackgroundStyle(serviceObject) as React.CSSProperties
            return (
              <Container display="flex" alignItems="center" gap="8px" height="100%">
                {iconStyle && (
                  <div className={classes.socialIconContainer} style={iconStyle}>
                    {renderIcon(true, serviceObject)}
                  </div>
                )}
                <Container display="flex" alignItems="center" justifyContent="space-between" width="100%" gap="8px">
                  <Body1 color={Colors.textColor}>{serviceName}</Body1>
                  <Body1 color={Colors.textColor}>{clicks}</Body1>
                </Container>
              </Container>
            )
          }}
        />
      }
    </Container>
  )
};