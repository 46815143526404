import { makeStyles } from '@material-ui/core'
import Colors from "modules/Colors"
import styled from 'styled-components'

export const useStyles = makeStyles(() => ({
  iconAccentColor: {
    '& path': {
      stroke: Colors.purple,
    },
  },
  iconDisabledColor: {
    '& path': {
      stroke: Colors.grey,
    },
  },
}))

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 16px;
  cursor: pointer;
  background-color: ${Colors.PAPER_ACCENT_CONTAINER};
  svg {
    min-width: 24px;
    min-height: 24px;
  }
  & [aria-label='animation'] {
    overflow: initial !important
    ;
  }
`
