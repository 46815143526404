import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";
import { NAV_BAR_HEIGHT_MOBILE } from "modules/Const";
import { styx, clsx } from "modules/Utils";
import { CSSProperties, ReactNode, useContext } from "react";
import ContentSymphony from "./ContentSymphony";

interface PageSymphonyProps {
    className?: string;
    style?: CSSProperties;
    isProMobileNavCta?: boolean;
    isContent?: boolean;
    children: ReactNode;
}

const PageSymphony = ({
    className = '',
    style = {},
    isProMobileNavCta = false,
    isContent = false,
    children,
}: PageSymphonyProps) => {
    const { 
        mobileView, 
        smallView,
        mStyle, 
        screenWidth, 
        screenHeight,
        
    } = useScreen();
    const { isProBrand } = useContext(CurrentBrandContext);

    return (
        <div
            className={clsx('pageSymphonyComponent', className)}
            style={styx(
                mStyle(sty, 'pageSymphonyComponent'),
                style,
                mobileView && !isProBrand && isProMobileNavCta && { marginBottom: NAV_BAR_HEIGHT_MOBILE },
            )}
        >
            {isContent ? <ContentSymphony>{children}</ContentSymphony> : children}
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    pageSymphonyComponent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        marginBottom: 0,
    },
    pageSymphonyComponent_mobile: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    pageSymphonyComponent_medium: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
    }
}

export default PageSymphony;