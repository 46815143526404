import { createContext, ReactNode, useContext, useState } from "react";
import { track } from "analytics";
import UpgradeToProModal from "components/shareable/UpgradeToProModal";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import ConfirmChangeSubscriptionModal from "pages/post-auth/OrganizationPage/ReadyState/ConfirmChangeSubscriptionModal";
import { toast } from "react-toastify";
import { upgradeToProInOrganization } from "services/symphonyApi/organizationService";
import { isOrganizationAdminOrOwner, triggerVWOVirtualPageView } from "modules/Utils";
import { CurrentBrandContext } from "./CurrentBrandContext";
import { PlanType } from "modules/Const";
import { CancellationProvider } from "./useCancellation";
import UpgradeToProSucceededModal from "components/shareable/UpgradeToProSucceededModal";
import { CurrentUserContext } from "./CurrentUserContext";

interface HandleOpenUpgradeModalProps {
  /** Source identifier for tracking where the upgrade was initiated from */
  source?: string;
  /** Which tab to show initially - creator or team view */
  tab?: "creator" | "team";
  /** Custom call-to-action text to display in the modal */
  upgradeCallToAction?: string;
  /** Which plan should be highlighted/featured in the modal */
  highlightedPlan?: PlanType;
  /** Optional array to restrict which plans are shown */
  onlyShowPlans?: PlanType[];
  /** Whether the modal is for a downgrade */
  isDowngrade?: boolean;
}

interface Context {
  /** Whether the upgrade modal is currently open */
  openModal: boolean;
  /** Function to close the upgrade modal */
  handleCloseModal: () => void;
  /** Function to open the upgrade modal */
  handleOpenModal: (props?: HandleOpenUpgradeModalProps) => void;
  /** Function to set the showPlanUpgradeSuccessModal state */
  setShowPlanUpgradeSuccessModal: (value: boolean) => void;
  /** State for tracking if the plan upgrade success modal is open */
  showPlanUpgradeSuccessModal: boolean;
  /** Function to set the showPlanUpgradeSuccessModal state */
  handleSuccessfulCancellation: () => void;
}

// Default context values
export const UpgradeModalContext = createContext<Context>({
  openModal: false,
  handleOpenModal: () => { },
  handleCloseModal: () => { },
  setShowPlanUpgradeSuccessModal: () => { },
  showPlanUpgradeSuccessModal: false,
  handleSuccessfulCancellation: () => { },
});

type Props = {
  children: ReactNode[] | ReactNode;
};

// Extend Window interface for VWO analytics
declare global {
  interface Window {
    VWO: any[];
  }
}

/**
 * Provider component that manages the upgrade modal state and functionality
 * Handles both organization-level and individual upgrades
 */
const UpgradeModalProvider = ({
  children,
}: Props) => {
  const { currentUser, getBrands } = useContext(CurrentUserContext);
  const { currentBrand } = useContext(CurrentBrandContext);

  // Modal state
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState<string | null>(null);
  const [tab, setTab] = useState<"creator" | "team">("creator");
  const [upgradeCallToAction, setUpgradeCallToAction] = useState<string | null>(null);
  const [highlightedPlan, setHighlightedPlan] = useState<PlanType | undefined>(undefined);
  const [onlyShowPlans, setOnlyShowPlans] = useState<PlanType[] | undefined>(undefined);
  const [isDowngrade, setIsDowngrade] = useState(false);

  // Organization upgrade state
  const { hasProAccountOverage, organization } = useCurrentTeam();
  const [upgradeInTeamButtonLoading, setUpgradeInTeamButtonLoading] = useState(false);
  const hasEnabledOrganization = Boolean(organization && organization.status !== "not_started");
  const isAdminOrOwner = isOrganizationAdminOrOwner(currentUser);

  /** State for tracking if the plan upgrade success modal is open */
  const [showPlanUpgradeSuccessModal, setShowPlanUpgradeSuccessModal] = useState(false);


  /**
   * Handles the organization upgrade process
   * Makes API call to upgrade and shows success/error messages
   */
  async function handleProceedWithOrganizationUpgradeAction() {
    setUpgradeInTeamButtonLoading(true);
    try {
      if (!currentBrand?.slug) {
        throw new Error("No brand slug provided");
      }

      await upgradeToProInOrganization(currentBrand.slug);
      getBrands();
      toast.success("Successfully upgraded to Pro!", SHARED_TOAST_OPTIONS);
      handleCloseModal();
    } catch (error) {
      console.error(error);
      toast.error("There was an error enabling Pro Plan access. Please try again.", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setUpgradeInTeamButtonLoading(false);
    }
  }

  /**
   * Opens the upgrade modal with the specified configuration
   * Tracks analytics events and sets modal state
   */
  const handleOpenModal = ({
    source,
    tab,
    upgradeCallToAction,
    highlightedPlan,
    onlyShowPlans,
    isDowngrade
  }: HandleOpenUpgradeModalProps = {}) => {
    // Track analytics
    triggerVWOVirtualPageView('/upgrade-to-pro-modal');
    track("Upgrade to Pro Modal Opened", { source: source || null });

    // Update modal state
    const selectedTab = tab || "creator";
    setTab(selectedTab);

    // Set onlyShowPlans based on tab and passed in plans
    const plansToShow = selectedTab === "team" ? [PlanType.TEAM] : onlyShowPlans;
    setOnlyShowPlans(plansToShow);

    setSource(source || null);
    setUpgradeCallToAction(upgradeCallToAction || null);
    setHighlightedPlan(highlightedPlan || undefined);
    setOpen(true);
    setIsDowngrade(isDowngrade || false);
  };

  const handleCloseModal = () => setOpen(false);

  /** Handles successful cancellation cleanup */
  const handleSuccessfulCancellation = () => {
    toast.success("Subscription successfully cancelled.", SHARED_TOAST_OPTIONS);
    handleCloseModal();
  };

  // Context value to be provided
  const context = {
    openModal: open,
    handleOpenModal,
    handleCloseModal,
    setShowPlanUpgradeSuccessModal,
    showPlanUpgradeSuccessModal,
    handleSuccessfulCancellation,
  };

  return (

    <UpgradeModalContext.Provider value={context}>
      <CancellationProvider>

        {/* Show different modals based on organization status */}
        {hasEnabledOrganization ? (
          <ConfirmChangeSubscriptionModal
            open={open}
            type="upgrade"
            onClose={handleCloseModal}
            buttonLoading={upgradeInTeamButtonLoading}
            onConfirm={handleProceedWithOrganizationUpgradeAction}
            proAccounts={organization?.totalProBrands || 0}
            brandName={currentBrand?.name || ''}
            isTeamAdmin={isAdminOrOwner}
          />
        ) : (
          <UpgradeToProModal
            highlightedPlan={highlightedPlan}
            checkout_source={source || undefined}
            onlyShowPlans={onlyShowPlans}
            open={open}
            onClose={handleCloseModal}
            upgradeCallToAction={upgradeCallToAction || undefined}
            isDowngrade={isDowngrade}
          />
        )}
        {children}


        <UpgradeToProSucceededModal
          dialogProps={{
            open: showPlanUpgradeSuccessModal,
            onClose: () => {
              setShowPlanUpgradeSuccessModal(false);
              handleCloseModal();
            }
          }}
        />
      </CancellationProvider>

    </UpgradeModalContext.Provider>
  );
};

export default UpgradeModalProvider;
