import { CSSProperties, useContext } from "react";
import useScreen from "Hooks/useScreen";
import ButtonSymphony from "./shareable/ButtonSymphony";
import Colors from "modules/Colors";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { useOnboarding } from "Hooks/OnboardingContext";
import Recommendations from "./shareable/Recommendations";

const WelcomeToSymphony = ({
    skipAction
}: {
    skipAction?: () => void
}) => {
    const { loadedBrands } = useContext(CurrentUserContext)
    const { mobileView, mStyle } = useScreen()

    return (
        <div
            className="welcomeToSymphonyComponent"
            style={mStyle(sty, 'welcomeToSymphonyComponent')}
        >
            <div className="welcomeToSymphonyInner" style={sty.welcomeToSymphonyInner}>
                <div className="welcomeTitle" style={{
                    ...sty.welcomeTitle,
                    ...(mobileView && {
                        fontSize: 24,
                    })
                }}>
                    🚀 Welcome to Symphony!
                </div>
                <div className="welcomeSubtitle" style={{
                    ...sty.welcomeSubtitle,
                    ...(mobileView && {
                        fontSize: 18,
                    })
                }}>
                    Here are some recommendations on where to get started:
                </div>
                <Recommendations />
                {skipAction && loadedBrands.length > 1 &&
                    <ButtonSymphony
                        onClick={skipAction}
                        variant="outlined"
                        style={{ marginTop: 16 }}
                    >
                        Skip for now
                    </ButtonSymphony>
                }
            </div>
        </div>
    )
}

const sty: Record<string, CSSProperties> = {
    welcomeToSymphonyComponent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        overflowY: 'scroll',
    },
    welcomeToSymphonyInner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 678,
        backgroundColor: 'white',
        borderRadius: 8,
        gap: 8
    },
    welcomeTitle: {
        fontSize: 40,
        fontWeight: 500,
    },
    welcomeSubtitle: {
        fontSize: 24,
        color: Colors.greyDark,
        marginBottom: 8,
    },
}

export default WelcomeToSymphony;