import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react"
import Axios from "helpers/Interceptor"
import { convertArrayDraftDataToSendAsObject, STEPS } from "../utils"
import { CurrentBrand, PlaylistsRecord, SavingDraftStatus } from "types/global"
import debounce from "lodash/debounce"
import { saveDraft } from "../api"
import { track } from "analytics"
import { DEBOUNCE_SAVE_DRAFT_TIME } from "modules/Const"
import { availableTiers } from "../utils";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

const { FIRST_TIME_DRAFT_DATA_LOAD, SAVING_NEXT_DRAFT } = SavingDraftStatus

export const useDraftDataGetter = (args: {
  setters: Record<string, Dispatch<SetStateAction<unknown>>>
  draft?: string | null
}) => {
  const { setters, draft } = args
  const { currentBrand, isProBrand } = useContext(CurrentBrandContext)
  const {
    setRecordSelected,
    setBudgetValue,
    setSelectedTab,
    setCampaignDraftData,
    setPitchNotes,
    setRetrievingDraftData,
    setIsSavingDraft,
  } = setters

  const validateBudgetValue = async (selectedCost: number | null) => {
    if (!selectedCost) return

    let tierSelected;
    for (const tier of availableTiers) {
      const tierCost = await tier.cost(currentBrand?.currency?.code)
      const proCost = tierCost - (tierCost * tier.proDiscount)
      if (tierCost === selectedCost || proCost === selectedCost) {
        tierSelected = tier;
        break;
      }
    }

    if (!tierSelected) return
    const tierCost = await tierSelected.cost(currentBrand?.currency?.code)

    const proCost = tierCost - (tierCost * tierSelected.proDiscount)
    const selectedProCost = Boolean(selectedCost === proCost)
    const selectedFreeCost = Boolean(selectedCost === tierCost)
    if ((isProBrand && !selectedProCost) || (!isProBrand && !selectedFreeCost)) {
      setSelectedTab(STEPS.GOAL)
    }
  }

  useEffect(() => {
    const getDraft = async (draft: string) => {
      try {
        const response = await Axios.get(`campaign/${draft}`)
        const data = response.data.data
        const { record, price_data, saved_draft_step, pitch_notes } = data.campaign_metadata
        const { tier, price } = price_data

        track("Continued Creating Campaign from Draft", {
          type: "get_playlisted",
          step: saved_draft_step || STEPS.RECORD,
        })

        setRecordSelected(record || null)
        setBudgetValue(tier && price ? { tier, cost: price } : null)
        setSelectedTab(saved_draft_step || STEPS.RECORD)
        setCampaignDraftData(data)
        setPitchNotes(pitch_notes)
        setRetrievingDraftData(false)
        validateBudgetValue(price)
      } catch (error) {
        console.log("error retrieving draft campaign data: ", error)
      }
    }

    if (draft) getDraft(draft)
    setIsSavingDraft(false)
  }, [draft])
}

export const useDraftDataSetter = ({
  setters,
  campaignDraftData,
  requiredDataChanged,
  recordSelected,
  retrievingDraftData,
  dataToSaveDraft,
  draft,
  currentBrand,
  savingDraftStatus,
}: {
  setters: Record<string, Dispatch<unknown>>
  campaignDraftData?: Record<string, unknown> | null
  requiredDataChanged: boolean
  retrievingDraftData: boolean
  recordSelected: PlaylistsRecord | null
  dataToSaveDraft: Record<string, unknown>[]
  draft: string | null
  currentBrand: CurrentBrand
  savingDraftStatus: number
}) => {
  const {
    setRequiredDataChanged,
    setIsSavingDraft,
    setCampaignDraftData,
    setSavingDraftStatus,
  } = setters

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        async (
          dataToSend: Record<string, unknown>,
          campaignDraftData?: Record<string, unknown> | null
        ) => {
          if (draft && !campaignDraftData) return setIsSavingDraft(false)

          const { data, error } = await saveDraft({
            dataToSend,
            currentBrand,
            campaignDraftData,
          })

          if (!error && data) {
            const draftData = data as { campaign_metadata: Record<string, unknown> }
            const step = draftData.campaign_metadata.saved_draft_step

            track("Saved Draft", { type: "get_playlisted", step })
            setCampaignDraftData(data)
          }
          setIsSavingDraft(false)
        },
        DEBOUNCE_SAVE_DRAFT_TIME
      ),
    [campaignDraftData]
  )

  useEffect(() => {
    setRequiredDataChanged(true)
  }, dataToSaveDraft)

  useEffect(() => {
    if (requiredDataChanged && recordSelected && !retrievingDraftData) {
      setSavingDraftStatus((prev: number) =>
        prev > FIRST_TIME_DRAFT_DATA_LOAD ? SAVING_NEXT_DRAFT : prev + 1
      )
    }
  }, [
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    ...dataToSaveDraft
  ])

  useEffect(() => {
    const dataToSend = convertArrayDraftDataToSendAsObject(
      dataToSaveDraft as unknown as Record<string, unknown>[]
    )

    if (
      requiredDataChanged &&
      recordSelected &&
      !retrievingDraftData &&
      savingDraftStatus === SAVING_NEXT_DRAFT
    ) {
      setIsSavingDraft(true)
      debouncedChangeHandler(dataToSend, campaignDraftData)
      setRequiredDataChanged(false)
    }
  }, [
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    campaignDraftData,
    dataToSaveDraft,
    savingDraftStatus,
  ])
  const cancelSavingDraft = () => {
    setIsSavingDraft(false)
    debouncedChangeHandler.cancel()
  }
  useEffect(() => {
    return cancelSavingDraft;
  }, [debouncedChangeHandler])

  return { cancelSavingDraft }
}
