import upperCase from 'lodash/upperCase'
import Colors from "modules/Colors"

const { PAPER_WARNING_CONTAINER } = Colors

export const backgroundColorsByStatus: Record<string, string> = {
  pending: PAPER_WARNING_CONTAINER,
  denied: '#FFEFEF',
  error: '#FFEFEF',
  paused: PAPER_WARNING_CONTAINER,
  anticipated_puase: PAPER_WARNING_CONTAINER,
  in_review: PAPER_WARNING_CONTAINER,
  to_retry: PAPER_WARNING_CONTAINER,
  with_issues: PAPER_WARNING_CONTAINER,
  active: '#EBFBEB',
  pitched: '#EBFBEB',
  finished: '#E3E3E3',
  completed: '#E3E3E3',
  live: '#EBFBEB',
  live_with_no_stats: '#EBFBEB',
  live_with_issues: PAPER_WARNING_CONTAINER,
  in_review_after_active: '#EBFBEB',
  draft: PAPER_WARNING_CONTAINER,
  not_found: PAPER_WARNING_CONTAINER,
  in_progress: PAPER_WARNING_CONTAINER,
  processing: PAPER_WARNING_CONTAINER,
}
export const textColorsByStatus: Record<string, string> = {
  pending: Colors.yellow,
  denied: Colors.red,
  error: Colors.red,
  paused: Colors.yellow,
  anticipated_puase: Colors.yellow,
  in_review: Colors.yellow,
  to_retry: Colors.yellow,
  with_issues: Colors.yellow,
  active: Colors.green,
  pitched: Colors.green,
  finished: Colors.greyDark,
  completed: Colors.greyDark,
  live: Colors.green,
  live_with_no_stats: Colors.green,
  live_with_issues: Colors.yellow,
  in_review_after_active: Colors.green,
  draft: Colors.yellow,
  not_found: Colors.yellow,
  in_progress: Colors.yellow,
  processing: Colors.yellow,
}

export const getAdStatusBackgroundColor = (status: string): string => backgroundColorsByStatus[status] || ''
export const getAdStatusTextColor = (status: string): string => textColorsByStatus[status] || ''

export const getAdLabelByStatus = (status?: string): string => upperCase(status)
