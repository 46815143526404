import { useContext } from 'react';
import { StyledLabel, WebsiteEditorCard } from "pages/post-auth/Website/styles";
import { clsx } from "clsx";
import GemIcon from "components/shareable/ProCallouts/GemIcon";
import Colors from "modules/Colors";
import DollarSignIcon from "assets/images/gettingStarted/dollar_sign.svg";
import { Body2, Subtitle1, Subtitle2 } from "components/shareable/Typography";
import LinkButton from "components/shareable/LinkButton";
import Intercom from "helpers/Intercom";
import { useStyles } from "./styles";
import { ThemeBoxPresentation } from '../CustomizeWebsite/components/StyleSection/ThemeSelectorSection';
import { objectToArrayInOrder } from "modules/Utils";
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { Container } from 'styles/shared';
import SwitchSymphony from 'components/shareable/SwitchSymphony';

/**
 * Enum representing the possible styles for the Symphony logo placement
 * @enum {string}
 */
export enum SymphonyLogoStyleKey {
    BANNER = 'sticky-banner',
    BELOW = 'below-content'
}

/**
 * Type representing a Symphony logo style option with display text and ID
 * @interface SymphonyLogoStyle
 */
export interface SymphonyLogoStyle {
    /** The display text shown to users */
    text: string;
    /** Unique identifier for the style option */
    id: SymphonyLogoStyleKey;
}

/**
 * Available Symphony logo style options
 * @type {Record<SymphonyLogoStyleKey, SymphonyLogoStyle>}
 */
export const SYMPHONY_LOGO_STYLES: Record<SymphonyLogoStyleKey, SymphonyLogoStyle> = {
    [SymphonyLogoStyleKey.BANNER]: {
        text: 'Sticky Bottom Banner',
        id: SymphonyLogoStyleKey.BANNER
    },
    [SymphonyLogoStyleKey.BELOW]: {
        text: 'Below all content',
        id: SymphonyLogoStyleKey.BELOW
    }
};

interface SymphonyLogoEditorCardProps {
    selectedSymphonyLogoStyle: SymphonyLogoStyleKey;

    /**
     * Callback to set the selected Symphony logo style
     * @param {SymphonyLogoStyleKey} styleId - The ID of the selected style
     * @returns {void}
     * @required
     */
    setSelectedSymphonyLogoStyle: (styleId: SymphonyLogoStyleKey) => void;

    /**
     * Flag controlling whether to show/hide the Symphony branding
     * @type {boolean} 
     * @required
     */
    removeSymphonyBrand: boolean;

    /**
     * Optional flag to add affiliate link to the Symphony logo
     * @type {boolean}
     * @optional
     */
    addAffiliateLinkToLogo?: boolean;

    /**
     * Callback fired when Symphony brand visibility is toggled
     * @param {boolean} checked - The new checked state
     * @returns {void}
     * @required
     */
    onChangeShowSymphonyLogo: (checked: boolean) => void;

    /**
     * Optional callback fired when affiliate link toggle changes
     * @param {boolean} checked - The new checked state
     * @returns {void}
     * @optional
     */
    onAddAffiliateLinkChange?: (checked: boolean) => void;

    /**
     * Callback to open upgrade modal for non-pro users
     * @returns {void}
     * @required
     */
    onUpgradeClick: () => void;
}

const SymphonyLogoEditorCard = ({
    removeSymphonyBrand,
    addAffiliateLinkToLogo,
    onChangeShowSymphonyLogo,
    onAddAffiliateLinkChange,
    onUpgradeClick,
    selectedSymphonyLogoStyle = SymphonyLogoStyleKey.BANNER,
    setSelectedSymphonyLogoStyle,
}: SymphonyLogoEditorCardProps) => {
    const classes = useStyles();
    const { isPaidBrand } = useContext(CurrentBrandContext);

    let referralTitle = 'Upgrade to get paid from your Website'
    let referralDescription = 'Pro members get paid every time someone clicks on their website\'s Symphony logo and subscribes. Earn up to $120 / year for every new subscriber.'

    if (addAffiliateLinkToLogo) {
        if (!removeSymphonyBrand) {
            referralTitle = 'You\'ll get paid from your Symphony Website!'
        } else {
            referralTitle = 'Show your Symphony Logo & get paid!'
        }
        referralDescription = 'When someone clicks on your website\'s Symphony logo and subscribes to Symphony, you\'ll get paid 50% of their subscription fee!'
    }

    const renderLogoStyleSelector = () => {
        const options = objectToArrayInOrder(SYMPHONY_LOGO_STYLES);
        return (
            <Container display='flex' gap="4px" alignItems="flex-start" flexDirection='column'>
                <Subtitle1>Logo Style</Subtitle1>
                <div className="flex gap-4 overflow-scroll">
                    {options.map((option) => (
                        <ThemeBoxPresentation
                            key={option.id}
                            title={option.text}
                            isSelected={selectedSymphonyLogoStyle === option.id}
                            isPaidOnly={!isPaidBrand}
                            onClick={() => setSelectedSymphonyLogoStyle(option.id)}
                        />
                    ))}
                </div>
            </Container>
        )
    }

    return (
        <WebsiteEditorCard className='symphonyLogoEditorCard' title='Symphony Logo'>
            <Container
                display='flex'
                flexDirection='row'
                alignItems={'center'}
                justifyContent='flex-start'
                onClick={() => !isPaidBrand && onUpgradeClick()}
            >
                <Container alignItems='center' justifyContent='center'>
                    <SwitchSymphony
                        disabled={!isPaidBrand}
                        onChange={() => onChangeShowSymphonyLogo(!removeSymphonyBrand)}
                        checked={!removeSymphonyBrand}
                        className={clsx(!removeSymphonyBrand && classes.switchEnabled)}
                        label={
                            <Container display='flex' gap="4px" alignItems="center">
                                <StyledLabel fontSize={16}>Show Symphony Logo</StyledLabel>
                                <GemIcon color="purple" width={18} height={18} />
                            </Container>
                        }
                    />
                </Container>

            </Container>

            {isPaidBrand && !removeSymphonyBrand && (
                renderLogoStyleSelector()
            )}

            {onAddAffiliateLinkChange && (<Container
                display='flex'
                flexDirection='row'
                alignItems={'center'}
                justifyContent='flex-start'
                onClick={() => !isPaidBrand && onUpgradeClick()}
            >
                <Container alignItems='center' justifyContent='center'>
                    <SwitchSymphony
                        disabled={!isPaidBrand}
                        onChange={() => onAddAffiliateLinkChange(!addAffiliateLinkToLogo)}
                        checked={addAffiliateLinkToLogo}
                    />
                </Container>
                <Container display='flex' gap="4px" alignItems="center">
                    <StyledLabel fontSize={16}>Add your Referral Link to Symphony Logo</StyledLabel>
                    {!isPaidBrand && (
                        <GemIcon color="purple" width={18} height={18} />
                    )}
                </Container>
            </Container>)}

            <div className={classes.banner}>
                <Container display="flex" gap="8px" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <Container display="flex" flexDirection="row" gap={"8px"} alignItems="center">
                        <img className="w-8 h-8" src={DollarSignIcon} alt="Dollar Sign" />
                        <Subtitle2>{referralTitle}</Subtitle2>
                    </Container>
                    <Body2 color={Colors.textColor}>
                        {referralDescription}
                    </Body2>
                    <LinkButton
                        onClick={() => {
                            Intercom.openArticleWithId('7002468')
                        }}
                        text={`Learn More >`}
                    />
                </Container>
            </div>
        </WebsiteEditorCard>
    );
};

export default SymphonyLogoEditorCard;
