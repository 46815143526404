import { useContext, useEffect, useState } from "react";
import { PlanType } from "modules/Const";
import useScreen from "Hooks/useScreen";
import SubscriptionDetails from "components/SubscriptionDetails";
import ModalSymphony from "components/shareable/ModalSymphony";
import { clsx } from "modules/Utils";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";

/**
 * Props interface for the UpgradeToProModal component
 * @interface UpgradeToProModalProps
 */
interface UpgradeToProModalProps {
  /** Optional class name for styling */
  className?: string;
  /** Controls modal visibility */
  open: boolean;
  /** Callback when modal is closed */
  onClose: () => void;
  /** Optional array to restrict which plans are shown */
  onlyShowPlans?: PlanType[];
  /** Optional custom call to action text */
  upgradeCallToAction?: string;
  /** Optional plan to highlight */
  highlightedPlan?: PlanType;
  /** Source identifier for tracking where the upgrade was initiated from */
  checkout_source?: string;
  /** Whether this is a downgrade flow */
  isDowngrade?: boolean;
}

/**
 * Modal component for upgrading/downgrading subscription plans
 * Displays plan options and handles the upgrade/downgrade flow
 */
const UpgradeToProModal = ({
  open,
  className,
  onClose,
  onlyShowPlans,
  upgradeCallToAction,
  highlightedPlan,
  checkout_source,
  isDowngrade = false
}: UpgradeToProModalProps) => {
  const { mobileView } = useScreen();

  const {
    setShowPlanUpgradeSuccessModal,
    showPlanUpgradeSuccessModal,
  } = useContext(UpgradeModalContext)

  // Default to showing all plan types if none specified
  const [planTabs, setPlanTabs] = useState<PlanType[]>(
    onlyShowPlans ? onlyShowPlans : [PlanType.LITE, PlanType.PRO, PlanType.TEAM]
  );


  // Update available plans when onlyShowPlans prop changes
  useEffect(() => {
    setPlanTabs(onlyShowPlans || [PlanType.LITE, PlanType.PRO, PlanType.TEAM]);
  }, [onlyShowPlans]);

  /** Handler for modal close button click */
  const handleClickCloseIconButton = () => {
    if (onClose) onClose();
  };

  /**
 * Handles successful plan change
 * Shows success modal after plan is updated
 * @param {PlanType} tier - The new subscription tier
 */
  const handlePlanChangedSuccess = async (tier: PlanType) => {
    setShowPlanUpgradeSuccessModal(true);
  };



  /** Reduce modal width if only showing one plan option */
  const makeSmallerWidth = onlyShowPlans && onlyShowPlans.length === 1;

  return (
    <ModalSymphony
      className={clsx("modalSymphonyUpgradeToProModal", className)}
      isOpen={open && !showPlanUpgradeSuccessModal}
      onClose={handleClickCloseIconButton}
      fullScreen={mobileView}
      size="large"
      maxWidth={makeSmallerWidth ? 750 : undefined}
    >
      <SubscriptionDetails
        onPlanChangedSuccess={handlePlanChangedSuccess}
        checkout_source={checkout_source}
        onlyShowPlans={planTabs}
        highlightedPlan={highlightedPlan || undefined}
        upgradeCallToAction={upgradeCallToAction || undefined}
        isDowngrade={isDowngrade}
        className="subscriptionDetailsUpgradeToProModal"
      />
    </ModalSymphony>
  );
};

export default UpgradeToProModal;
