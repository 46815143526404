import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import { clsx } from "modules/Utils";

interface FinalizationCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const FinalizationCampaignView = ({
    className = '',
    style = {}
}: FinalizationCampaignViewProps) => {
    const { campaign } = useCampaign();

    return (
        <div
            className={clsx("finalizationCampaignViewComponent", className)}
            style={{ ...sty.finalizationCampaignViewComponent, ...style }}
        >
            FinalizationCampaignView
        </div>
    )
}

const sty = {
    finalizationCampaignViewComponent: {
        width: '100%',
        height: '100%',
    }
}

export default FinalizationCampaignView;