import dayjs from "dayjs";
import { CSSProperties } from "react";
import { TextButton } from "styles/shared";
import { getCampaignName } from "modules/Utils";
import CampaignImage from "./CampaignImage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { ReactComponent as SpotifyIcon } from "assets/images/spotify.svg"
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-icon.svg";
import { CAMPAIGN_TYPE_MAPPINGS } from "modules/Const";
import Colors from "modules/Colors";

const SymphonyDefaultAnnotationsModal = ({
    itemsArray = [],
    showGridModal = false,
    setShowGridModal = () => { },
    selectedCampaign = null,
    selectedIgPost = null,
    selectedSpotifyRelease = null,
    setSelectedCampaign = () => { },
    setSelectedIgPost = () => { },
    setSelectedSpotifyRelease = () => { }
}: {
    itemsArray?: any[];
    showGridModal: boolean;
    setShowGridModal: (show: boolean) => void;
    selectedCampaign?: any;
    selectedIgPost?: any;
    selectedSpotifyRelease?: any;
    setSelectedCampaign?: (campaign: any) => void;
    setSelectedIgPost?: (igPost: any) => void;
    setSelectedSpotifyRelease?: (spotifyRelease: any) => void;
}) => {
    const viewCampaignInNewTab = () => {
        window.open(`/marketing/campaigns/${selectedCampaign?.id}`, '_blank');
    }

    const viewInstagramInNewTab = () => {
        const { permalink } = selectedIgPost;
        window.open(permalink, '_blank');
    }

    const viewSpotifyInNewTab = () => {
        const { record_url } = selectedSpotifyRelease;
        window.open(record_url, '_blank');
    }

    const getSelectedItemName = (type: string, item: any) => {
        let selectedItemName = '' as string | React.ReactNode;

        switch (type) {
            case 'campaign':
                selectedItemName = getCampaignName(item.campaign_metadata);
                break;
            case 'instagram':
                selectedItemName = item.caption;
                break;
            case 'spotify':
                selectedItemName = item.name;
                break;
        }

        if (!selectedItemName || (typeof selectedItemName === 'string' && selectedItemName.length === 0)) {
            selectedItemName = <span className="noTitleItemName" style={sty.noTitleItemName}>No title</span>
        }

        return (
            <div className="textEllipsis" style={sty.textEllipsis}>
                {selectedItemName}
            </div>
        )
    }

    const getSelectedItemImage = (type: string, item: any) => {
        let selectedItemImage = '';

        switch (type) {
            case 'instagram':
                selectedItemImage = item.thumbnail_url ?? item.media_url;
                break;
            case 'spotify':
                selectedItemImage = item.thumbnail_url;
                break;
        }

        return selectedItemImage
    }

    const resetSelectedItems = () => {
        setSelectedCampaign(null);
        setSelectedIgPost(null);
        setSelectedSpotifyRelease(null);
    }

    const handleItemClick = (item: any) => {
        const {
            type
        } = item;

        switch (type) {
            case 'campaign':
                setSelectedCampaign(item);
                setSelectedIgPost(null);
                setSelectedSpotifyRelease(null);
                break;
            case 'instagram':
                setSelectedCampaign(null);
                setSelectedIgPost(item);
                setSelectedSpotifyRelease(null);
                break;
            case 'spotify':
                setSelectedCampaign(null);
                setSelectedIgPost(null);
                setSelectedSpotifyRelease(item);
                break;
        }
    }

    const renderItemDate = (date: string) => {
        return (
            <div className="campaignDateText text-gray-500" style={{ fontSize: 14 }}>
                {dayjs(date).format('MMM DD, YYYY')}
            </div>
        )
    }

    const heightGapComponent = <div className="smallGap" style={{ height: 8, width: '100%' }} />;

    const renderSelectedItem = () => {
        if (!selectedCampaign && !selectedIgPost && !selectedSpotifyRelease) {
            return null;
        }

        const borderGap = <div style={{ width: 24, height: 12 }} />

        if (selectedCampaign) {
            const {
                campaign_metadata = null
            } = selectedCampaign;

            const {
                campaign_type
            } = campaign_metadata;

            return (
                <>
                    {borderGap}
                    <CampaignImage
                        campaign_metadata={campaign_metadata}
                        thumbnail={null}
                        size={64}
                        isBorder
                        style={{ backgroundColor: Colors.purple }}
                    />
                    {heightGapComponent}
                    {getSelectedItemName('campaign', selectedCampaign)}
                    {campaign_type !== 'link_clicks' &&
                        <div className="campaignTypeText" style={sty.campaignTypeText}>
                            {CAMPAIGN_TYPE_MAPPINGS[campaign_type]?.title || 'No Campaign Type'}
                        </div>
                    }
                    {renderItemDate(campaign_metadata.updatedAt)}
                    <TextButton
                        className="viewCampaignButton"
                        onClick={viewCampaignInNewTab}
                        style={{
                            padding: 0,
                            height: 0
                        }}
                    >
                        View Campaign
                    </TextButton>
                </>
            )
        } else if (selectedIgPost) {
            const {
                timestamp,
                thumbnail_url,
                media_url
            } = selectedIgPost;

            return (
                <>
                    {borderGap}
                    <div
                        className="itemImageModal"
                        style={{ ...sty.itemImageModal, ...{ backgroundImage: `url(${thumbnail_url ?? media_url})` } }}
                        role="img"
                        aria-label="instagram post"
                    />
                    {heightGapComponent}
                    {getSelectedItemName('instagram', selectedIgPost)}
                    {renderItemDate(timestamp)}
                    <TextButton
                        className="viewInstagramButton"
                        onClick={viewInstagramInNewTab}
                        style={{
                            padding: 0,
                            height: 0
                        }}
                    >
                        View Post
                    </TextButton>
                </>
            )
        } else if (selectedSpotifyRelease) {
            const {
                release_date,
                thumbnail_url
            } = selectedSpotifyRelease;

            return (
                <>
                    {borderGap}
                    <div
                        className="itemImageModal"
                        style={{ ...sty.itemImageModal, ...{ backgroundImage: `url(${thumbnail_url})` } }}
                        role="img"
                        aria-label="spotify release"
                    />
                    {heightGapComponent}
                    {getSelectedItemName('spotify', selectedSpotifyRelease)}
                    {renderItemDate(release_date)}
                    <TextButton
                        className="viewSpotifyButton"
                        onClick={viewSpotifyInNewTab}
                        style={{
                            padding: 0,
                            height: 0
                        }}
                    >
                        View Release
                    </TextButton>
                </>
            )
        } else {
            return null;
        }
    }

    const renderModalBackButton = () => {
        return (
            <div
                className="modalBackButton"
                style={sty.modalBackButton}
                onClick={resetSelectedItems}
            >
                <KeyboardArrowLeft />
            </div>
        )
    }

    const renderModalCloseButton = () => {
        return (
            <div
                className="modalCloseButton"
                style={sty.modalCloseButton}
                onClick={() => {
                    setShowGridModal(false)
                    resetSelectedItems()
                }}
            >
                ×
            </div>
        )
    }

    const renderItemIcon = (type: string) => {
        switch (type) {
            case 'campaign':
                return null
            case 'instagram':
                return <InstagramIcon />
            case 'spotify':
                return <SpotifyIcon />
            default:
                return null
        }
    }

    const renderSelectedItems = () => {
        console.log('itemsArray >>>>', itemsArray);

        const items = itemsArray.map((item) => {
            return (
                <div
                    className="selectedItemRow"
                    style={sty.selectedItemRow}
                    onClick={() => handleItemClick(item)}
                >
                    {item.type === 'campaign' ?
                        <CampaignImage
                            campaign_metadata={item.campaign_metadata}
                            thumbnail={null}
                            size={24}
                            isBorder
                            style={{ backgroundColor: Colors.purple }}
                        />
                        :
                        <img
                            className="selectedItemImage"
                            style={sty.selectedItemImage}
                            src={getSelectedItemImage(item.type, item)}
                        />
                    }
                    <div>
                        {renderItemIcon(item.type)}
                    </div>
                    {getSelectedItemName(item.type, item)}
                    <KeyboardArrowRight style={{ marginLeft: 'auto', marginRight: -8 }} />
                </div>
            )
        })

        return (
            <div className="selectedItemsContainer" style={sty.selectedItemsContainer}>
                <div className="itemsDateContainer" style={sty.itemsDateContainer}>
                    {dayjs(itemsArray[0]?.date).format('MMM D, YYYY')}
                </div>
                <div className="itemsScrollContainer" style={sty.itemsScrollContainer}>
                    {items}
                </div>
            </div>
        )
    }

    if (!showGridModal) {
        return null;
    }

    const isMultipleItemsAndNoneSelected = itemsArray.length > 1 && !selectedCampaign && !selectedIgPost && !selectedSpotifyRelease;

    const noPaddingStyle = isMultipleItemsAndNoneSelected ? { padding: 0 } as CSSProperties : {};

    return (
        <div className="gridModal" style={{ ...sty.gridModal, ...noPaddingStyle }}>
            {!isMultipleItemsAndNoneSelected && itemsArray.length > 0 && renderModalBackButton()}
            {renderModalCloseButton()}
            {isMultipleItemsAndNoneSelected ?
                renderSelectedItems()
                :
                renderSelectedItem()
            }
        </div>
    )
}

const sty = {
    gridModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: 8,
        width: 260,
        zIndex: 1000,
        boxShadow: '0px 4px 7.3px 0px rgba(0, 0, 0, 0.08)',
        padding: 16,
    } as CSSProperties,
    modalCloseButton: {
        position: 'absolute',
        top: -8,
        right: 8,
        color: Colors.greyDark,
        fontSize: 24,
        cursor: 'pointer',
    } as CSSProperties,
    itemImageModal: {
        width: 64,
        height: 64,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 8,
        marginTop: 12
    } as CSSProperties,
    textEllipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 14
    } as CSSProperties,
    selectedItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
    } as CSSProperties,
    selectedItemRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        cursor: 'pointer'
    } as CSSProperties,
    selectedItemImage: {
        width: 24,
        height: 24,
        backgroundColor: Colors.purple,
        flexShrink: 0,
        borderRadius: 4
    } as CSSProperties,
    modalBackButton: {
        position: 'absolute',
        top: -1,
        left: 0,
        cursor: 'pointer',
        color: Colors.greyDark,
        fontWeight: 600
    } as CSSProperties,
    itemsScrollContainer: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        maxHeight: 250,
        overflowY: 'auto'
    } as CSSProperties,
    itemsDateContainer: {
        padding: '8px 16px',
        borderBottom: '1px solid #bbb',
        color: Colors.greyDark,
    } as CSSProperties,
    noTitleItemName: {
        color: Colors.greyDark,
        fontStyle: 'italic'
    } as CSSProperties,
    campaignTypeText: {
        color: Colors.greyDark,
        fontSize: 14
    } as CSSProperties,
}

export default SymphonyDefaultAnnotationsModal;