import PopupHeader from "components/shareable/Popup/header";
import Axios from "helpers/Interceptor";
import { getChannelIdFromUrl, isYouTubeChannelUrl, isYouTubeChannelUrlWithProfile, queryYouTubeApi, queryYouTubeSearch } from "helpers/YouTube";
import { useContext, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import RowItem from "../shareable/RowItem";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

interface YouTubeConnectProps {
    closeModal: () => void;
    selected: any;
    onSave?: () => void;
}
const YouTubeConnectv2 = ({
    selected,
    onSave,
    closeModal,
}: YouTubeConnectProps) => {
    const { currentBrand } = useContext(CurrentBrandContext);
    // search loader
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

    // search string 
    const [searchString, setSearchString] = useState<string>(selected && selected.snippet && selected.snippet.title ? selected.snippet.title : '')

    // search results
    const [searchResults, setSearchResults] = useState<any>(null)

    // selected channel 
    const [selectedChannel, setSelectedChannel] = useState<any>(null)

    const searchChannels = async () => {
        if (searchString.length) {
            setLoadingSearch(true)

            let results = null
            const isChannelUrl = isYouTubeChannelUrl(searchString)
            const isChannelWithUsername = isYouTubeChannelUrlWithProfile(searchString)

            if (isChannelUrl || isChannelWithUsername) {
                const channelId = await getChannelIdFromUrl(searchString)

                if (channelId) {
                    try {
                        results = await queryYouTubeApi({
                            endpoint: '/channels',
                            params: {
                                id: channelId,
                                part: 'id,snippet',
                            }
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            } else {
                try {
                    results = await queryYouTubeSearch({
                        type: 'channel',
                        q: searchString
                    });
                } catch (error) {
                    console.error(error);
                }
            }

            if (results?.data?.items?.length) {
                setSearchResults(results.data.items.map((o: any) => {
                    return {
                        ...o,
                        id: o.id.channelId ?? o.id
                    }
                }));
            }
            setLoadingSearch(false)
        }
    }

    useEffect(() => {
        searchChannels();
    }, [searchString]);

    const updateInBrand = async () => {
        if (!currentBrand) {
            return;
        }

        Axios.put(`/brand/${currentBrand.slug}/connect`, {
            id: currentBrand.id,
            name: currentBrand.slug,
            service: 'youtube_channel',
            value: selectedChannel
        })
            .then((result) => {
                // reloadBrand()
                if (onSave) onSave()
                closeModal()
            })
            .catch((err) => {
                console.log('error creating brand', err)
            })
    }

    useEffect(() => {
        if (selectedChannel) {
            updateInBrand()
        }
    }, [selectedChannel])

    if (!currentBrand) {
        return null
    }

    const selectChannel = (item: any) => {
        setSelectedChannel(item)
    }

    return (
        <div className="youtubeConnectv2Component">
            <h4 className="pb-5 text-2x font-semibold border-b border-gray-200">
                What is the name or URL for {currentBrand.name}'s YouTube channel?
            </h4>
            <form noValidate action="" onSubmit={(e) => e.preventDefault()}>
                <div className="input-form flex flex-row align-middle relative">
                    <DebounceInput
                        className="border-b p-6 outline-none text-left border-gray-200 w-full"
                        minLength={2}
                        placeholder="eg. Jay-Z"
                        debounceTimeout={500}
                        value={searchString}
                        onChange={(e: any) => setSearchString(e.target.value)}
                    />
                    <label
                        htmlFor=""
                        className="absolute h-16 bg-white items-center flex text-lg right-0"
                    >
                        {loadingSearch ?
                            <div className="ml-3 flex items-center justify-center">
                                <LoadingIndicator
                                    color="black"
                                    height="30px"
                                />
                            </div>
                            :
                            null
                        }
                    </label>
                </div>
                {searchString && searchString.length > 0 &&
                    <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                        {searchResults && searchResults
                            .map((item: any) => {
                                return (
                                    <RowItem
                                        key={item.id}
                                        secondRowContentContainerStyles={{
                                            width: '90%'
                                        }}
                                        arrowIconContainerStyles={{
                                            width: 16,
                                            display: 'block'
                                        }}
                                        imageTagStyles={{
                                            width: 98,
                                            height: 98,
                                            objectFit: 'contain'
                                        }}
                                        selectable
                                        onClick={() => selectChannel(item)}
                                        image={item.snippet.thumbnails.default.url}
                                        name={item.snippet.title}
                                        secondRowContent={(
                                            <div className="flex flex-row items-center">
                                                <div className="social-text">
                                                    <p className="text-xs">
                                                        {item?.snippet?.description.slice(0, 140)}
                                                    </p>
                                                </div>
                                            </div>

                                        )}
                                    />
                                );
                            })}

                        {!loadingSearch && searchString && (searchResults && searchResults.length === 0) &&
                            <li className="text-center mt-3 pb-3 border-b">No YouTube channels found for "<span className="font-semibold">{searchString}</span>".</li>
                        }
                    </ul>
                }
            </form>
            <p className="text-xs mt-6">By connecting YouTube, you agree to the terms defined by YouTube API Services, <a target="_blank" href="http://www.google.com/policies/privacy" className="underline">Google's Privacy Policy</a> and <a target="_blank" href="https://www.youtube.com/t/terms" className="underline">YouTube's Terms of Service.</a></p>
        </div>
    )
}

export default YouTubeConnectv2;