import { BackArrowImage, BackButtonContainer, BackToDashboardContainer, EnterArtistContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, RightColumn, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import HappyFace from './images/high-bar.svg';
import NeutralFace from './images/medium-bar.svg';
import SadFace from './images/none-bar.svg';
import MarketingSelectorVertical, { MarketingSelectorOption } from "./MarketingSelectorVertical";
import PrimaryButtonContainer from "./PrimaryButtonContainer";
import useScreen from "Hooks/useScreen";
import BackButton from "../BackButton";
import { useOnboarding } from "Hooks/OnboardingContext";
import ButtonSymphony from "components/shareable/ButtonSymphony";

const MarketingExperience = () => {
    const { mobileView } = useScreen();
    const {
        setMarketingExperience: setSelectedItem,
        marketingExperience: selectedItem,
        handleGoNext,
        showBackButton,
        handleGoBack
    } = useOnboarding()


    const options: MarketingSelectorOption[] = [
        {
            image: SadFace,
            title: "None",
            description: "I've never run ads before or have no clue how they work.",
        },
        {
            image: NeutralFace,
            title: "Medium",
            description: "I have an idea of how to read ads data, or I've hired an agency or someone to run ads for me.",
        },
        {
            image: HappyFace,
            title: "High",
            description: "I know how to run ads through platforms like Facebook + TikTok Ads Manager.",
        },
    ]

    return (
        <>
            <LeftColumn className="leftColumnMarketingExperience" width={mobileView ? "100%" : "50%"} isMobile={mobileView}>
                {showBackButton() && mobileView && (
                    <BackButton
                        onBack={handleGoBack}
                    />
                )
                }
                <LogoContainer>
                    <SymphonyLogo height="30px" />
                </LogoContainer>
                <Titles gap={mobileView ? "8px" : "18px"}>
                    {showBackButton() && !mobileView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>What's your experience with marketing?</MainTitle>
                </Titles>
                <EnterArtistContainer mobileView={mobileView}>
                    <MarketingSelectorVertical
                        options={options}
                        mobileView={mobileView}
                        selected={selectedItem}
                        onSelected={(option: MarketingSelectorOption) => {
                            setSelectedItem(option)
                            // handleSelected(option);
                        }}
                    />
                </EnterArtistContainer>
                <PrimaryButtonContainer>
                    <ButtonSymphony
                        className="continueButtonMarketingExperience"
                        width={mobileView ? "100%" : "300px"}
                        disabled={!selectedItem}
                        onClick={handleGoNext}
                    >
                        Continue
                    </ButtonSymphony>
                </PrimaryButtonContainer>

            </LeftColumn >
            <RightColumn display={mobileView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}

export default MarketingExperience