import { useHistory } from 'react-router-dom'
import DialogPaper from 'components/shareable/DialogPaper'
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal'
import { ReactComponent as WarningIcon } from 'assets/images/campaignDetails/warning.svg'
import { CampaignDetails } from 'types/global'
import SecondaryButton from 'components/shareable/SecondaryButton'
import { Subtitle2, Caption } from 'components/shareable/Typography'
import Colors from "modules/Colors"
import { getReleaseCaptionColor } from './utils'
import useScreen from 'Hooks/useScreen'
import {
  CampaignModalItem,
  CampaignImg,
  CampaignInfo,
  PresaveCampaignList,
  CampaignData,
} from './styles';
import { getFormatDateCampaigns } from 'pages/post-auth/MarketingPage/utils/daysCalculator'
import { OrderCampaignsByEndDate } from 'pages/post-auth/MarketingPage/utils/marketing'
import ButtonSymphony from 'components/shareable/ButtonSymphony'

interface Props {
  campaigns: CampaignDetails[];
  open: boolean;
  closeModal: () => void;
}

const Modal = ({
  campaigns,
  closeModal,
  open
}: Props) => {
  const history = useHistory();
  const { mobileView } = useScreen();

  const handleClickAdd = (campaign: CampaignDetails) => () => {
    history.push(`/marketing/campaigns/${campaign.id}`)
  }

  const orderedCampaigns = OrderCampaignsByEndDate(campaigns, 'ASC')

  return (
    <DialogPaper open={open}>
      <>
        <HeaderModal
          closeModal={closeModal}
          title="Add UPC or ISRC"
          subtitle="Please add the release’s UPC / ISRC to these campaigns"
          HeaderIcon={WarningIcon}
        />
        <PresaveCampaignList>
          {orderedCampaigns.map((campaign: CampaignDetails) => (
            <CampaignModalItem>
              <CampaignData>
                <CampaignImg src={campaign.campaign_metadata.content.thumbnail_url} />
                <CampaignInfo>
                  <Subtitle2>{campaign.campaign_metadata.content.name}</Subtitle2>
                  <div>
                    <Caption color={Colors.greyDark}> Release date: </Caption>
                    <Caption color={getReleaseCaptionColor(campaign)}> {getFormatDateCampaigns(campaign.campaign_metadata.endDate, campaign.campaign_metadata.presave?.options?.timezone)} </Caption>
                  </div>
                </CampaignInfo>
              </CampaignData>
              <ButtonSymphony
                className="cardButtonUPC"
                onClick={handleClickAdd(campaign)}
                variant="outlined"
                width={mobileView ? '100%' : 'auto'}
              >
                Add UPC or ISRC
              </ButtonSymphony>
            </CampaignModalItem>
          ))}
        </PresaveCampaignList>
      </>
    </DialogPaper>
  )
}

export default Modal