import { useContext, useEffect, useMemo, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { CONVERSIONS_VIDEOS, CampaignDetails, MarketingCampaignType } from "types/global";
import { BannerContainer, TabsContainer, TabsPanelsContainer } from './styles';
import CardTitle from "components/shareable/CardTitle/CardTitle";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import CreateNewCampaignModal from "./Components/CreateNewCampaignModal/CreateNewCampaignModal";
import { availableGoals } from "./utils/marketing";
import { CAMPAIGN_TYPE_MAPPINGS, NAV_BAR_HEIGHT_MOBILE } from "modules/Const";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import SuperboostSection from "./Components/SuperboostSection";
import CampaignsSection from "./Components/CampaignsSection";
import ToolsTab from './Components/ToolsTab';
import { trackNewCampaign } from "./Components/CampaignsSection/analytics";
import ConversionsCallToAction from "./Components/ConversionsCallToAction";
import useBanner from "Hooks/useBanner";
import * as Sentry from "@sentry/react";
import WarningNoReleaseUpc from './PreSave/Components/WarningNoReleaseUpc';
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import { getStatusToShowPaymentFailedBanner } from "modules/Utils";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import { healthCheckCampaign } from "services/facebookApi";
import { Container } from "styles/shared";
import DropdownArrow from "components/shareable/DropdownArrow";
import CampaignTypesFilter from "./Components/CampaignTypesFilter";
import { CampaignFilterType } from "./Components/CampaignTypesFilter/types";
import { StyledNavBar } from "styles/shared";
import { DEFAULT_CAMPAIGN_PARAMS } from "./constants";
import DefaultAdditionalActions from "components/shareable/CardTitle/DefaultAdditionalActions";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments";
import useScreen from "Hooks/useScreen";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import PageSymphony from "components/shareable/PageSymphony";
import ContentSymphony from "components/shareable/ContentSymphony";

const hiddenCampaigns = ['creative_generator']

enum MarketingPageTabs {
  CAMPAIGNS = 'campaigns',
  TOOLS = 'tools',
  INSTAGRAM = 'instagram',
}

interface GetStartedProps {
  currentCampaigns: CampaignDetails[];
  getCampaigns: (filterObject: CampaignFilterType, localLoading?: boolean) => void;
  loadingLocally: boolean;
  loadingMore: boolean;
  loadMoreCampaigns: () => void;
  quantityCampaigns: number;
  returnedAllCampaigns: boolean;
  setCampaignParams(campaignParams: CampaignFilterType): void;
  setGoal: (goal: string) => void;
}

const GetStarted = ({
  currentCampaigns,
  getCampaigns,
  loadingLocally,
  loadingMore,
  loadMoreCampaigns,
  quantityCampaigns,
  returnedAllCampaigns,
  setCampaignParams,
  setGoal
}: GetStartedProps) => {
  const { mobileView } = useScreen();

  const { currentBrand, isBrandAdmin } = useContext(CurrentBrandContext);
  const [isChecked, setIsChecked] = useState(false);
  let campaigns: CampaignDetails[] = []

  try {
    campaigns = currentCampaigns
      ?.filter(({ status }: { status: string }) => status !== 'DRAFT')
      .filter(({ campaign_metadata }: { campaign_metadata: { campaign_type: string } }) =>
        !hiddenCampaigns.includes(campaign_metadata.campaign_type)
      )

    if (campaigns) {
      const campaignHealthChecks: (Promise<void> | null)[] | null = campaigns.map((campaign: CampaignDetails) => {
        const { campaign_type } = campaign.campaign_metadata
        const validCampaigns = ['link_clicks', 'record_streams', 'boost_instagram', 'grow_playlist_followers']

        if (
          currentBrand?.id &&
          campaign.status === 'ACTIVE' &&
          validCampaigns.includes(campaign_type)
        ) {
          return healthCheckCampaign(currentBrand?.id, campaign.id)
        }

        return null
      })

      Promise.all(campaignHealthChecks)
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)
  const { remainingFreeTrial, isOrganizationInactive } = useCurrentTeam()

  const creativeGeneratorCampaigns = currentCampaigns?.filter((
    { campaign_metadata }: { campaign_metadata: { campaign_type: string } }
  ) => campaign_metadata.campaign_type === "creative_generator");

  const { conversions_tasks_completed, isError, conversions_tasks } = currentBrand?.conversionsStatus || {};
  const showCallToAction = Boolean(!conversions_tasks_completed || isError)
  const conversionsCallToAction = useBanner({ initiallyVisible: showCallToAction });

  const tabs = [MarketingPageTabs.CAMPAIGNS, MarketingPageTabs.TOOLS];
  const [selectedTab, setSelectedTab] = useTabs(tabs, MarketingPageTabs.CAMPAIGNS);

  // used to show new modal popup 
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

  const lastPreference = localStorage.getItem('showFilters')
  const savedFiltersPreference = lastPreference ? JSON.parse(lastPreference) : true;
  const [showFilters, setShowFilters] = useState<boolean>(savedFiltersPreference);

  useEffect(() => {
    localStorage.setItem('showFilters', JSON.stringify(showFilters));
  }, [showFilters]);

  const openCreateCampaignModal = () => setOpenCreateModal(true)
  const closeCreateCampaignModal = () => setOpenCreateModal(false)

  const [campaignsInDraft, setCampaignsInDraft] = useState<CampaignDetails[]>([])
  const [campaignWasDeleted, setCampaignWasDeleted] = useState(false)
  const draftsCount = useMemo(() => campaignsInDraft ? campaignsInDraft.length : 0, [campaignsInDraft])

  const onDraftDelete = (campaignId: number) => {
    const filteredCampaigns = (
      campaignsInDraft
    ).filter(({ id }: { id: number }) => id !== campaignId)
    setCampaignsInDraft(filteredCampaigns)
    setCampaignWasDeleted(true)
  }

  useEffect(() => {
    if (currentCampaigns) {
      const filteredCampaigns = currentCampaigns.filter((campaign: CampaignDetails) => {
        const { campaign_metadata, status } = campaign;
        if (!campaign_metadata || status !== "DRAFT") return false;
        const { content, campaign_type } = campaign_metadata;
        const mappingData = CAMPAIGN_TYPE_MAPPINGS[campaign_type as string]?.title;
        return Boolean(content) && Boolean(mappingData);
      }).slice(0, 3)
      // only show 3 campaign drafts
      setCampaignsInDraft(filteredCampaigns)
    }

  }, [currentCampaigns]);

  useEffect(() => {
    // if (!draftsCount && campaignWasDeleted) setSelectedTab("new")
    if (campaignWasDeleted) setCampaignWasDeleted(false)
  }, [draftsCount, campaignWasDeleted])

  const POPULAR_CAMPAIGNS = Object.values(availableGoals)
    .filter((o: MarketingCampaignType) => {
      return [
        "increase_record_streams",
        // "grow_instagram_engagement",
        // "pre_save"
        "pre_save",
        'increase_video_views',
        // 'get_playlisted'
      ].indexOf(o.key) !== -1
    })
    .map((goal: MarketingCampaignType) => {
      return {
        ...goal
      }
    })

  /** 
   * Determines which tabs to show based on whether there are campaigns or not in mobile view
   * Only shows campaigns tab if there are existing campaigns or not in mobile view
   */
  const tabsToShow = (!mobileView || currentCampaigns?.length)
    ? [{ value: MarketingPageTabs.CAMPAIGNS, label: "Campaigns" }]
    : [];

  if (creativeGeneratorCampaigns && creativeGeneratorCampaigns.length > 0) {
    tabsToShow.push({
      value: MarketingPageTabs.TOOLS,
      label: "Tools"
    })
  }

  const [filters, setFilters] = useState<CampaignFilterType>(DEFAULT_CAMPAIGN_PARAMS)
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (currentBrand) {
      getCampaigns(filters, true)
    }
  }, [filters])

  // handles when filters are updated
  const handleFiltersUpdate = (filters: CampaignFilterType) => {
    setCampaignParams(filters)
    setFilters(filters)
  }

  const renderFilterSection = () => {
    return showFilters && quantityCampaigns > 3 && <CampaignTypesFilter updatedAppliedFilterObject={handleFiltersUpdate} searchQuery={searchQuery} />
  }

  // if there are already campaigns and its locally loading, show the loader over the current items
  const showLoader = loadingLocally

  return (
    <>
      <PageSymphony
        className="goalSelectorComponent"
        isContent
      >
        {(showPaymentFailedBanner || remainingFreeTrial > 0 || isOrganizationInactive) &&
          <BannerContainer className="bannerContainerGoalSelector">
            {showPaymentFailedBanner && <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />}
            <OrganizationSubscriptionStatusBanner onlyShowFailedPayments />
          </BannerContainer>
        }
        <CardTitle
          className="cardTitleGoalSelector"
          title="Marketing"
          useDefaultPadding={false}
          titleTextPadding={mobileView ? '16px 24px 0' : '16px 24px'}
          subtitle="Grow your fanbase with AI-powered marketing."
          additionalContent={
            <DefaultLearningIndicator
              text="Learn how to use Symphony's Marketing tools"
              type={LearningIndicatorType.MARKETING}
            />
          }
          additionalButtons={(
            <>
              {isBrandAdmin &&
                <DefaultAdditionalActions
                  className="defaultAdditionalActionsGoalSelector"
                  buttonText="Start New Campaign"
                  additionalItemsText="Popular Campaigns:"
                  onButtonClick={openCreateCampaignModal}
                  campaignItems={POPULAR_CAMPAIGNS.filter(campaignType => !campaignType.underMaintenance).map(campaignType => ({
                    key: campaignType.link,
                    title: campaignType.title,
                    icon: campaignType.icon,
                    link: `/marketing/new/${campaignType.link}`
                  }))}
                  onCampaignItemClick={(campaignType: any) => {
                    trackNewCampaign({
                      type: campaignType,
                      from: 'popular',
                      zeroState: false,
                      aiQuestion: null,
                      currentBrand: currentBrand
                    });
                  }}
                />
              }
            </>
          )}
        />
        {!mobileView && conversionsCallToAction.visible && isBrandAdmin &&
          <ConversionsCallToAction
            className="conversionsCallToActionGoalSelector"
            style={{ width: '100%' }}
            videoUrl={CONVERSIONS_VIDEOS.onboardingVideo.videoUrl}
            hide={conversionsCallToAction.hide}
            conversionsTasks={conversions_tasks}
            rounded
          />
        }
        <WarningNoReleaseUpc campaigns={campaigns} />
        <TabsContainer borderRadius={mobileView ? "0px" : "8px"} padding="16px 40px 16px 40px">

          {tabsToShow?.length > 0 &&
            <StyledNavBar>
              <SymphonyTabs
                className='symphonyTabsGoalSelector'
                selectedValue={selectedTab!}
                onChange={(value) => setSelectedTab(value as MarketingPageTabs)}
                data={tabsToShow}
              />
              <Container marginLeft="auto" display="flex" gap="8px">
                {/* 
                    TODO: uncomment this when search is needed
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className={classes.textField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                {quantityCampaigns > 3 &&
                  <ButtonSymphony
                    className="filtersMarketingToggleButton"
                    variant="outlined"
                    onClick={() => setShowFilters(!showFilters)}
                    style={{
                      paddingLeft: 18,
                      paddingRight: 14,
                    }}
                    iconRight={
                      <DropdownArrow
                        color="#8800FF"
                        width={16}
                        height={16}
                        direction={showFilters ? "down" : "up"}
                      />
                    }
                  >
                    Filters
                  </ButtonSymphony>
                }
              </Container>
            </StyledNavBar>
          }
          <TabsPanelsContainer>
            <TabPanel hidden={selectedTab !== MarketingPageTabs.CAMPAIGNS}>
              {renderFilterSection()}
              <CampaignsSection
                addedFilters={filters !== DEFAULT_CAMPAIGN_PARAMS}
                locallyLoading={showLoader}
                campaigns={campaigns}
                campaignsInDraft={campaignsInDraft}
                onDraftDelete={onDraftDelete}
                fullyReturned={returnedAllCampaigns}
                loadingMore={loadingMore}
                loadNextPage={() => {
                  if (!returnedAllCampaigns) {
                    loadMoreCampaigns()
                  }
                }}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== MarketingPageTabs.INSTAGRAM}>
              <SuperboostSection />
            </TabPanel>
            {creativeGeneratorCampaigns && creativeGeneratorCampaigns.length > 0 &&
              <TabPanel hidden={selectedTab !== MarketingPageTabs.TOOLS}>
                <ToolsTab
                  campaigns={creativeGeneratorCampaigns}
                  setGoal={setGoal}
                />
              </TabPanel>
            }
          </TabsPanelsContainer>
        </TabsContainer>
      </PageSymphony>
      <CreateNewCampaignModal
        isOpen={openCreateModal}
        onClose={closeCreateCampaignModal}
      />
    </>
  )
};

export default GetStarted;