import ButtonSymphony from "components/shareable/ButtonSymphony"
import ModalSymphony from "components/shareable/ModalSymphony"
import { Body2 } from "components/shareable/Typography"
import { DisplayedBrand } from "./OrganizationProfiles"
import { useState } from "react"
import { toast } from "react-toastify"
import { deleteOrganizationBrand } from "services/symphonyApi/organizationService"

const DeleteBrandConfirmationModal = ({
    open,
    onClose,
    brand,
    onDeleteAction,
    loading
}: { open: boolean, onClose: () => void, brand?: DisplayedBrand, onDeleteAction: (brand: DisplayedBrand) => Promise<void>, loading: boolean }) => {


    if (!brand) return null;

    
    return (
        <ModalSymphony
            title="Remove from Team"
            subtitle={`Are you sure you want to remove ${brand.name} from your team? You won't be able to access their dashboard or campaigns.`}
            isOpen={open}
            onClose={onClose}
        >
            <div className="flex gap-4 items-center">
                <ButtonSymphony
                    disabled={loading}
                    onClick={onClose}
                    variant="outlined"
                >
                    Cancel
                </ButtonSymphony>
                <ButtonSymphony
                    isLoading={loading}
                    onClick={() => {
                        onDeleteAction(brand);
                    }}
                >
                    Yes, remove
                </ButtonSymphony>
            </div>
        </ModalSymphony>
    )
}

export default DeleteBrandConfirmationModal