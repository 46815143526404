import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OrganizationData, OrganizationBrand, TeamMember } from 'types/global';
import { CurrentBrandContext } from './CurrentBrandContext';
import { CurrentUserContext } from './CurrentUserContext';
import moment from "moment";
import * as Sentry from "@sentry/react";
import Axios from "helpers/Interceptor";
import { goToUrl, isOrganizationAdministrator } from "modules/Utils";
import { getExistingBrands, getOrganizationBrands, getOrganizationMembers, getSubscriptionStatusUrl, SpoofConfig } from 'services/symphonyApi/organizationService';
import { BillingCycle } from 'components/shareable/UpgradeToProModal/utils';
import { TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from 'modules/Const';

/**
 * Interface defining the shape of the team context data
 * @interface TeamContext
 */
interface TeamContext {
    organization: OrganizationData | undefined;
    isOrganizationReady: boolean;
    hasOrganizationAdminAccess: boolean;
    goToCheckout: (data: {
        recurring_interval: BillingCycle;
        brandSlug?: string | null,
        is_trial?: boolean
    }) => Promise<void>;
    freeTrialRedeemed: boolean;
    subscriptionStatus: string | undefined;
    remainingFreeTrial: number;
    redirectToSubscriptionStatus: () => Promise<void>;
    isLoadingSubscriptionView: boolean;
    isOrganizationInactive: boolean;
    isPaymentFailed: boolean;
    brandIsInOrganization: (brandSlug: string) => boolean;
    teamMembers: TeamMember[];
    teamArtists: OrganizationBrand[];
    existingArtists: OrganizationBrand[];
    getTeamMembers: () => Promise<void>;
    getTeamArtists: () => Promise<void>;
    getExistingArtists: () => Promise<void>;
    isLoadingTeamArtists: boolean;
    isInitializing: boolean;
    hasProAccountOverage: boolean;
    // used for Admin View of organizations
    loadingOrganizationFromAdminView: boolean;
}

/**
 * Context providing team and organization related state and functionality
 * Default values are provided for type safety and testing
 */
export const CurrentTeamContext = createContext<TeamContext>({
    organization: undefined,
    isOrganizationReady: false,
    hasOrganizationAdminAccess: false,
    goToCheckout: async () => { },
    freeTrialRedeemed: false,
    subscriptionStatus: undefined,
    remainingFreeTrial: 0,
    redirectToSubscriptionStatus: async () => { },
    isLoadingSubscriptionView: false,
    isOrganizationInactive: false,
    isPaymentFailed: false,
    brandIsInOrganization: () => false,
    teamMembers: [],
    teamArtists: [],
    existingArtists: [],
    getTeamMembers: async () => { },
    getTeamArtists: async () => { },
    getExistingArtists: async () => { },
    isLoadingTeamArtists: false,
    isInitializing: false,
    hasProAccountOverage: false,
    loadingOrganizationFromAdminView: false
});

/**
 * Props interface for the CurrentTeamProvider component
 */
interface Props {
    children: ReactNode;
}

/**
 * Provider component for managing organization/team-related state and functionality.
 * Handles organization data fetching, subscription management, and team member management.
 * Integrates with CurrentBrandContext and CurrentUserContext to provide comprehensive team functionality.
 * 
 * @param {Props} props - Component props containing children to be wrapped
 * @returns {JSX.Element} Provider component that makes team context available to children
 */
export const CurrentTeamProvider = ({ children }: Props): JSX.Element => {
    const { currentBrand } = useContext(CurrentBrandContext);
    const { currentUser, organization, spoofedUserId, loadingOrganizationFromAdminView } = useContext(CurrentUserContext);

    // Loading and data states
    const [isInitializing, setIsInitializing] = useState<boolean>(false);
    const [isLoadingSubscriptionView, setIsLoadingSubscriptionView] = useState<boolean>(false);
    const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
    const [teamArtists, setTeamArtists] = useState<OrganizationBrand[]>([]);
    const [existingArtists, setExistingArtists] = useState<OrganizationBrand[]>([]);
    const [isLoadingTeamArtists, setIsLoadingTeamArtists] = useState<boolean>(false);


    /**
     * Configuration object used when making API calls to the organization service
     * Allows admin users to impersonate other users by including their ID
     * This is passed to organization API calls to fetch data for the spoofed user
     */
    let spoofConfig: SpoofConfig = {
        spoofedUserId: spoofedUserId || undefined
    }
    /**
     * Determines if free trial has been redeemed for an organization based on user role and subscription metadata
     * @returns {boolean} Whether the free trial has been redeemed
     */
    const freeTrialRedeemed = useMemo(() => {
        return Boolean(!(organization?.role === "administrator" ||
            organization?.role === "owner") &&
            currentUser?.metadata?.organization_subscription
                ?.organization_free_trial_redeemed);
    }, [currentUser, organization]);


    /**
   * Determines if the organization has exceeded their included pro account limit
   * Calculates based on actual pro accounts in teamArtists
   */
    const hasProAccountOverage = useMemo(() => {
        const proAccountCount = teamArtists.filter(artist => artist.isPro).length;
        return proAccountCount > TEAM_PLAN_INCLUDED_PRO_ACCOUNTS;
    }, [teamArtists]);

    /**
     * Checks if organization subscription is inactive based on subscription status
     */
    const isOrganizationInactive = Boolean(currentUser?.metadata?.organization_subscription && !["completed", "succeeded", "active"].includes(currentUser?.metadata?.organization_subscription?.status!))

    /**
     * Checks if organization payment has failed
     */
    const isPaymentFailed = currentUser?.metadata?.organization_subscription?.status === "failed"

    /**
     * Calculates remaining free trial days
     * @returns {number} Number of days remaining in trial, or 0 if expired/not applicable
     */
    const remainingFreeTrial = useMemo(() => {
        if (!currentUser?.metadata?.organization_subscription?.organization_free_trial_redeemed || !currentUser?.metadata?.organization_subscription?.trial_start_date) {
            return 0
        }

        const now = moment();
        const expiresAt = moment(currentUser?.metadata?.organization_subscription?.trial_start_date).add(14, "days");

        if (expiresAt.isBefore(now)) {
            return 0
        } else {
            return expiresAt.diff(now, "days")
        }
    }, [currentUser])

    /**
     * Checks if a brand exists in the organization
     * @param {string} brandSlug - Unique identifier for the brand
     * @returns {boolean} Whether brand exists in organization
     */
    const brandIsInOrganization = (brandSlug: string) => {
        const isInOrganization = Boolean(teamArtists?.some(brand => brand.slug === brandSlug))
        return isInOrganization
    }

    /** 
     * Initiates Stripe checkout flow for organization subscription
     * @param recurring_interval - Monthly or annual billing cycle
     * @param brandSlug - Optional brand identifier to associate with subscription
     * @param is_trial - Whether to start a trial subscription
     */
    const goToCheckout = useCallback(async ({
        recurring_interval,
        brandSlug
    }: {
        recurring_interval: BillingCycle
        brandSlug?: string | null,
    }) => {
        try {
            const response = await Axios.post(
                `/subscription/brand/${brandSlug}/create-organization-checkout`,
                {
                    recurring_interval,
                }
            )
            window.location = response.data.data.url
        } catch (error) {
            Sentry.captureException(error)
            console.error("Organization checkout failed: ", error)
        }
    }, [organization])

    /**
     * Redirects user to subscription status page
     */
    const redirectToSubscriptionStatus = async () => {
        try {
            setIsLoadingSubscriptionView(true);
            const url = await getSubscriptionStatusUrl();
            goToUrl(url, setIsLoadingSubscriptionView);
        } catch (error) {
            setIsLoadingSubscriptionView(false);
            Sentry.captureException(error);
            console.error("redirectToSubscriptionStatus: ", error);
        }
    };

    /**
     * Fetches and sets team members list with current user flag
     * Adds isCurrentUser flag to each member for UI purposes
     */
    const getTeamMembers = async () => {
        try {

            const members = await getOrganizationMembers(spoofConfig);
            if (members) {
                const membersWithCurrentUser = members.map((member: TeamMember) => ({
                    ...member,
                    isCurrentUser: member.id === currentUser?.id
                }));
                setTeamMembers(membersWithCurrentUser);
            }
        } catch (error) {
            console.error("Failed to get team members:", error);
            Sentry.captureException(error);
        }
    }

    /**
     * Fetches and sets organization brands/artists
     * Manages loading state during fetch
     */
    const getTeamArtists = async () => {
        try {
            setIsLoadingTeamArtists(true)
            const artists = await getOrganizationBrands(spoofConfig);
            setTeamArtists(artists || []);

        } catch (error) {
            console.error("Failed to get team artists:", error);
        } finally {
            setIsLoadingTeamArtists(false)
        }
    }

    /**
     * Fetches and sets existing brands/artists
     */
    const getExistingArtists = async () => {
        const artists = await getExistingBrands();
        setExistingArtists(artists || []);
    }

    /**
     * Fetches all organization related data in parallel
     * Manages initialization state and error handling
     */
    const fetchOrganizationData = useCallback(async () => {
        try {
            setIsInitializing(true);
            await Promise.all([
                getTeamMembers(),
                getTeamArtists(),
                getExistingArtists()
            ]);
        } catch (error) {
            Sentry.captureException(error);
            console.error("Failed to fetch organization data:", error);
        } finally {
            setIsInitializing(false);
        }
    }, [spoofedUserId]);

    /**
     * Effect to initialize and cleanup organization data when brand changes
     * Only fetches data if organization exists, subscription is not canceled, and brand is selected
     */
    useEffect(() => {
        const shouldFetchData = Boolean(organization && currentBrand?.id)

        if (shouldFetchData) {
            fetchOrganizationData();
        }

        // Cleanup function
        return () => {
            setTeamMembers([]);
            setTeamArtists([]);
            setExistingArtists([]);
        };
    }, [
        currentBrand?.id,
        organization, // Added because of setOrganization call
    ]);

    const contextValue = {
        organization,
        isOrganizationReady: organization?.status === "ready",
        hasOrganizationAdminAccess: Boolean(organization && isOrganizationAdministrator(organization)),
        goToCheckout,
        freeTrialRedeemed,
        subscriptionStatus: currentUser?.metadata?.organization_subscription?.status,
        remainingFreeTrial,
        redirectToSubscriptionStatus,
        isLoadingSubscriptionView,
        isOrganizationInactive,
        isPaymentFailed,
        brandIsInOrganization,
        teamMembers,
        teamArtists,
        existingArtists,
        getTeamMembers,
        getTeamArtists,
        getExistingArtists,
        isLoadingTeamArtists,
        isInitializing,
        hasProAccountOverage,
        loadingOrganizationFromAdminView
    };

    return (
        <CurrentTeamContext.Provider value={contextValue}>
            {children}
        </CurrentTeamContext.Provider>
    );
};

/**
 * Custom hook to access the CurrentTeamContext
 * Ensures the hook is used within a provider and provides type-safe access to team context
 * @returns {TeamContext} Team context value with all organization and team related state and functions
 * @throws {Error} If used outside of CurrentTeamProvider
 */
export const useCurrentTeam = (): TeamContext => {
    const context = useContext(CurrentTeamContext);
    if (context === undefined) {
        throw new Error('useCurrentTeam must be used within a CurrentTeamProvider');
    }
    return context;
};