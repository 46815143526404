import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AdAccountPageType, FacebookBusinessManager, FacebookPageType, FBPixel, InstagramPageType, LoggedUser, PixelConversionEvent } from 'types/global';
import { getAdAccountDetails, getFbAccountDetails, getInstagramDetails, getPreconnectionConfigurationsStatus, PreConnectionConfigs, validateInstagram } from '../utils/fbCampaigns';
import { getTosConfiguration } from '../utils';
import { useLocation } from 'react-router-dom';
import { CAMPAIGN_TYPES } from 'modules/Const';
import { getConversionsEvents } from '../LinkClicks/utils';
import { getFacebookImage, getInstagramAccountFromFbPage, isValidAccessToken, loggedInFb } from 'services/facebookApi';
import { updateInBrand } from 'services/symphonyApi/brandService';
import { checkIfUrlExpired } from 'services/general';

interface Context {
  facebookPage?: FacebookPageType;
  instagramPage?: InstagramPageType;
  facebookAdAccount?: AdAccountPageType;
  facebookPixel?: FBPixel;
  facebookBusinessManager?: FacebookBusinessManager;
  conversionsEnabled?: boolean;
  conversionsEventName?: string;
  conversionsEventOptions?: PixelConversionEvent[];
  validatingConnections: boolean;
  areConnectionsValid: boolean;
  connectionsConfirmed: boolean;
  preConnectionConfigurations?: PreConnectionConfigs;
  areTermsAccepted: boolean;
  validateConnections: boolean;
  eventsLoading: boolean;
  connectionsType: string;
  instagramPagesAvailable: boolean;
  loggedInFbUser?: LoggedUser;
  needsToReLogIn: boolean;
  confirmConnections: boolean;
  setFacebookPage: Dispatch<SetStateAction<FacebookPageType | undefined>>;
  setInstagramPage: Dispatch<SetStateAction<InstagramPageType | undefined>>;
  setFacebookAdAccount: Dispatch<SetStateAction<AdAccountPageType | undefined>>;
  setFacebookPixel: Dispatch<SetStateAction<FBPixel | undefined>>;
  setFacebookBusinessManager: Dispatch<SetStateAction<FacebookBusinessManager | undefined>>;
  setConversionsEnabled: Dispatch<SetStateAction<boolean>>;
  setValidatingConnections: Dispatch<SetStateAction<boolean>>;
  setAreConnectionsValid: Dispatch<SetStateAction<boolean>>;
  setConnectionsConfirmed: Dispatch<SetStateAction<boolean>>;
  setPreConnectionConfigurations: Dispatch<SetStateAction<PreConnectionConfigs | undefined>>;
  setAreTermsAccepted: Dispatch<SetStateAction<boolean>>;
  removeConnections: () => void;
  setValidateConnections: Dispatch<SetStateAction<boolean>>;
  setConversionsEventName: Dispatch<SetStateAction<string | undefined>>;
  setEventsLoading: Dispatch<SetStateAction<boolean>>;
  setConversionsEventOptions: Dispatch<SetStateAction<PixelConversionEvent[]>>;
  setConnectionsType: Dispatch<SetStateAction<string>>;
  setInstagramPagesAvailable: Dispatch<SetStateAction<boolean>>;
  setLoggedInFbUser: Dispatch<SetStateAction<LoggedUser | undefined>>;
  setNeedsToReLogIn: Dispatch<SetStateAction<boolean>>;
  setConfirmConnections: Dispatch<SetStateAction<boolean>>;
}


export const ConnectionsContext = createContext<Context>({
  facebookPage: undefined,
  instagramPage: undefined,
  facebookAdAccount: undefined,
  facebookPixel: undefined,
  facebookBusinessManager: undefined,
  conversionsEnabled: undefined,
  conversionsEventName: undefined,
  validatingConnections: false,
  areConnectionsValid: false,
  connectionsConfirmed: false,
  preConnectionConfigurations: undefined,
  areTermsAccepted: false,
  validateConnections: false,
  eventsLoading: false,
  conversionsEventOptions: [],
  connectionsType: '',
  instagramPagesAvailable: false,
  loggedInFbUser: undefined,
  needsToReLogIn: false,
  confirmConnections: false,
  setConversionsEventOptions: () => { },
  setFacebookPage: () => { },
  setInstagramPage: () => { },
  setFacebookAdAccount: () => { },
  setFacebookPixel: () => { },
  setFacebookBusinessManager: () => { },
  setConversionsEnabled: () => { },
  setValidatingConnections: () => { },
  setAreConnectionsValid: () => { },
  setConnectionsConfirmed: () => { },
  setPreConnectionConfigurations: () => { },
  setAreTermsAccepted: () => { },
  removeConnections: () => { },
  setValidateConnections: () => { },
  setConversionsEventName: () => { },
  setEventsLoading: () => { },
  setConnectionsType: () => { },
  setInstagramPagesAvailable: () => { },
  setLoggedInFbUser: () => { },
  setNeedsToReLogIn: () => { },
  setConfirmConnections: () => { },
});

interface Props {
  campaignType: string;
  children: ReactNode[] | ReactNode;
}

export const ConnectionsProvider = ({
  campaignType,
  children,
}: Props) => {
  const location = useLocation();
  const draft = new URLSearchParams(location.search).get("draft")
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
  const { connections, conversionsStatus } = currentBrand || {};

  const {
    facebook_page,
    instagram_page,
    facebook_ad_account,
    facebook_pixel,
    facebook_business_manager,
    logged_in_fb_user,
  } = connections || {};
  const { conversions_tasks_completed } = conversionsStatus || {};

  const [facebookPage, setFacebookPage] = useState<FacebookPageType>();
  const [instagramPage, setInstagramPage] = useState<InstagramPageType>();
  const [facebookAdAccount, setFacebookAdAccount] = useState<AdAccountPageType>();
  const [facebookPixel, setFacebookPixel] = useState<FBPixel>();
  const [facebookBusinessManager, setFacebookBusinessManager] = useState<FacebookBusinessManager>();
  const [conversionsEnabled, setConversionsEnabled] = useState<boolean>(Boolean(conversions_tasks_completed && campaignType !== CAMPAIGN_TYPES.boost_instagram));
  const [conversionsEventOptions, setConversionsEventOptions] = useState<PixelConversionEvent[]>([]);
  const [eventsLoading, setEventsLoading] = useState<boolean>(false);
  const [conversionsEventName, setConversionsEventName] = useState<string>();
  const [validatingConnections, setValidatingConnections] = useState<boolean>(false);
  const [areConnectionsValid, setAreConnectionsValid] = useState<boolean>(true);
  const [confirmedConnections, setConnectionsConfirmed] = useState<boolean>(true);
  const [preConnectionConfigurations, setPreConnectionConfigurations] = useState<PreConnectionConfigs>();
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(true);
  const [validateConnections, setValidateConnections] = useState<boolean>(false);
  const [confirmConnections, setConfirmConnections] = useState<boolean>(false);
  const [connectionsType, setConnectionsType] = useState<string>("quick")
  const [instagramPagesAvailable, setInstagramPagesAvailable] = useState<boolean>(true)
  const [loggedInFbUser, setLoggedInFbUser] = useState<LoggedUser>();
  const [needsToReLogIn, setNeedsToReLogIn] = useState<boolean>(false);

  useEffect(() => {
    if (draft) return;
    if (facebook_page) setFacebookPage(facebook_page);
    if (instagram_page) setInstagramPage(instagram_page);
    if (facebook_ad_account) setFacebookAdAccount(facebook_ad_account);
    if (facebook_pixel) setFacebookPixel(facebook_pixel);
    if (facebook_business_manager) setFacebookBusinessManager(facebook_business_manager);
    if (logged_in_fb_user) setLoggedInFbUser(logged_in_fb_user);
    setValidateConnections(true);
    setConfirmConnections(true);
  }, []);

  useEffect(() => {
    validateToken();
  }, [loggedInFbUser]);

  useEffect(() => {
    if (campaignType === CAMPAIGN_TYPES.link_clicks) loadConversionsEvents();
  }, [campaignType]);

  useEffect(() => {
    if (!validateConnections && draft) return;
    if (validateConnections) {
      checkConnectedAccounts()
      setValidateConnections(false)
    }
  }, [validateConnections]);

  useEffect(() => {
    if (confirmConnections) {
      setConnectionsConfirmed(areConnectionsValid && areTermsAccepted)
      setConfirmConnections(false)
    }
  }, [confirmConnections]);

  const validateToken = async () => {
    if (!loggedInFbUser) return;
    const validAccessToken = await isValidAccessToken(loggedInFbUser.access_token);

    if(!validAccessToken) {
      setNeedsToReLogIn(!validAccessToken);
      return
    } else setNeedsToReLogIn(false);

    const isExpiredPicture = await checkIfUrlExpired(loggedInFbUser.picture?.data.url || '');

    const needsProfileData = !loggedInFbUser.id || !loggedInFbUser.name || !loggedInFbUser.picture || isExpiredPicture;
    if (needsProfileData || isExpiredPicture) {
      await refreshFbLoggedIn();
      const profileData = await loggedInFb();
      if(!profileData) return;
      setLoggedInFbUser(prev => ({ ...prev, ...profileData }));
      return;
    }
  }

  const refreshFbLoggedIn = async () => {
    if(!loggedInFbUser?.access_token) return;
    await updateInBrand(currentBrand?.id!, currentBrand?.slug!, { logged_in_fb_user: loggedInFbUser?.access_token }, 'logged_in_fb_user');
    await reloadBrand();
  }

  const validateAccountsImages = async () => {
    if (facebookPage?.picture?.data?.url) {
      const isFbPageImageExpired = await checkIfUrlExpired(facebookPage?.picture?.data?.url)
      if (isFbPageImageExpired) {
        const fbImage = await getFacebookImage(facebookPage.id, loggedInFbUser?.access_token)
        setFacebookPage(prev => ({ ...prev, picture: { data: { url: fbImage } } } as FacebookPageType))
      }
    }
    if (facebookPage && instagramPage?.instagramAccount.profile_pic) {
      const isIGPageImageExpired = await checkIfUrlExpired(instagramPage?.instagramAccount.profile_pic)
      if (isIGPageImageExpired) {
        const updatedIgPage = await getInstagramAccountFromFbPage(facebookPage, loggedInFbUser?.access_token)
        setInstagramPage(updatedIgPage || instagramPage)
      }
    }
  }

  const loadConversionsEvents = async () => {
    setEventsLoading(true)
    const events = await getConversionsEvents()
    if (events?.length) setConversionsEventOptions(events)
    setEventsLoading(false)
  }

  const checkConnectedAccounts = async () => {
    if (!loggedInFbUser) {
      setAreConnectionsValid(false)
      setConnectionsConfirmed(false)
      return;
    }

    setValidatingConnections(true)

    const hasAllConnections = Boolean(facebookAdAccount && facebookPage)
    if (!hasAllConnections) {
      setAreConnectionsValid(false)
      setValidatingConnections(false)
      return;
    }

    const adAccountDetails = await getAdAccountDetails(facebookAdAccount, loggedInFbUser.access_token)
    const fbAccountDetails = await getFbAccountDetails(facebookPage, loggedInFbUser.access_token)
    const igAccountDetails = await getInstagramDetails(instagramPage, loggedInFbUser.access_token)
    const preConnectionConfigurations = await getTosConfiguration(adAccountDetails, facebookAdAccount, loggedInFbUser.access_token)

    const areAllConnectionsValid = Boolean(adAccountDetails && fbAccountDetails)

    if (!areAllConnectionsValid) {
      removeConnections()
      setConversionsEnabled(false)
      setValidatingConnections(false)
      return;
    }

    const isValidInstagram = await validateInstagram(instagramPage, fbAccountDetails)

    if (campaignType === CAMPAIGN_TYPES.boost_instagram && !instagramPage) {
      setAreConnectionsValid(false)
      setConnectionsConfirmed(false)
    }

    if (instagramPage && (!isValidInstagram || !igAccountDetails)) {
      setInstagramPage(undefined)
      setAreConnectionsValid(false)
      setConnectionsConfirmed(false)
    }

    setPreConnectionConfigurations(preConnectionConfigurations)

    if (preConnectionConfigurations) {
      const currentTermsStatus = getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'tos')
      setAreTermsAccepted(currentTermsStatus)
    }

    if (hasAllConnections && areAllConnectionsValid) {
      setAreConnectionsValid(true)
    }

    validateAccountsImages()
    setValidatingConnections(false)
  }

  const removeConnections = () => {
    setFacebookAdAccount(undefined)
    setFacebookPage(undefined)
    setInstagramPage(undefined)
    setFacebookBusinessManager(undefined)
    setFacebookPixel(undefined)
    setAreConnectionsValid(false)
    setConnectionsConfirmed(false)
    reloadBrand()
  }

  const context = {
    facebookPage,
    instagramPage,
    facebookAdAccount,
    facebookPixel,
    facebookBusinessManager,
    conversionsEnabled,
    validatingConnections,
    areConnectionsValid,
    connectionsConfirmed: confirmedConnections,
    preConnectionConfigurations,
    areTermsAccepted,
    validateConnections,
    conversionsEventName,
    eventsLoading,
    conversionsEventOptions,
    connectionsType,
    instagramPagesAvailable,
    loggedInFbUser,
    needsToReLogIn,
    confirmConnections,
    setConversionsEventOptions,
    setFacebookPage,
    setInstagramPage,
    setFacebookAdAccount,
    setFacebookPixel,
    setFacebookBusinessManager,
    setConversionsEnabled,
    setValidatingConnections,
    setAreConnectionsValid,
    setConnectionsConfirmed,
    setPreConnectionConfigurations,
    setAreTermsAccepted,
    removeConnections,
    setValidateConnections,
    setConversionsEventName,
    setEventsLoading,
    setConnectionsType,
    setInstagramPagesAvailable,
    setLoggedInFbUser,
    setNeedsToReLogIn,
    setConfirmConnections,
  };

  return (
    <ConnectionsContext.Provider value={context}>
      {children}
    </ConnectionsContext.Provider>
  )
};
