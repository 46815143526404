import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { TABS } from "modules/Const";
import InfoSection from "components/InfoSection";
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils";
import Select from 'react-select'
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import { SelectedTikTokSparkAd, TikTokAdAccount } from "types/global";
import TiktokAdAccountConnect from "components/connect/TikTokAdAccount";
import Axios from "helpers/Interceptor";
import { OutlinedButton } from "styles/shared";

interface TikTokCreativeTab {
    brand: any;
    reloadBrand: any;
    setSelectedAssets: any;
    selectedAssets: SelectedTikTokSparkAd[] | null;
    setSelectedTab: any;
    tiktokLoggedIn: boolean;
    setTiktokLoggedIn: Dispatch<SetStateAction<boolean>>;
    reloadBrands: any;
    setCTA: any;
    cta: any;
    adConnectionsAdded: boolean;
    setAdConnectionsAdded: Dispatch<SetStateAction<boolean>>
    showContinueButton?: boolean;
    saveConnectModalType?: (type: string) => void;
    previewedVideo: SelectedTikTokSparkAd | null;
    setPreviewedVideo: (type: SelectedTikTokSparkAd | null) => void;
    selectedTiktokAdAccount: TikTokAdAccount | null;
    setSelectedTikTokAdAccount: (type: TikTokAdAccount) => void;
}

export default function CreativeTab(props: TikTokCreativeTab) {
    const {
        brand,
        reloadBrand,
        setSelectedAssets,
        selectedAssets,


        previewedVideo,
        setPreviewedVideo,

        setTiktokLoggedIn,
        tiktokLoggedIn,

        cta,
        setCTA,

        setSelectedTab,

        adConnectionsAdded,
        setAdConnectionsAdded,
        showContinueButton = true,
        saveConnectModalType,

        selectedTiktokAdAccount,
        setSelectedTikTokAdAccount
    } = props

    const { data } = useContext(ConnectModalsContext)
    const { loading: isConnectModalLoading } = data

    const [showConnectModal, setConnectModal] = useState<any>('');


    // tiktok 
    const [connectingTikTok, setConnectingTiktok] = useState(false);

    async function loginWithTikTok() {
        setConnectingTiktok(true)
        window.open(`https://ads.tiktok.com/marketing_api/auth?app_id=7190203583793463298&state=${brand.slug}&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fapi%2Fbrand%2Ftiktok-connect&rid=6jj75ldezg4`,
            '_blank' // <- This is what makes it open in a new window.
        )

    }



    useEffect(() => {
        if (saveConnectModalType) saveConnectModalType(showConnectModal)
    }, [showConnectModal])

    // scroll to top if ad connections were added
    useEffect(() => {
        scrollToTop()
        scrollIntoView()
    }, [adConnectionsAdded])

    // creative details 
    // React state hook for managing loading state
    const [currentLink, setCurrentLink] = useState<string>('');
    const [loadingData, setLoadingData] = useState<boolean>(false);


    async function getCreativeDetails() {
        // Begin loading
        if (!currentLink || !brand) return;
        setLoadingData(true);
        try {
            const response = await Axios.post(`/brand/${brand.id}/tiktok/creatives/authorize`, {
                tiktokSparkCode: currentLink
            });

            // Check if the response is successful
            if (!response || response.status !== 200) {
                throw new Error('Error getting creative details');
            }

            // Save the post in the selectedPosts state
            setSelectedAssets([...(selectedAssets ? selectedAssets : []), response.data]);

        } catch (error) {
            console.error('Error getting creative details', error);
        }

        // Stop loading
        setLoadingData(false);
    }


    // Handlers
    const handleSetAdConnectionsAdded = () => {

        setAdConnectionsAdded(true)
    }

    function handleOnClickContinue() {



        setSelectedTab(TABS.BUDGET)
    }

    const handleShowConnectModal = async (modal: string) => {

        setConnectModal(modal);
    }

    function connectTiktokClicked() {
        if (!tiktokLoggedIn) {
            loginWithTikTok()
        } else if (!selectedTiktokAdAccount?.advertiser_id) {
            handleShowConnectModal('tiktok-ad-account')
        } else {
            handleShowConnectModal('tiktok-ad-account')

        }
    }


    useEffect(() => {
        if (connectingTikTok) {
            const interval = setInterval(() => {
                // refresh the brand to see if user logged in
                reloadBrand()
            }, 10000);
            return () => clearInterval(interval);

        } else if (!connectingTikTok) {

        }
    }, [connectingTikTok]);


    return (
        <div className="middle-wrapper">
            {!(adConnectionsAdded) ? (
                <>
                    {showConnectModal === '' && (
                        <>
                            <div className="sy-card px-5 lg:px-10">
                                <div className="">
                                    <h5 className="text-dark font-bold text-lg">Connect Accounts</h5>
                                    <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                        To create the most optimal audiences to send traffic to your TikTok, we'll need to plug-in with your TikTok Ad account. (You'll only have to do this one time.)
                                    </p>
                                </div>
                            </div>

                            <div className="px-5 lg:px-10 py-5 border-b">
                                <p className="text-lg font-bold">Advertising Accounts</p>
                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                    Run Symphony marketing using your TikTok Ad Account.
                                </p>
                            </div>


                            <div>
                                <div className="sy-card px-5 lg:px-10">
                                    <div className="flex flex-none text-center ">
                                        <div className="artist-img">
                                            <img src={require('../../../../assets/images/tiktok.svg').default} />

                                        </div>
                                        <div className="px-3 items-center flex text-left">
                                            <h5 className="text-dark">TikTok Ad Account</h5>
                                        </div>
                                    </div>
                                    <div>
                                        {selectedTiktokAdAccount && selectedTiktokAdAccount?.advertiser_id ? (
                                            <div className="flex items-center ">
                                                <p className="pl-6 pr-3 text-fontColor-100 text-right">
                                                    {selectedTiktokAdAccount && selectedTiktokAdAccount.name}
                                                </p>
                                                <a onClick={connectTiktokClicked}>
                                                    <img
                                                        src={
                                                            require("../../../../assets/images/right-arrow.svg")
                                                                .default
                                                        }
                                                    />
                                                </a>
                                            </div>
                                        ) : (
                                            <button className={"text-green cursor-pointer"}
                                                onClick={connectTiktokClicked}
                                                disabled={connectingTikTok}>
                                                {connectingTikTok ? "Connecting..." : "Connect"}

                                            </button>

                                        )}
                                    </div >
                                    {/* <div>
                                        <FacebookGenericSelectorView

                                            selected={selectedFBAdAccount}
                                            update={() => {
                                                // if (!selectedFBBusinessManager) {
                                                //     handleShowConnectModal('facebook-biz-mgr')

                                                // } else {
                                                handleShowConnectModal('tiktok-ad-account')

                                                // }
                                            }}
                                        />
                                    </div> */}
                                </div>
                            </div>




                            {/* {connectionsType === "simple" ? showExpressSetupUI() : null} */}
                            {showContinueButton &&
                                <div className="text-center px-4 lg:px-10 py-4">
                                    <button
                                        disabled={false}
                                        onClick={handleSetAdConnectionsAdded}
                                        className="continueButtonCreativeTab1 btn-primary w-full br-20px mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3"
                                    >
                                        Continue
                                    </button>
                                </div>}

                        </>)}

                    {showConnectModal === 'tiktok-ad-account' && (
                        <TiktokAdAccountConnect

                            // withBorderRadius
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            // showBusinessManagerSelector={() => handleShowConnectModal('facebook-biz-mgr')}
                            // businessManager={selectedFBBusinessManager ? selectedFBBusinessManager : null}
                            selected={selectedTiktokAdAccount}
                            set={setSelectedTikTokAdAccount}
                        // showConnectButton={isConnectModalLoading}
                        />
                    )}

                </>
            ) : (
                <>
                    {showConnectModal === '' && (
                        <>


                            <InfoSection title="Add Creative Visuals" description="Upload the videos you want shown in your ads. We recommend selecting an Instagram Reel or post that's already received some engagement." />

                            <div className="px-6 lg:px-10 py-5 border-b">
                                <p className="text-lg font-bold">Which video do you want to use?</p>
                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">Enter your TikTok video's authorization code. Your authorization code lets Symphony's AI automatically create ads that drive back to your TikTok profile.</p>
                            </div>

                            <div className="input-form flex flex-row align-middle ">

                                <input
                                    className="border-b  py-6 px-6 lg:px-10 outline-none text-left border-gray-200 w-full"
                                    // minLength={2}
                                    placeholder="e.g. #xyz"
                                    value={currentLink}
                                    onChange={(e) => setCurrentLink(e.target.value)}
                                />
                            </div>
                            <div className="mt-4 mb-2 px-6 lg:px-10  text-left w-full">
                                <OutlinedButton onClick={getCreativeDetails}>Add to Creatives</OutlinedButton>
                            </div>
                            {selectedAssets && selectedAssets.length > 0 ? (
                                <div>
                                    {selectedAssets.map((post: SelectedTikTokSparkAd, index: number) => {

                                        const videoThumbnail = post.video_info.poster_url
                                        return (
                                            <div className="sy-card px-5 lg:px-10" key={index}>
                                                <div className="flex flex-row justify-between items-center">
                                                    <img src={videoThumbnail} alt="tiktok video cover" className="w-20 h-auto rounded-sm" />

                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            ) : null}


                            {/* {creativeSelectorTab === 'instagram_posts' &&
                                <div className="border-b pb-6">
                                    <InstagramPosts
                                        posts={instagramPosts}
                                        setSelectedInstagramPost={setSelectedInstagramPost}
                                        selectedInstagramPost={selectedInstagramPost}
                                        showCaption={false}
                                        allowPostPreviews={true}
                                    />
                                </div>
                            }
 */}


                            <InfoSection title="Call-to-Action" description="Enter the caption you want to use for your ad, and what users should see as the swipe-up call-to-action on your ad." />

                            <div className={`px-6 lg:px-10 ${showContinueButton ? "py-2" : "pt-2 pb-8"} border-b`}>

                                <p className="text-md font-medium mt-6">Ad Link</p>
                                <p className="text-sm text-gray-500">Your ads will link to your TikTok profile:</p>
                                {/* <div className="flex flex-row items-center mt-1">
                                    <img className="w-8 h-8 rounded-full" src={
                                        selectedInstaPage.instagramAccount.profile_pic
                                    }></img>
                                    <p className="ml-1"> @{selectedInstaPage.instagramAccount.username}</p>
                                </div> */}

                                <p className="text-md font-medium mt-8">Call-to-Action</p>
                                <p className="text-sm text-gray-500">Select a call-to-action for your ad - when a user clicks this, they will be sent to your Instagram Profile.</p>

                                <Select
                                    value={cta}
                                    onChange={(value: any) => setCTA(value)}
                                    options={[
                                        { value: 'CONTACT_US', label: 'Contact Us' },
                                        { value: 'DOWNLOAD', label: 'Download' },
                                        { value: 'LEARN_MORE', label: 'Learn More' },
                                        { value: 'SHOP_NOW', label: 'Shop Now' },
                                        { value: 'SIGN_UP', label: 'Sign Up' },
                                        { value: 'LISTEN_NOW', label: 'Listen Now' },
                                        { value: 'WATCH_MORE', label: 'Watch More' }

                                    ]}
                                />


                            </div>


                            {showContinueButton &&
                                <div className="text-center px-4 lg:px-10 py-4">
                                    <button
                                        onClick={handleOnClickContinue}
                                        className="continueButtonCreativeTab2 btn-primary w-full br-20px mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3"
                                        disabled={false}
                                    >
                                        Continue
                                    </button>
                                </div>}


                        </>
                    )}
                </>
            )
            }
        </div >

    )
}