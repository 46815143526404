import { toast } from "react-toastify";
import { ToastOptions } from "react-toastify";
import { MenuItem, Select, FormControl } from "@material-ui/core";
import { useContext, useState } from "react";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { PlanType } from "modules/Const";
import ModalSymphony from "components/shareable/ModalSymphony";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import { updateBrandSubscription } from "services/symphonyApi/adminService";
import Colors from "modules/Colors";
import { CurrentUserContext } from "Hooks/CurrentUserContext";

/** Toast configuration for notifications */
const TOAST_OPTIONS: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

const DevSubscriptionSpoofer = ({
    createStripeTrial = false
}: {
    createStripeTrial?: boolean
}): JSX.Element | null => {
    const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
    const { isSymphonyAdmin } = useContext(CurrentUserContext);
    const [loading, setLoading] = useState(false);
    const [showTrialDetails, setShowTrialDetails] = useState(false);
    const [selectedPlanType, setSelectedPlanType] = useState<PlanType | null>(PlanType.LITE);
    const [trialDays, setTrialDays] = useState(14);

    if (!currentBrand || !isSymphonyAdmin) {
        return null;
    }

    const updateSubscription = async (planType: PlanType, trialDays?: number) => {
        setLoading(true);
        try {
            await updateBrandSubscription(currentBrand.slug, {
                planType,
                createStripeTrial,
                trialDays: trialDays ?? undefined
            });
            await reloadBrand();
            toast.success(`Subscription updated to ${planType}`, TOAST_OPTIONS);
            setShowTrialDetails(false);
        } catch (e) {
            toast.error("Error updating subscription", TOAST_OPTIONS);
            console.error(e);
        }
        setLoading(false);
    };

    const handlePlanSelect = (planType: PlanType) => {
        if (createStripeTrial) {
            setSelectedPlanType(planType);
            setShowTrialDetails(true);
        } else {
            updateSubscription(planType);
        }
    };

    const renderDropdown = (showBgColor = false, showFreeOption = true) => {
        if (!currentBrand) {
            return null;
        }
        return (
            <FormControl variant="outlined" size="small" fullWidth={true} style={{
                padding: '0 8px',
                backgroundColor: showBgColor ? Colors.purple : 'transparent'
            }}>
                <Select
                    value={selectedPlanType || currentBrand.tier || PlanType.FREE}
                    onChange={(e) => handlePlanSelect(e.target.value as PlanType)}
                    disabled={loading}
                >
                    <MenuItem value={PlanType.LITE}>Lite</MenuItem>
                    <MenuItem value={PlanType.PRO}>Pro</MenuItem>
                </Select>
            </FormControl>
        )
    }

    return (
        <>
            <ModalSymphony
                size="small"
                title="Admin: Set Trial Duration"
                subtitle="This will create a Lite or Pro subscription trial for the brand. The profile will be asked to enter card to continue the trial after the trial period."
                isOpen={showTrialDetails}
                onClose={() => { setShowTrialDetails(false) }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 12,
                    width: '100%'
                }}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 4
                    }}>
                        <p>Plan</p>
                        {renderDropdown(false, false)}
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 4
                    }}>
                        <p>Trial Duration</p>
                        <FormControl variant="outlined" size="small" fullWidth style={{
                            padding: '0 8px',
                        }}>
                            <Select
                                value={trialDays}
                                onChange={(e) => setTrialDays(Number(e.target.value))}
                                disabled={loading}
                            >
                                <MenuItem value={14}>14 Days</MenuItem>
                                <MenuItem value={30}>30 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <ButtonSymphony
                    isLoading={loading}
                    onClick={() => selectedPlanType && updateSubscription(selectedPlanType, trialDays)}
                    variant="outlined"
                    disabled={loading}
                >
                    {loading ? 'Updating...' : 'Confirm'}
                </ButtonSymphony>
            </ModalSymphony>
            <ButtonSymphony
                width="100%"
                onClick={() => setShowTrialDetails(true)}
                size="small"
            >
                Admin: Start Trial
            </ButtonSymphony>
        </>
    );
};

export default DevSubscriptionSpoofer;