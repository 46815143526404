import styled from 'styled-components';
import Colors from "modules/Colors";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ButtonCampaignInfo = styled.button`
  border: 1px solid ${Colors.purple};
  border-radius: 48px;
  max-height: 32px;
  width: auto;
  display: inline;
  white-space: nowrap;
  padding: 6px 24px;
  display: flex;
  align-items: center;
`;

export const TextButton = styled.span`
  color: ${Colors.purple};
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;