import { Fragment, useContext, useEffect, useRef, useState, CSSProperties } from "react";
import AudioPlayer from "components/shareable/AudioPlayer";
import PauseIcon from "components/svg-icons/pause";
import PlayIcon from "components/svg-icons/play";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { contrastLinkImgMapping, filterOutLinks } from "../../../../../helpers/StreamingMappings";
import { ColorFormat, lightOrDark } from "../../../../../helpers/StyleUtils";
import VinylPlayer from "../../themes/VinylPlayer";
import FacebookIcon from "components/svg-icons/social/facebook";
import InstagramIcon from "components/svg-icons/social/instagram";
import TikTokIcon from "components/svg-icons/social/tiktok";
import TwitterIcon from "components/svg-icons/social/twitter";
import { YouTubeIcon } from "components/svg-icons/social/youtube";
import { formattedRecordName, newTab, objectToArray } from "modules/Utils";
import { WebsiteLink } from "../CustomizeWebsite/utils";
import { useStyles } from "./styles";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { WebsiteContext } from "../CustomizeWebsite/hooks/WebsiteContext";
import { Field, WEBSITE_TYPES, WebsiteType } from "types/global";
import { ReactComponent as ArrowDown } from "assets/images/chevronDown.svg";
import dayjs from "dayjs";
import { FontThemeStyleType } from "../CustomizeWebsite/components/StyleSection/FontSelectorSection/utils";
import { fontThemeStyles } from "../CustomizeWebsite/components/StyleSection/FontSelectorSection/utils";
import { THEME_STYLES } from "../CustomizeWebsite/components/StyleSection/ThemeSelectorSection/utils";
import Colors from "modules/Colors";
import useScreen from "Hooks/useScreen";
import { WEBSITE_TOP_BAR_HEIGHT, WEBSITE_BOTTOM_BAR_HEIGHT } from "modules/Const";
import { clsx } from "modules/Utils";

const { DATA_COLLECTOR, RECORD_STREAMS, TOUR } = WEBSITE_TYPES;
const { BASIC, CLASSIC, FULL_IMAGE, FULL_VIDEO, SIMPLE, VINYL } = THEME_STYLES

const getWhiteBlackTextColor = (mainBoxContrast: ColorFormat, flipColor?: boolean) => {
    let color = mainBoxContrast === 'light' ? 'black' : 'white';
    if (flipColor) {
        color = color === "black" ? "white" : "black";
    }

    return color;
}

const SocialMediaIcon = (props: { sm: any, mainBoxContrast: ColorFormat }) => {
    const { sm, mainBoxContrast } = props;
    if (!sm.enabled) {
        return null;
    }

    sm.iconColor = getWhiteBlackTextColor(mainBoxContrast);
    switch (sm.key) {
        case "facebook":
            return (
                <FacebookIcon
                    {...sm}
                    onClick={() => { newTab(sm.url) }}
                    cursor="pointer"
                />
            );
        case "instagram":
            return (
                <InstagramIcon
                    {...sm}
                    onClick={() => { newTab(sm.url) }}
                    cursor="pointer"
                />
            );
        case "tiktok":
            return (
                <TikTokIcon
                    {...sm}
                    onClick={() => { newTab(sm.url) }}
                    cursor="pointer"
                />
            );
        case "twitter":
            return (
                <TwitterIcon
                    {...sm}
                    onClick={() => { newTab(sm.url) }}
                    cursor="pointer"
                />
            );
        case "youtube":
            return (
                <YouTubeIcon
                    {...sm}
                    onClick={() => { newTab(sm.url) }}
                    cursor="pointer"
                />
            );
        default:
            return null;
    }
}

const SocialMediaIcons = (props: { mainBoxContrast: ColorFormat, parentWidth: number }) => {
    const classes = useStyles();
    const { mainBoxContrast, parentWidth } = props;
    const {
        socialMediaIcons,
        enableSocialIcons,
        themeStyle,
    } = useContext(WebsiteContext);

    if (!enableSocialIcons) return null;

    const socialMediaIconsArray = objectToArray(socialMediaIcons || {});
    const height = themeStyle === CLASSIC ? parentWidth * 0.08 : parentWidth * 0.10
    const width = themeStyle === CLASSIC ? parentWidth * 0.08 : parentWidth * 0.10
    return (
        <div className={classes.socialMediaIconsContainer}>
            {socialMediaIconsArray.map((sm: object, smi: number) => {
                return (
                    <Fragment key={`socialMediaIcon-${smi}`}>
                        <SocialMediaIcon sm={{ ...sm, height, width }} mainBoxContrast={mainBoxContrast} />
                    </Fragment>
                );
            })}
        </div>
    );
}

const LowerContent = (props: { mainBoxContrast: ColorFormat, parentWidth: number }) => {
    const { mainBoxContrast, parentWidth } = props;
    const {
        recordSelected,
        styleType,
    } = useContext(WebsiteContext);

    const {
        external_links
    } = recordSelected || {}

    if (!external_links) {
        return null;
    }
    switch (styleType) {
        case "boxes":
        case "recordPlayer": // NOTE: This is not used anymore but old websites need it as they used this to represent boxes
            return <BoxesLowerContent parentWidth={parentWidth} />
        case "stacked":
            return <StackedLowerContent mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
        case "traditional":
            return <TraditionalLowerContent mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
        default:
            return null;
    }
}

const BoxesLowerContent = (props: { parentWidth: number }) => {
    const classes = useStyles();
    const { parentWidth } = props;

    const {
        streamingLinks,
        secondaryColor = '',
        themeStyle,
    } = useContext(WebsiteContext);

    const actionBoxContrast = lightOrDark(secondaryColor)

    if (!streamingLinks) return <></>

    const links: WebsiteLink[] = Object.values(streamingLinks).filter((a) => filterOutLinks(a.key))
    const fontSize = parentWidth * 0.05
    return (
        <div className={classes.externalLinksBoxesContainer} style={{ padding: themeStyle === CLASSIC ? '0px 5% 5%' : '0px 5%' }}>
            {links.map((link, index) => {
                const { url, title, enabled, key } = link
                const imgLink = contrastLinkImgMapping('../../../', actionBoxContrast, key)
                const textColor = getWhiteBlackTextColor(actionBoxContrast)

                if (!enabled) return null;

                return (
                    <a
                        key={`${index}-${key}-boxesLowerContent`}
                        className={`boxesLowerContentComponent ${classes.externalLinkBox}`}
                        style={{ backgroundColor: secondaryColor }}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {imgLink?.default ?
                            <img className={classes.externalLinkImage} src={imgLink.default} alt={title} />
                            :
                            <div className="flex gap-1 w-full justify-center items-center">
                                <p className={classes.streamingServiceName} style={{ color: textColor, fontSize }}>
                                    {title}
                                </p>
                            </div>
                        }
                    </a>
                )
            })}
        </div>
    );
}

const StackedLowerContent = (props: { mainBoxContrast: ColorFormat, parentWidth: number }) => {
    const classes = useStyles();
    const { mainBoxContrast, parentWidth } = props;
    const {
        streamingLinks,
        themeStyle,
    } = useContext(WebsiteContext);

    if (!streamingLinks) return null

    const borderColor = mainBoxContrast === 'light' ? Colors.PAPER_DARK_CONTAINER : Colors.PAPER_CONTAINER
    const textColor = getWhiteBlackTextColor(mainBoxContrast)
    const links: WebsiteLink[] = Object.values(streamingLinks).filter((a) => filterOutLinks(a.key))
    const fontSize = parentWidth * 0.05

    return (
        <div className={classes.externalLinksContainer}>
            {links.map((link: any, index) => {
                const { url, title, enabled, key } = link
                const imgLink = contrastLinkImgMapping('../../../', mainBoxContrast, key)

                if (!enabled) return null

                return (
                    <a
                        key={key}
                        className={`${classes.stackedExternalLinks}`}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            borderColor,
                            borderBottom: links.length - 1 === index && themeStyle !== 'classic' ? `0.25px solid ${borderColor}` : 'none',
                            height: parentWidth * 0.20
                        }}
                    >
                        {imgLink?.default ?
                            <img className={classes.stackedImageIcons} src={imgLink.default} alt={title} />
                            :
                            <p className={classes.streamingServiceName} style={{ color: textColor, fontSize, maxWidth: "45%", }}>
                                {title}
                            </p>
                        }
                    </a>
                )
            })}
        </div>
    )
}

const TraditionalLowerContent = (props: { mainBoxContrast: ColorFormat, parentWidth: number }) => {
    const classes = useStyles();
    const { mainBoxContrast, parentWidth } = props;
    const {
        streamingLinks,
        mainColor = '',
        secondaryColor = '',
        themeStyle,
    } = useContext(WebsiteContext);

    if (!streamingLinks) return null

    const links: WebsiteLink[] = Object.values(streamingLinks).filter((a) => filterOutLinks(a.key))

    return (
        <div className={`traditionalLowerContentComponent ${classes.externalLinksContainer}`} style={{ paddingBottom: themeStyle === CLASSIC ? '0px' : '4%' }}>
            {links.map((link: any, index) => {
                const { url, title, enabled, key } = link
                const imgLink = contrastLinkImgMapping('../../../', mainBoxContrast, key)
                const mainColorContrast = lightOrDark(mainColor)
                const secondaryColorContrast = lightOrDark(secondaryColor)
                const borderColor = mainBoxContrast === 'light' ? Colors.PAPER_DARK_CONTAINER : Colors.PAPER_CONTAINER

                if (!enabled) return null

                return (
                    <a
                        key={key}
                        className={`traditionalExternalLinks ${classes.traditionalExternalLinks}`}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            borderColor,
                            borderBottom: links.length - 1 === index && themeStyle !== 'classic' ? `0.25px solid ${borderColor}` : 'none',
                            height: parentWidth * 0.20,
                        }}
                    >
                        {imgLink?.default ?
                            <img className={`stackedImageIcons ${classes.stackedImageIcons}`} src={imgLink.default} alt={title} />
                            :
                            <p
                                className={`streamingServiceName ${classes.streamingServiceName}`}
                                style={{
                                    color: getWhiteBlackTextColor(mainColorContrast),
                                    fontSize: parentWidth * 0.05,
                                    maxWidth: "45%",
                                }}
                            >
                                {title}
                            </p>
                        }
                        <div
                            className={`traditionalButton ${classes.traditionalButton}`}
                            style={{
                                color: getWhiteBlackTextColor(secondaryColorContrast),
                                backgroundColor: secondaryColor,
                                fontSize: parentWidth * 0.05,
                            }}
                        >
                            {imgLink?.default ? 'Listen' : 'Open'}
                        </div>
                    </a>
                )
            })}
        </div>
    )
}

const PhoneHeader = () => {
    const classes = useStyles();
    return (
        <div className={`phoneHeaderComponent ${classes.phoneHeader}`}></div>
    )
}

const Footer = ({
    mainBoxContrast,
    parentWidth
}: {
    mainBoxContrast: ColorFormat,
    parentWidth: number
}) => {
    const textColor = getWhiteBlackTextColor(mainBoxContrast)

    return (
        <div
            className="websitePreviewV2Footer"
            style={{
                color: textColor,
                fontSize: 10,
                padding: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 8,
            }}
        >
            <div className="infoTextPreview" style={{ fontSize: parentWidth * 0.03 }}>
                By using this service you agree to our Privacy Policy and Terms Of Use.
            </div>
        </div>
    );
}

const InfoText = (props: { websiteType: WebsiteType, mainBoxContrast: ColorFormat, parentWidth: number }) => {
    const { websiteType, mainBoxContrast, parentWidth } = props;
    const { currentBrand } = useContext(CurrentBrandContext)
    const {
        recordSelected,
        themeStyle,
        subtitle: subtitleText,
        showSecondaryLine,
        titleText,
        fontThemeStyle = 'font1',
        titleAlignment,
    } = useContext(WebsiteContext);

    const {
        name,
        explicit,
    } = recordSelected || {}

    const recordName = name ? formattedRecordName(name, explicit) : ''
    const titleFontSize = themeStyle === CLASSIC ? parentWidth * 0.05 : parentWidth * 0.06
    const titleTextTransform = themeStyle === CLASSIC ? 'uppercase' : 'none'
    const recordFontSize = themeStyle === CLASSIC ? parentWidth * 0.06 : themeStyle === SIMPLE ? parentWidth * 0.07 : parentWidth * 0.085
    const subtitleFontSize = parentWidth * 0.05
    const font = fontThemeStyles[fontThemeStyle as FontThemeStyleType].title;
    const defaultTitle = websiteType === DATA_COLLECTOR ? currentBrand?.name : ''

    const commonStyles = {
        fontFamily: `'${font}'`,
        lineHeight: 'normal',
        textAlign: titleAlignment
    };

    const containerStyles = {
        display: 'flex',
        flexDirection: 'column',
        color: getWhiteBlackTextColor(mainBoxContrast),
        margin: '4% 5%',
        gap: 4,
    } as CSSProperties;

    const titleStyles = {
        ...commonStyles,
        fontSize: titleFontSize,
        textTransform: titleTextTransform,
        fontFamily: 'DIN'
    } as CSSProperties

    const recordNameStyles = {
        ...commonStyles,
        fontSize: recordFontSize,
        fontWeight: 600
    };

    const subtitleStyles = {
        ...commonStyles,
        fontSize: subtitleFontSize
    };

    switch (websiteType) {
        case DATA_COLLECTOR:
        case TOUR:
            return (
                <div className="mainSongInfoContainerPreview"
                    style={containerStyles}
                >
                    <p
                        className="recordNameTextPreview font-semibold w-full"
                        style={recordNameStyles}
                    >
                        {titleText}
                    </p>
                    {subtitleText && showSecondaryLine &&
                        <p className="subtitleTextPreview font-weight-500"
                            style={subtitleStyles}
                        >
                            {subtitleText}
                        </p>
                    }
                </div>
            );
        default:
            return (
                <div className="mainSongInfoContainerPreview" style={containerStyles}>
                    <p className="titleTextPreview text-lg font-normal w-full" style={titleStyles}>
                        {titleText || defaultTitle}
                    </p>
                    <p className="recordNameTextPreview text-xl font-semibold w-full" style={recordNameStyles}>
                        {recordName || currentBrand?.name}
                    </p>
                    {subtitleText && showSecondaryLine &&
                        <p className="subtitleTextPreview font-weight-500" style={subtitleStyles}>
                            {subtitleText}
                        </p>
                    }
                </div>
            );
    }
}

const FullImageInfoText = (props: { mainBoxContrast: ColorFormat, parentWidth: number, websiteType: WebsiteType }) => {
    const classes = useStyles();
    const { currentBrand } = useContext(CurrentBrandContext)
    const { mainBoxContrast, parentWidth, websiteType } = props;

    const {
        titleText,
        showSecondaryLine,
        recordSelected,
        subtitle: subtitleText,
        fontThemeStyle = 'font1',
        titleAlignment,
    } = useContext(WebsiteContext);

    const {
        name,
        explicit,
    } = recordSelected || {}
    const recordName = name ? formattedRecordName(name, explicit) : ''

    const font = fontThemeStyles[fontThemeStyle as FontThemeStyleType].title;
    const titleFontSize = parentWidth * 0.05
    const recordFontSize = parentWidth * 0.09
    const subtitleFontSize = parentWidth * 0.05

    const containerStyle = {
        color: getWhiteBlackTextColor(mainBoxContrast),
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: '5%',
    } as CSSProperties;

    const commonStyles = {
        fontFamily: `'${font}'`,
        textAlign: titleAlignment
    };

    const artistTextStyle = {
        ...commonStyles,
        fontSize: recordFontSize,
        fontWeight: 600
    };

    const subtitleStyle = {
        ...commonStyles,
        fontSize: subtitleFontSize
    };

    const titleStyle = {
        ...commonStyles,
        fontFamily: 'DIN',
        fontSize: titleFontSize
    };

    const recordTextStyle = {
        ...artistTextStyle
    };

    switch (websiteType) {
        case DATA_COLLECTOR:
        case TOUR:
            return (
                <div className={classes.fullImageInfoTextContainer} style={containerStyle}>
                    <div className={classes.fullImageArtistText} style={artistTextStyle}>
                        {titleText}
                    </div>
                    {(subtitleText && showSecondaryLine) &&
                        <p className={classes.fullImageArtistText} style={subtitleStyle}>
                            {subtitleText}
                        </p>
                    }
                </div>
            );
        default:
            return (
                <div className={classes.fullImageInfoTextContainer} style={containerStyle}>
                    <div className={classes.fullImageArtistText} style={titleStyle}>{titleText}</div>
                    <div className={classes.fullImageRecordText} style={recordTextStyle}>
                        {recordName || currentBrand?.name}
                    </div>
                    {(subtitleText && showSecondaryLine) &&
                        <p className={classes.fullImageArtistText} style={subtitleStyle}>
                            {subtitleText}
                        </p>
                    }
                </div>
            );
    }
}

const MainSongThumbnail = (props: {
    fullView: boolean,
    playingVinylPlayer: boolean,
    mainBoxContrast: ColorFormat
    parentWidth: number
    websiteType: WebsiteType
}) => {
    const { playingVinylPlayer, mainBoxContrast, fullView, parentWidth, websiteType } = props;
    const classes = useStyles();
    const {
        recordSelected,
        mainColor = '',
        themeStyle,
        fullVideoUrl,
    } = useContext(WebsiteContext);

    const {
        thumbnail_url = '',
    } = recordSelected || {}

    const backgroundImageFullImage = {
        backgroundImage: `url(${thumbnail_url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    }

    const fullImageGradient = {
        background: `linear-gradient(180deg, rgba(245, 214, 214, 0.00) 23.3%, ${mainColor} 100%)`,
    }

    switch (themeStyle) {
        case "classic":
            return (
                <div className="classicContainer">
                    <img
                        style={{ width: '100%', aspectRatio: 1, objectFit: 'cover' }}
                        className="mainSongThumbnail"
                        src={thumbnail_url}
                        alt={recordSelected?.name}
                    />
                </div>
            );
        case "vinyl":
            return (
                <div className={classes.vinylPlayerContainer}>
                    <VinylPlayer
                        playing={playingVinylPlayer}
                        imageUrl={thumbnail_url}
                    />
                </div>
            );
        case "art":
            return (
                <div className="artWebsiteContainer" style={{ padding: '16px 6%' }}>
                    <img
                        className="mainSongThumbnail"
                        style={{ borderRadius: 10, aspectRatio: 1, width: '100%', objectFit: 'cover' }}
                        src={thumbnail_url}
                        alt={recordSelected?.name}
                    />
                </div>
            );
        case "full_image":
            return (
                <div className={classes.fullImageContainer} style={{ ...backgroundImageFullImage }}>
                    <div className={classes.fullImageBackground} style={{ ...fullImageGradient }}>
                        {!fullView && <PhoneHeader />}
                        <FullImageInfoText mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} websiteType={websiteType} />
                    </div>
                </div>
            );
        case "full_video":
            if (!fullVideoUrl) return (
                <div className={classes.fullImageContainer} style={{ ...backgroundImageFullImage }}>
                    <div className={classes.fullImageBackground} style={{ ...fullImageGradient }}>
                        {!fullView && <PhoneHeader />}
                        <FullImageInfoText mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} websiteType={websiteType} />
                    </div>
                </div>
            );
            return (
                <div className={classes.fullImageContainer} style={{ position: 'relative' }}>
                    <div className={classes.fullImageBackground} style={{ ...fullImageGradient, zIndex: 100, position: 'relative' }}>
                        {!fullView && <PhoneHeader />}
                        <FullImageInfoText mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} websiteType={websiteType} />
                    </div>
                    <video autoPlay muted loop playsInline style={{ width: "100%", height: '100%', objectFit: "cover", top: 0, position: 'absolute' }}>
                        <source src={fullVideoUrl} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            );
        default: return null;
    }
}

const PlayMedia = (props: {
    playingVinylPlayer: boolean
    mainBoxContrast: ColorFormat
    parentWidth: number
    handleVinylPlayerPlayState: VoidFunction
}) => {
    const classes = useStyles();
    const { playingVinylPlayer, mainBoxContrast, parentWidth, handleVinylPlayerPlayState } = props;
    const {
        recordSelected,
        mainColor = '',
        selectedPreviewTrack,
        isProject,
    } = useContext(WebsiteContext);

    const {
        primary_artist: artistName = '',
        preview_url = '',
        tracks = [],
        name: recordName = '',
    } = recordSelected || {}

    const getLightDarkTextClass = () => {
        return mainBoxContrast === 'light' ? 'text-black' : 'text-white'
    }

    const getAudioPlayerDetails = () => {
        let url: string | null = null;
        let trackName: string | null = null

        if (isProject) {
            if (selectedPreviewTrack && selectedPreviewTrack.preview_url) {
                url = selectedPreviewTrack.preview_url;
                trackName = formattedRecordName(selectedPreviewTrack.name, selectedPreviewTrack.explicit);
            } else if (tracks[1] && tracks[1].preview_url) {
                url = tracks[1].preview_url;
                trackName = formattedRecordName(tracks[1].name, tracks[1].explicit);
            }
        } else {
            // NOTE: is a single record
            url = preview_url;
            trackName = recordName;
        }

        return {
            previewUrl: url,
            previewTrackName: trackName,
        }
    }

    const vinylPlayerPlayButton = {
        background: mainBoxContrast === 'dark' ? 'rgba(255,255,255)' : 'rgba(0,0,0)',
    }

    const {
        previewUrl,
        previewTrackName,
    } = getAudioPlayerDetails();

    if (!previewUrl) return null;

    const fontSize = parentWidth * 0.04

    return (
        <div className={clsx(classes.playMedia, getLightDarkTextClass())}>
            <div className={classes.audioPlayerHolder}>
                <div className={classes.artistInfo} style={{ fontFamily: 'DIN', fontSize }}>"{previewTrackName}" - {artistName}</div>
                <AudioPlayer
                    url={previewUrl}
                    playing={playingVinylPlayer}
                    backgroundContrast={mainBoxContrast}
                />
            </div>
            <div className={classes.vinylPlayerPlayButton} onClick={handleVinylPlayerPlayState} style={vinylPlayerPlayButton}>
                {playingVinylPlayer ?
                    <PauseIcon width="50%" height="50%" color={mainColor} /> :
                    <PlayIcon width="50%" height="50%" color={mainColor} />
                }
            </div>
        </div>
    );
}

const MusicSiteContent = (props: { mainBoxContrast: ColorFormat, parentWidth: number }) => {
    const { mainBoxContrast, parentWidth } = props;
    return (
        <>
            <SocialMediaIcons mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
            <LowerContent mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
        </>
    )
}

const FieldContent = (props: {
    field: Field,
    parentWidth: number
}) => {
    const { field, parentWidth } = props;
    const classes = useStyles();
    const { mainColor = '' } = useContext(WebsiteContext);
    const [typeSelected, setTypeSelected] = useState<string>('number')

    const { themeStyle } = useContext(WebsiteContext);
    const borderRadius = themeStyle === SIMPLE ? 0 : 7
    const padding = field.id === 'phone_number' ? '4%' : '4% 4.5%'
    const fontSize = parentWidth * 0.04
    const iconSize = parentWidth * 0.05
    const borderColor = lightOrDark(mainColor) === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.12)'

    switch (field.id) {
        case 'email_number':
            return (
                <div className={classes.emailPhoneContainer}>
                    <div className={classes.emailNumberSelector} style={{ fontSize }}>
                        <div
                            className={clsx(classes.emailPhoneButton, typeSelected === 'number' && 'selected')}
                            onClick={() => setTypeSelected('number')}
                        >
                            Number
                        </div>
                        <div
                            className={clsx(classes.emailPhoneButton, typeSelected === 'email' && 'selected')}
                            onClick={() => setTypeSelected('email')}
                        >
                            Email
                        </div>
                    </div>
                    <div className={classes.fieldContainer} style={{ borderRadius, padding, fontSize, borderColor }}>
                        {typeSelected === 'number' && (
                            <div className="flex gap-1 items-center">
                                🇺🇸 <ArrowDown height={iconSize} width={iconSize} />
                            </div>
                        )}
                        {typeSelected === 'number' ? "What's your number?" : "What's your email?"}
                    </div>
                </div>
            )
        case 'phone_number':
            return (
                <div className={classes.fieldContainer} style={{ borderRadius, padding, fontSize, borderColor }}>
                    <div className="flex gap-1 items-center">
                        🇺🇸 <ArrowDown height={iconSize} width={iconSize} />
                    </div>
                    {field.label}
                </div>
            )
        default:
            return (
                <div className={classes.fieldContainer} style={{ borderRadius, padding, fontSize, borderColor }}>
                    {field.label}
                </div>
            )
    }
}

const DataCollectorSiteContent = ({
    mainBoxContrast,
    parentWidth,
}: {
    mainBoxContrast: ColorFormat
    parentWidth: number
}) => {
    const classes = useStyles();
    const {
        fields,
        submitButtonText,
        secondaryColor = '',
    } = useContext(WebsiteContext);

    if (!fields?.length) {
        return null
    }

    const textColor = getWhiteBlackTextColor(mainBoxContrast)
    const buttonTextColor = getWhiteBlackTextColor(lightOrDark(secondaryColor))
    const borderColor = getWhiteBlackTextColor(mainBoxContrast) === 'black' ? Colors.DARK_DIVIDER_LINE : 'white'
    const buttonFontSize = parentWidth * 0.05

    return (
        <div
            className='dataCollectorSiteContentComponent flex flex-col'
            style={{ color: textColor, padding: '0px 6%' }}
        >
            {fields.map((field, index) => {
                return (
                    <FieldContent
                        key={`field-${field.id}-${index}`}
                        field={field}
                        parentWidth={parentWidth}
                    />
                )
            })}
            <div
                className={classes.submitButton}
                style={{ background: secondaryColor, color: buttonTextColor, fontSize: buttonFontSize, borderColor }}
            >
                {submitButtonText?.length ? submitButtonText : 'Subscribe'}
            </div>
        </div>
    )
}

const getTourButtonText = ({ isSoldOut, isPreSalePeriod, preSaleButtonText, showIsPassed, buttonLinkText }: { isSoldOut: boolean, isPreSalePeriod: boolean, preSaleButtonText: string, showIsPassed: boolean, buttonLinkText: string }) => {
    if (showIsPassed) return 'Done';
    if (isSoldOut) return 'Sold Out';
    if (isPreSalePeriod) {
        if (preSaleButtonText) {
            return preSaleButtonText
        }
        return 'Pre-Sale'
    }
    return buttonLinkText || 'Buy';
}

const getTicketUrl = (ticketUrl: string, isSoldOut: boolean, isPreSalePeriod: boolean) => {
    if (!ticketUrl || isPreSalePeriod) return '';

    let url = ticketUrl;
    if (!/^https?:\/\//i.test(url)) {
        url = `https://${url.replace(/^\/+/, '')}`;
    }
    return isSoldOut || !isPreSalePeriod ? url : '';
}

const handleTicketClick = (ticketUrl: string, isSoldOut: boolean, isPreSalePeriod: boolean) => {
    const url = getTicketUrl(ticketUrl, isSoldOut, isPreSalePeriod);
    if (url) {
        window.open(url, '_blank');
    }
}

const LowerContentTour = (props: { tourDates: any[], parentWidth: number }) => {
    const { tourDates, parentWidth } = props;
    const classes = useStyles();

    const fontSize = parentWidth * 0.05

    const {
        styleType,
        secondaryColor,
        mainColor,
    } = useContext(WebsiteContext);

    // Helper function to render location details
    const renderLocationDetails = (city: string, region: string, country: string) => {
        const locationParts = [
            city,
            region,
            !['US', 'USA', 'United States'].includes(country) ? country : null
        ].filter(Boolean);
        return locationParts.join(', ');
    };

    return (
        <>
            {tourDates.map((tourDate, index) => {
                const {
                    date,
                    venue,
                    city,
                    region,
                    country,
                    isSoldOut,
                    isPreSalePeriod,
                    preSaleButtonText,
                    buttonLinkText,
                    ticketUrl,
                } = tourDate;

                const today = dayjs().startOf('day');
                const eventDate = dayjs(date).startOf('day');
                const showIsPassed = eventDate.isBefore(today);

                const lightOrDarkPrimaryColor = lightOrDark(mainColor as string) === 'dark' ? 'white' : 'black'
                const lightOrDarkSecondaryColor = lightOrDark(secondaryColor as string) === 'dark' ? 'white' : 'black'

                const day = eventDate.format('DD')
                const month = eventDate.format('MMM')

                const buttonStyle = {
                    backgroundColor: secondaryColor,
                    color: lightOrDarkSecondaryColor,
                    opacity: isSoldOut ? 0.5 : 1,
                };

                const locationDetails = renderLocationDetails(city, region, country);

                const firstLine = venue || locationDetails;
                const secondLine = venue ? locationDetails : null;

                switch (styleType) {
                    case "boxes":
                        return (
                            <div
                                key={`tourDateRowPreview-${index}`}
                                className={`tourDateRowPreview ${classes.tourDateRow}`}
                                style={{ fontSize, color: lightOrDarkPrimaryColor, opacity: showIsPassed ? 0.4 : 1 }}
                            >
                                <div className={`tourDatePreviewContainer ${classes.tourDatePreviewContainer}`}>
                                    <div className={classes.tourDateMonth}>
                                        {month.toUpperCase()}
                                    </div>
                                    <div className={classes.tourDateDay}>
                                        {day}
                                    </div>
                                </div>
                                <div className={classes.tourDateDetailContainer}>
                                    <div className={classes.tourDateCity}>
                                        {firstLine}
                                    </div>
                                    {secondLine && (
                                        <div className={classes.tourDatePlace}>
                                            {secondLine}
                                        </div>
                                    )}
                                </div>
                                <button
                                    className={classes.tourDateBuyButton}
                                    style={buttonStyle}
                                    onClick={() => handleTicketClick(ticketUrl, isSoldOut, isPreSalePeriod)}
                                >
                                    {getTourButtonText({ isSoldOut, isPreSalePeriod, preSaleButtonText, showIsPassed, buttonLinkText })}
                                </button>
                            </div>
                        )
                    case "stacked":
                        return (
                            <div
                                key={`tourDateRowStackedPreview-${index}`}
                                className={`tourDateRowStackedPreview ${classes.tourDateRowStackedPreview}`}
                                style={{ fontSize, color: lightOrDarkPrimaryColor, opacity: showIsPassed ? 0.4 : 1 }}
                            >
                                <div className={classes.tourDateDetailStackedContainer}>
                                    <div className={`tourDatePreviewStackedContainer ${classes.tourDatePreviewStackedContainer}`}>
                                        <div className={classes.tourDateDay}>
                                            {month} {day.replace(/^0/, '')}
                                        </div>
                                    </div>
                                    <div className={`${classes.stackedTourDateText} tourStackedFirstLine`}>
                                        {firstLine}
                                    </div>
                                    {secondLine &&
                                        <div
                                            style={{ opacity: 0.5 }}
                                            className={`${classes.stackedTourDateText} tourStackedSecondLine`}
                                        >
                                            {secondLine}
                                        </div>
                                    }
                                </div>
                                <button
                                    className={classes.tourDateBuyButtonStacked}
                                    style={buttonStyle}
                                    onClick={() => handleTicketClick(ticketUrl, isSoldOut, isPreSalePeriod)}
                                >
                                    {getTourButtonText({ isSoldOut, isPreSalePeriod, preSaleButtonText, showIsPassed, buttonLinkText })}
                                </button>
                            </div>
                        )
                    default:
                        return <div key={`tourDateRowPreview-${index}`} className="nothingTourState">NOTHING</div>
                }
            })}
        </>
    )
}

const TourSiteContent = (props: {
    mainBoxContrast: ColorFormat,
    parentWidth: number
}) => {
    const { mainBoxContrast, parentWidth } = props;
    const {
        tourDates,
        themeStyle,
        hidePastTourDates,
    } = useContext(WebsiteContext);

    const textColor = getWhiteBlackTextColor(mainBoxContrast)

    // Filter out past dates if hidePastTourDates is true
    const filteredTourDates = hidePastTourDates
        ? tourDates.filter(tourDate => dayjs(tourDate.date).isAfter(dayjs()))
        : tourDates;

    return (
        <div
            className="tourDatesContainer"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', color: textColor, padding: themeStyle === CLASSIC ? '0px 5% 5%' : '0px 5%' }}
        >
            <LowerContentTour tourDates={filteredTourDates} parentWidth={parentWidth} />
        </div>
    )
}

const MusicSiteHeader = (props: {
    fullView: boolean,
    playingVinylPlayer: boolean
    mainBoxContrast: ColorFormat
    parentWidth: number
    websiteType: WebsiteType
    handleVinylPlayerPlayState: VoidFunction
}) => {
    const { fullView, playingVinylPlayer, mainBoxContrast, parentWidth, websiteType, handleVinylPlayerPlayState } = props;
    const {
        embedYt,
        youtubeID,
        themeStyle,
    } = useContext(WebsiteContext);
    const classes = useStyles();
    return (
        <>
            {embedYt && themeStyle !== VINYL && themeStyle !== FULL_IMAGE && themeStyle !== FULL_VIDEO ? (
                <div className={classes.youtubeEmbeddedContainer}>
                    <LiteYouTubeEmbed
                        id={youtubeID ? youtubeID : "L2vS_050c-M"}
                        title=""
                    />
                </div>
            ) : (
                <MainSongThumbnail
                    fullView={fullView}
                    playingVinylPlayer={playingVinylPlayer}
                    mainBoxContrast={mainBoxContrast}
                    parentWidth={parentWidth}
                    websiteType={websiteType}
                />
            )}
            {themeStyle === VINYL && (
                <PlayMedia
                    parentWidth={parentWidth}
                    playingVinylPlayer={playingVinylPlayer}
                    handleVinylPlayerPlayState={handleVinylPlayerPlayState}
                    mainBoxContrast={mainBoxContrast}
                />
            )}
        </>
    )
}

const DataCollectorSiteHeader = (props: { parentWidth: number, fullView: boolean, websiteType: WebsiteType }) => {
    const { parentWidth, fullView, websiteType } = props;
    const { currentBrand } = useContext(CurrentBrandContext)
    const classes = useStyles();

    const {
        themeStyle,
        mainColor,
        customThumbnailUrl,
        fullVideoUrl,
        customThumbnailViewStyle,
    } = useContext(WebsiteContext);
    const backgroundImageFullImage = {
        backgroundImage: `url(${customThumbnailUrl || currentBrand?.image || ''})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    }
    const fullImageGradient = {
        background: `linear-gradient(180deg, rgba(245, 214, 214, 0.00) 23.3%, ${mainColor} 100%)`,
    }
    const mainBoxContrast = lightOrDark(mainColor || '')

    switch (themeStyle) {
        case "full_image":
            return (
                <div className={classes.fullImageContainer} style={{ ...backgroundImageFullImage }}>
                    <div className={classes.fullImageBackground} style={{ ...fullImageGradient }}>
                        {!fullView && <PhoneHeader />}
                        <FullImageInfoText mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} websiteType={DATA_COLLECTOR} />
                    </div>
                </div>
            );
        case "full_video":
            if (!fullVideoUrl) return (
                <div className={classes.fullImageContainer} style={{ ...backgroundImageFullImage }}>
                    <div className={classes.fullImageBackground} style={{ ...fullImageGradient }}>
                        {!fullView && <PhoneHeader />}
                        <FullImageInfoText mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} websiteType={websiteType} />
                    </div>
                </div>
            );
            return (
                <div className={classes.fullImageContainer} style={{ position: 'relative' }}>
                    <div className={classes.fullImageBackground} style={{ ...fullImageGradient, zIndex: 100, position: 'relative' }}>
                        {!fullView && <PhoneHeader />}
                        <FullImageInfoText mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} websiteType={websiteType} />
                    </div>
                    <video autoPlay muted loop playsInline style={{ width: "100%", height: '100%', objectFit: "cover", top: 0, position: 'absolute' }}>
                        <source src={fullVideoUrl} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
            );
        default:
            return (
                <div className="mainImageCreatorContainer flex w-full justify-center" style={{ padding: '4% 5% 0px' }}>
                    <img
                        src={customThumbnailUrl || currentBrand?.image || ''}
                        className={clsx({
                            [classes.oneFieldImage]: themeStyle !== BASIC,
                            [classes.basicCollectorImage]: themeStyle === BASIC,
                            [classes.tourBasicCollectorImage]: themeStyle === BASIC && websiteType === TOUR
                        })}
                        style={
                            websiteType === TOUR && themeStyle === BASIC
                                ? { objectFit: customThumbnailViewStyle === 'cover' ? 'cover' : 'contain' }
                                : {}
                        }
                        alt="Custom Record"
                    />
                </div>
            );
    }
}

interface WebsitePreviewProps {
    className?: string;
    style?: CSSProperties;
    websiteType: WebsiteType;
}

const WebsitePreviewV2 = ({
    className = '',
    style = {},
    websiteType,
}: WebsitePreviewProps) => {
    const classes = useStyles();
    const {
        mainColor = '',
        styleType,
        embedYt,
        themeStyle,
        bgType,
        bgValue,
        recordSelected
    } = useContext(WebsiteContext);
    const { mobileView, mStyle, cssCalc } = useScreen();
    const { currentBrand } = useContext(CurrentBrandContext)

    const [playingVinylPlayer, setPlayingVinylPlayer] = useState<boolean>(false);
    const [parentWidth, setParentWidth] = useState<number>(0);

    const mainContainerRef = useRef<HTMLDivElement>(null);

    const mainBoxContrast = lightOrDark(mainColor)
    const isArtwork = bgType === 'artwork';

    const { thumbnail_url } = recordSelected || {}

    useEffect(() => {
        setParentWidth(mainContainerRef.current?.offsetWidth || 0)
    }, [mainContainerRef.current?.offsetWidth, themeStyle])

    const handleVinylPlayerPlayState = () => {
        setPlayingVinylPlayer(!playingVinylPlayer);
    }

    const getVinylPlayerMainHeaderStyle = () => {
        let vinylPlayerMainHeader = {};
        switch (styleType) {
            case "boxes":
                break;
            case "stacked":
                break;
            case "vinylPlayer":
                vinylPlayerMainHeader = {
                    overflow: "hidden"
                }
                break;
        }
        return vinylPlayerMainHeader;
    }

    const renderCoverImage = () => {
        return (
            <div
                className={clsx('backgroundContainerImageCover', classes.backgroundContainerImageCover)}
                style={themeStyle === CLASSIC && isArtwork ?
                    {
                        backgroundImage: `url(${thumbnail_url || currentBrand?.image})`,
                        opacity: 0.6,
                        filter: 'blur(16px)',
                        scale: '1.1',
                    }
                    :
                    {
                        backgroundColor: themeStyle !== CLASSIC ? mainColor : bgValue?.color
                    }
                }
            />
        )
    }

    // NOTE: render()
    return (
        <div
            className={clsx('websitePreviewV2Component', className)}
            style={{ ...mStyle(sty, 'websitePreviewV2Component'), ...style }}
        >
            {renderCoverImage()}
            <div
                ref={mainContainerRef}
                className={mobileView ? classes.websitePreviewComponentMobile : classes.websitePreviewComponentV2}
                style={{
                    maxWidth: themeStyle === CLASSIC ? 331 : '100%',
                    margin: themeStyle === CLASSIC && mobileView ? '4% 0px' : 0,
                    width: themeStyle === CLASSIC && !mobileView ? '80%' : '100%',
                    padding: themeStyle === CLASSIC && !mobileView ? '8% 0px' : 0,
                    top: themeStyle === CLASSIC ? '16px' : 0,
                }}
            >
                <div
                    className={classes.vinylPlayerMainHeader}
                    style={{
                        // height: mobileView ? `calc(100vh - ${WEBSITE_BOTTOM_BAR_HEIGHT}px)` : 'fit-content',
                        height: mobileView ? cssCalc(WEBSITE_BOTTOM_BAR_HEIGHT) : 'fit-content',
                        backgroundColor: mainColor,
                        paddingTop: 0,
                        paddingBottom: mobileView && themeStyle !== CLASSIC ? WEBSITE_BOTTOM_BAR_HEIGHT : '0px',
                        ...getVinylPlayerMainHeaderStyle()
                    }}
                >
                    {(!mobileView && (themeStyle !== FULL_IMAGE && themeStyle !== FULL_VIDEO && themeStyle !== CLASSIC && !embedYt)) && <PhoneHeader />}
                    {websiteType === RECORD_STREAMS ?
                        <MusicSiteHeader
                            fullView={mobileView}
                            handleVinylPlayerPlayState={handleVinylPlayerPlayState}
                            mainBoxContrast={mainBoxContrast}
                            playingVinylPlayer={playingVinylPlayer}
                            parentWidth={parentWidth}
                            websiteType={RECORD_STREAMS}
                        />
                        :
                        <DataCollectorSiteHeader parentWidth={parentWidth} fullView={mobileView} websiteType={websiteType} />
                    }
                    <div className={classes.infoTextContainer}>
                        {((themeStyle !== FULL_IMAGE && themeStyle !== FULL_VIDEO) || embedYt) &&
                            <InfoText
                                mainBoxContrast={mainBoxContrast}
                                websiteType={websiteType}
                                parentWidth={parentWidth}
                            />
                        }
                        {websiteType === RECORD_STREAMS &&
                            <MusicSiteContent mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
                        }
                        {websiteType === DATA_COLLECTOR &&
                            <DataCollectorSiteContent mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
                        }
                        {websiteType === TOUR &&
                            <TourSiteContent mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
                        }
                        {themeStyle !== CLASSIC &&
                            <Footer mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
                        }
                    </div>
                </div>
                {themeStyle === CLASSIC &&
                    <Footer mainBoxContrast={mainBoxContrast} parentWidth={parentWidth} />
                }
            </div>
        </div>
    );
}

const sty: Record<string, CSSProperties> = {
    websitePreviewV2Component: {
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        width: '100%',
        maxWidth: 410,
        maxHeight: 736,
        top: WEBSITE_TOP_BAR_HEIGHT + 16,
        borderRadius: 36,
        overflow: 'hidden',
        aspectRatio: '1/2',
        border: `8px solid ${Colors.greyLight}`,
        alignSelf: 'flex-start',
        overflowY: 'initial',
    },
    websitePreviewV2Component_mobile: {
        position: 'relative',
        border: 'none',
        maxHeight: `calc(100vh - ${WEBSITE_BOTTOM_BAR_HEIGHT}px)`,
        maxWidth: 'auto',
        minHeight: 'auto',
        aspectRatio: 'unset',
        overflow: 'unset',
        top: 0,
        overflowY: 'scroll',
        borderRadius: 0,
    }
}

export default WebsitePreviewV2;