import dayjs from "dayjs";
import Axios from "helpers/Interceptor";
import { getFormattedRecords } from "../MarketingPage/utils";
import { getRecentReleases } from "../MarketingPage/Components/SuperboostModal/api";
import { CurrentBrand } from "types/global";

/**
 * Type for default date options
 */
export type DATE_OPTIONS = 'last-7-days' | 'last-30-days' | 'last-3-months' | 'custom' | 'all-time'

/**
 * Returns a start and end date based on a given range
 * @param range 
 * @returns startDate and endDate, formatted as "YYYY-MM-DD"
 */
export function getDatesByRange(range: DATE_OPTIONS): {
    startDate: string;
    endDate: string;
} {
    const today = dayjs()
    switch (range) {
        case 'last-7-days':
            return {
                startDate: today.subtract(7, 'day').format("YYYY-MM-DD"),
                endDate: today.format("YYYY-MM-DD")
            }

        case 'last-30-days':
            return {
                startDate: today.subtract(30, 'day').format("YYYY-MM-DD"),
                endDate: today.format("YYYY-MM-DD")
            }
        case 'last-3-months':
            return {
                startDate: today.subtract(90, 'day').format("YYYY-MM-DD"),
                endDate: today.format("YYYY-MM-DD")
            }
        case 'custom':
            return {
                startDate: today.subtract(30, 'day').format("YYYY-MM-DD"),
                endDate: today.format("YYYY-MM-DD")
            }
        case 'all-time':
            return {
                startDate: today.subtract(5, 'year').format("YYYY-MM-DD"),
                endDate: today.format("YYYY-MM-DD")
            }
    }
}

/**
 * Calls endpoint to pull fanbase data for a given stat identifier
 * @param props 
 * @returns 
 */
export async function getFanbaseData(props: {
    brandId: string | number;
    platformId: string;
    statIdentifier: string;
    startDate: string;
    endDate: string;
}) {

    const {
        brandId,
        platformId,
        statIdentifier,
        startDate,
        endDate
    } = props

    const url = `/dashboard/brand/${brandId}/fanbase/${platformId}/${statIdentifier}?startDate=${startDate}&endDate=${endDate}`
    return Axios.get(url)
}

export async function getDashboardCampaignsData(props: {
    brandId: string | number;
    startDate: string;
    endDate: string;
    dateModifier?: string;
    filterDays?: number;
}) {
    const {
        brandId,
        startDate,
        endDate,
        dateModifier = 'in_between',
        filterDays = 30
    } = props

    // https://example.com/api/campaigns?dateModifier=in_between&startDate=2023-10-01&endDate=2023-10-07&filterDays=30

    const url = `/campaign/brand/${brandId}?dateModifier=${dateModifier}&startDate=${startDate}&endDate=${endDate}&filterDays=${filterDays}`
    return Axios.get(url)
}

export async function getInstagramData(props: {
    brandId: string | number;
    startDate: string;
    endDate: string;
}) {
    const {
        brandId,
        startDate,
        endDate
    } = props

    const url = `/dashboard/brand/${brandId}/instagram-insights?startDate=${startDate}&endDate=${endDate}&options[posts]=true`
    return Axios.get(url)
}

export async function getSpotifyReleaseData(props: {
    currentBrand: CurrentBrand;
}) {
    const {
        currentBrand
    } = props

    const { data, error } = await getRecentReleases(currentBrand)

    const formattedData = getFormattedRecords({
        recentReleases: data || [],
        releaseType: 'project',
        search: ''
    })

    return formattedData;
}

/**
 * Calls endpoint to pull fanbase data for a given stat identifier
 * @param props 
 * @returns 
 */
export async function getFanReleases(props: {
    brandId: string | number;
    startDate?: string;
    endDate?: string;
}) {

    const {
        brandId,
        startDate,
        endDate
    } = props

    const url = `/brand/${brandId}/metrics/fans/releases?startDate=${startDate}&endDate=${endDate}`
    return Axios.get(url)
}

export function getSmallGraphEmptyData(platformId: string, statIdentifier: string) {
    return {
        platformId,
        data: {
            data: {
                chartData: [],
                growthData: { currentGrowthValue: 0, difference: 0, percentage: null },
                platform: platformId,
                stat: statIdentifier,
            },
            isError: false,
            message: null
        }
    };
}