/* eslint-disable no-throw-literal */
import { Dispatch, SetStateAction } from "react"
import { queryFB } from 'helpers/FB';
import Axios from "helpers/Interceptor";
import { AdAccountPageType, CurrentBrand } from "types/global";
import some from "lodash/some";
import * as Sentry from "@sentry/react";
import { track } from "analytics";
import { getTosStatus } from "../../utils";
import { PreConnectionConfigs } from "../../utils/fbCampaigns";
import { createCampaignAndUpgradePlanCheckout } from "services/symphonyApi/subscriptionService";

export const createPayPalOrder = async (
    brand: CurrentBrand,
    data: Record<string, unknown>
  ) => {
    try {
      const campaignInput = data.campaign_input as { conversionsEnabled: boolean };
      track("Completed Campaign Step - Checkout", {
        type: "increase_link_clicks",
        step: 4,
        name: "confirmation",
        brand_id: brand.id,
        brand_name: brand.name,
        brand_slug: brand.slug,
        payment_method: "paypal",
        conversionsEnabled: Boolean(campaignInput?.conversionsEnabled),
        conversionsSetup: Boolean(brand.conversionsStatus?.conversions_tasks_completed),
      });

      const response = await Axios.post(
        `/campaign/brand/${brand.id}/checkout`,
        data
      );

      if (some([!response, !response.data, !response.data.data])) {
        throw new Error("Error creating PayPal order");
      }

      const order = response.data.data;
      const orderId = order.paypalOrder.id;
      const campaignId = order.campaignId;

      return { orderId, campaignId };
    } catch (error) {
      Sentry.captureException(error);
      console.log("error: ", error);
      return { orderId: null, campaignId: null };
    }
  };

  export const redirectToStripeCheckout = async (
    brand: CurrentBrand,
    data: Record<string, unknown>,
  ) => {
    const campaignInput = data.campaign_input as { conversionsEnabled: boolean };
    track("Completed Campaign Step - Checkout", {
      type: "increase_link_clicks",
      step: 4,
      name: "confirmation",
      brand_id: brand.id,
      brand_name: brand.name,
      brand_slug: brand.slug,
      payment_method: "stripe",
      conversionsEnabled: Boolean(campaignInput?.conversionsEnabled),
      conversionsSetup: Boolean(brand.conversionsStatus?.conversions_tasks_completed),
    });

    return createCampaignAndUpgradePlanCheckout(brand, data);
  };

export const confirmConnectionSettings = async (args: {
    fbAdAccount?: AdAccountPageType;
    setPreconnectConfigurations: Dispatch<SetStateAction<PreConnectionConfigs | null>>;
}) => {
    const {
        fbAdAccount,
        setPreconnectConfigurations
    } = args;

    if (!fbAdAccount) return;

    const results = await queryFB(`/${fbAdAccount?.id}`, {
        params: {
            fields: 'tos_accepted,offsite_pixels_tos_accepted,user_tos_accepted,business'
        }
    })

    const toDo = getTosStatus(fbAdAccount, results)
    setPreconnectConfigurations(toDo)
}
