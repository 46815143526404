import "./App.css";
import InitializeApp from './layouts/InitializeApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { analyticsInitialize, handlePageChange } from "./analytics";
import { LicenseInfo } from '@mui/x-license-pro';
import Hotjar from '@hotjar/browser'
import { useEffect } from "react";
import CurrentUserProvider from "Hooks/CurrentUserContext";
import { CurrentTeamProvider } from "Hooks/CurrentTeamContext";
import CurrentBrandProvider from "Hooks/CurrentBrandContext";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID)
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION)
const isProd = process.env.NODE_ENV === 'production'

analyticsInitialize()
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE as string);

const history = createBrowserHistory();

// Subscribe to history changes
history.listen(() => {
  handlePageChange();
});

const App = () => {
  useEffect(() => {
    if (isProd) Hotjar.init(hotjarId, hotjarVersion)
  }, [])

  return (
    <Router history={history}>
      <CurrentUserProvider>
        <CurrentBrandProvider>
          <CurrentTeamProvider>
            <InitializeApp />
            <ToastContainer />
          </CurrentTeamProvider>
        </CurrentBrandProvider>
      </CurrentUserProvider>
    </Router>
  );
};

export default App;
