import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Card, Container, StyledLabel } from "styles/shared";
import Colors from "modules/Colors";

interface RoundedCardProps {
    title?: string;
    description?: string;
    children?: React.ReactNode;
}

export default function RoundedCard(props: RoundedCardProps) {
    const {
        title,
        description,
        children
    } = props

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(769))

    return (
        <Grid className={isMobile ? "mb-0" : "mb-4"}>

            <Card  showBorder={!isMobile}>
                <Container display="flex" gap="16px"  flexDirection="column" >
                    <Container gap="8px" display="flex" flexDirection="column">

                        {title && <StyledLabel fontSize={18} fontWeight={600}>
                            {title}
                        </StyledLabel>}
                        {description && (<StyledLabel color={Colors.greyDark} fontSize={16} marginTop="12px">
                            {description}
                        </StyledLabel>)}
                    </Container>
                    <Grid>
                        {children}
                    </Grid>
                </Container>
            </Card>
        </Grid>

    )
}