import { useContext, useMemo, useState } from 'react';
import {
  BackArrowActionsContainerMobile,
  OverviewAction,
  OverviewActionsContainer,
  OverviewBadgesContainer,
  OverviewCampaignCardContainer,
  OverviewCampaignCardContainerMobile,
  OverviewInfoContainer,
  OverviewText,
  OverviewTitleContainer,
  SongName,
  useStyles,
} from './styles';
import { ReactComponent as PauseIcon } from 'assets/images/pause-purple.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play-purple.svg';
import { ReactComponent as ContextualMenuIcon } from 'assets/images/contextualMenu.svg';
import CustomMenu, { MenuElement } from 'components/shareable/CustomMenu/index';
import { ReactComponent as DuplicateIcon } from 'assets/images/duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as MoneyIcon } from 'assets/images/money.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as BackArrowIcon } from 'assets/images/BackArrow.svg';
import { ReactComponent as GlobeIcon } from 'assets/images/campaignDetails/globe.svg';
import { daysLeftCalculator } from 'helpers/General';
import BadgeCampaignTypeContainer from '../BadgeCampaignTypeContainer/BadgeCampaignTypeContainer';
import CampaignStatusBadge from '../CampaignStatusBadge';
import LoaderV2 from 'components/Loader/LoaderV2';
import { UpgradeModalContext } from 'Hooks/UpgradeModalContext';
import ViewSetupOptionsModal from '../Modals/ViewSetupOptionsModal';
import { Settings } from '@material-ui/icons';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import useScreen from 'Hooks/useScreen';
import { getCampaignName } from 'modules/Utils';
import CampaignImage from 'components/shareable/CampaignImage';

interface Props {
  className?: string;
  goBack: () => void;
  campaignDetails: any;

  // optional
  campaignName?: string;
  isIGCampaign?: boolean;
  thumbnail?: string;
  campaignStatus?: string;
  campaignStatusLabel?: string;
  isLoading?: boolean;

  downloadReport?: () => void;
  downloadingReport?: boolean;
  disableCampaignUpdates?: boolean;

  // required for IG + FB campaigns
  handleOpenEditBudgetModal?: () => void;
  handleOpenEditEndDateModal?: () => void;
  handleOpenPauseModal?: () => void;
  handleSetMarketsTab?: () => void;

  // avail on all IG + video view campaigns 
  handleOpenDuplicateModal?: () => void;
  handleOpenRenameCampaignModal?: () => void;

  showSetupOptions?: boolean;
}

const STATUSES_TO_SHOW_PAUSE_BUTTON = [
  'active',
  'paused',
  'in_review',
  'live_with_no_stats',
  'live_with_issues',
  'anticipated_puase',
  'to_retry',
];

const STATUSES_TO_SHOW_DOWNLOAD_BUTTON = [
  'active',
  'paused',
  'live_with_issues',
  'finished'
];

const OverviewCampaignCard = ({
  className = '',
  isLoading,
  campaignName = '',
  goBack,
  thumbnail,
  campaignDetails,
  campaignStatus = 'pending',
  campaignStatusLabel,
  disableCampaignUpdates,
  handleOpenEditBudgetModal,
  handleOpenEditEndDateModal,
  handleOpenPauseModal,
  handleOpenDuplicateModal,
  handleSetMarketsTab,
  downloadReport,
  downloadingReport,
  handleOpenRenameCampaignModal,
  showSetupOptions,
  isIGCampaign,
}: Props) => {
  const classes = useStyles();
  const { largeView } = useScreen();
  const { isProBrand, isBrandAdmin } = useContext(CurrentBrandContext);
  const { handleOpenModal } = useContext(UpgradeModalContext);

  const [openSetupModal, setOpenSetupModal] = useState(false);

  const { campaign_metadata } = campaignDetails;
  const { content, campaign_type, friendlyName, campaign_state, creative } = campaign_metadata;
  const {
    thumbnail_url,
    type: contentType
  } = content;

  const active: boolean = campaignStatus !== 'finished';
  const pause: boolean = campaignStatus === 'paused' || campaignStatus === 'anticipated_puase';
  const labelPause = pause ? 'Resume' : 'Pause';

  let setupDetails: MenuElement | undefined = undefined;

  if (isIGCampaign && isBrandAdmin) {
    setupDetails = {
      icon: <Settings />,
      text: 'View setup details',
      show: true,
      onClick: () => setOpenSetupModal(true)
    }
  }
  const getMenuItems = (): MenuElement[] => {
    const items: MenuElement[] = []
    // Check if campaign updates are disabled,
    // if so don't show anything that edits the campaign's 
    // budget, end date, or locations, etc.

    if (disableCampaignUpdates) {
      if (handleOpenRenameCampaignModal) {
        items.push({
          icon: <EditIcon className={classes.menuItemSvgGray} />,
          text: 'Rename campaign',
          show: !!handleOpenRenameCampaignModal,
          onClick: handleOpenRenameCampaignModal
        })
      }

      if (handleOpenDuplicateModal) {
        items.push(
          {
            icon: <DuplicateIcon />,
            text: 'Duplicate campaign',
            show: true,
            onClick: handleOpenDuplicateModal
          }
        )
      }

      return items
    }

    if (handleOpenEditBudgetModal) {
      items.push({
        icon: <MoneyIcon />,
        text: 'Edit budget',
        show: active && !!handleOpenEditBudgetModal,
        onClick: handleOpenEditBudgetModal,
        isProOnly: true
      })
    }

    if (handleOpenEditEndDateModal) {
      items.push({
        icon: <CalendarIcon color='black' />,
        text: 'Edit end date',
        show: active && !!handleOpenEditEndDateModal,
        onClick: handleOpenEditEndDateModal,
        isProOnly: true
      })
    }

    if (handleSetMarketsTab) {
      items.push({
        icon: <GlobeIcon className={classes.menuItemSvgGray} />,
        text: 'Edit locations',
        show: !!handleSetMarketsTab,
        onClick: handleSetMarketsTab,
        isProOnly: true
      })
    }

    if (handleOpenRenameCampaignModal) {
      items.push({
        icon: <EditIcon className={classes.menuItemSvgGray} />,
        text: 'Rename campaign',
        show: true,
        onClick: handleOpenRenameCampaignModal,
        isProOnly: true
      });
    }

    if (handleOpenDuplicateModal) {
      items.push({
        icon: <DuplicateIcon />,
        text: 'Duplicate campaign',
        show: true,
        onClick: handleOpenDuplicateModal
      });
    }

    if (showSetupOptions && setupDetails) {
      items.push(setupDetails);
    }

    return items;
  };

  const menuItems: MenuElement[] = getMenuItems()

  const { endDate } = campaign_metadata;
  const daysLeft: number = useMemo(
    () => daysLeftCalculator(new Date().toString(), endDate),
    [endDate]
  );

  const showDownload = (status: string) => (
    !isLoading &&
    Boolean(downloadReport) &&
    STATUSES_TO_SHOW_DOWNLOAD_BUTTON.includes(status))

  const showDownloadButton: boolean = showDownload(campaignStatus);

  const showPause = (status: string) => (
    !isLoading &&
    Boolean(handleOpenPauseModal) &&
    STATUSES_TO_SHOW_PAUSE_BUTTON.includes(status) &&
    daysLeft >= 0)
  const showPauseButton: boolean = showPause(campaignStatus);

  const renderDownloadButton = () => {
    if (!downloadReport || !isBrandAdmin) {
      return null
    }

    if (downloadingReport) {
      return (
        <OverviewAction>
          Downloading
          <div className='ml-1'>
            <LoaderV2 height={16} width={16} />
          </div>
        </OverviewAction>
      )
    } else {
      return (
        <OverviewAction
          onClick={() => {
            if (!isProBrand) {
              handleOpenModal({ source: "IG Campaign Details - Download Report" })
              return
            } else {
              downloadReport()
            }
          }}
        >
          Download Report
          <PlayIcon className={classes.actionIcon} />
        </OverviewAction>
      )
    }
  }

  const showMoreOptions = (status: string) =>
    !isLoading && (status !== 'to_retry' && status !== 'anticipated_puase');

  const showMoreOptionsButton: boolean = showMoreOptions(campaignStatus);

  const getMenuItemsByStatus = (status: string) => {
    if (
      (status === 'active' && daysLeft <= 0) ||
      status === 'live_with_issues' ||
      campaignStatusLabel === 'FINISHED'
    ) {
      const options = []
      const renameItem = menuItems.find(
        (item) => item.text === 'Rename campaign'
      );

      if (renameItem) options.push(renameItem)

      const duplicateItem = menuItems.find(
        (item) => item.text === 'Duplicate campaign'
      );

      if (duplicateItem) options.push(duplicateItem)

      if (setupDetails) {
        options.push(setupDetails)

      }

      return options
    }

    return menuItems;
  };

  let internalModals = null;
  if (!isLoading && campaignDetails) {
    internalModals = (
      <ViewSetupOptionsModal
        key="view-setup-options-modal"
        campaignDetails={campaignDetails}
        closeModal={() => {
          setOpenSetupModal(false)
        }}
        open={openSetupModal}
      />
    )
  }

  let defaultCampaignName = getCampaignName(campaign_metadata, campaignName)

  if (largeView) {
    return (
      <>
        <OverviewCampaignCardContainerMobile className={`overviewCampaignCardComponent ${className}`}>
          <BackArrowActionsContainerMobile>
            <BackArrowIcon onClick={goBack} style={{cursor: 'pointer'}} />
            <OverviewActionsContainer>
              {showPauseButton && isBrandAdmin && (
                <OverviewAction onClick={handleOpenPauseModal}>
                  {labelPause}
                  {pause ? (
                    <PlayIcon className={classes.actionIcon} />
                  ) : (
                    <PauseIcon className={classes.actionIcon} />
                  )}
                </OverviewAction>
              )}
              {showMoreOptionsButton && isBrandAdmin && (
                <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
                  <OverviewAction>
                    Edit <ContextualMenuIcon className={classes.actionIcon} />
                  </OverviewAction>
                </CustomMenu>
              )}
            </OverviewActionsContainer>
          </BackArrowActionsContainerMobile>
          <OverviewTitleContainer>
            <CampaignImage
              campaign_metadata={campaign_metadata}
              thumbnail={thumbnail}
            />
            <OverviewInfoContainer>
              <OverviewText>Overview</OverviewText>
              <SongName>{defaultCampaignName}</SongName>
              <OverviewBadgesContainer>
                <BadgeCampaignTypeContainer campaignType={campaign_type} />
                {!isLoading && (
                  <CampaignStatusBadge
                    campaignStatus={campaignStatus}
                    campaignStatusLabel={campaignStatusLabel}
                  />
                )}
              </OverviewBadgesContainer>
            </OverviewInfoContainer>
          </OverviewTitleContainer>
        </OverviewCampaignCardContainerMobile>
        {internalModals}
      </>
    );
  }

  // NOTE: render function
  return (
    <>
      <OverviewCampaignCardContainer className={`overviewCampaignCardComponent ${className}`}>
        <BackArrowIcon onClick={goBack} style={{cursor: 'pointer'}} />
        <OverviewTitleContainer>
          <CampaignImage
            campaign_metadata={campaign_metadata}
            thumbnail={thumbnail}
          />
          <OverviewInfoContainer>
            <OverviewText>Overview</OverviewText>
            <SongName>{defaultCampaignName}</SongName>
            <OverviewBadgesContainer>
              <BadgeCampaignTypeContainer campaignType={campaign_type} />
              {!isLoading && (
                <CampaignStatusBadge
                  campaignStatus={campaignStatus}
                  campaignStatusLabel={campaignStatusLabel}
                />
              )}
            </OverviewBadgesContainer>
          </OverviewInfoContainer>
        </OverviewTitleContainer>
        <OverviewActionsContainer>
          {showPauseButton && isBrandAdmin &&
            <OverviewAction onClick={handleOpenPauseModal}>
              {labelPause}
              {pause ?
                <PlayIcon className={classes.actionIcon} />
                :
                <PauseIcon className={classes.actionIcon} />
              }
            </OverviewAction>
          }
          {showDownloadButton && isBrandAdmin &&
            renderDownloadButton()
          }
          {showMoreOptionsButton && isBrandAdmin &&
            <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
              <OverviewAction>
                Edit <ContextualMenuIcon className={classes.actionIcon} />
              </OverviewAction>
            </CustomMenu>
          }
        </OverviewActionsContainer>
      </OverviewCampaignCardContainer>
      {internalModals}
    </>
  );
};

export default OverviewCampaignCard;
