import { ChangeEvent, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles from "./styles";
import { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import { getAdsComission } from "modules/Utils";
import { clsx } from 'modules/Utils';
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { track } from "analytics";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";

const ERROR_COLOR = "rgba(239, 68, 68, 1)";

interface Props {
  budgetValue: number;
  handleChangeInputText?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const BudgetCard = ({
  budgetValue,
  handleChangeInputText,
}: Props) => {
  const classes = useStyles();
  const { mobileView } = useScreen()
  const { isProBrand } = useContext(CurrentBrandContext);

  const adsComission = getAdsComission(isProBrand);
  const MIN_LIFETIME_BUDGET = 50

  const { handleOpenModal } = useContext(UpgradeModalContext);

  return (
    <Card borderRadius={mobileView ? undefined : "12px"}>
      <Grid container direction="column">
        <StyledLabel fontSize={18} fontWeight={600}>
          Budget
        </StyledLabel>
        <StyledLabel color="#707070" fontSize={16} marginTop="12px">
          Enter the total budget and dates you want to run your campaign.
        </StyledLabel>
        <Grid>
          <Grid
            className={clsx(
              classes.overall,
              isProBrand && classes.noBottomBorder,
              isProBrand && classes.paddingBottomThemeSpacing
            )}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container direction="column">
                <StyledLabel fontSize={18} fontWeight={600}>
                  Overall Budget
                </StyledLabel>

              </Grid>
            </Grid>
            <TextField
              className={classes.textField}
              variant="outlined"
              value={budgetValue}
              onChange={handleChangeInputText}
              inputProps={{ className: classes.inputProps }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />

          </Grid>
          {budgetValue < MIN_LIFETIME_BUDGET && (
            <StyledLabel color={ERROR_COLOR} paddingTop="8px">
              Please enter a minimum budget of ${MIN_LIFETIME_BUDGET}.
            </StyledLabel>
          )}
          {!isProBrand && (
            <Grid
              className={clsx(
                classes.marketing,
                isProBrand && classes.noBottomBorder,
                isProBrand && classes.noPaddingBottom
              )}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledLabel fontWeight={600} fontSize={16}>
                Marketing Budget
              </StyledLabel>
              <StyledLabel fontWeight={400} fontSize={16}>
                ${(budgetValue - budgetValue * adsComission).toFixed(2)}
              </StyledLabel>
            </Grid>
          )}
          {!isProBrand && (
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid className={classes.automationFee}>
                <StyledLabel fontWeight={600} fontSize={16}>
                  Automation Fee
                </StyledLabel>
                <StyledLabel color="#707070" fontSize={16}>
                  {adsComission * 100}% of your budget will be charged as an
                  automation fee.
                </StyledLabel>
                <button className="text-sm text-left text-yellow-600 underline" onClick={() => {
                  track("Clicked Upgrade on Budget Page", {
                    type: 'increase_video_views'
                  })
                  handleOpenModal()
                }}>
                  Upgrade to Symphony Pro to remove automation fee.
                </button>
              </Grid>
              <StyledLabel fontWeight={400} fontSize={16}>
                ${(budgetValue * adsComission).toFixed(2)}
              </StyledLabel>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default BudgetCard;
