import { makeStyles, Tab, Tabs, Theme, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    svgRotated: {
        transform: 'rotate(-90deg)',
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        color: '#707070',
    },
    svgEnabledContainer: {
        '& :hover': {
            color: '#8800ff',
        },
    },
    svgDisabledContainer: {
        '& :hover': {
            color: '#b2b2b2',
        },
    },
    svgGray: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        color: '#707070',
    },
    svgDisabled: {
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        color: '#edecf2',
    },
    datagridRoot: {
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#edecf252',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'DIN',
            fontSize: '16px',
            lineHeight: '19px',
            fontWeight: 400,
            color: '#707070',
        },
    },
}));


export const CustomTabs = withStyles({
    indicator: {
        backgroundColor: '#8800FF',
    },
})(Tabs);

export const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 'auto', // Set minWidth to auto
        padding: '6px 20px', // Specify the padding as per your need
        // other styles
        //   color: '#8800FF',
        '&$selected': {
            color: '#8800FF', // The color you want for the selected tab
        },
    },
    selected: {},
}))(Tab);

export default useStyles;
