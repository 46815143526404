import { SaveDraftChipContainer, StyledLabel } from "../../styles"
import { useMediaQuery, useTheme } from "@material-ui/core"

interface Props {
  savingChanges?: boolean
  onClick?: () => void
}

const SaveDraftsChip = ({
  savingChanges,
  onClick,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down(601))

  return (
    <SaveDraftChipContainer
      {...{ onClick }}
      flexGrow={!isResponsive ? "1" : undefined}
    >
      <StyledLabel
        cursor="pointer"
        width={`${savingChanges ? 110 : 100}px`}
        borderRadius="40px"
        backgroundColor="rgba(255, 255, 255, 0.08)"
        padding="4px 8px"
        margin={isResponsive ? "4px 0px" : "4px 24px"}
        fontSize={12}
        fontWeight={400}
        color="rgba(255, 255, 255, 0.72)"
      >
        {savingChanges ? "Saving changes..." : "Saved on drafts"}
      </StyledLabel>
    </SaveDraftChipContainer>
  )
}

export default SaveDraftsChip
