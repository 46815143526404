import { Switch } from '@material-ui/core';
import { Body2 } from 'components/shareable/Typography';
import Colors from "modules/Colors";
import { ReactComponent as LocationPin } from "assets/images/campaignDetails/location.svg";
import { useStyles } from './styles';
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import Intercom from "helpers/Intercom";
import SwitchSymphony from 'components/shareable/SwitchSymphony';

interface Props {
  isLocalRelease: boolean;
  toggleSwitch: () => void;
}

const ReleaseTypeSwitch = ({
  isLocalRelease,
  toggleSwitch,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.localReleaseContainer}>
      <div className={classes.globalIconContainer}>
        <LocationPin className={classes.globalIcon} />
      </div>
      <div >
        <div className='flex gap-2 items-center'>
          <Body2 color={Colors.textColor}>Realtime Release</Body2>
          <QuestionMarkIcon height={16} width={16} className='cursor-pointer' onClick={Intercom.openRealTimeReleaseArticle}/>
        </div>
        <div>
          <Body2>
            Automatically convert your pre-save into a "Listen Now" music site for fans where your song / album has already released.
          </Body2>
        </div>
      </div>
      <SwitchSymphony
        checked={isLocalRelease}
        onChange={toggleSwitch}
      />
    </div>
  );
};

export default ReleaseTypeSwitch;