import { useState } from "react";


export default function VinylPlayer(props: {
    imageUrl: string,
    playing: boolean
}) {
    const {
        imageUrl,
        playing
    } = props

    const [artworkHidden, setArtworkHidden] = useState<boolean>(false)


    const recordSpinningAnimation = {
        animation: 'slowRotate 10s linear infinite',
    }

    const imageStyleShown = {
        filter: `drop-shadow(rgba(0, 0, 0, 0.5) 0px 6px 5px)`,
        transition: `transform 0.3s ease`,
        transform: `translateY(${playing ? -50 : 0}%)`, // NOTE: this used to be 90
        borderRadius: 8,
    }

    return (
        <div
            className="albumRecordElementStyle relative"
            onClick={() => {
                setArtworkHidden(!artworkHidden)
            }}
        >
            <img
                style={imageStyleShown}
                src={imageUrl}
                className="relative z-50 mainSongThumbnail w-full h-full  m-auto"
                alt="album artwork"
            />
            <div className="
            flex items-center justify-center
            absolute w-full h-full left-1/2 transform-gpu -translate-x-1/2" style={{ top: '15%' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213 213" width="92%" height="92%" fill="none"
                    style={recordSpinningAnimation}>
                    <mask id="b" fill="#fff">
                        <path fillRule="evenodd"
                            d="M106.301 212.602c58.708 0 106.301-47.593 106.301-106.301C212.602 47.592 165.009 0 106.301 0 47.592 0 0 47.593 0 106.301s47.593 106.301 106.301 106.301Zm0-90.95c8.478 0 15.351-6.873 15.351-15.351 0-8.479-6.873-15.352-15.351-15.352-8.479 0-15.352 6.873-15.352 15.352 0 8.478 6.873 15.351 15.352 15.351Z"
                            clipRule="evenodd" />
                    </mask>
                    <path fill="url(#a)" fillRule="evenodd"
                        d="M106.301 212.602c58.708 0 106.301-47.593 106.301-106.301C212.602 47.592 165.009 0 106.301 0 47.592 0 0 47.593 0 106.301s47.593 106.301 106.301 106.301Zm0-90.95c8.478 0 15.351-6.873 15.351-15.351 0-8.479-6.873-15.352-15.351-15.352-8.479 0-15.352 6.873-15.352 15.352 0 8.478 6.873 15.351 15.352 15.351Z"
                        clipRule="evenodd" />
                    <path fill="#fff" fillOpacity=".26"
                        d="M211.642 106.301c0 58.178-47.163 105.341-105.341 105.341v1.919c59.238 0 107.26-48.022 107.26-107.26h-1.919ZM106.301.959c58.178 0 105.341 47.163 105.341 105.342h1.919c0-59.238-48.022-107.26-107.26-107.26V.959ZM.959 106.301C.96 48.122 48.122.959 106.301.959V-.959C47.063-.96-.959 47.063-.959 106.3H.959Zm105.342 105.341C48.122 211.642.959 164.479.959 106.301H-.959c0 59.238 48.022 107.26 107.26 107.26v-1.919Zm14.392-105.341c0 7.948-6.444 14.392-14.392 14.392v1.919c9.008 0 16.311-7.303 16.311-16.311h-1.919Zm-14.392-14.392c7.948 0 14.392 6.443 14.392 14.392h1.919c0-9.008-7.303-16.311-16.311-16.311v1.919ZM91.909 106.3c0-7.949 6.443-14.392 14.392-14.392v-1.92c-9.008 0-16.311 7.303-16.311 16.312h1.919Zm14.392 14.392c-7.949 0-14.392-6.444-14.392-14.392h-1.92c0 9.008 7.303 16.311 16.312 16.311v-1.919Z"
                        mask="url(#b)" />
                    <path fill="url(#c)" fillRule="evenodd"
                        d="M106.301 58.327c-26.495 0-47.974 21.479-47.974 47.974 0 26.495 21.479 47.973 47.974 47.973 26.495 0 47.973-21.478 47.973-47.973 0-26.495-21.478-47.974-47.973-47.974Zm-.005 63.054c8.479 0 15.352-6.873 15.352-15.351 0-8.478-6.873-15.352-15.352-15.352-8.478 0-15.351 6.874-15.351 15.352 0 8.478 6.873 15.351 15.351 15.351Z"
                        clipRule="evenodd" />
                    <circle cx="106.301" cy="106.301" r="97.276" stroke="#fff" strokeOpacity=".17" strokeWidth=".48" />
                    <circle cx="106.301" cy="106.301" r="85.808" stroke="#fff" strokeOpacity=".17" strokeWidth=".48" />
                    <circle cx="106.301" cy="106.301" r="75.248" stroke="#fff" strokeOpacity=".17" strokeWidth=".48" />
                    <circle cx="106.301" cy="106.301" r="59.844" stroke="#fff" strokeOpacity=".17" strokeWidth=".48" />
                    <defs>
                        <radialGradient id="a" cx="0" cy="0" r="1" gradientTransform="rotate(90 0 106.301) scale(106.301)"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#5B5B5B" />
                            <stop offset="1" stop-color="#0D0D0D" />
                        </radialGradient>
                        <pattern id="c" width="1" height="1" patternContentUnits="objectBoundingBox">
                            <use xlinkHref="#d" transform="matrix(.0016 0 0 .0017 -.015 -.04)" />
                        </pattern>
                        <image id="d" width="630" height="630" href={imageUrl} />
                    </defs>
                </svg>
            </div>
        </div>
    )
}