import {
  CreatedCampaign,
  CurrentBrand,
  DuplicateSongAdsRecord as IncreaseMusicStreamsRecord,
  Release,
} from "types/global"
import Axios from "helpers/Interceptor"

export const getRecentReleases = async (brand: CurrentBrand | undefined) => {
  if (!brand) {
    return { data: null, error: null }
  }

  try {
    const response = await Axios.get(`/brand-content/${brand.id}`)
    const data = response.data.data as Release[]
    const releases = data.filter(({ content_metadata }) => {
      if (content_metadata.type === 'project') return true
      if (content_metadata.type === 'record' && content_metadata?.presave?.saved_at && content_metadata?.spotify_id) return true
      return false
    })
    return { data: releases, error: null }
  } catch (error) {
    console.log("Could not get recent releases: ", error)
    return { data: null, error }
  }
}

export const duplicateSongAdsCampaign = async ({ campaign, recordSelected }: {
  campaign: CreatedCampaign,
  recordSelected: IncreaseMusicStreamsRecord | null,
}) => {
  try {
    const originalRecord = campaign?.campaign_metadata?.content as Record<string, unknown> | undefined
    const isSameRecord = (originalRecord?.slug === recordSelected?.slug)
    const record = isSameRecord ? undefined : recordSelected

    const response = await Axios.put(`campaign/${campaign.id}/duplicate`, { projectData: record })
    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error when duplicating increase music streams campaign: ", error)
    return { data: null, error }
  }
}

export const duplicateLinkClicksCampaign = async ({ campaign, linkSelected }: {
  campaign: CreatedCampaign,
  linkSelected?: string,
}) => {
  try {
    const originalLink = campaign?.campaign_metadata?.content as Record<string, unknown> | undefined
    const isSameLink = (originalLink?.link === linkSelected)
    const link = isSameLink ? undefined : linkSelected

    const response = await Axios.put(`campaign/${campaign.id}/duplicate`, { projectData: link })
    const data = response.data.data

    return { data, error: null}
  } catch (error) {
    console.log("error when duplicating link clicks campaign: ", error)
    return { data: null, error }
  }
}

export const duplicateBoostInstagramCampaign = async ({campaign, isSameProject }: {
  campaign: CreatedCampaign,
  isSameProject?: boolean,
}) => {
  try {
    const response = await Axios.put(`campaign/${campaign.id}/duplicate`, {
      projectData: !isSameProject
    })
    const data = response.data.data

    return { data, error: null}
  } catch (error) {
    console.log("error when duplicating boost instagram campaign: ", error)
    return { data: null, error }
  }
}
