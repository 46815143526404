import { CSSProperties, useContext, useState } from "react";
import DialogPaper from "components/shareable/DialogPaper";
import TertiaryButton from "components/shareable/TertiaryButton";
import { Body1 } from "components/shareable/Typography";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import SharedMusicViewSelector from "pages/post-auth/MarketingPage/Components/SuperboostModal/Components/shared-music-selector-view";
import Colors from "modules/Colors";
import { forceForeverSaves } from "../../api";
import { AlertContext } from "Hooks/AlertContext";
import LoadingLottie from "components/Loader/LoadingLottie";

export const AdminContainerForceForeverSaves = () => {
  const { mobileView } = useScreen();
  const [openModal, setOpenModal] = useState(false);

  const styles = sty(mobileView);

  return (
    <div
      style={styles.cardContainer}
      className="AdminContainerForceForeverSaves-Container"
    >
      <Body1 color={Colors.greyDark}>ADMIN:</Body1>
      <TertiaryButton
        text="Run Forever Saves Manually"
        onClick={() => setOpenModal(true)}
      />
      <ForceForeverSavesRecordSelector
        closeModal={() => setOpenModal(false)}
        open={openModal}
      />
    </div>
  );
};

interface ForceForeverSavesRecordSelectorProps {
  closeModal: () => void;
  open: boolean;
}

const ForceForeverSavesRecordSelector = ({
  closeModal,
  open,
}: ForceForeverSavesRecordSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useContext(AlertContext);

  const { currentBrand } = useContext(CurrentBrandContext);

  const handleChangeRecord = async (_record: string, slug: string | null) => {
    console.log('🚦 ~ handleChangeRecord ~ slug:', {
      slug, _record
    });
    if (!slug) return;
    setLoading(true);

    try {
      await forceForeverSaves(slug, currentBrand?.slug || "");
      showSnackbar("Job added to queue", {
        severity: "success",
      });
      closeModal();
    } catch (error) {
      console.error("🚦 ~ handleChangeRecord ~ error:", error);
      showSnackbar("Error adding job to queue, check logs", {
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!currentBrand) return null;

  return (
    <DialogPaper open={open}>
      <>
        <HeaderModal
          title="ADMIN: Run Forever Saves Manually"
          subtitle="Select a record to force forever fan saves"
          closeModal={closeModal}
        />
        {loading ? (
          <LoadingLottie />
        ) : (
          <SharedMusicViewSelector
            isSpotifyConnected={Boolean(
              currentBrand?.connections?.spotify_artist_page
            )}
            handleChangeRecord={handleChangeRecord}
            options={["single", "project"]}
          />
        )}
      </>
    </DialogPaper>
  );
};

const sty = (mobileView: boolean) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: Colors.PAPER_CONTAINER,
    borderRadius: "8px",
    padding: mobileView ? "16px 24px" : "24px 40px",
    gap: "8px",
    width: "100%",
  } as CSSProperties,
});
