import { makeStyles } from '@material-ui/core/styles';
import Colors from 'modules/Colors';
import Sty from 'modules/Sty';
import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';

const {
  PAPER_LIGHT_GRAY_CONTAINER,
  PAPER_SUCCESS_CONTAINER,
} = Colors;

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: Sty.border,
    padding: '12px',
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
  greenIcon: {
    '& path': {
      strokeWidth: '1.3',
      stroke: `${Colors.green} !important`,
    },
  },
  smallIcon: {
    '& svg': {
      height: '12px !important',
      width: '12px !important',
    },
  },
  thumbnail: {
    minWidth: '40px',
    minHeight: '40px',
    maxWidth: '40px',
    maxHeight: '40px',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  greenContainer: {
    display: 'flex',
    gap: '8px',
    height: '39px',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '48px',
    backgroundColor: PAPER_SUCCESS_CONTAINER,
  },
  descriptionContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    [MEDIA_BREAKPOINTS.largeView]: {
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  warnContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: Sty.border,
    padding: '12px',
    backgroundColor: Colors.PAPER_WARNING_CONTAINER,
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
  errorContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: Sty.border,
    padding: '12px',
    backgroundColor: Colors.PAPER_ERROR_CONTAINER,
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
    },
  },
});

interface ConnectionIconProps {
  grayedOut?: boolean;
  backgroundColor?: string;
  padding?: string;
}

export const ConnectionIcon = styled.div<ConnectionIconProps>`
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ backgroundColor, grayedOut }) => grayedOut ? PAPER_LIGHT_GRAY_CONTAINER : backgroundColor};
  padding: ${({ padding }) => padding};
  & path:first-of-type {
    fill: ${({ grayedOut }) => grayedOut && Colors.grey};
  }
`;
