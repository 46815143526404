import Colors from "modules/Colors"
import styled from "styled-components"
import { ContainerProps } from "styles/shared"

interface ButtonContainerProps {
  loading?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  background-color: ${Colors.PAPER_ERROR_CONTAINER};
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const ButtonContainer = styled.div<ButtonContainerProps>`
  pointer-events: ${({ loading }) => (loading ? "none" : undefined)};
`
