import { useContext, useState } from "react";
import useScreen from "Hooks/useScreen";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import DialogPaper from "components/shareable/DialogPaper";
import { TeamMember, TeamPricesData } from "types/global";
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import { useStyles } from "../AddUserModal/styles";
import { Divider } from "@material-ui/core";
import { Banner } from "components/shareable/Banner";
import PrimaryButton from "components/shareable/PrimaryButton";
import gravatar from "gravatar";
import Avatar from '@mui/material/Avatar';
import { Body2, Subtitle1 } from "components/shareable/Typography";
import { ArtistsList } from "../ArtistsList";
import { removeOrganizationMember } from "services/symphonyApi/organizationService";
import { TOAST_ERROR_OPTIONS, TOAST_SUCCESS_OPTIONS } from "../../utils"
import { toast } from "react-toastify"
import { TEAM_PLAN_INCLUDED_SEATS } from "modules/Const"
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import Colors from "modules/Colors";

interface RemoveMemberModalProps {
  open: boolean;
  member: TeamMember;
  closeModal: () => void;
  teamPrices?: TeamPricesData;
}

const RemoveMemberModal = ({
  open,
  member,
  closeModal,
  teamPrices,
}: RemoveMemberModalProps) => {
  const classes = useStyles()
  const { mobileView } = useScreen();
  const { teamMembers } = useCurrentTeam();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { spoofedUserId } = useContext(CurrentUserContext);
  const onConfirmDeletion = async () => {
    try {
      setIsLoading(true);
      await removeOrganizationMember(member.id, { spoofedUserId: spoofedUserId ?? undefined });
      toast.success("Member removed from the team", TOAST_SUCCESS_OPTIONS)
      closeModal();
    } catch (error) {
      toast.error("Something went wrong removing the member", TOAST_ERROR_OPTIONS)
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  let memberCountAfterUpdate = teamMembers.length - 1;

  return (
    <DialogPaper
      open={open}
      fullScreen={mobileView}
      dialogStyle={{
        margin: mobileView ? 0 : 'auto 16px',
        borderRadius: mobileView ? 0 : 8,
        maxWidth: 559,
      }}
      containerStyle={{ margin: mobileView ? 0 : 'auto' }}
    >
      <div className="flex flex-col gap-8 p-">
        <HeaderModal
          closeModal={closeModal}
          title="Remove member"
          subtitle='Are you sure you want to remove this member?'
          HeaderIcon={<UserIcon width={32} height={32} className={classes.userIcon} />}
        />
        <div className="flex gap-4 items-center">
          <Avatar src={gravatar.url(member.email, { protocol: 'https', d: 'retro' })} sx={{ width: 32, height: 32 }} />
          <div className="flex flex-col gap-1">
            <Body2 color={Colors.textColor}>{member.name ? `${member.name} ${member?.lastName || ''}` : member.email}</Body2>
            {member.name && <Body2>{member.email}</Body2>}
          </div>
        </div>
        <Divider />
        <div>
          <Body2 color={Colors.textColor}>Linked to:</Body2>
          <ArtistsList artists={member.artists} canEdit={false} />
        </div>
        {memberCountAfterUpdate < TEAM_PLAN_INCLUDED_SEATS ? (
          <Banner variant="accent" classes="flex flex-col gap-1 p-4">
            <Subtitle1>This action will free up a member space</Subtitle1>
            <Body2>Removing this member will free up 1 of your {TEAM_PLAN_INCLUDED_SEATS} spaces, so you'll be able to invite {TEAM_PLAN_INCLUDED_SEATS - (teamMembers.length - 1)} more members without additional fees.</Body2>
          </Banner>
        ) : (
          <Banner variant="accent" classes="flex flex-col gap-1 p-4">
            <Subtitle1>This action will reduce your monthly subscription</Subtitle1>
            <Body2>Removing this extra member will reduce - ${teamPrices?.priceSeatRemaining || 0} USD in your next invoice.</Body2>
          </Banner>
        )}
        <div className="flex w-full justify-end gap-2">
          <PrimaryButton text='Cancel' variant='outlined' onClick={closeModal}
            disabled={isLoading}
            height='39px' />
          <PrimaryButton text='Yes, remove member' variant='error' onClick={onConfirmDeletion} height='39px'
            disabled={isLoading}
            loading={isLoading} />
        </div>
      </div>
    </DialogPaper>
  );
};

export default RemoveMemberModal;