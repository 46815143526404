import { useContext, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import Logger from "Logger";
import { Container, StyledLabel, TextButton } from "styles/shared";
import EditSpendLimitModal from "pages/post-auth/MarketingPage/Components/Modals/AdminEditSpendLimitModal";
import { goToUrl } from "modules/Utils";
import dayjs from "dayjs";
import { SpendBudgetData } from "types/global";
import { PRO_MONTHLY_PLAN_PRICE, DEFAULT_CURRENCY, PlanType } from 'modules/Const';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import getSymbolFromCurrency from "currency-symbol-map";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { getBrandCurrentSubscriptionData, getStripeSubscriptionPortalUrl } from "services/symphonyApi/subscriptionService";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import ButtonSymphony from "components/shareable/ButtonSymphony";

/** 
 * Extends Window interface to include Baremetrics cancellation widget properties
 */
declare global {
  interface Window {
    barecancel?: {
      created: boolean;
      params: {
        access_token_id: string;
        customer_oid: string;
        callback_send: (data: any) => void;
        callback_error: (error: any) => void;
      };
    };
  }
}

/**
 * Component for displaying and managing paid plan billing information
 * Handles subscription management, billing details, and admin controls
 */
const PaidPlanBillingView = () => {
  const theme = useTheme();
  const { currentBrand, isProBrand, isLiteBrand, isPaidBrand } = useContext(CurrentBrandContext);
  const { isSymphonyAdmin: isAdmin } = useContext(CurrentUserContext);
  const { slug: brandSlug, subscription: subscriptionDetails } = currentBrand || {};

  // Media query hooks for responsive layout
  const setMdWidth = useMediaQuery(theme.breakpoints.down(645));
  const setLgWidth = useMediaQuery(theme.breakpoints.between(645, 920));
  const setUpperPartAsColumn = useMediaQuery(theme.breakpoints.down(524));

  // State management
  const [renderedSubscriptionData, setRenderedSubscriptionData] = useState<SpendBudgetData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [downgradingActively, setDowngradingActively] = useState<boolean>(false);
  const [isEditSpendLimitModalOpen, setIsEditSpendLimitModalOpen] = useState(false);

  const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY;
  const currencySymbol = getSymbolFromCurrency(currencyCode);

  const { handleOpenModal } = useContext(UpgradeModalContext);

  /**
   * Redirects user to Stripe billing portal
   */
  const redirectToStripeBillingPortal = async () => {
    try {
      setIsLoading(true);
      const url = await getStripeSubscriptionPortalUrl(brandSlug || '');
      goToUrl(url, setIsLoading);
    } catch (error) {
      setIsLoading(false);
      Sentry.captureException(error);
      console.error("redirectToSubscriptionStatus: ", error);
    }
  };

  /**
   * Opens Stripe subscription details in new tab
   */
  const redirectToStripeSubscription = () => {
    if (renderedSubscriptionData?.viewSubscriptionUrl) {
      window.open(renderedSubscriptionData.viewSubscriptionUrl, "_blank");
    }
  }

  /**
   * Opens Stripe customer details in new tab
   */
  const redirectToStripeCustomer = () => {
    if (renderedSubscriptionData?.viewCustomerUrl) {
      window.open(renderedSubscriptionData.viewCustomerUrl, "_blank");
    }
  }

  /**
   * Fetches current subscription data for the brand
   * @param data Object containing brandSlug
   * @returns Promise resolving to SpendBudgetData or null
   */
  const getData = async (data: { brandSlug?: string | null }): Promise<SpendBudgetData | null> => {
    const { brandSlug } = data;
    setIsLoading(true);

    try {
      const subscriptionData = await getBrandCurrentSubscriptionData({
        brandSlug: brandSlug || '',
        currencyCode
      });

      if (subscriptionData) {
        setRenderedSubscriptionData({ ...subscriptionData });
      }
      return subscriptionData;
    } catch (error) {
      Logger.error("Error when retrieving spend budget data in BudgetTabSpendContext: ", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData({ brandSlug })
  }, [currencyCode, brandSlug, subscriptionDetails])

  const dataRow = (array: {
    title: string;
    text: string
    editable?: boolean;
    onEdit?: () => void
  }[]) => {
    return (
      <Container
        gap={setUpperPartAsColumn ? "8px" : "32px"}
        display="flex"
        flexDirection={setUpperPartAsColumn ? "column" : "row"}
        padding="24px 0px"
        borderBottom="1px solid #E5E7EB"
        alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
      >
        {array.map((item) => (
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width={setLgWidth ? "32%" : setMdWidth ? "100%" : "20%"}
          >
            <StyledLabel fontSize={16} fontWeight={400}>
              {item.title}
            </StyledLabel>
            <Container display="flex" flexDirection="row" gap="12px">
              <StyledLabel fontSize={24} fontWeight={500}>
                {item.text}
              </StyledLabel>
              {item.editable && isAdmin && (
                <Container display="flex" gap="4px" alignItems="center">
                  <TextButton onClick={item.onEdit}>Edit Spend</TextButton>
                </Container>
              )}
            </Container>
          </Container>
        ))}
      </Container>
    )
  }

  const openEditSpendLimitModal = () => setIsEditSpendLimitModalOpen(true);
  const closeEditSpendLimitModal = () => setIsEditSpendLimitModalOpen(false);
  const updateSpendLimit = async () => getData({ brandSlug });

  /**
   * Renders upgrade/downgrade buttons based on subscription status
   */
  function renderUpgradeDowngradeButtons() {
    if (renderedSubscriptionData?.scheduledCancellationDate) {
      return (
        <Container flexDirection={"row"}>
          <ButtonSymphony onClick={() => handleOpenModal({
            isDowngrade: true,
            onlyShowPlans: [PlanType.FREE, PlanType.LITE, PlanType.PRO]
          })}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Resume Subscription
          </ButtonSymphony>
        </Container>
      )
    }

    if (isPaidBrand && !isForcedPro) {
      return (
        <Container flexDirection={"row"}>
          <ButtonSymphony
            isLoading={isLoading}
            disabled={isLoading}
            variant="outlined"
            onClick={() => handleOpenModal({
              isDowngrade: true,
              onlyShowPlans: [PlanType.FREE, PlanType.LITE, PlanType.PRO]
            })}
          >
            Update Subscription
          </ButtonSymphony>
        </Container>
      )
    }
  }

  // Prepare data for display rows
  const basePlanRowData = [];
  let planTitle = isLiteBrand ? "Lite" : "Pro";

  if (renderedSubscriptionData?.planType) {
    const text = renderedSubscriptionData.planType === 'monthly' ? 'Monthly' : 'Yearly';
    basePlanRowData.push({
      title: "Current Plan",
      text: `${planTitle}${text ? ` (${text})` : ''}`
    })
  } else {
    basePlanRowData.push({
      title: "Current Plan",
      text: planTitle
    })
  }

  const isForcedPro = subscriptionDetails?.type === 'forced';
  if (isForcedPro) {
    basePlanRowData.push({
      title: "Applied Discounts",
      text: "Our team has granted you free Symphony Pro access 💜"
    })
  } else {
    if (renderedSubscriptionData?.trialEndDate && !renderedSubscriptionData?.scheduledCancellationDate) {
      basePlanRowData.push({
        title: "Trial Ends",
        text: dayjs(renderedSubscriptionData?.trialEndDate).format("MMM DD, YYYY")
      })
    }
    if (renderedSubscriptionData?.nextBillingDate) {
      basePlanRowData.push({
        title: "Next Billing Date",
        text: dayjs(renderedSubscriptionData?.nextBillingDate).format("MMM DD, YYYY")
      })
    } else if (renderedSubscriptionData?.scheduledCancellationDate) {
      basePlanRowData.push({
        title: "Subscription Ends After",
        text: dayjs(renderedSubscriptionData?.scheduledCancellationDate).format("MMM DD, YYYY")
      })
    }
    if (renderedSubscriptionData?.coupons) {
      renderedSubscriptionData.coupons.map((coupon) => {
        basePlanRowData.push({
          title: `Coupon: ${coupon.name} (${dayjs.unix(coupon.start_date).format("MMM DD, YYYY")} to ${dayjs.unix(coupon.end_date).format("MMM DD, YYYY")})`,
          text: coupon.amount_off ? `${coupon.amount_off}` : `${coupon.percent_off}% OFF`
        })

        return null
      });
    }
  }

  const adSpendRowData = [{
    title: `Monthly Ad Spend Limit`,
    text: renderedSubscriptionData?.effectiveSpendLimit ? `${currencySymbol}${renderedSubscriptionData?.effectiveSpendLimit.toLocaleString('en', {
      currency: currencyCode,
    })}` : "N/A"
  },
  {
    title: "Total Spent this Month",
    text: renderedSubscriptionData?.spentToDate ? `${currencySymbol}${renderedSubscriptionData?.spentToDate.toLocaleString('en', {
      currency: currencyCode,
    })}` : `${currencySymbol}0`,
    editable: isAdmin,
    onEdit: openEditSpendLimitModal,
  },
  {
    title: `Automation Fee this Month (${getSymbolFromCurrency(DEFAULT_CURRENCY)}${PRO_MONTHLY_PLAN_PRICE} ${DEFAULT_CURRENCY})`,
    text: renderedSubscriptionData?.feeTotal ? `+${currencySymbol}${renderedSubscriptionData?.feeTotal.toLocaleString('en', {
      currency: currencyCode,
    })}` : `${currencySymbol}0`
  }];

  const userSeatsRowData = [{
    title: "User Seats",
    text: `${renderedSubscriptionData?.totalSeatsUsed ? `${renderedSubscriptionData?.totalSeatsUsed.toLocaleString('en')}` : "0"}`
  },
  {
    title: "Additional User Seats Used (2 included)",
    text: renderedSubscriptionData?.seatsOverage ? `${renderedSubscriptionData?.seatsOverage.toLocaleString('en')}` : "0"
  },
  {
    title: "Additional Seats Cost this Month",
    text: renderedSubscriptionData?.seatsOverFee ? `+${currencySymbol}${renderedSubscriptionData?.seatsOverFee.toLocaleString('en', {
      currency: currencyCode,
    })}` : `${currencySymbol}0`
  }];

  return (
    <Container>
      {!isForcedPro && (
        <Container
          gap={setUpperPartAsColumn ? "8px" : "32px"}
          display="flex"
          flexDirection={setMdWidth ? "column" : "row"}
          padding="24px 0px"
          borderBottom="1px solid #E5E7EB"
          alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
        >
          <Container flexDirection="row">
            <ButtonSymphony
              onClick={redirectToStripeBillingPortal}
              disabled={isLoading}
              isLoading={isLoading}
              variant="outlined"
              loadingText="View Billing Settings"
            >
              View Billing Settings
            </ButtonSymphony>
          </Container>
          {renderUpgradeDowngradeButtons()}
        </Container>
      )}

      {isAdmin && (
        <Container
          gap={setUpperPartAsColumn ? '8px' : '32px'}
          display="flex"
          flexDirection="row"
          padding="24px 0px"
          borderBottom="1px solid #E5E7EB"
          alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
        >
          <Container flexDirection="row">
            <ButtonSymphony
              className="settingsPageAdminViewStripeSubscriptionButton"
              onClick={redirectToStripeSubscription}
              variant="outlined"
            >
              Admin: View Stripe Subscription
            </ButtonSymphony>
          </Container>
          <Container flexDirection="row">
            <ButtonSymphony
              className="settingsPageAdminViewStripeCustomerButton"
              onClick={redirectToStripeCustomer}
              variant="outlined"
            >
              Admin: View Stripe Customer
            </ButtonSymphony>
          </Container>
        </Container>
      )}

      {dataRow(basePlanRowData)}
      {isProBrand && dataRow(adSpendRowData)}
      {isProBrand && dataRow(userSeatsRowData)}

      {isAdmin && brandSlug && (
        <EditSpendLimitModal
          brandSlug={brandSlug}
          open={isEditSpendLimitModalOpen}
          closeModal={closeEditSpendLimitModal}
          currentSpendLimit={renderedSubscriptionData?.spentToDate || 0}
          defaultSpendLimit={renderedSubscriptionData?.spentToDate || 0}
          updateSpendLimit={updateSpendLimit}
        />
      )}
    </Container>
  );
};

export default PaidPlanBillingView;
