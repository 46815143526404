import { DataGrid, DataGridProps, GridCallbackDetails } from "@mui/x-data-grid";
import { useStyles } from "./styles";
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg";
import { ReactComponent as ChevronRight } from "assets/images/chevronRight.svg";
import { Body2 } from "components/shareable/Typography";
import Colors from "modules/Colors";
import LoaderV2 from 'components/Loader/LoaderV2';

type OrganizationViewDefaultGridProps = Omit<DataGridProps, 'classes'> & {
    className?: string;
    hideFooter?: boolean;
    itemsPerPage?: number;
    itemName?: string;
    rowCount: number;
    disableRowSelectionOnClick?: boolean;
    isLoadingData?: boolean;
    emptyStateComponent?: React.ReactNode;
};

export const OrganizationViewDefaultGrid = ({
    className = '',
    hideFooter = true,
    autoHeight = true,
    rowHeight = 80,
    itemsPerPage = 8,
    itemName = 'Items',
    paginationMode = 'server',
    rowCount,
    disableRowSelectionOnClick = true,
    isLoadingData = false,
    emptyStateComponent,
    ...props
}: OrganizationViewDefaultGridProps) => {
    const classes = useStyles();
    const needsPagination = rowCount > (props.rows?.length || 0);
    const page = props.page || 0;
    const numberOfPages = Math.ceil(rowCount / itemsPerPage);
    const firstShownIndex = (page * itemsPerPage) + 1;
    const lastShownIndex = firstShownIndex + ((props.rows?.length || 0) - 1);

    const handlePageChange = (newPage: number) => {
        if (props.onPageChange) {
            const details: GridCallbackDetails = {
                reason: 'pagination'
            };
            props.onPageChange(newPage, details);
        }
    };

    return (
        <div className={`organizationViewDefaultGridComponent ${className} relative`}>
            <DataGrid
                autoHeight={autoHeight}
                rowHeight={rowHeight}
                classes={{ root: classes.dataGridRoot }}
                hideFooter={hideFooter}
                components={{
                    LoadingOverlay: function Component(props) {
                        return (
                            <div
                                className="loadingOverlayInnerComponent w-full h-full flex-col flex justify-center items-center gap-2"
                                style={{ transform: 'translateY(-10%)' }}
                            >
                                <LoaderV2 /> Loading {itemName}...
                            </div>
                        )
                    },
                    NoRowsOverlay: () => <div style={{ pointerEvents: 'none' }} />,
                }}
                paginationMode={paginationMode}
                pageSize={itemsPerPage}
                rowCount={rowCount}
                checkboxSelection={false}
                disableSelectionOnClick={disableRowSelectionOnClick || true}
                {...props}
                loading={isLoadingData}
            />
            {!isLoadingData && !props.rows?.length && emptyStateComponent &&
                <div
                    className="organizationViewDefaultGridEmptyStateComponent"
                    style={{ top: 56, position: 'absolute', inset: 0 }}
                >
                    {emptyStateComponent}
                </div>
            }
            {needsPagination &&
                <div className="needsPaginationContainer flex gap-6 w-full justify-end py-2 items-center">
                    <div className="needsPaginationItemsPerPageContainer flex gap-1">
                        <Body2 color={Colors.greyDark}>{itemName} per page:</Body2>
                        <Body2 color={Colors.textColor}>{itemsPerPage}</Body2>
                    </div>
                    <Body2 color={Colors.textColor}>
                        {firstShownIndex}-{lastShownIndex} of {rowCount} {itemName}
                    </Body2>
                    <div className="needsPaginationChevronContainer flex gap-6 h-4">
                        <ChevronLeft
                            {...(page === 0 ?
                                { className: classes.disabledChevron }
                                :
                                {
                                    className: classes.enabledChevron,
                                    onClick: () => handlePageChange(page - 1)
                                }
                            )}
                        />
                        <ChevronRight
                            {...(page === numberOfPages - 1 ?
                                { className: classes.disabledChevron }
                                :
                                {
                                    className: classes.enabledChevron,
                                    onClick: () => handlePageChange(page + 1)
                                }
                            )}
                        />
                    </div>
                </div>
            }
        </div>
    );
};