import { useEffect, useState, useContext } from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import Finalization from "./Finalization"
import RecordSelectorTab from "components/shareable/RecordSelectorTab"
import { track } from "analytics"
import CreativeCustomizationTab from "./Preview"
import { scrollToTop, scrollIntoView } from "helpers/StyleUtils"
import { ColorPalette, CurrentBrand, RecordSelected, Template } from "types/global"
import { useHistory } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TopBar from "components/shareable/TopBar/TopBar"
import CustomStepper from "components/shareable/CustomStepper/CustomStepper"
import useStyles from "./styles"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import useScreen from "Hooks/useScreen"

enum STEPS {
  RECORD = "record",
  CUSTOMIZATION = "customization",
  FINALIZATION = "finalization",
}

const { RECORD, CUSTOMIZATION, FINALIZATION } = STEPS

const RECORD_SELECTED_ISSUES = `
    This song doesn't have an associated song preview, so it is not eligible for the Music Video Generator. 
    We're working to streamline this soon, but for now please pick another song.
`
const steps = [
  { description: "Record", name: RECORD },
  { description: "Style", name: CUSTOMIZATION },
  { description: "Review", name: FINALIZATION },
]
const DEFAULT_TAB = RECORD
const CONTINUE = "Continue"
const NEXT = "Next"

const CreativeGenerator = () => {
  const classes = useStyles()
  const history = useHistory()
  const tabs = steps.map(({ name }) => name)
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }
  const { mobileView } = useScreen()
  const [selectedStep, setSelectedStep] = useTabs(tabs, DEFAULT_TAB)
  const [recordSelected, setRecordSelected] = useState<RecordSelected | null>(
    null
  )
  const [recordSelectedIssues, setRecordSelectedIssues] = useState<
    string | null
  >(null)
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )

  const [selectedArtworkUrl, setArtworkUrl] = useState<string | undefined>(undefined)
  const [selectedArtistUrl, setArtistUrl] = useState<string | undefined>(undefined)

  const [selectedColorPalette, setSelectedColorPalette] =
    useState<ColorPalette | null>(null)
  const [recordTabNextButtonData, setSaveNextButtonData] = useState({
    areLinksValid: false,
    isLoadingButton: false,
  })
  const { areLinksValid } = recordTabNextButtonData
  const showCloseIcon = selectedStep === RECORD
  const showGoBackIcon = selectedStep !== RECORD
  const mainTitle = "Music Video Generator"
  const disableNextButtonWhenCustomizationTab =
    !selectedColorPalette || !selectedTemplate
  const spotifyConnected =
    currentBrand && currentBrand.connections.spotify_artist_page
      ? currentBrand.connections.spotify_artist_page
      : null

  const handleClickCloseTopBar = () => {
    if (recordSelected) {
      setRecordSelected(null)
    } else {
      history.push("/marketing")
    }
  }

  const handleClickGoBackTopBar = () => {
    if (selectedStep === CUSTOMIZATION) {
      setSelectedStep(RECORD)
    } else if (selectedStep === FINALIZATION) {
      setSelectedStep(CUSTOMIZATION)
    }
  }

  const handleClickBackButton = showCloseIcon
    ? handleClickCloseTopBar
    : handleClickGoBackTopBar

  const handleClickNextButton = () => {
    if (selectedStep === RECORD) {
      setSelectedStep(CUSTOMIZATION)
    } else if (selectedStep === CUSTOMIZATION) {
      setSelectedStep(FINALIZATION)
    }
  }

  const handleClickStep = (step: string) => () => {
    if (step === CUSTOMIZATION) {
      if (!recordSelectedIssues) setSelectedStep(step as STEPS)
    } else if (step === FINALIZATION) {
      if (selectedColorPalette && selectedTemplate && !recordSelectedIssues) setSelectedStep(step as STEPS)
    } else {
      setSelectedStep(step as STEPS)
    }
  }

  const handleSelectStep = (step: string) => () => {
    setSelectedStep(step as STEPS)
  }

  const handleRecordTabNextButton = () => {
    track("Completed Campaign Step - Content Selection", {
      type: "creative_generator",
      step: 1,
      name: "select_song",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected?.name,
      content_slug: recordSelected?.slug,
      content_id: recordSelected?.id,
    })

    setSelectedStep(CUSTOMIZATION)
  }

  const saveNextButtonData = (data: {
    areLinksValid: boolean
    isLoadingButton: boolean
  }) => {
    setSaveNextButtonData(data)
  }

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedStep])

  useEffect(() => {
    if (recordSelected && !recordSelected.preview_url) {
      setRecordSelectedIssues(RECORD_SELECTED_ISSUES)
    } else {
      setRecordSelectedIssues(null)
      setArtworkUrl(recordSelected?.thumbnail_url)

      // also set the artist image url 
      var artistImageUrl = recordSelected?.thumbnail_url
      const { connections } = currentBrand
      const { spotify_artist_page } = connections
      if (spotify_artist_page && spotify_artist_page.images && spotify_artist_page.images.length > 0) {
        artistImageUrl = spotify_artist_page.images[0].url;
      }
      setArtistUrl(artistImageUrl)
    }
  }, [recordSelected])

  return (
    <Grid>
      <TopBar
        {...{ showCloseIcon, showGoBackIcon }}
        title={mainTitle}
        handleGoBack={handleClickGoBackTopBar}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        activeStep={steps
          .map(({ name }) => name)
          .indexOf(selectedStep as STEPS)}
        steps={steps.map((data) => ({
          ...data,
          onClickHandler: handleClickStep,
        }))}
        stepButtonsProps={{
          className: 'stepButtonsCreativeGenerator',
          handleClickBackButton,
          handleClickNextButton,
          showNextButton: selectedStep !== FINALIZATION,
          disableNextButton:
            (selectedStep === RECORD &&
              (!!recordSelectedIssues || !areLinksValid)) ||
            (selectedStep === CUSTOMIZATION &&
              disableNextButtonWhenCustomizationTab) ||
            selectedStep === FINALIZATION,
          nextButtonLabel: selectedStep === CUSTOMIZATION ? CONTINUE : NEXT,
        }}
      >
        <MainContentContainer className={classes.mainContainer}>
          <Grid className={`bg-white h-full w-full mb-40 rounded-${mobileView ? "none" : "xl mt-6"}`}>
            <TabPanel hidden={selectedStep !== RECORD}>
              <RecordSelectorTab
                className="creativeGeneratorRecordSelectorTab"
                filter="single"
                title="Select the song you want to generate videos for."
                spotifyConnected={spotifyConnected}
                setRecordSelected={setRecordSelected}
                recordSelected={recordSelected}
                nextTab={handleRecordTabNextButton}
                brand={currentBrand}
                columns={["artist", "type", "issues"]}
                recordSelectedIssues={recordSelectedIssues}
                showNextButton={false}
                saveNextButtonData={saveNextButtonData}
                fullInfoContainerClassName={classes.recordFullInfoContainer}
              />
            </TabPanel>
            <TabPanel hidden={selectedStep !== CUSTOMIZATION}>
              <CreativeCustomizationTab
                setArtistUrl={setArtistUrl}
                setArtworkUrl={setArtworkUrl}
                selectedArtistUrl={selectedArtistUrl}
                selectedArtworkUrl={selectedArtworkUrl}
                nextPage={handleSelectStep(FINALIZATION)}
                setSelectedColorPalette={setSelectedColorPalette}
                selectedColorPalette={selectedColorPalette as ColorPalette}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate as Template}
                record={recordSelected as RecordSelected}
                showContinueButton={false}
              />
            </TabPanel>
            <TabPanel hidden={selectedStep !== FINALIZATION}>
              <Finalization
                artworkImageUrl={selectedArtworkUrl}
                artistImageUrl={selectedArtistUrl}
                record={recordSelected as RecordSelected}
                brand={currentBrand}
                selectedTemplate={selectedTemplate as Template}
                selectedColorPalette={selectedColorPalette as ColorPalette}
              />
            </TabPanel>
          </Grid>
        </MainContentContainer>
      </CustomStepper>
    </Grid>
  )
}

export default CreativeGenerator