import { Body2 } from 'components/shareable/Typography';
import { StyledChip } from '../ConversionsSteps/FacebookAdAccount/SelectAdAccountModal/AccountsList/AdAccountOption/styles';
import Colors from 'modules/Colors';

interface BusinessChipProps {
  business?: {
    id: string;
    name: string;
  } | undefined;
  assetId: string;
  isDisabled: boolean;
  fallbackText?: string;
}

const BusinessChip = ({
  business,
  assetId,
  isDisabled,
  fallbackText
}: BusinessChipProps) => {
  const handleIsDisabled = isDisabled
    ? Colors.grey
    : undefined;

  if (business && business.name) {
    return (
      <StyledChip>
        <Body2 color={handleIsDisabled}>Business: {business.name} (ID: {assetId})</Body2>
      </StyledChip>
    )
  } else {
    // this is a personal account, so we should let the user know
    let text = fallbackText ? fallbackText : 'Personal (Not linked to a Meta Business Manager)'

    return (
      <StyledChip>
        <Body2 color={handleIsDisabled}>{text}</Body2>
      </StyledChip>
    )
  }
}

export default BusinessChip;