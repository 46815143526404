import { useEffect, useMemo, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getColumnDefinition } from './utils';
import { useStyles } from './styles';
import LoadingLottie from 'components/Loader/LoadingLottie';
import useModal from 'Hooks/useModal';
import { EditMemberModal } from '../EditMemberModal';
import { TeamMember, TeamPricesData } from 'types/global';
import { Caption } from 'components/shareable/Typography';
import { CurrentUserContext } from 'Hooks/CurrentUserContext';
import { TEAM_PLAN_INCLUDED_SEATS } from 'modules/Const'
import { getSimulationPrices } from 'services/symphonyApi/organizationService'
import Colors from 'modules/Colors';

interface TeamMemberListProps {
  searchTerm?: string;
  teamMembers: TeamMember[];
  handleRemoveTeamMember: () => void;
}

export const TeamMemberList = ({ teamMembers, searchTerm, handleRemoveTeamMember }: TeamMemberListProps) => {
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext)
  const editMemberModal = useModal();


  const [selectedMember, setSelectedMember] = useState<TeamMember>();
  const [teamPrices, setTeamPrices] = useState<TeamPricesData>();

  const myAccount = teamMembers.find(member => member.id === currentUser?.id);
  const myRole = myAccount?.role;

  // Use computed value instead of state
  const members = useMemo(() => {
    if (searchTerm && searchTerm.length > 1) {
      return teamMembers.filter(
        (member) =>
          member.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return teamMembers;
  }, [teamMembers, searchTerm]);

  useEffect(() => {
    if (teamMembers.length >= TEAM_PLAN_INCLUDED_SEATS) initStripeTeamPlanCosts();
  }, [teamMembers]);

  const initStripeTeamPlanCosts = async () => {
    const teamPricesResponse = await getSimulationPrices({ typeSimulation: 'remove-seat' });
    if (teamPricesResponse) setTeamPrices(teamPricesResponse);
  };

  const handleOpenEditMemberModal = (member: TeamMember) => {
    setSelectedMember(member);
    editMemberModal.openModal();
  };

  const handleCloseEditMemberModal = () => {
    setSelectedMember(undefined);
    editMemberModal.closeModal();
  };

  if (!members.length && searchTerm && searchTerm?.length > 1) return (
    <div className='w-full h-16 flex justify-center items-center'>
      <Caption color={Colors.greyDark}>No users found for '{searchTerm}'.</Caption>
    </div>
  )

  if (!members.length) return (
    <div className='w-full h-16 flex justify-center items-center'>
      <LoadingLottie black height={24} />
    </div>
  );

  return (
    <>
      <DataGrid
        autoHeight
        hideFooter
        headerHeight={0}
        rowHeight={80}
        rows={members}
        columns={getColumnDefinition(handleOpenEditMemberModal, handleRemoveTeamMember, myRole, teamPrices)}
        classes={{ root: classes.dataGridRoot }}
      />
      {editMemberModal.open && selectedMember && (
        <EditMemberModal
          currentMember={myAccount!}
          member={selectedMember}
          onClose={handleCloseEditMemberModal}
        />
      )}
    </>
  );
};

