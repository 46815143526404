import RoundedCard from "components/shareable/RoundedCard";
import CampaignSelection from "./CampaignCard";
import MarketingCard from "./CampaignHelperSection";
import { StyledLabel } from "components/shareable/UpgradeToProLabel/styles";
import { Container } from "styles/shared";
import { CampaignOption, campaignOptions, getCampaignOptionById } from "../utils";

interface GoalTabProps {
    selectedGoal: CampaignOption;
    setSelectedGoal: (goal: CampaignOption) => void;
}

const GoalTab = ({
    selectedGoal,
    setSelectedGoal
}: GoalTabProps) => {
    const handleChange = (event: string) => {
        const id = event
        const foundOption = getCampaignOptionById(id)
        console.log('id', id)
        if (foundOption) {
            setSelectedGoal(foundOption); // This will update the state in the parent component
        }
    };
    const selectedOption = campaignOptions.find(option => option.id === selectedGoal.id);

    const whenToUse = selectedOption?.whenToUse || [];
    const setupNeeded = selectedOption?.setupNeeded || [];
    return (
        <Container display="flex" flexDirection="column" gap="12px">
            <RoundedCard title="What do you want to boost?" description="Select your campaign goal and Symphony's ads will configure to maximize your budget.">
                <CampaignSelection options={campaignOptions} handleChange={handleChange} selectedValue={selectedGoal} />
            </RoundedCard>

            <RoundedCard>
                <StyledLabel fontWeight={400} fontSize={"24px"} color="#000">
                    🫡 Quick tips on this playbook:
                </StyledLabel>
                <MarketingCard
                    averageConversionCost={selectedOption?.averageConversionCost || 0}
                    conversionType={selectedOption?.conversionType || ''}
                    // topImageTextCombos={topImageTextCombos}
                    whenToUse={whenToUse}
                    setupNeeded={setupNeeded}
                />
            </RoundedCard>
        </Container>
    )
}

export default GoalTab;