import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react"
import Axios from "helpers/Interceptor"
import { convertArrayDraftDataToSendAsObject, getFromDraftDataSelectedPlatforms, STEPS } from "../utils"
import { CurrentBrand, SavingDraftStatus, SongAdsRecord, AdAccountStatusCodes  } from "types/global"
import debounce from "lodash/debounce"
import { saveDraft } from "../api"
import { track } from "analytics"
import { CAMPAIGN_TYPES, DEBOUNCE_SAVE_DRAFT_TIME } from "modules/Const"
import { UploadedAssetType } from '../../utils/fbCampaigns'
import { areValidDates } from '../../utils/daysCalculator'
import { AD_ACCOUNT_STATUS } from 'helpers/FB'
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { getFormattedGeographyTargeting } from "../../Components/TargetingView/utils"
import { CreativeAssetTypes } from "../../constants"
import { SongAdsCampaignSubtype } from ".."
import { ConnectionsContext } from "../../hooks/ConnectionsContext"

const { FIRST_TIME_DRAFT_DATA_LOAD, SAVING_NEXT_DRAFT } = SavingDraftStatus
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

export const useDraftDataGetter = (args: {
  setters: Record<string, Dispatch<SetStateAction<unknown>>>
  draft?: string | null;
  subtype: SongAdsCampaignSubtype;
}) => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const { conversions_tasks_completed } = currentBrand?.conversionsStatus || {};
  const { setters, draft, subtype } = args
  const {
    setRetrievingDraftData,
    setRecordSelected,
    setBudgetValue,
    setSelectedTab,
    setCampaignDraftData,
    setTargets,
    setAudiences,
    setCustomAudiences,
    setAdditionalPagesAudiences,
    setSelectedInstagramPost,
    setUpload,
    setSpotifyAssets,
    setAppleAssets,
    setYouTubeAssets,
    setCaptions,
    setEndDate,
    setStartDate,
    saveTargets,
    saveAudiences,
    saveArtists,
    saveDemographics,
    setAddVisualSelected,
    setSelectedPlatforms,
  } = setters

  const {
    setFacebookPage: setSelectedFB_page,
    setInstagramPage: setSelectedInstaPage,
    setFacebookAdAccount:setFBAdAccount,
    setFacebookPixel: setFBPixel,
    setFacebookBusinessManager: setFBBusinessManager,
    setLoggedInFbUser,
    setValidateConnections,
    setConversionsEnabled,
    setConfirmConnections,
  } = useContext(ConnectionsContext)

  useEffect(() => {
    const getDraftCampaignData = async (draft?: string | null) => {
      if (draft) {
        try {
          setRetrievingDraftData(true)
          const response = await Axios.get(`campaign/${draft}`)
          const data = response.data.data
          const saved_draft_step = data?.campaign_metadata?.saved_draft_step || STEPS.RECORD
          const add_visual_selected = data?.campaign_metadata?.add_visual_selected
          const budget = data?.budget;
          const {
            targetedCountries,
            interestTargetingAudiences,
            igMedia,
            record,
            assets,
            captions,
            startDate,
            endDate,
            price_data,
            targetingDemographics,
            customAudiences = [],
            additionalPageAudiences = [],
            conversionsEnabled,
          } = data?.campaign_metadata?.campaign_input || {}
          const { price } = price_data || {}
          const {
            facebook_business_manager,
            facebook_ad_account,
            facebook_pixel,
            facebook_page,
            instagram_page,
            logged_in_fb_user,
          } = data?.campaign_metadata?.campaign_state.brand?.connections || {}
          const formattedAudiences =
            interestTargetingAudiences?.map(
              ({ fbId, name }: { fbId: string; name: string }) => ({
                id: fbId,
                name,
                audience_size: null,
                country: null,
                country_access: null,
                description: null,
                partner: null,
                path: null,
                source: null,
                topic: null,
                type: null,
                artistId: null,
              })
            ) || []

          track("Continued Creating Campaign from Draft", {
            type: subtype,
            step: saved_draft_step,
          })

          const {
            validDates,
            formattedEndDate,
            formattedStartDate
          } = areValidDates({ startDate, endDate })

          const adAccountToSet = conversions_tasks_completed ? currentBrand?.connections.facebook_ad_account : facebook_ad_account

          if (facebook_ad_account && !AD_ACCOUNT_STATUS[facebook_ad_account.account_status as AdAccountStatusCodes]?.valid) {
            setSelectedTab(STEPS.CREATIVE)
          } else if (saved_draft_step === STEPS.FINALIZATION && !validDates) {
            setSelectedTab(STEPS.BUDGET)
          } else {
            setSelectedTab(saved_draft_step)
          }
          setEndDate(formattedEndDate)
          setStartDate(formattedStartDate)

          if (facebook_page) setSelectedFB_page(facebook_page);
          if (adAccountToSet) setFBAdAccount(adAccountToSet);
          if (instagram_page) setSelectedInstaPage(instagram_page);
          if (facebook_pixel) setFBPixel(facebook_pixel);
          if (facebook_business_manager) setFBBusinessManager(facebook_business_manager);
          if (logged_in_fb_user) setLoggedInFbUser(logged_in_fb_user);
          setValidateConnections(true)
          setConfirmConnections(true)

          let campaignType = subtype === "grow_playlist_followers" ? CAMPAIGN_TYPES.grow_playlist_followers : CAMPAIGN_TYPES.record_streams
          const formattedGeographyTargeting = getFormattedGeographyTargeting(campaignType, targetedCountries)
          setTargets(formattedGeographyTargeting)
          setCustomAudiences(customAudiences)
          setAdditionalPagesAudiences(additionalPageAudiences)


          setAudiences(formattedAudiences)
          setSelectedInstagramPost(igMedia)
          setRecordSelected(record || false)
          setConversionsEnabled(conversions_tasks_completed && conversionsEnabled)

          const allAssets = assets?.all as UploadedAssetType[] || []
          if (add_visual_selected) {
            setAddVisualSelected(add_visual_selected)
          } else {
            setAddVisualSelected(allAssets.length ? UPLOAD_VIDEO : INSTAGRAM_POSTS)
          }

          const newCaptions = { ...captions }
          Object.keys(newCaptions).forEach((key: string) => {
            if (!newCaptions[key]) {
              const captionFound = record.external_links.find((record: { name: string }) => record.name === key)
              if (captionFound) newCaptions[key] = null
            }
          })

          const selectedPlatforms = getFromDraftDataSelectedPlatforms(data)
          setCaptions(newCaptions)
          setSelectedPlatforms(selectedPlatforms)
          setUpload(allAssets || null)
          setSpotifyAssets(assets?.spotify || null)
          setAppleAssets(assets?.apple || null)
          setYouTubeAssets(assets?.youtube || null)
          setBudgetValue(price || budget || 0)
          saveTargets(formattedGeographyTargeting)
          saveAudiences(formattedAudiences)
          saveArtists([])
          saveDemographics(targetingDemographics || {})
          setCampaignDraftData(data || null)
          setRetrievingDraftData(false)
        } catch (error) {
          console.log("error retrieving draft campaign data: ", error)
        }
      }
    }

    getDraftCampaignData(draft)
  }, [draft])
}

export const useDraftDataSetter = (args: {
  subtype: SongAdsCampaignSubtype,
  setters: Record<string, Dispatch<unknown>>
  campaignDraftData?: Record<string, unknown> | null
  requiredDataChanged: boolean
  retrievingDraftData: boolean
  recordSelected: SongAdsRecord | null
  dataToSaveDraft: Record<string, unknown>[]
  audiencesData: Record<string, unknown>
  currentBrand: CurrentBrand
  draft: string | null
  savingDraftStatus: number
}) => {
  const {
    setters,
    campaignDraftData,
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    dataToSaveDraft,
    audiencesData,
    currentBrand,
    draft,
    savingDraftStatus,
    subtype
  } = args
  const {
    setRequiredDataChanged,
    setIsSavingDraft,
    setCampaignDraftData,
    setSavingDraftStatus,
  } = setters

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        async (args: {
          dataToSend: Record<string, unknown>
          campaignDraftData?: Record<string, unknown> | null
          audiencesData: Record<string, unknown>
          currentBrand: CurrentBrand
          subtype: SongAdsCampaignSubtype
        }) => {
          if (draft && !campaignDraftData) return setIsSavingDraft(false)

          const { data, error } = await saveDraft(args)

          if (!error && data) {
            const draftData = data as { campaign_metadata: Record<string, unknown> }
            const step = draftData.campaign_metadata.saved_draft_step

            track("Saved Draft", { type: subtype, step })
            setCampaignDraftData(data)
          }
          setIsSavingDraft(false)
        },
        DEBOUNCE_SAVE_DRAFT_TIME
      ),
    [campaignDraftData]
  )

  useEffect(() => {
    setRequiredDataChanged(true)
  }, dataToSaveDraft)

  useEffect(() => {
    if (requiredDataChanged && recordSelected && !retrievingDraftData) {
      setSavingDraftStatus((prev: number) =>
        prev > FIRST_TIME_DRAFT_DATA_LOAD ? SAVING_NEXT_DRAFT : prev + 1
      )
    }
  }, [
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    ...dataToSaveDraft,
  ])

  useEffect(() => {
    if (
      requiredDataChanged &&
      recordSelected &&
      !retrievingDraftData &&
      savingDraftStatus === SAVING_NEXT_DRAFT
    ) {
      const dataToSend = convertArrayDraftDataToSendAsObject(
        dataToSaveDraft as unknown as Record<string, unknown>[]
      )

      setIsSavingDraft(true)
      debouncedChangeHandler({
        audiencesData,
        dataToSend,
        campaignDraftData,
        currentBrand,
        subtype
      })
      setRequiredDataChanged(false)
    }
  }, [
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    campaignDraftData,
    ...dataToSaveDraft,
    audiencesData,
    savingDraftStatus
  ])

  const cancelSavingDraft = () => {
    setIsSavingDraft(false)
    debouncedChangeHandler.cancel()
  }

  useEffect(() => {
    return cancelSavingDraft
  }, [debouncedChangeHandler])

  return { cancelSavingDraft }
}
