import MaestroAiTag from 'assets/images/maestro/maestro-logo.svg';
import { Container } from 'styles/shared';
import { Caption } from '../Typography';
import Colors from "modules/Colors";

const MaestroBadge = ({
    className = '',
    showBetaTag = true,
    subtitle = '',
}: {
    className?: string,
    showBetaTag?: boolean,
    subtitle?: string,
}) => {
    return (
        <Container
            className={`maestroBadgeComponent ${className}`}
            backgroundColor="#F5EBFF"
            display="flex"
            borderRadius="8px"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            padding="2px 6px"
            gap="4px"
            width="fit-content"
        >
            <img
                className="maestroBadgeImage"
                draggable={false}
                width={24}
                height={24}
                src={MaestroAiTag}
                alt="Maestro AI Tag"
            />
            <Caption color={Colors.textColor}>Maestro AI{subtitle && ` ${subtitle}`}</Caption>
            {showBetaTag &&
                <div
                    className="maestroBadgeBetaTag"
                    style={{
                        backgroundColor: "#2C2135",
                        borderRadius: '99px',
                        textTransform: 'uppercase',
                        padding: '0px 6px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Caption color={Colors.textColorWhite}>
                        Beta
                    </Caption>
                </div>
            }
        </Container>
    )
}

export default MaestroBadge;