import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json";
import { ReactComponent as ProfileIcon } from "assets/images/profileIcon.svg";
import { ReactComponent as SpotifyIcon } from "assets/images/streaming/icons/spotify-i.svg";
import { ButtonText } from "components/shareable/Typography";
import millify from "millify";
import { useState } from "react";
import Lottie from "react-lottie";
import { TextButton } from "styles/shared";
import Colors from "modules/Colors";
import { RowProfileItemProps } from "../interfaces";
import { useStyles } from "../styles";

export function RowProfileItem({
  className = '',
  artist,
  handleArtist,
  actionLabel,
  footerLabel,
}: RowProfileItemProps) {
  const classes = useStyles();
  const [loadingActionArtist, setLoadingActionArtist] = useState(false);

  const handleAction = async (params: any) => {
    setLoadingActionArtist(true);
    await handleArtist(params);
    setLoadingActionArtist(false);
  };

  return (
    <li
      className={`rowProfileItemComponent ${className} ${classes.liProfileArtist}`}
      style={{ cursor: 'pointer' }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center mt-3">
          {artist.image_url ? (
            <img
              src={artist.image_url}
              alt="platform icon 3"
              className={classes.imgStylesUserSquare}
            />
          ) : (
            <ProfileIcon
              className={classes.imgStylesUserSquare}
              style={{
                backgroundColor: Colors.greyDark,
              }}
            />
          )}

          <div className="px-3">
            <h5 className="text-dark">{artist.name}</h5>
            {
              <div className="flex flex-row items-center">
                <div className="social-img">
                  <SpotifyIcon />
                </div>
                <div className="social-text mt-1">
                  <p className="spotifyFollowersCount text-xs ml-2">
                    {artist.sp_followers > 0 ? millify(artist.sp_followers) : 0}{" "}
                    followers
                  </p>
                </div>
              </div>
            }
          </div>
        </div>

        <TextButton
          onClick={() => handleAction(artist)}
          className="rowProfileItemButton flex justify-end mr-3"
        >
          {loadingActionArtist ?
            <Lottie
              height={24}
              width={24}
              options={{
                loop: true,
                autoplay: true,
                animationData: SymphonyLoadingLottie,
              }}
            />
            :
            <ButtonText>{actionLabel}</ButtonText>
          }
        </TextButton>
      </div>

      {!!footerLabel && <div className="mt-1 mr-3">{footerLabel}</div>}
    </li>
  );
}
