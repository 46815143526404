import { CSSProperties } from "react";
import { clsx } from "modules/Utils";
import { useCampaign } from "Hooks/useCampaign";

interface VideoSelectorCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const VideoSelectorCampaignView = ({
    className = '',
    style = {}
}: VideoSelectorCampaignViewProps) => {
    const { campaign } = useCampaign();

    return (
        <div
            className={clsx("videoSelectorCampaignViewComponent", className)}
            style={{ ...sty.videoSelectorCampaignViewComponent, ...style }}
        >
            VideoSelectorCampaignView
        </div>
    )
}

const sty = {
    videoSelectorCampaignViewComponent: {
        width: '100%',
        height: '100%',
    }
}

export default VideoSelectorCampaignView;