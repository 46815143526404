import { useEffect, Dispatch, useMemo } from "react"
import Axios from "helpers/Interceptor"
import { timeZoneParser } from "modules/Utils"
import {
  availablePlatforms,
  getDraftPlatforms,
  validateReleaseUrlUriUpcIsrc,
} from "../utils"
import { BackgroundTypes, CurrentBrand, SavingDraftStatus, StyleTypes, Website } from "types/global"
import { STEPS } from "../index"
import {
  Customize as CustomizeContext,
  Details as DetailsContext,
} from "../../Data/PreSaveProvider"
import debounce from "lodash/debounce"
import { saveDraft } from "../api"
import { track } from "analytics"
import { DEBOUNCE_SAVE_DRAFT_TIME } from "modules/Const"
import Colors from "modules/Colors"

const { FIRST_TIME_DRAFT_DATA_LOAD, SAVING_NEXT_DRAFT } = SavingDraftStatus

export const useDraftDataGetter = (args: {
  draft: string | null
  website: Website
  setters: Record<string, Dispatch<unknown>>
}) => {
  const { draft, website, setters } = args
  const {
    setSelectedTab,
    setRetrievingDraftData,
    setCampaignDraftData,
    setIsSavingDraft,
    saveCustomize,
    saveDetails,
  } = setters

  useEffect(() => {
    const getDraft = async (draft: string) => {
      try {
        const response = await Axios.get(`campaign/${draft}`)
        const data = response.data.data
        const { saved_draft_step, endDate, presave, content } =
          data.campaign_metadata
        const {
          style,
          boosts,
          options,
          platforms,
          release_url_upc_isrc,
          subtitle,
        } = presave
        const { bgType, bgColor, buttonConfig, primaryColor, secondaryColor } =
          style
        const { auto_presaves, boost_follows, collect_emails } = boosts
        const { timezone, redirect_url, redirect_to_custom_webpage } = options
        const { name, thumbnail_url } = content
        const parseTimezone = timeZoneParser("abbrevNoPrefix")
        const parsedTimezone = parseTimezone(timezone)
        const { value, abbrev } = parsedTimezone || {}
        const isValidReleaseUrl = validateReleaseUrlUriUpcIsrc(redirect_url)
        const draftPlatforms = getDraftPlatforms(platforms)

        track("Continued Creating Campaign from Draft", {
          type: "pre_save",
          step: saved_draft_step || STEPS.CUSTOMIZE,
        })

        saveDetails({
          releaseUrl: release_url_upc_isrc || "",
          releaseUrlValid: isValidReleaseUrl,
          releaseDate: endDate !== "Invalid date" ? endDate  || null : null,
          musicPlatforms:
            platforms && platforms.length
              ? draftPlatforms
              : availablePlatforms.map((p) => ({
                  name: p,
                  show: p !== "deezer",
                })),
          boosts: {
            collectEmails:
              typeof collect_emails === "boolean" ? collect_emails : true,
            boostFollows:
              typeof boost_follows === "boolean" ? boost_follows : true,
            autoPresaves:
              typeof auto_presaves === "boolean" ? auto_presaves : true,
          },
          timezone: { value: value || "", abbrev: abbrev || "" },
        })
        saveCustomize({
          artwork: thumbnail_url || null,
          releaseTitle: name || "",
          styleType: buttonConfig || StyleTypes.BOXES,
          backgroundType: bgType || BackgroundTypes.ARTWORK,
          primaryColor:
            primaryColor || website?.primaryColor || Colors.textColor,
          buttonColor:
            secondaryColor || website?.secondaryColor || Colors.textColor,
          sendFansToCustomWebPage: redirect_to_custom_webpage,
          customWebPageUrl: redirect_url || "",
          backgroundColor: bgColor || website?.bgColor || Colors.DARK_GRAY,
          subtitle: subtitle || "",
        })
        setSelectedTab(saved_draft_step || STEPS.CUSTOMIZE)
        setCampaignDraftData(data)
        setRetrievingDraftData(false)
      } catch (error) {
        console.log("error retrieving draft campaign data: ", error)
      }
    }

    if (draft) getDraft(draft)
    setIsSavingDraft(false)
  }, [draft])
}

export const useDraftDataSetter = (args: {
  setters: Record<string, Dispatch<unknown>>
  campaignDraftData?: Record<string, unknown> | null
  requiredDataChanged: boolean
  retrievingDraftData: boolean
  releaseTitle: string
  dataToSaveDraft: {
    customize: CustomizeContext
    details: DetailsContext
    selectedTab: STEPS
  },
  currentBrand: CurrentBrand,
  draft: string | null
  savingDraftStatus: number
}) => {
  const {
    setters,
    campaignDraftData,
    requiredDataChanged,
    releaseTitle,
    retrievingDraftData,
    dataToSaveDraft,
    currentBrand,
    draft,
    savingDraftStatus
  } = args
  const {
    setRequiredDataChanged,
    setIsSavingDraft,
    setCampaignDraftData,
    setSavingDraftStatus,
  } = setters
  const { customize, details, selectedTab } = dataToSaveDraft

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        async (
          dataToSend: { customize: CustomizeContext, details: DetailsContext, selectedTab: STEPS },
          campaignDraftData?: Record<string, unknown> | null
        ) => {
          if (draft && !campaignDraftData) return setIsSavingDraft(false)

          const { data, error } = await saveDraft({ dataToSend, currentBrand, campaignDraftData })

          if (!error && data) {
            const draftData = data as { campaign_metadata: Record<string, unknown> }
            const step = draftData.campaign_metadata.saved_draft_step

            track("Saved Draft", { type: "pre_save", step })
            setCampaignDraftData(data)
          }
          setIsSavingDraft(false)
        },
        DEBOUNCE_SAVE_DRAFT_TIME
      ),
    [campaignDraftData]
  )

  useEffect(() => {
    setRequiredDataChanged(true)
  }, [customize, details, selectedTab])

  useEffect(() => {
    if (requiredDataChanged && releaseTitle && !retrievingDraftData) {
      setSavingDraftStatus((prev: number) =>
        prev > FIRST_TIME_DRAFT_DATA_LOAD ? SAVING_NEXT_DRAFT : prev + 1
      )
    }
  }, [
    requiredDataChanged,
    releaseTitle,
    retrievingDraftData,
    selectedTab
  ])

  useEffect(() => {
    if (
      requiredDataChanged &&
      releaseTitle &&
      !retrievingDraftData &&
      savingDraftStatus === SAVING_NEXT_DRAFT
    ) {
      setIsSavingDraft(true)
      debouncedChangeHandler(dataToSaveDraft, campaignDraftData)
      setRequiredDataChanged(false)
    }
  }, [
    requiredDataChanged,
    releaseTitle,
    retrievingDraftData,
    campaignDraftData,
    dataToSaveDraft,
    savingDraftStatus
  ])

  const cancelSavingDraft = () => {
    setIsSavingDraft(false)
    debouncedChangeHandler.cancel()
  }

  useEffect(() => {
    return cancelSavingDraft
  }, [debouncedChangeHandler])

  return { cancelSavingDraft }
}
