import { ReactNode } from 'react';
import { Subtitle1 } from "components/shareable/Typography";
import { clsx } from 'modules/Utils';
import { objectToArrayInOrder } from "modules/Utils";
import GemIcon from "components/shareable/ProCallouts/GemIcon";
import { WEBSITE_TYPES, WebsiteType } from "types/global";
import {
    BasicWebsiteThemeIcon,
    ClassicWebsiteThemeIcon,
    FullImageWebsiteThemeIcon,
    OneFieldWebsiteThemeIcon,
    VinylWebsiteThemeIcon
} from "components/svg-icons/website/themes/icons";
import { useStyles } from '../../../styles';
import { dataCollectorThemeStyles, musicThemeStyles, THEME_STYLES, ThemeStyle, ThemeStyleKey, tourThemeStyles } from './utils';
import DefaultWebsiteThemeIcon from 'components/svg-icons/website/themes/default';
import { WebsiteEditorSection } from '../styles';
import UpgradeToUnlockCTA from '../../UpgradeToUnlockCTA';
import { PlanType } from "modules/Const";

const { BASIC, ART, CLASSIC, FULL_IMAGE, FULL_VIDEO, SIMPLE, VINYL } = THEME_STYLES;


/** Props for the presentational ThemeBox component */
interface ThemeBoxPresentationProps {
    /** Theme icon to display */
    icon?: ReactNode;
    /** Theme title */
    title: string;
    /** Whether the theme is selected */
    isSelected: boolean;
    /** Whether the theme is pro-only */
    isPaidOnly: boolean;
    /** Click handler */
    onClick: () => void;
}

/**
 * Presentational component for displaying a theme option
 */
export const ThemeBoxPresentation = ({
    icon,
    title,
    isSelected,
    isPaidOnly,
    onClick
}: ThemeBoxPresentationProps) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.themeBox, isSelected && classes.themeBoxSelected)}
            onClick={onClick}
        >
            {icon}
            {title}
            {isPaidOnly && <GemIcon color='purple' />}
        </div>
    );
};

interface ThemeBoxProps {
    /** Current theme style object containing name, title and pro status */
    currentThemeStyle: ThemeStyle;
    /** Currently selected theme name */
    selectedTheme: ThemeStyleKey;
    /** Callback when theme is selected */
    onThemeSelect: (name: ThemeStyleKey) => void;
}

/**
 * Individual theme selection box component
 */
const ThemeBox = ({ currentThemeStyle, selectedTheme, onThemeSelect }: ThemeBoxProps) => {
    const { name, title, isPaidOnly } = currentThemeStyle;
    const isThemeSelected = selectedTheme === name;

    /** Get appropriate theme icon based on theme name */
    const getThemeIcon = (): ReactNode => {
        switch (name) {
            case FULL_IMAGE:
            case FULL_VIDEO:
                return <FullImageWebsiteThemeIcon selected={isThemeSelected} />;
            case VINYL:
                return <VinylWebsiteThemeIcon selected={isThemeSelected} />;
            case ART:
                return <DefaultWebsiteThemeIcon selected={isThemeSelected} />;
            case BASIC:
                return <BasicWebsiteThemeIcon selected={isThemeSelected} />;
            case SIMPLE:
                return <OneFieldWebsiteThemeIcon selected={isThemeSelected} />;
            case CLASSIC:
                return <ClassicWebsiteThemeIcon selected={isThemeSelected} />;
            default:
                return null;
        }
    };

    return (
        <ThemeBoxPresentation
            icon={getThemeIcon()}
            title={title}
            isSelected={isThemeSelected}
            isPaidOnly={isPaidOnly}
            onClick={() => onThemeSelect(name)}
        />
    );
};

interface ThemeSelectorSectionProps {
    /** Type of website being customized */
    websiteType: WebsiteType;
    /** Currently selected theme */
    selectedTheme: ThemeStyleKey;
    /** Whether user has pro access */
    isPaidUser: boolean;
    /** Record type if applicable */
    recordType?: string;
    /** Callback when theme is selected */
    onThemeSelect: (theme: ThemeStyleKey) => void;
}

/**
 * Theme selector section component that displays available themes based on website type
 */
export const ThemeSelectorSection = ({
    websiteType,
    selectedTheme,
    isPaidUser,
    recordType,
    onThemeSelect
}: ThemeSelectorSectionProps) => {
    /** Get appropriate theme styles based on website type */
    const getThemeStyles = () => {
        let themeStyles: Partial<typeof musicThemeStyles> = musicThemeStyles;

        switch (websiteType) {
            case WEBSITE_TYPES.RECORD_STREAMS:
                // Handle playlist case by removing vinyl theme
                if (recordType === 'playlist') {
                    const { vinyl, ...rest } = musicThemeStyles;
                    themeStyles = rest;
                }
                break;
            case WEBSITE_TYPES.DATA_COLLECTOR:
                themeStyles = dataCollectorThemeStyles;
                break;
            case WEBSITE_TYPES.TOUR:
                themeStyles = tourThemeStyles;
                break;
            default:
                themeStyles = musicThemeStyles;
        }
        return themeStyles;
    };

    const themeStyles = getThemeStyles();
    type ThemeStylesType = keyof typeof themeStyles;

    return (
        <WebsiteEditorSection>
            <Subtitle1>Theme</Subtitle1>
            <div className="flex gap-4 overflow-scroll">
                {objectToArrayInOrder(themeStyles).map((themeStyle) => (
                    <ThemeBox
                        key={themeStyle.name}
                        currentThemeStyle={themeStyle}
                        selectedTheme={selectedTheme}
                        onThemeSelect={onThemeSelect}
                    />
                ))}
            </div>
            <UpgradeToUnlockCTA
                highlightedPlan={PlanType.LITE}
                requiresPaidPlan={Boolean(themeStyles[selectedTheme as ThemeStylesType]?.isPaidOnly && !isPaidUser)}
                upgradeSource="Website Editor - Theme Selector"
                buttonText="Upgrade to unlock Premium Themes"
                upgradePopupCallToAction="Unlock unlimited Website Customization with Symphony Lite"
                paywallText="You've selected a Lite-only theme. You'll need to upgrade to Lite before publishing your site."
            />
        </WebsiteEditorSection>
    );
};