import {
  useEffect,
  useState,
  useContext,
} from "react"
import HeaderModal from "../../../HeaderModal"
import { getRecentReleases } from "../../api"
import { DuplicateSteps } from "../../utils"
import {
  OptionSelectionContainer,
  MainContainer,
} from "./style"
import {
  Release,
} from "types/global"
import { CAMPAIGN_TYPES } from "modules/Const"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import SharedMusicViewSelector from "../../../SuperboostModal/Components/shared-music-selector-view"

const { SELECT_DUPLICATE_OPTION } = DuplicateSteps

const { record_streams: RECORD_STREAMS } = CAMPAIGN_TYPES

interface Props {
  errorRecordDetails: boolean
  loadingRecordDetails: boolean
  campaignType: string
  isSpotifyConnected: boolean
  handleChangeRecord: (inputValue: string, slug: string | null) => void
  handleGoBack: (step: DuplicateSteps) => void
  closeModal: () => void
}

const ChooseAnotherProjectView = ({
  errorRecordDetails,
  loadingRecordDetails,
  campaignType,
  isSpotifyConnected,
  handleChangeRecord,
  closeModal,
  handleGoBack,
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)

  const [recentReleases, setRecentReleases] = useState<Release[] | null>(null)
  const [errorReleases, setErrorReleases] = useState(false)
  const [loadingReleases, setLoadingReleases] = useState(false)

  const goBack = () => handleGoBack(SELECT_DUPLICATE_OPTION)

  useEffect(() => {
    (async () => {
      if (
        isSpotifyConnected &&
        !recentReleases &&
        campaignType === RECORD_STREAMS
      ) {
        setLoadingReleases(true)
        const { data, error } = await getRecentReleases(currentBrand)

        if (data && !error) {
          setRecentReleases(data)
        } else {
          setErrorReleases(true)
        }
        setLoadingReleases(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBrand, isSpotifyConnected, campaignType])

  if (!currentBrand) {
    return null
  }

  return (
    <MainContainer>
      <HeaderModal
        title="Duplicate campaign"
        subtitle="Select the release you want to promote"
        closeModal={closeModal}
        handleGoBack={goBack}
      />
      <OptionSelectionContainer>
        <SharedMusicViewSelector
          isSpotifyConnected={isSpotifyConnected}
          parentLoading={loadingRecordDetails}
          parentError={errorRecordDetails}
          handleChangeRecord={handleChangeRecord}
          options={['single', 'project', 'playlist']}
        />

      </OptionSelectionContainer>
    </MainContainer>
  )
}

export default ChooseAnotherProjectView
