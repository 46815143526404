import { CSSProperties, useContext, useState } from "react";
import { BottomNavigation, BottomNavigationAction, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, SvgIcon, Theme } from "@material-ui/core";
import { SupportIcon } from "components/svg-icons";
import { Link, useLocation } from "react-router-dom";
import { NavigationItem } from "./Navbar";
import SubscriptionStatusIndicator from "./Components/SubscriptionStatusIndicator";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import Colors from "modules/Colors";

const GRAY_MENU_COLOR = "#888888";
const NAVIGATION_FONT_SIZE = 11;

const useStyles = makeStyles((theme: Theme) => ({
    overall: {
        fontFamily: "DIN",
        fontWeight: 'normal'
    },
    bgColor: {
        background: "#2C2135"
    },
    grayColor: {
        color: GRAY_MENU_COLOR
    },
    selected: {
        color: "white",
    },
    menuIcon: {
        color: GRAY_MENU_COLOR, // The gray color for the Menu icon
    },
    bottomNavAction: {
        padding: '8px 12px !important'  // Using !important to override Material-UI default styles
    }
}));

interface MobileNavigationProps {
    navigationItems: NavigationItem[];
}

/**
 * Looks through the NavigationItem array to find the info for the currently active page
 * @param location the current page location
 * @param navigationItems all of the navigation items
 * @returns 
 */
const getCurrentlySelectedPage = (
    location: {
        pathname: string;
    },
    navigationItems: NavigationItem[]
): NavigationItem | undefined => {
    const pathname = location.pathname;
    // first search the main HREFs, then the alternate HREFs to see if it matches
    const selectedPage = navigationItems.find((navItem: NavigationItem) => {
        const {
            href: navItemHref,
            alternateHrefs,
            // name
        } = navItem;

        if (navItemHref === pathname) {
            return true
        }

        if (alternateHrefs && alternateHrefs.indexOf(pathname) > -1) {
            return true
        }

        return false
    });

    return selectedPage;
}

const MobileNavigation = ({
    navigationItems
}: MobileNavigationProps) => {
    const classes = useStyles();
    const location = useLocation();
    const { isProBrand, proFreeTrialRedeemed } = useContext(CurrentBrandContext)
    const { handleOpenModal } = useContext(UpgradeModalContext);
    const { organization } = useCurrentTeam();

    let freeTrialOver = !proFreeTrialRedeemed()
    // location used to check if item selected
    const currentNavigationItem = getCurrentlySelectedPage(location, navigationItems);

    // to handle opening / closing mobile drawer
    const [drawerOpen, setOpenDrawer] = useState<boolean>(false)

    const [value, setValue] = useState(0);

    // Filter navigation items for the BottomNavigation bar
    const bottomNavigationItems = navigationItems.filter((navItem: NavigationItem) => navItem.showOnMobileMainMenu);

    // Filter navigation items for the Drawer
    const drawerNavigationItems = navigationItems.filter((navItem: NavigationItem) => !navItem.showOnMobileMainMenu);

    const showUpgradeToProButton = () => {
        if (isProBrand) {
            return null
        }

        // only show upgrade to Pro on Dashboard
        if (currentNavigationItem?.name === "Dashboard") {
            return (
                <BottomNavigation
                    className={`bottomNavigationContainer ${classes.bgColor}`}
                >
                    <SubscriptionStatusIndicator
                        maxWidth="100%"
                        width="100%"
                        margin="auto"
                        text={freeTrialOver ? "Upgrade to Pro" : "Start 14-day Free Pro Trial"}
                        showStars
                        handleOpenModal={() => handleOpenModal({
                            source: "Mobile Dashboard - Bottom Bar"
                        })}
                    />
                </BottomNavigation>
            )
        }
    }

    return (
        <Box className="mobileNavigationComponent lg:hidden">
            <Box className="mobileNavigationInner" position="fixed" bottom={0} left={0} right={0} zIndex={99}>
                {showUpgradeToProButton()}
                <BottomNavigation
                    className={`${classes.bgColor} px-2`}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                >
                    {bottomNavigationItems.map((item: NavigationItem, index: number) => {
                        // ... rest of your code, including styling for selected items
                        const isSelected = currentNavigationItem && currentNavigationItem.name === item.name;

                        const IconComponent = item.iconComponent;
                        const color = isSelected ? "white" : GRAY_MENU_COLOR;

                        return (
                            <BottomNavigationAction
                                key={`${item.name}-${index}`}
                                className={classes.bottomNavAction}
                                disableRipple
                                component={Link} to={item.href!}
                                icon={IconComponent &&
                                    <div style={sty.iconContainer}>
                                        <IconComponent color={color} />
                                        <div style={{
                                            color: color,
                                            fontSize: NAVIGATION_FONT_SIZE,
                                            marginTop: 4,
                                            lineHeight: 1,
                                            marginBottom: isSelected ? 4 : 0
                                        }}>
                                            {item.name}
                                        </div>
                                        <div style={isSelected ? sty.iconDividerSelected : sty.iconDividerBlank} />
                                    </div>
                                }
                            />
                        );
                    })}
                    <BottomNavigationAction
                        className="mobileNavigationBottomNavigationAction"
                        disableRipple
                        icon={
                            <IconButton
                                style={{ color: GRAY_MENU_COLOR }}
                                onClick={() => setOpenDrawer(true)}
                            >
                                <div style={sty.iconContainer}>
                                    <SupportIcon color={GRAY_MENU_COLOR} />
                                    <div style={{ color: GRAY_MENU_COLOR, fontSize: NAVIGATION_FONT_SIZE }}>
                                        Chat
                                    </div>
                                    <div style={sty.iconDividerBlank} />
                                </div>
                            </IconButton>
                        }
                    />
                </BottomNavigation>
            </Box>

            <Drawer
                className="mobileNavigationDrawer"
                anchor="bottom"
                open={drawerOpen}
                onClose={() => setOpenDrawer(false)}
            >
                <List>
                    {drawerNavigationItems.map((navItem: NavigationItem, index: number) => {
                        if (navItem.needsOrganization && !organization) {
                            return null;
                        }

                        const isSelected = currentNavigationItem && currentNavigationItem.name === navItem.name;

                        const additionalProps: any = {}

                        if (navItem.onClick) {
                            additionalProps['onClick'] = (e: any) => {
                                setOpenDrawer(false);
                                if (navItem.onClick) {
                                    navItem!.onClick(e)
                                }
                            }
                        } else {
                            additionalProps['component'] = Link
                            additionalProps['to'] = navItem.href!
                            additionalProps['onClick'] = () => { setOpenDrawer(false) }
                        }

                        return (
                            <ListItem
                                key={`${navItem.name}-${index}`}
                                className="mobileNavigationListItem"
                                button
                                {...additionalProps}
                            >
                                <ListItemIcon
                                    className="mobileNavigationListItemIcon mr-4"
                                    style={{ minWidth: 0 }}
                                >
                                    <SvgIcon
                                        htmlColor={isSelected ? "#8800FF" : "#707070"}
                                        fontSize="small"
                                        viewBox={navItem.svgIconViewbox!}
                                    >
                                        {navItem.iconPath}
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText
                                    className={`listItemTextElement ${classes.overall}`}
                                    style={{ color: isSelected ? "#8800FF" : "#707070" }}
                                    primary={navItem.name}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Drawer>
        </Box>
    )
}

const sty: Record<string, CSSProperties> = {
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1
    },
    iconDividerSelected: {
        marginTop: 1,
        width: 24,
        height: 1.5,
        backgroundColor: Colors.purpleLight,
        borderRadius: 100,
        transition: 'all 0.2s ease'
    },
    iconDividerBlank: {
        marginTop: 0,
        width: 24,
        height: 0,
        backgroundColor: Colors.purpleDark,
        borderRadius: 100
    }
}

export default MobileNavigation
