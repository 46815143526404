import { CSSProperties, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoaderFullScreen from "../components/Loader/LoaderFullScreen";
import Logger from "Logger";
import PostAuthRoutes from "./PostAuthRoutes";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useHistory, useLocation } from "react-router-dom";
import { CAMPAIGN_WIZARD_ROUTES } from "modules/Const";
import Navbar from "components/Navbar/Navbar";
import { FanbaseProvider } from "Hooks/FanbaseContext";
import UpgradeModalProvider from "Hooks/UpgradeModalContext";
import NavbarProvider from "pages/post-auth/MarketingPage/Data/NavbarProvider";
import GettingStartedProvider from "Hooks/GettingStartedContext";
import AlertContext from "Hooks/AlertContext";
import useScreen from "Hooks/useScreen";

const getBackgroundColor = (location: string) => {
  const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.filter(
    (route) => !route.includes("success")
  ).includes(location)
  if (isCampaignWizard || location.includes("add-artist") || location.includes("settings") || location.includes("fans") || location.includes("marketing/campaigns")) {
    return "#F5F3F6";
  }
  return "white";
};

const InitializeApp = () => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    logout,
    loginWithRedirect
  } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  // The context that manages user session
  const {
    currentUser,
    initializeUserSession,
    isInitializing,
    loadedBrands,
    loadingBrands,
  } = useContext(CurrentUserContext);
  const { setCurrentBrand } = useContext(CurrentBrandContext);
  const { organization } = useCurrentTeam();
  const { cssCalc } = useScreen();

  const [canDisplayRoutes, setCanDisplayRoutes] = useState(false);

  /**
   * On mount (once `auth0Loading` is complete), decide:
   * 1) If authenticated, initialize session
   * 2) Otherwise, log out or redirect
   */
  useEffect(() => {
    if (!auth0Loading) {
      if (isAuthenticated) {
        // Add guards against re-initialization
        if (isInitializing || currentUser) {
          return;
        }

        initializeUserSession().catch(err => {
          Logger.error("Error initializing session:", err);
          logout({ returnTo: window.location.origin });
        });
      } else {
        // Option A: Force redirect to Auth0 login
        loginWithRedirect();
      }
    }
  }, [auth0Loading, isAuthenticated, initializeUserSession, logout]);

  /** 
   * Handles post-authentication user setup and routing:
   * Waits for user and brands data to fully load, then performs:
   * 1. Email verification check
   * 2. Brand existence check and initial brand selection
   * 3. Organization setup check
   */
  useEffect(() => {
    // If we've already set the initial route, don't do it again
    if (canDisplayRoutes) {
      return;
    }
    // Wait for both user and brands to finish loading
    if (!currentUser || loadingBrands) {
      setCanDisplayRoutes(false);
      return;
    }

    // Handle routing based on user state
    if (!currentUser.email) {
      history.push("/email");
      setCanDisplayRoutes(true);
    } else if (!loadedBrands.length) {
      history.push("/add-artist");
      setCanDisplayRoutes(true);
    }
    
    if (loadedBrands.length) {
      // Set initial brand if we have brands
      const cachedBrand = localStorage.getItem("selected-brand");
      let selectedBrand;

      if (cachedBrand) {
        selectedBrand = loadedBrands.find(
          (brand) => brand.id === parseInt(cachedBrand)
        ) || loadedBrands[0];
      } else {
        selectedBrand = loadedBrands[0];
      }

      // Save selected brand ID to localStorage
      localStorage.setItem("selected-brand", String(selectedBrand.id));
      setCurrentBrand(selectedBrand);

      // if the organization is in the waiting_for_details state, redirect to the team page
      if (organization?.status === "waiting_for_details") {
        history.push("/team");
      }
    }

    // Enable routes after all checks and brand selection
    setCanDisplayRoutes(true);
  }, [currentUser, loadedBrands, loadingBrands, canDisplayRoutes]);

  // Render a full-screen loader while:
  // 1) Auth0 is still deciding if the user is authenticated
  // 2) Our app is still loading user data from the backend
  // 3) We manually set `uiLoading` for certain UI transitions
  if (auth0Loading || isInitializing || !canDisplayRoutes) {
    return <LoaderFullScreen />;
  }

  // If we do have a user, show the main app routes
  if (currentUser) {

    const backgroundColor = getBackgroundColor(location.pathname)
    const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.filter(
      (route) => !route.includes("success")
    ).includes(location.pathname)

    return (
      <div
        className="initializeAppComponent"
        style={{
          ...sty.initializeAppComponent,
          height: cssCalc()
        }}
      // {...{ backgroundColor }}
      // height={isCampaignWizard ? "auto" : "100%"}
      >
        <UpgradeModalProvider>
          <GettingStartedProvider>
            <FanbaseProvider>
              <AlertContext>
                <NavbarProvider>
                  <Navbar />
                  <PostAuthRoutes />
                </NavbarProvider>
              </AlertContext>
            </FanbaseProvider>
          </GettingStartedProvider>
        </UpgradeModalProvider>
      </div>
    )
  }

  // Otherwise, fallback: 
  // In your scenario, we’d rather not show <AuthLayout />, so 
  // we can do one of two things:
  //   A) Force a logout 
  //   B) Show a loader again 
  //   C) Or forcibly redirect 
  // We’ll just do a final “logout” for safety.
  logout({ returnTo: window.location.origin });
  return <LoaderFullScreen />;
};

export default InitializeApp;

const sty = {
  initializeAppComponent: {
    display: "flex",
    width: "100%",
  } as CSSProperties
}