import { EmptyFanbaseContainer, EmptyFanbaseHeader, EmptyFanbaseLabel } from "./styles";
import useScreen from "Hooks/useScreen";

const EmptyFanbase = () => {
  const { mobileView } = useScreen();

  return (
    <EmptyFanbaseContainer
      className="emptyFanbaseComponent"
      gap="0px"
      width={mobileView ? "95%" : "70%"}
    >
      <EmptyFanbaseHeader>
        Create a pre-save campaign to start building your fans list.
      </EmptyFanbaseHeader>
      <EmptyFanbaseLabel />
    </EmptyFanbaseContainer>
  );
}

export default EmptyFanbase