import { Subtitle1 } from "components/shareable/Typography"
import { WebsiteEditorSection } from "../styles"
import Colors from "modules/Colors";
import { clsx } from 'modules/Utils';
import { objectToArrayInOrder } from "modules/Utils";
import { useStyles } from '../../../styles';
import { TitleAlignment, titleAlignmentThemeStyles } from "./utils";

/**
 * Props for the TitleAlignmentBox component
 * @interface TitleAlignmentBoxProps
 */
interface TitleAlignmentBoxProps {
    /** The current title alignment style object */
    currentTitleAlignmentStyle: any; // TODO: Add proper type
    /** Index of the alignment option in the list */
    index: number;
    /** Currently selected title alignment value */
    selectedTitleAlignment: TitleAlignment;
    /** Callback function to handle title alignment changes */
    onTitleAlignmentChange: (titleAlignment: TitleAlignment) => void;
}

/**
 * Renders a selectable box containing a title alignment preview and selection indicator
 * @param {TitleAlignmentBoxProps} props - Component props
 * @returns {JSX.Element} Title alignment selection box component
 */
const TitleAlignmentBox = ({
    currentTitleAlignmentStyle,
    index,
    selectedTitleAlignment,
    onTitleAlignmentChange
}: TitleAlignmentBoxProps): JSX.Element => {
    const classes = useStyles();
    const isSelected = selectedTitleAlignment === currentTitleAlignmentStyle.name;

    const renderTitleAlignmentIcon = (titleAlignment: string, isSelected: boolean) => {
        const titleAlignmentContainerStyle = { display: 'flex', flexDirection: 'column', gap: 2, padding: '0 4px' } as React.CSSProperties;
        let barColor = Colors.greyDark;
        if (isSelected) {
            barColor = Colors.purple;
        }
        const topBarStyle = { height: 7, width: 24, backgroundColor: barColor, borderRadius: 20 } as React.CSSProperties;
        const bottomBarStyle = { height: 7, width: 12, backgroundColor: barColor, borderRadius: 20 } as React.CSSProperties;

        switch (titleAlignment) {
            case 'left':
                return (
                    <div style={titleAlignmentContainerStyle}>
                        <div style={topBarStyle} />
                        <div style={bottomBarStyle} />
                    </div>
                )
            case 'center':
                return (
                    <div style={titleAlignmentContainerStyle}>
                        <div style={topBarStyle} />
                        <div style={{ ...bottomBarStyle, marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                )
            case 'right':
                return (
                    <div style={titleAlignmentContainerStyle}>
                        <div style={topBarStyle} />
                        <div style={{ ...bottomBarStyle, marginLeft: 'auto' }} />
                    </div>
                )
        }
    };

    return (
        <div
            className={clsx(classes.themeBox, isSelected && classes.themeBoxSelected)}
            onClick={() => {
                onTitleAlignmentChange(currentTitleAlignmentStyle.name)
            }}
        >
            {renderTitleAlignmentIcon(currentTitleAlignmentStyle.name, isSelected)}
        </div>
    );
};


/**
 * Props for the TextAlignmentSection component
 * @interface TextAlignmentSectionProps
 */
interface TextAlignmentSectionProps {
    /** Currently selected title alignment value */
    selectedTitleAlignment: TitleAlignment;
    /** Callback function to handle title alignment changes */
    onTitleAlignmentChange: (titleAlignment: TitleAlignment) => void;
}

/**
 * Renders a section that allows users to select the title alignment for their website
 * @param props - Component props
 * @returns React component for selecting title alignment
 */
const TextAlignmentSection = ({
    selectedTitleAlignment,
    onTitleAlignmentChange
}: TextAlignmentSectionProps) => {
    const classes = useStyles();
    return (
        <WebsiteEditorSection>
            <Subtitle1>Text Alignment</Subtitle1>
            <div className={classes.renderSelectorBoxRow}>
                {objectToArrayInOrder(titleAlignmentThemeStyles).map((ta, tai) => {
                    return <TitleAlignmentBox
                        key={`titleAlignmentBox-${tai}-${ta.name}`}
                        currentTitleAlignmentStyle={ta}
                        index={tai}
                        selectedTitleAlignment={selectedTitleAlignment}
                        onTitleAlignmentChange={onTitleAlignmentChange}
                    />
                })}
            </div>
        </WebsiteEditorSection>
    )
}

export default TextAlignmentSection;
