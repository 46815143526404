import { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import CircularStep from "../CircularStep";
import { StyledLabel, Card, useStyles } from "./styles";
import { Container } from 'styles/shared';
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import getSymbolFromCurrency from "currency-symbol-map";
import useScreen from "Hooks/useScreen";

interface Props {
  budget: number;
  adAccountName: string;
  expressSetupOrCustomPages?: 'custom' | 'express';
}

const format = (budget: number, showDigits?: boolean) => {
  const numberOfDigits = showDigits ? 2 : 0

  return budget.toLocaleString("en-US", {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
  })
}

const BudgetCard = ({ budget, adAccountName, expressSetupOrCustomPages = 'custom' }: Props) => {
  const classes = useStyles();
  const { mobileView } = useScreen()

  const adsComission = 0
  const { currency } = useContext(SpendBudgetContext)

  let adSpendDisclaimer = "You'll be charged this amount upfront, and all budget will be spent on your Symphony IG Ads campaign."
  if (expressSetupOrCustomPages === 'custom') {
    adSpendDisclaimer = `This amount will be charged directly to your Meta Ad Account (${adAccountName}). ${currency !== 'USD' && `Your ad account's currency is set to ${currency}.`}`

  }
  return (
    <Card borderRadius={mobileView ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={3} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Budget
            </StyledLabel>
          </Grid>
        </Grid>
        <Container className={clsx(classes.space, classes.border)}
          display="flex" flexDirection="row"
          justifyContent="space-between">
          <Container width="75%" justifyContent="flex-start">
            <StyledLabel fontSize={16} fontWeight={600}>
              Marketing Budget
            </StyledLabel>
            <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
              {adSpendDisclaimer}
            </StyledLabel>
          </Container>

          <Container width="25%" display="flex" justifyContent="flex-end">
            <StyledLabel fontSize={16} fontWeight={400}>
              {getSymbolFromCurrency(currency)}
              {format(budget - adsComission * budget, true)}
            </StyledLabel>
          </Container>
        </Container>
      </Grid>
    </Card>
  );
};

export default BudgetCard;
