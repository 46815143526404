import { useContext, useEffect, useState } from "react";
import { getOrganizationCampaigns } from "services/symphonyApi/organizationService";
import { CurrentBrand, OrganizationCampaign } from "types/global";
import { Body2, Caption } from "components/shareable/Typography";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import TeamTabSection from "../../TeamTabSection";
import { GridColDef } from "@mui/x-data-grid";
import { OrganizationViewDefaultGrid } from "../OrganizationViewDefaultGrid";
import dayjs from "dayjs";
import Axios from "helpers/Interceptor";
import getSymbolFromCurrency from "currency-symbol-map";
import { formatStatusName, formatCampaignType, getCampaignIcon, getStyleByStatus, getActualStatus } from './utils';
import BrandSelectionModal from "components/shareable/CreateCampaignModal/SelectBrandModal";
import TableEmptyState from "./TableEmptyState";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import CreateNewCampaignModal from "pages/post-auth/MarketingPage/Components/CreateNewCampaignModal/CreateNewCampaignModal";
import Colors from "modules/Colors";

const PAGE_SIZE = 8;

interface Props {
  organizationBrands: CurrentBrand[];
}

export const OrganizationMarketingTab = ({ organizationBrands }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<OrganizationCampaign[]>([]);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const { spoofedUserId } = useContext(CurrentUserContext);
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);
  const history = useHistory();
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);

  const fetchCampaigns = async (page: number) => {
    setIsLoading(true);
    const offset = page * PAGE_SIZE;
    const response = await getOrganizationCampaigns(offset, PAGE_SIZE, spoofedUserId ? {
      spoofedUserId: spoofedUserId
    } : undefined);
    if (response) {
      setCampaigns(response.campaigns);
      setTotalCampaigns(response.total);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCampaigns(page);
  }, [page]);


  const handleBrandSelection = async ({ brandId, campaignId }: { brandId: number, campaignId: number }) => {
    try {
      if (currentBrand?.id === brandId) {
        history.push(`/marketing/campaigns/${campaignId}`, { from: 'TEAM' });
      }

      setIsLoading(true)
      const response = await Axios.get(`/brand/${brandId}`);
      const newBrand = response.data.data as CurrentBrand;
      if (newBrand) {
        setCurrentBrand(newBrand);
        history.push(`/marketing/campaigns/${campaignId}`, { from: 'TEAM' });
      } else {
        throw new Error("Brand not found");
      }
    } catch (error) {
      toast.error("Error getting brand information", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setIsLoading(false)
    }
  }

  const columns: GridColDef[] = [
    {
      field: "profile",
      headerName: "Profile",
      flex: 1.5,
      renderCell: (params) => (
        <div className="flex items-center gap-3">
          <img
            src={params.row.brandImage}
            alt={params.row.brandName}
            className="w-10 h-10 rounded-full object-cover"
          />
          <Body2>{params.row.brandName}</Body2>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const actualStatus = getActualStatus(params.row);
        return (
          <div className="flex gap-2 rounded py-1 px-2" style={{ background: getStyleByStatus(actualStatus).background }}>
            <Body2 color={getStyleByStatus(actualStatus).color}>
              {formatStatusName(actualStatus)}
            </Body2>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Campaign Name",
      flex: 2,
      renderCell: (params) => (
        <div className="flex flex-col gap-1">
          <Body2>{params.row.campaignName}</Body2>
          <div className="inline-flex w-fit gap-2 rounded py-1 px-2" style={{
            width: 'fit-content',
            background: Colors.PAPER_ACCENT_CONTAINER
          }}>
            <img src={getCampaignIcon(params.row.type)} style={{ height: 18, width: 18 }} alt="campaign_type" />
            <Caption color={Colors.textColor}>
              {formatCampaignType(params.row.type)}
            </Caption>
          </div>
        </div>
      ),
    },
    {
      field: "budget",
      headerName: "Budget",
      flex: 1,
      renderCell: (params) => (
        <Body2>
          {params.row.budget
            ? `${getSymbolFromCurrency(params.row.currency)}${Number(params.row.budget).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            : '-'}
        </Body2>
      ),
    },
    {
      field: "length",
      headerName: "Campaign Length",
      flex: 2,
      renderCell: (params) => {
        const startDate = params.row.startDate ? dayjs(params.row.startDate).format('MMM DD, YYYY') : '-';
        const endDate = params.row.endDate ? dayjs(params.row.endDate).format('MMM DD, YYYY') : '-';
        const length = params.row.startDate && params.row.endDate
          ? `${dayjs(params.row.endDate).diff(dayjs(params.row.startDate), 'day')} days`
          : '-';

        return (
          <div className="flex flex-col gap-1">
            <Body2>{`${startDate} - ${endDate}`}</Body2>
            <Caption color={Colors.greyDark}>{length}</Caption>
          </div>
        );
      },
    },
  ];

  const handleCreateCampaign = () => {
    setIsBrandModalOpen(true);
  };

  const handleBrandSelect = (brandId: string) => {
    const selectedBrand = organizationBrands.find(brand => brand.id === Number(brandId));
    if (selectedBrand) {
      setCurrentBrand(selectedBrand);
      setIsBrandModalOpen(false);
      setIsCreateCampaignModalOpen(true);
    }
  };

  return (
    <>
      <TeamTabSection
        title="Marketing Campaigns"
        buttonText="Create a Campaign"
        onButtonClick={handleCreateCampaign}
        showDividers
      >
        <OrganizationViewDefaultGrid
          className="organizationMarketingGrid"
          rows={campaigns}
          columns={columns}
          onRowClick={({ row }) => {
            handleBrandSelection({ brandId: row.brandId, campaignId: row.id })
          }}
          hideFooter={false}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          itemsPerPage={PAGE_SIZE}
          rowCount={totalCampaigns}
          itemName="Campaigns"
          isLoadingData={isLoading}
          paginationMode="server"
          emptyStateComponent={
            <TableEmptyState
              className="organizationMarketingTableEmptyState"
              style={{ marginTop: 10 }}
              primaryText="One place to view and manage your team's campaigns."
              buttonText="Create your First Campaign"
              buttonAction={handleCreateCampaign}
            />
          }
        />
      </TeamTabSection>

      <BrandSelectionModal
        className="organizationMarketingTabBrandSelectionModal"
        isOpen={isBrandModalOpen}
        onClose={() => setIsBrandModalOpen(false)}
        brands={organizationBrands}
        onBrandSelect={handleBrandSelect}
        titleText="Select a Profile"
        subtitleText="Choose the profile you want to create a campaign for."
      />

      <CreateNewCampaignModal
        isOpen={isCreateCampaignModalOpen}
        onClose={() => setIsCreateCampaignModalOpen(false)}
      />
    </>
  );
};
