import { Body2, Subtitle2 } from 'components/shareable/Typography';
import { ColumnContainer } from './styles';

interface DescriptionProps {
  title: string;
  details: string;
  titleColor?: string;
  detailsColor?: string;
}

const Description = ({
  title,
  details,
  titleColor,
  detailsColor,
}: DescriptionProps) => {
  return (
    <ColumnContainer>
      <Subtitle2 color={titleColor}>{title}</Subtitle2>
      <Body2 color={detailsColor}>{details}</Body2>
    </ColumnContainer>
  );
}

export default Description;