import { Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import useStyles, {
  DropDownBorder,
  DropDownIcon,
  ItemContainer,
  RowContainer,
  SelectLanguageButton,
  SearchContainer,
  LanguageList as StyledLanguageList,
  DropDownListContainer,
  DropDownChipContainer,
  StyledLabel,
  ChipContainer,
  DropDownButton
} from './styles';
import DropdownArrowIcon from "assets/images/chevronDown.svg";
import AutomatedIcon from 'assets/images/automated.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-icon-2.svg';
import { getLocales } from 'helpers/FB.js';
import debounce from 'lodash/debounce';
import { FB_COMMON_LANGUAGES } from '../../constants';
import CloseIcon from "components/shareable/CloseIcon";
import useVisible from 'Hooks/useVisible';
import { ReactComponent as CheckBoxEmptyIcon } from 'assets/images/checkbox.svg';
import { ReactComponent as CheckBoxCheckedIcon } from 'assets/images/checkboxChecked.svg';
import { LanguageChip } from 'types/global';
import useScreen from 'Hooks/useScreen';

interface Props {
  locales: Array<LanguageChip>;
  setLocales: (values: Array<LanguageChip>) => void;
  disabled?: boolean;
  setWarning?: (value: boolean) => void;
}

const sortLanguageList = (locales: LanguageChip[]) => {
  return locales.sort((a: LanguageChip, b: LanguageChip) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}

const getDropDownSectionTitle = (title: string) => {
  return <ItemContainer
    display="flex"
    alignItems="center"
    flexDirection='row'
    key={title}
    width='100%'
    padding='24px 24px 8px'
    gap='16px'
    borderBottom='1px solid #F2F2F2'
  >
    <StyledLabel
      fontSize={12}
      lineHeight='15px'
      color='#707070'
    >{title}</StyledLabel>
  </ItemContainer>
}

const getDropDownItem = (element: LanguageChip, identifier: number, isSelected: boolean, onClickFunction: (value: LanguageChip) => void) => {
  return <ItemContainer
    display="flex"
    alignItems="center"
    flexDirection='row'
    key={`${element.name}-${identifier}`}
    width='100%'
    justifyContent='space-between'
    padding='4px 24px'
    gap='16px'
    borderBottom='1px solid #F2F2F2'
    cursor='pointer'
    onClick={() => onClickFunction(element)}
  >
    <ItemContainer
      display='flex'
      flexDirection='row'
      alignItems='center'
      padding='0'
      gap='8px'
    >
      { isSelected ? <CheckBoxCheckedIcon width={24} height={24} /> : <CheckBoxEmptyIcon width={24} height={24} /> }
      {element.name}
    </ItemContainer>
    <SelectLanguageButton>
      { isSelected ? 'Remove' : 'Add' }
    </SelectLanguageButton>
  </ItemContainer>
}

const DropdownChip = ({
  locales,
  setLocales,
  disabled,
  setWarning
}: Props) => {
  const { mobileView } = useScreen()

  const [itemsState, setItemsState] = useState<{default: boolean, locales: Array<LanguageChip>}>({
    default: true,
    locales
  });
  const [searchValue, setSearchValue] = useState<string>('');

  const { targetContent, toggleElement, isVisible, setIsVisible } = useVisible(false);

  const handleOpenDropdown = () => {
    if (disabled) {
      handleWarning();
      return;
    }

    setIsVisible(!isVisible)
  }

  const handleWarning = () => setWarning?.(true);

  const handleOnToggleDropDown = (event: MouseEvent<HTMLDivElement, any>) => {
    disabled ? handleWarning() : handleOpenDropdown();
    event.stopPropagation()
  }

  const handleOnClickLanguageChip = (e: MouseEvent<HTMLParagraphElement, any>) => {
    if (!disabled) setIsVisible(true);
    e.stopPropagation();
  }

  const handleOnDeleteChip = (event: MouseEvent<HTMLDivElement, any>, item: LanguageChip) => {
    onDeleteChip(item);
    event.stopPropagation();
  }

  const onClickItem = (value: LanguageChip) => {
    if (value.selected) {
      const filteredItems = locales.filter((item: LanguageChip) => item.key !== value.key);
      setLocales(filteredItems);
      return;
    }
    setLocales([...locales, value]);
  }

  const onDeleteChip = (value: LanguageChip) => {
    if (disabled) return;
    value.selected = true;
    onClickItem(value);
  }

  const completeLanguageList = useCallback(async () => {
    let formattedLocales = locales;
    try {
      const fbLocales = await getLocales();
      const moreLanguages = fbLocales.data.filter((language: LanguageChip) => {
        return !FB_COMMON_LANGUAGES.some((commonLanguage: LanguageChip) => {
          return commonLanguage.key === language.key
        })
      });
      formattedLocales = moreLanguages;
    } catch (e) {
      console.error('error fetching locales', e);
    }

    const sortedLocales = sortLanguageList(formattedLocales);
    return sortedLocales;
  }, []);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        getLocales(value)
          .then(result => {
            const sortedResult = sortLanguageList(result.data);
            setItemsState({ locales: sortedResult, default: false });
          })
          .catch((err) => {
            console.error('error fetching locales', err);
          });
      }, 800),
    []
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    completeLanguageList().then((locales) => setItemsState({ locales, default: true }));
  }, []);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length) {
      debouncedChangeHandler(value);
    } else {
      setItemsState({ locales: await completeLanguageList(), default: true });
    }
    setSearchValue(value);
  }

  const classes = useStyles();

  const isLocaleSelected = (locale: LanguageChip) => {
    return locales.some((item: LanguageChip) => item.key === locale.key);
  }

  const getMenuItems = (elements: { default: boolean, locales: Array<LanguageChip> }) => {
    if (!elements.default) {
      return elements.locales.map((element: LanguageChip, index: number) => {
        const isSelected = isLocaleSelected(element);
        element.selected = isSelected;
        return getDropDownItem(element, index, isSelected, onClickItem);
      });
    }

    return (
      <Fragment>
        {getDropDownSectionTitle('COMMON LANGUAGES')}
        {
          FB_COMMON_LANGUAGES.map((element: LanguageChip, index: number) => {
            const isSelected = isLocaleSelected(element);
            element.selected = isSelected;
            return getDropDownItem(element, index, isSelected, onClickItem);
          })
        }
        {getDropDownSectionTitle('MORE LANGUAGES')}
        {
          elements.locales.map((element: LanguageChip, index: number) => {
            const isSelected = isLocaleSelected(element);
            element.selected = isSelected;
            return getDropDownItem(element, index, isSelected, onClickItem);
          })
        }
      </Fragment>
    );

  }

  return (
    <DropDownChipContainer>
      {isVisible &&
        <div style={{ position: 'relative' }}>
          <DropDownListContainer
            position='absolute'
            ref={targetContent}
            style={{ bottom: "8px", top: undefined }}
          >
            <SearchContainer width='100%'>
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <SearchIcon height={24} width={24}/>
                  </InputAdornment>,
                }}
                value={searchValue}
                onChange={handleChange}
                classes={{ root: classes.textField }}
                variant="outlined"
                fullWidth
                placeholder="Search..."
              />
            </SearchContainer>
            <StyledLanguageList gap={mobileView ? "4px" : "8px"}>
              {getMenuItems(itemsState)}
            </StyledLanguageList>
          </DropDownListContainer>
        </div>
      }
      <DropDownBorder backgroundColor={disabled ? '#EDECF2' : '#FFFFFF'}>
        <DropDownButton
          className={classes.buttonChip}
          onClick={handleOnToggleDropDown}
          ref={toggleElement}
        >
          <Fragment>
            <RowContainer>
              {Boolean(locales && locales.length)
                ? locales.map((item: LanguageChip, index) => {
                  return (
                    <ChipContainer key={`${item.name}-${index}`}>
                      <StyledLabel
                        fontSize={16}
                        lineHeight='19px'
                        color='#000000'
                        textAlign='center'
                        onClick={handleOnClickLanguageChip}
                      >
                        {item.name}
                      </StyledLabel>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => handleOnDeleteChip(e, item)}
                      >
                        {!disabled && <CloseIcon width={18} height={18} color='#707070' strokeWidth='1.5'/>}
                      </div>
                    </ChipContainer>
                  )
                })
                : <StyledLabel
                  fontFamily='DIN'
                  fontStyle='normal'
                  fontWeight={400}
                  fontSize={16}
                  lineHeight='19px'
                  color='#707070'
                  style={{ padding: '8px 0' }}
                >Select a language</StyledLabel>
              }
            </RowContainer>
            <div style={{ cursor: 'pointer', minWidth: 22, height: 22 }} onClick={handleOpenDropdown}>
              <DropDownIcon
                className='visible'
                alt="dropdown-arrow.svg"
                width={22}
                height={22}
                src={disabled ? AutomatedIcon : DropdownArrowIcon}
                style={{ transform: isVisible ? 'rotate(-180deg)' : ''}}
              />
            </div>
          </Fragment>
        </DropDownButton>
      </DropDownBorder>
    </DropDownChipContainer>
  );
};

export default DropdownChip;