import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import { ButtonContainer, Label, LabelContainer, DefaultImage } from './style';
import paypalIcon from "assets/images/paypal-icon.png";
import { useMediaQuery, useTheme } from '@material-ui/core';

interface Props {
  width: string;
  height: string;
}

const PaypalButtonNonPro = ({ width, height }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(701));

  return (
    <ButtonContainer width={width} height={height} gap={isMobile ? "8px" : "10px"}>
      <DefaultImage
        height='32px'
        width='auto'
        src={paypalIcon}
      />
      <LabelContainer>
        <Label>only available on</Label>
        <UpgradeToProChip
          className="paypalProChip"
          type='label'
          mainContainerProps={{ width: "fit-content" }}
        />
      </LabelContainer>
    </ButtonContainer>
  );
};

export default PaypalButtonNonPro;
