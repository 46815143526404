import dayjs from "dayjs";
import { getDashboardCampaignsData, getInstagramData, getSpotifyReleaseData } from "pages/post-auth/DashboardModule/utils";
import { useEffect, useState, CSSProperties, useCallback } from "react";
import { ReferenceLine } from "recharts";
import { CurrentBrand } from "types/global";
import { getItemCountsDict } from "modules/Utils";
import CampaignImage from "./CampaignImage";
import Colors from "modules/Colors";
const igBoostsProfileVisitsImage = require('assets/images/ui/ig-boost-campaign-img.png').default

// NOTE: this HAS to be called as a function, not JSX syntax
interface SymphonyDefaultAnnotationsProps {
    yOffset?: number;
    currentBrand: CurrentBrand;
    campaignsData?: any[];
    instagramData?: any[];
    spotifyData?: any[];
    showAnnotations?: any;
    startDate: string;
    endDate: string;
    setShowGridModal?: (show: boolean) => void;
    setSelectedCampaign?: (campaign: any) => void;
    setSelectedIgPost?: (post: any) => void;
    setSelectedSpotifyRelease?: (release: any) => void;
    setItemsArray?: (items: any[]) => void;
}

const showAnnotationsInit = {
    campaigns: false,
    instagram: false,
    spotify: false
}

const SymphonyDefaultAnnotations = ({
    yOffset = 50,
    currentBrand,
    campaignsData: campaignsDataProp = [],
    instagramData: instagramDataProp = [],
    spotifyData: spotifyDataProp = [],
    showAnnotations = showAnnotationsInit,
    startDate,
    endDate,
    setShowGridModal = () => { },
    setSelectedCampaign = () => { },
    setSelectedIgPost = () => { },
    setSelectedSpotifyRelease = () => { },
    setItemsArray = () => { }
}: SymphonyDefaultAnnotationsProps) => {
    const [campaignsData, setCampaignsData] = useState<any[]>(campaignsDataProp);
    const [instagramData, setInstagramData] = useState<any[]>(instagramDataProp);
    const [spotifyData, setSpotifyData] = useState<any[]>(spotifyDataProp);
    const [isLoading, setIsLoading] = useState(false);
    const [itemCountsDict, setItemCountsDict] = useState<{ [key: string]: any[] }>({});

    useEffect(() => {
        fetchAllData();
    }, [currentBrand, startDate, endDate])

    useEffect(() => {
        console.log('Fetched data: >>>>', {
            campaigns: campaignsData,
            instagram: instagramData,
            spotify: spotifyData
        });

        const itemCountsDictLocal = getItemCountsDict({
            campaignsData,
            instagramData,
            spotifyData
        });

        setItemCountsDict(itemCountsDictLocal);
    }, [campaignsData, instagramData, spotifyData])

    const fetchAllData = async () => {
        if (!currentBrand) {
            return;
        }

        try {
            // Fetch all data in parallel for better performance
            await Promise.all([
                fetchCampaignsData(),
                fetchInstagramData(),
                fetchSpotifyReleaseData()
            ]);
        } catch (error) {
            console.error('Error fetching dashboard data: >>>>', error);
        }
    }

    const fetchCampaignsData = async () => {
        try {
            const res = await getDashboardCampaignsData({
                brandId: currentBrand.id,
                startDate,
                endDate,
                dateModifier: 'in_between',
                filterDays: 30
            });
            const campaignsDataLocal = res.data.data || [];

            // Filter campaignsData by startDate and endDate
            const filteredCampaignsData = campaignsDataLocal.filter((campaign: any) => {
                const campaignDate = new Date(campaign.updatedAt);
                return campaignDate >= new Date(startDate) && campaignDate <= new Date(endDate);
            });

            // Sort the filtered data by updatedAt in descending order
            const sortedCampaignsData = filteredCampaignsData.sort((a: any, b: any) => {
                return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
            });

            setCampaignsData(sortedCampaignsData);
        } catch (error) {
            console.error('Error fetching dashboard campaigns data:', error);
        }
    }

    const fetchInstagramData = async () => {
        try {
            const res = await getInstagramData({
                brandId: currentBrand.id,
                startDate,
                endDate
            });

            const instagramPosts = res.data.data?.instagramPosts || [];

            const filteredIgPosts = (instagramPosts || []).filter((post: any) => {
                const postDate = dayjs(post.timestamp).format('YYYY-MM-DD');
                return postDate >= startDate && postDate <= endDate;
            });

            setInstagramData(filteredIgPosts);
        } catch (error) {
            console.error('Error fetching Instagram data:', error);
        }
    }

    const fetchSpotifyReleaseData = async () => {
        try {
            const res = await getSpotifyReleaseData({ currentBrand });

            const releases = res || [];

            const filteredReleases = releases.filter((release: any) => {
                const releaseDate = dayjs(release.release_date).format('YYYY-MM-DD');
                return releaseDate >= startDate && releaseDate <= endDate;
            });

            setSpotifyData(filteredReleases);
        } catch (error) {
            console.error('Error fetching Spotify release data:', error);
        }
    }

    const resetSelectedItem = () => {
        setSelectedCampaign(null);
        setSelectedIgPost(null);
        setSelectedSpotifyRelease(null);
    }

    const renderItemCount = (items: any[]) => {
        const count = items?.length || 0;

        if (!count || count === 1) {
            return null;
        }

        return (
            <div
                className="gridCount"
                style={sty.gridCount}
            >
                +{count}
            </div>
        )
    }

    const CampaignImageMemo = useCallback(({ campaign_metadata, style }: any) => (
        <CampaignImage
            campaign_metadata={campaign_metadata}
            thumbnail={null}
            size={40}
            isBorder
            style={{ backgroundColor: Colors.purple, ...style }}
        />
    ), []);

    if (!showAnnotations.spotify && !showAnnotations.instagram && !showAnnotations.campaigns) {
        return null;
    }

    return (
        <>
            {showAnnotations.spotify && spotifyData.map((release: any) => {
                const releaseDate = release.release_date

                return (
                    <ReferenceLine
                        key={release.id}
                        label={({ viewBox }) => {
                            return (
                                <foreignObject width={40} height={40} x={viewBox.x - 20} y={viewBox.y + viewBox.height - yOffset}>
                                    <div
                                        className="gridImage"
                                        style={sty.gridImage}
                                        onClick={() => {
                                            setShowGridModal(true);
                                            if (itemCountsDict[releaseDate].length > 1) {
                                                setItemsArray(itemCountsDict[releaseDate]);
                                                resetSelectedItem();
                                            } else {
                                                setItemsArray([]);
                                                setSelectedCampaign(null);
                                                setSelectedIgPost(null);
                                                setSelectedSpotifyRelease(release);
                                            }
                                        }}
                                    >
                                        {renderItemCount(itemCountsDict[releaseDate])}
                                        <img
                                            className="gridImageInner"
                                            style={sty.gridImageInner}
                                            src={release.thumbnail_url ?? 'https://placehold.co/60x60/green/black'}
                                            alt="spotify-release"
                                        />
                                    </div>
                                </foreignObject>
                            )
                        }}
                        stroke={Colors.green}
                        strokeWidth={2}
                        strokeDasharray="5 5"
                        x={releaseDate}
                    />
                )
            })}

            {showAnnotations.instagram && instagramData.map((post: any) => {
                const postDate = post.timestamp

                return (
                    <ReferenceLine
                        key={post.id}
                        label={({ viewBox }) => {
                            return (
                                <foreignObject width={40} height={40} x={viewBox.x - 20} y={viewBox.y + viewBox.height - yOffset}>
                                    <div
                                        className="gridImage"
                                        style={sty.gridImage}
                                        onClick={() => {
                                            setShowGridModal(true);
                                            if (itemCountsDict[postDate].length > 1) {
                                                setItemsArray(itemCountsDict[postDate]);
                                                resetSelectedItem();
                                            } else {
                                                setItemsArray([]);
                                                setSelectedCampaign(null);
                                                setSelectedIgPost(post);
                                                setSelectedSpotifyRelease(null);
                                            }
                                        }}
                                    >
                                        {renderItemCount(itemCountsDict[postDate])}
                                        <img
                                            className="gridImageInner"
                                            style={sty.gridImageInner}
                                            src={post.thumbnail_url ?? post.media_url ?? igBoostsProfileVisitsImage}
                                            alt="instagram-post"
                                        />
                                    </div>
                                </foreignObject>
                            )
                        }}
                        stroke={Colors.orangeAnnotation}
                        strokeWidth={2}
                        strokeDasharray="5 5"
                        x={postDate}
                    />
                )
            })}

            {showAnnotations.campaigns && campaignsData.map((campaign: any) => {
                const {
                    campaign_metadata
                } = campaign;

                const campaignDate = campaign.updatedAt.split('T')[0]

                return (
                    <ReferenceLine
                        key={campaign.id}
                        label={({ viewBox }) => {
                            return (
                                <foreignObject width={40} height={40} x={viewBox.x - 20} y={viewBox.y + viewBox.height - yOffset}>
                                    <div
                                        className="gridImage"
                                        style={sty.gridImage}
                                        onClick={() => {
                                            setShowGridModal(true);
                                            if (itemCountsDict[campaignDate].length > 1) {
                                                setItemsArray(itemCountsDict[campaignDate]);
                                                resetSelectedItem();
                                            } else {
                                                setItemsArray([]);
                                                setSelectedCampaign(campaign);
                                                setSelectedIgPost(null);
                                                setSelectedSpotifyRelease(null);
                                            }
                                        }}
                                    >
                                        {renderItemCount(itemCountsDict[campaignDate])}
                                        <CampaignImageMemo campaign_metadata={campaign_metadata} />
                                    </div>
                                </foreignObject>
                            )
                        }}
                        stroke={Colors.purple}
                        strokeWidth={2}
                        strokeDasharray="5 5"
                        x={campaignDate}
                    />
                )
            })}
        </>
    )
}

const sty = {
    gridImage: {
        color: 'white',
        borderRadius: 8,
        textAlign: 'center',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        zIndex: 1000,
        overflow: 'hidden',
    } as CSSProperties,
    gridImageInner: {
        width: 40,
        height: 40,
        borderRadius: 8,
        objectFit: 'cover',
        border: '1px solid #E5E5E5',
    } as CSSProperties,
    gridCount: {
        position: 'absolute',
        top: 1,
        right: 1,
        fontSize: 10,
        fontWeight: 600,
        color: 'white',
        backgroundColor: Colors.purple,
        padding: "2px 4px",
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
    } as CSSProperties,
}

export default SymphonyDefaultAnnotations;