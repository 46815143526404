import { useState, ReactNode, useContext } from "react";
import { IconButton } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import BadgeCampaignTypeContainer from "../BadgeCampaignTypeContainer/BadgeCampaignTypeContainer";
import CampaignStatusBadge from "../CampaignStatusBadge";
import { RowContainer } from "./styles";
import SymphonyConversionsBadge from "../SymphonyConversionsBadge";
import { Caption } from "components/shareable/Typography";
import Badge from "components/shareable/Badge";
import { ShowCampaignInfo } from "../Modals/AdminShowCampaignInfo";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import Colors from "modules/Colors";
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface SubtitleProps {
  children: ReactNode;
}

interface CampaignCardContentProps {
  thumbnailSrc: string;
  titleText: string;
  subtitle: ReactNode;
  campaignType: string;
  campaignStatus?: string;
  createdAt?: string;
  campaignLabel?: string;
  campaignNeedsActions: boolean;
  isUsingConversionsApi?: boolean;
  onClick: () => void;
  campaignId?: string | number; // only for admin usage
}

export const Thumbnail = ({
  src
}: {
  src: string
}) => (
  <img
    className="w-12 h-12 md:mb-0 md:w-20 md:h-20 object-cover rounded-xl mr-4"
    src={src}
    alt=""
  />
);

export const Title = ({
  text
}: {
  text: string
}) => (
  <h3 className="font-normal text-lg">{text}</h3>
);

export const Subtitle = ({
  children
}: SubtitleProps) => (
  <div className="text-sm text-gray-500">{children}</div>
);

const CampaignCardContent = ({
  thumbnailSrc,
  titleText,
  subtitle,
  createdAt,
  campaignType,
  campaignStatus,
  campaignLabel,
  campaignNeedsActions,
  isUsingConversionsApi,
  onClick,
  campaignId,
}: CampaignCardContentProps) => {
  const { isSymphonyAdmin } = useContext(CurrentUserContext)

  const [isShowCampaignInfoModal, setIsShowCampaignInfoModal] = useState(false);

  const openCampaignModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsShowCampaignInfoModal(true);
  }

  const closeCampaignModal = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsShowCampaignInfoModal(false);
  }

  const isFbCampaign = campaignType === "boost_instagram" ||
    campaignType === "link_clicks" ||
    campaignType === "record_streams" ||
    campaignType === "grow_playlist_followers";

  return (
    <>
      <div className="w-full flex items-center justify-center " onClick={onClick}>
        <div className="flex  flex-col items-start md:items-center w-10/12 md:w-full md:flex-row">
          <Thumbnail src={thumbnailSrc} />
          <div className="flex flex-col justify-center items-start">
            <RowContainer>
              <BadgeCampaignTypeContainer campaignType={campaignType} />
              {isUsingConversionsApi && <SymphonyConversionsBadge />}
              {campaignNeedsActions ?
                <Badge backgroundColor={Colors.PAPER_WARNING_CONTAINER}>
                  <Caption color={Colors.yellow}>
                    ACTION NEEDED
                  </Caption>
                </Badge>
                :
                <CampaignStatusBadge
                  campaignStatus={campaignStatus?.toLowerCase()!}
                  campaignStatusLabel={
                    campaignLabel ? campaignLabel : campaignStatus
                  }
                />
              }
            </RowContainer>
            <div className="mb-1">
              <Title text={titleText} />
            </div>
            <div>
              <Subtitle>{subtitle}</Subtitle>
            </div>
            {createdAt && (
              <div>
                <Subtitle>Created on {createdAt}</Subtitle>
              </div>
            )}
            {isSymphonyAdmin && campaignId && (
              <div>
                <Subtitle>Campaign ID: {campaignId}</Subtitle>
              </div>
            )}
          </div>
        </div>
        <div className="ml-auto flex items-center">
          {isSymphonyAdmin && isFbCampaign && (
            <ButtonSymphony
              className="adminCampaignInfoButton"
              onClick={openCampaignModal}
              variant="outlined"
              size="small"
            >
              Admin: Campaign info
            </ButtonSymphony>
          )}
          <IconButton
            onClick={onClick}
            aria-label={`View Campaign Details for ${titleText}`}
          >
            <NavigateNext fontSize="large"></NavigateNext>
          </IconButton>
        </div>
      </div>
      {campaignId && isFbCampaign && (
        <ShowCampaignInfo
          open={isShowCampaignInfoModal}
          closeModal={closeCampaignModal}
          campaignId={campaignId}
        />
      )}
    </>
  );
};

export default CampaignCardContent;
