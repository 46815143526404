import { Fragment } from "react";
import { SpotifyArtistSearchResponse } from "types/global";
import { ArtistList as StyledArtistList, ArtistListLine } from "../../style";
import ArtistItem from "./ArtistItem/index";
import useScreen from "Hooks/useScreen";
import { clsx } from 'modules/Utils';

interface Props {
  className?: string
  /** Whether the list is in a loading state */
  selectedArtistIsLoading?: boolean;
  /** Array of artists to display */
  artists: SpotifyArtistSearchResponse[];
  /** Currently selected artist, if any */
  selectedArtist: SpotifyArtistSearchResponse | null;
  /** Whether component is rendered in mobile view */
  /** Callback function when an artist is selected */
  selectArtist: (artist: SpotifyArtistSearchResponse) => void;
}

/**
 * ArtistList component displays a scrollable list of artists with selection functionality
 * @param props - Component props
 * @returns ArtistList component
 */
const ArtistList = ({
  className,
  selectedArtistIsLoading,
  artists,
  selectedArtist,
  selectArtist,
}: Props) => {
  const { mobileView } = useScreen();
  /** Array to store rendered artist items and dividers */
  const renderedList: React.ReactElement[] = [];

  // Map through artists and create list items
  artists.map((artist, index) => {
    renderedList.push(
      <Fragment key={`${artist.id}-${index}`}>
        <ArtistItem
          className={clsx("artistListComponent1", className)}
          loading={selectedArtistIsLoading}
          isSelected={
            selectedArtist ? selectedArtist?.id === artist.id : selectedArtistIsLoading
          }
          artist={artist}
          selectArtist={selectArtist}
          buttonLabel="Connect"
        />
        <ArtistListLine />
      </Fragment>
    );
  });

  return (
    <StyledArtistList gap={mobileView ? "4px" : "8px"}>
      {renderedList}
    </StyledArtistList>
  );
};

export default ArtistList;
