import { Body2 } from "components/shareable/Typography"
import { FieldContainer, FieldFooter, FieldsContainer } from "./SetupSection/styles"
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import Colors from "modules/Colors";
import { useContext } from "react";
import { WebsiteContext } from "../hooks/WebsiteContext";
import { Field } from "types/global";
import { DraggableList } from "components/shareable/DraggableList";
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { Container } from "styles/shared";
import SwitchSymphony from "components/shareable/SwitchSymphony";

interface Props {
  toggleIsRequiredField: (index: number, isRequired: boolean) => void,
  deleteField: (index: number) => void,
}

export const FieldsList = ({ deleteField, toggleIsRequiredField }: Props) => {
  const { fields, setFields } = useContext(WebsiteContext)

  const renderFieldContent = (item: Field, index?: number) => {
    return (
      <FieldContainer key={`data-field-container-${index}`}>
        <Container display="flex" gap='8px'>
          <DragIcon />
          <Body2 color={Colors.greyDark}>{item.label}</Body2>
        </Container>
        <FieldFooter>
          <Body2 color={Colors.greyDark}>Required</Body2>
          <SwitchSymphony
            checked={item.required}
            onChange={() => toggleIsRequiredField(index || 0, !item.required)}
          />
          <TrashIcon
            onClick={() => deleteField(index || 0)}
            cursor='pointer'
          />
        </FieldFooter>
      </FieldContainer>
    )
  }

  if (!fields?.length) return <></>
  return (
    <FieldsContainer>
      <DraggableList
        list={fields}
        updateList={(newFields: Field[]) => setFields(newFields)}
        renderItem={renderFieldContent}
        keyOfItem="label"
      />
    </FieldsContainer>
  )
}