import { Theme, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import Text from "modules/Text"
//// new way of doing styles
import { CSSProperties, ReactNode } from "react";
import { MEDIA_BREAKPOINTS } from "types/global";
import Colors from "modules/Colors";
import useScreen from "Hooks/useScreen";

export interface SHARED_WEBSITE_STYLES_PROPS {
  subHeaderText: CSSProperties & { marginBottom: number };
  editorPane: CSSProperties & { maxWidth: number };
  innerPanelSection: CSSProperties;
}

export const SHARED_WEBSITE_STYLES = makeStyles((_theme: Theme) => ({
  subHeaderText: {
    ...Text.subHeader,
  },
  innerPanelSection: {
    padding: 16,
    borderRadius: 12,
    border: "1px solid #EDECF2",
  },
  websiteEditorCardTitles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: 24,
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
}))

interface WebsiteEditorCardProps {
  className?: string;
  style?: CSSProperties;
  title?: string;
  subtitle?: string;
  gap?: number;
  padding?: string | number;
  children: ReactNode;
  rightContent?: ReactNode;
}

export const WebsiteEditorCard = ({
  className = '',
  style = {},
  title,
  subtitle,
  gap = 8,
  children,
  rightContent,
}: WebsiteEditorCardProps) => {
  const classes = SHARED_WEBSITE_STYLES()
  const { mStyle } = useScreen();

  return (
    <div
      className={`websiteEditorCardComponent ${className}`}
      style={{
        ...mStyle(sty, 'websiteEditorCardComponent'),
        ...style
      }}
    >
      <div className={classes.websiteEditorCardTitles}>
        <div style={{ gap }}>
          {title && <SubHeaderText text={title} />}
          {subtitle && (
            <div style={Text.primary}>{subtitle}</div>
          )}
        </div>
        {rightContent}
      </div>
      {children}
    </div>
  )
}

interface SubHeaderTextProps {
  text: string;
}

export const SubHeaderText = ({
  text,
}: SubHeaderTextProps) => {
  const classes = SHARED_WEBSITE_STYLES()
  return (
    <p className={classes.subHeaderText}>
      {text}
    </p>
  )
}

export const renderBorderSeparator = (style?: object) => {
  return (
    <div className="borderSeparator" style={{ ...Text.borderSeparator, ...style }} />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  grayText: {
    color: "#707070",
    cursor: "pointer",
    fontSize: 16,
  },
  select: {
    height: 44,
    marginLeft: "auto",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: theme.spacing(1.75),
    },
    [theme.breakpoints.down(361)]: {
      width: "100%",
    },
  },
}));

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
}

export interface ImageProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  backgroundColor?: string;
  alignItems?: string;
  width?: number;
  height?: number;
  marginLeft?: string;
  borderRadius?: string;
  justifyContent?: string;
}

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
`;

export const Image = styled.img`
  gap: ${({ gap }: ImageProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ImageProps) => flexDirection};
  display: ${({ display }: ImageProps) => display};
  align-items: ${({ alignItems }: ImageProps) => alignItems};
  padding: ${({ padding }: ImageProps) => padding};
  background-color: ${({ backgroundColor }: ImageProps) => backgroundColor};
  width: ${({ width }: ImageProps) => width};
  margin-left: ${({ marginLeft }: ImageProps) => marginLeft};
  border-radius: ${({ borderRadius }: ImageProps) => borderRadius};
  justify-content: ${({ justifyContent }: ImageProps) => justifyContent};
  height: ${({ height }: ImageProps) => height};
`;

interface TopBarContentContainerProps {
  published?: boolean;
}

export const TopBarContentContainer = styled.div<TopBarContentContainerProps>`
  width: ${({ published }) => (published ? "50%" : "fit-content")};
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin-left: 8px;
  ${MEDIA_BREAKPOINTS.mobileView} {
    width: fit-content;
    padding: 8px 0px 8px 8px;
    justify-content: space-between;
    margin-left: 0px;
  }
`

export const Puller = styled.div`
  width: 40px;
  min-height: 5px;
  background-color: ${Colors.grey};
  border-radius: 8px;
  margin-top: 8px;
  position: absolute;
  top: 0;
  left: 47%;
`;

const sty: Record<string, CSSProperties> = {
  websiteEditorCardComponent: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    backgroundColor: "white",
    borderRadius: 12,
    padding: 24,
  },
  websiteEditorCardComponent_mobile: {
    borderRadius: 0,
    padding: 16,
  }
}

export default useStyles;
