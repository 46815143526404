import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import CampaignCreationPage from "./CampaignCreationPage"
import CreativeTab from "./CreativeTab"
import Finalization from "./FinalizationTab"
import { track } from "analytics"
import TargetingView from "./TargetingView"
import {
  CreatedCampaign,
  FbSuggestion,
  SavingDraftStatus,
  FBCustomAudience,
  FacebookPageResponse,
  CurrencyCodesType,
} from "types/global"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { useMediaQuery, useTheme } from "@material-ui/core"
import useStyles, { LoadingContainer } from "./styles"
import {
  getWhenCreativeTabNextButtonIsDisabled,
  getIfTargetingSaveButtonIsDisabled,
  getNextButtonLabel,
  getNextButtonWidth,
  STEPS,
  campaignOptions,
  CampaignOption
} from "./utils"
import startCase from "lodash/startCase"
import Grid from "@material-ui/core/Grid"
import TopBar from 'components/shareable/TopBar/TopBar';
import CustomStepper from "components/shareable/CustomStepper/CustomStepper"
import { GeographicTargetingType } from "./TargetingView/reducer"
import { Option } from "./TargetingView/Components/AutoCompleteSpotifyArtists/utils"
import { MarketingDataContext } from "../Data/Provider"
import { CAMPAIGN_CREATED, CREATING_CAMPAIGN, GOAL, mainTitle } from "./constants"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import { clsx } from 'modules/Utils'
import { AssetUploadTypes, getIfPagesAreSelected, UploadedAssetType } from "../utils/fbCampaigns"
import { getNotRemovableOptions, NOT_REMOVABLE } from "./TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import { fixGeographicTargets } from "modules/Utils"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPES, GEOGRAPHY_TARGETING_DEFAULTS, CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "modules/Const"
import { getInitialTargetAudiences, getTargetingViewGenre } from "../Components/TargetingView/utils"
import { MobileAdPreviewBar } from "../MusicStreams/MobileAdPreviewBar"
import useScreen from "Hooks/useScreen"
import { CreativeAssetTypes } from "../constants"
import GoalTab from "./GoalTab"
import { getConvertedCurrency } from "services/symphonyApi"
import { geographyTargetingDefaults, lowBudgetGeographyTargetingDefaults } from "../MusicStreams/TargetingView/data"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { ConnectionsContext } from "../hooks/ConnectionsContext"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"

const { CAMPAIGN_GOAL, CREATIVE, BUDGET, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus
const tabs = [CAMPAIGN_GOAL, CREATIVE, BUDGET, FINALIZATION]
const { INSTAGRAM_POSTS } = CreativeAssetTypes

interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
  refreshCampaigns: () => void
  reloadBrands: () => void
}

const GrowInstagramEngagement = ({
  setGoal,
  refreshCampaigns,
  reloadBrands,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const { organization: primaryOrganization } = useCurrentTeam()
  const { mobileView } = useScreen()
  const draft = new URLSearchParams(location.search).get("draft")
  const { data: connectModalData } = useContext(ConnectModalsContext)
  const { loading } = connectModalData
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const isTargetingViewResponsive = useMediaQuery(theme.breakpoints.down(551))
  const history = useHistory()
  const {
    saveTargets,
    saveAudiences,
    saveCustomAudiences,
    saveArtists,
    saveAdditionalPageAudiences,
    saveDemographics,
    demographics,
  } = useContext(MarketingDataContext)
  const { currentBrand } = useContext(CurrentBrandContext)

  const {
    facebookPage: selectedFB_page,
    instagramPage: selectedInstaPage,
    facebookAdAccount: selectedFBAdAccount,
    facebookPixel: selectedFBPixel,
    facebookBusinessManager: selectedFBBusinessManager,
    loggedInFbUser,
    areConnectionsValid: adConnectionsAdded,
    connectionsConfirmed: adConnectionsConfirmed,
    validatingConnections,
    preConnectionConfigurations,
    areTermsAccepted,
    connectionsType,
    instagramPagesAvailable,
    needsToReLogIn,
    setConnectionsConfirmed: setAdConnectionsConfirmed,
    setAreConnectionsValid: setAdConnectionsAdded,
  } = useContext(ConnectionsContext)

  const [targets, setTargets] = useState<GeographicTargetingType[]>(geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram))
  const [audiences, setAudiences] = useState<FbSuggestion[]>([])
  const [customAudiences, setCustomAudiences] = useState<FBCustomAudience[]>([])
  const [additionalPageAudiences, setAdditionalPagesAudiences] = useState<FacebookPageResponse[]>([])
  const [artists, setArtists] = useState<Option[]>([])
  const [totalBudget, setTotalBudget] = useState(Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2)))
  const [isEditingTargeting, setIsEditingTargeting] = useState(false)
  const [selectedTab, setSelectedTab] = useTabs(tabs, CAMPAIGN_GOAL)
  const [finished, setFinished] = useState(false)
  const [recentlyCreatedCampaign, setRecentlyCreatedCampaign] = useState<CreatedCampaign | null>(null)
  const [showTargetingView, setShowTargetingView] = useState(false)
  const [fbSuggestions, setFbSuggestions] = useState<FbSuggestion[]>([])
  const [loadingInitialAudiences, setLoadingInitialAudiences] = useState(false)
  const [selectedInstagramPost, setSelectedInstagramPost] = useState<InstagramPost>()

  const [uploadedImages, setUpload] = useState<UploadedAssetType[] | null>(null)
  const [creativeSelectorTab, setCreativeSelectorTab] = useState<AssetUploadTypes>(INSTAGRAM_POSTS)
  const [previewedVideo, setPreviewedVideo] = useState<UploadedAssetType | null>(null)
  const [addVisualSelected, setAddVisualSelected] = useState<AssetUploadTypes>(INSTAGRAM_POSTS);

  const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignOption>(campaignOptions[0]);
  const [campaignName, setCampaignName] = useState<string>("")
  const [caption, setCaption] = useState<string>("")
  const [cta, setCTA] = useState<{ value: string, label: string }>({ value: "LEARN_MORE", label: "Learn More" })
  const [showPreview, enablePreviewMode] = useState<boolean>(false)
  const [interestTargetingAudiences, setInterestTargetingAudiences] = useState<any>(null)
  const [validatedAudiencesAlready, setValidatedAudiences] = useState<boolean>(false)

  const [budgetValue, setBudgetValue] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");
  const [isBelowRecommendedBudget, setIsBelowRecommendedBudget] = useState<boolean>(false);

  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [creatingCampaign, allowCampaignCreation] = useState(false)
  const [isBudgetTabNextButtonDisabled, setIsBudgetTabNextButtonDisabled] = useState(false)
  const [uploadQueueCreativeTab, setUploadQueueCreativeTab] = useState<Record<string, unknown>[] | null>(null)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<string, unknown> | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)
  const [showLoading, setShowLoading] = useState<boolean>(Boolean(draft) && retrievingDraftData)

  // Facebook Express Setup or User Pages setup 
  const [expressSetupOrCustomPages, setExpressSetupOrCustomPages] = useState<'custom' | 'express'>('custom')

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])

  function updatedCurrency(newCurrency: CurrencyCodesType) {
    setCurrency(newCurrency);
  }

  let recommendedUsdBudget = 100;
  const checkMinimumBudgetStatus = async () => {
    const convertedBudget = await getConvertedCurrency({ from: currency, to: "USD", amount: budgetValue });
    return convertedBudget < recommendedUsdBudget;
  }
  async function recheckIfBudgetOverRecommendation() {
    const belowRecommendedBudget = await checkMinimumBudgetStatus() || false
    setIsBelowRecommendedBudget(belowRecommendedBudget)
  }

  useEffect(() => {
    recheckIfBudgetOverRecommendation()
  }, [budgetValue, currency]);

  useEffect(() => {
    if (isBelowRecommendedBudget) {
      setTargets(lowBudgetGeographyTargetingDefaults);
      saveTargets(lowBudgetGeographyTargetingDefaults);
      // when the budget goes below recommended budget:
      // - default to the below recommended budget targets
      // - otherwise use the default targeting
    } else {
      setTargets(geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram));
      saveTargets(geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram));
    }
  }, [isBelowRecommendedBudget]);

  useEffect(() => {
    if (draft && retrievingDraftData) {
      setShowLoading(false)
    }
  }, [draft, retrievingDraftData]);

  const uploadingAssets =
    !!uploadQueueCreativeTab && uploadQueueCreativeTab.length > 0
  const steps = Object.values(STEPS).map((value) => ({
    description: startCase(value),
    name: value,
  }))

  const disableTargetingSaveButton = getIfTargetingSaveButtonIsDisabled(
    targets,
    totalBudget
  )
  const genres = currentBrand?.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)

  const dataToSaveDraft = [
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    demographics?.age?.min || null,
    demographics?.age?.max || null,
    demographics?.gender || null,
    demographics?.languages || null,
    addVisualSelected,
    selectedCampaignType,
    selectedFBPixel,
    selectedFBBusinessManager,
    loggedInFbUser,
  ]
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id")
  const targetAudiences = Boolean(audiences.length) ? audiences : initialTargetingAudiences
  const fixedGeographicTargets = Boolean(targets.length)
    ? fixGeographicTargets(targets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const fixedTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));
  const audiencesData = {
    geographicTargets: fixedGeographicTargets,
    interestTargetingAudiences: fixedTargetingAudiences,
  }

  const handleSaveTargetingData = () => {
    saveTargets(targets)
    saveAudiences(audiences || [])
    saveCustomAudiences(customAudiences || [])
    saveAdditionalPageAudiences(additionalPageAudiences || [])

    saveArtists(artists || [])
    setShowTargetingView(false)
  }

  const saveUploadQueueCreativeTab = (
    queue: Record<string, unknown>[] | null
  ) => {
    setUploadQueueCreativeTab(queue)
  }

  const saveWhenBudgetTabButtonIsDisabled = (isDisabled: boolean) => {
    setIsBudgetTabNextButtonDisabled(isDisabled)
  }

  let igMedia = undefined
  if (creativeSelectorTab === INSTAGRAM_POSTS || addVisualSelected === INSTAGRAM_POSTS) {
    igMedia = selectedInstagramPost
  }

  const handleClickCloseTopBar = () => {
    setGoal(null)
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (showTargetingView) {
      setShowTargetingView(false)
    } else if (selectedTab === CAMPAIGN_GOAL) {
      setGoal(null)
      history.push("/marketing")

    } else if (selectedTab === CREATIVE) {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    } else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }
  const handleConfirmConnections = () => {
    track("Completed Campaign Step - Account Connections", {
      type: "grow_instagram_engagement",
      step: 1,
      name: "connect_accounts",
      brand_id: currentBrand?.id,
      brand_name: currentBrand?.name,
      brand_slug: currentBrand?.slug,
    })

    setAdConnectionsAdded(true)
    setAdConnectionsConfirmed(true)
  }

  const handleClickContinueCreativeTab = () => {
    track("Completed Campaign Step - Creative Assets", {
      type: "grow_instagram_engagement",
      step: 2,
      name: "add_creatives",
      brand_id: currentBrand?.id,
      brand_name: currentBrand?.name,
      brand_slug: currentBrand?.slug,
      creative_assets: selectedInstagramPost ? 1 : uploadedImages?.length || 0,
      caption: caption,
      cta: cta.value,
    })

    setSelectedTab(BUDGET)
  }

  const handleClickNextButton = () => {
    if (selectedTab === CAMPAIGN_GOAL) {
      setSelectedTab(CREATIVE)
    } else if (selectedTab === CREATIVE) {
      if (adConnectionsAdded && adConnectionsConfirmed) {
        handleClickContinueCreativeTab()
      } else {
        handleConfirmConnections()
      }
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  const createdCampaign = (campaign: CreatedCampaign) => {
    setFinished(true)
    setRecentlyCreatedCampaign(campaign)
  }

  const handleBudgetTabNextButton = () => {
    track("Completed Campaign Step - Budget", {
      type: "grow_instagram_engagement",
      step: 3,
      name: "set_budget",
      brand_id: currentBrand?.id,
      brand_name: currentBrand?.name,
      brand_slug: currentBrand?.slug,
      budget: budgetValue,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    })

    setSelectedTab(FINALIZATION)
  }

  useEffect(() => {
    if (draft && selectedTab !== CAMPAIGN_GOAL && (!adConnectionsAdded || !areTermsAccepted)) {
      setSelectedTab(CREATIVE)
    }
  }, [adConnectionsAdded, areTermsAccepted, selectedTab])

  useEffect(() => {
    getInitialTargetAudiences({
      genre,
      setFbSuggestions,
      setLoading: setLoadingInitialAudiences,
      access_token: loggedInFbUser?.access_token,
    })
  }, [])

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab, showTargetingView, adConnectionsConfirmed])

  useDraftDataGetter({
    draft,
    setters: {
      setCaption,
      setCTA,
      setRetrievingDraftData,
      setBudgetValue,
      setSelectedTab,
      setCampaignDraftData,
      setTargets,
      setAdditionalPagesAudiences,
      setAudiences,
      saveDemographics,
      setSelectedInstagramPost,
      setUpload,
      setEndDate,
      setStartDate,
      setCustomAudiences,
      saveTargets,
      saveAudiences,
      saveArtists,
      setAdConnectionsAdded,
      setAddVisualSelected,
      setSelectedCampaignType
    } as Record<string, Dispatch<unknown>>
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    audiencesData,
    currentBrand,
    dataToSaveDraft: dataToSaveDraft as Record<string, unknown>[],
    draft,
    requiredDataChanged,
    retrievingDraftData,
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus,
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    savingDraftStatus
  })

  // only allow ad preview to be shown on creative page
  const canShowAdPreview: boolean =
    selectedTab === CREATIVE && adConnectionsAdded && adConnectionsConfirmed

  const getArePagesSelected = () => {
    return getIfPagesAreSelected(connectionsType, {
      fbPixel: selectedFBPixel,
      fbAdAccount: selectedFBAdAccount,
      fbPage: selectedFB_page,
      instaPage: selectedInstaPage,
    }, {
      fbPixel: false,
      fbAdAccount: true,
      fbPage: true,
      instaPage: true
    })
  }

  const getDisableNextButtonWhenCreativeTab = () => {
    const arePagesSelected = getArePagesSelected()
    return getWhenCreativeTabNextButtonIsDisabled({
      uploadedImages,
      adConnectionsAdded,
      adConnectionsConfirmed,
      uploadingAssets,
      selectedInstagramPost,
      selectedFB_page,
      selectedInstaPage,
      arePagesSelected,
      selectedFBAdAccount,
      preConnectionConfigurations,
      selectedCreativeType: creativeSelectorTab,
      cta,
      caption,
    })
  }

  const getDisableNextButton = () => {
    const disableForTargeting = showTargetingView && (disableTargetingSaveButton || isEditingTargeting)

    if (disableForTargeting) return true
    switch (selectedTab) {
      case CREATIVE:
        const disableNextButtonWhenCreativeTab = getDisableNextButtonWhenCreativeTab()
        const conditionsToDisable = [
          disableNextButtonWhenCreativeTab,
          selectedInstagramPost && selectedInstagramPost.explicit_content,
          selectedFBAdAccount && !areTermsAccepted,
          !instagramPagesAvailable,
          !selectedFBAdAccount || !selectedFB_page,
          needsToReLogIn,
        ]
        return conditionsToDisable.some(condition => Boolean(condition))
      case BUDGET:
        return isBudgetTabNextButtonDisabled
      case FINALIZATION:
        return !showTargetingView
      default:
        return false
    }
  }

  let topbarTitle = mainTitle
  if (selectedCampaignType?.id === 'boost_views') topbarTitle = 'Boost IG Post Views'
  else if (selectedCampaignType?.id === 'boost_follows') topbarTitle = 'Boost IG Profile Visits'

  if (creatingCampaign || finished) {
    return (
      <>
        <TopBar title={finished ? CAMPAIGN_CREATED : CREATING_CAMPAIGN} />
        <CampaignCreationPage
          reloadBrands={reloadBrands}
          primaryOrganization={primaryOrganization ? primaryOrganization : undefined}
          draft={campaignDraftData?.id as string}
          campaignName={campaignName}
          goal={GOAL}
          igMedia={igMedia}
          budget={budgetValue}
          startDate={startDate}
          selectedCampaignType={selectedCampaignType}
          endDate={endDate}
          assets={{ all: uploadedImages }}
          caption={caption}
          cta={cta}
          customAudiences={customAudiences}
          additionalPageAudiences={additionalPageAudiences}
          createdCampaign={createdCampaign}
          finished={finished}
          recentlyCreatedCampaign={recentlyCreatedCampaign}
          refreshCampaigns={refreshCampaigns}
          setSelectedTab={setSelectedTab}
          setFinished={setFinished}
          allowCampaignCreation={allowCampaignCreation}
          fbSuggestions={fbSuggestions}
        />
      </>
    )
  }

  return (
    <Grid
      className={
        selectedTab === CREATIVE && adConnectionsAdded
          ? classes.creativeTabHighMarginBottom
          : classes.normalMarginBottom
      }
    >
      <TopBar
        showCloseIcon
        showSaveDraftChip={
          !!draft ||
          (!!selectedFBAdAccount && !!selectedFB_page && !!selectedInstaPage)
        }
        savingChanges={isSavingDraft}
        title={topbarTitle}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          className: 'stepButtonsGrowInstagram',
          handleClickBackButton,
          handleClickNextButton: showTargetingView
            ? handleSaveTargetingData
            : handleClickNextButton,
          showNextButton: !(selectedTab === FINALIZATION && !showTargetingView),
          disableNextButton: getDisableNextButton(),
          nextButtonLabel: getNextButtonLabel({
            showTargetingView,
            selectedTab,
            isNextButtonMobile
          }),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab,
          }),
          loadingNextButton: loading || validatingConnections,
        }}
        stepperButtonAmendments={
          <MobileAdPreviewBar
            {...{
              creativeSelectorTab,
              enablePreviewMode,
              previewedVideo,
              selectedInstagramPost,
              uploadedImages,
            }}
            show={mobileView && canShowAdPreview}
          />
        }
      >
        {showLoading &&
          <LoadingContainer>
            <LoadingIndicator color="black" height="80px" />
            <p className="text-center mt-4">Loading Campaign Draft...</p>
          </LoadingContainer>
        }
        {!showLoading && (
          <MainContentContainer
            backgroundColor="transparent"
            className={clsx(
              classes.mainContainer,
              canShowAdPreview && classes.creativeTabContainer,
              `${selectedTab === CREATIVE && 'w-auto max-w-full flex gap-4 justify-center'}`
            )}
          >
            <Grid
              className={clsx(
                ` h-full w-full rounded-${isMobile ? "none" : "xl"}`,
                showTargetingView && classes.mbWhenTargetingView,
                !showTargetingView && classes.mbWhenNotTargetingView,
                canShowAdPreview && classes.gridCreativeTabContainer,
              )}
            >
              {showTargetingView ? (
                <TargetingView
                  isBelowRecommendedBudget={isBelowRecommendedBudget}
                  deleteIconColor="#80f"
                  isResponsive={isTargetingViewResponsive}
                  showTrashIcon={showTargetingView}
                  setShowTargetingView={setShowTargetingView}
                  fbSuggestions={fbSuggestions}
                  loading={loadingInitialAudiences}
                  genre={genre}
                  handleArtists={(artists: Option[]) => setArtists(artists)}
                  handleAudiences={(audiences: FbSuggestion[]) =>
                    setAudiences(audiences)
                  }
                  customAudiences={customAudiences}
                  setCustomAudiences={setCustomAudiences}
                  additionalPageAudiences={additionalPageAudiences}
                  setAdditionalPagesAudiences={setAdditionalPagesAudiences}

                  handleTargets={(targets: GeographicTargetingType[]) =>
                    setTargets(targets)
                  }
                  handleTotalBudget={(budget: number) => setTotalBudget(budget)}
                  handleIsEditing={(isEditing: boolean) =>
                    setIsEditingTargeting(isEditing)
                  }
                  showSaveButton={false}
                />
              ) : (
                <>
                  <div className="w-full">
                    <TabPanel hidden={selectedTab !== CAMPAIGN_GOAL}>
                      <GoalTab
                        selectedGoal={selectedCampaignType}
                        setSelectedGoal={setSelectedCampaignType}
                      />
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== CREATIVE}>
                      <CreativeTab
                        expressSetupOrCustomPages={expressSetupOrCustomPages}
                        setExpressSetupOrCustomPages={setExpressSetupOrCustomPages}
                        setPreviewedVideo={setPreviewedVideo}
                        previewedVideo={previewedVideo}
                        creativeSelectorTab={creativeSelectorTab}
                        setCreativeSelectorTab={setCreativeSelectorTab}
                        setAddVisualSelected={setAddVisualSelected}
                        selectedAssets={uploadedImages}
                        setSelectedAssets={setUpload}
                        caption={caption}
                        setCaption={setCaption}
                        cta={cta}
                        setCTA={setCTA}
                        selectedInstagramPost={selectedInstagramPost}
                        setSelectedInstagramPost={setSelectedInstagramPost}
                        saveUploadQueue={saveUploadQueueCreativeTab}
                        showContinueButton={false}
                        selectedCampaignType={GLOBAL_CAMPAIGN_TYPES.boost_instagram}
                        showPreview={showPreview}
                        enablePreviewMode={enablePreviewMode}
                      />
                    </TabPanel>
                    <SpendBudgetProvider
                      budgetValue={budgetValue}
                      step={selectedTab as string}
                      fbAccountId={selectedFBAdAccount?.id}
                      campaignType={GLOBAL_CAMPAIGN_TYPES.boost_instagram}
                      access_token={loggedInFbUser?.access_token}
                    >
                      <TabPanel hidden={selectedTab !== BUDGET}>
                        <BudgetTab
                          updatedCurrency={updatedCurrency}
                          selectedTab={selectedTab}
                          budgetValue={budgetValue}
                          setBudgetValue={setBudgetValue}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          finishFlow={handleBudgetTabNextButton}
                          saveWhenNextButtonIsDisabled={saveWhenBudgetTabButtonIsDisabled}
                          showReviewButton={false}
                        />
                      </TabPanel>
                      <TabPanel hidden={selectedTab !== FINALIZATION}>
                        <Finalization
                          selectedCampaignType={selectedCampaignType}
                          primaryOrganization={primaryOrganization}
                          isBelowRecommendedBudget={isBelowRecommendedBudget}
                          draft={campaignDraftData?.id}
                          campaignName={campaignName}
                          goal={GOAL}
                          budget={budgetValue}
                          startDate={startDate}
                          endDate={endDate}
                          allowCampaignCreation={allowCampaignCreation}
                          setSelectedTab={setSelectedTab}
                          caption={caption}
                          cta={cta}
                          interestTargetingAudiences={interestTargetingAudiences}
                          setInterestTargetingAudiences={setInterestTargetingAudiences}
                          validatedAudiencesAlready={validatedAudiencesAlready}
                          setValidatedAudiences={setValidatedAudiences}
                          setShowTargetingView={setShowTargetingView}
                          fbSuggestions={fbSuggestions}
                          assets={{ all: uploadedImages }}
                          igMedia={igMedia}
                          addVisualSelected={addVisualSelected}
                          cancelSaving={cancelSavingDraft}
                          handleSaveTargetingData={handleSaveTargetingData}
                          additionalPageAudiences={additionalPageAudiences}
                          customAudiences={customAudiences}
                        />
                      </TabPanel>
                    </SpendBudgetProvider>
                  </div>
                </>
              )}
            </Grid>
          </MainContentContainer>
        )}
      </CustomStepper>
    </Grid>
  )
}

export default GrowInstagramEngagement
