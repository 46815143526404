import { useState } from 'react';
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { TeamMemberList } from '../TeamMemberList';
import useModal from 'Hooks/useModal';
import AddMemberModal from '../AddMemberModal';
import AvailableSeatBadge from '../AvailableSeatBadge';
import TeamTabSection from '../../TeamTabSection';
import { TEAM_PLAN_INCLUDED_SEATS } from 'modules/Const'

const TeamTab = () => {
  const { teamMembers, getTeamMembers } = useCurrentTeam();
  const addMemberModal = useModal();
  const [searchTerm, setSearchTerm] = useState<string>();

  const handleModalClose = async () => {
    await getTeamMembers();
    addMemberModal.closeModal();
  };

  const handleRemoveTeamMember = async () => {
    await getTeamMembers();
  };

  return (
    <TeamTabSection
      title="Team"
      searchValue={searchTerm}
      onSearch={setSearchTerm}
      buttonText="Add Team Member"
      onButtonClick={addMemberModal.openModal}
      showDividers
    >
      <TeamMemberList
        handleRemoveTeamMember={handleRemoveTeamMember}
        teamMembers={teamMembers}
        searchTerm={searchTerm} />
      {teamMembers.length < TEAM_PLAN_INCLUDED_SEATS && (
        <AvailableSeatBadge
          onAddMember={addMemberModal.openModal}
          currentSeats={teamMembers.length}
        />
      )}
      {addMemberModal.open && (
        <AddMemberModal onClose={handleModalClose} />
      )}
    </TeamTabSection>
  );
};

export default TeamTab;