import Colors from 'modules/Colors';
import { Body2, Headline2 } from '../Typography';
import {
  CardContainer,
  ImageContainer,
  DefaultImage,
  RightSideContainer,
} from './styles';
import emptyUserPink from "assets/images/empty-user-pink.png";

interface Props {
  image?: JSX.Element,
  title: string;
  statValue: number | string;
}

export function StatCard({
  title, image, statValue
}: Props) {
  return (
    <CardContainer>
      <ImageContainer>
        {image || <DefaultImage src={emptyUserPink} width='16px' height='24px'/>}
      </ImageContainer>
      <RightSideContainer>
        <Body2>{title}</Body2>
        <Headline2 color={Colors.textColor}>{statValue}</Headline2>
      </RightSideContainer>
    </CardContainer>
  )
}