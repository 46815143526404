import { Typography } from "@material-ui/core"
import useStyles from "./styles"
import { useContext } from "react";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import SymphonyConversionsBadge from "../SymphonyConversionsBadge";
import { Container } from "styles/shared";
import LearningIndicator from "components/shareable/LearningIndicator";
import { Link } from "react-router-dom";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";

interface CreateCampaignCardProps {
    title: string;
    imageUrl: string;
    description: string;
    status?: string | null;
    statusMessage?: string | null;
    isConversionsCampaign?: boolean;
    backgroundColor?: string;
    link?: string;
    onLinkClick?: () => void;
    onHelpClick?: () => void;
    requiresPro: boolean;
    chipLabel?: boolean;
}

const CreateCampaignCard = ({
    title,
    imageUrl,
    description,
    status,
    statusMessage,
    isConversionsCampaign,
    link,
    onLinkClick,
    onHelpClick,
    requiresPro,
    chipLabel
}: CreateCampaignCardProps) => {
    const classes = useStyles()
    const { currentBrand } = useContext(CurrentBrandContext)
    const hasConversionsApiSetup = Boolean(currentBrand?.conversionsStatus?.conversions_tasks_completed)

    // This section should be clickable if a link is passed
    const clickableSection = (
        <>
            <div className={`clickableSectionContainer ${classes.campaignImageBg} relative`}>
                <img
                    className={classes.campaignImage}
                    src={imageUrl}
                    alt={title}
                />

                {requiresPro && (<div className="absolute"
                    style={{
                        top: 10,
                        left: !chipLabel ? 10 : '',
                        right: chipLabel ? 10 : ''
                    }}>
                    {!chipLabel ? (
                        <UpgradeToProChip
                            type="gem"
                            color="purple"
                            height={20}
                            width={20}
                        />
                    ) : (
                        <UpgradeToProChip
                            type="label"
                            mainContainerProps={{
                                margin: "0",
                            }}
                        />
                    )}

                </div>)}

            </div>
            <div className={classes.campaignContent}>
                <Container flexDirection="column" cursor="pointer">
                    <Typography className={classes.campaignTitle}>
                        {title}
                    </Typography>
                    <Typography className={classes.campaignDescription}>
                        {description}
                    </Typography>
                    {status && status === 'maintenance' &&
                        <Typography className={classes.maintenanceText}>
                            {statusMessage ? statusMessage : 'This campaign type is under maintenance and will be back shortly.'}
                        </Typography>
                    }
                </Container>
                {isConversionsCampaign && hasConversionsApiSetup &&
                    <SymphonyConversionsBadge text="Works with Symphony Conversions" />
                }
            </div>
        </>
    )

    return (
        <div
            className={`createCampaignCardComponent ${classes.campaignType}`}
            onClick={() => console.log(`${title} clicked`)}
        >
            {link ?
                <Link
                    to={link}
                    onClick={onLinkClick}
                >
                    {clickableSection}
                </Link>
                :
                clickableSection
            }
            {onHelpClick &&
                <div className={`onHelpClickContainer ${classes.campaignLearnSection}`}>
                    <LearningIndicator
                        text="How it works"
                        onClick={() => {
                            onHelpClick()
                        }}
                    />
                </div>
            }
        </div>
    )
}

export default CreateCampaignCard
