import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { toast, ToastOptions } from 'react-toastify';
import { Select, MenuItem, FormControl, InputLabel, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import CardTitle from 'components/shareable/CardTitle/CardTitle';
import csvtojson from 'csvtojson';
import styled from 'styled-components';
import { CloudUpload } from '@material-ui/icons';
import Axios from 'helpers/Interceptor';
import { CurrentUserContext } from 'Hooks/CurrentUserContext';
import ButtonSymphony from 'components/shareable/ButtonSymphony';

const TOAST_WARNING_OPTIONS: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    closeButton: true,
};

export const MainContainer = styled.div`
  display: flex;
  background: #F5F3F6;
  gap: 24px;
  min-height: 100%;
  flex-direction: column;
  margin-bottom: 64px;
  width: 100%;
  padding: 32px 80px;

`;

const AdminDataUpload = () => {
    const { isSymphonyAdmin: isAdmin } = useContext(CurrentUserContext)

    const [fileData, setFileData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [reportType, setReportType] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [numRows, setNumRows] = useState<number>(0);
    const [previewData, setPreviewData] = useState<any[]>([]);

    useEffect(() => {
        if (reportType && fileData.length === 0) {
            setFileName('')
            setNumRows(0)
            setPreviewData([])
            setLoading(false);
            setFileData([]);
        }
    }, [reportType])

    const categorizeObject = (jsonObject: any): string => {
        // Definitions of categories
        const ageGenderKeys = [
            'Male_18 - 24_Cost', 'Male_18 - 24_Views', 'Male_18 - 24_Avg CPV',
        ];

        const geographiesKeys = [
            'City (User location)', 'Country/Territory (User location)'
        ];

        const placementsKeys = [
            'Placement (group)', 'Placement type'
        ];

        // Check the keys
        for (const key in jsonObject) {
            if (ageGenderKeys.includes(key)) {
                return 'age-gender';
            } else if (geographiesKeys.includes(key)) {
                return 'geographies';
            } else if (placementsKeys.includes(key)) {
                return 'placements';
            }
        }

        return 'unknown';
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setLoading(true);
        const file = acceptedFiles[0];

        const reader = new FileReader();
        reader.onload = async (event: any) => {
            try {
                const text = event.target.result as string;
                const rows = text.split('\n').slice(2); // Split text by line break and remove first two lines
                const header = rows[0].replace(/\./g, ''); // Remove periods from header
                const jsonData = await csvtojson({
                    noheader: true,
                    headers: header.split(',')
                }).fromString(rows.slice(1).join('\n')); // Join rows back into a string and convert to JSON


                setLoading(false);
                setFileData(jsonData);
                setFileName(file.name);
                setNumRows(jsonData.length);
                setPreviewData(jsonData.slice(0, 3));


                // try to figure out what type it is using the file type
                const categorized = categorizeObject(jsonData[0]);
                if (categorized !== 'unknown') {
                    // if(reportType === '') {
                    setReportType(categorized);
                    // }
                }
            } catch (err: any) {
                setLoading(false);
                toast.error(`Error parsing file: ${err.message}`, TOAST_WARNING_OPTIONS);
            }
        };
        reader.onerror = (err: any) => {
            setLoading(false);
            toast.error(`Error reading file: ${err.message}`, TOAST_WARNING_OPTIONS);
        };
        reader.readAsText(file);
    }, []);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,

        accept: {
            "text/csv": ['.csv'],
            "application/csv": ['.csv'],
            "text/x-csv": ['.csv']
            // , application/csv": [
            //     '.csv',
            //     '.xls',
            //     '.xlsx',
            // ],
        },
    });

    const handleReportTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setReportType(event.target.value as string);
    };

    const handleUpload = async () => {
        setLoading(true);
        try {
            const chunkSize = 25000;
            const chunks = Math.ceil(fileData.length / chunkSize);

            for (let i = 0; i < chunks; i++) {
                const start = i * chunkSize;
                const end = start + chunkSize;
                const chunk = fileData.slice(start, end);

                const response = await Axios.post(`/admin/video-views-upload`, {
                    type: reportType,
                    data: chunk,
                });


                if (!(response && response.data && response.data.data && response.data.data.success)) {
                    throw new Error('Error uploading data');
                } else {
                    if (chunks > 1) {
                        toast.success(`Data chunk ${i + 1} / ${chunks} uploaded successfully. Please remain on this page while the remaining data points are uploaded.`, {
                            ...TOAST_WARNING_OPTIONS,
                            autoClose: 6000
                        });
                    }

                }
            }
            toast.success('All data uploaded successfully!', TOAST_WARNING_OPTIONS);


        } catch (error: any) {
            toast.error(`Error uploading data: ${error.message}`, TOAST_WARNING_OPTIONS);
        } finally {
            setLoading(false);
        }
    };

    if (!isAdmin) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <MainContainer>
            <CardTitle title="Upload Video Views Exports" subtitle="Upload exports from Google Ads reports into here to automatically update the dataset."
            />

            <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                <InputLabel htmlFor="report-type">Report Type</InputLabel>
                <Select value={reportType} onChange={handleReportTypeChange} label="Report Type" inputProps={{ id: 'report-type' }}>
                    <MenuItem value="age-gender">Age / Gender Report</MenuItem>
                    <MenuItem value="geographies">Geographies Report</MenuItem>
                    <MenuItem value="placements">Placements report</MenuItem>
                </Select>
            </FormControl>

            <div
                {...getRootProps()}
                style={{ border: '1px dashed gray', padding: '1rem', marginBottom: '1rem' }}
            >
                <input {...getInputProps({ multiple: false })} />
                <p>Drag and drop a CSV or Excel-type spreadsheet file here, or click to select a file</p>
            </div>
            {fileName && numRows > 0 && (
                <>
                    <Box display="flex" alignItems="center" marginTop="0.5rem">
                        <CloudUpload style={{ marginRight: '0.5rem' }} />
                        <Typography variant="body2">
                            {fileName} ({numRows} rows)
                        </Typography>
                    </Box>
                    {previewData.length > 0 && (
                        <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(previewData[0]).map((key) => (
                                            <TableCell key={key}>{key}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {previewData.slice(0, 3).map((row, i) => (
                                        <TableRow key={i}>
                                            {Object.values(row).map((value: any, j) => (
                                                <TableCell key={j}>{value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {numRows > 3 && (
                                <Typography variant="body2" style={{ padding: '0.5rem' }}>
                                    and {numRows - 3} more rows...
                                </Typography>
                            )}
                        </TableContainer>
                    )}

                </>
            )}

            <ButtonSymphony
                className="adminDataUploadUploadButton"
                onClick={handleUpload}
                disabled={loading || !reportType || fileData.length === 0}
                isLoading={loading}
                loadingText="Uploading..."
            >
                Upload
            </ButtonSymphony>
        </MainContainer>
    )
}

export default AdminDataUpload