import { useContext, useEffect } from "react";
import { useState } from "react";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Axios from "../../helpers/Interceptor";
import PopupHeader from "components/shareable/Popup/header";
import { getIgUserData } from "helpers/FB";
import { uploadProfilePicture } from "pages/post-auth/AddArtist/Components/ConnectPlatformModal/InstagramBusinessDiscovery/utils";
import NotFoundInstaUser from "./NotFoundInstaUser/index";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface ConnectProps {
    closeModal: () => void;
    selected: any;
    set?: any;
    localSave: any;
    onSave?: any;
}

const InstagramBusinessDiscoveryConnect = ({
    closeModal,
    selected,
    set,
    localSave,
    onSave
}: ConnectProps) => {
    const { currentBrand } = useContext(CurrentBrandContext);

    const [loading, setLoading] = useState<boolean>(false)
    const [invalidUser, setInvalidUser] = useState<boolean>(false)
    const [notFoundUser, setNotFoundUser] = useState<boolean>(false)

    // these strings map to the username
    //      -- to get the URL, update to `https://www.instagram.com/${formattedValue}`
    const [valueString, setValueString] = useState<string>(selected && selected.username ? selected.username : '')

    const [formattedValue, setFormattedValue] = useState<any>(selected ? selected : null)

    useEffect(() => {
        // if first character is an @, remove the @ before storing
        // also force lowercase
        const foundName = valueString
        let nameWithoutAt = foundName.toLowerCase()
        if (nameWithoutAt.charAt(0) === '@') {
            nameWithoutAt = nameWithoutAt.substring(1)
        }
        if (foundName) {
            setNotFoundUser(false)
            setFormattedValue({
                url: `https://www.instagram.com/${nameWithoutAt}`,
                username: nameWithoutAt
            })
        }
    }, [valueString])

    if (!currentBrand) {
        return null;
    }

    const updateInBrand = async () => {
        const igUser = await getIgUserData(formattedValue.username);
        let cloudinaryUrl = '';
        let url = '';
        if (igUser) {
            const { profile_picture_url, name, username } = igUser;
            url = `https://www.instagram.com/${username}`
            cloudinaryUrl = await uploadProfilePicture(profile_picture_url) as string
            setFormattedValue({ url, username, picture: cloudinaryUrl })
        }
        else {
            setNotFoundUser(true)
            setLoading(false)
            return;
        }

        Axios.put(`/brand/${currentBrand.slug}/connect`, {
            id: currentBrand.id,
            name: currentBrand.slug,
            service: 'business_discovery_instagram',
            value: { url, username: igUser.username, picture: cloudinaryUrl }
        })
            .then((result) => {
                // reloadBrand()
                if (set) set(formattedValue)
                onSave()
                closeModal()
            })
            .catch((err) => {
                console.log('error creating brand', err)
            })
    }

    const isValid = (str: string) => {
        return formattedValue && (/^[@{1}]?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,50}$/).test(str.toString());
    }

    // NOTE: render()
    return (
        <div className="instagramBusinessDiscoveryConnectComponent">
            <h4 className="mt-5 pb-5 text-2x font-semibold border-b border-gray-200">
                What's {currentBrand.name}'s Instagram profile username?
            </h4>
            <div className="input-form flex flex-row align-middle relative">
                <input
                    className="border-b p-6 outline-none text-left border-gray-200 w-full"
                    // minLength={2}
                    placeholder="@badbunnypr, @sza..."
                    value={valueString}
                    onChange={(e) => setValueString(e.target.value)} />
            </div>

            <p className={`w-12/12 mt-5 text-2x text-center ${!isValid(valueString) ? 'text-red-500' : ''}`}>
                {valueString ? isValid(valueString) ? " " : "Please enter a valid Instagram profile username." : " "}
            </p>

            {notFoundUser &&
                <>
                    <p className={'w-12/12 mt-5 text-2x text-center text-red-500 pb-2'}>
                        We weren't able to find your Instagram data, please double check the username you entered.
                    </p>
                    <NotFoundInstaUser />
                </>
            }

            <ButtonSymphony
                className="instagramBusinessDiscoveryConnectButton"
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                isLoading={loading}
                loadingText="Connecting..."
                disabled={notFoundUser || invalidUser || (!valueString || valueString === "") || !isValid(valueString)}
                onClick={() => {
                    if (!localSave) {
                        updateInBrand()
                        setLoading(true)
                    } else {
                        if (set) set(formattedValue)
                        closeModal()
                    }
                }}
                size="large"
            >
                Connect
            </ButtonSymphony>
        </div>
    )
}

export default InstagramBusinessDiscoveryConnect;