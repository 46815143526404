import { Dialog } from "@material-ui/core"
import useStyles, {
  CloseIconContainer,
  DialogMainContainer,
  OutlinedButton,
  StyledLabel,
  ActionButtonContainer,
} from "./styles"
import CloseIcon from "components/shareable/CloseIcon"
import { StarsIcon } from "components/svg-icons"

interface Props {
  open: boolean
  handleClose?: () => void
  onClickAction?: () => void
}

const SavesYourProgressInfoModal = ({
  open,
  handleClose,
  onClickAction,
}: Props) => {
  const classes = useStyles()

  return (
    <Dialog
      {...{ open }}
      classes={{ root: classes.rootDialog }}
      onClose={handleClose}
      PaperProps={{ classes: { root: classes.paperRounded } }}
    >
      <DialogMainContainer>
        <CloseIconContainer onClick={handleClose}>
          <CloseIcon />
        </CloseIconContainer>
        <StarsIcon color="#8800FF" />
        <StyledLabel fontSize={32} fontWeight={500} marginTop="16px">
          Symphony automatically saves your progress
        </StyledLabel>
        <StyledLabel
          color="#707070"
          fontSize={18}
          fontWeight={400}
          marginTop="8px"
        >
          If you don’t finish creating your campaign right now, you’ll find it in the “Drafts” section in the Marketing tab.
        </StyledLabel>
        <ActionButtonContainer>
          <OutlinedButton width="100px" onClick={onClickAction}>
            Got it!
          </OutlinedButton>
        </ActionButtonContainer>
      </DialogMainContainer>
    </Dialog>
  )
}

export default SavesYourProgressInfoModal
