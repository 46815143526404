import { useContext, useState, CSSProperties } from 'react';
import useScreenQueryDimensions from 'Hooks/useScreenQueryDimensions';
import { CurrentBrand, MarketingCampaignType, WebsiteType } from 'types/global';
import MaestroCampaignRecommender from 'pages/post-auth/MarketingPage/Components/CreateNewCampaignModal/MaestroCampaignRecommender';
import { CampaignCreationSource, trackNewCampaign } from 'pages/post-auth/MarketingPage/Components/CampaignsSection/analytics';
import { availableGoals } from 'pages/post-auth/MarketingPage/utils/marketing';
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import BrandSelectionModal from './CreateCampaignModal/SelectBrandModal';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import Axios from 'helpers/Interceptor';
import DefaultAdditionalActions from './CardTitle/DefaultAdditionalActions';
import CreateNewWebsiteModal from 'pages/post-auth/Website/components/CreateNewWebsiteModal';
import useScreen from 'Hooks/useScreen';
import { clsx } from "modules/Utils";
export const POPULAR_CAMPAIGNS = Object.values(availableGoals)
    .filter((o: MarketingCampaignType) => {
        return [
            "increase_record_streams",
            "pre_save",
            'increase_video_views',
            'grow_instagram_engagement'
        ].indexOf(o.key) !== -1
    })
    .map((goal: MarketingCampaignType) => {
        return {
            ...goal
        }
    })

type AskMaestroSource = "Dashboard" | "Marketing" | "Marketing - Team Plan";

export interface QuickActionItem {
    key: string;
    title: string;
    icon: string;
    link?: string;
    isWebsite?: boolean;
}

interface QuickActionsProps {
    className?: string;
    style?: CSSProperties;
    showMaestro?: boolean;
    campaignCreationSource?: CampaignCreationSource;
    askMaestroSource?: AskMaestroSource;
    campaignItems?: QuickActionItem[];
    initialSiteType?: WebsiteType;
    allowBrandSelector?: boolean;
    loadedBrands?: CurrentBrand[];
    handleQuickActionClick?: (itemKey: string) => void;
}

const defaultQuickActionItems: QuickActionItem[] = [
    {
        key: "music",
        title: "Build a Music Site",
        icon: MusicSiteIcon,
        link: undefined,
        isWebsite: true
    },
    ...POPULAR_CAMPAIGNS.filter(campaignType => !campaignType.underMaintenance).map(campaignType => ({
        key: campaignType.link,
        title: campaignType.title,
        icon: campaignType.icon,
        link: `/marketing/new/${campaignType.link}`,
        isWebsite: false
    }))
];

const QuickActions = ({
    className = '',
    showMaestro = true,
    campaignCreationSource = 'dashboard-quick-actions',
    askMaestroSource = 'Dashboard',
    campaignItems = undefined,
    style,
    allowBrandSelector = false,
    loadedBrands = [],
    handleQuickActionClick = (itemKey: string) => { }
}: QuickActionsProps) => {
    const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);
    const { mStyle, mobileView } = useScreen();   

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [initialSiteType, setInitialSiteType] = useState<WebsiteType | null>('record_streams');
    const [showBrandSelector, setShowBrandSelector] = useState(false);
    const [inProgressQuickAction, setInProgressQuickAction] = useState<string | undefined>(undefined);

    let actionsToShow = campaignItems || defaultQuickActionItems;

    // Handles brand selection when creating a new campaign or website
    // brandId: ID of the brand selected from the brand selector modal
    const handleBrandSelection = async (brandId: string) => {
        try {
            let newBrand = currentBrand;

            // Only fetch new brand data if selected brand is different from current
            // This prevents unnecessary API calls if user selects same brand
            if (currentBrand?.id !== parseInt(brandId)) {
                const response = await Axios.get(`/brand/${brandId}`);
                newBrand = response.data.data as CurrentBrand;
            }

            // Update global brand context with selected brand
            // Falls back to current brand if new brand fetch fails

            let updatedBrand = newBrand ?? currentBrand;
            if (updatedBrand) {
                setCurrentBrand(updatedBrand);
            }

            // Continue with campaign/website creation flow using stored action
            handleQuickActionClick(inProgressQuickAction!);
        } catch (error) {
            return null;
        }
    }

    const openCreateSiteModal = (websiteKey?: string) => {
        setInitialSiteType(websiteKey as WebsiteType || null);
        setOpenCreateModal(true);
    }

    const closeCreateSiteModal = () => {
        setOpenCreateModal(false);
        setInitialSiteType(null);
    }

    const handleCampaignItemClick = (itemKey: string) => {
        setInProgressQuickAction(itemKey);
        if (allowBrandSelector && loadedBrands?.length) {
            setShowBrandSelector(true);
            return;
        } else {
            defaultCampaignItemClick(itemKey);
        }
    }

    const defaultCampaignItemClick = (itemKey: string) => {
        // Find the campaign item that matches the itemKey

        const item = actionsToShow.find(i => i.key === itemKey);
        if (item?.isWebsite) {
            // Handle website creation tracking
            openCreateSiteModal(itemKey)
        } else {
            // Handle regular campaign tracking
            trackNewCampaign({
                type: itemKey,
                from: 'dashboard-quick-actions',
                zeroState: false,
                aiQuestion: null,
                currentBrand: currentBrand,
                key: itemKey
            });

            if (handleQuickActionClick) {
                handleQuickActionClick(itemKey);
            }
        }
    }

    return (
        <>
            <div
                className={clsx('quickActionsComponent', className)}
                style={{
                    ...(mStyle(sty, 'quickActionsComponent')),
                    ...style
                }}
            >
                {/*
                    TODO: Currently hidden by default. If want to show on mobile, remove the md:hidden
                    and follow the existing implementations in the codebase for reference for pro usage
                */}
                {showMaestro &&
                    <div className="bg-white mb-0 md:hidden w-11/12 mx-auto mt-2 mb-2">
                        <MaestroCampaignRecommender
                            askMaestroSource={askMaestroSource}
                            hideAskAiBadge={mobileView}
                            defaultQueryOptions={[]}
                            campaignCreationSource={campaignCreationSource as CampaignCreationSource}
                        />
                    </div>
                }
                <DefaultAdditionalActions
                    className="quickActionsDefaultAdditionalActions"
                    isOnlyActionsView
                    hidePrimaryButton
                    onButtonClick={console.log}
                    campaignItems={actionsToShow}
                    stackedView={mobileView}
                    additionalItemsText={mobileView ? undefined : "Shortcuts:"}
                    onCampaignItemClick={handleCampaignItemClick}
                />
            </div>

            <CreateNewWebsiteModal
                isOpen={openCreateModal}
                onClose={closeCreateSiteModal}
                initialSiteType={initialSiteType}
            />

            <BrandSelectionModal
                className="quickActionsBrandSelectionModal"
                isOpen={showBrandSelector}
                onClose={() => setShowBrandSelector(false)}
                brands={loadedBrands}
                onBrandSelect={handleBrandSelection}
            />
        </>
    );
};

const sty: Record<string, CSSProperties> = {
    quickActionsComponent: {
        display: 'flex',
        alignSelf: 'flex-start',
        borderRadius: 8,
        backgroundColor: 'white',
        width: '100%',
        overflowX: 'scroll',
    },
    quickActionsComponent_mobile: {
        flexDirection: 'column',
    }
}

export default QuickActions;