import styled from "styled-components";
import Colors from 'modules/Colors';

interface ButtonContainerProps {
  width: string;
  height: string;
  color: string;
  gap?: string;
  padding?: string;
  minWidth?: string
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding ? padding : '12px 24px'};

  border-radius: 48px;
  gap: ${({ gap }) => gap};
  min-width: ${({ minWidth }) => minWidth};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: none;
  border: 1px solid ${({ color }) => color};
  white-space: nowrap;
  :disabled {
    border-color: ${Colors.grey};
    color: ${Colors.grey};
    background: ${Colors.PAPER_CONTAINER};
    cursor: not-allowed;
  }
`;
