import {
  PRO_MONTHLY_PLAN_PRICE,
  PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
  TEAM_MONTHLY_PLAN_PRICE,
  TEAM_ANNUAL_BY_MONTH_PLAN_PRICE
} from "modules/Const"
import { CurrencyCodesType } from "types/global"

export enum BillingCycle {
  MONTHLY = "monthly",
  ANNUAL = "annual",
}

type IconComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string
  }
>

export type FeatureType = {
  featureId?: string
  icon: IconComponent
  feature: string
  caption?: string | ((currency: CurrencyCodesType, convertedAmount: number) => string)
  className?: string
}

//TODO: Change by the real quotes and images
export const TESTIMONIALS = [
  {
    action: "Grew by",
    quantity: "400%",
    units: "monthly listeners",
    testimony:
      "For any artist serious about growing their fanbase, Symphony is a must. Their ability to automate ad campaigns and extract valuable insight gives users a unique blend of dependability & simplicity.",
    witness: "West",
    occupation: "Rapper",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/artist-west.jpeg",
  },
  {
    action: "Manages",
    quantity: "5 artists",
    units: "using Symphony",
    testimony:
      "Symphony is an incredible resource for today’s music entrepreneur and artist. As a full time artist manager for 4 artists and the CEO of a record label, my goal everyday is to promote my artist’s music and scale their careers as quickly as possible, with long-term career sustainability in mind.",
    witness: "Ashley",
    occupation: "CEO, Breakroom17",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/ceo-ashley-breakroom17.png",
  },
  {
    action: "Grown to",
    quantity: "25k",
    units: "monthly listeners on Spotify",
    testimony:
      "Symphony is a necessity for all artists. Their automated ad campaigns and other marketing tools have allowed me to grow my following and listeners across all platforms!",
    witness: "Christof Hunte",
    occupation: "Pop Artist",
    picture:
      "https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/testimonials/artist-christof-hunte.png",
  },
]

export const format = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const labels = {
  [BillingCycle.MONTHLY]: {
    id: 'monthly',
    price: PRO_MONTHLY_PLAN_PRICE,
    totalPrice: PRO_MONTHLY_PLAN_PRICE,
    billed: "Billed monthly",
    billedTrialText: 'month'
  },
  [BillingCycle.ANNUAL]: {
    id: 'annually',
    price: PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
    totalPrice: PRO_ANNUAL_BY_MONTH_PLAN_PRICE * 12,
    billed: "Billed annually",
    billedTrialText: 'year'
  },
}

export const teamLabels = {
  [BillingCycle.MONTHLY]: {
    id: 'monthly',
    price: TEAM_MONTHLY_PLAN_PRICE,
    totalPrice: TEAM_MONTHLY_PLAN_PRICE,
    billed: "Billed monthly",
    billedTrialText: 'month'
  },
  [BillingCycle.ANNUAL]: {
    id: 'annually',
    price: TEAM_ANNUAL_BY_MONTH_PLAN_PRICE,
    totalPrice: TEAM_ANNUAL_BY_MONTH_PLAN_PRICE * 12,
    billed: "Billed annually",
    billedTrialText: 'year'
  },
}