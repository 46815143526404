import { ReactComponent as FacebookRoundedIcon } from 'assets/images/facebook-rounded-icon.svg'
import HeaderModal from 'components/connect/connectModals/Spotify/Components/HeaderModal'

interface SelectPixelHeaderProps {
  currentBrandName: string
  isCreatingAdAccount: boolean
  closeModal: () => void
  handleGoBack: () => void
}

export const SelectPixelHeader = ({
  currentBrandName,
  isCreatingAdAccount,
  closeModal,
  handleGoBack,
}: SelectPixelHeaderProps) => {
  return (
    <HeaderModal
      title={
        !isCreatingAdAccount
          ? `Select ${currentBrandName}'s Facebook Pixel`
          : 'Create Facebook Pixel'
      }
      subtitle={
        !isCreatingAdAccount
          ? 'Symphony Conversions will send valuable fan data to this pixel to optimize your ads.'
          : `Connect the Business Manager you use to manage your Artist Page and accounts.\n\nAfter you connect, you may receive a verification email from Facebook. If so, you'll need to confirm your business email address before proceeding.`
      }
      closeModal={closeModal}
      HeaderIcon={
        !isCreatingAdAccount ? <FacebookRoundedIcon width={32} height={32} /> : undefined
      }
      handleGoBack={isCreatingAdAccount ? handleGoBack : undefined}
    />
  )
}
