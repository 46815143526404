import { StyledLabel } from "./styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import TaskCard from "components/TaskCard/TaskCard";
import useScreen from "Hooks/useScreen";
import { Container } from "styles/shared";

export interface TaskItem {
  icon?: string;
  title: string;
  description: string;
  actionName?: string;
  action?: () => void;
  done?: boolean;
  href?: string;
}

interface TaskListProps {
  tasks: TaskItem[];
  listTitle?: string;
  width?: string;
  horizontal?: boolean;
}

const TaskList = ({
  tasks,
  listTitle,
  horizontal,
  width,
}: TaskListProps) => {
  const { mobileView, largeView } = useScreen();

  if (largeView || horizontal) {
    if (horizontal) {
      return (
        <Container
          className="taskListContainer1"
          padding="24px"
          backgroundColor="#FFFFFF"
          borderRadius={mobileView ? "0px" : "16px"}
          flexDirection={"column"}
          display="flex"
          height="fit-content"
        >
          {listTitle &&
            <StyledLabel fontSize={20} padding="8px 8px">
              {listTitle}
            </StyledLabel>
          }
          <Swiper slidesPerView={4} navigation={true} modules={[Navigation]} style={{ padding: "8px 8px", width: '100%' }}>
            {tasks.map((item, index) => (
              <SwiperSlide key={`task-${index}`}>
                <div className="p-3">
                  <TaskCard
                    href={item.href}
                    showBoxShadow={true}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    actionName={item.actionName}
                    action={item.action} // Use noop if action is undefined
                    crossedOut={item.done}
                    headerAlign="center"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      );
    } else {
      return (
        <Container
          className="taskListContainer2"
          padding="24px"
          backgroundColor="#FFFFFF"
          borderRadius={mobileView ? "0px" : "16px"}
          flexDirection={"column"}
          display="flex"
          height="fit-content"
        >
          <StyledLabel fontSize={20} padding="8px 8px">
            {listTitle}
          </StyledLabel>
          <Swiper slidesPerView={4} navigation={true} modules={[Navigation]} style={{ padding: "8px 8px" }}>
            {tasks.map((item, index) => (
              <SwiperSlide
                key={`task-${index}`}
                className="p-3"
              >
                <TaskCard
                  href={item.href}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  actionName={item.actionName}
                  action={item.action} // Use noop if action is undefined
                  crossedOut={item.done}
                  headerAlign="center"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      );
    }
  }

  return (
    <Container
      className="taskListContainer3"
      backgroundColor="#F5F3F6"
      flexDirection="column"
      display="flex"
      height="100%"
      width={width || "550px"}
    >
      <Container
        padding="24px"
        backgroundColor="white"
        borderRadius="16px"
        flexDirection="column"
        display="flex"
        height="100%"
      >
        <StyledLabel fontSize={20} padding="8px 0px">
          {listTitle}
        </StyledLabel>
        {tasks.map((item, index) => (
          <TaskCard
            href={item.href}
            key={`task-${index}`}
            icon={item.icon}
            title={item.title}
            actionName={item.actionName}
            description={item.description}
            action={item.action} // Use noop if action is undefined
            crossedOut={item.done}
          />
        ))}
      </Container>
    </Container>
  );
};

export default TaskList;