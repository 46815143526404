import { useEffect, useState, Dispatch, useContext } from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import Finalization from "./FinalizationTab"
import VideoTab from "components/shareable/VideoSelectorTab"
import { track } from "analytics"
import VideoTargetingTab from "./VideoTargetingTab"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import { useHistory, useLocation } from "react-router-dom"
import { CurrentBrand, SavingDraftStatus, VideoAdsRecord } from "types/global"
import Grid from "@material-ui/core/Grid"
import useStyles from "./styles"
import TopBar from 'components/shareable/TopBar/TopBar'
import CustomStepper from "components/shareable/CustomStepper/CustomStepper"
import { getNextButtonLabel, getNextButtonWidth, STEPS } from "./utils"
import { useMediaQuery, useTheme } from "@material-ui/core"
import {
  useDraftDataGetter,
  useDraftDataSetter,
} from "./Hooks/DraftsHandlers"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "modules/Const"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

const { RECORD, TARGETING, BUDGET, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus

const steps = [
  { description: "Video", name: RECORD },
  { description: "Targeting", name: TARGETING },
  { description: "Budget", name: BUDGET },
  { description: "Review", name: FINALIZATION },
]

const DEFAULT_TAB = RECORD

const VideoViews = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const draft = new URLSearchParams(location.search).get("draft")
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const { currentBrand } = useContext(CurrentBrandContext) as { currentBrand: CurrentBrand }
  const tabs = steps.map(({ name }) => name)
  const [selectedTab, setSelectedTab] = useTabs(tabs, DEFAULT_TAB)
  const [recordSelected, setRecordSelected] = useState<VideoAdsRecord | null>(
    null
  )
  const [selectedAudiences, setAudiences] = useState([])
  const [campaignType, setCampaignType] = useState<string>("views")
  const [geographyType, setGeographyType] = useState<string>("english")
  const [selectedCountries, setSelectedCountries] = useState<
    { label: string; value: string }[]
  >([])
  const [budgetValue, setBudgetValue] = useState(20)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<
    string,
    unknown
  > | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)

  // to ensure user has confirmed the inability to pause
  const [userConfirmedCampaign, setUserConfirmedCampaign] = useState<boolean>(false)

  // to ensure user has confirmed the refund 5% processing fee
  const [userConfirmedRefundFee, setUserConfirmedRefundFee] = useState<boolean>(false)

  const youtubeConnected =
    currentBrand && currentBrand.connections.youtube_channel
      ? currentBrand.connections.youtube_channel
      : null
  const commonTrackData = {
    brand_id: currentBrand.id,
    brand_name: currentBrand.name,
    brand_slug: currentBrand.slug,
    content_name: recordSelected?.title,
    content_id: recordSelected?.id,
  }
  const mainContainerMb =
    selectedTab === TARGETING || selectedTab === FINALIZATION ? 32 : 24
  const disableNextButtonWhenBudgetTab = !(
    typeof budgetValue !== "undefined" &&
    budgetValue !== null &&
    budgetValue > 0 &&
    startDate &&
    endDate
  )
  const dataToSaveDraft = [
    recordSelected,
    budgetValue,
    startDate,
    endDate,
    selectedAudiences,
    campaignType,
    geographyType,
    selectedTab,
    selectedCountries,
  ]

  const mainTitle = recordSelected
    ? `Promote "${(recordSelected as any).name}"`
    : "Increase Video Views"

  const handleRecordTabNextButton = () => {
    setSelectedTab(TARGETING)

    track("Completed Campaign Step - Content Selection", {
      type: "increase_video_views",
      step: 1,
      name: "select_video",
      ...commonTrackData,
    })
  }

  const handleTargetingTabNextButton = () => {
    setSelectedTab(BUDGET)
    track("Completed Campaign Step - Targeting Selection", {
      type: "increase_video_views",
      step: 2,
      name: TARGETING,
      audiences: selectedAudiences,
      geographyType: geographyType,
      campaignType: campaignType,
      countries: selectedCountries,
      ...commonTrackData,
    })
  }

  const handleBudgetTabNextButton = () => {
    setSelectedTab(FINALIZATION)
    track("Completed Campaign Step - Budget Selection", {
      type: "increase_video_views",
      step: 3,
      name: "budget",
      budget: budgetValue,
      startDate: startDate,
      endDate: endDate,
      ...commonTrackData,
    })
  }

  const handleClickCloseTopBar = () => {
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (selectedTab === RECORD) {
      if (recordSelected) {
        setRecordSelected(null)
      } else {
        history.push("/marketing")
      }
    } else if (selectedTab === TARGETING) {
      setSelectedTab(RECORD)
    } else if (selectedTab === BUDGET) {
      setSelectedTab(TARGETING)
    } else if (selectedTab === FINALIZATION) {
      setSelectedTab(BUDGET)
    }
  }

  const handleClickNextButton = () => {
    if (selectedTab === RECORD && recordSelected) {
      setSelectedTab(TARGETING)
    } else if (selectedTab === TARGETING) {
      setSelectedTab(BUDGET)
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab])

  useDraftDataGetter({
    setters: {
      setSelectedCountries,
      setGeographyType,
      setCampaignType,
      setAudiences,
      setRecordSelected,
      setBudgetValue,
      setStartDate,
      setEndDate,
      setSelectedTab,
      setRetrievingDraftData,
      setCampaignDraftData,
      setIsSavingDraft,
    } as Record<string, Dispatch<unknown>>,
    draft,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    requiredDataChanged,
    recordSelected,
    retrievingDraftData,
    dataToSaveDraft: dataToSaveDraft as unknown as Record<string, unknown>[],
    draft,
    currentBrand,
    savingDraftStatus
  })

  return (
    <Grid>
      <TopBar
        title={mainTitle}
        showSaveDraftChip={!!draft || !!recordSelected}
        showCloseIcon
        savingChanges={isSavingDraft}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          className: 'stepButtonsVideoAds',
          handleClickBackButton,
          handleClickNextButton,
          showNextButton: selectedTab !== FINALIZATION,
          disableNextButton:
            (selectedTab === RECORD && !recordSelected) ||
            (selectedTab === BUDGET && disableNextButtonWhenBudgetTab) ||
            selectedTab === FINALIZATION,
          nextButtonLabel: getNextButtonLabel(isNextButtonMobile, selectedTab),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab,
          }),
        }}
      >
        <MainContentContainer className={classes.mainContainer}>
          <Grid
            className={`bg-white h-full w-full mb-${mainContainerMb} rounded-${isMobile ? "none" : "xl mt-6"}`}
          >
            <TabPanel hidden={selectedTab !== RECORD}>
              <VideoTab
                showNextButton={false}
                youtubeConnected={youtubeConnected}
                setVideoSelected={setRecordSelected}
                videoSelected={recordSelected}
                nextTab={handleRecordTabNextButton}
                brand={currentBrand}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== TARGETING}>
              <VideoTargetingTab
                showNextButton={false}
                setGeographyType={setGeographyType}
                setTargetingType={setCampaignType}
                targetingType={campaignType}
                geographyType={geographyType}
                selectedAudiences={selectedAudiences}
                setAudiences={setAudiences}
                nextTab={handleTargetingTabNextButton}
                setSelectedCountries={setSelectedCountries}
                selectedCountries={selectedCountries}
              />
            </TabPanel>
            <SpendBudgetProvider
              budgetValue={budgetValue}
              step={selectedTab as string}
              campaignType={GLOBAL_CAMPAIGN_TYPES.increase_video_views}
            >
              <TabPanel hidden={selectedTab !== BUDGET}>
                <BudgetTab
                  brand={currentBrand}
                  geographyType={geographyType}
                  targetingType={campaignType}
                  budgetValue={budgetValue}
                  setBudgetValue={setBudgetValue}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  finishFlow={handleBudgetTabNextButton}
                  showReviewCampaignButton={false}
                />
              </TabPanel>
              <TabPanel hidden={selectedTab !== FINALIZATION}>
                <Finalization
                  refundFeeConfirmed={userConfirmedRefundFee}
                  setRefundConfirmation={setUserConfirmedRefundFee}
                  confirmed={userConfirmedCampaign}
                  setConfirmation={setUserConfirmedCampaign}
                  campaignId={campaignDraftData?.id as string}
                  budget={budgetValue}
                  startDate={startDate}
                  endDate={endDate}
                  targetingType={campaignType}
                  geographyType={geographyType}
                  brand={currentBrand}
                  record={recordSelected}
                  targeting={selectedAudiences}
                  selectedCountries={selectedCountries}
                  cancelSaving={cancelSavingDraft}
                />
              </TabPanel>
            </SpendBudgetProvider>
          </Grid>
        </MainContentContainer>
      </CustomStepper>
    </Grid>
  )
}

export default VideoViews;