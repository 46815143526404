import { ToastOptions } from "react-toastify"
import Colors from "modules/Colors";
export enum PageTabs {
  BRANDS = "BRANDS",
  BILLING = "BILLING",
  MARKETING_CAMPAIGNS = "MARKETING_CAMPAIGNS",
  WEBSITES = "WEBSITES",
  FANS = "FANS",
  TEAM= "TEAM",
}

const TOAST_DEFAULT_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  closeButton: true,
};

export const TOAST_SUCCESS_OPTIONS: ToastOptions = {
  ...TOAST_DEFAULT_OPTIONS,
  style: {
    background: Colors.green,
    color: Colors.textColorWhite,
    margin: "16px 16px auto 16px",
    fontSize: "16px",
    borderRadius: "8px",
  }
};

export const TOAST_ERROR_OPTIONS: ToastOptions = {
  ...TOAST_DEFAULT_OPTIONS,
  style: {
    background: Colors.red,
    color: Colors.textColorWhite,
    margin: "16px 16px auto 16px",
    fontSize: "16px",
    borderRadius: "8px",
  }
};