import { useContext } from 'react';
import millify from "millify";
import ReactCountryFlag from "react-country-flag";
import { capitalizeFirstLetter } from "helpers/General";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GraphTooltip } from "pages/post-auth/DashboardModule/components/Graphs";
import { AxisInterval } from "recharts/types/util/types";
import { fixCountryNameForUnited, performanceTextForDemos, performanceTextForLocations } from "./helpers";
import { BarChartOutlined } from "@material-ui/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { CurrencyCodesType } from "types/global";
import { DEFAULT_CURRENCY } from "modules/Const";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import useScreen from "Hooks/useScreen";
import ButtonSymphony from 'components/shareable/ButtonSymphony';

interface DemographicsStatsProps {
    loading: boolean,
    shown: boolean,
    showMarkets?: () => void,
    campaignDetails: {
        startDate: string;
        endDate: string;
        platform_details: any;
        ad_account: string;
    };
    geographicBreakdown: {
        countries: any[],
        regions: any[],
        dmas: any[]
    };
    demographicBreakdown: any
    currency?: CurrencyCodesType
}

const DemographicsStats = ({
    geographicBreakdown,
    demographicBreakdown,
    shown,
    loading,
    showMarkets,
    currency,
}: DemographicsStatsProps) => {
    const { isProBrand } = useContext(CurrentBrandContext)
    const { mobileView } = useScreen()

    const renderLocationData = (key: 'regions' | 'countries' | 'dmas', title: string, subtitle: string) => {
        const data = geographicBreakdown[key]

        if (!data) return null

        const dataMappings: Record<string, any> = {}

        const processedData = data.filter((item: {
            name: string,
            dma?: string,
        }) => {
            return item.dma ? item.dma !== 'Unknown' : true
        })
            .map((item: {
                country?: string,
                countryName?: string,
                region?: string,
                dma?: string,
                cpc: number,
                impressions: number,
                clicks: number,
                spend: number,
                reach: number
            }) => {
                let name
                let countryCode
                switch (key) {
                    case 'regions':
                        name = item.region
                        countryCode = null
                        break;
                    case 'countries':
                        name = item.countryName
                        countryCode = item.country
                        break
                    case 'dmas':
                        name = item.dma
                        countryCode = null
                        break;
                    default:
                        break;
                }

                if (name) {
                    dataMappings[name] = {
                        type: key,
                        countryCode: countryCode ? countryCode : null,
                        clicks: item.clicks,
                        cpc: item.cpc,
                        reach: item.reach,
                        spend: item.spend
                    }

                }
                return {
                    name: name,
                    type: key,
                    countryCode: countryCode ? countryCode : null,
                    countryName: item.countryName ? item.countryName : null,
                    value: item.clicks,
                    clicks: item.clicks,
                    cpc: item.cpc,
                    reach: item.reach,
                    spend: item.spend
                }
            })

        const CustomizedAxisTick = ({
            x,
            y,
            payload
        }: any) => {
            const labelWidth = 120

            const item = dataMappings[payload.value]

            var itemName = item.type === 'countries' && mobileView ? item.countryCode : payload.value
            return (
                <g transform={`translate(${x - (labelWidth / 2)},${y}) ${mobileView ? `rotate(0 ${100 / 2} ${labelWidth / 2})` : ''}`}>
                    <foreignObject x={0} y={0} width={labelWidth} height={100} className="overflow-visible">
                        {mobileView ?
                            <div className="w-full text-center overflow-ellipses">
                                {item.countryCode && (<div >
                                    <ReactCountryFlag
                                        style={{
                                            fontSize: '0.9em'
                                        }}
                                        countryCode={item.countryCode} />
                                </div>)}
                                <div className="mt-0.5 text-sm" >{itemName}</div>
                            </div>
                            :
                            <div className="w-full text-center overflow-ellipses">
                                {item.countryCode && (<div >
                                    <ReactCountryFlag
                                        style={{
                                            fontSize: '1.1em'
                                        }}
                                        countryCode={item.countryCode} />
                                </div>)}
                                <div className="mt-0.5 text-base" >{itemName}</div>
                                <div className="text-sm text-gray-500">{millify(item.clicks)} clicks</div>
                                <div className="text-sm text-gray-500">{getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{(item.cpc).toLocaleString('en', {
                                    type: 'currency',
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })} CPC</div>
                            </div>
                        }
                    </foreignObject>
                </g>
            );
        }


        let barInterval: number | AxisInterval = 5
        if (mobileView) {
            if (key === 'countries') {
                barInterval = 0
            } else {
                barInterval = 1
            }
        } else {
            barInterval = 0
        }

        let emptyState = false
        if (processedData.length === 0) emptyState = true

        let firstLocation: any
        let secondLocation: any
        let subtitleSentence: string

        if (processedData.length > 2) {
            firstLocation = processedData[0].name
            secondLocation = processedData[1].name
            subtitleSentence = performanceTextForLocations([firstLocation, secondLocation]);
        } else if (processedData.length === 0) {
            firstLocation = null
            secondLocation = null
            subtitleSentence = `We're still waiting for data to come in for top ${key === 'dmas' ? 'cities' : key}.`
        } else {
            firstLocation = fixCountryNameForUnited(processedData[0].name!)
            subtitleSentence = `Top clicks came from ${firstLocation}.`
        }

        return (
            <div className="demographicsStatsCustomBox border-b py-7">
                <div className="flex justify-between items-center">
                    <div className="lg:px-3 pb-5 mt-2">
                        <p className="text-lg text-gray-500 flex flex-row items-center gap-1">
                            {title}<UpgradeToProChip type="gem" color="purple" height={18} width={18} />
                        </p>
                        <p className="text-xl ">{subtitleSentence}</p>
                    </div>
                    {showMarkets && !emptyState && !mobileView &&
                        <div className="flex-shrink-0">
                            <ButtonSymphony
                                className="buttonDemographicsStats"
                                onClick={showMarkets}
                                variant="text"
                            >
                                <BarChartOutlined /> See Stats
                            </ButtonSymphony>
                        </div>
                    }
                </div>
                {!emptyState && (<div>
                    <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 300 : 300} minHeight="100px">
                        <BarChart
                            width={500}
                            height={300}
                            margin={{
                                bottom: mobileView ? 20 : 80
                            }}
                            data={processedData?.slice(0, 5)}
                        >
                            <XAxis type="category" dataKey="name"
                                axisLine={false}
                                tickLine={false}
                                interval={barInterval}
                                tick={<CustomizedAxisTick />}
                            />
                            <YAxis
                                hide={true}
                                domain={[(dataMin: any) => (0), (dataMax: any) => (dataMax * 1.02)]}
                            />
                            <Tooltip
                                cursor={{ fill: '#8800FF', fillOpacity: 0.1 }}
                                content={({ active, payload, label }) => {

                                    if (active && payload && payload.length) {
                                        let mainData: any = payload[0]
                                        if (mainData && mainData.payload) {
                                            mainData = mainData.payload
                                        } else {
                                            return null
                                        }

                                        const cpc = mainData.cpc!.toLocaleString('en', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                            type: 'currency'
                                        })
                                        const clicks = mainData.clicks!.toLocaleString('en', {
                                            maximumFractionDigits: 0
                                        })
                                        const spend = mainData.spend!.toLocaleString('en', {
                                            maximumFractionDigits: 0,
                                            type: 'currency'
                                        })

                                        var mainLabel = label
                                        if (key === 'regions') {
                                            mainLabel = `${mainLabel}`
                                        }
                                        return (
                                            <GraphTooltip
                                                title={mainLabel}
                                                value={(
                                                    <div>
                                                        <p className="font-semibold text-md">
                                                            {clicks} clicks
                                                        </p>
                                                        <p className="font-semibold text-md">
                                                            {getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{cpc} CPC
                                                        </p>
                                                        <p className="font-semibold text-md">
                                                            {getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{spend} spent
                                                        </p>
                                                    </div>
                                                )}
                                            />
                                        );
                                    }

                                    return null;
                                }}

                            // formatter={(value: any, name: any, props: any) => [value.toLocaleString(), "Total Fanbase Size"]} 
                            />

                            <Bar
                                dataKey="value"
                                fill="#C698FF"
                                radius={6}
                            >
                                {/* <LabelList dataKey="value" position="top"
                                    formatter={(value: number) => `${value.toLocaleString('en', {
                                        maximumFractionDigits: 0,
                                    })}%`}
                                    style={{ fill: "black" }} /> */}
                            </Bar>

                        </BarChart>
                    </ResponsiveContainer>
                </div>)}

            </div>
        )
    }

    const renderDemoData = () => {
        const data = demographicBreakdown

        if (!data) return null

        const dataMappings: Record<string, any> = {}

        const processedData = data.filter((item: {
            age: string,
            gender: string,
            cpc: number,
            impressions: number,
            clicks: number,
            spend: number
        }) => {
            return true
        })
            .map((item: {
                age: string,
                gender: string,
                cpc: number,
                impressions: number,
                clicks: number,
                spend: number
            }) => {

                var capitalizedGender = capitalizeFirstLetter(item.gender)
                const combined = `Age ${item.age} / ${capitalizedGender}`

                dataMappings[combined] = {
                    age: item.age,
                    gender: capitalizedGender,
                    name: combined,
                    value: item.clicks,
                    clicks: item.clicks,
                    cpc: item.cpc,
                    spend: item.spend
                }



                return {
                    age: item.age,
                    gender: capitalizedGender,
                    name: combined,
                    value: item.clicks,
                    clicks: item.clicks,
                    cpc: item.cpc,
                    spend: item.spend
                }
            })
            .slice(0, 5)

        const CustomizedDemoAxisTick = ({
            x,
            y,
            height,
            payload
        }: any) => {
            const item = dataMappings[payload.value]

            return (

                <text x={x} y={y} height={height}
                    type="category" orientation={"left"} className="recharts-text recharts-layer recharts-cartesian-axis-tick-value"
                    textAnchor="end"
                    style={{
                        fontSize: '0.95rem'
                    }}>
                    <tspan x={x} dy="-0.145em">{item.age}</tspan>
                    <tspan x={x} dy="1.3em">{item.gender}</tspan>
                </text>

            );
        }
        const renderCustomizedLabel = (props: any) => {
            const {
                x,
                height, value,
                offset,
                index
            } = props;
            const yValue = (index * 60) + 30
            var rectanglesBox = document.querySelectorAll('#barChart .recharts-bar-rectangles')
            let boxWidth = x
            if (rectanglesBox && rectanglesBox[0]) {
                boxWidth = rectanglesBox[0].getBoundingClientRect().width
            }

            if (value < 100) {
            } if (value < 1000) {

            } else if (value < 10000) {
                boxWidth -= 6

            } else if (value < 100000) {
                boxWidth -= 8
            } else if (value < 1000000) {
                boxWidth -= 10
            } else if (value >= 1000000) {
                boxWidth -= 12
            }

            return mobileView ? (
                <text y={yValue} height={height}
                    offset={offset}
                    style={{
                        fontSize: '0.8rem'
                    }}>

                    <tspan x={x - 25} dx={boxWidth} dy="-0.145em " textAnchor="middle"
                    >{millify(value)}</tspan>
                    <tspan x={x - 25} dx={boxWidth} dy="1.2em" textAnchor="middle"
                    >clicks</tspan>
                </text>

            ) : (
                <text x={boxWidth} y={yValue} height={height}
                    offset={offset}
                    textAnchor="start">

                    <tspan dy="0.355em" textAnchor="start">{millify(value)} clicks</tspan>
                </text>
            )
        };

        let emptyState = false
        if (processedData.length < 2) emptyState = true

        let firstSet: {
            age: string,
            gender: string
        }
        let secondSet: {
            age: string,
            gender: string
        }
        let subtitleSentence: string


        if (!emptyState) {
            firstSet = {
                age: processedData[0].age,
                gender: processedData[0].gender
            }
            secondSet = {
                age: processedData[1].age,
                gender: processedData[1].gender
            }
            subtitleSentence = performanceTextForDemos([firstSet, secondSet])
        } else {

            subtitleSentence = "We're still waiting for data to come in."
        }

        return (
            <div className="border-b py-7">
                <div className="lg:px-3 pb-5 mt-2">
                    <p className="text-lg text-gray-500 flex flex-row items-center gap-1">{"Top Demographics by Clicks"}<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                    <p className="text-xl ">{subtitleSentence}</p>

                </div>
                {!emptyState &&
                    <div>
                        <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 300 : 300} minHeight="100px">
                            <BarChart
                                id="barChart"
                                width={500}
                                height={300}
                                // margin={{
                                //     bottom: 80
                                // }}
                                margin={{
                                    left: mobileView ? 0 : 20
                                }}
                                layout="vertical"
                                data={processedData}
                            >
                                <XAxis
                                    type="number"
                                    hide
                                    domain={[(dataMin: any) => (0), (dataMax: any) => (dataMax * 1.3)]}
                                />

                                <YAxis
                                    type="category"
                                    dataKey="name"
                                    axisLine={false}
                                    tickLine={false}
                                    minTickGap={0}
                                    tickMargin={10}
                                    tick={<CustomizedDemoAxisTick />}
                                />

                                <Tooltip
                                    cursor={{ fill: '#8800FF', fillOpacity: 0.1 }}
                                    content={({ active, payload, label }) => {
                                        if (active && payload && payload.length) {
                                            let mainData: any = payload[0]
                                            if (mainData && mainData.payload) {
                                                mainData = mainData.payload
                                            } else {
                                                return null
                                            }

                                            const cpc = mainData.cpc!.toLocaleString('en', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                                type: 'currency'
                                            })
                                            const clicks = mainData.clicks!.toLocaleString('en', {
                                                maximumFractionDigits: 0
                                            })
                                            const spend = mainData.spend!.toLocaleString('en', {
                                                maximumFractionDigits: 0,
                                                type: 'currency'
                                            })

                                            var mainLabel = mainData.name

                                            return (
                                                <GraphTooltip
                                                    title={mainLabel}
                                                    value={(
                                                        <div>
                                                            <p className="font-semibold text-md">
                                                                {clicks} clicks</p>
                                                            <p className="font-semibold text-md">
                                                                {getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{cpc} CPC
                                                            </p>
                                                            <p className="font-semibold text-md">
                                                                {getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}{spend} spent
                                                            </p>
                                                        </div>
                                                    )}
                                                />
                                            );
                                        }

                                        return null;
                                    }}
                                // formatter={(value: any, name: any, props: any) => [value.toLocaleString(), "Total Fanbase Size"]} 
                                />

                                <Bar dataKey="value"
                                    id="bar"
                                    radius={6}
                                    fill="#D3B7F7"
                                    background={{ fill: '#EDECF2', radius: 6 }}
                                    barSize={50}
                                >
                                    <LabelList dataKey="value" position="right"
                                        formatter={(value: number) => `${value.toLocaleString('en', {
                                            maximumFractionDigits: 0,
                                        })} clicks`}
                                        style={{ fill: "black" }}
                                        content={renderCustomizedLabel}
                                    />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                }
            </div>
        )
    }

    if (!shown) return null

    if (loading || !(geographicBreakdown && demographicBreakdown)) {
        return (
            <div className="mx-auto my-6 flex-col flex items-center ">
                <NewLoader black />
                <p className="mt-1 text-center">Loading Demographics...</p>
            </div>
        );
    }

    if (!isProBrand) {
        return (
            <>
                <div className="px-6 lg:px-10 py-5 flex flex-col justify-between">
                    <UnlockDataBanner
                        source="IG Campaign Details - Demographics"
                        title="Track your ad analytics down to the city"
                        description="Go Pro to track the cities, countries, ages, and genders of fans who interact with your ads the most."
                        image={UnlockDataImage}
                        hideImage={mobileView}
                    />
                </div>
                <div className="px-6">
                    <div className="lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex flex-row gap-1 items-center">Top Countries by Clicks<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">These are the top 10 countries that clicked on your ads the most.</p>
                    </div>
                    <img src={require("../../../../assets/images/campaignDetails/impactBlurredCountries.png").default} alt="impactBlurredCountries" />
                    <div className="px-6 lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex flex-row gap-1 items-center">Top Demographics by Clicks<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">These are the top 5 age &amp; gender breakdowns that clicked on your ads the most.</p>
                    </div>
                    <img src={require("../../../../assets/images/campaignDetails/impactBlurredDemographics.png").default} alt="impactBlurredDemographics" />
                </div>
            </>
        );
    }

    return (
        <div className="px-6">
            {/* <div className="border-b pb-4">
                <div className="lg:px-3 py-5 mt-2">
                    <p className="text-lg text-gray-500">Top Demographics by Clicks</p>
                    <p className="text-xl ">These are the top 5 age &amp; gender breakdowns that clicked on your ads the most.</p>

                </div>

                <div>
                    <ul className="max-h-80 overflow-auto">
                        {
                            demographicBreakdown
                                .slice(0, 5)
                                .map((item: {
                                    age: string,
                                    gender: string,
                                    cpc: number,
                                    impressions: number,
                                    clicks: number,
                                    spend: number
                                }) => {

                                    // get friendly name of service
                                    const combined = `Age ${item.age} / ${capitalizeFirstLetter(item.gender)}`
                                    return (
                                        <RowItem
                                            key={combined}
                                            // onClick={() => selectYoutubeChannel(item)}
                                            // image={image}
                                            imageStyles={`h-6`}
                                            name={combined}
                                            showRightValue={true}
                                            rightValue={`${millify(item.clicks)} clicks`}

                                        />
                                    );
                                })
                        }
                    </ul>
                </div>
            </div> */}
            {renderDemoData()}

            {renderLocationData(
                'countries',
                'Top Countries by Clicks',
                'These are the top 10 countries that clicked on your ads the most.'
            )}

            {renderLocationData(
                'regions',
                'Top Regions by Clicks',
                'These are the top 10 regions that clicked on your ads the most.'
            )}

            {renderLocationData(
                'dmas',
                'Top Cities by Clicks',
                'These are the top 10 cities that clicked on your ads the most.'
            )}
        </div>
    )
}

export default DemographicsStats;