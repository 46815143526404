/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from "react"
import "styles/react-datepicker.css"
import BudgetCard from "./Components/BudgetCard"
import Grid from "@material-ui/core/Grid"
import useScreen from "Hooks/useScreen"

interface BudgetProps {
  budgetValue: number
  startDate: Date | null
  endDate: Date | null
  setBudgetValue: Dispatch<SetStateAction<number>>
  setStartDate: Dispatch<SetStateAction<Date | null>>
  setEndDate: Dispatch<SetStateAction<Date | null>>
}

const BudgetTab = ({
  budgetValue,
  startDate,
  endDate,
  setBudgetValue,
  setStartDate,
  setEndDate,
}: BudgetProps) => {
  const { mobileView } = useScreen()
  const [currentEndSchedule] = useState("scheduled")
  const disableMobileButton = !(
    typeof budgetValue !== "undefined" &&
    budgetValue !== null &&
    budgetValue >= 5 &&
    startDate &&
    endDate
  )

  const handleChangeBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value >= 0) setBudgetValue(value)
  }

  const handleSelectStartDate = (date: Date | null) => setStartDate(date)

  const handleSelectEndDate = (date: Date | null) => setEndDate(date)

  return (
    <div className="tikTokAdsBudgetTabComponent" style={{ backgroundColor: "#EDECF2", minHeight: "300px" }}>
      <Grid className={mobileView ? "mb-0" : "mb-4"}>
        <BudgetCard
          budgetValue={budgetValue}
          handleChangeInputText={handleChangeBudget}
        />
      </Grid>
      {/* <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <DatesCard
          {...{
            startDate,
            endDate,
            currentEndSchedule,
            budgetValue,
            handleSelectStartDate,
            handleSelectEndDate,
          }}
        />
      </Grid> */}
      {/* <RunningForCard
        {...{
          geographyType,
          budgetValue,
          startDate,
          endDate,
          finishFlow,
          disableMobileButton,
          showReviewCampaignButton,
        }}
      /> */}
    </div>
  )
}

export default BudgetTab