import { useContext, useEffect, useState } from "react";
import { checkForFBPermissions, loginWithFB } from "../../helpers/FB";
import { clsx } from "modules/Utils";
import useStyles from "./styles";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import { ConnectedInstagramAccountType, CreativeTabConnectTypes, InstagramBusinessAccountType } from "types/global";
import { InstagramPageType } from "types/global";
import Intercom from "helpers/Intercom";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import AccountContainerIg from "components/AccountContainerIg";
import HeaderModal from "./connectModals/Spotify/Components/HeaderModal";
import { DialogContent } from "@material-ui/core";
import DialogPaper from "components/shareable/DialogPaper";
import FooterModal from "components/FooterModal";
import LoadingLottie from "components/Loader/LoadingLottie";
import useScreen from "Hooks/useScreen";
import { ConnectionsContext } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext";
import { updateInBrand } from "services/symphonyApi/brandService";
import { getInstagramAccountFromFbPage } from "services/facebookApi";
import UnloggedView from "pages/post-auth/MarketingPage/Components/UnloggedView";

export interface ConnectorInstagramPagesType {
    connected_instagram_account: ConnectedInstagramAccountType | null,
    instagram_business_account: InstagramBusinessAccountType | null,
    instagram_account?: {
        follow_count: number;
        followed_by_count: number;
        id: string;
        profile_pic: string;
        username: string;
    } | null,
    facebookPage?: {
        name: string,
        id: string,
        category: string,
        access_token: string,
        followers_count: number,
        picture: string,
        is_published: boolean
    }
}


enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Instagram Pages because we don't have permission. Click the button below to authorize access to your pages."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Instagram pages associated with your Facebook page. To run ads using your Instagram, please link your Instagram to your Facebook page by following the instructions at the link."
    }
}

interface InstagramConnectProps {
    open: boolean;
    closeModal: () => void;
    localSave?: any;
    withBorderRadius?: boolean;
}

export const InstagramModal = ({
    open,
    closeModal,
    localSave,
    withBorderRadius,
}: InstagramConnectProps) => {
    const { saveData } = useContext(ConnectModalsContext)
    const { currentBrand: artist, reloadBrand } = useContext(CurrentBrandContext)

    const {
        loggedInFbUser,
        facebookPage: selectedFBPage,
        instagramPage: selectedInstaPage,
        setInstagramPage: selectInstagramPage,
        setInstagramPagesAvailable,
        setValidateConnections,
    } = useContext(ConnectionsContext)

    const classes = useStyles();
    const { mobileView } = useScreen();

    const [checkedIg, setCheckedIg] = useState<boolean>(false)
    const [gettingPageOption, setGettingPageOption] = useState<boolean>(false)
    const [instagramOption, setInstagramOption] = useState<InstagramPageType>()
    const [connecting, setConnecting] = useState<boolean>(false)
    const [error, setError] = useState<any>(null);
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES | null>(null)

    const isDisabledConnectButton = error || !selectedInstaPage

    async function getIgPage() {
        if (!selectedFBPage || !loggedInFbUser) return;
        setGettingPageOption(true)

        const response = await getInstagramAccountFromFbPage(selectedFBPage, loggedInFbUser?.access_token)
        if (response) {
            setInstagramOption(response)
            setInstagramPagesAvailable(true)
        } else {
            setError(true)
            setInstagramPagesAvailable(false)
        }

        setGettingPageOption(false)
        setCheckedIg(true)
    }

    useEffect(() => {
        getIgPage()
        if (open && selectedInstaPage) setCheckedIg(true)
    }, [open]);

    async function checkPermissions() {
        const status: any = await checkForFBPermissions([
            'instagram_basic',
            'instagram_manage_insights'
        ], loggedInFbUser?.access_token)
        if (!status || status.length > 0) {
            // if invalid, show dialog to re-request permissions

            setPermissionsError(ERROR_STATES_STATUSES.PERMISSIONS_ERROR)
        } else {
            // there are no pages
            setPermissionsError(ERROR_STATES_STATUSES.NO_PAGES_ERROR)
        }

        setGettingPageOption(false)

    }

    async function reRequestPermissions() {
        const p = await loginWithFB(("rerequest" as any))
        if (p) {
            setError(null)

            setPermissionsError(ERROR_STATES_STATUSES.NONE)
            setCheckedIg(false)
            getIgPage()
        }
    }

    useEffect(() => {
        if ((checkedIg && !selectedInstaPage) || (selectedInstaPage && !selectedInstaPage.instagramAccount && !selectedInstaPage.connected_instagram_account)) {
            checkPermissions()
        }
    }, [selectedInstaPage, checkedIg])


    async function handleOnSelectInstagramPage() {
        if (!instagramOption || !selectedFBPage) return;

        setConnecting(true)

        if (!localSave) {
            const response = await updateInBrand(artist!.id, artist!.slug!, instagramOption, 'instagram_page')
            if (response) {
                selectInstagramPage(instagramOption)
                setValidateConnections(true)
                reloadBrand()
            } else {
                setError(true)
            }
        } else {
            selectInstagramPage(instagramOption)
            setValidateConnections(true)
        }
        setConnecting(false)
        closeModal()
    }

    useEffect(() => {
        saveData({
            loading: connecting,
            connectModalType: CreativeTabConnectTypes.INSTAGRAM_CONNECT,
            isDisabledConnectButton,
            handleConnect: handleOnSelectInstagramPage,
        })
    }, [isDisabledConnectButton, selectedInstaPage, connecting])

    function handleNonError() {

        if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR
            || permissionsError === ERROR_STATES_STATUSES.NONE) {
            return (
                <><p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        onClick={() => {
                            Intercom.openFacebookInstagramConnectorArticle()
                        }} className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Learn how to link your Instagram &amp; Facebook Page &gt;
                    </a>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {

            return (
                <><p className="my-4">{ERROR_STATES.PERMISSIONS_ERROR.message}</p>
                    <button type="button"
                        onClick={() => reRequestPermissions()}
                        className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Add Instagram Permissions &gt;
                    </button>
                </>
            )
        }

    }

    function postLoadingComponent() {
        if (gettingPageOption) return <LoadingLottie black />
        if (instagramOption && (instagramOption.connected_instagram_account)) {
            const {
                connected_instagram_account,
                instagramAccount: instagram_account,
            } = instagramOption!

            const profilePic = connected_instagram_account ? connected_instagram_account!.profile_picture_url : instagram_account?.profile_pic
            let checked
            let username = connected_instagram_account ? connected_instagram_account.username : instagram_account?.username
            if (connected_instagram_account) {
                checked = selectedInstaPage && selectedInstaPage.connected_instagram_account ? selectedInstaPage?.connected_instagram_account!.id === connected_instagram_account!.id : false
            } else if (instagram_account) {
                checked = selectedInstaPage && selectedInstaPage.instagramAccount ? selectedInstaPage?.instagramAccount!.id === instagram_account!.id : false
            }

            return (
                <div className={classes.accountsContainer}>
                    <AccountContainerIg
                        className="accountContainerIgInstagramModal"
                        fbAccount={selectedFBPage?.name || ''}
                        fbImg={selectedFBPage?.picture?.data?.url}
                        instagramAccount={`@${username}`}
                        instagramImg={profilePic}
                        buttonText={selectedInstaPage?.id === instagramOption.id ? "Connected" : "Use"}
                        onClick={() => handleOnSelectInstagramPage()}
                        disabledButton={selectedInstaPage?.id === instagramOption.id}
                    />
                </div>
            )
        } else {
            return handleNonError()
        }
    }

    return (
        <DialogPaper
            open={open}
            fullScreen={Boolean(mobileView && loggedInFbUser)}
            dialogStyle={{
                margin: mobileView && loggedInFbUser ? 0 : 'auto 16px',
                borderRadius: mobileView && loggedInFbUser ? 0 : 8,
                maxWidth: 559,
            }}
            containerStyle={{
                margin: mobileView ? 0 : 'auto',
                padding: mobileView ? 8 : '32px 24px',
            }}
        >
            <DialogContent className="flex flex-col gap-8">
                {!loggedInFbUser ? (
                    <UnloggedView onClose={closeModal} />
                ) : (
                    <>
                        <HeaderModal
                            closeModal={closeModal}
                            title="Facebook and Ig Pages"
                            subtitle="Select the accounts on which your ads will be launched."
                        />
                        <div className={clsx("bg-white w-full mx-auto", withBorderRadius && classes.withBorderRadius)}>
                            <ul className="add-new">
                                {postLoadingComponent()}
                            </ul>
                        </div>
                        <FooterModal
                            footerText="Don’t see your Facebook or Instagram page listed? Make sure you've linked your page with Symphony. If you still don't see it, you may need to get access to the page."
                            footerButton="Link a page to Symphony"
                            footerButtonOnClick={() => reRequestPermissions()}
                        />
                    </>
                )}
            </DialogContent>
        </DialogPaper>
    )
}
