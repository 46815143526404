import { CSSProperties } from "react";
import Colors from "modules/Colors";
interface LegendCampaignAnnotationsProps {
    className?: string;
    style?: CSSProperties;
}

const LegendCampaignAnnotations = ({
    className = '',
    style = {},
}: LegendCampaignAnnotationsProps) => {
    const legendItems = [
        {
            title: 'Campaign',
            color: Colors.purple
        },
        {
            title: 'Instagram Post',
            color: Colors.orangeAnnotation
        },
        {
            title: 'Spotify Release',
            color: Colors.green
        }
    ]

    return (
        <div
            className={`${className} legendCampaignAnnotationsComponent`}
            style={sty.legendCampaignAnnotationsComponent}
        >
            {legendItems.map((item, index) => (
                <div
                    key={index}
                    className="legendItemContainer"
                    style={sty.legendItemContainer}
                >
                    <div style={{ width: 20, borderWidth: 1, borderColor: item.color, borderStyle: 'dashed' }} />
                    <div style={{ color: item.color }}>{item.title}</div>
                </div>
            ))}
        </div>
    )
}

const sty = {
    legendCampaignAnnotationsComponent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        gap: 10,
        flexWrap: 'wrap',
        width: '100%',
        textAlign: 'center',
        justifyContent: 'center',
    } as CSSProperties,
    legendItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    } as CSSProperties
}

export default LegendCampaignAnnotations