import React, { Fragment, useContext, useRef, useState } from 'react';
import { FlexContainer, LeftContainer, RightContainer, useStyles, SymphonyLogoContainer, TitleContainer, Title, Subtitle, EmailInputForm, LottieContainer } from './style';
import { InputBase } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg'
import Axios from 'helpers/Interceptor';
import { Alert } from '@material-ui/lab';
import Lottie from 'react-lottie';
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import { useHistory } from 'react-router-dom';
import { isValidEmail } from 'modules/Utils';
import useScreen from 'Hooks/useScreen';
import { CurrentUserContext } from 'Hooks/CurrentUserContext';

const lottieDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: GetStartedLottie,
};

const EnterEmail = () => {
  const classes = useStyles();
  const { mobileView } = useScreen();
  const { currentUser, setCurrentUser , loadedBrands, loadingBrands } = useContext(CurrentUserContext)
  const history = useHistory();

  const [ alert, setAlert ] = useState(false);
  const [ alertContent, setAlertContent ] = useState('');
  const [ disable, setDisable ] = useState(false);
  const inputEmail = useRef<HTMLInputElement>();

  const handleCloseAlert = () => setAlert(false);

  const handleOnCreateAccount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAlert(false);
    const email = String(inputEmail?.current?.value);
    if (!isValidEmail(email)) {
      setAlert(true);
      setAlertContent('Please type a valid email');
      return;
    }
    setDisable(true);
    const payload = {
      ...currentUser,
      email
    }
    try {
      const response = await Axios.post('/user/add-email', payload);
      
      if (response.status >= 400) {
        setAlert(true);
        setAlertContent(response.statusText);
        setDisable(false);
        return;
      }

      setAlert(false);

      if (loadedBrands.length) {
        history.replace("/");
      } else {
        history.replace('/add-artist');
      }

      setDisable(false);
      
      if (currentUser) {
        setCurrentUser({
          ...currentUser,
          email,
          id: currentUser.id
        });
      }
    } catch (e) {
      setAlert(true);
      setAlertContent('Unexpected error');
      setDisable(false);
    }
  }

  return (
    <Fragment>
      <FlexContainer>
        <LeftContainer width={mobileView ? "100%" : "50%"}>
          {alert && <Alert onClose={handleCloseAlert} severity='error'>{alertContent}</Alert>}
          <SymphonyLogoContainer>
            <SymphonyLogo height="30"/>
          </SymphonyLogoContainer>
          <TitleContainer>
            <Title>Add your email</Title>
            <Subtitle>To finish creating your account, enter your email address.</Subtitle>
          </TitleContainer>
          <EmailInputForm>
            <InputBase
              className={classes.inputProps}
              inputRef={inputEmail}
              fullWidth
              type="email"
              disabled={disable}
              placeholder="Email"/>
            <Button
              variant="contained"
              disableElevation
              disableRipple
              disabled={disable}
              classes={{ text: classes.buttonText }}
              className={classes.button}
              onClick={handleOnCreateAccount}
              type="submit"
            >Create Account</Button>
          </EmailInputForm>
        </LeftContainer>
        <RightContainer display={mobileView ? "none" : "flex"}>
          <LottieContainer>
            <Lottie 
              options={lottieDefaultOptions}
            />
          </LottieContainer>
          </RightContainer>
      </FlexContainer>
    </Fragment>
  );
}

export default EnterEmail;
