import { SetStateAction, Dispatch, useRef, useState, useContext } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import { clsx } from 'modules/Utils';
import CircularStep from "../CircularStep";
import { VideoAdsRecord } from "types/global";
import { useHistory } from "react-router-dom";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import { createPayPalOrder } from "./api";
import { formatCountries } from "../../../utils";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import useScreen from "Hooks/useScreen";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import SubmitCampaignOrUpgrade from "pages/post-auth/MarketingPage/Components/GoLiveCard/SubmitCampaignOrUpgrade";
import { CheckoutType, DEFAULT_CURRENCY, PlanType } from "modules/Const";
import { showToast } from "modules/Utils";
import { redirectToOneOffCampaignCheckout } from "services/symphonyApi/checkout.service";
import { changeSubscriptionPlan, createCampaignAndUpgradePlanCheckout } from "services/symphonyApi/subscriptionService";

type PayPalOrder = {
  orderId: string | null;
  campaignId: string | null;
};

interface Props {
  campaignId: string | null
  startDate: string | Date | null;
  endDate: string | Date | null;
  budget: number;
  creatingCampaign?: boolean;
  record: VideoAdsRecord | null;
  targeting: VideoAdsRecord[];
  selectedCountries: { label: string; value: string }[];
  targetingType: string;
  geographyType: string;
  onCancelPayPalOrder?: () => void;
  onErrorPayPalOrder?: () => void;
  setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
  cancelSaving: () => void;
  confirmed: boolean;
  refundFeeConfirmed: boolean;
}

const GoLiveCard = ({
  campaignId,
  startDate,
  endDate,
  budget,
  record,
  creatingCampaign,
  selectedCountries,
  targeting,
  targetingType,
  geographyType,
  onCancelPayPalOrder,
  onErrorPayPalOrder,
  setCreatingCampaign,
  cancelSaving,
  confirmed,
  refundFeeConfirmed
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const {
    currentBrand,
    isProBrand,
    isLiteBrand,
    reloadBrand,
    proFreeTrialRedeemed
  } = useContext(CurrentBrandContext);
  const { mobileView } = useScreen()
  const { brandIsInOrganization, organization } = useCurrentTeam()
  const payPalOrder = useRef<PayPalOrder>({ orderId: null, campaignId: null });
  const currency = currentBrand?.currency?.code || DEFAULT_CURRENCY;

  const [selectedBillingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.MONTHLY) // [BillingCycle.MONTHLY, BillingCycle.ANNUALLY
  const [error, setError] = useState(false);

  console.log("selectedBillingCycle", selectedBillingCycle)

  const inOrganization = brandIsInOrganization(currentBrand?.slug!)

  const modifyCheckoutData = (checkoutType: CheckoutType, data: any) => {
    switch (checkoutType) {
      case CheckoutType.ONE_OFF_CAMPAIGN:
        const { campaign_input, checkout_source } = data;
        let modifiedData = {
          campaign_input,
          checkout_source,
        };
        return modifiedData;
      case CheckoutType.UPGRADE_PLAN:
      default:
        return data;
    }
  }


  const handleClickCampaignWithStripe = async (checkoutType: CheckoutType) => {
    cancelSaving();
    const data = {
      campaign_input: {
        id: campaignId,
        campaign_type: "increase_video_views",
        price_data: {
          price: budget,
          name: `YouTube Ads Campaign for "${record?.name}"`,
          description: `"${record?.name}" (Increase Video Views Campaign)`,
        },
        logistics: {
          refundFeeConfirmed,
          confirmedCampaignSettings: confirmed,
          budget,
          startDate,
          endDate,
        },
        content: {
          name: record?.name,
          id: record?.id,
          thumbnail_url: record?.primaryThumbnail,
          channelId: record?.channelId,
          channelTitle: record?.channelTitle,
        },
        targeting,
        targetingType,
        geographies: {
          type: geographyType,
          countries: formatCountries(geographyType, selectedCountries),
        },
        campaign_platforms: ["youtube"],
      },
      checkout_source: "Increase Video Views - Campaign Creation",
      recurring_interval: selectedBillingCycle
    };

    const finalDataForApi = modifyCheckoutData(checkoutType, data)

    setCreatingCampaign(true);

    let errorObject: any;
    switch (checkoutType) {
      case CheckoutType.ONE_OFF_CAMPAIGN:
        errorObject = await redirectToOneOffCampaignCheckout(currentBrand!, finalDataForApi);
        break;
      case CheckoutType.UPGRADE_PLAN:
        // - if its a lite plan, first upgrade the plan to pro,
        // then continue with the normal flow 
        // - if the user is already on Pro, they'll still need to pay budget
        // via the checkout page
        if (isLiteBrand) {
          // first call the upgrade plan api
          // then submit the campaign the traditional way 
          try {
            // First upgrade the plan from Lite to Pro
            await changeSubscriptionPlan({
              brandSlug: currentBrand!.slug,
              recurring_interval: selectedBillingCycle,
              checkout_source: data.checkout_source,
              plan_type: PlanType.PRO
            });

          } catch (error) {
            showToast({
              message: 'Failed to upgrade plan. Please contact support.',
              error: true,
              mobile: mobileView,
            });
            setError(true);
          }
        }


        errorObject = await createCampaignAndUpgradePlanCheckout(currentBrand!, finalDataForApi);
        // Reload brand after to ensure UI snt broken in update
        await reloadBrand();

        break;
    }

    if (errorObject) {
      showToast({
        message: 'Something went wrong. Please contact support.',
        error: true,
        mobile: mobileView,
      });
      setError(true);
      setCreatingCampaign(false);
    }
  };

  const onPayPalOrderApprove = async (
    _data: OnApproveData,
    _actions: OnApproveActions
  ) => {
    const { campaignId, orderId } = payPalOrder.current;
    const url = `/marketing/success?campaign_id=${campaignId}&session_id=${orderId}`;

    history.push(url);
  };

  const createPaypalOrder = async () => {
    cancelSaving();
    const data = {
      campaign_input: {
        id: campaignId,
        campaign_type: "increase_video_views",
        payment_method: "paypal",
        price_data: {
          price: budget,
          name: `YouTube Ads Campaign for "${record?.name}"`,
          description: `"${record?.name}" (Increase Video Views Campaign)`,
        },
        logistics: {
          refundFeeConfirmed: true,
          confirmedCampaignSettings: true,
          budget,
          startDate,
          endDate,
        },
        content: {
          name: record?.name,
          id: record?.id,
          thumbnail_url: record?.primaryThumbnail,
          channelId: record?.channelId,
          channelTitle: record?.channelTitle,
        },
        targeting,
        targetingType,
        geographies: {
          type: geographyType,
          countries: formatCountries(geographyType, selectedCountries),
        },
        campaign_platforms: ["youtube"],
      },
    };

    setCreatingCampaign(true);

    const order = await createPayPalOrder({
      currentBrand,
      record,
      data,
    });

    const { orderId } = order || {};
    setCreatingCampaign(false);

    return orderId;
  };

  return (
    <Card borderRadius={mobileView ? undefined : "12px"}
      className={!confirmed || !refundFeeConfirmed ? 'pointer-events-none opacity-80	filter saturate-0	transition-all	' : 'filter saturate-1 transition-all	'}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step="⚡" />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Let’s go live
            </StyledLabel>
          </Grid>
        </Grid>
        <SubmitCampaignOrUpgrade
          submissionButtonIsLoading={creatingCampaign ?? false}
          error={error}
          currencyCode={currency}
          allowsDirectPayment={true}
          handleClickCampaignWithStripe={handleClickCampaignWithStripe}
          selectedBillingCycle={selectedBillingCycle}
          setBillingCycle={setBillingCycle}
          campaignDisclaimer="Your marketing campaign may take up to 48 hours to go live. You will be redirected to a payment page to finish checkout."
          onErrorPayPalOrder={onErrorPayPalOrder}
          onPayPalOrderApprove={onPayPalOrderApprove}
          createPaypalOrder={createPaypalOrder}
          onCancelPayPalOrder={onCancelPayPalOrder}
        />
      </Grid>
    </Card>
  );
};

export default GoLiveCard;
