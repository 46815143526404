import { FacebookAdAccountInfo, FacebookCampaignIssuesBanner } from "../Components/FacebookCampaignIssuesBanner";
import FacebookCampaignSimpleBanner, { SimpleBannerProps } from "../Components/FacebookCampaignIssuesBanner/FacebookCampaignSimpleBanner";
import { OverallCampaignStatus } from "types/global";

type ErrorActionDetails = {
  title: string
  description?: string
  ctaText?: string
  ctaLink?: string
  supportMessage?: string
  intercomArticleId?: string
}

export type FacebookErrorAction = Record<string, ErrorActionDetails>

interface RenderErrorStatesProps {
  adAccountInfo: FacebookAdAccountInfo,
  facebookErrors?: any,
  facebookErrorCodes?: string[],
  campaignStatus: string,
  conversionsEventName?: string,
  adAccountStatus?: { error: string, good: boolean },
}

const getStatusErrorData = ({
  adAccountInfo,
  campaignStatus,
  adAccountStatus,
}: RenderErrorStatesProps): SimpleBannerProps | null => {
  const { DENIED, ERROR, LIVE_WITH_ISSUES, PROCESSING } = OverallCampaignStatus;
  switch (campaignStatus) {
    case DENIED: {
      return {
        title: "Your ad campaign wasn't approved by Facebook's Ads Review.",
        description: "It looks like your ad campaign was denied during Facebook's Ads Review - this happens automatically on Facebook's side after a campaign is submitted. Please make sure you use assets with no swear words or explicit content. You can re-submit your campaign by creating a new campaign, or request a review below.",
        ctaLink: 'https://business.facebook.com/business-support-home',
        ctaText: 'Request a Review on Facebook',
      } as SimpleBannerProps
    }
    case ERROR: {
      if (!adAccountStatus?.good) {
        return {
          title: 'It looks like your Facebook Ad Account is unsettled.',
          description: 'Your ad campaign was submitted but your Ad Account has a remaining balance to pay before your ads can keep running. Please take care of that, and your ads should resume automatically.',
          ctaText: 'View Ad Account Payment Status',
          ctaLink: `https://business.facebook.com/billing_hub/payment_settings/?asset_id=${adAccountInfo?.id}`
        } as SimpleBannerProps
      }
      break
    }
    case LIVE_WITH_ISSUES:
      return {
        title: 'Your campaign is partially live, but some of your ads got denied by Facebook.',
        description: 'It looks one or more of the ad assets you uploaded were denied by Facebook’s Ads Review process - though not all of them. Your campaign will still run, but we recommend requesting a review on the denied assets.',
      } as SimpleBannerProps
    case PROCESSING: {
      return {
        title: 'Your campaign is being processed',
        description: 'Your campaign is being processed. It will be live shortly.',
      } as SimpleBannerProps
    }
  }
  return null
}

export function renderErrorStates({
  adAccountInfo,
  facebookErrors,
  campaignStatus,
  conversionsEventName,
  adAccountStatus,
}: RenderErrorStatesProps) {

  if (facebookErrors?.length) {
    return (
      <FacebookCampaignIssuesBanner
        adAccount={{ ...adAccountInfo }}
        facebookErrors={facebookErrors}
        conversionsEventName={conversionsEventName}
      />
    )
  }

  const statusErrorData = getStatusErrorData({
    adAccountInfo,
    campaignStatus,
    facebookErrors,
    adAccountStatus,
  })

  if (statusErrorData) return <FacebookCampaignSimpleBanner {...statusErrorData} />

  return null
}