import dayjs from 'dayjs';

const NAME_BLUR_TEXT = "Xxxxx Xxxxxx";

export const getFormattedDate = (date: string) => {
  return dayjs(date).format('MMMM DD, YYYY');
}

export const getFormattedName = (data: { name?: string, lastname?: string, isProBrand?: boolean }) => {
  if (!data.isProBrand) return NAME_BLUR_TEXT;
  const formattedName = [data.name, data.lastname].join(' ').trim();
  return formattedName || "Name not added";
}

export const getFormattedSource = (source?: string, firstPresave?: string): string => {
  if (source && source?.indexOf('brandContentSlug') > -1) {
    return "Signed up through website"
  } else {
    switch (source) {
      case "presave":
        return `Pre-saved "${firstPresave}"`.trim();
      default:
        return "Updated manually";
    }
  }
}
