import { ReactComponent as SearchIcon } from 'assets/images/search-icon-2.svg'
import Colors from 'modules/Colors';
import styled from 'styled-components';

interface SearchInputProps {
  label: string
  showPlaceholder?: boolean
  value?: string
  ref?: React.RefObject<HTMLInputElement>
  onClick?: () => void
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const SearchInput = ({
  label,
  showPlaceholder,
  value,
  ref,
  onClick,
  onChange,
}: SearchInputProps) => {
  return (
    <StyledDiv>
      <SearchIcon onClick={onClick} className="cursor-pointer" />
      <label hidden>{label}</label>
      <StyledInput
        type="search"
        ref={ref}
        placeholder={showPlaceholder ? label : ''}
        value={value}
        onSubmit={onClick}
        onChange={onChange}
      />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid ${Colors.divider};
`;

const StyledInput = styled.input`
  width: 100%;
  outline: none;
  ::placeholder {
    color: ${Colors.greyDark};
  }
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    cursor: pointer;
  }
`;

export default SearchInput;