import Axios from "helpers/Interceptor"
import * as Sentry from "@sentry/browser"
import { CampaignFilterType } from "pages/post-auth/MarketingPage/Components/CampaignTypesFilter/types"
import { CurrentBrand, YouTubeChannel, SpotifyArtistSearchResponse, TikTokProfileForOnboarding, InstagramProfileForOnboarding } from "types/global"
import { showToast } from "modules/Utils"

export type CreateBrandPayload = {
  name: string | null;
  customization: {
    youtube_channel?: YouTubeChannel;
    spotify_artist?: SpotifyArtistSearchResponse;
    tiktok?: TikTokProfileForOnboarding;

    // we don't validate the instagram profile for onboarding to check if its a
    // business or creator account, or personal account. to pull data,
    // it needs to be a business account.
    instagram_profile_for_onboarding?: InstagramProfileForOnboarding;
    business_discovery_instagram?: {
      url: string;
      username: string;
      picture?: string;
    };
    chartmetric?: {
      id: string;
    };
    onboarding_answers?: Record<string, any>;
  }
}

/**
 * Retrieves all brands associated with the current user
 * @async
 * @returns {Promise<CurrentBrand[]>} Array of brands belonging to the user
 */
export const getUserBrands = async (): Promise<CurrentBrand[]> => {
  try {
    Sentry.setTag("flow", "getUserBrands")

    const response = await Axios.get(`/brand`)

    const brandsData = response.data.data
    return brandsData
  } catch (error) {
    Sentry.captureException(error)
    return []
  }
}

/**
 * Updates a specific service connection for a brand
 * @async
 * @param {string | number} brandId - The ID of the brand to update
 * @param {string} brandSlug - The slug/URL-friendly name of the brand
 * @param {any} value - The value/data to update for the service
 * @param {string} service - The service type being updated
 * @returns {Promise<any>} The updated brand data or null if error occurs
 */
export const updateInBrand = async (
  brandId: string | number,
  brandSlug: string,
  value: any,
  service: string
) => {
  try {
    Sentry.setTag("flow", "updateInBrand")
    Sentry.setContext("input", { brandId, value, service })

    const response = await Axios.put(`/brand/${brandSlug}/connect`, {
      id: brandId,
      name: brandSlug,
      service,
      value,
    })
    return response.data;
  } catch (error) {
    Sentry.captureException(error)
    return null;
  }
}

/**
 * Retrieves overall website analytics data for a brand within an optional date range
 * @async
 * @param {string} brandSlug - The slug/URL-friendly name of the brand
 * @param {string} [startDate] - Optional start date for filtering data
 * @param {string} [endDate] - Optional end date for filtering data
 * @returns {Promise<any>} The website analytics data or null if error occurs
 */
export const getWebsiteOverallData = async (brandSlug: string, startDate?: string, endDate?: string) => {
  try {
    let url = `/dashboard/brand/${brandSlug}/website/overall`
    if (startDate && endDate) url += `?startDate=${startDate}&endDate=${endDate}`

    const response = await Axios.get(url)
    return response.data;
  } catch (error) {
    return null;
  }
}

/**
 * Fetches campaigns associated with a specific brand
 * @async
 * @param {CampaignFilterType} params - Filter parameters for the campaigns
 * @param {number} brandId - The ID of the brand to fetch campaigns for
 * @returns {Promise<Array>} Array of campaign data or null if error occurs
 */
export const getCampaignsForBrand = async (params: CampaignFilterType, brandId: number) => {
  try {
    const response = await Axios.get(`/campaign/brand/${brandId}`, { params });
    return response.data.data || [];
  } catch (e) {
    return null
  }
}

/**
 * Disconnects Facebook accounts from a brand
 * @async
 * @param {string} brandSlug - The slug/URL-friendly name of the brand
 * @param {number} brandId - The ID of the brand
 * @param {string} brandName - The name of the brand
 * @returns {Promise<void>}
 */
export const disconnectAccounts = async (brandSlug: string, brandId: number, brandName: string) => {
  await Axios.put(`/brand/${brandSlug}/disconnect`, {
    id: brandId,
    name: brandName,
    service: 'facebook'
  })
}

/**
 * Checks if a Spotify artist already exists for the current user in the system
 * @param spotifyArtistId - The Spotify artist ID to check. Can be null.
 * @returns Promise containing either the found brand data or error details
 * @throws Will capture exception in Sentry if API call fails
 */
export const checkIfSpotifyArtistExistsForUser = async (
  spotifyArtistId: string | null
): Promise<{
  // Response data containing brand info if found
  data: CurrentBrand | null;
  // Error details if request fails
  error: unknown | null;
}> => {
  // Return early if no Spotify ID provided
  if (!spotifyArtistId) {
    return { data: null, error: null }
  }

  try {
    // Make API call to check if artist exists
    const response = await Axios.get(`/brand/spotify/${spotifyArtistId}`)
    return { data: response.data.data, error: null }
  } catch (error) {
    // Log error and return null data with error
    console.error("Error checking if Spotify artist exists:", error)
    Sentry.captureException(error)
    return { data: null, error }
  }
}


/**
 * Interface representing user data structure
 * @interface BrandUser
 * @property {string} email - User's email address
 * @property {string} [name] - User's name (optional)
 * @property {string} [role] - User's role in the system
 */
export interface BrandUser {
  id?: string
  name?: string
  email?: string
  isOwner: boolean
  removable?: boolean
}


/**
 * Interface for user data response
 * @interface PulledUsers
 * @property {Row[]} users - Array of user data
 * @property {number} availableSeats - Number of available seats in the organization
 */
export interface PulledUsers {
  users: BrandUser[];
  availableSeats: number;
}

/**
 * Fetches users associated with a specific brand
 * @async
 * @param {Object} params - Parameters for fetching users
 * @param {number} [params.brandId] - ID of the brand to fetch users for
 * @param {(users: PulledUsers) => void} params.onSaveUsers - Callback to handle fetched users
 * @returns {Promise<void>}
 */
export const getBrandUsers = async ({
  brandId,
  onSaveUsers
}: {
  brandId?: number | null;
  onSaveUsers: (pulledUsers: PulledUsers) => void;
}): Promise<void> => {
  try {
    const response = await Axios.get(`/brand/${brandId}/users`);
    if (!response?.data?.data) {
      throw new Error('Error fetching brand users');
    }
    const { users, availableSeats } = response.data.data;
    onSaveUsers({ users, availableSeats });
  } catch (error) {
    console.error('Error fetching brand users:', error);
    // TODO: Implement proper error handling
  }
};

/**
 * Adds a new user to a brand
 * @async
 * @param {Object} params - Parameters for adding new user
 * @param {number} [params.brandId] - ID of the brand
 * @param {Row[]} params.users - Current users list
 * @param {string} params.newUserEmail - Email of the new user
 * @param {boolean} params.mobileView - Flag for mobile view
 * @param {Function} params.onLoading - Loading state callback
 * @param {Function} params.onSaveUsers - Callback to save updated users
 * @param {Function} params.onChangeNewUserEmail - Callback to reset email input
 * @returns {Promise<void>}
 */
export const addBrandUser = async ({
  brandId,
  users,
  newUserEmail,
  mobileView,
  onLoading,
  onSaveUsers,
  onChangeNewUserEmail,
}: {
  brandId?: number | null;
  users: BrandUser[];
  newUserEmail: string;
  mobileView: boolean;
  onLoading: (loading: boolean) => void;
  onSaveUsers: (pulledUsers: PulledUsers) => void;
  onChangeNewUserEmail: (email: string) => void;
}): Promise<void> => {
  onLoading(true);

  try {
    const currentEmails = users.map(user => user.email);

    if (currentEmails.includes(newUserEmail)) {
      showToast({
        message: `${newUserEmail} already has access to your profile.`,
        mobile: mobileView,
      });
      return;
    }

    await Axios.post(`/brand/${brandId}/users`, { email: newUserEmail });
    await getBrandUsers({ brandId, onSaveUsers });

    onChangeNewUserEmail("");
    showToast({
      message: "New user added!",
      mobile: mobileView,
    });
  } catch (error) {
    console.error("Error adding user:", error);
    showToast({
      message: `There was an error adding ${newUserEmail} to your profile - please try again.`,
      mobile: mobileView,
      error: true,
    });
  } finally {
    onLoading(false);
  }
};
/**
 * Removes a user from a brand
 * @async
 * @param {Object} params - Parameters for deleting user
 * @param {number} [params.brandId] - ID of the brand
 * @param {Row | null} params.potentiallyDeletedUser - User to be deleted
 * @param {boolean} params.mobileView - Flag for mobile view
 * @param {Function} params.onSaveUsers - Callback to save updated users
 * @param {Function} params.onSetDeletedUser - Callback to reset deleted user state
 * @returns {Promise<void>}
 */
export const deleteBrandUser = async ({
  brandId,
  potentiallyDeletedUser,
  mobileView,
  onSaveUsers,
  onSetDeletedUser,
}: {
  brandId?: number | null;
  potentiallyDeletedUser: BrandUser | null;
  mobileView: boolean;
  onSaveUsers: (pulledUsers: PulledUsers) => void;
  onSetDeletedUser: (user: BrandUser | null) => void;
}): Promise<void> => {
  try {
    await Axios.delete(`/brand/${brandId}/users`, {
      data: { email: potentiallyDeletedUser?.email },
    });

    await getBrandUsers({ brandId, onSaveUsers });

    showToast({
      message: `${potentiallyDeletedUser?.email} has been deleted from your profile.`,
      mobile: mobileView,
    });
  } catch (error) {
    console.error("Error deleting user:", error);
    showToast({
      message: `There was an error deleting ${potentiallyDeletedUser?.email} from your profile - please try again.`,
      error: true,
      mobile: mobileView,
    });
  } finally {
    onSetDeletedUser(null);
  }
};

/**
 * Creates a new brand with the provided payload
 * @async
 * @param {CreateBrandPayload} payload - The brand creation payload containing name and customization options
 * @param {string | null} payload.name - Name of the brand
 * @param {Object} payload.customization - Customization options for the brand
 * @param {YouTubeChannel} [payload.customization.youtube_channel] - YouTube channel details
 * @param {SpotifyArtistSearchResponse} [payload.customization.spotify_artist] - Spotify artist details
 * @param {TikTokProfileForOnboarding} [payload.customization.tiktok] - TikTok account details
 * @param {Object} [payload.customization.business_discovery_instagram] - Instagram business account details
 * @param {Object} [payload.customization.chartmetric] - Chartmetric details
 * @param {Record<string, any>} [payload.customization.onboarding_answers] - Onboarding survey answers
 * @returns {Promise<BrandCreationResponse>} The created brand data
 */
export const createNewBrand = async (payload: CreateBrandPayload): Promise<CurrentBrand> => {
  try {
    const response = await Axios.post('/brand', payload, {
      timeout: 60 * 1000
    });

    console.log("response", response.data)
    return response.data.data;
  } catch (error) {
    console.error('Error creating brand:', error);
    throw error;
  }
};