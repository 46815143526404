import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import CloudinaryUploadWidget from "components/shareable/CloudinaryUploadWidget";
import { uploadAdVideo } from "helpers/Facebook";
import InfoSection from "components/InfoSection";
import Select from 'react-select'
import { InstagramPost } from "components/shareable/InstagramPosts/types";
import InstagramPosts from "components/shareable/InstagramPosts";
import { AssetUploadTypes, AssetUploadTypeSelector, containsExplicitContent, ExplicitContentWarning, getPreconnectionConfigurationsStatus, loadInstagramPosts, UploadedAssetType, VideoUploader } from "../utils/fbCampaigns";
import { getRefreshInstagramPost, showNotFoundIGPostWarningToast } from "modules/Utils";
import { CreativeAssetTypes } from "../constants";
import { ConnectionsContext } from "../hooks/ConnectionsContext";
import CampaignConnectionsDetails from "components/connect/CampaignConnectionsDetails";
import AdPreviewSection from "../Components/AdPreviewSection";
import LoadingLottie from "components/Loader/LoadingLottie";
import ConnectionsSetup from "../Components/ConnectionsSetup";

const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface LinkClicksCreativeTab {
    setSelectedAssets: any;
    selectedAssets: UploadedAssetType[] | null;
    setCaption: any;
    caption: any;
    setCTA: any;
    cta: any;
    selectedInstagramPost: any;
    setSelectedInstagramPost: any;
    saveUploadQueue?: (queue: Record<string, unknown>[] | null) => void;
    showContinueButton?: boolean;
    creativeSelectorTab: AssetUploadTypes;
    setCreativeSelectorTab: (type: AssetUploadTypes) => void;
    setAddVisualSelected: Dispatch<SetStateAction<AssetUploadTypes>>;
    previewedVideo: UploadedAssetType | null;
    setPreviewedVideo: (type: UploadedAssetType | null) => void;
    selectedCampaignType: string;
    showPreview: boolean;
    enablePreviewMode: (show: boolean) => void;
    expressSetupOrCustomPages: 'custom' | 'express';
    setExpressSetupOrCustomPages: (value: 'custom' | 'express') => void;
}

const CreativeTab = ({
    expressSetupOrCustomPages,
    setExpressSetupOrCustomPages,
    setSelectedAssets,
    selectedAssets,
    creativeSelectorTab, // This state was configured by default in INSTAGRAM_POSTS and its value is not being changed in this component
    setCreativeSelectorTab, // This function is used to change the state of the tab to select media, but for this type of campaign all those that had to do with UPLOAD_VIDEO were commented
    setAddVisualSelected,
    previewedVideo,
    setPreviewedVideo,
    setCaption,
    caption,
    setCTA,
    cta,
    selectedInstagramPost,
    setSelectedInstagramPost,
    saveUploadQueue,
    showContinueButton = true,
    selectedCampaignType,
    showPreview,
    enablePreviewMode,
}: LinkClicksCreativeTab) => {
    const {
        loggedInFbUser,
        facebookAdAccount: selectedFBAdAccount,
        instagramPage: selectedInstaPage,
        preConnectionConfigurations,
        areTermsAccepted,
        areConnectionsValid: adConnectionsAdded,
        connectionsConfirmed: adConnectionsConfirmed,
        setAreTermsAccepted,
        setConnectionsConfirmed,
    } = useContext(ConnectionsContext)

    const [uploadQueue, setUploadQueue] = useState<any>(null)
    const [LoadingPosts, setLoadingPosts] = useState<boolean>(false)
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([])

    const loadIGPosts = async () => {
        setLoadingPosts(true)
        if (selectedInstaPage) {
            const instagramPosts = await loadInstagramPosts({ instagramPage: selectedInstaPage, access_token: loggedInFbUser?.access_token })

            const hasInstagramPosts = !!instagramPosts.length
            const hasVideosUploaded = !!selectedAssets?.length

            if (hasInstagramPosts) {
                // map through IG posts, make sure they're not explicit
                const mappedIgPosts = instagramPosts.map((post: InstagramPost) => {
                    const caption = post.caption || ''
                    var explicitContent = false
                    if (caption) {
                        explicitContent = containsExplicitContent(caption)
                    }

                    return {
                        ...post,
                        explicit_content: explicitContent
                    }
                })

                setInstagramPosts(mappedIgPosts)

                if (hasVideosUploaded) {
                    // setCreativeSelectorTab(UPLOAD_VIDEO)
                    // setAddVisualSelected(UPLOAD_VIDEO)
                } else {
                    setCreativeSelectorTab(INSTAGRAM_POSTS)
                    setAddVisualSelected(INSTAGRAM_POSTS)
                    // If there is not an selectedInstagramPost, default to selecting first post
                    const refreshSelectedInstaPost = getRefreshInstagramPost(selectedInstagramPost, instagramPosts);
                    setSelectedInstagramPost(refreshSelectedInstaPost);
                }
            } else {
                // setCreativeSelectorTab(UPLOAD_VIDEO)
                // setAddVisualSelected(UPLOAD_VIDEO)
                setInstagramPosts([])
            }

            showNotFoundIGPostWarningToast(instagramPosts, selectedInstagramPost)
        } else {
            // setCreativeSelectorTab(UPLOAD_VIDEO)
            // setAddVisualSelected(UPLOAD_VIDEO)
            setInstagramPosts([])
        }
        setLoadingPosts(false)
    }

    useEffect(() => {
        if ((selectedInstaPage && adConnectionsAdded) || (preConnectionConfigurations?.status && !instagramPosts.length)) {
            loadIGPosts()
        } else {
            // setCreativeSelectorTab(UPLOAD_VIDEO)
            setInstagramPosts([])
            setSelectedInstagramPost(null)
        }
    }, [selectedInstaPage, adConnectionsAdded, loggedInFbUser])

    useEffect(() => {
        if (saveUploadQueue) saveUploadQueue(uploadQueue)

        if (uploadQueue && uploadQueue.length > 0) {
            const { items } = uploadQueue
            var existing = selectedAssets ? selectedAssets : []
            existing = existing.concat(items).slice(0, 2)
            setSelectedAssets(existing)
            setUploadQueue(null)
            if (saveUploadQueue) saveUploadQueue(null)
        }
    }, [uploadQueue])

    const onClickCreativeSelectorTab = (evt: any) => {
        const tabClicked = evt.target.id;
        setCreativeSelectorTab(tabClicked);
        setAddVisualSelected(tabClicked);
    }

    async function uploadCreativeAssets(assets: Array<any>) {
        var uploadedAssets = assets.map((asset: any) => {
            return new Promise(async (resolve: any, reject: any) => {
                const { url } = asset
                try {
                    const uploadedVideo = await uploadAdVideo(url, selectedFBAdAccount?.id, loggedInFbUser?.access_token)
                    resolve({
                        ...asset,
                        fb_id: uploadedVideo.id,
                        type: 'video'
                    })
                } catch (e) {
                    alert("Error uploading video - " + asset.name + `\n${e}`)
                    console.error('e', e)
                    resolve(null)
                }
            })
        })

        var uploadedItems = await Promise.all(uploadedAssets)

        uploadedItems = uploadedItems.filter(o => o !== null)

        return uploadedItems
    }

    useEffect(() => {
        if (preConnectionConfigurations) {
            const currentTermsStatus = getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'tos')
            setAreTermsAccepted(currentTermsStatus)
        }
    }, [selectedFBAdAccount, preConnectionConfigurations])

    const [cloudinaryUploadQueue, setCloudinaryUploadQueue] = useState<Array<string>>([])

    function addToCloudinaryUploadQueue(fileId: string) {
        setCloudinaryUploadQueue((previous) => previous.concat(fileId));
    }

    function removeFromCloudinaryUploadQueue(fileId: string) {
        const newQueue = [
            ...cloudinaryUploadQueue
        ]

        const indexOfId = newQueue.indexOf(fileId)
        if (indexOfId > -1) {
            newQueue.splice(indexOfId, 1)

        }
        setCloudinaryUploadQueue(newQueue);
    }

    const successfulCloudinaryUpload = (file: any) => {

        const {
            info
        } = file

        const {
            id: fileId
        } = info

        removeFromCloudinaryUploadQueue(fileId)
        try {
            addToFacebookUploadQueue(file)
        } catch (e: any) {
            const errors = [...uploadErrors]
            errors.push(e)
            setUploadErrors(errors)
        }
    }

    const [facebookUploadQueue, setFacebookUploadQueue] = useState<Array<any>>(selectedAssets || [])
    const [queueToUpload, setQueueToUpload] = useState<Array<any>>([]);

    const [uploadErrors, setUploadErrors] = useState<Array<{
        name: string,
        message: string
    }>>([])

    const addToFacebookUploadQueue = (file: any) => {

        const {
            info,
        } = file

        const {
            thumbnail_url,
            asset_id,
            height,
            width,
            secure_url,
            original_filename: name,
            format

        } = info

        var sizeRatio = width / height

        // error handling
        //  - must be minimum with of 500px
        if (width < 500) {
            const error = new Error("Video must be wider than 500px.")
            error.name = `${name}.${format}`
            throw error
        }

        let type = 'feed'

        // IG Story Sizing:
        /**
         * 16/9 to 4/5
         * 9/16
         */

        // IG Feed Sizing:
        /**
         * 16/9 to 9/16
         */
        if (sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) {
            type = 'feed'
        } else if ((sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) || sizeRatio < 9 / 16 || sizeRatio >= 996 / 2156) {
            type = 'story'
        } else {
            const error = new Error("Video isn't formatted for feed or story. Please make sure the video is sized in an aspect ratio of 4x5 or 16x9 for feed, or 9x16 for story.")
            error.name = `${name}.${format}`
            throw error
        }

        // upload the asset
        const formattedValue = {
            thumbnail: thumbnail_url,
            cloudinary_id: asset_id,
            height: height,
            width: width,
            name: name,
            url: secure_url,
            formatType: type,
            uploading: true,
            fb_id: null
        }

        setQueueToUpload(previous => previous.concat(formattedValue))
        uploadToFb(formattedValue);
    }

    async function uploadToFb(file: any) {
        const {
            thumbnail,
            cloudinary_id,
            height,
            width,
            name,
            url,
            formatType,
        } = file

        let fbUploaded: any = await uploadCreativeAssets([
            {
                thumbnail,
                cloudinary_id,
                height,
                width,
                name,
                url,
                formatType
            }
        ])

        const fbUploadedValue = fbUploaded[0];
        setFacebookUploadQueue(previous => previous.concat(fbUploadedValue));
        setQueueToUpload((previous: any) => previous.filter((file: any) => file.cloudinary_id !== fbUploadedValue.cloudinary_id))
    }

    useEffect(() => {
        setSelectedAssets(facebookUploadQueue)
    }, [facebookUploadQueue])

    const removeAsset = (cloudinary_id: string) => {
        const updatedWithoutAsset = selectedAssets?.filter((item: UploadedAssetType) => {
            return item.cloudinary_id !== cloudinary_id
        }) || [];
        setFacebookUploadQueue(updatedWithoutAsset)
    }

    const uploadingAssets = uploadQueue && uploadQueue.length > 0
    const showConnectionsSetup = !adConnectionsAdded || !adConnectionsConfirmed || !areTermsAccepted || !selectedInstaPage

    if (showConnectionsSetup) {
        return <ConnectionsSetup 
        selectedCampaignType={selectedCampaignType}
        expressSetupOrCustomPages={expressSetupOrCustomPages}
        setExpressSetupOrCustomPages={setExpressSetupOrCustomPages}
        />
    }

    return (
        <div className="flex gap-6">
            <div className="flex flex-col gap-4 w-full">
                <div className="rounded-lg bg-white">
                    <CampaignConnectionsDetails
                        expressSetupOrCustomPages={'custom'}
                        onEditConnections={() => setConnectionsConfirmed(false)}
                    />
                </div>
                <div className="rounded-lg bg-white">
                    <InfoSection title="Add Creative Visuals" description="Upload the videos you want shown in your ads. We recommend selecting an Instagram Reel or post that's already received some engagement." />

                    <AssetUploadTypeSelector
                        instagramPage={selectedInstaPage}
                        selected={creativeSelectorTab}
                        onChange={onClickCreativeSelectorTab}
                        deniedUploadVideo
                    />
                    {creativeSelectorTab === INSTAGRAM_POSTS ? (
                        LoadingPosts ? (
                            <div className="pt-6 pb-4">
                                <LoadingLottie black />
                            </div>
                        ) : (
                            <div className="border-b pb-6">
                                <InstagramPosts
                                    posts={instagramPosts}
                                    setSelectedInstagramPost={setSelectedInstagramPost}
                                    selectedInstagramPost={selectedInstagramPost}
                                    showCaption={false}
                                    allowPostPreviews={true}
                                />
                            </div>
                        )
                    ) : creativeSelectorTab === UPLOAD_VIDEO && (
                        <>
                            <div className="sy-card px-5 lg:px-10">
                                <VideoUploader
                                    maxAssets={3}
                                    uploadingAssets={uploadingAssets}
                                    selectedAssets={[...(selectedAssets || []), ...queueToUpload]}
                                    removeAsset={removeAsset}
                                    setPreviewedVideo={setPreviewedVideo}
                                    previewedVideo={previewedVideo}
                                />

                            </div>
                            {uploadErrors.length > 0 ? <div className="rounded-sm bg-yellow-500 text-white py-1 text-md py-3 px-3 text-center">
                                <p className="w-8/12 m-auto">There was an error uploading the following assets:</p>
                                {uploadErrors.map((o: {
                                    message: string,
                                    name: string
                                }) => {
                                    return (
                                        <p>{o.name}: {o.message}</p>
                                    )
                                })}
                                <button className="mt-3" onClick={() => {
                                    setUploadErrors([])
                                }}>Close</button>
                            </div> : null}
                        </>
                    )}

                    <InfoSection title="Call-to-Action" description="Enter the caption you want to use for your ad, and what users should see as the swipe-up call-to-action on your ad." />

                    <div className={`px-6 lg:px-10 ${showContinueButton ? "py-2" : "pt-2 pb-8"} border-b`}>
                        {
                            creativeSelectorTab === UPLOAD_VIDEO ?
                                <>
                                    <p className="text-md font-medium mt-2">Caption</p>
                                    <p className="text-sm text-gray-500">Enter the caption you want people to see alongside your ad.</p>

                                    <textarea
                                        value={caption}
                                        onChange={(e) => setCaption(e.target.value)}
                                        placeholder={`e.g. Follow my Instagram for more fire content!`}
                                        className="rounded-md border w-full resize-none p-2 h-20 mt-2"

                                    ></textarea>
                                </>
                                : (selectedInstagramPost && selectedInstagramPost.caption) ?
                                    <>
                                        <p className="text-md font-medium mt-2">Caption</p>
                                        <p className="text-sm text-gray-500">Your Instagram post’s caption will be used for your ad.</p>

                                        <textarea
                                            value={selectedInstagramPost.caption} readOnly
                                            className="rounded-md border w-full resize-none p-2 h-20 mt-2 border-2 bg-gray-100 border-gray-200 resize-none"
                                        />

                                        {selectedInstagramPost.explicit_content && (
                                            <ExplicitContentWarning />
                                        )}
                                    </>
                                    : null
                        }


                        <p className="text-md font-medium mt-8">Call-to-Action</p>
                        <p className="text-sm text-gray-500">Select a call-to-action for your ad - when a user clicks this, they will be sent to your Instagram Profile.</p>

                        <Select
                            value={cta}
                            onChange={(value: any) => setCTA(value)}
                            options={creativeSelectorTab === UPLOAD_VIDEO
                                ? [{ value: 'CONTACT_US', label: 'Contact Us' },
                                { value: 'DOWNLOAD', label: 'Download' },
                                { value: 'LEARN_MORE', label: 'Learn More' },
                                { value: 'SHOP_NOW', label: 'Shop Now' },
                                { value: 'SIGN_UP', label: 'Sign Up' },
                                { value: 'LISTEN_NOW', label: 'Listen Now' }]
                                : [{ value: 'CONTACT_US', label: 'Contact Us' },
                                { value: 'DOWNLOAD', label: 'Download' },
                                { value: 'LEARN_MORE', label: 'Learn More' },
                                { value: 'SHOP_NOW', label: 'Shop Now' },
                                { value: 'SIGN_UP', label: 'Sign Up' },
                                { value: 'LISTEN_NOW', label: 'Listen Now' },
                                { value: 'WATCH_MORE', label: 'Watch More' }]
                            }
                        />
                    </div>
                </div>
                <CloudinaryUploadWidget
                    onUploadAdded={(fileId: string) => addToCloudinaryUploadQueue(fileId)}
                    onUploadSuccess={successfulCloudinaryUpload}
                    onUploadAbortAll={() => setUploadQueue([])}
                    onUploadAbort={(fileId: string) => removeFromCloudinaryUploadQueue(fileId)}
                    uploadButtonId={"all_widget"}
                />
            </div >
            <AdPreviewSection
                showPreview={showPreview}
                creativeSelectorTab={creativeSelectorTab}
                selectedInstagramPost={selectedInstagramPost}
                captions={creativeSelectorTab === INSTAGRAM_POSTS ?
                    (selectedInstagramPost ? [{ type: 'all', caption: selectedInstagramPost.caption }] : [])
                    : caption ? [{ type: 'all', caption: caption }] : []
                }
                previewedVideo={previewedVideo}
                uploadedImages={selectedAssets}
                canShowAdPreview={adConnectionsAdded && adConnectionsConfirmed}
                cta={cta}
                enablePreviewMode={enablePreviewMode}
            />
        </div>
    )
}

export default CreativeTab;