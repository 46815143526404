import { Dispatch, SetStateAction } from 'react'
import { CurrentBrand, SpotifyArtist } from 'types/global'
import HeaderModal from './Components/HeaderModal'
import { ContactSupportButton, TextWarning, WarningContainer } from './styles'
import SmallCard from 'components/shareable/SmallCard'
import { Severity } from 'modules/Const'
import InputSearchSpotify from './Components/InputSearchSpotify'
import { showIntercom } from 'components/Navbar/Navbar'

interface Props {
  inputDisabled?: boolean,
  open: boolean,
  localSave: boolean,
  artistName?: string,
  closeModal: () => void,
  goBack?: () => void,
  onSave: () => void,
  previouslyConnected?: boolean,
  handleShowAlreadyExistsModal: (artist: SpotifyArtist, existingBrand: CurrentBrand) => void,
  setSpotifyArtist?: Dispatch<SetStateAction<SpotifyArtist | null>>,
}

const SpotifyConnect = ({
  inputDisabled,
  open,
  localSave,
  closeModal,
  goBack,
  artistName,
  onSave,
  setSpotifyArtist,
  handleShowAlreadyExistsModal,
  previouslyConnected
}: Props) => {
  const handleShowIntercom = () => showIntercom()

  return (
    <div className="spotifyConnectModalBody">
      <div style={{ paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
        What is the name or URL for {artistName ? artistName + ' ' : ''}Spotify account?
      </div>
      {previouslyConnected &&
        <SmallCard
          className="spotifyConnectModalSmallCard"
          style={{ marginBottom: 24 }}
          severity={Severity.WARNING}
          text="When changing your Spotify, all of the releases from your new Spotify profile will be pulled into Symphony. Your old Spotify releases will be removed from our system. It might take a little bit to update."
        />
      }
      <InputSearchSpotify
        shown={open}
        allowNewSpotifyArtistConnection={previouslyConnected}
        disabled={inputDisabled}
        artistName={artistName || ""}
        closeModal={closeModal}
        onSave={onSave}
        localSave={localSave}
        setSpotifyArtist={setSpotifyArtist}
        handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
      />
      {inputDisabled && (
        <WarningContainer>
          <TextWarning>Change Spotify account can be only updated by calling support</TextWarning>
          <ContactSupportButton
            onClick={handleShowIntercom}
          >Contact support</ContactSupportButton>
        </WarningContainer>
      )}
    </div>
  )
}

export default SpotifyConnect
