import { setUserProperty, track } from "analytics";
import InstagramBusinessDiscoveryConnect from "components/connect/InstagramBusinessDiscovery";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { siteIconsData } from "./Dashboard";
import { GettingStartedContext } from 'Hooks/GettingStartedContext';
import { CurrentBrand, SpotifyArtist, SYMPHONY_TASKS, TikTokProfileForOnboarding } from "types/global";
import SpotifyConnect from 'components/connect/connectModals/Spotify';
import FacebookConnectv2 from "components/connect/FacebookConnectv2";
import YouTubeConnect from "../AddArtist/Components/ConnectPlatformModal/Youtube";
import LinkButton from "components/shareable/LinkButton";
import AudiomackConnectv2 from "components/connect/Audiomack";
import SoundcloudConnectv2 from "components/connect/Soundcloud";
import DeezerConnectv2 from "components/connect/Deezer";
import ModalSymphony from "components/shareable/ModalSymphony";
import TikTokConnect from "components/connect/TikTokConnect";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import Colors from "modules/Colors";
import Sty from "modules/Sty";
import useScreen from "Hooks/useScreen";

interface PlatformsConnectorProps {
    open: boolean;
    closeModal: () => void;
    updateBrandConnections: () => Promise<void>;
    onArtistAlreadyExist: (artist: SpotifyArtist, existingBrand: CurrentBrand) => void;
}

export interface PlatformType {
    id: string;
    name: string;
    needsConnection: boolean;
    imageAsset: string,
    username: string | undefined | null,
    connectorComponent: JSX.Element
}

const PlatformsConnector = ({
    open,
    closeModal,
    updateBrandConnections,
    onArtistAlreadyExist
}: PlatformsConnectorProps) => {
    const { mobileView } = useScreen();
    const { finishedTasks } = useContext(GettingStartedContext);
    const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);
    const [editedPlatform, setEditedPlatform] = useState<PlatformType | undefined>(undefined)

    useEffect(() => {
        // if modal is closed, reset back to default state
        if (!open) {
            setEditedPlatform(undefined)
        }
    }, [open])

    if (!currentBrand) {
        return null;
    }

    const onSave = async (platform: string) => {
        await updateBrandConnections()
        track('Linked Platform',
            {
                brand_id: currentBrand.id,
                brand_slug: currentBrand.slug,
                brand_name: currentBrand.name,
                platform: platform,
                page: "Dashboard",
                from: "connector"
            });

        setUserProperty(`${platform}_linked`, true)
        await reloadBrand()
        setEditedPlatform(undefined)
        if (!finishedTasks.includes(SYMPHONY_TASKS.FANBASE_COMPLETED)) {
            // fetchOnboardingTasks()
        }

        setTimeout(() => {
            window.location.reload()
        }, 2000)
    }

    const getTitle = () => {
        const connectName = editedPlatform?.name ? `Connect ${editedPlatform.name}` : "Connect Your Platforms"
        return connectName
    }

    const PLATFORMS: PlatformType[] = currentBrand.connections ?
        [
            {
                id: 'spotify',
                name: 'Spotify',
                imageAsset: siteIconsData['spotify'],
                needsConnection: !currentBrand || !currentBrand?.connections?.spotify_artist_page?.id,
                username: currentBrand && currentBrand.connections.spotify_artist_page ? currentBrand.connections.spotify_artist_page.name : '',
                connectorComponent: <SpotifyConnect
                    goBack={() => setEditedPlatform(undefined)}
                    closeModal={() => {
                        closeModal();
                        setEditedPlatform(undefined);
                    }}
                    localSave={false}
                    open={true}
                    handleShowAlreadyExistsModal={onArtistAlreadyExist}
                    previouslyConnected={Boolean(currentBrand?.connections?.spotify_artist_page?.id)}
                    onSave={() => onSave("spotify")}
                    artistName={currentBrand && currentBrand.connections.spotify_artist_page ? currentBrand.connections.spotify_artist_page.name : ''}
                />,
            },
            {
                id: 'youtube',
                name: "YouTube",
                imageAsset: siteIconsData['youtube'],
                needsConnection: currentBrand && currentBrand.connections.youtube_channel ? false : true,
                username: currentBrand && currentBrand.connections.youtube_channel ? currentBrand.connections.youtube_channel.snippet.title : '',
                connectorComponent: (
                    <YouTubeConnect
                        open={true}
                        youtubeSearchString={''}
                        youtubeSearchResults={[]}
                        artist={currentBrand}
                        selectedYoutubeChannel={currentBrand?.connections?.youtube_channel}
                        localSave={false}  // assuming you want to save the data remotely
                        setYoutubeSearchString={console.log}
                        selectYoutubeChannel={console.log}
                        closeModal={() => setEditedPlatform(undefined)}
                        onSave={() => onSave('youtube')}
                    />
                )
            },
            {
                id: 'facebook',
                name: "Facebook",
                imageAsset: siteIconsData['facebook'],
                needsConnection: currentBrand && (currentBrand.connections.facebook_page && currentBrand.connections.facebook_page.id) && (!currentBrand.connections.reauthenticateFbUser) ? false : true,
                username: currentBrand && currentBrand.connections.facebook_page ? currentBrand.connections.facebook_page.name : null,
                connectorComponent: <FacebookConnectv2
                    autoconnectInstagram={true}
                    onSave={() => onSave('facebook')}
                    closeModal={() => setEditedPlatform(undefined)}
                    selected={{
                        ...currentBrand.connections.facebook_page
                    }}
                />
            },
            {
                id: 'instagram',
                name: "Instagram",
                imageAsset: siteIconsData['instagram'],
                needsConnection: currentBrand && currentBrand.connections.business_discovery_instagram ? false : true,
                username: currentBrand?.connections?.business_discovery_instagram?.username || '',
                connectorComponent: <InstagramBusinessDiscoveryConnect
                    closeModal={() => setEditedPlatform(undefined)}
                    selected={currentBrand && currentBrand.connections.business_discovery_instagram ? currentBrand.connections.business_discovery_instagram : null}
                    localSave={false}
                    onSave={() => onSave('instagram')}
                />
            },
            {
                id: 'tiktok',
                name: "TikTok",
                imageAsset: siteIconsData['tiktok'],
                needsConnection: currentBrand.connections.tiktok && currentBrand.connections.tiktok.url && currentBrand.connections.tiktok.username ? false : true,
                username: currentBrand.connections.tiktok ? currentBrand.connections.tiktok.username : '',
                connectorComponent: <TikTokConnect
                    className="platformsConnectorTikTokConnectModal"
                    closeModal={() => setEditedPlatform(undefined)}
                    selected={currentBrand.connections.tiktok ? currentBrand.connections.tiktok as TikTokProfileForOnboarding : null}
                    localSave={false}
                    onSave={() => onSave('tiktok')}
                />
            },
            {
                id: 'soundcloud',
                name: "SoundCloud",
                imageAsset: siteIconsData['soundcloud'],
                needsConnection: currentBrand.connections.soundcloud_artist_page ? false : true,
                username: currentBrand.connections.soundcloud_artist_page ?
                    currentBrand.connections.soundcloud_artist_page.name ||
                    currentBrand.connections.soundcloud_artist_page.username : '',
                connectorComponent: <SoundcloudConnectv2
                    closeModal={() => setEditedPlatform(undefined)}
                    selected={currentBrand.connections.soundcloud_artist_page ? currentBrand.connections.soundcloud_artist_page.username : null}
                    onSave={() => onSave('soundcloud')}
                    open
                />
            },
            {
                id: 'audiomack',
                name: "Audiomack",
                imageAsset: siteIconsData['audiomack'],
                needsConnection: currentBrand.connections.audiomack ? false : true,
                username: currentBrand.connections.audiomack ? currentBrand.connections.audiomack.name || currentBrand.connections.audiomack.username : '',
                connectorComponent: <AudiomackConnectv2
                    closeModal={() => setEditedPlatform(undefined)}
                    open
                    selected={currentBrand.connections.audiomack ?
                        (currentBrand.connections.audiomack.name || currentBrand.connections.audiomack.url) : null}
                    onSave={() => onSave('audiomack')}
                />
            },
            {
                id: 'deezer',
                name: "Deezer",
                imageAsset: siteIconsData['deezer'],
                needsConnection: currentBrand.connections.deezer ? false : true,
                username: currentBrand.connections.deezer ? currentBrand.connections.deezer.name : '',
                connectorComponent: <DeezerConnectv2
                    closeModal={() => setEditedPlatform(undefined)}
                    open
                    selected={currentBrand.connections.deezer ? (currentBrand.connections.deezer.name) : null}
                    onSave={() => onSave('deezer')}
                />
            },
            // Twitter has been decommissioned at the moment - as of Oct 30, 2023
            //   this is due to changes in X's API which make pulling twitter followers
            //   super duper expensive
            // {
            //     id: 'twitter',
            //     name: "Twitter",
            //     imageAsset: siteIconsData['twitter'],
            //     needsConnection: !brand || !brand?.connections?.twitter?.url,
            //     username: brand && brand.connections.twitter ? brand.connections.twitter.screen_name : '',
            //     connectorComponent: <TwitterConnect
            //         onCloseModal={() => setEditedPlatform(undefined)}
            //         currentBrand={brand}
            //         onSave={() => onSave('twitter')}
            //         currentUserData={brand && brand.connections.twitter ? {
            //             username: brand.connections.twitter.screen_name,
            //             url: brand.connections.twitter.url
            //         } : undefined}
            //     />
            // },
        ]
        :
        []

    const selectedEditedPlatform = (platformType: PlatformType) => {
        // if its Facebook or IG, we should call FB login within this first
        // if (platformType.id === 'instagram') {
        //     if (!fbLoggedIn()) {
        //         console.log('not logged in, calling login')
        //         await loginWithFB()
        //         console.log('user logged in')
        //     }
        //     setEditedPlatform(platformType)
        // }
        setEditedPlatform(platformType)
    }

    const renderCheckmark = () => {
        return (
            <div className="absolute bottom-0 right-0 w-4 h-4">
                <img
                    src={require("../../../assets/images/tick.svg").default}
                    style={{ maxWidth: 20, height: 'auto' }}
                    alt="tick"
                />
            </div>
        )
    }
    return (
        <ModalSymphony
            className="platformsConnectorModal"
            isOpen={open}
            onClose={closeModal}
            title={getTitle()}
            subtitle="Update the platforms connected to your profile."
            maxWidth={420}
            fullScreen={mobileView}
        >
            {editedPlatform ?
                editedPlatform.connectorComponent
                :
                <div className="platformsConnectorModalContent" style={{ width: '100%', borderTop: Sty.border }}>
                    {PLATFORMS
                        .sort((a: PlatformType, b: PlatformType) => {
                            if (a.needsConnection && !b.needsConnection) {
                                return 1
                            }

                            if (b.needsConnection && !a.needsConnection) {
                                return -1
                            }

                            return 0
                        })
                        .map((platform: PlatformType, index: Number) => {
                            const {
                                id,
                                name,
                                needsConnection,
                                imageAsset,
                                username
                            } = platform

                            return (
                                <div key={id} className="platformsConnectorModalCard" style={sty.platformsConnectorModalCard}>
                                    <div className="artist-img">
                                        <img src={imageAsset} alt={name} />
                                        {!needsConnection && renderCheckmark()}
                                    </div>
                                    <div className="platformName" style={{ marginRight: 'auto' }}>
                                        {name}
                                    </div>
                                    {!needsConnection ?
                                        <>
                                            <div style={sty.usernameText}>
                                                {username}
                                            </div>
                                            <ButtonSymphony onClick={() => selectedEditedPlatform(platform)} variant="text">
                                                Edit
                                            </ButtonSymphony>
                                        </>
                                        :
                                        <ButtonSymphony onClick={() => selectedEditedPlatform(platform)} variant="text">
                                            Connect
                                        </ButtonSymphony>
                                    }
                                </div>
                            )
                        })}
                </div>
            }
        </ModalSymphony>
    )
}

const sty: Record<string, CSSProperties> = {
    platformsConnectorModalCard: {
        display: 'flex',
        alignItems: 'center',
        padding: 16,
        borderBottom: Sty.border,
        width: '100%',
        gap: 12
    },
    usernameText: {
        paddingLeft: 12,
        color: Colors.grey,
        wordBreak: 'break-all'
    }
}

export default PlatformsConnector;
