import Axios from 'helpers/Interceptor';
import { OrganizationBrand, ArtistPermission, MemberRole, TeamPricesData } from '../../types/global'
import * as Sentry from "@sentry/react"
import { SpotifyArtistSearch } from 'pages/post-auth/OrganizationPage/Components/AddNewArtistModal/interfaces';

interface AddNewMember {
  member: {
    name: string;
    lastName: string;
    email: string;
    role: MemberRole;
  },
  brands: OrganizationBrand[]
}

interface UpdateMemberPermissions {
  memberId: number
  addedArtists: {
    brandId: number;
    permission: ArtistPermission;
  }[]
  role?: MemberRole;
  editedArtists: {
    brandId: number;
    permission: ArtistPermission;
  }[]
  removedArtists: number[]
}

interface GetSimulationPrices {
  typeSimulation: 'add-seat' | 'remove-seat'
}

/**
 * Configuration interface for admin spoofing functionality
 * Allows admins to pull organization data by impersonating a specific user
 * @interface SpoofConfig
 * @property {number} [spoofedUserId] - Optional ID of user to impersonate
 */
export interface SpoofConfig {
  spoofedUserId?: number;
}

/**
 * Fetches main info on the organization
 * @async
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Organization members data or null if error
 */
export const getOrganizationData = async (config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get(`/organization`, { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization data');
    return response.data.data;
  } catch (error) {
    return null;
  }
};
/**
 * Fetches all members of the organization
 * @async
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Organization members data or null if error
 */
export const getOrganizationMembers = async (config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get(`/organization/members`, { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization members');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

/**
 * Searches for artists on Spotify to add to organization
 * @async
 * @param {string} keyword - Search term to find artists
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<SpotifyArtistSearch[]>} Array of matching Spotify artists
 */
export const organizationArtistSpotifySearch = async (
  keyword: string,
  config?: SpoofConfig
): Promise<SpotifyArtistSearch[]> => {
  try {
    if (!keyword) return [];
    const params = {
      q: keyword,
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get(
      `/organization/search/artists/spotify`,
      { params }
    );
    return response?.data?.data || [];
  } catch (err) {
    return [];
  }
};

/**
 * Retrieves Stripe team plan pricing information
 * @async
 * @returns {Promise<{priceExtraSeat: number, priceExtraSeatRemaining: number, remainingDays: number}>} Team plan cost details
 * @todo Implement actual endpoint service integration
 */
export const getStripeTeamPlanCosts = async () => {
  //TODO: return data from endpoint service
  return {
    priceExtraSeat: 5,
    priceExtraSeatRemaining: 5,
    remainingDays: 10,
  }
};
/**
 * Removes a member from the organization
 * @async
 * @param {number} memberId - ID of member to remove
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Response data or null if error
 */
export const removeOrganizationMember = async (memberId: number, config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.delete(`/organization/member/${memberId}`, { params });
    if (!response?.data?.data)
      throw new Error('Error removing organization member');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

/**
 * Deletes a brand from the organization
 * @async
 * @param {number} brandId - ID of the brand to delete
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<boolean>} True if deletion was successful, false otherwise
 */
export const deleteOrganizationBrand = async (brandId: number, config?: SpoofConfig): Promise<boolean> => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.delete(`/organization/brands/${brandId}`, { params });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

/**
 * Fetches all brands/artists associated with the organization
 * @async
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<OrganizationBrand[] | null>} Array of organization brands or null if error
 */
export const getOrganizationBrands = async (config?: SpoofConfig): Promise<OrganizationBrand[] | null> => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get(`/organization/brands`, { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization brands');
    return response.data.data;
  } catch (error) {
    return null;
  }
}

/**
 * Retrieves list of existing brands/artists
 * @async
 * @returns {Promise<OrganizationBrand[] | null>} Array of existing brands or null if error
 */
export const getExistingBrands = async (): Promise<OrganizationBrand[] | null> => {
  try {
    const response = await Axios.get(`/organization/artists/existing`);
    if (!response?.data?.data)
      throw new Error('Error fetching organization brands');
    return response.data.data;
  } catch (error) {
    return null;
  }
}

/**
 * Gets price simulation data for adding/removing seats
 * @async
 * @param {GetSimulationPrices} param0 - Simulation type configuration
 * @returns {Promise<TeamPricesData | null>} Price simulation data or null if error
 */
export const getSimulationPrices = async ({ typeSimulation }: GetSimulationPrices, config?: SpoofConfig): Promise<TeamPricesData | null> => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get(`/organization/simulation-prices/${typeSimulation}`, { params });
    if (!response?.data?.data)
      throw new Error('Error fetching extra prices');
    return response.data.data;
  } catch (error) {
    return null;
  }
}

/**
 * Redirects to the subscription status URL
 * @async
 * @returns {Promise<string>} Subscription status URL
 * @throws {Error} If the request fails
 */
export const getSubscriptionStatusUrl = async (config?: SpoofConfig): Promise<string> => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.post(`/organization/billing-settings`,
      {},
      { params }
    );
    if (!response?.data?.data?.url) {
      throw new Error('Error fetching subscription status URL');
    }
    return response.data.data.url;
  } catch (error) {
    throw error;
  }
};

/**
 * Adds a new member to the organization
 * @async
 * @param {AddNewMember} data - New member details and brand assignments
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Response data
 * @throws {Error} If member addition fails
 */
export const addNewMember = async (data: AddNewMember, config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.post(`/organization/new-member`, data, { params });
    if (!response?.data?.data)
      throw new Error('Error adding member');
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Updates permissions for an organization member
 * @async
 * @param {UpdateMemberPermissions} data - Updated permission settings
 * @returns {Promise<any>} Response data
 * @throws {Error} If permission update fails
 */
export const updateMemberPermissions = async (data: UpdateMemberPermissions, config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.put(`/organization/member-permissions`, data, { params });
    if (!response?.data?.data)
      throw new Error('Error updating member permissions');
    return response.data.data;
  } catch (error) {
    throw error
  }
}

/**
 * Fetches organization campaigns with pagination
 * @async
 * @param {number} offset - Pagination offset
 * @param {number} limit - Number of items per page
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Paginated campaign data or null if error
 */
export const getOrganizationCampaigns = async (offset: number, limit: number, config?: SpoofConfig) => {
  try {
    const params = {
      offset,
      limit,
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get('/organization/campaigns', { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization campaigns');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

/**
 * Fetches organization websites with pagination
 * @async
 * @param {number} offset - Pagination offset
 * @param {number} limit - Number of items per page
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Paginated website data or null if error
 */
export const getOrganizationWebsites = async (offset: number, limit: number, config?: SpoofConfig) => {
  try {
    const params = {
      offset,
      limit,
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get('/organization/websites', { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization websites');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

/**
 * Fetches organization fans with pagination and sorting
 * @async
 * @param {number} offset - Pagination offset
 * @param {number} limit - Number of items per page
 * @param {string} orderBy - Field to sort by (added, email, location, source, brand)
 * @param {string} direction - Sort direction (ASC or DESC)
 * @param {boolean} [targeting] - Optional targeting filter
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<OrganizationFansResponse | null>} Paginated fans data or null if error
 */
export const getOrganizationFans = async (
  offset: number,
  limit: number,
  orderBy: string,
  direction: string,
  targeting?: boolean,
  config?: SpoofConfig
) => {
  try {
    const params = {
      offset,
      limit,
      orderBy,
      direction,
      ...(targeting && { targeting }),
      ...(config?.spoofedUserId && { spoofedUserId: config.spoofedUserId })
    };

    const response = await Axios.get('/organization/fans', { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization fans');
    return response.data;
  } catch (error) {
    return null;
  }
};

/**
 * Fetches organization billing data
 * @async
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<OrganizationBillingData | null>} Organization billing data or null if error
 */
export const getOrganizationBillingData = async (config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };

    const response = await Axios.get('/organization/billing', { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization billing data');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

/**
 * Completes organization setup with selected name + brand slug.
 * @param name
 * @param profiles
 */
export const finishOrganizationSetup = async (
  name: string,
  profiles: {
    brandSlug: string;
    currentTier: "pro" | "free";
    upgradeToPro: boolean;
  }[],
  config?: SpoofConfig
) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.post(
      `/organization/finish-setup`,
      {
        name: name,
        profiles: profiles
      },
      { params }
    )

    console.log("response: ", response.data)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error("error: ", error)
  }
}

/**
 * Creates a new organization with a trial plan. Used mainly for admin setup.
 * @param {string} name - The name of the organization to create
 * @param {string} email - The email of the organization owner
 * @param {Array<{brandSlug: string, currentTier: "pro"|"free", upgradeToPro: boolean}>} profiles - Array of profiles to add
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<{data: {organization: Organization}, message: string|null, isError: boolean}>} Response containing organization data
 */
export const adminCreateNewOrganizationWithTrial = async (
  name: string,
  email: string,
  profiles: {
    brandSlug: string;
    currentTier: "pro" | "free";
    upgradeToPro: boolean;
  }[],
  config?: SpoofConfig
): Promise<{
  primaryOwner: {
    id: number;
    name: string;
    email: string;
  } | undefined,
  organization: {
    id: number;
    name: string;
    owner_id: number;
    stripe_customer_id: string;
    stripe_subscription_id: string;
    setup_status: string;
    monthly_spent_subscription_item_id: string;
    monthly_team_seats_item_id: string;
    additional_pro_plans_subscription_item_id: string;
    updatedAt: string;
    createdAt: string;
  } | undefined
} | undefined> => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.post(
      `/organization/admin/create-new-organization-with-trial`,
      {
        name: name,
        ownerEmail: email,
        profiles: profiles
      },
      { params }
    );

    return {
      organization: response.data?.data?.organization,
      primaryOwner: response.data?.data?.primaryOwner
    }
  } catch (error) {
    Sentry.captureException(error);
    console.error("error: ", error);
  }
}

/**
 * Upgrades an organization's brand to Pro tier
 * @async
 * @param {string} brandSlug - The slug identifier for the brand to upgrade
 * @returns {Promise<any>} Response data from the upgrade request or null if error
 */
export const upgradeToProInOrganization = async (brandSlug: string, config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.post(`/organization/upgrade-to-pro`, { brandSlug }, { params });
    if (!response?.data)
      throw new Error('Error upgrading organization to pro');
    return response.data;
  } catch (error) {
    return null;
  }
};

/**
 * Downgrades an organization's brand to Free tier
 * @async
 * @param {string} brandSlug - The slug identifier for the brand to downgrade
 * @returns {Promise<any>} Response data from the downgrade request or null if error
 */
export const downgradeToFreeInOrganization = async (brandSlug: string, config?: SpoofConfig) => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.post(`/organization/downgrade-to-free`, { brandSlug }, { params });
    if (!response?.data)
      throw new Error('Error downgrading organization to free');
    return response.data;
  } catch (error) {
    return null;
  }
};

/**
 * Fetches organization campaign usage data
 * @async
 * @param {number} budget - The budget amount to check usage against
 * @param {string} currency - The currency code for the budget
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<OrganizationCampaignUsage | null>} Campaign usage data or null if error
 */
export const getOrganizationCampaignUsage = async (
  budget: number,
  currency: string,
  config?: SpoofConfig
) => {
  try {
    const params = {
      budget,
      currency,
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get('/organization/campaign-usage', { params });
    if (!response?.data?.data)
      throw new Error('Error fetching organization campaign usage');
    return response.data.data;
  } catch (error) {
    return null;
  }
};
/**
 * Adds an existing brand to the user's organization
 * @async
 * @param {string} brandSlug - Unique identifier slug for the brand to add
 * @returns {Promise<any>} Response data if successful, null if failed
 */
export const addBrandToOrganization = async (brandSlug: string, upgradeToPro: boolean = false, config?: SpoofConfig) => {
  const params = {
    spoofedUserId: config?.spoofedUserId || undefined
  };
  const payload = {
    brandSlug,
    upgradeToPro,
  };

  try {
    const response = await Axios.post(`/organization/brand`, payload, { params });
    return response?.data?.data || null;
  } catch (err) {
    return null;
  }
};

/**
 * Adds multiple existing brands to the user's organization
 * @async
 * @param {string[]} brandSlugs - Array of unique identifier slugs for the brands to add
 * @param {boolean} upgradeToPro - Whether to upgrade the brands to Pro tier
 * @param {SpoofConfig} [config] - Optional spoofing configuration for admin access
 * @returns {Promise<any>} Response data if successful, null if failed
 */
export const addMultipleBrandsToOrganization = async (brandSlugs: string[], upgradeToPro: boolean = false, config?: SpoofConfig) => {
  const params = {
    spoofedUserId: config?.spoofedUserId || undefined
  };
  const payload = {
    brandSlugs,
    upgradeToPro,
  };

  try {
    const response = await Axios.post(`/organization/brands`, payload, { params });
    return response?.data?.data || null;
  } catch (err) {
    return null;
  }
};

/**
 * Lists existing artists in the organization
 * @async
 * @returns {Promise<OrganizationBrand[]>} Array of organization brands or empty array if error
 */
export const listExistingArtists = async (config?: SpoofConfig): Promise<OrganizationBrand[]> => {
  try {
    const params = {
      spoofedUserId: config?.spoofedUserId || undefined
    };
    const response = await Axios.get(`/organization/artists/existing`, { params });
    return response?.data?.data || [];
  } catch (err) {
    return [];
  }
};