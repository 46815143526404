import {
  useEffect,
  useState,
  ChangeEvent,
  useContext,
} from "react"
import {
  ArtistImage as ChannelImage,
  CloseButtonContainer,
  CloseIconContainer,
  ModalContainer,
  ModalMainTitleLabel,
  ModalPlatformInputContainer,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  PlatformLogoContainer,
  useStyles,
  SearchListContainer,
} from "../../../pages/post-auth/AddArtist/style"
import CloseIcon from "components/shareable/CloseIcon"
import { TextField, Dialog, Grid, InputAdornment } from "@material-ui/core"
import { DeezerArtistProfile } from "types/global"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import Axios from "helpers/Interceptor"
import isEqual from "lodash/isEqual"
import { track } from "analytics"
import { StyledLabel, TextButton } from "styles/shared"
import { ReactComponent as DeezerIcon } from "assets/images/platforms/deezer.svg";
import { clsx } from "modules/Utils"
import { StyledButton } from "pages/post-auth/MarketingPage/Components/AddLocationModal/styles"
import useScreen from "Hooks/useScreen"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

interface Props {
  closeModal: () => void;
  selected: string | number | undefined | null;
  open: boolean;
  onSave?: () => void;
}

export const queryDeezerArtists = async (query: string) => {
  try {
    const results = await Axios.get(`/deezer/search/artists`, {
      params: {
        q: query
      }
    })
    return results.data
  } catch (error: any) {
    throw error
  }
}

export const queryDeezerArtistsById = async (artistId: string) => {
  try {
    const results = await Axios.get(`/deezer/artists/${artistId}`)
    return results.data
  } catch (error: any) {
    throw error
  }
}

const DeezerConnectv2 = ({
  closeModal,
  selected,
  open,
  onSave
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext);
  const { smallView, mobileView } = useScreen();
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = useState("")

  const [results, setResults] = useState<DeezerArtistProfile[]>([])

  const [error, setError] = useState(false)
  const showConnectButton = !isEqual(results, [selected])


  // used to determine if the user submitted a query already
  const [userSubmittedSearch, setUserSubmittedSearch] = useState(false)

  const getModalContainerHeight = (profiles: number) => {
    if (profiles > 2) return "300px"
    return "100%"
  }

  const containerHeight = smallView ? '100%' : getModalContainerHeight(results.length)

  const pullResults = async (value: string) => {
    setUserSubmittedSearch(true);
    setLoading(true)

    if (!value.trim().length) {
      setResults([]);
      return;
    }

    let data = [];
    let error = null;

    try {
      // Extract the artist username from the Soundcloud URL
      const pattern = /^https?:\/\/(?:www\.)?deezer\.com\/(?:\w+\/)?artist\/\d+/
      const deezerId = value.match(/\/artist\/([^/]+)/)
      if (pattern.test(value) && deezerId) {
        const results = await queryDeezerArtistsById(deezerId[1]);
        data = results?.data || [];
      } else {
        const results = await queryDeezerArtists(value);
        data = results?.data || [];
      }
    } catch (e) {
      error = e;
    }


    if (error) {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      setResults(data.map((artist: DeezerArtistProfile) => ({
        ...artist,
        id: artist.id
      })));
      setLoading(false);

    }
    // setUserSubmittedSearch(false)

  }

  useEffect(() => {
    if (results.length && !textFieldValue) {
      setResults([])
    }
  }, [textFieldValue])

  const updateInBrand = (channel: DeezerArtistProfile) => {
    setLoading(true)
    if (currentBrand) {
      track("Linked Deezer Profile")
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "deezer",
        value: channel,
      })
        .then((_result) => {
          onSave && onSave()
          closeModal()
          setError(false)
          setLoading(false)
        })
        .catch((err) => {
          console.log("error creating brand", err)
          setError(true)
        })
    }
  }

  const handleOnClick = (profile: DeezerArtistProfile) => () => {
    updateInBrand(profile)
  }

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setTextFieldValue(value)
  }

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])


  const renderEndAdornment = () => {
    if (loading) {
      // Assuming LottieAnimation is a component or element that renders your Lottie animation
      return <InputAdornment position="end">
        <Lottie
          height={24}
          width={24}
          options={{
            loop: true,
            autoplay: true,
            animationData: SymphonyLoadingLottie,
          }}
        />
      </InputAdornment>;
    }

    return (
      <TextButton
        onClick={() => pullResults(textFieldValue)}
      >
        Search
      </TextButton>
    )
  }

  let mainSubtitle = `Search for ${`${currentBrand?.name}'s` || "your primary"} Deezer profile.`
  let mainPlaceholder = "Search"

  const getHelperText = (args: {
    value: string
    error: boolean
    results: DeezerArtistProfile[]
    loading: boolean
  }) => {
    const { value, error, results, loading } = args

    if (error) return "An error occurred. Try again later."
    if (!results.length && value && !loading && userSubmittedSearch) {
      return `No artists found for "${textFieldValue}".`
    }
    return undefined
  }

  const helperText = getHelperText({
    value: textFieldValue,
    error,
    results,
    loading,
  })

  return (
    <div
      className="deezerConnectv2Component"
      style={{ width: '100%' }}
    >
      <ModalPlatformInputContainer>
        <PlatformLogoContainer
          width="48px"
          height="48px"
        >
          <DeezerIcon width="48px" height="48px" />
        </PlatformLogoContainer>
        <form style={{
          width: '100%'
        }}
          onSubmit={(e) => {
            e.preventDefault()
            pullResults(textFieldValue)
          }}>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: renderEndAdornment(),
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder={mainPlaceholder}
            helperText={helperText}
            error={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />
        </form>
      </ModalPlatformInputContainer>
      {userSubmittedSearch && textFieldValue && !loading &&
        <SearchListContainer height={containerHeight}>
          <Grid container direction="column">
            {!loading && results.map((profile: DeezerArtistProfile, index) => {
              const {
                id,
                name,
                thumbnail_url,
              } = profile
              return (
                <Grid
                  item
                  key={id}
                  className={clsx(
                    classes.channelItemRow,
                    index === 0 && classes.dividerBottom,
                    index !== 0 && index !== 1 && classes.dividerTop
                  )}
                >
                  <Grid
                    className="cursor-pointer"
                    onClick={handleOnClick(profile)}
                    spacing={showConnectButton && smallView ? 2 : 3}
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item className={classes.channelImage}>
                      <ChannelImage
                        widthStyle={mobileView ? "48px" : "56px"}
                        heightStyle={mobileView ? "48px" : "56px"}
                        alt={`${name}-url-image`}
                        src={thumbnail_url}
                      />
                    </Grid>
                    <Grid
                      item
                      className={clsx(showConnectButton && smallView && "w-24")}
                      xs={showConnectButton && !smallView || !showConnectButton}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <StyledLabel fontWeight={500} fontSize={16}>
                            {name}
                          </StyledLabel>
                        </Grid>

                      </Grid>
                    </Grid>
                    {showConnectButton && (
                      <Grid item className={classes.connectButtonContainer}>
                        <Grid container direction="row-reverse">
                          <StyledButton>Connect</StyledButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </SearchListContainer>
      }
    </div>
  )
}

export default DeezerConnectv2
