import { useContext } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AdPreviewBox, { SelectedCaption } from 'components/shareable/FacebookAdPreview';
import useAdPreviewStyles from 'components/shareable/FacebookAdPreview/styles';
import { UploadedAssetType } from '../utils/fbCampaigns';
import { ConnectionsContext } from '../hooks/ConnectionsContext';
import useScreen from 'Hooks/useScreen';
import { CreativeAssetTypes } from '../constants';

const { INSTAGRAM_POSTS } = CreativeAssetTypes

interface AdPreviewProps {
    showPreview: boolean;
    creativeSelectorTab: string;
    selectedInstagramPost: any;
    captions: SelectedCaption[];
    previewedVideo: any;
    uploadedImages: UploadedAssetType[] | null;
    canShowAdPreview: boolean;
    cta?: { label: string };
    enablePreviewMode: (show: boolean) => void;
}

const AdPreviewSection = ({
    showPreview,
    creativeSelectorTab,
    selectedInstagramPost,
    captions,
    previewedVideo,
    uploadedImages,
    canShowAdPreview,
    cta,
    enablePreviewMode,
}: AdPreviewProps) => {
    const adPreviewStyles = useAdPreviewStyles();
    const { mobileView } = useScreen();

    const {
        facebookPage: selectedFB_page,
        instagramPage: selectedInstaPage,
    } = useContext(ConnectionsContext);

    const previewModule = (shown: boolean, onClose?: () => void) => (
        <AdPreviewBox
            callToAction={cta?.label || 'Listen Now'}
            closePreview={onClose}
            shown={shown}
            facebookPage={selectedFB_page ? selectedFB_page : null}
            instagramAccount={selectedInstaPage}
            captions={captions}
            previewedPost={previewedVideo && creativeSelectorTab !== INSTAGRAM_POSTS ? {
                postType: 'uploaded',
                caption: "test",
                media_url: previewedVideo.url,
                media_type: "VIDEO",
                thumbnail_url: previewedVideo.thumbnail,
                type: previewedVideo.formatType
            } : null}
            selectedPosts={selectedInstagramPost && creativeSelectorTab === INSTAGRAM_POSTS ?
                [{
                    postType: 'instagram',
                    caption: selectedInstagramPost.caption,
                    media_url: selectedInstagramPost.media_url,
                    media_type: selectedInstagramPost.media_type,
                    thumbnail_url: selectedInstagramPost.thumbnail_url,
                    type: selectedInstagramPost.media_product_type === 'REELS' ? 'story' : 'feed'
                }] :
                uploadedImages && uploadedImages.length > 0 ? uploadedImages.map((uploadedVideo: UploadedAssetType) => {
                    return {
                        postType: 'uploaded',
                        caption: "test",
                        media_url: uploadedVideo.url,
                        media_type: "VIDEO",
                        thumbnail_url: uploadedVideo.thumbnail,
                        type: uploadedVideo.formatType
                    }
                }) : null}
        />
    );

    if (mobileView) {
        return (
            <Modal
                aria-labelledby="connector-modal"
                aria-describedby="connector-modal for platforms"
                className={adPreviewStyles.previewAdModal}
                open={showPreview}
                onClose={() => enablePreviewMode(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 400,
                }}
            >
                <Fade in={showPreview}>
                    {previewModule(true, () => enablePreviewMode(false))}
                </Fade>
            </Modal>
        );
    } else {
        return previewModule(canShowAdPreview);
    }
};

export default AdPreviewSection