import { useState } from 'react';
import { Dialog } from '@material-ui/core';
import {
  ModalContainer,
  useStyles,
  ButtonsContainer,
  DraftContainer,
  ImageContainer,
  Image
} from './style';
import { ReactComponent as TrashIcon } from 'assets/images/trash-purple.svg';
import { toast, ToastOptions } from 'react-toastify';
import Axios from "helpers/Interceptor";
import HeaderModal from '../HeaderModal';
import { track } from 'analytics';
import moment from 'moment';
import CampaignDescription from '../CampaignDescription';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';

interface Props {
  open: boolean;
  draftId: number;
  closeModal: () => void;
  toastOptions?: ToastOptions;
  onDelete: (campaignId: number) => void;
}

const TOAST_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  closeButton: true,
  style: {
    background: "#07C806",
    color: "#FFFFFF",
    margin: "16px 16px auto 16px",
    fontSize: "16px",
    borderRadius: "8px",
  },
}

interface Props {
  open: boolean;
  toastOptions?: ToastOptions;
  draftId: number;
  campaignType: string;
  imageUrl: string,
  campaignName: string,
  createdAt: string,
  closeModal: () => void;
  onDelete: (campaignId: number) => void;
}

const DeleteCampaignModal = ({
  open,
  toastOptions,
  draftId,
  campaignType,
  imageUrl,
  campaignName,
  createdAt,
  closeModal,
  onDelete,
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useScreen();
  const [loading, setLoading] = useState<boolean>(false)

  const handleDelete = async () => {
    setLoading(true)
    const options = toastOptions || TOAST_OPTIONS
    const marginToast = mobileView ? "24px 16px auto 16px" : "41px 22px auto"
    try {
      await Axios.delete(`/campaign/${draftId}`)

      track("Deleted Draft", {
        type: campaignType,
        deletedOn: moment().utc().format(),
        createdOn: createdAt
      })

      onDelete(draftId)

      toast.success('Draft deleted!', {
        ...options,
        style: {
          ...options.style,
          margin: marginToast
        }
      })
    } catch (e) {
      toast.error('Unexpected error!', {
        ...options,
        style: {
          ...options.style,
          margin: marginToast,
          background: "#FF3D3D",
        }
      })
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={mobileView ? "auto" : "678px"}
        padding={mobileView ? "32px 24px" : "32px"}
      >
        <HeaderModal
          HeaderIcon={TrashIcon}
          closeModal={closeModal}
          title='Delete Draft'
          subtitle='Are you sure you want to delete this draft?'
        />
        <DraftContainer>
          <ImageContainer>
            <Image
              alt="campaign-image"
              src={imageUrl}
            />
          </ImageContainer>
          <CampaignDescription
            campaignName={campaignName}
            campaignType={campaignType}
            date={createdAt}
          />
        </DraftContainer>
        <ButtonsContainer flexDirection={mobileView ? 'column-reverse' : 'row'}>
          <ButtonSymphony
            className="buttonDeleteCampaignModal1"
            width={mobileView ? '100%' : 'fit-content'}
            disabled={loading}
            onClick={closeModal}
            variant="outlined"
          >
            No, don't delete
          </ButtonSymphony>
          <ButtonSymphony
            className="buttonDeleteCampaignModal2"
            onClick={handleDelete}
            variant="outlined"
            width={mobileView ? '100%' : '50%'}
            disabled={loading}
            color="red"
          >
            Yes, delete this draft
          </ButtonSymphony>
        </ButtonsContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default DeleteCampaignModal;