/**
 * Autocomplete Component
 */
import { debounce, capitalize } from "lodash"
import useStyles from "../styles";
import { ChangeEvent, useState, useEffect, useRef } from "react"
import LoadingIndicator from "components/Loader/LoadingIndicator"
import { FBLocation } from "../reducer";
import { clsx } from 'modules/Utils'

function useOutsideAlerter(ref: any, setShow: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setShow]);
}

type Props = {
    loading: boolean
    locations: FBLocation[]
    searchText: string
    selectedLocations: any
    onSelectLocation: (location: FBLocation) => void
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Autocomplete = ({ onChange, locations = [], loading, onSelectLocation, selectedLocations, searchText }: Props) => {

    const classes = useStyles()
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShow);

    const handleOnSelectLocation = (index: number) => () => onSelectLocation(locations[index])
    const handleOnSetFocus = () => setShow(true)

    const suggestions = locations.map(({ name, type, key, region, country_name }, index) => (
        <div key={index} className="flex flex-row justify-between p-2 cursor-pointer" onClick={handleOnSelectLocation(index)}>
            <div>
                <div className="text-sm">{type === 'country' ? `${name}` : `${name}, ${region ? `${region},` : ''} ${country_name}`}</div>
                <div className="text-xs mt-0.5">{capitalize(type)}</div>
            </div>
            <div className="w-4 h-4" style={{ border: '2px solid #07C806', backgroundColor: !!selectedLocations[key] ? "#07C806" : 'white' }} />
        </div>
    ))

    const loadingNode = (
        <div className="p-2 flex flex-row justify-center">
            <LoadingIndicator color="black" height="30px" />
        </div>
    )

    const resultsNode = loading ? loadingNode : suggestions
    const emptyNode = <div style={{ fontSize: 10 }} className="w-full bg-white absolute grid grid-cols-1 divide-y mt-1 overflow-scroll overflow-x-hidden border border-backgound-100 text-center p-4">{`No locations found for “${searchText}”.`}</div>
    return (
        <div className="relative w-fit" ref={wrapperRef}>
            <input
                type="text"
                autoComplete="off"
                onFocus={handleOnSetFocus}
                onChange={debounce(onChange, 800)}
                className={clsx(classes.inputPlaceHolder, "w-11/12 mx-2 focus:outline-none outline-0 my-3")} placeholder="Type to search for cities, states, and countries..."
            />
            {
                ((!!locations.length && show) || loading) &&
                <div
                    style={{ maxHeight: 150 }}
                    className="w-full bg-white absolute grid grid-cols-1 divide-y mt-1 overflow-scroll overflow-x-hidden border border-backgound-100">
                    {resultsNode}
                </div>
            }
            {!loading && show && !locations.length && searchText && emptyNode}
        </div>
    )
}

export default Autocomplete