import { CSSProperties, useEffect, useState } from "react"
import { CampaignOption } from "pages/post-auth/MarketingPage/GrowInstagram/utils"
import { OverviewSongImage } from "components/shareable/OverviewSongImage"
import DefaultImg from 'assets/images/music-key-green-bg.svg';
import SmokingLaptopIcon from 'assets/images/smoking-laptop.png';
import IGBoostViews from 'assets/images/ui/ig-boost-campaign-img.png';
import IGBoostFollowers from 'assets/images/ui/ig-boost-profile-visits-img.png';
import { checkImageUrlValidity } from "helpers/General";
import Colors from "modules/Colors";

interface CampaignImageProps {
    className?: string;
    style?: CSSProperties;
    campaign_metadata: any;
    thumbnail?: string | null | undefined;
    size?: number;
    isBorder?: boolean;
}

const CampaignImage = ({
    className: classNameProp = '',
    style = {},
    campaign_metadata,
    thumbnail,
    size,
    isBorder
}: CampaignImageProps) => {
    const [loadedCampaignImage, setLoadedCampaignImage] = useState<string>(SmokingLaptopIcon);

    const {
        content,
        campaign_type,
        campaign_state,
        creative
    } = campaign_metadata;

    const {
        thumbnail_url,
        type: contentType
    } = content;

    let imageSrc = '';

    switch (campaign_type) {
        case 'record_streams':
        case 'grow_playlist_followers':
            imageSrc = thumbnail_url || DefaultImg
            break;
        case 'link_clicks':
            imageSrc = SmokingLaptopIcon;
            break;
        case 'boost_instagram':
            let campaignImg = IGBoostViews;
            let campaignSubtype: CampaignOption | undefined = undefined;

            if (campaign_type === 'boost_instagram') {
                campaignSubtype = campaign_state?.campaign?.targeting?.campaignType || undefined;
            }

            if (campaignSubtype) {
                switch (campaignSubtype?.id) {
                    case 'boost_views':
                        campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || creative?.owner?.profile_pic || IGBoostViews;
                        break;
                    case 'boost_follows':
                        campaignImg = creative?.owner?.profile_pic || creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || IGBoostFollowers;
                        break;
                }
            } else {
                campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || creative?.owner?.profile_pic || IGBoostViews;
            }

            imageSrc = campaignImg;
            break;
        case 'increase_video_views':
            imageSrc = thumbnail || DefaultImg;
            break;
    }

    const noImageStyle = imageSrc === '' ? { backgroundColor: Colors.purple } : {};

    const getImageForCampaign = async (campaignImg: string) => {
        try {
            if (!campaignImg) {
                return SmokingLaptopIcon;
            }
            await checkImageUrlValidity(campaignImg);
            return campaignImg;
        } catch (error) {
            console.error('Failed to load campaign image:', error);
            return SmokingLaptopIcon;
        }
    }

    useEffect(() => {
        const loadImage = async () => {
            const newImage = await getImageForCampaign(imageSrc);
            setLoadedCampaignImage(newImage);
        };

        loadImage();
    }, []);

    if (!campaign_metadata) {
        return null;
    }

    return (
        <OverviewSongImage
            className={`campaignImageComponent ${classNameProp}`}
            style={{
                ...noImageStyle,
                ...style
            }}
            size={size}
            src={loadedCampaignImage}
            isBorder={isBorder}
        />
    )
};

export default CampaignImage;