import { useState } from 'react';
import { LinkContainer, MainContainer, TextLinkInput, ButtonsContainer } from './styles';
import SecondaryButton from 'components/shareable/SecondaryButton';
import useScreen from 'Hooks/useScreen';
import { track } from 'analytics';
import ButtonSymphony from '../ButtonSymphony';

interface Props {
  link: string;
  padding?: string;
  variant?: 'default' | 'small'
}

const LinkCopyShareComponent = ({ link, padding, variant }: Props) => {
  const { mobileView } = useScreen();

  const [copyButtonLabel, setCopyButtonLabel] = useState<string>('Copy');
  const showShareButton = Boolean(navigator.share);

  const onClickCopy = () => {
    track('Copied Link', {
      link: link
    })
    navigator.clipboard.writeText(link)
      .then(() => setCopyButtonLabel('Copied!'))
      .catch(() => setCopyButtonLabel('Failed! Try again'));
    setTimeout(() => setCopyButtonLabel('Copy'), 3000);
  };

  const onClickShare = () => {
    if (showShareButton) {
      navigator.share({
        title: "Check this out!",
        text: "Here's something interesting.",
        url: link,
      }).catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    <MainContainer
      style={{
        width: '100%',
        padding: variant === 'small' ? 0 : padding || '16px',
        background: 'none'
      }}>
      <LinkContainer>
        <TextLinkInput value={link} readOnly
        />
        {!mobileView &&
          <ButtonsContainer>
            {mobileView && showShareButton &&
              <ButtonSymphony
                className='textButtonCopyableLink'
                variant="text"
                onClick={onClickShare}
              >
                Share
              </ButtonSymphony>
            }
            <ButtonSymphony
              className='buttonCopyableLink1'
              onClick={onClickCopy}
              size={variant === 'small' ? 'small' : 'medium'}
              variant="outlined"
            >
              {copyButtonLabel}
            </ButtonSymphony>
          </ButtonsContainer>
        }
      </LinkContainer>
      {mobileView ? (<SecondaryButton className='buttonCopyableLink2' width="100%" text={copyButtonLabel} onClick={onClickCopy} />
      ) : null}
    </MainContainer>
  );
};

export default LinkCopyShareComponent;
