import { ButtonContainer } from './styles';
import LoadingLottie from 'components/Loader/LoadingLottie';
import { ShowTooltip } from '../CustomMenu';
import { ButtonText } from '../Typography';
import Colors from "modules/Colors";

interface Props {
  className?: string,
  text: string;
  loadingText?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  width?: string;
  height?: string;
  tooltip?: string;
  textColor?: string;
}

const TertiaryButton = ({
  className = '',
  disabled,
  loading,
  loadingText = "",
  onClick,
  text,
  width = "fit-content",
  height = "fit-content",
  tooltip,
  textColor = Colors.purple,
}: Props) => {
  const color = disabled ? Colors.grey : textColor
  return (
    <ButtonContainer
      className={`tertiaryButtonComponent ${className}`}
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
    >
      {loading ? (
        <>
          <LoadingLottie height={18} width={18} black />
          <ButtonText color={color}>{loadingText}</ButtonText>
        </>
      ) : (
        <ShowTooltip tooltip={tooltip}>
          <ButtonText color={color}>{text}</ButtonText>
        </ShowTooltip>
      )}
    </ButtonContainer >
  );
};

export default TertiaryButton;