import useStyles, {
  ItemContainer,
  ItemDataContainer,
  ChipContainer,
  StyledListItem,
  NotAvailableChip,
} from './styles'
import Radio from '@material-ui/core/Radio'
import { Body2, Caption, Headline3 } from 'components/shareable/Typography'
import { StyledChip } from '../../../../ConversionsSteps/FacebookAdAccount/SelectAdAccountModal/AccountsList/AdAccountOption/styles'
import { useEffect, useState } from 'react'
import { FBPixel } from 'types/global'
import { getFacebookPermissions } from 'services/symphonyApi'
import { AccountDisabledBanner } from '../../../AccountDisabledBanner/AccountDisabledBanner'
import { PIXEL_SETTINS_URL } from 'helpers/FB'
import Colors from 'modules/Colors'
import BusinessChip from '../../../BusinessChip'

interface PixelOptionProps {
  pixel: FBPixel
  selectedPixel: FBPixel | null
  onSelectPixel: (pixel: FBPixel | null) => void
  business?: {
    id: string;
    name: string;
  } | undefined
  linkedPixels: FBPixel[]
}

export const PixelOption = ({
  pixel, selectedPixel, onSelectPixel, business, linkedPixels
}: PixelOptionProps) => {
  const [hasPermissions, setHasPermissions] = useState(true);

  const classes = useStyles()

  const { name, id, is_unavailable: isUnavailable } = pixel
  const { id: selectedId } = selectedPixel || {}
  const checked = selectedId === id

  const isDisabled: boolean = isUnavailable || !hasPermissions

  if (isDisabled && checked) onSelectPixel(null)

  const handleSelectPixel = () => {
    if (!isDisabled) onSelectPixel(pixel)
  }

  useEffect(() => {
    // TODO: Temporarily remoevd validation of pixel permissiosn - should re-add
    // if (business) {
    //   getFacebookPermissions({ pixelId: id, businessId: business.id }).then((res) => {
    //     setHasPermissions(Boolean(res.data?.pixel))
    //   })
    // }
  }, []);

  return (
    <StyledListItem onClick={handleSelectPixel} key={id}>
      <ItemContainer>
      <Radio
        {...{ checked }}
        classes={{
        root: classes.root,
        checked: classes.checked,
        }}
        onChange={handleSelectPixel}
        disabled={isDisabled}
        name="connect-facebook-pages"
        value={id}
      />
      <ItemDataContainer>
        <Headline3
          color={ isDisabled ? Colors.grey : Colors.textColor}
        >{name}</Headline3>
        <BusinessChip
          business={business}
          assetId={id}
          isDisabled={isDisabled}
        />
        {linkedPixels.find((linkedPixel) => linkedPixel.id === id) && (
        <StyledChip isLinkedVariant>
          <Caption color={isDisabled ? Colors.grey : Colors.textColor}>
            Connected to Ad Account
          </Caption>
        </StyledChip>
        )}
        {!hasPermissions && (
          <AccountDisabledBanner
            bannerMessage="It looks like you don't have access to this pixel as a user. Please add yourself to the pixel before connecting it here."
            linkText="Review Pixel User Permissions"
            reviewAccountLink={`${PIXEL_SETTINS_URL}?business_id=${business?.id}`}
          />
        )}
      </ItemDataContainer>
      {isUnavailable && (
        <ChipContainer>
        <NotAvailableChip>
          <Body2>Not available</Body2>
        </NotAvailableChip>
        </ChipContainer>
      )}
      </ItemContainer>
    </StyledListItem>
  )
}