import { ReactElement, useContext } from "react"
import ConversionsConfigStep from "../../../ConversionsConfigStep"
import StyledTextField from "../../Components/StyledTextField"
import { commonCardsProps, Steps } from "../../utils"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { Body1, Body2, ButtonText, Subtitle1, Subtitle2 } from "components/shareable/Typography"
import { FlexContainer, InstructionsCard, LinkContainer, NumberedItem, useStyles } from "../FacebookPixel/styles"
import LinkButton from "components/shareable/LinkButton"
import Colors from "modules/Colors"
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg"
import useScreen from "Hooks/useScreen"
import { Container } from "styles/shared"
import { StyledAnchor } from "./SelectAdAccountModal/styles"
import SecondaryButton from "components/shareable/SecondaryButton"

interface Props {
  facebookAdAccount: {
    error: boolean
    loading: boolean
    stepValue: string | null
  }
  onClickConnectButton: () => void
  onHover: () => void;
  onClickWatchVideoButton: () => void
  onVerifyConnection: () => void
  onSkipError: () => void
  conversionsEnabled: boolean
  conversionsError: boolean
}

const instructionsLabels: {
  label: ReactElement
  buttonLabel?: string
}[] = [
  {
    label: <Body2 color={Colors.textColor}>Click this link to open your&nbsp;</Body2>,
    buttonLabel: "Meta Business Manager Settings",
  },
  {
    label: (
      <Body2 color={Colors.textColor}>
        Contact an admin and ask them to give you admin permissions on the Business
      </Body2>
    ),
  },
  {
    label: (
      <Body2 color={Colors.textColor}>
        Once you’ve been setup as an admin, or your admin logs in and connects to your Symphony profile - click “Verify Connection” to proceed.
      </Body2>
    ),
  },
]

export const StepFacebookAdAccount = ({
  facebookAdAccount,
  onClickConnectButton,
  onHover,
  onClickWatchVideoButton,
  onVerifyConnection,
  onSkipError,
  conversionsEnabled,
  conversionsError,
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useScreen()

  const { currentBrand } = useContext(CurrentBrandContext)
  const { connections } = currentBrand || {}
  const { facebook_ad_account: brandAdAccount } = connections || {}
  const { business: fbBusinessAccount } = brandAdAccount || {}
  const id = connections?.facebook_ad_account?.id.split("act_")[1]

  const disabled = (facebookAdAccount.loading || conversionsEnabled) && !conversionsError

  const handleClickGoToBusinessSettingsPage = () => {
    window.open(
      `https://business.facebook.com/settings/people?business_id=${connections?.facebook_ad_account.business?.id}`,
      "_blank",
      'noreferrer'
    )
  }

  const showErrorView = Boolean(facebookAdAccount.error && fbBusinessAccount)
  const showNonAdminErrorView = Boolean(facebookAdAccount.error && !fbBusinessAccount)
  return (
    <ConversionsConfigStep
      {...commonCardsProps[Steps.FACEBOOK_AD_ACCOUNT]}
      {...{ disabled, onHover, onSkipError }}
      isCompleted={Boolean(brandAdAccount?.id) && !facebookAdAccount.error}
      isSuccessful={!facebookAdAccount.error}
      onClickIntercomButton={onClickWatchVideoButton}
      onClickErrorButton={onVerifyConnection}
      showErrorView={Boolean(facebookAdAccount.error && fbBusinessAccount)}
    >
      <>
        <StyledTextField
          readOnly
          showBrandIcon
          {...{ disabled, id: `act_${id}` }}
          connected={Boolean(brandAdAccount?.id)}
          loading={facebookAdAccount.loading}
          value={facebookAdAccount.stepValue || "Facebook Ad Account"}
          onClickConnectButton={onClickConnectButton}
          warning={facebookAdAccount.error}
        />
        {showErrorView && (<>
            <Body1 className="mt-4" color={Colors.textColor}>
              Before we proceed, you'll need to make sure you're an admin on the Meta Business linked to your ad account.
            </Body1>
            <Subtitle1>{`You currently have non-admin access on the business: ${fbBusinessAccount?.name}. To fix this, follow these steps:`}</Subtitle1>
            <InstructionsCard>
              {instructionsLabels.map(({ label, buttonLabel }, index) => {
                return (
                  <FlexContainer key={`step-${index}`}>
                    <NumberedItem>
                      <Subtitle2 color={Colors.purple}>
                        {index + 1}
                      </Subtitle2>
                    </NumberedItem>
                    <LinkContainer>
                      {label}
                      {buttonLabel && (
                        <LinkButton
                          text={buttonLabel}
                          onClick={handleClickGoToBusinessSettingsPage}
                          rightIcon={<ExternalLinkIcon className={classes.externalLinkIcon} />}
                          textAlign={mobileView ? "left" : "center"}
                          showInline
                        />
                      )}
                    </LinkContainer>
                  </FlexContainer>
                )
              })}
            </InstructionsCard>
            <SecondaryButton
              customColor={Colors.textColor}
              text={commonCardsProps[Steps.FACEBOOK_AD_ACCOUNT].buttonText}
              onClick={onVerifyConnection}
              width="fit-content"
            />
            <LinkButton text="I've added myself as an admin" onClick={onSkipError}/>
          </>)
        }
        {showNonAdminErrorView && (
          <Container
            display="flex"
            flexDirection="column"
            gap="8px"
            backgroundColor={Colors.PAPER_ERROR_CONTAINER}
            padding="16px"
            borderRadius="8px"
          >
            <Subtitle2 color={Colors.textColor}>
              {commonCardsProps[Steps.FACEBOOK_AD_ACCOUNT].errorTitle}
            </Subtitle2>
            <Body2>
              {`Your connected ad account ${brandAdAccount?.name} is a personal ad account. To enable and maximize Conversions, you'll need to connect an ad account linked to a Meta / Facebook Business Manager.`}
            </Body2>
            <StyledAnchor href='https://www.facebook.com/business/help/910137316041095?id=420299598837059' target="about:blank" rel="noreferrer">
              <ButtonText>Learn how to create a Business Ad Account</ButtonText>
            </StyledAnchor>
          </Container>)
        }
      </>
    </ConversionsConfigStep>
  )
}
