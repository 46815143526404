import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components"
import Colors from "modules/Colors";

interface Props {
  error?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  overall: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    borderBottomWidth: 1,
  },
  marketing: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
  },
  automationFee: {
    marginTop: theme.spacing(2),
  },
  noBottomBorder: {
    borderBottomWidth: 0,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  paddingBottomThemeSpacing: {
    paddingBottom: theme.spacing(1),
  },
  textField: { height: 48, maxWidth: 116, borderRadius: 6 },
  inputProps: ({ error }: Props) => ({
    fontFamily: "DIN",
    textAlign: "right",
    color: Colors.textColor,
  }),
}))

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  alignText?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
  borderBottomWidth?: string;
}

interface CardProps {
  borderRadius?: string;
}

export const SpendLimitContainer = styled.div`
  margin-top: 16px;
`

export const Card = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  width: 100%;
  background-color: #ffffff;
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  @media (max-width: 768px) {
    border-radius: 0px;
  };
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  align-text: ${({ alignText }: StyledLabelProps) => alignText};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
  borde-bottom-width: ${({ borderBottomWidth }: StyledLabelProps) => borderBottomWidth};
`;

export default useStyles;