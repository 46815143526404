import React from 'react';
import { Draggable, Droppable, DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import { deepCopy } from "modules/Utils";

interface DraggableListProps {
    list: any[];
    keyOfItem: string;
    updateList: (newList: any) => void;
    onDragEnd?: OnDragEndResponder;
    renderItem: (item: any, index: number) => React.ReactNode;
}

export const DraggableList: React.FC<DraggableListProps> = ({ list, keyOfItem, updateList, onDragEnd, renderItem }) => {

    const defaultDragEnd: OnDragEndResponder = (result) => {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination?.index;

        if (!destinationIndex && destinationIndex !== 0) return;

        const tempList = deepCopy(list);
        const temp = tempList[sourceIndex];
        tempList[sourceIndex] = tempList[destinationIndex];
        tempList[destinationIndex] = temp;

        updateList(tempList)
    };

    return (
        <DragDropContext onDragEnd={onDragEnd || defaultDragEnd}>
            <Droppable droppableId="draggable-list">
                {(provided) => (
                    <div className='flex flex-col gap-2 w-full' {...provided.droppableProps} ref={provided.innerRef}>
                        {list.map((item, index) => (
                            <Draggable key={item[keyOfItem]} draggableId={`item-${item[keyOfItem]}`} index={index}>
                                {(provided) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        {renderItem(item, index)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
