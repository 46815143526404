import { makeStyles, Theme } from "@material-ui/core/styles";
import Colors from "modules/Colors";

const useStyles = makeStyles((theme: Theme) => ({
  chipLabelColor: {
    color: Colors.textColor,
  },
  chip: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    padding: '8px 12px',
    width: 'fit-content',
  },
  chipBackgroundColor: {
    backgroundColor: `${Colors.purple}08`,
  },
}));

export default useStyles;