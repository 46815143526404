
export enum WEBSITE_CREATION_TYPE {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
  PRESAVE = 'presave',
  CAMPAIGN = 'marketing',
}

export const WEBSITE_TYPES = {
  RECORD_STREAMS: "record_streams",
  DATA_COLLECTOR: "data_collector",
  TOUR: "tour",
} as const;

export type WebsiteType = typeof WEBSITE_TYPES[keyof typeof WEBSITE_TYPES];

export const RECORD_STREAMS_TYPES = {
  PROJECT: "project",
  PLAYLIST: "playlist",
  TRACK: "track",
} as const;

export type RecordStreamsType = typeof RECORD_STREAMS_TYPES[keyof typeof RECORD_STREAMS_TYPES];


export type WebsiteDataType = {
  contentSlug: string;
  since: string;
  until: string;
  topLevelMetrics: TopLevelMetrics;
  referrers: Referrer[];
  overallActivity: OverallActivity[];
  geographicBreakdown: GeographicBreakdown;
  contentBreakdown: any[]; // Specify further if there's a known structure
  socialMediaBreakdown: any[]; // Specify further if there's a known structure
  // for music
  streamingServicesBreakdown?: any[];
  // for tour
  tourLinkClicksBreakdown?: TourLinkClicksBreakdown[];
  presaleActionBreakdowns?: TourLinkClicksBreakdown[];

  // for presave - used to show daily stats on the presave dashboard
  dailyStats?: {
      // The date of the daily stat entry
      date: string;
      // Number of new pre-saves for this date
      newPresaves: number;
      // Number of new forever saves for this date
      newForeverSaves: number;
      // Cumulative total of forever saves up to this date
      totalForeverSaves: number;
      // Cumulative total of pre-saves up to this date
      totalPresaves: number;
      // Number of page visits for this date
      pageVisits: number;
  }[];
};

export type TourLinkClicksBreakdown = {
  count: number;
  city: string;
  date: string;
  venue: string;
};

// used to show overall numbers on the website dashboard
export type TopLevelMetrics = {
  pageVisits: number;
  ctr: number;
  // for music
  streamingLinkClicks?: number;
  // for tour
  tourLinkClicks?: number;
  // for data collector
  signups?: number;
  // for presave
  newPresaves?: number;
  newForeverSaves?: number;
  totalPresaves?: number;
  totalForeverSaves?: number;
};

export type Referrer = {
  referrer: string | null;
  count: number;
};

export type OverallActivity = {
  streamingLinkClicks?: number;
  tourLinkClicks?: number;
  signups?: number;
  pageViews: number;
  date: string;
};

export type GeographicBreakdown = {
  cities?: LocationCount[];
  countries?: LocationCount[];
};

export type LocationCount = {
  country: string;
  city?: string;
  count: number;
  uniqueId?: string;
};
