import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { IconContainer } from "../styles";
import { StarsIcon } from "components/svg-icons";
import { CSSProperties } from "react";
import { styx } from "modules/Utils";

interface SubscriptionStatusIndicatorProps {
    style?: CSSProperties;
    backgroundColor?: string;
    handleOpenModal?: () => void;
    margin?: string;
    maxWidth?: string;
    showStars?: boolean;
    text: string;
    textColor?: string;
    width?: string;
}

const SubscriptionStatusIndicator = ({
    style,
    backgroundColor,
    handleOpenModal,
    margin,
    maxWidth,
    showStars,
    text,
    textColor,
    width
}: SubscriptionStatusIndicatorProps) => {
    return (
        <div
            className={`subscriptionStatusIndicatorComponent px-5 justify-center ${handleOpenModal ? 'cursor-pointer' : ''}`}
            onClick={handleOpenModal}
            style={styx(
                {
                    width: width || 'inherit',
                    margin: margin || 'inherit'
                },
                style
            )}
        >
            <UpgradeToProLabel
                label={text}
                maxWidth={maxWidth}
                customColors={{
                    backgroundColor: backgroundColor || "rgba(245, 186, 35, 0.12)",
                    labelColor: textColor || "#F5BA23",
                }}
                iconBeforeLabel={showStars &&
                    <IconContainer>
                        <StarsIcon />
                    </IconContainer>
                }
                iconAfterLabel={showStars &&
                    <IconContainer transform="scaleX(-1)">
                        <StarsIcon />
                    </IconContainer>
                }
            />
        </div>
    )
}

export default SubscriptionStatusIndicator;