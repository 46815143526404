import { useState } from 'react'
import HeadCard from "./Components/HeadCard";
import BudgetCard from "./Components/BudgetCard";
import DurationCard from "./Components/DurationCard";
import GoLiveCard from "./Components/GoLiveCard";
import { SelectedTikTokSparkAd, VideoAdsRecord } from "types/global";
import Grid from "@material-ui/core/Grid"
import useScreen from 'Hooks/useScreen';

interface FinalizationProps {
    campaignId: string | null
    startDate: Date | null;
    endDate: Date | null;
    sparkCode: string | null;
    budget: number;
    targeting: VideoAdsRecord[];
    link: string;
    geographyType: string;
    selectedCountries: { label: string; value: string }[];
    selectedPosts: SelectedTikTokSparkAd[]
}

const Finalization = ({
    campaignId,
    budget,
    sparkCode,
    endDate,
    startDate,
    targeting,
    link,
    geographyType,
    selectedCountries,
    selectedPosts
}: FinalizationProps) => {
    const { mobileView } = useScreen();
    const [creatingCampaign, setCreatingCampaign] = useState<boolean>(false)

    const onCancelPayPalOrder = () => setCreatingCampaign(false);
    const onErrorPayPalOrder = () => setCreatingCampaign(false);

    return (
        <div className="tikTokAdsFinalizationTabComponent" style={{ backgroundColor: "#EDECF2" }}>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <HeadCard {...{ geographyType, link }} />
            </Grid>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <BudgetCard budget={budget} />
            </Grid>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <DurationCard {...{ startDate, endDate }} />
            </Grid>
            <GoLiveCard
                {...{
                    selectedPosts,
                    campaignId,
                    startDate,
                    endDate,
                    budget,
                    sparkCode,
                    creatingCampaign,
                    selectedCountries,
                    targeting,
                    link,
                    geographyType,
                    onCancelPayPalOrder,
                    onErrorPayPalOrder,
                    setCreatingCampaign,
                }}
            />
        </div>
    )
}

export default Finalization;