import { useContext, useState } from 'react'
import HeadCard from "./Components/HeadCard";
import BudgetCard from "./Components/BudgetCard";
import DurationCard from "./Components/DurationCard";
import GoLiveCard from "./Components/GoLiveCard";
import { CurrentBrand, VideoAdsRecord } from "types/global";
import Grid from "@material-ui/core/Grid"
import ConfirmationStepCard from './Components/ConfirmationStep';
import useScreen from 'Hooks/useScreen';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

interface FinalizationProps {
    campaignId: string | null
    startDate: Date | null;
    endDate: Date | null;
    record: VideoAdsRecord | null;
    budget: number;
    brand: CurrentBrand;
    targeting: VideoAdsRecord[];
    targetingType: string;
    geographyType: string;
    selectedCountries: { label: string; value: string }[];
    cancelSaving: () => void;
    confirmed: boolean;
    setConfirmation: (val: boolean) => void;
    refundFeeConfirmed: boolean;
    setRefundConfirmation: (val: boolean) => void;
}

const Finalization = ({
    campaignId,
    budget,
    record,
    brand,
    endDate,
    startDate,
    targeting,
    targetingType,
    geographyType,
    selectedCountries,
    cancelSaving,
    confirmed,
    setConfirmation,
    setRefundConfirmation,
    refundFeeConfirmed
}: FinalizationProps) => {
    const { mobileView } = useScreen();
    const { proFreeTrialRedeemed } = useContext(CurrentBrandContext)

    const [creatingCampaign, setCreatingCampaign] = useState<boolean>(false)

    const onCancelPayPalOrder = () => setCreatingCampaign(false);
    const onErrorPayPalOrder = () => setCreatingCampaign(false);

    return (
        <div className="finalizationTabComponent" style={{ backgroundColor: "#f5f3f6" }}>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <HeadCard {...{ record, geographyType, targetingType }} />
            </Grid>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <BudgetCard 
                    budget={budget}
                />
            </Grid>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <DurationCard {...{ startDate, endDate }} />
            </Grid>
            <Grid className={mobileView ? "mb-0" : "mb-4"}>
                <ConfirmationStepCard {...{ setConfirmation, confirmed, refundFeeConfirmed, setRefundConfirmation }} />
            </Grid>
            <GoLiveCard
                refundFeeConfirmed={refundFeeConfirmed}
                confirmed={confirmed}
                campaignId={campaignId}
                startDate={startDate}
                endDate={endDate}
                budget={budget}
                record={record}
                creatingCampaign={creatingCampaign}
                selectedCountries={selectedCountries}
                targeting={targeting}
                targetingType={targetingType}
                geographyType={geographyType}
                onCancelPayPalOrder={onCancelPayPalOrder}
                onErrorPayPalOrder={onErrorPayPalOrder}
                setCreatingCampaign={setCreatingCampaign}
                cancelSaving={cancelSaving}
            />
        </div>
    )
}

export default Finalization