import { useMediaQuery, useTheme } from "@material-ui/core";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

export type Data = {
    [key: string]: number | string;
}[]

interface SBarChartProps {
    xAxisFormat?: 'date';
    data: Data
    direction?: 'horizontal' | 'vertical';
    xAxisKey: string;
    yAxisKey: string;
    barHeight?: number;
    barWidth?: number;
    barGap?: number;
    tickHeight?: number;
    tickWidth?: number;
    tickGap?: number;
    tick?: (value: any) => JSX.Element;
    uniqueKey?: string;
}

export default function SBarChart(props: SBarChartProps) {
    const {
        xAxisFormat,
        data,
        xAxisKey,
        yAxisKey,
        direction,
        barHeight = 300,
        barWidth = 50,
        barGap = 5,
        tickHeight = 20,
        tickWidth = 50,
        tickGap = 5,
        tick,
        uniqueKey,
    } = props

    const theme = useTheme()
    const showResponsiveGraphView = useMediaQuery(theme.breakpoints.down(575));

    const processedData = data.map((item, index) => ({
        ...item,
        uniqueId: uniqueKey ? item[uniqueKey] : item.uniqueId || `${item[yAxisKey]}-${index}`
    }));

    const Tick = (props: any) => {
        const { x, y, payload } = props;
        const translate = direction === 'horizontal' ? `translate(0,${(y - (tickHeight / 2))})` : `translate(${x - (tickWidth / 2)},${barHeight + 10})`

        return (
            <g
                transform={`${translate} ${showResponsiveGraphView ? `rotate(0 ${100 / 2} ${120 / 2})` : ''}`}
            >
                <foreignObject x={0} y={0} width={tickWidth} height={tickHeight} className="overflow-visible">
                    {tick ? tick(payload) : payload.value}
                </foreignObject>
            </g>
        )
    }

    if (direction === 'horizontal') {
        const barsHeight = (processedData.length + 1) * (barHeight + barGap)
        const yAxisHeight = (processedData.length + 1) * (tickHeight + tickGap)
        const height = Math.max(barsHeight, yAxisHeight)
        const width = barWidth + tickWidth + 10

        return (
            <ResponsiveContainer className="mx-auto" height={height} width='100%'>
                <BarChart
                    id="barChart"
                    width={width}
                    height={height}
                    layout='vertical'
                    data={processedData}
                >
                    <XAxis
                        type="number"
                        hide={true}
                        dataKey={xAxisKey}
                        domain={[(dataMin: any) => (0), (dataMax: any) => (dataMax * 1.3)]}
                        axisLine={false}
                        tickLine={false}
                    />
                    <YAxis
                        type="category"
                        dataKey="uniqueId"
                        axisLine={false}
                        tickLine={false}
                        height={yAxisHeight}
                        width={tickWidth}
                        tickSize={tickWidth}
                        tick={<Tick/>}
                    />
                    <Bar
                        dataKey={xAxisKey}
                        radius={6}
                        fill="#D3B7F7"
                        background={{ fill: '#EDECF2', radius: 6 }}
                        barSize={barHeight}
                        width={barWidth}
                        id="bar"
                    />
                </BarChart>
            </ResponsiveContainer>
        )
    }

    const height = barHeight + tickHeight
    const width = (processedData.length + 1) * (barWidth + barGap)

    return (
        <ResponsiveContainer className="mx-auto" height={height} width="100%">
            <BarChart
                id="barChart"
                height={height}
                width={width}
                layout="horizontal"
                data={processedData}
            >
                <XAxis 
                    type='category'
                    dataKey="uniqueId"
                    domain={[(dataMin: any) => (0), (dataMax: any) => (dataMax * 1.3)]}
                    axisLine={false}
                    tickLine={false}
                    tick={<Tick/>}
                    tickSize={tickHeight}
                    height={tickHeight}
                    width={width}
                    interval={0} // Add this line to show all ticks

                />
                <YAxis
                    type="number"
                    dataKey={yAxisKey}
                    axisLine={false}
                    tickLine={false}
                    hide={true}
                    minTickGap={0}
                    tickMargin={10}
                />
                <Bar
                    dataKey={yAxisKey}
                    radius={6}
                    fill="#D3B7F7"
                    background={{ fill: '#EDECF2', radius: 6 }}
                    barSize={barWidth}
                    height={barHeight}
                    id="bar"
                />
            </BarChart>
        </ResponsiveContainer>
    )
}