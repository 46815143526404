import Axios from "helpers/Interceptor";
import { track } from 'analytics';
import { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { CurrentBrand, RecordSelected } from "types/global";

interface FinalizationProps {
    record: RecordSelected,
    brand: CurrentBrand,
    selectedTemplate: {
        name: string,
        templateId: string,
        previewUrl: string
    },
    selectedColorPalette: {
        color1: string,
        color2: string,
        color3: string
    };
    artworkImageUrl: string | undefined;
    artistImageUrl: string | undefined;
}

export default function Finalization(props: FinalizationProps) {
    const {
        // main detaills
        record,
        brand,
        selectedTemplate,
        selectedColorPalette,
        artistImageUrl,
        artworkImageUrl
    } = props

    const [creatingCampaign, setCreatingCampaign] = useState<boolean>(false)

    const handleCreateCampaign = (creatingCampaign: boolean) => () => setCreatingCampaign(creatingCampaign)

    const history = useHistory()
    // PayPal - Checkout
    const paypalOrderDetails = useRef<any>()

    async function createPayPalOrder(data: any, actions: any) {
        track("Completed Campaign Step - Checkout", {
            type: "creative_generator",
            payment_method: "paypal",
            step: 3,
            name: "confirmation",
            brand_id: brand.id,
            brand_name: brand.name,
            brand_slug: brand.slug,
            content_name: record.name,
            content_slug: record.slug,
            content_id: record.id,

            template_id: selectedTemplate.templateId,
            template_name: selectedTemplate.name,

            colorPalette: selectedColorPalette
        })

        setCreatingCampaign(true)
        // return "yes"
        // create the request to get the payment URL
        const paypalCheckoutRequest = await Axios.post(`/campaign/brand/${brand.id}/checkout`, {
            campaign: {
                campaign_type: 'creative_generator',
                price_data: {
                    price: 10,
                    name: `${selectedTemplate.name} Videos for "${record.name}"`
                },
                content: {
                    name: record.name,
                    id: record.id,
                    slug: record.slug,
                    url: record.url,
                    thumbnail_url: record.thumbnail_url,
                    artworkImage: artworkImageUrl,
                    artistImage: artistImageUrl
                },
                campaign_platforms: [
                    'creative_generator'
                ],
                payment_method: "paypal",
                creativeData: {
                    template: selectedTemplate,
                    colorPalette: selectedColorPalette
                },
            },
        })

        if (!paypalCheckoutRequest || !paypalCheckoutRequest.data || !paypalCheckoutRequest.data.data) {
            setCreatingCampaign(false)
            throw new Error("Error creating PayPal order")
        }

        const paypalCreatedOrder = paypalCheckoutRequest.data.data
        const orderId = paypalCreatedOrder.paypalOrder.id
        const campaignId = paypalCreatedOrder.campaignId

        paypalOrderDetails.current = {
            orderId: orderId,
            campaignId: campaignId
        }
        setCreatingCampaign(false)

        return orderId
    }

    async function onPayPalOrderApprove() {
        // redirect to success page once order is created
        history.push(`/marketing/success?campaign_id=${paypalOrderDetails.current.campaignId}&session_id=${paypalOrderDetails.current.orderId}`)
    }

    // Stripe - Checkout
    const redirectToStripeCheckout = async () => {
        track("Completed Campaign Step - Checkout", {
            type: "creative_generator",
            payment_method: "stripe",
            step: 3,
            name: "confirmation",
            brand_id: brand.id,
            brand_name: brand.name,
            brand_slug: brand.slug,
            content_name: record.name,
            content_slug: record.slug,
            content_id: record.id,

            template_id: selectedTemplate.templateId,
            template_name: selectedTemplate.name,

            colorPalette: selectedColorPalette
        })

        setCreatingCampaign(true)

        // create the request to get the payment URL
        try {
            const redirectUrlReq = await Axios.post(`/campaign/brand/${brand.id}/checkout`, {
                campaign: {

                    campaign_type: 'creative_generator',
                    creativeData: {
                        template: selectedTemplate,
                        colorPalette: selectedColorPalette
                    },
                    price_data: {
                        price: 10,
                        name: `${selectedTemplate.name} Videos for "${record.name}"`
                    },
                    content: {
                        name: record.name,
                        id: record.id,
                        slug: record.slug,
                        url: record.url,
                        thumbnail_url: record.thumbnail_url,
                        artworkImage: artworkImageUrl,
                        artistImage: artistImageUrl
                    },
                    campaign_platforms: [
                        'creative_generator'
                    ]
                }
            })

            if (!redirectUrlReq || !redirectUrlReq.data ||
                !redirectUrlReq.data.data || !redirectUrlReq.data.data.url) {
                throw new Error("Error getting URL")
            }
            window.location = redirectUrlReq.data.data.url
        } catch (e) {
            console.error('e', e)
        }
    }

    return (
        <div className="mt-1" style={{
            overflow: 'hidden',
            overflowAnchor: 'none',
            overflowX: 'hidden',
            overflowY: 'hidden',
            msOverflowStyle: 'none',
            msOverflowX: 'hidden',
            msOverflowY: 'hidden',
            overflowBlock: 'hidden',
            overflowClipBox: 'content-box',
            overflowInline: 'hidden',
        }}
        >
            <div className="sy-card px-5 lg:px-10">
                <div className="">
                    <h5 className="text-dark font-bold text-lg">Let's Make your Videos</h5>
                    <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                        Review your details to make sure everything looks good before we begin generating your custom videos.
                    </p>
                </div>
            </div>
            <div className="add-new ">
                {/* Details */}
                <div className="sy-card px-5 lg:px-10">
                    <div className="flex flex-none text-center">
                        <div className={`number-blk added`}>01</div>
                        <div className="px-3 items-center flex">
                            <h5 className="text-dark">Details</h5>
                        </div>
                    </div>
                </div>
                <div className="px-5 lg:px-10 text-center p-4 lg:p-4 border-b border-backgound-100">
                    <div className="flex items-center">
                        <div className="artist-img">
                            <img src={record?.thumbnail_url || ''} alt="thumbnail" />
                        </div>
                        <div className="px-3">
                            <h5 className="text-dark font-bold text-lg">{record?.name || ''}</h5>

                        </div>
                    </div>

                </div>
                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                    <p className="text-lg font-bold">Total Videos</p>
                    <div className='text-right'>
                        <p className="text-lg underline">2 Videos</p>
                        <p className="text-lg">4x5 (for IG Feed, Facebook)</p>
                        <p className="text-lg">9x16 (for TikTok, IG Stories)</p>

                    </div>
                </div>

                <div className="overflow-hidden px-5 lg:px-10 py-5 border-b flex flex-row justify-between items-center">
                    <p className="text-lg font-bold">Style</p>
                    <div className='flex items-center justify-center'>
                        <p className="text-lg mr-2">{selectedTemplate.name}</p>
                        <video className="overflow-hidden w-16 lg:w-24" src={selectedTemplate.previewUrl} autoPlay loop muted playsInline />

                    </div>
                </div>

                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between items-center">
                    <p className="text-lg font-bold">Color Scheme</p>
                    <div className='flex items-center justify-center'>
                        <div className='rounded-md w-full flex-row flex '>
                            {Object.keys(selectedColorPalette).map((k: string, idx: number) => {
                                let color = (selectedColorPalette as any)[k];
                                return (
                                    <div
                                        key={color.replace('#', '') + idx}
                                        className='rounded-md w-10 h-10 m-2 first-child:m-10  '
                                        style={{
                                            backgroundColor: color,
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                {/* Payment Method */}
                <div className="sy-card px-5 lg:px-10">
                    <div className="flex flex-none text-center">
                        <div className={`number-blk added`}>02</div>
                        <div className="px-3 items-center flex">
                            <h5 className="text-dark">Payment</h5>
                        </div>
                    </div>

                </div>
                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                    <p className="text-lg font-bold">Budget</p>
                    <p className="text-lg">$10</p>
                </div>
                <p className="px-6 lg:px-10 pt-5">It’ll take us a few minutes to generate your video. You'll receive an email once it's ready, and you'll be able to download it from the Symphony Marketing tab.</p>
                {/* create campaign */}
                <div className="text-center px-4 lg:px-10 md:py-4 pb-0
                 pb-20
                ">
                    <button
                        disabled={creatingCampaign}
                        onClick={redirectToStripeCheckout}
                        className="btn-primary 
                        w-full br-20px 
                        sm:md-20 
                        mb-0
                        mb-2
                        lg:mb-4 py-2 lg:py-3">Checkout with Card</button>

                    <PayPalScriptProvider options={{
                        "client-id": (process.env.REACT_APP_PAYPAL_CLIENT_ID as string)
                    }}>

                        <PayPalButtons
                            className="w-full relative z-0"
                            style={{
                                label: "pay",
                                shape: "pill"
                            }}
                            fundingSource='paypal'
                            onCancel={handleCreateCampaign(false)}
                            onError={handleCreateCampaign(false)}
                            createOrder={createPayPalOrder}
                            onApprove={onPayPalOrderApprove}
                        />
                        <PayPalButtons
                            className="w-full relative z-0"
                            style={{
                                label: "pay",
                                shape: "pill"
                            }}
                            fundingSource='venmo'
                            onCancel={handleCreateCampaign(false)}
                            onError={handleCreateCampaign(false)}
                            createOrder={createPayPalOrder}
                            onApprove={onPayPalOrderApprove}
                        />

                    </PayPalScriptProvider>
                </div>
            </div>
        </div>
    )
}