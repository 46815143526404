import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { THEME_BREAKPOINTS } from 'types/global'
import $ from 'jquery'
import { CSSProperties } from 'react'

interface StyleObject {
    [key: string]: CSSProperties
}

interface MediaStore {
    screenWidth: number
    screenHeight: number

    smallView: boolean
    mobileView: boolean
    mediumView: boolean
    largeView: boolean

    updateDimensions: () => void
    mStyle: (sty: StyleObject, styKey: string) => CSSProperties
    cssCalc: (elOrNum1?: string | number, elOrNum2?: string | number) => number | 'auto'
    getHeightBetweenElements: (topName: string, bottomName: string) => number | 'auto'
}

// Add type declaration for module hot API
declare const module: {
    hot?: {
        dispose: (callback: () => void) => void;
    };
};

const useScreen = create<MediaStore>()(
    immer((set, get) => ({
        // Initial state
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight, 
        smallView: window.innerWidth < THEME_BREAKPOINTS.SMALL,
        mobileView: window.innerWidth < THEME_BREAKPOINTS.MOBILE,
        mediumView: window.innerWidth < THEME_BREAKPOINTS.MEDIUM,
        largeView: window.innerWidth >= THEME_BREAKPOINTS.LARGE,

        // Actions
        updateDimensions: () => {
            set(state => {
                state.screenWidth = window.innerWidth
                state.screenHeight = window.innerHeight

                state.smallView = window.innerWidth < THEME_BREAKPOINTS.SMALL
                state.mobileView = window.innerWidth < THEME_BREAKPOINTS.MOBILE
                state.mediumView = window.innerWidth < THEME_BREAKPOINTS.MEDIUM
                state.largeView = window.innerWidth >= THEME_BREAKPOINTS.LARGE
            })
        },

        mStyle: (sty: StyleObject, styKey: string) => {
            const styObject = sty[styKey]
            const screenWidth = get().screenWidth

            const getBreakpointStyle = (suffix: string) => {
                const breakpointStyle = sty[`${styKey}_${suffix}`]
                return breakpointStyle ? { ...styObject, ...breakpointStyle } : null
            }

            if (screenWidth < THEME_BREAKPOINTS.SMALL) {
                return (
                    getBreakpointStyle('small') ||
                    getBreakpointStyle('mobile') ||
                    getBreakpointStyle('medium') ||
                    getBreakpointStyle('large') ||
                    styObject
                )
            }

            if (screenWidth < THEME_BREAKPOINTS.MOBILE) {
                return (
                    getBreakpointStyle('mobile') ||
                    getBreakpointStyle('medium') ||
                    getBreakpointStyle('large') ||
                    styObject
                )
            }

            if (screenWidth < THEME_BREAKPOINTS.MEDIUM) {
                return (
                    getBreakpointStyle('medium') ||
                    getBreakpointStyle('large') ||
                    styObject
                )
            }

            if (screenWidth < THEME_BREAKPOINTS.LARGE) {
                return getBreakpointStyle('large') || styObject
            }

            return styObject
        },

        /** Helper function to calculate height between two DOM elements */
        getHeightBetweenElements: (topName: string, bottomName: string): number | 'auto' => {
            const topElement = $(topName)
            const bottomElement = $(bottomName)

            if (topElement && bottomElement && topElement.offset() && bottomElement.offset()) {
                const topPosition = topElement.offset()?.top ?? 0
                const bottomPosition = bottomElement.offset()?.top ?? 0
                const distance = Math.abs(bottomPosition - topPosition)

                if (isNaN(distance)) {
                    return 'auto'
                }
                return distance
            }

            return 'auto'
        },

        cssCalc: (elOrNum1?: string | number, elOrNum2?: string | number) => {
            if (typeof elOrNum1 === 'string' && typeof elOrNum2 === 'string') {
                return get().getHeightBetweenElements(elOrNum1, elOrNum2)
            }

            if (typeof elOrNum1 === 'string') {
                const el = $(elOrNum1)
                if (el && el.offset()) {
                    const elHeight = ($(window).height() ?? 0) - (el.offset()?.top ?? 0)
                    if (isNaN(elHeight)) {
                        return 'auto'
                    }
                    return elHeight
                }
            }

            if (!elOrNum1) {
                elOrNum1 = 0
            }
            if (isNaN(Number(elOrNum1))) {
                return 'auto'
            }
            return ($(window).height() ?? 0) - Number(elOrNum1)
        }
    }))
)

// Cleanup function to remove resize event listener
const cleanupResizeListener = () => {
    console.log('cleanupResizeListener ++++')
    if (typeof window !== 'undefined') {
        window.removeEventListener('resize', useScreen.getState().updateDimensions)
    }
}

// Clean up existing listener before adding new one
cleanupResizeListener()

// Set up resize listener
if (typeof window !== 'undefined') {
    console.log('setting up resize listener ++++')
    window.addEventListener('resize', () => {
        console.log('resize event ++++')
        // Debounce the resize event to prevent too many updates
        const handler = useScreen.getState().updateDimensions
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(handler)
        } else {
            handler()
        }
    })
}

// Clean up on module unload/hot reload
if (module.hot) {
    console.log('module.hot ++++')
    module.hot.dispose(cleanupResizeListener)
}

export default useScreen