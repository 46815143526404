import { CardContainer, CardContainerMobile, CompletedMarkContainer, TaskDescription, TaskHeaderMobile, TaskIcon, TaskIconImage, TaskTextInfo, TaskTitle, useStyles } from "./styles";
import { clsx } from 'clsx';
import { ReactComponent as TickIcon } from "assets/images/tick.svg"
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrowRight.svg';
import useScreen from "Hooks/useScreen";
import ButtonSymphony from "components/shareable/ButtonSymphony";

interface Props {
  icon?: string;
  title: string;
  description: string;
  actionName?: string;
  action?: () => void;
  href?: string;
  crossedOut?: boolean;
  addBorder?: boolean;
  headerAlign?: string;
  showBoxShadow?: boolean;
}

const TaskCard = ({
  icon,
  title,
  description,
  actionName,
  action,
  crossedOut = false,
  addBorder = false,
  headerAlign = "flex-start",
  showBoxShadow,
  href
}: Props) => {
  const classes = useStyles();
  const { largeView, mobileView } = useScreen();

  if (largeView) {
    return (
      <CardContainerMobile>
        <TaskHeaderMobile flexDirection={largeView ? "row" : "column"}>
          <TaskIcon>
            <TaskIconImage src={icon} />
          </TaskIcon>
          <TaskTitle textAlign={headerAlign}>
            {title}
          </TaskTitle>
        </TaskHeaderMobile>
        <TaskTextInfo className={clsx(crossedOut && classes.crossedOut)}>
          {
            (!mobileView || !largeView) &&
            <TaskTitle>
              {title}
            </TaskTitle>
          }
          <TaskDescription>
            {description}
          </TaskDescription>
          {action && !crossedOut ?
            <ButtonSymphony
              className="taskCardActionButton1"
              onClick={action}
              variant="link"
              iconRight={<ArrowRightIcon style={{marginTop: 3, marginLeft: -4}} />}
            >
              {actionName ? actionName : 'Start'}
            </ButtonSymphony>
            :
            <TickIcon className={clsx(classes.markIcon, !crossedOut && 'hidden')} />
          }
        </TaskTextInfo>
      </CardContainerMobile>
    )
  }

  return (
    <CardContainer
      className={addBorder ? classes.borded : ''}
      showBoxShadow={showBoxShadow}
    >
      <div>
        <TaskIcon>
          <TaskIconImage src={icon} />
        </TaskIcon>
      </div>
      <TaskTextInfo className={clsx(crossedOut && classes.crossedOut)}>
        <TaskTitle>
          {title}
        </TaskTitle>
        <TaskDescription>
          {description}
        </TaskDescription>

        {!href && action && !crossedOut &&
          <ButtonSymphony
            className="taskCardActionButton2"
            variant="link"
            onClick={action}
            iconRight={<ArrowRightIcon />}
          >
            {actionName ? actionName : 'Start'}
          </ButtonSymphony>
        }
        {!action && href && !crossedOut &&
          <ButtonSymphony
            className="taskCardActionButton3"
            variant="link"
            href={href}
            iconRight={<ArrowRightIcon />}
          >
            {actionName ? actionName : 'Start'}
          </ButtonSymphony>
        }
        {action && href && !crossedOut &&
          <ButtonSymphony
            className="taskCardActionButton4"
            variant="link"
            href={href}
            onClick={action}
            iconRight={<ArrowRightIcon />}
          >
            {actionName ? actionName : 'Start'}
          </ButtonSymphony>
        }
      </TaskTextInfo>
      {crossedOut &&
        <CompletedMarkContainer>
          <TickIcon className={classes.markIcon} />
        </CompletedMarkContainer>
      }
    </CardContainer>
  );
};

export default TaskCard;