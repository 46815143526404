import { useState, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";

type Option = {
  id: string;
  component: JSX.Element;
};

interface Props {
  options: Option[];
  gap?: string;
  handleOnDragEnd?: (items: Option[]) => void;
}

const reorder = (list: Option[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  _isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
  gap?: string,
) => {
  return ({
  background: "white",
  margin: `0 0 ${gap || 0} 0`,
  ...draggableStyle,
});
}

const DraggableList = ({
  options,
  handleOnDragEnd,
  gap = "",
}: Props) => {
  const [items, setItems] = useState<Option[]>([] as Option[]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index
    ) as Option[];

    if (handleOnDragEnd) handleOnDragEnd(reordered);
    setItems(reordered);
  };

  useEffect(() => {
    if (options) setItems(options);
  }, [options]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, _snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item: Option, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => {
                  const { component } = item;

                  console.log('item', item)
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        gap,
                      )}
                    >
                      {component}
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
