import { useState } from "react";
import { Body2, Caption } from "components/shareable/Typography";
import { ReactComponent as FacebookRoundedIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as InstagramBwIcon } from "assets/images/instagram-bw.svg";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-bordered-icon.svg";
import styled from "styled-components";
import { MEDIA_BREAKPOINTS } from "types/global";
import ButtonSymphony from "./shareable/ButtonSymphony";
import Colors from "modules/Colors";
import Sty from "modules/Sty";

interface Props {
  className?: string;
  title: string;
  subtitle?: string;
  iconImg?: string | null;
  text?: string;
  buttonText?: string;
  instagramIcon?: boolean;
  fbIcon?: boolean;
  showSubtextIcon?: boolean;
  connectedChip?: boolean;
  connectedText?: string;
  emptyConnection?: boolean;
  disabledButton?: boolean;
  igLinked?: string;
  onClick?: () => Promise<void> | void;
}

const AccountContainer = ({
  className = '',
  title,
  subtitle = '',
  iconImg = null,
  text,
  buttonText,
  instagramIcon,
  fbIcon,
  showSubtextIcon = true,
  connectedChip,
  connectedText,
  igLinked,
  emptyConnection,
  disabledButton,
  onClick,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    onClick && await onClick();
    setLoading(false);
  }

  const renderIcon = () => {
    if (!iconImg) {
      return null;
    }

    if (iconImg) {
      return <img className="w-10 h-10 rounded-lg" src={iconImg} alt="icon" />;
    }

    if (instagramIcon) {
      return (
        <ImageContainer backgroundColor={Colors.PAPER_DIVIDER_CONTAINER_DARK}>
          <InstagramBwIcon />
        </ImageContainer>
      );
    }
    if (fbIcon) {
      return (
        <ImageContainer>
          <FacebookRoundedIcon />
        </ImageContainer>
      );
    }
    return null;
  };

  return (
    <MainContainer className={`accountContainerComponent ${className}`}>
      <div className="w-full flex gap-2 items-center">
        {renderIcon()}
        <div className="flex flex-col">
          {(instagramIcon && showSubtextIcon && !emptyConnection) && (
            <div className="flex gap-1 items-center">
              <InstagramIcon width={16} height={16} />
              <Caption color={Colors.greyDark}>
                IG Page
              </Caption>
            </div>
          )}
          {(fbIcon && showSubtextIcon && !emptyConnection) && (
            <div className="flex gap-1 items-center">
              <FacebookRoundedIcon width={16} height={16} />
              <Caption color={Colors.greyDark}>
                FB Page
              </Caption>
            </div>
          )}
          <Body2 color={Colors.textColor}>{title}</Body2>
          <Caption color={Colors.textColor}>
            {subtitle}
          </Caption>
          <Caption color={Colors.textColor}>{text}</Caption>
          {igLinked && (
            <LinkedIgContainer>
              <Caption color={Colors.textColor}>Linked to: </Caption>
              <InstagramIcon width={12} height={12} />
              <Caption color={Colors.greyDark}>@{igLinked} </Caption>
            </LinkedIgContainer>
          )}
        </div>
      </div>
      <div className="accountContainerButtonContainer flex items-center gap-2">
        {buttonText && onClick &&
          <ButtonSymphony
            className="buttonAccountContainer"
            onClick={handleClick}
            disabled={disabledButton || loading}
            isLoading={loading}
            variant="outlined"
            minWidth={109}
          >
            {buttonText}
          </ButtonSymphony>
        }
        {connectedChip &&
          <ButtonSymphony
            className="connectedChipButton"
            color="connected"
          >
            {connectedText ? connectedText : 'Connected'}
          </ButtonSymphony>
        }
      </div>
    </MainContainer>
  );
}

interface ImageContainerProps {
  backgroundColor?: string;
}

const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || "#1977F329"};
  padding: 0.75rem;
  border-radius: 8px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${Sty.border};
  padding: 12px;
  border-radius: 8px;
  gap: 16px;
  flex-direction: row;
  ${MEDIA_BREAKPOINTS.mobileView} {
      flex-direction: column;
      gap: 8px;
  }
`;

const LinkedIgContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export default AccountContainer;