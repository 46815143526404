import { useContext, useEffect, useState } from "react";
import { daysCalculator, checkImageUrlValidity } from "helpers/General";
import { availableGoals, CAMPAIGN_CARDS } from "../../utils/marketing";
import { CAMPAIGN_TYPE_MAPPINGS } from "modules/Const";
import MaestroCampaignRecommender from "../CreateNewCampaignModal/MaestroCampaignRecommender";
import { Grid } from "@material-ui/core";
import { CampaignDetails } from "types/global";
import { useHistory } from "react-router-dom";
import CreateCampaignCard from "../CreateNewCampaignModal/CreateCampaignCard";
import CardCampaignDraft from "../../DraftTab/Components/CardCampaignDraft";
import DeleteCampaignModal from "../DeleteCampaignModal";
import { trackNewCampaign } from "./analytics";
import HorizontalModalWithVideo from "components/shareable/Modal/with-video";
import { OutlinedButton } from "styles/shared";
import LoaderV2 from 'components/Loader/LoaderV2';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { CampaignOption } from "../../GrowInstagram/utils";
import { getFormattedDateLastUpdate } from "../../utils/daysCalculator";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import CampaignCard from "../CampaignCard";

const boostIgCampaignImage = require('assets/images/ui/ig-boost-campaign-img.png').default
const boostVisitsCampaignImage = require('assets/images/ui/ig-boost-profile-visits-img.png').default

type Draft = {
    id: number
    brand_id: number
    createdAt: string
    status: string
    updatedAt: string
    campaign_metadata: { campaign_type: string } & Record<string, unknown>
}

type CampaignInformation = {
    campaignId: number;
    date: string;
    typeTitle: string;
    imageUrl: string;
    campaignName: string;
    campaignType: string;
}

const getCampaignName = (args: {
    campaignName?: string
    contentName?: string
    campaign_type: string
}) => {
    const { campaign_type, campaignName, contentName } = args

    if (campaign_type === "link_clicks" || campaign_type === "boost_instagram") {
        if (typeof campaignName === 'string') return campaignName
        return ""
    }

    if (typeof contentName === 'string') return contentName
    return ""
}

const getDraftCampaignInformation = async (campaign: Record<string, unknown> | unknown) => {
    const { updatedAt, id } = campaign as Record<string, unknown>
    const campaign_metadata = (campaign as Record<string, unknown>)
        .campaign_metadata as Record<string, unknown>;
    const {
        content,
        campaign_type,
        creative,
        campaign_state,
        campaign_input
    } = campaign_metadata as Record<string, any>;

    if (!content) return null;

    const {
        name: contentName,
        thumbnail_url: contentThumbnail,
        campaign_name: campaignName,
    } = content as Record<string, unknown>;
    const mappingData = CAMPAIGN_TYPE_MAPPINGS[campaign_type as string];
    if (!mappingData) return null;

    // used to make any adjustments needed
    let amendedCampaignName = campaignName
    let campaignImg = contentThumbnail as string || mappingData.defaultImage;
    if (campaign_type === 'boost_instagram') {
        let campaignSubtype: CampaignOption | string | undefined = campaign_input?.selectedCampaignType?.id || undefined;
        switch (campaignSubtype) {
            case 'boost_follows':
                campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || campaign_input?.igPage?.instagramAccount?.profile_pic || boostVisitsCampaignImage
                // Fetch image metadata and fix if image is broken
                try {
                    if (campaignImg) await checkImageUrlValidity(campaignImg);
                    // if this doesnt throw, the image is valid and doesnt need a fallback.
                } catch (error) {
                    // if this throws, the image is invalid and needs a fallback.
                    campaignImg = boostVisitsCampaignImage
                }
                amendedCampaignName = `Boost Profile Visits`
                break;
            case 'boost_views':
            default:
                campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || campaign_input?.igPage?.instagramAccount?.profile_pic || boostIgCampaignImage
                // Fetch image metadata and fix if image is broken
                try {
                    if (campaignImg) await checkImageUrlValidity(campaignImg);
                    // if this doesnt throw, the image is valid and doesnt need a fallback.
                } catch (error) {
                    // if this throws, the image is invalid and needs a fallback.
                    campaignImg = boostIgCampaignImage
                }
                amendedCampaignName = `Boost Post Engagement`
                break;
        }
        // add the profile name if its avail using the igPage
        if (campaign_input?.igPage?.instagramAccount?.username) {
            amendedCampaignName += ` (@${campaign_input?.igPage?.instagramAccount?.username})`
        }
    }

    const formattedDate = getFormattedDateLastUpdate(updatedAt as string);
    const campaignInformation = {
        campaignId: id as number,
        date: formattedDate,
        typeTitle: mappingData?.title || "",
        imageUrl: campaignImg,
        campaignName: getCampaignName({
            campaign_type: campaign_type as string,
            campaignName: amendedCampaignName as string | undefined,
            contentName: contentName as string | undefined
        }),
        campaignType: campaign_type as string,
    };
    return campaignInformation;
}

interface CampaignsProps {
    campaigns: CampaignDetails[] | null;
    campaignsInDraft: CampaignDetails[] | null;
    onDraftDelete: (campaignId: number) => void,
    locallyLoading: boolean,
    addedFilters?: boolean
    loadNextPage: () => void;
    fullyReturned: boolean;
    loadingMore: boolean;
}

const CampaignsSection = ({
    campaigns,
    campaignsInDraft,
    onDraftDelete,
    locallyLoading,
    addedFilters,
    loadNextPage,
    fullyReturned,
    loadingMore
}: CampaignsProps) => {
    const history = useHistory();
    const { isOrganizationReady, hasOrganizationAdminAccess } = useCurrentTeam()
    const { currentBrand, isBrandAdmin } = useContext(CurrentBrandContext)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [draftId, setDraftId] = useState<number>(0);
    const [campaignDetails, setCampaignDetails] = useState<any>(null);
    // saves details on campaign drafts
    const [draftCampaignsInfo, setDraftCampaignsInfo] = useState<CampaignInformation[]>([]);
    useEffect(() => {
        const fetchDraftCampaignsInfo = async () => {
            if (campaignsInDraft) {
                const campaignsInfo = await Promise.all(
                    campaignsInDraft.map(async (campaign: unknown) => {
                        return await getDraftCampaignInformation(campaign);
                    })
                );
                setDraftCampaignsInfo(campaignsInfo.filter(Boolean) as CampaignInformation[]);
            }
        };

        fetchDraftCampaignsInfo();
    }, [campaignsInDraft]);

    const handleFinishCampaign = (campaignType: string, campaignId: number) => {
        if (!CAMPAIGN_TYPE_MAPPINGS[campaignType]) {
            return
        }
        const route = CAMPAIGN_TYPE_MAPPINGS[campaignType].route
        history.push(`${route}?draft=${campaignId}`);
    }

    const handleDeleteDraft = async (campaignId: number) => {
        const drafts = campaignsInDraft as unknown as Draft[] || [] as Draft[]
        const data = drafts.find((campaign: any) => campaign.id === campaignId) as Draft
        const campaignInformation = await getDraftCampaignInformation(data)
        if (!campaignInformation) return
        setShowDeleteModal(true)
        setDraftId(campaignId)
        setCampaignDetails(campaignInformation)
    }

    const handleCloseModal = () => setShowDeleteModal(false)

    const [selectedModalHelper, setSelectedModalHelper] = useState<string | null>(null);
    const [showModalHelper, setShowModalHelper] = useState<boolean>(false);

    useEffect(() => {
        if (selectedModalHelper) {
            setShowModalHelper(true)
        }
    }, [selectedModalHelper])

    const closeModalHelper = () => {
        setShowModalHelper(false)
        setTimeout(() => {
            setSelectedModalHelper(null)
        }, 300)
    }

    const handleMainCampaigns = () => {
        const showLoader = locallyLoading

        if (!campaigns || campaigns.length === 0) {
            return (
                <div className="pb-6">
                    {addedFilters && Boolean(campaigns?.length) && (
                        <div className="my-12 w-full flex justify-center items-center">
                            <p className="text-gray-500 text-center">No campaigns match your filters.</p>
                        </div>
                    )}
                    {!Boolean(campaigns?.length) && !isBrandAdmin && (
                        <div className="my-12 w-full flex justify-center items-center">
                            <p className="text-gray-500 text-center">No campaigns created yet.</p>
                        </div>
                    )}
                    {(isBrandAdmin || (isOrganizationReady && hasOrganizationAdminAccess)) && (
                        <>
                            <div className="w-full mt-0 md:mt-4">
                                <MaestroCampaignRecommender
                                    askMaestroSource="Marketing"
                                    defaultQueryOptions={null}
                                />
                            </div>
                            <p className="mx-3 text-gray-500 mt-6">Or choose an automated playbook:</p>
                            <div>
                                <Grid container spacing={2}>
                                    {CAMPAIGN_CARDS
                                        .sort((a: any, b: any) => {
                                            if (a.underMaintenance && !b.underMaintenance) {
                                                return 1;
                                            }
                                            if (!a.underMaintenance && b.underMaintenance) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                        .map((campaign, index) => {
                                            if (!campaign.underMaintenance) {
                                                return <Grid item key={index} xs={12} sm={6} md={4}>

                                                    <CreateCampaignCard
                                                        requiresPro={campaign.requiresPro}
                                                        link={`/marketing/new/${campaign.link}`}
                                                        onLinkClick={() => {
                                                            trackNewCampaign({
                                                                type: campaign.title,
                                                                from: 'list',
                                                                zeroState: true,
                                                                aiQuestion: null,
                                                                currentBrand: currentBrand,
                                                                key: campaign.id
                                                            })
                                                        }}
                                                        onHelpClick={() => setSelectedModalHelper(campaign.id)}
                                                        backgroundColor={campaign.backgroundColor}
                                                        title={campaign.title}
                                                        description={campaign.description}
                                                        imageUrl={campaign.imageUrl}
                                                        status={campaign.underMaintenance ? "maintenance" : null}
                                                    />
                                                </Grid>
                                            } else {
                                                return <Grid item key={index} xs={12} sm={6} md={4}>
                                                    <CreateCampaignCard
                                                        requiresPro={campaign.requiresPro}
                                                        backgroundColor={campaign.backgroundColor}
                                                        onHelpClick={() => setSelectedModalHelper(campaign.id)}
                                                        title={campaign.title}
                                                        description={campaign.description}
                                                        imageUrl={campaign.imageUrl}
                                                        status={campaign.underMaintenance ? "maintenance" : null}
                                                    />
                                                </Grid>
                                            }
                                        })}
                                </Grid>
                                <HorizontalModalWithVideo
                                    icon={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].icon : ""}
                                    open={showModalHelper}
                                    closeModal={closeModalHelper}
                                    title={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].title : ""}
                                    innerContent={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].helperContent : null}
                                    onClickButton={() => {
                                        if (selectedModalHelper) {
                                            trackNewCampaign({
                                                type: availableGoals[selectedModalHelper].title,
                                                from: 'helper-popup',
                                                zeroState: true,
                                                aiQuestion: null,
                                                currentBrand: currentBrand,
                                                key: selectedModalHelper
                                            })
                                            history.push(`/marketing/new/${availableGoals[selectedModalHelper].link}`)
                                        }
                                    }}
                                    buttonText={"Create Campaign"}
                                    videoUrl={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].walkthroughVideoUrl : ""}
                                />
                            </div>
                        </>
                    )}
                </div>
            )
        } else {
            return (
                <div className="w-full h-full relative">
                    {showLoader && (
                        <div className="absolute top-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <LoaderV2 />
                        </div>
                    )}
                    <div style={{
                        opacity: showLoader ? 0.3 : 1,
                        pointerEvents: showLoader ? 'none' : 'all'
                    }}>
                        {campaigns?.filter((campaign: any) => {
                            const { campaign_metadata } = campaign
                            let hiddenFromMarketingTab = true
                            if (campaign_metadata && campaign_metadata.campaign_type === 'email_fanbase') {
                                hiddenFromMarketingTab = false
                            } else if (campaign_metadata && campaign_metadata.job_details) {
                                // this is a creative generator job - we should hide it
                                hiddenFromMarketingTab = false
                            }

                            return hiddenFromMarketingTab
                        })
                            .map((campaign: any, idx: number) => {
                                return (
                                    <CampaignCard
                                        key={campaign.id}
                                        campaign={campaign}
                                        daysCalculator={daysCalculator}
                                    />
                                )
                            })}
                    </div>
                </div>
            )
        }
    }


    const handleDraftCampaigns = () => {
        if (draftCampaignsInfo.length > 0 && isBrandAdmin) {
            return (
                <>
                {draftCampaignsInfo.map((campaignInformation) => (
                        <CardCampaignDraft
                            {...campaignInformation}
                            deleteDraft={handleDeleteDraft}
                            goToDraft={handleFinishCampaign}
                            key={campaignInformation.campaignId}
                        />
                    ))}
                    {Boolean(campaignDetails) && (
                        <DeleteCampaignModal
                            open={showDeleteModal}
                            draftId={draftId}
                            createdAt={campaignDetails?.date || ''}
                            campaignType={campaignDetails?.campaignType || ''}
                            closeModal={handleCloseModal}
                            onDelete={onDraftDelete}
                            campaignName={campaignDetails?.campaignName || ''}
                            imageUrl={campaignDetails?.imageUrl || ''}
                        />
                    )}
                </>
            );
        }
    }


    const paginationView = () => {
        const totalCampaigns = (campaigns?.length || 0) + (campaignsInDraft?.length || 0)
        if (fullyReturned) return null
        if (totalCampaigns < 15) return null
        return (
            <div className="my-4 flex items-center justify-center">
                <OutlinedButton
                    disabled={loadingMore}
                    onClick={loadNextPage}>{loadingMore ? <LoaderV2 /> : 'Load More'}</OutlinedButton>
            </div>
        )
    }

    return (
        <>
            {handleDraftCampaigns()}
            {handleMainCampaigns()}
            {paginationView()}
        </>
    )
}

export default CampaignsSection