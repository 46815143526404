import { MouseEventHandler } from "react";

interface Props {
  link?: string;
  onClick: () => void;
}

type LabelButtonProps = {
  label: string;
  color: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const LabelButton = ({
  label,
  onClick,
  color,
}: LabelButtonProps) => (
  <div
    {...{ onClick }}
    className="transition ease-in-out delay-150 text-base font-semibold font-display cursor-pointer"
    style={{ color }}
  >
    {label}
  </div>
);

const CopyLinkInputButton = ({
  link,
  onClick,
}: Props) => {
  return (
    <div
      className="
        w-80 h-14 text-sm font-medium mt-2 
        rounded-l-lg rounded-r-lg 
        flex flex-row items-center 
        border-c4c4c4 rounded divide-x
        mx-auto"
    >
      <div
        {...{ onClick }}
        className="max-h-14 w-full overflow-y-scroll 
          py-1 pl-4 pr-2 font-display font-semibold 
          text-base text-justify align-middle break-all"
      >
        {link}
      </div>
      <div className="flex flex-column items-center justify-center ml-1 p-2">
        <LabelButton label="COPY" color="#8800FF" {...{ onClick }} />
      </div>
    </div>
  );
};

export default CopyLinkInputButton;
