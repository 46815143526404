import { CSSProperties, ReactNode } from 'react';
import { clsx, styx } from "modules/Utils";
import Colors from 'modules/Colors';

interface SwitchSymphonyProps {
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    checked?: boolean;
    color?: 'purple' | 'green' | 'red';
    label?: string | ReactNode;
    labelPlacement?: 'left' | 'right' | 'top' | 'bottom';
    labelStyle?: CSSProperties;
    onChange?: (checked: boolean) => void;
    isDarkMode?: boolean;
}

const SwitchSymphony = ({
    className = '',
    style = {},
    disabled = false,
    checked = false,
    color = 'purple',
    label,
    labelPlacement = 'right',
    labelStyle = {},
    onChange,
    isDarkMode = false,
}: SwitchSymphonyProps) => {
    const handleClick = () => {
        if (!disabled && onChange) {
            onChange(!checked);
        }
    };

    const getColor = () => {
        switch (color) {
            case 'purple':
                return Colors.purple;
            case 'green':
                return Colors.green;
            case 'red':
                return Colors.red;
            default:
                return Colors.purple;
        }
    }

    const getBackgroundColor = () => {
        if (disabled) {
            return '#e0e0e0'
        }
        
        if (checked) {
            return getColor() + 'cc'
        }

        if (isDarkMode) {
            return 'rgba(255, 255, 255, 0.3)'
        }

        return 'rgba(0, 0, 0, 0.25)';
    }

    const getContainerDirection = () => {
        switch (labelPlacement) {
            case 'top':
                return 'column-reverse';
            case 'bottom':
                return 'column';
            case 'left':
                return 'row-reverse';
            case 'right':
            default:
                return 'row';
        }
    };

    return (
        <div
            className={clsx('switchSymphonyComponent', className)}
            style={{
                ...sty.switchSymphonyComponent,
                ...style,
                flexDirection: getContainerDirection(),
            }}
        >
            <div
                className="switchSymphonyTrack"
                onClick={handleClick}
                style={{
                    ...sty.track,
                    backgroundColor: getBackgroundColor(),
                    cursor: disabled ? 'not-allowed' : 'pointer',
                }}
            >
                <div
                    className="switchSymphonyThumb"
                    style={{
                        ...sty.thumb,
                        backgroundColor: disabled ? '#bdbdbd' : 'white',
                        transform: checked ? 'translateX(16px)' : 'translateX(0)',
                    }}
                />
            </div>
            {label &&
                <span className="switchSymphonyLabel" style={styx(sty.label, labelStyle)}>
                    {label}
                </span>
            }
        </div>
    );
};

const sty: Record<string, CSSProperties> = {
    switchSymphonyComponent: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: 'fit-content',
        borderRadius: 100,
    },
    track: {
        width: 36,
        height: 20,
        borderRadius: 100,
        position: 'relative',
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    thumb: {
        width: 16,
        height: 16,
        backgroundColor: 'white',
        borderRadius: 50,
        position: 'absolute',
        top: 2,
        left: 2,
        transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    },
    label: {
        userSelect: 'none',
    },
};

export default SwitchSymphony;