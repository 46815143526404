import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      borderRadius: "8px",
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
      },
    },
  },
}));

interface ModalContainerProps {
  width?: string
  padding?: string
};

interface ButtonsContainerProps  {
  flexDirection: string
}

interface ButtonProps {
  width?: string
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 678px;
  width: ${({ width }: ModalContainerProps) => width || '678px'};
  margin: auto;
  padding: ${({ padding }: ModalContainerProps) => padding || '32px'};
  gap: 32px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ButtonsContainerProps) => flexDirection || 'row'};
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const DraftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border: 1px solid #EDECF2;
  border-radius: 8px;
  gap: 24px;
  padding: 8px 16px;
  width: 100%;
`;

export const ImageContainer = styled.div`
  border-radius: 16px;
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
  background-color: rgba(246, 128, 197, 0.29);
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Image = styled.img`
  border-radius: 16px;
  z-index: 100;
  object-fit: cover;
`;

