import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px 24px 16px;
  gap: 16px;
  border-top: 1px solid #EDECF2;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const ImageContainer = styled.div`
  border-radius: 16px;
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
  background-color: rgba(246, 128, 197, 0.29);
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Image = styled.img`
  border-radius: 16px;
  z-index: 100;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 16px;
`;

export const TrashButton = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
