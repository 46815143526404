import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    switchEnabled: {
        "& .MuiSwitch-track": {
            backgroundColor: "#6941C6 !important",
        },
        "& .MuiSwitch-thumb": {
            backgroundColor: "#6941C6",
        },
    },
    banner: {
        backgroundColor: "#F9F5FF",
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
    },
}));