/* eslint-disable react-hooks/exhaustive-deps */
import {
  CSSProperties,
  useContext,
  useEffect,
  useState,
} from "react"
import { Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert } from "@mui/material"
import { pageView } from "analytics"
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner"
import { CurrentBrandContext, } from "Hooks/CurrentBrandContext"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"
import isEqual from "lodash/isEqual"
import lowerCase from "lodash/lowerCase"
import startCase from "lodash/startCase"
import { TabPanel } from "react-headless-tabs"
import { useLocation } from "react-router-dom"
import { getStatusToShowPaymentFailedBanner } from "modules/Utils"
import { ReactComponent as TeamsIcon } from '../../../assets/images/teams.svg'
import AccountTab from "./Components/AccountTab"
import AdvancedSettingsTab from "./Components/AdvancedSettingsTab"
import BillingTab from "./Components/BillingTab"
import UsersTab from "./Components/UsersTab"
import {
  BannerContainer,
  Card,
  StyledLabel,
  StyledNavBar,
  SupportButtonContainer,
  TabsPanelsContainer,
} from "./styles"
import { PageTabs, PageTabsViewOnly } from "./utils"
import { OrganizationSubscriptionStatusBanner } from "components/shareable/OrganizationFailedPayments"
import { Body2, Headline3 } from "components/shareable/Typography"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import Colors from "modules/Colors"
import useScreen from "Hooks/useScreen"
import PageSymphony from "components/shareable/PageSymphony"

type GlobalWindow = Window & {
  Intercom: (type: string) => void
}

const { ACCOUNT, BILLING, USERS, ADVANCED_SETTINGS } = PageTabs

// Create new organization tabs array
const organizationTabs = [
  ACCOUNT,
  ADVANCED_SETTINGS,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const tabs = [
  USERS,
  ACCOUNT,
  BILLING,
  ADVANCED_SETTINGS,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const tabsViewOnly = [
  ACCOUNT,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const SettingsPage = () => {
  const theme = useTheme()
  const { currentBrand, isBrandAdmin } = useContext(CurrentBrandContext)
  const { handleOpenModal } = useContext(UpgradeModalContext);
  const {
    organization,
    brandIsInOrganization
  } = useCurrentTeam()
  const { mStyle } = useScreen()


  const hideSupportButton = useMediaQuery(theme.breakpoints.down(1024))

  const showPaymentFailedBanner =
    getStatusToShowPaymentFailedBanner(currentBrand)
  let defaultTab: PageTabs | PageTabsViewOnly = PageTabsViewOnly.ACCOUNT;

  // used to check if the brand belongs to the organization
  // so we can render either organization-specific View, or regular pro/free view
  const inOrganization = brandIsInOrganization(currentBrand?.slug!)

  if (organization && inOrganization) {
    defaultTab = PageTabs.ACCOUNT;
  } else if (isBrandAdmin) {
    defaultTab = PageTabs.USERS;
  }

  const [materialTabValue, setMaterialTabValue] = useState<PageTabs | PageTabsViewOnly>(defaultTab);
  const { remainingFreeTrial, isOrganizationInactive } = useCurrentTeam()

  const handleClickSupportButton = () => {
    const globalWindow = window as unknown as GlobalWindow
    if (globalWindow.Intercom) globalWindow.Intercom("show")
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = queryParams.get("tab") as PageTabs;

  useEffect(() => {
    if (tabFromQuery && Object.values(PageTabs).includes(tabFromQuery)) {
      setMaterialTabValue(tabFromQuery);
    }
  }, [tabFromQuery]);

  useEffect(() => {
    pageView("Settings");
  }, []);

  const getTabsToRender = (
    brandBelongsToOrganzation: boolean,
    hasAdminAccess: boolean
  ) => {
    if (brandBelongsToOrganzation) {
      return organizationTabs;
    }

    if (hasAdminAccess) {
      return tabs;
    }

    return tabsViewOnly;
  };

  return (
    // <div className="settingsPageComponent" style={mStyle(sty, 'settingsPageComponent')}>
    //   <div
    //     style={{
    //       maxWidth: 1200,
    //       width: '100%',
    //       marginLeft: 'auto',
    //       marginRight: 'auto',
    //     }}
    //   >
    <PageSymphony isContent>
      {(showPaymentFailedBanner || remainingFreeTrial > 0 || isOrganizationInactive) && (
        <BannerContainer>
          {showPaymentFailedBanner && <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />}
          <OrganizationSubscriptionStatusBanner />
        </BannerContainer>
      )}
      <Card padding="40px 24px" borderRadius="8px" marginBottom="16px" marginTop="0px">
        <StyledLabel fontSize={28}>Settings</StyledLabel>
        <StyledNavBar>
          <Tabs
            scrollButtons="auto"
            variant="scrollable"
            value={materialTabValue}
            onChange={(_event, newValue) => setMaterialTabValue(newValue)}
            aria-label="Settings Options Selector"
            TabIndicatorProps={{
              style: { backgroundColor: Colors.purple },
            }}
          >
            {getTabsToRender(inOrganization, isBrandAdmin).map(
              ({ name, value }) => (
                <Tab
                  key={value}
                  disableRipple
                  value={value}
                  label={
                    <span className="capitalize font-medium text-base px-4 py-2">
                      {name}
                    </span>
                  }
                />
              )
            )}
          </Tabs>
          {!hideSupportButton && (
            <SupportButtonContainer>
              <ButtonSymphony
                onClick={handleClickSupportButton}
              >
                Support / Help
              </ButtonSymphony>
            </SupportButtonContainer>
          )}
        </StyledNavBar>
        {!isEqual(materialTabValue, ADVANCED_SETTINGS) && (
          <TabsPanelsContainer>
            <TabPanel hidden={!isEqual(materialTabValue, ACCOUNT)}>
              <AccountTab />
            </TabPanel>
            <TabPanel hidden={!isEqual(materialTabValue, USERS)}>
              {organization?.status !== "ready" && <Alert
                className="w-full"
                icon={<TeamsIcon />}
                sx={{
                  background: `${Colors.purple}14`,
                  borderRadius: "12px",
                  marginBottom: "32px",
                  "& .MuiAlert-message": {
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }
                }}
              >
                <div className="flex flex-col">
                  <Headline3 color={Colors.textColor}>Unlock discounted pricing with Symphony for Teams</Headline3>
                  <Body2 color={Colors.greyDark}>Save money + time with a Team Account</Body2>
                </div>
                <div>
                  <ButtonSymphony
                    variant="outlined"
                    onClick={() => handleOpenModal({
                      source: "Settings Page - Upgrade to Pro Button",
                      tab: "team"
                    })}
                  >
                    Upgrade
                  </ButtonSymphony>
                </div>
              </Alert>}
              <UsersTab
                organization={organization ? organization : undefined}
                brandBelongsToOrganization={inOrganization}
              />
            </TabPanel>
            <TabPanel hidden={!isEqual(materialTabValue, BILLING)}>
              <BillingTab
                brandBelongsToOrganization={inOrganization}
                organization={organization ? organization : undefined}
              />
            </TabPanel>
          </TabsPanelsContainer>
        )}
      </Card>
      {isEqual(materialTabValue, ADVANCED_SETTINGS) &&
        <AdvancedSettingsTab />
      }
    </PageSymphony>
    // </div>
    // </div>
  )
}

const sty: Record<string, CSSProperties> = {
  settingsPageComponent: {
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  settingsPageComponent_mobile: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  }
}

export default SettingsPage
