import {
    useContext,
    useEffect,
    useState,
} from 'react';
import { TextField, Dialog } from '@material-ui/core';
import { toast } from 'react-toastify';
import Axios from 'helpers/Interceptor';
import some from "lodash/some";
import * as Sentry from "@sentry/react";
import { track } from 'analytics';
import { SHARED_TOAST_OPTIONS } from '../utils';
import { ButtonsContainer, InputContainer, ModalContainer, useStyles } from 'components/shareable/Modal/styles';
import { StyledLabel, TextButton } from 'styles/shared';
import UnlockDataBanner from 'components/shareable/UnlockDataBanner';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import useScreen from 'Hooks/useScreen';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import HeaderModal from '../../HeaderModal';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';

interface Props {
    open: boolean;
    currentCampaignName: string;
    originalCampaignName: string;
    closeModal: () => void;
    reload: (campaignName: string) => void
    onErrorHandler?: () => void;
    campaignId: number;
    trackObject?: Record<string, any>;
}

const EditCampaignNameModal = ({
    open,
    currentCampaignName,
    originalCampaignName,
    closeModal,
    reload,
    onErrorHandler,
    campaignId,
    trackObject
}: Props) => {
    const { isProBrand } = useContext(CurrentBrandContext);
    const { mobileView } = useScreen();
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    const [campaignNameError, setCampaignNameError] = useState<boolean>(false)
    const [campaignName, setCampaignName] = useState<string>(currentCampaignName || '')

    useEffect(() => {
        if (campaignName.trim().length > 100) {
            setCampaignNameError(true)
        } else {
            setCampaignNameError(false)
        }
    }, [campaignName])

    useEffect(() => {
        if (!open) {
            // reset the campaign name when the modal is closed
            // after a timeout so that the user doesn't see the name change
            // during the fade
            setTimeout(() => {
                setCampaignName(currentCampaignName || "")
            }, 500)
        }
    }, [open])

    const isValidCampaignName = () => {
        return campaignName.trim() && campaignName.trim().length <= 100;
    }

    const handleCampaignNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCampaignName(event.target.value)
    }

    const resetToOriginalName = () => {
        setCampaignName(originalCampaignName)
    }

    const handleConfirmRenameCampaign = async () => {
        setLoading(true)
        const options = SHARED_TOAST_OPTIONS
        const marginToast = mobileView ? "24px 16px auto 16px" : "40px 22px auto"

        try {
            const cleanedName = campaignName.trim()
            const response = await Axios.put(`campaign/${campaignId}/name`, {
                campaignName: cleanedName
            });
            if (some([!response, !response.data, !response.data.data])) {
                throw new Error("Error renaming campaign");
            }
            reload(cleanedName)
            toast.success('Campaign renamed!', {
                ...options,
                style: {
                    ...options.style,
                    margin: marginToast
                }
            })

            track('Saved Rename Campaign', {
                ...trackObject,
                opened: 'Rename Campaign',
            })
        } catch (e) {
            Sentry.withScope(scope => {
                scope.setExtra("newName", campaignName.trim())
                scope.setExtra("oldName", originalCampaignName)
                scope.setExtra("campaignId", campaignId)
                Sentry.captureException(`Error renaming campaign in campaign ${campaignId}: ${e}`)
            });
            onErrorHandler?.()
        } finally {
            closeModal()
            setLoading(false)
        }
    }

    const renderEditCampaignNameModalBody = () => {
        if (!isProBrand) {
            return (
                <UnlockDataBanner
                    className="unlockDataBannerEditCampaignName"
                    title="Unlock more budget options"
                    description="Upgrade to Pro to unlock more budget options and features."
                />
            )
        }

        return (
            <>
                <InputContainer className="inputContainerEditCampaignName">
                    <TextField
                        className={`textFieldEditCampaignName ${classes.textField}`}
                        InputProps={{
                            classes: {
                                root: classes.inputBorder,
                            }
                        }}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.formHelperText
                            },
                        }}
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={campaignName}
                        onChange={handleCampaignNameChange}
                    />
                    <TextButton onClick={resetToOriginalName}>Reset to Original Name</TextButton>
                    {campaignNameError &&
                        <StyledLabel
                            color="red"
                            fontSize={16}
                        >
                            Please only use a maximum of 100 characters. You currently have {campaignName.length} characters.
                        </StyledLabel>
                    }
                </InputContainer>

                <ButtonsContainer flexDirection={mobileView ? "column-reverse" : "row"} >
                    <ButtonSymphony
                        disabled={loading}
                        onClick={closeModal}
                        width={mobileView ? "100%" : "fit-content"}
                        variant="outlined"
                    >
                        Cancel
                    </ButtonSymphony>
                    <ButtonSymphony
                        disabled={loading || !isValidCampaignName() || campaignName === currentCampaignName || campaignNameError}
                        onClick={handleConfirmRenameCampaign}
                        width={mobileView ? "100%" : "50%"}
                        isLoading={loading}
                        loadingText="Updating campaign..."
                    >
                        Update campaign
                    </ButtonSymphony>
                </ButtonsContainer>
            </>
        )
    }

    return (
        <Dialog
            open={open}
            classes={{
                root: classes.rootDialog,
            }}
        >
            <ModalContainer
                width={mobileView ? "auto" : "678px"}
                padding={mobileView ? "32px 24px" : "32px"}
            >
                <HeaderModal
                    HeaderIcon={EditIcon}
                    closeModal={closeModal}
                    title='Rename Campaign'
                    subtitle='You can rename your campaign to more easily label or identify it. Your campaign name will be shown in the "Marketing" tab and in the campaign details page.'
                />
                {renderEditCampaignNameModalBody()}
            </ModalContainer>
        </Dialog>
    )
}

export default EditCampaignNameModal;
