import { CSSProperties, useContext } from 'react';
import LinkButton from 'components/shareable/LinkButton';
import { Body2, Caption, Subtitle2 } from 'components/shareable/Typography';
import { ConnectionsContext } from 'pages/post-auth/MarketingPage/hooks/ConnectionsContext';
import Colors from "modules/Colors";
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-rounded-icon.svg';
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-bordered-icon.svg';
import { ReactComponent as InstagramGrayIcon } from 'assets/images/instagram-bw.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrowRight.svg';
import { Divider } from '@material-ui/core';
import useScreen from 'Hooks/useScreen';
import { makeStyles } from '@material-ui/core/styles';
import symphonySLogo from 'assets/images/logos/sidebar-collapsed.png';

const useStyles = makeStyles({
  arrowIcon: {
    width: '24px',
    height: '24px',
    '& path': {
      stroke: Colors.greyDark,
    },
  }
});

const { PAPER_BACKGROUND_CONTAINER } = Colors;

interface CampaignConnectionsDetailsProps {
  onEditConnections: () => void;
  expressSetupOrCustomPages: 'custom' | 'express';
}

const EXPRESS_SETUP_INFO: {
  imageUrl: string;
  username: string;
  displayName: string;
} = {
  imageUrl: symphonySLogo,
  displayName: 'Symphony',
  username: 'symphonyforcreators',
}

const CampaignConnectionsDetails = ({
  onEditConnections,
  expressSetupOrCustomPages,
}: CampaignConnectionsDetailsProps) => {
  const classes = useStyles();
  const { mobileView } = useScreen();
  const { loggedInFbUser, facebookAdAccount, facebookPage, instagramPage } = useContext(ConnectionsContext);


  function renderCustomSetupInfo() {
    return (
      <>
        <div className='flex p-2 pr-6 justify-between items-center rounded-lg' style={{ backgroundColor: PAPER_BACKGROUND_CONTAINER }}>
          <div className='flex gap-4 items-center'>
            <div className='flex gap-1 items-center'>
              <FacebookIcon width={24} height={24} />
              <div className='flex gap-1 overflow-hidden items-center'>
                <img className='h-6 w-6 rounded-md' src={facebookPage?.picture.data.url} />
                <Body2
                  className="connectionsNameBody2"
                  style={sty.connectionsName}
                >
                  {facebookPage?.name}
                </Body2>
              </div>
            </div>
            <ArrowIcon
              className={`arrowIcon ${classes.arrowIcon}`}
            />
            {instagramPage ?
              <div className='flex gap-1 items-center'>
                <InstagramIcon width={24} height={24} />
                <div className='flex gap-1 overflow-hidden items-center'>
                  <img className='h-6 w-6 rounded-md' src={instagramPage?.instagramAccount.profile_pic} />
                  <Body2 style={sty.connectionsName}>
                    {instagramPage?.instagramAccount.username}
                  </Body2>
                </div>
              </div>
              :
              <div className='flex gap-1 items-center'>
                <InstagramGrayIcon height={24} width={24} />
                <Caption color={Colors.grey}>No Instagram Page</Caption>
              </div>
            }
          </div>
          <LinkButton text={mobileView ? 'Edit' : 'Edit connections'} onClick={onEditConnections} />
        </div>
        <div className='flex gap-2'>
          <div className='flex gap-1 items-center'>
            <Caption color={Colors.grey}>Fb profile:</Caption>
            <img className='h-4 w-4 rounded-full' src={loggedInFbUser?.picture?.data.url} />
            <Caption color={Colors.textColor}>{loggedInFbUser?.name}</Caption>
          </div>
          <Divider orientation='vertical' flexItem />
          <div className='flex gap-1 items-center'>
            <Caption color={Colors.textColor}>Ad account:</Caption>
            <Caption color={Colors.grey}>{facebookAdAccount?.name}</Caption>
          </div>
        </div>
      </>
    )
  }

  function renderExpressSetupInfo() {
    return (
      <>
        <div className='flex p-2 pr-6 justify-between items-center rounded-lg' style={{ backgroundColor: PAPER_BACKGROUND_CONTAINER }}>
          <div className='flex gap-4 items-center'>
            <div className='flex gap-1 items-center'>
              <div className="flex items-center">
                <FacebookIcon width={24} height={24} />
                <InstagramIcon width={24} height={24} />
              </div>
              <div className='flex gap-1 overflow-hidden items-center'>
                <img className='h-6 w-6 rounded-md' src={EXPRESS_SETUP_INFO.imageUrl} />
                <Body2
                  className="connectionsNameBody2"
                  style={sty.connectionsName}
                >
                  {EXPRESS_SETUP_INFO?.displayName}
                </Body2>
              </div>
            </div>
          </div>
          <LinkButton text={mobileView ? 'Edit' : 'Edit connections'} onClick={onEditConnections} />
        </div>
      </>
    )
  }

  return (
    <div className='campaignConnectionsDetailsComponent flex flex-col gap-2 px-6 py-2 rounded-lg bg-white'>
      <Subtitle2 color={Colors.grey}>Your ad will use these pages:</Subtitle2>
      {expressSetupOrCustomPages === 'custom' ? renderCustomSetupInfo() : renderExpressSetupInfo()}
    </div>
  );
};

const sty = {
  connectionsName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: Colors.textColor,
  } as CSSProperties,
};

export default CampaignConnectionsDetails;