import { useEffect, useState } from 'react';
import useStyles, {
  AutomatedTargetingBanner,
  AutomatedTargetingDescription,
  DropDownContainer,
  DropDownInputContainer,
  DropDownTitle,
  ItemContainer,
  StyledLabel,
  TargetingAgeGenderModalContainer,
  SaveTargetingButton,
  ExitModalButton,
  ErrorBanner,
} from './styles';
import CloseIcon from "components/shareable/CloseIcon"
import { Dialog } from '@material-ui/core';
import DropdownString from 'components/shareable/DropDown/DropDownString';
import DropdownChip from './DropDownChip';
import { ReactComponent as AutomatedIcon } from 'assets/images/automated.svg';
import {
  FB_CAMPAIGNS_AGES,
  FB_CAMPAIGNS_AUTOMATED_AGES,
  FB_CAMPAIGNS_DEFAULT_LOCAES,
  FB_CAMPAIGNS_GENDERS,
} from '../../constants';
import { Body2 } from 'components/shareable/Typography';
import RangeSlider from 'components/shareable/Slider/RangeSlider';
import { LanguageChip } from 'types/global';
import useVisible from 'Hooks/useVisible';
import TooltipBox from 'components/shareable/Tooltip/TooltipBox';
import useScreen from 'Hooks/useScreen';
import SwitchSymphony from 'components/shareable/SwitchSymphony';

interface Props {
  minAge: number;
  setMinAge: (value: number) => void;
  maxAge: number;
  setMaxAge: (value: number) => void;
  gender: string;
  setGender: (value: string) => void;
  locales: Array<LanguageChip>;
  setLocales: (values: Array<LanguageChip>) => void;
  automatedTargeting: boolean;
  setAutomatedTargeting: (value: boolean) => void;
  openTargetingModal: boolean;
  setOpenTargetingModal: (value: boolean) => void;
  handleOnChangeDemographics: (data: {
    minAge: number,
    maxAge: number,
    gender: string,
    locales: Array<LanguageChip>
  }) => void;
  discardChanges: () => void;
  handleClose: () => void;
}

const tooltipMessage = 'Turn off the Automated targeting to edit';

const TargetingAgeGenderModal = ({
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  gender,
  setGender,
  locales,
  setLocales,
  automatedTargeting,
  setAutomatedTargeting,
  openTargetingModal,
  setOpenTargetingModal,
  handleOnChangeDemographics,
  discardChanges,
  handleClose
}: Props) => {
  const { mobileView } = useScreen()

  const [emptyLanguages, setEmptyLanguages] = useState<boolean>(false);

  const { targetContent: targetSlider, isVisible: sliderTooltip, setIsVisible: setSliderTooltip } = useVisible(false);
  const { targetContent: targetGender, isVisible: genderTooltip, setIsVisible: setGenderTooltip } = useVisible(false);
  const { targetContent: targetLanguages, isVisible: languagesTooltip, setIsVisible: setLanguagesTooltip } = useVisible(false);

  const classes = useStyles();

  const handleAutomatedTargeting = () => {
    if (!automatedTargeting) {
      setMinAge(FB_CAMPAIGNS_AUTOMATED_AGES.MIN);
      setMaxAge(FB_CAMPAIGNS_AUTOMATED_AGES.MAX);
      setGender(FB_CAMPAIGNS_GENDERS.ALL);
      setLocales(FB_CAMPAIGNS_DEFAULT_LOCAES);
    }
    setAutomatedTargeting(!automatedTargeting);
  }

  const handleOnCloseModal = () => setOpenTargetingModal(false);

  const handleOnSaveTargeting = () => {
    if (!locales.length) {
      setEmptyLanguages(true);
      return;
    }

    if (emptyLanguages) setEmptyLanguages(false);

    handleOnChangeDemographics({ gender, minAge, maxAge, locales });
    handleOnCloseModal();
  }

  const handleOnClickSilder = () => {
    if (automatedTargeting) {
      setSliderTooltip(true);
    }
  }

  useEffect(() => {
    if (sliderTooltip) {
      setTimeout(() => setSliderTooltip(false), 3000);
      return;
    }

    if (genderTooltip) {
      setTimeout(() => setGenderTooltip(false), 3000);
      return;
    }

    if (languagesTooltip) {
      setTimeout(() => setLanguagesTooltip(false), 3000);
      return;
    }
  }, [sliderTooltip, genderTooltip, languagesTooltip]);

  return (
    <Dialog
      open={openTargetingModal}
      classes={{
        root: classes.rootDialog,
      }}
      onClose={handleClose}
    >
      <ExitModalButton onClick={discardChanges}>
        <CloseIcon />
      </ExitModalButton>
      <TargetingAgeGenderModalContainer gap={mobileView ? '24px' : '40px'} padding={mobileView ? '40px 16px' : '40px 32px'}>
        <ItemContainer
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          gap='8px'
        >
          <StyledLabel
            fontFamily='DIN'
            fontStyle='normal'
            fontWeight={500}
            fontSize={32}
            lineHeight='40px'
            color='#000000'
          >
            Age and gender
          </StyledLabel>
          <Body2>Use Symphony's automated targeting to optimize age, gender and language to lower your costs</Body2>
        </ItemContainer>
        <AutomatedTargetingBanner>
          <AutomatedTargetingDescription>
            <AutomatedIcon style={{ flexShrink: 0, width: 24, height: 24 }} />
            Use Symphony's automated targeting to optimize age, gender and language to lower your costs
          </AutomatedTargetingDescription>
          <SwitchSymphony
            onChange={handleAutomatedTargeting}
            checked={automatedTargeting}
          />
        </AutomatedTargetingBanner>
        <DropDownInputContainer>
          <ItemContainer
            gap='8px'
            width='100%'
            display='flex'
            flexDirection='column'
          >
            <DropDownTitle>Age:</DropDownTitle>
            <DropDownTitle>
              Your ads will target people between the age of {minAge} - {maxAge}.
              {automatedTargeting && <AutomatedIcon style={{ flexShrink: 0, width: 24, height: 24 }} />}
            </DropDownTitle>
            <ItemContainer display='flex' flexDirection='column' alignItems='flex-start' padding='0px'>
              <ItemContainer
                display='flex'
                flexDirection='row'
                alignItems='center'
                padding={mobileView ? '24px 0px 0px' : '40px 0px 0px'}
                gap='12px'
                width='100%'
                onClick={handleOnClickSilder}
                style={{ position: 'relative' }}
              >
                <Body2>{String(FB_CAMPAIGNS_AGES.MIN)}</Body2>
                {sliderTooltip && <TooltipBox
                  reference={targetSlider}
                  top={'80px'}
                  left={'80px'}
                >{tooltipMessage}</TooltipBox>}
                <RangeSlider
                  maxLimit={FB_CAMPAIGNS_AGES.MAX}
                  minLimit={FB_CAMPAIGNS_AGES.MIN}
                  minValue={minAge}
                  maxValue={maxAge}
                  setMinValue={setMinAge}
                  setMaxValue={setMaxAge}
                  disabled={automatedTargeting}
                />
                <Body2>{String(FB_CAMPAIGNS_AGES.MAX)}</Body2>
              </ItemContainer>
            </ItemContainer>
          </ItemContainer>
          <ItemContainer
            gap='8px'
            width='100%'
            display='flex'
            flexDirection='column'
            style={{ position: 'relative' }}
          >
            <DropDownTitle>Gender:</DropDownTitle>
            <DropDownContainer>
              {genderTooltip && <TooltipBox
                reference={targetGender}
                top={'80px'}
                left={'80px'}
              >{tooltipMessage}</TooltipBox>}
              <DropdownString
                value={gender}
                items={Object.values(FB_CAMPAIGNS_GENDERS)}
                setSelectedValue={setGender}
                disabled={automatedTargeting}
                setWarning={setGenderTooltip}
              />
            </DropDownContainer>
          </ItemContainer>
          <ItemContainer
            gap='8px'
            width='100%'
            display='flex'
            flexDirection='column'
            style={{ position: 'relative' }}
          >
            <DropDownTitle>Languages:</DropDownTitle>
            {languagesTooltip &&
              <TooltipBox
                reference={targetLanguages}
                top={'80px'}
                left={'80px'}
              >
                {tooltipMessage}
              </TooltipBox>
            }
            <DropDownContainer>
              <DropdownChip
                setWarning={setLanguagesTooltip}
                locales={locales}
                disabled={automatedTargeting}
                setLocales={setLocales}
              />
            </DropDownContainer>
          </ItemContainer>
          {emptyLanguages &&
            <ErrorBanner>
              Select at least one language
            </ErrorBanner>
          }
        </DropDownInputContainer>
        <SaveTargetingButton onClick={handleOnSaveTargeting}>Set age, gender & language</SaveTargetingButton>
      </TargetingAgeGenderModalContainer>
    </Dialog>
  )
}

export default TargetingAgeGenderModal;