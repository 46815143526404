import { useEffect, useState } from 'react';
import useStyles, {
  TargetingDemographicsModalContainer,
  TargetDemographicsContainer,
  ItemContainer,
  TargetingDemograpgicsButtonsContainer,
  EditTargetingDemographicsButton,
  StyledLabel
} from './styles';
import { Chip, Switch } from '@material-ui/core';
import { ReactComponent as AutomatedIcon } from 'assets/images/automated.svg';
import TargetingAgeGenderModal from './TargetingAgeGenderModal';
import {
  FB_CAMPAIGNS_AUTOMATED_AGES,
  FB_CAMPAIGNS_DEFAULT_LOCAES,
  FB_CAMPAIGNS_GENDERS,
} from '../../constants';
import { LanguageChip } from 'types/global';
import useScreen from 'Hooks/useScreen';
import SwitchSymphony from 'components/shareable/SwitchSymphony';

type DemographicData = {
  minAge: number,
  maxAge: number,
  gender: string,
  locales: Array<LanguageChip>
}

interface Props {
  age: { min: number, max: number };
  gender: string;
  handleOnChangeDemographics: (data: DemographicData) => void;
  languages: Array<LanguageChip>;
}

let previousState: DemographicData | null = null;

const TargetingDemographics = ({
  age,
  gender: genderProp,
  handleOnChangeDemographics,
  languages,
}: Props) => {
  const classes = useStyles();

  const [automatedTargeting, setAutomatedTargeting] = useState(true);
  const [openTargetingModal, setOpenTargetingModal] = useState(false);
  const [minAge, setMinAge] = useState(age?.min || FB_CAMPAIGNS_AUTOMATED_AGES.MIN);
  const [maxAge, setMaxAge] = useState(age?.max || FB_CAMPAIGNS_AUTOMATED_AGES.MAX);
  const [gender, setGender] = useState(genderProp || FB_CAMPAIGNS_GENDERS.ALL);
  const [locales, setLocales] = useState(languages || FB_CAMPAIGNS_DEFAULT_LOCAES);

  const handleClose = () => setOpenTargetingModal(false);

  const handleOnSaveDemographics = (data: DemographicData) => handleOnChangeDemographics(data);

  const handleAutomatedTargeting = () => {
    if (!automatedTargeting) {
      setMinAge(FB_CAMPAIGNS_AUTOMATED_AGES.MIN)
      setMaxAge(FB_CAMPAIGNS_AUTOMATED_AGES.MAX)
      setGender(FB_CAMPAIGNS_GENDERS.ALL)
      setLocales(FB_CAMPAIGNS_DEFAULT_LOCAES)

      handleOnSaveDemographics({
        minAge: FB_CAMPAIGNS_AUTOMATED_AGES.MIN,
        maxAge: FB_CAMPAIGNS_AUTOMATED_AGES.MAX,
        gender: FB_CAMPAIGNS_GENDERS.ALL,
        locales: FB_CAMPAIGNS_DEFAULT_LOCAES
      });
    }
    setAutomatedTargeting(!automatedTargeting);
  }

  const handleOpenTargetingModal = () => {
    previousState = { minAge, maxAge, gender, locales };
    setOpenTargetingModal(true);
  }

  const discardChanges = () => {
    if (previousState && !automatedTargeting) {
      setMinAge(previousState.minAge);
      setMaxAge(previousState.maxAge);
      setGender(previousState.gender);
      setLocales(previousState.locales);
    } else if (automatedTargeting) {
      handleOnSaveDemographics({
        minAge: FB_CAMPAIGNS_AUTOMATED_AGES.MIN,
        maxAge: FB_CAMPAIGNS_AUTOMATED_AGES.MAX,
        gender: FB_CAMPAIGNS_GENDERS.ALL,
        locales: FB_CAMPAIGNS_DEFAULT_LOCAES
      });
    }
    setOpenTargetingModal(false);
  }

  const checkAutomatedTargeting = (data: {
    minAge: number,
    maxAge: number,
    gender: string,
    locales: Array<LanguageChip>
  }) => {
    const { gender, locales, maxAge, minAge } = data;
    const automatedAge = (maxAge === FB_CAMPAIGNS_AUTOMATED_AGES.MAX) && (minAge === FB_CAMPAIGNS_AUTOMATED_AGES.MIN);
    const automatedGender = gender === FB_CAMPAIGNS_GENDERS.ALL;
    const automatedLocales = locales.length === FB_CAMPAIGNS_DEFAULT_LOCAES.length && locales.every((v, i) => v.key === FB_CAMPAIGNS_DEFAULT_LOCAES[i].key);
    if (!(automatedAge && automatedGender && automatedLocales)) {
      setAutomatedTargeting(false);
    }
  }

  useEffect(() => {
    checkAutomatedTargeting({ gender, locales, maxAge, minAge })
  }, []);

  useEffect(() => {
    if (minAge === maxAge) {
      setMaxAge(maxAge + 1);
    }
  }, [minAge, maxAge]);

  useEffect(() => {
    setMinAge(age.min);
    setMaxAge(age.max);
    setGender(genderProp);
    setLocales(languages);
    checkAutomatedTargeting({ gender: genderProp, locales: languages, maxAge: age.max, minAge: age.min })
  }, [genderProp, languages, age])

  return (
    <>
      <TargetingDemographicsModalContainer>
        <StyledLabel
          fontFamily='DIN'
          fontSize={16}
          fontWeight={600}
          color="#000000"
          fontStyle='normal'
          lineHeight='110%'
        >
          Age, Gender and Languages
        </StyledLabel>
        <StyledLabel
          fontFamily='DIN'
          fontSize={16}
          fontWeight={400}
          color="#707070"
          fontStyle='normal'
          lineHeight='19px'
        >
          Use Symphony's automated targeting to find the best performing audiences, or customize your targeting.
        </StyledLabel>
        <TargetDemographicsContainer backgroundColor={automatedTargeting ? '#8800FF14' : '#FFFFFF'}>
          <ItemContainer
            display='flex'
            flexFlow='row wrap'
            overflowWrap='break-word'
            alignItems='center'
            padding='0px'
            gap='8px'
          >
            <Chip
              style={{
                backgroundColor: "rgba(136, 0, 255, 0.08)",
              }}
              className={classes.chip}
              classes={{
                label: classes.chipLabelColor,
                colorPrimary: classes.chipBackgroundColor,
              }}
              color="primary"
              label={`Age: ${minAge} to ${maxAge}`}
            />
            <Chip
              style={{
                backgroundColor: "rgba(136, 0, 255, 0.08)",
              }}
              className={classes.chip}
              classes={{
                label: classes.chipLabelColor,
                colorPrimary: classes.chipBackgroundColor,
              }}
              color="primary"
              label={`Gender: ${gender}`}
            />
            {
              locales && locales.map((locale, index) => {
                return <Chip
                  style={{
                    backgroundColor: "rgba(136, 0, 255, 0.08)",
                  }}
                  key={`${locale.name}-${index}`}
                  className={classes.chip}
                  classes={{
                    label: classes.chipLabelColor,
                    colorPrimary: classes.chipBackgroundColor,
                  }}
                  color="primary"
                  label={locale.name}
                />
              })
            }
          </ItemContainer>
          <TargetingDemograpgicsButtonsContainer>
            <EditTargetingDemographicsButton onClick={handleOpenTargetingModal}>
              Edit
            </EditTargetingDemographicsButton>
            <ItemContainer
              display='flex'
              flexDirection='row'
              alignItems='center'
              padding='0px'
              gap='8px'
            >
              <ItemContainer
                display='flex'
                flexDirection='row'
                alignItems='center'
                padding='0px'
                gap='8px'
              >
                <AutomatedIcon />
                {`Automated targeting: ${automatedTargeting ? 'On' : 'Off'}`}
              </ItemContainer>
              <SwitchSymphony
                onChange={handleAutomatedTargeting}
                checked={automatedTargeting}
              />
            </ItemContainer>
          </TargetingDemograpgicsButtonsContainer>
        </TargetDemographicsContainer>
      </TargetingDemographicsModalContainer>
      {openTargetingModal && <TargetingAgeGenderModal
        minAge={minAge}
        setMinAge={setMinAge}
        maxAge={maxAge}
        setMaxAge={setMaxAge}
        gender={gender}
        setGender={setGender}
        locales={locales}
        setLocales={setLocales}
        automatedTargeting={automatedTargeting}
        setAutomatedTargeting={setAutomatedTargeting}
        openTargetingModal={openTargetingModal}
        setOpenTargetingModal={setOpenTargetingModal}
        discardChanges={discardChanges}
        handleOnChangeDemographics={handleOnChangeDemographics}
        handleClose={handleClose}
      />}
    </>
  );
};

export default TargetingDemographics;