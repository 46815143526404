import { useContext, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SymphonyDefaultAnnotations from "components/shareable/SymphonyDefaultAnnotations";
import SymphonyDefaultAnnotationsModal from "components/shareable/SymphonyDefaultAnnotationsModal";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import dayjs from "dayjs";
import LegendCampaignAnnotations from "components/shareable/LegendCampaignAnnotations";
import { hasAnnotations } from "modules/Utils";

interface DatasetConfig {
    key: string;
    name: string;
    color: string;
    showIfPositive?: boolean;
}

interface SLineChartProps {
    data: {
        [key: string]: number | string;
    }[];
    datasets: DatasetConfig[];
    xAxisKey: string;
    startDate?: string;
    endDate?: string;
    showAnnotations?: any;
}

export function SLineChart({
    data,
    datasets,
    xAxisKey,
    startDate,
    endDate,
    showAnnotations = {}
}: SLineChartProps) {
    const filteredDatasets = datasets.filter(dataset =>
        !dataset.showIfPositive || data.some(item => (item[dataset.key] as number) > 0)
    );

    const { currentBrand } = useContext(CurrentBrandContext);

    const [showGridModal, setShowGridModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedIgPost, setSelectedIgPost] = useState(null);
    const [selectedSpotifyRelease, setSelectedSpotifyRelease] = useState(null);
    const [itemsArray, setItemsArray] = useState<any[]>([]);

    return (
        <div className="symphonyLineChartComponentContainer" style={{ position: 'relative', width: '100%' }}>
            <SymphonyDefaultAnnotationsModal
                itemsArray={itemsArray}
                showGridModal={showGridModal}
                setShowGridModal={setShowGridModal}
                selectedCampaign={selectedCampaign}
                selectedIgPost={selectedIgPost}
                selectedSpotifyRelease={selectedSpotifyRelease}
                setSelectedCampaign={setSelectedCampaign}
                setSelectedIgPost={setSelectedIgPost}
                setSelectedSpotifyRelease={setSelectedSpotifyRelease}
            />
            {hasAnnotations(showAnnotations) && <LegendCampaignAnnotations />}
            <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
                <LineChart
                    width={500}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dy={12} dataKey={xAxisKey} tickFormatter={(value) => dayjs(value).format('MM/DD')} />
                    <YAxis domain={['auto', 'auto']} />
                    <Legend verticalAlign="top" height={36} />
                    <Tooltip />
                    {filteredDatasets.map((dataset) => (
                        <Line
                            key={dataset.key}
                            type="monotone"
                            dataKey={dataset.key}
                            name={dataset.name}
                            stroke={dataset.color}
                            fill="#d9ffd9"
                            fillOpacity="0.8"
                            activeDot={{ r: 8 }}
                        />
                    ))}
                    {SymphonyDefaultAnnotations({
                        currentBrand: currentBrand!,
                        startDate: startDate!,
                        endDate: endDate!,
                        showAnnotations,
                        setShowGridModal,
                        setSelectedCampaign,
                        setSelectedIgPost,
                        setSelectedSpotifyRelease,
                        setItemsArray
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}