import { Fragment, ReactElement, useContext } from "react"
import { ConversionsTask } from "types/global"
import ConversionsConfigStep from "../../../ConversionsConfigStep"
import StyledTextField from "../../Components/StyledTextField"
import { commonCardsProps, Steps } from "../../utils"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { FlexContainer, InstructionsCard, LinkContainer, NumberedItem, PixelContainer, useStyles } from "./styles"
import Colors from "modules/Colors"
import { Body1, Body2, Caption, Subtitle2 } from "components/shareable/Typography"
import useScreen from "Hooks/useScreen"
import LinkButton from "components/shareable/LinkButton"
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg"
import { Data } from "../../Hooks/useCommonStates"
import { ReactComponent as PixelIcon } from "assets/images/facebookPixel.svg"
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { useSharedStyles } from "../styles"

const primaryTextColor = Colors.textColor

export const instructionsLabels: {
  label: ReactElement
  buttonLabel?: string
}[] = [
    {
      label: <Body2 color={primaryTextColor}>Click this link to open your&nbsp;</Body2>,
      buttonLabel: "Facebook Pixel Settings",
    },
    {
      label: (
        <FlexContainer flexDirection="column">
          <Body2 color={primaryTextColor}>
            Next to your pixel’s name, you should see a button titled <Subtitle2>“Add assets”</Subtitle2>. Click that button.
          </Body2>
          <PixelContainer>
            <PixelIcon />
            <Caption color={Colors.PAPER_DARK_CONTAINER}>Add assets</Caption>
          </PixelContainer>
        </FlexContainer>
      ),
    },
    {
      label: (
        <Body2 color={primaryTextColor}>
          You’ll see a list of your ad accounts pop up. Search for your ad account and select it, and click
          <Subtitle2>“Add”</Subtitle2> or <Subtitle2>“Save”</Subtitle2>.
        </Body2>
      ),
    },
    {
      label: (
        <Body2 color={primaryTextColor}>
          Confirm your ad accounts are connected by clicking "Connected Assets"on the same page. Once that's done, come back to Symphony and click “Verify Connection” to proceed.
        </Body2>
      ),
    },
  ]

interface Props {
  facebookPixel: Data
  facebookAdAccount: Data
  fbPixelConnectModal: {
    openModal: () => void
  }
  onClickConnectButton: () => void
  onHover: () => void
  onClickWatchVideoButton: () => void
  selectedFBPixelId?: string,
  onVerifyConnection: () => void
  conversionsEnabled: boolean
  conversionsError: boolean
}

export const PixelInstructionsSteps = (props: {
  onClickWatchVideoButton?: () => void
  handleClickGoToPixelSettingsPage: () => void
}) => {
  const { onClickWatchVideoButton, handleClickGoToPixelSettingsPage } = props
  const classes = useStyles()
  const sharedClasses = useSharedStyles()
  const { mobileView } = useScreen()

  return (
    <Fragment>
      <Body1 className="mt-4" color={Colors.textColor}>Before we proceed, you'll need to connect your Pixel to your Ad Account. Just follow the steps below.</Body1>
      <FlexContainer key="step">
        <LinkButton
          leftIcon={
            <PlayIcon className={sharedClasses.playIcon} display="inline" />
          }
          text="Watch a walkthrough"
          onClick={onClickWatchVideoButton}
          textAlign="left"
        />
      </FlexContainer>
      <InstructionsCard>
        {instructionsLabels.map(({ label, buttonLabel }, index) => {
          return (
            <FlexContainer key={`step-${index}`}>
              <NumberedItem>
                <Subtitle2 color={Colors.purple}>
                  {index + 1}
                </Subtitle2>
              </NumberedItem>
              <LinkContainer>
                {label}
                {buttonLabel && (
                  <LinkButton
                    text={buttonLabel}
                    onClick={handleClickGoToPixelSettingsPage}
                    rightIcon={<ExternalLinkIcon className={classes.externalLinkIcon} />}
                    textAlign={mobileView ? "left" : "center"}
                    showInline
                  />
                )}
              </LinkContainer>
            </FlexContainer>
          )
        })}
      </InstructionsCard>
    </Fragment>
  )
}

const StepFacebookPixel = ({
  facebookPixel,
  facebookAdAccount,
  onClickConnectButton,
  onHover,
  onClickWatchVideoButton,
  selectedFBPixelId,
  onVerifyConnection,
  conversionsEnabled,
  conversionsError,
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const { conversionsStatus, connections } = currentBrand || {}
  const { facebook_pixel: brandFBPixel, facebook_ad_account: fbAdAccount } = connections || {}
  const { business } = fbAdAccount || {}
  const { conversions_tasks: conversionTasks } = conversionsStatus || {}

  const isConnected = Boolean(brandFBPixel?.id) && !brandFBPixel?.is_unavailable
  const isDisabled =
    !conversionsError && (
      !conversionTasks?.includes(ConversionsTask.AD_ACCOUNT_CONNECTED) ||
      !fbAdAccount ||
      facebookAdAccount.error ||
      facebookPixel.loading ||
      conversionsEnabled
    )

  const handleClickGoToPixelSettingsPage = () => {
    window.open(
      `https://business.facebook.com/settings/events-dataset-news/${selectedFBPixelId}?business_id=${business?.id}`,
      "_blank"
    )
  }

  return (
    <ConversionsConfigStep
      {...commonCardsProps[Steps.FACEBOOK_PIXEL]}
      disabled={isDisabled}
      showErrorView
      onClickErrorButton={onVerifyConnection}
      isSuccessful={!facebookPixel.error}
      isCompleted={Boolean(brandFBPixel?.id) && !brandFBPixel?.is_unavailable && !facebookPixel.error}
      onHover={onHover}
      onClickIntercomButton={onClickWatchVideoButton}
    >
      <Fragment>
        <StyledTextField
          readOnly
          id={selectedFBPixelId}
          warning={facebookPixel.error}
          connected={isConnected}
          disabled={isDisabled}
          loading={facebookPixel.loading}
          value={facebookPixel.stepValue || "Facebook Pixel"}
          onClickConnectButton={onClickConnectButton}
          stepName={Steps.FACEBOOK_PIXEL}
        />
        {facebookPixel.error && (
          <PixelInstructionsSteps
            onClickWatchVideoButton={onClickWatchVideoButton}
            handleClickGoToPixelSettingsPage={handleClickGoToPixelSettingsPage}
          />
        )}
      </Fragment>
    </ConversionsConfigStep>
  )
}

export default StepFacebookPixel
