import {
  useState,
  createContext,
  createElement,
  ReactNode,
} from "react";
import {
  StyleTypes,
  BackgroundTypes,
  PreSaveErrorTypes as ErrorTypes,
  TitleAlignments,
} from "types/global";
import moment from "moment";
import isEmpty from "lodash/isEmpty"

export interface Details {
  releaseUrl: string;
  releaseUrlValid: boolean;
  releaseDate: string | null;
  musicPlatforms: { name: string; show: boolean }[];
  timezone: {
    value: string;
    abbrev: string;
  };
  boosts: {
    collectEmails: boolean;
    boostFollows: boolean;
    autoPresaves: boolean;
  };
  localRelease: boolean;
  hideReleaseDate: boolean;
}

export interface Customize {
  artwork: string | null;
  releaseTitle: string;
  styleType: StyleTypes;
  backgroundType: BackgroundTypes;
  primaryColor: string;
  buttonColor: string;
  sendFansToCustomWebPage: boolean;
  customWebPageUrl: string;
  backgroundColor: string;
  subtitle: string;
  showSecondaryLine: boolean;
  titleAlignment: TitleAlignments;
}

interface Context {
  details: Details;
  customize: Customize;
  showPostCreation: boolean;
  loading: boolean;
  postCreationErrorType: ErrorTypes | null;
  setPostCreationErrorType: (errorType: ErrorTypes | null) => void;
  saveDetails: (details: Details) => void;
  saveCustomize: (customize: Customize) => void;
  setShowPostCreation: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  dataComplete: () => boolean
}

export const PreSaveDataContext = createContext<Context>({
  details: {
    releaseUrl: "",
    releaseUrlValid: false,
    releaseDate: null,
    musicPlatforms: [],
    boosts: {
      collectEmails: true,
      boostFollows: true,
      autoPresaves: true,
    },
    timezone: { value: "", abbrev: "" },
    localRelease: false,
    hideReleaseDate: false,
  },
  customize: {
    artwork: null,
    releaseTitle: "",
    styleType: StyleTypes.BOXES,
    backgroundType: BackgroundTypes.ARTWORK,
    primaryColor: "",
    buttonColor: "",
    sendFansToCustomWebPage: false,
    customWebPageUrl: "",
    backgroundColor: "",
    subtitle: "",
    showSecondaryLine: false,
    titleAlignment: TitleAlignments.CENTER,
  },
  showPostCreation: false,
  loading: true,
  postCreationErrorType: null,
  saveDetails: () => {},
  saveCustomize: () => {},
  setShowPostCreation: () => {},
  setLoading: () => {},
  setPostCreationErrorType: () => {},
  dataComplete: () => false,
});

type Props = {
  children: ReactNode[] | ReactNode;
};

const PreSaveProvider = ({ children }: Props) => {
  const [details, saveDetails] = useState<Details>({} as Details);
  const [customize, saveCustomize] = useState<Customize>({} as Customize);
  const [showPostCreation, setShowPostCreation] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [postCreationErrorType, setPostCreationErrorType] =
    useState<ErrorTypes | null>(null);

  const onSaveDetails = (details: Details) => saveDetails(details);
  const onSaveCustomize = (customize: Customize) => saveCustomize(customize);
  const onSetShowPostCreation = (value: boolean) => setShowPostCreation(value);
  const onSetLoading = (value: boolean) => setLoading(value);
  const onSetPostCreationErrorType = (errorType: ErrorTypes | null) =>
    setPostCreationErrorType(errorType);

  function returnDataComplete() {
    // confirm all details avail
    return Boolean(
      (isEmpty(details.releaseUrl) || details.releaseUrlValid) &&
      customize.releaseTitle &&
      details.releaseDate &&
      moment(details.releaseDate).isValid() &&
      details.musicPlatforms.length > 0 &&
      details.timezone.value &&
      customize.styleType &&
      customize.backgroundType
    )
  }

  const context = {
    details,
    customize,
    showPostCreation,
    loading,
    postCreationErrorType,
    saveDetails: onSaveDetails,
    saveCustomize: onSaveCustomize,
    setShowPostCreation: onSetShowPostCreation,
    setLoading: onSetLoading,
    setPostCreationErrorType: onSetPostCreationErrorType,
    dataComplete: returnDataComplete
  };

  return createElement(
    PreSaveDataContext.Provider,
    { value: context },
    children
  );
};

export default PreSaveProvider;
