import Colors from "modules/Colors"
import { Theme, makeStyles } from "@material-ui/core"
import styled from "styled-components"

interface UseStylesProps {
  id?: string
  error?: boolean
  warning?: boolean
  connected?: boolean
  value?: string
  initialValue?: string | null
  showButtonLabelAsSaved?: boolean
  endAdornment?: boolean
  customButtonLabel?: string
}

const { PAPER_WARNING_CONTAINER, PAPER_ERROR_CONTAINER } = Colors

//TODO: Add these colors in Colors object when merged main to release branch
const getInputBackgroundColor = ({ error, warning }: UseStylesProps) =>
  warning ? PAPER_WARNING_CONTAINER : error ? PAPER_ERROR_CONTAINER : "rgba(237, 236, 242, 0.48)"
const getInputBorderColor = ({ error, warning }: UseStylesProps) =>
  warning ? Colors.yellow : error ? Colors.red : Colors.purple
const getButtonTextColor = ({
  initialValue,
  connected,
  value,
  error,
  showButtonLabelAsSaved,
  customButtonLabel,
}: UseStylesProps) => {
  if (customButtonLabel) return Colors.purple

  if (showButtonLabelAsSaved) {
    const valueLength = value?.trim().length

    if (connected && initialValue !== value && valueLength) {
      return Colors.purple
    }

    if ((connected || !valueLength) && !error) {
      return Colors.greyDark
    }

    return Colors.purple
  }

  if (connected && !error) {
    return Colors.greyDark
  }

  return Colors.purple
}

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    "& .MuiInputBase-root": {
      height: "fit-content",
      padding: "8px 16px",
      overflow: "hidden",
      justifyContent: ({ endAdornment }: UseStylesProps) => endAdornment ? "space-between" : "flex-start",
      backgroundColor: getInputBackgroundColor,
    },
    "& fieldset": {
      height: "54px !important",
      borderRadius: "8px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px !important",
      "& fieldset": {
        borderColor: "transparent !important",
      },
      "&:hover fieldset": {
        borderColor: "transparent !important",
      },
      "&:disabled fieldset": {
        borderColor: "transparent !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: getInputBorderColor,
      },
      "& .MuiInputBase-input": {
        width: ({ endAdornment, id }: UseStylesProps) => !id || !endAdornment ? "100%" : "0px",
      },
      "& .MuiInputAdornment-positionStart": {
        width: ({ endAdornment, id }: UseStylesProps) => !id || !endAdornment ? "fit-content" : "calc(100% - 140px)",
      },
    },
  },
  input: {
    fontFamily: "DIN !important",
    "&::placeholder": {
      fontWeight: 400,
      fontSize: "16px !important",
      fontFamily: "DIN !important",
    },
  },
  button: {
    color: getButtonTextColor,
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "DIN",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiButton-label": {
      display: "flex !important",
      justifyContent: "flex-end !important",
      gap: "8px !important",
    },
    "&:disabled": {
      backgroundColor: 'transparent !important',
    },
  },
}))

export const IconContainer = styled.div`
  border-radius: 8px;
  padding: 4px;
  background-color: #1977f314;
`

export const BrandIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`

export const InputAdornmentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  overflow: hidden;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export default useStyles
