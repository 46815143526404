import { CurrentBrand, OrganizationData } from "types/global";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "helpers/Interceptor";
import ConfirmChangeSubscriptionModal from "./ConfirmChangeSubscriptionModal";
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import { toast } from "react-toastify";
import AddNewArtistModal from "../Components/AddNewArtistModal/AddNewArtistModal";
import TeamTabSection from "../TeamTabSection";
import { GridColDef } from "@mui/x-data-grid";
import { Body2, Caption } from "components/shareable/Typography";
import { OrganizationViewDefaultGrid } from "../Components/OrganizationViewDefaultGrid";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import millify from "millify";
import BrandsActionMenu from "./BrandsActionMenu";
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { isOrganizationAdminOrOwner } from "modules/Utils";
import Colors from "modules/Colors";
import { addMultipleBrandsToOrganization, deleteOrganizationBrand, downgradeToFreeInOrganization, upgradeToProInOrganization } from "services/symphonyApi/organizationService";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import SearchSelector from "components/ProfileDropdown/SearchSelector";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import DeleteBrandConfirmationModal from "./DeleteBrandConfirmationModal";
import ButtonSymphony from "components/shareable/ButtonSymphony";

export interface DisplayedBrand {
    name: string;
    image: string;
    slug: string;
    isPro: boolean;
    isLoading: boolean;
    id: number;
    fanbaseSize: number;
    totalCampaigns: number;
}

const PAGE_SIZE = 8;

const OrganizationProfiles = ({
    existingBrands,
    organization,
    reloadOrganization,
    reloadBrands,
}: {
    existingBrands: CurrentBrand[];
    organization: OrganizationData;
    reloadOrganization: () => void;
    reloadBrands: () => void;
}) => {
    const history = useHistory();
    const { teamArtists, getTeamArtists, isLoadingTeamArtists } = useCurrentTeam();
    const { spoofedUserId, isSymphonyAdmin, currentUser } = useContext(CurrentUserContext);
    const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);

    const isAdminOrOwner = isSymphonyAdmin || spoofedUserId === currentUser?.id || isOrganizationAdminOrOwner(currentUser)
    // total allocated seats - by default organization plans come with 5.
    // any additional seats are charged at $15 per profile.
    const [proAccounts, setProAccounts] = useState<number>(0)
    // const defaultBrands = defaultDisplayedBrands()
    const [displayedBrands, setDisplayedBrands] = useState<DisplayedBrand[]>([])

    // used for search
    const [searchValue, setSearchValue] = useState<string>('')
    const [handlingDelete, setHandlingDelete] = useState(false);

    const [brandName, setBrandName] = useState<string>('')
    const location = useLocation();
    const [slugParam, setSlugParam] = useState<string | null>(new URLSearchParams(location.search).get('slug'));

    // Used for Admins - Invite from Admin Search popup
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openAdminSelector, setOpenAdminSelector] = useState<boolean>(false)
    const handleCloseAdminSelectorDropdown = () => {
        setAnchorEl(null);
        setOpenAdminSelector(false)
    }


    // upgrade modal
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false)
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<DisplayedBrand | null>(null);
    const [inProgressUpdatingBrandSlug, setInProgressUpdatingBrandSlug] = useState<{
        slug: string;
        status: 'upgrade' | 'downgrade'
    } | null>(null)

    // Selected Artist State
    const [page, setPage] = useState<number>(0);
    const [totalProfiles, setTotalProfiles] = useState<number>(0);

    const defaultDisplayedBrands = useCallback(() => {
        if (teamArtists.length && existingBrands.length) {
            let proCount = 0;
            const brands = teamArtists.map((artist) => {
                if (artist?.isPro) {
                    proCount++
                }

                return {
                    name: artist.name,
                    image: artist.profile_picture,
                    slug: artist.slug,
                    isPro: artist.isPro,
                    isLoading: false,
                    fanbaseSize: artist.fanbaseSize || 0,
                    totalCampaigns: artist.totalCampaigns || 0,
                    id: artist.id
                }
            });
            setProAccounts(proCount);
            setTotalProfiles(brands.length);
            return brands;
        }
        return [];
    }, [existingBrands, teamArtists]);


    useEffect(() => {
        if (searchValue) {
            const result = defaultDisplayedBrands().filter(item =>
                item.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setDisplayedBrands(result);
            setTotalProfiles(result.length);
        } else {
            setDisplayedBrands(defaultDisplayedBrands());
        }
    }, [searchValue])

    useEffect(() => {
        // when this slug is updated, we should open the modal
        if (inProgressUpdatingBrandSlug) {
            const selectedBrand = displayedBrands.find(brand => brand.slug === inProgressUpdatingBrandSlug.slug)
            if (selectedBrand) {
                setBrandName(selectedBrand.name)
            }
            setShowUpgradeModal(true)
        }
    }, [inProgressUpdatingBrandSlug])

    useEffect(() => {
        if (!showUpgradeModal) {
            setInProgressUpdatingBrandSlug(null)
        }
    }, [showUpgradeModal])

    useEffect(() => {
        if (teamArtists.length && existingBrands.length) {
            const data = defaultDisplayedBrands()
            setDisplayedBrands(data)
        }
    }, [existingBrands, teamArtists])

    useEffect(() => {
        if (slugParam && displayedBrands.length) {
            beginUpgradeToPro(slugParam)
            setSlugParam(null)
        }
    }, [slugParam, displayedBrands])

    /**
     * For Admins only:
     * Handles importing multiple brands into an organization at once.
     * Takes the imported brands array, extracts valid slugs, and adds them to the organization.
     * Cleans up state and closes modal on success.
     */
    async function adminViewImportBrands(brands: CurrentBrand[]) {
        if (brands.length) {
            const slugs = brands.map(brand => brand.slug).filter((slug): slug is string => slug !== null)
            try {
                toast.info("Adding profiles... This may take a few seconds.", SHARED_TOAST_OPTIONS)
                const addedBrands = await addMultipleBrandsToOrganization(slugs, true, { spoofedUserId: spoofedUserId ?? undefined })
                if (addedBrands) {
                    await getTeamArtists()
                    handleCloseAdminSelectorDropdown()
                    toast.success("Profiles successfully added.", SHARED_TOAST_OPTIONS)

                }
            } catch (error) {
                console.error(error)
                toast.error("There was an error adding profiles.", SHARED_TOAST_OPTIONS_ERROR)
            }
        }
    }

    /**
     * This lets you upgrade a brand to Pro within the organization - the backend handles
     * the billing and updates the status of the brand to Pro.
     * @param slug slug of brand that represents the upgrade
     */
    const beginUpgradeToPro = (slug: string) => {
        // Find the brand and set its isLoading to true
        setInProgressUpdatingBrandSlug({
            slug: slug,
            status: 'upgrade'
        })
    }

    const beginDowngradeToFree = (slug: string) => {
        // Find the brand and set its isLoading to true
        setInProgressUpdatingBrandSlug({
            slug: slug,
            status: 'downgrade'
        })
    }

    const handleProceedWithSubscriptionAction = async () => {
        setShowUpgradeModal(false)
        const slug = inProgressUpdatingBrandSlug?.slug

        // set the selected brand to isLoading state
        const updatedBrands = displayedBrands.map(brand => brand.slug === slug ? { ...brand, isLoading: true } : brand);
        setDisplayedBrands(updatedBrands);

        try {
            if (inProgressUpdatingBrandSlug?.status === 'upgrade') {
                await upgradeToProInOrganization(slug!)
                // Here you might want to update the brand's tier to PRO based on the result
            } else {
                await downgradeToFreeInOrganization(slug!)
            }

            // Reset the isLoading state for the brand
            const resetLoadingBrands = displayedBrands.map(brand => brand.slug === slug ?
                {
                    ...brand,
                    isPro: inProgressUpdatingBrandSlug?.status === 'upgrade',
                    isLoading: false
                }
                :
                brand
            );
            setDisplayedBrands(resetLoadingBrands);
            reloadOrganization()
            toast.success("Subscription successfully updated.", SHARED_TOAST_OPTIONS);
        } catch (error) {
            console.error(error);
            toast.error("There was an error enabling pro plan access for this user.", SHARED_TOAST_OPTIONS_ERROR);
        }
    }

    const changeSelectedBrand = async (brandId: number) => {
        try {
            const response = await Axios.get(`/brand/${brandId}`)
            const newBrand = response.data.data as CurrentBrand
            setCurrentBrand(newBrand || currentBrand);
            history.push(`/`)
        } catch (error) {
            return null
        }
    }

    const renderSelectArtistList = useCallback(() => {
        const defaultColDef: GridColDef = {
            sortable: true,
            field: 'profile',
            headerName: 'Profile',
            editable: false
        }

        // If there is a search value and no results, show a message
        if (searchValue && searchValue?.length > 1 && !displayedBrands.length) {
            return (
                <div className='w-full h-16 flex justify-center items-center'>
                    <Caption color={Colors.greyDark}>No profiles found for '{searchValue}'.</Caption>
                </div>
            )
        }

        const columns: GridColDef[] = [
            {
                ...defaultColDef,
                field: "profile",
                headerName: "Profile",
                flex: 2,
                renderCell: (params) => (
                    <div className="flex items-center gap-3">
                        <img
                            src={params.row.image}
                            alt={params.row.name}
                            className="w-10 h-10 rounded-full object-cover"
                        />
                        <Body2>{params.row.name}</Body2>
                        {params.row.isPro && <UpgradeToProChip
                            type={'label'}
                            withMargin={false}
                        />}
                    </div>
                ),
            },
            {
                ...defaultColDef,
                field: "fanbase",
                headerName: "Fanbase Size",
                flex: 1,
                renderCell: (params) => {
                    return <Body2>{millify(params.row.fanbaseSize || 0)}</Body2>
                },
            },
            {
                ...defaultColDef,
                field: "campaigns",
                headerName: "Total Campaigns",
                flex: 1,
                renderCell: (params) => (
                    <Body2>{params.row.totalCampaigns || 0} Campaign{params.row.totalCampaigns === 1 ? '' : 's'}</Body2>
                ),
            },
            {
                ...defaultColDef,
                field: "actions",
                headerName: "",
                flex: 3,
                renderCell: (params) => {
                    return (
                        <div className="ml-auto flex gap-4 items-center">
                            {!params.row.isPro && (
                                <ButtonSymphony
                                    className="buttonUpgradeToProOrganizationProfiles"
                                    disabled={params.row.isLoading}
                                    onClick={() => beginUpgradeToPro(params.row.slug)}
                                    isLoading={params.row.isLoading}
                                    variant="outlined"
                                >
                                    Upgrade To Pro
                                </ButtonSymphony>
                            )}
                            <ButtonSymphony
                                className="buttonOpenDashboardOrganizationProfiles"
                                onClick={() => changeSelectedBrand(params.row.id)}
                                variant="text"
                            >
                                Open Dashboard
                            </ButtonSymphony>
                            <BrandsActionMenu
                                isAdminOrOwner={isAdminOrOwner}
                                row={params.row}
                                onDashboard={(id: number) => changeSelectedBrand(id)}
                                onUpgrade={beginUpgradeToPro}
                                onDowngrade={beginDowngradeToFree}
                                canDelete={isAdminOrOwner && teamArtists.length > 1}
                                openDeleteModal={() => setOpenDeleteConfirmationModal(params.row)}
                            />
                        </div>
                    )
                }
            },
        ];


        return (
            <OrganizationViewDefaultGrid
                className="readyStateGridComponent"
                rows={displayedBrands}
                columns={columns}
                hideFooter={false}
                disableColumnMenu
                disableColumnSelector
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                itemsPerPage={PAGE_SIZE}
                rowCount={totalProfiles}
                paginationMode="client"
                itemName="Profiles"
                isLoadingData={isLoadingTeamArtists}
            />
        );
    }, [displayedBrands, existingBrands, page, totalProfiles, isLoadingTeamArtists]);

    const [showAddArtistModal, setShowAddArtistModal] = useState(false);

    /**
     * Handles the completion of adding a new artist to the organization
     * Updates the team artists list, reloads brands, and shows a success message
     */
    const onFinishAddingToOrganization = async () => {
        await getTeamArtists();
        await reloadBrands();
        setShowAddArtistModal(false);
        toast.success("Profile successfully added.", SHARED_TOAST_OPTIONS);
    }


    const handleDelete = async (brand: DisplayedBrand) => {
        setHandlingDelete(true);
        try {
            const success = await deleteOrganizationBrand(brand.id, {
                spoofedUserId: spoofedUserId ?? undefined
            });
            if (success) {
                setOpenDeleteConfirmationModal(null);
                await getTeamArtists();
                await reloadBrands();
                toast.success(`${brand.name} removed from team${brand.isPro ? ' and their Pro plan has been downgraded to Free.' : '.'}`);
            } else {
                throw new Error('Error deleting brand');
            }
        } catch (error) {
            toast.error('There was an error deleting the brand. Please try again.');
        } finally {
            setHandlingDelete(false);
        }
    }

    
    return (
        <TeamTabSection
            className="readyStateComponent"
            title="Your Profiles"
            onSearch={(searchTerm: string) => setSearchValue(searchTerm)}
            buttonText="Add a Profile"
            onButtonClick={() => setShowAddArtistModal(true)}
            secondaryButtonText={isSymphonyAdmin ? "Admin: Invite from Admin Search" : undefined}
            onSecondaryButtonClick={isSymphonyAdmin ? () => setOpenAdminSelector(true) : undefined}
            showDividers
            searchValue={searchValue}
        >
            {renderSelectArtistList()}
            <AddNewArtistModal
                className="readyStateAddArtistModal"
                open={showAddArtistModal}
                onClose={() => setShowAddArtistModal(false)}
                onFinishAddingToOrganization={onFinishAddingToOrganization}
            />
            <ConfirmChangeSubscriptionModal
                open={showUpgradeModal}
                type={inProgressUpdatingBrandSlug?.status}
                onClose={() => setShowUpgradeModal(false)}
                onConfirm={() => handleProceedWithSubscriptionAction()}
                proAccounts={proAccounts}
                brandName={brandName}
                isTeamAdmin={isAdminOrOwner}
            />
            {openDeleteConfirmationModal && (
                <DeleteBrandConfirmationModal
                    open={!!openDeleteConfirmationModal}
                    onClose={() => setOpenDeleteConfirmationModal(null)}
                    brand={openDeleteConfirmationModal}
                    onDeleteAction={handleDelete}
                    loading={handlingDelete}
                />
            )}
            {isSymphonyAdmin && (
                <SearchSelector
                    mode="select"
                    onImportSelected={(brands) => {
                        adminViewImportBrands(brands)
                    }}
                    open={openAdminSelector}
                    closeModal={() => {
                        handleCloseAdminSelectorDropdown()
                    }}
                />
            )}
        </TeamTabSection>
    );
}

export default OrganizationProfiles;
