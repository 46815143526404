import { useContext, useEffect, useState } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { setUserProperty, track } from "analytics";
import CloseButton from "components/shareable/CloseButton";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import FacebookConnectv2 from "components/connect/FacebookConnectv2";
import { siteIconsData } from "pages/post-auth/DashboardModule/Dashboard";
import { PlatformType } from "pages/post-auth/DashboardModule/PlatformsConnector";
import InstagramConnect from "components/connect/Instagram";
import { FacebookPageType } from "types/global";

interface PlatformsConnectorProps {
    open: boolean;
    closeModal: () => void;
}

const IGConnector = ({
    open,
    closeModal
}: PlatformsConnectorProps) => {
    const { currentBrand, reloadBrand } = useContext(CurrentBrandContext);

    const [editedPlatform, setEditedPlatform] = useState<PlatformType | undefined>(undefined)

    function onSave(platform: string) {
        updateBrandConnections()
        track('Linked Platform',
            {
                brand_id: currentBrand!.id,
                brand_slug: currentBrand!.slug,
                brand_name: currentBrand!.name,
                platform: platform,
                page: "Dashboard",
                from: "connector"
            });

        setUserProperty(`${platform}_linked`, true)
        reloadBrand()
        setEditedPlatform(undefined)
    }

    const selectedFacebookPage: FacebookPageType = currentBrand!.connections!.facebook_page!

    const selectedInstaPage = currentBrand &&
        currentBrand.connections &&
        currentBrand.connections.facebook_page &&
        !currentBrand.connections.reauthenticateFbUser &&
        currentBrand.connections.instagram_page

    const PLATFORMS: PlatformType[] =
        [
            {
                id: 'facebook',
                name: "Facebook",
                imageAsset: siteIconsData['facebook'],
                needsConnection: currentBrand && (currentBrand.connections.facebook_page && currentBrand.connections.facebook_page.id) && (!currentBrand.connections.reauthenticateFbUser) ? false : true,
                username: currentBrand && currentBrand.connections.facebook_page ? currentBrand.connections.facebook_page.name : null,
                connectorComponent: <FacebookConnectv2
                    autoconnectInstagram
                    onSave={() => onSave('facebook')}
                    closeModal={() => setEditedPlatform(undefined)}
                    selected={{
                        ...currentBrand!.connections.facebook_page
                    }}
                />
            },

            {
                id: 'instagram',
                name: "Instagram",
                imageAsset: siteIconsData['instagram'],
                needsConnection: currentBrand && currentBrand.connections.business_discovery_instagram ? false : true,
                username: currentBrand?.connections?.business_discovery_instagram?.username || '',
                connectorComponent: <InstagramConnect
                    withBorderRadius
                    showSave
                    closeModal={() => setEditedPlatform(undefined)}
                    selectedInstaPage={selectedInstaPage}
                    updateFBPages={() => setEditedPlatform({
                        id: 'facebook',
                        name: "Facebook",
                        imageAsset: siteIconsData['facebook'],
                        needsConnection: currentBrand && (currentBrand.connections.facebook_page && currentBrand.connections.facebook_page.id) && (!currentBrand.connections.reauthenticateFbUser) ? false : true,
                        username: currentBrand && currentBrand.connections.facebook_page ? currentBrand.connections.facebook_page.name : null,
                        connectorComponent: <FacebookConnectv2
                            autoconnectInstagram
                            onSave={() => onSave('facebook')}
                            closeModal={() => setEditedPlatform(undefined)}
                            selected={{
                                ...currentBrand!.connections.facebook_page
                            }}
                        />
                    })}
                    selectedFBPage={selectedFacebookPage}
                    selectInstagramPage={console.log}
                />
            }
        ]

    useEffect(() => {
        // if modal is closed, reset back to default state
        if (!open) {
            setEditedPlatform(undefined)
        }
    }, [open])

    const selectedEditedPlatform = async (platformType: PlatformType) => {
        // if its Facebook or IG, we should call FB login within this first
        // if (platformType.id === 'instagram') {
        //     if (!fbLoggedIn()) {
        //         console.log('not logged in, calling login')
        //         await loginWithFB()
        //         console.log('user logged in')
        //     }
        //     setEditedPlatform(platformType)
        // }
        setEditedPlatform(platformType)
    }

    const updateBrandConnections = async () => {
        await reloadBrand()
    }

    return (
        <Modal
            aria-labelledby="connector-modal"
            aria-describedby="connector-modal for platforms"
            className={"flex items-center justify-center"}
            open={open}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
        >
            <Fade in={open}>
                {editedPlatform ? (
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md">
                        {editedPlatform.connectorComponent}
                    </div>
                ) : (
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md px-6 py-5 ">
                        <div className="flex justify-end">
                            <CloseButton onClick={closeModal} />
                        </div>
                        <h1 className=" text-2xl">Connect Instagram</h1>
                        <h2 className=" text-md">Connect your Facebook + Instagram Page to Superboost posts.</h2>
                        <div className="mt-4">
                            <hr className="h-px	bg-gray-200" />

                            <div className="add-new">
                                {PLATFORMS
                                    .sort((a: PlatformType, b: PlatformType) => {
                                        if (a.needsConnection && !b.needsConnection) {
                                            return 1
                                        }

                                        if (b.needsConnection && !a.needsConnection) {
                                            return -1
                                        }

                                        return 0
                                    })
                                    .map((platform: PlatformType, index: Number) => {

                                        const {
                                            id,
                                            name,
                                            needsConnection,
                                            imageAsset,
                                            username
                                        } = platform

                                        return (
                                            <div key={id} className="sy-card">
                                                <div className="flex items-center">
                                                    <div className="artist-img">
                                                        <img
                                                            src={imageAsset}
                                                            alt={name}
                                                        />
                                                        {!needsConnection &&
                                                            <div className="absolute bottom-2 right-0 w-4 h-4">
                                                                <img
                                                                    src={require("assets/images/tick.svg").default}
                                                                    style={{ maxWidth: 20 }}
                                                                    alt="tick"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="px-3">
                                                        <h5 className="text-dark">{name}</h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    {!needsConnection ? (
                                                        <div className="flex items-center">
                                                            <p className="pl-6 pr-3 text-fontColor-100 text-right break-all	">{username}</p>
                                                            <a
                                                                className="flex-shrink-0 cursor-pointer"
                                                                onClick={() => {
                                                                    selectedEditedPlatform(platform)
                                                                }}
                                                            >
                                                                <img
                                                                    src={require("assets/images/right-arrow.svg").default}
                                                                    alt="right-arrow"
                                                                />
                                                            </a>
                                                        </div>

                                                    ) : (
                                                        <div>
                                                            <button id="spotify-connect" className="text-green cursor-pointer"
                                                                onClick={() => selectedEditedPlatform(platform)}
                                                            >
                                                                Connect
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}

                            </div>
                        </div>
                    </div>
                )}
            </Fade>
        </Modal>
    )
}

export default IGConnector