import { CSSProperties } from "react";
import CampaignDescription, { CampaignBadges, CampaignTitleAndDate } from "../../../Components/CampaignDescription";
import { ButtonsContainer, TrashButton } from "./styles";
import defaultImage from 'assets/images/music-key-green-bg.svg';
import trashIcon from 'assets/images/trash.svg';
import useScreen from "Hooks/useScreen";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import { Thumbnail } from "pages/post-auth/MarketingPage/Components/CampaignCard/CampaignCardContent";

interface CardCampaignDraftProps {
  imageUrl: string;
  campaignName: string;
  typeTitle: string;
  date: string;
  campaignId: number;
  campaignType: string;
  deleteDraft: (campaignId: number) => void;
  goToDraft: (campaignType: string, campaignId: number) => void;
}

/**
 * Component for campaign action buttons (Finish creating and Delete)
 */
type CampaignActionsProps = {
  onFinish: () => void;
  onDelete: () => void;
}

const CampaignActions = ({
  onFinish,
  onDelete,
}: CampaignActionsProps) => {
    const { mobileView } = useScreen();

    const renderTrashButton = () => {
      return (
        <TrashButton
          onClick={onDelete}
          src={trashIcon}
          alt="trash-icon"
        />
      )
    }

    return (
      <div
        className="cardCampaignDraftActions"
        style={{
          ...sty.cardCampaignDraftActions,
          ...(!mobileView && { marginLeft: 'auto' })
        }}
      >
        <ButtonsContainer>
          {mobileView && renderTrashButton()}
          <ButtonSymphony
            className="finishCreatingButtonCampaign"
            onClick={onFinish}
            variant="outlined"
          >
            Finish creating
          </ButtonSymphony>
          {!mobileView && renderTrashButton()}
        </ButtonsContainer>
      </div>
    );
  }

/**
 * Component for campaign information display
 */
type CampaignInfoProps = {
  imageUrl: string;
  campaignName: string;
  date: string;
  campaignId: number;
  campaignType: string;
}

const CampaignInfo = ({
  imageUrl,
  campaignName,
  date,
  campaignId,
  campaignType,
}: CampaignInfoProps) => {
    const { mobileView } = useScreen();
    return (
      <div
        className="cardCampaignDraftInfo"
        style={mobileView ? sty.responsiveDraftInfo : sty.desktopDraftInfo}
      >
        <Thumbnail src={imageUrl || defaultImage} />
        {mobileView ?
          <CampaignTitleAndDate campaignName={campaignName} date={date} />
          :
          <CampaignDescription
            campaignName={campaignName}
            date={date}
            key={campaignId}
            campaignType={campaignType}
          />
        }
      </div>
    );
  }

const CardCampaignDraft = ({
  imageUrl,
  campaignName,
  date,
  campaignId,
  campaignType,
  deleteDraft,
  goToDraft,
}: CardCampaignDraftProps) => {
  const { mobileView } = useScreen();
  const handleFinishCampaign = () => goToDraft(campaignType, campaignId);
  const handleConfirmDeleteDraft = () => deleteDraft(campaignId);

  return (
    <div
      className="cardCampaignDraftDesktopView border-b"
      style={mobileView ? sty.mobileView : sty.desktopView}
    >
      {mobileView && <CampaignBadges campaignType={campaignType} campaignStatus="draft" />}
      <div style={mobileView ? sty.mobileViewCampaignInfo : undefined}>
        <CampaignInfo
          imageUrl={imageUrl}
          campaignName={campaignName}
          date={date}
          campaignId={campaignId}
          campaignType={campaignType}
        />
      </div>
      <div style={mobileView ? sty.mobileViewCampaignActions : undefined}>
        <CampaignActions
          onFinish={handleFinishCampaign}
          onDelete={handleConfirmDeleteDraft}
        />
      </div>
    </div>
  );
};

const sty: Record<string, CSSProperties> = {
  desktopView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '1.75rem',
    paddingBottom: '1.75rem'
  },
  mobileView: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingBottom: '1.25rem',
    marginBottom: '1rem',
  },
  mobileViewCampaignInfo: {
    width: '100%',
    marginTop: '1rem'
  },
  mobileViewCampaignActions: {
    width: '100%'
  },
  responsiveDraftInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  desktopDraftInfo: {
    maxWidth: '75%',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardCampaignDraftActions: {
    marginTop: '1rem',
  }
}

export default CardCampaignDraft;
