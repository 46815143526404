import { useState, useMemo, ReactNode } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import LoadingLottie from 'components/Loader/LoadingLottie';
import { useStyles } from './styles';
import debounce from 'lodash.debounce';

interface Props {
  placeholder: string;
  value: string;
  error?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onChange: (value: string) => void;
  debounceHandler?: (value: string) => void;
  debounceTime?: number;
  helperText?: JSX.Element;
  size?: 'small' | 'medium';
  styling?: TextFieldProps;
  leftIcon?: ReactNode;
}

const InputWithDebounce = ({
  placeholder,
  value,
  error,
  disabled,
  loading,
  onChange,
  debounceHandler,
  debounceTime = 0,
  helperText,
  size,
  styling = {},
  leftIcon,
}: Props) => {
  const [inputValue, setInputValue] = useState(value);
  const classes = useStyles();

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        debounceHandler?.(value);
      }, debounceTime),
    []
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange(newValue);
    if (debounceHandler) debouncedChangeHandler(newValue)
  }

  return (
    <TextField
      InputProps={{
        classes: {
          root: classes.inputBorder,
          disabled: classes.inputDisable,
        },
        startAdornment: leftIcon,
        endAdornment: loading && (
          <InputAdornment position="end">
            <LoadingLottie height={24} width={24} black />
          </InputAdornment>
        ),
      }}
      value={inputValue}
      onChange={handleInputChange}
      className={classes.textField}
      style={styling}
      variant="outlined"
      placeholder={placeholder}
      error={error}
      FormHelperTextProps={{
        classes: {
          root: classes.formHelperText,
        },
      }}
      fullWidth
      disabled={disabled}
      helperText={helperText}
      size={size}
    />
  );
};

export default InputWithDebounce;