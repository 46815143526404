import Axios from 'helpers/Interceptor';
import { WebsiteSocialMediaIcon } from 'types/global';
import { PublishBrandContent } from './brandContentService';

type BrandWebsiteData = {
  buttonConfig?: string,
  enableSocialIcons?: boolean,
  removeSymphonyBrand?: boolean,
  socialIcons?: WebsiteSocialMediaIcon[],
}

export const saveBrandWebsite = async (brandSlug: string, websiteData: BrandWebsiteData) => {
  try {
    const response = await Axios.post(`/website/${brandSlug}`, websiteData);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const saveWebsiteChanges = async (brandSlug: string, websiteData: PublishBrandContent) => {
  try {
    const response = await Axios.post(`/website/update/${brandSlug}/${websiteData.slug}`, websiteData);

    return response.data;
  } catch (error) {
    console.log('error, saveWebsiteChanges', error)
    throw error;
  }
}
/**
 * Saves changes to a website for a specific brand and content
 * @async
 * @param {string | number} slug - The unique identifier for the brand content
 * @returns {Promise<Campaign>} The response data from the API
 * @throws {Error} If the API request fails
 * @description Makes a POST request to update website content for a specific brand and content slug
 */

export const boostWebsite = async (slug: number | string) => {
  try {
    const response = await Axios.post(`/website/boost/${slug}`);
    return response.data;
  } catch (error) {
    console.log('error, boostWebsite', error);
    throw error;
  }
}