import { CSSProperties } from "react";
import Lottie from "react-lottie";
import SymphonyLoadingLottieWhite from 'assets/images/lotties/SymphonyLoading_White.json';
import SymphonyLoadingLottiePurple from 'assets/images/lotties/SymphonyLoading.json';

interface LoaderV2Props {
    height?: number;
    width?: number;
    color?: 'white' | 'purple';
    style?: CSSProperties | undefined;
    rendererSettings?: {
        preserveAspectRatio?: string | undefined;
        context?: any;
        scaleMode?: any;
        clearCanvas?: boolean | undefined;
        progressiveLoad?: boolean | undefined;
        hideOnTransparent?: boolean | undefined;
        className?: string | undefined;
    } | undefined
}

const LoaderV2 = (props: LoaderV2Props) => {
    const {
        height = 24,
        width = 24,
        color = "purple",
        rendererSettings,
        style = {}
    } = props;

    const getLoaderColor = () => {
        switch (color) {
            case 'purple':
                return SymphonyLoadingLottiePurple;
            case 'white':
                return SymphonyLoadingLottieWhite;
            default:
                return SymphonyLoadingLottieWhite;
        }
    }

    return (
        <div className="loaderV2Component" style={style}>
            <Lottie
                height={height}
                width={width}
                options={{
                    rendererSettings: rendererSettings,
                    loop: true,
                    autoplay: true,
                    animationData: getLoaderColor()
                }}
                isClickToPauseDisabled
            />
        </div>
    )
}

export default LoaderV2;