import { MenuItem, Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as ChevronDownIcon } from 'assets/images/chevronDown.svg';
import { Body2, Caption } from 'components/shareable/Typography';
import Colors from "modules/Colors";
import { MemberRole } from 'types/global';
import { MemberRolesNames } from '../TeamMemberList/utils';

interface MenuItemContentProps {
    title: string;
    description: string;
  }
  
  const MenuItemContent = ({ title, description }: MenuItemContentProps) => (
    <div className='flex flex-col gap-1' style={{ width: 300, textWrap: 'wrap' }}>
      <Body2 color={Colors.textColor}>{title}</Body2>
      <Caption color={Colors.greyDark}>
        {description}
      </Caption>
    </div>
  );
  
interface MemberPermissionsSelectProps {
  value: MemberRole | '';
  onChange: (role: MemberRole) => void;
  required?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    height: '45px',
    '&.MuiSelect-select': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '&.Mui-focused > .MuiSelect-select:focus': {
      backgroundColor: `${Colors.PAPER_CONTAINER} !important`,
    },
    '& fieldset': {
      borderColor: `${Colors.PAPER_DIVIDER_CONTAINER} !important`,
    },
    '&.Mui-focused > fieldset': {
      borderColor: `${Colors.purple} !important`,
    },
  },
}));

export const MemberPermissionsSelect = ({ 
  value, 
  onChange, 
  required = false, 
  disabled = false 
}: MemberPermissionsSelectProps) => {
  const classes = useStyles();

  return (
    <Select
      fullWidth
      required={required}
      displayEmpty
      value={value}
      onChange={(e) => onChange(e.target.value as MemberRole)}
      variant="outlined"
      className={classes.select}
      disabled={disabled}
      IconComponent={() => {
        if (disabled) return null;
        return <ChevronDownIcon className="mr-4 h-8 w-8" />
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }
      }}
      renderValue={(value) => {
        if (!value) {
          return <Body2 color={Colors.greyDark}>Select permissions</Body2>
        }
        return <Body2 color={Colors.textColor}>{MemberRolesNames[value as MemberRole].name}</Body2>
      }}
    >
      <MenuItem value="administrator">
        <MenuItemContent
          title="Team Admin"
          description="Team Admins have full access to add and edit profiles, invite new teammates, create campaigns and websites, and update Billing settings."
        />
      </MenuItem>
      <MenuItem value="member">
        <MenuItemContent
          title="Member"
          description="This teammate will have only have access to selected profiles, with customizable Admin or View-only permissions per profile."
        />
      </MenuItem>
    </Select>
  );
};
