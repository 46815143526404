import { CSSProperties, ReactNode } from 'react';
import Colors from "modules/Colors";
import { ReactComponent as CheckGreen } from 'assets/images/check-green.svg';
import LoaderV2 from 'components/Loader/LoaderV2';
import { SymphonyTooltip } from 'styles/shared';

type ButtonVariant = 'primary' | 'text' | 'outlined' | 'link';
type ButtonColor = 'green' | 'red' | 'purple' | 'orange' | 'grey' | 'white' | 'black' | 'connected';
type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonSymphonyProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    style?: CSSProperties;
    onClick?: (...args: any[]) => void;
    variant?: ButtonVariant;
    color?: ButtonColor;
    size?: ButtonSize;
    width?: string | number;
    minWidth?: string | number;
    isLoading?: boolean;
    loadingText?: string;
    disabled?: boolean;
    children: ReactNode;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    href?: string;
    target?: string;
    tooltip?: string | ReactNode | undefined;
}

const ButtonSymphony = ({
    className = '',
    style = {},
    onClick = () => { },
    variant = 'primary',
    size = 'medium',
    color = 'purple',
    width = 'fit-content',
    minWidth = 'fit-content',
    isLoading = false,
    loadingText,
    disabled = false,
    children = 'My Button',
    iconLeft = null,
    iconRight = null,
    href,
    target,
    tooltip,
    ...props
}: ButtonSymphonyProps) => {
    const getLoaderColor = () => {
        if (variant === 'outlined') {
            return 'purple';
        }
        return 'white';
    }

    const renderButtonContent = () => {
        if (isLoading) {
            return (
                <div className="buttonSymphonyLoading" style={sty.buttonSymphonyLoading}>
                    <LoaderV2 height={18} width={18} style={{ marginTop: -3 }} color={getLoaderColor()} />
                    {loadingText && <div>{loadingText}</div>}
                </div>
            )
        }

        if (color === 'connected') {
            iconRight = <CheckGreen stroke={Colors.green} />
        }

        return (
            <div
                className="buttonSymphonyContent"
                style={sty.buttonContent}
            >
                {iconLeft &&
                    <div
                        className="buttonIconLeft"
                        style={sty.iconStyle}
                    >
                        {iconLeft}
                    </div>
                }
                {children}
                {iconRight &&
                    <div
                        className="buttonIconRight"
                        style={sty.iconStyle}
                    >
                        {iconRight}
                    </div>
                }
            </div>
        )
    }

    if (variant === 'link') {
        return (
            <a
                className={`buttonSymphonyComponentLink ${className}`}
                onClick={disabled ? undefined : onClick}
                href={href}
                target={target}
                style={{
                    ...sty.buttonBase,
                    ...sty.link,
                    ...sty[`link_${color}`],
                    ...sty[`link_${size}`],
                    ...sty[disabled ? 'link_disabled' : ''],
                    ...style
                }}
            >
                {renderButtonContent()}
            </a>
        )
    }

    const buttonComponent = (
        <button
            className={`buttonSymphonyComponent ${className}`}
            disabled={disabled || isLoading}
            onClick={disabled || isLoading ? undefined : onClick}
            style={{
                ...sty.buttonBase,
                ...sty[variant],
                ...sty[`${variant}_${color}`],
                ...sty[`${variant}_${size}`],
                ...sty[disabled ? `${variant}_disabled` : ''],
                ...sty[disabled ? 'disabled' : ''],
                ...{ width },
                ...{ minWidth },
                ...style
            }}
            {...props}
        >
            {renderButtonContent()}
        </button>
    )

    if (tooltip) {
        return (
            <SymphonyTooltip title={tooltip}>
                {buttonComponent}
            </SymphonyTooltip>
        )
    }

    return buttonComponent;
};

const sty: Record<string, CSSProperties> = {
    buttonBase: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        borderRadius: 100,
        fontWeight: 500,
        fontSize: 16,
        paddingTop: 3,
        paddingLeft: 24,
        paddingRight: 24,
        backgroundColor: Colors.purple,
        color: 'white',
        height: 40,
        cursor: 'pointer',
        border: 'none',
        whiteSpace: 'nowrap',
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
    },
    disabled: {
        cursor: 'not-allowed',
    },

    // NOTE: VARIANT
    primary: {}, // NOTE: DEFAULT
    outlined: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.purple,
    },
    text: {
        backgroundColor: 'transparent',
        color: Colors.purple,
        fontWeight: 500,
        fontSize: 16,
        paddingLeft: 0,
        paddingRight: 0,
        height: 'auto',
        border: 'none',
    },
    link: {
        backgroundColor: 'transparent',
        color: Colors.purple,
        fontWeight: 600,
        fontSize: 16,
        paddingLeft: 0,
        paddingRight: 0,
        height: 'auto',
        border: 'none',
    },

    // NOTE: DISABLED
    primary_disabled: {
        backgroundColor: Colors.greyLight,
        color: Colors.grey,
    },
    outlined_disabled: {
        borderColor: Colors.grey,
        color: Colors.grey,
    },
    text_disabled: {
        color: Colors.grey,
    },
    link_disabled: {
        color: Colors.grey,
    },

    // NOTE: SIZE
    primary_small: {
        fontSize: 12,
        paddingLeft: 16,
        paddingRight: 16,
        height: 32,
    },
    primary_medium: {}, // NOTE: DEFAULT
    primary_large: {
        fontSize: 18,
        paddingLeft: 32,
        paddingRight: 32,
        height: 48,
    },

    outlined_small: {
        fontSize: 12,
        paddingLeft: 16,
        paddingRight: 16,
        height: 32,
    },
    outlined_medium: {}, // NOTE: DEFAULT
    outlined_large: {
        fontSize: 18,
        paddingLeft: 32,
        paddingRight: 32,
        height: 48,
    },

    text_small: {
        fontSize: 13,
    },
    text_medium: {}, // NOTE: DEFAULT
    text_large: {
        fontSize: 18,
    },

    link_small: {
        fontSize: 12,
    },
    link_medium: {
        fontSize: 16,
    },
    link_large: {
        fontSize: 18,
    },

    // NOTE: COLOR
    primary_black: {
        backgroundColor: 'black',
    },
    primary_grey: {
        backgroundColor: Colors.greyDark,
    },
    primary_green: {
        backgroundColor: Colors.green,
    },
    primary_orange: {
        backgroundColor: Colors.yellow,
    },
    primary_purple: {
        backgroundColor: Colors.purple,
    },
    primary_red: {
        backgroundColor: Colors.red,
    },
    primary_white: {
        backgroundColor: 'white',
        color: Colors.purple,
    },
    primary_connected: {
        backgroundColor: "#07C80614",
        color: Colors.green,
        paddingRight: 16,
    },

    outlined_black: {
        borderColor: 'black',
        color: 'black',
    },
    outlined_grey: {
        borderColor: Colors.greyDark,
        color: Colors.greyDark,
    },
    outlined_green: {
        borderColor: Colors.green,
        color: Colors.green,
    },
    outlined_orange: {
        borderColor: Colors.yellow,
        color: Colors.yellow,
    },
    outlined_purple: {
        borderColor: Colors.purple,
        color: Colors.purple,
    },
    outlined_red: {
        borderColor: Colors.red,
        color: Colors.red,
    },
    outlined_white: {
        borderColor: 'white',
        color: 'white',
    },

    text_black: {
        color: 'black',
    },
    text_grey: {
        color: Colors.greyDark,
    },
    text_green: {
        color: Colors.green,
    },
    text_orange: {
        color: Colors.yellow,
    },
    text_purple: {
        color: Colors.purple,
    },
    text_red: {
        color: Colors.red,
    },
    text_white: {
        color: 'white',
    },

    link_black: {
        color: 'black',
    },
    link_grey: {
        color: Colors.greyDark,
    },
    link_green: {
        color: Colors.green,
    },
    link_orange: {
        color: Colors.yellow,
    },
    link_purple: {
        color: Colors.purple,
    },
    link_red: {
        color: Colors.red,
    },
    link_white: {
        color: 'white',
    },

    // NOTE: ICON
    iconStyle: {
        marginTop: -3,
    },
    buttonSymphonyLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        whiteSpace: 'nowrap',
    }
}

export default ButtonSymphony;
