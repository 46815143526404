import { useContext } from 'react';
import { Body1, Body2, Caption } from 'components/shareable/Typography';
import { ConnectionsContext } from 'pages/post-auth/MarketingPage/hooks/ConnectionsContext';
import { useStyles } from './styles';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { ConnectedStepIcon } from './ConnectedStepIcon';
import Intercom from 'helpers/Intercom';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import Colors from 'modules/Colors';

const { textColor: PRIMARY_TEXT_COLOR, grey } = Colors;

interface ConnectionsStepsDescriptionProps {
  showConversionsStep?: boolean;
}

const ConnectionsStepsDescription = ({
  showConversionsStep = true,
}: ConnectionsStepsDescriptionProps) => {
  const classes = useStyles();

  const {
    facebookAdAccount,
    facebookPage,
    instagramPage,
    conversionsEnabled,
  } = useContext(ConnectionsContext);

  return (
    <div className={classes.mainContainer}>
      {/* <Headline2 color={PRIMARY_TEXT_COLOR}>Accounts</Headline2> */}
      <Body1 color={PRIMARY_TEXT_COLOR}>Connected Accounts</Body1>
      {/* <Caption1>Connect your Accounts</Caption1> */}
      <Stepper orientation="vertical" className={classes.stepper}>
        <Step active={true} completed={Boolean(facebookAdAccount)}>
          <StepLabel StepIconComponent={() => <ConnectedStepIcon connected={Boolean(facebookAdAccount)} />}>
            <Body2>Ad Account</Body2>
          </StepLabel>
        </Step>
        <Step active={true} completed={Boolean(facebookPage && instagramPage)}>
          <StepLabel StepIconComponent={() => <ConnectedStepIcon connected={Boolean(facebookAdAccount)} />}>
            <Body2>FB & IG Page</Body2>
          </StepLabel>
        </Step>
        {showConversionsStep && (
          <Step active={true} completed={Boolean(conversionsEnabled)}>
            <StepLabel StepIconComponent={() => <ConnectedStepIcon connected={Boolean(conversionsEnabled)} />}>
              <div className='flex flex-col'>
                <Body1>Conversions</Body1>
                <Caption color={grey}>Optional</Caption>
              </div>
            </StepLabel>
          </Step>
        )}
      </Stepper>
      <div className='flex flex-col gap-2'>
        <Body2>Having issues connecting your accounts?</Body2>
        <ButtonSymphony
          className="contactSupportButton"
          onClick={Intercom.open}
          variant="text"
        >
          Contact Support
        </ButtonSymphony>
      </div>
    </div>
  );
};

export default ConnectionsStepsDescription;