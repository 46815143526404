import DialogPaper from "components/shareable/DialogPaper";
import { ReactComponent as UserIcon } from "assets/images/user.svg";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { useStyles } from "./styles";
import PrimaryButton from "components/shareable/PrimaryButton";
import { TextField } from "@material-ui/core";
import { OrganizationBrand, ArtistPermissions, MemberRole, MemberRoles, TeamPricesData } from "types/global";
import { useContext, useEffect, useMemo, useState } from "react";
import { Body2, Subtitle1, Subtitle2 } from "components/shareable/Typography";
import { isValidEmail } from "modules/Utils";
import { Banner } from "components/shareable/Banner";
import { ArtistsList } from "../ArtistsList";
import useScreen from "Hooks/useScreen";
import { addNewMember, getSimulationPrices } from "../../../../../services/symphonyApi/organizationService"
import { toast } from "react-toastify"
import { TOAST_ERROR_OPTIONS, TOAST_SUCCESS_OPTIONS } from "../../utils"
import { TEAM_PLAN_ADDITIONAL_SEAT_PRICE, TEAM_PLAN_INCLUDED_SEATS } from "modules/Const"
import { Container } from "styles/shared";
import { MemberPermissionsSelect } from '../MemberPermissionsSelect';
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import Colors from "modules/Colors";
import ModalSymphony from "components/shareable/ModalSymphony";
import ButtonSymphony from "components/shareable/ButtonSymphony";

const { ADMIN, MEMBER } = MemberRoles;
const { ADMINISTRATOR } = ArtistPermissions;

interface AddMemberModalProps {
  onClose: () => void;
}

const AddMemberModal = ({ onClose }: AddMemberModalProps) => {
  const classes = useStyles();
  const { teamMembers, teamArtists, getTeamMembers } = useCurrentTeam();
  const { spoofedUserId } = useContext(CurrentUserContext);
  const { mobileView } = useScreen();

  const [name, setName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [role, setRole] = useState<MemberRole>()

  // Default to admin permission level for all artists
  const [artists, setArtists] = useState<OrganizationBrand[]>(
    teamArtists.map((artist: OrganizationBrand) => ({ ...artist, permissionLevel: artist.permissionLevel || ADMINISTRATOR }))
  )
  const [extraCosts, setExtraCosts] = useState<TeamPricesData | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const missingFields = useMemo(
    () => !name || !lastName || !role || !email || (email?.length && !isValidEmail(email)) || Boolean(artists.find(artist => !artist.permissionLevel)),
    [name, lastName, email, role, artists]
  )

  useEffect(() => setArtists(teamArtists), [teamArtists])

  useEffect(() => {
    switch (role) {
      case ADMIN:
        setArtists(teamArtists.map((artist: OrganizationBrand) => ({ ...artist, permissionLevel: ADMINISTRATOR })));
        break;
      case MEMBER:
        setArtists(teamArtists.map((artist: OrganizationBrand) => ({ ...artist, permissionLevel: undefined })));
        break;
    }
  }, [role])

  useEffect(() => {
    if (teamMembers.length >= TEAM_PLAN_INCLUDED_SEATS) {
      getExtraCost()
    }
  }, [teamMembers])

  const onAddMember = async () => {
    try {
      if (!name || !lastName || !email || !role || !isValidEmail(email)) return
      setIsLoading(true)
      const newMember = {
        name,
        lastName,
        email,
        role: role
      }

      await addNewMember({ member: newMember, brands: artists }, { spoofedUserId: spoofedUserId ?? undefined })
      // await getTeamMembers()
      toast.success("New member added to team! Your new teammate will receive an email with the invite.", TOAST_SUCCESS_OPTIONS)
      onClose();
    } catch (error: any) {
      let message = "Something went wrong adding the member"
      if (error?.response?.data?.message === 'User already belongs to an organization') {
        message = "This user already belongs to another Team. Please contact support if you need to add them to this Team."
      } else if (error?.response?.data?.message === 'User already invited to your organization') {
        message = "This user has already been invited to your organization. Please contact support if you need help getting them added to the Team."
      }
      toast.error(message, TOAST_ERROR_OPTIONS)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onAddArtist = (artist: OrganizationBrand) => {
    setArtists(prev => [...prev, artist]);
  }

  const getExtraCost = async () => {
    const extraCost = await getSimulationPrices({ typeSimulation: 'add-seat' });
    setExtraCosts(extraCost)
  }

  return (
    <ModalSymphony
      className="addMemberModalComponent"
      isOpen
      fullScreen={mobileView}
      onClose={onClose}
      title="Add a new teammate"
      subtitle="Manage the users who can access your organization and profiles."
      headerIcon={<UserIcon className={classes.userIcon} />}
      maxWidth={500}
    >
      {/* TODO: in the future we should handle prices based on currency selected */}
      {(teamMembers.length >= TEAM_PLAN_INCLUDED_SEATS && extraCosts) &&
        <Banner className="addMemberModalBanner" variant="warning" classes="flex flex-col gap-4 p-4">
          <div className="flex flex-col gap-1">
            <Subtitle1>You're out of seats</Subtitle1>
            <Body2 color={Colors.textColor}>
              You'll be charged with + ${extraCosts.priceSeatRemaining} on your next invoice and then ${extraCosts.priceSeat} per month for this new user
            </Body2>
          </div>
          <div className="flex gap-2 justify-between">
            <Body2>Extra user prorated {extraCosts.remainingDays} days</Body2>
            <Subtitle2>${extraCosts.priceSeatRemaining}</Subtitle2>
          </div>
        </Banner>
      }
      <Container className="addMemberModalContainer" display='flex' flexDirection='column' gap='8px' width='100%'>
        <div className="flex gap-4">
          <TextField
            type="text"
            required
            value={name}
            variant="outlined"
            style={{ width: '50%' }}
            placeholder="First name"
            className={classes.input}
            onChange={(e) => {
              const REG_EXP: RegExp = /^[ A-Za-z]*$/
              if (REG_EXP.test(e.target.value)) setName(e.target.value)
            }}
          />
          <TextField
            type="text"
            required
            value={lastName}
            variant="outlined"
            style={{ width: '50%' }}
            placeholder="Last name"
            className={classes.input}
            onChange={(e) => {
              const REG_EXP: RegExp = /^[ A-Za-z]*$/
              if (REG_EXP.test(e.target.value)) setLastName(e.target.value)
            }}
          />
        </div>
        <TextField
          type="email"
          error={Boolean(email?.length && !isValidEmail(email))}
          required
          value={email}
          inputMode="email"
          variant="outlined"
          fullWidth placeholder="Email"
          className={classes.input}
          onChange={(e) => setEmail(e.target.value)}
        />
        <MemberPermissionsSelect
          value={role || ''}
          onChange={setRole}
          required
        />
        {role === ADMIN &&
          <Banner variant="accent" classes="p-4">
            <Body2 color={Colors.textColor}>As a Team Admin, this teammate will have full access to update profiles, invite new teammates, create campaigns and websites, and update Billing settings.</Body2>
          </Banner>
        }
      </Container>

      {role === MEMBER &&
        <ArtistsList artists={artists} onUpdateArtists={setArtists} />
      }

      {teamMembers.length + 1 > TEAM_PLAN_INCLUDED_SEATS &&
        <Banner variant="accent" classes="flex flex-col gap-1 p-4">
          <Subtitle1>You've used 3 out of 3 included seats in your Team Plan.</Subtitle1>
          <Body2>Adding this member will add ${TEAM_PLAN_ADDITIONAL_SEAT_PRICE} / month to your subscription.</Body2>
        </Banner>
      }
      <ButtonSymphony
        className="addMemberModalButtonTeam"
        style={{ marginTop: 16 }}
        isLoading={isLoading}
        onClick={onAddMember}
        disabled={isLoading || missingFields || artists.length === 0}
        tooltip={missingFields && "Please fill all the required fields"}
      >
        Send invite
      </ButtonSymphony>
    </ModalSymphony>
  );
};

export default AddMemberModal;
