import { useContext, useState } from "react"
import {
  DateInputContainer,
} from "./style"
import DatePicker from "react-datepicker"
import moment from "moment"
import DialogPaper from 'components/shareable/DialogPaper'
import { WarningCodesEditBudget } from "../../LowDailySpendContainer/constants"
import LowDailySpendContainer from "../../LowDailySpendContainer"
import Axios from "helpers/Interceptor"
import some from "lodash/some"
import FB from "helpers/FB"
import * as Sentry from "@sentry/react";
import { showToast } from 'modules/Utils'
import useScreen from 'Hooks/useScreen'
import { track } from "analytics"
import { getTotalRemaining } from "helpers/CampaignDetails"
import { ButtonsContainer } from "components/shareable/Modal/styles"
import { CurrencyCodesType } from "types/global"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import UnlockDataBanner from "components/shareable/UnlockDataBanner"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import HeaderModal from "../../HeaderModal"
import { ReactComponent as CalendarIcon } from "assets/images/calendar-email-campaign.svg"

interface Props {
  budget: number;
  campaignId: number;
  closeModal: () => void;
  currency?: CurrencyCodesType;
  endDate: string;
  minimalAmountPerDay: number;
  onErrorHandler?: () => void;
  open: boolean;
  reload: (endDate: string, budget: number) => void;
  startDate: string;
  totalSpent?: string | number;
  trackObject?: Record<string, any>;
}

const EditEndDateModal = ({
  budget,
  campaignId,
  closeModal,
  currency,
  endDate,
  minimalAmountPerDay,
  onErrorHandler,
  open,
  reload,
  startDate,
  totalSpent,
  trackObject,
}: Props) => {
  const { mobileView } = useScreen();
  const { isProBrand } = useContext(CurrentBrandContext);

  const [loading, setLoading] = useState<boolean>(false)
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(false)
  const [newEndDate, setNewEndDate] = useState<string>(endDate)
  const [warningError, setWarningError] = useState<WarningCodesEditBudget | null>(null)
  const [newDailySpend, setNewDailySpend] = useState<number>(0)
  const [minimalBudget, setMinimalBudget] = useState<number>(0)
  const spent = typeof totalSpent === 'string' ? Number(totalSpent) : totalSpent || 0
  const remainingBudget = Number(getTotalRemaining(budget, (totalSpent || '0') as string))

  const dateValidator = (newDate: Date) => {
    if (moment(newDate).isSame(endDate, "day")) {
      setIsValidEndDate(false)
      setWarningError(null)
      return
    }
    const campaignStartDate = moment(startDate).isAfter() ? moment(startDate) : moment()
    const remainingDays = Math.abs(campaignStartDate.diff(newDate, "days"))
    const costPerDay = remainingBudget / remainingDays

    if (costPerDay < minimalAmountPerDay) {
      setIsValidEndDate(false)
      setWarningError(WarningCodesEditBudget.MANY_DAYS)
      const minimalBudget = minimalAmountPerDay * remainingDays + spent
      setMinimalBudget(minimalBudget)
      return
    }
    setIsValidEndDate(true)
    setNewDailySpend(costPerDay)
    if (moment(newDate).isAfter(endDate)) {
      setWarningError(WarningCodesEditBudget.DECREASE_DAILY)
    } else {
      setWarningError(WarningCodesEditBudget.INCREASE_DAILY)
    }
  }

  const handleEditEndDate = async () => {
    setLoading(true)
    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) { }

    try {
      const response = await Axios.put(`/campaign/${campaignId}/endDate`, {
        access_token,
        endDate: moment(newEndDate).toLocaleString(),
      })
      if (
        some([
          !response,
          !response.data,
          !response.data.data,
          response.data.isError,
        ])
      ) {
        throw new Error("Error editing end date on campaign")
      }

      reload(newEndDate, budget)
      showToast({ message: 'End date updated!', mobile: mobileView })
      track('Saved Edit End Date', {
        ...trackObject,
        opened: 'Edit End Date Modal',
      })
    } catch (e) {
      Sentry.withScope(scope => {
        scope.setExtra("campaignId", campaignId)
        scope.setExtra("endDate", newEndDate)
        Sentry.captureException(`Error editing end date in campaign ${campaignId}: ${e}`)
      })
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const handleEditEndDateAndBudget = async () => {
    setLoading(true)
    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) { }

    try {
      const response = await Axios.put(
        `/campaign/${campaignId}/budget-endDate`,
        {
          access_token,
          endDate: newEndDate,
          budget: minimalBudget,
        }
      )
      if (
        some([
          !response,
          !response.data,
          !response.data.data,
          response.data.isError,
        ])
      ) {
        throw new Error("Error editing end date and budget on campaign")
      }
      reload(newEndDate, minimalBudget)
      showToast({ message: 'Success edit end date', mobile: mobileView })
    } catch (e) {
      Sentry.captureException(`Error editing end date and budget in campaign ${campaignId}: ${e}`)
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const handleChangeDate = (date: Date) => {
    setIsValidEndDate(false)
    setWarningError(null)
    setNewEndDate(date.toISOString())
    dateValidator(date)
  }

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  const renderEditEndDateModalBody = () => {
    if (!isProBrand) {
      return (
        <UnlockDataBanner
          className="unlockDataBannerEditEndDate"
          title="Unlock more budget options"
          description="Upgrade to Pro to unlock more budget options and features."
        />
      )
    }

    return (
      <>
        <DateInputContainer>
          <DatePicker
            withPortal
            className="p-3 rounded w-full"
            minDate={moment().add(1, "day").toDate()}
            selected={moment(newEndDate).toDate()}
            onChange={handleChangeDate}
            placeholderText="MMMM DD, YYYY"
            dateFormat="MMMM dd, yyyy"
            customInput={<DatepickerInput />}
          />
        </DateInputContainer>
        {warningError && (
          <LowDailySpendContainer
            currentBudget={budget}
            selectedBudget={remainingBudget}
            suggestedBudget={minimalBudget}
            dailySpend={newDailySpend}
            currency={currency}
            errorCode={warningError}
            date={new Date(newEndDate)}
            totalSpent={spent}
            currentEndDate={endDate}
            selectedEndDate={newEndDate}
          />
        )}
        <ButtonsContainer
          flexDirection={mobileView ? "column-reverse" : "row"}
        >
          <ButtonSymphony
            className="buttonEditEndDateModal1"
            onClick={closeModal}
            variant="outlined"
            width={mobileView ? "100%" : "fit-content"}
            disabled={loading}
          >
            Cancel
          </ButtonSymphony>
          {warningError !== WarningCodesEditBudget.MANY_DAYS ?
            <ButtonSymphony
              className="buttonEditEndDateModal2"
              width={mobileView ? "100%" : "50%"}
              disabled={loading || !isValidEndDate}
              onClick={handleEditEndDate}
              isLoading={loading}
              loadingText="Updating campaign..."
            >
              Update campaign
            </ButtonSymphony>
            :
            <ButtonSymphony
              className="buttonEditEndDateModal3"
              width={mobileView ? "100%" : "fit-content"}
              disabled={loading}
              onClick={handleEditEndDateAndBudget}
              isLoading={loading}
              loadingText="Updating campaign..."
            >
              Update campaign
            </ButtonSymphony>
          }
        </ButtonsContainer>
      </>
    )
  }

  return (
    <DialogPaper open={open}>
      <>
        <HeaderModal
          HeaderIcon={CalendarIcon}
          closeModal={closeModal}
          title='Edit end date'
          subtitle='After editing your campaign, it may go into review again while your changes are reflected.'
        />
        {renderEditEndDateModalBody()}
      </>
    </DialogPaper>
  )
}

export default EditEndDateModal
