import { getAdLabelByStatus, getAdStatusBackgroundColor, getAdStatusTextColor } from '../../utils/adsStatuses'
import { BadgeText } from './styles'
import Badge from 'components/shareable/Badge'

const CampaignStatusBadge = ({
  campaignStatus,
  campaignStatusLabel,
}: {
  campaignStatus: string
  campaignStatusLabel: string | undefined
}) => {
  return (
    <Badge backgroundColor={`${getAdStatusBackgroundColor(campaignStatus)}`}>
      <BadgeText color={getAdStatusTextColor(campaignStatus)}>
        {getAdLabelByStatus(campaignStatusLabel?.toUpperCase())}
      </BadgeText>
    </Badge>
  )
}

export default CampaignStatusBadge