import styled from "styled-components"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Container} from 'styles/shared'
import Colors from "modules/Colors"

interface useStylesProps {
  isMobileView?: boolean
}

const useStyles = makeStyles((_theme: Theme) => ({
  closeIcon: {
    "& path": {
      stroke: Colors.PAPER_CONTAINER,
    },
  },
  paper: {
    borderRadius: ({ isMobileView }: useStylesProps) => (isMobileView ? 0 : 12),
    margin: ({ isMobileView }: useStylesProps) => (isMobileView ? 0 : 'inherit')
  },
  billingChip: {
    backgroundColor: Colors.PAPER_CONTAINER,
    borderRadius: 8,
    border: "1px solid var(--divider-line, #EDECF2)",
    width: ({ isMobileView }: useStylesProps) => (isMobileView ? "100%" : 232),
    display: "flex",
    "& .MuiChip-label": {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: Colors.PAPER_ACCENT_CONTAINER,
    },
    height: 48,
  },
  billingChipActive: {
    width: ({ isMobileView }: useStylesProps) => (isMobileView ? "100%" : 232),
    backgroundColor: Colors.PAPER_ACCENT_CONTAINER,
    color: Colors.purple,
  },
  billingRadio: {
    color: Colors.purple,
  },
  icon: {
    width: "100%",
    "& path": {
      stroke: Colors.purple,
    },
  },
  iconFacebook: {
    marginTop: 4,
    width: "100%",
    "& path": {
      fill: Colors.purple,
    },
  },
}))


interface DialogContentProps {
  flexDirection?: string
  overflowY?: string
}

interface LeftContentProps {
  width?: string
  height?: string
}

interface RightContentProps {
  width?: string
  height?: string
  padding?: string
  overflowY?: string
  bottom?: string
  margin?: string
  maxHeight?: string
}

interface BillingChipContainerProps {
  flexDirection?: string
  isMobileView?: boolean
}

interface MarqueesContainerProps {
  isMobile?: boolean
}

interface BottomBarProps {
  isMobileView?: boolean
}

interface FeatureListProps {
  isMobileView?: boolean
  marginTop?: string | null
}

interface CloseIconContainerProps {
  isMobileView?: boolean
}

export const DialogContent = styled.div<DialogContentProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  overflow-y: ${({ overflowY }) => overflowY};
  height: 100%;
`

export const LeftContent = styled.div<LeftContentProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  overflow: hidden;
`

export const RightContent = styled.div<RightContentProps>`
  display: flex;
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  flex-direction: column;
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "750px")};
  overflow-y: ${({ overflowY }) => overflowY};
  margin: ${({ margin }) => (margin ? margin : "0px")};
`

export const BillingChipContainer = styled.div<BillingChipContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: space-between;
  gap: ${({ isMobileView }) => (isMobileView ? "8px" : "8px")};
  margin-bottom: ${({ isMobileView }) => (isMobileView ? "4px" : "4px")};
  margin-top: ${({ isMobileView }) => (isMobileView ? "4px" : "4px")};
`

export const Feature = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`

export const FeatureIcon = styled.div`
  padding: 4px;
  background-color: ${Colors.PAPER_ACCENT_CONTAINER};
  border-radius: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const FeatureList = styled.div<FeatureListProps>`
  margin-top:${({ marginTop }) => (marginTop ? marginTop : "18px")};
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const MarqueesContainer = styled.div<MarqueesContainerProps>`
  z-index: 10;
  position: relative;
  transform: ${({ isMobile }) =>
    isMobile ? "rotate(-6.149deg)" : "rotate(-22deg)"};
  display: flex;
  flex-direction: column;
  bottom: ${({ isMobile }) => (isMobile ? "200px" : "100px")};
  right: 124px;
`

export const MarqueeItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SpotifyIconContainer = styled.div`
  background-color: ${Colors.green}14;
  border-radius: 8px;
  padding: 2px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ViewAllButton = styled.button`
  display: flex;
`

export const FlexContainer = styled(Container)`
  display: flex;
  gap: ${({ gap }) => {
    if (typeof gap === "number") {
      return `${gap}px`
    }
    if (typeof gap === "string") {
      return gap
    }
    return undefined
  }};
`

export const FixedContainer = styled.div`
  align-items: center;
  padding: 8px 24px;
  display: inline-flex;
  background-color: ${Colors.PAPER_BACKGROUND_CONTAINER};
  justify-content: space-between;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`


export const BottomBar = styled.div<BottomBarProps>`
  position: sticky;
  bottom: 0px;
  margin-left: ${({ isMobileView }) => (isMobileView ? "-18px" : "-32px")};
  margin-right: ${({ isMobileView }) => (isMobileView ? "-18px" : "-32px")};
  border-top: 1px solid ${Colors.divider};
  width: ${({ isMobileView }) => (isMobileView ? "calc(100% + 36px)" : "calc(100% + 64px)")};
`
export const CloseIconContainer = styled.div<CloseIconContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${({ isMobileView }) =>
    isMobileView ? "rgba(255, 255, 255, 0.16)" : "rgba(0, 0, 0, 0.08)"};
  border-radius: 9999px;
  cursor: pointer;
  align-self: flex-end;
  flex-shrink: 0;
  z-index: 10;
  position: ${({ isMobileView }) => (isMobileView ? "absolute" : undefined)};
  z-index: 10;
  top: ${({ isMobileView }) => (isMobileView ? "24px" : undefined)};
  right: ${({ isMobileView }) => (isMobileView ? "16px" : undefined)};
`

export const StyledSpan = styled.span`
  color: ${Colors.purple};
`

export const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export default useStyles
