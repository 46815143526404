import { MenuItem, Select, TextField } from "@material-ui/core";
import SecondaryButton from "components/shareable/SecondaryButton";
import { Body2, Subtitle2 } from "components/shareable/Typography";
import { SHARED_WEBSITE_STYLES, WebsiteEditorCard } from "pages/post-auth/Website/styles";
import { Container, TextButton } from "styles/shared";
import { useStyles } from "../../styles";
import { StreamingLinkInputs } from "../StreamingLinkInputs";
import Text from 'modules/Text';
import { checkIsValidUrl, deepCopy, objectToArray, showToast } from "modules/Utils";
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { StreamingLinkTypeOptions, WebsiteLink, WebsiteLinks, getIconBackgroundStyle, websiteLinkInitObj } from "../../utils";
import { SocialMediaIconsInputs, renderIcon } from "../SocialMediaIconsInputs";
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { STREAMLINK_REGEX, streamingLinkNameMapping } from "helpers/StreamingMappings";
import { debounce } from "lodash"
import { clsx } from 'modules/Utils';
import Axios from "helpers/Interceptor";
import useScreen from "Hooks/useScreen";
import Colors from "modules/Colors";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import SwitchSymphony from "components/shareable/SwitchSymphony";

export type StreamingRegexType = keyof typeof STREAMLINK_REGEX
export type StreamingNameType = keyof typeof streamingLinkNameMapping

export const MusicSiteSetupSection = () => {
  const classes = useStyles();
  const { isProBrand } = useContext(CurrentBrandContext);
  const { mobileView } = useScreen();

  const sharedClasses = SHARED_WEBSITE_STYLES();

  const {
    subtitle, setSubtitle,
    streamingLinks = {}, setStreamingLinks,
    socialMediaIcons = {}, setSocialMediaIcons,
    titleText, setTitleText,
    showSecondaryLine, setShowSecondaryLine,
    customLinksAdded, setCustomLinksAdded,
    isNewLink, setIsNewLink,
    enableSocialIcons, setEnableSocialIcons,
    customLinkOptions, setCustomLinkOptions,
  } = useContext(WebsiteContext);

  const [customLinkUrlText, setCustomLinkUrlText] = useState<string>('')
  const [customButtonText, setCustomButtonText] = useState<string>('')
  const [customLinkSelected, setCustomLinkSelected] = useState<WebsiteLink>(customLinkOptions[0])
  const [invalidUrl, setInvalidUrl] = useState<boolean>(false)
  const [isRefreshingLinks, setIsRefreshingLinks] = useState<boolean>(false)

  useEffect(() => {
    if (!titleText) setTitleText('Listen to');
  }, [])

  useEffect(() => {
    checkIfInvalidUrl()
  }, [customLinkSelected, customLinkUrlText])

  const selectCustomLink = (option: string) => {
    const customLink = {
      ...websiteLinkInitObj,
      title: option,
      key: option,
      url: customLinkUrlText,
    }
    setCustomLinkSelected(customLink);
  };

  const handleStreamingLinkUrlChange = (value: string, streamingService: WebsiteLink) => {
    const isCustomLink = customLinksAdded?.find((link) => link.key === streamingService.key);

    if (isCustomLink) {
      const newCustomLinksAdded = deepCopy(customLinksAdded) as WebsiteLink[];
      const linkIndex = newCustomLinksAdded.findIndex((link) => link.key === streamingService.key);
      newCustomLinksAdded[linkIndex].url = value;
      setCustomLinksAdded(newCustomLinksAdded);
      return;
    }

    let newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
    newStreamingLinks[streamingService.key].url = value;
    setStreamingLinks(newStreamingLinks);
  };

  const handleStreamingLinkEnabledSwitch = (checked: any, streamingService: WebsiteLink) => {
    const isCustomLink = customLinksAdded?.find((link) => link.key === streamingService.key);

    if (isCustomLink) {
      const newCustomLinksAdded = deepCopy(customLinksAdded) as WebsiteLink[];
      const linkIndex = newCustomLinksAdded.findIndex((link) => link.key === streamingService.key);
      newCustomLinksAdded[linkIndex].enabled = checked;
      setCustomLinksAdded(newCustomLinksAdded);
    }

    let newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
    newStreamingLinks[streamingService.key].enabled = checked;
    setStreamingLinks(newStreamingLinks);
  };

  const onDeleteStreamingService = (streamingService: WebsiteLink) => {
    const isCustomLink = customLinksAdded?.find((link) => link.key === streamingService.key);

    if (isCustomLink) {
      const newCustomLinksAdded = deepCopy(customLinksAdded) as WebsiteLink[];
      const linkIndex = newCustomLinksAdded.findIndex((link) => link.key === streamingService.key);
      newCustomLinksAdded.splice(linkIndex, 1);
      setCustomLinksAdded(newCustomLinksAdded);
      return;
    }

    const newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
    delete newStreamingLinks[streamingService.key]
    setStreamingLinks(newStreamingLinks)
  }

  const onChangeCustomLinkUrl = (value: string): any => {
    debouncedCheckForValidStreamingService(value);
    setCustomLinkUrlText(value);
  }

  const checkForValidStreamingService = async (value: string) => {
    const validationRegex = Object.entries(STREAMLINK_REGEX)
    const regexMatch = validationRegex.find(([_, regex]) => regex.find((expresion) => expresion.test(value)))
    if (regexMatch) {
      const customLink = StreamingLinkTypeOptions.find((link) => link.key === regexMatch[0]);
      setCustomLinkSelected(customLink!);
    } else {
      setCustomLinkSelected({
        ...websiteLinkInitObj,
        title: 'Custom Link',
        key: 'custom_link',
        url: customLinkUrlText,
      });
    }
  }

  const checkIfInvalidUrl = () => {
    const validationRegex = STREAMLINK_REGEX[customLinkSelected?.key as StreamingRegexType]
    if (!validationRegex) {
      setInvalidUrl(!checkIsValidUrl(customLinkUrlText));
      return;
    }
    const isValid = validationRegex.find((expresion) => expresion.test(customLinkUrlText))
    setInvalidUrl(!Boolean(isValid))
  }

  const debouncedCheckForValidStreamingService = useMemo(
    () =>
      debounce(async (value: string) => {
        await checkForValidStreamingService(value)
      }, 500),
    []
  )

  const saveNewLink = () => {
    if (!customLinkSelected) return;

    const alreadyAdded = streamingLinks[customLinkSelected?.key]

    if (alreadyAdded) {
      const newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
      newStreamingLinks[customLinkSelected?.key].url = customLinkUrlText;
      setStreamingLinks(newStreamingLinks);
      setCustomLinkUrlText('');
      setIsNewLink(false);
      setCustomLinkSelected(customLinkOptions[0])
      return;
    }

    if (customButtonText.length) {
      const newStreamingLink = {
        ...customLinkSelected,
        url: customLinkUrlText,
        title: (customButtonText || customLinkSelected.title),
        key: (customButtonText || customLinkSelected.title),
        type: 'custom_link',
      }
      setCustomLinksAdded((prev) => prev?.concat(newStreamingLink));
      setStreamingLinks((prev) => ({ ...prev, [newStreamingLink.key]: newStreamingLink }));
      setCustomLinkUrlText('');
      setCustomButtonText('');
      setIsNewLink(false);
      return;
    }

    const newStreamingLink = {
      ...customLinkSelected,
      url: customLinkUrlText,
      title: (customButtonText || customLinkSelected.title),
    }

    const newCustomLinkOptions = deepCopy(customLinkOptions) as WebsiteLink[];
    const optionAddedIndex = newCustomLinkOptions.findIndex((link) => link.key === customLinkSelected.key);
    newCustomLinkOptions.splice(optionAddedIndex, 1);

    setStreamingLinks((prev) => ({ ...prev, [newStreamingLink.key]: newStreamingLink }));
    setCustomLinkSelected(customLinkOptions[0])
    setCustomLinkOptions(newCustomLinkOptions);
    setCustomLinkUrlText('');
    setIsNewLink(false);
  };

  const handleSocialMediaUrlChange = (value: string, sm: WebsiteLink) => {
    const socialMediaUrl = value;
    let newSocialMediaIcons = deepCopy(socialMediaIcons) as WebsiteLinks;

    newSocialMediaIcons[sm.key].url = socialMediaUrl;
    setSocialMediaIcons(newSocialMediaIcons);
  };

  const handleSocialMediaEnabledSwitch = (checked: any, sm: WebsiteLink) => {
    const isEnabled = checked;
    let newSocialMediaIcons = deepCopy(socialMediaIcons) as WebsiteLinks;

    newSocialMediaIcons[sm.key].enabled = isEnabled;
    setSocialMediaIcons(newSocialMediaIcons);
  };


  const handleOnClickNewLink = () => {
    if (!customLinkSelected) {
      setCustomLinkSelected(
        customLinkOptions[0] || {
          ...websiteLinkInitObj,
          title: 'Custom Link',
          key: 'custom_link',
          type: 'custom_link',
        }
      );
    }
    setIsNewLink(true);
  }

  const handleRefreshLinks = async () => {
    if (isRefreshingLinks) {
      return
    }

    try {
      setIsRefreshingLinks(true)

      const res = await Axios.post(`/spotify/track/streaming-links`, { spotifyTrackUrl: streamingLinks.spotify.url })
      const streamingLinksFromRefresh = res.data.data
      if (streamingLinksFromRefresh && streamingLinksFromRefresh.length > 0) {
        const newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
        streamingLinksFromRefresh.forEach((link: any) => {
          const name = link.name.toLowerCase();
          if (newStreamingLinks[name]) {
            newStreamingLinks[name] = {
              ...newStreamingLinks[name],
              url: link.url
            };
          } else {
            const mappedName = streamingLinkNameMapping[name as StreamingNameType] || name;
            newStreamingLinks[name] = {
              ...websiteLinkInitObj,
              title: mappedName,
              key: name,
              url: link.url,
            };
          }
        });
        setStreamingLinks(newStreamingLinks);
        showToast({
          message: 'Successfully updated links',
          mobile: mobileView,
        });
      }
    } catch (error) {
      console.error(error)
      showToast({
        message: "We couldn't refresh streaming links for your release. Please try again later or contact support.",
        error: true,
        mobile: mobileView,
      })
    } finally {
      setIsRefreshingLinks(false)
    }
  }

  const streamingLinksArray = objectToArray(streamingLinks);

  function renderLinkEntry() {
    return (
      <div
        className={sharedClasses.innerPanelSection}
        style={{ display: 'flex', gap: 24, flexDirection: 'column', marginBottom: 0 }}
      >
        <Body2 color={Colors.greyDark}>
          Add information about your link.
        </Body2>
        <div
          className='inputTitle'
          style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        >
          <Subtitle2>URL</Subtitle2>
          <TextField
            variant='outlined'
            inputProps={{ className: classes.socialMediaInput }}
            value={customLinkUrlText}
            placeholder='Enter your link / URL'
            onChange={(e) => onChangeCustomLinkUrl(e.target.value)}
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        >
          <Subtitle2>Link Type</Subtitle2>
          <div
            style={{ width: '100%', display: 'flex', gap: 8 }}
          >
            <div
              className={classes.iconBackground}
              style={getIconBackgroundStyle(customLinkSelected)}
            >
              {renderIcon(true, customLinkSelected)}
            </div>
            <Select
              placeholder='Select type of link'
              value={customLinkSelected?.key}
              onChange={(event, value) => {
                const selected = (value as ReactElement).props
                selectCustomLink(selected.value)
              }}
              renderValue={(selected) => {
                if (String(selected).length === 0) {
                  return <em>Select your link type</em>;
                }
                return streamingLinkNameMapping[selected as StreamingNameType] || 'Custom Link';
              }}
              variant="outlined"
              style={{ width: '100%', height: 45 }}
              onEmptied={() => <>You already added all streaming links</>}
            >
              <MenuItem value="custom_link">
                Custom Link
              </MenuItem>
              {customLinkOptions.map((option) => <MenuItem value={option.key}>{option.title}</MenuItem>)}
            </Select>
          </div>
        </div>
        {customLinkSelected.key === 'custom_link' && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            <Subtitle2>Button Text</Subtitle2>
            <TextField
              variant='outlined'
              style={{ height: '45px', width: '100%' }}
              inputProps={{ style: { padding: '12px 16px' } }}
              placeholder='Add button text'
              value={customButtonText}
              onChange={(e) => setCustomButtonText(e.target.value)}
            />
          </div>
        )}
        <div className='flex gap-2'>
          <SecondaryButton
            text='Cancel'
            onClick={() => setIsNewLink(false)}
          />
          <SecondaryButton
            text='Save'
            onClick={() => saveNewLink()}
            disabled={Boolean(!customLinkSelected || !customLinkUrlText || invalidUrl || (customLinkSelected.key === 'custom_link' && !customButtonText.length))}
          />
        </div>
      </div>
    )
  }
  return (
    <div
      className={`musicSiteSetupSectionComponent ${classes.customizeWebsiteV2Variable}`}
    >
      <div className='w-full flex flex-col gap-4'>
        <WebsiteEditorCard title='Title'>
          <input
            className=''
            style={Text.input}
            type='text'
            value={titleText}
            placeholder='Title'
            onChange={(e) => setTitleText(e.target.value)}
          />
          <div className={classes.secondaryTextSection}>
            <SwitchSymphony
              checked={showSecondaryLine}
              onChange={() => setShowSecondaryLine(!showSecondaryLine)}
              className={clsx(showSecondaryLine && classes.switchEnabled)}
              label="Add secondary line"
            />
          </div>
          {showSecondaryLine && (
            <input
              style={Text.input}
              type='text'
              value={subtitle}
              placeholder='Secondary line'
              onChange={(e) => setSubtitle(e.target.value)}
            />
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard
          title='Streaming Links'
          className="streamingLinksTitle"
          subtitle="Add + edit your streaming service URLs and custom links."
        >

          <Container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            {(isProBrand || customLinksAdded?.length < 3) &&
              <div className='flex justify-center'>
                <ButtonSymphony
                  className='addNewInputLinkButton'
                  variant="outlined"
                  onClick={() => handleOnClickNewLink()}
                >
                  + Add New Link
                </ButtonSymphony>
              </div>
            }
            {streamingLinksArray.length > 0 && streamingLinks.spotify?.url &&
              <TextButton
                onClick={handleRefreshLinks}
                disabled={isRefreshingLinks}
              >
                {isRefreshingLinks ? 'Pulling new links...' : 'Refresh Links'}
              </TextButton>
            }

          </Container>
          {isNewLink && renderLinkEntry()}
          <StreamingLinkInputs
            handleStreamingLinkUrlChange={handleStreamingLinkUrlChange}
            handleStreamingLinkEnabledSwitch={handleStreamingLinkEnabledSwitch}
            onDeleteStreamingService={onDeleteStreamingService}
          />
        </WebsiteEditorCard>

        <WebsiteEditorCard title='Social platforms'
          subtitle="Add social icons to your website.">
          <div className='enableSocialIconsSwitch flex items-center'>
            <SwitchSymphony
              checked={enableSocialIcons}
              onChange={() => setEnableSocialIcons(!enableSocialIcons)}
              className={clsx(enableSocialIcons && classes.switchEnabled)}
              label="Show Social Icons"
            />
          </div>
          {enableSocialIcons && (
            <SocialMediaIconsInputs
              {...{
                socialMediaIcons,
                handleSocialMediaUrlChange,
                handleSocialMediaEnabledSwitch,
              }}
            />
          )}
        </WebsiteEditorCard>
      </div>
    </div>
  )
}