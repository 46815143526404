import { useContext } from "react";
import { StyledLabel } from "../../styles";
import AvatarUserStatus from "components/shareable/AvatarUserStatus";
import FreePlanView from "../FreePlanView";
import PaidPlanBillingView from "../PaidPlanBillingView";
import { OrganizationData } from "types/global";
import CurrencyDropdown from '../../../../../components/shareable/CurrencyDropdown';
import { CURRENCY_SYMBOLS, DEFAULT_CURRENCY } from "modules/Const";
import OrganizationProPlanView from "../PaidPlanBillingView/organization";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { Container } from "styles/shared";

interface BillingTabProps {
  organization?: OrganizationData;
  brandBelongsToOrganization?: boolean;
}

const BillingTab = ({
  organization,
  brandBelongsToOrganization
}: BillingTabProps) => {
  const { isPaidBrand, currentBrand } = useContext(CurrentBrandContext)

  if (!currentBrand) {
    return null
  }

  const {
    currency,
  } = currentBrand

  const renderPrimaryView = () => {
    // Helper function to render Pro/Free view based on user status
    const renderPlanView = () => {
      if (isPaidBrand) {
        return <PaidPlanBillingView
        />;
      }
      return <FreePlanView
      />;
    };

    // If organization is ready, check if brand belongs to org
    if (organization?.status === 'ready') {


      // Show org view if brand belongs to org, otherwise show plan view
      if (brandBelongsToOrganization) {
        return (
          <OrganizationProPlanView
            organization={organization}
          />
        )
      }

      return renderPlanView()
    }

    // Default to plan view if no org or org not ready
    return renderPlanView()
  }

  return (
    <Container>
      <StyledLabel>
        <Container
          margin="32px 0px 0px 0px"
          padding="0px 0px 24px 0px"
          borderBottom="1px solid #E5E7EB"
        >
          <StyledLabel fontSize={20}>Billing</StyledLabel>
        </Container>
      </StyledLabel>
      <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
        <AvatarUserStatus
          userContainerProps={{ gap: "4px" }}
          mainContainerProps={{ gap: "16px" }}
          userNameTextProps={{ fontSize: 18 }}
          proTextProps={{ fontSize: 12, color: "#E08507" }}
        />
      </Container>
      <CurrencyDropdown
        initialValue={currency?.code
          ? `${currency.code} (${CURRENCY_SYMBOLS[currency.code]})`
          : `${DEFAULT_CURRENCY} (${CURRENCY_SYMBOLS[DEFAULT_CURRENCY]})`
        }
      />
      {renderPrimaryView()}
    </Container>
  );
};

export default BillingTab;
