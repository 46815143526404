import { CSSProperties } from "react";
import { useCampaign } from "Hooks/useCampaign";
import { clsx } from "modules/Utils";

interface ContentCampaignViewProps {
    className?: string;
    style?: CSSProperties;
}

const ContentCampaignView = ({
    className = '',
    style = {}
}: ContentCampaignViewProps) => {
    const { campaign } = useCampaign();

    return (
        <div
            className={clsx("contentCampaignViewComponent", className)}
            style={{ ...sty.contentCampaignViewComponent, ...style }}
        >
            ContentCampaignView
        </div>
    )
}

const sty = {
    contentCampaignViewComponent: {
        width: '100%',
        height: '100%',
    }
}

export default ContentCampaignView;