import { Box, Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import Colors from "modules/Colors";
import { PlatformLogoContainerProps } from "./interfaces";

import { makeStyles, Theme } from "@material-ui/core";

export const MainTitleNewArtist = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 8px;
`;

export const SubTitleNewArtist = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  opacity: 0.56;
  margin-bottom: 24px;
`;

//

export const AnotherSocialPlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

export const CreateAccountWithoutSpotifyText = styled.p`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
  margin-top: 32px;
`;

export const PlatformsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;

export const PlatformButtonContainer = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  background: ${({ backgroundColor }: { backgroundColor: string }) =>
    backgroundColor};
  border-radius: 12px;
  cursor: pointer;
`;

/// artist

export const EnterArtistContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  gap: 24px;
`;

export const EnterArtistLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

export const ArtistInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;

export const PlatformLogoContainer = styled.div`
  background: ${({ backgroundColor }: PlatformLogoContainerProps) =>
    backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: ${({ padding }: PlatformLogoContainerProps) => padding};
  height: ${({ height }: PlatformLogoContainerProps) => height};
  min-width: ${({ width }: PlatformLogoContainerProps) => width};
  z-index: 10;
`;

export const CustomTab = styled(Tab)`
  text-transform: none !important;
  color: ${Colors.textColor};
  font-family: Arial;
  font-size: 16px;
`;

export const CustomBoxTab = styled(Box)`
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CustomTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${Colors.purple};
  }
`;

export const PlatformButtonYoutubeContainer = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;
  background: ${Colors.BACKGROUND_YOUTUBE_ICON};
`;

export const PlatformButtonTikTokContainer = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;
  background: ${Colors.BACKGROUND_TIKTOK_ICON};
`;

export const PlatformButtonInstagramContainer = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;
  background: ${Colors.GRADIENT_INSTAGRAM};
`;

export const IconStyled = styled.img``;

export const useStyles = makeStyles((_theme: Theme) => ({
  inputBorder: {
    borderRadius: 6,
    width: "100%",
    height: "100%",
  },
  formHelperText: {
    "&.MuiFormHelperText-root": {
      fontSize: 16,
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#8800FF",
      },
      "&:hover fieldset": {
        borderColor: "#8800FF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8800FF",
      },
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },

  // profile image
  imgStylesUserCircle: {
    width: "50px",
    height: "50px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  imgStylesUserSquare: {
    width: "70px",
    height: "70px",
    objectFit: "cover",
    borderRadius: "20%",
  },
  liProfileArtist: {
    borderBottom: `1px solid ${Colors.textColorWhite}`,
    paddingBottom: "10px",
  },
}));


export const NotArtistContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 10px;
  background: #f5f3f6;
  border-radius: 12px;
`;