import {
  FollowersData,
  Platforms,
  StreamsData,
  ConnectorIndicatorTypes,
} from "types/global";
import SpotifyIcon from "assets/images/streaming/square-icons/spotify-sqr.svg";
import InstagramIcon from "assets/images/insta.svg";
import ShazamIcon from "assets/images/shazam.svg";
import moment from "moment";
import { AttributionData, PlatformData } from "../../MusicStreams/CampaignDetails";
import dayjs from "dayjs";

export enum findMinMaxCases {
  minimum = "minimum",
  maximum = "maximum",
}

export interface ImpactStatsOption {
  alt: string;
  icon: any;
  mainLabel: string;
  unit: string;
  dataType: Platforms;
  connectorType: ConnectorIndicatorTypes;
  roundPercent: boolean;
  show?: boolean | ((value: number | boolean) => boolean);
  connect?: boolean;
  platformName: string;
  needsConnection?: boolean;
  hideCostLabel?: boolean;
  // if showAverage is true, the average value will be shown instead of the cost per growth value
  showAverage?: boolean;
}

export enum AvgLabelPlatforms {
  Follower = "Follower",
  Stream = "Stream",
  Shazam = "Shazam",
}

const { SPOTIFY_FOLLOWERS, SPOTIFY_STREAMS, INSTAGRAM_FOLLOWERS, SPOTIFY_PLAYLIST_FOLLOWERS, SHAZAMS } =
  ConnectorIndicatorTypes;

export const getPercentLabelColor = (value: number) =>
  value > 0 ? "green" : "gray";

export const getAverageCostPerUnit = (args: {
  totalBudget: number;
  totalLengthCampaignPeriod: number;
  growth: number;
  campaignStartDate: string;
  campaignEndDate: string;
}) => {
  const {
    totalBudget,
    totalLengthCampaignPeriod,
    growth,
    campaignStartDate,
    campaignEndDate,
  } = args;
  const today = dayjs();
  const isCampaignNotFinished = today.diff(campaignEndDate, "days") < 0;
  const diffDays = today.diff(campaignStartDate, "days");
  const totalGrowthPerDay = isCampaignNotFinished
    ? growth / diffDays
    : growth / totalLengthCampaignPeriod;

  if (growth && totalLengthCampaignPeriod) {
    const averageCostPerUnit =
      totalBudget / totalLengthCampaignPeriod / totalGrowthPerDay;
    return Number(averageCostPerUnit.toFixed(2));
  }
  return 0;
};

export const findClosest = (value: number, arr: number[]) => {
  const differences = arr.map((el) => Math.abs(el - value));
  const min = Math.min.apply(Math, differences);
  const index = differences.indexOf(min);
  const minimum = arr[index];
  const areEqual = minimum === 0;
  return { minimum, index, areEqual };
};

export const findMinMax = (arr: number[], find: findMinMaxCases) => {
  if (findMinMaxCases.maximum === find) return Math.max.apply(Math, arr);
  if (findMinMaxCases.minimum === find) return Math.min.apply(Math, arr);
  return undefined;
};

export const getFixedDates = (args: {
  type: ConnectorIndicatorTypes;
  date: string;
  campaignStartDate: string;
  count?: number;
}) => {
  const { type, date, campaignStartDate, count } = args;
  const prev = moment(date).subtract(1, "day");
  const midPrev = moment(prev).subtract(1, "day");
  const firstPrev = moment(midPrev).subtract(1, "day");
  const fixDates = [firstPrev, midPrev, prev].map((date) => ({
    type,
    day: moment(date).format("ddd"),
    count: count || 0,
    date: date.format("YYYY-MM-DD"),
    campaignStartDate,
    valueOf: moment(moment(date).format("YYYY-MM-DD")).valueOf(),
  }));

  return fixDates;
};

export const formatFollowersChartData = (args: {
  dataByType: FollowersData;
  type: ConnectorIndicatorTypes;
  startDate: string;
  renderCondition: boolean;
}) => {
  const {
    dataByType,
    type,
    startDate,
    renderCondition,
  } = args;
  const followersChartData = dataByType?.data?.chartData;
  const formatted = followersChartData?.map(({ datetime, fanbase_count }) => {
    const count = Number(fanbase_count) || 0;
    return {
      type,
      day: moment(datetime.split("T")[0]).format("ddd"),
      count,
      date: datetime,
      campaignStartDate: startDate,
      valueOf: moment(datetime.split("T")[0]).valueOf(),
    };
  });

  return {
    type,
    data: formatted,
    startDate,
    renderCondition,
  };
};

export const formatStreamsChartData = (args: {
  dataByType: StreamsData;
  type: ConnectorIndicatorTypes;
  startDate: string;
  renderCondition: boolean;
}) => {
  const {
    dataByType,
    type,
    startDate,
    renderCondition,
  } = args;
  const streamsChartData = dataByType?.data?.data;
  const formatted = streamsChartData?.map(({ datetime, views_stats }) => {
    const count = Number(views_stats) || 0;
    return {
      type,
      day: moment(datetime.split("T")[0]).format("ddd"),
      count,
      date: datetime,
      campaignStartDate: startDate,
      valueOf: moment(datetime.split("T")[0]).valueOf(),
    };
  });

  return {
    type,
    data: formatted,
    startDate,
    renderCondition,
  };
};

export const getChartData = (args: {
  data: FollowersData | StreamsData;
  platform: Platforms.followers | Platforms.streams;
  type: ConnectorIndicatorTypes;
  startDate: string;
  endDate: string;
  renderCondition: boolean;
}) => {
  let dataByType = null;
  const {
    data,
    platform,
    type,
    startDate,
    renderCondition,
  } = args;
  const chartDataArgs = {
    type,
    startDate,
    renderCondition,
  };

  if (platform === Platforms.followers) {
    dataByType = data as FollowersData;
    return formatFollowersChartData({
      dataByType,
      ...chartDataArgs,
    });
  } else {
    dataByType = data as StreamsData;
    return formatStreamsChartData({
      dataByType,
      ...chartDataArgs,
    });
  }
};

export const getCountIndicatorProps = (
  value: number,
  type?: ConnectorIndicatorTypes
) => {
  const mainLabel = value.toLocaleString();

  switch (type) {
    case SPOTIFY_FOLLOWERS:
      return {
        src: SpotifyIcon,
        alt: "spotify-icon.svg",
        mainLabel,
        secondaryLabel: "Spotify Followers",
      };
    case SPOTIFY_PLAYLIST_FOLLOWERS:
      return {
        src: SpotifyIcon,
        alt: "spotify-icon.svg",
        mainLabel,
        secondaryLabel: "Spotify Playlist Followers",
      };
    case SPOTIFY_STREAMS:
      return {
        src: SpotifyIcon,
        alt: "spotify-icon.svg",
        mainLabel,
        secondaryLabel: "Spotify Streams",
      };
    case SHAZAMS:
      return {
        src: ShazamIcon,
        alt: "shazam-icon.svg",
        mainLabel,
        secondaryLabel: "Shazams",
      };
    case INSTAGRAM_FOLLOWERS:
      return {
        src: InstagramIcon,
        alt: "shazam-icon.svg",
        mainLabel,
        secondaryLabel: "Instagram Followers",
      };

    default:
      return {
        src: "",
        alt: "",
        mainLabel: "",
        secondaryLabel: "",
      };
  }
};


export const getImpactStatsData = (args: {
  attributionData: AttributionData[];
  campaignLengthInDays: number;
  startDate: string;
  endDate: string;
  budget: number;
  isShazamConnected?: boolean;
  isInstagramConnected?: boolean;
}): {
  data: PlatformData;
  platform: Platforms;
  type: ConnectorIndicatorTypes;
  campaignLengthInDays: number;
  startDate: string;
  endDate: string;
  budget: number;
  renderCondition: boolean;
}[] => {
  const {
    attributionData,
    campaignLengthInDays,
    startDate,
    endDate,
    budget,
    isShazamConnected,
    isInstagramConnected,
  } = args;

  return attributionData.map((item) => {
    const { description, data } = item;
    let renderCondition = true;

    if (description.connectorType === "INSTAGRAM_FOLLOWERS") {
      renderCondition = Boolean(isInstagramConnected);
    }

    if (description.connectorType === "SHAZAMS") {
      renderCondition = Boolean(isShazamConnected);
    }

    return {
      data: data,
      platform: description.dataType, // assuming platform can be derived from description
      type: description.connectorType,
      campaignLengthInDays,
      startDate,
      endDate,
      budget,
      renderCondition: Boolean(renderCondition && description.show),
    };
  });
};

