export enum PlaybookStep {
    TARGETING = 'targeting',
    CONTENT = 'content',
    CONNECTIONS = 'connections',
    BUDGET = 'budget',
    FINALIZATION = 'finalization',

    RECORD_SELECTOR = 'record_selector',
    LINK_SELECTOR = 'link_selector',
    LINK_ENTRY = 'link_entry',
    PLAYLIST_SELECTOR = 'playlist_selector',
    VIDEO_SELECTOR = 'video_selector',
    PRESAVE_SELECTOR = 'presave_selector',
}

const {
    TARGETING,
    CONTENT,
    CONNECTIONS,
    BUDGET,
    FINALIZATION,

    RECORD_SELECTOR,
    LINK_SELECTOR,
    LINK_ENTRY,
    PLAYLIST_SELECTOR,
    VIDEO_SELECTOR,
    PRESAVE_SELECTOR,
} = PlaybookStep;

export interface PlaybookStepInfo {
    title: string;
    description: string;
}

export const PlaybookInfo: { [key in PlaybookStep]: PlaybookStepInfo } = {
    [TARGETING]: {
        title: 'Targeting',
        description: 'Targeting DESCRIPTION',
    },
    [CONTENT]: {
        title: 'Content',
        description: 'Content DESCRIPTION',
    },
    [CONNECTIONS]: {
        title: 'Connections',
        description: 'Connections DESCRIPTION',
    },
    [BUDGET]: {
        title: 'Budget',
        description: 'Budget DESCRIPTION',
    },
    [FINALIZATION]: {
        title: 'Finalization',
        description: 'Finalization DESCRIPTION',
    },
    [RECORD_SELECTOR]: {
        title: 'Record Selector',
        description: 'Record Selector DESCRIPTION',
    },
    [LINK_SELECTOR]: {
        title: 'Link Selector',
        description: 'Link Selector DESCRIPTION',
    },
    [LINK_ENTRY]: {
        title: 'Link Entry',
        description: 'Link Entry DESCRIPTION',
    },
    [PLAYLIST_SELECTOR]: {
        title: 'Playlist Selector',
        description: 'Playlist Selector DESCRIPTION',
    },
    [VIDEO_SELECTOR]: {
        title: 'Video Selector',
        description: 'Video Selector DESCRIPTION',
    },
    [PRESAVE_SELECTOR]: {
        title: 'Presave Selector',
        description: 'Presave Selector DESCRIPTION',
    },
}

export enum PlaybookType {
    INCREASE_MUSIC_STREAMS = 'increaseMusicStreams',
    INCREASE_VIDEO_VIEWS = 'increaseVideoViews',
    BOOST_INSTAGRAM_ENGAGEMENT = 'boostInstagramEngagement',
    GROW_PLAYLIST_FOLLOWERS = 'growPlaylistFollowers',
    INCREASE_LINK_CLICKS = 'increaseLinkClicks',
    GET_PLAYLISTED = 'getPlaylisted',
}

export interface Playbook {
    title: string;
    description: string;
    type: string;

    // NOTE: STEPS
    steps: PlaybookStep[];
}

const Playbooks: { [key in PlaybookType]: Playbook } = {
    [PlaybookType.INCREASE_MUSIC_STREAMS]: {
        title: 'Increase Music Streams',
        description: 'Increase music streams DESCRIPTION',
        type: 'increaseMusicStreams',
        steps: [
            RECORD_SELECTOR,
            TARGETING,
            CONTENT,
            CONNECTIONS,
            BUDGET,
            FINALIZATION,
        ],
    },
    [PlaybookType.INCREASE_VIDEO_VIEWS]: {
        title: 'Increase Video Views',
        description: 'Increase video views DESCRIPTION',
        type: 'increaseVideoViews',
        steps: [
            VIDEO_SELECTOR,
            TARGETING,
            CONTENT,
            CONNECTIONS,
            BUDGET,
            FINALIZATION,
        ],
    },
    [PlaybookType.BOOST_INSTAGRAM_ENGAGEMENT]: {
        title: 'Boost Instagram Engagement',
        description: 'Boost Instagram engagement DESCRIPTION',
        type: 'boostInstagramEngagement',
        steps: [
            RECORD_SELECTOR,
            TARGETING,
            CONTENT,
            CONNECTIONS,
            BUDGET,
            FINALIZATION,
        ],
    },
    [PlaybookType.GROW_PLAYLIST_FOLLOWERS]: {
        title: 'Grow Playlist Followers',
        description: 'Grow playlist followers DESCRIPTION',
        type: 'growPlaylistFollowers',
        steps: [
            RECORD_SELECTOR,
            TARGETING,
            CONTENT,
            CONNECTIONS,
            BUDGET,
            FINALIZATION,
        ],
    },
    [PlaybookType.INCREASE_LINK_CLICKS]: {
        title: 'Increase Link Clicks',
        description: 'Increase link clicks DESCRIPTION',
        type: 'increaseLinkClicks',
        steps: [
            LINK_SELECTOR,
            TARGETING,
            CONTENT,
            CONNECTIONS,
            BUDGET,
            FINALIZATION,
        ],
    },
    [PlaybookType.GET_PLAYLISTED]: {
        title: 'Get Playlisted',
        description: 'Get playlisted DESCRIPTION',
        type: 'getPlaylisted',
        steps: [
            PLAYLIST_SELECTOR,
            TARGETING,
            CONTENT,
            CONNECTIONS,
            BUDGET,
            FINALIZATION,
        ],
    },
}

export default Playbooks;