import useScreen from 'Hooks/useScreen';
import { ContentContainer, MainContainer, Subtitle, Title, TitlesContainer } from './styles';
import { clsx } from "modules/Utils";

interface Props {
  className?: string;
  title: string;
  subtitle?: string;
  useDefaultPadding?: boolean,
  padding?: string,
  titleTextPadding?: string,
  additionalButtons?: JSX.Element,
  additionalContent?: JSX.Element
}

const CardTitle = ({
  className = '',
  useDefaultPadding = true,
  padding,
  titleTextPadding,
  title,
  subtitle,
  additionalButtons,
  additionalContent,
}: Props) => {
  const { mobileView } = useScreen()

  return (
    <MainContainer
      className={clsx('cardTitleComponent', className)}
      mobile={mobileView}
      useDefaultPadding={useDefaultPadding}
      padding={padding}
    >
      <ContentContainer>
        <TitlesContainer
          mobile={mobileView}
          padding={titleTextPadding}
        >
          <Title>{title}</Title>
          {subtitle &&
            <Subtitle>{subtitle}</Subtitle>
          }
          {additionalContent && additionalContent}
        </TitlesContainer>
        {additionalButtons &&
          <div className='additionalButtonsContainer' style={{ width: '100%' }}>{additionalButtons}</div>
        }
      </ContentContainer>
      {/* TODO:Add design for this button when is ready */}
    </MainContainer>
  )
}

export default CardTitle;
