import { useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import {
  ButtonsContainer,
  FadeContainer,
  MainContainer,
  LinkText,
} from './styles';
import TertiaryButton from '../TertiaryButton';
import { SymphonyTooltip } from 'styles/shared';
import Logger from 'Logger';
import Colors from 'modules/Colors';
import useScreen from 'Hooks/useScreen';

interface Props {
  url: string;
  showShareButton: boolean;
  songName?: string;
  showEditButton?: boolean;
  width?: string;
  padding?: string;
  textColor?: string;
  buttonTextColor?: string;
  backgroundColor?: string;
  margin?: string;
  onOpenEditLinkModal?: () => void;
}

const LinkContainer = ({
  url,
  showShareButton,
  songName,
  showEditButton = false,
  width,
  padding,
  backgroundColor,
  textColor,
  buttonTextColor,
  margin,
  onOpenEditLinkModal,
}: Props) => {
  const { mobileView } = useScreen();

  const [fade, setFade] = useState<boolean>(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState<string>('Copy');
  const canShare = Boolean(navigator.share);

  const onClickCopy = () => {
    navigator.clipboard.writeText(url);
    setCopyButtonLabel('Copied!');
    setFade(true);
    setTimeout(() => {
      setCopyButtonLabel('Copy');
      setFade(false);
    }, 3000);
  };

  const onClickShare = () => {
    try {
      navigator.share({
        title: `Listen to ${songName}`,
        text: `Listen to ${songName}`,
        url,
      });
      setFade(true);
      setTimeout(() => {
        setFade(false);
      }, 3000);
    } catch (e) {
      Logger.error('This action is only suported on mobiles');
    }
  };

  const onClickNewTab = () => {
    window.open(url, '_blank');
  }

  return (
    <MainContainer
      width={width}
      padding={padding}
      background={backgroundColor}
      flexDirection={mobileView ? 'column' : 'row'}
      margin={margin}
    >
      <LinkText color={textColor}>{url}</LinkText>
      <ButtonsContainer
        width={mobileView ? '100%' : 'fit-content'}
        justifyContent={mobileView ? 'space-around' : undefined}
        alignItems={!mobileView ? 'center' : undefined}
      >
        {(showShareButton && canShare) && (
          <TertiaryButton
            text='Share'
            onClick={onClickShare}
            height='15px'
            textColor={buttonTextColor}
          />
        )}
        <TertiaryButton
          text='View'
          onClick={() => onClickNewTab()}
          textColor={buttonTextColor}
          height='15px'
        />
        <TertiaryButton
          text={copyButtonLabel}
          onClick={() => onClickCopy()}
          textColor={buttonTextColor}
          height='15px'
        />
        {showEditButton && (
          <SymphonyTooltip title='Edit URL Slug'>
            <EditIcon
              onClick={onOpenEditLinkModal}
              color={buttonTextColor || Colors.purple}
              cursor='pointer'
            />
          </SymphonyTooltip>
        )}
      </ButtonsContainer>

      {fade && <FadeContainer />}
    </MainContainer>
  );
};

export default LinkContainer;
