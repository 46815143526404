import { useContext, useState, useMemo, ReactNode, Fragment, CSSProperties, useEffect } from "react";
import SymphonyDefaultAnnotations from "components/shareable/SymphonyDefaultAnnotations";
import dayjs from "dayjs";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import millify from "millify";
import { GraphTooltip } from "pages/post-auth/DashboardModule/components/Graphs";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis, Legend } from "recharts";
import SymphonyDefaultAnnotationsModal from "components/shareable/SymphonyDefaultAnnotationsModal";

export interface AreaChartDataSet {
    key: string;
    color: string;
    title?: string;
    subtitle?: string;
    hideFromGraph?: boolean;
    summationSection?: boolean;
}

interface SAreaChartProps {
    className?: string;
    xAxisFormat?: 'date';
    data: {
        [key: string]: number | string;
    }[]
    xAxisKey: string;
    yAxisKey?: string;
    dataSets?: AreaChartDataSet[];
    width?: number;
    height?: number;
    xTickHeight?: number;
    yTickWidth?: number;
    showLegend?: boolean;
    startDate?: string;
    endDate?: string;
    showAnnotations?: any;
}

const Tick = (props: any) => {
    const { payload: { value } } = props;
    const color = value === "yourdata" ? "red" : "#666";

    return (
        <Text
            {...props}
            fill={color}
            style={{
                zIndex: 99
            }}
        >
            {millify(value, { precision: 2 })}
        </Text>
    )
}

const formatCamelCase = (str: string): string => {
    return str.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
};

export default function SAreaChart({
    className,
    data,
    xAxisKey,
    yAxisKey,
    dataSets,
    width = 500,
    height = 300,
    xTickHeight = 20,
    yTickWidth = 8,
    showLegend = false,
    startDate,
    endDate,
    showAnnotations = {}
}: SAreaChartProps) {
    const lineChartSvgId = `${Date.now()}-splitColor`

    const colors = ['#8800FF', '#00A3FF']; // Default and complementary color

    const { currentBrand } = useContext(CurrentBrandContext);

    const [showGridModal, setShowGridModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedIgPost, setSelectedIgPost] = useState(null);
    const [selectedSpotifyRelease, setSelectedSpotifyRelease] = useState(null);
    const [itemsArray, setItemsArray] = useState<any[]>([]);

    useEffect(() => {
        setShowGridModal(false)
    }, [startDate, endDate])

    // Filter data for rendering, but keep all data for tooltip
    const chartData = useMemo(() => {
        if (!dataSets) {
            return data
        }

        return data.map(item => {
            const newItem: { [key: string]: any } = { ...item };
            dataSets.forEach(dataSet => {
                if (dataSet.hideFromGraph) {
                    delete newItem[dataSet.key];
                }
            });

            if (newItem.date && typeof newItem.date === 'string') {
                newItem.date = dayjs(newItem.date).format('YYYY-MM-DD');
            }

            return newItem;
        });
    }, [data, dataSets]);

    const renderAreas = () => {
        if (dataSets) {
            return dataSets.filter(dataSet => !dataSet.hideFromGraph).map((dataSet, index) => (
                <Area
                    key={dataSet.key}
                    type="monotone"
                    dataKey={dataSet.key}
                    name={formatCamelCase(dataSet.key)}
                    strokeWidth={2}
                    stroke={dataSet.color || colors[index]}
                    fill={`url(#${lineChartSvgId}-${index})`}
                    activeDot={{ r: 8 }}
                />
            ));
        } else if (yAxisKey) {
            return (
                <Area
                    type="monotone"
                    dataKey={yAxisKey}
                    name={formatCamelCase(yAxisKey)}
                    strokeWidth={2}
                    stroke={colors[0]}
                    fill={`url(#${lineChartSvgId}-0)`}
                    activeDot={{ r: 8 }}
                />
            );
        }

        return null;
    };

    return (
        <div className="symphonyAreaChartComponent" style={sty.symphonyAreaChartComponent}>
            <SymphonyDefaultAnnotationsModal
                itemsArray={itemsArray}
                showGridModal={showGridModal}
                setShowGridModal={setShowGridModal}
                selectedCampaign={selectedCampaign}
                selectedIgPost={selectedIgPost}
                selectedSpotifyRelease={selectedSpotifyRelease}
                setSelectedCampaign={setSelectedCampaign}
                setSelectedIgPost={setSelectedIgPost}
                setSelectedSpotifyRelease={setSelectedSpotifyRelease}
            />
            <ResponsiveContainer
                className={`symphonyAreaChartResponsiveContainer ${className} mx-auto`}
                width="100%"
                height={window.innerWidth < 576 ? 250 : window.innerHeight * 0.3}
                minHeight="100px"
            >
                <AreaChart
                    width={width}
                    height={height}
                    data={chartData}
                    margin={{
                        top: 15,
                        right: 0,
                        left: window.innerWidth >= 768 ? 20 : 0,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid
                        stroke="#8800FF29"
                        strokeWidth={1.5}
                        strokeDasharray="8 8"
                    />
                    <XAxis
                        dy={12}
                        axisLine={false}
                        dataKey={xAxisKey}
                        tickLine={false}
                        minTickGap={window.innerWidth < 768 ? 20 : 30}
                        width={width}
                        height={xTickHeight}
                        tickFormatter={(value: any, index: number) => {
                            const date = dayjs(value).format("MM/DD")
                            return date !== 'Invalid Date' ? date : value
                        }}
                    />
                    <YAxis
                        mirror={window.innerWidth < 768}
                        height={height}
                        width={yTickWidth}
                        tick={<Tick />}
                        tickCount={window.innerWidth < 768 ? 3 : 4}
                        axisLine={false}
                        minTickGap={window.innerWidth < 768 ? 30 : 20}
                        tickLine={false}
                        allowDecimals={false}
                        domain={['auto', 'auto']}
                        dataKey={yAxisKey}
                    />
                    <Tooltip
                        cursor={{ stroke: '#8800FF', strokeWidth: 2 }}
                        content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                const dataPoint = data.find(item => item[xAxisKey] === label);
                                const regularItems: ReactNode[] = [];
                                const summationItems: ReactNode[] = [];

                                if (dataSets) {
                                    dataSets.forEach((dataSet, index) => {
                                        const item = (
                                            <Fragment key={index}>
                                                <strong style={{ fontWeight: 600 }}>{dataSet.title || formatCamelCase(dataSet.key)}</strong>
                                                {dataSet.subtitle ? (
                                                    <>
                                                        <div style={{ fontSize: '0.9em', color: '#666' }}>{dataSet.subtitle}</div>:
                                                    </>
                                                ) : ': '}
                                                {dataPoint && dataPoint[dataSet.key] !== undefined
                                                    ? dataPoint[dataSet.key].toLocaleString('en')
                                                    : 'N/A'}
                                                <br />
                                            </Fragment>
                                        );
                                        if (dataSet.summationSection) {
                                            summationItems.push(item);
                                        } else {
                                            regularItems.push(item);
                                        }
                                    });
                                } else if (yAxisKey) {
                                    regularItems.push(
                                        <Fragment key="single">
                                            <strong style={{ fontWeight: 600 }}>{formatCamelCase(yAxisKey)}</strong>:{' '}
                                            {payload[0]?.value?.toLocaleString('en')}
                                        </Fragment>
                                    );
                                }

                                return (
                                    <GraphTooltip
                                        title={dayjs(label).format('dd MM/DD')}
                                        value={
                                            <div>
                                                {regularItems}
                                                {summationItems.length > 0 &&
                                                    <>
                                                        <hr style={{ margin: '8px 0', borderTop: '1px solid #ccc' }} />
                                                        {summationItems}
                                                    </>
                                                }
                                            </div>
                                        }
                                    />
                                );
                            }
                            return null;
                        }}
                    />
                    {showLegend && <Legend wrapperStyle={{ bottom: -20 }} verticalAlign="bottom" align="center" formatter={(value) => formatCamelCase(value)} />}
                    {(dataSets || [{ key: yAxisKey || '', color: colors[0] }]).map((dataSet, index) => (
                        <defs key={`gradient-${index}`}>
                            <linearGradient
                                id={`${lineChartSvgId}-${index}`}
                                x1="0" y1="0" x2="0" y2="1"
                            >
                                <stop stopColor={colors[index]} stopOpacity={0.4} />
                                <stop offset="1" stopColor={colors[index]} stopOpacity={0.05} />
                            </linearGradient>
                        </defs>
                    ))}
                    {renderAreas()}
                    {SymphonyDefaultAnnotations({
                        currentBrand: currentBrand!,
                        startDate: startDate!,
                        endDate: endDate!,
                        showAnnotations,
                        setShowGridModal,
                        setSelectedCampaign,
                        setSelectedIgPost,
                        setSelectedSpotifyRelease,
                        setItemsArray
                    })}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

const sty = {
    symphonyAreaChartComponent: {
        position: 'relative'
    } as CSSProperties
}