import { ChangeEvent, useEffect, useState } from "react"
import { ReactComponent as TiktokIcon } from "assets/images/tiktok-bordered-icon.svg"
import { getTikTokUsernameFromUrl, checkIsValid } from "./TikTok/utils"
import { TikTokProfileForOnboarding } from "types/global"
import { track } from "analytics"
import useScreen from "Hooks/useScreen"
import ModalSymphony from "components/shareable/ModalSymphony"
import ButtonSymphony from "components/shareable/ButtonSymphony"
import TextInputSymphony from "components/shareable/TextInputSymphony"
import { clsx } from 'modules/Utils'

/**
 * Props for the TiktokConnectModal component
 * @interface Props
 */
interface Props {
  className?: string;
  /** Name of the brand */
  brandName?: string
  /** Currently selected TikTok profile */
  selectedTikTokProfile: TikTokProfileForOnboarding | null
  /** Callback to handle selecting a TikTok profile */
  selectTikTokProfile: (tiktokProfile: TikTokProfileForOnboarding) => void
  /** Whether the modal is open */
  open: boolean
  /** Callback to close the modal */
  closeModal: () => void
  /** Custom label for the connect button */
  buttonLabel?: string,
}

/** Error message shown for invalid TikTok URLs */
const INVALID_URL_MESSAGE = "Looks like this TikTok username or URL is invalid…"

/**
 * Formats the input value to extract username from either URL or direct username input
 * @param value - Input value (URL or username)
 * @returns Formatted TikTok data object
 */
const formatTikTokInput = (value: string) => {
  // Remove whitespace and convert to lowercase
  const cleanValue = value.trim().toLowerCase();

  // Handle URL format
  if (cleanValue.includes('tiktok.com/')) {
    return getTikTokUsernameFromUrl(cleanValue);
  }

  // Handle direct username format
  let username = cleanValue;
  // Remove @ if present
  if (username.startsWith('@')) {
    username = username.substring(1);
  }

  return {
    url: `https://tiktok.com/@${username}`,
    username: username
  };
};

/**
 * Modal component for connecting a TikTok account
 */
const TikTokConnectModal = ({
  className = '',
  brandName,
  selectedTikTokProfile,
  selectTikTokProfile,
  open,
  closeModal,
  buttonLabel,
}: Props) => {
  const { mobileView } = useScreen();

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState("")
  const [textFieldValue, setTextFieldValue] = useState<string>(selectedTikTokProfile?.username || "")
  const [formattedValue, setFormattedValue] = useState(selectedTikTokProfile || null)

  /**
   * Handles changes to the text input field
   * @param e - Change event from input
   */
  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTextFieldValue(value);

    if (value.length) {
      const formatted = formatTikTokInput(value);
      setFormattedValue(formatted);
      const isValidInput = checkIsValid(value, formatted);
      setError(!isValidInput ? INVALID_URL_MESSAGE : "");
    }
  };

  /**
   * Handles clicking the connect button
   */
  const handleOnClickConnectButton = async () => {
    setLoading(true)
    track("Linked TikTok")

    if (formattedValue) {

      //TODO: Add to backend
      /*
       let cloudinaryUrl = gravatar.url(user?.email as string, { protocol: 'https', d: 'retro' })
      try{ 
            const picture = await getTiktokPicture(formattedValue.username)
            cloudinaryUrl = await uploadProfilePicture(picture) as string
            if (cloudinaryUrl) {
                setFormattedValue({ ...formattedValue, picture: cloudinaryUrl })
            }
        } catch(e) {
            console.error("Error fetching Tiktok profile picture")
        }*/
      setFormattedValue({ ...formattedValue })
    }
    
    selectTikTokProfile({
      username: formattedValue?.username || "",
      url: formattedValue?.url || "",
      picture: formattedValue?.picture || ""
    })

    closeModal()
    setLoading(false)
  }

  useEffect(() => {
    setTextFieldValue(selectedTikTokProfile?.username || "")
  }, [selectedTikTokProfile])

  return (
    <ModalSymphony
      className={clsx('tikTokConnectModalComponent', className)}
      isOpen={open}
      headerIcon={<TiktokIcon width="32px" height="32px" />}
      onClose={closeModal}
      title="Connect TikTok"
      subtitle={`What is the name or URL for ${brandName || "your"} TikTok account?`}
      fullScreen={mobileView}
      subtitleStyle={{ marginBottom: 24 }}
    >
      <TextInputSymphony
        fullWidth
        value={textFieldValue}
        onChange={handleChangeTextField}
        placeholder="Enter username or TikTok URL"
      />
      <ButtonSymphony
        width="100%"
        style={{ marginTop: 16 }}
        disabled={
          !!loading ||
          !!error ||
          !textFieldValue ||
          textFieldValue === "" ||
          !checkIsValid(textFieldValue, formattedValue)
        }
        onClick={handleOnClickConnectButton}
      >
        {buttonLabel || "Connect"}
      </ButtonSymphony>
    </ModalSymphony>
  )
}

export default TikTokConnectModal
