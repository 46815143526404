import { FBLocation } from "../../MusicStreams/TargetingView/reducer";
import { LocationDetail } from "../MarketsDetail";
import { NameContainer, OptionContainer, OptionLabel, TypeText } from "./styles";
import LoadingLottie from 'components/Loader/LoadingLottie';

interface Props {
  value: FBLocation
  loading: boolean
  isSelected: boolean
  existingLocations: LocationDetail[]
  selectedLocations: FBLocation[]
  handleOnSelectLocation: (location: FBLocation) => void
}

const LocationCell = ({
  value,
  loading,
  isSelected,
  existingLocations,
  selectedLocations,
  handleOnSelectLocation,
}: Props) => {

  const checkLocations = () => {
    const isAdded = existingLocations.find((location) => location.name === value.name)
    const isSelected = selectedLocations.find((location) => location.name === value.name)
    return isAdded || isSelected
  }

  const isAdded = checkLocations()

  const handleOnAddLocation = () => {
    handleOnSelectLocation(value)
  }

  return (
    <OptionContainer>
      <NameContainer>
        <p>{value.name}{value.country_name ? ", " + value.country_name : ""}</p>
        <TypeText>{value.type}</TypeText>
      </NameContainer>
      {loading && isSelected &&
        <div>
          <LoadingLottie black width={20} height={20} />
        </div>
      }
      {!loading && !isSelected &&
          <OptionLabel onClick={handleOnAddLocation}>
            {isAdded ? "Added" : "Add"}
          </OptionLabel>
      }
    </OptionContainer>
  )
}

export default LocationCell