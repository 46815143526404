import { Theme, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { ContainerProps } from "styles/shared";

const useStyles = makeStyles((theme: Theme) => ({
  grayText: {
    color: "#707070",
    cursor: "pointer",
    fontSize: 16,
  },
  select: {
    height: 44,
    marginLeft: "auto",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: theme.spacing(1.75),
    },
    [theme.breakpoints.down(361)]: {
      width: "100%",
    },
  },
}));

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
}

export interface ImageProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  backgroundColor?: string;
  alignItems?: string;
  width?: number;
  height?: number;
  marginLeft?: string;
  borderRadius?: string;
  justifyContent?: string;
}

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
`;

export const Image = styled.img`
  gap: ${({ gap }: ImageProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ImageProps) => flexDirection};
  display: ${({ display }: ImageProps) => display};
  align-items: ${({ alignItems }: ImageProps) => alignItems};
  padding: ${({ padding }: ImageProps) => padding};
  background-color: ${({ backgroundColor }: ImageProps) => backgroundColor};
  width: ${({ width }: ImageProps) => width};
  margin-left: ${({ marginLeft }: ImageProps) => marginLeft};
  border-radius: ${({ borderRadius }: ImageProps) => borderRadius};
  justify-content: ${({ justifyContent }: ImageProps) => justifyContent};
  height: ${({ height }: ImageProps) => height};
`;

export const Container = styled.div`
  gap: ${({ gap }: ContainerProps) => gap || "0px"};
  cursor: ${({ cursor }: ContainerProps) => cursor || "default"};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  width: ${({ width }: ContainerProps) => width};
  margin-left: ${({ marginLeft }: ContainerProps) => marginLeft};
  border-radius: ${({ borderRadius }: ContainerProps) => borderRadius};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
  margin-top: ${({ marginTop }: ContainerProps) => marginTop};
  @media (max-width: 1280px) {
    flex-direction: ${({ responsiveFlexDirection }: ContainerProps) => responsiveFlexDirection};
  }
  height: ${({ height }: ContainerProps) => height};
`;

export default useStyles;
