import useStyles, {
  BodyContainer,
  ButtonsContainer,
  HeaderContainer,
  LiveIndicator,
  MainContainer,
  StepNumber,
} from "./styles"
import { Body2, Caption, Subtitle1, Subtitle2 } from "components/shareable/Typography"
import Colors from "modules/Colors"
import { ReactComponent as CheckedIcon } from "assets/images/tick-white.svg"
import { useTheme } from "@material-ui/core"
import LinkButton from "components/shareable/LinkButton"
import ErrorBanner from "../AdvancedSettingsTab/Components/ErrorBanner"
import useScreen from "Hooks/useScreen"

const { PAPER_ACCENT_CONTAINER } = Colors

interface Props {
  className?: string
  title: string
  completedTitle?: string
  description: string
  stepNumber?: number
  children: JSX.Element
  isSuccessful: boolean
  errorTitle?: string
  errorDescription?: string
  isCompleted: boolean
  showErrorView?: boolean
  hideErrorBanner?: boolean
  buttonText: string
  intercomButtonText?: string | null
  disabled?: boolean
  retriedText?: string
  showIntercomButton?: boolean
  padding?: string
  live?: boolean
  onSkipError?: () => void
  onHover?: () => void
  onClickHowToLinkButton?: () => void
  onClickErrorButton?: () => void
  onClickIntercomButton?: () => void
}

const ConversionsConfigStep = ({
  className = '',
  title,
  completedTitle,
  description,
  stepNumber,
  children,
  isSuccessful,
  errorTitle,
  errorDescription,
  isCompleted,
  showErrorView,
  hideErrorBanner,
  buttonText,
  intercomButtonText = 'Watch video',
  disabled,
  retriedText,
  showIntercomButton,
  padding,
  live,
  onSkipError,
  onHover,
  onClickHowToLinkButton,
  onClickErrorButton,
  onClickIntercomButton,
}: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { mobileView } = useScreen()

  return (
    <MainContainer className={`conversionsConfigStepComponent ${className}`} {...{ disabled, padding }} onMouseOver={onHover}>
      <HeaderContainer>
        {stepNumber && (
          <StepNumber background={isCompleted ? Colors.green : PAPER_ACCENT_CONTAINER}>
            {isCompleted ? (
              <CheckedIcon
                className={classes.svgChecked}
              />
            ) : (
              <Subtitle2 color={Colors.purple}>{String(stepNumber)}</Subtitle2>
            )}
          </StepNumber>
        )}
        <Subtitle1>{isCompleted && completedTitle ? completedTitle : title}</Subtitle1>
        {live && (
          <LiveIndicator>
            <Caption color={Colors.textColor}>LIVE</Caption>
          </LiveIndicator>
        )}
      </HeaderContainer>
      <BodyContainer paddingLeft={stepNumber ? '32px' : '0px'}>
        <Body2>{description}</Body2>
        {intercomButtonText &&
          <ButtonsContainer>
            {((mobileView && onClickIntercomButton) || showIntercomButton) &&
              <LinkButton onClick={onClickIntercomButton} text={intercomButtonText} />
            }
          </ButtonsContainer>
        }
        {children}
        {!isSuccessful && !hideErrorBanner && (
          <ErrorBanner
            {...{
              errorDescription,
              errorTitle,
              showErrorView,
              onClickErrorButton,
              onClickHowToLinkButton,
              buttonText,
              retriedText,
              onSkipError,
            }}
          />
        )}
      </BodyContainer>
    </MainContainer>
  )
}

export default ConversionsConfigStep
