import { useEffect } from "react";

/**
 * Custom hook to initialize and manage the Baremetrics cancellation widget
 * @param customerId - The customer's unique identifier
 * @param cancellationEventId - Event ID for cancellation callbacks
 */
function useBaremetricsCancellationWidget(
    customerId?: string | undefined | null, 
    cancellationEventId: string = "symphony:subscription-cancelled"
) {
    useEffect(() => {
        if (!customerId) return;

        // Track if callback was fired to prevent multiple calls
        let hasCalledBack = false;

        const params = {
            access_token_id: "3126ce03-b1c3-41d4-935b-894bc48e0237",
            customer_oid: customerId,
            callback_send: function (data: any) {
                 
                // Prevent multiple callbacks
                if (hasCalledBack) {
                    return;
                }
                
                hasCalledBack = true;
                window.dispatchEvent(new CustomEvent(cancellationEventId));
            },
            callback_error: function (error: any) {
                console.error('[Baremetrics] Error:', error);
            }
        };

        // Check if script already exists
        const existingScript = document.querySelector('script[src="https://baremetrics-barecancel.baremetrics.com/js/application.js"]');
        
        if (existingScript) {
            window.barecancel = { 
                created: true,
                params: params
            } as any;
            return;
        }

        const script = document.createElement('script');
        script.src = "https://baremetrics-barecancel.baremetrics.com/js/application.js";
        script.async = true;

        window.barecancel = { 
            created: true,
            params: params
        } as any;

        document.body.appendChild(script);

        return () => {
            hasCalledBack = false;
            const scriptToRemove = document.querySelector('script[src="https://baremetrics-barecancel.baremetrics.com/js/application.js"]');
            if (scriptToRemove) {
                scriptToRemove.remove();
            }
            if (window.barecancel) {
                delete window.barecancel;
            }
        };
    }, [customerId, cancellationEventId]);
}

export default useBaremetricsCancellationWidget