import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import Colors from "modules/Colors"

interface RowItemProps {
  gap?: string
  alignItems?: string
  justifyContent?: string
  flexDirection?: string
}

interface StatusCircleProps {
  verified?: boolean
}

interface GrayCircleProps {
  hover?: boolean
}

interface ItemContainerProps {
  marginTop?: string
}

export const useStyles = makeStyles(() => ({
  svgSuccess: {
    "& path": {
      stroke: Colors.green,
    },
  },
}))

export const Container = styled.div`
  padding: 16px;
  background-color: ${Colors.DARK_BLUE_PAPER_CONTAINER};
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const RowItem = styled.div<RowItemProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
`

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop};
  flex: 1;
`

export const StyledImage = styled.img``

export const GrayCircle = styled.div<GrayCircleProps>`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  background-color: ${Colors.greyDark};
  color: ${Colors.PAPER_CONTAINER};
  font-size: 12px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`

export const StatusCircle = styled.div<StatusCircleProps>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: ${({ verified }) =>
    verified ? Colors.DARK_SUCCESS : Colors.DARK_WARNING};
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: row-reverse;
`

export const DummyDiv = styled.div`
  width: 24px;
  height: 100%;
`
