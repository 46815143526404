import { useContext, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import useStyles from './styles';
import { Box } from '@material-ui/core';
import { getFanbaseParams, orderByDirection, orderByFields } from '../../constants';
import { Fan } from 'types/global';
import { getFanbase } from '../../api';
import { getColumnDefinition, getFormattedSort } from './utils';
import { track } from "analytics";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

const PAGE_SIZE = 25;

interface Props {
  openFanDetailModal: (fan: Fan) => void,
}

const CustomTable = ({
  openFanDetailModal,
}: Props) => {
  const { currentBrand, isProBrand } = useContext(CurrentBrandContext)
  const {
    id: brandId = 0
  } = currentBrand || {}

  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [fansData, setFansData] = useState<Fan[]>([])
  const [fansCount, setFansCount] = useState<number>(0)
  const [sortByField, setSortByField] = useState<string>(orderByFields.added);
  const [sortDirection, setSortDirection] = useState<string>(orderByDirection.DESC)

  const getFansData = async (params?: getFanbaseParams) => {
    setLoading(true);
    const { data: fanbaseData, error: errorFanbase } = await getFanbase(brandId, params)
    if (!errorFanbase) {
      const { fanbase, count } = fanbaseData;
      setFansData(fanbase);
      setFansCount(count);
    }
    setLoading(false);
  }

  const handleClickPagination = (newPage: number) => {
    setPage(newPage);
    getFansData({
      limit: PAGE_SIZE,
      offset: newPage * PAGE_SIZE,
      orderBy: sortByField,
      direction: sortDirection,
    });
  }

  const handleClickSort = (sortBy: GridSortModel) => {
    if (!sortBy.length) sortBy = [{ field: "added", sort: "desc" }];
    track("Sorted Fanbase Table", {
      field: sortBy[0].field,
    })
    const { orderBy, direction } = getFormattedSort(sortBy);
    setSortByField(orderBy);
    setSortDirection(direction);
    getFansData({
      limit: PAGE_SIZE,
      offset: page * PAGE_SIZE,
      orderBy,
      direction,
    })
  }

  const handleClickFan = (event: GridRowParams) => {
    const formattedValue = event.row as Fan;
    openFanDetailModal(formattedValue);
  }

  useEffect(() => {
    getFansData({
      limit: PAGE_SIZE,
      offset: page * PAGE_SIZE,
      orderBy: sortByField,
      direction: sortDirection,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: GridColDef[] = getColumnDefinition(isProBrand);

  const classes = useStyles();
  return (
    <Box className={classes.dataGridWrapper}>
      <DataGrid
        columns={columns}
        loading={loading}
        classes={{root: classes.dataGridRoot}}
        rows={fansData}
        rowCount={fansCount}
        pagination
        page={page}
        pageSize={PAGE_SIZE}
        paginationMode='server'
        rowsPerPageOptions={[PAGE_SIZE]}
        onPageChange={handleClickPagination}
        disableSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        disableExtendRowFullWidth
        sortingMode='server'
        onSortModelChange={handleClickSort}
        onRowClick={handleClickFan}
        autoHeight
        rowHeight={78}
      />
    </Box>
  );
}

export default CustomTable;
