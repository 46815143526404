import { TextField } from "@material-ui/core"
import {
  ChangeEvent,
  useState,
} from "react"
import PrimaryButton from "components/shareable/PrimaryButton"
import useStyles, { ActionsContainer, AlertContainer } from "./styles"
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import { Body2, Subtitle1 } from "components/shareable/Typography"
import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import { isValidEmail } from "modules/Utils"
import { Row } from "../UsersTab/utils"
import useScreen from "Hooks/useScreen"
import { PRO_PLAN_ADDITIONAL_SEAT_PRICE, PRO_PLAN_INCLUDED_SEATS } from "modules/Const"
import { addBrandUser, PulledUsers } from "services/symphonyApi/brandService"
import Colors from "modules/Colors"
import ButtonSymphony from "components/shareable/ButtonSymphony"

interface Props {
  brandId?: number | null
  open: boolean
  users: Row[]
  hasReachedLimit?: boolean
  onSaveUsers: (pulledUsers: PulledUsers) => void
  onClose: () => void
}

const AddUserModal = ({
  brandId,
  open,
  users,
  hasReachedLimit,
  onSaveUsers,
  onClose,
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useScreen()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")

  const currentlyUsedSeats = users.length
  let remainingSeats = PRO_PLAN_INCLUDED_SEATS - currentlyUsedSeats

  const handleAddUser = async () => {
    let sanitizedEmail = email.toLowerCase().trim()
    await addBrandUser({
      newUserEmail: sanitizedEmail,
      users,
      brandId,
      mobileView,
      onChangeNewUserEmail: setEmail,
      onLoading: setLoading,
      onSaveUsers,
    })
    onClose()
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setError(value.length > 0 && !isValidEmail(value))
    setEmail(value)
  }

  const handleClose = () => !loading && onClose()

  const getAlertProps = (hasReachedLimit?: boolean) => {
    return {
      type: hasReachedLimit ? "warning" : "success",
      subtitle: hasReachedLimit
        ? `You’ll be charged $${PRO_PLAN_ADDITIONAL_SEAT_PRICE} / month for this new user`
        : `You can invite ${remainingSeats} more user${remainingSeats > 1 ? 's' : ''} for free with Pro.`,
      body: `Every Pro account comes with ${PRO_PLAN_INCLUDED_SEATS} included users, and additional users can be added at any time for $${PRO_PLAN_ADDITIONAL_SEAT_PRICE} monthly.`
    }
  }

  const { type, body, subtitle } = getAlertProps(hasReachedLimit)

  return (
    <DialogPaper {...{ open }} onClose={handleClose}>
      <>
        <HeaderModal
          HeaderIcon={
            <UserIcon width={32} height={32} className={classes.userIcon} />
          }
          title="Add a new user"
          subtitle="Manage the users who have access to your profile."
          closeModal={handleClose}
        />
        <TextField
          {...{ error }}
          value={email}
          helperText={error && "Please type a valid email"}
          fullWidth
          placeholder="Email address"
          variant="outlined"
          onChange={handleChangeEmail}
        />
        {(
          <AlertContainer {...{ type }}>
            <Subtitle1>{subtitle}</Subtitle1>
            <Body2 color={Colors.textColor}>{body}</Body2>
          </AlertContainer>
        )}
        <ActionsContainer>
          <ButtonSymphony
            isLoading={loading}
            disabled={error || loading || !email.length}
            width={160}
            onClick={handleAddUser}
          >
            Send invite
          </ButtonSymphony>
        </ActionsContainer>
      </>
    </DialogPaper>
  )
}

export default AddUserModal
