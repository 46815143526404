import { useState } from "react"
import {
  ErrorBannerBody,
  ErrorBannerContainer,
} from "./styles"
import SecondaryButton from "components/shareable/SecondaryButton"
import { Subtitle2, Body2 } from "components/shareable/Typography"
import Colors from "modules/Colors"
import LinkButton from "components/shareable/LinkButton"
import { commonCardsProps } from '../../utils'
import ButtonSymphony from "components/shareable/ButtonSymphony"

interface Props {
  errorTitle?: string
  errorDescription?: string
  showErrorView?: boolean
  buttonText: string
  retriedText?: string
  onSkipError?: () => void
  onClickHowToLinkButton?: () => void
  onClickErrorButton?: () => void
}

const ErrorBanner = ({
  errorTitle,
  errorDescription,
  showErrorView,
  buttonText,
  retriedText,
  onSkipError,
  onClickHowToLinkButton,
  onClickErrorButton,
}: Props) => {
  const [showOnRetriedText, setShowOnRetriedText] = useState<boolean>(false)

  const handleOnClickErrorButton = () => {
    if (retriedText) setShowOnRetriedText(true)
    if (onClickErrorButton) onClickErrorButton()
  }

  return (
    <>
      {errorTitle && errorDescription ? (
        <ErrorBannerContainer>
          <ErrorBannerBody>
            <Subtitle2>{errorTitle}</Subtitle2>
            <Body2 color={Colors.textColor}>
              {errorDescription}
            </Body2>
            {showErrorView && (
              <LinkButton
                text="How do I link them?"
                onClick={onClickHowToLinkButton}
              />
            )}
          </ErrorBannerBody>
          {showErrorView &&
            <ButtonSymphony
              className="errorBannerButton1"
              color="black"
              onClick={onClickErrorButton}
              variant="outlined"
            >
              {buttonText}
            </ButtonSymphony>
          }
        </ErrorBannerContainer>
      ) : showErrorView &&
      <ButtonSymphony
        className="errorBannerButton2"
        onClick={handleOnClickErrorButton}
        color="black"
        variant="outlined"
      >
        {buttonText}
      </ButtonSymphony>
      }
      {(onSkipError && errorTitle !== commonCardsProps.facebookAdAccount.errorTitle)
        && <LinkButton text="I've added myself as an admin" onClick={onSkipError} />
      }
      {(showOnRetriedText && errorTitle !== commonCardsProps.facebookAdAccount.errorTitle) && (
        <Body2 color={Colors.red}>
          {retriedText}
        </Body2>
      )}
    </>
  )
}

export default ErrorBanner
