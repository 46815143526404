import { useContext, useState } from 'react';
import { Dialog } from '@mui/material';
import SharedMusicViewSelector from 'pages/post-auth/MarketingPage/Components/SuperboostModal/Components/shared-music-selector-view';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal';
import { useStyles } from './styles';
import { toast } from 'react-toastify';
import { TOAST_OPTIONS } from 'components/Navbar/Components/AdminOptions';
import { forceRelease } from 'services/symphonyApi/campaignService';
import useScreen from 'Hooks/useScreen';

interface ManuallyReleaseModalProps {
    open: boolean;
    contentSlug: string;
    onClose: () => void;
    refreshCampaignDetails: () => void;
}

export const ManuallyReleaseModal = ({
    open,
    contentSlug,
    onClose,
    refreshCampaignDetails,
}: ManuallyReleaseModalProps) => {
    const { currentBrand } = useContext(CurrentBrandContext)
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const { mobileView } = useScreen();

    const onReleaseSelected = async (spotifyId: string) => {
        setLoading(true)
        const response = await forceRelease(contentSlug, spotifyId)
        if (response) {
            toast.success('Content successfully released', TOAST_OPTIONS)
        } else {
            toast.error('Error releasing content.', TOAST_OPTIONS)
        }
        setLoading(false)
        await refreshCampaignDetails()
        onClose()
        return;
    }

    const handleChangeRecord = async (inputValue: string, slug: string | null) => {
        if (!slug) return
        await onReleaseSelected(slug)
    }

    return (
        <Dialog open={open} PaperProps={{ className: classes.paper }} fullWidth={mobileView}>
            <HeaderModal
                closeModal={onClose}
                title='Find your release'
                subtitle='Select the release you want to promote'
            />
            <SharedMusicViewSelector
                isSpotifyConnected={Boolean(currentBrand?.connections?.spotify_artist_page)}
                parentLoading={loading}
                letUserSearchSpotify
                handleChangeRecord={handleChangeRecord}
                options={['single', 'project']}
            />
        </Dialog>
    );
};