import { useContext } from "react"
import { InfoBanner } from "./styles"
import useStyles, { Card, StyledLabel, Icon } from "../../styles"
import Grid from "@material-ui/core/Grid"
import { PlaylistsRecord as Record } from "types/global"
import { clsx } from 'modules/Utils'
import SpotifyIcon from "assets/images/spotify.svg"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

type Tier = {
  tier: number
  cost: number
}

interface Props {
  record: Record | null
  tier: Tier
}

interface CampaignBannerTypeProps {
  hideIcons?: boolean
}

const CampaignTypeBanner = ({
  hideIcons,
}: CampaignBannerTypeProps) => (
  <InfoBanner
    width={hideIcons ? "170px" : undefined}
    minWidth={hideIcons ? "140px" : undefined}
  >
    {!hideIcons && (
      <Grid>
        <Icon width={20} height={20} alt="spotify-icon.svg" src={SpotifyIcon} />
      </Grid>
    )}
    <Grid>
      <StyledLabel
        textAlign="center"
        fontSize={12}
        fontWeight={400}
        width="160px"
      >
        Pitch Playlists on Spotify
      </StyledLabel>
    </Grid>
  </InfoBanner>
)

const HeadCard = ({ record, tier }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { currentBrand } = useContext(CurrentBrandContext)

  const hideIcons = useMediaQuery(theme.breakpoints.down(395))
  const isResponsive = useMediaQuery(theme.breakpoints.down(621))

  if (!currentBrand) {
    return null
  }

  const { name: brandName } = currentBrand
  const { thumbnail_url, name: recordName } = record || {}

  return (
    <Card>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container direction="column">
            <StyledLabel fontSize={18} fontWeight={600}>
              Review Campaign Details
            </StyledLabel>
            <StyledLabel
              fontSize={16}
              fontWeight={400}
              color="#707070"
              marginTop="8px"
            >
              Review your details to make sure everything looks good before we
              begin pitching your campaign to playlists.
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.space, classes.border)}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Icon
                  width={48}
                  alt="brand-icon.svg"
                  borderRadius="6px"
                  src={thumbnail_url}
                />
              </Grid>
              <Grid item xs>
                <Grid container direction="column">
                  {isResponsive && (
                    <Grid>
                      <CampaignTypeBanner {...{ hideIcons }} />
                    </Grid>
                  )}
                  <StyledLabel fontWeight={500} fontSize={18}>
                    {brandName} - {recordName}
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isResponsive && (
            <Grid item>
              <CampaignTypeBanner />
            </Grid>
          )}
        </Grid>
        <Grid
          className={classes.space}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={600}>
              Playlisting Tier
            </StyledLabel>
          </Grid>
          <Grid item>
            <StyledLabel marginTop="8px" fontSize={16} fontWeight={400}>
              {tier.tier.toLocaleString("en")} Followers across Playlists
            </StyledLabel>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HeadCard
