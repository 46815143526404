import { CSSProperties, useContext, useEffect, useState } from 'react'
import Colors from "modules/Colors"
import PrimaryButton from './shareable/PrimaryButton'
import { ReactComponent as CheckGreen } from "assets/images/check-green.svg"
import subscribeGemIcon from 'assets/images/subscribe-gem-icon.png'
import subscribeArrowIcon from 'assets/images/subscribe-arrow-icon.png'
import subscribeTeamIcon from 'assets/images/subscribe-team-icon.png'
import useScreen from 'Hooks/useScreen'
import { BillingCycle } from './shareable/UpgradeToProModal/utils'
import {
    PlanType,
    DEFAULT_CURRENCY,
    SUBSCRIPTION_BENEFITS,
    Perk
} from 'modules/Const'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { CurrentUserContext } from 'Hooks/CurrentUserContext'
import { AffiliateDetails, PaymentMethods } from 'types/global'
import { track } from 'analytics'
import {
    changeSubscriptionPlan,
    ChangeSubscriptionPlanParams,
    upgradePlanAndRedirectToCheckout,
    UpgradePlanAndRedirectToCheckoutParams
} from 'services/symphonyApi/subscriptionService'
import { getConvertedCurrency } from "services/symphonyApi"
import { getNumberWithDecimals } from "helpers/General"
import getSymbolFromCurrency from 'currency-symbol-map'
import { formatNumberToCurrency } from "modules/Utils"
import { useCurrentTeam } from 'Hooks/CurrentTeamContext'
import { toast } from 'react-toastify'
import { StripeFormattedData } from './shareable/UpgradeToProModal/api'
import { SHARED_TOAST_OPTIONS } from 'pages/post-auth/MarketingPage/Components/Modals/utils'
import { useCancellation } from 'Hooks/useCancellation'

/**
 * Props for the SubscriptionDetailsTile component
 * @interface SubscriptionDetailsTileProps
 */
interface SubscriptionDetailsTileProps {
    /** Type of subscription plan to display */
    subscriptionType: PlanType;
    /** Flag to show if this is the most popular plan */
    isMostPopular?: boolean;
    /** Selected billing cycle (monthly/annual) */
    billingCycle?: BillingCycle;
    /** Partner information for special discounts */
    fromPartner?: AffiliateDetails | null;
    /** Flag to highlight this subscription tile */
    isHighlighted?: boolean;
    /** Source of the checkout */
    checkout_source?: string;
    /** Styles for the tile */
    style?: CSSProperties;
    /** Callback function to be called when a user upgrades To Pro or downgrades to Lite successfully */
    onPlanChangedSuccess?: (tier: PlanType) => void;
    /** Whether this is a downgrade flow */
    isDowngrade?: boolean;
}


/**
 * SubscriptionDetailsTile Component
 * Renders a detailed tile for a subscription plan with pricing, features and subscription button
 */
const SubscriptionDetailsTile = ({
    subscriptionType = PlanType.LITE,
    isMostPopular = false,
    billingCycle = BillingCycle.MONTHLY,
    fromPartner,
    isHighlighted = false,
    checkout_source = '',
    style = {},
    onPlanChangedSuccess,
    isDowngrade = false
}: SubscriptionDetailsTileProps) => {
    const { mobileView } = useScreen()
    const { currentUser } = useContext(CurrentUserContext)
    const { handleCancellationFlow, cancellationButtonId, isCancelling } = useCancellation();

    const {
        currentBrand,
        isPaidBrand,
        isFreeBrand,
        isLiteBrand,
        isProBrand,
        reloadBrand,
        proFreeTrialRedeemed,
        liteFreeTrialRedeemed
    } = useContext(CurrentBrandContext)
    const { goToCheckout: goToTeamCheckout, freeTrialRedeemed: teamFreeTrialRedeemed } = useCurrentTeam();


    /** State for loading checkout */
    const [loadingCheckout, setLoadingCheckout] = useState(false)

    /** Current currency code for pricing display */
    const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY

    /** Current tier of the brand */
    const currentTier = currentBrand?.tier

    /** Gets the base USD price for a subscription plan */
    const getUsdPrice = (subscriptionType: PlanType, billingCycle: BillingCycle) => {
        switch (billingCycle) {
            case BillingCycle.MONTHLY:
                return SUBSCRIPTION_BENEFITS[subscriptionType].priceMonthly
            case BillingCycle.ANNUAL:
                return SUBSCRIPTION_BENEFITS[subscriptionType].priceAnnual
        }
    }

    const usdPrice = getUsdPrice(subscriptionType, billingCycle)

    /** State for storing the converted price in user's currency */
    const [userFacingPrice, setUserFacingPrice] = useState<number>(usdPrice)

    /** Converts USD price to user's selected currency */
    const convertToNewCurrencyPrice = async () => {
        if (currencyCode !== DEFAULT_CURRENCY) {
            const res = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: usdPrice })
            setUserFacingPrice(Number(getNumberWithDecimals(res, 2)))
        } else {
            setUserFacingPrice(usdPrice)
        }
    }

    useEffect(() => {
        convertToNewCurrencyPrice()
    }, [currencyCode, billingCycle])

    /**
     * Handles subscription button click for both upgrade and downgrade flows
     * 
     * Downgrade Flow:
     * - For Pro/Lite brands: Changes subscription plan via changeSubscriptionPlan()
     * - For Free plan: Redirects to Stripe portal for cancellation
     * 
     * Upgrade Flow:
     * - For Team plan: Redirects to team checkout with trial if eligible
     * - For other plans: Redirects to standard checkout with trial if eligible
     * 
     * @throws Error if subscription change fails
     */
    const handlePrimaryButtonClick = async () => {
        setLoadingCheckout(true);
        try {
            // Handle downgrade flow
            if (isDowngrade) {
                // Handle subscription changes between Pro and Lite plans
                if ((isPaidBrand) &&
                    (subscriptionType === PlanType.PRO || subscriptionType === PlanType.LITE)) {
                    // Determine trial eligibility - no trials for Lite plan
                    let isTrial = false
                    if (subscriptionType === PlanType.LITE) {
                        isTrial = !liteFreeTrialRedeemed()
                    } else if (subscriptionType === PlanType.PRO) {
                        isTrial = !proFreeTrialRedeemed()
                    }

                    // Prepare subscription change data
                    const data = {
                        brandSlug: currentBrand?.slug,
                        recurring_interval: billingCycle,
                        checkout_source: checkout_source,
                        plan_type: subscriptionType
                    } as ChangeSubscriptionPlanParams;

                    track("Subscription Plan Change Started");
                    const result = await changeSubscriptionPlan(data);

                    // Handle successful plan change
                    if (result.success) {
                        await reloadBrand()
                        if (onPlanChangedSuccess) {
                            onPlanChangedSuccess(result.tier as PlanType)
                        }
                    }
                }
                // If user is on Pro/Lite and downgrading to Free, redirect to cancellation portal
                else if ((isPaidBrand) && subscriptionType === PlanType.FREE) {
                    await handleCancellationFlow()
                }
            }
            // Handle upgrade flow 
            else {
                // Special handling for Team plan upgrades
                if (subscriptionType === PlanType.TEAM) {

                    track("Start Subscription Checkout", {
                        planType: subscriptionType,
                        billingCycle: billingCycle,
                        hasFreeTrial: teamFreeTrialRedeemed
                    })
                    // Redirect to team checkout with trial if eligible
                    await goToTeamCheckout({
                        recurring_interval: billingCycle,
                        brandSlug: currentBrand?.slug
                    });
                }
                // Handle other plan upgrades
                else {
                    // Determine trial eligibility - no trials for Lite plan
                    let isTrial = false
                    if (subscriptionType === PlanType.LITE) {
                        
                        isTrial = !liteFreeTrialRedeemed()
                    } else if (subscriptionType === PlanType.PRO) {
                        isTrial = !proFreeTrialRedeemed()
                    }

                    track("Start Subscription Checkout", {
                        planType: subscriptionType,
                        billingCycle: billingCycle,
                        hasFreeTrial: isTrial
                    })

                    // Prepare upgrade data
                    const data = {
                        recurring_interval: billingCycle,
                        plan_type: subscriptionType,
                        brandSlug: currentBrand?.slug,
                        checkout_source: checkout_source
                    } as UpgradePlanAndRedirectToCheckoutParams;

                    // Redirect to standard checkout
                    await upgradePlanAndRedirectToCheckout(data);
                }
            }
        } catch (error) {
            toast.error("There was an error processing your subscription.", SHARED_TOAST_OPTIONS);
        } finally {
            setLoadingCheckout(false);
        }
    }

    /** Gets appropriate button text based on trial status */
    const getButtonText = () => {

        let freeTrialRedeemed = false
        if (subscriptionType === PlanType.PRO) {
            freeTrialRedeemed = proFreeTrialRedeemed()
        } else if (subscriptionType === PlanType.LITE) {
            freeTrialRedeemed = liteFreeTrialRedeemed()
        } else if (subscriptionType === PlanType.TEAM) {
            freeTrialRedeemed = teamFreeTrialRedeemed
        }

        let defaultText = SUBSCRIPTION_BENEFITS[subscriptionType].buttonText;
        if (freeTrialRedeemed) {
            defaultText = SUBSCRIPTION_BENEFITS[subscriptionType].buttonText;
        } else {
            defaultText = SUBSCRIPTION_BENEFITS[subscriptionType].buttonFreeTrialText;
        }

        if (isDowngrade) {
            if (isFreeBrand) {
                if (subscriptionType === PlanType.LITE) {
                    return defaultText
                } else if (subscriptionType === PlanType.PRO) {
                    return defaultText
                } else {
                    return 'Cancel Subscription'
                }
            } else if (isLiteBrand) {
                if (subscriptionType === PlanType.LITE) {
                    return "Your Current Plan"
                } else if (subscriptionType === PlanType.PRO) {
                    return defaultText
                } else {
                    return 'Cancel Subscription'
                }
            } else if (isProBrand) {
                if (subscriptionType === PlanType.PRO) {
                    return "Your Current Plan"
                } else if (subscriptionType === PlanType.LITE) {
                    return defaultText
                } else {
                    return 'Cancel Subscription'
                }
            }
        }

        return defaultText

    }

    /** Renders the action button for the subscription tile */
    const renderActionButton = () => {
        // if its a downgrade flow and the plan is free,
        // show the "Cancel Subscription" button
        console.log("isDowngrade", isDowngrade)
        console.log("subscriptionType", subscriptionType)
        console.log("currentTier", currentTier)
        console.log("cancellationButtonId", cancellationButtonId)
        if (isDowngrade && (
            subscriptionType === PlanType.FREE &&
            currentTier !== PlanType.FREE
        )) {
            return (
                <>
                    <PrimaryButton
                        id={cancellationButtonId}
                        data-cancel-button={true}
                        disabled={isCancelling}
                        loading={isCancelling}
                        className="tileButton"
                        text="Cancel Subscription"
                        width="100%"
                        height="40px"
                        onClick={() => {
                            // if there is no cancellation button id, we need to handle the cancellation flow
                            // directly
                            if (!cancellationButtonId) {
                                handleCancellationFlow()
                            }
                        }}
                    />
                    <p style={sty.buttonDisclaimer}>Your subscription will be downgraded to free immediately upon cancellation.</p>
                </>
            )
        }
        if (currentTier === subscriptionType) {
            return (
                <PrimaryButton
                    disabled={true}
                    className="tileButton"
                    text="Your Current Plan"
                    width="100%"
                    height="40px"
                    onClick={() => { }}
                />
            )
        } else {
            return (
                <PrimaryButton
                    loading={loadingCheckout}
                    disabled={loadingCheckout}
                    className="tileButton"
                    text={getButtonText()}
                    width="100%"
                    height="40px"
                    onClick={handlePrimaryButtonClick}
                />
            )
        }
    }

    const renderTitle = () => {
        return (
            <div className="tileTitle" style={sty.tileTitle}>
                {renderSubscriptionIcon()}
                {SUBSCRIPTION_BENEFITS[subscriptionType].title}
            </div>
        )
    }


    const getPartnerDiscountText = () => {
        if (!fromPartner) return null
        const partnerDiscount = fromPartner?.discountAmount || 1

        const partnerDiscountPercentage = 100 - (partnerDiscount * 100)
        const basePartnerLabel = `${partnerDiscountPercentage}% off Monthly Pro for 12 months!`

        switch (fromPartner.partnerId) {
            case 'UM':
                return `UnitedMasters SELECT artists receive ${basePartnerLabel}`
            case 'Audiomack':
                return `Audiomack artists receive ${basePartnerLabel}`
            case 'CDBaby':
                return `CDBaby artists receive ${basePartnerLabel}`
            case 'Downtown':
                return `Downtown Music artists receive ${basePartnerLabel}`
            case 'Daily Playlists':
                return `Daily Playlists artists receive ${basePartnerLabel}`
            default:
                return basePartnerLabel
        }
    }

    /** Gets the discounted price based on partner discount */
    const getDiscountedPrice = (originalPrice: number) => {
        const partnerDiscount = fromPartner?.discountAmount || 1
        return originalPrice * partnerDiscount
    }
    /**
     * Renders the price section of the subscription tile
     * Handles both regular pricing and partner discount scenarios
     * Note: Partner discounts only apply to monthly Pro plans
     * @returns JSX.Element Price display with appropriate formatting and discount info
     */
    const renderPrice = () => {
        // Get currency symbol and check for partner discount
        const currencySymbol = getSymbolFromCurrency(currencyCode)
        const hasPartnerDiscount = Boolean(fromPartner) && billingCycle === BillingCycle.MONTHLY

        // Calculate prices based on billing cycle
        const basePrice = billingCycle === BillingCycle.MONTHLY ? userFacingPrice : userFacingPrice / 12
        const discountedPrice = hasPartnerDiscount ? getDiscountedPrice(userFacingPrice) : userFacingPrice
        const priceToFormat = billingCycle === BillingCycle.MONTHLY ? discountedPrice : discountedPrice / 12

        const userFacingPriceLocal = formatNumberToCurrency(priceToFormat, true)
        const originalPriceLocal = formatNumberToCurrency(basePrice, true)

        // Show discounted price for monthly plans with partner discount
        if (hasPartnerDiscount) {
            return (
                <>
                    <div className="tilePriceContainer" style={sty.tilePriceContainer}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <div style={sty.originalPrice}>
                                {currencySymbol}{originalPriceLocal}
                            </div>
                            <div className="tilePrice" style={sty.tilePrice}>
                                {currencySymbol}{userFacingPriceLocal}
                            </div>
                        </div>
                        <div className="tilePricePer" style={sty.tilePricePer}>
                            / month
                        </div>
                    </div>
                </>
            )
        } else {
            // Show regular pricing for non-discounted plans
            return (
                <>
                    <div className="tilePriceContainer" style={sty.tilePriceContainer}>
                        <div className="tilePrice" style={sty.tilePrice}>
                            {currencySymbol}{userFacingPriceLocal}
                        </div>
                        <div className="tilePricePer" style={sty.tilePricePer}>
                            / month
                        </div>
                    </div>
                    {/* Show annual price if annual billing selected */}
                    {billingCycle === BillingCycle.ANNUAL &&
                        <div className="tilePriceAnnual" style={sty.tilePriceAnnual}>
                            <div>{currencySymbol}{formatNumberToCurrency(userFacingPrice, true)}</div>
                            <div style={{ color: Colors.greyDark, fontSize: 12 }}>
                                / year billed upfront
                            </div>
                        </div>
                    }
                </>
            )
        }
    }

    const renderSubscriptionIcon = () => {
        switch (subscriptionType) {
            case PlanType.LITE:
                return (
                    <img
                        className="subscriptionIcon"
                        style={sty.subscriptionIcon}
                        src={subscribeArrowIcon}
                        alt="Lite Account"
                    />
                )
            case PlanType.PRO:
                return (
                    <img
                        className="subscriptionIcon"
                        style={sty.subscriptionIcon}
                        src={subscribeGemIcon}
                        alt="Pro Account"
                    />
                )
            case PlanType.TEAM:
                return (
                    <img
                        className="subscriptionIcon"
                        style={sty.subscriptionIcon}
                        src={subscribeTeamIcon}
                        alt="Team Account"
                    />
                )
        }
    }

    const renderSubtitle = () => {
        return (
            <div className="tileSubtitle" style={sty.tileSubtitle}>
                {SUBSCRIPTION_BENEFITS[subscriptionType].subtitle}
            </div>
        )
    }


    const renderMostPopularBanner = () => {
        const partnerText = getPartnerDiscountText()

        return (
            <div
                className="mostPopularBanner"
                style={{
                    ...sty.mostPopularBanner,
                    ...(fromPartner ? sty.partnerDiscountBanner : {})
                }}
            >
                {partnerText || 'MOST POPULAR'}
            </div>
        )
    }

    const renderDetails = () => {
        const perks = SUBSCRIPTION_BENEFITS[subscriptionType].perks;

        return (
            <div className="tileDetails" style={sty.tileDetails}>
                {perks.map((perk: Perk, index: number) => {
                    const {
                        info,
                        detail
                    } = perk;

                    return (
                        <div
                            key={`perk-${index}`}
                            className="perkContainer"
                            style={{
                                ...sty.perkContainer,
                                ...(mobileView ? { flexDirection: 'column', alignItems: 'flex-start' } : {})
                            }}
                        >
                            <div className="perkInfoContainer" style={sty.perkInfoContainer}>
                                <CheckGreen style={{ marginRight: 8, width: 18, flexShrink: 0 }} />
                                <div className="perkInfo" style={{
                                    ...sty.perkInfo,
                                }}>
                                    {info}
                                </div>
                            </div>
                            {detail &&
                                <div className="perkDetail" style={{
                                    ...sty.perkDetail,
                                    ...(mobileView ? {
                                        marginLeft: 26,
                                        textAlign: 'left'
                                    } : {})
                                }}>
                                    {detail}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div
            className="subscriptionDetailsTileComponent"
            style={{
                ...sty.subscriptionDetailsTileComponent,
                ...(isHighlighted ? { backgroundColor: Colors.purpleLighter } : {}),
                ...style,
                ...(mobileView ? { maxWidth: '100%', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' } : {}),
            }}
        >
            {(isMostPopular || (fromPartner && billingCycle === BillingCycle.MONTHLY)) && renderMostPopularBanner()}
            <div
                className="subscriptionDetailsTileInner"
                style={sty.subscriptionDetailsTileInner}
            >
                {renderTitle()}
                {renderPrice()}
                {renderSubtitle()}
                {renderActionButton()}

            </div>

            {renderDetails()}
        </div>
    )
}

/** Component styles */
const sty = {
    subscriptionDetailsTileComponent: {
        width: '100%',
        maxWidth: 300,
        border: `1px solid ${Colors.DARK_DIVIDER_LINE}`,
        borderRadius: 16,
        backgroundColor: 'white',
        boxShadow: 'none',
    } as CSSProperties,
    subscriptionDetailsTileInner: {
        paddingTop: 28,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        borderBottom: `1px solid ${Colors.DARK_DIVIDER_LINE}`,
    } as CSSProperties,
    buttonDisclaimer: {
        fontSize: 12,
        color: Colors.greyDark,
        marginTop: 8,
        fontWeight: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
    } as CSSProperties,
    tileTitle: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 24,
        lineHeight: '24px',
        alignItems: 'flex-end',
        fontWeight: 500,
    } as CSSProperties,
    tilePriceContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
    } as CSSProperties,
    tilePrice: {
        fontSize: 40,
        fontWeight: 500,
    } as CSSProperties,
    tilePricePer: {
        alignSelf: 'center',
        color: Colors.greyDark,
    } as CSSProperties,
    tilePriceAnnual: {
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        alignItems: 'center',
        fontSize: 24,
        fontWeight: 500,
        color: Colors.greyDark,
    } as CSSProperties,
    tileSubtitle: {
        fontSize: 16,
        color: Colors.greyDark,
        minHeight: 60,
        lineHeight: '18px',
    } as CSSProperties,
    mostPopularBanner: {
        textAlign: 'center',
        width: '100%',
        backgroundColor: Colors.purpleLighter,
        color: Colors.purple,
        padding: '4px 8px',
        borderTopRightRadius: 16,
        borderTopLeftRadius: 16,
        fontSize: 10,
        fontWeight: 500,
        marginBottom: -23,
    } as CSSProperties,
    partnerDiscountBanner: {
        fontSize: 12,
        backgroundColor: '#FFD56B',
        color: '#000000',
    } as CSSProperties,
    tileDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        gap: 16,
    } as CSSProperties,
    perkContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    } as CSSProperties,
    perkInfo: {
        // maxWidth: '50%',
        color: Colors.greyDark,
    } as CSSProperties,
    perkInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    } as CSSProperties,
    perkDetail: {
        fontSize: 14,
        color: Colors.green,
        textAlign: 'right',
    } as CSSProperties,
    subscriptionIcon: {
        width: 32,
        height: 32,
        flexShrink: 0,
        marginRight: 8,
    } as CSSProperties,
    originalPrice: {
        textDecoration: 'line-through',
        color: Colors.greyDark,
        fontSize: 24,
    } as CSSProperties
}

export default SubscriptionDetailsTile