import { ChangeEvent, CSSProperties } from "react";
import Colors from "modules/Colors";
import LoaderV2 from 'components/Loader/LoaderV2';

/**
 * Props interface for the TextInputSymphony component
 */
interface TextInputSymphonyProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /** Current value of the text input */
    value: string;
    /** Handler for value changes */
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    /** Loading state to show/hide loading animation */
    loading?: boolean;
    /** Error message to display */
    error?: string;
    /** Additional className for styling */
    className?: string;
    /** Controls if the input takes full width */
    fullWidth?: boolean;
    /** Custom styles to override defaults */
    style?: CSSProperties;
    /** Maximum allowed length for the input */
    maxLength?: number;
    /** Helper text to display below the input */
    helperText?: string;
}

/**
 * A customized input component with Symphony styling and loading state
 */
const TextInputSymphony = ({
    value,
    onChange,
    loading = false,
    error,
    className = '',
    fullWidth = false,
    placeholder,
    style = {},
    maxLength,
    helperText,
    ...props
}: TextInputSymphonyProps) => {
    return (
        <>
            <style>
                {`
                    .textInputSymphonyWrapper input {
                        outline: 1px solid ${Colors.purple};
                        box-sizing: border-box;
                    }

                    .textInputSymphonyWrapper input::placeholder {
                        color: ${Colors.grey};
                    }

                    .textInputSymphonyWrapper input:focus {
                        outline: 2px solid ${Colors.purple};
                        outline-offset: -2px;
                    }

                    .textInputSymphonyWrapper input:hover {
                        border-color: ${Colors.purple};
                    }
                `}
            </style>
            <div
                className={`textInputSymphonyWrapper ${className}`}
                style={{
                    ...sty.wrapper,
                    ...(fullWidth && sty.fullWidth),
                    ...style
                }}
            >
                <div style={sty.inputContainer}>
                    <input
                        className="textInputSymphonyInput"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        style={{
                            ...sty.input,
                            ...(error && sty.inputError),
                            ...(loading && sty.inputWithLoader)
                        }}
                        {...props}
                    />
                    {loading && (
                        <div style={sty.loaderContainer}>
                            <LoaderV2 height={24} width={24} />
                        </div>
                    )}
                </div>
                {helperText && (
                    <div style={sty.helperText}>{helperText}</div>
                )}
                {error && (
                    <div style={sty.errorText}>{error}</div>
                )}
            </div>
        </>
    );
};

const sty: Record<string, CSSProperties> = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        width: 'fit-content'
    },
    fullWidth: {
        width: '100%'
    },
    inputContainer: {
        position: 'relative',
        width: '100%'
    },
    input: {
        width: '100%',
        height: 48,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 18,
        fontFamily: 'DIN',
        color: Colors.textColor,
        backgroundColor: 'white',
        borderRadius: 6
    },
    inputError: {
        borderColor: Colors.red
    },
    inputWithLoader: {
        paddingRight: 48
    },
    loaderContainer: {
        position: 'absolute',
        right: 12,
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorText: {
        color: Colors.red,
        fontSize: 16,
        fontFamily: 'DIN'
    }
};

export default TextInputSymphony;