import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import Axios from "helpers/Interceptor"
import {
  convertArrayDraftDataToSendAsObject,
  getSelectedCountries,
  STEPS
} from "../utils"
import debounce from "lodash/debounce"
import { CurrentBrand, SavingDraftStatus } from "types/global"
import { saveDraft } from "../api"
import { track } from "analytics"
import { DEBOUNCE_SAVE_DRAFT_TIME } from "modules/Const"

const { FIRST_TIME_DRAFT_DATA_LOAD, SAVING_NEXT_DRAFT } = SavingDraftStatus

export const useDraftDataGetter = (args: {
  setters: Record<string, Dispatch<SetStateAction<unknown>>>
  draft?: string | null
}) => {
  const { setters, draft } = args
  const {
    setSelectedCountries,
    setGeographyType,
    setCampaignType,
    setAudiences,
    setRecordSelected,
    setBudgetValue,
    setStartDate,
    setEndDate,
    setSelectedTab,
    setRetrievingDraftData,
    setCampaignDraftData,
    setIsSavingDraft,
  } = setters

  useEffect(() => {
    const getDraft = async (draft: string) => {
      try {
        const response = await Axios.get(`campaign/${draft}`)
        const data = response.data.data
        const {
          record,
          logistics,
          targeting,
          targetingType,
          geographies,
          saved_draft_step,
        } = data.campaign_metadata
        const { budget, startDate, endDate } = logistics
        const { type: geographyType, countries } = geographies
        const selectedCountries = getSelectedCountries(countries || [])

        track("Continued Creating Campaign from Draft", {
          type: "increase_video_views",
          step: saved_draft_step || STEPS.CREATIVE,
        })

        setSelectedCountries(selectedCountries)
        setGeographyType(geographyType)
        setCampaignType(targetingType)
        setAudiences(targeting)
        setRecordSelected(record)
        setBudgetValue(budget)
        setStartDate(startDate ? new Date(startDate) : null)
        setEndDate(endDate ? new Date(endDate) : null)
        setSelectedTab(saved_draft_step || STEPS.CREATIVE)
        setRetrievingDraftData(false)
        setCampaignDraftData(data)
      } catch (error) {
        console.log("error retrieving draft campaign data: ", error)
      }
    }

    if (draft) getDraft(draft) 
    setIsSavingDraft(false)
  }, [draft])
}

export const useDraftDataSetter = (args: {
  setters: Record<string, Dispatch<unknown>>
  campaignDraftData?: Record<string, unknown> | null
  requiredDataChanged: boolean
  retrievingDraftData: boolean
  sparkCode: string | null
  dataToSaveDraft: Record<string, unknown>[]
  draft: string | null 
  currentBrand: CurrentBrand | undefined
  savingDraftStatus: number
}) => {
  const {
    setters,
    campaignDraftData,
    requiredDataChanged,
    sparkCode,
    retrievingDraftData,
    dataToSaveDraft,
    draft, 
    currentBrand,
    savingDraftStatus
  } = args
  const {
    setRequiredDataChanged,
    setIsSavingDraft,
    setCampaignDraftData,
    setSavingDraftStatus,
  } = setters

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(
        async (
          dataToSend: Record<string, unknown>,
          campaignDraftData?: Record<string, unknown> | null
        ) => {
          if (draft && !campaignDraftData) return setIsSavingDraft(false)

          const { data, error } = await saveDraft({ dataToSend, currentBrand, campaignDraftData })

          if (!error && data) {
            const draftData = data as { campaign_metadata: Record<string, unknown> }
            const step = draftData.campaign_metadata.saved_draft_step

            track("Saved Draft", { type: "increase_video_views", step })
            setCampaignDraftData(data)
          }
          setIsSavingDraft(false)
        },
        DEBOUNCE_SAVE_DRAFT_TIME
      ),
    [campaignDraftData]
  )

  useEffect(() => {
    setRequiredDataChanged(true)
  }, dataToSaveDraft)

  useEffect(() => {
    if (requiredDataChanged && sparkCode && !retrievingDraftData) {
      setSavingDraftStatus((prev: number) =>
        prev > FIRST_TIME_DRAFT_DATA_LOAD ? SAVING_NEXT_DRAFT : prev + 1
      )
    }
  }, [
    requiredDataChanged,
    sparkCode,
    retrievingDraftData,
    ...dataToSaveDraft,
  ])

  useEffect(() => {
    if (
      requiredDataChanged &&
      sparkCode &&
      !retrievingDraftData &&
      savingDraftStatus === SAVING_NEXT_DRAFT
    ) {
      const dataToSend = convertArrayDraftDataToSendAsObject(
        dataToSaveDraft as unknown as Record<string, unknown>[]
      )

      setIsSavingDraft(true)
      debouncedChangeHandler(dataToSend, campaignDraftData)
      setRequiredDataChanged(false)
    }
  }, [
    requiredDataChanged,
    sparkCode,
    retrievingDraftData,
    campaignDraftData,
    dataToSaveDraft,
  ])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])
}
