import { Body2 } from 'components/shareable/Typography'
import Colors from "modules/Colors"
import { StyledWarningDiv } from './styles'

export const BusinessManagerWarningMessage = () => {
  return (
    <StyledWarningDiv>
      <Body2 color={Colors.textColor}>
        You can only connect a Business Manager you have admin access to.
      </Body2>
    </StyledWarningDiv>
  )
}
