import { useEffect, useState } from 'react';
import { Popover, TextField } from '@mui/material';
import { ReactComponent as AddIcon } from "assets/images/circled-add.svg";
import { ReactComponent as SearchIcon } from "assets/images/search-icon-2.svg";

import { OrganizationBrand } from 'types/global';
import { useStyles } from './styles';
import { Body2, Subtitle2 } from 'components/shareable/Typography';
import { TextButton } from 'styles/shared';
import Avatar from '@mui/material/Avatar';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import LinkButton from 'components/shareable/LinkButton';
import { useCurrentTeam } from "Hooks/CurrentTeamContext";
import Colors from 'modules/Colors';
import ButtonSymphony from 'components/shareable/ButtonSymphony';

interface AddArtistsPopUpProps {
  addedArtists: OrganizationBrand[];
  onAddArtist: (artist: OrganizationBrand) => void;
}

export const AddArtistsPopUp = ({ addedArtists, onAddArtist }: AddArtistsPopUpProps) => {
  const { teamArtists } = useCurrentTeam();
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [artists, setArtists] = useState<OrganizationBrand[]>(teamArtists);

  useEffect(() => setArtists(teamArtists), [teamArtists]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (event.target.value.length > 1) {
      const filteredArtists = teamArtists.filter((artist) =>
        artist.name.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setArtists(filteredArtists);
    } else {
      setArtists(teamArtists);
    }
  };

  // Add this check to determine if all artists have been added
  const allArtistsAdded = teamArtists.length > 0 && teamArtists.every(artist =>
    addedArtists.some(addedArtist => addedArtist.slug === artist.slug)
  );

  // Close the popover if all artists have been added
  useEffect(() => {
    if (allArtistsAdded) setAnchorEl(null);
  }, [allArtistsAdded]);

  // Don't render the component if all artists have been added
  if (allArtistsAdded) return null;

  return (
    <>
      {!allArtistsAdded &&
        <ButtonSymphony
          iconLeft={<AddIcon className="cursor-pointer" height={24} width={24} />}
          onClick={event => setAnchorEl(event.currentTarget)}
          disabled={allArtistsAdded}
          variant="text"
        >
          Add a Profile
        </ButtonSymphony>
      }
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ style: { width: 345, maxHeight: 284 } }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="flex flex-col p-4 gap-4">
          <TextField
            value={searchTerm}
            type="search"
            variant="outlined"
            fullWidth
            className={classes.input}
            placeholder="Search profile"
            onChange={onSearchChange}
            InputProps={{ startAdornment: (<SearchIcon height={24} width={24} className="mr-3" />) }}
          />
          <div className="flex flex-col max-h-48 overflow-y-scroll">
            {artists.length === 0 && <Body2 color={Colors.grey}>No artists found</Body2>}
            {artists.map((artist) => (
              <ArtistToAddItem
                artist={artist}
                isAdded={Boolean(addedArtists.find(addedArtist => addedArtist.slug === artist.slug))}
                onAddArtist={onAddArtist}
              />
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

interface ArtistToAddItemProps {
  artist: OrganizationBrand;
  isAdded: boolean;
  onAddArtist: (artist: OrganizationBrand) => void;
}

export const ArtistToAddItem = ({ artist, isAdded, onAddArtist }: ArtistToAddItemProps) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <div className='flex gap-4'>
        <Avatar src={artist.profile_picture} sx={{ width: 24, height: 24 }} />
        <Body2 style={{
          textWrap: 'nowrap', maxWidth: 100, textOverflow: 'ellipsis', overflow: 'hidden'
        }} color={Colors.textColor}>{artist.name}</Body2>
        {artist.isPro && <UpgradeToProChip type='label' />}
      </div>
      <TextButton onClick={() => onAddArtist(artist)} disabled={isAdded}>
        <Subtitle2 color={isAdded ? Colors.grey : Colors.purple}>
          {isAdded ? 'Added' : 'Add'}
        </Subtitle2>
      </TextButton>
    </div>
  );
};