import { Caption } from "../Typography";
import { ColumnContainer } from "./styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Container, SymphonyTooltip } from "styles/shared";
import Intercom from "helpers/Intercom";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import DetailCard from "pages/post-auth/MarketingPage/PreSave/Details/DetailCard";
import { CurrencyCodesType } from "types/global";
import Colors from "modules/Colors";

interface WideCardStat {
    icon?: JSX.Element | string,
    primaryTitle: string;
    subtitle: JSX.Element | string;
    stats: {

        title: string;
        value: string | number;
        isMoney?: boolean;
    }[]
}
interface WideStatCardProps {
    goal?: string,
    stats: WideCardStat[]
    currencyCode?: CurrencyCodesType
}

export default function MainStatCard(props: WideStatCardProps) {
    const {
        goal,
        stats,
        currencyCode
    } = props
    const theme = useTheme();
    const setMobileView = useMediaQuery(theme.breakpoints.down(701));

    return (
        <>
            <Container
                display="flex"
                justifyContent="space-between"
                gap="8px"
                marginTop="16px"
                alignItems="flex-start"
                flexDirection={setMobileView ? "column" : "row"}
            >
                <div className="flex items-start">
                    <p className="text-lg font-semibold">Performance </p>
                    <button className="ml-1.5 w-6 h-6"
                        onClick={() => {
                            Intercom.openIGPerformanceStatsArticle()
                        }}>
                        <SymphonyTooltip className="block w-6 h-6 cursor-pointer" title={
                            "Click to learn more about what each of these stats mean."
                        } arrow>
                            <img
                                className="w-6 h-6"
                                alt="question-mark-icon.svg"
                                src={QuestionMarkIcon}
                            />
                        </SymphonyTooltip>
                    </button>
                </div>
                <Container gap="8px"
                    flexDirection={setMobileView ? "column" : "row"}
                    alignItems={setMobileView ? "flex-start" : "end"}
                    justifyContent={setMobileView ? "flex-start" : "end"}
                >
                    {goal && (
                        <Container
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            gap="8px"
                        >
                            <Container
                                display="flex"
                                padding="8px"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                borderRadius="8px"
                                backgroundColor={Colors.PAPER_BACKGROUND_CONTAINER}
                            >
                                <Caption color={Colors.greyDark}>Goal:&nbsp;</Caption>
                                <Caption color={Colors.textColor}>{goal}</Caption>
                            </Container>
                            <SymphonyTooltip
                                className="block w-6 h-6 cursor-pointer"
                                title="See more"
                                arrow
                                onClick={() => Intercom.OpenGrowConversionsArticle()}
                            >
                                <img
                                    className="w-6 h-6"
                                    alt="question-mark-icon.svg"
                                    src={QuestionMarkIcon}
                                />
                            </SymphonyTooltip>
                        </Container>
                    )}
                </Container>
            </Container>

            <ColumnContainer>
                {stats.map((cardStats: WideCardStat) => {

                    const {
                        icon,
                        primaryTitle,
                        subtitle,
                        stats
                    } = cardStats

                    const firstStat = stats[0]
                    const secondStat = stats[1] ? stats[1] : null

                    return (
                        <DetailCard
                            svgIcon={icon}
                            title={primaryTitle}
                            subtitle={
                                <Caption color={Colors.textColor}>
                                    {subtitle}
                                </Caption>
                            }
                            firstStatTitle={firstStat.title}
                            isMoney={firstStat.isMoney || false}

                            value={firstStat.value}

                            secondStatTitle={secondStat?.title || ""}
                            secondStatValue={secondStat?.value || ""}
                            isSecondStatMoney={secondStat?.isMoney}
                            currency={currencyCode}
                        />
                    )
                })}


            </ColumnContainer>

        </>
    )
}

