import Axios from "helpers/Interceptor"
import { AttributionDetails, RewardfulDetails } from "types/global";

/**
 * Updates user metadata with tracking and attribution information
 * @async
 * @param {string} userId - The ID of the user to update
 * @param {Object} metadata - The metadata to add or update
 * @param {string} [metadata.affiliate_referrer] - Partner/affiliate referral code
 * @param {AttributionDetails} [metadata.attribution] - Initial UTM attribution parameters
 * @param {AttributionDetails} [metadata.last_touch_attribution] - Most recent UTM parameters
 * @param {RewardfulDetails} [metadata.rewardful] - Rewardful referral tracking data
 * @returns {Promise<any>} Updated user data from the server
 */
export default async function updateUserMetadata(userId: string, metadata: {
    affiliate_referrer?: string;
    attribution?: AttributionDetails
    last_touch_attribution?: AttributionDetails
    rewardful?: RewardfulDetails
}) {
    const response = await Axios.post(`/user/${userId}/add-metadata`, {
        ...metadata,
    })
    const userData = response.data
    return userData
}