import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

import ListComponent from "components/marketing/HelperPopup/list-section";
import BadgeContainer from "../Components/BadgeCampaignTypeContainer/BadgeContainer";
import PLATFORMS_BADGE_ICONS from "../Components/BadgeCampaignTypeContainer/BADGE_ICONS";
import ContactUsHelpPopupSection from "components/marketing/HelperPopup/contact-us";

const PresaveHelperPopup = () => {
    return (
        <>
            <div className=" mb-6">
                <p className="text-lg">Boost your streams, grow Spotify followers, and collect fan emails using the free "Create a Pre-save" tool. </p>
                <div className="flex mt-3 text-sm gap-3">
                    <BadgeContainer text="Collect Fan Data"
                        imageUrls={[
                            PLATFORMS_BADGE_ICONS["fans"]
                        ]}
                    />
                    <BadgeContainer
                        text="Grow Streams + Followers"
                        imageUrls={[
                            PLATFORMS_BADGE_ICONS["spotify"]
                        ]}
                    />
                </div>
            </div>

            <ListComponent
                title="When to use it:"
                bulletPoints={[
                    "You want to grow your email list by collecting email addresses from your most engaged fans - via Spotify pre-saves.",
                    "You want to boost your week 1 streams."
                ]}
            />
            <ListComponent
                title="The Symphony Advantage:"
                bulletPoints={[
                    "One-click Spotify presaves: Symphony's exclusive presave integration with Spotify lets anyone presave without having to login, leading to a 30% higher pre-save rate when using Symphony.",
                    "Forever Saves: With Forever Saves, fans can subscribe to your future releases in one-click. This can lead to thousands of additional streams, with no work done on your end.",
                    "When a fan pre-saves using Symphony, you will also get the fan's name, email address, location, and preferred streaming service - giving you invaluable data on who your fans are and how they're interacting with your music."
                ]}
            />

            <ListComponent
                title="What you need to get started:"
                bulletPoints={[
                    "You should have your song's / album's UPC code, ISRC code, or Spotify URL."
                ]}
            />

            <ListComponent
                title="The Dashboard:"
                bulletPoints={[
                    "Track total pre-saves across all platforms.",
                    "Track geographic data, down to cities, of where your pre-saving fans are located.",
                    "Collected deep email data on your most engaged fans who pre-save, providing you unprecedented insight into your fanbase."
                ]}
                swiperImages={[
                    require("assets/images/onboarding/data-examples/dsp-breakdown-example.png").default,
                    require("assets/images/onboarding/data-examples/email-list-example.png").default,
                    require("assets/images/onboarding/data-examples/presave-countries-example.png").default,
                    require("assets/images/onboarding/data-examples/boosts-example.png").default
                    // Add other image paths as needed
                ]}
            />
            <ContactUsHelpPopupSection />
        </>
    )
}

export default PresaveHelperPopup;