import React, { CSSProperties, useState } from 'react';
import {
    getCookie,
    setCookie,
    getCircularReplacer,
    getUrlParam,
    styx,
    clsx,
    utilsGet,
    findKeyPath,
} from 'modules/Utils';
import SwitchSymphony from './SwitchSymphony';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Colors from 'modules/Colors';

// Register the JSON language
SyntaxHighlighter.registerLanguage('json', json);

interface DebugBoxProps {
    className?: string,
    style?: CSSProperties,
    varName?: string,
    filePath?: string,
    data?: any,
    alwaysShow?: boolean,
    mode?: string,
}

/** Type for the debug body content that can be either a string or array of React nodes */
type DebugBodyContent = string | React.ReactNode[] | React.ReactElement | null;

const DebugBox = ({
    className = '',
    style = {},
    varName,
    filePath,
    data,
    alwaysShow,
    mode,
}: DebugBoxProps) => {
    const [displayMode, setDisplayMode] = useState(mode ? mode : 'brief');
    const [filterText, setFilterText] = useState(getCookie('debugFilterText', ''));
    const [filterValueText, setFilterValueText] = useState(getCookie('debugFilterValueText', ''));
    const [flexDirection, setFlexDirection] = useState(getCookie('debugFlexDirection', 'row'));
    const [persistOriginalData, setPersistOriginalData] = useState(getCookie('debugPersistOriginalData', 'false') === 'true');
    const [isNumber, setIsNumber] = useState(getCookie('debugIsNumber', 'false') === 'true');

    const toggleRowColumn = () => {
        const newFlexDirection = flexDirection === 'row' ? 'column' : 'row';
        setCookie('debugFlexDirection', newFlexDirection);
        setFlexDirection(newFlexDirection);
    }

    const toggleIsNumber = () => {
        setCookie('debugIsNumber', (!isNumber).toString());
        setIsNumber(!isNumber);
    }

    const togglePersistOriginalData = () => {
        setCookie('debugPersistOriginalData', (!persistOriginalData).toString());
        setPersistOriginalData(!persistOriginalData);
    }

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFilterText = e.target.value;
        setCookie('debugFilterText', newFilterText);
        setFilterText(newFilterText);
    }

    const handleFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFilterValueText = e.target.value;
        setCookie('debugFilterValueText', newFilterValueText);
        setFilterValueText(newFilterValueText);
    }

    const generateLine = (utilsGetString: string, codeResult: string | React.ReactElement, i: number, isKey = false) => {
        return (
            <div
                key={`debugBox-${isKey ? 'key' : 'value'}-${i}`}
                className={`debugFilterSection-${i}`}
                style={{ ...sty.debugFilterSection, ...(i % 2 ? sty.section1 : sty.section2) }}
            >
                <div className="utilsGetStringContainer" style={sty.utilsGetStringContainer}>
                    {isKey ? 'KEY PATH' : 'VALUE'}: <span style={{ fontWeight: 400, marginLeft: 4 }}>{utilsGetString}</span>
                </div>
                <div style={sty.codeSectionContainer}>
                    {codeResult}
                </div>
            </div>
        )
    }

    /** Formats the data based on debug mode and filter settings */
    const getFormattedBody = (): DebugBodyContent => {
        if (!showBox || !showData) {
            return null;
        }

        // Handle basic JSON stringify modes
        if (debug === '11') {
            return (
                <SyntaxHighlighter
                    language="json"
                    style={atomOneDark}
                    customStyle={sty.syntaxHighlighter}
                >
                    {JSON.stringify(showData, getCircularReplacer())}
                </SyntaxHighlighter>
            );
        }

        if (debug === '12') {
            return (
                <SyntaxHighlighter
                    language="json"
                    style={atomOneDark}
                    customStyle={sty.syntaxHighlighter}
                >
                    {JSON.stringify(showData, getCircularReplacer(), 2)}
                </SyntaxHighlighter>
            );
        }

        if (debug === '13') {
            // If no filters are active, return pretty-printed JSON
            const originalData = (
                <SyntaxHighlighter
                    language="json"
                    style={atomOneDark}
                    customStyle={sty.syntaxHighlighter}
                >
                    {JSON.stringify(showData, getCircularReplacer(), 2)}
                </SyntaxHighlighter>
            )

            if (!filterText && !filterValueText) {
                return originalData
            }

            const bodyElements: React.ReactNode[] = [];

            // Process key path filters
            if (filterText) {
                filterText.split(', ').forEach((utilsGetString: string, i: number) => {
                    const codeResult = (
                        <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={sty.syntaxHighlighter}
                        >
                            {JSON.stringify(
                                utilsGet(showData, utilsGetString),
                                getCircularReplacer(),
                                2
                            )}
                        </SyntaxHighlighter>
                    );
                    bodyElements.push(generateLine(utilsGetString, codeResult, i, true));
                });
            }

            // Process value filters
            if (filterValueText) {
                filterValueText.split(', ').forEach((valueString: string, i: number) => {
                    const result = (
                        <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={sty.syntaxHighlighter}
                        >
                            {String(findKeyPath(showData, valueString, isNumber))}
                        </SyntaxHighlighter>
                    );
                    bodyElements.push(generateLine(valueString, result, i));
                });
            }

            if (persistOriginalData) {
                bodyElements.unshift(originalData);
            }

            return bodyElements;
        }

        return null;
    };

    const debug = getUrlParam('debug');
    const debugVar = getUrlParam('debugVar');
    const showBox = debug === '11' || debug === '12' || debug === '13' || alwaysShow;
    const showData = debugVar ? data[debugVar] : data;

    if (getUrlParam('dConsole') === '13') {
        console.log('DebugBox', showData);
    }

    if (!showBox) {
        return null;
    }

    const body = getFormattedBody();

    return (
        <div className={clsx('debugboxComponent', className)} style={styx(sty.debugboxComponent, style)}>
            <div className="debugboxHeader" style={sty.debugboxHeader}>
                <div className="debugboxHeaderContent" style={sty.debugboxHeaderContent}>
                    {filePath &&
                        <div className="debugboxFilePathText" style={sty.debugboxFilePathText}>
                            <span style={{ fontWeight: 500, color: Colors.green }}>filePath:</span> {filePath}
                        </div>
                    }
                    {varName &&
                        <div className="debugboxVarName" style={sty.debugboxVarName}>
                            <span style={{ fontWeight: 500, color: Colors.green }}>varName:</span> {varName}
                        </div>
                    }
                </div>
                {debug === '13' && (
                    <>
                        <input
                            className="debugboxFilterKeyInput"
                            style={sty.debugboxFilterInput}
                            type="text"
                            placeholder="Search Key"
                            onChange={handleFilterChange}
                            value={filterText}
                        />
                        <input
                            className="debugboxFilterValueInput"
                            style={sty.debugboxFilterInput}
                            type="text"
                            placeholder="Search Value"
                            onChange={handleFilterValueChange}
                            value={filterValueText}
                        />
                    </>
                )}
                <div className="checkboxContainer" style={sty.checkboxContainer}>
                    <SwitchSymphony
                        className="debugboxIsNumberCheckbox"
                        checked={isNumber}
                        onChange={toggleIsNumber}
                        label="isNum"
                        labelPlacement="bottom"
                        isDarkMode
                        labelStyle={{ fontSize: 12 }}
                    />
                    <SwitchSymphony
                        className="debugboxFlexDirectionCheckbox"
                        checked={flexDirection === 'row'}
                        onChange={toggleRowColumn}
                        label={flexDirection === 'row' ? 'row' : 'col'}
                        labelPlacement="bottom"
                        isDarkMode
                        labelStyle={{ fontSize: 12 }}
                    />
                    <SwitchSymphony
                        className="debugboxPersistOriginalDataCheckbox"
                        checked={persistOriginalData}
                        onChange={togglePersistOriginalData}
                        label="persist"
                        labelPlacement="bottom"
                        isDarkMode
                        labelStyle={{ fontSize: 12 }}
                    />
                </div>
            </div>
            <pre
                className="preDebugCode"
                style={styx(
                    sty.preDebugCode,
                    { flexDirection: flexDirection === 'row' ? 'row' : 'column' }
                )}
            >
                {body}
            </pre>
        </div>
    );
}

const sty: Record<string, CSSProperties> = {
    debugboxComponent: {
        width: '100%',
        padding: "0px 10px",
        background: "black",
        maxHeight: Number(getUrlParam('dHeight', 300)),
        overflow: "scroll",
        color: "white",
        borderRadius: 8,
    },
    debugboxHeader: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        paddingTop: 8,
        paddingBottom: 8,
        gap: 16,
        top: 0,
        position: 'sticky',
        zIndex: 1,
    },
    debugboxFilePathText: {
        fontSize: 16,
        fontWeight: 500,
    },
    debugboxVarName: {
        fontSize: 14,
        fontWeight: 400,
    },
    debugboxFilterInput: {
        background: 'rgba(255,255,255,0.2)',
        padding: 4,
        borderRadius: 4,
        fontSize: 14,
        color: 'white',
        marginBottom: 0,
        fontWeight: 400,
    },
    section1: {
        background: 'rgba(255,255,255,0.17)',
    },
    section2: {
        background: 'rgba(255,255,255,0.12)',
    },
    checkboxContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 24,
        marginTop: 8,
    },
    preDebugCode: {
        display: 'flex',
        fontSize: 14,
        gap: 4,
        margin: 0,
        paddingBottom: 10,
    },
    debugFilterSection: {
        padding: 8,
        borderRadius: 4,
        overflowX: 'scroll',
        flex: 1,
    },
    utilsGetStringContainer: {
        fontWeight: 'bold',
        position: 'sticky',
        left: 0,
    },
    codeSectionContainer: {
        paddingTop: 8,
    },
    syntaxHighlighter: {
        background: 'transparent',
        margin: 0,
        padding: 0,
        flex: 1,
    },
}

export default DebugBox;