import { LocationDetail } from '../MarketsDetail';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PausedLabelContainer } from '../MarketDetailHeader/styles';
import { LiveLabel, LiveLabelContainer, PausedLabel } from './styles';
import LocationCell from './LocationCell';
import { Dispatch, SetStateAction } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CurrencyCodesType } from 'types/global';
import { DEFAULT_CURRENCY } from 'modules/Const';

const getFormattedMoney = (amount?: number, currency?: CurrencyCodesType) =>
  `${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${amount?.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`

export const getGroupingColumDefinition = (
  openConfirmationModal: () => void,
  selectLocation: Dispatch<SetStateAction<LocationDetail>>,
  showOverall: boolean,
  campaignStatus: string,
) => {
  return {
    headerName: "Location",
    maxWidth: 224,
    minWidth: 224,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <LocationCell
          params={params}
          openConfirmationModal={openConfirmationModal}
          selectLocation={selectLocation}
          showOverall={showOverall}
          campaignStatus={campaignStatus}
        />
      )
    }
  }
}

export const getColumnDefinition = (marketColumnWidth: number, hasConversions: boolean, currency?: CurrencyCodesType) => {
  const defaultColDef: GridColDef = {
    field: "field",
    headerName: "column",
    type: "string",
    sortable: true,
    editable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    minWidth: 120,
    flex: 1,
    width: 120,
  }

  const columns = [
    {
      ...defaultColDef,
      field: "markets",
      headerName: "Markets",
      maxWidth: marketColumnWidth,
      minWidth: marketColumnWidth,
      width: marketColumnWidth,
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}>{formattedRow.market}</p>
      }
    },
    {
      ...defaultColDef,
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        if (formattedRow.status === "paused")
          return (
            <PausedLabelContainer>
              <PausedLabel>Paused</PausedLabel>
            </PausedLabelContainer>
          )
        return (
          <LiveLabelContainer>
            <LiveLabel>Live</LiveLabel>
          </LiveLabelContainer>
        )
      }
    },
    {
      ...defaultColDef,
      field: "clicks",
      headerName: "Clicks",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{formattedRow.stats.clicks.toLocaleString("en")}</p>
      },
      valueGetter: (params) => params.row?.stats?.clicks
    },
    {
      ...defaultColDef,
      field: "reach",
      headerName: "Reach",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{formattedRow.stats.reach.toLocaleString("en")}</p>
      },
      valueGetter: (params) => params.row?.stats?.reach
    },
    {
      ...defaultColDef,
      field: "impressions",
      headerName: "Impressions",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{formattedRow.stats.impressions.toLocaleString("en")}</p>
      },
      valueGetter: (params) => params.row?.stats?.impressions
    },
    {
      ...defaultColDef,
      field: "cpm",
      headerName: "CPM",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{getFormattedMoney(formattedRow.stats.cpm, currency)}</p>
      },
      valueGetter: (params) => params.row?.stats?.cpm
    },
    {
      ...defaultColDef,
      field: "spend",
      headerName: "Spent",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{getFormattedMoney(formattedRow.stats.spend, currency)}</p>
      },
      valueGetter: (params) => params.row?.stats?.spend
    },
    {
      ...defaultColDef,
      field: "costPerClick",
      headerName: "Cost-per-click",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{getFormattedMoney(formattedRow.stats.costPerClick, currency)}</p>
      },
      valueGetter: (params) => params.row?.stats?.costPerClick
    }
  ] as GridColDef[];

  if (hasConversions) {
    columns.splice(2, 0, {
      ...defaultColDef,
      field: "conversions",
      headerName: "Conversions",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{formattedRow.stats.conversions.toLocaleString("en")}</p>
      },
      valueGetter: (params) => params.row?.stats?.conversions
    });
    columns.splice(3, 0, {
      ...defaultColDef,
      field: "cost_per_conversion",
      headerName: "Cost-per-conversion",
      renderCell: (params) => {
        const formattedRow = params.row as LocationDetail;
        if (!formattedRow.id) return;
        return <p>{formattedRow.stats.costPerConversion.toLocaleString("en")}</p>
      },
      valueGetter: (params) => params.row?.stats?.costPerConversion
    });
  }

  return columns;
}
