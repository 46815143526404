import {
  ButtonContactSupport,
  EmailLabel,
  SendEmailContainer,
  SendEmailLabel,
} from './style';
import { showNewMessageIntercom } from 'components/Navbar/Navbar';
import HeaderModal from '../HeaderModal';
import DialogPaper from 'components/shareable/DialogPaper';

interface Props {
  open: boolean;
  closeModal: () => void;
  campaignId: number;
  title: string;
  message: string;
  callToActionText?: string;
}

const GeneralContactSupportModal = ({
  open,
  closeModal,
  campaignId,
  title,
  message,
  callToActionText
}: Props) => {
  const handleContactSupport = () => {
    closeModal()
    showNewMessageIntercom()
  }

  return (
    <DialogPaper open={open} >
      <>
        <HeaderModal
          title={title}
          subtitle={message}
          closeModal={closeModal}
        />
        <ButtonContactSupport
          onClick={handleContactSupport}
        >
          {callToActionText ? callToActionText : 'Contact support'}
        </ButtonContactSupport>
        <SendEmailContainer>
          <SendEmailLabel>Or send an email to:</SendEmailLabel>
          <EmailLabel href={`mailto:team@symphonyos.co?subject=Update Campaign ${campaignId}`}>team@symphonyos.co</EmailLabel>
        </SendEmailContainer>
      </>
    </DialogPaper>
  )
}

export default GeneralContactSupportModal;
