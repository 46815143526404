/* eslint-disable no-throw-literal */
import Axios from "helpers/Interceptor";
import * as Sentry from "@sentry/react";
import some from "lodash/some";
import { CurrentBrand, PlaylistsRecord } from "types/global";
import { track } from "analytics";
import { createCampaignAndUpgradePlanCheckout } from "services/symphonyApi/subscriptionService";

type Tier = {
  tier: number;
  cost: number;
};

export const createPayPalOrder = async ({
  currentBrand,
  record,
  tier,
  campaignId: campaignIdProp,
  pitchNotes
}: {
  campaignId: string | null;
  currentBrand: CurrentBrand;
  record: PlaylistsRecord | null;
  tier: Tier;
  pitchNotes: string;
}) => {
  track("Completed Campaign Step - Checkout", {
    type: "get_playlisted",
    payment_method: "paypal",
    step: 3,
    name: "confirmation",
    brand_id: currentBrand.id,
    brand_name: currentBrand.name,
    brand_slug: currentBrand.slug,
    content_name: record?.name,
    content_slug: record?.slug,
    content_id: record?.id,
    tier: tier,
    pitchNotes: pitchNotes
  });

  try {
    const response = await Axios.post(`/campaign/brand/${currentBrand.id}/checkout`, {
      campaign: {
        id: campaignIdProp,
        campaign_type: "get_playlisted",
        campaign_version: 'v2',

        pitch_notes: pitchNotes,

        price_data: {
          tier: tier.tier,
          price: tier.cost,
          name: `${tier.tier.toLocaleString(
            "en"
          )} Followers Across Playlists ("${record?.name}")`,
          description: `"${record?.name}" (Get Playlisted)`,
        },
        content: {
          spotifyId: record?.spotify_id,
          name: record?.name,
          id: record?.id,
          slug: record?.slug,
          thumbnail_url: record?.thumbnail_url,
          url: record?.url,
        },
        campaign_platforms: ["playlisting"],
        payment_method: "paypal",
      },
    });

    if (some([!response, !response.data, !response.data.data])) {
      throw new Error("Error creating PayPal order");
    }

    const order = response.data.data;
    const orderId = order.paypalOrder.id;
    const campaignId = order.campaignId;

    return { orderId, campaignId };
  } catch (error) {
    Sentry.captureException(error);
    console.log("error: ", error);
    return { orderId: null, campaignId: null };
  }
};
