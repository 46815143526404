import { CSSProperties } from "react";
import { ToggleOption } from "./Components/CustomizeTab";
import {
  BackgroundTypes,
  StyleTypes,
  Website,
  CampaignPreSaveCreationData,
  BrandContentExistsData,
  PresaveCampaignExistsData,
  PreSaveErrorTypes as ErrorTypes,
  TitleAlignments,
} from "types/global";
import BoxesIcon from "assets/images/boxes-icon.svg";
import BoxesIconWhite from "assets/images/boxes-icon-white.svg";
import StackedIcon from "assets/images/stacked-icon.svg";
import StackedIconWhite from "assets/images/stacked-icon-white.svg";
import TraditionalIcon from "assets/images/traditional-icon.svg";
import TraditionalIconWhite from "assets/images/traditional-icon-white.svg";
import isEqual from "lodash/isEqual";
import moment, { Moment } from "moment";
import { Details as DetailsContext, Customize as CustomizeContext } from "pages/post-auth/MarketingPage/Data/PreSaveProvider";
import { ITimezoneOption } from "components/shareable/TimezoneSelect/types/timezone";
import every from "lodash/every";
import DetailsTab from "./Components/DetailsTab"
import CustomizeTab from "./Components/CustomizeTab"
import ReviewTab from "./Components/ReviewTab"
import { upperFirst, words } from "lodash"
import Colors from "modules/Colors";

enum TABS {
  DETAILS = "DETAILS",
  CUSTOMIZE = "CUSTOMIZE",
  REVIEW = "REVIEW",
}

const { DETAILS, CUSTOMIZE, REVIEW } = TABS

const { BOXES, STACKED, TRADITIONAL } = StyleTypes;

const { PRE_SAVE_CAMPAIGN_EXISTS } = ErrorTypes;

type Data =
  | CampaignPreSaveCreationData
  | BrandContentExistsData
  | PresaveCampaignExistsData
  | null;

export interface PositionOption {
  name: string;
  show: boolean;
}

export const showPositionOption = (
  prev: PositionOption[],
  name: string,
  show: boolean
) =>
  prev.map((o) => {
    if (isEqual(o.name, name)) {
      return {
        ...o,
        show: !show,
      };
    }
    return o;
  });

export const getDatePickerTextFieldClassName = (
  selectedDate: Moment | null,
  classes: Record<string, string>
) => {
  if (selectedDate) return classes.datePickerTextFieldWhenSelectedDate;
  return classes.datePickerTextFieldWhenNotSelectedDate;
};

export const getToggleOptions = (selectedValue: StyleTypes) => [
  {
    value: BOXES,
    component: (
      <ToggleOption
        selectedValue={selectedValue}
        value={BOXES}
        containerClassName="flex flex-row items-center gap-x-2"
        imageClassName="w-6 h-6"
        alt="boxes-icon.svg"
        src={selectedValue === BOXES ? BoxesIconWhite : BoxesIcon}
        label="Boxes"
      />
    ),
  },
  {
    value: STACKED,
    component: (
      <ToggleOption
        selectedValue={selectedValue}
        value={STACKED}
        containerClassName="flex flex-row gap-x-2"
        imageClassName="w-6 h-6 pt-1"
        alt="stacked-icon.svg"
        src={selectedValue === STACKED ? StackedIconWhite : StackedIcon}
        label="Stacked"
      />
    ),
  },
  {
    value: TRADITIONAL,
    component: (
      <ToggleOption
        selectedValue={selectedValue}
        value={TRADITIONAL}
        containerClassName="flex flex-row gap-x-2"
        imageClassName="w-6 h-6 pt-1"
        alt="traditional-icon.svg"
        src={
          selectedValue === TRADITIONAL ? TraditionalIconWhite : TraditionalIcon
        }
        label="Traditional"
      />
    ),
  },
];

export const getBoostOptions = (args: {
  collectEmails: boolean;
  boostFollows: boolean;
  handleChangeCollectEmails: () => void;
  handleChangeBoostFollows: () => void;
}) => {
  const {
    collectEmails,
    boostFollows,
    handleChangeCollectEmails,
    handleChangeBoostFollows,
  } = args;

  return [
    {
      boldLabel: "Collect Emails:",
      label: "Collect fan email addresses through pre-save.",
      checked: collectEmails,
      onChange: handleChangeCollectEmails,
    },
    {
      boldLabel: "Boost Follows:",
      label: "Automatically have pre-savers follow your artist profile on their preferred streaming platform.",
      checked: boostFollows,
      onChange: handleChangeBoostFollows,
    },
  ];
};

const SPOTIFY_URL = "https://open.spotify.com/";
const ALBUM_URL_START = "album/";
const TRACK_URL_START = "track/";
const START_URI_START = "spotify:";
const ALBUM_URI_START = "album:";
const TRACK_URI_START = "track:";
const REG_EXP = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;

const checkIfIsValidAlbumOrTrack = (args: {
  url: string;
  startUrl: string;
  album: string;
  track: string;
}) => {
  const { url, startUrl, album, track } = args;
  const [, rest] = url.split(startUrl);
  const isAlbum = rest.startsWith(album);
  const isTrack = rest.startsWith(track);

  if (isAlbum) {
    const [, values] = rest.split(album);
    return !!values;
  }
  if (isTrack) {
    const [, values] = rest.split(track);
    return !!values;
  }

  return false;
};

export const validateReleaseUrlUriUpcIsrc = (value: string) => {
  const isSpotifyUrl = value.startsWith(SPOTIFY_URL);
  const isUri = value.startsWith(START_URI_START);
  const isUpc = every([
    !!value,
    value.length <= 24,
    value.length >= 4,
    Number.isFinite(Number(value)),
    Number(value) > 0,
  ]);
  const isIsrc = every([
    !!value,
    value.length <= 24,
    value.length >= 4,
    REG_EXP.test(value),
  ]);

  if (isSpotifyUrl) {
    return checkIfIsValidAlbumOrTrack({
      url: value,
      startUrl: SPOTIFY_URL,
      album: ALBUM_URL_START,
      track: TRACK_URL_START,
    });
  }

  if (isUri) {
    return checkIfIsValidAlbumOrTrack({
      url: value,
      startUrl: START_URI_START,
      album: ALBUM_URI_START,
      track: TRACK_URI_START,
    });
  }

  if (isIsrc) return isIsrc;

  if (isUpc) return isUpc;

  return false;
};

export const getSelectedDatePickerDate = (selectedDate: Moment | null) => {
  if (selectedDate) return moment(selectedDate).toDate();
  return null;
};

export const getRecordPreviewProps = (args: {
  brandArtwork: string | null;
  releaseTitle: string;
  subtitle: string;
  positionsOptions: { name: string; show: boolean }[];
  website: Website;
  customize: CustomizeContext;
  titleAlignment?: TitleAlignments;
}) => {
  const { releaseTitle, subtitle, brandArtwork, positionsOptions, website, customize } =
    args;
  const {
    artwork: customizeArtwork,
    styleType: customizeStyleType,
    backgroundType: customizeBackgroundType,
    primaryColor: customizePrimaryColor,
    buttonColor: customizeButtonColor,
    backgroundColor: customizeBackgroundColor,
  } = customize;

  return {
    contentDetails: {
      name: releaseTitle,
      subtitle: subtitle,
      options: positionsOptions.filter((o) => o.show),
      artworkUrl: customizeArtwork || brandArtwork || "",
    },
    backgroundType:
      customizeBackgroundType || website?.bgType || BackgroundTypes.ARTWORK,
    backgroundColor:
      customizeBackgroundColor || website?.bgColor || Colors.DARK_GRAY,
    mainColor:
      customizePrimaryColor || website?.primaryColor || Colors.purple,
    secondaryColor:
      customizeButtonColor || website?.secondaryColor || Colors.textColor,
    styleType:
      (customizeStyleType as StyleTypes) ||
      website?.buttonConfig ||
      StyleTypes.STACKED,
  };
};

export const getBackgroundTypeSelectClassName = (
  selectedBackgroundType: BackgroundTypes
) => {
  const isColorBackgroundType = isEqual(
    selectedBackgroundType,
    BackgroundTypes.COLOR
  );
  if (isColorBackgroundType) return "border h-8 mt-2";
  return "border h-8 w-10/12";
};

export const getRecordPreviewContainerStyles = (args: {
  backgroundType: BackgroundTypes;
  classes: Record<string, string>;
  backgroundColor: string;
}) => {
  const { backgroundColor, backgroundType, classes } = args;
  const isArtworkBackgroundType = isEqual(
    backgroundType,
    BackgroundTypes.ARTWORK
  );
  if (isArtworkBackgroundType) {
    return {
      className: classes.recordPreviewContainerWhenArtwork,
      style: {
        overflow: window.innerWidth <= 768 ? "auto" : "hidden",
        overflowX: 'hidden'
      } as CSSProperties,
    };
  }

  return {
    className: classes.recordPreviewContainerWhenColor,
    style: { backgroundColor } as CSSProperties,
  };
};

export const getDatePickerWidthClassName = (
  selectedDate: Moment | null,
  isRow: boolean
) => {
  if (!selectedDate) {
    return isRow ? "w-58" : "w-full";
  }
  return isRow ? "w-8/12 max-w-xs" : "w-full";
};

export const getTimezonePickerWidthClassName = (
  selectedDate: Moment | null,
  isRow: boolean
) => {
  if (!selectedDate) {
    return isRow ? "w-auto" : "w-full";
  }
  return isRow ? "w-6/12" : "w-full";
};

export const getDatePickerDateFormat = (
  _selectedTimezone: ITimezoneOption | string
) => "MMMM d, yyyy h:mm aa";

export const getPostCreationValuesFromData = (campaignData: Data) => {
  const {
    PRE_SAVE_CAMPAIGN_EXISTS,
    BRAND_CONTENT_EXISTS,
    ERROR_ON_PRE_SAVE_CREATION,
  } = ErrorTypes;
  const data = campaignData as unknown as Record<string, unknown>;

  if (data?.campaign) {
    const presaveExistsData = data as unknown as PresaveCampaignExistsData;
    const id = presaveExistsData.campaign?.id;
    const link = presaveExistsData?.campaign?.campaign_metadata?.link;

    return {
      link,
      id,
      errorLabel: "You’ve created a pre-save campaign!",
      errorType: PRE_SAVE_CAMPAIGN_EXISTS,
    };
  }

  if (data?.brandContent) {
    const brandContentExistsData = data as unknown as BrandContentExistsData;
    const id = brandContentExistsData.brandContent?.id;
    const link = brandContentExistsData?.link;

    return {
      link,
      id,
      errorLabel: "This song has already been released.",
      errorType: BRAND_CONTENT_EXISTS,
    };
  }

  const presaveCampaignData = data as unknown as CampaignPreSaveCreationData;
  const id = presaveCampaignData?.id;
  const link = presaveCampaignData?.campaign_metadata?.link;

  return {
    link,
    id,
    errorLabel: "",
    errorType: ERROR_ON_PRE_SAVE_CREATION,
  };
};


export const getTabViews = (selectedTab?: string | null) => [
  {
    id: "customizePanel",
    hidden: !isEqual(selectedTab, CUSTOMIZE),
    component: CustomizeTab,
  },
  {
    id: "detailsPanel",
    hidden: !isEqual(selectedTab, DETAILS),
    component: DetailsTab,
  },
  {
    id: "reviewPanel",
    hidden: !isEqual(selectedTab, REVIEW),
    component: ReviewTab,
  },
]

export const getMainTitle = (args: {
  showPostCreation: boolean
  loading: boolean
  errorType: ErrorTypes | null
}) => {
  const { showPostCreation, loading, errorType } = args
  const campaignExists = isEqual(errorType, PRE_SAVE_CAMPAIGN_EXISTS)

  if (campaignExists) return "Pre-Save Campaign"
  if (loading && showPostCreation) return "Loading your pre-save"
  if (!loading && showPostCreation) return "Your pre-save is live!"
  return "Create a Pre-Save Campaign"
}

export const getContainerClassName = (
  showPostCreation: boolean,
  isMobile: boolean
) =>
  `bg-white ${showPostCreation
    ? `mx-auto mt-0 lg:mt-2 rounded-xl`
    : `w-full rounded-${isMobile ? "none mb-36 " : "xl mt-6 mb-24 "}`
  }`

export const findPlatform = (
  platformName: string,
  platforms?: { name: string; show: boolean }[]
) => platforms?.find(({ name, show }) => name === platformName && show)

export const availablePlatforms = ["spotify", "apple_music", "audiomack", "soundcloud", "deezer"];

export const getDraftPlatforms = (platforms: string[]) => {
  const orderedPlatforms: { name: string; show: boolean }[] = []

  for (const { index, name } of availablePlatforms.map((name, index) => ({ index, name }))) {
    const indexOf = platforms.indexOf(name)
    const found = indexOf > -1

    if (found) {
      orderedPlatforms.splice(indexOf, 0, { name: platforms[indexOf], show: true })
    } else {
      orderedPlatforms.push({ name: availablePlatforms[index], show: false })
    }
  }

  return orderedPlatforms
}

export const formatTabsData = (
  details: DetailsContext,
  customize: CustomizeContext,
  draft: string | null
) => {
  const {
    releaseDate,
    releaseUrl: release_url_upc_isrc,
    musicPlatforms,
    timezone,
    boosts,
    localRelease,
    hideReleaseDate,
  } = details;
  const {
    artwork: thumbnail_url,
    releaseTitle: title,
    subtitle,
    showSecondaryLine,
    styleType: buttonConfig,
    backgroundType: bgType,
    primaryColor,
    buttonColor: secondaryColor,
    sendFansToCustomWebPage,
    customWebPageUrl,
    backgroundColor: bgColor,
  } = customize;
  const {
    collectEmails: collect_emails,
    boostFollows: boost_follows,
    autoPresaves: auto_presaves,
  } = boosts;
  const redirect_to_custom_webpage =
    customWebPageUrl.length > 0 && Boolean(sendFansToCustomWebPage);
  const release_date = moment(releaseDate).utc().format();
  const redirect_url = redirect_to_custom_webpage ? customWebPageUrl : "";
  const platforms = musicPlatforms
    .filter(({ show }) => show)
    .map(({ name }) => name);

  return {
    id: draft,
    title,
    subtitle,
    showSecondaryLine,
    release_url_upc_isrc,
    release_date,
    thumbnail_url,
    presave: {
      platforms,
      boosts: {
        collect_emails,
        boost_follows,
        auto_presaves,
      },
      local_release: localRelease,
      hideReleaseDate,
      style: {
        bgType,
        bgColor,
        buttonConfig,
        primaryColor,
        secondaryColor,
      },
      options: {
        redirect_to_custom_webpage,
        redirect_url,
        timezone: timezone.value,
      },
    },
  };
};

export const getFormattedPlatform = (word: string) => {
  const ws = words(word)
  if (word === 'soundcloud' || word === 'Soundcloud') return 'SoundCloud'
  return ws.map(upperFirst).join(" ")
}


export const validateSlugName = (slug: string, isUrl = false) => {
  const normalizedSlug = slug.normalize('NFKD');
  const containsAccents = /[\u0300-\u036f]/g.test(normalizedSlug);

  let invalidChars

  if (isUrl) {
    invalidChars = /[^\p{L}\p{N}_-]+/gu.test(slug);
    const containsSpaces = / /g.test(slug);
    invalidChars = invalidChars || containsSpaces
  } else {
    invalidChars = /[^\p{L}\p{N}_\s\.,?!$&()/\[\]#+='"`-]+/gu.test(slug);
  }

  if (containsAccents || invalidChars) {
    return false;
  }
  return true;
}

export const checkIsValidName = (value: string) => {

  if (value.trim().length) {
    // const regex = new RegExp(NAME_REG_EXP)
    // return regex.test(value.replace(' ', '')) && value.length < 90
    const valid = validateSlugName(value)
    return valid
  }
  return false
};
