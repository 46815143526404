import Lottie from 'react-lottie';
import styled from "styled-components";
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';
import SymphonyLoadingLottieBlack from 'assets/images/lotties/SymphonyLoading.json';

export const NewLoader = (props: {
    black?: boolean
}) => {
    return (
        <Lottie
            width={44}
            height={44}
            options={{
                rendererSettings: {
                    className: "bg-white-500"
                },
                loop: true,
                autoplay: true,
                animationData: props.black ? SymphonyLoadingLottieBlack : SymphonyLoadingLottie,
            }}
        />
    )
}

const ConnectBrandLoaderLayout = styled.div`
    text-align: center;
    flex-flow: column;
    background-color: #8800FF;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    z-index: 1000;
`;

const Text = styled.div`
    font-family: 'DIN';
    font-style: normal;
    font-weight: 480;
    font-size: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 8px;
    opacity: 0.64
`;

const ConnectBrandLoader = ({
    title = "Gathering fanbase data..."
}: {
    title?: string | null
}) => {
    return (
        <ConnectBrandLoaderLayout className="connectBrandLoaderComponent">
            <NewLoader />
            <Text>{title}</Text>
        </ConnectBrandLoaderLayout>
    );
};

export default ConnectBrandLoader;
