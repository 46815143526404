import { AxiosResponse } from "axios";
import Axios from "helpers/Interceptor";
import { SpotifyArtist, SpotifyArtistSearchResponse } from "types/global";

/**
 * Fetches artist details from Spotify API by their Spotify ID and transforms it into a standardized format
 * 
 * @param artistId - The Spotify artist ID to look up
 * @returns {Promise<SpotifyArtist|null>} Spotify artist object or null if not found/error
 */
export const getArtistBySpotifyIdService = async (artistId: string): Promise<SpotifyArtist | null> => {
    try {
        const response: AxiosResponse = await Axios.get(
            `/spotify/artists/${artistId}`
        );
        const artist = response?.data?.data;
        if (!artist) return null;

        return {
            externalUrls: artist.external_urls,
            followers: artist.followers,
            genres: artist.genres,
            href: artist.href,
            id: artist.id,
            images: artist.images,
            name: artist.name,
            popularity: artist.popularity,
            type: artist.type,
            uri: artist.uri
        };
    } catch (err) {
        return null;
    }
};

/**
 * Searches for Spotify artists based on a query string
 * 
 * @param query - The search query string to find artists
 * @param limit - Optional limit on number of results to return (default 20)
 * @returns {Promise<SpotifyArtistSearchResponse[]>} Array of matching Spotify artists
 */
export const searchSpotifyArtists = async (query: string, limit: number = 20): Promise<SpotifyArtistSearchResponse[]> => {
    try {
        const response: AxiosResponse = await Axios.get('/spotify/search/artists', {
            params: {
                q: query,
                limit
            }
        });

        const artists: SpotifyArtistSearchResponse[] = response?.data?.data || [];
        return artists;
    } catch (err) {
        return [];
    }
};
