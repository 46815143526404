
import { Card, StyledLabel } from "../../styles";
import useStyles, { ReviewCampaignButton } from "./styles";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery, useTheme } from "@material-ui/core";
import getSymbolFromCurrency from "currency-symbol-map";
import { dailyBudgetCalculator, totalLengthCalculator } from "pages/post-auth/MarketingPage/MusicStreams/BudgetTab/utils";

interface Props {
  budgetValue: number;
  startDate: Date | null;
  endDate: Date | null;
  disableMobileButton: boolean;
  showReviewButton?: boolean;
  currencyCode?: string;
  finishFlow: () => void;
}

const RunningForCard = ({
  budgetValue,
  startDate,
  endDate,
  disableMobileButton,
  showReviewButton = true,
  currencyCode,
  finishFlow,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <div style={{ textAlign: "center", width: "100%" }}>
        <Grid
          container
          className={classes.runningFor}
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Running for
          </StyledLabel>
          <StyledLabel fontWeight={400} fontSize={16}>
            {startDate && endDate
              ? totalLengthCalculator({ startDate, endDate })
              : "..."}
          </StyledLabel>
        </Grid>
        <Grid
          className={classes.dailySpend}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Daily Spend
          </StyledLabel>
          <StyledLabel fontWeight={400} fontSize={16}>
            {currencyCode ? getSymbolFromCurrency(currencyCode) : ''}
            {budgetValue && budgetValue > 0
              ? dailyBudgetCalculator({ startDate, endDate, budgetValue })
              : "..."}
          </StyledLabel>
        </Grid>
        {showReviewButton && (
          <ReviewCampaignButton
            disabled={disableMobileButton}
            onClick={finishFlow}
          >
            Review Your Campaign
          </ReviewCampaignButton>
        )}
      </div>
    </Card>
  );
};

export default RunningForCard;
