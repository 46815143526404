import { AdAccountPageType, BrandContent, CurrentBrand, FacebookPageType, FBPixel, FbSuggestion, InstagramPageType, PlatformsPreview } from "types/global"
import { GeographicTargetingType } from "./TargetingView/reducer"
import Axios from "helpers/Interceptor"
import { Dispatch, SetStateAction } from "react"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import {
  CONTINUE,
  NEXT,
  REVIEW_YOUR_CAMPAIGN,
  SAVE,
  PLATFORMS
} from "./constants"
import { REVIEW } from "../LinkClicks/constants"
import { PreConnectionConfigs, UploadedAssetType } from "../utils/fbCampaigns"
import { formattedWebsiteUrl } from "modules/Utils"
import { CreativeAssetTypes } from "../constants"
import { AddedLinksType } from "components/shareable/RecordSelectorTab"

const dev = process.env.NODE_ENV !== "production"
const { INSTAGRAM_POSTS } = CreativeAssetTypes

export enum STEPS {
  RECORD = "record",
  CREATIVE = "creative",
  BUDGET = "budget",
  FINALIZATION = "review",
}

const { BUDGET, CREATIVE } = STEPS

export enum Platforms {
  SPOTIFY = "spotify",
  ALL = "all",
  APPLE_MUSIC = "apple_music",
  YOUTUBE = "youtube",
  SOUNDCLOUD = "soundcloud",
  DEEZER = "deezer",
  TIDAL = "tidal",
  AMAZON_STORE = "amazon_store",
  AMAZON_MUSIC = "amazon_music",
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
}

export const ConversionsPlatforms = [
  "deezer",
  "tidal",
  "amazon_store",
  "amazon_music",
  "instagram",
  "tiktok",
]

export type DataPlatform = {
  name: string
  key: string
  goal: string
  selected: boolean
  subtitle: string | null
  uploadingAssets: boolean
}

export type SelectedPlatforms = {
  spotify: DataPlatform
  all: DataPlatform
  apple_music: DataPlatform
  youtube: DataPlatform
  soundcloud: DataPlatform,
  deezer: DataPlatform,
  tidal: DataPlatform,
  amazon_store: DataPlatform,
  amazon_music: DataPlatform,
  instagram: DataPlatform,
  tiktok: DataPlatform,
}

export type Captions = {
  spotify: string
  all: string
  apple_music: string
  youtube: string
  soundcloud: string,
  deezer: string,
  tidal: string,
  amazon_store: string,
  amazon_music: string,
  instagram: string,
  tiktok: string,
}

export type RecordSelected = {
  name: string,
  type: string,
  external_links: { name: string }[],
}

export const getIfTargetingSaveButtonIsDisabled = (
  targets: GeographicTargetingType[],
  totalBudget: number
) => {
  const locations = targets.map(({ locations }) => Object.values(locations))
  const budgets = targets.map(({ budget }) => budget)
  return (
    totalBudget !== 1
    || !locations.filter((location) => location.length > 0).length
    || budgets.includes(0)
  )
};

export const getWhenCreativeTabNextButtonIsDisabled = (args: {
  adConnectionsAdded: boolean;
  adConnectionsConfirmed: boolean;
  connectionsType: string;
  selectedFB_page?: FacebookPageType;
  selectedInstaPage?: InstagramPageType;
  selectedFBAdAccount?: AdAccountPageType;
  selectedFBPixel?: FBPixel;
  selectedInstagramPost?: InstagramPost | null;
  preConnectionConfigurations?: PreConnectionConfigs;
  selectedCreativeType: string;
  arePagesSelected: boolean;
  uploadingAssets: boolean;
  uploadedImages: UploadedAssetType[] | null;
  expressSetupOrCustomPages: 'custom' | 'express';
}) => {
  const {
    uploadedImages,
    adConnectionsAdded,
    adConnectionsConfirmed,
    uploadingAssets,
    selectedInstagramPost,
    arePagesSelected,
    preConnectionConfigurations,
    selectedCreativeType,
    expressSetupOrCustomPages
  } = args
  if (!adConnectionsAdded) {
    console.log('expressSetupOrCustomPages', expressSetupOrCustomPages)
    if (expressSetupOrCustomPages === 'custom') {
      return !(
        arePagesSelected &&
        preConnectionConfigurations &&
        preConnectionConfigurations.status
      )
    } else {
      console.log('expressSetupOrCustomPages - returning true')
      return true
    }
  }

  if (!adConnectionsConfirmed) {
    return false
  }

  return selectedCreativeType === INSTAGRAM_POSTS
    ? !(arePagesSelected && selectedInstagramPost)
    : !(
      !uploadingAssets &&
      arePagesSelected &&
      uploadedImages &&
      uploadedImages.length > 0
    )
}

export const getCampaignCreationTargetingAudiences = (
  targetingAudiences: FbSuggestion[]
) => targetingAudiences.map((o) => ({ name: o.name, fbId: o.id }))

export const getCampaignCreationTargetedCountries = (
  geographicTargets: GeographicTargetingType[]
) =>
  geographicTargets.map((o) => ({
    ...o,
    locations: Object.entries(o.locations).map((e) => {
      const [, value] = e
      return value
    }),
  }))

export const handleConnectionsType = (args: {
  connectionsType: string
  selectedFBBusinessManager: { id: string }
  selectedFBAdAccount: { id: string; business: { id: string } }
  currentBrand: CurrentBrand
  previouslySelectedBizMgr: { current: { id: string } }
  previouslySelectedAdAccount: { current: { id: string } }
  selectedFB_page: { id: string; current: { id: string } }
  previouslySelectedFbPage: { current: { id: string } }
  setFBBusinessManager: Dispatch<SetStateAction<Record<string, unknown> | null>>
  setFBAdAccount: Dispatch<SetStateAction<Record<string, unknown> | null>>
  setFBPixel: Dispatch<SetStateAction<Record<string, unknown> | null>>
  setSelectedInstaPage: Dispatch<SetStateAction<Record<string, unknown> | null>>
  reloadBrand: () => void
}) => {
  const {
    connectionsType,
    selectedFBBusinessManager,
    selectedFBAdAccount,
    currentBrand,
    previouslySelectedBizMgr,
    previouslySelectedAdAccount,
    selectedFB_page,
    previouslySelectedFbPage,
    setSelectedInstaPage,
    setFBBusinessManager,
    setFBAdAccount,
    setFBPixel,
    reloadBrand,
  } = args

  if (connectionsType === "custom") {
    if (dev) console.log("Custom connection")
    if (dev) console.log("bizmgr", selectedFBBusinessManager)
    if (dev) console.log("ad account", selectedFBAdAccount)

    // if a user changes their business manager,
    // fb ad account + pixel must be deselected
    // OR if a user goes from quick to custom
    // and the business ID in the ad account doesnt match the selected biz mgr
    if (
      selectedFBBusinessManager &&
      selectedFBAdAccount &&
      (!selectedFBAdAccount.business ||
        (selectedFBAdAccount.business &&
          selectedFBAdAccount.business.id &&
          selectedFBAdAccount.business.id !== selectedFBBusinessManager.id))
    ) {
      if (dev) console.log("remove biz mgr")
      setFBBusinessManager(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_business_manager",
        value: selectedFBBusinessManager,
      })
        .then((result) => {
          reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting biz mgr", err)
        })
    }

    if (
      !selectedFBBusinessManager ||
      (selectedFBBusinessManager &&
        (!previouslySelectedBizMgr.current ||
          previouslySelectedBizMgr.current.id !== selectedFBBusinessManager.id))
    ) {
      if (dev) console.log("selected biz mgr changed")

      if (!selectedFBBusinessManager) {
        Axios.put(`/brand/${currentBrand.slug}/connect`, {
          id: currentBrand.id,
          name: currentBrand.slug,
          service: "facebook_business_manager",
          value: selectedFBBusinessManager,
        })
          .then((result) => {
            reloadBrand()
          })
          .catch((err) => {
            console.log("error connecting biz mgr", err)
          })
      }
      // set the ad account null in backend too
      setFBAdAccount(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_ad_account",
        value: null,
      })
        .then((result) => {
          reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting ad account", err)
        })

      setFBPixel(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })

      previouslySelectedBizMgr.current = selectedFBBusinessManager
    }

    // if a user changes their selected ad account,
    // fb pixel must be deselected
    if (
      selectedFBAdAccount &&
      (!previouslySelectedAdAccount.current ||
        previouslySelectedAdAccount.current.id !== selectedFBAdAccount.id)
    ) {
      // set the pixel null in backend too
      if (dev) console.log("selected FB Ad Account changed")

      setFBPixel(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })
      previouslySelectedAdAccount.current = selectedFBAdAccount
    }

    // if a user changes their selected Facebook page,
    // IG page must be deselected
    if (
      selectedFB_page &&
      (!previouslySelectedFbPage.current ||
        previouslySelectedFbPage.current.id !== selectedFB_page.id)
    ) {
      // set the IG to null
      if (dev) console.log("selected FB Page changed")
      setSelectedInstaPage(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "instagram_page",
        value: null,
      })
        .then((result) => {
          reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting instagram", err)
        })

      previouslySelectedFbPage.current = selectedFB_page
    }
  } else if (connectionsType === "quick") {
    // if a user changes their selected ad account,
    // fb pixel must be deselected
    if (
      selectedFBAdAccount &&
      (!previouslySelectedAdAccount.current ||
        previouslySelectedAdAccount.current.id !== selectedFBAdAccount.id)
    ) {
      // set the pixel null in backend too
      if (dev) console.log("selected FB Ad Account changed")

      setFBPixel(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })
      previouslySelectedAdAccount.current = selectedFBAdAccount
    }

    // if a user changes their selected Facebook page,
    // IG page must be deselected
    if (
      selectedFB_page &&
      (!previouslySelectedFbPage.current ||
        previouslySelectedFbPage.current.id !== selectedFB_page.id)
    ) {
      // set the IG to null
      if (dev) console.log("selected FB Page changed")
      setSelectedInstaPage(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "instagram_page",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting instagram", err)
        })

      previouslySelectedFbPage.current = selectedFB_page
    }
  }
}

export const getNextButtonLabel = (args: {
  showTargetingView: boolean
  selectedTab?: STEPS | null
  isNextButtonMobile: boolean
}) => {
  const { showTargetingView, selectedTab, isNextButtonMobile } = args

  if (showTargetingView) return SAVE
  if (selectedTab === BUDGET && isNextButtonMobile) return REVIEW
  if (selectedTab === BUDGET && !isNextButtonMobile) return REVIEW_YOUR_CAMPAIGN
  if (selectedTab === CREATIVE) return CONTINUE
  return NEXT
}

export const getNextButtonWidth = (args: {
  selectedTab?: STEPS | null
  isNextButtonResponsive: boolean
  isNextButtonMobile: boolean
}) => {
  const { selectedTab, isNextButtonMobile, isNextButtonResponsive } = args

  if (selectedTab === BUDGET && isNextButtonMobile) return undefined
  if (selectedTab === BUDGET && !isNextButtonMobile && isNextButtonResponsive) return "220px"
  if (selectedTab === BUDGET) return "300px"
  return undefined
}

export const convertArrayDraftDataToSendAsObject = (
  data: Record<string, unknown>[]
) => {
  const [
    recordSelected,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    captions,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    spotifyAssets,
    appleAssets,
    youtubeAssets,
    selectedTab,
    minAge,
    maxAge,
    gender,
    languages,
    addVisualSelected,
    conversionsEnabled,
    facebook_pixel,
    facebook_business_manager,
    logged_in_fb_user,
  ] = data

  return {
    recordSelected,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    captions,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    spotifyAssets,
    appleAssets,
    youtubeAssets,
    selectedTab,
    additionalPageAudiences,
    demographics: {
      age: {
        min: minAge,
        max: maxAge,
      },
      gender,
      languages,
    },
    addVisualSelected,
    conversionsEnabled,
    facebook_pixel,
    facebook_business_manager,
    logged_in_fb_user,
  }
}

export const getFromDraftDataSelectedPlatforms = (
  draftData: Record<string, unknown>
) => {
  const { campaign_metadata } = draftData
  const { campaign_input } = campaign_metadata as {
    campaign_input: { captions: Captions }
  }
  const captions = campaign_input?.captions
  const platformsEntries = Object.entries(PLATFORMS).map(([key, obj]) => [
    key,
    {
      ...obj,
      selected: captions
        ? !!captions[key as keyof Captions]
        : {
          all: null,
          spotify: null,
          apple_music: null,
          youtube: null,
          custom: null,
        },
    },
  ])

  return Object.fromEntries(platformsEntries)
}

export const getWebsiteLinkWithUsersConfiguration = (args: {
  currentBrand: CurrentBrand,
  selectedRecord: BrandContent,
  isConversionsEnabled: boolean,
  selectedPlatforms: Record<string, string>
}): string => {
  const { currentBrand, selectedRecord, isConversionsEnabled, selectedPlatforms } = args

  const url = formattedWebsiteUrl(currentBrand, selectedRecord) + "/conversions"
  let params = ""
  const enabledPlatforms: string[] = Object.keys(selectedPlatforms).filter(
    (platform) =>
      selectedPlatforms[platform] &&
      selectedPlatforms[platform] !== 'all' &&
      selectedPlatforms[platform] !== 'custom'
  )

  if (!isConversionsEnabled && enabledPlatforms.length) params += "?"
  if (isConversionsEnabled) params += "?conversions=1"

  enabledPlatforms.forEach((platform, index) => {
    if (index !== 0 || (index === 0 && isConversionsEnabled)) params += '&'
    params += `${platform}=1`
  }
  )

  return url + params
}

export const getWebsiteLinkWithUsersConfigurationPreview = (args: {
  brand?: CurrentBrand,
  selectedRecord: BrandContent,
  isConversionsEnabled?: boolean,
  selectedPlatforms: PlatformsPreview
}): string => {
  const { brand, selectedRecord, isConversionsEnabled, selectedPlatforms } = args

  const url = formattedWebsiteUrl(brand, selectedRecord) + "/conversions"
  let params = ""
  const enabledPlatforms: string[] = Object.keys(selectedPlatforms).filter(
    (platform) => platform && platform !== 'all' && platform !== 'custom'
  )

  if (!isConversionsEnabled && enabledPlatforms.length) params += "?"
  if (isConversionsEnabled) params += "?conversions=1"

  enabledPlatforms.forEach((platform, index) => {
    if (index !== 0 || (index === 0 && isConversionsEnabled)) params += '&'
    params += `${platform}${selectedPlatforms[platform].selected ? '=1' : '=0'}`
  })

  return url + params
}

export const getIfDraftHasConversionsEnabled = (data: {
  campaignDraftData: Record<string, unknown> | null,
}) => {
  const { campaignDraftData } = data
  if (!campaignDraftData) return true

  const draftMetadata = campaignDraftData?.campaign_metadata as { campaign_input: { brand: CurrentBrand } }
  const draftConnections = draftMetadata?.campaign_input.brand.connections
  if (!draftConnections?.facebook_ad_account) return false

  const campaignDraftMetadata = campaignDraftData.campaign_metadata as { campaign_input: { conversionsEnabled: boolean } }
  return Boolean(campaignDraftMetadata.campaign_input.conversionsEnabled)
}

export const formatValidLinksAdded = (
  addedLinks: AddedLinksType[],
  selectedLinks: Array<{ url: string, name: string }>
): Array<{ serviceName: string, url: string }> => {
  const validLinks = addedLinks.filter((addedLink: AddedLinksType) => {
    const alreadyAdded = selectedLinks.find((link) => link.name === addedLink.name)
    return Boolean(addedLink.valid && !alreadyAdded)
  })

  const contentFormatted = []
  for (const validLink in validLinks) {
    const item = validLinks[validLink]
    const updateData = {
      serviceName: item.name,
      url: item.value.trim()
    }
    contentFormatted.push(updateData)
  }

  return contentFormatted
}