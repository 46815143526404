import { useEffect, useState } from "react"
import axios from "axios"
import { RecordSelected, Template, ColorPalette } from "types/global";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import ButtonWithPaletteAndPreviews from "./SVGPreviews";
import { TEMPLATE_NAMES, DEFAULT_TEMPLATES, DEFAULT_COLORS_PALETTE } from "./data";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";

interface CreativeCustomizationProps {
    record: RecordSelected;
    selectedTemplate: Template;
    selectedArtworkUrl: string | undefined;
    selectedArtistUrl: string | undefined;
    setArtworkUrl: (artworkUrl: string) => void;
    setArtistUrl: (artistUrl: string) => void;
    selectedColorPalette: ColorPalette;
    showContinueButton?: boolean
    setSelectedTemplate: (selectedTemplate: Template) => void;
    setSelectedColorPalette: (selectedColorPalette: ColorPalette) => void;
    nextPage: () => void;
}

const shuffle = (array: ColorPalette[]) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const HARDCODED = true

const CreativeCustomizationTab = ({
    showContinueButton = true,
    record,
    selectedTemplate,
    setSelectedTemplate,
    selectedColorPalette,
    setSelectedColorPalette,
    selectedArtistUrl,
    selectedArtworkUrl,
    setArtworkUrl,
    setArtistUrl,
    nextPage
}: CreativeCustomizationProps) => {
    const { isMobile } = useScreenQueryDimensions();

    const [availableTemplates, setAvailableTemplates] = useState<Template[]>(!HARDCODED ? [] : DEFAULT_TEMPLATES)
    const [availableColors, setAvailableColors] = useState<ColorPalette[]>(!HARDCODED ? [] : DEFAULT_COLORS_PALETTE)

    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false)

    // TODO: handle loading error
    const [loadingError, setLoadingError] = useState<null | unknown>(null)

    const handleSelectTemplate = (template: Template) => () => setSelectedTemplate(template)

    const loadTemplates = async () => {
        try {
            setLoadingError(null)
            setLoadingTemplates(true)

            const url = process.env.REACT_APP_BLURB_API_URL
            const token = process.env.REACT_APP_BLURB_TOKEN

            const getData = await axios.get(`${url}/seedData`, {
                params: {
                    imageUrl: record.thumbnail_url,
                    templateTypes: "MUSIC",
                    maxPalettes: 20
                },
                headers: { "Authorization": `Bearer ${token}` }
            })

            if (getData && getData.data) {
                const templates = getData.data.templates.map((o: {
                    templateId: string,
                    videoPreviewUrl: string
                }) => {
                    return {
                        name: TEMPLATE_NAMES[o.templateId],
                        templateId: o.templateId,
                        previewUrl: o.videoPreviewUrl
                    }
                })

                setAvailableTemplates(templates)

                const colors = getData.data.colorPalettes.map((o: {
                    color1: string,
                    color2: string,
                    color3: string,
                    score: string
                }) => {
                    return {
                        color1: o.color1,
                        color2: o.color2,
                        color3: o.color3
                    }
                })

                setAvailableColors(colors)
            } else {
                throw "Error loading templates"
            }


        } catch (e) {
            // TODO: handle
            setLoadingError(e)
        }
        setLoadingTemplates(false)
    }

    useEffect(() => {
        if (!HARDCODED) loadTemplates()
    }, [])

    // shuffles the list of colors shown
    const refreshColors = () => {
        const currentColors = [
            ...availableColors
        ]
        const refreshed = shuffle(currentColors)
        setAvailableColors(refreshed)
    }

    return (
        <div className="middle-wrapper lg:px-0 relative">
            <div className="sy-card px-5 lg:px-10">
                <div className="">

                    <h5 className="text-dark font-bold text-lg">Select your Style</h5>
                    <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                        Choose a style that speaks to you, and we'll generate customized assets with the same look and feel.
                    </p>
                </div>
            </div>

            {loadingTemplates || !availableTemplates ?
                <div className="flex-col flex items-center m-8 ">
                    <LoadingIndicator height="80px" color="black" />
                    <p className="mt-1">Loading customization library...</p>
                </div>
                :
                <>
                    <div className="overflow-hidden mt-6 flex items-center flex-wrap justify-center border-b">

                        {availableTemplates.map((template: Template) => {
                            return <div
                                key={template.name}
                                onClick={handleSelectTemplate(template)}
                                className={`overflow-hidden w-5/12 border border-4 hover:shadow-md transition-all cursor-pointer rounded-md p-4 mb-4 ml-2 mr-2 text-center
                                    ${selectedTemplate && selectedTemplate.templateId === template.templateId ? `shadow-md border-indigo-700 border-24` : ``}`}
                            >
                                <video src={template.previewUrl} autoPlay loop muted playsInline />
                                <p className="mt-1 text-sm">{template.name}</p>
                            </div>
                        })}
                    </div>
                    <div className="sy-card px-5 lg:px-10">
                        <div className="">

                            <h5 className="text-dark font-bold text-lg">Pick your Colors</h5>
                            <p className="text-sm sm:text-sm text-gray-500 lg:text-base">

                                Pick the colors you want to use for your video. We've automatically generated some color palettes that look great with your cover art. 💥
                            </p>
                        </div>

                    </div>

                    {selectedTemplate && availableColors &&
                        <>
                            <button
                                type="button"
                                onClick={refreshColors}
                                className="w-full text-center p-4 text-primary font-bold border-b border-backgound-100"
                            >
                                Refresh Colors
                            </button>
                            <div
                                className="overflow-hidden mt-6 flex items-center flex-wrap justify-center border-b">
                                {availableColors.slice(0, 4).map((o: {
                                    color1: string,
                                    color2: string,
                                    color3: string
                                }) => {

                                    return <ButtonWithPaletteAndPreviews
                                        setIsSelected={(o: {
                                            color1: string,
                                            color2: string,
                                            color3: string
                                        }) => {
                                            setSelectedColorPalette(o)
                                        }}
                                        isSelected={JSON.stringify(o) === JSON.stringify(selectedColorPalette)}
                                        colorPalette={o}
                                        selectedTemplateId={selectedTemplate.templateId}
                                        idx={1}
                                        trackData={{
                                            spotifyUid: record?.spotify_id || '',
                                            trackTitle: record?.name || '',
                                            audioSampleUrl: record?.preview_url || '',
                                            albumName: record?.name || '',
                                            albumArtImageUrl: record?.thumbnail_url || '',
                                            artistName: record?.primary_artist || '',
                                            artistArtImageUrl: record?.primary_artist || '', //TODO: update to artist image,
                                        }}
                                    />
                                })}
                            </div>
                        </>
                    }

                    <div className="sy-card px-5 lg:px-10">
                        <div className="">
                            <h5 className="text-dark font-bold text-lg">Artwork</h5>
                            <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                By default, your assets will showcase both your song's artwork and your Spotify artist image.
                            </p>
                        </div>
                    </div>
                    <div className="sy-card px-5 lg:px-10">
                        <div className="w-full flex items-center justify-center flex-col md:flex-row">
                            <div className="w-36 h-36 flex-shrink-0 bg-black">
                                {selectedArtworkUrl && <img className="w-36 h-36 flex-shrink-0" src={selectedArtworkUrl} alt="song artwork" />}
                            </div>
                            <div className="ml-8 w-full">
                                <p className="text-md font-medium mb-0 mt-6">Song Artwork Image</p>
                                <p className="text-sm text-gray-500">Enter the artwork image you wanted used in your asset. If you leave this empty, we'll use your default Spotify release artwork.</p>
                                <div
                                    className="rounded-md border w-full resize-none mt-2 relative"
                                >
                                    <input
                                        className="w-full h-full h-12 p-3.5 rounded-md"
                                        placeholder={`e.g. image.com`}
                                        value={selectedArtworkUrl}
                                        onChange={(e) => {
                                            setArtworkUrl(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sy-card px-5 lg:px-10">
                        <div className="w-full flex items-center justify-center flex-col md:flex-row">
                            <div className="w-36 h-36 flex-shrink-0 bg-black">
                                {selectedArtistUrl && <img className="w-36 h-36 flex-shrink-0 bg-black" src={selectedArtistUrl} alt="artist profile" />}
                            </div>
                            <div className="ml-8 w-full">
                                <p className="text-md font-medium mb-0 mt-6">Artist Image</p>
                                <p className="text-sm text-gray-500">Enter the artist image you wanted used in your asset. If you leave this empty, we'll use your default Spotify artist artwork.</p>
                                <div
                                    className="rounded-md border w-full resize-none mt-2 relative">
                                    <input
                                        className="w-full h-full h-12 p-3.5 rounded-md"
                                        placeholder={`e.g. image.com`}
                                        value={selectedArtistUrl}
                                        onChange={(e) => {
                                            setArtistUrl(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showContinueButton &&
                        <div className="text-center px-4 lg:px-10 py-4 mb-6 lg:mb-0">
                            {!isMobile &&
                                <button
                                    disabled={!selectedColorPalette || !selectedTemplate}
                                    onClick={() => nextPage()}
                                    className="continueButtonCreativeGeneratorbtn-primary w-full br-20px mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3"
                                >
                                    Continue
                                </button>
                            }
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default CreativeCustomizationTab