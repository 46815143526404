import { useContext, useState } from "react"
import Colors from "modules/Colors"
import { Body1, Subtitle1 } from "../Typography"
import SecondaryButton from "../SecondaryButton"
import { ButtonContainer, Container, InfoContainer } from "./styles"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { showToast } from "modules/Utils"
import useScreen from "Hooks/useScreen"
import { BarepayComponent } from "components/baremetrics/BaremetricsCreditCardRecoveryForm"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { getStripeSubscriptionPortalUrl } from "services/symphonyApi/subscriptionService"

interface Props {
  withBorderRadius?: boolean
  customerId?: string | undefined
}

// This component renders the Baremetrics capture, and also
// our in-app capture at the top of the page.
// The in-app capture leads directly to STripe's billing.
const PaymentFailedBanner = ({
  withBorderRadius = true,
  customerId,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { currentBrand } = useContext(CurrentBrandContext)
  const { slug } = currentBrand || {}
  const { mobileView } = useScreen()
  const toastProps = {
    message: "An error ocurred. Try again later.",
    error: true,
    mobile: mobileView,
  }

  const { handleOpenModal } = useContext(UpgradeModalContext);

  const retriesFailed = Boolean(currentBrand?.subscription?.retriesFailed)


  const handleUpdatePaymentMethod = async () => {
    try {
      setLoading(true)

      if (retriesFailed) {
        handleOpenModal({
          source: "Payment Failed Banner - Reactivate Pro Button"
        })
      } else {
        const url = await getStripeSubscriptionPortalUrl(slug || '');
        window.location.href = url;
      }
      setLoading(false)
    } catch (error) {
      showToast(toastProps)
      setLoading(false)
    }
  }




  let title = "An update on your payment status..."
  let body = "Your latest Symphony Pro payment was unsuccessful. Please update your payment method to continue enjoying Symphony Pro. You can reach out to our team if you have any questions!"
  let buttonText = "Update payment method"
  if (retriesFailed) {
    title = "You can re-activate your Symphony Pro membership at anytime."
    body = "Your latest Symphony Pro payment was unsuccessful. Once you update your payment method, you'll be able to upgrade to Pro again. You can reach out to our team if you have any questions!"
    buttonText = "Reactivate Pro"
  }
  return (
    <>
      {customerId && (
        <div style={{ display: mobileView ? 'none' : 'block' }}>
          <BarepayComponent customerId={customerId} />
        </div>
      )}
      <Container borderRadius={withBorderRadius ? '8px' : '0px'}>
        <InfoContainer>
          <Subtitle1>{title}</Subtitle1>
          <Body1 color={Colors.textColor}>
            {body}
          </Body1>
        </InfoContainer>
        <ButtonContainer>
          <SecondaryButton
            className="buttonPaymentFailedBanner"
            {...{ loading }}
            minWidth="220px"
            height="40px"
            text={buttonText}
            onClick={handleUpdatePaymentMethod}
          />
        </ButtonContainer>
      </Container>
    </>
  )
}

export default PaymentFailedBanner
