import Axios from 'helpers/Interceptor';
import Logger from 'Logger';

export const getCreativePerformanceData = async ({
  campaignId,
  access_token,
  brandId
}: {
  campaignId: number;
  access_token: string;
  brandId: number | undefined;
}) => {
  try {
    if (!brandId) {
      return
    }

    const url = `campaign/${campaignId}/creative-performance`;
    const response = await Axios.put(url, {
      brandId,
      access_token,
    });
    return response.data.data;
  } catch (e) {
    Logger.error('Error getting creative performance data');
  }
};
