import { Suspense, useContext, CSSProperties, useEffect } from "react"
import { Switch, Route, useHistory } from "react-router-dom"
import CreateNewSymphonyProfile from "pages/post-auth/AddArtist/CreateNewSymphonyProfile"
import Website from "pages/post-auth/Website/Website"
import Dashboard from "pages/post-auth/DashboardModule/Dashboard"
import SettingsPage from "pages/post-auth/SettingsPage"
import MarketingSuccessPage from "../pages/post-auth/MarketingPage/SuccessPage"
import MarketingPage from "pages/post-auth/MarketingPage/MarketingPage"
import MarketingCampaignDetails from "pages/post-auth/MarketingCampaignDetails"
import Fans from "pages/post-auth/Fans/Fans";
import PreSaveProvider from "pages/post-auth/MarketingPage/Data/PreSaveProvider"
import PreSave from "pages/post-auth/MarketingPage/PreSave"
import MarketingDataProvider from "pages/post-auth/MarketingPage/Data/Provider"
import MusicStreams from "pages/post-auth/MarketingPage/MusicStreams"
import GetPlaylisted from "pages/post-auth/MarketingPage/Playlisting"
import VideoViews from "pages/post-auth/MarketingPage/VideoAds"
import CreativeGeneratorStatusPage from "pages/post-auth/MarketingPage/CreativeGenerator/StatusPage"
import CreativeGenerator from "pages/post-auth/MarketingPage/CreativeGenerator"
import LinkClicks from "pages/post-auth/MarketingPage/LinkClicks"
import FanbaseCampaignDetails from "pages/post-auth/Fans/Components/Details";
import isEqual from "lodash/isEqual"
import { NAV_BAR_HEIGHT_MOBILE, NAV_BAR_WIDTH_COLLAPSED } from "modules/Const"
import GrowInstagramEngagement from "pages/post-auth/MarketingPage/GrowInstagram"
import ConnectModalsProvider from "Hooks/ConnectModalsProvider"
import EnterEmail from "pages/post-auth/EnterEmail"
import TiktokAds from "pages/post-auth/MarketingPage/TikTokAds"
import ReferPage from "pages/post-auth/Refer"
import AdminDataUpload from "pages/post-auth/Admin"
import CampaignReports from "pages/post-auth/Admin/reports"
import TiktokCallbackPage from "pages/post-auth/MarketingPage/Components/TikTokCallback"
import Learn from "pages/post-auth/Learn"
import KPITrends from "pages/post-auth/Admin/kpis"
import AffiliateTrends from "pages/post-auth/Admin/affiliates"
import WebsitesList from "pages/post-auth/Website/components/WebsiteList"
import OrganizationPage from "pages/post-auth/OrganizationPage/OraganizationPage"
import SourcesTrends from "pages/post-auth/Admin/sources"
import { ConnectionsProvider } from "pages/post-auth/MarketingPage/hooks/ConnectionsContext"
import AdminOnboarding from "pages/post-auth/OrganizationPage/AdminOnboarding"
import { OnboardingProvider } from "Hooks/OnboardingContext"
import MarketingFlow2 from "components/MarketingFlow2"
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import useScreen from "Hooks/useScreen"
import { useNavbar } from "Hooks/useNavbar"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { useCurrentTeam } from "Hooks/CurrentTeamContext"
import UploadEmailsByCsv from "pages/post-auth/Fans/Components/UploadEmailsByCsv/UploadEmailsByCsv"
import UploadEmailsByCsvProvider from "pages/post-auth/Fans/Data/UploadEmailsByCsvProvider"

const getHeight = (args: {
  path: string
  isCampaignWizard: boolean
  setFullHeightForLgWidthScreen: boolean
}) => {
  const { path, isCampaignWizard, setFullHeightForLgWidthScreen } = args

  if (
    isEqual(path, "settings") ||
    isEqual(path, "email") ||
    isEqual(path, "website") ||
    isEqual(path, "fans") ||
    isEqual(path, "organization") ||
    isCampaignWizard || setFullHeightForLgWidthScreen
  ) {
    return "100%"
  }

  return "fit-content"
}

const PostAuthRoutes = () => {
  const history = useHistory()
  const { currentBrand } = useContext(CurrentBrandContext)
  const { getBrands } = useContext(CurrentUserContext)
  const { organization } = useCurrentTeam()
  const { mStyle, mobileView, cssCalc } = useScreen()
  const { isCollapsed, shouldHideNavbar, isWebsiteChildPage, isAddArtistPage } = useNavbar();

  // const [, path] = location.pathname.split("/")
  // const setFullHeightForLgWidthScreen = useMediaQuery(
  //   theme.breakpoints.up(1023)
  // )
  // const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.includes(location.pathname)
  // const backgroundColor = path === "subscribe" ? Colors.PAPER_CONTAINER : Colors.PAPER_BACKGROUND_CONTAINER

  // const height = getHeight({
  //   path,
  //   isCampaignWizard,
  //   setFullHeightForLgWidthScreen
  // })

  const IS_IMPORT_FANS_ACTIVE = false;

  useEffect(() => {
    getBrands(true);
  }, [])

  let postAuthRoutesWidth = '100%'

  if (!mobileView && isCollapsed) {
    // NOTE: if showing the navbar
    if (!shouldHideNavbar(mobileView, organization, currentBrand)) {
      postAuthRoutesWidth = `calc(100% - ${NAV_BAR_WIDTH_COLLAPSED}px)`
    }
  }

  let overflowX: string = 'hidden'

  const getPostAuthRoutesHeight = () => {
    if (mobileView && !isAddArtistPage()) {
      return cssCalc(NAV_BAR_HEIGHT_MOBILE)
    }

    return cssCalc()
  }

  if (isWebsiteChildPage()) {
    overflowX = 'visible'
  }

  return (
    <div
      style={{
        ...mStyle(sty, 'postAuthRoutesComponent'),
        height: getPostAuthRoutesHeight(),
        width: postAuthRoutesWidth,
        overflowX: overflowX as CSSProperties['overflowX'],
      }}
      id="main-body"
      className="postAuthRoutesComponent"
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path={"/email"}
            children={<EnterEmail />}
          />
          <Route
            exact
            path={["/", "/dashboard"]}
            children={
              <Dashboard />
            }
          />
          <Route
            exact
            path="/marketing/campaigns/:id"
            children={<MarketingCampaignDetails />}
          />
          <Route
            exact
            path="/marketing/tools/creatives"
            children={<CreativeGeneratorStatusPage />}
          />
          <Route
            exact
            path="/marketing/new/creatives"
            children={<CreativeGenerator />}
          />
          <Route
            exact
            path="/marketing/new/presave"
            children={
              <PreSaveProvider>
                <PreSave setGoal={() => null} />
              </PreSaveProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/music-streams"
            children={
              <MarketingDataProvider>
                <ConnectionsProvider campaignType="record_streams">
                  <ConnectModalsProvider>
                    <MusicStreams
                      subtype="increase_record_streams"
                      refreshCampaigns={() => null}
                      reloadBrands={getBrands}
                    />
                  </ConnectModalsProvider>
                </ConnectionsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/grow-playlist-followers"
            children={
              <MarketingDataProvider>
                <ConnectionsProvider campaignType="grow_playlist_followers">
                  <ConnectModalsProvider>
                    <MusicStreams
                      subtype="grow_playlist_followers"
                      refreshCampaigns={() => null}
                      reloadBrands={getBrands}
                    />
                  </ConnectModalsProvider>
                </ConnectionsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/grow-instagram-engagement"
            children={
              <MarketingDataProvider>
                <ConnectionsProvider campaignType="boost_instagram">
                  <ConnectModalsProvider>
                    <GrowInstagramEngagement
                      setGoal={() => null}
                      refreshCampaigns={() => null}
                      reloadBrands={getBrands}
                    />
                  </ConnectModalsProvider>
                </ConnectionsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/playlists"
            children={
              <GetPlaylisted />
            }
          />
          <Route
            exact
            path="/marketing/new/video-views"
            children={
              <VideoViews />
            }
          />
          <Route
            exact
            path="/marketing/new/tiktok-ads"
            children={
              <TiktokAds />
            }
          />
          <Route
            exact
            path="/marketing/new/link-clicks"
            children={
              <MarketingDataProvider>
                <ConnectionsProvider campaignType="link_clicks">
                  <ConnectModalsProvider>
                    <LinkClicks
                      setGoal={() => null}
                      refreshCampaigns={() => null}
                      reloadBrands={getBrands}
                    />
                  </ConnectModalsProvider>
                </ConnectionsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            path="/add-artist"
            children={
              <OnboardingProvider>
                <CreateNewSymphonyProfile />
              </OnboardingProvider>
            }
          />
          <Route
            path="/marketing/success"
            children={
              <MarketingSuccessPage
                reloadBrands={getBrands}
                refreshCampaigns={() => {
                  history.push("/marketing")
                }}
              />
            }
          />
          <Route
            path="/tiktok/callback"
            children={<TiktokCallbackPage />}
          />
          <Route
            path="/marketing"
            children={
              <MarketingPage
                reloadBrands={getBrands}
              />
            }
          />
          <Route
            path="/marketing2/new"
            children={<MarketingFlow2 />}
          />
          <Route
            path="/website/:websiteType/:websiteSlug"
            children={<Website />}
          />
          <Route
            path="/website"
            exact
            children={<WebsitesList />}
          />
          <Route
            path="/fans/campaigns/:id"
            children={<FanbaseCampaignDetails />}
          />
          <Route
            path="/fans"
            children={<Fans />}
          />
          {!!IS_IMPORT_FANS_ACTIVE && 
            <Route
              path="/import-fans"
              component={() =>
                <UploadEmailsByCsvProvider>
                  <UploadEmailsByCsv />
                </UploadEmailsByCsvProvider>
              }
            />
          }
          <Route
            path="/learn"
            children={<Learn />}
          />

          <Route
            path="/settings"
            exact
            children={<SettingsPage />}
          />

          <Route
            path="/team"
            exact
            children={
              <OrganizationPage
              />
            }
          />
          <Route
            path="/team/admin/onboarding"
            exact
            children={<AdminOnboarding />}
          />
          <Route
            path="/refer"
            exact
            children={<ReferPage />}
          />
          <Route
            path="/admin"
            exact
            children={<AdminDataUpload />}
          />
          <Route
            path="/admin/reports"
            exact
            children={<CampaignReports />}
          />
          <Route
            path="/admin/kpis"
            exact
            children={<KPITrends />}
          />
          <Route
            path="/admin/sources"
            exact
            children={<SourcesTrends />}
          />
          <Route
            path="/admin/affiliates"
            exact
            children={<AffiliateTrends />}
          />
        </Switch>
      </Suspense>
    </div>
  )
}

const sty: Record<string, CSSProperties> = {
  postAuthRoutesComponent: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 'auto',
  },
  postAuthRoutesComponent_mobile: {
    marginLeft: 0,
  }
}

export default PostAuthRoutes
