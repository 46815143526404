import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"
import styled from "styled-components"
import Colors from "modules/Colors"

const useStyles = makeStyles((theme: Theme) => ({
  dataGridWrapper: {
    width: "100%",
    border: "none !important",
  },
  dataGridRoot: {
    border: "none !important",
    outline: "none !important",
    "& .MuiDataGrid-row:hover": {
      background: "none !important",
    },
    "& .MuiDataGrid-main, .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cell": {
      fontFamily: "DIN",
      color: `${Colors.greyDark}`,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      padding: "16px",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: `${Colors.PAPER_CONTAINER} !important`,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
      borderBottom: `1px solid ${Colors.divider}`,
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell, .MuiDataGrid-row": {
      borderBottom: `1px solid ${Colors.divider}`,
      cursor: "pointer",
    },
    "& .MuiDataGrid-iconButtonContainer": {
      marginLeft: "2px",
      visibility: "visible !important",
      width: "auto !important",
    },
    "& .MuiDataGrid-cell:hover": {
      cursor: "default",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus":
      {
        outline: "none !important",
      },
  },
}))

interface RemoveButtonProps {
  enabled: boolean
}

export const StyledButton = styled.button``

export const RemoveButton = styled.button<RemoveButtonProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${({ enabled }) => (!enabled ? "default" : "pointer")};
  pointer-events: ${({ enabled }) => (!enabled ? "none" : "auto")};
`

export default useStyles
