import { OptionItem, radioBtnColorTheme } from './styles';
import { Chip, Radio, ThemeProvider } from '@mui/material';
import { ACCOUNT_QUALITY_URL, AD_ACCOUNT_SETTINGS_URL, AD_ACCOUNT_STATUS } from 'helpers/FB';
import { Body2, Headline3 } from 'components/shareable/Typography';
import { AccountDisabledBanner } from '../../../../../Components/AccountDisabledBanner/AccountDisabledBanner';
import { FacebookAdAccountAccess } from 'services/facebookApi';
import { AdAccountStatus, AdAccountStatuses, FacebookBusinessManager } from 'types/global';
import { DisabledBannerDiv } from '../styles';
import millify from 'millify';
import { IntercomArticleIds } from 'helpers/Intercom';
import Colors from 'modules/Colors';
import BusinessChip from 'pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/Components/BusinessChip';

interface AdAccountOptionProps {
  account: FacebookAdAccountAccess;
  businessManager: FacebookBusinessManager;
  selectedAdAccount?: FacebookAdAccountAccess;
  setSelectedAdAccount: (account: FacebookAdAccountAccess) => void;
}

const AccountStatuses = AD_ACCOUNT_STATUS as AdAccountStatuses;

export const AdAccountOption = ({
  account,
  businessManager,
  selectedAdAccount,
  setSelectedAdAccount,
}: AdAccountOptionProps) => {
  const { id, name, account_status, userHasAccess, amount_spent, userHasPermissions } = account;

  const accountStatus: AdAccountStatus = AccountStatuses[account_status];

  // An ad account needs to be linked to a business
  const isPersonal: boolean = account.business === undefined;

  const isDisabled: boolean = !accountStatus.valid || !userHasAccess || isPersonal;

  const handleItemChange = () => {
    if (setSelectedAdAccount && accountStatus.valid) {
      setSelectedAdAccount(account);
    }
  };

  const accountIdEndpoint = id.replace('act_', '');

  const spentCalc: string = millify(amount_spent, {
    precision: amount_spent < 1000 ? 0 : 1,
    lowercase: true,
  });

  let noAccessReviewAccountLink = `${AD_ACCOUNT_SETTINGS_URL}/${accountIdEndpoint}`

  if (businessManager || account.business) {
    noAccessReviewAccountLink += `?business_id=${businessManager?.id || account.business?.id}}`
  }

  const isChecked = selectedAdAccount ? selectedAdAccount.id === id : false;
  return (
    <OptionItem onChange={handleItemChange}>
      <div className="flex items-center">
        <ThemeProvider theme={radioBtnColorTheme}>
          <Radio
            id={`facebook_${id}`}
            name="connect-facebook-pages"
            value={selectedAdAccount}
            checked={isChecked}
            disabled={isDisabled}
          />
        </ThemeProvider>
        <div className="flex flex-col items-center px-3">
          <label
            htmlFor={'facebook_' + id}
            className="cursor-pointer flex items-start flex-col gap-1"
          >
            <Headline3
              color={
                isDisabled
                  ? Colors.grey
                  : Colors.textColor
              }
            >
              {name}
            </Headline3>
            {parseInt(spentCalc) > 0 && (
              <Body2
                color={
                  isDisabled ? Colors.grey : undefined
                }
              >
                {`$${spentCalc} Spent`}
              </Body2>
            )}
            <BusinessChip
              business={account.business}
              assetId={id}
              isDisabled={isDisabled}
            />
          </label>
        </div>
        {!accountStatus.valid && (
          <div className="flex items-center ml-auto">
            <Chip label="Not available" disabled />
          </div>
        )}
      </div>
      {!userHasAccess && (
        <DisabledBannerDiv>
          <AccountDisabledBanner
            bannerMessage="You don't have permission to advertise for this ad account. To use this account, click the link below to add yourself as an Advertiser"
            reviewAccountLink={noAccessReviewAccountLink}
            linkText='Review account'
          />
        </DisabledBannerDiv>
      )}
      {!accountStatus.valid && (
        <DisabledBannerDiv>
          <AccountDisabledBanner
            bannerMessage={`This ad account's status is ${accountStatus.name} and it cannot be used.`}
            reviewAccountLink={`${ACCOUNT_QUALITY_URL}/${accountIdEndpoint}`}
            linkText="Review account"
          />
        </DisabledBannerDiv>
      )}
      {isPersonal && (
        <DisabledBannerDiv>
          <AccountDisabledBanner
            bannerMessage="To setup Conversions, you'll need to use an ad account linked to a Meta Business Manager."
            linkText="Learn how to setup your Facebook for Conversions"
            intercomArticle={IntercomArticleIds.PROPER_FB_ACCOUNT_SETUP}
          />
        </DisabledBannerDiv>
      )}
      {!userHasPermissions && (
        <DisabledBannerDiv>
          <AccountDisabledBanner
            bannerMessage="To setup Conversions, you'll need to use an ad acccount you have full permissions to."
            linkText="Learn how to setup your Facebook for Conversions"
            intercomArticle={IntercomArticleIds.PROPER_FB_ACCOUNT_SETUP}
          />
        </DisabledBannerDiv>
      )}
    </OptionItem>
  );
}
