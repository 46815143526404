import { filterOutLinks } from "helpers/StreamingMappings";
import { deepCopy, objectToArray } from "modules/Utils";
import { WebsiteLinks, WebsiteLink, getIconBackgroundStyle, convertWebsiteLinkArrayToObject } from "../../utils";
import { renderIcon } from "../SocialMediaIconsInputs";
import { useStyles } from "../../styles";
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { clsx } from 'modules/Utils';
import { OnDragEndResponder } from "react-beautiful-dnd";
import { useContext } from "react";
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { DraggableList } from "components/shareable/DraggableList";
import SwitchSymphony from "components/shareable/SwitchSymphony";

interface Props {
  streamingLinks?: WebsiteLinks;
  handleStreamingLinkUrlChange: (url: string, sl: WebsiteLink) => void;
  handleStreamingLinkEnabledSwitch: (enabled: boolean, sl: WebsiteLink) => void;
  onDeleteStreamingService: (sl: WebsiteLink) => void;
}

export const StreamingLinkInputs = ({
  handleStreamingLinkUrlChange,
  handleStreamingLinkEnabledSwitch,
  onDeleteStreamingService,
}: Props) => {
  const classes = useStyles();
  const { streamingLinks, setStreamingLinks } = useContext(WebsiteContext);
  const streamingLinksArray = objectToArray(streamingLinks || {}).filter((a: any) => filterOutLinks(a.key));

  const handleOnDragEnd: OnDragEndResponder = (destination) => {
    if (!destination) return;

    const sourceIndex = destination.source.index;
    const destinationIndex = destination.destination?.index || 0;

    const list = deepCopy(streamingLinksArray);
    const temp = streamingLinksArray[sourceIndex];
    list[sourceIndex] = list[destinationIndex];
    list[destinationIndex] = temp;

    setStreamingLinks(convertWebsiteLinkArrayToObject(list));
  };

  const renderStreamingLink = (sl: WebsiteLink, index?: number) => {
    return (
        <div
          key={`streamingLink-${index}`}
          className={classes.socialMediaInputContainer}
        >
          <DragIcon/>
          <div className={classes.iconTitleInputContainer}>
            <div className={classes.iconTitleContainer}>
              <div className={classes.iconBackground} style={getIconBackgroundStyle(sl)}>
                {renderIcon(true, sl)}
              </div>
              <div className='inputTitle' style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>
                {sl.title}
              </div>
            </div>
            <input
              className={classes.socialMediaInput}
              type='text'
              value={sl.url}
              placeholder={sl.placeholder}
              onChange={(e) =>
                handleStreamingLinkUrlChange(e.target.value, sl)
              }
            />
          </div>
          <div className={classes.switchTrashContainer}>
            <SwitchSymphony
              checked={sl.enabled}
              onChange={() =>
                handleStreamingLinkEnabledSwitch(!sl.enabled, sl)
              }
              className={clsx(sl.enabled && classes.switchEnabled)}
            />
            <TrashIcon
              style={{ cursor: 'pointer' }}
              height={24}
              width={24}
              onClick={() => onDeleteStreamingService(sl)}
            />
          </div>
        </div>
    );
  }

  return (
    <div className={classes.socialMediaIconsContainer}>
      <DraggableList
        list={streamingLinksArray}
        updateList={(newList: WebsiteLinks) => setStreamingLinks(newList)}
        renderItem={renderStreamingLink}
        onDragEnd={handleOnDragEnd}
        keyOfItem="key"
      />
    </div>
  );
}