import { useContext } from "react";
import { BackArrowActionsContainerMobile, OverviewAction, OverviewActionsContainer, OverviewBadgesContainer, OverviewCampaignCardContainer, OverviewCampaignCardContainerMobile, OverviewInfoContainer, OverviewSongImage, OverviewText, OverviewTitleContainer, SongName, useStyles } from "./styles";
import DefaultImg from "assets/images/music-key-green-bg.svg";
import { ReactComponent as ContextualMenuIcon } from "assets/images/contextualMenu.svg";
import CustomMenu, { MenuElement } from "components/shareable/CustomMenu/index";
import { ReactComponent as BackArrowIcon } from 'assets/images/BackArrow.svg';
import { useMediaQuery, useTheme } from "@material-ui/core";
import BadgeCampaignTypeContainer from '../BadgeCampaignTypeContainer/BadgeCampaignTypeContainer';
import CampaignStatusBadge from "../CampaignStatusBadge";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

interface Props {
  goBack: () => void;
  name: string;
  thumbnail?: string;
  // TODO: change this any to deestructured definition
  campaignStatus?: string;
  campaignStatusLabel?: string;
  isLoading?: boolean;
}

const PlaylistingOverviewCampaignCard = ({
  isLoading,
  goBack,
  name,
  thumbnail,
  campaignStatus = 'pending',
  campaignStatusLabel
}: Props) => {
  const classes = useStyles();
  const theme = useTheme()
  const { isBrandAdmin } = useContext(CurrentBrandContext)
  const isMdWidthScreen = useMediaQuery(theme.breakpoints.down(1024))

  const campaignName = name;

  const menuItems: MenuElement[] = [];

  const showMoreOptions = (status: string) => false
  const showMoreOptionsButton = showMoreOptions(campaignStatus);

  const campaignImage = () => {
    return (
      <OverviewSongImage
        src={thumbnail || DefaultImg}
      />
    )
  }

  const getMenuItemsByStatus = (status: string) => {
    return menuItems;
  }

  if (isMdWidthScreen) {
    return (
      <OverviewCampaignCardContainerMobile>
        <BackArrowActionsContainerMobile>
          <BackArrowIcon onClick={goBack} />
          <OverviewActionsContainer>
            {showMoreOptionsButton && isBrandAdmin &&
              <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
                <OverviewAction>
                  Edit <ContextualMenuIcon className={classes.actionIcon} />
                </OverviewAction>
              </CustomMenu>
            }
          </OverviewActionsContainer>
        </BackArrowActionsContainerMobile>
        <OverviewTitleContainer>
          {campaignImage()}
          <OverviewInfoContainer>
            <OverviewText>Overview</OverviewText>
            <SongName>{campaignName}</SongName>
            <OverviewBadgesContainer>
              <BadgeCampaignTypeContainer
                campaignType={"get_playlisted"}
              />
              {!isLoading &&
                <CampaignStatusBadge
                  campaignStatus={campaignStatus}
                  campaignStatusLabel={campaignStatusLabel}
                />
              }
            </OverviewBadgesContainer>
          </OverviewInfoContainer>
        </OverviewTitleContainer>
      </OverviewCampaignCardContainerMobile>
    );
  }

  return (
    <OverviewCampaignCardContainer>
      <BackArrowIcon onClick={goBack} />
      <OverviewTitleContainer>
        {campaignImage()}
        <OverviewInfoContainer>
          <OverviewText>Overview</OverviewText>
          <SongName>{campaignName}</SongName>
          <OverviewBadgesContainer>
            <BadgeCampaignTypeContainer
              campaignType="get_playlisted"
            />
            {!isLoading &&
              <CampaignStatusBadge
                campaignStatus={campaignStatus}
                campaignStatusLabel={campaignStatusLabel}
              />
            }
          </OverviewBadgesContainer>
        </OverviewInfoContainer>
      </OverviewTitleContainer>
      <OverviewActionsContainer>
        {showMoreOptionsButton && isBrandAdmin &&
          <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
            <OverviewAction>
              Edit <ContextualMenuIcon className={classes.actionIcon} />
            </OverviewAction>
          </CustomMenu>
        }
      </OverviewActionsContainer>
    </OverviewCampaignCardContainer>
  )
}

export default PlaylistingOverviewCampaignCard;