import DialogPaper from "components/shareable/DialogPaper";

import { ReactComponent as EditIcon } from "assets/images/edit.svg"
import Colors from "modules/Colors";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import SecondaryButton from "components/shareable/SecondaryButton";
import { BodyContainer, ButtonsContainer } from "./styles";

interface Props {
  open: boolean
  onClose: () => void
  onEdit: () => void
  loading?: boolean
}

const EditConversionsStepModal = ({
  open,
  onClose,
  onEdit,
  loading = false,
}: Props) => {

  const handleOnEdit = async () => {
    await onEdit()
    onClose()
  }

  return (
    <DialogPaper open={open}>
      <BodyContainer>
        <HeaderModal
          closeModal={onClose}
          title="Are you sure you want to edit?"
          subtitle="Modifying this setup may require adjustments in other setups."
          HeaderIcon={<EditIcon color={Colors.purple} />}
        />
        <ButtonsContainer>
          <SecondaryButton
            onClick={onClose}
            text="Cancel"
          />
          <SecondaryButton
            onClick={handleOnEdit}
            text="Yes, I want to edit"
            loading={loading}
          />
        </ButtonsContainer>
      </BodyContainer>
    </DialogPaper>
  );
}

export default EditConversionsStepModal