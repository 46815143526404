import { makeStyles } from '@material-ui/core';
import Colors from "modules/Colors";
import styled from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';

export const useStyles = makeStyles(() => ({
  tadaIconStroke: {
    height: '24px',
    width: '24px',
    '& path': {
      stroke: Colors.divider,
    },
  },
}));

export const TadaIconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  min-width: 32px;
  min-height: 32px;
  background-color: ${Colors.green};
`;

export const ValidationMessage = styled.article`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 8px;
  background: ${Colors.PAPER_ERROR_CONTAINER};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainCard = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  padding: 24px 16px;
  background-color: ${Colors.textColorWhite};
  width: 100%;
  ${MEDIA_BREAKPOINTS.mobileView} {
    border-radius: 0px;
  }
`;

export const SuccessfulContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
`;
