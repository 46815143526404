import SubscriptionStatusIndicator from 'components/Navbar/Components/SubscriptionStatusIndicator';
import { Caption } from 'components/shareable/Typography';
import { useContext } from 'react';
import { UpgradeModalContext } from 'Hooks/UpgradeModalContext';
import Colors from "modules/Colors";
import { clsx } from 'modules/Utils';
import { PlanType } from 'modules/Const';
import { useCurrentTeam } from 'Hooks/CurrentTeamContext';

/**
 * Props for the UpgradeToUnlockCTA component
 * @interface UpgradeToUnlockCTAProps
 * @property {boolean} requiresPaidPlan - Whether the selected feature requires a paid plan
 * @property {string} [upgradeSource] - Source identifier for tracking upgrade clicks
 * @property {string} buttonText - Text to display on the upgrade button
 * @property {string} paywallText - Warning message to display when feature is locked
 * @property {string} [upgradePopupCallToAction] - Call to action text for the upgrade popup
 * @property {string} [className] - Class name for the component
 * @property {PlanType} [highlightedPlan] - Plan to highlight in the upgrade modal
 */
interface UpgradeToUnlockCTAProps {
    requiresPaidPlan: boolean;
    upgradeSource?: string;
    buttonText: string;
    paywallText?: string;
    upgradePopupCallToAction?: string;
    className?: string;
    highlightedPlan?: PlanType;
}

/**
 * Component that displays an upgrade CTA when a user selects a paid-only feature.
 * Shows a warning message and upgrade button with custom styling.
 * Only renders when requiresPaidPlan is true.
 * 
 * @param {UpgradeToUnlockCTAProps} props - Component props
 * @property {boolean} props.requiresPaidPlan - Whether to show the upgrade CTA
 * @property {string} props.buttonText - Text for the upgrade button
 * @property {string} props.paywallText - Warning message text
 * @property {string} [props.upgradeSource] - Source identifier for tracking
 * @property {string} [props.upgradePopupCallToAction] - Call to action text for the upgrade popup
 * @property {string} [props.className] - Class name for the component
 * @returns {JSX.Element | null} Upgrade CTA component or null if paid plan not required
 * @property {PlanType} [props.highlightedPlan] - Plan to highlight in the upgrade modal
 */
const UpgradeToUnlockCTA = ({
    requiresPaidPlan,
    buttonText,
    paywallText,
    upgradeSource,
    upgradePopupCallToAction,
    className,
    highlightedPlan
}: UpgradeToUnlockCTAProps): JSX.Element | null => {

    const { handleOpenModal } = useContext(UpgradeModalContext);

    const { organization, isOrganizationReady } = useCurrentTeam()

    if (!requiresPaidPlan) return null;

    // Set default paywall text
    let finalPaywallText = paywallText;

    // If user is part of a Team (has organization), they can only upgrade to Pro, not Lite
    // So we override the paywall text to make it clear they need Pro specifically
    if (organization && isOrganizationReady) {
        finalPaywallText = '';
    }

    const handleUpgradeClick = () => {
        handleOpenModal({
            highlightedPlan: highlightedPlan ?? undefined,
            source: upgradeSource ?? undefined,
            upgradeCallToAction: upgradePopupCallToAction ?? undefined
        });
    }

    return (
        <div className={clsx('upgradeToUnlockCTA', className)}>
            {finalPaywallText && <Caption>{finalPaywallText}</Caption>}
            <div style={{
                marginTop: '8px'
            }}>
                <SubscriptionStatusIndicator
                    backgroundColor={Colors.purple}
                    textColor={Colors.textColorWhite}
                    margin="0"
                    maxWidth="100%"
                    text={buttonText}
                    handleOpenModal={handleUpgradeClick}
                />
            </div>
        </div>
    );
};

export default UpgradeToUnlockCTA;
