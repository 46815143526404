import { TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE, TEAM_PLAN_INCLUDED_PRO_ACCOUNTS } from "modules/Const";
import React from "react";
import { Container, StyledLabel } from "styles/shared";
import { Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import Colors from "modules/Colors";
import ButtonSymphony from "components/shareable/ButtonSymphony";
import SwitchSymphony from "components/shareable/SwitchSymphony";

interface SelectableArtistProps {
    name: string;
    selected: boolean;
    image: string;
    isAlreadyPro: boolean;
    setSelected: (selected: boolean) => void;
    seatCount: number;
    overageSeat: boolean;
    removeBrand: () => void;
    canRemove: boolean;
}

const SelectableArtist = ({
    name,
    selected,
    image,
    isAlreadyPro,
    setSelected,
    seatCount,
    overageSeat,
    removeBrand,
    canRemove,
}: SelectableArtistProps) => {
    const styles: Record<string, React.CSSProperties> = {
        image: {
            width: '60px',
            height: '60px',
            borderRadius: '99%'
        },
    }

    const showHelperText = () => {
        if (isAlreadyPro) {
            if (selected) {
                return "This profile will remain on Pro."
            } else {
                return "This profile will be downgraded to the Free tier."
            }
        } else if (selected) {
            return "This profile will be upgraded to Pro."
        } else {
            return "This profile is on the Free tier."
        }
    }

    const renderSeatStatus = () => {
        if (selected) {
            if (overageSeat) {
                return (
                    <Caption color={Colors.purple}>
                        +${TEAM_PLAN_ADDITIONAL_PRO_ACCOUNT_PRICE} / month
                    </Caption>
                )
            } else {
                return (
                    <StyledLabel>
                        {seatCount} / {TEAM_PLAN_INCLUDED_PRO_ACCOUNTS}
                    </StyledLabel>
                )
            }
        }

        return null
    }
    return (
        <Container
            borderBottom="1px solid #E5E7EB"
            padding="12px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            backgroundColor={selected ? `#f7eeff` : '#fff'}
            style={{
                transition: `all 0.2s ease`
            }}
        >
            <Container display="flex" flexDirection="row" gap="8px"
                alignItems="center"
                justifyContent="center">
                <img style={styles.image} src={image} alt="Artist" />
                <Container display="flex" flexDirection="column" gap="0px">
                    <Subtitle1 color={Colors.textColor} >{name}</Subtitle1>
                    <Body2 color={Colors.greyDark}>{showHelperText()}</Body2>
                    {/* TODO: add back in when we have a way to remove artists from the team */}
                    {canRemove &&
                        <ButtonSymphony
                            className="organizationPageRemoveArtistButton"
                            onClick={removeBrand}
                            variant="text"
                            color="red"
                            size="small"
                        >
                            Remove from Team
                        </ButtonSymphony>
                    }
                </Container>
            </Container>
            <SwitchSymphony
                className="organizationPageArtistSwitch"
                disabled={isAlreadyPro}
                checked={selected}
                onChange={() => setSelected(!selected)}
                label={isAlreadyPro ? 'Pro Profile' : 'Upgrade to Pro'}
                labelPlacement="left"
            />
        </Container>
    )
}

export default SelectableArtist