import { useContext } from 'react';
import { Body2 } from 'components/shareable/Typography';
import FB, { loginWithFB } from 'helpers/FB';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { TOAST_ERROR_OPTIONS } from 'pages/post-auth/OrganizationPage/utils';
import { toast } from 'react-toastify';
import { ConnectionsContext } from '../hooks/ConnectionsContext';
import { connectUserToFacebook } from 'pages/post-auth/SettingsPage/Components/AccountTab/api';
import { loggedInFb } from 'services/facebookApi';
import PrimaryButton from 'components/shareable/PrimaryButton';
import LinkButton from 'components/shareable/LinkButton';

interface UnloggedViewProps {
  onClose: () => void;
}

const UnloggedView = ({
  onClose,
}: UnloggedViewProps) => {
  const { setLoggedInFbUser: setMe } = useContext(ConnectionsContext);
  const { currentBrand } = useContext(CurrentBrandContext);
  const { id: brandId, slug: brandSlug, name: brandName } = currentBrand || {};

  const triggerFBLogin = async () => {
    try {
      await loginWithFB();
      await checkFBConnectionStatus();
    } catch (error) {
      console.error('error connecting FB: ', error)
      toast.error('Error logging in to Facebook', TOAST_ERROR_OPTIONS)
    }
  }

  const checkFBConnectionStatus = async () => {
    try {
      if (!brandId || !brandSlug || !brandName) return;

      const userLoggedIn = await new Promise((resolve, reject) => {
        FB().getLoginStatus(function (response: any) {
          if (response.status === "connected") {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });

      if (userLoggedIn) {
        await connectUserToFacebook({
          brandId,
          brandSlug,
          brandName,
        });
        const profileData = await loggedInFb();
        setMe(profileData);
      }
    } catch (error) {
      throw new Error("Error connecting to Facebook");
    }
  }

  return (
    <div className='unloggedViewComponent flex flex-col gap-4'>
      <Body2>You need to log in to your Facebook Profile</Body2>
      <div className='flex gap-6 items-center'>
        <PrimaryButton text='Login with Facebook' onClick={triggerFBLogin} />
        <LinkButton text='Cancel' onClick={onClose} />
      </div>
    </div>
  );
};

export default UnloggedView;