import {
  CAMPAIGN_TYPE_MAPPINGS,
  LITE_ANNUAL_PLAN_PRICE,
  LITE_MONTHLY_PLAN_PRICE,
  PRO_MONTHLY_PLAN_PRICE,
  PlanType,
  SYMPHONY_WEBSITE_URL,
  PRO_ANNUAL_BY_MONTH_PLAN_PRICE,
  PRO_ADS_COMISSION,
  FREE_ADS_COMISSION
} from "modules/Const"
import { GeographicTargetingType } from "pages/post-auth/MarketingPage/MusicStreams/TargetingView/reducer"
import { CurrentBrand, BrandContent, OrganizationData, MemberRoles, UserData } from "types/global"
import isEqual from "lodash/isEqual"
import {
  ITimezone,
  ITimezoneOption,
} from "components/shareable/TimezoneSelect/types/timezone"
import allTimezones from "components/shareable/TimezoneSelect/timezone-list"
import spacetime from "spacetime"
import soft from "timezone-soft"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { toast, ToastOptions } from "react-toastify"
import { CSSProperties, useEffect, useState } from "react"
import moment from "moment"

/**
 * Used to trigger virtual page views for VWO A/B testing campaigns
 * @param path 
 */
export const triggerVWOVirtualPageView = (path: string) => {
  const baseUrl = 'https://beta.symphonyos.co'; // Base URL of your app
  const fullVirtualPageUrl = `${baseUrl}${path}`; // Construct the full URL

  window.VWO = window.VWO || [];
  window.VWO.push(['activate', {
    virtualPageUrl: fullVirtualPageUrl
  }]);
  console.log("triggered virtual popup event for VWO", path)
};

const VALID_URL_REGEXP = /(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

const TOAST_DEFAULT_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  closeButton: true,
};

const TOAST_WARNING_OPTIONS: ToastOptions = {
  ...TOAST_DEFAULT_OPTIONS,
};

const TOAST_SUCCESS_OPTIONS: ToastOptions = {
  ...TOAST_DEFAULT_OPTIONS,
  style: {
    background: "#07C806",
    color: "#FFFFFF",
    margin: "16px 16px auto 16px",
    fontSize: "16px",
    borderRadius: "8px",
  }
};

const TOAST_ERROR_OPTIONS: ToastOptions = {
  ...TOAST_DEFAULT_OPTIONS,
  style: {
    background: "#FF0000",
    color: "#FFFFFF",
    margin: "16px 16px auto 16px",
    fontSize: "16px",
    borderRadius: "8px",
  }
};

export const getAdsComission = (isProBrand: boolean) =>
  isProBrand ? PRO_ADS_COMISSION : FREE_ADS_COMISSION

export const formattedWebsiteUrl = (
  brand?: CurrentBrand,
  content?: BrandContent
) => {
  if (!brand || !content) return SYMPHONY_WEBSITE_URL

  const brandSlug = brand.slug
  const contentSlug = content.slug

  if (content.url) {
    const contentSlugFormatted = content.url.replace(`${brandSlug}-`, "")
    return `${SYMPHONY_WEBSITE_URL}/${brandSlug}/${contentSlugFormatted}`
  } else {
    return `${SYMPHONY_WEBSITE_URL}/${brandSlug}/${contentSlug}`
  }
}

/**
 * Checks if the user has administrator permissions for the organization
 * 
 * @param {OrganizationData} currentOrganization - The organization object to check permissions against
 * @returns {boolean} True if user has administrator or owner permissions, false otherwise
 * 
 * @example
 * const isOrgAdmin = isOrganizationAdministrator(organization);
 * if (isOrgAdmin) {
 *   // Perform organization admin actions
 * }
 */
export const isOrganizationAdministrator = (currentOrganization: OrganizationData) => {
  return currentOrganization.role === MemberRoles.ADMIN || currentOrganization.role === MemberRoles.OWNER
}

export const isValidURL = (string: string) => {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
  return (res !== null)
};

export const fixGeographicTargets = (target: GeographicTargetingType[]) => target.map(o => ({
  ...o,
  locations: Object.entries(o.locations).map(([, value]) => value)
}))

export const sleep = async (ms: number) => {
  return new Promise<void>((res, rej) => {
    setTimeout(() => {
      res()
    }, ms)
  })
}

export const timeZoneParser = (labelStyle: string) => {
  const getOptions = (labelStyle: string) =>
    Object.entries(allTimezones)
      .reduce<ITimezoneOption[]>((selectOptions, zone) => {
        const now = spacetime.now(zone[0])
        const tz = now.timezone()
        const tzStrings = soft(zone[0])

        let label = ""
        let abbr = now.isDST()
          ? tzStrings[0].daylight?.abbr
          : tzStrings[0].standard?.abbr
        let altName = now.isDST()
          ? tzStrings[0].daylight?.name
          : tzStrings[0].standard?.name

        const min = tz.current.offset * 60
        const hr =
          `${(min / 60) ^ 0}:` + (min % 60 === 0 ? "00" : Math.abs(min % 60))
        const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${zone[1]}`

        switch (labelStyle) {
          case "original":
            label = prefix
            break
          case "altName":
            label = `${prefix} ${altName?.length ? `(${altName})` : ""}`
            break
          case "abbrev":
            label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ""}`
            break
          case "abbrevNoPrefix":
            label = `${zone[1]} ${abbr?.length < 5 ? `(${abbr})` : ""}`
            break
          default:
            label = `${prefix}`
        }

        selectOptions.push({
          value: tz.name,
          label: label,
          offset: tz.current.offset,
          abbrev: abbr,
          altName: altName,
        })

        return selectOptions
      }, [])
      .sort(
        (a: ITimezoneOption, b: ITimezoneOption) =>
          (a.offset || 0) - (b.offset || 0)
      )

  const findFuzzyTz = (zone: string) => {
    let currentTime = spacetime.now("GMT")
    try {
      currentTime = spacetime.now(zone)
    } catch (err) {
      return
    }
    return getOptions(labelStyle)
      .filter(
        (tz: ITimezoneOption) =>
          tz.offset === currentTime.timezone().current.offset
      )
      .map((tz: ITimezoneOption) => {
        let score = 0
        if (
          currentTime.timezones[tz.value.toLowerCase()] &&
          !!currentTime.timezones[tz.value.toLowerCase()].dst ===
          currentTime.timezone().hasDst
        ) {
          if (
            tz.value
              .toLowerCase()
              .indexOf(
                currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)
              ) !== -1
          ) {
            score += 8
          }
          if (
            tz.label
              .toLowerCase()
              .indexOf(
                currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)
              ) !== -1
          ) {
            score += 4
          }
          if (
            tz.value
              .toLowerCase()
              .indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf("/")))
          ) {
            score += 2
          }
          score += 1
        } else if (tz.value === "GMT") {
          score += 1
        }
        return { tz, score }
      })
      .sort((a, b) => b.score - a.score)
      .map(({ tz }) => tz)[0]
  }

  const parseTimezone = (zone: ITimezone) => {
    if (typeof zone === "object" && zone.value && zone.label) return zone
    if (typeof zone === "string") {
      return (
        getOptions(labelStyle).find((tz) => tz.value === zone) ||
        (zone.indexOf("/") !== -1 && findFuzzyTz(zone))
      )
    } else if (zone.value && !zone.label) {
      return getOptions(labelStyle).find((tz) => tz.value === zone.value)
    }
  }

  return parseTimezone
}

export const showNotFoundIGPostWarningToast = (posts: InstagramPost[], selectedPost?: InstagramPost) => {
  if (selectedPost?.id) {
    const { id: selectedId } = selectedPost
    const found = posts.find(({ id }) => id === selectedId)

    if (!found) {
      toast.warning(
        "We couldn't find the previously selected Instagram post. Please select another.",
        TOAST_WARNING_OPTIONS
      )
    }
    return Boolean(found)
  }
}

export const getRefreshInstagramPost = (selectedInstagramPost: InstagramPost, newestInstagramPost: InstagramPost[]) => {
  if (selectedInstagramPost?.id) {
    const { id: selectedId } = selectedInstagramPost;
    const found = newestInstagramPost.find(({ id }) => id === selectedId);

    return found || newestInstagramPost[0];
  }
  return null;
}

export const showToast = (args: { error?: boolean; message: string, mobile?: boolean }) => {
  const { error, message, mobile } = args
  const marginToast = mobile
    ? "24px 16px auto 16px"
    : "40px 22px auto";
  const options = error ? TOAST_ERROR_OPTIONS : TOAST_SUCCESS_OPTIONS;
  options.style = {
    ...options.style,
    margin: marginToast,
  };

  if (error) {
    toast.error(message, options);
  } else {
    toast.success(message, options);
  }
}

export const isValidEmail = (email: string) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email); //eslint-disable-line

export const getStatusToShowPaymentFailedBanner = (brand?: CurrentBrand) => brand?.subscription?.status === 'unpaid'

export const checkIsValidUrl = (value: string) => value.match(VALID_URL_REGEXP) !== null

export interface TargetedCountries {
  budget: number
  description?: string
  id?: string
  locations: {
    value: string
    label: string
    type: string
    country_code: string | null
    region_id: string | null
  }[]
  name: string
}

export const showFixTargetingSnackbar = (targeted: TargetedCountries[]) => {
  const foundEmptyLocations = targeted.find(
    ({ locations }) => !locations.length
  )

  return Boolean(foundEmptyLocations)
}

export const SNACKBAR_WRONG_TARGETING_MESSAGE = "It looks like you submitted no countries in a targeting bucket. Please enter at least one location to all the location buckets you defined."

export const goToUrl = (url: string, setIsLoading?: (isLoading: boolean) => void) => {
  let newWindow;
  try {
    newWindow = window.parent.open(url, '_self')
  } catch { }

  if (!newWindow) {
    try {
      newWindow = window.parent.open(url, '_blank')
      if (newWindow && setIsLoading) setIsLoading(false);
    } catch { }
  }

  if (!newWindow) {
    try {
      newWindow = window.parent.open('', '_blank')
      if (newWindow) {
        newWindow.document.write('Loading...');
        newWindow.location.href = url;
        if (setIsLoading) setIsLoading(false);
      }
    } catch { }
  }

  if (!newWindow) window.parent.location.href = url;
}

export const extractArrayByKey = (data: object, key: string): any[] => {
  return Object.values(data).filter(item => item[key] !== undefined).map(item => item[key]);
}

export const objectToArray = (data: object): any[] => {
  return Object.entries(data).map(([key, value]) => {
    return { ...value, key };
  });
}

type WithOrderAndKey<T> = T & { order: number; key: string; };

export const objectToArrayInOrder = <T extends object>(obj: Record<string, T>): WithOrderAndKey<T>[] => {
  return Object.entries(obj)
    .map(([key, value]) => ({ key, ...value as any, order: (value as any).order }))
    .sort((a, b) => a.order - b.order);
};

export const arrayToObject = (array: any[], keyName = 'key'): { [key: string]: any } => {
  const result: { [key: string]: any } = {};
  array.forEach(item => {
    // Check if the specified key exists in the item; if not, skip this item
    if (!(keyName in item)) return;

    // Extract the value for the specified key and the rest of the properties
    const { [keyName]: keyValue, ...rest } = item;
    // Use the keyValue as the new object's key and the rest as its value
    result[keyValue] = rest;
  });
  return result;
};

export const findObjectWithValue = (
  array: any[],
  key: string,
  value: any
): object | null => {
  return array.find(obj => obj[key] === value) || null;
};

export const findIndexWithValue = (
  array: any[],
  key: string,
  value: any
): number => {
  return array.findIndex(obj => obj[key] === value);
};

export const convertSnakeCase = (
  str: string,
): string => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export function isValidUrl(string: string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

export const deepCopy = <T>(obj: T): T => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }

  if (Array.isArray(obj)) {
    const arrCopy = obj.map((item) => deepCopy(item));
    return arrCopy as unknown as T;
  }

  if (typeof obj === "object") {
    const objCopy: { [key: string]: any } = {};
    Object.keys(obj).forEach((key) => {
      objCopy[key] = deepCopy((obj as { [key: string]: any })[key]);
    });
    return objCopy as T;
  }

  throw new Error('Unable to copy object!');
};

export const newTab = (url: string): void => {
  if (!url) {
    console.error('URL is required to open in new tab');
    return;
  }

  window.open(url, '_blank');
};

export const formattedRecordName = (recordName: string, explicit?: boolean) => {
  let originalName = recordName;
  if (!explicit && originalName.slice(-8) === " (Clean)") {
    originalName = originalName.slice(0, recordName.length - 8)
  }

  return originalName
}


// utils/checkUserAgent.ts
export const isMobileSafari = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipod|ipad/.test(userAgent) && /safari/.test(userAgent);
};

export const isDesktopSafari = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /safari/.test(userAgent) && !/chrome/.test(userAgent) && !isMobileSafari();
};

export const useIsSafari = (): boolean => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isSafariBrowser = /safari/.test(userAgent) && !/chrome|chromium|edg/.test(userAgent);
    setIsSafari(isSafariBrowser);
  }, []);

  return isSafari;
};

export const isPossiblyInProgress = (status: string, local_release: boolean | undefined, release_url_upc_isrc: string | undefined, preSaveEndDate: string): boolean => {
  return (isEqual(status, "ACTIVE") && local_release && Boolean(release_url_upc_isrc) && moment().isBetween(moment(preSaveEndDate), moment(preSaveEndDate).add(60, 'minutes'))) || false;
}

export const pluralize = (word: string, count: number) => count === 1 ? word : `${word}s`;

export const isOrganizationAdminOrOwner = (user?: UserData | null) => {
  if (!user?.organization) return false;

  return user.organization.role === 'administrator' || user.organization.role === 'owner';
}

export const getYearlyPrice = (planType: PlanType) => {
  switch (planType) {
    case PlanType.LITE:
      return LITE_ANNUAL_PLAN_PRICE
    case PlanType.PRO:
    default:
      return PRO_ANNUAL_BY_MONTH_PLAN_PRICE
  }
}

export const getMonthlyPrice = (planType: PlanType) => {
  switch (planType) {
    case PlanType.LITE:
      return LITE_MONTHLY_PLAN_PRICE
    case PlanType.PRO:
    default:
      return PRO_MONTHLY_PLAN_PRICE
  }
}

export const formatNumberToCurrency = (value: number | undefined, showZeroDecimals: boolean = false) => {
  if (!value) return '0.00';
  return value.toLocaleString('en-US', { minimumFractionDigits: showZeroDecimals ? 0 : 2, maximumFractionDigits: 2 });
};

/**
 * Gets a search parameter value from a URL search string or full URL
 * @param search - URL search string (e.g. '?param=value') or full URL. If not provided, uses window.location.search
 * @param name - Name of the parameter to get
 * @param defaultValue - Default value to return if parameter not found
 * @returns The parameter value or the default value
 */
export const getSearchParam = ({
  name,
  search,
  defaultValue = ''
}: {
  name: string,
  search?: string | undefined,
  defaultValue?: any
}) => {
  // If no search string provided, get from window location
  const searchInput = search || window.location.search;

  // If full URL provided, split to get search params
  const searchString = searchInput.includes('?') ? searchInput.split('?')[1] : searchInput;

  // Remove leading ? if present
  const cleanSearch = searchString.startsWith('?') ? searchString.substring(1) : searchString;

  const value = new URLSearchParams(cleanSearch).get(name);
  return value || defaultValue;
}
export const getUrlParam = (param: string, defaultValue: string | number | null = null): string | number | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param) || defaultValue;
};

export const getItemCountsDict = ({
  campaignsData,
  instagramData,
  spotifyData
}: {
  campaignsData: any[],
  instagramData: any[],
  spotifyData: any[]
}) => {
  let itemCountsDictLocal: { [key: string]: any[] } = {};

  campaignsData.forEach((campaign: any) => {
    const date = campaign.updatedAt.split('T')[0];
    const campaignWithType = { type: 'campaign', date: date, ...campaign };

    if (!itemCountsDictLocal[date]) {
      itemCountsDictLocal[date] = [campaignWithType];
    } else {
      itemCountsDictLocal[date].push(campaignWithType);
    }
  })

  instagramData.forEach((post: any) => {
    const date = post.timestamp;
    const postWithType = { type: 'instagram', date: date, ...post };
    if (!itemCountsDictLocal[date]) {
      itemCountsDictLocal[date] = [postWithType]
    } else {
      itemCountsDictLocal[date].push(postWithType);
    }
  })

  spotifyData.forEach((release: any) => {
    const date = release.release_date;
    const releaseWithType = { type: 'spotify', date: date, ...release };

    if (!itemCountsDictLocal[date]) {
      itemCountsDictLocal[date] = [releaseWithType];
    } else {
      itemCountsDictLocal[date].push(releaseWithType);
    }
  })

  return itemCountsDictLocal;
}

export const getCampaignName = (campaign_metadata: any, campaignName?: string | null | undefined) => {
  if (!campaign_metadata) {
    return '';
  }

  const { friendlyName, content, contentType, campaign_type } = campaign_metadata;

  if (campaignName) return campaignName

  switch (campaign_type) {
    default:
      let mainName = (friendlyName || content?.name || content?.campaign_name || campaignName || CAMPAIGN_TYPE_MAPPINGS[campaign_type]?.title)

      if (contentType === 'playlist') {
        mainName = content?.name + ' (Playlist)'
      }
      return mainName
  }
};

export const hasAnnotations = (annotations: any) => {
  const {
    campaigns,
    instagram,
    spotify
  } = annotations

  return campaigns || instagram || spotify;
}

/**
 * Gets a cookie value by its name
 */
export const getCookie = (name: string, defaultValue: string = '') => {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return defaultValue;
}

/**
 * Sets a cookie with the specified name and value
 */
export const setCookie = (name: string, value: string = 'true', options: {
  days?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
} = {}) => {
  const {
      days = 30,
      path = '/',
      domain,
      secure = true,
      sameSite = 'Lax'
  } = options;

  // Calculate expiration
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  
  // Build cookie string
  let cookieString = `${name}=${value}; expires=${date.toUTCString()}; path=${path}`;
  
  if (domain) cookieString += `; domain=${domain}`;
  if (secure) cookieString += '; secure';
  if (sameSite) cookieString += `; samesite=${sameSite}`;

  document.cookie = cookieString;
}

/**
* Deletes a cookie by setting its expiration to the past
*/
export const deleteCookie = (name: string, path = '/') => {
  setCookie(name, '', { days: -1, path });
}

export const styx = (...styles: (CSSProperties | undefined | null | boolean)[]): CSSProperties => {
  return Object.assign({}, ...styles.filter(Boolean));
};

export const clsx = (...args: any[]): string => {
  const classes: string[] = [];
  
  const handleArg = (arg: any): void => {
    if (!arg) return;

    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg.toString());
    } else if (Array.isArray(arg)) {
      arg.forEach(handleArg);
    } else if (typeof arg === 'object') {
      Object.entries(arg).forEach(([key, value]) => {
        if (value) {
          classes.push(key);
        }
      });
    }
  };

  args.forEach(handleArg);
  return classes.filter(Boolean).join(' ');
};

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
              return;
          }
          seen.add(value);
      }
      return value;
  };
}

export const utilsGet = (object: any, path: string, defaultVal?: any) => {
  //console.log("UTILS.GET", object, path, defaultVal);
  window.test = object;
  if(!object || typeof object != "object") {
      return defaultVal;
  }
  if(!path) {
      return defaultVal;
  }
  var parts = path.split(".");
  var cur = object;
  for (var idx in parts) {
    if(!cur) {
        return defaultVal;
    }

    var pathPart = parts[idx];
    if (pathPart in cur) {
        cur = cur[pathPart];
        if(cur == null) {
            return defaultVal;
        }
    } else {
      return defaultVal;
    }
  }
  return cur;
}

export const findKeyPath = (obj: any, searchValue: any, isNumber: boolean) => {
  searchValue = isNumber && !isNaN(searchValue) ? parseInt(searchValue) : searchValue;
  const queue = [{ obj, path: '' }];
  const results = [];

  while (queue.length > 0) {
      const { obj, path } = queue.shift() as { obj: any, path: string };

      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const newPath = path ? `${path}.${key}` : key;

              if (Array.isArray(obj[key])) {
                  obj[key].forEach((item: any, index: number) => {
                      queue.push({ obj: item, path: `${newPath}.${index}` });
                  });
              } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                  queue.push({ obj: obj[key], path: newPath });
              } else if (obj[key] === searchValue) {
                  results.push(newPath);
              }
          }
      }
  }
  
  let resultsString = results.length > 0 ? results.join('\n') : null;
  return resultsString;
}