import CustomTable from '../CustomTable';
import EmptyFanbase from "../EmptyFanbase";
import { FanbaseContainer } from './styles';

interface Props {
  loading: boolean,
  hasFans: boolean,
  openFanDetailModal: (fan: any) => void,
}

const Fanbase = ({
  loading,
  hasFans,
  openFanDetailModal,
}: Props) => {
  if (loading) {
    return null
  }

  return (
    <FanbaseContainer className="fanbaseComponent" justifyContent={hasFans ? "flex-start" : "center"}>
      {hasFans ?
        <CustomTable openFanDetailModal={openFanDetailModal} />
        :
        <EmptyFanbase />
      }
    </FanbaseContainer>
  );
};

export default Fanbase;
