import ButtonSymphony from "components/shareable/ButtonSymphony"
import useStyles from "./styles"
import Intercom from "helpers/Intercom"

export const NotFoundInstaUser = () => {
    const classes = useStyles()
    return (
        <div className={classes.mainContainer}>
            <div className={classes.textContainer}>
                <p className={classes.headerLabel}>
                    Make sure your Instagram is a business or creator account.</p>
                <p className={classes.content}>
                    To track followers, Instagram requires your account to be a creator or business account. We recommend switching to optimize your growth more effectively.&nbsp;
                    <ButtonSymphony
                        style={{ marginTop: 8 }}
                        onClick={() => {
                            Intercom.openInstagramBusinessAccountArticle()
                        }}
                        variant="text"
                    >
                        Learn how to switch <br/>to a Business Account &gt;
                    </ButtonSymphony>
                </p>
            </div>
        </div>
    )
}

export default NotFoundInstaUser