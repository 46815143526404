import { StyledDiv, StyledLi, useStyles } from './styles'
import { ReactComponent as AddIcon } from 'assets/images/campaignDetails/add.svg'
import { Body2, Subtitle2 } from 'components/shareable/Typography'
import LoadingLottie from 'components/Loader/LoadingLottie'
import Colors from 'modules/Colors'

interface CreateAccountOptionProps {
  accountType: 'Pixel' | 'Ad Account' | 'Facebook Business Manager'
  description: JSX.Element | string
  disabled?: boolean
  isCreatingAccount?: boolean
  onClick?: () => void
}

export const CreateAccountOption = ({
  accountType,
  description,
  disabled,
  isCreatingAccount,
  onClick,
}: CreateAccountOptionProps) => {
  const { iconAccentColor, iconDisabledColor } = useStyles()

  function renderTitleText() {
    let titleText = ''
    let verb = isCreatingAccount ? 'Creating' : 'Create'
    switch (accountType) {
      case 'Pixel':
        titleText = `${verb} a new Pixel`
        break
      case 'Ad Account':
        titleText = `${verb} a new Ad Account`
        break
      case 'Facebook Business Manager':
        titleText = `Connect to an Ad Account linked to a Facebook Business Manager to generate a tracking pixel`
        break
    }
    return titleText
  }
  return (
    <StyledLi onClick={disabled ? undefined : onClick}>
      {isCreatingAccount ? (
        <LoadingLottie black width={24} height={24} />
      ) : (
        <AddIcon className={disabled ? iconDisabledColor : iconAccentColor} />
      )}
      <StyledDiv>
        <Subtitle2 color={Colors.purple}>
          {renderTitleText()}
        </Subtitle2>
        <Body2>{description}</Body2>
      </StyledDiv>
    </StyledLi>
  )
}
