import Axios from "helpers/Interceptor";
import { CurrentBrand, PaymentMethods } from "types/global";
import * as Sentry from "@sentry/react";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { PlanType } from "modules/Const";

/**
 * Redirects user to the upgrade plan checkout page to upgrade their account to Pro status.
 * This endpoint handles both upgrading the user's account to Pro and collecting payment details.
 * 
 * @param currentBrand - The current brand object containing brand details
 * @param data - Checkout data including campaign and billing details
 * @returns Object with error flag if the request fails
 */
export const redirectToUpgradePlanCheckout = async (
    currentBrand: CurrentBrand,
    data: Record<string, unknown>,
) => {

    try {
        const response = await Axios.post(
            `/campaign/brand/${currentBrand.id}/checkout`,
            data
        );

        window.location = response.data.data.url;
    } catch (error) {
        Sentry.captureException(error);
        console.error("error: ", error);
        return { error: true }
    }
};

/**
 * Redirects user to the one-off campaign checkout page.
 * This endpoint is specifically for non-Pro users to create and pay for individual campaigns
 * without requiring a Pro subscription.
 * 
 * @param currentBrand - The current brand object containing brand details
 * @param data - Campaign data including budget, targeting and content details
 * @returns Object with error flag if the request fails
 */
export const redirectToOneOffCampaignCheckout = async (
    currentBrand: CurrentBrand,
    data: Record<string, unknown>,
) => {
    try {
        const response = await Axios.post(
            `/campaign/brand/${currentBrand.id}/checkout/one-off`,
            data
        );

        window.location = response.data.data.url;
    } catch (error) {
        Sentry.captureException(error);
        console.error("error: ", error);
        return { error: true }
    }
};

export interface UpgradePlanAndRedirectToCheckoutParams {
    recurring_interval: BillingCycle,
    payment_method: PaymentMethods.STRIPE,
    checkout_source?: 'onboarding-signup',
    plan_type: PlanType,
    brandSlug: string,
    is_trial: boolean
}

/**
 * Upgrades a brand's plan and redirects to the Stripe checkout page.
 * This function handles the upgrade process for brand subscriptions, including trial periods.
 * 
 * @param {Object} params - The parameters for upgrading the plan
 * @param {BillingCycle} params.recurring_interval - The billing cycle (monthly/annual) for the subscription
 * @param {PaymentMethods.STRIPE} params.payment_method - The payment method (Stripe only)
 * @param {'onboarding-signup'} [params.checkout_source] - Source of the checkout, e.g. onboarding flow
 * @param {PlanType} [params.plan_type] - The plan type being upgraded to (Pro, Team etc)
 * @param {string|null} [params.brandSlug] - The brand's unique slug identifier
 * @param {boolean} [params.is_trial] - Whether this is a trial subscription
 * 
 * @returns {Promise<void>} Redirects to Stripe checkout on success, logs error on failure
 */
export const upgradePlanAndRedirectToCheckout = async({
    recurring_interval,
    payment_method,
    checkout_source,
    plan_type,
    brandSlug,
    is_trial
}: UpgradePlanAndRedirectToCheckoutParams) => {
    // pull rewardful data here and add it into the data object
    const data = {
        recurring_interval,
        payment_method,
        checkout_source,
        plan_type,
        brandSlug,
        is_trial
    }
    try {
        const res = await Axios.post(`/subscription/brand/${brandSlug}/checkout`, data)

        window.location = res.data.data.url
    } catch (error) {
        Sentry.captureException(error)
        console.error("Brand Suscription checkout failed: ", error)
    }
}
