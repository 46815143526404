import {
    useContext,
    useState,
    useEffect
} from 'react';
import mobileIconImage from 'assets/images/mobile.svg';
import useScreen from 'Hooks/useScreen';
import { ButtonsContainer } from 'components/shareable/Modal/styles';
import { Container } from 'styles/shared';
import { useHistory } from 'react-router-dom';
import CardOption from 'components/shareable/Modal/CardOption';
import SharedMusicViewSelector from 'pages/post-auth/MarketingPage/Components/SuperboostModal/Components/shared-music-selector-view';
import { v4 } from 'uuid';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import DataCollectIcon from 'assets/images/websiteThemes/data-collector-site.svg';
import TourSiteIcon from 'assets/images/websiteThemes/tour.svg';
import PresaveIcon from 'assets/images/websiteThemes/presave-site.svg';
import { track } from 'analytics';
import { isValidURL } from "modules/Utils"
import { WEBSITE_TYPES, WebsiteType } from 'types/global';
import ButtonSymphony from 'components/shareable/ButtonSymphony';
import ModalSymphony from 'components/shareable/ModalSymphony';

// Add this function at the top of your file, outside of any component
const generateShortUniqueId = (): string => {
    const fullUuid = v4();
    return Buffer.from(fullUuid.replace(/-/g, ''), 'hex').toString('base64').replace(/[+/=]/g, '').substring(0, 12);
}

export enum Steps {
    SELECT_OPTION = "SELECT_OPTION",
    SELECT_RECORD = "SELECT_RECORD",
}

export const CreateWebsiteOptions = ({
    step,
    setStep
}: {
    step: Steps;
    setStep: (step: Steps) => void;
}) => {
    const history = useHistory()
    const screenBreakpoints = useScreen();
    const { currentBrand } = useContext(CurrentBrandContext);
    const { mobileView } = screenBreakpoints;

    const handleBack = () => {
        setStep(Steps.SELECT_OPTION)
    }

    const handleChangeRecord = async (inputValue: string, slug: string | null) => {
        if (!slug) return
        if (isValidURL(inputValue)) {
            const url = encodeURIComponent(inputValue)
            history.push(`/website/record_streams/${slug}?url=${url}`)
        } else {
            history.push(`/website/record_streams/${slug}`)
        }
    }

    const cardOptions = [
        {
            thumbnail: MusicSiteIcon,
            text: "Promote your Songs, Projects, and Playlists",
            subtitle: "Showcase your latest releases with our music site - perfect for promoting your songs, albums, and playlists across streaming platforms.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'music' })
                setStep(Steps.SELECT_RECORD)
            }
        },
        {
            thumbnail: DataCollectIcon,
            text: "Collect Fan Data",
            subtitle: "Build your email + phone number list with our data collection toolset - perfect for fan clubs, drops, RSVPs, and anything in between.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'data collector' })
                const slug = generateShortUniqueId()
                history.push(`/website/data_collector/${slug}`)
            }
        },
        {
            thumbnail: TourSiteIcon, // TODO CONNER: placeholder icon from Megh
            text: "Promote Events",
            subtitle: "Showcase your latest event or tour with our events site.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'tour' })
                const slug = generateShortUniqueId()
                history.push(`/website/tour/${slug}`)
            }
        },
        {
            thumbnail: PresaveIcon,
            text: "Create a Pre-Save",
            subtitle: "Build hype before your next release with Symphony's pre-save playbook.",
            onClick: () => {
                track('Start "New Website" flow', { type: 'pre-save' })
                history.push('/marketing/new/presave')
            }
        },
    ]

    return (
        <>
            {step === Steps.SELECT_OPTION && (
                <Container 
                    className="createNewSiteModalInnerContainerSelectOption" 
                    display='flex'
                    gap="12px" 
                    flexDirection='column'
                    style={{
                        width: '100%'
                    }}
                >
                    {cardOptions.map((option, index) => (
                        <CardOption
                            className="createSiteOptionCreateNewSiteModal"
                            key={index}
                            thumbnail={option.thumbnail}
                            text={option.text}
                            subtitle={option.subtitle}
                            onClick={option.onClick}
                        />
                    ))}
                </Container>
            )}
            {step === Steps.SELECT_RECORD && (
                <Container
                    className="createNewSiteModalInnerContainerSelectRecord"
                    display='flex'
                    gap="12px"
                    flexDirection='column'
                    width="100%"
                >
                    <SharedMusicViewSelector
                        isSpotifyConnected={Boolean(currentBrand?.connections?.spotify_artist_page)}
                        letUserSearchSpotify
                        handleChangeRecord={handleChangeRecord}
                        options={['single', 'project', 'playlist']}
                    />
                    <ButtonsContainer
                        flexDirection={mobileView ? "column-reverse" : "row"}
                    >
                        <ButtonSymphony
                            className="createNewSiteModalBackButton"
                            onClick={handleBack}
                            width={mobileView ? "100%" : "fit-content"}
                            variant="outlined"
                        >
                            Back
                        </ButtonSymphony>
                    </ButtonsContainer>
                </Container>
            )}
        </>
    )
}

interface Props {
    isOpen: boolean
    onClose: () => void;
    isFirstSite?: boolean;
    titleText?: string;
    initialSiteType?: WebsiteType | null;
}

const CreateNewWebsiteModal = ({
    isOpen,
    onClose,
    isFirstSite,
    initialSiteType
}: Props) => {
    const screenBreakpoints = useScreen();
    const { mobileView } = screenBreakpoints;
    const history = useHistory();

    const [step, setStep] = useState<Steps>(Steps.SELECT_OPTION);

    useEffect(() => {
        if (initialSiteType) {
            handleInitialSiteType(initialSiteType);
        }
    }, [initialSiteType]);

    const handleInitialSiteType = (siteType: WebsiteType) => {
        switch (siteType) {
            case WEBSITE_TYPES.RECORD_STREAMS:
                setStep(Steps.SELECT_RECORD);
                break;
            case WEBSITE_TYPES.DATA_COLLECTOR:
                track('Start "New Website" flow', { type: 'data collector' });
                const dataCollectorSlug = generateShortUniqueId();
                history.push(`/website/data_collector/${dataCollectorSlug}`);
                onClose();
                break;
            case WEBSITE_TYPES.TOUR:
                track('Start "New Website" flow', { type: 'tour' });
                const tourSlug = generateShortUniqueId();
                history.push(`/website/tour/${tourSlug}`);
                onClose();
                break;
            case WEBSITE_TYPES.PRESAVE:
                track('Start "New Website" flow', { type: 'pre-save' });
                history.push('/marketing/new/presave');
                onClose();
                break;
        }
    };

    let titleText = 'Create a New Website'
    if (isFirstSite) {
        titleText = 'Create your first site'
    }

    if (step === Steps.SELECT_RECORD) {
        titleText = "Create a Music Site"
    }

    let subtitleText = "Select a website based on your marketing goal - every site is hyper-optimized to help you grow your audience and track your data."
    if (step === Steps.SELECT_RECORD) {
        subtitleText = "Search for your release or paste a Spotify URL to create your website."
    }

    return (
        <ModalSymphony
            fullScreen={mobileView}
            isOpen={isOpen}
            onClose={onClose}
            title={titleText}
            subtitle={subtitleText}
            headerIcon={mobileIconImage}
        >
            <CreateWebsiteOptions
                step={step}
                setStep={setStep}
            />
        </ModalSymphony>
    )
}

export default CreateNewWebsiteModal