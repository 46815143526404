import { GridColDef } from "@mui/x-data-grid"
import { ReactComponent as CrownIcon } from "assets/images/crown.svg"
import { Body2, ButtonText } from "components/shareable/Typography"
import { Fragment } from "react"
import { RemoveButton } from "./styles"
import { Container } from "styles/shared"
import Colors from "modules/Colors"

export interface Row {
  id?: string
  name?: string
  email?: string
  isOwner: boolean
  removable?: boolean
}

export const getDataGridColumns = (data: {
  onRemove: (row: Row | null) => void;
}) => {
  const {
    onRemove
  } = data

  const grid: GridColDef[] = [
    //TODO: Add this when name of user is ready
    // {
    //   field: "name",
    //   headerName: "Name",
    //   flex: 1,
    //   editable: false,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Body2 color={Colors.PRIMARY_TEXT_COLOR}>
    //       {(params.row as Row).name}
    //     </Body2>
    //   ),
    // },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      editable: false,
      sortable: false,
      renderCell: (params) => <Body2>{(params.row as Row).email}</Body2>,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      editable: false,
      sortable: false,
      align: "right",
      renderCell: (params) => {
        const row = params.row as Row
        const isOwner = row.isOwner
        const removable = row.removable

        const handleRemove = () => {
          onRemove(row)
        }

        return (
          <Container display="flex" flexDirection="row"
            alignItems="center" gap="16px"
            justifyContent="center">
            {isOwner &&
              <Container
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="4px"
                flexDirection="row">
                <Fragment>
                  <CrownIcon />
                  <Body2>Owner</Body2>
                </Fragment>
              </Container>
            }
            {removable && (
              <RemoveButton enabled onClick={() => {
                handleRemove()
              }}>
                <ButtonText color={Colors.greyDark}
                  style={{
                    transform: 'translateY(2px)'
                  }}>
                  Remove
                </ButtonText>
              </RemoveButton>
            )}
          </Container>
        )
      }
    },
    // {
    //   field: "removable",
    //   headerName: "Remove",
    //   flex: 1,
    //   editable: false,
    //   sortable: false,
    //   align: "right",
    //   renderCell: (params) => {
    //     const row = params.row as Row
    //     const owner = row.owner
    //     const handleRemove = () => onRemove(row)

    //     return (
    //       <RemoveButton {...{ owner }} onClick={handleRemove}>

    //         <ButtonText color={Colors.SECONDARY_TEXT_COLOR}>
    //           Remove
    //         </ButtonText>
    //       </RemoveButton>
    //     )
    //   }
    // },
  ]
  return grid
}
