import { useState } from "react";
import { LoggedUser } from "types/global";
import HeaderModal from "../HeaderModal";
import { DialogContent } from "@material-ui/core";
import DialogPaper from "components/shareable/DialogPaper";
import AccountContainer from "components/AccountContainer";
import SecondaryButton from "components/shareable/SecondaryButton";
import PrimaryButton from "components/shareable/PrimaryButton";
import useScreen from "Hooks/useScreen";
import { ReactComponent as WarningIcon } from "assets/images/campaignDetails/warning.svg";
import { useStyles } from "./styles";
import { Body2, Subtitle1 } from "components/shareable/Typography";

interface LogoutModalProps {
  open: boolean;
  me: LoggedUser;
  closeModal: () => void;
  logout: () => Promise<void>;
}

const LogoutModal = ({
  open,
  me,
  closeModal,
  logout
}: LogoutModalProps) => {
  const classes = useStyles();
  const { mobileView } = useScreen();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
    } finally {
      setLoading(false);
    }
  }

  return (
    <DialogPaper
      open={open}
      fullScreen={mobileView}
      dialogStyle={{
        margin: mobileView ? 0 : 'auto 16px',
        borderRadius: mobileView ? 0 : 8,
        maxWidth: 700,
      }}
      containerStyle={{
        margin: mobileView ? 0 : 'auto',
        padding: mobileView ? 8 : '32px 24px',
      }}
    >
      <DialogContent className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <HeaderModal
            closeModal={closeModal}
            title="Are you sure you want to logout?"
            titleBigger
            HeaderIcon={WarningIcon}
          />
          <div className={classes.warningBanner}>
            <Subtitle1>All of your Facebook + Instagram connections will be removed.</Subtitle1>
            <Body2>Symphony relies on your Facebook login access to run ads, analyze audiences, and track data for your FB + IG ads. You can always reconnect at any time.</Body2>
          </div>
        </div>
        <AccountContainer
          className="accountContainerLogoutModal"
          title={me?.name || ""}
          iconImg={me?.picture?.data.url}
          connectedChip
          connectedText="Logged in"
        />
        <div className="flex gap-3">
          <PrimaryButton
            text={!loading ? "Log out" : "Logging out"}
            onClick={handleLogout}
            disabled={loading}
            variant="error"
          />
          <SecondaryButton text="Cancel" onClick={closeModal} disabled={loading} />
        </div>
      </DialogContent>
    </DialogPaper>
  );
}

export default LogoutModal;