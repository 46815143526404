import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { HIDDEN_NAVBAR_ROUTES } from 'modules/Const';
import { CurrentBrand, OrganizationData } from 'types/global';

interface NavbarState {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
    isHoverSmallSidebar: boolean;
    setIsHoverSmallSidebar: (isHoverSmallSidebar: boolean) => void;
    shouldHideNavbar: (mobileView: boolean, organization: OrganizationData | undefined, currentBrand: CurrentBrand | undefined) => boolean;
    isWebsiteChildPage: () => boolean;
    isAddArtistPage: () => boolean;
}

export const useNavbar = create(immer<NavbarState>((set, get) => ({
    isCollapsed: false,
    setIsCollapsed: (isCollapsed: boolean) => set({ isCollapsed }),
    isHoverSmallSidebar: false,
    setIsHoverSmallSidebar: (isHoverSmallSidebar: boolean) => set({ isHoverSmallSidebar }),

    isWebsiteChildPage: () => {
        return !!window.location.pathname.match(/^\/website\/[^/]+\/[^/]+$/)
    },

    isAddArtistPage: () => {
        return window.location.pathname.includes('/add-artist')
    },

    shouldHideNavbar: (mobileView: boolean, organization: OrganizationData | undefined, currentBrand: CurrentBrand | undefined) => {
        const location = window.location;

        // Check for specific hidden routes
        if (HIDDEN_NAVBAR_ROUTES.includes(location.pathname)) {
            return true
        }

        /** Hide mobile navigation bar when campaign view is active */
        if (mobileView && location.pathname.indexOf('/campaigns/') > -1) {
            return true;
        }

        if (get().isWebsiteChildPage() || location.pathname.match(/^\/marketing\/new\/.+/)) {
            return true;
        }

        // Special case for add-artist page (only hidden on mobile)
        if (location.pathname === '/add-artist' && mobileView) {
            return true;
        }

        // if the user is on the "setup team UI", hide the navbar
        if (location.pathname === "/team" && organization?.status === "waiting_for_details") {
            return true;
        }

        // Hide navbar on import-fans page
        if (location.pathname === "/import-fans") {
            return true;
        }

        // Hide navbar on marketing success pages
        if (location.pathname.match(/^\/marketing\/success/)) {
            return true;
        }

        // Check other conditions
        return (
            !currentBrand
        );
    }
})))
