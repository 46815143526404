import React from 'react';

interface PauseIconProps {
    width: string;
    height: string;
    color: string;
}

const PauseIcon: React.FC<PauseIconProps> = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g stroke={color ? color : "#B78D40"} strokeLinecap="round" clipPath="url(#a)">
                <path d="M12.448 4.333a1 1 0 0 0-2 0v7.334a1 1 0 1 0 2 0V4.334ZM6.448 4.333a1 1 0 1 0-2 0v7.334a1 1 0 1 0 2 0V4.334Z" /></g>
            <defs>
                <clipPath id="a"><path fill="#fff" d="M.448 0h16v16h-16z" /></clipPath>
            </defs>
        </svg>
    );
};

export default PauseIcon;

