
/**
 * Interface defining the structure of a font theme style
 */
export interface FontThemeStyle {
    /** Display title of the font */
    title: string;
    /** Unique identifier for the font */
    name: string;
    /** Order in which the font appears in the selector */
    order: number;
    /** Whether this font is only available to Pro users */
    isPaidOnly: boolean;
}

/**
 * Type defining the available font theme style keys
 */
export type FontThemeStyleType = keyof typeof fontThemeStyles;

/**
 * Available font theme styles with their configurations
 */
export const fontThemeStyles: Record<string, FontThemeStyle> = {
    font1: {
        title: "DIN",
        name: "font1",
        order: 1,
        isPaidOnly: false,
    },
    font4: {
        title: "Baskerville",
        name: "font4",
        order: 4,
        isPaidOnly: true,
    },
    font5: {
        title: "Kode Mono",
        name: "font5",
        order: 5,
        isPaidOnly: true,
    },
    font6: {
        title: "Lato",
        name: "font6",
        order: 6,
        isPaidOnly: true,
    },
    font7: {
        title: "DM Serif Display",
        name: "font7",
        order: 7,
        isPaidOnly: true,
    },
    font8: {
        title: "Lobster",
        name: "font8",
        order: 8,
        isPaidOnly: true,
    },
    font9: {
        title: "Dosis",
        name: "font9",
        order: 9,
        isPaidOnly: true,
    },
    font10: {
        title: "Comfortaa",
        name: "font10",
        order: 10,
        isPaidOnly: true,
    },
    font11: {
        title: "Jacquarda Bastarda 9",
        name: "font11",
        order: 11,
        isPaidOnly: true,
    },
    font12: {
        title: "Slackside One",
        name: "font12",
        order: 12,
        isPaidOnly: true,
    },
    font2: {
        title: "Times New Roman",
        name: "font2",
        order: 15,
        isPaidOnly: true,
    },
    font3: {
        title: "Helvetica",
        name: "font3",
        order: 16,
        isPaidOnly: true,
    },
} as const;
