import { Chip as MaterialChip } from '@material-ui/core';
import useStyles from './styles';
import Colors from "modules/Colors";
interface Props {
  children: string | string[]
  backgroundColor?: string
}

const Chip = ({
  children,
  backgroundColor = `${Colors.purple}08`
}: Props) => {
  const classes = useStyles();

  return (
    <MaterialChip
      style={{ backgroundColor }}
      className={classes.chip}
      classes={{
        label: classes.chipLabelColor,
        colorPrimary: classes.chipBackgroundColor,
      }}
      color="primary"
      label={children}
    />
  )
}

export default Chip