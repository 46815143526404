import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import CustomTooltip from 'components/shareable/Tooltip';
import { countryToAlpha2 } from "country-to-iso";
import dayjs from 'dayjs';
import { Fan } from 'types/global';
import { orderByDirection, orderByFields } from '../../constants';
import ContactInfoCell from './EmailCell';
import PlatformCell from './PlatformCell';
import { BlurContainer, UserInformationContainer as SourcePresaveContainer } from './styles';
import TooltipText from './TooltipText';
import { ReactComponent as StarIcon } from 'assets/images/outlinedStar.svg'
import { ReactComponent as PresaveIcon } from 'assets/images/PreSaves.svg'
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';

const LOCATION_BLUR_TEXT = "Xxxxxxx, XX";

export const getFormattedLocation = (
  data: {
    city?: string,
    country?: string,
    isProBrand?: boolean
  }
) => {
  if (!data.isProBrand) return LOCATION_BLUR_TEXT;
  const { city, country } = data;
  if (!city && !country) return "-";
  const countryCode = country ? countryToAlpha2(country) : "";
  const formattedLocation = [city, countryCode].filter((value) => Boolean(value)).join(', ');
  return formattedLocation || "-";
}

export const getFormattedSource = (source?: string, presaveFutureReleases?: boolean): {
  element?: JSX.Element;
  text: string;
} => {
  if (source && source?.indexOf('brandContentSlug') > -1) {
    return { text: "Signed up through website" }
  } else {
    switch (source) {
      case "presave":
        if (presaveFutureReleases) return {
          element: <SourcePresaveContainer>
            <StarIcon />
            Forever Saved
          </SourcePresaveContainer>,
          text: 'Forever Saved'
        }
        return {
          element: <SourcePresaveContainer>
            <PresaveIcon />
            Pre-saved
          </SourcePresaveContainer>,
          text: 'Pre-saved'
        }
      default:
        return { text: "Updated manually" };
    }
  }
}

export const getFormattedSort = (sortBy: GridSortModel) => {
  const { field, sort } = sortBy[0] as { field: string, sort: string };
  const orderBy = orderByFields[field] || orderByFields.added;
  const direction = orderByDirection[sort.toUpperCase()] || orderByDirection.DESC;
  return {
    orderBy,
    direction,
  }
}

export const getColumnDefinition = (isProBrand?: boolean) => {
  const defaultColDef: GridColDef = {
    field: "field",
    headerName: "column",
    sortable: true,
    editable: false,
    disableColumnMenu: true,
    minWidth: 150,
    width: 150,
  }
  return [
    {
      ...defaultColDef,
      field: "email",
      headerName: "Email",
      type: "string",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        const formattedRow = params.row as Fan;
        if (!formattedRow) return;
        return <ContactInfoCell
          cellphone={formattedRow.cellphone}
          email={formattedRow.email}
          name={formattedRow.name}
          lastname={formattedRow.lastname}
          imageUrl={formattedRow.profile_url}
          isNew={formattedRow.isNew}
          newFollower={formattedRow.newFollower}
          newFollowerPlatformName={formattedRow.platform}
        />
      }
    },
    {
      ...defaultColDef,
      width: 170,
      field: "location",
      headerName: (<div className='flex flex-row items-center justify-center gap-1'><UpgradeToProChip type="gem" width={16} height={16} color="purple" />Location</div>),
      renderCell: (params) => {
        const formattedRow = params.row as Fan;
        if (!formattedRow) return;
        if (!isProBrand) return <BlurContainer>{LOCATION_BLUR_TEXT}</BlurContainer>
        return <span>{getFormattedLocation({ city: formattedRow.city, country: formattedRow.country, isProBrand })}</span>
      },
    },
    {
      ...defaultColDef,
      field: "source",
      headerName: "Source",
      renderCell: (params) => {
        const formattedRow = params.row as Fan;
        if (!formattedRow) return;
        const formattedSource = getFormattedSource(formattedRow.origin, formattedRow.presaveFutureReleases);
        return <CustomTooltip
          title={<TooltipText songsList={formattedRow.presavedSongs} formattedSource={formattedSource.text} />}
        >
          <span>{formattedSource.element ? formattedSource.element : formattedSource.text}</span>
        </CustomTooltip>
      },
    },
    {
      ...defaultColDef,
      field: "added",
      headerName: "Added",
      type: "string",
      renderCell: (params) => {
        const formattedRow = params.row as Fan;
        if (!formattedRow) return;
        return <span>{dayjs(formattedRow.createdAt).format("MMM DD, YYYY")}</span>
      }
    },
    {
      ...defaultColDef,
      field: "platform",
      headerName: "Platform",
      renderCell: (params) => {
        const formattedValue = params.row as Fan;
        if (!formattedValue) return;
        return <PlatformCell
          platformKey={formattedValue.platform}
        />
      }
    },
  ] as GridColDef[];
}
